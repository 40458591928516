import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { TextAreaField } from '@atomic/atm.text-field';
import { H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { Strings } from '../../order-update.string';
import { setGeneralComment, setIsCommentModalOpen } from '../../store/order-update.store';

export const ModalAddComment: React.FC = () => {
  const strings = Strings.pt;
  const [comment, setComment] = React.useState<string>();
  const { isCommentModalOpen } = useSelector((state: RootState) => state.orderUpdate);
  const dispatch = useDispatch();

  const onClose = () => dispatch(setIsCommentModalOpen(false));

  const onSaveComment = () => {
    dispatch(setGeneralComment(comment));
    dispatch(setIsCommentModalOpen(false));
  };
  return (
    <Modal small opened={isCommentModalOpen} onClose={onClose}>
      <Grid fluid>
        <H2>{strings.comment.title}</H2>
        <VSeparator />
        <TextAreaField
          placeholder={strings.comment.placeholder}
          onValueChange={(value: string) => setComment(value)}
          disableResize
        />
        <VSeparator />
        <Hbox hAlign='flex-end'>
          <Hbox.Item noGrow>
            <Button kind='secondary' onClick={onClose}>
              {strings.comment.goBack}
            </Button>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item noGrow>
            <Button kind='primary' onClick={onSaveComment}>
              {strings.comment.save}
            </Button>
          </Hbox.Item>
        </Hbox>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
