import { ColDef, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { MutableRefObject } from 'react';
import { CriticalItemDto } from '@app/data/http/order-priorization.dto';
import { DeliveryStatus, SalesOrderSummaryItem } from '@app/models';
import { OpenMIStatusKey } from '@app/modules/order/grid-filters/grid-open-row-filter.component';
import { sizeInFormatter, valueQtyFormatter } from '@app/utils/order-column-formatter';

interface HandleFilterParams {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  filter: string;
  handleFilterCheck: (checkedFilters: string) => any;
}

export const handleFilter = ({ gridEvent, filter, handleFilterCheck }: HandleFilterParams): any => {
  if (!gridEvent || !gridEvent.current) {
    return;
  }

  const filterData = gridEvent.current.api.getFilterInstance('status');
  if (filterData) {
    const aux = handleFilterCheck(filter);

    const model = Object.keys(aux).reduce((acc, cur) => {
      if (aux[cur]) {
        acc.push(cur);
      }

      return acc;
    }, []);

    filterData.setModel({ values: model });
    gridEvent.current.api.onFilterChanged();
  }
};

interface HandleSelectionChangeParams {
  e: SelectionChangedEvent;
  setSelectedRows: (value: any) => void;
}

export const handleSelectionChange = ({ e, setSelectedRows }: HandleSelectionChangeParams) => {
  setSelectedRows(e.api.getSelectedRows());
};

export const handleFilterCheck = (checkedFilters: string) => {
  const statusFilter = [OpenMIStatusKey.Implanted, OpenMIStatusKey.OnSchedule, OpenMIStatusKey.Confirmed];
  let implanted = true;
  let onSchedule = true;
  let confirmed = true;
  let checkedFilterSplit = [];

  if (checkedFilters) {
    checkedFilterSplit = checkedFilters.split(',');

    statusFilter.forEach(c => {
      if (c === OpenMIStatusKey.Implanted) {
        const isCheckedImplanted = checkedFilterSplit.find(chk => chk === OpenMIStatusKey.Implanted);
        isCheckedImplanted ? (implanted = true) : (implanted = false);
      }

      if (c === OpenMIStatusKey.OnSchedule) {
        const isCheckedOnSchedule = checkedFilterSplit.find(chk => chk === OpenMIStatusKey.OnSchedule);
        isCheckedOnSchedule ? (onSchedule = true) : (onSchedule = false);
      }

      if (c === OpenMIStatusKey.Confirmed) {
        const isCheckedConfirmed = checkedFilterSplit.find(chk => chk === OpenMIStatusKey.Confirmed);
        isCheckedConfirmed ? (confirmed = true) : (confirmed = false);
      }
    });
  } else {
    implanted = false;
    onSchedule = false;
    confirmed = false;
  }

  return {
    [OpenMIStatusKey.Implanted]: implanted,
    [OpenMIStatusKey.OnSchedule]: onSchedule,
    [OpenMIStatusKey.Confirmed]: confirmed,
  };
};

export const handleOpenTabColumn = (columns: ColDef[], language: string) => {
  const valueColumnsFields = ['thickness, width'];

  if (columns) {
    columns.forEach(column => {
      if (column.field === 'status' || column.field === 'deliveryStatus') {
        column.filter = 'agSetColumnFilter';
        column.floatingFilter = false;
      }

      if (valueColumnsFields.includes(column.field)) {
        column.valueFormatter =
          language === 'in' ? param => valueQtyFormatter(param, true) : param => valueQtyFormatter(param, false);
      }

      if (column.field === 'diameter') {
        column.valueFormatter = param => sizeInFormatter(param, language);
      }
    });
  }
};

interface HandleCriticalFilterParams {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  criticalFilter: boolean;
}

export const handleCriticalFilter = ({ gridEvent, criticalFilter }: HandleCriticalFilterParams): any => {
  if (!gridEvent || !gridEvent.current) {
    return;
  }

  const filterData = gridEvent.current.api.getFilterInstance('activateCritical');

  if (filterData) {
    if (criticalFilter) {
      filterData.setModel({
        filterType: 'text',
        type: 'equals',
        filter: criticalFilter.toString(),
      });

      gridEvent.current.api.onFilterChanged();
    } else {
      filterData.setModel({});
      gridEvent.current.api.onFilterChanged();
    }
  }
};

interface HandleLackOfChargeFilterParams {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  lackOfChargeFilter: boolean;
}

export const handleLackOfChargeFilter = ({ gridEvent, lackOfChargeFilter }: HandleLackOfChargeFilterParams): any => {
  if (!gridEvent || !gridEvent.current) {
    return;
  }

  const filterData = gridEvent.current.api.getFilterInstance('deliveryStatus');

  if (filterData) {
    if (lackOfChargeFilter) {
      filterData.setModel({ values: [DeliveryStatus.LackOfCharge] });
      gridEvent.current.api.onFilterChanged();
    } else {
      filterData.setModel({});
      gridEvent.current.api.onFilterChanged();
    }
  }
};

export const isEditCriticalItem = (selectedRows: SalesOrderSummaryItem[], criticalItems: CriticalItemDto[]) => {
  let isEdit = false;

  selectedRows.forEach(s => {
    const criticalItem = criticalItems?.find(c => c.ov === s.ov);

    if (criticalItem) {
      isEdit = true;
    }
  });

  return isEdit;
};
