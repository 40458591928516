export interface IFinanceStrings {
  showLimits: string;
  feedback: string;
  paid: string;
  expired: string;
  notExpired: string;
  status: {
    notExpired: string;
    expired: string;
    paid: string;
  };
  credit: {
    limit: string;
    total: string;
    expirationDate: string;
  };
  limit: {
    creditLimit: string;
    percentageUsed: string;
    openedInvoice: string;
    batchOrders: string;
    expirationDate: string;
    nonBindingCredit: string;
    nonBindingCreditTip: string;
  };
  tableMI: {
    documentNumber: string;
    invoiceKey: string;
    launchDate: string;
    experyDate: string;
    amount: string;
    statusCompensation: string;
    documentType: string;
  };
  tableME: {
    shipment: string;
    shipmentDate: string;
    experyDateBranch: string;
    days: string;
    amountBranch: string;
    status: string;
    currencyBranch: string;
  };
  tooltip: {
    documentNumber: string;
    invoiceKey: string;
    launchDate: string;
    experyDate: string;
    amount: string;
    statusCompensation: string;
    documentType: string;
  };
  successSaveLayout: string;
  agGrid: {
    subtotal: string;
    error: string;
  };
  refresh: string;
  modal: {
    note: string;
  };
  fraudControl: {
    title: string;
    actionButton: string;
    confirmButton: string;
  };
  fileName: string;
}

interface IFinanceLanguages {
  pt: IFinanceStrings;
  es: IFinanceStrings;
  in: IFinanceStrings;
}

const pt: IFinanceStrings = {
  showLimits: 'Ver limites',
  feedback: 'De 1 a 5, o quão você está satisfeito com as informações de finanças nessa página?',
  paid: 'Pago',
  expired: 'Vencida',
  notExpired: 'A vencer',
  status: {
    notExpired: 'A vencer',
    expired: 'Vencidas',
    paid: 'Pagas',
  },
  credit: {
    limit: 'Seu limite de crédito',
    total: 'Total utilizado',
    expirationDate: 'Vencimento do seu limite de crédito',
  },
  limit: {
    creditLimit: 'Limite de crédito:',
    percentageUsed: 'Percentual do crédito utilizado',
    openedInvoice: 'NFs em aberto',
    batchOrders: 'Carteira até D+3',
    expirationDate: 'Vencimento do seu limite de crédito:',
    nonBindingCredit: 'Crédito sem vinculação:',
    nonBindingCreditTip:
      'Valor que já foi pago pelo cliente e está aguardando o CS fazer a vinculação com uma nota fiscal.',
  },
  tableMI: {
    documentNumber: 'Nº NF/ND',
    invoiceKey: 'Chave de Acesso',
    launchDate: 'Data Documento',
    experyDate: 'Vencimento',
    amount: 'Valor Total',
    statusCompensation: 'Status Pagamento',
    documentType: 'Tipo de Documento',
  },
  tableME: {
    shipment: 'Nº Fatura/Invoice',
    shipmentDate: 'Data documento',
    experyDateBranch: 'Vencimento',
    days: 'Dias em atraso',
    amountBranch: 'Valor',
    status: 'Status',
    currencyBranch: 'Moeda',
  },
  tooltip: {
    documentNumber: 'Nº NF/ND',
    invoiceKey: 'Chave de acesso referente a Nota Fiscal.',
    launchDate: 'Data da emissão da Nota Fiscal.',
    experyDate: 'Data de vencimento da fatura.',
    amount: 'Valor financeiro da Nota Fiscal.',
    statusCompensation: 'Status do Pagamento (Pago / A vencer / Vencido)',
    documentType: 'Tipo de Documento',
  },
  successSaveLayout: 'Fique tranquilo! A configuração das colunas acabou de ser salva.',
  agGrid: {
    subtotal: 'Subtotal:',
    error: 'Ocorreu um erro ao carregar os dados de Finanças! Por favor, tente novamente.',
  },
  refresh: 'Atualizar',
  modal: {
    note:
      'Obs: A concessão do limite está sujeita à análise de crédito. As regras e condições podem ser revisadas a qualquer tempo pela Gerdau, sem prévia comunicação. O consumo do limite de crédito compreenderá as faturas em aberto e todos os pedidos em carteira com remessa prevista para até 03 dias.',
  },
  fraudControl: {
    title: 'Recomendação de segurança',
    actionButton: 'Saiba mais como se proteger',
    confirmButton: 'Entendi',
  },
  fileName: 'Finanças',
};

const es: IFinanceStrings = {
  showLimits: 'Ver limites',
  feedback: 'Del 1 al 5, ¿qué tan satisfecho está con la información financiera en esta página?',
  paid: 'Pago',
  expired: 'En abierto - retraso',
  notExpired: 'En abierto',
  status: {
    notExpired: 'En abierto',
    expired: 'En abierto - retraso',
    paid: 'Pago',
  },
  credit: {
    limit: 'Tu límite de crédito',
    total: 'Total usado',
    expirationDate: 'Expiración de su límite de crédito',
  },
  limit: {
    creditLimit: 'Limite de crédito:',
    percentageUsed: 'Porcentaje de crédito utilizado',
    openedInvoice: 'Facturas abiertas',
    batchOrders: 'Cartera hasta D+3',
    expirationDate: 'Expiración de su límite de crédito',
    nonBindingCredit: 'Crédito no vinculante:',
    nonBindingCreditTip:
      'Monto que ya ha sido pagado por el cliente y está esperando que el CS lo vincule a una factura.',
  },
  tableMI: {
    documentNumber: 'Nº NF/ND',
    invoiceKey: 'Chave de Acesso',
    launchDate: 'Data Documento',
    experyDate: 'Vencimento',
    amount: 'Valor Total',
    statusCompensation: 'Status Pagamento',
    documentType: 'Tipo de Documento',
  },
  tableME: {
    shipment: 'Factura/Número de factura',
    shipmentDate: 'Fecha del documento',
    experyDateBranch: 'Fecha de vencimiento',
    days: 'Días de retraso',
    amountBranch: 'Valor',
    status: 'Estado',
    currencyBranch: 'Moneda',
  },
  tooltip: {
    documentNumber: 'Nº NF/ND',
    invoiceKey: 'Clave de acceso referente a la factura.',
    launchDate: 'Fecha de emisión de la factura.',
    experyDate: 'Fecha de vencimiento de la factura.',
    amount: 'Valor económico de la factura.',
    statusCompensation: 'Estado de pago (pagado / vencerá / vencido)',
    documentType: 'Tipo de documento.',
  },
  successSaveLayout: '¡Quédate tranquilo! La configuración de los altavoces se acaba de guardar.',
  agGrid: {
    subtotal: 'Subtotal:',
    error: '¡Ocurrió un error al cargar los datos de Finanzas! Inténtalo de nuevo.',
  },
  refresh: 'Actualizar',
  modal: {
    note:
      'Nota: El otorgamiento del límite está sujeto a análisis crediticio. Las reglas y condiciones podrán ser revisadas por Gerdau en cualquier momento, sin previo aviso. El consumo del límite de crédito comprenderá las facturas abiertas y todos los pedidos atrasados ​​con envío programado hasta 03 días.',
  },
  fraudControl: {
    title: 'Recomendación de seguridad',
    actionButton: 'Obtenga más información sobre cómo protegerse',
    confirmButton: 'Entendí',
  },
  fileName: 'Finanzas',
};

const en: IFinanceStrings = {
  showLimits: 'Ver limites',
  feedback: 'From 1 to 5, how satisfied are you with the finance information on this page?',
  paid: 'Paid',
  expired: 'Expired',
  notExpired: 'Not Expired',
  status: {
    notExpired: 'Not Expired',
    expired: 'Expired',
    paid: 'Paid',
  },
  credit: {
    limit: 'Your credit limit',
    total: 'Total used',
    expirationDate: 'Expiration of your credit limit',
  },
  limit: {
    creditLimit: 'Credit limit:',
    percentageUsed: 'Percentage of credit used',
    openedInvoice: 'Open invoices',
    batchOrders: 'Order up to D+3',
    expirationDate: 'Expiration of your credit limit',
    nonBindingCredit: 'Non-binding credit:',
    nonBindingCreditTip:
      'Amount that has already been paid by the customer and is waiting for the CS to link it to an invoice.',
  },
  tableMI: {
    documentNumber: 'Nº NF/ND',
    invoiceKey: 'Chave de Acesso',
    launchDate: 'Data Documento',
    experyDate: 'Vencimento',
    amount: 'Valor Total',
    statusCompensation: 'Status Pagamento',
    documentType: 'Tipo de Documento',
  },
  tableME: {
    shipment: 'Invoice/Invoice No.',
    shipmentDate: 'Document date',
    experyDateBranch: 'Due date',
    days: 'Days',
    amountBranch: 'Amount',
    status: 'Estatus',
    currencyBranch: 'Currency',
  },
  tooltip: {
    documentNumber: 'Nº NF/ND',
    invoiceKey: 'Access key referring to the Invoice.',
    launchDate: 'Invoice issue date.',
    experyDate: 'Invoice due date.',
    amount: 'Financial value of the Invoice.',
    statusCompensation: 'Payment Status (Paid / Will expire / Overdue)',
    documentType: 'Document Type',
  },
  successSaveLayout: 'Do not worry! The speaker configuration has just been saved.',
  agGrid: {
    subtotal: 'Subtotal:',
    error: 'An error occurred while loading the Finance data! Please try again.',
  },
  refresh: 'Update',
  modal: {
    note:
      'Note: The granting of the limit is subject to credit analysis. The rules and conditions may be revised by Gerdau at any time, without prior notice. The consumption of the credit limit will comprise open invoices and all backlog orders with shipment scheduled for up to 03 days.',
  },
  fraudControl: {
    title: 'Security recommendation',
    actionButton: 'Learn more about how to protect yourself',
    confirmButton: 'Understood',
  },
  fileName: 'Finance',
};

export const Strings: IFinanceLanguages = {
  pt,
  es,
  in: en,
};
