import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Color, FontFamily, Spacing } from '@atomic/obj.constants';

export const OptionsWrapper = styled.div`
  position: absolute;
  z-index: 20;
  overflow-x: hidden;
  top: 40px;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  background-color: ${Color.White};
  border: 2px solid ${Color.Primary};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-family: ${FontFamily.Primary};
  width: 100%;
`;

export const OptionItens = styled(FlexRow)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-left: ${Spacing.Small};
  &:hover {
    background-color: ${Color.GrayLight};
  }
`;
