import { createAsyncThunk } from '@reduxjs/toolkit';
import { ScheduleDataSource } from '@app/data/http/schedule.datasource';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { SLICE_NAME } from '../../schedule.constants';

export const getLayoutDefault = createAsyncThunk(`${SLICE_NAME}/getLayout`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const customerIds: string = customerIdsJoinedSelector(state);

  const values = await Promise.all([
    ScheduleDataSource.getLayoutCart(customerIds),
    ScheduleDataSource.getLayoutStock(customerIds),
  ]);

  return { layoutDefaultCart: values[0], layoutDefaultStock: values[1] };
});

export const addGetLayoutReducers = builder => {
  builder.addCase(getLayoutDefault.pending, state => {
    state.loadingLayout = true;
  });
  builder.addCase(getLayoutDefault.fulfilled, (state, action) => {
    const { payload } = action;
    state.loadingLayout = false;
    state.layoutDefaultCart = payload.layoutDefaultCart;
    state.layoutDefaultStock = payload.layoutDefaultStock;
    state.layout = payload;
  });
  builder.addCase(getLayoutDefault.rejected, state => {
    state.loadingLayout = false;
  });
};
