import { IOrderPriorizationState } from '../order-priorization.interfaces';

export const initialState: IOrderPriorizationState = {
  rows: [],
  showOrderPriorization: false,
  showConfirmBtn: true,
  showConfirmModal: false,
  showRemoveItemModal: false,
  itemToRemove: null,
  loading: false,
  criticalItemInfoLoading: false,
  criticalItemInfo: null,
  activedCriticalItem: null,
  showSuccessPage: false,
  alreadyOpenedModalMessage: false,
};
