import * as React from 'react';
import { Spacing } from '@atomic/obj.constants';
import { FeedBackIconWrapper } from './image.component.style';

export const Image = {
  Isa: () => <img src='assets/img/ic_isa.png' width='100%' />,
  LoadingTooltipArrow: () => <img src='assets/img/img_tooltip_arrow.png' width='40px' />,
  LogoHorizontal: (props: any) => <img src='assets/img/logo-horizontal-default.png' width={props.width || '102px'} />,
  LogoVertical: (props: any) => <img src='assets/img/logo_vertical_default.png' width={props.width || '80px'} />,
  LogoGerdauCustom: (props: any) => <img src='assets/img/logo-gerdau-custom.png' width={props.width || '52px'} />,
  LogoGerdauMax: (props: any) => <img src='assets/img/logo-gerdau-max.png' width={props.width || '52px'} />,
  LogoGerdauTech: (props: any) => <img src='assets/img/logo-gerdau-tech.png' width={props.width || '52px'} />,
  LogoGerdauStandard: (props: any) => <img src='assets/img/logo-gerdau-standard.png' width={props.width || '52px'} />,
  LogoGerdauMaxPremiumFlat: (props: any) => (
    <img src='assets/icons/brand/logo-gerdau-max-premium-flat.png' width={props.width || '52px'} />
  ),
  LogoGerdauMaxMachinity: (props: any) => (
    <img src='assets/icons/brand/logo-gerdau-max-machinity.png' width={props.width || '52px'} />
  ),
  LogoGerdauTechInduheat: (props: any) => (
    <img src='assets/icons/brand/logo-gerdau-tech-induheat.png' width={props.width || '52px'} />
  ),
  LogoGerdauMaxCleanity: (props: any) => (
    <img src='assets/icons/brand/logo-gerdau-max-cleanity.png' width={props.width || '52px'} />
  ),
  LogoGerdauTechAlloyedFlat: (props: any) => (
    <img src='assets/icons/brand/logo-gerdau-tech-alloyed-flat.png' width={props.width || '52px'} />
  ),
  LogoGerdauTechEcomachining: (props: any) => (
    <img src='assets/icons/brand/logo-gerdau-tech-ecomachining.png' width={props.width || '65px'} />
  ),
  LogoGerdauDefault: (props: any) => (
    <img src='assets/icons/brand/logo-gerdau-default.png' width={props.width || '52px'} />
  ),
  NotificationIcon: () => <img src='assets/icons/ic-settings-notification.png' />,
  AdminLogo: () => <img src='assets/img/img_logo_white_future.png' />,
  WideLogo: () => (
    <img
      src='assets/img/Logo Gerdau - O futuro se molda - white.png'
      width='100%'
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    />
  ),
  CelebrationWideLogo: () => (
    <img
      src='assets/img/img_celebration_auth_logo.png'
      width='100%'
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    />
  ),
  NewLogo: props => (
    <img
      src='assets/img/gerdau_mais_logo.svg'
      width={props.width || '60%'}
      style={{ marginLeft: '8px', marginRight: 'auto' }}
    />
  ),
  NoDataPlaceholder: (props: any) => (
    <img
      src='assets/img/no-data-placeholder.png'
      width={props.width ? props.width : '200px'}
      height={props.height ? props.height : '200px'}
    />
  ),
  NoNotificationPlaceholder: () => (
    <img src='assets/img/img_notification_placeholder.png' width='200px' height='200px' />
  ),
  ErrorPlaceholder: () => <img src='assets/img/error-placeholder.png' width='200px' height='200px' />,
  NoTrackingPlaceholder: () => <img src='assets/img/no_tracking_placeholder.png' width='200px' height='200px' />,
  TrackingFinishedPlaceholder: () => (
    <img src='assets/icons/truck-tracking-finished.png' width='200px' height='200px' />
  ),
  DestinationMarker: () => <img src='assets/img/subtitle-marker-destination.png' />,
  GerdauMarker: () => <img src='assets/img/subtitle-marker-gerdau.png' />,
  TruckMarker: () => <img src='assets/icons/truck-tracking/truck-icon-R36G.png' style={{ marginTop: '6px' }} />,
  FeedbackLove: props => (
    <FeedbackIcon
      src={props.selected ? 'assets/img/img_feedback_love_selected.png' : 'assets/img/img_feedback_love_unselected.png'}
    />
  ),
  FeedbackLike: props => (
    <FeedbackIcon
      src={props.selected ? 'assets/img/img_feedback_like_selected.png' : 'assets/img/img_feedback_like_unselected.png'}
    />
  ),
  FeedbackNeutral: props => (
    <FeedbackIcon
      src={
        props.selected
          ? 'assets/img/img_feedback_neutral_selected.png'
          : 'assets/img/img_feedback_neutral_unselected.png'
      }
    />
  ),
  FeedbackUnsatisfied: props => (
    <FeedbackIcon
      src={
        props.selected
          ? 'assets/img/img_feedback_unsatisfied_selected.png'
          : 'assets/img/img_feedback_unsatisfied_unselected.png'
      }
    />
  ),
  FeedbackHate: props => (
    <FeedbackIcon
      src={props.selected ? 'assets/img/img_feedback_hate_selected.png' : 'assets/img/img_feedback_hate_unselected.png'}
    />
  ),
  Check: () => <img src='assets/img/img_check.png' />,
  HelpCenterCustom: props => <img src={props.src} width='90%' />,
  HelpCenterStretchImage: props => <img src={props.src} width='100%' />,
  HelpCenterMediumImage: props => <img src={props.src} width='50%' />,
  HelpCenterCustomImage: props => <img src={props.src} width={props.size} />,
  MockDashboardGraph: () => <img src='assets/img/img_graph_placeholder.png' width='300px' height='300px' />,
  OrderImplementationOnboarding: () => <img src='assets/img/order_implantation_onboarding.gif' />,
  TableIcon: () => <img src='assets/icons/ic_planilha.svg' width='25px' />,
  TableIconActive: () => <img src='assets/icons/ic_planilha_active.svg' width='25px' />,
  SelectionIcon: () => <img src='assets/icons/ic_selecao.svg' width='25px' />,
  SelectionIconActive: () => <img src='assets/icons/ic_selecao_active.svg' width='25px' />,
  HistoryIcon: () => <img src='assets/icons/ic_historico.svg' width='25px' />,
  HistoryIconActive: () => <img src='assets/icons/ic_historico_active.svg' width='25px' />,
  RefreshIcon: () => <img src='assets/icons/ic-refresh.svg' width='20px' />,
  UploadNormLoadingImage: () => <img src='assets/img/processinguploadLoading.png' width='450px' />,
  File: () => <img src='assets/icons/file.png' width='50px' />,
  SpreadsheetColumns: () => <img src='assets/img/order-input/spreadsheet-columns.png' width='75%' />,
  SpreadsheetConfig: () => (
    <img src='assets/img/order-input/spreadsheet-config.png' width='60%' style={{ marginTop: Spacing.Small }} />
  ),
  Kanban: () => <img src='assets/img/ico_kanban.png' />,
  NpsPtHeader: () => <img src='assets/img/nps/nps-pt.png' width={'705px'} />,
  NpsEsHeader: () => <img src='assets/img/nps/nps-es.png' width={'705px'} />,
  NpsInHeader: () => <img src='assets/img/nps/nps-in.png' width={'705px'} />,
  NewsModalHeaderBullHorn: () => <img src='assets/icons/bull_horn_blue.png' style={{ marginBottom: '-4px' }} />,
  NewsModalHeaderCloseButton: () => <img src='assets/icons/ic_close_white.png' width='14px' />,
  FlagPT: () => <img src='assets/icons/flag_brazil.png' width='60%' />,
  FlagES: () => <img src='assets/icons/flag_spain.png' width='60%' />,
  FlagIN: () => <img src='assets/icons/flag_us.png' width='60%' />,
  ScheduleModalCalendar: () => <img src='assets/img/schedule.png' />,
  Success: () => <img src='assets/icons/ic-circle-success.png' />,
  SuccessLarge: () => <img src='assets/icons/ic-circle-success-large.png' />,
  DragIcon: () => <img src='assets/icons/drag-icon.svg' width='24px' />,
  CloseIcon: () => <img src='assets/icons/close-icon.png' height='13px' />,
  SendToBaggIcon: () => <img src='assets/icons/ic-send-to-bagg.svg' width='20px' />,
  NewQuotation: () => <img src='assets/icons/ic-new-quotation.svg' width='20px' />,
  ScheduleSuccess: () => <img src='assets/img/schedule-modal/schedule-success.svg' width='224px' height='207px' />,
  ScheduleDelete: () => <img src='assets/img/schedule-modal/schedule-delete.svg' width='224px' height='207px' />,
  OrderInputCalendar: () => <img src='assets/img/order-input/order-input-calendar.png' width='1280px' height='421px' />,
  LogoGerdauMais: () => <img src='assets/icons/brand/logo-gerdau-mais.svg' />,
  ZeroState: () => <img src='assets/img/zero-state/zero-state.png' width='214px' height='216px' />,
  PrioritizedOrder: () => <img src='assets/icons/order/prioritized-order.png' width='46px' height='46px' />,
  BlueCloseIcon: () => <img src='assets/icons/blue-close-icon.png' height='24px' />,
  ErrorHandler: () => <img src='assets/img/error-handler-modal/error-handler.png' />,
  OrderInputMultipleAlert: () => (
    <img src='assets/img/order-input/order-input-multiple-alert.png' width='181px' height='184px' />
  ),
  BrFlagIcon: () => <img src='assets/icons/app-config/br-flag-icon.png' height='20px' />,
  UsFlagIcon: () => <img src='assets/icons/app-config/us-flag-icon.png' height='20px' />,
  EsFlagIcon: () => <img src='assets/icons/app-config/es-flag-icon.png' height='20px' />,
  Mail: () => <img src='assets/icons/app-config/mail-icon.png' height='18px' />,
  InfoCircle: () => <img src='assets/icons/ic_info_outline.png' />,
  BuildingFeature: () => <img src='assets/img/building-feature.png' height='200px' />,
  AttachmentIconActive: () => <img src='assets/icons/ic_attachment.png' width='25px' />,
  IA: () => <img src='assets/icons/order/ic_ia.png' width='20px' />,
  IALoader: () => <img src='assets/icons/order/ic_ia.png' />,
  FileUpload: () => <img src='assets/icons/file.png' width='30px' />,
};

const FeedbackIcon = props => {
  enum FeedbackIconSize {
    heightOnMouseOver = 72,
    heightOnMouseOut = 60,
  }

  return (
    <FeedBackIconWrapper>
      <img
        src={props.src}
        onMouseOver={e => (e.currentTarget.height = FeedbackIconSize.heightOnMouseOver)}
        onMouseOut={e => (e.currentTarget.height = FeedbackIconSize.heightOnMouseOut)}
      />
    </FeedBackIconWrapper>
  );
};
