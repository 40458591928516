import React, { useCallback, useEffect, useState } from 'react';
import update from 'react-addons-update';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IScheduleLayout } from '@app/models/schedule.model';
import { DragItem } from '@app/modules/components/schedule-modal/schedule.interfaces';
import { setOrderColumnLayout } from '@app/modules/components/schedule-modal/store/schedule.store';
import { reIndexDragItems } from '../../../../../utils';
import { OrderColumnItem } from '../order-column-item/selected-column-item';
import { ColumnDragWrapper } from './order-column-container.style';

export const OrderColumnsContainer: React.FC = () => {
  const [cards, setCards] = useState<DragItem[]>([]);
  const { dragItems } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();

  useEffect(() => {
    setCards(dragItems);
  }, [dragItems]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: DragItem[]) => {
      const resultArray = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as IScheduleLayout],
        ],
      });
      const reindexedDragItens = reIndexDragItems(resultArray);
      dispatch(setOrderColumnLayout(reindexedDragItens));
      return resultArray;
    });
  }, []);

  const renderCard = useCallback((card: DragItem, index: number) => {
    return <OrderColumnItem key={card.value} index={index} value={card.value} text={card.label} moveCard={moveCard} />;
  }, []);

  return <ColumnDragWrapper>{cards.map((card, index) => renderCard(card, index))}</ColumnDragWrapper>;
};
