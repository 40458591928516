import React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Caption } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { OrderInputPhaseEnum } from '../../order-input.constants';
import { OrderInputStrings } from '../../order-input.string';
import { WizardProgressWrapper } from './order-input-wizard.style';

interface OrderInputWizardProps {
  phase: OrderInputPhaseEnum;
}

const Strings = OrderInputStrings.pt.phases;

export const OrderInputWizard: React.FC<OrderInputWizardProps> = props => {
  const getFirstPhase = () => {
    switch (props.phase) {
      case OrderInputPhaseEnum.UploadSpreadsheet:
        return OrderInputPhaseEnum.UploadSpreadsheet;
      case OrderInputPhaseEnum.UploadPdfEmail:
        return OrderInputPhaseEnum.UploadPdfEmail;
      case OrderInputPhaseEnum.ItensSelection:
        return OrderInputPhaseEnum.ItensSelection;
      default:
        return OrderInputPhaseEnum.ItensSelection;
    }
  };

  const phasesOptions = {
    [getFirstPhase()]: { title: Strings.Order, percent: 25 },
    [OrderInputPhaseEnum.DataAndAmout]: { title: Strings.DataAndAmout, percent: 50 },
    [OrderInputPhaseEnum.Multiple]: { title: Strings.Multiple, percent: 75 },
    [OrderInputPhaseEnum.Resume]: { title: Strings.Revision, percent: 100 },
  };

  return (
    <FlexRow hAlign='flex-end'>
      <WizardProgressWrapper>
        <progress max='100' value={phasesOptions[props.phase].percent} />
        <FlexRow hAlign='space-around'>
          {Object.values(phasesOptions).map(phase => {
            const isActual: boolean = phasesOptions[props.phase].percent >= phase.percent;
            const isDone: boolean = phasesOptions[props.phase].percent > phase.percent;
            return (
              <FlexColumn key={phase.percent} noGrow>
                <Caption style={isActual ? { color: Color.Purple } : null}>
                  {isDone && <FaIcon.Check size='sm' />}
                  &nbsp;
                  {phase.title}
                </Caption>
              </FlexColumn>
            );
          })}
        </FlexRow>
      </WizardProgressWrapper>
    </FlexRow>
  );
};
