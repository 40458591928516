import { Pagination } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setUserActiveId } from '@app/modules/admin/store/admin.store';
import { getUsers } from '@app/modules/admin/store/thunk/users-get.thunk';
import { LIMIT_ITEMS_PER_PAGE } from '@app/modules/admin/utils/utils';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';

export const AdminUserPagination: React.FC = () => {
  const { userSearch } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handlePagination = page => {
    const query = {
      skip: 0,
      limit: LIMIT_ITEMS_PER_PAGE,
      filterType: userSearch.filterOption,
      filterValue: userSearch.textQuery,
    };

    if (userSearch.selectedOptions?.length) {
      query.filterValue = userSearch.selectedOptions.join();
    } else if (userSearch.textQuery?.length > 2) {
      query.filterValue = userSearch.textQuery;
    } else {
      query.filterValue = '';
    }

    query.skip = Math.ceil(page) * LIMIT_ITEMS_PER_PAGE - LIMIT_ITEMS_PER_PAGE;
    dispatch(setUserActiveId(''));
    dispatch(getUsers(query));
  };

  if (!userSearch.filteredUsers?.length) {
    return null;
  }

  return (
    <FlexRow hAlign='center' mb={Spacing.Small}>
      <Pagination
        ariaLabel='pagination'
        onChange={e => handlePagination(e)}
        select={false}
        showTotal={false}
        total={userSearch.pagination.count}
        pageSizeOptions={[LIMIT_ITEMS_PER_PAGE]}
      />
    </FlexRow>
  );
};
