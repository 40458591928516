import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const CheckboxFieldWrapper = styled.div`
  & > div {
    white-space: nowrap;
  }
`;

export const ColumnFieldsWrapper = styled.div`
  display: flex;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${Spacing.Small};
  border: 1px solid ${Color.GrayLight};
  border-radius: 4px;

  & > div:not(:last-child) {
    margin-right: ${Spacing.XLarge};
  }
`;
