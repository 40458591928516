import { ISurrogateState } from '../surrogate.interfaces';

export const initialState: ISurrogateState = {
  _id: '',
  from: '',
  email: '',
  surrogatesTo: [],
  isIndeterminateTime: false,
  expiresFrom: null,
  expiresTo: null,
  loading: false,
  successMessage: '',
  error: false,
  errorMessage: '',
  showRemoveSurrogate: false,
  isValidSurrogate: true,
  surrogateInfoModalOpened: false,
};
