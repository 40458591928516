import { debounce } from '@app/utils/debounce.utils';
import {
  BODY_HEIGHT_DISCOUNT,
  ON_QTY_CHANGE_DEBOUNCE_TIME,
  ON_RANGE_CHANGE_DEBOUNCE_TIME,
  ON_VALUE_CHANGE_DEBOUNCE_TIME,
} from './sidebar-filters.constants';
import { IRangeState } from './sidebar-filters.interfaces';

export const handleDebounceInputUpdate = debounce<any>((newValue: any, fn: any) => {
  fn?.(newValue);
}, ON_VALUE_CHANGE_DEBOUNCE_TIME);

export const handleDebounceRangeUpdate = debounce<any>((newValue: any, fn: any) => {
  fn?.(newValue);
}, ON_RANGE_CHANGE_DEBOUNCE_TIME);

export const handleDebounceQtyInput = debounce<any>((newValue: any, fn: any) => {
  fn?.(newValue);
}, ON_QTY_CHANGE_DEBOUNCE_TIME);

export const getBodyHeightDiscount = countFilters => {
  if (countFilters >= 5) {
    return BODY_HEIGHT_DISCOUNT[4];
  }
  return BODY_HEIGHT_DISCOUNT[countFilters];
};

export const getMinMaxLimitColumn = (data: any[], field: string): IRangeState => {
  const value = {
    minLimit: 0,
    maxLimit: 0,
    minVal: 0,
    maxVal: 0,
  };

  if (data) {
    const columnValues: number[] = data.filter(row => !!row[field]).map(row => row[field]);

    if (columnValues.length) {
      const [min, max] = [Math.floor(Math.min(...columnValues)), Math.ceil(Math.max(...columnValues))];

      value.minLimit = min;
      value.maxLimit = max;
      value.minVal = min;
      value.maxVal = max;
    }
  }

  return value;
};
