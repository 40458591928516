export interface NotificationItem {
  aboveTitle: string;
  title: string;
  subTitle: string;
  read: boolean;
  steps: NotificationStep[];
  id: string;
}

export enum NoficationStepKeys {
  arrived = 'arrived',
  billed = 'billed',
  identifier = 'identifier',
  inStock = 'inStock',
  orderConfirmation = 'orderConfirmation',
  sentForDelivery = 'sentForDelivery',
  shipCreated = 'shipCreated',
  shipmentStarted = 'shipmentStarted',
  startProduction = 'startProduction',
  statusChange = 'statusChange',
}

export interface NotificationStep {
  key: NoficationStepKeys;
  time: string;
}

export enum NotificationPanelSteps {
  Notification = 'notification',
  Configuration = 'configuration',
}

export interface NotificationConfigurationItem {
  key: string;
  title: string;
  description?: string;
  active?: boolean;
  subItems?: NotificationConfigurationSubItems[];
  subActions?: NotificationConfigurationSubAction[];
}

export interface NotificationConfigurationSubItems {
  key: string;
  title: string;
  active: boolean;
  description?: string;
}
export interface NotificationConfigurationSubAction {
  key: string;
  title: string;
  active: boolean;
  description?: string;
}
