import styled, { css } from 'styled-components';
import { BrandColor, Color, FontFamily, FontSize } from '@atomic/obj.constants';

const cssInactiveDay = css`
  color: ${Color.Gray};
  background-color: ${Color.White};
`;

const cssActiveDay = css`
  &:hover {
    background-color: ${Color.Gray};
  }
`;

const cssToday = css`
  background: ${Color.White};
`;

const cssSelectedDay = css`
  color: ${Color.White};
  background-color: ${BrandColor.Blue};
`;

const cssWeekNames = css`
  cursor: default;
  border: none;
`;

const cssM2 = css`
  background-color: ${Color.Yellow};
  border-color: ${Color.Warning};
`;

const cssIsDifferentMonth = css`
  background-color: ${Color.GrayLight};
`;

export const WeekContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 1px;
`;

export const Day = styled.div`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${FontFamily.Primary};
  font-size: 14px;
  border: 1px solid ${Color.Gray};

  ${({ isToday }) => isToday && cssToday}
  ${({ isWeekNames }) => isWeekNames && cssWeekNames}
  ${({ isDiferentMonth }) => isDiferentMonth && cssIsDifferentMonth}
  ${({ isInactiveDay }) => (isInactiveDay ? cssInactiveDay : cssActiveDay)}
  ${({ isSelectedDay }) => isSelectedDay && cssSelectedDay}
  ${({ isM2, isInactiveDay }) => isM2 && !isInactiveDay && cssM2}
`;

export const CustomCalendarWrapper = styled.div`
  background-color: ${Color.White};
  width: 320px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.Gray};
  position: absolute;
  z-index: 5;
  top: ${({ openBottom }) => (openBottom ? '40px' : '-363px')};
  left: ${({ openBottom }) => (openBottom ? '-319px' : '-298px')};
`;

export const RulesMessage = styled.div`
  display: inline;
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-size: 14px;
  color: ${Color.GrayDark};
`;
