import styled from 'styled-components';
import { FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.type === 'primary' ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;
  height: ${props => (props.type === 'primary' ? Spacing.Large : Spacing.XLarge)};
  padding: 5px 8px 5px 8px;
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Small};
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.color};
  border-radius: 24px;
  width: fit-content;
  margin-right: ${Spacing.Small};
  margin-top: ${Spacing.Small};
`;
