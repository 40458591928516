import {
  RTC_PERMISSIONS,
  internalStatus,
  commonStatus,
  EPP_PERMISSIONS,
  PED_PERMISSIONS,
  GQ_PERMISSIONS,
  SELLER_PERMISSIONS,
} from '../../quote.contants';

export const makeOrderingByRole = (role: string) => {
  if (role === 'RTC') {
    return RTC_PERMISSIONS.map(
      item => internalStatus.find(i => i.key === item) || commonStatus.find(i => i.key === item),
    );
  } else if (role === 'EPP') {
    return EPP_PERMISSIONS.map(
      item => internalStatus.find(i => i.key === item) || commonStatus.find(i => i.key === item),
    );
  } else if (role === 'PED') {
    return PED_PERMISSIONS.map(
      item => internalStatus.find(i => i.key === item) || commonStatus.find(i => i.key === item),
    );
  } else if (role === 'GQ') {
    return GQ_PERMISSIONS.map(
      item => internalStatus.find(i => i.key === item) || commonStatus.find(i => i.key === item),
    );
  } else if (role === 'SELLER') {
    return SELLER_PERMISSIONS.map(
      item => internalStatus.find(i => i.key === item) || commonStatus.find(i => i.key === item),
    );
  } else {
    return internalStatus;
  }
};
