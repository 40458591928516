import * as React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Col, Row, VSeparator } from '@atomic/obj.grid';

export const OrderDocumentsCellShimmer: React.FC = () => {
  return (
    <Row>
      {[...Array(20)].map((_, index) => (
        <Col md={3} key={index}>
          <TextShimmerBoxStyled height='100px' width='180px' margin='0' />
          <VSeparator />
        </Col>
      ))}
    </Row>
  );
};
