import { LayoutTabs } from '@app/models/layout.model';
import { brDateFormatToDate } from '@app/utils/date-utils';

export const filteredDataSelector = (state, data) => {
  let filteredData = data || [];

  const { confirmedFilters } = state.advancedFilters[LayoutTabs.FinanceMI];

  if (confirmedFilters?.documentNumber?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.documentNumber.forEach(filterValue => {
        if (row.documentNumber.toString().includes(filterValue.trim())) {
          include = true;
        }
      });
      return include;
    });
  }

  if (confirmedFilters?.invoiceKey?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.invoiceKey.forEach(filterValue => {
        if (row.invoiceKey.toString().includes(filterValue.trim())) {
          include = true;
        }
      });

      return include;
    });
  }

  const { launchDate, experyDate } = confirmedFilters;

  if (launchDate?.startDate && launchDate?.endDate) {
    filteredData = filteredData.filter(row => {
      let include = false;

      const date = brDateFormatToDate(row.launchDate);

      if (date.getTime() >= launchDate.startDate && date <= launchDate.endDate) {
        include = true;
      }

      return include;
    });
  }

  if (experyDate?.startDate && experyDate?.endDate) {
    filteredData = filteredData.filter(row => {
      let include = false;

      const date = brDateFormatToDate(row.experyDate);

      if (date.getTime() >= experyDate.startDate && date <= experyDate.endDate) {
        include = true;
      }

      return include;
    });
  }

  return filteredData;
};
