export interface INewsTagStrings {
  text: string;
}

export interface INewsTagLanguages {
  pt: INewsTagStrings;
  es: INewsTagStrings;
  in: INewsTagStrings;
}

const pt: INewsTagStrings = {
  text: 'NOVO!',
};

const es: INewsTagStrings = {
  text: 'NUEVO!',
};

const en: INewsTagStrings = {
  text: 'NEW!',
};

export const Strings: INewsTagLanguages = {
  pt,
  es,
  in: en,
};
