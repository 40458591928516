import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../lack-of-charge-modal.constants';
import { ILackOfChargeModalState } from '../lack-of-charge-modal.interfaces';
import { initialState } from './lack-of-charge-modal.initial';
import { reducers } from './lack-of-charge-modal.reducers';
import { addThunkReducers } from './lack-of-charge.thunks';

export const lackOfChargeModalSlice = createSlice<ILackOfChargeModalState, SliceCaseReducers<ILackOfChargeModalState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const { setLackOfCharge, setHasLackOfCharge, setOpenLackOfChargeActionModal } = lackOfChargeModalSlice.actions;

export const lackOfChargeReducer = lackOfChargeModalSlice.reducer;
