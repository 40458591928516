import styled from 'styled-components';
import { TruckTrackingStepStatus } from '@app/models/truck-tracking.model';
import { Border, Color } from '@atomic/obj.constants';

interface TruckTrackingStepIconStyledProps {
  status: TruckTrackingStepStatus;
}

const StepIconColors = {
  finished: Color.Success,
  current: Color.Primary,
  pending: Color.Gray,
};

const ICON_DIAMETER = '20px';
const TRACKING_PLACEHOLDER_HEIGHT = '500px';

export const TruckTrackingStepIconWrapperStyled = styled.div`
  display: flex;
  padding-top: 3px;
  justify-content: center;
`;

export const TruckTrackingStepIconStyled = styled.div`
  border-radius: ${Border.RadiusLarge};
  height: ${ICON_DIAMETER};
  width: ${ICON_DIAMETER};
  background-color: ${(props: TruckTrackingStepIconStyledProps) => StepIconColors[props.status]};
`;

export const TrackingPlaceholderWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: ${TRACKING_PLACEHOLDER_HEIGHT};
  width: 100%;
  justify-content: center;
`;
