import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { ZeroStateTooltip } from './zero-state-tooltip.component';
import { IZeroStateStrings, Strings } from './zero-state.string';

export const ZeroState: React.FC = () => {
  const [visible, setVisible] = useState(true);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { openBPsModal } = useSelector((state: RootState) => state.navigation);
  const strings: IZeroStateStrings = Strings[userInfo?.language];

  const onClose = () => setVisible(false);

  useEffect(() => {
    if (openBPsModal) {
      setVisible(true);
    }
  }, [openBPsModal]);

  return (
    <FlexRow>
      {!openBPsModal && <ZeroStateTooltip visible={visible} onClick={onClose} onClose={onClose} />}
      <FlexColumn>
        <FlexRow hAlign='center' mt={Spacing.XXXLarge}>
          <Image.ZeroState />
        </FlexRow>
        <FlexColumn mt={Spacing.XLarge} hAlign='center' vAlign='center'>
          <Body>
            <b>{strings.zeroStateMsg1}</b>
          </Body>
          <FlexRow>
            <Body>
              <b>{strings.zeroStateMsg2} - </b>
            </Body>
            <Body ml={Spacing.XSmall}>{strings.consolidatedView}</Body>
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </FlexRow>
  );
};
