/* eslint-disable prettier/prettier */
import { LocationDescriptorObject, Path } from 'history';
import * as React from 'react';
import { Link } from '@app/core/route';
import { Body, ButtonText } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';

interface BreadcrumbDataProps {
  label: string;
  to?: Path | LocationDescriptorObject | string;
  onLinkClick?: () => void;
}
interface BreadcrumbProps {
  data: BreadcrumbDataProps[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = props => {
  const breadCrumbLength = props.data.length;

  return (
    <Hbox>
      {props.data.map((item, index) => (
        <React.Fragment key={item.label}>
          {index === breadCrumbLength - 1 ? (
            <Hbox.Item noGrow>
              <Body>{item.label}</Body>
            </Hbox.Item>
          ) : (
            <>
              <Hbox.Item noGrow>
                  {item.to ? (
                    <Link to={item.to} onClick={item.onLinkClick}>
                      <ButtonText>{item.label}</ButtonText>
                    </Link>
                  ) : (
                    <ButtonText onClick={item.onLinkClick}>{item.label}</ButtonText>
                  )}
              </Hbox.Item>
              <Hbox.Separator small />
              <Hbox.Item noGrow>
                <Body>/</Body>
              </Hbox.Item>
              <Hbox.Separator small />
            </>
          )}
        </React.Fragment>
      ))}
    </Hbox>
  );
};
