import * as React from 'react';
import { TagCheckboxField, TagCheckboxGroup } from '@atomic/atm.tag-checkbox';
import { Form } from '@atomic/obj.form';
import { Strings } from '../order-tabs/MI/tab-stock/order-tab-stock.string';

interface GridStockRowFilterProps {
  value: string;
  onTagChanged: (value: string) => void;
}

export enum StockStatusKey {
  Expired = 'Vencido',
  NotExpired = 'No prazo',
  CGData = '-',
}

const options = [
  {
    id: StockStatusKey.Expired,
    label: Strings.pt.status.label.expired,
    checked: true,
  },
  {
    id: StockStatusKey.NotExpired,
    label: Strings.pt.status.label.notExpired,
    checked: true,
  },
  {
    id: StockStatusKey.CGData,
    label: Strings.pt.status.label.cgData,
    checked: true,
  },
];

export const GridStockRowFilter: React.FC<GridStockRowFilterProps> = props => {
  return (
    <Form>
      <Form.Field
        name='stockRadio'
        value={props.value.split(',')}
        onValueChange={value => {
          if (value.join() !== props.value) {
            props.onTagChanged(value.join());
          }
        }}
      >
        <TagCheckboxGroup>
          {options.map(option => (
            <TagCheckboxField key={option.id} id={option.id} hide={option.id === StockStatusKey.CGData}>
              {option.label}
            </TagCheckboxField>
          ))}
        </TagCheckboxGroup>
      </Form.Field>
    </Form>
  );
};
