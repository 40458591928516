import React from 'react';
import { useDispatch } from 'react-redux';
import { Material } from '@app/models';
import { updateMaterial } from '@app/modules/order-input/store/order-input.store';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { OrderItemOffSet } from '../order-number-field/order-number-field.style';

interface IOrderItemProps {
  material: Material;
}

export const OrderItemField: React.FC<IOrderItemProps> = ({ material }) => {
  const dispatch = useDispatch();

  return (
    <FlexColumn>
      <FlexColumn pt={Spacing.Medium}>
        <input
          defaultValue={material.orderItem}
          onBlur={e => {
            const updatedMaterial = { ...material };
            updatedMaterial.orderItem = e.target.value;
            dispatch(updateMaterial(updatedMaterial));
          }}
        />
      </FlexColumn>
      <OrderItemOffSet />
    </FlexColumn>
  );
};
