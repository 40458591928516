import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IScheduleClient } from '@app/models/schedule.model';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { ScheduleBpsSelectionOptions } from './schedule-bps-selection-options';
import { SearchFieldWrapper } from './scheudle-bps-selections.style';

export const ScheduleBpSelection: React.FC = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [querySearch, setQuerySearch] = useState('');

  const { clientBpsSchedule } = useSelector((state: RootState) => state.schedule);

  const filteredCustomerSearch: IScheduleClient[] = clientBpsSchedule.filter(
    bp => bp.code.includes(querySearch) || bp.name.toUpperCase().includes(querySearch.toUpperCase()),
  );

  const handleValueChange = value => setQuerySearch(value);

  const handleSearch = () => setShowOptions(true);

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      handleSearch();
    }
  };

  const handleTyping = () => {
    if (querySearch.length > 2) {
      handleSearch();
    } else {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    handleTyping();
  }, [querySearch]);

  return (
    <SearchFieldWrapper>
      <Body mb={Spacing.XSmall}>{strings.labelSelectBps}</Body>
      <FlexRow>
        <TextField
          value={querySearch}
          onFocus={handleTyping}
          onClick={e => e.stopPropagation()}
          onKeyPress={handleKeyPress}
          onValueChange={handleValueChange}
          placeholder={strings.placeholderSelectBps}
          hasButton
        >
          <FlexRow mr={Spacing.Small} mb={Spacing.Small}>
            {showOptions ? (
              <FaIcon.ChevronUp onClick={() => setShowOptions(false)} clickable='true' color={Color.Primary} />
            ) : (
              <FaIcon.ChevronDown onClick={() => setShowOptions(true)} clickable='true' color={Color.Primary} />
            )}
          </FlexRow>
        </TextField>
      </FlexRow>
      {!!filteredCustomerSearch.length && showOptions && (
        <ScheduleBpsSelectionOptions filteredCustomerSearch={filteredCustomerSearch} setShowOptions={setShowOptions} />
      )}
    </SearchFieldWrapper>
  );
};
