import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Divisor } from '@atomic/atm.divisor';
import { FaIcon } from '@atomic/atm.fa-icon';
import { RadioField } from '@atomic/atm.radio';
import { TextField } from '@atomic/atm.text-field';
import { Body, ButtonText, H1, H3, H4 } from '@atomic/atm.typography';
import { Tab } from '@atomic/mol.tab/tab.component';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { Measure, jominyPoints, rangeLimits } from '../form.contants';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';
import { JominyContentWrapperStyled, JominyErrorStyled } from './modal-jominy.component.style';

export interface JominyData {
  MEDIDA?: string;
  PONTOS: {
    ponto: string;
    min: number;
    max: number;
    hardenabilityUnit?: string;
  }[];
}

export interface JominyContext {
  JOMINY: JominyData;
  hardenability_unit: string;
}

interface JominyRowProps {
  currentPoints: string[];
  data?: ElementProps[];
  index: number;
  point: string;
  tabIndex: number;
  units: string;
  handleRemove?: (value: number) => void;
}

const formatStringValue = (val: any, range: string) => {
  if (val) {
    if (range === 'max') {
      const newMask = val
        .toString()
        .replace(rangeLimits.maxString, '')
        .replace(',', '.');
      return newMask;
    } else {
      if (val === rangeLimits.minString) {
        const newMask = val
          .toString()
          .replace(rangeLimits.minString, '')
          .replace(',', '.');
        return newMask;
      }
      return val.replace(',', '.');
    }
  }
  return '';
};

const JominyRow: React.FC<JominyRowProps> = props => {
  const current = props?.data
    ? props.data.find((item: ElementProps) => parseFloat(props.point) === parseFloat(item.points))
    : null;
  return (
    <>
      <TR>
        <TD>
          {props.point ? (
            <>
              <Form.Field name={`JOMINY.PONTOS[${props.index}].ponto`} initialValue={props.point} />
              <H3>{props.point}</H3>
              <H4>{props.units}</H4>
            </>
          ) : (
            <Form.Field
              name={`JOMINY.PONTOS[${props.index}].ponto`}
              validators={[Validators.Required(), Validators.UniqueInArray(props.currentPoints)]}
              hideErrorCaption
            >
              <TextField placeholder='Pto.' type='text' noArrows={true} />
            </Form.Field>
          )}
        </TD>
        <TD>
          <Form.Field
            name={`JOMINY.PONTOS[${props.index}].min`}
            hideErrorCaption
            key={current?.value?.min}
            initialValue={formatStringValue(current?.value?.min, 'min')}
          >
            <TextField placeholder='Min.' type='text' noArrows={true} />
          </Form.Field>
        </TD>
        <TD> - </TD>
        <TD>
          <Form.Field
            name={`JOMINY.PONTOS[${props.index}].max`}
            key={current?.value?.max}
            hideErrorCaption
            initialValue={formatStringValue(current?.value?.max, 'max')}
          >
            <TextField placeholder='Máx.' type='text' />
          </Form.Field>
        </TD>

        <TD>
          <Button kind='link' onClick={() => props.handleRemove(props.index)}>
            <FaIcon.Trash />
          </Button>
        </TD>
      </TR>
      <VSeparator />
    </>
  );
};

interface ModalJominyProps {
  data?: ElementProps[];
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
}

interface ElementProps {
  hardenabilityUnit?: string;
  points: string;
  unit: string;
  value: {
    min: number;
    max: number;
    unit: string;
  };
}

export const ModalJominy: React.FC<ModalJominyProps> = props => {
  const { setModalToOpen } = React.useContext(FormContext);
  const [reset, setReset] = React.useState(0);
  const [opened, setOpened] = React.useState(props.open);
  const [measure, setMeasure] = React.useState(Measure.MM as string);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [currentPoints, setCurrentPoints] = React.useState(jominyPoints);
  const [errorData, setErrorData] = React.useState<boolean>(false);
  const contentRef = React.useRef(null);
  const units = [Measure.MM, Measure.POL];
  const hardenability_unit = ['HRC', 'HV'];
  const [hardenabilityUnit, setHardenabilityUnit] = React.useState(
    props?.data && props?.data[0]?.value?.unit ? props.data[0].value.unit : hardenability_unit[0],
  );

  // const verifyJominyPolUnit = value => {
  //   if (value % 1 === 0) {
  //     return `${value}/16`;
  //   } else {
  //     return value;
  //   }
  // };

  const orderingPoints = (values: string[], unit: string) => {
    let newArray = [];
    if (unit === 'pol') {
      const teste = values.map(val => val.split('/').shift()).sort((a, b) => +a - +b);
      teste.map((item, index) => (newArray[index] = `${item}/16`));
    } else {
      newArray = values;
    }
    return newArray;
  };

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].jominy;

  React.useEffect(() => {
    if (props?.data) {
      const incomingPoints = { ...jominyPoints };
      const unit = props.data[0].unit !== '' ? props.data[0].unit : 'mm';
      incomingPoints[unit] = [...new Set([...incomingPoints[unit], ...props.data.map(item => `${item.points}`)])].sort(
        (a, b) => +a - +b,
      );

      orderingPoints(incomingPoints[unit], unit);
      setCurrentPoints(incomingPoints);

      setMeasure(unit);
      if (props.data[0].unit === 'pol') {
        setTabIndex(1);
      } else {
        setTabIndex(0);
      }
    }
  }, [props?.data]);

  const handleSubmit = (data: FormData<JominyContext>) => {
    const haveData = data.data.JOMINY.PONTOS.filter(item => item.min || item.max).length;

    if (Object.keys(data.error).length > 0 || haveData === 0) {
      setErrorData(true);
      return;
    }

    const newValues = data.data.JOMINY.PONTOS.filter(item => !!item.min || !!item.max).map(item => {
      return {
        points: item.ponto,
        unit: measure,
        value: {
          min: item.min,
          max: item.max,
          unit: data.data.hardenability_unit,
        },
      };
    });

    props.submitModals('ADDITIONAL', 'HARDENABILITY', {
      value: newValues,
    });

    setModalToOpen(null);
    setOpened(false);
  };

  const handleReset = () => {
    setReset(reset + 1);
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  const handleTabChange = (index: number) => {
    const newMeasure = index === 0 ? Measure.MM : Measure.POL;
    handleReset();
    setTabIndex(index);
    setMeasure(newMeasure);
  };

  const handleNewPointClick = () => {
    const updatedPoints = { ...currentPoints };
    updatedPoints[measure] = [...updatedPoints[measure], ''];
    setCurrentPoints(updatedPoints);
    contentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleRemovePoint = index => {
    const updatedPoints = { ...currentPoints };
    const pointsLeft = updatedPoints[measure].filter((_, indexItem) => index !== indexItem);
    setCurrentPoints({ ...currentPoints, [measure]: pointsLeft });
  };

  const handleChangeUnit = value => {
    setHardenabilityUnit(value);
  };

  return (
    <Modal preventOverlayClick opened={opened} small onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <H1>{strings.title}</H1>
              <VSeparator />
            </Col>
            <Col xs={12}>
              <Tab initialIndex={tabIndex} onIndexChanged={handleTabChange}>
                <Tab.Item>{strings.milimeters}</Tab.Item>
                <Tab.Item>{strings.inches}</Tab.Item>
              </Tab>
              <Divisor />
              {errorData && (
                <JominyErrorStyled>
                  <Body color={Color.Alert}>{strings.errorData}</Body>
                </JominyErrorStyled>
              )}
            </Col>
          </Row>
          <JominyContentWrapperStyled>
            <Row mb>
              <Col xs={12}>
                <VSeparator />
                <Form.Field
                  name='hardenability_unit'
                  initialValue={hardenabilityUnit}
                  onValueChange={e => handleChangeUnit(e)}
                >
                  <Hbox>
                    <Hbox.Item noGrow>
                      <RadioField id={hardenability_unit[0]}>{hardenability_unit[0]}</RadioField>
                    </Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Item noGrow>
                      <RadioField id={hardenability_unit[1]}>{hardenability_unit[1]}</RadioField>
                    </Hbox.Item>
                  </Hbox>
                </Form.Field>
              </Col>
            </Row>
            <Row mt mb key={reset}>
              <Col xs={12}>
                <Table>
                  <TR>
                    <TD></TD>
                    <TD></TD>
                    <TD>
                      <Body>{hardenabilityUnit}</Body>
                    </TD>
                    <TD></TD>
                  </TR>
                  {currentPoints[measure].map((item: string, index: number) => (
                    <JominyRow
                      tabIndex={tabIndex}
                      currentPoints={currentPoints[measure]}
                      units={units[tabIndex]}
                      point={item}
                      key={index + reset}
                      index={index}
                      data={props?.data}
                      handleRemove={handleRemovePoint}
                    />
                  ))}
                </Table>
              </Col>
            </Row>
            <div ref={contentRef} />
          </JominyContentWrapperStyled>
          <Divisor />
          <VSeparator />
          <Hbox vAlign='center'>
            <Hbox.Item>
              <ButtonText onClick={() => handleNewPointClick()}>
                <FaIcon.Plus size='1x' /> {strings.addMeasure}
              </ButtonText>
            </Hbox.Item>
            <Hbox.Item noGrow>
              <Button kind='secondary' expanded onClick={handleReset}>
                {strings.reset}
              </Button>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item noGrow>
              <Button kind='primary' expanded type='submit'>
                {strings.save}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};
