import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { formatMaterialAmount, formatMaterialDescription } from '@app/modules/order-input/order-input.utils';
import { dateParser } from '@app/utils/date-parser';
import { Label } from '@atomic/atm.typography';
import { Table, TD, TH, THead, TR } from '@atomic/mol.table';
import { FlexColumn } from '@atomic/obj.box';
import { Color, FontSize, FontWeight } from '@atomic/obj.constants';
import { MultipleTableStyled } from './multiple-table.style';
import { MultipleField } from './partials/multiple-field/multiple-field';

const strings = OrderInputStrings.pt.resumeTable;

export const MultipleTable: React.FC = () => {
  const { selectedMaterials, searchBy } = useSelector((state: RootState) => state.orderInput);

  return (
    <FlexColumn mt>
      <MultipleTableStyled>
        <Table>
          <THead>
            <TR>
              <TH>{strings.orderNumber}</TH>
              <TH>{strings.orderItem}</TH>
              <TH>{strings.materialDescription}</TH>
              <TH>{strings.amount}</TH>
              <TH>{strings.multiple}</TH>
              <TH>{strings.unit}</TH>
              <TH>{strings.expectedDate}</TH>
            </TR>
          </THead>
          {selectedMaterials?.map((material: Material, index: number) => (
            <TR key={`${material.materialCode}_${index}`}>
              <TD>
                <Label weight={FontWeight.Normal} size={FontSize.Small}>
                  {material.orderNumber}
                </Label>
              </TD>
              <TD>
                <Label weight={FontWeight.Normal} size={FontSize.Small}>
                  {material.orderItem}
                </Label>
              </TD>
              <TD>
                <FlexColumn hAlign='center' fullWidth>
                  <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small}>
                    {formatMaterialDescription(material, searchBy)}
                  </Label>
                  <Label size={FontSize.Small} fullWidth color={Color.Black}>
                    {`${strings.codProdClientLabel} ${material.materialCode}`}
                  </Label>
                </FlexColumn>
              </TD>
              <TD>
                <Label weight={FontWeight.Normal} size={FontSize.Small}>
                  {formatMaterialAmount(material.amount)}
                </Label>
              </TD>
              <TD>
                <MultipleField material={material} index={index} />
              </TD>
              <TD>
                <Label weight={FontWeight.Normal} size={FontSize.Small}>
                  {material.unity}
                </Label>
              </TD>
              <TD>
                <Label weight={FontWeight.Normal} size={FontSize.Small}>
                  {dateParser({ unformattedDate: new Date(material.date).toString(), hideHours: true, utc: true })}
                </Label>
              </TD>
            </TR>
          ))}
        </Table>
      </MultipleTableStyled>
    </FlexColumn>
  );
};
