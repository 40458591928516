import { createAsyncThunk } from '@reduxjs/toolkit';
import { ScheduleDataSource } from '@app/data/http/schedule.datasource';
import { SLICE_NAME } from '../../schedule.constants';

export const addSelectedEmail = createAsyncThunk(`${SLICE_NAME}/addSelectedEmail`, async (email: string, thunkAPI) => {
  return await ScheduleDataSource.validateEmail(email)
    .then(() => email)
    .catch(err => {
      return thunkAPI.rejectWithValue({ status: err.response.status, email });
    });
});

export const addValidateEmailReducers = builder => {
  builder.addCase(addSelectedEmail.pending, state => {
    state.loadingValidateEmail = true;
  });
  builder.addCase(addSelectedEmail.fulfilled, (state, action) => {
    const payload: string = action.payload as string;
    state.loadingValidateEmail = false;
    state.selectedEmails.push({ email: payload, valid: true });
  });
  builder.addCase(addSelectedEmail.rejected, (state, action) => {
    const payload = action.payload as { status: number; email: string };
    state.loadingValidateEmail = false;
    if (payload.status === 404) {
      state.selectedEmails.push({ email: payload.email, valid: false });
    }
  });
};
