import * as React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';

export const ReceiverFormShimmer: React.FC = () => {
  return (
    <FlexRow mb mt pr>
      <FlexColumn mr>
        <TextShimmerBoxStyled />
        <VSeparator />
        <TextShimmerBoxStyled height='14px' width='50%' margin='0' />
        <VSeparator />
      </FlexColumn>
      <FlexColumn mr>
        <TextShimmerBoxStyled />
        <VSeparator />
        <TextShimmerBoxStyled height='14px' width='50%' margin='0' />
      </FlexColumn>
      <FlexColumn>
        <TextShimmerBoxStyled />
        <VSeparator />
        <TextShimmerBoxStyled height='14px' width='50%' margin='0' />
      </FlexColumn>
    </FlexRow>
  );
};
