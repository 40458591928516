import { Customer } from '@app/models';

export interface CustomerResponse extends Customer {
  customers: Customer[];
}

export const customerResponseToCustomerList = (response: CustomerResponse): Customer[] => {
  const customerList: Customer[] = response.customers.filter(item => Object.keys(item).length !== 0);

  return customerList;
};
