import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputLabel } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { QuoteStrings } from '../quote/quote.string';
import { useDownloadAsset } from '../quote/technical-analysis/hooks/useDownloadAsset';
import { ChatResultAttributeWrapper, ChatResultTable } from './chat-result.component.style';
import { ChatStrings } from './chat.string';

interface FileNormProps {
  data: any;
  disableAction?: boolean;
  delible?: boolean;
  onDelete?: () => void;
  modified?: string;
  disableButton?: boolean;
}

export const FileNorm: React.FC<FileNormProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const { mutate: handleDownload } = useDownloadAsset();

  const strings = ChatStrings[userInfo.language];
  return (
    <ChatResultAttributeWrapper modified={props.modified}>
      <ChatResultTable>
        <Hbox>
          <Hbox.Item vAlign='center'>
            <InputLabel>
              {strings.fileNorm.title}
              {props.modified !== '' &&
                (props.modified === 'GERDAU' ? (
                  <span title={QuoteStrings[userInfo.language].defaultMessageGerdau.tootipChatResult}></span>
                ) : (
                  <span>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}</span>
                ))}
            </InputLabel>
          </Hbox.Item>
        </Hbox>
        <Hbox>
          <Hbox.Item hAlign='flex-start'>
            <Button
              kind='link'
              onClick={() =>
                handleDownload({
                  filename: `${props.data.name}.pdf`,
                  url: props.data.url,
                  language: userInfo.language,
                  type: 'pdf',
                })
              }
              size='small'
            >
              <Hbox>
                <Hbox.Item noGrow vAlign='center'>
                  <FaIcon.PDF small />
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item>{props.data.name}</Hbox.Item>
              </Hbox>
            </Button>
          </Hbox.Item>
          {props.delible && (
            <>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <Button kind='link' onClick={props.onDelete} disabled={props.disableAction || props.disableButton}>
                  <FaIcon.Trash />
                </Button>
              </Hbox.Item>
            </>
          )}
        </Hbox>
      </ChatResultTable>
    </ChatResultAttributeWrapper>
  );
};
