import * as React from 'react';
import { Col as FlexCol, Grid as FlexGrid, Row as FlexRow } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export interface RowProps {
  mb?: boolean;
  mt?: boolean;
  pb?: boolean;
  pt?: boolean;
  noGutter?: boolean;
}

const RowStyled = styled(FlexRow)`
  margin-bottom: ${(props: RowProps) => (props.mb ? Spacing.Large : '0px')};
  margin-top: ${(props: RowProps) => (props.mt ? Spacing.Large : '0px')};
  padding-top: ${(props: RowProps) => (props.pt ? Spacing.Medium : '0px')};
  padding-bottom: ${(props: RowProps) => (props.pb ? Spacing.Medium : '0px')};

  ${(props: RowProps) => {
    if (props.noGutter) {
      return `overflow: hidden;
              margin-left: -${rowMargin}rem;
              margin-right: -${rowMargin}rem;`;
    } else {
      return '';
    }
  }}
`;

export const Grid = props => <FlexGrid {...props} />;
export const Col = props => <FlexCol {...props} />;
export const Row = props => <RowStyled {...props} />;

export const GridSettings = {
  flexboxgrid: {
    gridSize: 12,
    // Defaults
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    breakpoints: {
      xs: 0, // em
      sm: 32, // em
      md: 48, // em
      lg: 64, // em
    },
  },
};

export const Root = styled.div`
  background-color: ${Color.White};
  width: 100%;
  height: 100%;
`;

interface VSeparatorProps {
  small?: boolean;
  lineVisible?: boolean;
}
export const VSeparator = styled.div`
  border-top: ${(props: VSeparatorProps) => (props.lineVisible ? `3px solid ${Color.GrayLight}` : 'none')};
  padding-top: ${(props: VSeparatorProps) => (props.small ? Spacing.XSmall : Spacing.Medium)};
`;

export const rowMargin = GridSettings.flexboxgrid.outerMargin + GridSettings.flexboxgrid.gutterWidth / 2;
