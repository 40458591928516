import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';

const tagColors = {
  success: { selected: Color.Primary, nonSelected: Color.Secondary },
  alert: { selected: Color.Alert, nonSelected: Color.AlertLight },
  analysis: { selected: Color.Primary, nonSelected: Color.CallToAction },
  accepted: { selected: Color.SuccessMain, nonSelected: Color.Secondary },
  other: { selected: Color.White, nonSelected: Color.Primary },
};
interface TagWrapperStyledProps {
  ml?: boolean;
  hide?: boolean;
}

export const TagWrapperStyled = styled.div`
  display: ${(props: TagWrapperStyledProps) => (props.hide ? 'none' : 'inline-block')};
  margin-bottom: ${Spacing.XSmall};
  width: fit-content;

  & + & {
    margin-left: ${(props: TagWrapperStyledProps) => (props.ml ? Spacing.Large : Spacing.XSmall)};
  }
`;

export interface TagContentStyledProps {
  selected?: boolean;
  type?: keyof typeof tagColors;
}

export const TagContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.XSmall} ${Spacing.Medium};
  border-radius: ${Border.RadiusLarge};
  border: ${Border.Width} solid ${Color.Secondary};
  background-color: ${(props: TagContentStyledProps) =>
    props.selected ? tagColors[props.type].selected : tagColors[props.type].nonSelected};
  font-family: ${FontFamily.Primary};
  color: ${(props: TagContentStyledProps) => (props.selected ? Color.White : tagColors[props.type].selected)};
  font-size: ${FontSize.XSmall};
  cursor: pointer;
  white-space: nowrap;
`;

export const TagGroupStyled = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
`;
