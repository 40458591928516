import { ColDef } from 'ag-grid-community';
import { GKN_BPS } from '@app/constants';
import { LayoutTabs } from '@app/models/layout.model';
import { GridColumnDefinition } from '@app/modules/components';
import { CustomerSelection } from '@app/providers';
import { addColumn, cloneReadOnlyTabs, removeColumn } from '../../grid-layout.utils';

export const manageFieldsGKN = (
  tabs: GridColumnDefinition[] = [],
  clientBpsSelected: CustomerSelection[],
): GridColumnDefinition[] => {
  const configuredTabs = cloneReadOnlyTabs(tabs);

  const stockTabIndex = configuredTabs.findIndex((tab: GridColumnDefinition) => tab.name === LayoutTabs.StockMI);

  if (stockTabIndex !== -1) {
    const columnDef: ColDef[] = configuredTabs[stockTabIndex].cols;

    if (clientBpsSelected.some(bps => GKN_BPS.includes(bps.customerId))) {
      addFieldsGKN(columnDef);
    } else {
      configuredTabs[stockTabIndex].cols = removeFieldsGKN(columnDef);
    }
  }

  return configuredTabs;
};

export const removeFieldsGKN = (columnDef: ColDef[]): ColDef[] => {
  let columnDefAux = [];
  columnDefAux = removeColumn(columnDef, 'breakHeat');
  columnDefAux = removeColumn(columnDefAux, 'finish');
  columnDefAux = removeColumn(columnDefAux, 'lastHeat');
  columnDefAux = removeColumn(columnDefAux, 'hasQuantityLastHeat');
  columnDefAux = removeColumn(columnDefAux, 'qtyStockLastHeat');
  return columnDefAux;
};

export const addFieldsGKN = (columnDef: ColDef[]): void => {
  addColumn({
    columnDef,
    field: 'breakHeat',
    headerName: 'Quebra de corrida',
    cellRenderer: 'alertCellRenderer',
    width: 170,
    filter: false,
  });
  addColumn({
    columnDef,
    field: 'finish',
    headerName: 'Acabamento',
    cellRenderer: 'finishStatusCellRenderer',
    width: 120,
    filter: false,
  });
  addColumn({
    columnDef,
    field: 'lastHeat',
    headerName: 'Última corrida faturada do material',
    cellRenderer: null,
    width: 250,
    filter: 'agTextColumnFilter',
  });
  addColumn({
    columnDef,
    field: 'hasQuantityLastHeat',
    headerName: 'Última corrida tem produção/estoque?',
    cellRenderer: null,
    width: 290,
    filter: 'agTextColumnFilter',
  });
  addColumn({
    columnDef,
    field: 'qtyStockLastHeat',
    headerName: 'Qtd em estoque da última corrida',
    cellRenderer: null,
    width: 200,
    hide: true,
  });
};

export const removeFilterGKN = (
  configuredTabs: GridColumnDefinition[] = [],
  clientBpsSelected: CustomerSelection[] = [],
): GridColumnDefinition[] => {
  const fields = ['agreedQuantity', 'qtyCustomerRequiredTons'];

  const isGKN = clientBpsSelected.some(bps => GKN_BPS.includes(bps.customerId));
  const stockTabIndex = configuredTabs.findIndex((tab: GridColumnDefinition) => tab.name === LayoutTabs.StockMI);

  if (stockTabIndex !== -1) {
    const columnDef: ColDef[] = configuredTabs[stockTabIndex].cols;

    fields.forEach(field => {
      const index = columnDef.findIndex(column => column.field === field);

      if (index >= 0) {
        if (isGKN) {
          columnDef[index].filter = false;
        } else {
          columnDef[index].filter = 'agTextColumnFilter';
        }
      }
    });

    configuredTabs[stockTabIndex].cols = columnDef;
  }

  return configuredTabs;
};
