import styled, { keyframes } from 'styled-components';
import { TransitionDuration, ZIndex } from '@atomic/obj.constants/constants';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

interface FadeProps {
  show: boolean;
}

export const Fade = styled.div`
  border: 1px solid transparent;
  animation: ${(props: FadeProps) => (props.show ? fadeIn : fadeOut)} ${TransitionDuration} linear forwards;
  z-index: ${ZIndex.Fade};
`;
