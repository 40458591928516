import React from 'react';
import { useDispatch } from 'react-redux';
import { Material } from '@app/models';
import { updateMaterial } from '@app/modules/order-input/store/order-input.store';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FlexRow } from '@atomic/obj.box';

interface ISelectionFieldProps {
  material: Material;
  index: number;
}

export const SelectionField: React.FC<ISelectionFieldProps> = ({ material, index }) => {
  const dispatch = useDispatch();

  const handleSelect = () => {
    const updatedMaterial = { ...material };
    updatedMaterial.selected = !material.selected;
    dispatch(updateMaterial(updatedMaterial));
  };

  return (
    <FlexRow vAlign='center' hAlign='center'>
      <CheckboxField
        id={`chk_${material.materialCode}_${index}`}
        checked={material.selected}
        onValueChange={handleSelect}
      ></CheckboxField>
    </FlexRow>
  );
};
