import { ITruckTrackingState } from '../truck-tracking.interfaces';

export const initialState: ITruckTrackingState = {
  showTruckTracking: false,
  truckTrackingInfo: null,
  generalInformations: null,
  mapInformations: null,
  trackingUpdateInformations: null,
  map: null,
  loading: false,
  loadingMap: false,
  error: null,
};
