import React from 'react';
import { TableColumns, TableColumnType } from '@app/models/order-priorization.model';
import { H4 } from '@atomic/atm.typography';
import { Table, TD, TH, TR } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { orderPriorizationTableRowData } from '../../order-priorization.constants';
import { IOrderPriorizationTableData } from '../../order-priorization.interfaces';
import { IOrderPriorizationStrings, Strings } from '../../order-priorization.string';
import { OrderPriorizationTableCell } from './partials/order-priorization-table-cell.component';

interface OrderPriorizationTableComponentProps {
  tableRows: IOrderPriorizationTableData[];
}

export const OrderPriorizationCriticalTableComponent: React.FC<OrderPriorizationTableComponentProps> = ({
  tableRows,
}) => {
  const strings: IOrderPriorizationStrings = Strings.pt;

  return (
    <>
      <Table collapse>
        <TR bordered>
          {Object.keys(orderPriorizationTableRowData).map(column => {
            return (
              <TH key={column} color={Color.Secondary}>
                <H4>{strings.table.headerRow[column]}</H4>
              </TH>
            );
          })}
        </TR>
        {tableRows?.map((item: IOrderPriorizationTableData, index: number) => {
          return (
            <TR bordered key={index}>
              {Object.keys(orderPriorizationTableRowData).map(column => {
                return (
                  <TD key={column} textAlign='center'>
                    {
                      <OrderPriorizationTableCell
                        type={
                          column === TableColumns.messageClient
                            ? TableColumnType.StaticText
                            : orderPriorizationTableRowData[column].type
                        }
                        item={item}
                        column={column}
                        index={index}
                      />
                    }
                  </TD>
                );
              })}
            </TR>
          );
        })}
        <VSeparator />
      </Table>
    </>
  );
};
