import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { SchedulePhase } from '../../../schedule.constants';
import { strings } from '../../../schedule.strings';
import { scheduleByTabSelector } from '../../../store/schedule.selectores';
import { setEditMode, setSchedulePhase, setSelectedEmails } from '../../../store/schedule.store';

export const ReturnButton: React.FC = () => {
  const { loading, editMode } = useSelector((state: RootState) => state.schedule);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const schedules = useSelector(scheduleByTabSelector);

  const showAddButton = schedules.length < 5 && !editMode;
  const dispatch = useDispatch();

  if (showAddButton) {
    return (
      <Button
        kind='secondary'
        loading={loading}
        onClick={() => {
          dispatch(setEditMode(false));
          dispatch(setSelectedEmails([{ email: userInfo?.email, valid: true }]));
          dispatch(setSchedulePhase(SchedulePhase.Form));
        }}
      >
        {strings.makeAnotherSchedule}
      </Button>
    );
  }
  return null;
};
