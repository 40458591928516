import styled from 'styled-components';
import { Color, FieldHeight, Spacing } from '@atomic/obj.constants';

export const AdminNPSViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdminNPSViewWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px ${Spacing.XLarge};
`;

interface AdminNPSAddUserStyledProps {
  margingLeft: boolean;
}

export const AdminNPSActionButtonStyled = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: ${(props: AdminNPSAddUserStyledProps) => (props.margingLeft ? Spacing.Medium : '0px')};
`;

export const AdminNPSContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AdminNPSInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const AdminNPSDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Small} ${Spacing.Small};
  height: ${FieldHeight};
`;

export const AdminNPSPermissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Small} ${Spacing.Small};
`;

export const AdminNPSPermissionRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${Spacing.Small};
`;

export const PermissionTagContainer = styled.div`
  margin-right: ${Spacing.Small};
`;

export const AdminNPSBPsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Small} ${Spacing.Small};
`;

export const AdminNPSBPsRow = styled.div`
  display: flex;
  flex-direction: row;
  line-height: ${Spacing.XXLarge};
`;

export const BPNumberContainer = styled.div`
  margin-left: ${Spacing.Large};
`;

export const AdminNPSControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: flex-start;
  align-items: flex-end;
  padding-right: ${Spacing.Small};
  padding-top: ${Spacing.XLarge};
`;

export const ButtonContainer = styled.div`
  width: auto;
  margin-bottom: ${Spacing.Medium};
`;

export const AdminNPSContainerShimmer = styled.div`
  border: 1px solid ${Color.MediumGray};
  border-radius: 4px;
  margin: ${Spacing.XLarge};
  padding: 0px ${Spacing.Small} ${Spacing.Small} 0px;
`;

interface AdminNPSItemShimmerProps {
  lastItem: boolean;
}

export const AdminNPSItemShimmer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Small} 0px ${Spacing.Small} ${Spacing.Small};
  border-bottom: ${(props: AdminNPSItemShimmerProps) => (props.lastItem ? '' : `1px solid ${Color.MediumGray}`)};
`;

export const AdminNPSStatisticsContainerShimmer = styled.div`
  border: 1px solid ${Color.MediumGray};
  border-radius: 4px;
  margin: ${Spacing.XLarge};
  padding: 0px ${Spacing.Small} ${Spacing.Small} 0px;
`;

export const AdminNPSStatisticsContainer = styled.div`
  display: flex;
`;

export const AdminNPSRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
`;

export const NPSGroupOptionsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.Large};
  padding-left: ${Spacing.Large};
`;
