import { ColDef, GridReadyEvent } from 'ag-grid-community';
import * as React from 'react';
import { getStorage, StorageType } from '@app/core/storage/storage.provider';

export interface GridColumnDefinition {
  name: string;
  cols: ColDef[];
  doNotPersist?: boolean;
}

export interface ColumnDefHook {
  setGrid: (grid: GridReadyEvent) => void;
  setVisible: (field: string, visible: boolean) => void;
  columns: ColDef[];
}

export const useGridColumn = (colDef: GridColumnDefinition): ColumnDefHook => {
  const ls = getStorage(StorageType.Local);
  const gridEvent = React.useRef<GridReadyEvent>(null);
  const [columns, setColumns] = React.useState<ColDef[]>([]);

  const setColumnDef = cols => {
    setColumns(cols);
    !colDef.doNotPersist && ls.put(colDef.name, cols);
    gridEvent.current.api.setColumnDefs(cols);
  };

  const setGrid = (grid: GridReadyEvent) => {
    if (!grid || !colDef) {
      return;
    }
    gridEvent.current = grid;

    let lsData = ls.get(colDef.name);
    if (!lsData) {
      lsData = colDef.cols;
    }

    setColumnDef(lsData);
  };

  const setVisible = (field: string, visible: boolean) => {
    const updated = columns.map((column: ColDef) => {
      if (column.field === field) {
        column.hide = !visible;
      }
      return column;
    });
    setColumnDef(updated);
    gridEvent.current.columnApi.setColumnVisible(field, visible);
  };
  return { setGrid, setVisible, columns: colDef?.cols };
};
