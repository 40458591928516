import { SCHEDULE_TYPE, SchedulePhase, UserScheduleType, weekDays } from '../schedule.constants';
import { IScheduleState } from '../schedule.interfaces';

export const initialState: IScheduleState = {
  currentTab: UserScheduleType.ORDER_OPEN,
  schedulePhase: SchedulePhase.List,
  schedules: [],
  loading: false,
  scheduleId: null,
  scheduleName: '',
  showDaySelect: false,
  querySearch: '',
  clientBpsSchedule: [],
  layout: [],
  dragItems: [],
  layoutDefaultCart: [],
  layoutDefaultStock: [],
  scheduleType: SCHEDULE_TYPE.DAILY,
  time: '07:00',
  selectedDays: [],
  selectedDaysOfWeek: weekDays,
  selectedEmails: [],
  loadingValidateEmail: false,
  loadingSubmit: false,
  loadingLayout: false,
  loadingDelete: false,
  loadingUpdate: false,
  editMode: false,
};
