export const mapTechnicalAnalysisEmails = (emails: string[]): string[] => {
  return emails
    .filter(email => email.length > 0)
    .map(email => {
      return email.split(',').map(item => item.trim());
    })
    .flat();
};

export const mapSKU = (skus: string) => {
  let skusArray = [];
  if (skus) {
    skusArray = skus
      .split(',')
      .map(item =>
        item
          .trim()
          .split(';')
          .map(item2 => item2.trim()),
      )
      .flat();
  }

  return skusArray;
};
