import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { QuoteDataSource } from '@app/data/http';
import { GetAnalysisParams } from '@app/data/http/quote-params.dto';
import { FullAnalysisData } from '@app/models/quote.model';

export interface ContextTechnicalAnalysisValues {
  contextLoading: boolean;
  contextError: Error;
  getAnalysis: (params: GetAnalysisParams) => void;
}

export function useContextTechnicalAnalysis(
  setAnalysis: (analysis: FullAnalysisData) => void,
): ContextTechnicalAnalysisValues {
  const { performRequest: getAnalysis, loading: contextLoading, error: contextError } = useLazyRequest<
    GetAnalysisParams,
    FullAnalysisData
  >(QuoteDataSource.getAnalysis, setAnalysis);

  return {
    contextLoading,
    getAnalysis,
    contextError,
  };
}
