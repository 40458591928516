import { createGlobalStyle } from 'styled-components';
import { Color, FontFamily } from '@atomic/obj.constants';

export const GlobalStyled = createGlobalStyle`
  html {
    min-height: 100%;
  }
  * {
    box-sizing: border-box;
    }
  body {
    margin: 0;
    background-color: ${Color.GrayXLight};
  }
  a {
    font-family: ${FontFamily.Primary};
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0.05);
    color: ${Color.Primary};
  }

  #root {
    min-height: calc(100vh - 15px);
    display: flex;
    flex-direction: column;
  }
`;
