import React, { useEffect } from 'react';
import {
  RouteProps as ReactRouteProps,
  RouteComponentProps as ReactRouterComponentProps,
  __RouterContext as RouterContext,
} from 'react-router';
import { Route as ReactRoute } from 'react-router-dom';
import Container from 'typedi';
import { AppPath } from '@app/modules/app/route-constants';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Redirect } from './redirect.component';
import { Router } from './router';

export interface RouteComponentProps<P, C, S> extends ReactRouterComponentProps<P, C, S> {
  router: Router;
}

export type RouteGuard = () => boolean;

export interface RouteProps extends ReactRouteProps {
  authorized?: boolean;
  redirectPath?: string;
}

const validPaths = [
  AppPath.QUOTE.BASE,
  AppPath.COMPLAIN.BASE,
  AppPath.DASHBOARD,
  AppPath.ORDER_INPUT,
  AppPath.ORDERS,
  AppPath.FINANCE,
];

export const Route: React.FC<RouteProps> = props => {
  const WrappedComponent: any = props.component;
  const router: Router = Container.get(Router);
  const context = React.useContext(RouterContext);
  const { path, authorized, component, ...other } = props;

  const [, setPath] = useLocalStorageState('path', AppPath.ORDERS);

  useEffect(() => {
    if (validPaths.includes(path.toString())) {
      setPath(path.toString());
    }
  }, [path]);

  const handleRender = innerProps => {
    if (authorized) {
      return <WrappedComponent {...innerProps} />;
    }
    if (props.redirectPath) {
      return <Redirect to={props.redirectPath} />;
    }
    return null;
  };

  const computedPath = router.resolve(context.match.path, path as string);

  return <ReactRoute path={computedPath as string} render={handleRender} {...other} />;
};

Route.defaultProps = {
  authorized: true,
};
