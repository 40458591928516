import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { HelpCenterSectionKeys, HelpCenterSubsectionKeys } from '@app/models/help-center.model';
import { LayoutTabs } from '@app/models/layout.model';
import { GridColumnFilter, GridDownload } from '@app/modules/components';
import { GridButtonWithIcon } from '@app/modules/components/grid-button-with-icon.component';
import { MarketType } from '@app/providers';
import { setOpenScheduleModal } from '@app/providers/navigation/navigation.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { IOrderStrings } from '../../order.strings.interface';
import { OrdersStrings } from '../../orders.string';

interface OrderHeaderActionsParams {
  tabName: LayoutTabs;
  refresh: () => void;
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  filename: string;
  helpCenterSubsection?: HelpCenterSubsectionKeys;
  children?: JSX.Element;
  hasScheduleSend?: boolean;
}

export const OrderTabsActionsMI: React.FC<OrderHeaderActionsParams> = ({
  tabName,
  children,
  refresh,
  gridEvent,
  helpCenterSubsection,
  filename,
  hasScheduleSend,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { currentMarket } = useSelector((state: RootState) => state.customerPersist);
  const strings: IOrderStrings = OrdersStrings[userInfo?.language];
  const dispatch = useDispatch();
  let helpRedirect = null;

  if (helpCenterSubsection) {
    helpRedirect = { section: HelpCenterSectionKeys.Glossary, subsection: helpCenterSubsection };
  }

  return (
    <FlexRow pt>
      <FlexRow fullWidth>{children}</FlexRow>
      <FlexRow hAlign='flex-end' style={{ minWidth: '560px' }}>
        <FlexRow hAlign='flex-end'>
          <FlexColumn noGrow>
            <GridColumnFilter tabName={tabName} helpRedirect={helpRedirect} />
          </FlexColumn>
          <Hbox.Separator />
          <FlexColumn noGrow>
            <GridButtonWithIcon
              text={strings.common.refresh}
              icon={() => <Image.RefreshIcon />}
              kind='secondary'
              onClick={refresh}
            />
          </FlexColumn>
          <Hbox.Separator />

          <FlexColumn noGrow>
            <GridDownload gridEvent={gridEvent.current} filename={filename} tabName={tabName} />
          </FlexColumn>
          <Hbox.Separator />
          {hasScheduleSend && currentMarket === MarketType.Internal && (
            <FlexColumn noGrow>
              <GridButtonWithIcon
                text={strings.common.scheduleSend}
                icon={() => <FaIcon.Custom icon='paper-plane' />}
                kind='callToAction'
                onClick={() => dispatch(setOpenScheduleModal(true))}
              />
            </FlexColumn>
          )}
        </FlexRow>
      </FlexRow>
    </FlexRow>
  );
};
