import React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FormContext } from '../form.context';
import { FieldsData } from '../utils/mapPayloadAnalysisOld.dto';
import { IconStep, StepHeader } from './result/form-result.component.style';

export const StepsHeaderItem = props => {
  const { scrollTo, calcMandatory } = React.useContext(FormContext);

  const [mandatoryFieldsLeft, setMandatoryFieldsLeft] = React.useState(0);
  React.useEffect(() => {
    const time = setTimeout(() => {
      if (props?.step?.questions?.fields) {
        setMandatoryFieldsLeft(calcMandatory(Object.values(props.step.questions.fields)));
      }
    }, 500);
    return () => clearTimeout(time);
  }, [props?.step, calcMandatory]);

  const hasAttributeToShow = props.step?.questions?.fields
    ? Object.entries(props.step?.questions?.fields).filter((field: [string, FieldsData]) => field[1].display)
    : [];

  return props.step && JSON.stringify(props.step?.questions?.fields) !== '{}' && hasAttributeToShow.length > 0 ? (
    <StepHeader
      key={props.step.questions.key}
      percent={mandatoryFieldsLeft}
      onClick={() => scrollTo(props.step.questions.key, null, false)}
    >
      {props.step.questions.key !== 'ADDITIONAL' && (
        <IconStep>{mandatoryFieldsLeft === 100 ? <FaIcon.Check /> : <FaIcon.Pen />}</IconStep>
      )}

      <Body>
        {props.step.questions.label}{' '}
        {props.step.questions.key !== 'ADDITIONAL' &&
          `${calcMandatory(props.step.questions?.fields && Object.values(props.step.questions.fields))}%`}
      </Body>
    </StepHeader>
  ) : (
    <></>
  );
};
