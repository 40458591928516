import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  ConfigColumnsContext,
  ConfigColumnsContextState,
} from '@app/modules/order-input/components/config-columns-modal/config-columns-modal.context';
import { DeleteSpreadsheetConfirmModal } from '@app/modules/order-input/components/delete-spreadsheet-confirm-modal/delete-spreadsheet-confirm-modal';
import { setSpreadSheetFileName } from '@app/modules/order-input/store/order-input.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import { OrderInputUploadFileWrapper, SpreasheetFileWrapperStyled } from './loaded-file.style';

export const LoadedFile: React.FC = () => {
  const [openDeleteSpreadsheetModal, setOpenDeleteSpreadsheetModal] = useState(false);
  const { spreadSheetFileName } = useSelector((state: RootState) => state.orderInput);
  const { setSpreadSheetFile } = useContext<ConfigColumnsContextState>(ConfigColumnsContext);

  const dispatch = useDispatch();

  const handleDeleteSpreadsheetFile = () => {
    setSpreadSheetFile(null);
    dispatch(setSpreadSheetFileName(null));
    setOpenDeleteSpreadsheetModal(false);
  };

  return (
    <OrderInputUploadFileWrapper>
      <SpreasheetFileWrapperStyled>
        <Image.File />
        <Body>{spreadSheetFileName}</Body>
        <FaIcon.Trash
          color={Color.Primary}
          icon='trash'
          size='1x'
          clickable
          onClick={() => setOpenDeleteSpreadsheetModal(true)}
        />
      </SpreasheetFileWrapperStyled>
      <DeleteSpreadsheetConfirmModal
        opened={openDeleteSpreadsheetModal}
        onClose={() => {
          setOpenDeleteSpreadsheetModal(false);
        }}
        onConfirm={() => {
          handleDeleteSpreadsheetFile();
        }}
      />
    </OrderInputUploadFileWrapper>
  );
};
