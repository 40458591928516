import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import type { RootState } from '@app/core/redux/store';
import { ReceiverDataSource } from '@app/data/http/receiver.datasource';
import { Receiver } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID, SLICE_NAME } from '../../order-input.constants';
import { SelectOption } from '../../order-input.interfaces';

export const getReceiverThunk = createAsyncThunk(`${SLICE_NAME}/getReceivers`, async (_, thunkAPI) => {
  const state: RootState = thunkAPI.getState() as RootState;
  const preCustomerIds: string = customerIdsJoinedSelector(state);
  const customerIds = preCustomerIds.replace(MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID);
  return await ReceiverDataSource.getReceivers({ customerIds }).then(data => data).catch(err => {
    return thunkAPI.rejectWithValue({ status: err.response.status, message: err.response.message });
  });
});

export const addGetReceiversReducers = builder => {
  builder.addCase(getReceiverThunk.pending, state => {
    state.loadingReceivers = true;
  });
  builder.addCase(getReceiverThunk.fulfilled, (state, action) => {
    const payload: Receiver[] = action.payload as Receiver[];
    state.loadingReceivers = false;

    const mappedOptions: SelectOption[] = payload.map(item => ({
      value: item.receiverBpNumber,
      name: `${item.receiverBpNumber} - ${item.receiverName} - ${item.receiverCity}`,
      city: item.receiverCity,
    }));

    state.receiverOptions = mappedOptions;

  });
  builder.addCase(getReceiverThunk.rejected, (state) => {
    state.loadingReceivers = false;
  });
};
