import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { FormContext } from '../../form.context';
import { FormStrings } from '../../form.string';
import { FormResultEditOptions } from './form-result-edit-options';
import { FormResultAttributeWrapper, FormResultOptionsWrapper } from './form-result.component.style';

interface MicroinclusaoItems {
  element: string;
  value: {
    THIN: any;
    THICK: any;
  };
}
interface MicroinclusaoTableProps {
  attrKey: string;
  stepKey: string;
  attribute: {
    display: boolean;
    key: string;
    label?: string;
    mandatory?: boolean;
    permissions?: {
      visible: boolean;
      editable: boolean;
    };
    type: string;
    readBy: string;
    value: MicroinclusaoItems[];
  };
}

export const MicroinclusaoTable: React.FC<MicroinclusaoTableProps> = props => {
  const { focusAttribute } = React.useContext(FormContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].modalMicroinclusao;

  const convertDataToNumber = (val: string | number) => {
    if (val) {
      return val.toString().replace(',', '.');
    }
    return val;
  };

  return props.attribute?.permissions.visible ? (
    <FormResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.readBy === 'nlu'}
      onClick={() => focusAttribute(props.attrKey)}
    >
      <Hbox.Item>
        <FormResultOptionsWrapper>
          <InputLabel>
            {props.attribute?.label}{' '}
            {props.attribute?.readBy === 'nlu' ? (
              <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
            ) : (
              props.attribute?.readBy === 'GERDAU' && ' *'
            )}
          </InputLabel>
          <FormResultEditOptions
            stepKey={props.stepKey}
            editable={props.attribute.permissions.editable}
            attrKey={props.attrKey}
            type={props.attribute.type}
            attribute={props.attribute}
          />
        </FormResultOptionsWrapper>
      </Hbox.Item>

      <Table>
        <TR>
          <TD />
          <TD>
            <H4>{strings.thin}</H4>
          </TD>
          <TD>
            <H4>{strings.thick}</H4>
          </TD>
        </TR>
        {props.attribute.value.map(item => (
          <TR key={item.element}>
            <TD>
              <H4>{item.element}</H4>
            </TD>
            <TD>{convertDataToNumber(item.value.THIN)}</TD>
            <TD>{convertDataToNumber(item.value.THICK)}</TD>
          </TR>
        ))}
      </Table>
      <VSeparator />
    </FormResultAttributeWrapper>
  ) : null;
};
