import React, { useContext, useRef } from 'react';
import { PopoverContext, PopoverContextState } from '@app/modules/components/popover/popover-container';
import {
  OderStatusContainerWrapperStyledProps,
  OrderStatusBarStyledProps,
  OrderStatusDefaultWrappedStyledProps,
  OrderStatusWrappedStyledProps,
} from './order-status-renderer.component.style';

export const OrderStatusRenderer: React.FC<{ data: any; setValue: any }> = props => {
  const { openOrderStatusPopover, closePopover } = useContext<PopoverContextState>(PopoverContext);
  const ref = useRef(null);
  const { data } = props;

  const graphFields = ['qtyTotalBilled', 'qtyStock', 'qtyDelivery', 'qtyTotalProduction', 'qtyNotAllocated'];

  const getBarColumn = name => {
    switch (name) {
      case 'qtyTotalBilled':
        return '#47A947';
      case 'qtyStock':
        return '#FECB2F';
      case 'qtyDelivery':
        return '#3399CC';
      case 'qtyTotalProduction':
        return '#A480A3';
      case 'qtyNotAllocated':
        return '#BFC9D0';
      default:
        return '';
    }
  };

  const getBarData = () => {
    const graphRow = [];
    let total = 0;

    // eslint-disable-next-line guard-for-in
    for (const key in data) {
      if (graphFields.includes(key) && data[key] > 0) {
        const graphRowItem = {
          name: key,
          value: data[key],
          roundedValue: Math.round(data[key]),
          color: getBarColumn(key),
        };
        graphRow.push(graphRowItem);

        total += graphRowItem.roundedValue;
      }
    }

    return { rowGraph: graphRow, totalWidth: total };
  };

  const getProportionalWidth = (roundedValue, totalWidth) => {
    let proportionalWidth = 0;

    if (roundedValue < 1) {
      proportionalWidth = (0.5 * 100) / totalWidth;
    } else {
      proportionalWidth = (roundedValue * 100) / totalWidth;
    }

    return proportionalWidth;
  };

  const renderBarSection = () => {
    const barData = getBarData();
    const lastItem = barData.rowGraph[barData.rowGraph.length - 1];

    return barData.rowGraph.map((item, i) => {
      const rowId = `order-status-bar-${data.ov}-${i}`;
      const proportionalWidth = getProportionalWidth(item.roundedValue, barData.totalWidth);

      if (!graphFields.includes(item.name)) {
        return null;
      }

      if (barData.rowGraph.length > 1) {
        if (i === 0) {
          return (
            <OrderStatusBarStyledProps
              id={rowId}
              key={rowId}
              width={`${proportionalWidth}%`}
              background={item.color}
              firstBar={true}
            >
              <span>{item.roundedValue}t</span>
            </OrderStatusBarStyledProps>
          );
        } else if (item === lastItem) {
          return (
            <OrderStatusBarStyledProps
              id={rowId}
              key={rowId}
              width={`${proportionalWidth}%`}
              background={item.color}
              lastBar={true}
            >
              <span>{item.roundedValue}t</span>
            </OrderStatusBarStyledProps>
          );
        } else {
          return (
            <OrderStatusBarStyledProps id={rowId} key={rowId} width={`${proportionalWidth}%`} background={item.color}>
              <span>{item.roundedValue}t</span>
            </OrderStatusBarStyledProps>
          );
        }
      } else {
        return (
          <OrderStatusDefaultWrappedStyledProps id={rowId} key={rowId} background={item.color}>
            <span>{item.roundedValue}t</span>
          </OrderStatusDefaultWrappedStyledProps>
        );
      }
    });
  };

  return (
    <OderStatusContainerWrapperStyledProps ref={ref}>
      <OrderStatusWrappedStyledProps
        onMouseOver={() => openOrderStatusPopover(ref, data)}
        onMouseOut={() => closePopover()}
      >
        {data && renderBarSection()}
      </OrderStatusWrappedStyledProps>
    </OderStatusContainerWrapperStyledProps>
  );
};
