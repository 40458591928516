import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import Container from 'typedi';
import { LogAnalytics } from '@app/core/analytics';
import { GlobalStore } from '@app/core/global-store.service';
import store, { persistor } from '@app/core/redux/store';
import { isQuoteChatComplainCrmPage } from '@app/data/http/quote.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { UserAnalyticsType } from '@app/models/analytics.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { PopoverContainer } from '@app/modules/components/popover/popover-container';
import { conversationContextStore, useUserStore } from '@app/providers';
import { CSATContainer } from '@app/providers/csat-container';
import { CustomerContainer } from '@app/providers/customer-container';
import { DatadogContainer } from '@app/providers/datadog-container';
import { FlashMessageTargetName, useFlashStore } from '@app/providers/flash-message.store';
import { useNotificationStore } from '@app/providers/notification.store';
import { OnboardingContainer } from '@app/providers/onboarding-container';
import { Body } from '@atomic/atm.typography';
import { FlashMessage, FlashMessageWrapperStyled } from '@atomic/mol.flash-message';
import { GlobalStyled } from '@atomic/obj.globals';
import { AnnouncementModal } from '../components/announcement-modal/announcement-modal';
import { AnnouncementRegularModal } from '../components/announcement-regular-modal/announcement-regular-modal';
import { AuthorizationContainer } from '../components/authorization-container/authorization-container';
import { BrowserRecomendationModal } from '../components/browser-recomendation-modal/browser-recomendation-modal';
import { DropDownMenuContainer } from '../components/drop-down-menu/drop-down-menu-container';
import { ErrorHandlerModal } from '../components/error-handler-modal/error-handler-modal.component';
import { LackOfChargeConfigModal } from '../components/lack-of-charge-config-modal/lack-of-charge-config-modal';
import { MaintenanceModal } from '../components/maintenance-modal/maintenance-modal.component';
import { NewsModal } from '../components/news-modal/news-modal';
import { NpsModal } from '../components/nps-modal/nps-modal';
import { PreventiveStopModal } from '../components/preventive-stop-modal/preventive-stop-modal.component';
import { ScheduleModal } from '../components/schedule-modal/schedule-modal.container';
import { SchedulingCompleteConfigModal } from '../components/scheduling-complete-config-modal/scheduling-complete-config-modal';
import { ToastMessage } from '../components/toast/toast-message.component';
import { AdminHeader } from './admin-header/admin-header.component';
import { AppHeader } from './app-header/app-header.component';
import { SCROLL_TOP_DIFFERENCE } from './app.constants';
import { AppRoutesStyled } from './app.style';
import { AppPath } from './route-constants';
import { Routes } from './routes';

export const Content: React.FC = () => {
  const [hasScroll, setHasScroll] = useState(false);
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);
  const hasUserLoggedIn = !!authStorageService.getUser()?.uid;
  const isAdmin = useRouteMatch(AppPath.ADMIN.BASE);
  const isDashboard = window.location.href.includes(AppPath.DASHBOARD);

  useEffect(() => {
    if (hasUserLoggedIn) {
      const user = authStorageService.getUser();
      LogAnalytics.setUserId(user.uid);
      LogAnalytics.setUserProperties({
        kind: user.isEmployee ? UserAnalyticsType.Employee : UserAnalyticsType.Client,
        userId: user.uid,
      });
    }
  }, [hasUserLoggedIn]);

  window.addEventListener('wheel', findScrollDirectionOtherBrowsers);

  function findScrollDirectionOtherBrowsers() {
    const hasScrolled = document.documentElement.scrollTop > SCROLL_TOP_DIFFERENCE;
    setHasScroll(hasScrolled);
  }

  const isShortHeader = (hasScroll && !isDashboard) || isQuoteChatComplainCrmPage();
  const windowWitdh = document.body.clientWidth;

  return (
    <Provider store={store}>
      <GlobalStore stores={[useUserStore, useFlashStore, useNotificationStore, conversationContextStore]}>
        <ThemeProvider theme={{}}>
          <React.Fragment>
            <GlobalStyled />
            {!hasUserLoggedIn && <BrowserRecomendationModal />}
            <PersistGate persistor={persistor}>
              <AuthorizationContainer hasUserLoggedIn={hasUserLoggedIn}>
                <DatadogContainer hasUserLoggedIn={hasUserLoggedIn}>
                  <CustomerContainer hasUserLoggedIn={hasUserLoggedIn}>
                    <OnboardingContainer hasUserLoggedIn={hasUserLoggedIn}>
                      <CSATContainer>
                        <>
                          {hasUserLoggedIn &&
                            (isAdmin ? <AdminHeader /> : <AppHeader isAdmin shortHeader={isShortHeader} />)}
                          <DropDownMenuContainer>
                            <PopoverContainer>
                              <AppRoutesStyled
                                isAdmin={isAdmin}
                                hasUserLoggedIn={hasUserLoggedIn}
                                shortHeader={isShortHeader}
                                windowWitdh={windowWitdh}
                              >
                                <FlashWrapper />
                                <ToastMessage />
                                <Routes />
                                {hasUserLoggedIn && (
                                  <>
                                    <NewsModal />
                                    <ScheduleModal />
                                    <NpsModal />
                                    <AnnouncementModal />
                                    <AnnouncementRegularModal />
                                    <ErrorHandlerModal />
                                    <LackOfChargeConfigModal />
                                    <SchedulingCompleteConfigModal />
                                    <PreventiveStopModal />
                                  </>
                                )}
                                <MaintenanceModal />
                              </AppRoutesStyled>
                            </PopoverContainer>
                          </DropDownMenuContainer>
                        </>
                      </CSATContainer>
                    </OnboardingContainer>
                  </CustomerContainer>
                </DatadogContainer>
              </AuthorizationContainer>
            </PersistGate>
          </React.Fragment>
        </ThemeProvider>
      </GlobalStore>
    </Provider>
  );
};

export const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Content />
      <ReactQueryDevtools initialIsOpen={true} panelPosition='left' />
    </QueryClientProvider>
  );
};

const FlashWrapper: React.FunctionComponent<any> = _props => {
  const [, hide, data] = useFlashMessage(FlashMessageTargetName.APP);

  return !data.hidden && data.content ? (
    <FlashMessageWrapperStyled type={data.type} alignRight={data.alignRight} small={data.small}>
      <FlashMessage type={data.type} onClose={hide} dismissible>
        <Body>{data.content}</Body>
      </FlashMessage>
    </FlashMessageWrapperStyled>
  ) : null;
};
