import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { feedbackAnalyticsToCSATModule } from '@app/modules/admin-settings/admin-settings.modules';
import { postAnswerCsatUser } from '@app/providers/csat/csat-post.thunk';
import { showFeedbackSelector } from '@app/providers/csat/csat.selectors';
import { setAnswered } from '@app/providers/csat/csat.store';
import { FormData } from '@atomic/obj.form';
import { FeedbackBox } from './feedback-variants/feedback-box.component';
import { FeedbackModal } from './feedback-variants/feedback-modal.component';
import { answers } from './feedback-variants/partials/feedback-answers';
import { FeedbackAnalytics } from './feedback.analytics';
import { FeedbackContainerType, FeedbackPhase, FeedbackData, FeedbackType } from './feedback.interfaces';

interface FeedbackProps {
  opened: boolean;
  setOpened: (value: boolean) => void;
  context: FeedbackAnalytics;
  type: FeedbackType;
  message?: string;
  onFinish?: () => void;
  analyticsType: string;
  analysisId?: number;
  containerType?: FeedbackContainerType;
}

export const Feedback: React.FC<FeedbackProps> = ({
  opened,
  setOpened,
  context,
  type,
  message,
  onFinish,
  analyticsType,
  analysisId,
  containerType,
}) => {
  const [comment, setComment] = useState('');
  const [selected, setSelected] = useState<number>(6);
  const [feedbackPhase, setFeedbackPhase] = useState(FeedbackPhase.Initial);
  const dispatch = useDispatch<AppThunkDispatch>();
  const contextFunctionality = feedbackAnalyticsToCSATModule[context];
  const showFeedback = useSelector((state: RootState) =>
    showFeedbackSelector(state, contextFunctionality, feedbackPhase, context, opened),
  );

  const handleSuccess = () => {
    LogAnalytics.logEvent(analyticsType, {
      value: selected,
      message: comment,
      context: context,
      analysisId: analysisId,
    });
    setFeedbackPhase(FeedbackPhase.Success);
    setTimeout(() => {
      setOpened(false);
      setFeedbackPhase(FeedbackPhase.Initial);
    }, 3000);
  };

  const handleSubmit = (formData: FormData<FeedbackData>) => {
    if (!(Object.keys(formData.error).length > 0)) {
      dispatch(postAnswerCsatUser({ functionality: contextFunctionality, handleSuccess }));
    }
  };

  const onClose = () => {
    dispatch(setAnswered(contextFunctionality));
    setFeedbackPhase(FeedbackPhase.Initial);
    setOpened(false);
    if (onFinish) {
      onFinish();
    }
  };

  useEffect(() => {
    if (selected <= 5) {
      setFeedbackPhase(FeedbackPhase.Comment);
    }
  }, [selected]);

  const FeedbackComponent = containerType === FeedbackContainerType.Box ? FeedbackBox : FeedbackModal;

  if (showFeedback) {
    return (
      <FeedbackComponent
        context={context}
        type={type}
        message={message}
        onClose={onClose}
        feedbackPhase={feedbackPhase}
        selected={selected}
        setSelected={setSelected}
        answers={answers}
        handleSubmit={handleSubmit}
        comment={comment}
        setComment={setComment}
      />
    );
  }

  return null;
};
