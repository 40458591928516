import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { setOpenBPsModal } from '@app/providers/navigation/navigation.store';
import { CustomerSearchModalClientComponent } from './customer-search-modal-client.component';

export const CustomerSearchModalClient: React.FC = () => {
  const { openNewsModal, openBPsModal, openWarningModal } = useSelector((state: RootState) => state.navigation);
  const { clientBpsSearch } = useSelector((state: RootState) => state.customerSearch);
  const dispatch = useDispatch();

  const [textSearch, setTextSearch] = useState('');

  useEffect(() => {
    if (clientBpsSearch.length) {
      const someBpSelect = clientBpsSearch.find(bp => bp.selected);
      if (!someBpSelect && !openNewsModal && !openWarningModal) {
        dispatch(setOpenBPsModal(true));
      }
    }
  }, [clientBpsSearch, openNewsModal, openWarningModal]);

  const onClose = () => dispatch(setOpenBPsModal(false));

  return (
    <CustomerSearchModalClientComponent
      textSearch={textSearch}
      setTextSearch={setTextSearch}
      opened={openBPsModal}
      onClose={onClose}
    />
  );
};
