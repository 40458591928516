import { performRequest } from '@app/modules/order/order-tabs/MI/tab-open/store/order-tab-open.thunks';
import { setShowConfirmModal } from '../order-priorization.store';
import { getCriticalItemRules } from './get-critical-item-rule.thunk';
import { getCriticalItems } from './get-critical-item.thunk';
import { IPostCriticalItemsParams, postCriticalItems } from './post-critical-items.thunk';

export const postCriticalItemAndUpdateOrderTabOpen = (params: IPostCriticalItemsParams) => async (dispatch, _) => {
  await dispatch(postCriticalItems(params));
  await dispatch(getCriticalItemRules());
  await dispatch(getCriticalItems());
  await dispatch(performRequest());
  await dispatch(setShowConfirmModal(false));

  return;
};
