import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { hasSelectedMaterialsSelector } from '@app/modules/order-input/store/order-input.selectors';
import {
  setDeleteMaterialModalOpened,
  setMaterialTemplate,
  setUpdateMaterialModalOpened,
} from '@app/modules/order-input/store/order-input.store';
import { Button } from '@atomic/atm.button';
import { AmountTableControlsStyled } from '../amount-table.style';

const strings = OrderInputStrings.pt.dateAndAmount;

export const AmountTableControls: React.FC = () => {
  const hasSelectedMaterials = useSelector(hasSelectedMaterialsSelector);
  const dispatch = useDispatch();

  const onClickEdit = () => {
    dispatch(setMaterialTemplate({ amount: '', unity: '', date: null, messages: [] }));
    dispatch(setUpdateMaterialModalOpened(true));
  };

  const onClickDelete = () => {
    dispatch(setDeleteMaterialModalOpened(true));
  };

  return (
    <AmountTableControlsStyled show={hasSelectedMaterials}>
      <Button onClick={onClickEdit}>{strings.editButton}</Button>
      <Button kind='secondary' onClick={onClickDelete}>
        {strings.deleteButton}
      </Button>
    </AmountTableControlsStyled>
  );
};
