import styled, { css } from 'styled-components';
import { Color, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';

const hasErrorCss = css`
  border: ${Spacing.XXSmall} solid ${Color.MediumAlert};
`;

const hasWarningCss = css`
  border: ${Spacing.XXSmall} solid ${Color.Warning};
  background-color: ${Color.Yellow};
`;

export const DateFieldWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const DateSelectedWrapper = styled.div`
  height: 40px;
  width: 120px;
  border: 2px solid ${Color.GrayLight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: ${Spacing.Small};
  cursor: pointer;
  background-color: ${Color.GrayXLight};

  border-radius: ${Spacing.XSmall};
  ${({ hasError }) => hasError && hasErrorCss};
  ${({ hasWarning }) => hasWarning && hasWarningCss};
`;

export const DateSelected = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0;
  height: 25px;
  font-family: ${FontFamily.Primary};
  color: ${Color.GrayDark};
  font-size: ${FontSize.Small};
`;
