import { InStockOrderItem } from '@app/models';
import { brDateFormatToDate } from '@app/utils/date-utils';
import { tabName } from '../order-tab-stock.constants';

export const filteredDataSelector = (state, data) => {
  let filteredData = data;

  const { confirmedFilters } = state.advancedFilters[tabName];

  const { dateSolClient, dateConfirmed } = confirmedFilters;

  if (dateSolClient?.startDate && dateSolClient?.endDate) {
    filteredData = filteredData.filter((row: InStockOrderItem) => {
      let include = false;

      const date = brDateFormatToDate(row.customerRequiredDate);

      if (date.getTime() >= dateSolClient.startDate && date <= dateSolClient.endDate) {
        include = true;
      }

      return include;
    });
  }

  if (dateConfirmed?.startDate && dateConfirmed?.endDate) {
    filteredData = filteredData.filter((row: InStockOrderItem) => {
      let include = false;

      const date = brDateFormatToDate(row.customerAgreedDate);

      if (date.getTime() >= dateConfirmed.startDate && date <= dateConfirmed.endDate) {
        include = true;
      }

      return include;
    });
  }

  if (confirmedFilters?.ov?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.ov.forEach(filterValue => {
        if (row.salesDocument.toString().includes(filterValue.trim())) {
          include = true;
        }
      });
      return include;
    });
  }
  if (confirmedFilters?.material?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.material.forEach(filterValue => {
        if (row.material.toString().includes(filterValue.trim())) {
          include = true;
        }
      });

      return include;
    });
  }
  if (confirmedFilters?.descMaterial?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.descMaterial.forEach(filterValue => {
        if (
          row.materialName
            .toString()
            .toUpperCase()
            .includes(filterValue.trim().toUpperCase())
        ) {
          include = true;
        }
      });

      return include;
    });
  }

  if (confirmedFilters?.codMaterialClient?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.codMaterialClient.forEach(filterValue => {
        if (
          row.customerMaterialCode
            .toString()
            .toUpperCase()
            .includes(filterValue.trim().toUpperCase())
        ) {
          include = true;
        }
      });

      return include;
    });
  }

  if (confirmedFilters?.plantName?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.plantName.forEach(filterValue => {
        if (
          row.plantName
            .toString()
            .toUpperCase()
            .includes(filterValue.trim().toUpperCase())
        ) {
          include = true;
        }
      });

      return include;
    });
  }

  return filteredData;
};
