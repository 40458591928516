import * as React from 'react';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { PlaceholderStyled, PlaceholderWrapperStyled } from './placeholder.style';

interface NoDataPlaceholderProps {
  message: string;
  type?: 'default' | 'bell';
}

export const NoDataPlaceholder: React.FC<NoDataPlaceholderProps> = props => {
  const placeholderType = {
    default: <Image.NoDataPlaceholder />,
    bell: <Image.NoNotificationPlaceholder />,
  };

  return (
    <PlaceholderWrapperStyled>
      <PlaceholderStyled>
        <Hbox>
          <Hbox.Item hAlign='center'>{placeholderType[props.type]}</Hbox.Item>
        </Hbox>
        <Hbox>
          <Hbox.Item hAlign='center'>
            <H2>{props.message}</H2>
          </Hbox.Item>
        </Hbox>
      </PlaceholderStyled>
    </PlaceholderWrapperStyled>
  );
};

NoDataPlaceholder.defaultProps = {
  type: 'default',
};
