export interface ICustomerSearchModalFooterClientStrings {
  totalText: string;
  cancelButton: string;
  confirmButton: string;
}

interface ICustomerSearchModalFooterClientLanguages {
  pt: ICustomerSearchModalFooterClientStrings;
  es: ICustomerSearchModalFooterClientStrings;
  in: ICustomerSearchModalFooterClientStrings;
}

const pt: ICustomerSearchModalFooterClientStrings = {
  totalText: 'filiais selecionadas',
  cancelButton: 'Cancelar',
  confirmButton: 'Ver filiais selecionadas',
};

const es: ICustomerSearchModalFooterClientStrings = {
  totalText: 'filiales seleccionadas',
  cancelButton: 'Cancelar',
  confirmButton: 'Ver filiales seleccionadas',
};

const en: ICustomerSearchModalFooterClientStrings = {
  totalText: 'Selected business partners',
  cancelButton: 'Cancel',
  confirmButton: 'View selected business partner',
};

export const Strings: ICustomerSearchModalFooterClientLanguages = {
  pt,
  es,
  in: en,
};
