import styled from 'styled-components';
import { FilterBadgesWrapper } from '@app/modules/components/customer-search/customer-search-admin/customer-search-modal-admin/customer-search-modal-header-admin/partials/filter-badges/filter-badges.style';
import { Color, Spacing } from '@atomic/obj.constants';

export const EmailFieldWrapper = styled.div`
  width: 100%;
  input {
    height: 44px;
    width: 100%;
  }
`;

export const BadgesEmailsSelectWrapper = styled(FilterBadgesWrapper)`
  margin-top: ${Spacing.Small};
  margin-bottom: 0;
  height: 50px;
  overflow-y: auto;
  border: 2px solid ${Color.GrayLight};
`;
