import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { extraReducersConfig, reducers } from '../../../utils';
import { SLICE_NAME } from '../order-tab-open.constants';
import { initialState } from './order-tab-open.initial';
import { IOrderTabOpenState } from './order-tab-open.interface';
import { performRequest } from './order-tab-open.thunks';

export const order = createSlice<IOrderTabOpenState, SliceCaseReducers<IOrderTabOpenState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: extraReducersConfig(performRequest),
});

export const {
  setData,
  setFilter,
  setFilteredRows,
  setSelectedRows,
  setShowActionBar,
  setCriticalFilter,
  setLackOfChargeFilter,
} = order.actions;

export const orderTabOpenReducer = order.reducer;
