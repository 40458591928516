export interface IComponentsStrings {
  gridColumnFilter: string;
  help: string;
  helpLink: string;
  actions: (actionNumber: number) => string;
  save: string;
  cancel: string;
}

export interface IComponentsLanguages {
  pt: IComponentsStrings;
  es: IComponentsStrings;
  in: IComponentsStrings;
}

const pt: IComponentsStrings = {
  gridColumnFilter: 'Colunas',
  help: 'Alguma dúvida sobre os campos?',
  helpLink: 'Veja mais informações',
  actions: (actionNumber: number) => `${actionNumber} pedido(s) selecionado(s)`,
  save: 'Salvar',
  cancel: 'Cancelar',
};

const es: IComponentsStrings = {
  gridColumnFilter: 'Columnas',
  help: '¿Alguna pregunta sobre los campos?',
  helpLink: 'Ver más información',
  actions: (actionNumber: number) => `${actionNumber} pedido(s) seleccionado(s)`,
  save: 'Guardar',
  cancel: 'Cancelar',
};

const en: IComponentsStrings = {
  gridColumnFilter: 'Columns',
  help: 'Need any help with the meaning of each field?',
  helpLink: 'See more information',
  actions: (actionNumber: number) => `${actionNumber} selected order(s)`,
  save: 'Save',
  cancel: 'Cancel',
};

export const Strings = {
  pt,
  es,
  in: en,
};
