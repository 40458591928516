import { createAsyncThunk } from '@reduxjs/toolkit';
import { NPSDataSource } from '@app/data/http/nps.datasource';
import { GetNPSUsersParams } from '@app/data/http/nps.params.dto';
import { IAdminNPSUserList } from '@app/models/admin.model';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { AdminStrings } from '../../admin.string';

export const getNPSUserGroup = createAsyncThunk(
  `${SLICE_NAME}/getNPSUserGroup`,
  async (params: GetNPSUsersParams, thunkAPI) => {
    const strings = AdminStrings.pt.nps;

    return await NPSDataSource.getGroup(params)
      .then(data => data)
      .catch(err => {
        handleUnauthorized(err);
        thunkAPI.dispatch(setToast({ show: true, text: strings.npsUserListError, variant: ToastVariant.DANGER }));
      });
  },
);

export const addGetNPSUserGroupReducers = builder => {
  builder.addCase(getNPSUserGroup.pending, state => {
    state.loadingNPSUsers = true;
  });
  builder.addCase(getNPSUserGroup.fulfilled, (state, action) => {
    const payload: IAdminNPSUserList = action.payload as IAdminNPSUserList;
    state.loadingNPSUsers = false;
    state.npsUserSearch.filteredUsers = payload?.users;
    state.npsUserSearch.pagination = payload?.pagination;
  });
  builder.addCase(getNPSUserGroup.rejected, state => {
    state.loadingNPSUsers = false;
  });
};
