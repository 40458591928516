import * as React from 'react';
import { Image } from '@atomic/atm.image';
import { ButtonText } from '@atomic/atm.typography';
import { AuthLanguageStyled } from '../components/auth-box.style';

export interface AuthLanguagesProps {
  setLanguage: (language: string) => void;
}

export const AuthLanguages: React.FC<AuthLanguagesProps> = props => {
  return (
    <AuthLanguageStyled>
      <ButtonText onClick={() => props.setLanguage('pt')}>
        <Image.FlagPT />
      </ButtonText>
      <ButtonText onClick={() => props.setLanguage('in')}>
        {' '}
        <Image.FlagIN />
      </ButtonText>
      <ButtonText onClick={() => props.setLanguage('es')}>
        {' '}
        <Image.FlagES />
      </ButtonText>
    </AuthLanguageStyled>
  );
};
