import ColorFunc from 'color';
import styled from 'styled-components';
import { Breakpoint, Color, FontWeight } from '@atomic/obj.constants';

export const JominyContentWrapperStyled = styled.div`
  max-height: 430px;
  overflow-y: scroll;
  @media all and (max-height: ${Breakpoint.md}em) {
    height: auto;
    max-height: 280px;
  }
  & > div {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const JominyErrorStyled = styled.div`
  padding-top: 15px;
`;

export const LinkText = styled.a`
  color: ${Color.Primary};
  font-weight: ${FontWeight.Medium};
  cursor: pointer;
  width: fit-content;
  :hover {
    color: ${ColorFunc(Color.Accessory)
      .darken(0.2)
      .hsl()
      .string()};
  }
`;
