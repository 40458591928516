import * as React from 'react';
import { UserData } from '@app/models/quote.model';
import { TextField } from '@atomic/atm.text-field';
import { Form } from '@atomic/obj.form';
import { TechnicalAnalysisStatusUserList } from '../technical-analysis/status/technical-analysis-user-list.component';

interface Options {
  key: string;
  label: string;
}

interface QuoteAdvancedFilterSelectProps {
  options: Options[];
  name: string;
  label: string;
  initialValue?: UserData;
  onValueChange: (value: any) => void;
}

export const QuoteAdvancedFilterSelect: React.FC<QuoteAdvancedFilterSelectProps> = props => {
  const [showList, setShowList] = React.useState(false);
  const [fieldValue, setFieldValue] = React.useState(
    props?.initialValue ? `${props.initialValue.firstName} ${props.initialValue.lastName}` : '',
  );
  const [element, setElement] = React.useState(null);

  const handleValueChange = (value: string) => {
    setFieldValue(value);
  };

  const handleSelectUser = (value: string) => {
    const selectedOption = props.options.find(item => item.label === value);

    props.onValueChange(selectedOption);
    setElement(null);
    setFieldValue('');
    element.blur();
  };

  const findUser = value => {
    if (value) {
      return value.toLowerCase().includes(fieldValue.toLowerCase());
    }
  };

  const filterList = (list: any) => {
    let formattedLists = [];
    formattedLists = list.map(item => item.label);

    return formattedLists.filter(findUser);
  };

  const handleFocus = elementFocus => {
    setElement(elementFocus);
    setShowList(true);
  };

  const handleBlur = () => {
    element.blur();
    setElement(null);
    setFieldValue('');
    setShowList(false);
  };

  return (
    <>
      <Form.Field label={props.label} name={props.name} value={fieldValue} onValueChange={handleValueChange}>
        <TextField
          data-testid={props.name}
          hasButton
          onBlur={() => handleBlur()}
          onFocus={e => handleFocus(e.target)}
        />
      </Form.Field>
      <TechnicalAnalysisStatusUserList
        show={showList}
        users={filterList(props.options)}
        onClose={() => setShowList(false)}
        onSelect={handleSelectUser}
        loading={false}
      />
    </>
  );
};
