export interface ISurrogateStrings {
  title: string;
  descriptionUp: string;
  descriptionBottom: string;
  input: {
    label: string;
    description: string;
    error: string;
  };
  periodDescription: string;
  from: string;
  to: string;
  option: string;
  addButton: string;
  button: string;
  deleteButton: string;
  success: string;
  selectedEmailsLabel: string;
  modal: {
    message: string;
    removeBtn: string;
    cancelBtn: string;
  };
  infoModal: {
    title: string;
    expiresMessage: any;
    indeterminateTimeMessage: string;
    message: string;
    confirm: string;
  };
}

interface ISurrogateLanguages {
  pt: ISurrogateStrings;
  es: ISurrogateStrings;
  in: ISurrogateStrings;
}

const pt: ISurrogateStrings = {
  title: 'Configurar email adicional',
  descriptionUp:
    'Indique um email adicional para receber em cópia as mensagens e atualizações da plataforma Gerdau Mais.',
  descriptionBottom:
    'Utilize essa função em casos de ausência do usuário primário ou para envios de email em cópia recorrente:',
  input: {
    label: 'Usuário adicional',
    description: 'O usuário adicional deverá ter funções similares do usuário primário.',
    error: 'Esse email não é válido como usuário adicional. Adicione um usuário que tenha o mesmo perfil que o seu.',
  },
  periodDescription: 'Indique o período em que gostaria de permitir a adição:',
  from: 'De:',
  to: 'Até:',
  option: 'Enviar por tempo indeterminado.',
  addButton: 'Adicionar',
  button: 'Salvar informações',
  deleteButton: 'Remover',
  success: 'Suas informações à respeito da substituição de usuário foram salvas!',
  selectedEmailsLabel: 'Emails selecionados:',
  modal: {
    message: 'Deseja remover configuração de email adicional?',
    removeBtn: 'Remover',
    cancelBtn: 'Cancelar',
  },
  infoModal: {
    title: 'Aviso',
    expiresMessage: (expiresFrom: string, expiresTo: string) => `Entre os dias ${expiresFrom} e ${expiresTo},`,
    indeterminateTimeMessage: 'Por tempo indeterminado, ',
    message: 'o(s) e-mail(s) abaixo também receberão as solicitações oriundas do portal.',
    confirm: 'Ok, entendido',
  },
};

export const Strings: ISurrogateLanguages = {
  pt,
  es: pt,
  in: pt,
};
