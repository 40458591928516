import { Accordion } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AdminStrings } from '@app/modules/admin/admin.string';
import { setBPActiveId } from '@app/modules/admin/store/admin.store';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { AdminBPListContent } from './admin-bp-list-content.component';
import { AdminBPListHeader } from './admin-bp-list-header.component';
import { AdminBPListShimmer } from './admin-bp-list.shimmer';

export const AdminBPList: React.FC = () => {
  const { bpSearch, loadingBPs } = useSelector((state: RootState) => state.admin);
  const strings = AdminStrings.pt.common;
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleBPsList = () => {
    const userList = [];

    bpSearch.filteredBPs.map((filteredBP, i) => {
      const headerData = <AdminBPListHeader bp={filteredBP} />;
      const contentData = <AdminBPListContent bp={filteredBP} />;

      userList.push({ id: i, title: headerData, content: contentData });
    });

    return userList;
  };

  const handleExpandedAccordion = (id: number | string) => {
    if (bpSearch?.activeId !== id) {
      dispatch(setBPActiveId(id));
    } else {
      dispatch(setBPActiveId(''));
    }
  };

  return (
    <LoadingState loading={loadingBPs} data={bpSearch.filteredBPs?.length > 0} error={!bpSearch}>
      <LoadingState.Shimmer>
        <AdminBPListShimmer />
      </LoadingState.Shimmer>

      <LoadingState.NoData>
        <FlexRow hAlign='center' mt={Spacing.XLarge} mb={Spacing.XLarge}>
          <Body size='16px' bold={600}>
            {strings.noResultsFound}
          </Body>
        </FlexRow>
      </LoadingState.NoData>
      <FlexColumn vAlign='center' mt={Spacing.XLarge} mb={Spacing.Large} ml={Spacing.XLarge} mr={Spacing.XLarge}>
        <Accordion
          data={handleBPsList()}
          activeId={bpSearch?.activeId}
          onExpandedAccordion={id => handleExpandedAccordion(id)}
        />
      </FlexColumn>
    </LoadingState>
  );
};
