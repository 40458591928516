import * as React from 'react';
import { QuoteDataSource } from '@app/data/http';
import { PostNluParams } from '@app/data/http/quote-params.dto';

export const useUploadProjectQuery = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState<any>(null);

  const fetch = async (params: PostNluParams) => {
    setLoading(true);
    try {
      const res = await QuoteDataSource.uploadProject(params);
      setData(res);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };
  return { fetch, loading, error, data };
};
