export interface CustomCalendarStrings {
  rulesMessagePart1: string;
  rulesMessagePart2: string;
  rulesMessagePart3: string;
  rulesMessagePart4: string;
  rulesMessagePart5: string;
}

interface CustomCalendarLangages {
  pt: CustomCalendarStrings;
  es: CustomCalendarStrings;
  in: CustomCalendarStrings;
}

const pt: CustomCalendarStrings = {
  rulesMessagePart1: 'A escolha das datas em amarelo',
  rulesMessagePart2: 'fará com que o pedido entre em',
  rulesMessagePart3: 'análise de inclusão pois não está de',
  rulesMessagePart4: 'acordo com o',
  rulesMessagePart5: ' calendário M+2 ',
};

export const Strings: CustomCalendarLangages = {
  pt,
  es: pt,
  in: pt,
};
