import { SLICE_NAME } from '../order-priorization.constants';
import { IOrderPriorizationState } from '../order-priorization.interfaces';

export const RESET_ORDER_PRIORIZATION_ACTION_TYPE = `${SLICE_NAME}/reset`;
export const RESET_ORDER_PRIORIZATION_ACTION = { type: RESET_ORDER_PRIORIZATION_ACTION_TYPE };

export const reducers = {
  reset: (state: IOrderPriorizationState) => {
    state.rows = [];
    state.showOrderPriorization = false;
    state.showConfirmBtn = true;
    state.showConfirmModal = false;
    state.showRemoveItemModal = false;
    state.itemToRemove = null;
    state.loading = false;
    state.criticalItemInfoLoading = false;
    state.showSuccessPage = false;
    state.alreadyOpenedModalMessage = false;
  },
  setRows: (state: IOrderPriorizationState, { payload }) => {
    state.rows = payload;
  },
  setShowOrderPriorization: (state: IOrderPriorizationState, { payload }) => {
    state.showOrderPriorization = payload;
  },
  setShowConfirmModal: (state: IOrderPriorizationState, { payload }) => {
    state.showConfirmModal = payload;
  },
  setShowRemoveItemModal: (state: IOrderPriorizationState, { payload }) => {
    state.showRemoveItemModal = payload;
  },
  setItemToRemove: (state: IOrderPriorizationState, { payload }) => {
    state.itemToRemove = payload;
  },
  setShowSuccessPage: (state: IOrderPriorizationState, { payload }) => {
    state.showSuccessPage = payload;
  },
  setLoading: (state: IOrderPriorizationState, { payload }) => {
    state.loading = payload;
  },
  setAlreadyOpenedModalMessage: (state: IOrderPriorizationState, { payload }) => {
    state.alreadyOpenedModalMessage = payload;
  },
};
