import React from 'react';
import { useSelector } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { RootState } from '@app/core/redux/store';
import { UserStore, useUserStore } from '@app/providers';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { NpsPhase } from '../../nps-modal.constants';
import { InpsModalStrings, Strings } from '../../nps.string';
import { NpsModalBodyWrapper } from './nps-modal-body.style';
import { NpsModalGrade } from './nps-modal-grade/nps-modal-grade';
import { NpsModalMessage } from './nps-modal-message/nps-modal-message';
import { NpsModalSucess } from './nps-modal-success/nps-modal-success';

const npsPhaseComponent = {
  [NpsPhase.Grade]: <NpsModalGrade />,
  [NpsPhase.Message]: <NpsModalMessage />,
  [NpsPhase.Success]: <NpsModalSucess />,
};
export const NpsModalBody: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const strings: InpsModalStrings = Strings[user?.language];
  const { phase } = useSelector((state: RootState) => state.nps);

  const { forecastMessageSimpleMessage } = strings;

  return (
    <NpsModalBodyWrapper>
      {npsPhaseComponent[phase]}
      {phase !== NpsPhase.Success && (
        <FlexRow mt>
          <Body mr={Spacing.XSmall}>{`${forecastMessageSimpleMessage}`}</Body>
        </FlexRow>
      )}
    </NpsModalBodyWrapper>
  );
};
