import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';

export const ShippingRequestSuccessMsg = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${Color.SuccessLight};
  border-radius: 4px;
  height: 100px;
  position: absolute;
  right: ${Spacing.Medium};
  bottom: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: ${FontSize.Medium};
  color: ${Color.SuccessMain};
  padding: ${Spacing.Large};
  margin-right: ${Spacing.Medium};
`;

export const ShippingSuccessIcon = styled.div`
  margin-right: 16px;
`;

export const ShippingRequestFooterButtonWrapperStyled = styled.div`
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 1%;
`;
