import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TagCheckboxField, TagCheckboxGroup } from '@atomic/atm.tag-checkbox';
import { Form } from '@atomic/obj.form';
import { Strings } from '../order-tabs/MI/tab-open/order-tab-open.string';

interface GridOpenRowFilterProps {
  value: string;
  onTagChanged: (value: string) => void;
}

export enum OpenMIStatusKey {
  Implanted = 'Implantado',
  Confirmed = 'Confirmado',
  OnSchedule = 'Em aprazamento',
}

export const GridOpenRowFilter: React.FC<GridOpenRowFilterProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const options = [
    {
      id: OpenMIStatusKey.Implanted,
      label: Strings[userInfo?.language].status.implanted,
      checked: true,
    },
    {
      id: OpenMIStatusKey.OnSchedule,
      label: Strings[userInfo?.language].status.schedule,
      checked: true,
    },
    {
      id: OpenMIStatusKey.Confirmed,
      label: Strings[userInfo?.language].status.confirmed,
      checked: true,
    },
  ];

  return (
    <Form>
      <Form.Field
        name='openMIRadio'
        value={props.value.split(',')}
        onValueChange={value => {
          if (value.join() !== props.value) {
            props.onTagChanged(value.join());
          }
        }}
      >
        <TagCheckboxGroup>
          {options.map(option => (
            <TagCheckboxField key={option.id} id={option.id}>
              {option.label}
            </TagCheckboxField>
          ))}
        </TagCheckboxGroup>
      </Form.Field>
    </Form>
  );
};
