import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { SelectOption } from '@app/modules/order-input/order-input.interfaces';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { RadioField } from '@atomic/atm.radio';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { FormField } from '@atomic/obj.form';
import { ShippingRequestModalBodyWrapper } from './shipping-request-receiver-modal-body.style';

interface IShippingRequestReceiverOptionsParams {
  receiverOptions: SelectOption[];
  selectedReceiver: string;
  setselectedReceiver: (value: string) => void;
}

export const ShippingRequestReceiverOptions: React.FC<IShippingRequestReceiverOptionsParams> = ({
  receiverOptions,
  selectedReceiver,
  setselectedReceiver,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IShippingRequestStrings = Strings[userInfo.language];

  return (
    <ShippingRequestModalBodyWrapper>
      <FlexRow pb>
        <Body color={Color.Black}>{strings.receiverModal.description}</Body>
      </FlexRow>
      <FormField name='selected-receiver' value={selectedReceiver}>
        <FlexColumn mb>
          {receiverOptions.map(option => (
            <RadioField onClick={() => setselectedReceiver(option.value)} key={option.value} id={option.value}>
              {option.name}
            </RadioField>
          ))}
        </FlexColumn>
      </FormField>
    </ShippingRequestModalBodyWrapper>
  );
};
