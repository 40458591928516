import { LayoutTabs } from '@app/models/layout.model';
import { INPUT_FILTERS_INITIAL_VALUE, RANGE_FILTERS_INITIAL_VALUE } from '../sidebar-filters.constants';
import { IAdvancedFiltersState, IFinanceFiltersState, IOrderTabFiltersState } from '../sidebar-filters.interfaces';

export const OrderTabFiltersInitialState: IOrderTabFiltersState = {
  filters: {
    ov: INPUT_FILTERS_INITIAL_VALUE,
    material: INPUT_FILTERS_INITIAL_VALUE,
    descMaterial: INPUT_FILTERS_INITIAL_VALUE,
    codMaterialClient: INPUT_FILTERS_INITIAL_VALUE,
    dateSolClient: { startDate: null, endDate: null },
    dateConfirmed: { startDate: null, endDate: null },
    plantName: [],
    diameter: RANGE_FILTERS_INITIAL_VALUE,
    thickness: RANGE_FILTERS_INITIAL_VALUE,
    width: RANGE_FILTERS_INITIAL_VALUE,
    qtdConf: RANGE_FILTERS_INITIAL_VALUE,
    qtyStock: RANGE_FILTERS_INITIAL_VALUE,
    qtyTotalProduction: RANGE_FILTERS_INITIAL_VALUE,
    qtyDelivery: RANGE_FILTERS_INITIAL_VALUE,
    qtyTotalBilled: RANGE_FILTERS_INITIAL_VALUE,
    qtyBillingOpen: RANGE_FILTERS_INITIAL_VALUE,
    qtdSolClient: RANGE_FILTERS_INITIAL_VALUE,
  },
  confirmedFilters: {
    ov: [],
    material: [],
    descMaterial: [],
    codMaterialClient: [],
    dateSolClient: { startDate: null, endDate: null },
    dateConfirmed: { startDate: null, endDate: null },
    plantName: [],
    diameter: RANGE_FILTERS_INITIAL_VALUE,
    thickness: RANGE_FILTERS_INITIAL_VALUE,
    width: RANGE_FILTERS_INITIAL_VALUE,
    qtdConf: RANGE_FILTERS_INITIAL_VALUE,
    qtyStock: RANGE_FILTERS_INITIAL_VALUE,
    qtyTotalProduction: RANGE_FILTERS_INITIAL_VALUE,
    qtyDelivery: RANGE_FILTERS_INITIAL_VALUE,
    qtyTotalBilled: RANGE_FILTERS_INITIAL_VALUE,
    qtyBillingOpen: RANGE_FILTERS_INITIAL_VALUE,
    qtdSolClient: RANGE_FILTERS_INITIAL_VALUE,
  },
};

export const FinanceFilterInitialState: IFinanceFiltersState = {
  filters: {
    documentNumber: INPUT_FILTERS_INITIAL_VALUE,
    invoiceKey: INPUT_FILTERS_INITIAL_VALUE,
    launchDate: { startDate: null, endDate: null },
    experyDate: { startDate: null, endDate: null },
    rangeDaysOverdue: [],
    documentType: [],
  },
  confirmedFilters: {
    documentNumber: [],
    invoiceKey: [],
    launchDate: { startDate: null, endDate: null },
    experyDate: { startDate: null, endDate: null },
    rangeDaysOverdue: [],
    documentType: [],
  },
};

export const initialState: IAdvancedFiltersState = {
  [LayoutTabs.OpenMI]: OrderTabFiltersInitialState,
  [LayoutTabs.StockMI]: OrderTabFiltersInitialState,
  [LayoutTabs.BilledMI]: OrderTabFiltersInitialState,
  [LayoutTabs.FinanceMI]: FinanceFilterInitialState,
};
