export const arraysAreEquals = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

export const addOrRemoveItem = (value: string, property: string, currArray: any[], item: any) => {
  const newArray = [];
  const hasItem = currArray.some(e => e[property] === value);
  const currentItemIndex = currArray.findIndex(c => c[property] === value);

  for (let i = 0; i < currArray.length; i++) {
    const element = currArray[i];

    if (i !== currentItemIndex) {
      newArray.push(element);
    }
  }

  if (!hasItem) {
    newArray.push(item);
  }

  return newArray;
};

export const removeItems = (values: string[], property: string, currArray: any[]) => {
  const newArray = [];

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < currArray.length; i++) {
    const element = currArray[i];
    const hasValue = values.some(e => e === element[property]);

    if (!hasValue) {
      newArray.push(element);
    }
  }

  return newArray;
};

export const addLeftCharacters = (text: string, charMax: number) => {
  if (!text) {
    return Array.from({ length: charMax }, () => '0').join('');
  }

  const reversedSplittedText = text.split('').reverse();
  const formattedText: string[] = [];
  const lastTextIndex = text.length - 1;

  for (let index = 0; index < charMax; index++) {
    if (index > lastTextIndex) {
      formattedText.push('0');
    } else {
      formattedText.push(reversedSplittedText[index]);
    }
  }

  return formattedText.reverse().join('');
};

export const removeLeftCharacters = (text: string, charMax: number) => {
  if (!text) {
    return text;
  }

  const reversedSplittedText = text.split('').reverse();
  const formattedText: string[] = [];

  for (let index = 0; index < charMax; index++) {
    formattedText.push(reversedSplittedText[index]);
  }

  return formattedText.reverse().join('');
};

export const updateArray = (value: any, property: string, currArray: any[], item: any) => {
  let newArray = [];
  const hasItem = currArray.some(e => e[property] === value);
  const currentItemIndex = currArray.findIndex(c => c[property] === value);

  if (hasItem) {
    for (let i = 0; i < currArray.length; i++) {
      const element = currArray[i];

      if (i !== currentItemIndex) {
        newArray.push(element);
      } else {
        newArray.push(item);
      }
    }
  } else {
    newArray = currArray;
    newArray.push(item);
  }

  return newArray;
};
