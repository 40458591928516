import React from 'react';
import { IOrderPriorizationTableData } from '@app/modules/order-priorization/order-priorization.interfaces';
import { dateParserPTBR } from '@app/utils/date-parser';
import { Body } from '@atomic/atm.typography';

interface OrderPriorizationTableStaticDataProps {
  item: IOrderPriorizationTableData;
  column: string;
}

export const OrderPriorizationTableStaticDate: React.FC<OrderPriorizationTableStaticDataProps> = ({ item, column }) => {
  if (item[column]) {
    return <Body centralized>{dateParserPTBR({ unformattedDate: item[column], hideHours: true })}</Body>;
  }

  return null;
};
