import { format, subDays } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { Button } from '@atomic/atm.button';
import { Cell } from '@atomic/atm.cell';
import { DatePicker } from '@atomic/atm.date-picker';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { DateFilterStyled } from './date-filter.style';

enum FilterDates {
  LastMonth = 30,
  LastTwoMonths = 60,
  LastQuarter = 90,
  Initial = 1,
  All = -1,
  Custom = 0,
}

export interface DateFilterProps {
  onDateChange: (dateFilter: number | string, startDate?: Date, endDate?: Date) => void;
  statusDateFilter?: boolean;
  crmFilter?: boolean;
}

export const DateFilter: React.FC<DateFilterProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const menuRef = React.useRef(null);
  const strings = QuoteStrings[userInfo.language].dateFilter;

  const filters = props.crmFilter
    ? [
        { key: 'Initial', value: strings.initial },
        { key: 'All', value: strings.All },
        { key: 'Custom', value: strings.Custom },
      ]
    : [
        { key: 'LastMonth', value: strings.LastMonth },
        { key: 'LastTwoMonths', value: strings.LastTwoMonths },
        { key: 'LastQuarter', value: strings.LastQuarter },
        { key: 'All', value: strings.All },
        { key: 'Custom', value: strings.Custom },
      ];

  const [show, setShow] = React.useState(false);
  const [isCustom, setIsCustom] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState(filters[0]);
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = React.useState(new Date());

  const disable = props.statusDateFilter;

  const handleFilterSelect = (item: { key: string; value: string }) => {
    if (FilterDates[item.key]) {
      setSelectedFilter(item);
      setShow(!show);
      props.onDateChange(FilterDates[item.key]);
    } else {
      setIsCustom(true);
    }
  };

  const handleCustomFilterSelect = () => {
    setSelectedFilter({
      key: 'Custom',
      value: `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`,
    });
    setShow(!show);
    props.onDateChange(0, startDate, endDate);
  };

  const handleMouseDown = (event: MouseEvent) => {
    if (show && menuRef && !menuRef.current.contains(event.target)) {
      setShow(!show);
    }
  };
  useEventListener('mousedown', handleMouseDown);

  return (
    <div ref={menuRef}>
      <Button kind='neutral' onClick={() => setShow(!show)} disabled={disable}>
        <Hbox>
          <Hbox.Item>{selectedFilter.value}</Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item noGrow vAlign='center'>
            <FaIcon.ChevronDown size='1x' />
          </Hbox.Item>
        </Hbox>
      </Button>
      <DateFilterStyled show={show && !isCustom}>
        {filters.map(item => (
          <Cell key={item.key} hover onClick={() => handleFilterSelect(item)}>
            <Body>{item.value}</Body>
          </Cell>
        ))}
      </DateFilterStyled>
      <DateFilterStyled show={show && isCustom}>
        <Grid fluid>
          <VSeparator />
          <H3>{strings.custom}</H3>
          <VSeparator small />

          <Row>
            <Col xs={6}>
              <Body>{strings.from}</Body>
              <DatePicker startDate={startDate} onValueChange={setStartDate} />
            </Col>
            <Col xs={6}>
              <Body>{strings.to}</Body>
              <DatePicker startDate={endDate} onValueChange={setEndDate} />
            </Col>
          </Row>
          <VSeparator />

          <Row>
            <Col xs={6}>
              <Button expanded kind='secondary' onClick={() => setIsCustom(false)}>
                {strings.back}
              </Button>
            </Col>
            <Col xs={6}>
              <Button expanded kind='primary' onClick={handleCustomFilterSelect}>
                {strings.apply}
              </Button>
            </Col>
          </Row>
          <VSeparator />
        </Grid>
      </DateFilterStyled>
    </div>
  );
};
