import * as React from 'react';
import { useStore } from '@app/core/global-store.service';
import { EventTypes } from '@app/models/analytics.model';
import { ErrorPlaceholder } from '@app/modules/components/error.placeholder';
import { NoDataPlaceholder } from '@app/modules/components/no-data.placeholder';
import { NotificationStore, useNotificationStore } from '@app/providers/notification.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { ButtonText, H1, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Feedback, FeedbackAnalytics, FeedbackContainerType, FeedbackType } from '@atomic/obj.feedback';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { NotificationCell } from './notification-cell.component';
import { NotificationListWrapper } from './notification-center.style';
import { NotificationListShimmer } from './notification-list.component.shimmer';
import { NotificationStepButton } from './notification-step-button.component';
import { Strings, INotificationCenterStrings } from './notification.string';

export interface NotificationProps {
  onCloseClick: () => void;
  onConfigurationClick: () => void;
  onRefetch: () => void;
  onClearClick: (ids: string[]) => void;
  loading: boolean;
  error: boolean;
}

const strings: INotificationCenterStrings = Strings.pt;

export const Notification: React.FC<NotificationProps> = props => {
  const { notification } = useStore<NotificationStore>(useNotificationStore);
  const [showCleanAllConfirmation, setShowCleanAllConfirmation] = React.useState<boolean>(false);
  const [openFeedback, setOpenFeedback] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (notification?.length) {
      setOpenFeedback(true);
    }
  }, [notification]);

  return (
    <>
      <Hbox vAlign='center' mt>
        <Hbox.Item>
          <H1 noMargin>{strings.notification.title}</H1>
        </Hbox.Item>
        <Hbox.Item noGrow vAlign='center'>
          <FaIcon.Close clickable='true' onClick={props.onCloseClick} />
        </Hbox.Item>
      </Hbox>
      <VSeparator />
      <NotificationStepButton text={strings.notification.buttonText} onClick={props.onConfigurationClick} />
      <VSeparator />
      <Hbox>
        <Feedback
          containerType={FeedbackContainerType.Box}
          message={strings.notification.feedbackMessage}
          opened={openFeedback}
          setOpened={setOpenFeedback}
          context={FeedbackAnalytics.NotificationPanel}
          type={FeedbackType.Numeric}
          analyticsType={EventTypes.Feedback}
        />
      </Hbox>
      <VSeparator />
      <Hbox mt>
        <Hbox.Item>
          <H2 noMargin>{strings.notification.lastUpdates}</H2>
        </Hbox.Item>
        <Hbox.Item noGrow vAlign='center'>
          {!showCleanAllConfirmation && (
            <ButtonText onClick={() => setShowCleanAllConfirmation(true)}>{strings.notification.cleanAll}</ButtonText>
          )}
          {!!showCleanAllConfirmation && (
            <Hbox>
              <ButtonText
                style={{ color: Color.Alert }}
                onClick={() => {
                  !!notification && props.onClearClick(notification.map(n => n.id));
                  setShowCleanAllConfirmation(false);
                }}
              >
                {strings.notification.cleanAllConfirmation}
              </ButtonText>
              &nbsp; &nbsp; / &nbsp; &nbsp;
              <ButtonText onClick={() => setShowCleanAllConfirmation(false)}>
                {strings.notification.cleanAllCancel}
              </ButtonText>
            </Hbox>
          )}
        </Hbox.Item>
      </Hbox>
      <VSeparator />
      <LoadingState loading={props.loading} data={!!notification} error={props.error}>
        <LoadingState.Shimmer>
          <NotificationListShimmer />
        </LoadingState.Shimmer>

        <LoadingState.Error>
          <ErrorPlaceholder onRetryClick={props.onRefetch} />
        </LoadingState.Error>

        <LoadingState.NoData>
          <NoDataPlaceholder message={strings.notification.empty} type='bell' />
        </LoadingState.NoData>

        <NotificationListWrapper>
          {notification?.map((item, index) => (
            <React.Fragment key={`${item.title}-${index}`}>
              <NotificationCell notification={item} onClearClick={() => props.onClearClick([item.id])} />
              <VSeparator />
            </React.Fragment>
          ))}
        </NotificationListWrapper>
      </LoadingState>
    </>
  );
};
