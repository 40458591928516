import styled from 'styled-components';
import { DesignSystemColor, Spacing, ZIndex } from '@atomic/obj.constants';

export const AdminHeaderWrapperStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: ${ZIndex.HeaderWrapperStyled};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${DesignSystemColor.DarkBlue};
  padding-left: ${Spacing.Large};
  padding-right: ${Spacing.Large};
`;

export const AdminHeaderLogoStyled = styled.div`
  cursor: pointer;
`;
