import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Strings, IAppStrings } from '@app/modules/app/app.string';
import { AppPath } from '@app/modules/app/route-constants';
import { hasChileBps, hasMaritimeBps, hasRoadBps } from '@app/utils/bps-utils';
import { Menu } from '@atomic/mol.menu';
import { OrderMenuMe } from './order-menu-me/order-menu-me.component';

interface OrderMenuProps {
  preventRerender: (e, to) => void;
}

export const OrderMenu: React.FC<OrderMenuProps> = ({ preventRerender }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const strings: IAppStrings = Strings[userInfo?.language];
  const showSubmenuMe =
    (hasMaritimeBps(clientBpsSelected) && hasRoadBps(clientBpsSelected)) || hasChileBps(clientBpsSelected);

  if (showSubmenuMe && location.pathname === AppPath.ORDERS) {
    return <OrderMenuMe text={strings?.tabs.orders} path={AppPath.ORDERS} />;
  }

  return (
    <Menu.NavLink
      onClick={e => preventRerender(e, AppPath.ORDERS)}
      active={location.pathname === AppPath.ORDERS}
      to={AppPath.ORDERS}
    >
      {strings?.tabs.orders}
    </Menu.NavLink>
  );
};
