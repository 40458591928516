export const CustomizedTooltipStrings = {
  pt: {
    step: (currentStep: number, lastStep: number) => `Passo ${currentStep} de ${lastStep}`,
    next: 'Próximo',
    finish: 'Finalizar',
    close: 'Fechar',
  },
  in: {
    step: (currentStep: number, lastStep: number) => `Step ${currentStep} of ${lastStep}`,
    next: 'Next',
    finish: 'Finish',
    close: 'Close',
  },
  es: {
    step: (currentStep: number, lastStep: number) => `Paso ${currentStep} de ${lastStep}`,
    next: 'Próximo',
    finish: 'Cerrar',
    close: 'Cerrar',
  },
};
