import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { GridButtonWithIcon } from '@app/modules/components/grid-button-with-icon.component';
import { IOrderPriorizationStrings, Strings } from '@app/modules/order-priorization/order-priorization.string';
import { setShowConfirmModal } from '@app/modules/order-priorization/store/order-priorization.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexRow, Hbox } from '@atomic/obj.box';
import { Color, FontSize } from '@atomic/obj.constants';

interface OrderPriorizationHeaderControlProps {
  selectedItems: number;
  canProceed: boolean;
}

export const OrderPriorizationHeaderControl: React.FC<OrderPriorizationHeaderControlProps> = ({
  selectedItems,
  canProceed,
}) => {
  const strings: IOrderPriorizationStrings = Strings.pt;
  const { rows, showConfirmBtn } = useSelector((state: RootState) => state.orderPriorization);
  const dispatch = useDispatch();

  return (
    <FlexRow mt mb hAlign='space-between'>
      <FlexRow>
        <Body size={FontSize.Large}>{strings.page.headerControlInfo}</Body>
        <Hbox.Separator small />
        <Body size={FontSize.Large} bold={700} color={canProceed ? Color.SuccessMain : Color.Alert}>
          {`${selectedItems} ${canProceed ? strings.page.labelQtyItem : strings.page.removeItemsLabel}`}
        </Body>
      </FlexRow>
      {showConfirmBtn && (
        <FlexRow>
          <GridButtonWithIcon
            text={strings?.page.confirm}
            icon={FaIcon.Check}
            kind='primary'
            disabled={!canProceed || rows.length === 0}
            onClick={() => dispatch(setShowConfirmModal(true))}
          />
        </FlexRow>
      )}
    </FlexRow>
  );
};
