import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IAppStrings, Strings } from '@app/modules/app/app.string';
import { AppPath } from '@app/modules/app/route-constants';
import { hasOrdersTabAccessSelector, quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { NotificationCenter } from '@app/modules/components/notification-center/notification-center.component';
import { MarketType } from '@app/providers';
import { setOpenNewsModalHistory } from '@app/providers/navigation/navigation.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Menu } from '@atomic/mol.menu';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { LoginIconWrapper } from './actions-header.style';
import { AppConfigurationOptions } from './app-configuration-options/app-configuration-options.component';

interface ActionsAppHeaderProps {
  shortHeader: boolean;
  handleLogout: () => void;
}

export const ActionsAppHeader: React.FC<ActionsAppHeaderProps> = ({ shortHeader, handleLogout }) => {
  const { currentMarket } = useSelector((state: RootState) => state.customerPersist);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const hasOrdersTabAccess = useSelector(hasOrdersTabAccessSelector);
  const strings: IAppStrings = Strings[userInfo?.language];
  const dispatch = useDispatch();

  return (
    <Menu.Action shortHeader={shortHeader}>
      <LoginIconWrapper shortHeader={shortHeader}>
        <FlexRow vAlign='center'>
          {currentMarket === MarketType.Internal && (
            <FlexColumn vAlign='center'>
              <FaIcon.News
                clickable='true'
                onClick={() => dispatch(setOpenNewsModalHistory(true))}
                color={Color.Gray}
                size='sm'
              />
            </FlexColumn>
          )}
          <Hbox.Separator />
          <FlexColumn vAlign='center' hAlign='center'>
            <Body noWrap>{userInfo ? userInfo.name?.split(' ')[0] : 'Usuário'}</Body>
          </FlexColumn>
          <Hbox.Separator />
          <FlexColumn vAlign='center'>
            <AppConfigurationOptions onLogout={handleLogout} />
          </FlexColumn>
        </FlexRow>
      </LoginIconWrapper>
      {(currentMarket === MarketType.Internal || quoteIsInternalUser) && (
        <FlexRow vAlign='center' hAlign='center'>
          <FlexColumn vAlign='center'>
            <Menu.Help kind='link' to={AppPath.HELP_CENTER}>
              <FlexRow vAlign='center'>
                <Hbox.Separator small />
                <FlexColumn noGrow vAlign='center'>
                  <FaIcon.Help size='1x' />
                </FlexColumn>
                <Hbox.Separator />
                <FlexColumn noGrow vAlign='center'>
                  {strings?.tabs.helpCenter}
                </FlexColumn>
              </FlexRow>
            </Menu.Help>
          </FlexColumn>
          <Hbox.Separator />
          <FlexColumn>
            {currentMarket === MarketType.Internal && hasOrdersTabAccess && <NotificationCenter />}
          </FlexColumn>
        </FlexRow>
      )}
    </Menu.Action>
  );
};
