import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { OrderColumnsContainer } from './order-column-container/order-column-container';

export const OrderColumns: React.FC = () => {
  return (
    <>
      <FlexRow mt>
        <Body bold='bold' mr={Spacing.Medium}>
          {strings.columnOrderDescription}
        </Body>
        <Image.DragIcon />
      </FlexRow>
      <FlexRow mt mb>
        <DndProvider backend={HTML5Backend}>
          <OrderColumnsContainer />
        </DndProvider>
      </FlexRow>
    </>
  );
};
