import styled from 'styled-components';
import { Label } from '@atomic/atm.typography';
import { Color, Spacing } from '@atomic/obj.constants';

export const TitleLabel = styled(Label)`
  font-size: 18px;
  width: 180px;
  margin-left: ${Spacing.XXSmall};
`;

export const FilterTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.Medium};
  height: 75px;
  border-bottom: 2px solid ${Color.GrayLight};
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  background-color: ${Color.Transparent};
  padding: ${Spacing.Small};
  margin-right: ${Spacing.Medium};
  position: relative;
  cursor: pointer;
`;

export const CloseIcon = styled.span`
  &,
  &:after {
    display: block;
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: ${Color.Primary};
    border-radius: 4px;
  }

  top: 32;
  left: 0;
  transform: rotate(45deg);
  cursor: pointer;

  &:after {
    content: '';
    transform: rotate(-90deg);
  }
`;
