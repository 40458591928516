import styled from 'styled-components';
import { Color, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';

export const AdvancedFiltersButtonWrapper = styled.div`
  position: relative;
  margin-right: ${Spacing.Small};

  & > button {
    width: 120px;
  }
`;

export const FilterCounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 19px;
  height: 19px;
  background-color: ${Color.Primary};
  z-index: 5;
  top: -5px;
  right: -5px;
  border: 5px solid ${Color.Primary};
  border-radius: 10px;
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  color: ${Color.White};
  font-weight: bold;
`;
