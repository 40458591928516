import * as React from 'react';
import { Frame } from '@atomic/atm.frame';
import { TextShimmerBoxStyled, ThumbShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';

interface DashboardBlockShimmerProps {
  blocks: number;
}

export const DashboardBlockShimmer: React.FC<DashboardBlockShimmerProps> = props => {
  return (
    <>
      <VSeparator />
      <Hbox>
        <Hbox.Item hAlign='center'>
          <TextShimmerBoxStyled height='30px' width='300px' margin='0' />
        </Hbox.Item>
      </Hbox>
      <VSeparator />

      <Frame>
        <Grid fluid>
          <Row>
            {Array(props.blocks)
              .fill('')
              .map((_, index) => (
                <Col key={index} xs={12} md={12 / props.blocks}>
                  <VSeparator />
                  <Hbox>
                    <Hbox.Item hAlign='center'>
                      <TextShimmerBoxStyled height='20px' width='150px' margin='0' />
                    </Hbox.Item>{' '}
                  </Hbox>
                  <VSeparator />
                  <ThumbShimmerBoxStyled height='300px' />
                  <VSeparator />
                </Col>
              ))}
          </Row>
        </Grid>
      </Frame>
    </>
  );
};
