import * as React from 'react';
import { Cell } from '@atomic/atm.cell';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Hbox } from '@atomic/obj.box';
import { Col, Row, VSeparator } from '@atomic/obj.grid';

export const QuoteCellShimmer: React.FC = () => {
  return (
    <Row>
      <Col xs={12}>
        {Array(5)
          .fill('')
          .map((_tab, index) => (
            <Cell key={index}>
              <Hbox>
                <Hbox.Item>
                  <TextShimmerBoxStyled height='20px' width='60%' margin='0' />
                  <VSeparator small />

                  <TextShimmerBoxStyled height='14px' width='40%' margin='0' />
                  <VSeparator small />

                  <TextShimmerBoxStyled height='14px' width='40%' margin='0' />
                </Hbox.Item>
                <Hbox.Item noGrow>
                  <TextShimmerBoxStyled height='20px' width='100px' margin='0' />
                </Hbox.Item>
              </Hbox>
            </Cell>
          ))}
      </Col>
    </Row>
  );
};
