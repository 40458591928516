export const CrmStrings = {
  pt: {
    sessions: {
      OPPORTUNITY_DETAILS: 'Detalhes da Oportunidade',
      MANUFACTURING_CONSULTATION: 'CONSULTA DE FABRICAÇÃO CAMPOS PRINCIPAIS',
      NOTES: 'TEXTOS',
      ATTACHED_FILES: 'ARQUIVOS ANEXADOS',
      PLANTS: 'PLANTAS - Pessoas/organizações envolvidas',
    },
    subSessions: {
      GENERAL_DATA: 'Dados gerais',
      DATES: 'DATAS',
      CYCLE_SALES: 'CICLO DE VENDAS',
      CLASSIFICATION: 'CLASSIFICAÇÃO',
      NOTES: 'Notas',
    },
    fields: {
      tb: 'Tb:',
      cfId: 'ID CF:',
      clientName: 'Cliente potencial:',
      cfDescription: 'Descrição CF:',
      sendDerrogate: 'Dt. Envio Derroga:',
      initial: 'Dt. Início:',
      end: 'Dt. Encerramento:',
      cfStatus: 'Status da CF:',
      steel: 'Aço:',
      colaborator: 'Empregado Responsável:',
      clientAddress: 'End. Cliente potencial:',
      opportunitiesGroup: 'Grupo de Oportunidades:',
      revisionReview: 'Revisão de norma:',
      clientSteel: 'Aço do cliente:',
      diameter: 'Bitola / dimensão:',
      businessType: 'Tipo de Negócio:',
      finalMarket: 'Mercado Final:',
      finalClient: 'Cliente Final:',
      PPAP: 'PPAP:',
      pilotBatch: 'Lote piloto:',
      approvalDateEPP: 'Data Aprovação EPP:',
      dateSendDerogationCustomer: 'Data de Envio da Derroga ao Cliente:',
      clientReview: 'Norma do cliente:',
      partNumber: 'Part Number:',
      length: 'Comprimento:',
      classificationPRD: 'Classificação do PRD:',
      partApplication: 'Aplicação da peça:',
      quantity: 'Quantidade:',
      clientProcess: 'Processo do cliente:',
      development: 'Desenvolvimento:',
      rejectionDateEPP: 'Data Aprovação EPP:',
      approvalDerogationDate: 'Data de Aprovação da Derroga:',
    },
    crmList: {
      cfDescription: 'Descrição CF',
      cfId: 'ID CF',
      cfStatus: 'Status da CF',
      classificationPRD: 'Classificação PRD',
      clientName: 'Nome Cliente',
      clientSteel: 'Aço',
      development: 'Desenvolvido',
      endDate: 'Dt. Encerramento',
      finalClient: 'Cliente Final',
      finalMarket: 'Mercado Final',
      initialDate: 'Dt. Início',
      partApplication: 'Aplicação da Peça',
      plant: 'Planta',
      revisionReview: 'Norma do Cliente',
      sendDerrogateDate: 'Dt. Envio Derroga',
      lang: 'Idioma',
    },
    tables: {
      cols: {
        clientId: 'ID do Parceiro',
        email: 'Endereço email',
        name: 'Nome',
        type: 'Função do Parceiro',
        partnerRole: '',
        fileName: 'Arquivo',
        typeFile: 'Tipo de Arquivo',
        createdBy: 'Criado por',
        createdAt: 'Data de Criação',
        isMainPartner: 'Parceiro Principal',
        plant: 'Planta',
      },
    },
    filterFields: {
      clientName: 'Nome do Cliente',
      clientSteel: 'Aço do Cliente',
      clientReview: 'Norma',
      revisionReview: 'Revisão da Norma',
      classificationProduct: 'Classificação do produto',
      colaborator: 'Aberto por',
      cfId: 'Número do CRM',
      diameter: 'Bitola/Dimensão',
      rcId: 'Número do RC',
      saleDocument: 'Nota Fiscal',
      batch: 'Lote',
      rcDescription: 'Motivo',
      lang: 'Idioma',
      //internationalSteel: 'Aço - Classificação Internacional',
      //plant: 'Planta',
    },
  },
  in: {
    sessions: {
      OPPORTUNITY_DETAILS: 'Detalhes da Oportunidade',
      MANUFACTURING_CONSULTATION: 'CONSULTA DE FABRICAÇÃO CAMPOS PRINCIPAIS',
      NOTES: 'TEXTOS',
      ATTACHED_FILES: 'ARQUIVOS ANEXADOS',
      PLANTS: 'PLANTAS - Pessoas/organizações envolvidas',
    },
    subSessions: {
      GENERAL_DATA: 'Dados gerais',
      DATES: 'DATAS',
      CYCLE_SALES: 'CICLO DE VENDAS',
      CLASSIFICATION: 'CLASSIFICAÇÃO',
      NOTES: 'Notas',
    },
    fields: {
      tb: 'Tb:',
      cfId: 'ID CF:',
      clientName: 'Cliente potencial:',
      cfDescription: 'Descrição CF:',
      sendDerrogate: 'Dt. Envio Derroga:',
      initial: 'Dt. Início:',
      end: 'Dt. Encerramento:',
      cfStatus: 'Status da CF:',
      steel: 'Aço:',
      colaborator: 'Empregado Responsável:',
      clientAddress: 'End. Cliente potencial:',
      opportunitiesGroup: 'Grupo de Oportunidades:',
      revisionReview: 'Revisão de norma:',
      clientSteel: 'Aço do cliente:',
      diameter: 'Bitola / dimensão:',
      businessType: 'Tipo de Negócio:',
      finalMarket: 'Mercado Final:',
      finalClient: 'Cliente Final:',
      PPAP: 'PPAP:',
      pilotBatch: 'Lote piloto:',
      approvalDateEPP: 'Data Aprovação EPP:',
      dateSendDerogationCustomer: 'Data de Envio da Derroga ao Cliente:',
      clientReview: 'Norma do cliente:',
      partNumber: 'Part Number:',
      length: 'Comprimento:',
      classificationPRD: 'Classificação do PRD:',
      partApplication: 'Aplicação da peça:',
      quantity: 'Quantidade:',
      clientProcess: 'Processo do cliente:',
      development: 'Desenvolvimento:',
      rejectionDateEPP: 'Data Aprovação EPP:',
      approvalDerogationDate: 'Data de Aprovação da Derroga:',
    },
    crmList: {
      cfDescription: 'Descrição CF',
      cfId: 'ID CF',
      cfStatus: 'Status da CF',
      classificationPRD: 'Classificação PRD',
      clientName: 'Nome Cliente',
      clientSteel: 'Aço',
      development: 'Desenvolvido',
      endDate: 'Dt. Encerramento',
      finalClient: 'Cliente Final',
      finalMarket: 'Mercado Final',
      initialDate: 'Dt. Início',
      partApplication: 'Aplicação da Peça',
      plant: 'Planta',
      revisionReview: 'Norma do Cliente',
      sendDerrogateDate: 'Dt. Envio Derroga',
    },
    tables: {
      cols: {
        clientId: 'ID do Parceiro',
        email: 'Endereço email',
        name: 'Nome',
        type: 'Função do Parceiro',
        partnerRole: '',
        fileName: 'Arquivo',
        typeFile: 'Tipo de Arquivo',
        createdBy: 'Criado por',
        createdAt: 'Data de Criação',
        isMainPartner: 'Parceiro Principal',
        plant: 'Planta',
      },
    },
    filterFields: {
      clientName: 'Nome do Cliente',
      clientSteel: 'Aço do Cliente',
      clientReview: 'Norma',
      revisionReview: 'Revisão da Norma',
      classificationProduct: 'Classificação do produto',
      colaborator: 'Aberto por',
      cfId: 'Número do CRM',
      diameter: 'Bitola/Dimensão',
    },
  },
  es: {
    sessions: {
      OPPORTUNITY_DETAILS: 'Detalhes da Oportunidade',
      MANUFACTURING_CONSULTATION: 'CONSULTA DE FABRICAÇÃO CAMPOS PRINCIPAIS',
      NOTES: 'TEXTOS',
      ATTACHED_FILES: 'ARQUIVOS ANEXADOS',
      PLANTS: 'PLANTAS - Pessoas/organizações envolvidas',
    },
    subSessions: {
      GENERAL_DATA: 'Dados gerais',
      DATES: 'DATAS',
      CYCLE_SALES: 'CICLO DE VENDAS',
      CLASSIFICATION: 'CLASSIFICAÇÃO',
      NOTES: 'Notas',
    },
    fields: {
      tb: 'Tb:',
      cfId: 'ID CF:',
      clientName: 'Cliente potencial:',
      cfDescription: 'Descrição CF:',
      sendDerrogate: 'Dt. Envio Derroga:',
      initial: 'Dt. Início:',
      end: 'Dt. Encerramento:',
      cfStatus: 'Status da CF:',
      steel: 'Aço:',
      colaborator: 'Empregado Responsável:',
      clientAddress: 'End. Cliente potencial:',
      opportunitiesGroup: 'Grupo de Oportunidades:',
      revisionReview: 'Revisão de norma:',
      clientSteel: 'Aço do cliente:',
      diameter: 'Bitola / dimensão:',
      businessType: 'Tipo de Negócio:',
      finalMarket: 'Mercado Final:',
      finalClient: 'Cliente Final:',
      PPAP: 'PPAP:',
      pilotBatch: 'Lote piloto:',
      approvalDateEPP: 'Data Aprovação EPP:',
      dateSendDerogationCustomer: 'Data de Envio da Derroga ao Cliente:',
      clientReview: 'Norma do cliente:',
      partNumber: 'Part Number:',
      length: 'Comprimento:',
      classificationPRD: 'Classificação do PRD:',
      partApplication: 'Aplicação da peça:',
      quantity: 'Quantidade:',
      clientProcess: 'Processo do cliente:',
      development: 'Desenvolvimento:',
      rejectionDateEPP: 'Data Aprovação EPP:',
      approvalDerogationDate: 'Data de Aprovação da Derroga:',
    },
    crmList: {
      cfDescription: 'Descrição CF',
      cfId: 'ID CF',
      cfStatus: 'Status da CF',
      classificationPRD: 'Classificação PRD',
      clientName: 'Nome Cliente',
      clientSteel: 'Aço',
      development: 'Desenvolvido',
      endDate: 'Dt. Encerramento',
      finalClient: 'Cliente Final',
      finalMarket: 'Mercado Final',
      initialDate: 'Dt. Início',
      partApplication: 'Aplicação da Peça',
      plant: 'Planta',
      revisionReview: 'Norma do Cliente',
      sendDerrogateDate: 'Dt. Envio Derroga',
    },
    tables: {
      cols: {
        clientId: 'ID do Parceiro',
        email: 'Endereço email',
        name: 'Nome',
        type: 'Função do Parceiro',
        partnerRole: '',
        fileName: 'Arquivo',
        typeFile: 'Tipo de Arquivo',
        createdBy: 'Criado por',
        createdAt: 'Data de Criação',
        isMainPartner: 'Parceiro Principal',
        plant: 'Planta',
      },
    },
    filterFields: {
      clientName: 'Nome do Cliente',
      clientSteel: 'Aço do Cliente',
      clientReview: 'Norma',
      revisionReview: 'Revisão da Norma',
      classificationProduct: 'Classificação do produto',
      colaborator: 'Aberto por',
      cfId: 'Número do CRM',
      diameter: 'Bitola/Dimensão',
      //internationalSteel: 'Aço - Classificação Internacional',
      //plant: 'Planta',
    },
  },
};
