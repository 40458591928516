import Container from 'typedi';
import { ConversationContextStorageService } from '@app/data/storage/conversation-context.storage';

export const conversationContextStore = () => {
  const contextStorageService: ConversationContextStorageService = Container.get(ConversationContextStorageService);

  const getConversationContext = () => {
    return contextStorageService.get();
  };

  const setConversationContext = (context: any) => {
    contextStorageService.set(context);
  };

  return { getConversationContext, setConversationContext };
};
