import * as React from 'react';
import { Body, Caption } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';

interface SubtitleItemProps {
  caption: string;
  description: string;
}

export const TruckTrackingSubtitleItem: React.FC<SubtitleItemProps> = props => {
  return (
    <Grid fluid>
      <VSeparator />
      <Row noGutter>
        <Col xs={2}>{props.children}</Col>
        <Col xs={10}>
          <Hbox>
            <Hbox.Item>
              <Caption>{props.caption}</Caption>
              <Body>{props.description}</Body>
            </Hbox.Item>
          </Hbox>
        </Col>
      </Row>
      <VSeparator />
    </Grid>
  );
};
