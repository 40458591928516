import styled from 'styled-components';
import { Border } from '@atomic/obj.constants';

interface DivisorProps {
  color: string;
}

export const Divisor = styled.div`
  height: ${Border.Width};
  width: 100%;
  background-color: ${(props: DivisorProps) => (props.color ? props.color : Border.Color)};
`;
