export interface ICustomerSearchModalBodyClientStrings {
  initialMessage: string;
  noBpFoundMessage: string;
  selectAll: string;
}

interface ICustomerSearchModaBodyClientLanguages {
  pt: ICustomerSearchModalBodyClientStrings;
  es: ICustomerSearchModalBodyClientStrings;
  in: ICustomerSearchModalBodyClientStrings;
}

const pt: ICustomerSearchModalBodyClientStrings = {
  initialMessage: 'Para iniciar, realize uma busca',
  noBpFoundMessage: 'Nenhuma filial encontrado',
  selectAll: 'Selecionar todos',
};

const es: ICustomerSearchModalBodyClientStrings = {
  initialMessage: 'Para empezar, realiza una búsqueda',
  noBpFoundMessage: 'No se encontró ningún filial',
  selectAll: 'Seleccionar todo',
};

const en: ICustomerSearchModalBodyClientStrings = {
  initialMessage: 'To get started, perform a search',
  noBpFoundMessage: 'No Business Partner found',
  selectAll: 'Select all',
};

export const Strings: ICustomerSearchModaBodyClientLanguages = {
  pt,
  es,
  in: en,
};
