import { Checkbox } from '@gerdau/hefesto';
import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { RoleDisplayName, RoleInternalName, TabInternalName } from '@app/models/admin.model';
import { setEppRoleConfig } from '@app/modules/admin/store/admin.store';
import { updateRole } from '@app/modules/admin/store/thunk/profile-update.thunk';
import { addOrRemoveItem } from '@app/utils/array-helper';
import {
  AdminPermissionsRoleCol,
  PermissionRoleColCell,
  PermissionRoleTitleColCell,
} from '../../admin-profile-view.styles';
import { getTabIdList } from '../../utils/utils';

export const AdminProfileEppRoleCol: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { tabChips, eppRoleConfig } = useSelector((state: RootState) => state.admin);

  const handleChange = (tagName: string) => {
    const tab = { _id: tabChips.filter(t => t.tag === tagName)[0]._id, tag: tagName };
    const newTabArray = addOrRemoveItem(tagName, 'tag', eppRoleConfig.tabs, tab);
    const updatedEppRoleConfig = { _id: eppRoleConfig._id, profile: eppRoleConfig.profile, tabs: newTabArray };
    dispatch(setEppRoleConfig(updatedEppRoleConfig));
    dispatch(updateRole({ uid: eppRoleConfig._id, profile: eppRoleConfig.profile, tabs: getTabIdList(newTabArray) }));
  };

  return (
    <AdminPermissionsRoleCol>
      <PermissionRoleTitleColCell>
        <Tag variant='neutral'>{eppRoleConfig?.profile === RoleInternalName.Epp && RoleDisplayName.Epp}</Tag>
      </PermissionRoleTitleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppDashboardCb'
          checked={
            eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.Dashboard)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Dashboard);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppQuotationtCb'
          checked={
            eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.Quotation)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Quotation);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppOrderInputCb'
          checked={
            eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.OrderInput)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.OrderInput);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppOrderCb'
          checked={eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.Order)[0]}
          onChange={() => {
            handleChange(TabInternalName.Order);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppFinanceCb'
          checked={
            eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.Finance)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Finance);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppManifestCb'
          checked={
            eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.Manifest)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Manifest);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppIsaManagerCb'
          checked={
            eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.IsaManager)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.IsaManager);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppCrmCb'
          checked={eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.Crm)[0]}
          onChange={() => {
            handleChange(TabInternalName.Crm);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppSurrogateCb'
          checked={
            eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.Surrogate)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Surrogate);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppLackOfLoadCb'
          checked={
            eppRoleConfig?.tabs?.length && !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.LackOfLoad)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.LackOfLoad);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='eppSchedulingCompletedCb'
          checked={
            eppRoleConfig?.tabs?.length &&
            !!eppRoleConfig.tabs.filter(t => t.tag === TabInternalName.SchedulingCompleted)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.SchedulingCompleted);
          }}
        />
      </PermissionRoleColCell>
    </AdminPermissionsRoleCol>
  );
};
