import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { IOrderInputStrings, OrderInputStrings } from '../../order-input.string';
import { IATagRowWrapper, IATagWrapper } from '../../phases/order-input-options/order-input-options.style';

export const OrderInputPdfEmailRead: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];

  return (
    <FlexColumn mt={Spacing.Small}>
      <IATagRowWrapper>
        <IATagWrapper>
          <Image.IA />
          <Body color={Color.White}>{strings.uploadPdfEmail.iaTag}</Body>
        </IATagWrapper>
      </IATagRowWrapper>
      <FlexRow mt={Spacing.Small}>
        <Body color={Color.PurpleDark}>{strings.uploadPdfEmail.optionDescription}</Body>
      </FlexRow>
    </FlexColumn>
  );
};
