import { SLICE_NAME } from '../lack-of-charge-modal.constants';
import { ILackOfChargeModalState } from '../lack-of-charge-modal.interfaces';

export const RESET_LACK_OF_CHARGE_ACTION_TYPE = `${SLICE_NAME}/reset`;
export const RESET_LACK_OF_CHARGE_ACTION = { type: RESET_LACK_OF_CHARGE_ACTION_TYPE };

export const reducers = {
  reset: (state: ILackOfChargeModalState) => {
    state.announcementData = null;
    state.loading = false;
    state.hasLackOfCharge = false;
    state.hasLackOfChargeAnnouncement = false;
  },
  setLackOfCharge: (state: ILackOfChargeModalState, { payload }) => {
    state.announcementData = payload;
  },
  setHasLackOfCharge: (state: ILackOfChargeModalState, { payload }) => {
    state.hasLackOfCharge = payload;
  },
  setOpenLackOfChargeActionModal: (state: ILackOfChargeModalState, { payload }) => {
    state.openedConfigModal = payload;
  },
};
