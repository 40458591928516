import React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H3 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants/constants';
import { VSeparator } from '@atomic/obj.grid';

interface CustomizedTooltipHeaderProps {
  title: string;
  onClose: () => void;
  hasH3Font?: boolean;
}

export const CustomizedTooltipHeader: React.FC<CustomizedTooltipHeaderProps> = ({
  title,
  onClose,
  hasH3Font = true,
}) => {
  return (
    <>
      <FlexRow>
        <FlexColumn hAlign='flex-start'>
          {hasH3Font ? <H3 color={Color.White}>{title}</H3> : <Body color={Color.White}>{title}</Body>}
        </FlexColumn>
        <FlexColumn noGrow hAlign='flex-end'>
          <FaIcon.Close data-testid='close-tooltip' color={Color.White} clickable='true' onClick={onClose} />
        </FlexColumn>
      </FlexRow>
      <VSeparator small />
    </>
  );
};
