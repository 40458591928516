import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { setSelectAll, setSelectAllMaterials } from '@app/modules/order-input/store/order-input.store';
import { CheckboxField } from '@atomic/atm.checkbox';
import { TH, TR } from '@atomic/mol.table';
import { FlexRow } from '@atomic/obj.box';

const strings = OrderInputStrings.pt.dateAndAmount.tableColumns;

export const AmountTableHeader: React.FC = () => {
  const { selectAll } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch();

  const onValueChange = () => {
    dispatch(setSelectAll(!selectAll));
    dispatch(setSelectAllMaterials(!selectAll));
  };

  return (
    <TR>
      <TH width={60}>
        <FlexRow vAlign='center' hAlign='center'>
          <CheckboxField id='multiselection' checked={selectAll} onValueChange={onValueChange} />
        </FlexRow>
      </TH>
      <TH width={260}>{strings.orderNumber}</TH>
      <TH width={155}>{strings.orderItem}</TH>
      <TH>{strings.materialDescription}</TH>
      <TH width={135}>{strings.amount}</TH>
      <TH width={120}>{strings.unity}</TH>
      <TH width={170}>{strings.date}</TH>
      <TH width={60}></TH>
    </TR>
  );
};
