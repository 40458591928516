import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { QuoteDataSource } from '@app/data/http';
import { GetAnalysisParams } from '@app/data/http/quote-params.dto';
import { FullAnalysisData } from '@app/models/quote.model';

export interface FullTechnicalAnalysisValues {
  fullLoading: boolean;
  fullError: Error;
  getFullAnalysis: (params: GetAnalysisParams) => void;
}

export function useFullTechnicalAnalysis(
  setAnalysis: (analysis: FullAnalysisData) => void,
): FullTechnicalAnalysisValues {
  const { performRequest: getFullAnalysis, loading: fullLoading, error: fullError } = useLazyRequest<
    GetAnalysisParams,
    FullAnalysisData
  >(QuoteDataSource.getAnalysis, setAnalysis);

  return {
    fullLoading,
    fullError,
    getFullAnalysis,
  };
}
