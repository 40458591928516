import React from 'react';
import { useSelector } from 'react-redux';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { QuoteDataSource } from '@app/data/http';
import {
  CreateExtraDimensionData,
  CreateTableData,
  DeleteConversationParams,
  EditConversationData,
  ExtraDimensionData,
  GetAnalysisParams,
  PostConversationParams,
  TableRowData,
} from '@app/data/http/quote-params.dto';
import { useUploadProjectQuery } from '@app/domain/quote.use-case';
import { Chat } from '@app/models';
import { ChatAnalysisParams } from '@app/models/analysis.model';
import { FullAnalysisData } from '@app/models/quote.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { ChatStrings } from './chat.string';

export enum QuestionTypes {
  Pause = 'pause',
  Option = 'option',
  MultiSelection = 'multiple_options',
}

export enum InitialActions {
  Edit = 'edit',
  Duplicate = 'duplicate',
  Recovery = 'recovery',
}

export const ActionFlags = {
  [InitialActions.Edit]: 'EDITION',
  [InitialActions.Duplicate]: 'DUPLICATION',
  [InitialActions.Recovery]: 'RECOVERY',
};

export const useChatContext = () => {
  const [questions, setQuestions] = React.useState([]);
  const [action, setAction] = React.useState<string>();
  const [systemAttributes, setSystemAttributes] = React.useState([]);
  const [attributes, setAttributes] = React.useState([]);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [localContext, setLocalContext] = React.useState<any>({});

  const [timeQuote, setTimeQuote] = React.useState(0);

  React.useEffect(() => {
    let interval;

    const addTime = () => {
      setTimeQuote(prevTempo => prevTempo + 1);
    };

    const startTime = () => {
      if (!interval) {
        interval = setInterval(addTime, 1000);
      }
    };

    const stopTime = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    window.addEventListener('focus', startTime);
    window.addEventListener('blur', stopTime);

    return () => {
      window.removeEventListener('focus', startTime);
      window.removeEventListener('blur', stopTime);
      clearInterval(interval);
    };
  }, []);

  const { userInfo } = useSelector((state: RootState) => state.auth);

  const strings = ChatStrings[userInfo.language].error;

  const conversationQuery = useLazyRequest<PostConversationParams, Chat>(QuoteDataSource.postConversation, data => {
    setLocalContext(data.context);
  });

  const conversationNewAttributeQuery = useLazyRequest<PostConversationParams, Chat>(
    QuoteDataSource.postConversationNewAttribute,
    data => {
      setLocalContext(data.context);
    },
  );

  const unFinishedAnalysisQuery = useLazyRequest<ChatAnalysisParams, Chat>(
    QuoteDataSource.getUnfinishedAnalysis,
    data => {
      setLocalContext(data.context);
    },
  );

  const chatAnalysisQuery = useLazyRequest<ChatAnalysisParams, Chat>(QuoteDataSource.getChatAnalysis, data => {
    setLocalContext(data.context);
  });

  const conversationDelete = useLazyRequest<DeleteConversationParams, Chat>(
    QuoteDataSource.deleteConversation,
    data => {
      setLocalContext(data.context);
    },
    () => {
      show('alert', strings.attributeDeletion);
    },
  );

  const conversationReset = useLazyRequest<string, Chat>(
    QuoteDataSource.resetConversation,
    data => {
      setLocalContext(data.context);
    },
    () => {
      show('alert', strings.attributeReset);
    },
  );

  const conversationEdit = useLazyRequest<EditConversationData, Chat>(
    QuoteDataSource.editConversation,
    data => {
      setLocalContext(data.context);
    },
    () => {
      show('alert', strings.attributeEdition);
    },
  );

  const tableQuery = useLazyRequest<CreateTableData, Chat>(QuoteDataSource.postCreateTable, data => {
    setLocalContext(data.context);
  });

  const extraDimensionsQuery = useLazyRequest<CreateExtraDimensionData, Chat>(
    QuoteDataSource.postCreateExtraDimension,
    data => {
      setLocalContext(data.context);
    },
  );

  const analysisQuery = useLazyRequest<GetAnalysisParams, FullAnalysisData>(QuoteDataSource.getAnalysis);

  const documentQuery = useUploadProjectQuery();

  React.useEffect(() => {
    const data = documentQuery?.data;
    if (data?.attributes) {
      setAttributes(data.attributes);
    }
    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions?.length > 0) {
      const newQuestions = [...questions, ...data.questions];
      setQuestions(newQuestions);
      setLocalContext(data.context);
    }
  }, [documentQuery.data]);

  React.useEffect(() => {
    const { data } = conversationQuery;
    if (data?.attributes) {
      setAttributes(data.attributes);
    }

    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions.length > 0) {
      const newQuestions = [...questions, ...data.questions];
      setQuestions(newQuestions);
      setLocalContext(data.context);
    }
  }, [conversationQuery.data]);

  React.useEffect(() => {
    const { data } = conversationDelete;
    if (data?.attributes) {
      setAttributes(data.attributes);
    }

    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions.length > 0) {
      if (data?.questions[0].question !== questions[questions.length - 1].question) {
        const newQuestions = [...questions, ...data.questions];
        setQuestions(newQuestions);
      }
      setLocalContext(data.context);
    }
  }, [conversationDelete.data]);

  React.useEffect(() => {
    const { data } = conversationReset;
    if (data?.attributes) {
      setAttributes(data.attributes);
    }

    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions.length > 0) {
      setQuestions(data.questions);
      setLocalContext(data.context);
    }
  }, [conversationReset.data]);

  React.useEffect(() => {
    const { data } = conversationEdit;
    if (data?.attributes) {
      setAttributes(data.attributes);
    }

    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions.length > 0) {
      setLocalContext(data.context);
    }
  }, [conversationEdit.data]);

  React.useEffect(() => {
    const { data } = tableQuery;
    if (data?.attributes) {
      setAttributes(data.attributes);
    }

    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions.length > 0) {
      const newQuestions = [...questions, ...data.questions];
      setQuestions(newQuestions);
      setLocalContext(data.context);
    }
  }, [tableQuery.data]);

  React.useEffect(() => {
    const { data } = extraDimensionsQuery;
    if (data?.attributes) {
      setAttributes(data.attributes);
    }

    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions.length > 0) {
      const newQuestions = [...questions, ...data.questions];
      setQuestions(newQuestions);
      setLocalContext(data.context);
    }
  }, [extraDimensionsQuery.data]);

  React.useEffect(() => {
    if (analysisQuery.data) {
      chatAnalysisQuery.performRequest({
        language: userInfo.language,
        conversationId: analysisQuery.data.conversationId,
        type: ActionFlags[action],
      });
    }
  }, [analysisQuery.data]);

  React.useEffect(() => {
    const data = chatAnalysisQuery.data;

    if (data?.attributes) {
      setAttributes(data.attributes);
    }

    if (data?.context) {
      setLocalContext(data.context);
    }

    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions.length > 0) {
      const newQuestions = [...questions, ...data.questions];
      setQuestions(newQuestions);
    }
  }, [chatAnalysisQuery.data]);

  React.useEffect(() => {
    const data = conversationNewAttributeQuery.data;
    if (data?.attributes) {
      setAttributes(data.attributes);
    }

    if (data?.context) {
      setLocalContext(data.context);
    }

    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions.length > 0) {
      const newQuestions = [...questions, ...data.questions];
      setQuestions(newQuestions);
    }
  }, [conversationNewAttributeQuery.data]);

  React.useEffect(() => {
    const data = unFinishedAnalysisQuery.data;
    if (data?.attributes) {
      setAttributes(data.attributes);
    }

    if (data?.context) {
      setLocalContext(data.context);
    }

    if (data?.context?.systemAttributes) {
      setSystemAttributes(data.context.systemAttributes);
    }

    if (data?.questions.length > 0) {
      const newQuestions = [...questions, ...data.questions];
      setQuestions(newQuestions);
    }
  }, [unFinishedAnalysisQuery.data]);

  const start = (analysisId?: string, selectedAction?: string) => {
    if (analysisId === undefined) {
      conversationQuery.performRequest({
        language: userInfo.language,
        answer: '',
        currentContext: {},
      });
      //postConversation({ answer: '', currentContext: {} });
    } else {
      setAction(selectedAction);
      if (selectedAction === 'recovery') {
        unFinishedAnalysisQuery.performRequest({ language: userInfo.language, conversationId: analysisId });
      } else {
        analysisQuery.performRequest({ id: parseInt(analysisId, 10) });
      }
    }
  };

  const setAnswer = (id: string, label?: string) => {
    if (!questions.length || (!id?.length && !label?.length)) {
      return;
    }
    questions[questions.length - 1].answer = label || id;
    setQuestions([...questions]);
    conversationQuery.performRequest({
      language: userInfo.language,
      answer: id,
      currentContext: { ...localContext, openTableType: '', tableClosed: true },
    });
  };

  const setNewAttribute = (label: string, value: string, conversation_id: string) => {
    if (!questions.length || (!label?.length && !value?.length)) {
      return;
    }
    questions[questions.length - 1].answer = label || value;
    setQuestions([...questions]);
    conversationNewAttributeQuery.performRequest({
      language: userInfo.language,
      label: label,
      value: value,
      conversationId: conversation_id,
      currentContext: { ...localContext, openTableType: '', tableClosed: true },
    });
    setLocalContext({ ...localContext, openTableType: '', tableClosed: true });
  };

  const upload = (file: File[]) => {
    const [norm] = file;
    questions[questions.length - 1].answer = norm.name;
    setQuestions([...questions]);
    documentQuery.fetch({ file: norm, conversationId: localContext.conversation_id, language: userInfo.language });
  };

  const updateGlobalContext = (data: any) => {
    setLocalContext({ ...localContext, ...data });
    conversationQuery.performRequest({
      language: userInfo.language,
      answer: '',
      currentContext: { ...localContext, ...data },
    });
  };

  const updateContext = (data: any) => {
    setLocalContext({ ...localContext, ...data, openTableType: '' });
  };

  const removeAttribute = (key: string) => {
    conversationDelete.performRequest({
      language: userInfo.language,
      conversationId: localContext.conversation_id,
      attributeKey: key,
    });
  };

  const resetConversation = () => {
    conversationReset.performRequest(localContext.conversation_id);
  };

  const editAttribute = (data: EditConversationData) => {
    conversationEdit.performRequest({
      attributeKey: data.attributeKey,
      conversationId: localContext.conversation_id,
      value: data.value,
      type: data.type,
      unit: data.unit,
      language: userInfo.language,
    });
    setLocalContext({ ...localContext, ...data, openTableType: '', tableClosed: true });
  };

  const createTable = (data: TableRowData[], key: string) => {
    tableQuery.performRequest({
      conversation_id: localContext.conversation_id,
      value: typeof data === 'string' ? data : { rows: data },
      key,
      language: userInfo.language,
    });
    setLocalContext({ ...localContext, ...data, openTableType: '', tableClosed: true });
  };

  const createExtraDimension = (data: ExtraDimensionData[], key: string) => {
    extraDimensionsQuery.performRequest({
      conversation_id: localContext.conversation_id,
      value: data,
      key,
      currentContext: { ...localContext, ...data, openTableType: '', tableClosed: true },
      language: userInfo.language,
    });
    setLocalContext({ ...localContext, data, openTableType: '', tableClosed: true });
  };

  return {
    start,
    resetConversation,
    setAnswer,
    setNewAttribute,
    extraDimensionsQuery,
    questions,
    attributes,
    systemAttributes,
    upload,
    updateContext,
    updateGlobalContext,
    removeAttribute,
    editAttribute,
    createTable,
    createExtraDimension,
    forceRedirect: conversationQuery.data?.forceRedirect,
    context: localContext,
    chatLoading: chatAnalysisQuery.loading || unFinishedAnalysisQuery.loading,
    uploadLoading: documentQuery.loading,
    loading:
      conversationQuery.loading ||
      documentQuery.loading ||
      conversationDelete.loading ||
      conversationEdit.loading ||
      conversationNewAttributeQuery.loading,
    timeQuote,
  };
};
