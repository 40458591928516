import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

const SLICE_NAME = 'order';

export interface IOrderState {
  orderTabIndex: number;
  openFeedback: boolean;
}

const initialState: IOrderState = {
  orderTabIndex: 0,
  openFeedback: false,
};

const reducers = {
  setOrderTabIndex: (state: IOrderState, { payload }) => {
    state.orderTabIndex = payload;
  },
  setOpenFeedback: (state: IOrderState, { payload }) => {
    state.openFeedback = payload;
  },
};

export const orderSlice = createSlice<IOrderState, SliceCaseReducers<IOrderState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
});

export const { setOrderTabIndex, setOpenFeedback } = orderSlice.actions;

export const orderReducer = orderSlice.reducer;
