import { ColDef, GridReadyEvent } from 'ag-grid-community';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { NotificationDatasource } from '@app/data/http/notification.datasource';
import { useActivateNotificationRequest } from '@app/domain/activate-notification.use-case';
import { useActivateShippingConfigRequest } from '@app/domain/activate-shipping-config.use-case';
import { useDeactivateNotificationRequest } from '@app/domain/deactivate-notification.use-case';
import { useDeactivateShippingConfigRequest } from '@app/domain/deactivate-shipping-config.use-case';
import { EventTypes } from '@app/models/analytics.model';
import { TabIndex } from '@app/models/order.model';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { OrderPriozationPage } from '@app/modules/order-priorization/order-priorization.page';
import { getCriticalItemsAndRulesThunk } from '@app/modules/order-priorization/store/thunks/get-critical-items-and-rules.thunk';
import { OrderUpdatePage } from '@app/modules/order-update/order-update.page';
import { setOpenUpdateFeedback } from '@app/modules/order-update/store/order-update.store';
import { renderSubtotalField } from '@app/modules/order/grid-utils/grid-subtotal-field-render';
import { removeFilterInput } from '@app/modules/order/grid-utils/utils';
import { NotificationStore, useNotificationStore } from '@app/providers/notification.store';
import { Feedback, FeedbackAnalytics, FeedbackType } from '@atomic/obj.feedback';
import { Visibility } from '@atomic/obj.visibility';
import { OrdersStrings } from '../../../orders.string';
import { OrderTabsHeaderMI } from '../order-tabs-header-MI.component';
import { OrderTabOpenComponent } from './order-tab-open.component';
import { tabName } from './order-tab-open.constants';
import { setData, setFilteredRows, setSelectedRows, setShowActionBar } from './store/order-tab-open.store';
import { performRequest } from './store/order-tab-open.thunks';
import { configTanslateDeliveryStatusFilterOpened } from './utils/action-utils';
import {
  handleCriticalFilter,
  handleFilter,
  handleFilterCheck,
  handleLackOfChargeFilter,
  handleOpenTabColumn,
} from './utils/handler-utils';
import {
  handleActivationSuccess,
  handleDeactivateSuccess,
  handleActivationShippingConfigSuccess,
  handleDeactivationShippingConfigSuccess,
  configChangeColumnValue,
} from './utils/notification-utils';

export const OrderTabOpen: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const gridEvent = useRef<GridReadyEvent | null>(null);
  const clientBpsSelectedJoined = useSelector(customerIdsJoinedSelector);
  const {
    data,
    lastUpdate,
    filter,
    criticalFilter,
    lackOfChargeFilter,
    filteredRows,
    selectedRows,
    showActionBar,
  } = useSelector((state: RootState) => state.orderTabOpen);
  const { confirmedFilters } = useSelector((state: RootState) => state.advancedFilters[tabName]);

  const columns: ColDef[] = useSelector(columnsTabLayoutSelector(tabName, userInfo?.language));

  const { showOrderUpdate, openUpdateFeedback } = useSelector((state: RootState) => state.orderUpdate);
  const { showOrderPriorization, criticalItemInfoLoading, loading: criticalItemsLoading } = useSelector(
    (state: RootState) => state.orderPriorization,
  );

  const dispatch = useDispatch<AppThunkDispatch>();

  const { notificationConfigured, setConfiguration, setCurrentStep, setNotificationPanelOpen } = useStore<
    NotificationStore
  >(useNotificationStore);

  configTanslateDeliveryStatusFilterOpened(columns, userInfo?.language);

  const { performRequest: updateConfiguration } = useLazyRequest(NotificationDatasource.configureNotificationSettings);

  const { performRequest: getConfiguration } = useLazyRequest(
    NotificationDatasource.getConfiguration,
    setConfiguration,
  );

  const calculateSubtotals = () =>
    renderSubtotalField({
      tab: tabName,
      itens: filteredRows,
      language: userInfo?.language,
      columnDef: columns,
    });

  const changeColumnValue = configChangeColumnValue({
    selectedRows,
    setSelectedRows: value => dispatch(setSelectedRows(value)),
    gridEvent,
    data,
    setData: newData => dispatch(setData(newData)),
  });

  const { performRequest: activateNotifications, loading: activateLoading } = useActivateNotificationRequest(() =>
    handleActivationSuccess({
      notificationConfigured,
      updateConfiguration,
      setCurrentStep,
      setNotificationPanelOpen,
      getConfiguration,
      selectedRows,
      changeColumnValue,
    }),
  );

  const { performRequest: deactivateNotifications, loading: deactivateLoading } = useDeactivateNotificationRequest(() =>
    handleDeactivateSuccess({
      selectedRows,
      changeColumnValue,
    }),
  );

  const {
    performRequest: activateShippingConfig,
    loading: activateShippingConfigLoading,
  } = useActivateShippingConfigRequest(() =>
    handleActivationShippingConfigSuccess({
      selectedRows,
      changeColumnValue,
      customerIds: clientBpsSelectedJoined,
    }),
  );

  const {
    performRequest: deactivateShippingConfig,
    loading: deactivateShippingConfigLoading,
  } = useDeactivateShippingConfigRequest(() => {
    handleDeactivationShippingConfigSuccess({
      selectedRows,
      changeColumnValue,
      customerIds: clientBpsSelectedJoined,
    });
  });

  useEffect(() => {
    dispatch(setFilteredRows(data));
  }, [data]);

  useEffect(() => {
    calculateSubtotals();
    removeFilterInput(tabName, 'status');
    removeFilterInput(tabName, 'deliveryStatus');
  }, [columns]);

  useEffect(() => {
    dispatch(performRequest());
    dispatch(getCriticalItemsAndRulesThunk());
  }, [clientBpsSelectedJoined]);

  useEffect(() => {
    dispatch(setShowActionBar(!!selectedRows.length));
  }, [selectedRows]);

  useEffect(() => {
    if (showActionBar) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [showActionBar]);

  useEffect(() => {
    calculateSubtotals();
  }, [filteredRows]);

  useEffect(() => {
    handleFilter({ gridEvent, filter, handleFilterCheck });
  }, [filter, data, confirmedFilters]);

  useEffect(() => {
    handleCriticalFilter({ gridEvent, criticalFilter });
  }, [criticalFilter]);

  useEffect(() => {
    handleLackOfChargeFilter({ gridEvent, lackOfChargeFilter });
  }, [lackOfChargeFilter]);

  useEffect(() => {
    if (!showOrderUpdate && gridEvent) {
      gridEvent?.current?.api?.deselectAll();
    }
  }, [showOrderUpdate, gridEvent]);

  handleOpenTabColumn(columns, userInfo?.language);

  return (
    <>
      {showOrderUpdate && <OrderUpdatePage />}
      {showOrderPriorization && <OrderPriozationPage gridEvent={gridEvent} />}
      <Visibility visible={!showOrderUpdate && !showOrderPriorization}>
        <OrderTabsHeaderMI lastUpdate={lastUpdate} tabIndex={TabIndex.Open} />
        <OrderTabOpenComponent
          gridEvent={gridEvent}
          activateLoading={activateLoading}
          deactivateLoading={deactivateLoading}
          activateShippingConfigLoading={activateShippingConfigLoading}
          deactivateShippingConfigLoading={deactivateShippingConfigLoading}
          criticalItemInfoLoading={criticalItemInfoLoading}
          criticalItemsLoading={criticalItemsLoading}
          activateNotifications={activateNotifications}
          activateShippingConfig={activateShippingConfig}
          deactivateNotifications={deactivateNotifications}
          deactivateShippingConfig={deactivateShippingConfig}
        />
        <Feedback
          opened={openUpdateFeedback}
          setOpened={value => dispatch(setOpenUpdateFeedback(value))}
          context={FeedbackAnalytics.OrderUpdate}
          type={FeedbackType.Numeric}
          message={OrdersStrings[userInfo?.language]?.common.orderUpdateFeedback}
          analyticsType={EventTypes.OrderUpdateFeedback}
        />
      </Visibility>
    </>
  );
};
