import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
// import { MarketType } from '@app/providers';
import { announcementOpenedSelector } from '@app/providers/navigation/navigation.selectors';
// import { isQuoteChatComplainCrmPage } from '@app/utils/verify-quote-crm-complain-page';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Modal } from '@atomic/obj.modal';
import {
  IMG_RELATIVE_PATH,
  // TEMPORARY_SHUTDOWN_PLANT_BR_KEY,
  // TEMPORARY_SHUTDOWN_PLANT_EN_KEY,
} from './announcement-modal.constants';
import { Strings, IAnnouncementModalStrings } from './announcement-modal.string';
import {
  AnnouncementModalBody,
  AnnouncementModalCloseWrapper,
  AnnouncementModalFooter,
  AnnouncementModalHeader,
  AnnouncementModalImage,
  AnnouncementModalTitleWrapper,
  AnnouncementModalWrapper,
} from './announcement-modal.style';
import { setOpened } from './store/announcement-modal.store';
// import { getAnnouncement } from './store/get-announcement.thunk';
import { putAnnouncement } from './store/put-announcement.thunk';

export const AnnouncementModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { announcements, opened } = useSelector((state: RootState) => state.announcement);
  // const { currentMarket, clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const { openBPsModal } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch<AppThunkDispatch>();
  const announcementOpened = useSelector(announcementOpenedSelector);
  const strings: IAnnouncementModalStrings = Strings[userInfo?.language];

  const handleClose = () => {
    dispatch(putAnnouncement({ key: announcements[0]?._id, email: userInfo?.email }));
  };

  useEffect(() => {
    if (announcements.length) {
      dispatch(setOpened(true));
    } else {
      dispatch(setOpened(false));
    }
  }, [announcements, openBPsModal]);

  return (
    <Modal small opened={opened && announcementOpened}>
      <AnnouncementModalHeader>
        <AnnouncementModalTitleWrapper>{strings?.announcement.title}</AnnouncementModalTitleWrapper>
        <AnnouncementModalCloseWrapper>
          <FlexColumn onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
            <Image.NewsModalHeaderCloseButton />
          </FlexColumn>
        </AnnouncementModalCloseWrapper>
      </AnnouncementModalHeader>
      <AnnouncementModalWrapper>
        <AnnouncementModalBody>
          <AnnouncementModalImage>
            <img width='600px' src={IMG_RELATIVE_PATH + announcements[0]?.url[0]} />
          </AnnouncementModalImage>
          <AnnouncementModalFooter>
            <FlexRow hAlign='center'>
              <Button testid={`${TEST_ID_CLOSE_MODAL}-annoucement`} onClick={() => handleClose()}>
                {strings?.announcement.confirmButton}
              </Button>
            </FlexRow>
          </AnnouncementModalFooter>
        </AnnouncementModalBody>
      </AnnouncementModalWrapper>
    </Modal>
  );
};
