import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { ChatStrings } from '@app/modules/chat/chat.string';
import { TextField } from '@atomic/atm.text-field';
import { Form, Validators } from '@atomic/obj.form';
import { isMultipleEmailValid } from '@atomic/obj.form/regex-validator';
import { TechnicalAnalysisStatusUserList } from './technical-analysis-user-list.component';

interface TechnicalAnalysisStatusFieldProps {
  label: string;
  name: string;
  value: string;
  placeholder: string;
  show: boolean;
  users: string[];
  loading: boolean;
  profile: string;
  onClose: () => void;
  onSelect: (value: string) => void;
  onValueChange: (value: string) => void;
}

export const TechnicalAnalysisStatusField: React.FC<TechnicalAnalysisStatusFieldProps> = props => {
  const [defaultValue, setDefaultValue] = React.useState(null);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language];

  React.useEffect(() => {
    setDefaultValue(props.value);
  }, [props.value]);

  const handleBlur = value => {
    if (!isMultipleEmailValid(value)) {
      setDefaultValue(null);
    }
  };

  return (
    <>
      <Form.Field
        label={props.label}
        name={props.name}
        validators={[Validators.Required(strings.modalAttributes.alertInvalidEmail)]}
        onValueChange={props.onValueChange}
        initialValue={defaultValue}
      >
        <TextField
          data-testid={`emailField-${props.profile.toUpperCase()}`}
          placeholder={props.placeholder}
          onBlur={val => handleBlur(val.target.value)}
        />
      </Form.Field>
      <TechnicalAnalysisStatusUserList
        show={props.show}
        users={props.users}
        onClose={props.onClose}
        onSelect={props.onSelect}
        loading={props.loading}
      />
    </>
  );
};
