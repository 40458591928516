import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const AdminListWrapperStyled = styled.div`
  flex: 1;
  overflow: 'auto';
`;

export const AdminUserWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${Spacing.Large} ${Spacing.XLarge};
`;

export const AdminFilterStyled = styled.div`
  width: 20%;
`;

export const AdminSearchStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  width: 65%;
  margin-left: ${Spacing.XLarge};
  margin-right: ${Spacing.XLarge};
`;

export const AdminListHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AdminListHeaderSeparator = styled.div`
  width: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 16px;
  border-right: 2px solid ${Color.Gray};
  height: ${Spacing.Large};
`;

export const AdminBPsLineSeparator = styled.div`
  border-bottom: 2px solid #dad6d6;
`;

export const AdminDataSeparator = styled.div`
  width: 20px;
  height: ${Spacing.Large};
  margin-right: 16px;
  border-right: 2px solid ${Color.Gray};
`;

export const TagHeaderContainer = styled.div`
  padding-right: ${Spacing.Medium};
`;

export const AdminDefaultLineWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.Large};
`;

export const BpTypeSpan = styled.span`
  text-transform: capitalize;
  padding-left: ${Spacing.XSmall};
`;
