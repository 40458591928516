import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';

export const FileUploaderWrapper = styled(FlexRow)`
  width: 100%;
`;

export const ReadOrderLoaderWrapperStyled = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IAIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${FontSize.Small};
  width: 100px;
  height: 100px;
  background-color: ${Color.Primary};
  padding-right: ${Spacing.XSmall};
`;
