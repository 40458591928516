import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { Tab } from '@app/modules/components/tabs/tabs.component';
import { Frame } from '@atomic/atm.frame';
import { FlexRow } from '@atomic/obj.box';
import { setOrderTabIndex } from '../../order.store';
import { IOrderStrings } from '../../order.strings.interface';
import { OrdersStrings } from '../../orders.string';
import { OrderTabsLastUpdate } from '../order-tabs-last-update.component';
import { orderNationalTabEvents } from './order-tabs-MI.constants';

interface OrderTabsHeaderMIProps {
  lastUpdate?: string;
  tabIndex: number;
}

export const OrderTabsHeaderMI: React.FC<OrderTabsHeaderMIProps> = ({ lastUpdate, tabIndex }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderStrings = OrdersStrings[userInfo?.language];
  const dispatch = useDispatch();

  const handleTabChange = (index: number) => {
    LogAnalytics.pageView(orderNationalTabEvents[index]);
    dispatch(setOrderTabIndex(index));
  };

  return (
    <Frame>
      <Grid fluid>
        <Row>
          <Col xs={6}>
            <FlexRow>
              <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={0}>
                {strings?.tabsTitle.MI.opened}
              </Tab>
              <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={1}>
                {strings?.tabsTitle.MI.stock}
              </Tab>
              <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={2}>
                {strings?.tabsTitle.MI.billed}
              </Tab>
            </FlexRow>
          </Col>
          <Col xs={6}>
            <OrderTabsLastUpdate lastUpdate={lastUpdate} />
          </Col>
        </Row>
      </Grid>
    </Frame>
  );
};
