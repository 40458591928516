import styled from 'styled-components';
import { Breakpoint, HeaderHeight, ShortHeaderLimit } from '@atomic/obj.constants';

interface AppRoutesStyledProps {
  hasUserLoggedIn: boolean;
  shortHeader: boolean;
  windowWitdh: number;
  isAdmin: boolean;
  isNewAdmin: boolean;
}

export const AppRoutesStyled = styled.div`
  padding-top: ${(props: AppRoutesStyledProps) =>
    props.hasUserLoggedIn
      ? props.isNewAdmin
        ? '80px !important'
        : props.shortHeader || props.windowWitdh <= ShortHeaderLimit.limitMobile || props.isAdmin
        ? `${HeaderHeight.DeskShort} !important`
        : `${HeaderHeight.Desk} !important`
      : '0px'};

  @media all and (min-width: ${Breakpoint.sm}em) {
    padding-top: ${(props: AppRoutesStyledProps) => (props.hasUserLoggedIn ? HeaderHeight.DeskShort : '0px')};
  }
`;
