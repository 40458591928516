import { StatusByLanguage } from '@app/modules/order/grid-filters/grid-open-international-row-filter-options';

export const filterSelector = state => {
  const language = state.auth?.userInfo?.language;
  const tabOpenMaritimeOpenFilter = state.orderTabOpenInternational.filter;

  if (!language) {
    return '';
  } else if (tabOpenMaritimeOpenFilter !== null) {
    return tabOpenMaritimeOpenFilter;
  }

  return [
    StatusByLanguage[language]?.NotConfirmed,
    StatusByLanguage[language]?.InAnalysis,
    StatusByLanguage[language]?.Confirmed,
  ].join();
};
