import * as React from 'react';
import { TextFieldStyled } from '@atomic/atm.text-field/text-field.component.style';
import { Hbox } from '@atomic/obj.box';
import { FormField } from '@atomic/obj.form';

export interface CrmAdvancedFilterFieldsProps {
  label: string;
  name: string;
  type: string;
  onBlur: (value: string, key: string, action: string) => void;
  onFocus: (key: string) => void;
}

export const CrmAdvancedFilterFields = (props: CrmAdvancedFilterFieldsProps) => {
  return (
    <Hbox.Item>
      <FormField label={props.label} name={props.name}>
        <TextFieldStyled
          id={props.name}
          type={props.type}
          onBlur={value => props.onBlur(value.target.value, props.name, 'add')}
          onFocus={() => props.onFocus(props.name)}
        />
      </FormField>
    </Hbox.Item>
  );
};
