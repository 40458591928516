import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export interface ProgressBarWrapperStyledProps {
  percentage: number;
}

export const ProgressBarWrapperStyled = styled.div`
  margin-top: 2px;
  position: relative;
  background-color: ${Color.GrayLight};
  border-radius: ${Border.Radius};
  overflow: hidden;
  height: 20px;
  width: 100%;

  &:before {
    content: '${(props: ProgressBarWrapperStyledProps) => Math.round(props.percentage) + '%'}';
    padding-left: ${Spacing.XSmall};
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: ${Border.Radius};
    width: ${(props: ProgressBarWrapperStyledProps) => (props.percentage > 100 ? 100 : props.percentage)}%;
    background-color: ${Color.Success};
    transition: width 1s;
    color: ${(props: ProgressBarWrapperStyledProps) => (props.percentage < 5 ? Color.GrayXDark : Color.White)};
    font-family: ${FontFamily.Primary};
    font-size: ${FontSize.Small};
    font-weight: ${FontWeight.Bold};
    text-align: center;
  }
`;
