import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { DeleteAssetsParams } from '@app/data/http/quote-assets-params.dto';
import { AssetData } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { TechnicalAnalysisAssetsEvents } from '@app/modules/quote/quote.analytics';
import { TechnicalAnalysisContext } from '@app/modules/quote/technical-analysis-copy/technical-analysis.context';
import { useDeleteAssets } from '../hooks/useDeleteAssets';
import { useEditAssets } from '../hooks/useEditAssets';
import { useGetAssets } from '../hooks/useGetAssets';
import { usePostAssets } from '../hooks/usePostAssets';

interface TechnicalAnalysisAssetsContextState {
  loading: boolean;
  assets: AssetData[];
  selectedAssets: AssetData[];
  setAssets: (data: AssetData[]) => void;
  handleSelection: (asset: AssetData, checked: boolean) => void;
  handleBatchSelection: (assetList: AssetData[], checked: boolean) => void;
  handlePost: (file: File[]) => void;
  handleChangeAssetVisibility: (assetKey: string, isClientVisible: boolean) => void;
  handleDeleteAssets: (params: DeleteAssetsParams) => void;
}

export const TechnicalAnalysisAssetsContext = React.createContext<TechnicalAnalysisAssetsContextState>(null);

export const TechnicalAnalysisAssetsProvider = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const [assets, setAssets] = React.useState<AssetData[]>([]);
  const [selectedAssets, setSelectedAssets] = React.useState<AssetData[]>([]);
  const { updateAssetNumber } = React.useContext(TechnicalAnalysisContext);

  const updateAssets = (response: AssetData[]) => {
    setAssets(response);
    updateAssetNumber(response.length || 0);
  };

  const { isRefetching: getAssetsLoading, refetch: getAssets } = useGetAssets(
    { analysisId: props.analysisId, language: userInfo.language },
    updateAssets,
  );

  const { mutate: postAssets, isLoading: postAssetsLoading } = usePostAssets(getAssets);

  const { mutate: patchAssets, isLoading: patchAssetsLoading } = useEditAssets(getAssets);

  const { mutate: deleteAssets, isLoading: deleteAssetsLoading } = useDeleteAssets(getAssets);

  const handlePost = async (files: File[]) => {
    LogAnalytics.upload({ tipo: TechnicalAnalysisAssetsEvents.AddAsset });
    for (const file of files) {
      postAssets({
        file: file,
        analysisId: props.analysisId,
        isClientVisible: !quoteIsInternalUser,
        language: userInfo.language,
      });
    }
  };

  const handleChangeAssetVisibility = (assetKey: string, isClientVisible: boolean) => {
    LogAnalytics.click({ tipo: TechnicalAnalysisAssetsEvents.AssetVisibility });
    patchAssets({
      assetKey,
      analysisId: props.analysisId,
      isClientVisible,
      language: userInfo.language,
    });
  };

  const handleSelection = (asset: AssetData, checked: boolean) => {
    if (checked) {
      const actualSelected = [...selectedAssets, asset];
      setSelectedAssets(actualSelected);
    } else {
      const actualSelected = [...selectedAssets];
      const index = actualSelected.findIndex(item => item.key === asset.key);
      actualSelected.splice(index, 1);
      setSelectedAssets(actualSelected);
    }
  };

  const handleBatchSelection = (assetList: AssetData[], checked: boolean) => {
    if (checked) {
      const actualSelected = [...assetList];
      setSelectedAssets(actualSelected);
    } else {
      setSelectedAssets([]);
    }
  };

  const handleDeleteAssets = (params: DeleteAssetsParams) => {
    LogAnalytics.click({ tipo: TechnicalAnalysisAssetsEvents.DeleteAsset });
    deleteAssets(params);
  };

  const loading = patchAssetsLoading || deleteAssetsLoading || getAssetsLoading || postAssetsLoading;

  const value: TechnicalAnalysisAssetsContextState = {
    loading,
    assets,
    selectedAssets,
    setAssets,
    handleSelection,
    handleBatchSelection,
    handlePost,
    handleChangeAssetVisibility,
    handleDeleteAssets,
  };

  return (
    <TechnicalAnalysisAssetsContext.Provider value={value}>{props.children}</TechnicalAnalysisAssetsContext.Provider>
  );
};
