import { addDays, format, startOfWeek } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { Day, WeekContainer } from '../custom-calendar.style';

interface CustomCalendarWeekDaysProps {
  activeDate: Date;
}

export const CustomCalendarWeekDays: React.FC<CustomCalendarWeekDaysProps> = ({ activeDate }) => {
  const weekStartDate = startOfWeek(activeDate);
  const weekDays = [];
  for (let day = 0; day < 7; day++) {
    weekDays.push(
      <Day key={`week-day-${day}`} isWeekNames>
        {format(addDays(weekStartDate, day), 'E', { locale: ptBR })}
      </Day>,
    );
  }
  return <WeekContainer>{weekDays}</WeekContainer>;
};
