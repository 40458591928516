import * as React from 'react';
import { LogAnalytics } from '@app/core/analytics';
import { CommentsFullData, QuoteAttributeRow, QuoteAttributes, QuoteAttributeTypes } from '@app/models/quote.model';
import { TechnicalAnalysusAttributeEvents } from '@app/modules/quote/quote.analytics';
//import { useGetNewAttribute } from '../hooks/useGetNewAttributes';
//import { TechnicalAnalysisContext } from '../technical-analysis.context';

export interface AttributesData {
  key: string;
  label: string;
  isCritical: boolean;
  type: string;
  permissions: {
    derogate: boolean;
    delible: boolean;
    editable: boolean;
  };
  creation: {
    origin: string; //GERDAU, CLIENT
  };
  status: string;
  testMethod?: string;
  unit?: string;
  detail?: string;
  complementaries?: {
    url: string;
  };
  derogate?: {
    url: string;
  };
  allowedDerogate?: [
    {
      key: string;
      label: string;
    },
  ];
  value: any;
}
interface TechnicalAnalysisAttributeContextState {
  isEditing: boolean;
  editedStatus: string;
  rowsStatus: DerogateRowValues[];
  comments: CommentsFullData;
  selectedAttribute: AttributesData;
  setIsEditing: (value: boolean) => void;
  onAttributeStatusChange: (status: string) => void;
  setAttribute: (attribute: QuoteAttributes) => void;
  onRowStatusChanged: (status: string, index: number) => void;
  onRowReasonChanged: (reason: string, index: number) => void;
  onRowSuggestionChanged: (suggestion: string | number | string[], index: number, attribute?: string) => void;
  onRowMinChanged: (min: string, index: number, status?: string) => void;
  onRowMaxChanged: (max: string, index: number, status?: string) => void;
  setComments: (response: CommentsFullData) => void;
  setEditedStatus: (status: string) => void;
  checkStatusOption: (options: { value: string; name: string }[]) => void;
  setSelectedAttribute: (any) => void;
}

export interface DerogateRowValues {
  key: string;
  status: string;
  reason: string;
  suggestion?: string;
  minSuggestion?: string;
  maxSuggestion?: string;
  colSpan?: number;
  rowSpan?: number;
}

enum TableColumns {
  Status = 'status',
  Reason = 'reason',
  Suggestion = 'suggestion',
}

export const TechnicalAnalysisAttributeContext = React.createContext<TechnicalAnalysisAttributeContextState>(null);

export const TechnicalAnalysisAttributeProvider = props => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedStatus, setEditedStatus] = React.useState('');
  const [rowsStatus, setRowStatus] = React.useState<DerogateRowValues[]>([]);
  const [comments, setComments] = React.useState<CommentsFullData>();
  const [selectedAttribute, setSelectedAttribute] = React.useState<AttributesData>(null);

  const getValue = (row: QuoteAttributeRow[], column: string) => {
    return row.find(item => item.columnRef === column)?.value;
  };

  const setAttribute = (attribute: QuoteAttributes) => {
    if (attribute.type === QuoteAttributeTypes.Table) {
      setRowStatus(
        attribute.attributeValue.rows.map(row => {
          const status = getValue(row, TableColumns.Status);
          return {
            key: row[0]?.key,
            status: status ? status : attribute.status,
            reason: getValue(row, TableColumns.Reason),
            suggestion: getValue(row, TableColumns.Suggestion),
            minSuggestion: getValue(row, TableColumns.Suggestion)?.min,
            maxSuggestion: getValue(row, TableColumns.Suggestion)?.max,
          };
        }),
      );
    } else {
      setRowStatus([]);
    }
  };

  const onAttributeStatusChange = (status: string) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.AttributeStatusChange });

    // if (status !== InversedAttributeStatus.derogate) {
    //   const updatedRows = [...rowsStatus].map(row => {
    //     const updatedRow = row;
    //     updatedRow.status = status;
    //     updatedRow.reason = status === 'derogate' ? status : undefined;
    //     updatedRow.suggestion = undefined;
    //     return updatedRow;
    //   });

    //   setRowStatus(updatedRows);
    // }
    if (selectedAttribute?.value && selectedAttribute?.value?.length > 0) {
      selectedAttribute.value.map(item => {
        item.derogate.status = status;
      });
    }

    setEditedStatus(status);
  };

  const onRowStatusChanged = (status: string, index: number) => {
    console.log(status, index);
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowStatusChange });

    selectedAttribute.value[index].derogate.status = status;

    // setSelectedAttribute(prevValue => ({
    //   ...prevValue,
    //   value: [...prevValue.value[index]],
    // }));

    //setSelectedAttribute(prev => [...prev.value[index].derogate, status: status]);

    // const rows = [...rowsStatus];
    // const row = rowsStatus[index];
    // row.status = status;
    // row.maxSuggestion = null;
    // row.minSuggestion = null;
    // row.reason = status === 'derogate' ? status : undefined;
    // row.suggestion = undefined;
    // rows.splice(index, 1, row);
    // setRowStatus(rows);
  };

  const onRowReasonChanged = (reason: string, index: number) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowReasonChange });
    const rows = [...rowsStatus];
    const row = rowsStatus[index];
    row.reason = reason;
    rows.splice(index, 1, row);
    setRowStatus(rows);
  };

  const convertToNumber = (value: number | string) => {
    if (value?.toString().includes(',')) {
      return value.toString().replace(',', '.');
    }
    return value.toString();
  };

  const onRowSuggestionChanged = (suggestion: string, index: number, status?: string) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowSuggestionChange });

    if (status) {
      onRowStatusChanged(status, index);
      onRowReasonChanged(status, index);
    }

    const rows = [...rowsStatus];
    const row = rowsStatus[index];
    row.suggestion = convertToNumber(suggestion);
    row.reason = status;
    rows.splice(index, 1, row);
    setRowStatus(rows);
  };

  const onRowMinChanged = (min: string, index: number, status?: string) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowMinChange });
    selectedAttribute.value[index].derogate.value.min = min;
    selectedAttribute.value[index].derogate.status = status;
  };

  const onRowMaxChanged = (max: string, index: number, status?: string) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowMaxChange });
    selectedAttribute.value[index].derogate.value.max = max;
    selectedAttribute.value[index].derogate.status = status;
  };

  const checkStatusOption = options => {
    if (editedStatus === 'non-analysed' || editedStatus === 'accepted') {
      return options.filter(item => item.value !== 'derogate');
    } else {
      return options;
    }
  };

  const technicalAnalysisAttributeValue: TechnicalAnalysisAttributeContextState = {
    isEditing,
    editedStatus,
    setIsEditing,
    onAttributeStatusChange,
    rowsStatus,
    setAttribute,
    onRowStatusChanged,
    onRowReasonChanged,
    onRowSuggestionChanged,
    onRowMaxChanged,
    onRowMinChanged,
    comments,
    setComments,
    setEditedStatus,
    checkStatusOption,
    selectedAttribute,
    setSelectedAttribute,
  };

  return (
    <TechnicalAnalysisAttributeContext.Provider value={technicalAnalysisAttributeValue}>
      {props.children}
    </TechnicalAnalysisAttributeContext.Provider>
  );
};
