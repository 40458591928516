import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Container from 'typedi';
import { RENEW_TOKEN_INTERVAL_MILESECONDS, VERIFY_LOGOFF_MILESECONDS } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { initializeAndGetProfileThunk } from '@app/modules/auth/store/thunk/initialize-and-get-profile.thunk';
import { refreshAndUpdateTokensThunk } from '@app/modules/auth/store/thunk/refresh-and-update-azure-and-ssotoken.thunk';
import { redirect } from '@app/modules/auth/utils/utils';

interface IAuthorizationContainerProps {
  hasUserLoggedIn: boolean;
}
const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const AuthorizationContainer: React.FC<IAuthorizationContainerProps> = props => {
  const { token, userInfo, ssoAccess } = useSelector((state: RootState) => state.auth);
  const accessGSB = window.location.search.indexOf('accessGSB=true') > -1;
  const dispatch = useDispatch<AppThunkDispatch>();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  // const codeParam = searchParams.get('code');
  const redirectUrl = searchParams.get('redirect');

  if (redirectUrl && !!token?.length) {
    redirect(redirectUrl);
  }

  useEffect(() => {
    if (accessGSB) {
      dispatch(initializeAndGetProfileThunk(token));
    }
  }, [userInfo]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(refreshAndUpdateTokensThunk(ssoAccess));
    }, RENEW_TOKEN_INTERVAL_MILESECONDS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const user = authStorageService.getUser();
      const isLoginPage = window.location.pathname.indexOf('/auth') === 0;
      const isForgotPasswordPage = window.location.pathname.indexOf('/forgot-password') === 0;

      if (!user && !isLoginPage && !isForgotPasswordPage) {
        authStorageService.logout();
      }
    }, VERIFY_LOGOFF_MILESECONDS);
    return () => clearInterval(interval);
  }, []);

  return <>{props.children}</>;
};
