export interface INotificationCenterStrings {
  notification: {
    title: string;
    buttonText: string;
    lastUpdates: string;
    cleanAll: string;
    cleanAllConfirmation: string;
    cleanAllCancel: string;
    empty: string;
    feedbackMessage: string;
  };
  configuration: {
    title: string;
    saveButton: string;
    cancelButton: string;
    orderConfirmation: {
      title: string;
      description: string;
    };
    startProduction: {
      title: string;
      description: string;
    };
    inStock: {
      title: string;
      description: string;
    };
    sendShippingStock: {
      title: string;
      description: string;
    };
    statusChange: {
      title: string;
      description: string;
    };
    steps: string;
    shipCreated: string;
    shipmentStarted: string;
    billed: string;
    sentForDelivery: string;
    arrived: string;
  };
  notificationCell: {
    showLess: string;
    showMore: (stepCount: number) => string;
    step: {
      arrived: string;
      billed: string;
      inStock: string;
      orderConfirmation: string;
      sentForDelivery: string;
      shipCreated: string;
      shipmentStarted: string;
      startProduction: string;
      statusChange: string;
    };
  };
}

export interface INotificationCenterLanguages {
  pt: INotificationCenterStrings;
}

const pt: INotificationCenterStrings = {
  notification: {
    title: 'Notificações',
    buttonText: 'Configurar notificações',
    lastUpdates: 'Últimas atualizações',
    cleanAll: 'Limpar tudo',
    cleanAllConfirmation: 'Limpar',
    cleanAllCancel: 'Cancelar',
    empty: 'Sua caixa de notificações está vazia',
    feedbackMessage: 'De 1 a 5 o quanto você está satisfeito com a central de notificações?',
  },
  configuration: {
    title: 'Configurar notificações',
    saveButton: 'Salvar configurações',
    cancelButton: 'Cancelar',
    orderConfirmation: {
      title: 'Pedido confirmado',
      description: 'Pedido confirmado pelo PCP para a produção',
    },
    startProduction: {
      title: 'Início de produção',
      description: 'Informa que novos volumes iniciaram o processo produtivo',
    },
    inStock: {
      title: 'Material em estoque',
      description: 'Material disponível no estoque para faturamento',
    },
    sendShippingStock: {
      title: 'Programar geração de remessa',
      description:
        'Quando houver entrada de material no estoque para essa OV, automaticamente será solicitado para o GAT gerar remessa',
    },
    statusChange: {
      title: 'Mudança de status de entrega',
      description: 'Alteração entre "Dentro do prazo" para "Em atraso" e vice-versa.',
    },
    steps: 'Etapas logísticas',
    shipCreated: 'Remessa gerada',
    shipmentStarted: 'Carregamento iniciado',
    billed: 'Faturado',
    sentForDelivery: 'Viagem iniciada',
    arrived: 'Chegada no cliente',
  },
  notificationCell: {
    showLess: 'Mostrar menos',
    showMore: (stepCount: number) => `Mostrar mais (${stepCount})`,
    step: {
      arrived: 'Chegada no cliente',
      billed: 'Faturado',
      inStock: 'Material em estoque',
      orderConfirmation: 'Pedido confirmado',
      sentForDelivery: 'Viagem iniciada',
      shipCreated: 'Remessa gerada',
      shipmentStarted: 'Carregamento iniciado',
      startProduction: 'Início de produção',
      statusChange: 'Mudança do estado de entrega',
    },
  },
};

export const Strings: INotificationCenterLanguages = {
  pt,
};
