import { SCHEDULE_TYPE } from './schedule.constants';
import { IScheduleState } from './schedule.interfaces';

export const setWeeklyOrDaily = (state: IScheduleState) => {
  if (state.selectedDaysOfWeek.length === 7) {
    state.scheduleType = SCHEDULE_TYPE.DAILY;
  } else {
    state.scheduleType = SCHEDULE_TYPE.WEEKLY;
  }
};

export const getSelectedDays = (state: IScheduleState) => {
  switch (state.scheduleType) {
    case SCHEDULE_TYPE.MONTHLY:
      return state.selectedDays.join();

    case SCHEDULE_TYPE.WEEKLY:
      return state.selectedDaysOfWeek.join();

    default:
      return null;
  }
};

export const formatTime = time => {
  let formatedTime;

  if (time.toString().length < 5) {
    formatedTime = `${time}:00`;

    if (formatedTime.length < 5) {
      formatedTime = `0${formatedTime}`;
    }
  } else {
    formatedTime = time;
  }

  return formatedTime;
};
