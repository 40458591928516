import styled from 'styled-components';
import { Breakpoint, Color, Shadow, Spacing } from '@atomic/obj.constants';

export const GridColumnFilterStyled = styled.div`
  position: relative;
`;

export const CrmAdvancedFilterWrapper = styled.div`
  box-shadow: ${Shadow.Small};
  border-color: ${Color.GrayLight};
  background-color: ${Color.White};
`;

export const CrmSearchWrapper = styled.div`
  padding: ${Spacing.Large} ${Spacing.Large} ${Spacing.Medium} ${Spacing.Large};
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-rows: 2fr;
  grid-column-gap: ${Spacing.Large};
  grid-row-gap: ${Spacing.Medium};
  @media all and (max-width: ${Breakpoint.lg}em) {
    grid-template-columns: 2fr 1fr;
  }
  & div {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-self: end;
    width: 100%;
    & > label {
      font-weight: 300;
    }
  }
`;

export const CrmMainFilterWrapper = styled.div`
  padding: ${Spacing.Large} ${Spacing.Large} ${Spacing.Medium} ${Spacing.Large};
  & > h2 {
    margin-top: 0px;
  }
  & div {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    & > label {
      font-weight: 300;
    }
  }
`;

interface CrmFilterTagsWrapperProps {
  mb?: number;
}
export const CrmFilterTagsWrapper = styled.div`
  ${(props: CrmFilterTagsWrapperProps) => props?.mb && `margin-bottom: ${props.mb}px;`};
  & div {
    width: auto;
  }
`;

export const CrmMainFilterButtonWrapper = styled.div`
  padding: 0 ${Spacing.Large} ${Spacing.Medium} ${Spacing.Large};
`;

export const CrmHiddenFilterWrapper = styled.div`
  display: ${props => (props.hide ? 'none' : 'block !important')};
  padding: ${Spacing.Medium} ${Spacing.Large} ${Spacing.Medium} ${Spacing.Large};
  background-color: ${Color.GrayXLight};
  & div {
    & > label {
      font-weight: 300;
    }
  }
`;

export const CrmFilterListContent = styled.div`
  position: relative;
  padding: 0 ${Spacing.Large} ${Spacing.Large} ${Spacing.Large};
  width: 100%;
`;

export const CrmFilterListWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 5fr 1fr;
  padding: ${Spacing.Small};
  background-color: ${Color.GrayXLight};
  align-items: center;
  @media all and (max-width: ${Breakpoint.lg}em) {
    grid-template-columns: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  & > div {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
