import { format, parse } from 'date-fns';
import { RequestedVsConfirmedOrderGraph } from '@app/models/dashboard.model';

export interface QtdSolicitadaMensalDTO {
  qtdSolicitada: number;
  dtQtdSolicitada: string;
}
export interface QtdConfirmadaMensalDTO {
  qtdConfirmada: number;
  dtQtdConfirmada: string;
}
export interface QtdRequestedGraphsDTO {
  qtdSolicitadaMensal: QtdSolicitadaMensalDTO[];
}

export interface QtdConfirmedGraphsDTO {
  qtdConfirmadoMensal: QtdConfirmadaMensalDTO[];
}

interface RequestedDTO {
  requestedQty: number;
  date: string;
}

interface ConfirmedDTO {
  confirmedQty: number;
  date: string;
}

const DEFAULT_NUMBER_OF_MONTHS = 10;

const lastMonths = (monthQty: number) => {
  return Array(monthQty)
    .fill('')
    .map((_month, index) => {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getUTCMonth() + 3 - index);
      const currentMonth = currentDate.getUTCMonth() + 1;
      const currentYear = currentDate.getUTCFullYear();
      return `${currentMonth.toString().padStart(2, '0')}/${currentYear}`;
    });
};

export const mapRequestedVsConfirmedOrderGraph = (
  requestedDTO: QtdRequestedGraphsDTO,
  confirmedDTO: QtdConfirmedGraphsDTO,
): RequestedVsConfirmedOrderGraph[] => {
  const requested: RequestedDTO[] = requestedDTO.qtdSolicitadaMensal.map(item => {
    const splitedDate = item.dtQtdSolicitada.split('-');
    return {
      requestedQty: item?.qtdSolicitada ? +item?.qtdSolicitada.toFixed(0) : 0,
      date: `${splitedDate[1]}/${splitedDate[0]}`,
    };
  });

  const confirmed: ConfirmedDTO[] = confirmedDTO.qtdConfirmadoMensal.map(item => {
    const splitedDate = item.dtQtdConfirmada.split('-');
    return {
      confirmedQty: item?.qtdConfirmada ? +item?.qtdConfirmada?.toFixed(0) : 0,
      date: `${splitedDate[1]}/${splitedDate[0]}`,
    };
  });

  return lastMonths(DEFAULT_NUMBER_OF_MONTHS)
    .map(month => {
      return {
        requested: requested.find(item => item.date === month)?.requestedQty,
        confirmed: confirmed.find(item => item.date === month)?.confirmedQty,
        date: month,
      };
    })
    .sort(
      (a, b) =>
        new Date(format(parse(a.date, 'MM/yyyy', 0), 'yyyy/MM')).getTime() -
        new Date(format(parse(b.date, 'MM/yyyy', 0), 'yyyy/MM')).getTime(),
    );
};
