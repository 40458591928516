import styled from 'styled-components';
import { Color, FontSize, Spacing, TransitionDuration, ZIndex } from '@atomic/obj.constants';

export const GridColumnFilterStyled = styled.div`
  position: relative;
`;

export const QuoteItemWrapperStyled = styled.div`
  position: relative;
  & > div > div {
    align-items: center;
  }
  & > div > div > a {
    margin-top: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const BrandWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  width: 115px;
  justify-content: center;
  & > img {
    width: min-content;
    height: 52px;
    padding: 0px ${Spacing.XLarge} 0 ${Spacing.Small};
  }
`;

export const QuoteItemPriceStyled = styled.div`
  align-self: flex-end;
  margin-top: 5px;
  text-align: right;
  & > p {
    white-space: nowrap;
  }
`;

export const QuoteModalClientFilterStyled = styled.div`
  ${props => (props.show ? 'opacity: 1;' : 'visibility: hidden;')}
  z-index: ${ZIndex.QuoteModalClientFilterStyled};
  position: absolute;
  background-color: ${Color.White};
  width: 100%;
  min-width: 650px;
  max-height: 300px;
  overflow: scroll;
  top: 60px;
  padding: ${Spacing.Small};
  transition: all ${TransitionDuration} ease-in-out;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.2);
  align-content: flex-start;
  border: 1px solid ${Color.GrayLight};
`;

export const DraftBadgeWrapperStyled = styled.div`
  align-self: flex-end;
  padding: 15px 0;
`;

export const DraftDeleteWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const QuotationVisibleWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const HeaderButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const IconKanbanStyle = styled.div`
  rotate: ${props => (props.status ? '90' : '0')}deg;
  & > img {
    ${props => (props.status ? 'margin-left: 2px;' : 'margin-top: 6px;')}
  }
`;

export const TooltipsWrapper = styled.div``;

export const HeaderListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.White};
  min-height: 60px;
  margin-bottom: ${Spacing.Small};
  padding: ${Spacing.Small} ${Spacing.Medium};
  border: 1px solid ${Color.GrayLight};
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > div > button {
    border-radius: ${Spacing.XSmall};
  }
`;

export const PrevNextPaginationButton = styled.div`
  & > button {
    border-radius: ${Spacing.XSmall};
    min-height: 40px;
    margin: 0 ${Spacing.Small};
  }
`;

export const QuoteTimeLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    white-space: nowrap;
    text-align: right;
  }
  & > p > svg {
    margin-right: ${Spacing.XSmall};
  }
  & > span {
    white-space: nowrap;
    align-self: flex-end;
    justify-content: flex-end;
  }
`;

export const QuoteItemAdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const QuoteItemIsaNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    white-space: nowrap;
  }
`;

export const VisibleButtonStyled = styled.div`
  margin-top: 0;
  padding-right: ${Spacing.Medium};
`;

interface TagStyledProps {
  disabled?: boolean;
}
export const TagStyled = styled.label`
  font-size: ${FontSize.XSmall};
  border-radius: ${Spacing.Medium};
  padding: ${Spacing.XSmall} ${Spacing.Small};
  margin-left: ${Spacing.Medium};
  background-color: ${(props: TagStyledProps) => (props.disabled ? Color.CallToAction : Color.Primary)};
  color: ${(props: TagStyledProps) => (props.disabled ? Color.WarningDark : Color.White)};
`;
