import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';

export const ScheduleModalTitleWrapper = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-size: ${FontSize.XLarge};
  font-weight: 700;
  color: ${Color.White};
`;

export const TabsHeaderScheduleModal = styled.div`
  box-shadow: 0 4px 2px -2px rgb(0 0 0 / 20%);
`;

export const ScheduleItem = styled.div`
  display: flex;
  border: 1px solid ${Color.Gray};
  margin: ${Spacing.Small};
  padding: ${Spacing.Small};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const ScheduleModalWrapper = styled.div`
  height: 320px;
`;
