import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { SCHEDULE_TYPE } from '@app/modules/components/schedule-modal/schedule.constants';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { setScheduleType } from '@app/modules/components/schedule-modal/store/schedule.store';
import { RadioField } from '@atomic/atm.radio';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Form } from '@atomic/obj.form';
import { MonthySchedule } from './partials/monthy-schedule/monthy-schedule';
import { WeeklySchedule } from './partials/weekly-schedule/weekly-schedule';
import { OptionsWrapper } from './schedule-dates.style';

const scheduleTypeComponents = {
  [SCHEDULE_TYPE.MONTHLY]: <MonthySchedule />,
  [SCHEDULE_TYPE.WEEKLY]: <WeeklySchedule />,
  [SCHEDULE_TYPE.DAILY]: <WeeklySchedule />,
};

export const ScheduleDates: React.FC = () => {
  const scheduleTypeStrings = strings.scheduleType;
  const { scheduleType } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();

  return (
    <FlexRow vAlign='baseline'>
      <FlexColumn pr mr={Spacing.XLarge}>
        <FlexRow mb={Spacing.Small}>
          <Body bold='bold'>{strings.frequencySend}</Body>
        </FlexRow>
        <Form.Field
          name='schedule-type'
          initialValue={scheduleType}
          onValueChange={value => {
            dispatch(setScheduleType(value));
          }}
        >
          <OptionsWrapper>
            <FlexRow mr>
              <RadioField id={SCHEDULE_TYPE.MONTHLY}>{scheduleTypeStrings.monthly}</RadioField>
            </FlexRow>
            <FlexRow mr>
              <RadioField id={SCHEDULE_TYPE.WEEKLY}>{scheduleTypeStrings.weekly}</RadioField>
            </FlexRow>
            <FlexRow>
              <RadioField id={SCHEDULE_TYPE.DAILY}>{scheduleTypeStrings.daily}</RadioField>
            </FlexRow>
          </OptionsWrapper>
        </Form.Field>
      </FlexColumn>
      <FlexRow hAlign='center'>{scheduleTypeComponents[scheduleType]}</FlexRow>
    </FlexRow>
  );
};
