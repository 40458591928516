import { ColDef } from 'ag-grid-community';
import { InStockOrderItem } from '@app/models';
import { OrderAction } from '@app/models/order.model';
import { Color } from '@atomic/obj.constants';
import { IOrderTabStockStrings } from '../order-tab-stock.string';

interface GetStockOrderActionsParams {
  strings: IOrderTabStockStrings;
  handleShippingRequestClick: () => void;
  setShowShippingRequest: (value: boolean) => void;
  selectedRows: InStockOrderItem[];
}

export const getStockOrderActions = ({
  strings,
  setShowShippingRequest,
}: GetStockOrderActionsParams): OrderAction[] => {
  return [
    {
      title: strings.actions.shippingRequest,
      icon: 'pen',
      action: () => setShowShippingRequest(true),
    },
  ];
};

interface CanSelectRowParams {
  data: InStockOrderItem;
}

export const canSelectRow = ({ data }: CanSelectRowParams) => {
  const displayeChekboxSelection = data.deliveryQuantity + data.pickingQuantity >= data.stockQuantity ? false : true;

  return displayeChekboxSelection;
};

export const setBackgroundCellRules = (columns: ColDef[]) => {
  if (columns) {
    columns.forEach(column => {
      column.cellStyle = params => {
        return params?.data?.typeStock === 'ESTOQUE AVANCADO' ? { backgroundColor: Color.GrayLight } : null;
      };
    });
  }
};

const AgGridCheckedClass = 'ag-checked';

export const hasAgCheckedClass = classList => {
  let result = false;

  classList.forEach(c => {
    if (c === AgGridCheckedClass) {
      result = true;
    }
  });

  return result;
};
