import { setItemToRemove, setLoading, setShowRemoveItemModal } from '../order-priorization.store';
import { deleteCriticalItems, IDeleteCriticalItemsParams } from './delete-critical-items.thunk';
import { getUpdatedOrderTabOpen } from './get-updated-order-tab-open.thunk';

export const deleteAndGetCriticalItemsThunk = (deleteCriticalItem: IDeleteCriticalItemsParams) => async (
  dispatch,
  _,
) => {
  await dispatch(setLoading(true));
  await dispatch(deleteCriticalItems(deleteCriticalItem));
  await dispatch(getUpdatedOrderTabOpen());
  await dispatch(setItemToRemove(null));
  await dispatch(setShowRemoveItemModal(false));
  await dispatch(setLoading(false));

  return;
};
