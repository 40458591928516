import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { AppPath } from '@app/modules/app/route-constants';
import { ISidebarFiltersStrings, Strings } from '@app/modules/components/side-bar-filters/sidebar-filters.strings';
import { setOpenFinanceSidebarFilters, setOpenSidebarFilters } from '@app/providers/navigation/navigation.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { CloseIcon, CloseIconWrapper, FilterTitleWrapper, TitleLabel } from './sidebar-title.style';

export const SidebarTitle: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ISidebarFiltersStrings = Strings[userInfo.language];
  const dispatch = useDispatch();
  const history = useHistory();

  const onClose = () => {
    if (history.location.pathname === AppPath.FINANCE) {
      dispatch(setOpenFinanceSidebarFilters(false));
    } else {
      dispatch(setOpenSidebarFilters(false));
    }
  };

  return (
    <FilterTitleWrapper>
      <FlexRow vAlign='center'>
        <FaIcon.Filter color={Color.Primary} size='sm' />
        <TitleLabel>{strings.sideBarTitle}</TitleLabel>
      </FlexRow>
      <CloseIconWrapper onClick={onClose}>
        <CloseIcon vAlign='center' />
      </CloseIconWrapper>
    </FilterTitleWrapper>
  );
};
