import * as React from 'react';
import { H2 } from '@atomic/atm.typography';
import { Col, Row } from '@atomic/obj.grid';
import { Strings, IDashBoardStrings } from './dashboard.string';
import { FinanceMatchedMatchesGraph } from './finance/finance-matched-matches-graph';
import { FinanceOpenMatchesGraph } from './finance/finance-open-matches';
import { DashboardProps } from '.';

export const FinanceDashboard: React.FC<DashboardProps> = props => {
  const strings: IDashBoardStrings = Strings.pt;

  return (
    <div key={props.screenWidth}>
      <H2 center>{strings.finance.title}</H2>
      <Row>
        <Col xs={12} md={6}>
          <FinanceMatchedMatchesGraph clientNumber={props.clientNumber} />
        </Col>
        <Col xs={12} md={6}>
          <FinanceOpenMatchesGraph clientNumber={props.clientNumber} />
        </Col>
      </Row>
    </div>
  );
};
