import * as React from 'react';
import { PageDisabledStyled, PageStyled, PaginationStyled } from './pagination.component.style';

export interface PageItemProps {
  kind?: string;
  active: boolean;
  first: boolean;
  last: boolean;
  children: any;
  onClick: any;
}

const SEPARATOR = '...';

const PaginationItem = (props: PageItemProps) => {
  const { first, last, active } = props;

  const onClick = (_page: number) => {
    props.onClick(parseInt(props.children, 0));
  };

  if (props.children === SEPARATOR) {
    return <PageDisabledStyled>{props.children}</PageDisabledStyled>;
  } else {
    return (
      <PageStyled kind='primary' outlined onClick={onClick} first={first} last={last} active={active}>
        {props.children}
      </PageStyled>
    );
  }
};

export interface PaginationProps {
  current: number;
  total: number;
  window: number;
  onPageChange: any;
}
export const Pagination = (props: PaginationProps) => {
  const pagesArray: string[] = [];
  const halfWindow = Math.round(props.window / 2);

  const startPage = props.current - halfWindow > 0 ? props.current - halfWindow : 1;
  const endPage = props.current + halfWindow > props.total ? props.total : props.current + halfWindow;

  if (startPage > 1) {
    pagesArray.push('1');
  }
  if (startPage + 1 > 2) {
    pagesArray.push(SEPARATOR);
  }

  for (let page = startPage; page <= endPage && page <= props.total; page++) {
    pagesArray.push(page.toString());
  }
  if (props.total - 1 > endPage) {
    pagesArray.push(SEPARATOR);
  }
  if (endPage < props.total) {
    pagesArray.push(props.total.toString());
  }

  return (
    <PaginationStyled>
      {pagesArray.map((val: string, index: number) => (
        <PaginationItem
          first={index === 0 || pagesArray[index - 1] === SEPARATOR}
          last={val === props.total.toString() || pagesArray[index + 1] === SEPARATOR}
          key={'pag' + index}
          active={val === props.current.toString()}
          onClick={props.onPageChange}
        >
          {val}
        </PaginationItem>
      ))}
    </PaginationStyled>
  );
};
