import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
import {
  CommentIconWrapperStyled,
  DefaultListCellAngleStyled,
  DefaultListCellCloseStyled,
  DefaultListCellIconWrapperStyled,
  DefaultListCellStyled,
  DefaultListCellStyledProps,
} from './default-list-cell.component.style';

export interface DefaultListCellProps extends DefaultListCellStyledProps {
  iconName?: string[] | string;
  iconColor?: string;
  arrow?: boolean;
  close?: boolean;
  hasComments?: number;
  onClose?: () => void;
  onClick?: () => void;
  list?: string;
  canEdit?: boolean;
}

export const DefaultListCell: React.FC<DefaultListCellProps> = props => (
  <DefaultListCellStyled
    hasClick={!!props.onClick}
    selected={props.selected}
    hover={props.hover}
    onClick={props.onClick}
    bg={props.bg}
    list={props.list}
  >
    {props.iconName && (
      <DefaultListCellIconWrapperStyled bgColor={props.iconColor}>
        <FaIcon.Custom color={props.iconColor} size='1x' icon={props.iconName} />
      </DefaultListCellIconWrapperStyled>
    )}

    {props.children}
    {props.hasComments && props.hasComments > 0 ? (
      <CommentIconWrapperStyled>
        <FaIcon.Comments color={Color.Alert} size='1x' />
      </CommentIconWrapperStyled>
    ) : (
      ''
    )}

    {props.arrow && <DefaultListCellAngleStyled />}
    {props.close && <DefaultListCellCloseStyled clickable color={Color.GrayDark} size='1x' onClick={props.onClose} />}
  </DefaultListCellStyled>
);
