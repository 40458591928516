import { HelpCenterSubsectionKeys, HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterFinancesSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Finanças',
      key: HelpCenterSectionKeys.Finances,
      subsections: [
        {
          title: 'Dicionário',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.FinanceGlossary,
          informationBlocks: [
            {
              blockTitle: 'Entenda melhor os termos usados nas tabelas do Gerdau Mais',
              table: {
                columns: ['Nome da coluna', 'Conceito'],
                rows: [
                  ['Chave de acesso', 'Chave de acesso referente a Nota Fiscal.'],
                  ['Data faturamento', 'Data da emissão da Nota Fiscal.'],
                  ['Nº nota fiscal', 'Número da Nota Fiscal.'],
                  ['Status pagamento', 'Status do Pagamento (Pago / A vencer / Vencido)'],
                  ['Valor total', 'Valor financeiro da Nota Fiscal.'],
                  ['Vencimento', 'Data de vencimento da fatura.'],
                ],
              },
            },
          ],
        },
        {
          title: 'Recomendações de segurança',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.FinanceSecurity,
          informationBlocks: [
            {
              blockTitle: 'Recomendação de segurança para boletos',
              media: {
                path:
                  'assets/img/help-center/finance/security/anti-fraude-1.png, assets/img/help-center/finance/security/anti-fraude-2.png',
                type: 'double-image',
              },
            },
            {
              blockTitle: '',
              media: {
                path:
                  'assets/img/help-center/finance/security/anti-fraude-3.png, assets/img/help-center/finance/security/anti-fraude-4.png',
                type: 'double-image',
              },
              action: 'download',
            },
          ],
        },
      ],
    }
  );
};
