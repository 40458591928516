import { useSelector } from 'react-redux';
import Container from 'typedi';
import { RootState } from '@app/core/redux/store';
import { RouteGuard } from '@app/core/route';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { MarketType } from '@app/providers';

const user = Container.get(AuthStorageService).getUser();

export const useAuthGuard: RouteGuard = (): boolean => {
  return !!user?.uid;
};

export const useInternalMarket: RouteGuard = (): boolean => {
  const { currentMarket } = useSelector((state: RootState) => state.customerPersist);
  return currentMarket === MarketType.Internal;
};
