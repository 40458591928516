import React from 'react';
import { useDispatch } from 'react-redux';
import { GridButtonWithIcon } from '@app/modules/components/grid-button-with-icon.component';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { setOpenConfigColumnSpreadsheet } from '@app/modules/order-input/store/order-input.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { OrderInputTemplateDownload } from '../../../../../components/order-input-template-download/order-input-template-download';

const Strings = OrderInputStrings.pt.selectItens;

export const OrderInputUploadFileButtons: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <FlexColumn vAlign='flex-start' ml={Spacing.Large}>
      <FlexRow hAlign='flex-end'>
        <GridButtonWithIcon
          text={Strings.configureWorksheet}
          icon={() => <FaIcon.Cog color={Color.White} />}
          kind='primary'
          onClick={() => dispatch(setOpenConfigColumnSpreadsheet(true))}
        />
      </FlexRow>
      <FlexRow mt={Spacing.Medium} hAlign='flex-end'>
        <OrderInputTemplateDownload />
      </FlexRow>
    </FlexColumn>
  );
};
