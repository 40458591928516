import React from 'react';
import { useStore } from '@app/core/global-store.service';
import { UserStore, useUserStore } from '@app/providers';
import { Image } from '@atomic/atm.image';
import { Body, H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { InpsModalStrings, Strings } from '../../../nps.string';

export const NpsModalSucess: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const strings: InpsModalStrings = Strings[user.language];

  return (
    <>
      <H2>{strings.successMessage} </H2>
      <Body pt>{strings.successSubMessage}</Body>
      <FlexRow pt pb>
        <Image.SuccessLarge />
      </FlexRow>
    </>
  );
};
