export const CHECKBOX_SELECTION_COLUMN = 'checkboxSelection';
export const ACTIVATE_CRITICAL_COLUMN = 'activateCritical';
export const BOOLEAN_COLUMNS = ['activateCritical', 'activateNotificationCol', 'activateShipping'];
export const DELIVERY_STATUS_COLUMN = 'deliveryStatus';
export const SIZE_IN_COLUMN = 'sizeIn';
export const QTY_ORDER_COLUMNS = [
  'qtdSolClient',
  'qtyTotalBilled',
  'qtyBillingOpen',
  'qtyDelivery',
  'qtyStock',
  'qtyTotalProduction',
  'qtdConf',
];
export const QTY_STOCK_COLUMNS = [
  'agreedQuantity',
  'deliveryQuantity',
  'pickingQuantity',
  'stockQuantity',
  'qtyCustomerRequiredTons',
];
export const QTY_BILLED_COLUMNS = ['docQtdT', 'qtdSolClient'];
export const OPEN_MI_DATE_COLUMNS = [
  'dateSolClient',
  'dateDeliveryClient',
  'dateMaxDeliveryClient',
  'dtConfirmedStock',
  'dtCustomerRequiredOriginal',
  'dtPurchase',
];

export const OPEN_ME_DATE_COLUMNS = [
  'dtPurchase',
  'dtCustomerRequired',
  'dtEstimateStock',
  'dtEstimateBorder',
  'dtCustomerConfirmed',
];
export const BILLED_MI_DATE_COLUMNS = ['dateDeliveryClient', 'invoiceDate', 'dateOfDeparture', 'dateSolClient'];
export const FRONTIER_ME_DATE_COLUMNS = ['dtPurchase', 'dtCustomerRequired', 'dtCustomerConfirmed', 'nfDate'];
export const SHIPPED_ME_DATE_COLUMNS = [
  'dtPurchase',
  'approvementDate',
  'dtCustomerRequired',
  'dtCustomerConfirmed',
  'nfDate',
];
export const STOCK_MI_DATE_COLUMNS = ['customerRequiredDate', 'customerAgreedDate', 'dateOfStockEntry'];
export const FINANCE_MI_DATE_COLUMNS = ['launchDate', 'experyDate'];
export const FINANCE_ME_DATE_COLUMNS = ['shipmentDate', 'experyDate'];
export const OPEN_ME_MARITIME_DATE_COLUMNS = [
  'dtPurchase',
  'dtCustomerRequired',
  'dtEstimateStock',
  'dtEstimatedAtCustomer',
  'dtConfirmedStock',
];
export const SHIPPED_ME_MARITIME_DATE_COLUMNS = [
  'shipmentDate',
  'dtPurchase',
  'dtCustomerRequired',
  'nfDate',
  'locationDestinationDate',
];
export const HARBOR_ME_DATE_COLUMNS = ['dtPurchase', 'dtCustomerRequired', 'nfDate', 'etd', 'eta', 'etc'];
export const XLSX_MIME_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const FILE_NAME_PREFIX = 'Gerdau Mais';
export const XLSX_FILE_EXTENSION = 'xlsx';
export const WORKBOOK_TYPE = 'array';
export const DEFAULT_TRUE_VALUE = 'X';
export const AMOUNT_COLUMN = 'amount';
export const AMOUNT_BRANCH_COLUMN = 'amountBranch';
export const FINANCE_STATUS_COMPENSATION_COLUMN = 'statusCompensation';
export const FINANCE_STATUS_COLUMN = 'status';
export const BRL_CURRENCY_TAG = 'BRL';
export const USD_CURRENCY_TAG = 'USD';
