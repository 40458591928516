import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import { AppThunkDispatch } from '@app/core/redux/store';
import { setSelectedRows } from '@app/modules/order/order-tabs/MI/tab-open/store/order-tab-open.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { FlexColumn, Separator } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { IOrderPriorizationStrings, Strings } from '../../order-priorization.string';
import { RESET_ORDER_PRIORIZATION_ACTION } from '../../store/order-priorization.reducers';
import { CaptionMessage, OrderPriorizationSuccessWrapper, SuccessMessage } from './order-priorization-success.style';

interface OrderPriorizationSuccessProps {
  gridEvent?: MutableRefObject<GridReadyEvent | null>;
}

export const OrderPriorizationSuccess: React.FC<OrderPriorizationSuccessProps> = ({ gridEvent }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings: IOrderPriorizationStrings = Strings.pt;

  return (
    <>
      <OrderPriorizationSuccessWrapper>
        <Frame>
          <FlexColumn fullWidth pt={Spacing.XXLarge} pb={Spacing.XXLarge} mr hAlign='center'>
            <FaIcon.Check size='5x' color={Color.SuccessMain} />
            <Separator small />
            <SuccessMessage>{strings.successPage.title}</SuccessMessage>
            <Separator small />
            <>
              <CaptionMessage medium textAlign='center'>
                {strings.successPage.message} <b>{strings.successPage.boldMessage}</b>
              </CaptionMessage>
            </>
            <FlexColumn pb={Spacing.XXLarge} width={{ width: '272px' }} hAlign='center' mt>
              <Button
                expanded
                onClick={() => {
                  gridEvent?.current?.api?.deselectAll();
                  dispatch(setSelectedRows([]));
                  dispatch(RESET_ORDER_PRIORIZATION_ACTION);
                }}
              >
                {strings.successPage.backBtn}
              </Button>
              <Separator small />
              <Separator small />
            </FlexColumn>
          </FlexColumn>
        </Frame>
      </OrderPriorizationSuccessWrapper>
    </>
  );
};
