import React from 'react';
import { useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { ShippingRequestEvents } from '@app/modules/order/order.analytics';
import { IShippingRequestRow } from '@app/modules/shipping-request/shipping-request.interfaces';
import { Strings } from '@app/modules/shipping-request/shipping-request.string';
import { deleteItem, removeAlert } from '@app/modules/shipping-request/store/shipping-request.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { TD, TR } from '@atomic/mol.table';
import { FlexRow } from '@atomic/obj.box';
import { BrandColor, Color, Spacing } from '@atomic/obj.constants';

interface IShippingRequestWarningTRProps {
  item: IShippingRequestRow;
}

export const ShippingRequestWarningTR: React.FC<IShippingRequestWarningTRProps> = ({ item }) => {
  const strings = Strings.pt.page;
  const dispatch = useDispatch();

  const onClickIgnore = () => {
    dispatch(removeAlert({ heat: item?.heat, salesOrder: item?.salesOrder, value: true }));

    LogAnalytics.click({
      tipo: ShippingRequestEvents.IgnoreAlertClick,
    });
  };

  const onClickDeleteItem = () => {
    dispatch(deleteItem({ heat: item?.heat, salesOrder: item?.salesOrder, material: item?.material }));

    LogAnalytics.click({
      tipo: ShippingRequestEvents.DeleteItemClick,
    });
  };

  return (
    <TR key={`alert-${item.index}`} color={Color.Yellow}>
      <TD colSpan={12}>
        <FlexRow fullWidth vAlign='center' hAlign='center'>
          <FaIcon.Warning size='sm' color={BrandColor.Yellow} />
          <Body ml={Spacing.Small} mr={Spacing.Small}>
            {strings.trSequentialWarning}
          </Body>
          <FlexRow mr={Spacing.Small}>
            <Button size='small' kind='secondary' onClick={onClickIgnore}>
              {strings.trIgnoreButton}
            </Button>
          </FlexRow>
          <FlexRow>
            <Button size='small' kind='alertSecondary' onClick={onClickDeleteItem}>
              {strings.trDeleteItemButton}
            </Button>
          </FlexRow>
        </FlexRow>
      </TD>
    </TR>
  );
};
