export interface HelpCenterContentModel {
  sections: HelpCenterSectionModel[];
}

export interface HelpCenterSectionModel {
  title: string;
  key: string;
  subsections: SubsectionData[];
  internalLink?: string;
  target?: string;
}

export type MediaType = 'video' | 'image' | 'stretch-image' | 'centered-image' | 'double-image';

export enum HelpCenterSectionKeys {
  Orders = 'orders',
  Glossary = 'glossary',
  Admin = 'admin',
  Quotation = 'quotation',
  Dashboard = 'dashboard',
  OpenOrders = 'openOrders',
  StockOrders = 'stockOrders',
  BilledOrders = 'billedOrders',
  Finances = 'finances',
  Implantation = 'implantation',
  Documents = '/documents',
  Complain = 'complain',
}

export enum HelpCenterSubsectionKeys {
  OpenOrdersGlossary = 'openOrdersGlossary',
  StockOrdersGlossary = 'stockOrdersGlossary',
  BilledOrdersGlossary = 'billedOrdersGlossary',
  FinanceGlossary = 'financeGlossary',
  FinanceSecurity = 'financeSecurity',
  AdminAddUser = 'adminAddUser',
  AdminEditUser = 'adminEditUser',
  AdminDeleteUser = 'adminDeleteUser',
  IntrodutionQuotation = 'introdutionQuotation',
  NewQuotation = 'newQuotation',
  FlowQuotation = 'flowQuotation',
  TruckTracking = 'truckTracking',
  RemittanceGenerationRequest = 'RemittanceGenerationRequest',
  SaveLayout = 'saveLayout',
  ScheduleShipmentRequest = 'scheduleShipmentRequest',
  OpenOrdersDashboard = 'openOrdersDashboard',
  ConfirmedOrderDashboard = 'confirmedOrderDashboard',
  StockOrderDashboard = 'stockOrderDashboard',
  InvoicingDashboard = 'invoicingDashboard',
  Financas = 'financas',
  OrderUpdate = 'orderUpdate',
  OrderNotification = 'notificacoesDePedidos',
  ViaItenSelection = 'viaItemSelection',
  ViaSpreadSheet = 'viaSpreadSheet',
  ViaHistory = 'viaHistory',
  Documents = '/documents',
  StockRealDate = 'stockRealDate',
  OrderStatus = 'orderStatus',
  AutomaticReportSubmission = 'automaticReportSubmission',
  AdvancedFilters = 'advancedFilters',
  CriticalItems = 'criticalItems',
}

export interface HelpCenterPageState {
  section?: HelpCenterSectionKeys;
  subsection?: HelpCenterSubsectionKeys;
}

export interface MediaItem {
  path: string;
  type: MediaType;
  description?: string;
}

export interface WelcomeData {
  title: string;
  paragraph: string;
}

export interface SubsectionData {
  title: string;
  alternativeTitle?: string;
  key: string;
  description?: string;
  informationBlocks?: InformationBlock[];
  hasComingSoonTag?: boolean;
}

export interface InformationBlock {
  blockTitle?: string;
  paragraph?: string;
  paragraphBold?: string;
  paragraphList?: string[];
  paragraphHtml?: string;
  media?: MediaItem;
  table?: InformationBlockTable;
  action?: string;
}

export interface InformationBlockTable {
  columns: string[];
  rows: string[][];
}

export interface FeedbackData {
  text: string;
  afirmative: string;
  negative: string;
}
