import * as React from 'react';
import { AttachedFilesDataProps } from '@app/models/crm.model';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { CrmStrings } from '../../crm.string';
import { useDownloadAttachedFile } from '../hooks/useDownloadAttachedFile';
import {
  AttachedWrapperLink,
  CrmContentHeaderWrapper,
  CrmContentInfosWrapper,
  CrmContentWrapper,
} from './crmContent.style';

interface CrmAttachedFilesProps {
  data: AttachedFilesDataProps;
  loading: boolean;
}

export const CrmAttachedFiles = (props: CrmAttachedFilesProps) => {
  const strings = CrmStrings.pt;

  const { mutate: handleDownloadAttached } = useDownloadAttachedFile();

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.ATTACHED_FILES} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>

      {props?.data && (
        <CrmContentInfosWrapper>
          <Separator small />
          {props.data.infos.length > 0 && (
            <Table collapse>
              <TR bordered>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.partnerRole}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.fileName}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.typeFile}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.createdBy}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.createdAt}</Body>
                </TD>
              </TR>
              {props.data.infos.length > 0 &&
                props.data.infos.map((item, index) => (
                  <TR bordered key={`a-${index}`}>
                    <TD>
                      <Body>{item.value[0].partnerRole}</Body>
                    </TD>
                    <TD>
                      <Body>
                        <AttachedWrapperLink
                          onClick={() =>
                            handleDownloadAttached({
                              filename: item.value[0].fileName,
                              url: item.value[0].link,
                              type: item.value[0].typeFile,
                            })
                          }
                          rel='noreferrer'
                        >
                          {item.value[0].fileName}
                        </AttachedWrapperLink>
                      </Body>
                    </TD>
                    <TD>
                      <Body>{item.value[0].typeFile}</Body>
                    </TD>
                    <TD>
                      <Body>{item.value[0].createdBy}</Body>
                    </TD>
                    <TD>
                      <Body>{item.value[0].createdAt}</Body>
                    </TD>
                  </TR>
                ))}
            </Table>
          )}
        </CrmContentInfosWrapper>
      )}
    </CrmContentWrapper>
  );
};
