import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TextField } from '@atomic/atm.text-field';
import { H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import {
  CustomerSearchFieldClientWrapper,
  CustomerSearchModalHeaderClientWrapper,
  SelectSomeClientMessage,
} from '../customer-search-modal-client.style';
import { ICustomerSearchModalHeaderClientStrings, Strings } from './customer-search-modal-header-client.string';

interface CustomerSearchModalHeaderClientProps {
  textSearch: string;
  setTextSearch: (value: string) => void;
}

export const CustomerSearchModalHeaderClient: React.FC<CustomerSearchModalHeaderClientProps> = ({
  textSearch,
  setTextSearch,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ICustomerSearchModalHeaderClientStrings = Strings[userInfo.language];

  const { clientBpsSearch } = useSelector((state: RootState) => state.customerSearch);
  const someBpSelected = clientBpsSearch.find(bp => !!bp.selected);

  return (
    <CustomerSearchModalHeaderClientWrapper>
      <H2>{strings.titleClient}</H2>
      <FlexRow vAlign='center' hAlign='flex-start' fullWidth>
        <CustomerSearchFieldClientWrapper>
          <TextField
            value={textSearch}
            onValueChange={value => setTextSearch(value.toString())}
            placeholder={`${strings.placeholder}`}
            hasButton
          ></TextField>
        </CustomerSearchFieldClientWrapper>
      </FlexRow>
      {!someBpSelected && <SelectSomeClientMessage>{strings.validationMessage}</SelectSomeClientMessage>}
    </CustomerSearchModalHeaderClientWrapper>
  );
};
