import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { ISidebarFiltersStrings, Strings } from '@app/modules/components/side-bar-filters/sidebar-filters.strings';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { setExpiresFrom, setExpiresTo } from '../../store/surrogate.store';
import { SurrogateDatePickerCalendar } from './surrogate-date-picker-calendar.component';
import { DatePickerLabel } from './surrogate-date-picker.style';

export const DatePickerComponent: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { isIndeterminateTime, expiresFrom, expiresTo } = useSelector((state: RootState) => state.surrogate);
  const strings: ISidebarFiltersStrings = Strings[userInfo.language];

  const dispatch = useDispatch();

  const handleExpiresFrom = (date: string) => {
    const dateAux = new Date(date).toISOString().split('T')[0];
    const resultDate = `${dateAux}T00:00:00`;
    dispatch(setExpiresFrom(resultDate));
  };

  const handleExpiresTo = (date: string) => {
    const dateAux = new Date(date).toISOString().split('T')[0];
    const resultDate = `${dateAux}T20:59:59`;
    dispatch(setExpiresTo(resultDate));
  };

  useEffect(() => {
    if (expiresFrom && new Date(expiresFrom).getTime() > new Date(expiresTo).getTime()) {
      handleExpiresTo(expiresFrom);
    }
  }, [expiresFrom]);

  return (
    <FlexRow mt>
      <FlexRow mb vAlign='center'>
        <DatePickerLabel>{strings.from}</DatePickerLabel>
        <SurrogateDatePickerCalendar
          minDate={new Date()}
          selectedDate={expiresFrom ? new Date(expiresFrom) : null}
          onValueChange={date => handleExpiresFrom(date)}
          isDisabled={isIndeterminateTime}
        />
      </FlexRow>
      <FlexRow mb={Spacing.Medium} ml vAlign='center'>
        <DatePickerLabel>{strings.to}</DatePickerLabel>
        <SurrogateDatePickerCalendar
          minDate={expiresFrom ? new Date(expiresFrom) : new Date()}
          selectedDate={expiresTo ? new Date(expiresTo) : null}
          onValueChange={date => handleExpiresTo(date)}
          isDisabled={isIndeterminateTime}
        />
      </FlexRow>
    </FlexRow>
  );
};
