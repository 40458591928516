import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Divisor } from '@atomic/atm.divisor';
import { Frame } from '@atomic/atm.frame';
import { H3 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { MaterialForm } from '../../components/material-form/material-form';
import { MaterialOptionsSelected } from '../../components/material-options-selected/meterial-options-selected';
import { OrderInputStrings } from '../../order-input.string';
import { setQuery, setSelectedIssuer, setSelectedMaterials } from '../../store/order-input.store';
import { OrderInputSelectItensBaseboard } from './order-input-select-itens-baseboard';
import {
  MaterialFormWrapper,
  MaterialOptionsSelectedWrapper,
  OrderInputSelectItensGrid,
  OrderInputSelectItensWrapper,
} from './order-input-select-itens.style';
import { ReceiverForm } from './receiver-form/receiver-form';

const Strings = OrderInputStrings.pt.selectItens;

export const OrderInputSelectItens: React.FC = () => {
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    dispatch(setQuery(''));
    dispatch(setSelectedMaterials([]));
    dispatch(setSelectedIssuer(null));
  }, [clientBpsSelected]);

  return (
    <OrderInputSelectItensWrapper data-testid='order-input-select-itens'>
      <OrderInputSelectItensGrid fluid>
        <Frame>
          <FlexColumn pt pl>
            <H3>{Strings.title}</H3>
            <FlexColumn fullWidth mt pb>
              <ReceiverForm />
              <Divisor />
              <Form>
                <FlexRow mt>
                  <MaterialFormWrapper>
                    <MaterialForm />
                  </MaterialFormWrapper>
                  <MaterialOptionsSelectedWrapper>
                    <MaterialOptionsSelected />
                  </MaterialOptionsSelectedWrapper>
                </FlexRow>
              </Form>
            </FlexColumn>
          </FlexColumn>
        </Frame>
      </OrderInputSelectItensGrid>
      <OrderInputSelectItensBaseboard />
    </OrderInputSelectItensWrapper>
  );
};
