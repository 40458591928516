import * as React from 'react';
import { DashboardGraphName, DashboardLegendData } from '@app/models/dashboard.model';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
interface LegendProps {
  legendData: DashboardLegendData;
  graph: string;
}

export const DashboardLegend: React.FC<LegendProps> = props => {
  const renderAmountLegend = () => {
    if (props.legendData.hasOwnProperty('amount') && props.legendData.amount !== undefined) {
      return <H3>{props.legendData.amount + props.legendData.unit}</H3>;
    } else {
      return null;
    }
  };

  const renderCurrencyLegend = () => {
    if (props.legendData.amount !== undefined && props.legendData.hasOwnProperty('currency')) {
      return <H3>{props.legendData.currency + ' ' + props.legendData.amount}</H3>;
    } else {
      return <H3>R$ 0</H3>;
    }
  };

  const renderSimpleLegend = () => {
    if (props.legendData.amount !== undefined && !props.legendData.hasOwnProperty('currency')) {
      return <H3>{props.legendData.amount}</H3>;
    } else {
      return null;
    }
  };

  const renderNoValueLegend = () => {
    return null;
  };

  const handleLegend = () => {
    switch (props.graph) {
      case DashboardGraphName.MonthlyDeliveryForecastGraph:
        return renderAmountLegend();
      case DashboardGraphName.ConfirmedDeliveryGraph:
        return renderAmountLegend();
      case DashboardGraphName.StockStepGraph:
        return renderAmountLegend();
      case DashboardGraphName.StockStatusGraph:
        return renderAmountLegend();
      case DashboardGraphName.MonthlyFinanceMatchedMatchesGraph:
        return renderSimpleLegend();
      case DashboardGraphName.OpenMatchesGraph:
        return renderCurrencyLegend();
      case DashboardGraphName.OpenMatchesDocumentGraph:
        return renderSimpleLegend();
      case DashboardGraphName.requestedVsConfirmedOrderGraph:
        return renderNoValueLegend();
      default:
        break;
    }
  };

  return (
    <>
      <Hbox>
        {props.legendData.iconColor && (
          <>
            <Hbox.Item noGrow vAlign='center'>
              <FaIcon.Circle size='sm' color={props.legendData.iconColor} />
            </Hbox.Item>
            <Hbox.Separator small />
          </>
        )}
        <Hbox.Item vAlign='center'>
          <Body>{props.legendData.title}</Body>
        </Hbox.Item>
      </Hbox>
      <VSeparator small />
      {handleLegend()}
    </>
  );
};
