import ColorFunc from 'color';
import styled from 'styled-components';
import { Border, Color, Spacing, ZIndex, FontFamily, FontSize, FontWeight } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
import { FlashMessageProps } from './flash-message.component';

const flashMessageTypes = {
  warning: Color.Warning,
  success: Color.Success,
  info: Color.Accessory,
  alert: Color.Alert,
};

export const FlashMessageContentStyled = styled.div`
  flex-grow: 99;
  margin: 0 ${Spacing.Small};
`;

export const FlashMessageCloseStyled = styled.div`
  ${highlightStyle}

  &:hover > span {
    color: ${(props: FlashMessageProps) =>
      ColorFunc(flashMessageTypes[props.type])
        .darken(0.5)
        .string()};
  }
`;

export const FlashMessageStyled = styled.div`
  background-color: ${(props: FlashMessageProps) =>
    ColorFunc(flashMessageTypes[props.type])
      .lighten(0.5)
      .string()};
  border-radius: ${Border.Radius};
  display: flex;
  padding: ${Spacing.Medium};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Small};
  transition: opacity 0.3s ease-in-out;

  p {
    color: ${Color.Black};
  }

  svg {
    color: ${(props: FlashMessageProps) =>
      ColorFunc(flashMessageTypes[props.type])
        .darken(0.3)
        .string()};
  }
`;

interface FlashMessageWrapperStyledProps {
  alignRight?: boolean;
  small?: boolean;
}

export const FlashMessageWrapperStyled = styled.div`
  position: fixed;
  z-index: ${ZIndex.FlashMessageWrapperStyled};
  align-items: center;
  margin: ${Spacing.Medium};
  right: ${(props: FlashMessageWrapperStyledProps) => (props.alignRight ? '50px' : 'unset')};
  width: ${(props: FlashMessageWrapperStyledProps) => (props.small ? '376px' : 'unset')};
`;
