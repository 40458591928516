import React from 'react';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { DaySelect } from '../components/day-select/day-select';
import { TimeSelect } from '../components/time-select/time-select';
import { MonthlyWrapper } from './monthy-schedule.style';

export const MonthySchedule: React.FC = () => {
  return (
    <FlexColumn vAlign='start'>
      <FlexRow mb={Spacing.Small} vAlign='start' hAlign='start'>
        <Body bold='bold'>{strings.monthlyinstructions}</Body>
      </FlexRow>
      <FlexRow>
        <MonthlyWrapper>
          <FlexRow pr>
            <DaySelect />
          </FlexRow>
          <FlexRow>
            <TimeSelect />
          </FlexRow>
        </MonthlyWrapper>
      </FlexRow>
    </FlexColumn>
  );
};
