import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { ISchedule } from '@app/models/schedule.model';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { setScheduleName } from '@app/modules/components/schedule-modal/store/schedule.store';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { ValidateDuplicateMessage } from './schedule-name.styke';

export const ScheduleName: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { scheduleName, schedules, scheduleId, currentTab } = useSelector((state: RootState) => state.schedule);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const scheduleSelected: ISchedule = schedules.find(schedule => schedule.id === scheduleId);
  const tabSchedules: ISchedule[] = useMemo(() => schedules.filter(schedule => schedule.user_type === currentTab), []);
  const scheduleNames: string[] = useMemo(() => tabSchedules.map(schedule => schedule.name), []);

  useEffect(() => {
    if (scheduleNames.filter(name => name !== scheduleSelected?.name).includes(scheduleName)) {
      setShowValidationMessage(true);
    } else {
      setShowValidationMessage(false);
    }
  }, [scheduleName]);

  const onChange = value => dispatch(setScheduleName(value));

  return (
    <FlexColumn>
      <FlexRow hAlign='space-between' mb={Spacing.XSmall}>
        <Body>{strings.labelName}</Body>
        {showValidationMessage && (
          <ValidateDuplicateMessage>{strings.validationDuplicateMessage}</ValidateDuplicateMessage>
        )}
      </FlexRow>
      <TextField placeholder={strings.placeholderName} value={scheduleName} onValueChange={onChange} />
    </FlexColumn>
  );
};
