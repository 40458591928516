import { createAsyncThunk } from '@reduxjs/toolkit';
import { TruckTrackingDataSource, TruckTrackingMapParams } from '@app/data/http/truck-tracking.datasource';
import { SLICE_NAME } from '../../truck-tracking.constants';

export const getTruckTrackingMap = createAsyncThunk(
  `${SLICE_NAME}/truckTrackingMap`,
  async (params: TruckTrackingMapParams, _) => {
    return TruckTrackingDataSource.getTruckTrackingMap(params)
      .then(resp => resp)
      .catch(err => err.message);
  },
);

export const addGetTruckTrackingMapReducers = builder => {
  builder.addCase(getTruckTrackingMap.pending, state => {
    state.loadingMap = true;
  });
  builder.addCase(getTruckTrackingMap.fulfilled, (state, action) => {
    state.map = action.payload.base64;
    state.loadingMap = false;
  });
  builder.addCase(getTruckTrackingMap.rejected, state => {
    state.loadingMap = false;
  });
};
