import styled from 'styled-components';
import { BrandColor, Color, FontFamily, Spacing } from '@atomic/obj.constants';

export const SelectedMaterialsWrapper = styled.div`
  font-family: ${FontFamily.Primary};
  display: flex;
  flex-direction: column;
  border: 1px solid ${Color.GrayLight};
  overflow-y: auto;
  width: calc(100vw - 860px);
  max-width: 600px;
  height: calc(100vh - 580px);
`;

export const OptionItensSelected = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.Small} 0 ${Spacing.Small} ${Spacing.Small};
  margin: ${Spacing.XSmall};
  color: ${Color.GrayDark};
  background-color: ${BrandColor.LightBlue};
  height: auto;
  max-height: 62px;
`;

export const OptionItensDescription = styled.div``;
