import { Toast } from '@gerdau/hefesto';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setToast } from './store/toast.store';
import { PADDING_TOP } from './toast.constants';
import { TOAST_TIME } from './utils/utils';

export const ToastMessage: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { show, text, variant, actionOneLabel } = useSelector((state: RootState) => state.toast);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setToast({ show: false }));
    }, TOAST_TIME);
  }, [show]);

  if (show) {
    return (
      <Toast
        text={text}
        variant={variant}
        spaceToPageTop={PADDING_TOP}
        actionOne={{
          label: actionOneLabel ? actionOneLabel : 'Fechar',
          onClick: () => {
            dispatch(setToast({ show: false }));
          },
        }}
        onClose={() => null}
      />
    );
  } else {
    return null;
  }
};
