import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteAttributeComplement, QuoteAttributeTypes } from '@app/models/quote.model';
import { Body, ButtonText, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';
import { ChartTable } from './chartTable.component';
import {
  ComplementariesHeaderWrapper,
  ComplementariesTableStyled,
  ComplementariesWrapper,
} from './complementaries.component.style';

interface ComplementariesProps {
  handleComplementariesClick: () => void;
  attributeTitle: string;
  complementaries: QuoteAttributeComplement[];
}

export const Complementaries: React.FC<ComplementariesProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell;

  return (
    <ComplementariesWrapper open={true}>
      <ComplementariesHeaderWrapper onClick={props.handleComplementariesClick}>
        <ButtonText>{strings.additionalInformation}</ButtonText>
      </ComplementariesHeaderWrapper>
      {props.complementaries?.map(complement =>
        complement.type === 'chart_table' ? (
          Object.values(complement.chartValue).map(
            (chart, index) =>
              chart?.chart && (
                <React.Fragment key={`${complement.key}-${index}`}>
                  <H3>
                    {complement.label} {chart?.label && ` - ${chart.label}`}
                  </H3>
                  <VSeparator />
                  <ChartTable chart={chart?.chart} table={chart?.table} />
                </React.Fragment>
              ),
          )
        ) : (
          <React.Fragment key={complement.key}>
            <H3>{complement.label}</H3>
            <VSeparator small />

            {complement.type === QuoteAttributeTypes.Table && (
              <ComplementariesTableStyled>{ComplementariesTable(complement)}</ComplementariesTableStyled>
            )}

            {complement.type === QuoteAttributeTypes.Text && <Body>{complement.textValue}</Body>}
            <VSeparator />
          </React.Fragment>
        ),
      )}
    </ComplementariesWrapper>
  );
};

const ComplementariesTable = (complement: QuoteAttributeComplement) => {
  const tableValue = complement?.tableValue;

  return (
    <Table collapse>
      <TR bordered sticky>
        {tableValue.columns.length > 0 &&
          tableValue.columns.map((column, index) => (
            <TD key={`${column?.key}-${index}`} color={index === 0 ? Color.Secondary : Color.White}>
              <Body>{column.label}</Body>
            </TD>
          ))}
      </TR>
      {tableValue.rows.length > 0 &&
        tableValue.rows.map((row, index3) => (
          <TR key={`tr-${index3}-${row.length}`} bordered sticky>
            {row.map((element, index4) => (
              <TD
                key={`${element?.key}-${index4}-${element.columnRef}`}
                color={index4 === 0 ? Color.White : Color.Transparent}
              >
                <Body>{element?.value}</Body>
              </TD>
            ))}
          </TR>
        ))}
    </Table>
  );
};
