import { Dispatch } from 'redux';
import { LogAnalytics } from '@app/core/analytics';
import { OrderUpdateEvents } from '../../order/order.analytics';
import { IOrderUpdateTableData } from '../order-update.interfaces';
import { RESET_ORDER_UPDATE_ACTION } from '../store/order-update.reducers';
import { setFinishModalOpened, setOpenUpdateFeedback, setShowOrderUpdate } from '../store/order-update.store';

interface IHandleSuccessConfiguredParams {
  successMessage: string;
  show: (type: string, message: string) => void;
  tableRows: IOrderUpdateTableData[];
  customerIds: string;
  dispatch: Dispatch;
}

export const handleSuccessConfigured = ({
  show,
  successMessage,
  tableRows,
  customerIds,
  dispatch,
}: IHandleSuccessConfiguredParams) => () => {
  dispatch(setFinishModalOpened(true));
  show('success', successMessage);
  tableRows.forEach(row => {
    LogAnalytics.success({
      tipo: OrderUpdateEvents.OrderUpdateSuccess,
      salesOrderDocuments: row.ov,
      salesOrderDocumentItem: row.itemOv,
      customerIds,
    });
  });

  dispatch(setOpenUpdateFeedback(true));
  dispatch(setShowOrderUpdate(false));
  dispatch(RESET_ORDER_UPDATE_ACTION);
};
