import styled from 'styled-components';
import { Label } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';

export const OrderInputBaseboardWrapper = styled.div`
  background-color: ${Color.White};
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 10;
`;

export const ItemAmountWrapper = styled(FlexColumn)`
  border-right: 1px solid ${Color.GrayLight};
`;

export const LabelAmount = styled(Label)`
  width: 180px;
  font-size: ${FontSize.Small};
`;

export const AdjustedTotalWrapper = styled(FlexColumn)`
  width: 250px;
  margin-left: ${Spacing.Medium};
`;
