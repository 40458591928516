export interface IAdminStrings {
  updating: string;
  title: string;
  filename: string;
  deliveryStatus: {
    delay: string;
    delayTrend: string;
    onTime: string;
    noForecast: string;
    notConfirmed: string;
    notAnalized: string;
  };
  status: {
    implanted: string;
    schedule: string;
    production: string;
    stored: string;
    confirmed: string;
  };
  error: string;
  tooltip: {
    billed: string;
    stock: string;
    delivery: string;
    production: string;
    notAllocated: string;
    requested: string;
    confirmed: string;
  };
  common: {
    filter: string;
    search: string;
    profileSelect: string;
    tabSelect: string;
    corpGroupSelect: string;
    salesOrgSelect: string;
    clientLabel: string;
    uid: string;
    edit: string;
    created: string;
    createdBy: string;
    lastLogin: string;
    bpNumber: string;
    permissionByTab: string;
    editUser: string;
    sendPasswordRequest: string;
    userQty: string;
    noResultsFound: string;
    npsGroup: string;
  };
  userView: {
    actions: {
      addButton: string;
      exportButton: string;
      exportFileName: string;
    };
    listUsersError: string;
    expotExcelError: string;
  };
  bpView: {
    actions: {
      exportButton: string;
      exportFileName: string;
    };
    listBPsError: string;
    expotExcelError: string;
  };
  actions: {
    homeBtn: string;
    saveBtn: string;
  };
  tabs: {
    users: string;
    bps: string;
    profiles: string;
    nps: string;
  };
  editView: {
    name: string;
    language: string;
    profile: string;
    permissions: string;
    addBp: string;
    lastUpdate: string;
    actions: {
      saveBtn: string;
      deleteBtn: string;
      cancelBtn: string;
      updateListBtn: string;
    };
    success: string;
    getUserError: string;
    createUserError: string;
    updateUserError: string;
  };
  profileView: {
    title: string;
    success: string;
    getProfilesError: string;
    updateProfileError: string;
  };
  sendResetPassword: {
    success: string;
  };
  toast: {
    success: string;
    error: string;
    close: string;
  };
  modal: {
    title: string;
    message: string;
    confirmBtn: string;
    cancelBtn: string;
  };
  deleteModal: {
    title: string;
    message: string;
    deleteBtn: string;
    cancelBtn: string;
    success: any;
    error: any;
  };
  nps: {
    groupLabel: string;
    running: string;
    scheduled: string;
    researchStartDate: string;
    researchEndDate: string;
    researchNextStartDate: string;
    groupClients: string;
    answered: string;
    visualized: string;
    notAnswered: string;
    statisticsGroupError: string;
    npsUserListError: string;
    elegible: string;
    notElegible: string;
    corpGroupLabel: string;
    statusLabel: string;
    lastAnswerLabel: string;
  };
}

interface IAdminLanguages {
  pt: IAdminStrings;
}

const pt: IAdminStrings = {
  updating: 'Atualizando',
  title: 'Em aberto',
  filename: 'Pedidos em aberto',
  deliveryStatus: {
    delay: 'Em atraso',
    delayTrend: 'Tendência de atraso',
    onTime: 'Dentro do prazo',
    noForecast: 'Sem previsão - Consultar Gerdau',
    notConfirmed: 'Pedido analisado e não confirmado',
    notAnalized: 'Pedido ainda não analisado',
  },
  status: {
    implanted: 'Implantado',
    schedule: 'Em Aprazamento',
    production: 'Em Produção',
    stored: 'Em Estoque',
    confirmed: 'Confirmado',
  },
  error: 'Ocorreu um erro ao carregar os dados da aba Em aberto! Por favor, tente novamente.',
  tooltip: {
    billed: 'Faturado',
    stock: 'Estoque',
    delivery: 'Remessa',
    production: 'Produção',
    notAllocated: 'Saldo',
    requested: 'Solicitado:',
    confirmed: 'Confirmado:',
  },
  common: {
    filter: 'Filtrar por',
    search: 'Buscar por',
    profileSelect: 'Selecione um ou mais perfis',
    tabSelect: 'Selecione uma ou mais abas',
    corpGroupSelect: 'Selecione um ou mais corporate group',
    salesOrgSelect: 'Selecione um ou mais organizações de venda',
    clientLabel: 'Cliente',
    uid: 'UID:',
    edit: 'Editar',
    created: 'Criado em:',
    createdBy: 'Criado por:',
    lastLogin: 'Último login:',
    bpNumber: 'Nº do BP:',
    permissionByTab: 'Permissionamentos por aba:',
    editUser: 'Editar usuário',
    sendPasswordRequest: 'Enviar reset de senha',
    userQty: 'Quantidade de usuários:',
    noResultsFound: 'Não foi encontrado nenhum registro para essa busca.',
    npsGroup: 'Grupo de NPS',
  },
  userView: {
    actions: {
      addButton: 'Adicionar usuário',
      exportButton: 'Exportar Excel',
      exportFileName: 'Usuários',
    },
    listUsersError: 'A lista de usuários não foi carregada por erro do sistema.',
    expotExcelError: 'Erro ao exportar usuários.',
  },
  bpView: {
    actions: {
      exportButton: 'Exportar Excel',
      exportFileName: 'BPs',
    },
    listBPsError: 'A lista de BPs não foi carregada por erro do sistema.',
    expotExcelError: 'Erro ao exportar usuários.',
  },
  actions: {
    homeBtn: 'Voltar para o Gerdau Mais',
    saveBtn: 'Salvar configurações',
  },
  tabs: {
    users: 'Usuários',
    bps: 'BPs',
    profiles: 'Perfis',
    nps: 'NPS',
  },
  editView: {
    name: 'Nome e sobrenome',
    language: 'Idioma de preferência',
    profile: 'Tipo de perfil',
    permissions: 'Permissionamentos',
    addBp: 'Adicionar BP',
    lastUpdate: 'Atualizado por último em:',
    actions: {
      saveBtn: 'Salvar configurações',
      deleteBtn: 'Deletar usuário',
      cancelBtn: 'Cancelar',
      updateListBtn: 'Atualizar lista de clientes',
    },
    success: 'Suas alterações foram salvas',
    getUserError: 'Erro ao carregar usuário.',
    createUserError: 'Erro ao criar usuário.',
    updateUserError: 'Suas alterações não foram salvas por erro no sistema.',
  },
  profileView: {
    title: 'Permissionamentos por abas dos perfis internos',
    success: 'Permissão alterada!',
    getProfilesError: 'Erro ao carregar os perfis',
    updateProfileError: 'Suas alterações não foram salvas por erro no sistema',
  },
  sendResetPassword: {
    success: 'Solicitação de reset de senha enviada.',
  },
  toast: {
    success: 'Permissão alterada!',
    error: 'Suas alterações não foram salvas por erro no sistema',
    close: 'Fechar',
  },
  modal: {
    title: 'Suas alterações ainda não foram salvas',
    message: 'Deseja salvar as alterações realizadas antes de sair da página?',
    confirmBtn: 'Salvar',
    cancelBtn: 'Sair sem salvar',
  },
  deleteModal: {
    title: 'Excluir usuário',
    message: 'Tem certeza que deseja excluir o usuário',
    deleteBtn: 'Deletar',
    cancelBtn: 'Cancelar',
    success: (name: string) => `O usuário ${name} foi excluído.`,
    error: (name: string) => `O usuário ${name} não foi excluído por erro no sistema.`,
  },
  nps: {
    groupLabel: 'Grupo de Feedback NPS',
    running: 'Pesquisa em andamento',
    scheduled: 'Pesquisa agendada',
    researchStartDate: 'Data de início da pesquisa: ',
    researchEndDate: 'Data de encerramento da pesquisa: ',
    researchNextStartDate: 'Data da próxima pesquisa: ',
    groupClients: 'Clientes no grupo: ',
    answered: 'Responderam: ',
    visualized: 'Somente visualizaram: ',
    notAnswered: 'Optaram por não responder: ',
    statisticsGroupError: 'As estatísticas do grupo foi carregada por erro do sistema.',
    npsUserListError: 'A lista de usuários NPS não foi carregada por erro do sistema.',
    elegible: 'Elegível',
    notElegible: 'Não elegível',
    corpGroupLabel: 'Corp. group: ',
    statusLabel: 'Status: ',
    lastAnswerLabel: 'Última resposta: ',
  },
};

export const AdminStrings: IAdminLanguages = {
  pt,
};
