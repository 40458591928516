import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Divisor } from '@atomic/atm.divisor';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H1 } from '@atomic/atm.typography';
import { Table, TH, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';
import { DiameterRow } from './modal-diameter-row.component';
import { DiameterRowWrapper } from './modal-diameter.component.style';
import { JominyContentWrapperStyled, LinkText } from './modal-jominy.component.style';

interface ModalDiameterProps {
  data: Element[];
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
}
export interface AdditionalDiameterData {
  ADDITIONAL_DIAMETER: any[];
  DIAMETER: string[];
  AMOUNT: string[];
  LENGTH: { min: string; max: string }[];
  THICKNESS: string[];
  WIDTH: string[];
  SIDE: string[];
}

interface AdditionalDiameterValues {
  label?: string;
  diameter?: number;
  side?: number;
  thickness?: number;
  width?: number;
  length?: string;
  min?: number;
  max?: number;
  amount?: number;
}

export const ModalDiameter: React.FC<ModalDiameterProps> = props => {
  const { setModalToOpen, formResult, setFormResult, additionalDiameters, setAdditionalDiameters } = React.useContext(
    FormContext,
  );
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].modalDiameter;
  //const [additionalDiameters, setAdditionalDiameters] = React.useState(initialAditionalDiameter());
  const [opened, setOpened] = React.useState(props.open);
  const [numBitolas, setNumBitolas] = React.useState(1);
  const contentRef = React.useRef(null);
  const noLength = ['WIRE_ROD', 'WIRE', 'INGOT'].includes(formResult?.PRODUCT?.questions?.fields?.PRODUCT?.value?.key);
  const product = formResult?.PRODUCT?.questions?.fields?.PRODUCT?.value?.key;
  const shape = product === 'INGOT' ? 'INGOT' : formResult.PRODUCT?.questions?.fields[`SHAPE_${product}`]?.value.key;

  const handleSubmit = async (formData: FormData<AdditionalDiameterData>) => {
    if (Object.keys(formData.error).length > 0) {
      return;
    }

    const attributesToReset = Object.keys(formResult.PRODUCT.questions?.fields).filter(
      item =>
        (item.includes('DIAMETER') ||
          item.includes('SIDE') ||
          item.includes('LENGTH') ||
          item.includes('WIDTH') ||
          item.includes('THICKNESS') ||
          item.includes('AMOUNT')) &&
        item !== 'LENGTH_TOLERANCE' &&
        item !== 'DIAMETER_TOLERANCE' &&
        item !== 'ADDITIONAL_DIAMETER' &&
        item !== 'ADDITIONAL_SIDE',
    );
    if (attributesToReset.length > 0) {
      attributesToReset.forEach((item: string) => {
        if (formResult.PRODUCT?.questions?.fields[item]?.value) {
          setFormResult(prevValue => ({
            ...prevValue,
            PRODUCT: {
              ...prevValue.PRODUCT,
              questions: {
                ...prevValue.PRODUCT.questions,
                fields: {
                  ...prevValue.PRODUCT.questions.fields,
                  [item]: {
                    ...prevValue.PRODUCT.questions.fields[item],
                    value: null,
                    display: false,
                  },
                },
              },
            },
          }));
        }
      });
    }

    const array = [];
    formData.data.ADDITIONAL_DIAMETER.forEach((item, index) => {
      array.push({
        label: item.label,
        amount: formData?.data?.AMOUNT ? formData?.data?.AMOUNT[index] : null,
        diameter: formData?.data?.DIAMETER ? formData?.data?.DIAMETER[index] : null,
        side: formData?.data?.SIDE ? formData?.data?.SIDE[index] : null,
        thickness: formData?.data?.THICKNESS ? formData?.data?.THICKNESS[index] : null,
        width: formData?.data?.WIDTH ? formData?.data?.WIDTH[index] : null,
        length: formData?.data?.LENGTH
          ? `${formData.data.LENGTH[index].min} a ${formData.data.LENGTH[index].max}`
          : null,
      });

      if (item.dimensionId === 'BITOLA_1') {
        if (item?.diameter) {
          props.submitModals('PRODUCT', 'DIAMETER', {
            value: item.diameter,
            readBy: 'user',
          });
        } else {
          props.submitModals('PRODUCT', 'SIDE', {
            value: item.side,
            readBy: 'user',
          });
        }
      }
    });

    setAdditionalDiameters(array);

    if (formData?.data?.DIAMETER) {
      formData.data.DIAMETER.forEach((item, index) => {
        if (index === 0) {
          props.submitModals('PRODUCT', 'DIAMETER', {
            value: item,
            readBy: 'user',
          });
        } else {
          props.submitModals('PRODUCT', `DIAMETER_${index + 1}`, {
            key: `DIAMETER_${index + 1}`,
            step: 'PRODUCT',
            label: `Bitola ${index + 1}`,
            type: 'table',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.DIAMETER.id,
            display: false,
            value: item,
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.DIAMETER.unit,
          });
        }
      });
    }

    if (formData?.data?.SIDE) {
      formData.data.SIDE.forEach((item, index) => {
        if (index === 0) {
          props.submitModals('PRODUCT', 'SIDE', {
            value: item,
            readBy: 'user',
          });
        } else {
          props.submitModals('PRODUCT', `SIDE_${index + 1}`, {
            key: `SIDE_${index + 1}`,
            step: 'PRODUCT',
            label: `Lado ${index + 1}`,
            type: 'table',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.SIDE.id,
            display: false,
            value: item,
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.SIDE.unit,
          });
        }
      });
    }

    if (formData?.data?.WIDTH) {
      formData.data.WIDTH.forEach((item, index) => {
        if (index === 0) {
          props.submitModals('PRODUCT', 'WIDTH', {
            value: item,
            readBy: 'user',
          });
        } else {
          props.submitModals('PRODUCT', `WIDTH_${index + 1}`, {
            key: `WIDTH_${index + 1}`,
            step: 'PRODUCT',
            label: `Largura ${index + 1}`,
            type: 'table',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.WIDTH.id,
            display: false,
            value: item,
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.WIDTH.unit,
          });
        }
      });
    }

    if (formData?.data?.LENGTH) {
      formData.data.LENGTH.forEach((item, index) => {
        if (index === 0) {
          props.submitModals('PRODUCT', 'LENGTH', {
            min: item.min,
            max: item.max,
            value: `${item.min} a ${item.max}`,
            readBy: 'user',
          });
        } else {
          props.submitModals('PRODUCT', `LENGTH_${index + 1}`, {
            key: `LENGTH_${index + 1}`,
            step: 'PRODUCT',
            label: `Comprimento ${index + 1}`,
            type: 'table',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.LENGTH.id,
            display: false,
            value: `${item.min} a ${item.max}`,
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.LENGTH.unit,
          });
        }
      });
    }

    if (formData?.data?.THICKNESS) {
      formData.data.THICKNESS.forEach((item, index) => {
        if (index === 0) {
          props.submitModals('PRODUCT', 'THICKNESS', {
            value: item,
            readBy: 'user',
          });
        } else {
          props.submitModals('PRODUCT', `THICKNESS_${index + 1}`, {
            key: `THICKNESS_${index + 1}`,
            step: 'PRODUCT',
            label: `Espessura ${index + 1}`,
            type: 'table',
            id: formResult.PRODUCT.questions.fields.THICKNESS.id,
            permissions: { visible: true, editable: true },
            display: false,
            value: item,
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.THICKNESS.unit,
          });
        }
      });
    }

    if (formData?.data?.AMOUNT) {
      formData.data.AMOUNT.forEach((item, index) => {
        if (index === 0) {
          props.submitModals('PRODUCT', 'AMOUNT', {
            value: item,
            readBy: 'user',
          });
        } else {
          props.submitModals('PRODUCT', `AMOUNT_${index + 1}`, {
            key: `AMOUNT_${index + 1}`,
            step: 'PRODUCT',
            label: `Consumo ${index + 1}`,
            type: 'table',
            permissions: { visible: true, editable: true },
            id: formResult.PRODUCT.questions.fields.AMOUNT.id,
            display: false,
            value: item,
            readBy: 'user',
            unit: formResult.PRODUCT.questions.fields.AMOUNT.unit,
          });
        }
      });
    }

    setModalToOpen(null);
    setOpened(false);
    setNumBitolas(1);
    setOpened(false);
  };

  const updateRow = (value, index, field) => {
    additionalDiameters[index][field] = value;
  };

  const handleRemove = (ind: number) => {
    const itemsLeft = additionalDiameters.filter((_, index) => index !== ind);

    itemsLeft.map((item, index) => (item.label = `Bitola ${index + 1}`));

    setAdditionalDiameters(itemsLeft);
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  const handleNewPointClick = () => {
    setNumBitolas(numBitolas);
    setAdditionalDiameters(prev => [
      ...prev,
      {
        label: `Bitola ${additionalDiameters.length + 1}`,
        amount: null,
        diameter: null,
        side: null,
        thickness: null,
        width: null,
        length: null,
      },
    ]);
  };

  return (
    <Modal preventOverlayClick medium opened={opened} onClose={() => handleClose()}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <H1>{strings.title}</H1>
              <VSeparator />
            </Col>
          </Row>
          <JominyContentWrapperStyled>
            <Row mt mb>
              <Col xs={12}>
                <DiameterRowWrapper>
                  <Table>
                    <TR>
                      <TH></TH>
                      <TH>{strings.bitola} (mm)</TH>
                      {!noLength && <TH>{strings.comprimento} (mm)</TH>}
                      <TH>
                        {strings.consumo} ({strings.consumoTon})
                      </TH>
                      <TH></TH>
                    </TR>

                    {additionalDiameters.map((item: AdditionalDiameterValues, index: number) => (
                      <DiameterRow
                        key={`bitola-${index}`}
                        strings={strings}
                        shape={shape}
                        noLength={noLength}
                        diameter={index === 0 ? formResult?.PRODUCT?.questions?.fields?.DIAMETER?.value : item.diameter}
                        side={index === 0 ? formResult?.PRODUCT?.questions?.fields?.SIDE?.value : item.side}
                        thickness={
                          index === 0 ? formResult?.PRODUCT?.questions?.fields?.THICKNESS?.value : item.thickness
                        }
                        width={index === 0 ? formResult?.PRODUCT?.questions?.fields?.WIDTH?.value : item.width}
                        length={index === 0 ? formResult?.PRODUCT?.questions?.fields?.LENGTH?.value : item.length}
                        amount={index === 0 ? formResult?.PRODUCT?.questions?.fields?.AMOUNT?.value : item.amount}
                        min={item.min}
                        max={item.max}
                        index={index}
                        handleRemove={handleRemove}
                        updateRow={updateRow}
                      />
                    ))}
                  </Table>
                </DiameterRowWrapper>
              </Col>
            </Row>
            <div ref={contentRef} />
          </JominyContentWrapperStyled>
          <Divisor />
          <VSeparator />
          <Hbox vAlign='center'>
            <Hbox.Item>
              <LinkText noGrow onClick={() => handleNewPointClick()}>
                <FaIcon.Plus size='1x' /> {strings.addMeasure}
              </LinkText>
            </Hbox.Item>

            <Hbox.Separator />
            <Hbox.Item noGrow>
              <Button kind='primary' expanded type='submit'>
                {strings.save}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};
