import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';

export const SidebarFilterReference = styled.div`
  position: relative;
  min-width: ${props => (props.active ? '300px' : '0px')};
  transition: min-width 0.5s;
`;

export const SidebarFilterWrapper = styled.div`
  width: 300px;
  position: absolute;
  top: 0;
  left: ${props => (props.active ? '0' : '-300px')};
  height: calc(100vh - 140px);
  border: 1px solid ${Color.GrayXLight};
  background-color: ${Color.White};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
`;
