import styled from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export const UpdateMaterialModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const UpdateMaterialModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-top: ${Spacing.Medium};
  padding-left: ${Spacing.Medium};
  padding-right: ${Spacing.Medium};

  div {
    font-size: ${FontSize.Small};
    font-family: ${FontFamily.Primary};
    font-weight: ${FontWeight.Normal};
  }
`;

export const UpdateMaterialModalFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-top: ${Spacing.Medium};
  padding-bottom: ${Spacing.Medium};
`;

export const UpdateMaterialModalInputStyled = styled.div`
  input {
    width: 100px;
    height: 40px;
    background-color: ${Color.GrayXLight};
    border: 2px solid ${Color.GrayLight};
    border-radius: 4px;
    text-align: center;
  }
`;
