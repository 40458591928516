import * as React from 'react';
import { AvatarStyled, AvatarStyledProps } from './avatar.component.style';

export const Avatar: React.FC<AvatarStyledProps> = props => {
  return (
    <AvatarStyled type={props.type} direction={props.direction} small={props.small}>
      {props.children}
    </AvatarStyled>
  );
};
