import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Divisor } from '@atomic/atm.divisor';
import { Frame } from '@atomic/atm.frame';
import { H3 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { ConfigColumnsModal } from '../../components/config-columns-modal/config-columns-modal.componet';
import { MaterialForm } from '../../components/material-form/material-form';
import { OrderInputBaseboard } from '../../components/order-input-baseboard/order-input-baseboard';
import { OrderInputEvents } from '../../order-input.analytics';
import { OrderInputPhaseEnum } from '../../order-input.constants';
import { OrderInputStrings } from '../../order-input.string';
import { setPhase, setSearchBy } from '../../store/order-input.store';
import { validateMaterials } from '../../store/thunks/validate-materials.thunk';
import { ReceiverForm } from '../order-input-select-itens/receiver-form/receiver-form';
import { OrderInputUploadFile } from './order-input-upload-file/order-input-upload-file';
import {
  MaterialFormOffSet,
  MaterialFormWrapper,
  OrderInputUploadSpreadSheetWrapper,
} from './order-input-upload-spreadsheet.style';

const strings = OrderInputStrings.pt.selectItens;

export const OrderInputUploadSpreadsheet: React.FC = () => {
  const { loadingValidateMaterials } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    dispatch(setSearchBy(null));
  }, []);

  return (
    <>
      <OrderInputUploadSpreadSheetWrapper fluid data-testid='order-input-upload-spreadsheet-phase'>
        <Frame>
          <FlexColumn pt pl>
            <H3>{strings.uploadOptionTitle}</H3>
            <FlexColumn fullWidth mt pb>
              <ReceiverForm />
              <Divisor />
              <Form>
                <FlexRow mt>
                  <MaterialFormWrapper>
                    <MaterialForm />
                  </MaterialFormWrapper>
                  <MaterialFormOffSet />
                </FlexRow>
              </Form>
              <Divisor />
              <Form>
                <FlexRow mt>
                  <OrderInputUploadFile />
                </FlexRow>
              </Form>
            </FlexColumn>
          </FlexColumn>
        </Frame>
      </OrderInputUploadSpreadSheetWrapper>
      <OrderInputBaseboard
        onActionClick={async () => {
          LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: OrderInputPhaseEnum.DataAndAmout });
          dispatch(validateMaterials());
        }}
        onReturnClick={() => {
          LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: OrderInputPhaseEnum.Options });
          dispatch(setPhase(OrderInputPhaseEnum.Options));
        }}
        loading={loadingValidateMaterials}
      />
      <ConfigColumnsModal />
    </>
  );
};
