import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { AppThunkDispatch } from '@app/core/redux/store';
import { getCsatUser } from './csat/csat-get.thunk';

export const CSATContainer: React.FC = props => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectUrl = searchParams.get('redirect');

  useEffect(() => {
    const isLoginPage = window.location.pathname.indexOf('/auth') === 0;
    if (!redirectUrl && !isLoginPage) {
      dispatch(getCsatUser());
    }
  }, []);

  return <>{props.children}</>;
};
