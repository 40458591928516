import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { CustomerSelection } from '@app/providers';
import { optionsMI } from '../customer-search.constants';
import { ICustomerSearchState, ICustomerPersistState } from '../customer-search.interfaces';
import { initialState } from './customer-search.initial';
import { addThunkReducers } from './thunks/customer-search.thunks';

const reducers = {
  setTextSearch: (state: ICustomerSearchState, { payload }) => {
    state.querySearch = payload;
  },
  setFilterOptionSearch: (state: ICustomerSearchState, { payload }) => {
    if (payload?.market !== state.filterOptionSearch?.market) {
      state.corporateGroupSearch = [];
      state.salesOrgsSearch = [];
      state.salesGroupSearch = [];
      state.querySearch = '';
    }
    state.filterOptionSearch = payload;
  },
  setQuerySearch: (state: ICustomerSearchState, { payload }) => {
    state.querySearch = payload;
  },
  addSalesOrgsSearch: (state: ICustomerSearchState, { payload }) => {
    state.salesOrgsSearch.push(payload);
  },
  removeSalesOrgsSearch: (state: ICustomerSearchState, { payload }) => {
    state.salesOrgsSearch = state.salesOrgsSearch.filter(item => item.value !== payload);
  },
  addSalesGroupSearch: (state: ICustomerSearchState, { payload }) => {
    state.salesGroupSearch.push(payload);
  },
  removeSalesGroupSearch: (state: ICustomerSearchState, { payload }) => {
    state.salesGroupSearch = state.salesGroupSearch.filter(item => item.value !== payload);
  },
  addCorporateGroupSearch: (state: ICustomerSearchState, { payload }) => {
    state.corporateGroupSearch.push(payload);
  },
  removeCorporateGroupSearch: (state: ICustomerSearchState, { payload }) => {
    state.corporateGroupSearch = state.corporateGroupSearch.filter(item => item.value !== payload);
  },
  toogleSelectClientBpLocal: (state: ICustomerSearchState, { payload }: PayloadAction<string>) => {
    const findIndex = state.clientBpsSearch.findIndex(bp => bp.customerId === payload);
    state.clientBpsSearch[findIndex].selected = !state.clientBpsSearch[findIndex].selected;
  },
  toogleSelectAllClientBpLocal: (state: ICustomerSearchState) => {
    const filteredBps: CustomerSelection[] = state.clientBpsSearch;
    const someBpUnselected = filteredBps.find(bp => bp.selected === false);
    const allValue = someBpUnselected ? true : false;

    filteredBps.forEach(bp => {
      bp.selected = allValue;
    });
  },
  initCustomerSearch: (state: ICustomerSearchState, { payload }: PayloadAction<ICustomerPersistState>) => {
    state.filterOptionSearch = payload.filterOption || optionsMI[0];
    state.querySearch = payload.query;
    state.corporateGroupSearch = payload.corporateGroup;
    state.salesOrgsSearch = payload.salesOrgs;
    state.salesGroupSearch = payload.salesGroup;

    const selectedBps: CustomerSelection[] = state.clientBpsSearch?.map((bp: CustomerSelection) => ({
      ...bp,
      selected: !!payload.clientBps.find(
        bpConfirmed => bpConfirmed.customerId === bp.customerId && bpConfirmed.selected,
      ),
    }));

    state.clientBpsSearch = selectedBps;
  },
  setClientBpSearch: (state: ICustomerSearchState, { payload }) => {
    state.clientBpsSearch = payload;
  },
  reset: () => {
    return initialState;
  },
};

const SLICE_NAME = 'customer-search';

const TOOGLE_SELECT_ALL = 'customer-search/toogleSelectAllClientBpLocal';
export const TOOGLE_SELECT_ALL_ACTION = { type: TOOGLE_SELECT_ALL };

const RESET_CUSTOMER_SEARCH = `${SLICE_NAME}/reset`;
export const RESET_CUSTOMER_SEARCH_ACTION = { type: RESET_CUSTOMER_SEARCH };

export const customerSearchSlice = createSlice<ICustomerSearchState, SliceCaseReducers<ICustomerSearchState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const {
  setTextSearch,
  setFilterOptionSearch,
  addSalesOrgsSearch,
  removeSalesOrgsSearch,
  removeCorporateGroupSearch,
  addCorporateGroupSearch,
  addSalesGroupSearch,
  removeSalesGroupSearch,
  setQuerySearch,
  toogleSelectClientBpLocal,
  initCustomerSearch,
  setLoading,
  setError,
  setClientBpSearch,
} = customerSearchSlice.actions;

export const customerSearchReducer = customerSearchSlice.reducer;
