import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { UpdateUserParams } from '@app/data/http/admin.params.dto';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { IAdminState } from '../../admin.interfaces';
import { handlePermissionSelectSelector } from '../../admin.selectors';
import { AdminStrings } from '../../admin.string';
import { userInitialState } from '../../utils/utils';
import { getBPs } from './bps-get.thunk';
import { getUsers } from './users-get.thunk';

export const updateUser = createAsyncThunk(`${SLICE_NAME}/updateUser`, async (_, thunkAPI) => {
  const strings = AdminStrings.pt.editView;
  const state: any = thunkAPI.getState();
  const { user, userSearch, bpSearch, bpFilter }: IAdminState = state.admin;
  const handlePermissionSelect = handlePermissionSelectSelector(user);

  const bps = [];

  bpFilter.selectedOptions.forEach(bp => {
    bps.push({ _id: bp.id, customerId: bp.code });
  });

  const updateUserOjb: UpdateUserParams = {
    _id: user._id,
    name: user.name,
    email: user.email,
    language: user.language,
    profile: user.profile,
    bps: handlePermissionSelect ? bps : [],
  };

  return await AdminDataSource.updateUser(updateUserOjb)
    .then(data => {
      thunkAPI.dispatch(
        getUsers({
          skip: userSearch.pagination.skip - userSearch.pagination.limit,
          limit: userSearch.pagination.limit,
          filterType: userSearch.filterOption,
          filterValue: userSearch.textQuery,
        }),
      );
      thunkAPI.dispatch(
        getBPs({
          skip: bpSearch.pagination.skip - bpSearch.pagination.limit,
          limit: bpSearch.pagination.limit,
          filterType: bpSearch.filterOption,
          filterValue: bpSearch.textQuery,
        }),
      );
      thunkAPI.dispatch(setToast({ show: true, text: strings.success, variant: ToastVariant.SUCCESS }));
      return data;
    })
    .catch(err => {
      handleUnauthorized(err);
      if (err.response) {
        thunkAPI.dispatch(setToast({ show: true, text: strings.updateUserError, variant: ToastVariant.DANGER }));
      }
    });
});

export const addUpdateUserReducers = builder => {
  builder.addCase(updateUser.pending, state => {
    state.loadingSubmit = true;
  });
  builder.addCase(updateUser.fulfilled, state => {
    state.loadingSubmit = false;
    state.showForm = false;
    state.showConfirmModal = false;
    state.isEditing = false;
    state.user = userInitialState;
    state.bpFilter.filterOption = [];
    state.bpFilter.textQuery = '';
    state.bpFilter.selectedOptions = [];
    state.bpFilter.selectedCorpGroupOptions = [];
  });
  builder.addCase(updateUser.rejected, state => {
    state.loadingSubmit = false;
  });
};
