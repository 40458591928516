import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../shipping-request.constants';
import { IOrderTabOpenState } from '../shipping-request.interfaces';
import { initialState } from './shipping-request.initial';
import { reducers } from './shipping-request.reducers';

export const order = createSlice<IOrderTabOpenState, SliceCaseReducers<IOrderTabOpenState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
});

export const {
  setShowShippingRequest,
  setOpenShippingFeedback,
  setTableRows,
  updateObservations,
  updateRequestedShippingQuantity,
  updateEatonTableRequestedShippingQuantity,
  updateEatonSuggestionRequestedShippingQuantity,
  setOpenReceiverModalIndex,
  setOpenAlertQtyModal,
  updateReceiver,
  setReceiverOptions,
  deleteItem,
  removeAlert,
  setOpenShippingRequestSuggestionModalMaterial,
  setMogPlant,
  setPinPlant,
  setChaPlant,
  setSuggestions,
} = order.actions;

export const shippingRequestReducer = order.reducer;
