import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../order-priorization.constants';
import { IOrderPriorizationState } from '../order-priorization.interfaces';
import { initialState } from './order-priorization.initial';
import { reducers } from './order-priorization.reducers';
import { addThunkReducers } from './thunks/order-priorization.thunks';

export const orderPriorizationSlice = createSlice<IOrderPriorizationState, SliceCaseReducers<IOrderPriorizationState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const {
  setRows,
  setShowOrderPriorization,
  setShowConfirmModal,
  setShowRemoveItemModal,
  setItemToRemove,
  setShowSuccessPage,
  setLoading,
  setAlreadyOpenedModalMessage,
} = orderPriorizationSlice.actions;

export const orderPriorizationReducer = orderPriorizationSlice.reducer;
