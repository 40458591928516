import React from 'react';
import { Strings, ITruckTrackingStrings } from '@app/modules/truck-tracking/truck-tracking.string';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';

export const TruckTrackingExpiredMessage: React.FC = () => {
  const strings: ITruckTrackingStrings = Strings.pt;

  return (
    <FlexRow>
      <FaIcon.Warning color={Color.Alert} />
      <FlexRow ml={Spacing.Small}>
        <Body> {strings.expiredMessage}</Body>
      </FlexRow>
    </FlexRow>
  );
};
