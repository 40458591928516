import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { CrmStrings } from '../../crm.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

export const CrmPlants = props => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.PLANTS} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>

      <CrmContentInfosWrapper>
        {props?.data && (
          <>
            <Separator small />
            {props.data.infos.length > 0 && (
              <Table collapse>
                <TR bordered>
                  <TD color={Color.Secondary}>
                    <Body bold={600}>{strings.tables.cols.clientId}</Body>
                  </TD>
                  <TD color={Color.Secondary}>
                    <Body bold={600}>{strings.tables.cols.type}</Body>
                  </TD>
                  <TD color={Color.Secondary}>
                    <Body bold={600}>{strings.tables.cols.name}</Body>
                  </TD>
                  <TD color={Color.Secondary}>
                    <Body bold={600}>{strings.tables.cols.plant}</Body>
                  </TD>
                  <TD color={Color.Secondary}>
                    <Body bold={600}>{strings.tables.cols.isMainPartner}</Body>
                  </TD>
                  <TD color={Color.Secondary}>
                    <Body bold={600}>{strings.tables.cols.email}</Body>
                  </TD>
                </TR>
                {props.data.infos.map((item, index) => (
                  <TR bordered key={`t-${index}`}>
                    <TD>
                      <Body>{item.value.clientId}</Body>
                    </TD>
                    <TD>
                      <Body>{item.value.type}</Body>
                    </TD>
                    <TD>
                      <Body>{item.value.name}</Body>
                    </TD>
                    <TD>
                      <Body>{item.value.plant}</Body>
                    </TD>
                    <TD>
                      <Body>{item.value.isMainPartner ? 'Sim' : 'Não'}</Body>
                    </TD>
                    <TD>
                      <Body>{item.value.email}</Body>
                    </TD>
                  </TR>
                ))}
              </Table>
            )}
          </>
        )}
      </CrmContentInfosWrapper>

      <Separator mb={50} />
    </CrmContentWrapper>
  );
};
