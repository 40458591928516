import * as React from 'react';
import { InformationBlockTable } from '@app/models/help-center.model';
import { Body } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants/constants';

interface HelpCenterTableProps {
  table: InformationBlockTable;
}

export const HelpCenterTable: React.FC<HelpCenterTableProps> = props => {
  return (
    <Table collapse>
      <TR bordered>
        {props.table.columns.map((column, index) => (
          <TD key={index} color={Color.Secondary}>
            <Body>{column}</Body>
          </TD>
        ))}
      </TR>
      {props.table.rows.map((row, rowIndex) => (
        <TR key={rowIndex} bordered>
          {row.map((rowItem, index) => (
            <TD key={index} textAlign='left'>
              <Body>{rowItem}</Body>
            </TD>
          ))}
        </TR>
      ))}
    </Table>
  );
};
