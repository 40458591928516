import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const KanbanWrapper = styled.div`
  position: relative;
  display: flex;
  overflow-x: auto;
  align-items: stretch;
  padding-bottom: ${Spacing.Medium};
  & > div {
    padding-left: 0;
  }
`;

export const ColumnWrapper = styled.div`
  background-color: ${Color.GrayLight};
  margin-right: ${Spacing.Small};
  border-radius: ${Spacing.XSmall};
  width: auto;
`;

export const SeparatorCol = styled.div`
  height: ${Spacing.Small};
  background-color: ${Color.GrayXLight};
`;

export const HeaderCol = styled.div`
  background-color: ${Color.GrayLight};
  display: block;
  margin: ${Spacing.Small};
  border-radius: ${Spacing.XSmall};
  & > div > div > div {
    margin-top: ${Spacing.XSmall};
  }
  & > span {
    white-space: nowrap;
    display: block;
    width: fit-content;
  }
`;

export const ItemsCol = styled.div`
  padding: ${Spacing.Small} ${Spacing.Small} 0 ${Spacing.Small};
  border-radius: ${Spacing.XSmall};
  & > a {
    background-color: ${Color.GrayXLight};
    border-radius: ${Spacing.XSmall};
    padding: ${Spacing.Small};
    margin: 0px 0px ${Spacing.Small} 0px;
    width: 20vw;
    display: block;
    align-items: center;
    cursor: pointer;
    & > div > label {
      padding-left: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 90%;
      white-space: nowrap;
      cursor: pointer;
      &:first-child {
        padding: 0;
      }
    }
  }
`;
