import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { BrandColor, Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export const DropDownWrapper = styled.div`
  position: relative;
`;
export const DropDownSelectedWrapper = styled.div`
  display: flex;
  border: 2px solid ${Color.Primary};
  border-radius: 5px;
  margin-right: ${Spacing.Small};
  width: 100%;
`;

export const SelectedText = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-left: ${Spacing.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Small};
`;

export const SearchFieldWrapper = styled.div`
  position: relative;
  width: 500px;

  & > div::first-child {
    width: 0%;
  }
  & > div > div {
    width: 100%;
    input {
      border-color: ${Color.GrayDark};
      background-color: ${Color.White};
      height: 44px;
      width: 100%;
    }
  }
`;
export const ResultWrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${BrandColor.Blue};
  font-size: 15px;
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  cursor: pointer;
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  z-index: 20;
  overflow-x: hidden;
  top: 66px;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  background-color: ${Color.White};
  border: 2px solid ${Color.Primary};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-family: ${FontFamily.Primary};
  width: 100%;
`;

export const OptionItens = styled(FlexRow)`
  margin-top: ${Spacing.Small};
  margin-bottom: ${Spacing.Small};
  margin-left: ${Spacing.Small};
  display: flex;
  justify-content: start;
  align-items: center;
  &:hover {
    background-color: ${Color.GrayLight};
  }
`;
