import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SLICE_NAME } from './navigation.constants';
import { initialState } from './navigation.initial';
import { INavigationState } from './navigation.interface';
import { reducers } from './navigation.reducers';
import { addThunkReducers } from './thunks/navigation.thunks';

export const navigationSlice = createSlice<INavigationState, SliceCaseReducers<INavigationState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const {
  setReloadOpenDashboard,
  setReloadConfirmedDashboard,
  setReloadStockDashboard,
  setReloadBilledDashboard,
  setReloadFinanceDashboard,
  setOpenNpsModal,
  setOpenNewsModal,
  setOpenNewsModalHistory,
  setCompanyLogosTooltipVisible,
  setOpenWarningModal,
  setOpenBPsModal,
  setOpenScheduleModal,
  setOpenHambuguerMenu,
  setOpenMaintenanceModal,
  setOpenPreventiveStopModal,
  setOpenSidebarFilters,
  setOpenFinanceSidebarFilters,
  setOpenErrorHandlerModal,
} = navigationSlice.actions;

const persistConfig = {
  key: 'navigation-persist-reducer',
  storage,
  whitelist: ['openWarningModal'],
};

export const navigationPersistReducer = persistReducer(persistConfig, navigationSlice.reducer);
