import { IScheduleLayout } from '@app/models/schedule.model';

export interface ScheduleClientDTO {
  id: number;
  name: string;
  code: string;
}

export interface ScheduleLayoutDTO {
  id: number;
  uid: string;
  belongs: string;
  label: string;
  value: string;
  active: boolean;
  index: number;
  checked: boolean;
}

export interface ScheduleDTO {
  id: number;
  name: string;
  mail: string;
  user_type: number;
  occurrence_type: number;
  send_day: string;
  hour_to_send: number;
  active: boolean;
  clients: ScheduleClientDTO[];
  layout: ScheduleLayoutDTO[];
}

export const mapDefaultLayout = (items: ScheduleLayoutDTO[]): IScheduleLayout[] => items.map(mapDefaultLayoutItem);

export const mapDefaultLayoutItem = (item: ScheduleLayoutDTO): IScheduleLayout => {
  return {
    id: item.id,
    uid: item.uid,
    belongs: item.belongs,
    label: item.label,
    value: item.value,
    active: item.active,
    index: item.index,
    checked: !!item.checked,
  };
};
