import { HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterCatalogsSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Catálogo de produtos aços especiais',
      key: HelpCenterSectionKeys.Documents,
      internalLink: '/catalogs',
      subsections: [],
    }
  );
};
