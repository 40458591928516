import styled from 'styled-components';
import { Color, FontFamily, Spacing } from '@atomic/obj.constants';

export const AlertButtonModalWrapper = styled.div`
  border-radius: ${Spacing.XSmall};
  background-color: ${Color.AlertLight};
  padding: ${Spacing.Medium};
  color: ${Color.AlertDark};
  font-family: ${FontFamily.Primary};
  font-size: 14px;
  font-weight: bold;
`;
