import { ColDef } from 'ag-grid-community';
import { dateFields, mapDateColumns } from '@app/data/http/layout.dto';
import { RootState } from '../store';

export const columnsTabLayoutSelector = (tabName: string, language: string) => (state: RootState): ColDef[] => {
  const selectedTab = state.gridLayout.tabs.find(tab => tab.name === tabName);
  let columns = [];

  if (selectedTab) {
    columns = mapSelectedTabColumns(selectedTab.cols, language);
  }

  return columns;
};

export const mapSelectedTabColumns = (cols: ColDef[], language: string) => {
  const columns = cols.map((col: ColDef) => {
    const column = {
      field: col.field,
      headerName: col.headerName,
      headerTooltip: col.headerTooltip,
      pinned: col.pinned,
      filter: col.filter,
      width: col.width,
      cellRenderer: col.cellRenderer,
      hide: col.hide,
      checkboxSelection: col.checkboxSelection,
      sort: col.sort,
    };

    if (dateFields.includes(column.field)) {
      mapDateColumns(column, language);
    }

    return column;
  });
  return columns;
};
