import styled from 'styled-components';
import { TextField } from '@atomic/atm.text-field';
import { Color, FontWeight } from '@atomic/obj.constants';

export const EditQtyEatonFieldStyled = styled(TextField)`
  border: ${props => (props.hasError ? `1px solid ${Color.Alert}` : 'none')};
  color: ${props => (props.hasError ? Color.Alert : 'unset')};
  background-color: ${props => (props.selected ? Color.AlertLight : Color.White)};
  font-weight: ${FontWeight.Normal};
  text-align: left;
  text-align: center;
  &::placeholder {
    position: absolute;
    top: 12px;
  }
`;
