import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from 'react-styled-flexboxgrid';
import { Link } from '@app/core/route';
import { BackButton } from '@app/modules/components/back-button/back-button.component';
import { setSelectedRows } from '@app/modules/order/order-tabs/MI/tab-open/store/order-tab-open.store';
import { Breadcrumb } from '@atomic/atm.breadcrumb';
import { Frame } from '@atomic/atm.frame';
import { H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { IOrderPriorizationStrings, Strings } from '../../order-priorization.string';
import { RESET_ORDER_PRIORIZATION_ACTION } from '../../store/order-priorization.reducers';
import { HowPriorizateOrderStyled } from './order-priorization-header.style';

interface OrderPriorizationHeaderProps {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
}

export const OrderPriorizationHeader: React.FC<OrderPriorizationHeaderProps> = ({ gridEvent }) => {
  const strings: IOrderPriorizationStrings = Strings.pt;
  const dispatch = useDispatch();
  const helpCenterLink = '/help-center?section=openOrders&subSection=criticalItems';

  const handleClickBackButton = () => {
    gridEvent?.current?.api?.deselectAll();
    dispatch(setSelectedRows([]));
    dispatch(RESET_ORDER_PRIORIZATION_ACTION);
  };

  const breadCrumbData = strings.page.breadcrumbs.map(breadCrumb => ({
    label: breadCrumb.label,
    to: breadCrumb?.path,
    onLinkClick: handleClickBackButton,
  }));

  return (
    <Frame>
      <Grid fluid>
        <FlexRow pt={Spacing.Small} vAlign='center' hAlign='space-between'>
          <Breadcrumb data={breadCrumbData} />
          <BackButton handleClick={handleClickBackButton} />
        </FlexRow>
        <FlexRow vAlign='baseline'>
          <H2>{strings.page.title}</H2>
          <FlexRow ml>
            <Link to={helpCenterLink}>
              <HowPriorizateOrderStyled>{strings.page.help}</HowPriorizateOrderStyled>
            </Link>
          </FlexRow>
        </FlexRow>
      </Grid>
    </Frame>
  );
};
