import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Label } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { confirmSelectedBpsAdmin } from '../../../store/customer-persist.store';
import { filterSelector } from '../../../store/customer-search.selectors';
import { initCustomerSearch } from '../../../store/customer-search.store';
import { ICustomerSearchModalFooterAdminStrings, Strings } from './customer-search-modal-footer-admin.string';
import { CustomerSearchFooter } from './customer-search-modal-footer-admin.style';

interface CustomerSearchModalFooterProps {
  onClose: () => void;
}

export const CustomerSearchModalFooterAdmin: React.FC<CustomerSearchModalFooterProps> = ({ onClose }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ICustomerSearchModalFooterAdminStrings = Strings[userInfo.language];
  const customerState = useSelector((state: RootState) => state.customerPersist);
  const customerSearchState = useSelector((state: RootState) => state.customerSearch);
  const bpsFiltered = useSelector(filterSelector);
  const someBpSelected = bpsFiltered.find(bp => !!bp.selected);

  const dispatch = useDispatch();

  const handleConfirm = () => {
    const payload = { ...customerSearchState, bpsFiltered };

    dispatch(confirmSelectedBpsAdmin(payload));

    onClose();
  };

  const handleCancel = () => {
    dispatch(initCustomerSearch(customerState));
    onClose();
  };

  return (
    <CustomerSearchFooter>
      <FlexRow>
        <Label>
          {bpsFiltered.filter(bp => !!bp.selected).length} {strings.totalText}
        </Label>
      </FlexRow>
      <FlexRow>
        <Button kind='secondary' onClick={handleCancel}>
          {strings.cancelButton}
        </Button>
        <Button disabled={!someBpSelected} onClick={handleConfirm}>
          {strings.confirmButton}
        </Button>
      </FlexRow>
    </CustomerSearchFooter>
  );
};
