import * as React from 'react';
import { Container } from 'typedi/Container';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { EnhancedError } from './request.model';

export interface RequestHook<Params, Response> {
  loading: boolean;
  error: EnhancedError;
  data: Response;
  performRequest: (params?: Params) => void;
}

export function useRequest<Params, Response>(
  request: (params?: Params) => Promise<Response>,
  initialParams?: Params,
  onSuccess?: (res: Response) => void,
  onError?: (err: EnhancedError) => void,
): RequestHook<Params, Response> {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<EnhancedError>(null);
  const [data, setData] = React.useState<Response>(null);
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const memoizedRequest = React.useCallback(request, []);

  const performRequest = React.useCallback(
    async (params: Params): Promise<void> => {
      setLoading(true);
      setError(null);
      try {
        const res = await memoizedRequest(params);
        setData(res);
        setLoading(false);
        onSuccess?.(res);
      } catch (err) {
        const enhancedError = { ...err, code: err.message.replace(/\D/g, '') };
        setError(enhancedError);
        setLoading(false);
        if (err.message.includes('401')) {
          show('alert', 'Sua sessão expirou, por favor faça o login novamente');
          authStorageService.logout();
        }
        onError?.(enhancedError);
      }
    },
    [memoizedRequest],
  );

  React.useEffect(() => {
    performRequest(initialParams);
  }, [performRequest]);

  return { loading, error, data, performRequest };
}
