import * as React from 'react';
import { AttachedFilesDataProps, NotesDataProps } from '@app/models/crm.model';
import { Separator } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { CrmAttachedFiles } from './crmAttachedFiles';
import { CrmManufacturingConsultation } from './crmManufacturingConsultation';
import { CrmNotes } from './crmNotes';
import { CrmOpportunityDetails } from './crmOpportunityDetails';
import { CrmPlants } from './crmPlants';

interface CrmContentContainerProps {
  loadingAttachedFiles: boolean;
  loadingManufacturingConsultation: boolean;
  loadingNotes: boolean;
  loadingOpportunityDetail: boolean;
  loadingPlants: boolean;
  attachedFilesData: AttachedFilesDataProps;
  notesData: NotesDataProps;
  manufacturingConsultationData: any;
  opportunityDetailsData: any;
  plantsData: any;
}

export const CrmContentContainer = (props: CrmContentContainerProps) => {
  return (
    <Grid fluid mb={30}>
      <Row mt>
        <Col xs={12}>
          <CrmOpportunityDetails data={props?.opportunityDetailsData} loading={props.loadingOpportunityDetail} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmManufacturingConsultation
            data={props?.manufacturingConsultationData}
            loading={props.loadingManufacturingConsultation}
          />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmNotes data={props?.notesData} loading={props.loadingNotes} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmAttachedFiles data={props?.attachedFilesData} loading={props.loadingAttachedFiles} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmPlants data={props?.plantsData} loading={props.loadingPlants} />
        </Col>
      </Row>
      <Separator />
      <Separator />
    </Grid>
  );
};
