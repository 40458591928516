import * as React from 'react';
import { CrmFilterData } from '@app/models/crm.model';
import { SelectField } from '@atomic/atm.select';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';

export interface CrmFilterProps {
  filters: any;
  setFilters: (data: CrmFilterData) => void;
}

export const CrmLanguageFilter = props => {
  const filterLanguages = [
    { key: 'P', label: 'Português ' },
    { key: 'E', label: 'Inglês ' },
    { key: 'S', label: 'Espanhol ' },
  ];

  return (
    <Hbox hAlign='flex-end' vAlign='center'>
      <Hbox>
        <Body>Buscar CFs no idioma:</Body>
      </Hbox>

      <SelectField id='lang' onValueChange={val => props.setFilters({ ...props.filters, lang: val })}>
        {filterLanguages.map(option => (
          <Option key={`l-${option.key}`} value={option.key} name={option.label} />
        ))}
      </SelectField>
    </Hbox>
  );
};

const Option = ({ value, name }) => {
  return <option value={value}>{name}</option>;
};
