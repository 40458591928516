import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as TextInputMask from 'react-masked-text';
import styled from 'styled-components';
import { InputPlaceholderCss } from '@atomic/atm.typography';
import { Color, FieldHeight, Spacing, ZIndex } from '@atomic/obj.constants';
import { fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';
import { TextFieldProps } from './text-field.component';

const FIELD_WITH_ICON_PADDING = 'calc(' + Spacing.Medium + ' * 3)';
const ICON_HEIGHT = '18px';

export const TextFieldStyled = styled.input`
  ${fieldBorderCss}
  ${fieldCss}

  ${(props: TextFieldProps) => (props.icon ? 'padding-left: ' + FIELD_WITH_ICON_PADDING : null)};
  ${(props: TextFieldProps) => (props.hasButton ? 'padding-right: ' + FIELD_WITH_ICON_PADDING : null)};
  ${(props: TextFieldProps) => (props.align ? 'text-align: ' + props.align : null)};
  ${(props: TextFieldProps) =>
    props.noArrows &&
    `-moz-appearance: textfield;
    appearance: textfield;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }`};
  ::placeholder {
    ${InputPlaceholderCss}
    opacity: 0.5;
  }
`;

const DismissButtonSize = 16;

export const TextFieldDismissButtonStyled = styled.div`
  position: absolute;
  top: ${Spacing.Large};
  right: ${Spacing.Small};
  width: ${DismissButtonSize}px;
  height: ${DismissButtonSize}px;
  border-radius: ${DismissButtonSize / 2}px;
  background-color: ${Color.Gray};
  text-align: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 10px;
    top: 50%;
    left: 3px;
    background: ${Color.White};
  }
  &:before {
    transform: rotate(45deg);
    margin-top: -1px;
  }
  &::after {
    transform: rotate(-45deg);
    margin-top: -1px;
  }
`;

export const TextFieldWrapperStyled = styled.div`
  position: relative;
`;

export const TextFieldMaskedStyled: TextInputMask = TextFieldStyled.withComponent(TextInputMask);

export const TextFieldIconWrapperStyled = styled.div`
  position: relative;
`;

export const TextFieldButtonAreaStyled = styled.div`
  position: absolute;
  z-index: ${ZIndex.TextFieldButtonAreaStyled};
  bottom: 0px;
  right: 0px;
`;

export const TextFieldIconStyled = styled(FontAwesomeIcon).attrs({
  icon: props => (props.icon ? props.icon : null),
})`
  position: absolute;
  z-index: ${ZIndex.TextFieldIconStyled};
  top: ${'calc((' + FieldHeight + ' - ' + ICON_HEIGHT + ') / 2)'};
  left: ${Spacing.Medium};
`;
