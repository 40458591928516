import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { isNextMultipleUpDisabledSelector } from '@app/modules/order-input/store/order-input.selectors';
import { setMultipleUpQuantity } from '@app/modules/order-input/store/order-input.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
import { ArrowUpButtonWrapper } from './arrow-up-button.style';

interface IArrowUpButtonField {
  index: number;
  material: Material;
}

export const ArrowUpButton: React.FC<IArrowUpButtonField> = ({ index, material }) => {
  const dispatch = useDispatch();
  const disabled = useSelector((state: RootState) => isNextMultipleUpDisabledSelector(state, material.tempId));

  const onClick = () => {
    if (!disabled) {
      dispatch(setMultipleUpQuantity(material.tempId));
    }
  };

  return (
    <ArrowUpButtonWrapper disabled={disabled} onClick={onClick} data-testid={`arrow-up-button-${index}`}>
      <FaIcon.ChevronUp clickable color={Color.Primary} />
    </ArrowUpButtonWrapper>
  );
};
