import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { RootState } from '@app/core/redux/store';
import { CrmPaginateData } from '@app/data/http/crm.dto';
import { CrmFilterData } from '@app/models/crm.model';
import { CrmAdvancedFilterWrapper } from '@app/modules/complain/crm/components/crmAdvancedFilter.style';
import { CrmLanguageFilter } from '@app/modules/complain/crm/components/crmLanguageFilter';
import { DateFilter } from '@app/modules/components/date-filter.component';
import { ErrorPlaceholder } from '@app/modules/components/error.placeholder';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { NoDataPlaceholder } from '@app/modules/components/no-data.placeholder';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { Pagination } from '@atomic/atm.pagination';
import { Body } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { MenuQuotePage } from '../components/menu-quote-page.component';
import { PaginationWrapper, PrevNextPaginationButton } from '../components/quote.components.style';
import { QuoteCellShimmer } from '../quote-cell.shimmer';
import { QuoteStrings } from '../quote.string';
import { formatDateFilter } from '../utils/formatDateFilter';
import { CrmAdvancedFilter } from './components/crmAdvancedFilter';
import { CrmListItem } from './components/crmListItem';
import { DateFilterWrapper } from './components/crmListItem.style';
import { CrmSearchBar } from './components/crmSearchBar';
import { useGetCrmList } from './hooks/useGetCrmList';

// eslint-disable-next-line complexity
export const CrmPage: React.FC = () => {
  const takePerPage = 10;

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [currentFilter, setCurrentFilter] = React.useState<CrmFilterData>(null);
  const [page, setPage] = React.useState(0);
  const [paginate, setPaginate] = React.useState<CrmPaginateData>({ skip: 0, take: takePerPage, limit: 0 });
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const handleGetCrmError = data => {
    show('alert', data.message ? data.message : data.description);
  };

  const [filters, setFilters] = React.useState({
    currentFilter,
    paginate: { skip: 0, take: takePerPage, limit: 0 },
    action: 'search',
    enabled: false,
    startDate: null,
    endDate: null,
    lang: 'P',
  });

  const { data: dataList, isLoading: getLoading, isFetching, error: errorGetList } = useGetCrmList(
    { filters },
    handleGetCrmError,
  );

  const emptyFieldValue = (key: string) => {
    if (currentFilter !== null) {
      const el = document.getElementById(`${key}`) as HTMLInputElement;
      el.value = '';
    }
  };

  const handleSubmit = () => {
    setFilters({ ...filters, currentFilter: currentFilter });
  };

  const handleEditFilters = (value: string, key: string, actionUsed: string) => {
    const newFilter = { ...currentFilter };

    if (actionUsed === 'remove') {
      newFilter[key] = currentFilter[key].filter(item => item !== value);

      if (newFilter[key].length === 0) {
        delete newFilter[key];
      }

      setCurrentFilter(newFilter);
      setFilters({
        ...filters,
        currentFilter: newFilter,
      });
    } else if (actionUsed === 'removeAll') {
      setCurrentFilter(null);
      emptyFieldValue(key);
      setFilters({
        ...filters,
        currentFilter: null,
        paginate: { skip: 0, take: takePerPage, limit: 0 },
        action: 'search',
      });
    } else {
      if (value) {
        if (newFilter[key]) {
          if (!newFilter[key].includes(value)) {
            newFilter[key].push(value);
          }
        } else {
          newFilter[key] = [value];
        }
        setCurrentFilter(newFilter);
      }
    }
  };

  const numberOfPages = Math.ceil(dataList?.paginate?.total / takePerPage);

  const handlePageChange = (value: number) => {
    Scroll.animateScroll.scrollToTop({ containerId: 'tabs' });
    setPaginate({ ...paginate, skip: value * takePerPage });
    setPage(value);

    setFilters({ ...filters, paginate: { ...paginate, skip: value * takePerPage } });
  };

  const handleDateChange = (value: number, start?: Date, end?: Date) => {
    if (value !== 1) {
      setFilters({
        ...filters,
        startDate: value < 0 ? null : formatDateFilter(value, start),
        endDate: value < 0 ? null : formatDateFilter(value, end),
        paginate: { skip: 0, take: takePerPage, limit: 0 },
      });
    }
  };

  return (
    <>
      <Frame>
        <Grid fluid>
          <Row>
            <Col md={12} xs={12}>
              <MenuQuotePage id='tabs' tabIndex={4} language={userInfo.language} loading={false} />
            </Col>
          </Row>
        </Grid>
      </Frame>
      <Grid fluid>
        <Row mt>
          <Col xs={12}>
            <CrmAdvancedFilterWrapper>
              <CrmSearchBar
                currentFilter={currentFilter}
                setCurrentFilter={setCurrentFilter}
                handleEditFilters={handleEditFilters}
                emptyFieldValue={emptyFieldValue}
                onSubmit={() => handleSubmit()}
              />
            </CrmAdvancedFilterWrapper>
          </Col>
        </Row>
        <Row mt>
          <Col xs={12} sm={3}>
            <CrmAdvancedFilter
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              handleEditFilters={handleEditFilters}
              emptyFieldValue={emptyFieldValue}
              onSubmit={() => handleSubmit()}
            />
          </Col>
          <Col xs={12} sm={9}>
            <Row>
              <Col xs={6}>
                <Hbox noGrow hAlign='flx-start' vAlign='center'>
                  <DateFilterWrapper>
                    <DateFilter onDateChange={handleDateChange} crmFilter={true} />
                  </DateFilterWrapper>
                  <Hbox.Separator />
                  <Separator />
                  <Body>
                    {dataList && dataList?.data.length > 0
                      ? `${dataList.paginate.total} Resultados de busca`
                      : !isFetching && 'Nenhum registro encontrado'}
                  </Body>
                  <Separator />
                </Hbox>
                <Separator />
              </Col>
              <Col xs={6}>
                <CrmLanguageFilter filters={filters} setFilters={setFilters} />

                <Separator />
              </Col>
            </Row>
            <Row>
              <Col xs={12} mt mb>
                <LoadingState
                  loading={getLoading && isFetching}
                  error={!!errorGetList}
                  data={dataList?.paginate.total > 0}
                >
                  <LoadingState.Shimmer>
                    <QuoteCellShimmer />
                  </LoadingState.Shimmer>

                  <LoadingState.NoData>
                    <NoDataPlaceholder message={'Nenhum registro encontrado'} />
                  </LoadingState.NoData>

                  <LoadingState.Error>
                    <ErrorPlaceholder onRetryClick={() => handleSubmit()} />
                  </LoadingState.Error>

                  {dataList &&
                    dataList.data?.length > 0 &&
                    dataList.data.map((item, index) => <CrmListItem key={`${item.cfId}-${index}`} item={item} />)}
                  <Separator />
                  {dataList && dataList?.data.length > 0 && (
                    <PaginationWrapper>
                      {page > 0 && (
                        <PrevNextPaginationButton>
                          <Button size='small' onClick={() => handlePageChange(page - 1)}>
                            {QuoteStrings[userInfo.language].pagination.prev}
                          </Button>
                        </PrevNextPaginationButton>
                      )}
                      <Pagination
                        current={page + 1}
                        window={3}
                        total={numberOfPages}
                        onPageChange={value => handlePageChange(value - 1)}
                      />
                      {numberOfPages > 0 && page + 1 !== numberOfPages && (
                        <PrevNextPaginationButton>
                          <Button size='small' onClick={() => handlePageChange(page + 1)}>
                            {QuoteStrings[userInfo.language].pagination.next}
                          </Button>
                        </PrevNextPaginationButton>
                      )}
                    </PaginationWrapper>
                  )}
                </LoadingState>
                <Separator />
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
