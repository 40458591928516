import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AttributeComments, CommentsFullData } from '@app/models/quote.model';
import { AuthStorageService } from '../storage/auth.storage';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';
import { CommentsFullResponse, CommentsTempResponse, mapCommentsResponse, mapTempComment } from './quote-comment.dto';
import {
  DeleteCommentsParams,
  EditCommentsParams,
  GetCommentsParams,
  PostCommentsParams,
} from './quote-comments-params.dto';

const endpoint = {
  ANALYSIS: '/analyses',
  ANALYSIS_PLANT: '/plants',
  ANALYSIS_ATTRIBUTE: '/attributes',
  ANALYSIS_COMMENTS: '/comments',
};

const user = Container.get(AuthStorageService).getUser();
const defaultHeaders = {
  client_id: CXP_KEY_CLIENT_ID,
  client_secret: CXP_KEY_CLIENT_SECRET,
  'Content-Type': 'application/json',
  Accept: '*/*',
  'Access-Control-Allow-Origin': 'egerdau.com.br',
};

export const QuoteCommentsDataSource = {
  getComments: (params: GetCommentsParams): Promise<CommentsFullData> => {
    return Container.get(HttpRequestBuilder)
      .get(
        `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}`,
      )
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess, language: user.language })
      .interceptors({
        afterSuccess: async (res: CommentsFullResponse[]): Promise<CommentsFullData> => mapCommentsResponse(res),
      })
      .execute();
  },

  editComments: (params: EditCommentsParams): Promise<void> => {
    return Container.get(HttpRequestBuilder)
      .patch(
        `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}/${params.commentKey}`,
      )
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess, language: user.language })
      .data({ value: params.editedComment })
      .execute();
  },

  postComments: (params: PostCommentsParams): Promise<void> => {
    const data = params.isClient
      ? {
          value: params.comment,
        }
      : {
          value: params.comment,
          client_visible: params.isClientVisible,
        };
    const path = params.replicateComment
      ? `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}`
      : `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}`;

    return Container.get(HttpRequestBuilder)
      .post(path)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess, language: user.language })
      .data(data)
      .interceptors({
        afterSuccess: async (res: CommentsTempResponse): Promise<AttributeComments> =>
          mapTempComment(res, params.replicateComment),
      })
      .execute();
  },

  deleteComments: (params: DeleteCommentsParams): Promise<void> => {
    return Container.get(HttpRequestBuilder)
      .delete(
        `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}/${params.commentKey}`,
      )
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess, language: user.language })
      .execute();
  },
};
