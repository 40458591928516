import { createAsyncThunk } from '@reduxjs/toolkit';
import { NPSDataSource } from '@app/data/http/nps.datasource';
import { GetStatisticsParams } from '@app/data/http/nps.params.dto';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { AdminStrings } from '../../admin.string';

export const getNPSStatisticsGroup = createAsyncThunk(
  `${SLICE_NAME}/getNPSStatisticsGroup`,
  async (params: GetStatisticsParams, thunkAPI) => {
    const strings = AdminStrings.pt.nps;

    return await NPSDataSource.getStatisticsGroup(params)
      .then(data => data)
      .catch(err => {
        handleUnauthorized(err);
        thunkAPI.dispatch(setToast({ show: true, text: strings.statisticsGroupError, variant: ToastVariant.DANGER }));
      });
  },
);

export const addGetNPSStatisticsGroupReducers = builder => {
  builder.addCase(getNPSStatisticsGroup.pending, state => {
    state.loadingNPSStatistics = true;
  });
  builder.addCase(getNPSStatisticsGroup.fulfilled, (state, action) => {
    state.npsStatisticsGroup = action.payload;
    state.loadingNPSStatistics = false;
  });
  builder.addCase(getNPSStatisticsGroup.rejected, state => {
    state.loadingNPSStatistics = false;
  });
};
