import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis-copy/technical-analysis.string';
import { Button } from '@atomic/atm.button';
import { RadioField } from '@atomic/atm.radio';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';

interface TechnicalAnalysisStatusModalProps {
  opened: boolean;
  status: string;
  onClose: () => void;
  onSubmit: (key: string, data: PendingStatusModalFormData) => void;
}

export interface PendingStatusModalFormData {
  partNumber?: string | number;
  observation?: string;
}

export const StatusPendingModal: React.FC<TechnicalAnalysisStatusModalProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const strings = TechnicalAnalysisStrings[userInfo.language].status.peddingModal;

  const handleSubmit = (form: FormData<PendingStatusModalFormData>) => {
    if (!(Object.keys(form.error).length > 0)) {
      props.onSubmit(props.status, {
        partNumber: form.data.partNumber,
        observation: form.data.observation,
      });
      setShowForm(false);
    }
  };

  const handleOption = (value: string) => {
    if (value === 'n') {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Modal opened={props.opened} onClose={handleClose} small>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <VSeparator />

            <H3>{strings.title}</H3>
            <VSeparator />

            <Form.Field name='pn' onValueChange={handleOption} initialValue='n'>
              <Hbox>
                <Body>{strings.partNumberQuestion}</Body>
              </Hbox>
              <VSeparator />
              <Hbox>
                <Hbox.Item noGrow>
                  <RadioField id='s'>{strings.options.yes}</RadioField>
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item noGrow>
                  <RadioField id='n'>{strings.options.no}</RadioField>
                </Hbox.Item>
              </Hbox>
            </Form.Field>

            <Form onSubmit={handleSubmit}>
              {showForm && (
                <>
                  <VSeparator />
                  <VSeparator />
                  <Form.Field
                    name='partNumber'
                    validators={[Validators.Required(strings.partNumberField.alertMandatory)]}
                    label={strings.partNumberField.label}
                  >
                    <TextField />
                  </Form.Field>
                </>
              )}
              <VSeparator />
              <Form.Field name='observation'>
                <TextAreaField placeholder={strings.observationField.placeholder} disableResize />
              </Form.Field>
              <VSeparator small />

              <VSeparator />

              <Hbox hAlign='flex-end'>
                <Hbox.Item noGrow>
                  <Button type='submit' kind='primary'>
                    {strings.send}
                  </Button>
                </Hbox.Item>
              </Hbox>
            </Form>

            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
