import * as React from 'react';
import { CreditDataSource } from '@app/data/http/credit.datasource';
import { Credit } from '@app/models';
import { MarketType } from '@app/providers';

export const useCreditQuery = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState<Credit[]>(null);
  const [nonBindingCredit, setNonBindingCredit] = React.useState<number>(null);

  const fetch = React.useCallback(async (clientNumber: string, market: string) => {
    setLoading(true);

    try {
      let res;

      if (market === MarketType.Internal) {
        res = await CreditDataSource.getCreditMI({ customerIds: clientNumber });
      } else {
        res = await CreditDataSource.getCreditME({ customerIds: clientNumber });
      }

      setData(res);
      const nonBindigCredit = await CreditDataSource.getNonBindingCredit({ customerIds: clientNumber });
      setNonBindingCredit(nonBindigCredit.amountBranch);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }, []);

  return { fetch, loading, error, data, nonBindingCredit };
};
