import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../notification.constants';
import { INotificationState } from '../notification.interfaces';
import { initialState } from './notification.initial';
import { reducers } from './notification.reducers';
import { addThunkReducers } from './thunk/notification.thunks';

export const notificationSlice = createSlice<INotificationState, SliceCaseReducers<INotificationState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const { reset, setIsFirstPageLoad } = notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
