import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { TD, TR } from '@atomic/mol.table';
import { getRowColor } from '../utils';
import { AmountField } from './partials/amount-field/amount-field';
import { DateField } from './partials/date-field/date-field';
import { DeleteField } from './partials/delete-field/delete-field';
import { MaterialField } from './partials/material-field/material-field';
import { OrderItemField } from './partials/order-item-field/order-item-field';
import { OrderNumberField } from './partials/order-number-field/order-number-field';
import { SelectionField } from './partials/selection-field/selection-field';
import { UnityField } from './partials/unity-field/unity-field';

export const AmountTableBody: React.FC = () => {
  const { selectedMaterials } = useSelector((state: RootState) => state.orderInput);
  const today = new Date();

  return (
    <>
      {selectedMaterials.map((material: Material, index: number) => {
        const key = `${material.materialCode}_${material.tempId}`;

        return (
          <TR key={key} color={getRowColor(material, today)}>
            <TD>
              <SelectionField material={material} index={index} />
            </TD>
            <TD>
              <OrderNumberField material={material} />
            </TD>
            <TD>
              <OrderItemField material={material} />
            </TD>
            <TD>
              <MaterialField material={material} />
            </TD>
            <TD>
              <AmountField material={material} index={index} />
            </TD>
            <TD>
              <UnityField material={material} />
            </TD>
            <TD>
              <DateField material={material} />
            </TD>
            <TD>
              <DeleteField material={material} />
            </TD>
          </TR>
        );
      })}
    </>
  );
};
