export const HelpCenterString = {
  title: 'Central de ajuda',
  searchBar: 'Buscar em Central de ajuda',
  welcome: {
    title: 'Bem vindo à Central de ajuda',
    paragraph:
      'Você está na Central de ajuda, aqui você pode ver o passo a passo de como utilizar algumas funcionalidades do Gerdau Mais.\n\n Escolha uma tema à esquerda para prosseguir.',
  },
  feedback: {
    text: 'Essa informação foi útil?',
    afirmative: 'Sim',
    negative: 'Não',
  },
  action: {
    download: 'Download PDF',
  },
};
