import styled from 'styled-components';
import { Border, Color, Spacing } from '@atomic/obj.constants';

export const DROPDOWNMENU_WIDTH = 160;
export const DROPDOWNMENU_HEIGHT = 100;
export const DROPDOWNMENU_Z_INDEX = 99;
export const ARROW_DIMENSIONS = 10;

export const CustomizedDropDownMenuWrapper = styled.div`
  position: absolute;
  z-index: ${DROPDOWNMENU_Z_INDEX};
  width: ${DROPDOWNMENU_WIDTH}px;
  height: ${DROPDOWNMENU_HEIGHT}px;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
`;

export const CustomizedDropDownMenuBoxStyled = styled.div`
  padding: ${Spacing.XSmall} ${Spacing.Medium};
  background-color: ${Color.White};
  border-radius: ${Border.Radius};
  box-shadow: 0px 0px 40px rgba(114, 114, 114, 0.35);
`;
