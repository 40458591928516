import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';

export interface IUserState {
  email: string;
  language: string;
}
const initialState: IUserState = {
  email: null,
  language: null,
};

const reducers = {
  setUser: (state: IUserState, { payload }) => {
    state.email = payload.email;
    state.language = payload.language;
  },
  setUserEmail: (state: IUserState, { payload }) => {
    state.email = payload;
  },
  setLanguage: (state: IUserState, { payload }) => {
    state.language = payload;
  },
};

export const userSlice = createSlice<IUserState, SliceCaseReducers<IUserState>>({
  name: 'user',
  initialState,
  reducers,
});

export const { setUser, setUserEmail, setLanguage } = userSlice.actions;

const persistConfig = {
  key: 'user-persist-reducer',
  storage,
};

export const userPersistReducer = persistReducer(persistConfig, userSlice.reducer);
