import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { MEMaritimeOrderDataSource } from '@app/data/http/order-ME-maritime.datasource';
import { HanaLanguage } from '@app/models';
import { SalesOrdersMaritimeShippedItem } from '@app/models/maritime-order-item.model';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { initialStateWithDates, IOrderTabState } from '../../../order.interfaces';
import { extraReducersConfig, reducersWithDates } from '../../../utils';

interface IOrderTabShippedMaritimeState extends IOrderTabState {
  data: SalesOrdersMaritimeShippedItem[];
  filteredRows: SalesOrdersMaritimeShippedItem[];
  selectedRows: SalesOrdersMaritimeShippedItem[];
}

const SLICE_NAME = 'order-tab-shipped-maritime';

const currentReducerSelector = state => state.orderTabShippedMaritime;

export const performRequest = createAsyncThunk(`${SLICE_NAME}/performRequest`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const promiseRequestTime = new Date().getTime();
  const customerIds = customerIdsJoinedSelector(thunkAPI.getState());
  const orderTabShippedMaritimeState: IOrderTabShippedMaritimeState = currentReducerSelector(thunkAPI.getState());

  const startApprovementDate = format(orderTabShippedMaritimeState.startDate, 'yyyyMMdd');
  const endApprovementDate = format(orderTabShippedMaritimeState.endDate, 'yyyyMMdd');

  return await MEMaritimeOrderDataSource.getMeMaritimeShipped({
    startApprovementDate,
    endApprovementDate,
    customerIds,
    language: HanaLanguage[state.auth?.userInfo?.language],
  })
    .then(data => ({ promiseRequestTime, data }))
    .catch(err => {
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const order = createSlice<IOrderTabShippedMaritimeState, SliceCaseReducers<IOrderTabShippedMaritimeState>>({
  name: SLICE_NAME,
  initialState: initialStateWithDates,
  reducers: reducersWithDates,
  extraReducers: extraReducersConfig(performRequest),
});

export const { setStartDate, setEndDate, setFilteredRows } = order.actions;

export const orderTabShippedMaritimeReducer = order.reducer;
