import { OpenOrderStatusGraph } from '@app/models/dashboard.model';

export interface OpenedStatusGraphsDto {
  statusPedidoAberto: {
    qtdSolicitada: number;
    qtdConfirmada: number;
    qtdFaturada: number;
    qtdPendFaturamento: number;
  };
}

export const mapOpenedStatusGraphsDto = (data: OpenedStatusGraphsDto): OpenOrderStatusGraph => {
  const graphInfo = data.statusPedidoAberto;
  return {
    confirmed: graphInfo?.qtdConfirmada ? +graphInfo?.qtdConfirmada?.toFixed(0) : 0,
    requested: graphInfo?.qtdSolicitada ? +graphInfo?.qtdSolicitada?.toFixed(0) : 0,
    billed: graphInfo?.qtdFaturada ? +graphInfo?.qtdFaturada?.toFixed(0) : 0,
    pendingBilling: graphInfo?.qtdPendFaturamento ? +graphInfo?.qtdPendFaturamento?.toFixed(0) : 0,
  };
};
