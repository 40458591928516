/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const FraudAlertModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FraudAlertModalHeader = styled.div`

`;

export const FraudAlertModalBody = styled.div`
  min-height: 64vh;
  height: auto;
`;

export const FraudAlertModalFooter = styled.div`
  width: 100%;
  background-color: ${Color.White};
  padding: ${Spacing.Medium} 30px ${Spacing.Medium} ${Spacing.Medium};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
`;
