import { ConfigColumnsModel } from '@app/models';
import { OrderInputImplantationTypeEnum, OrderInputPhaseEnum, materialInitialTemplate } from '../order-input.constants';
import { IOrderInputState } from '../order-input.interfaces';

export const configColumnsSpreadsheetInitial: ConfigColumnsModel = {
  identifier: null,
  initRow: '',
  materialCodeColumn: '',
  amountColumn: '',
  unityAmountColumn: '',
  dateWishedColumn: '',
  orderNumberColumn: '',
  itemIdentifierColumn: '',
};

export const initialState: IOrderInputState = {
  phase: OrderInputPhaseEnum.Options,
  issuerOptions: [],
  receiverOptions: [],
  materialOptions: [],
  selectedMaterials: [],
  selectedReceiver: null,
  selectedIssuer: null,
  searchBy: null,
  query: '',
  editedNewline: false,
  pdfBase64: null,
  spreadSheetFileName: null,
  spreadSheetFileRequestItems: [],
  materialFieldID: null,
  materialTemplate: materialInitialTemplate,
  implantationType: OrderInputImplantationTypeEnum.ItemSelection,
  showM2CalendarModal: false,
  loadingIssuers: false,
  loadingReceivers: false,
  loadingMaterials: false,
  openMaterials: false,
  loadingPost: false,
  loadingValidateMaterials: false,
  deleteMaterialModalOpened: false,
  updateMaterialModalOpened: false,
  selectAll: false,
  materialDelete: null,
  openMultipleAlertModal: false,
  loadingMultiple: false,
  openConfigColumnSpreadsheet: false,
  loadingGetConfigColumnsSpreadsheet: false,
  loadingPostConfigColumnsSpreadsheet: false,
  configColumnsSpreadsheet: configColumnsSpreadsheetInitial,
  loadingPostFiles: false,
  files: [],
  orderReadData: [],
};
