export const DocumentString = {
  pt: {
    documents: {
      title: 'Documentos',
      searchBar: 'Buscar em documentos',
      subTitle: {
        title: 'Documentos Gerdau',
        paragraph: 'Documentos de informação pública, disponibilizados a todos os usuários.',
      },
      alerts: {
        newDocumentSuccess: 'Documento enviado com sucesso',
        newDocumentError: 'Ocorreu um erro ao enviar o documento',
        editDocumentSuccess: 'Documento editado com sucesso',
        editDocumentError: 'Ocorreu um erro ao editar o documento',
      },
      modal: {
        labelNewDocument: 'Novo Documento',
        labelEditButton: 'Editar Documento',
        labelRemoveList: 'Remover da lista',
        labelDeleteButton: 'Excluir Documento',
      },
      fields: {
        title: 'Nome do Documento *',
        expirationDate: 'Data de expiração',
        editFile: 'Clique aqui para substituir o arquivo se necessário',
        newFile: 'Clique aqui para adicionar um arquivo',
      },
    },
    catalogs: {
      title: 'Catálogo de produtos aços especiais',
      searchBar: 'Buscar em catálogos',
      subTitle: {
        title: 'Catálogos Gerdau',
        paragraph: '',
      },
    },
    messages: {
      successDelete: 'Arquivo excluído com sucesso',
      deleteError: 'Ocorreu um erro ao excluir o arquivo',
      confirmDelete: 'Você está apagando permanentemente o arquivo. Deseja continuar?',
    },
    box: {
      date: 'Atualizado em ',
      btn: 'Download',
    },
    btnBack: 'Voltar',
  },
  in: {
    documents: {
      title: 'Documents',
      searchBar: 'Search in documents',
      subTitle: {
        title: 'Gerdau documents',
        paragraph: 'Public information documents, made available to all Gerdau Mais users',
      },
    },
    catalogs: {
      title: 'Special steel products catalog',
      searchBar: 'Search in catalogs',
      subTitle: {
        title: 'Gerdau catalogs',
        paragraph: '',
      },
    },
    messages: {
      successDelete: 'File deleted successfully',
      deleteError: 'An error occurred while deleting the file',
      confirmDelete: 'You are permanently deleting the file. Do you wish to continue?',
    },
    box: {
      date: 'Updated on ',
      btn: 'Download',
    },
    btnBack: 'Back',
  },
  es: {
    documents: {
      title: 'Documentos',
      searchBar: 'Buscar en documentos',
      subTitle: {
        title: 'Documentos Gerdau',
        paragraph: 'Documentos de información pública, puestos a disposición de todos los usuarios de Gerdau Mais',
      },
    },
    catalogs: {
      title: 'Catálogo de productos de acero especial',
      searchBar: 'Buscar en catálogos',
      subTitle: {
        title: 'Catálogos Gerdau',
        paragraph: '',
      },
    },
    messages: {
      successDelete: 'Archivo eliminado exitosamente',
      deleteError: 'Se produjo un error al eliminar el archivo',
      confirmDelete: 'Estás eliminando permanentemente el archivo. ¿Desea continuar?',
    },
    box: {
      date: 'Actualizado el',
      btn: 'Descargar',
    },
    btnBack: 'Volver',
  },
};
