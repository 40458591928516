import { AdminBPsFilterOptionsId, AdminBPsFilterOptionsLabel } from '@app/models/admin.model';

export const BpFilterOptions = [
  {
    value: AdminBPsFilterOptionsId.Name,
    label: AdminBPsFilterOptionsLabel.Name,
  },
  {
    value: AdminBPsFilterOptionsId.Bp,
    label: AdminBPsFilterOptionsLabel.Bp,
  },
  {
    value: AdminBPsFilterOptionsId.CorpGroup,
    label: AdminBPsFilterOptionsLabel.CorpGroup,
  },
  {
    value: AdminBPsFilterOptionsId.SalesOrg,
    label: AdminBPsFilterOptionsLabel.SalesOrg,
  },
];
