import React from 'react';
import { IOrderPriorizationTableData } from '@app/modules/order-priorization/order-priorization.interfaces';
import { Image } from '@atomic/atm.image';

interface OrderPriorizationTableStaticDataProps {
  item: IOrderPriorizationTableData;
  column: string;
}

export const OrderPriorizationTableIconCell: React.FC<OrderPriorizationTableStaticDataProps> = ({ item }) => {
  if (item) {
    return <Image.PrioritizedOrder />;
  }

  return null;
};
