import { format } from 'date-fns';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '@app/core/redux/store';
import { AnalysisResume } from '@app/models/analysis.model';
import { AppPath } from '@app/modules/app/route-constants';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { FaIcon } from '@atomic/atm.fa-icon';
import { BodySecondary, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { TagStyled } from '../components/quote.components.style';
import { QuoteStrings } from '../quote.string';

interface CardItemProps {
  item: AnalysisResume;
}

export const CadtItem = (props: CardItemProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = QuoteStrings[userInfo.language].components;

  const formatPlant = (plants: string[]) => {
    const charPlants = plants.map(plant => {
      return plant.slice(0, 3).toUpperCase();
    });
    return charPlants.join(', ');
  };

  return (
    <Link to={`${AppPath.QUOTE.ANALYSIS}/${props.item.id}`} target='_blank'>
      <H3>
        ISA {props.item.formattedId}
        {props.item.client.visible && quoteIsInternalUser && (
          <TagStyled>
            <FaIcon.Eye size='1x' color={Color.White} title={strings.tagsLabel.clientVisible} />
          </TagStyled>
        )}
        {props.item.userCreator.profile === 'CLIENT' && quoteIsInternalUser && (
          <TagStyled disabled={true}>
            <FaIcon.User size='1x' color={Color.Gray} title={strings.tagsLabel.clientCreator} />
          </TagStyled>
        )}
      </H3>

      <Separator small />
      <Hbox.Item>
        {props.item.client && (
          <BodySecondary title={props.item.client.name}>
            <FaIcon.User size='1x' color={Color.Gray} /> {props.item.client.name}
          </BodySecondary>
        )}
        <Separator small />
        <BodySecondary title={props.item.title}>{props.item.title}</BodySecondary>
        <Separator small />
        <BodySecondary>{props.item.product.steelName}</BodySecondary>
      </Hbox.Item>
      <Separator small />

      {props.item.product.dimensional && (
        <>
          <Hbox.Item>
            <BodySecondary>
              <FaIcon.Measure size='1x' color={Color.Gray} /> {props.item.product.dimensional}
            </BodySecondary>
          </Hbox.Item>
          <Separator small />
        </>
      )}
      {props.item.flow.label && (
        <>
          <Hbox.Item>
            <BodySecondary>
              <FaIcon.Search size='1x' color={Color.Gray} /> {props.item.flow.label}
            </BodySecondary>
          </Hbox.Item>
          <Separator small />
        </>
      )}
      {props.item?.plants && (
        <>
          <Hbox.Item>
            <BodySecondary>
              <FaIcon.Industry size='1x' color={Color.Gray} /> {formatPlant(props.item.plants.split(', '))}
            </BodySecondary>
          </Hbox.Item>
          <Separator small />
        </>
      )}
      <Hbox.Item>
        <BodySecondary>
          <FaIcon.Clock size='1x' color={Color.Gray} /> {format(props.item.createdDate, 'dd/MM/yyyy - HH:mm')}
        </BodySecondary>
      </Hbox.Item>
    </Link>
  );
};
