import * as React from 'react';
import { NotesDataProps } from '@app/models/crm.model';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
//import { SelectField } from '@atomic/atm.select';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
//import { Form } from '@atomic/obj.form';
import { CrmStrings } from '../../crm.string';
import {
  CrmBoxWrapper,
  CrmContentHeaderWrapper,
  CrmContentInfosWrapper,
  CrmContentWrapper,
  CrmNoteText,
} from './crmContent.style';

interface CrmNotesProps {
  data: NotesDataProps;
  loading: boolean;
}

export const CrmNotes = (props: CrmNotesProps) => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.NOTES} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>

      <CrmContentInfosWrapper>
        {props?.data && (
          <Hbox>
            <Separator small />
            <CrmBoxWrapper>
              {props.data.infos.length > 0 &&
                props.data.infos.map((note, index) => (
                  <Hbox.Item key={`text-${index}`}>
                    <Body>
                      {note.value[0].date} - {note.value[0].hour} - {note.value[0].createdBy}
                    </Body>
                    <CrmNoteText>{note.value[0].text}</CrmNoteText>
                    <Separator />
                  </Hbox.Item>
                ))}
            </CrmBoxWrapper>
          </Hbox>
        )}
      </CrmContentInfosWrapper>
    </CrmContentWrapper>
  );
};
