import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { TabIndex } from '@app/models/order.model';
import { ScheduleModalComponent } from './schedule-modal.component';
import { SchedulePhase, UserScheduleType } from './schedule.constants';
import { initClientBpsSearch, setCurrentTab, setSchedulePhase } from './store/schedule.store';
import { getLayoutDefault, getSchedules } from './store/thunks/schedule.thunks';

export const ScheduleModal = () => {
  const { openScheduleModal } = useSelector((state: RootState) => state.navigation);
  const { clientBpsSearch } = useSelector((state: RootState) => state.customerSearch);
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const { loadingSubmit, loadingDelete, loadingUpdate } = useSelector((state: RootState) => state.schedule);
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    dispatch(setCurrentTab(orderTabIndex === TabIndex.Stock ? UserScheduleType.STOCK : UserScheduleType.ORDER_OPEN));
  }, [orderTabIndex]);

  useEffect(() => {
    if (clientBpsSelected?.length && openScheduleModal) {
      dispatch(getLayoutDefault());
    }
  }, [clientBpsSelected, openScheduleModal]);

  useEffect(() => {
    if ((!loadingSubmit && !loadingDelete && !loadingUpdate) || !openScheduleModal) {
      if (openScheduleModal) {
        dispatch(getSchedules());
      }
    }
  }, [loadingSubmit, loadingDelete, loadingUpdate, openScheduleModal]);

  useEffect(() => {
    if (openScheduleModal && clientBpsSearch.length) {
      dispatch(setSchedulePhase(SchedulePhase.List));
      dispatch(initClientBpsSearch(clientBpsSearch));
    }
  }, [openScheduleModal, clientBpsSearch]);

  return <ScheduleModalComponent />;
};
