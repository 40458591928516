import { GridReadyEvent } from 'ag-grid-community';
import { format, subDays } from 'date-fns';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AgGrid, useGridColumn } from '@app/modules/components';
import { AgGridWrapperStyled } from '@app/modules/components/ag-grid.style';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { GridDownload } from '@app/modules/components/grid-download/grid-download.component';
import { NoDataPlaceholder } from '@app/modules/components/no-data.placeholder';
import { QuoteCellShimmer } from '@app/modules/quote/quote-cell.shimmer';
import { LabelSimilarMaterialsModal } from '@app/modules/quote/technical-analysis/technical-analysis.style';
import { FlashMessageTargetName } from '@app/providers';
import { Frame } from '@atomic/atm.frame';
import { Hbox, Separator } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { FilterQuoteComplain } from '../filterQuoteComplain';
import { gridCaseComplainColumnsDef } from '../grid-case-complain-column.definition';
import { useComplainDownload } from '../hooks/useComplainDownload';
import { MenuComplainPage } from '../menu-complain-page.component';
import { QuoteComplainModalStrings } from '../quoteComplainModal.strings';

// eslint-disable-next-line complexity
export const ReportPage: React.FC = () => {
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const gridEvent = React.useRef<GridReadyEvent>(null);

  const [isGridReady, setIsGridReady] = React.useState(false);
  const Strings = QuoteComplainModalStrings[userInfo.language].modalDownload;

  const [startDate, setStartDate] = React.useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = React.useState(new Date());
  const [dataFile, setDataFile] = React.useState(null);

  const onErrorCasesList = () => {
    show('alert', Strings.errorDataCases);
  };
  const onSuccessData = data => {
    setDataFile(data.complainCases);
  };
  const { data, refetch: getDataDownload, isFetching } = useComplainDownload(
    {
      dataIni: startDate ? formatDate(startDate, 'start') : null,
      dataFim: endDate ? formatDate(endDate, 'end') : null,
    },
    onSuccessData,
    onErrorCasesList,
  );

  const columnsDef = useGridColumn(gridCaseComplainColumnsDef(dataFile?.columns ? dataFile?.columns : []));
  const hasColumns = data?.complainCases?.columns !== undefined;

  const handleGridReady = (event: GridReadyEvent) => {
    gridEvent.current = event;
    setIsGridReady(true);
  };

  React.useEffect(() => {
    dataFile && isGridReady && columnsDef.setGrid(gridEvent.current);
    setDataFile(data?.complainCases);
  }, [isGridReady, dataFile]);

  return (
    <>
      <Frame>
        <Grid fluid>
          <Row>
            <Col md={5} xs={12}>
              <MenuComplainPage tabIndex={1} />
            </Col>
            <Col md={7} xs={12}></Col>
          </Row>
        </Grid>
      </Frame>
      <Grid fluid>
        <Row mt>
          <Col xs={12}>
            <Row>
              <Col xs={6}>
                <FilterQuoteComplain
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  showDateFilter={true}
                  getDataDownload={getDataDownload}
                  strings={Strings}
                />
              </Col>
              <Col xs={6}>
                <Hbox hAlign='flex-end'>
                  {hasColumns && (
                    <>
                      <Hbox.Separator />
                      <Hbox.Item noGrow>
                        <GridDownload gridEvent={gridEvent.current} filename={Strings.filename} />
                      </Hbox.Item>
                    </>
                  )}
                </Hbox>
              </Col>
              <Separator />
            </Row>

            <Row>
              <Col xs={12}>
                <LoadingState loading={isFetching} data={dataFile?.total > 0}>
                  <LoadingState.Shimmer>
                    <QuoteCellShimmer />
                  </LoadingState.Shimmer>

                  <LoadingState.NoData>
                    <NoDataPlaceholder message={'Nenhum dado encontrado'} />
                  </LoadingState.NoData>

                  <Separator />
                  <AgGridWrapperStyled>
                    <LabelSimilarMaterialsModal>{`${dataFile?.total}${Strings.total}`}</LabelSimilarMaterialsModal>

                    <AgGrid
                      onGridReady={handleGridReady}
                      rowData={dataFile?.rows}
                      loading={isFetching && !!dataFile?.columns.length}
                    />
                  </AgGridWrapperStyled>
                </LoadingState>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </>
  );
};

function formatDate(date: Date, type: string) {
  return `${format(date, 'yyyy-MM-dd')}T${type === 'start' ? '00:01' : '23:59'}:00.000+0000`;
}
