import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Form, FormData } from '@atomic/obj.form';
import { Grid } from '@atomic/obj.grid';
import { setSelectedRows } from '../order/order-tabs/MI/tab-open/store/order-tab-open.store';
import { OrderPriorizationEvents } from '../order/order.analytics';
import { ICriticalItem, IOrderPriorizationData, IOrderPriorizationTableData } from './order-priorization.interfaces';
import { ModalMessage } from './partials/modal-message/modal-message.component';
import { OrderPriorizationActions } from './partials/order-priorization-actions/order-priorization-actions.component';
import { OrderPriorizationHeader } from './partials/order-priorization-header/order-priorization-header.component';
import { OrderPriorizationSuccess } from './partials/order-priorization-success/order-priorization-success.component';
import { OrderPriorizationTable } from './partials/order-priorization-table/order-priorization-table.container';
import { RemoveItemModal } from './partials/remove-item-modal/remove-item-modal.component';
import { setRows, setShowConfirmModal } from './store/order-priorization.store';
import { postCriticalItemAndUpdateOrderTabOpen } from './store/thunks/post-critical-item-and-update-order-tab-open.thunk';
import { handleErrorConfigured, handleSuccessConfigured } from './utils/handler-utils';
import { getUpdatedSelectedRows, mapCriticalItemsRows, mapSelectedRows } from './utils/utils';

interface OrderPriozationPageProps {
  gridEvent?: MutableRefObject<GridReadyEvent | null>;
}

export const OrderPriozationPage: React.FC<OrderPriozationPageProps> = ({ gridEvent }) => {
  const { selectedRows } = useSelector((state: RootState) => state.orderTabOpen);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const {
    rows,
    criticalItemInfo,
    activedCriticalItem,
    showSuccessPage,
    showConfirmModal,
    alreadyOpenedModalMessage,
  } = useSelector((state: RootState) => state.orderPriorization);
  const mappedSelectedRows: IOrderPriorizationTableData[] = mapSelectedRows(selectedRows, activedCriticalItem);
  const mappedCriticalItemsRows: IOrderPriorizationTableData[] = mapCriticalItemsRows(activedCriticalItem);
  const dispatch = useDispatch<AppThunkDispatch>();

  const canProceed =
    criticalItemInfo?.qtyAvailableCriticalItem >= rows.length &&
    criticalItemInfo?.qtyAvailableCriticalItem > 0 &&
    rows.length > 0;
  const showWarningMessage =
    criticalItemInfo?.qtyAvailableCriticalItem < rows.length ||
    (criticalItemInfo?.qtyAvailableCriticalItem === 0 && rows.length > criticalItemInfo?.qtyAvailableCriticalItem);

  useEffect(() => {
    LogAnalytics.pageView(OrderPriorizationEvents.OrderPriorizationView);
    if (mappedSelectedRows?.length) {
      dispatch(setRows(mappedSelectedRows));
    }

    if (mappedCriticalItemsRows?.length > 0) {
      dispatch(setSelectedRows(getUpdatedSelectedRows(mappedCriticalItemsRows, selectedRows)));
    }
  }, []);

  useEffect(() => {
    if (showWarningMessage && !alreadyOpenedModalMessage) {
      dispatch(setShowConfirmModal(true));
    }
  }, [rows]);

  const handleError = (formData: FormData<IOrderPriorizationData>, criticalItems: ICriticalItem[], error: any) => {
    handleErrorConfigured({ userInfo, formData, error, criticalItems });
    dispatch(setShowConfirmModal(false));
  };

  const handleSuccess = (formData: FormData<IOrderPriorizationData>) => {
    handleSuccessConfigured({
      selectedRows,
      userInfo,
      formData,
    });
  };

  return (
    <>
      <OrderPriorizationHeader gridEvent={gridEvent} />
      {!showSuccessPage && (
        <Grid fluid>
          <OrderPriorizationActions selectedItems={rows?.length} canProceed={canProceed} />
          <Form
            onSubmit={(formData: FormData<IOrderPriorizationData>) => {
              if (!canProceed) {
                dispatch(setShowConfirmModal(false));
                return;
              }
              dispatch(postCriticalItemAndUpdateOrderTabOpen({ formData, handleSuccess, handleError }));
            }}
          >
            <OrderPriorizationTable
              tableRows={rows}
              criticalItemsRows={mappedCriticalItemsRows}
              slotNumbers={criticalItemInfo?.qtyAvailableCriticalItem - rows?.length}
              canProceed={canProceed}
            />
            {showConfirmModal && (
              <ModalMessage
                tableRows={rows}
                slotNumbers={criticalItemInfo?.qtyAvailableCriticalItem}
                showWarningMessage={showWarningMessage}
              />
            )}
          </Form>
        </Grid>
      )}
      <RemoveItemModal />
      {showSuccessPage && <OrderPriorizationSuccess gridEvent={gridEvent} />}
    </>
  );
};
