import * as React from 'react';
import { AttributesValueOptions } from '@app/models';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import {
  MultipleSelectIconStyled,
  MultipleSelectStyled,
  OptionsBoxStyled,
  OptionStyled,
} from './multiple-select.component.style';

interface MultipleSelectFieldProps {
  options: AttributesValueOptions[];
  selected?: string;
  setSelected: (options: string) => void;
}

export const MultipleSelectField: React.FC<MultipleSelectFieldProps> = props => {
  const [opened, setOpened] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const selectRef = React.useRef(null);
  const [offset, setOffset] = React.useState<number>();

  const handleMouseDown = (event: MouseEvent) => {
    const isOutsideClick = opened && selectRef && !selectRef.current.contains(event.target);
    if (isOutsideClick) {
      setOpened(false);
    }
  };
  useEventListener('mousedown', handleMouseDown);

  React.useEffect(() => {
    props.setSelected(selectedOptions.toString());
  }, [selectedOptions]);

  const handleValueChange = (id, checked) => {
    if (checked) {
      setSelectedOptions(prev => [...prev, id]);
    } else {
      setSelectedOptions(prev => prev.filter(opt => opt !== id));
    }
  };

  React.useEffect(() => {
    const offsetTop = selectRef.current?.offsetParent.offsetTop;
    const parentHeight = selectRef.current?.parentElement.parentElement.parentElement.clientHeight;
    setOffset(parentHeight - offsetTop);
  });

  return (
    <MultipleSelectStyled onClick={() => setOpened(true)} ref={selectRef}>
      <MultipleSelectIconStyled />
      <OptionsBoxStyled show={opened} offsetTop={offset}>
        {props.options.map(option => (
          <OptionStyled key={option.key}>
            <Hbox>
              <Hbox.Separator />
              <Hbox.Item noGrow vAlign='center'>
                <CheckboxField id={option.key} onValueChange={handleValueChange}>
                  <Hbox.Item>
                    <Body>{option.label}</Body>
                  </Hbox.Item>
                </CheckboxField>
                <Hbox.Separator />
              </Hbox.Item>
              <Hbox.Separator />
            </Hbox>
          </OptionStyled>
        ))}
      </OptionsBoxStyled>
    </MultipleSelectStyled>
  );
};
