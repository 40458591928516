import { Select } from '@gerdau/hefesto';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AdminBPsFilterOptionsId } from '@app/models/admin.model';
import { AdminStrings } from '@app/modules/admin/admin.string';
import {
  setBPFormGroupSelectedOptions,
  setBPFormSelectedOptions,
  setFormBpFilterOption,
} from '@app/modules/admin/store/admin.store';
import { BpFilterOptions } from '../../utils/utils';
import { SelectedBpsChips } from '../selected-bps-chips/selected-bps-chips.component';
import {
  BPsFilterContainer,
  BPsFilterStyled,
  BPsFilterWrapperStyled,
  BPsSearchStyled,
  BPsTextContainer,
} from './bp-filter.component.styles';

export const BPsFilterForm: React.FC = () => {
  const { bpFilter, bpBPOptionsFormSelect, bpCorpGroupOptionsFormSelect } = useSelector(
    (state: RootState) => state.admin,
  );
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = AdminStrings.pt;

  const handleSelectFilter = (filterType: string) => {
    dispatch(setBPFormSelectedOptions([]));
    dispatch(setBPFormGroupSelectedOptions([]));
    dispatch(setFormBpFilterOption(filterType));
  };

  const handleSelectBP = (selectedBPs, nameSelector) => {
    const selectedFormBP = [];
    const selectedCorGroup = [];

    if (selectedBPs.length) {
      selectedBPs.forEach(s => {
        if (nameSelector) {
          const selectedBPData = userInfo.bps.find(bp => bp._id === s);

          selectedFormBP.push({
            code: selectedBPData.customerId,
            name: selectedBPData.customerName,
            id: selectedBPData._id,
            label: `${selectedBPData.customerId} - ${selectedBPData.customerName}`,
            corpGroup: selectedBPData.corporateGroup,
            corpGroupName: selectedBPData.corporateGroupName,
          });
        } else {
          const filteredCorporateGroupBPs = userInfo.bps.filter(bp => bp.corporateGroup === s);

          if (filteredCorporateGroupBPs?.length) {
            filteredCorporateGroupBPs.forEach(f => {
              selectedFormBP.push({
                code: f.customerId,
                name: f.customerName,
                id: f._id,
                label: `${f.customerId} - ${f.customerName}`,
                corpGroup: f.corporateGroup,
                corpGroupName: f.corporateGroupName,
              });

              if (!selectedCorGroup.includes(f.corporateGroup)) {
                selectedCorGroup.push(f.corporateGroup);
              }
            });
          }
        }
      });

      dispatch(setBPFormSelectedOptions(selectedFormBP));
      dispatch(setBPFormGroupSelectedOptions(selectedCorGroup));
    } else {
      dispatch(setBPFormSelectedOptions([]));
      dispatch(setBPFormGroupSelectedOptions([]));
    }
  };

  return (
    <BPsFilterContainer>
      <BPsFilterWrapperStyled>
        <BPsFilterStyled>
          <Select
            label={strings.common.filter}
            name='bpFilterSelect'
            value={bpFilter.filterOption}
            onSelect={e => handleSelectFilter(e?.toString())}
            options={BpFilterOptions}
          />
        </BPsFilterStyled>
        <BPsSearchStyled>
          {bpFilter.filterOption === AdminBPsFilterOptionsId.Bp ? (
            <Select
              isMultipleSelect
              label={strings.common.corpGroupSelect}
              name='bpFilterCorpNameSelect'
              value={bpFilter.selectedOptions?.map(s => s.id)}
              disabled={!bpFilter.filterOption || bpFilter.filterOption !== AdminBPsFilterOptionsId.Bp}
              onSelect={e => handleSelectBP(e, true)}
              options={bpBPOptionsFormSelect}
            />
          ) : (
            <Select
              isMultipleSelect
              label={strings.common.corpGroupSelect}
              name='bpFilterCorpGroupSelect'
              value={bpFilter.selectedCorpGroupOptions}
              disabled={!bpFilter.filterOption || bpFilter.filterOption !== AdminBPsFilterOptionsId.CorpGroup}
              onSelect={e => handleSelectBP(e, false)}
              options={bpCorpGroupOptionsFormSelect}
            />
          )}
        </BPsSearchStyled>
      </BPsFilterWrapperStyled>
      <BPsTextContainer>
        <SelectedBpsChips />
      </BPsTextContainer>
    </BPsFilterContainer>
  );
};
