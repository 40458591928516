import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const WizardProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-top: ${Spacing.Small};

  progress {
    background-color: ${Color.Gray};
    border: 0;
    border-radius: 2px;
    width: 100%;
    height: 4px;
    margin-bottom: 5px;
  }
  progress::-webkit-progress-bar {
    background-color: ${Color.Gray};
  }
  progress::-webkit-progress-value {
    background-color: ${Color.Purple};
  }
`;
