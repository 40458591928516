import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { CrmFilterData, filterFields } from '@app/models/crm.model';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { Button } from '@atomic/atm.button';
import { H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import {
  CrmAdvancedFilterWrapper,
  CrmHiddenFilterWrapper,
  CrmMainFilterButtonWrapper,
  CrmMainFilterWrapper,
} from './crmAdvancedFilter.style';
import { CrmAdvancedFilterFields } from './crmAdvancedFilterFields';
import { CrmAdvancedFilterTags } from './crmAdvancedFilterTags';

export interface CrmFilterProps {
  currentFilter: CrmFilterData;
  setCurrentFilter: (props: CrmFilterData) => void;
  handleEditFilters: (value: string, key: string, action: string) => void;
  emptyFieldValue: (key: string) => void;
  onSubmit: (data: any) => void;
}

export const CrmAdvancedFilter = (props: CrmFilterProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [hideFilters, setHideFilters] = React.useState(true);

  const showHideFiels = () => {
    if (hideFilters) {
      setHideFilters(false);
    } else {
      setHideFilters(true);
    }
  };

  return (
    <CrmAdvancedFilterWrapper>
      <Form onSubmit={props.onSubmit}>
        <CrmMainFilterWrapper key='normal-filters'>
          <H2>{QuoteStrings[userInfo.language].filterModal.searchFilter}</H2>
          {filterFields(userInfo.language).mainFields.map((field, index) => (
            <React.Fragment key={`f-${index}`}>
              <CrmAdvancedFilterFields
                label={field.label}
                name={field.key}
                type={field.type}
                onBlur={props.handleEditFilters}
                onFocus={props.emptyFieldValue}
              />
              <Separator small />
              {props.currentFilter && (
                <CrmAdvancedFilterTags
                  mb={20}
                  dataKey={field.key}
                  data={props.currentFilter[field.key]}
                  handleEditFilters={props.handleEditFilters}
                />
              )}
            </React.Fragment>
          ))}
        </CrmMainFilterWrapper>
        <CrmHiddenFilterWrapper key='extra-filters' hide={hideFilters}>
          {filterFields(userInfo.language).extraFields.map((field, index) => (
            <React.Fragment key={`fe-${index}`}>
              <CrmAdvancedFilterFields
                label={field.label}
                name={field.key}
                type={field.type}
                onBlur={props.handleEditFilters}
                onFocus={props.emptyFieldValue}
              />
              <Separator small />
              {props.currentFilter && (
                <CrmAdvancedFilterTags
                  mb={20}
                  dataKey={field.key}
                  data={props.currentFilter[field.key]}
                  handleEditFilters={props.handleEditFilters}
                />
              )}
            </React.Fragment>
          ))}
        </CrmHiddenFilterWrapper>
        <CrmMainFilterButtonWrapper>
          <Hbox.Item grow hAlign='center'>
            <Button kind='secondary' expanded onClick={() => showHideFiels()}>
              {hideFilters ? 'Exibir mais filtros' : 'Ocultar filtros'}
            </Button>
          </Hbox.Item>
          <Separator small />
          <Hbox.Item grow hAlign='center'>
            <Button type='submit' expanded>
              Pesquisar
            </Button>
          </Hbox.Item>
        </CrmMainFilterButtonWrapper>
      </Form>
    </CrmAdvancedFilterWrapper>
  );
};
