import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IScheduleClient } from '@app/models/schedule.model';
import { toogleSelectClient } from '@app/modules/components/schedule-modal/store/schedule.store';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Label } from '@atomic/atm.typography';
import { FontSize, FontWeight } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { OptionItens, OptionsWrapper } from './scheudle-bps-selections.style';

interface ScheduleBpsSelectionOptionsParams {
  setShowOptions: (value: boolean) => void;
  filteredCustomerSearch: IScheduleClient[];
}

export const ScheduleBpsSelectionOptions: React.FC<ScheduleBpsSelectionOptionsParams> = ({
  setShowOptions,
  filteredCustomerSearch,
}) => {
  const ref = useRef(null);

  const dispatch = useDispatch();

  const handleCheck = (code: string) => {
    dispatch(toogleSelectClient(code));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current) {
      const isOutsideClick = ref && !ref.current.contains(event.target);

      if (isOutsideClick) {
        setShowOptions(false);
      }
    }
  };

  useEventListener('click', handleClickOutside);

  if (!filteredCustomerSearch.length) {
    return null;
  }

  return (
    <OptionsWrapper ref={ref}>
      {filteredCustomerSearch.map((bp: IScheduleClient) => (
        <OptionItens onClick={() => handleCheck(bp.code)} key={bp.key}>
          <CheckboxField checked={bp.selected} id={bp.code} />
          <Label weight={FontWeight.Normal} size={FontSize.XSmall}>{`${bp.code} - ${bp.name} - ${bp.city}`}</Label>
        </OptionItens>
      ))}
    </OptionsWrapper>
  );
};
