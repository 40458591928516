import { Checkbox } from '@gerdau/hefesto';
import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { RoleDisplayName, RoleInternalName, TabInternalName } from '@app/models/admin.model';
import { setGqRoleConfig } from '@app/modules/admin/store/admin.store';
import { updateRole } from '@app/modules/admin/store/thunk/profile-update.thunk';
import { addOrRemoveItem } from '@app/utils/array-helper';
import {
  AdminPermissionsRoleCol,
  PermissionRoleColCell,
  PermissionRoleTitleColCell,
} from '../../admin-profile-view.styles';
import { getTabIdList } from '../../utils/utils';

export const AdminProfileGqRoleCol: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { tabChips, gqRoleConfig } = useSelector((state: RootState) => state.admin);

  const handleChange = (tagName: string) => {
    const tab = { _id: tabChips.filter(t => t.tag === tagName)[0]._id, tag: tagName };
    const newTabArray = addOrRemoveItem(tagName, 'tag', gqRoleConfig.tabs, tab);
    const updatedGqRoleConfig = { _id: gqRoleConfig._id, profile: gqRoleConfig.profile, tabs: newTabArray };
    dispatch(setGqRoleConfig(updatedGqRoleConfig));
    dispatch(updateRole({ uid: gqRoleConfig._id, profile: gqRoleConfig.profile, tabs: getTabIdList(newTabArray) }));
  };

  return (
    <AdminPermissionsRoleCol>
      <PermissionRoleTitleColCell>
        <Tag variant='neutral'>{gqRoleConfig?.profile === RoleInternalName.Gq && RoleDisplayName.Gq}</Tag>
      </PermissionRoleTitleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqDashboardCb'
          checked={
            gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.Dashboard)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Dashboard);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqQuotationtCb'
          checked={
            gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.Quotation)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Quotation);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqOrderInputCb'
          checked={
            gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.OrderInput)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.OrderInput);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqOrderCb'
          checked={gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.Order)[0]}
          onChange={() => {
            handleChange(TabInternalName.Order);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqFinanceCb'
          checked={gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.Finance)[0]}
          onChange={() => {
            handleChange(TabInternalName.Finance);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqManifestCb'
          checked={gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.Manifest)[0]}
          onChange={() => {
            handleChange(TabInternalName.Manifest);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqIsaManagerCb'
          checked={
            gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.IsaManager)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.IsaManager);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqCrmCb'
          checked={gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.Crm)[0]}
          onChange={() => {
            handleChange(TabInternalName.Crm);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqSurrogateCb'
          checked={
            gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.Surrogate)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Surrogate);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqLackOfLoadCb'
          checked={
            gqRoleConfig?.tabs?.length && !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.LackOfLoad)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.LackOfLoad);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='gqSchedulingCompletedCb'
          checked={
            gqRoleConfig?.tabs?.length &&
            !!gqRoleConfig.tabs.filter(t => t.tag === TabInternalName.SchedulingCompleted)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.SchedulingCompleted);
          }}
        />
      </PermissionRoleColCell>
    </AdminPermissionsRoleCol>
  );
};
