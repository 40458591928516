import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 2px solid ${Color.GrayLight};
  padding-top: ${Spacing.Medium};

  button {
    width: 136px;
  }
`;
