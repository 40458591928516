import * as React from 'react';
import { LogAnalytics } from '@app/core/analytics';
import { NotificationItem, NotificationPanelSteps } from '@app/models/notification-center.model';
import { NotificationConfiguration } from '@app/models/notification-configuration.model';
import { NotificationEvents } from '@app/modules/components/notification-center/notification.Analytics';
export interface NotificationStore {
  notification: NotificationItem[];
  configuration: NotificationConfiguration;
  notificationPanelOpen: boolean;
  notificationLength: number;
  notificationConfigured: boolean;
  currentStep: NotificationPanelSteps;
  setCurrentStep: (value: NotificationPanelSteps) => void;
  toggle: () => void;
  setConfiguration: (configuration: NotificationConfiguration) => void;
  setNotification: (notification: NotificationItem[]) => void;
  setNotificationPanelOpen: (value: boolean) => void;
}

export const useNotificationStore = (): NotificationStore => {
  const [notification, setNotification] = React.useState<NotificationItem[]>([]);

  const [configuration, setConfiguration] = React.useState<NotificationConfiguration>();
  const [notificationPanelOpen, setNotificationPanelOpen] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState<NotificationPanelSteps>(NotificationPanelSteps.Notification);

  const notificationLength = notification ? notification.length : 0;
  const notificationConfigured = !!configuration && configuration.showNotificationConfig;

  const toggle = () => {
    if (!notificationPanelOpen) {
      LogAnalytics.click({ tipo: NotificationEvents.OpenNotificationsPanelClick });
    }
    setNotificationPanelOpen(prevState => !prevState);
  };

  return {
    notification,
    configuration,
    notificationPanelOpen,
    notificationLength,
    notificationConfigured,
    currentStep,
    setCurrentStep,
    setConfiguration,
    setNotificationPanelOpen,
    setNotification,
    toggle,
  };
};
