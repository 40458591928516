export interface IOrderTabShippedMaritimeStrings {
  title: string;
  filename: string;
  error: string;
  from: string;
  to: string;
  search: string;
}

interface IOrderTabShippedMaritimeLanguages {
  pt: IOrderTabShippedMaritimeStrings;
  es: IOrderTabShippedMaritimeStrings;
  in: IOrderTabShippedMaritimeStrings;
}

const pt: IOrderTabShippedMaritimeStrings = {
  title: 'Embarcado',
  filename: 'Pedidos embarcados',
  error: 'Ocorreu um erro ao carregar os dados da aba Embarcado! Por favor, tente novamente.',
  from: 'de',
  to: 'até',
  search: 'Consultar',
};

const es: IOrderTabShippedMaritimeStrings = {
  title: 'Pedidos Abordó',
  filename: 'Ordenes embarcadas',
  error: '¡Ocurrió un error al cargar datos desde la pestaña Embarcados! Inténtalo de nuevo.',
  from: 'desde',
  to: 'hasta',
  search: 'Consultar',
};

const ing: IOrderTabShippedMaritimeStrings = {
  title: 'Shipped Orders',
  filename: 'Shipped',
  error: 'An error occurred while loading data from the Shipped tab! Please try again.',
  from: 'from',
  to: 'to',
  search: 'Search',
};

export const Strings: IOrderTabShippedMaritimeLanguages = {
  pt,
  es,
  in: ing,
};
