import { Checkbox } from '@gerdau/hefesto';
import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { RoleDisplayName, RoleInternalName, TabInternalName } from '@app/models/admin.model';
import { setPedRoleConfig } from '@app/modules/admin/store/admin.store';
import { updateRole } from '@app/modules/admin/store/thunk/profile-update.thunk';
import { addOrRemoveItem } from '@app/utils/array-helper';
import {
  AdminPermissionsRoleCol,
  PermissionRoleColCell,
  PermissionRoleTitleColCell,
} from '../../admin-profile-view.styles';
import { getTabIdList } from '../../utils/utils';

export const AdminProfilePedRoleCol: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { tabChips, pedRoleConfig } = useSelector((state: RootState) => state.admin);

  const handleChange = (tagName: string) => {
    const tab = { _id: tabChips.filter(t => t.tag === tagName)[0]._id, tag: tagName };
    const newTabArray = addOrRemoveItem(tagName, 'tag', pedRoleConfig.tabs, tab);
    const updatedPedRoleConfig = { _id: pedRoleConfig._id, profile: pedRoleConfig.profile, tabs: newTabArray };
    dispatch(setPedRoleConfig(updatedPedRoleConfig));
    dispatch(updateRole({ uid: pedRoleConfig._id, profile: pedRoleConfig.profile, tabs: getTabIdList(newTabArray) }));
  };

  return (
    <AdminPermissionsRoleCol>
      <PermissionRoleTitleColCell>
        <Tag variant='neutral'>{pedRoleConfig?.profile === RoleInternalName.Ped && RoleDisplayName.Ped}</Tag>
      </PermissionRoleTitleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedDashboardCb'
          checked={
            pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.Dashboard)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Dashboard);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedQuotationtCb'
          checked={
            pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.Quotation)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Quotation);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedOrderInputCb'
          checked={
            pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.OrderInput)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.OrderInput);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedOrderCb'
          checked={pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.Order)[0]}
          onChange={() => {
            handleChange(TabInternalName.Order);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedFinanceCb'
          checked={
            pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.Finance)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Finance);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedManifestCb'
          checked={
            pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.Manifest)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Manifest);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedIsaManagerCb'
          checked={
            pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.IsaManager)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.IsaManager);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedCrmCb'
          checked={pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.Crm)[0]}
          onChange={() => {
            handleChange(TabInternalName.Crm);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedSurrogateCb'
          checked={
            pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.Surrogate)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Surrogate);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedLackOfLoadCb'
          checked={
            pedRoleConfig?.tabs?.length && !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.LackOfLoad)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.LackOfLoad);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='pedSchedulingCompletedCb'
          checked={
            pedRoleConfig?.tabs?.length &&
            !!pedRoleConfig.tabs.filter(t => t.tag === TabInternalName.SchedulingCompleted)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.SchedulingCompleted);
          }}
        />
      </PermissionRoleColCell>
    </AdminPermissionsRoleCol>
  );
};
