export const SLICE_NAME = 'schedule';

export const SCHEDULE_TYPE = {
  MONTHLY: 'monthly-schedule',
  WEEKLY: 'weekly-schedule',
  DAILY: 'daily-schedule',
};

export const scheduleTypeToOccurenceType = {
  [SCHEDULE_TYPE.DAILY]: 0,
  [SCHEDULE_TYPE.WEEKLY]: 1,
  [SCHEDULE_TYPE.MONTHLY]: 2,
};

export const occurenceTypeToScheduleType = {
  0: SCHEDULE_TYPE.DAILY,
  1: SCHEDULE_TYPE.WEEKLY,
  2: SCHEDULE_TYPE.MONTHLY,
};

export const weekDays = ['0', '1', '2', '3', '4', '5', '6'];

export enum UserScheduleType {
  ORDER_OPEN = 0,
  STOCK = 1,
}

export const mapScheduleTypeToName = {
  [UserScheduleType.ORDER_OPEN]: 'Carteira',
  [UserScheduleType.STOCK]: 'Estoque',
};

export enum ScheduleEvents {
  CreateScheduleSuccess = 'CreateScheduleSuccess',
  CreateScheduleError = 'CreateScheduleError',
  UpdateScheduleSuccess = 'UpdateScheduleSuccess',
  UpdateScheduleError = 'UpdateScheduleError',
  DeleteScheduleSuccess = 'DeleteScheduleSuccess',
  DeleteScheduleError = 'DeleteScheduleError',
}

export enum SchedulePhase {
  List = 'List',
  Form = 'Form',
  SelectColumns = 'SelectColumns',
  Success = 'Success',
  SuccessDelete = 'SuccessDelete',
}

export enum LayoutBelongs {
  CART = 'cart',
  STOCK = 'stock',
}

export const SUCCESS_PHASE_CUSTOM_MODAL_WIDTH = '750px';
export const CUSTOM_MODAL_WIDTH = '1200px;';
