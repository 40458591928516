import styled, { StyledFunction } from 'styled-components';
import { Color } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';

interface AccordionCollapsibleProps {
  expanded: boolean;
  contentHeight: number;
  overflowVisible: boolean;
}

interface AccordionHeaderStyledProps {
  hideBorder: boolean;
  backgroundColor: string;
}

export const AccordionStyled = styled.div``;

const styledCollapsible: StyledFunction<AccordionCollapsibleProps> = styled.div;

export const AccordionCollapsibleStyled = styledCollapsible`
  max-height: ${(props: AccordionCollapsibleProps) => (props.expanded ? 'auto' : '0px')};
  -moz-transition: max-height .3s ease-in-out;
  -ms-transition: max-height .3s  ease-in-out;
  -o-transition: max-height .3s  ease-in-out;
  -webkit-transition: max-height .3s  ease-in-out;
  transition: max-height .3s  ease-in-out;
  overflow:  ${(props: AccordionCollapsibleProps) => (props.overflowVisible ? 'visible' : 'hidden')};
`;

export const AccordionHeaderStyled = styled.header`
  padding: 0 16px;
  background-color: ${(props: AccordionHeaderStyledProps) =>
    props.backgroundColor ? props.backgroundColor : Color.GrayLight};
  border: ${(props: AccordionHeaderStyledProps) => (props.hideBorder ? 'none' : '1px solid transparent')};
  ${highlightStyle}
`;

export const AccordionContentStyled = styled.div`
  border: 1px solid transparent;
`;
