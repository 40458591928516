import { ColDef } from 'ag-grid-community';
import { GridColumnDefinition } from '@app/modules/components';

interface IAddColumnParams {
  columnDef: ColDef[];
  field: string;
  headerName: string;
  headerTooltip?: string;
  pinned?: boolean;
  filter?: boolean | string;
  width?: number;
  cellRenderer?: string;
  checkboxSelection?: boolean;
  hide?: boolean;
  sortIndex?: number;
}

export const addColumn = ({
  columnDef,
  field,
  headerName,
  headerTooltip,
  pinned,
  filter,
  width,
  cellRenderer,
  checkboxSelection,
  hide,
  sortIndex,
}: IAddColumnParams) => {
  if (!columnDef.find(col => col.field === field)) {
    const newColumn = columnFactory(
      field,
      headerName,
      headerTooltip,
      pinned,
      filter,
      width,
      cellRenderer,
      checkboxSelection,
      hide,
      sortIndex,
    );
    columnDef.push(newColumn);
  }
};

export const columnFactory = (
  field: string,
  headerName: string,
  headerTooltip: string,
  pinned: boolean,
  filter: boolean | string = 'agTextColumnFilter',
  width = 150,
  cellRenderer: string = null,
  checkboxSelection = false,
  hide = false,
  sortIndex: number,
) => {
  return {
    field: field,
    headerName: headerName,
    headerTooltip: headerTooltip || headerName,
    pinned: pinned,
    filter: filter,
    width: width,
    cellRenderer: cellRenderer,
    checkboxSelection: checkboxSelection,
    hide: hide,
    sortindex: sortIndex,
    ColId: field,
    sort: null,
    pivot: false,
    pivotIndex: null,
    rowGroup: false,
    rowGroupIndex: null,
  };
};

export const removeColumn = (columnDef, field: string): ColDef[] => {
  const columnDefAux = columnDef;
  return columnDefAux.filter(col => col.field !== field);
};

export const cloneReadOnlyTabs = (tabs: GridColumnDefinition[] = []): GridColumnDefinition[] => {
  const configuredTabs: GridColumnDefinition[] = [];

  tabs.forEach(tab => {
    const cols: ColDef[] = [];

    tab.cols.forEach(col => {
      cols.push(col);
    });

    const newTab: GridColumnDefinition = { name: tab.name, cols };

    configuredTabs.push(newTab);
  });

  return configuredTabs;
};
