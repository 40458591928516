/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { OrderInputEvents } from '@app/modules/order-input/order-input.analytics';
import { SpreasheetRow } from '@app/modules/order-input/order-input.interfaces';
import { getFileContentArray } from '@app/modules/order-input/phases/order-input-upload-spreasheet/order-input-upload-file/order-input-upload-utils';
import { hasConfigurarionSelector } from '@app/modules/order-input/store/order-input.selectors';
import { setOpenConfigColumnSpreadsheet, setSpreadSheetFileRequestItems } from '@app/modules/order-input/store/order-input.store';
import { postSpreadsheetLayoutThunk } from '@app/modules/order-input/store/thunks/post-spreadsheet-layout.thunk';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { FlexRow } from '@atomic/obj.box';
import { ConfigColumnsContext, ConfigColumnsContextState } from '../config-columns-modal.context';
import { ConfigColumnsModalStrings, Strings } from '../config-columns-modal.string';
import { validateAndConfirm } from '../utils';

export const ConfigColumnsModalFooter: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const userLanguage = userInfo.language;
  const strings: ConfigColumnsModalStrings = Strings[userLanguage];
  const { spreadSheetFile } = useContext<ConfigColumnsContextState>(ConfigColumnsContext);
  const {
    configColumnsSpreadsheet: {
      materialCodeColumn,
      amountColumn,
      unityAmountColumn,
      dateWishedColumn,
      itemIdentifierColumn,
      orderNumberColumn,
      initRow,
    },
    loadingPostConfigColumnsSpreadsheet,
  } = useSelector((state: RootState) => state.orderInput);
  const hasConfiguration = useSelector(hasConfigurarionSelector);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleSpreadSheetFileRequestItems = async () => {
    if (spreadSheetFile) {
      const columns = [
        materialCodeColumn,
        amountColumn,
        unityAmountColumn,
        dateWishedColumn,
        orderNumberColumn,
        itemIdentifierColumn,
      ];
      const fileContentArray: SpreasheetRow[] = await getFileContentArray(spreadSheetFile, columns, initRow);
      dispatch(setSpreadSheetFileRequestItems(fileContentArray));
    }
  };

  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const handleConfirm = () => {

    const fields: any = {
      materialCodeColumn,
      amountColumn,
      unityAmountColumn,
      dateWishedColumn,
      initRow,
    };

    if (validateAndConfirm({ strings, show, fields })) {

      LogAnalytics.click({
        tipo: OrderInputEvents.OrderInputConfigColumnsClick,
        materialCodeColumn,
        amountColumn,
        unityAmountColumn,
        dateWishedColumn,
        itemIdentifierColumn,
        initRow,
      });

      dispatch(postSpreadsheetLayoutThunk());
      handleSpreadSheetFileRequestItems();
    }
  };

  return (
    <FlexRow fullWidth hAlign='right'>
      <Button onClick={() => dispatch(setOpenConfigColumnSpreadsheet(false))} kind='secondary'>
        {strings.cancelButton}
      </Button>
      {hasConfiguration ? (
        <Button loading={loadingPostConfigColumnsSpreadsheet} onClick={() => handleConfirm()}>
          {strings.confirmButton}
        </Button>
      ) : (
          <Button loading={loadingPostConfigColumnsSpreadsheet} onClick={() => handleConfirm()}>
          {strings.saveButton}
        </Button>
      )}
    </FlexRow>
  );
};
