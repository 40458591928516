import styled from 'styled-components';
import { Color, FieldHeight, FieldHeightSmall, FontFamily, FontWeight, Spacing } from '@atomic/obj.constants';

enum AvatarDirection {
  left = '50% 50% 50% 0',
  right = '50% 50% 0 50%',
  none = '50%',
}

export interface AvatarStyledProps {
  direction: 'left' | 'right' | 'none';
  type?: 'primary' | 'secondary' | 'neutral';
  small?: boolean;
}

const AvatarType = {
  primary: Color.CallToAction,
  secondary: Color.Primary,
  neutral: Color.Secondary,
};

export const AvatarStyled = styled.span`
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: ${(props: AvatarStyledProps) => AvatarDirection[props.direction]};
  height: ${props => (!props.small ? FieldHeight : FieldHeightSmall)};
  width: ${props => (!props.small ? FieldHeight : FieldHeightSmall)};
  background-color: ${(props: AvatarStyledProps) => AvatarType[props.type]};
  color: ${Color.Primary};
  padding: ${Spacing.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  ${props => props.small && 'font-size: 10px;'}
`;
