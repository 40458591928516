import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { optionsME } from '@app/modules/components/customer-search/customer-search.constants';
import { IFilterOption } from '@app/modules/components/customer-search/customer-search.interfaces';
import { Label } from '@atomic/atm.typography';
import { FontWeight } from '@atomic/obj.constants';
import { IFilterTypeStrings, Strings } from '../../filter-type-drop-down.string';
import { MarketOrganization, Option } from '../filter-type-drop-down-options.style';

interface FilterTypeDropDownOptionsMEProps {
  handleOptionSelected: (option: IFilterOption) => void;
}

export const FilterTypeDropDownOptionsME: React.FC<FilterTypeDropDownOptionsMEProps> = ({ handleOptionSelected }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IFilterTypeStrings = Strings[userInfo.language];

  return (
    <>
      <MarketOrganization>{strings.titleME}</MarketOrganization>
      {optionsME.map((option, index: number) => (
        <Option data-testid={`filter-type-option-ME-${index}`} onClick={() => handleOptionSelected(option)} key={index}>
          <Label weight={FontWeight.Normal}>{option.name}</Label>
        </Option>
      ))}
    </>
  );
};
