import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { MutableRefObject } from 'react';
import { MOVE_COLUMN_DEBOUNCE_TIME, SAVE_LAYOUT_DEBOUNCE_TIME } from '@app/models/layout.model';
import { debounce } from '@app/utils/debounce.utils';

interface HandleGridReadyParams {
  e: GridReadyEvent;
  gridEvent: MutableRefObject<GridReadyEvent | null>;
}

export const handleGridReady = ({ e, gridEvent }: HandleGridReadyParams): void => {
  gridEvent.current = e;
};

interface HandleGridFilterParams {
  gridApi: GridApi;
  setFilteredRows: (value: any) => void;
}

export const handleGridFilter = ({ gridApi, setFilteredRows }: HandleGridFilterParams): void => {
  const rows = [];

  gridApi.forEachNodeAfterFilter(rowNode => {
    rows.push(rowNode.data);
  });

  setFilteredRows(rows);
};

export const handleDebounce = debounce<ColDef[]>((newValue: any, fn: any) => {
  fn?.(newValue);
}, SAVE_LAYOUT_DEBOUNCE_TIME);

export const handleDebounceMoveColumn = debounce<ColDef[]>((newValue: any, fn: any) => {
  fn?.(newValue);
}, MOVE_COLUMN_DEBOUNCE_TIME);
