import React from 'react';
import { DatePicker } from '@atomic/atm.date-picker';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
import { DateSelectedWrapper } from './data-filter-calendar.style';
interface DataFilterCalendarProps {
  onValueChange: (value) => void;
  selectedDate?: Date;
  minDate?: Date;
}
export const DataFilterCalendar: React.FC<DataFilterCalendarProps> = ({ selectedDate, onValueChange, minDate }) => {
  return (
    <DateSelectedWrapper>
      <DatePicker
        minDate={minDate}
        popperPlacement='bottom'
        defineDate
        startDate={selectedDate}
        onValueChange={onValueChange}
      />
      <FaIcon.Calendar color={Color.Primary} />
    </DateSelectedWrapper>
  );
};
