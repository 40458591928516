import React from 'react';
import { OrderInputPdfEmailRead } from '@app/modules/order-input/components/order-input-pdf-email-read/order-input-pdf-email-read.component';
import { OrderInputTemplateDownload } from '@app/modules/order-input/components/order-input-template-download/order-input-template-download';
import { H4, Caption, Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { NewTag, OptionWrapper } from './order-input-option.style';

interface OrderInputOptionProps {
  children: JSX.Element;
  title: string;
  subtitle: string;
  active?: boolean;
  templateDownload?: boolean;
  onClick: () => void;
  testid: string;
  isNew?: boolean;
  iaTag?: boolean;
}

export const OrderInputOption: React.FC<OrderInputOptionProps> = ({
  children,
  title,
  subtitle,
  active,
  templateDownload,
  onClick,
  testid,
  isNew,
  iaTag,
}) => {
  const handleTemplateDownloadComponent = () => {
    if (!templateDownload) {
      return null;
    }

    return (
      <FlexRow mt={Spacing.Small}>
        <OrderInputTemplateDownload />
      </FlexRow>
    );
  };
  const renderIATag = () => {
    if (!iaTag) {
      return null;
    }

    return (
      <FlexRow mt={Spacing.Small}>
        <OrderInputPdfEmailRead />
      </FlexRow>
    );
  };

  return (
    <OptionWrapper data-testid={testid} onClick={onClick} active={active}>
      <FlexColumn mr pl pt>
        <FlexRow mb>
          {children}
          {isNew && (
            <NewTag>
              <Body color={Color.White}>Novidade!</Body>
            </NewTag>
          )}
        </FlexRow>
        <H4>{title}</H4>
        <Caption mt={Spacing.Small}>{subtitle}</Caption>
        {handleTemplateDownloadComponent()}
        {renderIATag()}
      </FlexColumn>
    </OptionWrapper>
  );
};
