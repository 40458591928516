import { ColDef } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { ISetColumnVisiblePayload } from '@app/core/redux/grid-layout/grid-layout.interfaces';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { setColumnVisible, setTabColumns } from '@app/core/redux/grid-layout/grid-layout.store';
import { postGridLayout } from '@app/core/redux/grid-layout/thunks/grid-layout-post.thunk';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Link } from '@app/core/route';
import { UserAnalyticsType } from '@app/models/analytics.model';
import { HelpCenterPageState } from '@app/models/help-center.model';
import { LayoutTabs } from '@app/models/layout.model';
import { AppPath } from '@app/modules/app/route-constants';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, ButtonText } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Grid, Row, VSeparator } from '@atomic/obj.grid';
import { hasInternalAccessSelector } from '../../auth/store/auth.selectores';
import { OrdersStrings } from '../../order/orders.string';
import { Strings, IComponentsStrings } from '../components.string';
import { useFlashMessage } from '../flash-message.hook';
import { GridButtonWithIcon } from '../grid-button-with-icon.component';
import { UNAVAILABLE_COLUMNS } from '../grid-column-filter.constants';
import {
  CheckboxFieldWrapper,
  ColumnFieldsWrapper,
  GridColumnMenuStyled,
} from './grid-column-filter-body.component.style';
import { orderColumns } from './utils';

interface IGridColumnFilterBodyProps {
  tabName: LayoutTabs;
  helpRedirect?: HelpCenterPageState;
  show: boolean;
  setShow: (value: boolean) => void;
}

export const GridColumnFilterBody: React.FC<IGridColumnFilterBodyProps> = ({
  tabName,
  helpRedirect,
  show,
  setShow,
}) => {
  const [columnState, setColumnState] = useState([]);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IComponentsStrings = Strings[userInfo?.language];
  const columnDef = useSelector(columnsTabLayoutSelector(tabName, userInfo?.language));
  const hasInternalAccess = useSelector(hasInternalAccessSelector);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [showMessage] = useFlashMessage(FlashMessageTargetName.APP);

  useEffect(() => {
    setColumnState(columnDef);
  }, []);

  const handleCheck = (id: string, checked: boolean) => {
    LogAnalytics.click({
      tipo: 'FiltroCarteiraAberta' + id,
      kind: hasInternalAccess ? UserAnalyticsType.Employee : UserAnalyticsType.Client,
      userId: userInfo.oktaUid,
    });

    const payload: ISetColumnVisiblePayload = { tabName, fieldName: id, show: checked };
    dispatch(setColumnVisible(payload));
  };

  const handleSuccess = () => {
    showMessage('success', OrdersStrings[userInfo?.language]?.successSaveLayout);
    setColumnState(columnDef);
  };
  const handleSave = () => {
    setShow(false);
    dispatch(postGridLayout({ tab: tabName, handleSuccess, columns: columnDef, language: userInfo.language }));
  };

  const handleCancel = () => {
    dispatch(setTabColumns({ name: tabName, cols: columnState }));
    setShow(false);
  };

  const handleFilteredColumn = () => {
    const filteredColumns = [];

    columnDef.map(c => {
      if (!UNAVAILABLE_COLUMNS.includes(c.field)) {
        filteredColumns.push(c);
      }
    });

    return filteredColumns;
  };

  const filteredColumns: ColDef[] = handleFilteredColumn();
  const matrixColumns = orderColumns(filteredColumns);
  const hasSomeColSelected = filteredColumns.some(col => !col.hide);

  return (
    <GridColumnMenuStyled show={show}>
      <Grid fluid>
        <Row mt>
          <ColumnFieldsWrapper>
            {matrixColumns.map((item, index) => (
              <FlexColumn key={index}>
                {item.map(col => (
                  <CheckboxFieldWrapper key={col.field}>
                    <CheckboxField id={col.field} checked={!col.hide} onValueChange={handleCheck}>
                      {col.headerName}
                    </CheckboxField>
                    <VSeparator small />
                  </CheckboxFieldWrapper>
                ))}
              </FlexColumn>
            ))}
          </ColumnFieldsWrapper>
        </Row>
        <VSeparator />
        <Row>
          <FlexRow fullWidth hAlign='flex-end'>
            <Button kind='secondary' onClick={handleCancel}>
              {strings.cancel}
            </Button>
            <GridButtonWithIcon
              text={strings.save}
              disabled={!hasSomeColSelected}
              icon={FaIcon.Save}
              onClick={handleSave}
            />
          </FlexRow>
        </Row>
        <VSeparator />
        {!!helpRedirect && (
          <FlexRow>
            <FlexColumn hAlign='flex-end'>
              <Body>{strings.help}</Body>
            </FlexColumn>
            <Hbox.Separator small />
            <FlexColumn noGrow>
              <Link
                to={{
                  pathname: AppPath.HELP_CENTER,
                  state: { section: helpRedirect?.section, subsection: helpRedirect?.subsection },
                }}
              >
                <ButtonText>{strings.helpLink}</ButtonText>
              </Link>
            </FlexColumn>
          </FlexRow>
        )}
      </Grid>
      <VSeparator />
    </GridColumnMenuStyled>
  );
};
