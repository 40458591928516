import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TabIndex } from '@app/models/order.model';
import { setOpenScheduleModal } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { FlexRow } from '@atomic/obj.box';
import { SchedulePhase } from '../../../schedule.constants';
import { strings } from '../../../schedule.strings';
import { setEditMode, setSchedulePhase } from '../../../store/schedule.store';
import { ReturnButton } from './schedule-modal-success-return-button';

export const ScheduleModalSuccessFooter: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();
  const { orderTabIndex } = useSelector((state: RootState) => state.order);

  return (
    <FlexRow mt mb hAlign='flex-end'>
      <ReturnButton />
      <Button
        kind='secondary'
        loading={loading}
        onClick={() => {
          dispatch(setEditMode(false));
          dispatch(setSchedulePhase(SchedulePhase.List));
        }}
      >
        {strings.returnToList}
      </Button>
      <Button
        onClick={() => {
          dispatch(setEditMode(false));
          dispatch(setSchedulePhase(SchedulePhase.Form));
          dispatch(setOpenScheduleModal(false));
        }}
      >
        <FlexRow>{orderTabIndex === TabIndex.Stock ? strings.returnToStock : strings.returnToOrders}</FlexRow>
      </Button>
    </FlexRow>
  );
};
