import { AppPath } from '@app/modules/app/route-constants';

export const isQuoteChatComplainCrmPage = (): boolean => {
  return (
    location.pathname.includes(AppPath.QUOTE.BASE) ||
    location.pathname.includes(AppPath.CHAT.BASE) ||
    location.pathname.includes(AppPath.COMPLAIN.BASE) ||
    location.pathname.includes(AppPath.QUOTE.CRM) ||
    location.pathname.includes(AppPath.HELP_CENTER) ||
    location.pathname.includes(AppPath.DOCUMENTS) ||
    location.pathname.includes(AppPath.CATALOGS)
  );
};
