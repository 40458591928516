export enum AnnouncementModalEvents {
  GetAnnouncementError = 'GetAnnouncementError',
  PutAnnouncementError = 'PutAnnouncementError',
  PatchAnnouncementError = 'PatchAnnouncementError',
  GetLackOfChargeAnnouncementeError = 'GetLackOfChargeAnnouncementeError',
  PutLackOfChargeAnnouncementeError = 'PutLackOfChargeAnnouncementeError',
  PatchLackOfChargeAnnouncementeError = 'PatchLackOfChargeAnnouncementeError',
  GetSchedulingCompleteAnnouncementeError = 'GetSchedulingCompleteAnnouncementeError',
  PutSchedulingCompleteAnnouncementeError = 'PutSchedulingCompleteAnnouncementeError',
  PatchSchedulingCompleteAnnouncementeError = 'PatchSchedulingCompleteAnnouncementeError',
  GetPreventiveStopModalAnnouncementeError = 'GetPreventiveStopModalAnnouncementeError',
  PutPreventiveStopModalAnnouncementeError = 'PutPreventiveStopModalAnnouncementeError',
}
