/* eslint-disable complexity */
import * as React from 'react';
import { H1, H1Sub } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { LoadingState } from '@atomic/obj.loading-state';
import { CrmShimmer } from './crm.shimmer';

interface CrmHeaderProps {
  loading: boolean;
  manufacturingConsultationData: any;
}

export const CrmDetailsHeader: React.FC<CrmHeaderProps> = props => {
  return (
    <LoadingState data={!!props.manufacturingConsultationData} loading={props.loading}>
      <LoadingState.Shimmer>
        <CrmShimmer />
      </LoadingState.Shimmer>

      <Hbox vAlign='center'>
        <Hbox.Item>
          <H1>Análise Técnica</H1>
          <H1Sub>
            {props?.manufacturingConsultationData &&
              `${props.manufacturingConsultationData.infos[1].value} - ${props.manufacturingConsultationData.infos[2].value}`}
          </H1Sub>
        </Hbox.Item>
      </Hbox>
    </LoadingState>
  );
};
