/* eslint-disable prettier/prettier */
import  React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Credit } from '@app/models';
import { dateParser } from '@app/utils/date-parser';
import { FaIcon } from '@atomic/atm.fa-icon';
import { ProgressBar } from '@atomic/atm.progress-bar';
import { Body, Caption, DD, DL, DT, H3, InputLabel, InputValue } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, FontSize } from '@atomic/obj.constants';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { toCurrency } from '@atomic/obj.utils';
import { Strings, IFinanceStrings } from '../finance.string';
import { NonBindingCreditTipWrapperStyled } from './finance-limit-modal.style';

interface FinanceLimitModalProps {
  opened: boolean;
  onClose: () => void;
  creditQuery: { data: Credit[]; loading: boolean; error: any; nonBindingCredit: number };
}

export const FinanceLimitModal: React.FC<FinanceLimitModalProps> = props => {
  const [showNonBindingCreditTip, setShowNonBindingCreditTip] = useState(false);
  const { creditQuery } = props;
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IFinanceStrings = Strings[userInfo?.language];

  return (
    <Modal medium opened={props.opened} onClose={props.onClose}>
      <Grid fluid>
        {creditQuery &&
          creditQuery.data &&
          creditQuery.data.map(credit => (
            <FlexColumn mt mb key={credit.clientNumber}>
              <FlexColumn mb>
                <H3>{credit.clientName}</H3>
                <Body>{credit.clientNumber}</Body>
              </FlexColumn>
              <FlexRow>
                <DL horizontal>
                  <DT>{strings?.limit.expirationDate}&nbsp;</DT>
                  <DD>
                    {credit.limitValidDate && dateParser({
                      unformattedDate: credit.limitValidDate,
                      hideHours: true,
                      utc: true,
                      language: userInfo?.language,
                    })}
                  </DD>
                </DL>
              </FlexRow>
              <VSeparator />
              <VSeparator lineVisible />
              <FlexColumn>
                <FlexRow hAlign='space-between'>
                  <FlexRow>
                    <DL horizontal>
                      <DT>{strings?.limit.creditLimit}&nbsp;</DT>
                      <DD>{toCurrency(credit.limitCredit, userInfo?.language === 'in' ? 'USD' : 'BRL')}</DD>
                    </DL>
                  </FlexRow>
                  <FlexRow>
                    <DL horizontal>
                      <DT>{strings?.limit.nonBindingCredit}&nbsp;</DT>
                      <FaIcon.FlashInfo style={{ fontSize: FontSize.Large, marginTop: '1px' }}
                        onMouseOver={() => setShowNonBindingCreditTip(true)}
                        onMouseOut={() => setShowNonBindingCreditTip(false)}
                        color={Color.Primary}
                      />
                      {showNonBindingCreditTip && (
                        <NonBindingCreditTipWrapperStyled show={showNonBindingCreditTip}>
                          <Body>{strings?.limit.nonBindingCreditTip}</Body>
                        </NonBindingCreditTipWrapperStyled>
                      )}
                      <DD>{toCurrency(creditQuery?.nonBindingCredit?.toString(), userInfo?.language === 'in' ? 'USD' : 'BRL')}</DD>
                    </DL>
                  </FlexRow>
                </FlexRow>
                <VSeparator medium />
                <VSeparator medium />
                <InputLabel small>{strings?.limit.percentageUsed}</InputLabel>
                <ProgressBar percentage={credit.percentUsedCredt} />
                <VSeparator small />
                <FlexRow mb>
                  <FlexColumn>
                    <InputLabel small>{strings?.limit.openedInvoice}</InputLabel>
                    <InputValue small>
                      {Math.round(parseFloat(credit.percentOtherObligations))}% |{' '}
                      {toCurrency(credit.otherObligations, userInfo?.language === 'in' ? 'USD' : 'BRL')}
                    </InputValue>
                  </FlexColumn>
                  <FlexColumn hAlign='flex-end'>
                    <InputLabel small>{strings?.limit.batchOrders}</InputLabel>
                    <InputValue small>
                      {Math.round(parseFloat(credit.percentOpenInvoice))}% |{' '}
                      {toCurrency(credit.openInvoice, userInfo?.language === 'in' ? 'USD' : 'BRL')}
                    </InputValue>
                  </FlexColumn>
                </FlexRow>
                <FlexColumn mt hAlign='center'>
                  <Caption medium>{strings?.modal.note}</Caption>
                </FlexColumn>
              </FlexColumn>
            </FlexColumn>
          ))}
      </Grid>
    </Modal>
  );
};
