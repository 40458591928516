import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { HelpCenterSubsectionKeys } from '@app/models/help-center.model';
import { AdvancedFiltersButton } from '@app/modules/components/advanced-filters-button/advanced-filters-button.component';
import { FlashWrapperStatic } from '@app/modules/components/flash-wrapper-static/flash-wrapper-static.component';
import { OrderActions } from '@app/modules/components/order/order-actions.component';
import { OrderTabGrid } from '@app/modules/order/order-tab-grid.component';
import { setShowShippingRequest } from '@app/modules/shipping-request/store/shipping-request.store';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';
import { GridStockRowFilter } from '../../../grid-filters/grid-stock-row-filter.component';
import { OrderTabsActionsMI } from '../order-tabs-actions-MI.component';
import { AlertCellRenderer } from './cell-renderer/alert-cell/alert-cell.component';
import { DateStockEntryCellComponent } from './cell-renderer/date-stock-entry-cell/date-stock-entry-cell.component';
import { FinishStatusCellRenderer } from './cell-renderer/finish-status-cell/finish-status-cell.component';
import CustomTooltip from './order-tab-stock-tooltip.component';
import { tabName } from './order-tab-stock.constants';
import { IOrderTabStockStrings, Strings } from './order-tab-stock.string';
import { filteredDataSelector } from './store/order-tab-stock.selectors';
import { setFilter, setFilteredRows, setSelectedRows } from './store/order-tab-stock.store';
import { performRequest } from './store/order-tab-stock.thunks';
import { getStockOrderActions } from './utils/action-utils';
import { handleStockSelectionChange, handleShippingRequestClick } from './utils/handler-utils';

interface OrderTabStockComponentProps {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
}

export const OrderTabStockComponent: React.FC<OrderTabStockComponentProps> = ({ gridEvent }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabStockStrings = Strings[userInfo?.language];
  const { data, loading, error, filter, selectedRows } = useSelector((state: RootState) => state.orderTabStock);
  const filteredData = useSelector((state: RootState) => filteredDataSelector(state, data));
  const dispatch = useDispatch<AppThunkDispatch>();

  const showActionBar = !!selectedRows.length;

  return (
    <>
      <FlashWrapperStatic opened={!!error} type='alert' content={strings.error} />
      <Grid fluid>
        <OrderTabsActionsMI
          tabName={tabName}
          refresh={() => dispatch(performRequest())}
          gridEvent={gridEvent}
          helpCenterSubsection={HelpCenterSubsectionKeys.StockOrdersGlossary}
          filename={strings.filename}
          hasScheduleSend
        >
          <FlexRow fullWidth vAlign='baseline' mr={Spacing.Small}>
            <AdvancedFiltersButton />
            <GridStockRowFilter value={filter} onTagChanged={value => dispatch(setFilter(value))} />
          </FlexRow>
        </OrderTabsActionsMI>
        <OrderTabGrid
          handleSelectionChange={handleStockSelectionChange}
          gridEvent={gridEvent}
          performRequest={performRequest}
          setFilteredRows={setFilteredRows}
          setSelectedRows={setSelectedRows}
          strings={strings}
          data={filteredData}
          loading={loading}
          error={error}
          frameworkComponents={{
            customTooltip: CustomTooltip,
            dateRenderer: DateStockEntryCellComponent,
            alertCellRenderer: AlertCellRenderer,
            finishStatusCellRenderer: FinishStatusCellRenderer,
          }}
          tabName={tabName}
          showActionBar={showActionBar}
        />
      </Grid>
      {showActionBar && (
        <OrderActions
          loading={false}
          selectedNumber={selectedRows.length}
          actions={getStockOrderActions({
            strings,
            handleShippingRequestClick,
            setShowShippingRequest: value => dispatch(setShowShippingRequest(value)),
            selectedRows,
          })}
        />
      )}
    </>
  );
};
