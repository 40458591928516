import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { ChartGraphicHardness, TableGraphicHardness } from '@app/data/http/quote.dto';
import { Body } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';

export interface ChartTableProps {
  chart?: ChartGraphicHardness;
  table?: TableGraphicHardness;
}

export const ChartTable: React.FC<ChartTableProps> = props => {
  const countProductionGraphic = [];
  const countProductionGraphicValues = [];
  const kdeGraphic = [];
  const kdeGraphicValues = [];

  const convertIndexToDecimal = value => {
    if (parseInt(value) !== parseFloat(value)) {
      return value;
    } else {
      return `${value}.0`;
    }
  };
  if (props.chart?.count_production?.index) {
    props.chart?.count_production?.index.forEach((item, index) => {
      countProductionGraphic[convertIndexToDecimal(item)] = props.chart?.count_production.values[index];
    });
  } else {
    countProductionGraphic['0.0'] = 0;
  }
  if (props.chart?.kde?.index) {
    props.chart?.kde?.index.forEach((item, index) => {
      kdeGraphic[convertIndexToDecimal(item)] = props.chart?.kde.values[index];
    });
  } else {
    kdeGraphic['0.0'] = 0;
  }

  countProductionGraphic.sort();
  kdeGraphic.sort();

  const lsl = props.chart?.lsl;
  const media = props.chart?.media;
  const usl = props.chart?.usl;
  const mediaArray = [lsl, media, usl];

  const eixoX = [
    ...new Set([
      ...(props.chart?.count_production?.index ? props.chart.count_production.index : countProductionGraphic),
      ...(props.chart?.kde?.index ? props.chart.kde.index : kdeGraphic),
      ...mediaArray,
    ]),
  ];

  const sortEixoX = (a, b) => {
    return a - b;
  };
  eixoX.sort(sortEixoX);

  eixoX.forEach(item => {
    return countProductionGraphicValues.push(
      countProductionGraphic[convertIndexToDecimal(item)] ? countProductionGraphic[convertIndexToDecimal(item)] : null,
    );
  });

  eixoX.forEach(item => {
    return kdeGraphicValues.push(
      kdeGraphic[convertIndexToDecimal(item)] ? kdeGraphic[convertIndexToDecimal(item)] : null,
    );
  });

  const newData = [];

  eixoX.forEach(element => {
    const ob = {
      name: element,
      countProduction: countProductionGraphic[+element] ? countProductionGraphic[+element] : 0,
      kde: kdeGraphic[+element] ? kdeGraphic[+element] : 0,
    };
    newData.push(ob);
  });

  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      zoomType: 'xy',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: eixoX,
      crosshair: false,
      plotLines: [
        {
          label: {
            text: 'LSL',
            color: Color.Alert,
            rotation: 0,
          },
          value: eixoX.indexOf(lsl),
          color: Color.Alert,
          width: 2,
          dashStyle: 'shortDash',
        },
        {
          label: {
            text: 'Média',
            color: Color.Success,
            rotation: 0,
          },
          value: eixoX.indexOf(media),
          color: Color.Success,
          width: 2,
          dashStyle: 'shortDash',
        },
        {
          label: {
            text: 'USL',
            color: Color.Primary,
            rotation: 0,
          },
          value: eixoX.indexOf(usl),
          color: Color.CallToAction,
          width: 2,
          dashStyle: 'shortDash',
        },
      ],
    },
    yAxis: [
      {
        labels: {
          style: {
            color: Color.Primary,
          },
        },
        title: {
          text: 'Distribuição',
          style: {
            color: Color.Primary,
          },
        },
      },
      {
        labels: {
          style: {
            color: Color.GrayDark,
          },
        },
        title: {
          text: 'Número de eventos',
          style: {
            color: Color.GrayDark,
          },
        },
        opposite: true,
      },
    ],
    plotOptions: {
      area: {
        fillOpacity: 0.2,
        lineWidth: 1,
        step: 'center',
      },
      spline: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Número de eventos',
        type: 'column',
        yAxis: 1,
        data: countProductionGraphicValues,
        color: Color.Gray,
      },
      {
        name: 'Distribuição',
        type: 'line',
        marker: {
          symbol: 'circle',
        },
        data: kdeGraphicValues,
        color: Color.Primary,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const tableHardness = props.table && Object.entries(props.table);

  const tableRowLabels = {
    count: 'Número de Eventos',
    mean: 'Média',
    std: 'Desvio Padrão',
    min: 'Mínimo',
    max: 'Máximo',
    cp: 'Cp',
    cpk: 'Cpk',
    kde: 'Distributiva',
  };

  return (
    <>
      <Table collapse>
        <TR key='header' bordered>
          <TD color={Color.Secondary} bordered width={20}>
            <Body bold={600} centralized>
              Descrição
            </Body>
          </TD>
          <TD color={Color.Secondary} bordered width={20}>
            <Body bold={600} centralized>
              Valor medido (MES)
            </Body>
          </TD>
        </TR>

        {tableHardness &&
          tableHardness.map((line, index) => (
            <TR key={`th-${index}`} bordered>
              <TD bordered>
                <Body centralized>{tableRowLabels[line[0]]}</Body>
              </TD>
              {line[1].map((col, index2) => (
                <TD key={`td-${index2}`} bordered>
                  <Body centralized>{col}</Body>
                </TD>
              ))}
            </TR>
          ))}
      </Table>
      <VSeparator />
      <VSeparator />
      <Body bold={600}>Capabilidade valor ajustado</Body>
      <VSeparator />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};
