import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { SurrogateDataSource } from '@app/data/http/surrogate.datasource';
import { GetSurrogateParams } from '@app/models/surrogate.model';
import { SurrogateEvents } from '../../surrogate.analytics';
import { SLICE_NAME } from '../../surrogate.constants';
import { ISurrogateState } from '../../surrogate.interfaces';

export const getSurrogate = createAsyncThunk(
  `${SLICE_NAME}/getSurrogate`,
  async (params: GetSurrogateParams, thunkAPI) => {
    return await SurrogateDataSource.getSurrogate(params)
      .then(resp => resp)
      .catch(err => {
        LogAnalytics.error({
          tipo: SurrogateEvents.GetSurrogateError,
          errorMessage: err.response.message,
          errorDetails: JSON.stringify(err.response.data),
        });
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addGetSurrogateReducer = builder => {
  builder.addCase(getSurrogate.pending, state => {
    state.loading = true;
  });
  builder.addCase(getSurrogate.fulfilled, (state: ISurrogateState, action) => {
    const payload = action.payload;

    if (payload) {
      state._id = payload._id;
      state.from = payload.from;
      state.surrogatesTo = payload.surrogatesTo ? payload.surrogatesTo.split(',') : '';
      state.isIndeterminateTime = payload.isIndeterminateTime;
      state.expiresFrom = payload.expiresFrom;
      state.expiresTo = payload.expiresTo;
    }

    state.loading = false;
    state.error = false;
  });
  builder.addCase(getSurrogate.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });
};
