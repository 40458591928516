import { IOrderInputState } from '../../order-input.interfaces';
import { postFiles } from './post-files.thunk';
import { validateMaterials } from './validate-materials.thunk';

export const postOrderAndValidateMaterial = () => async (dispatch, getState) => {
  const { files }: IOrderInputState = getState().orderInput;

  if (files) {
    await dispatch(postFiles({ file: files }));
    await dispatch(validateMaterials());
  }

  return;
};
