import ColorFunc from 'color';
import styled from 'styled-components';
import { Color, Spacing, FontFamily, FontWeight } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
import { FloatingActionButtonProps } from './floating-action-button.component';

export const FloatingActionButtonStyled = styled.button`
  background-color: ${Color.Primary};
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border-width: 0;
  opacity: ${(props: FloatingActionButtonProps) => (props.disabled || props.loading ? 0.5 : 1)};
  padding: 10px;
  color: ${Color.White};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  font-size: 24px;
  text-align: center;
  ${highlightStyle}
  position: relative;
  letter-spacing: 1px;
  padding: 0;

  :focus {
    outline: 0;
  }

  :active {
    background-color: ${(props: FloatingActionButtonProps) =>
      props.outlined
        ? 'transparent'
        : ColorFunc(Color.Accessory)
            .darken(0.2)
            .hsl()
            .string()};
  }

  @media (hover: hover) {
    :hover {
      background-color: ${ColorFunc(Color.Accessory)
        .darken(0.2)
        .hsl()
        .string()};
    }
  }

  & + & {
    margin-left: ${Spacing.Small};
  }
`;

export const FloatingActionButtonContentStyled = styled.div`
  transition: all 0.2s ease-in-out;
  opacity: ${(props: { loading: boolean }) => (props.loading ? 0 : 1)};

  & .fa {
    color: ${Color.Primary};
    position: relative;
    left: 1px;
    line-height: 48px;
  }
`;

export const FloatingActionButtonSpinnerStyled = styled.span`
  display: ${(props: { loading: boolean }) => (props.loading ? 'inline-block' : 'none')} !important;
  position: absolute;
  right: calc(50% - 13px);
  top: calc(50% - 13px);
`;
