import * as React from 'react';
import { useStore } from '@app/core/global-store.service';
import { UserStore, useUserStore } from '@app/providers';

export const useVerifySession = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const [sessionVerify, setSessionVerify] = React.useState(null);

  React.useEffect(() => {
    setSessionVerify(user.accessToken);
  });

  return sessionVerify;
};
