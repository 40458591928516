import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { CommentsFullData } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Avatar } from '@atomic/atm.avatar';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { CommentCell } from './comment-cell';

interface TechnicalAnalysisExternalCommentsProps {
  canAddComments: boolean;
  canReplicateComments: boolean;
  comments: CommentsFullData;
  loading: boolean;
  initials: string;
  tempComment?: any;
  attributeKey?: string;
  currentComment: string;
  analysis: any;
  resume?: boolean;
  blockCommentButtons?: boolean;
  blockButton: boolean;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onReplicateClick: (value: boolean) => void;
  setCurrentComment: (value: string) => void;
  onDeleteComment: (key: string) => void;
  onEditComment: (key: string, value: string) => void;
}

export const TechnicalAnalysisExternalComments: React.FC<TechnicalAnalysisExternalCommentsProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language].comment;

  return (
    <>
      {props.comments?.externalComments.map(comment => (
        <CommentCell
          key={comment.key}
          showAvatar
          comment={comment}
          loading={comment.loading}
          onDeleteComment={() => props.onDeleteComment(comment.key)}
          onEditComment={value => props.onEditComment(comment.key, value)}
          canAddComments={props.canAddComments}
          resume={props.resume}
          blockCommentButtons={props.blockCommentButtons}
          blockButton={props.blockButton}
        />
      ))}

      {props.canAddComments && !props.resume ? (
        <>
          {props.loading && <Separator />}
          <Hbox>
            <Hbox.Item noGrow>
              <Avatar type='neutral' direction='none'>
                {props.initials.toUpperCase()}
              </Avatar>
            </Hbox.Item>
            <Hbox.Separator />

            <Hbox.Item>
              <TextField
                hasButton
                value={props.currentComment}
                onValueChange={props.setCurrentComment}
                placeholder={strings.placeholder}
                onKeyDown={props.onKeyDown}
              >
                <Button kind='link' onClick={props.onSubmit} loading={false}>
                  <FaIcon.Plane size='1x' />
                </Button>
              </TextField>
              <VSeparator />
              {!quoteIsInternalUser && props.analysis?.externalStatus?.key === 'PENDING' ? (
                ''
              ) : (
                <CheckboxField
                  id='replicate'
                  initialChecked={true}
                  onValueChange={(_id, checked) => props.onReplicateClick(checked)}
                >
                  {strings.replicateComment}
                </CheckboxField>
              )}
            </Hbox.Item>
          </Hbox>
        </>
      ) : (
        props.comments?.externalComments.length === 0 && <H3>{strings.noData}</H3>
      )}
    </>
  );
};
