import * as React from 'react';
import { Frame } from '@atomic/atm.frame';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';

export const TechnicalAnalysisAssetsShimmer: React.FC = () => {
  return (
    <>
      <TextShimmerBoxStyled height='50px' width='100%' margin='0' />
      <VSeparator />

      <Frame>
        <DefaultListCell bg>
          <Hbox>
            <Hbox.Item noGrow>
              <TextShimmerBoxStyled height='16px' width='16px' margin='0' />
            </Hbox.Item>
            <Hbox.Separator small />
            <Hbox.Item>
              <TextShimmerBoxStyled height='16px' width='30%' margin='0' />
            </Hbox.Item>
          </Hbox>
        </DefaultListCell>
        {[...Array(2)].map((_tab, index) => (
          <DefaultListCell key={index}>
            <TextShimmerBoxStyled height='16px' width='30%' margin='0' />
          </DefaultListCell>
        ))}
      </Frame>
      <VSeparator />
    </>
  );
};
