import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Form } from '@atomic/obj.form';
import { TechnicalAnalysisStatusUserList } from '../status/technical-analysis-user-list.component';

interface TechnicalAnalysisStatusFieldProps {
  label: string;
  name: string;
  value: string;
  show: boolean;
  users: string[];
  loading: boolean;
  onClose: () => void;
  onSelect: (value: string) => void;
  onValueChange: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  onHandleEmptyField: () => void;
}

export const TechnicalAnalysisResponsibleField: React.FC<TechnicalAnalysisStatusFieldProps> = props => {
  return (
    <>
      <Form.Field label={props.label} name={props.name} value={props.value} onValueChange={props.onValueChange}>
        <TextField hasButton onBlur={() => props.onBlur()} onFocus={() => props.onFocus()}>
          <Button kind='link' onClick={() => props.onHandleEmptyField()}>
            <FaIcon.Close size='1x' />
          </Button>
        </TextField>
      </Form.Field>
      <TechnicalAnalysisStatusUserList
        show={props.show}
        users={props.users}
        onClose={props.onClose}
        onSelect={props.onSelect}
        loading={props.loading}
      />
    </>
  );
};
