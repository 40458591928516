import React, { useContext } from 'react';
import {
  ConfigColumnsContext,
  ConfigColumnsContextState,
} from '@app/modules/order-input/components/config-columns-modal/config-columns-modal.context';
import { Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontSize, Spacing } from '@atomic/obj.constants';
import { OrderInputStrings } from '../../../order-input.string';
import { CustomDropzoneWrapperStyled } from './order-input-upload-file.style';
import { LoadedFile } from './partials/loaded-file/loaded-file';
import { OrderInputUploadFileButtons } from './partials/order-input-upload-file-buttons/order-input-upload-file-buttons';
import { OrderInputUploadFileDropZone } from './partials/order-input-upload-file-drop-zone/order-input-upload-file-drop-zone';
import { OrderInputUploadFileInfo } from './partials/order-input-upload-file-info/order-input-upload-file-info';

const Strings = OrderInputStrings.pt.selectItens;

interface StepThreeHeaderInfoProps {
  description?: string;
}

export const OrderInputUploadFile: React.FC<StepThreeHeaderInfoProps> = () => {
  const { spreadSheetFile } = useContext<ConfigColumnsContextState>(ConfigColumnsContext);

  return (
    <FlexColumn>
      <FlexRow>
        <Label mr={Spacing.Small} size={FontSize.Medium}>
          {Strings.stepThree.title}
        </Label>
        <OrderInputUploadFileInfo />
      </FlexRow>
      <CustomDropzoneWrapperStyled>
        {spreadSheetFile ? <LoadedFile /> : <OrderInputUploadFileDropZone />}
        <OrderInputUploadFileButtons />
      </CustomDropzoneWrapperStyled>
    </FlexColumn>
  );
};
