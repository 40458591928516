import { PayloadAction } from '@reduxjs/toolkit';
import { SCHEDULE_TYPE, weekDays } from '../../schedule.constants';
import { IScheduleState } from '../../schedule.interfaces';
import { setWeeklyOrDaily } from '../../utils';

export const formReducers = {
  setScheduleName: (state: IScheduleState, { payload }) => {
    state.scheduleName = payload;
  },
  setShowDaySelect: (state: IScheduleState, { payload }) => {
    state.showDaySelect = payload;
  },
  setScheduleType: (state: IScheduleState, { payload }) => {
    state.scheduleType = payload;
    if (payload === SCHEDULE_TYPE.WEEKLY) {
      state.selectedDaysOfWeek = [];
    }

    if (payload === SCHEDULE_TYPE.DAILY) {
      state.selectedDaysOfWeek = weekDays;
    }
  },
  setQuerySearch: (state: IScheduleState, { payload }) => {
    state.querySearch = payload;
  },
  toogleSelectClient: (state: IScheduleState, { payload }: PayloadAction<string>) => {
    const findIndex = state.clientBpsSchedule.findIndex(bp => bp.code === payload);
    state.clientBpsSchedule[findIndex].selected = !state.clientBpsSchedule[findIndex].selected;
  },
  removeSelectedBpSchedule: (state: IScheduleState, { payload }) => {
    const findIndex = state.clientBpsSchedule.findIndex(bp => bp.code === payload);
    state.clientBpsSchedule[findIndex].selected = false;
  },
  setTime: (state: IScheduleState, { payload }) => {
    state.time = payload;
  },
  setSelectedDays: (state: IScheduleState, { payload }: PayloadAction<string[]>) => {
    state.selectedDays = payload.sort((a, b) => parseInt(a) - parseInt(b));
  },
  addSelectedDaysOfWeek: (state: IScheduleState, { payload }) => {
    state.selectedDaysOfWeek.push(payload);
    setWeeklyOrDaily(state);
  },
  removeSelectedDaysOfWeek: (state: IScheduleState, { payload }) => {
    state.selectedDaysOfWeek = state.selectedDaysOfWeek.filter(day => day !== payload);
    setWeeklyOrDaily(state);
  },
  removeSelectedEmail: (state: IScheduleState, { payload }) => {
    state.selectedEmails = state.selectedEmails.filter(item => item.email !== payload);
  },
};
