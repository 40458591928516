import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';

export const OrderInputAmountWrapper = styled.div`
  overflow: auto;
  min-height: 80vh;
`;

export const OrderInputAmountGridRegion = styled(Grid)`
  margin-top: ${Spacing.XLarge};
  padding-bottom: 180px;
`;
