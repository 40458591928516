import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  FINANCE_PAGE_DATE_FILTER_FIELDS,
  FINANCE_PAGE_INPUT_FILTER_FIELDS,
} from '@app/modules/components/side-bar-filters/sidebar-filters.constants';
import { ISidebarFiltersStrings, Strings } from '@app/modules/components/side-bar-filters/sidebar-filters.strings';
import { DateFilterContent } from '../filter-accordion-content/date-filter-content/date-filter-content.component';
import { InputFilterContent } from '../filter-accordion-content/input-filter-content/input-filter-content.component';
import { FilterAccordion } from '../filter-accordion/filter-accordion.component';

export const FinanceFilters: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ISidebarFiltersStrings = Strings[userInfo.language];

  return (
    <>
      {FINANCE_PAGE_DATE_FILTER_FIELDS.map(key => (
        <FilterAccordion overflowVisible key={key} title={strings.financeTitles[key]}>
          <DateFilterContent filterKey={key} />
        </FilterAccordion>
      ))}
      {FINANCE_PAGE_INPUT_FILTER_FIELDS.map(key => (
        <FilterAccordion key={key} title={strings.financeTitles[key]} tooltipInfo='wip wip wip'>
          <InputFilterContent filterKey={key} />
        </FilterAccordion>
      ))}
    </>
  );
};
