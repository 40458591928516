import {
  hasSpecialChars,
  isCnsValid,
  isCpfPatternValid,
  isDateValid,
  isDDValid,
  isEmailValid,
  isMultipleEmailValid,
  isNameValid,
  isPasswordValid,
  isPhoneValid,
  isSocialSecurityNumberValid,
  isSSOEmail,
  isWeightValid,
  isZipCodeValid,
  maxDate,
} from './regex-validator';

export interface Validator {
  error: ValidationError;
  validationFn: any;
}

export interface ValidationError {
  name: string;
  message: string;
}

export class Validators {
  static MaxLength(length: number, message: string): Validator {
    return {
      error: {
        name: 'MaxLength',
        message,
      },
      validationFn: (value: any) => {
        return !value || value.toLocaleString().length <= length;
      },
    };
  }

  static LettersOnly(acceptSpace: boolean, message: string): Validator {
    return {
      error: {
        name: 'LettersOnly',
        message,
      },
      validationFn: (value: any) => {
        return (
          value &&
          value.match(acceptSpace ? /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\s]*$/ : /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]*$/)
        );
      },
    };
  }

  static BpList(message: string): Validator {
    return {
      error: {
        name: 'BpList',
        message,
      },
      validationFn: (value: string) => {
        const valueArray = value?.split(',');
        const numberArray = valueArray?.map(item => +item);
        return (
          numberArray && !numberArray?.some(isNaN) && !valueArray?.find(item => item.length !== 9 && item.length !== 10)
        );
      },
    };
  }

  static AdminBp(message: string): Validator {
    return {
      error: {
        name: 'AdminBp',
        message,
      },
      validationFn: (value: string) => {
        return !isNaN(+value) && !(value.length !== 3);
      },
    };
  }

  static SingleBp(message: string): Validator {
    return {
      error: {
        name: 'SingleBp',
        message,
      },
      validationFn: (value: string) => {
        return !isNaN(+value) && !(value.length !== 9 && value.length !== 10);
      },
    };
  }

  static CodeSeller(message: string): Validator {
    return {
      error: {
        name: 'CodeSeller',
        message,
      },
      validationFn: (value: string) => {
        return value.match(/^[a-z0-9]{3}$/i);
      },
    };
  }

  static MinLength(length: number, message: string): Validator {
    return {
      error: {
        name: 'MinLength',
        message,
      },
      validationFn: (value: any) => {
        return !value || value.toLocaleString().length >= length;
      },
    };
  }

  static WeightRegex(message: string): Validator {
    return {
      error: {
        name: 'WeightRegex',
        message,
      },
      validationFn: (value: any) => {
        return isWeightValid(value);
      },
    };
  }

  static Required(message?: string): Validator {
    return {
      error: {
        name: 'Required',
        message: message || 'Required',
      },
      validationFn: (value: any) => {
        if (value === '' || (value && value.length === 0)) {
          return false;
        }
        return (value && Object.keys(value).length > 0) || (value && !isNaN(value)) || value instanceof Date;
      },
    };
  }

  static HasAtLeastOne(values: (string | number)[], message?: string): Validator {
    return {
      error: {
        name: 'HasAtLeastOne',
        message: message || 'Deve conter pelo menos um',
      },
      validationFn: () => {
        return values.some(item => !!item);
      },
    };
  }

  static MaxValue(max: number, value?: string | number, message?: string): Validator {
    let numValue;
    if (typeof value === 'string') {
      numValue = Number(value);
    }
    const val = numValue ? (numValue === 99999 ? 0 : numValue) : 0;
    return {
      error: {
        name: 'LimitValue',
        message: message,
      },
      validationFn: () => {
        return val <= max;
      },
    };
  }

  static UniqueInArray(base: string[], message?: string): Validator {
    return {
      error: {
        name: 'UniqueInArray',
        message: message || 'Valor já existente',
      },
      validationFn: (value: any) => {
        return !base.some(item => item === value);
      },
    };
  }

  static EmailRegex(message: string): Validator {
    return {
      error: {
        name: 'EmailRegex',
        message,
      },
      validationFn: (value: any) => {
        return isEmailValid(value);
      },
    };
  }

  static EmailRegexWithEmpty(message: string): Validator {
    return {
      error: {
        name: 'EmailRegex',
        message,
      },
      validationFn: (value: any) => {
        if (value === '' || (value && value.length === 0)) {
          return false;
        }
        return !value || value === undefined ? true : isEmailValid(value);
      },
    };
  }

  static MultipleEmailRegex(message: string): Validator {
    return {
      error: {
        name: 'MultipleEmailRegex',
        message,
      },
      validationFn: (value: any) => {
        if (value === '' || (value && value.length === 0)) {
          return false;
        }
        return isMultipleEmailValid(value);
      },
    };
  }

  static CpfPatternRegex(message: string): Validator {
    return {
      error: {
        name: 'Cpf pattern regex',
        message,
      },
      validationFn: (value: any) => {
        return isCpfPatternValid(value);
      },
    };
  }

  static ZipCodeRegex(message: string): Validator {
    return {
      error: {
        name: 'Zip code regex',
        message,
      },
      validationFn: (value: any) => {
        return isZipCodeValid(value);
      },
    };
  }

  static PhoneRegex(message: string): Validator {
    return {
      error: {
        name: 'PhoneRegex',
        message,
      },
      validationFn: (value: any) => {
        return !value || isPhoneValid(value);
      },
    };
  }

  static DDRegex(message: string): Validator {
    return {
      error: {
        name: 'DDRegex',
        message,
      },
      validationFn: (value: any) => {
        return isDDValid(value);
      },
    };
  }

  static PasswordRegex(message?: string): Validator {
    return {
      error: {
        name: 'PasswordRegex',
        message:
          message ||
          'A senha deve conter pelo menos uma letra minúscula e uma maiuscula, um número e um caracter especial',
      },
      validationFn: (value: any) => {
        return isPasswordValid(value);
      },
    };
  }

  static FullNameRegex(message: string): Validator {
    return {
      error: {
        name: 'FullNameRegex',
        message,
      },
      validationFn: (value: any) => {
        return isNameValid(value);
      },
    };
  }

  static DateRegex(message: string): Validator {
    return {
      error: {
        name: 'DateRegex',
        message,
      },
      validationFn: (value: any) => {
        return isDateValid(value);
      },
    };
  }

  static MaxDate(max: Date, message: string): Validator {
    return {
      error: {
        name: 'MaxDate',
        message,
      },
      validationFn: (value: any) => {
        return !value || maxDate(max, value);
      },
    };
  }

  static IsNotEqualToField(valueToCompare: any, message: string): Validator {
    return {
      error: {
        name: 'IsNotEqualToField',
        message,
      },
      validationFn: (value: any) => {
        return value === valueToCompare;
      },
    };
  }

  // Validates Brazilian social security number
  static SocialSecurityNumberRegex(message?: string): Validator {
    return {
      error: {
        name: 'SocialSecurityNumberRegex',
        message,
      },
      validationFn: (value: any) => {
        return isSocialSecurityNumberValid(value);
      },
    };
  }

  static CnsRegex(message?: string): Validator {
    return {
      error: {
        name: 'CnsRegex',
        message,
      },
      validationFn: (value: any) => {
        return !value || isCnsValid(value);
      },
    };
  }

  static SpecialChars(message?: string): Validator {
    return {
      error: {
        name: 'SpecialChars',
        message,
      },
      validationFn: (value: any) => {
        return !hasSpecialChars(value);
      },
    };
  }

  static MinMoreThanMax(valueToCompare: any, message: string): Validator {
    const minValue = parseFloat(valueToCompare[0]);
    const maxValue =
      valueToCompare[1] !== '-'
        ? valueToCompare[1] !== '' && valueToCompare[1] !== null
          ? parseFloat(valueToCompare[1])
          : '-'
        : '-';

    return {
      error: {
        name: 'MinMoreThanMax',
        message,
      },
      validationFn: () => {
        if (maxValue === '-') {
          return true;
        } else {
          if (minValue >= maxValue) {
            return false;
          } else {
            return true;
          }
        }
      },
    };
  }

  static CheckSkusIsNumber(message?: string): Validator {
    return {
      error: {
        name: 'CheckSkusIsNumber',
        message,
      },
      validationFn: (value: any) => {
        const hasString = [];
        if (value) {
          const skus = value.split(',');
          skus.map((sku, index) => {
            if (isNaN(sku.trim())) {
              hasString[index] = sku;
            }
          });
        }

        if (hasString.length > 0) {
          return false;
        } else {
          return true;
        }
      },
    };
  }

  static CheckSkusFormat(message?: string): Validator {
    return {
      error: {
        name: 'CheckSkusFormat',
        message,
      },
      validationFn: (value: any) => {
        const hasError = [];
        if (value) {
          const skus = value.split(',');
          skus.map((sku, index) => {
            if (sku.trim().length !== 9) {
              hasError[index] = sku;
            }
          });
        }

        if (hasError.length > 0) {
          return false;
        } else {
          return true;
        }
      },
    };
  }

  static SSOEmailRegex(message: string): Validator {
    return {
      error: {
        name: 'SSOEmailRegex',
        message,
      },
      validationFn: (value: any) => {
        return isSSOEmail(value);
      },
    };
  }
}
