import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { useStore } from '@app/core/global-store.service';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { EventTypes } from '@app/models/analytics.model';
import { ErrorType } from '@app/models/error.model';
import { TruckTrackingRequestEvents } from '@app/modules/order/order.analytics';
import { UserStore, useUserStore } from '@app/providers';
import { Feedback, FeedbackAnalytics, FeedbackType } from '@atomic/obj.feedback';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { TruckTrackingDetails } from './partials/truck-tracking-details/truck-tracking-details.component';
import { TruckTrackingHeader } from './partials/truck-tracking-header/truck-tracking-header.component';
import { TruckTrackingLocalization } from './partials/truck-tracking-localization/truck-tracking-localization.componet';
import { TruckTrackingNoData } from './partials/truck-tracking-no-data/truck-tracking-no-data';
import { TruckTrackingUnavailable } from './partials/truck-tracking-unavailable/truck-tracking-unavailable';
import { getTruckTrackingInfoAndMap } from './store/thunk/get-truck-tracking-info-and-map.thunk';
import { TruckTrackingShimmer } from './truck-tracking.shimmer';
import { Strings, ITruckTrackingStrings } from './truck-tracking.string';

export const TruckTrackingPage: React.FC = () => {
  const { truckTrackingInfo, loading, loadingMap, error } = useSelector((state: RootState) => state.truckTracking);
  const [openFeedback, setOpenFeedback] = useState(true);
  const { plant, transportDocument, concatenatedBillingDocument } = truckTrackingInfo || {};
  const { user } = useStore<UserStore>(useUserStore);
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const strings: ITruckTrackingStrings = Strings.pt;
  const customerIds = clientBpsSelected.map(client => client.customerId).join(',');
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    LogAnalytics.pageView(TruckTrackingRequestEvents.TruckTrackingPageView);
    dispatch(
      getTruckTrackingInfoAndMap({
        clientRequest: transportDocument,
        plant,
        concatenatedBillingDocument: concatenatedBillingDocument,
        customerIds: customerIds,
      }),
    );
  }, []);

  useEffect(() => {
    if (error === ErrorType.NotFound) {
      LogAnalytics.truckTrackingFeedback({
        tipo: TruckTrackingRequestEvents.TruckTrackingNoTracking,
        identifier: user.uid,
        bp: customerIds,
        nf: truckTrackingInfo?.invoice,
      });
    } else if (error === ErrorType.Timeout) {
      LogAnalytics.truckTrackingFeedback({
        tipo: TruckTrackingRequestEvents.TruckTrackingTimeout,
        identifier: user.uid,
        bp: customerIds,
        nf: truckTrackingInfo?.invoice,
      });
    }
  }, [error]);

  const handleResponse = () => {
    if (loading || loadingMap) {
      return <TruckTrackingShimmer />;
    } else if (error && +error === ErrorType.NotFound) {
      return <TruckTrackingNoData />;
    } else if (error && +error !== ErrorType.NotFound) {
      return <TruckTrackingUnavailable />;
    } else if (truckTrackingInfo) {
      return (
        <Grid fluid>
          <Row>
            <Col xs={5}>
              <TruckTrackingDetails />
            </Col>
            <Col xs={7}>
              <TruckTrackingLocalization />
            </Col>
          </Row>
          <Feedback
            opened={openFeedback}
            setOpened={setOpenFeedback}
            context={FeedbackAnalytics.TruckTracking}
            type={FeedbackType.Numeric}
            message={strings.feedback}
            analyticsType={EventTypes.TruckTrackingFeedback}
          />
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {!loading && !loadingMap && !error && (
        <TruckTrackingHeader
          title={truckTrackingInfo?.descMaterial}
          buyOrderCode={truckTrackingInfo?.ocClient}
          clientMaterial={truckTrackingInfo?.codMaterialClient}
          loading={loading}
          brand={truckTrackingInfo?.brand}
        />
      )}
      <VSeparator />
      {handleResponse()}
    </>
  );
};
