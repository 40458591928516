import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { FlashWrapperStatic } from '@app/modules/components/flash-wrapper-static/flash-wrapper-static.component';
import { OrderTabGrid } from '@app/modules/order/order-tab-grid.component';
import { Grid } from '@atomic/obj.grid';
import { GridOpenInternationalRowFilter } from '../../../../grid-filters/grid-open-international-row-filter.component';
import { OrderTabsActionsME } from '../../order-tabs-actions-ME.component';
import { DeliveryStatusInternationalRenderer } from './cell-renderer/delivery-status-renderer-international.component';
import { tabName } from './order-tab-open-international.constants';
import { filterSelector } from './order-tab-open-international.selectors';
import { performRequest, setFilter, setFilteredRows } from './order-tab-open-international.store';
import { Strings, IOrderTabOpenInternationalStrings } from './order-tab-open-international.string';

interface OrderTabOpenInternationalComponentProps {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
}

export const OrderTabOpenInternationalComponent: React.FC<OrderTabOpenInternationalComponentProps> = ({
  gridEvent,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabOpenInternationalStrings = Strings[userInfo.language];
  const filter = useSelector(filterSelector);
  const { data, loading, error } = useSelector((state: RootState) => state.orderTabOpenInternational);
  const dispatch = useDispatch<AppThunkDispatch>();

  return (
    <>
      <FlashWrapperStatic opened={!!error} type='alert' content={strings.error} />
      <Grid fluid>
        <OrderTabsActionsME
          tabName={tabName}
          refresh={() => dispatch(performRequest())}
          gridEvent={gridEvent}
          filename={strings.filename}
        >
          <GridOpenInternationalRowFilter
            value={filter}
            onTagChanged={value => {
              dispatch(setFilter(value));
            }}
          />
        </OrderTabsActionsME>
        <OrderTabGrid
          gridEvent={gridEvent}
          performRequest={performRequest}
          setFilteredRows={setFilteredRows}
          strings={strings}
          data={data}
          loading={loading}
          error={error}
          tabName={tabName}
          frameworkComponents={{
            deliveryStatusRenderer: DeliveryStatusInternationalRenderer,
          }}
        />
      </Grid>
    </>
  );
};
