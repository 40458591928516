import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H2 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { BrandColor } from '@atomic/obj.constants';

export const ShippingRequestSuggestionrModalHeader: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IShippingRequestStrings = Strings[userInfo.language];

  return (
    <FlexColumn pl vAlign='center' fullWidth>
      <H2>
        <FlexRow hAlign='center' fullWidth>
          <FaIcon.Custom icon='exclamation-triangle' color={BrandColor.Blue} />
          <Hbox.Separator small />
          {strings.suggestionModal.title}
        </FlexRow>
      </H2>
    </FlexColumn>
  );
};
