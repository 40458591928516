import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const AttributeCriticalWrapper = styled.div`
  background-color: ${Color.GrayXLight};
  padding: ${Spacing.Small};
  width: 100%;
  display: flex;
  margin-top: ${Spacing.Medium};
  & > div > label {
    z-index: 0;
  }
`;
