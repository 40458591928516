import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderPriorizationDataSource } from '@app/data/http/order-priorization.datasource';
import { SalesOrderSummaryItem } from '@app/models';
import { IAuthState } from '@app/modules/auth/auth.interfaces';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import { FormData } from '@atomic/obj.form';
import { SLICE_NAME } from '../../order-priorization.constants';
import { ICriticalItem, IOrderPriorizationData, IOrderPriorizationState } from '../../order-priorization.interfaces';
import { getUpdatedOrderTabOpen } from './get-updated-order-tab-open.thunk';

export interface IPostCriticalItemsParams {
  formData: FormData<IOrderPriorizationData>;
  handleSuccess: (formData: FormData<IOrderPriorizationData>) => void;
  handleError: (formData: FormData<IOrderPriorizationData>, criticalItems: ICriticalItem[], error: any) => void;
}

export const postCriticalItems = createAsyncThunk(
  `${SLICE_NAME}/postCriticalItems`,
  async ({ formData, handleSuccess, handleError }: IPostCriticalItemsParams, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const customerIds = customerIdsJoinedSelector(thunkAPI.getState());
    const { userInfo }: IAuthState = state.auth;
    const { rows }: IOrderPriorizationState = state.orderPriorization;
    const selectedRows: SalesOrderSummaryItem[] = state.orderTabOpen.selectedRows;
    const criticalItems: ICriticalItem[] = [];

    for (let i = 0; i < selectedRows.length; i++) {
      const orderItem = selectedRows.find(s => s.ov === rows[i].ovItem);

      criticalItems.push({
        customerId: orderItem?.clientNumber,
        customerName: orderItem?.nameClient,
        ov: orderItem?.ov,
        ovItem: orderItem?.itemOv,
        oc: orderItem?.ocClient,
        codProdCliente: orderItem?.codMaterialClient,
        descMaterial: orderItem?.descMaterial,
        dataConf: orderItem?.dateMaxDeliveryClient,
        qtdConf: orderItem?.qtdConf.toString(),
        qtdSolic: orderItem?.qtdSolClient.toString(),
        qtdPendFat: orderItem?.qtyBillingOpen.toString(),
        qtdEstoq: orderItem?.qtyStock.toString(),
        mensagem: formData.data.order[i].messageClient ? formData.data.order[i].messageClient : '',
      });
    }

    try {
      const resp = await OrderPriorizationDataSource.postCriticalItems({
        clientName: userInfo.name,
        clientEmail: userInfo.email,
        emissionCode: customerIds,
        itensCriticos: criticalItems,
      });

      thunkAPI.dispatch(getUpdatedOrderTabOpen());
      handleSuccess(formData);
      return resp;
    } catch (err) {
      handleError(formData, criticalItems, err.response);
      thunkAPI.dispatch(setOpenErrorHandlerModal(true));
      return thunkAPI.rejectWithValue({ status: err.response.status });
    }
  },
);

export const addPostCriticalItemsReducers = builder => {
  builder.addCase(postCriticalItems.pending, state => {
    state.loading = true;
  });
  builder.addCase(postCriticalItems.fulfilled, state => {
    state.rows = [];
    state.loading = false;
    state.showSuccessPage = true;
  });
  builder.addCase(postCriticalItems.rejected, state => {
    state.loading = false;
  });
};
