import * as React from 'react';
import { LogAnalytics } from '@app/core/analytics';
import { SubsectionData, WelcomeData } from '@app/models/help-center.model';
import { Button } from '@atomic/atm.button';
import { Divisor } from '@atomic/atm.divisor';
import { Body, H2, H3, ListItem } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { HelpCenterAction } from './components/help-center-action.component';
import { HelpCenterMedia } from './components/help-center-media.component';
import { HelpCenterTable } from './components/help-center-table.component';
import { HelpCenterString } from './help-center.string';
import { HelperCenterQuestionAnalytics } from './helper-center.analytics';
import { SectionListWrapperStyled } from './section-cell.style';

interface HelpCenterContentProps {
  subsectionData: SubsectionData;
  welcome: WelcomeData;
  section?: string;
}

export const HelpCenterContent: React.FC<HelpCenterContentProps> = props => {
  const strings = HelpCenterString;

  if (props.subsectionData && props.subsectionData.hasComingSoonTag) {
    return null;
  } else {
    return (
      <Grid fluid>
        <VSeparator />
        {props.subsectionData ? (
          <>
            <H2 color={Color.GrayXDark}>
              {props.subsectionData.alternativeTitle
                ? props.subsectionData.alternativeTitle
                : props.subsectionData.title}
            </H2>
            {!!props.subsectionData.description && (
              <>
                <Body>{props.subsectionData.description}</Body>
                <VSeparator />
              </>
            )}
            <VSeparator />
            {props.subsectionData.informationBlocks.map((block, i) => (
              <div key={`${block.blockTitle}-${i}`}>
                {!!block.blockTitle && (
                  <>
                    <H3 color={Color.GrayXDark}>{block.blockTitle}</H3>
                    <VSeparator />
                  </>
                )}
                {!!block.paragraph && (
                  <>
                    <Body>{block.paragraph}</Body>
                    <VSeparator small />
                  </>
                )}
                {!!block.paragraphBold && (
                  <>
                    <Body bold={700} color={Color.GrayXDark}>
                      {block.paragraphBold}
                    </Body>
                    <VSeparator small />
                  </>
                )}
                {!!block.paragraphList && (
                  <SectionListWrapperStyled>
                    {block.paragraphList.map((item, index) => (
                      <ListItem key={`${props.subsectionData.key}-listitem-${index}`}>{item}</ListItem>
                    ))}
                  </SectionListWrapperStyled>
                )}
                {!!block.paragraphHtml && (
                  <>
                    <Body dangerouslySetInnerHTML={{ __html: block.paragraphHtml }} style={{ display: 'inline' }} />
                    <VSeparator small />
                  </>
                )}
                <VSeparator />

                {!!block.media && <HelpCenterMedia media={block.media} />}
                {!!block.table && <HelpCenterTable table={block.table} />}
                {!!block.action && <HelpCenterAction action={block.action} />}
                <VSeparator />
              </div>
            ))}
            <VSeparator />
            {!props.subsectionData.hasComingSoonTag && <></>}
            <Divisor />
            <VSeparator />
            <Hbox>
              <Hbox.Item>
                <Body>{strings.feedback.text}</Body>
              </Hbox.Item>
              <Hbox.Item noGrow>
                <Button
                  size='small'
                  kind='alertSecondary'
                  onClick={() =>
                    LogAnalytics.click({
                      type: HelperCenterQuestionAnalytics.HelperQuestionNoButton,
                      section: props.subsectionData.title,
                    })
                  }
                >
                  {strings.feedback.negative}
                </Button>
              </Hbox.Item>
              <Hbox.Separator small />
              <Hbox.Item noGrow>
                <Button
                  size='small'
                  kind='secondary'
                  onClick={() =>
                    LogAnalytics.click({
                      type: HelperCenterQuestionAnalytics.HelperQuestionYesButton,
                      section: props.subsectionData.title,
                    })
                  }
                >
                  {strings.feedback.afirmative}
                </Button>
              </Hbox.Item>
            </Hbox>
          </>
        ) : (
          <Row>
            <Col xs={12}>
              <H2>{props.welcome.title}</H2>
              <Body>{props.welcome.paragraph}</Body>
            </Col>
          </Row>
        )}
        <VSeparator />
      </Grid>
    );
  }
};
