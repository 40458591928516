import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  isEatonSelector,
  isGKNSelector,
} from '@app/modules/components/customer-search/store/customer-search.selectors';
import { PARTY_NUMBERS_GKN_ALERT } from '@app/modules/order/order-tabs/MI/tab-stock/order-tab-stock.constants';
import { PlantNames } from '@app/modules/shipping-request/shipping-request.constants';
import {
  IBPGroup,
  IPlant,
  IShippingRequestColumns,
  IShippingRequestRow,
} from '@app/modules/shipping-request/shipping-request.interfaces';
import {
  getTableColumnsDefinitions,
  handleOldSalesOrderAlert,
} from '@app/modules/shipping-request/shipping-request.utils';
import { selectedRowsByPlantSelector } from '@app/modules/shipping-request/store/shipping-request.selectors';
import { setChaPlant, setMogPlant, setPinPlant } from '@app/modules/shipping-request/store/shipping-request.store';
import { TD, TR } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants';
import { ShippingRequestOldSaleOrderWarningTR } from './partials/shipping-request-old-sale-order-warning-tr/shipping-request-old-sale-order-warning-tr.componet';
import { ShippingRequestTableBodyTD } from './partials/shipping-request-table-body-td/shipping-request-table-body-td.component';
import { ShippingRequestWarningTR } from './partials/shipping-request-warning-tr/shipping-request-warning-tr.componet';

interface IShippingRequestTableBodyProps {
  plant: string;
}

export const ShippingRequestTableBody: React.FC<IShippingRequestTableBodyProps> = ({ plant }) => {
  const [plantData, setPlantData] = useState<IBPGroup[]>([]);
  const tableRows: IShippingRequestRow[] = useSelector((state: RootState) => selectedRowsByPlantSelector(state, plant));
  const { data } = useSelector((state: RootState) => state.orderTabStock);
  const { mog, pin, cha } = useSelector((state: RootState) => state.shippingRequest);
  const isGKN = useSelector(isGKNSelector);
  const isEaton = useSelector(isEatonSelector);
  const columns = getTableColumnsDefinitions(isGKN, isEaton);
  const dispatch = useDispatch();

  const handleSetPlantData = (oldSalesOrderAlert: IPlant) => {
    switch (plant) {
      case PlantNames.MOGI:
        dispatch(setMogPlant(oldSalesOrderAlert));
        break;
      case PlantNames.PINDA:
        dispatch(setPinPlant(oldSalesOrderAlert));
        break;
      case PlantNames.CHARQ:
        dispatch(setChaPlant(oldSalesOrderAlert));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (data && tableRows) {
      const oldSalesOrderAlert = handleOldSalesOrderAlert(plant, data, tableRows);
      handleSetPlantData(oldSalesOrderAlert);
    }
  }, []);

  useEffect(() => {
    switch (plant) {
      case PlantNames.MOGI:
        if (mog) {
          setPlantData(mog.bpGroup);
        }
        break;
      case PlantNames.PINDA:
        if (pin) {
          setPlantData(pin.bpGroup);
        }
        break;
      case PlantNames.CHARQ:
        if (cha) {
          setPlantData(cha.bpGroup);
        }
        break;
      default:
        break;
    }
  }, [mog, pin, cha]);

  if (plantData.length < 0) {
    return null;
  } else {
    return (
      <>
        {plantData?.map(bpGroup => {
          return bpGroup.orderedShippingMaterialUserGroup?.map(group => {
            const lastShippingRequestItemIndex = group.ovs.length - 1;
            const lastShippingRequestItem = group.ovs[lastShippingRequestItemIndex];

            return group.ovs?.map((item, rowIndex) => {
              const hasAlert =
                item.breakHeat === 'Sim' &&
                PARTY_NUMBERS_GKN_ALERT.includes(item.customerProductCode) &&
                !item.removedAlert;
              const key = isGKN ? `${item.salesOrder}-${item.heat}` : rowIndex;
              const isSuggestionTR = group.hasAlert && !isGKN && lastShippingRequestItemIndex === rowIndex;

              return (
                <>
                  <TR key={key} bordered>
                    {columns?.map((column: IShippingRequestColumns) => (
                      <TD color={hasAlert && Color.LightYellow} key={column.field}>
                        <ShippingRequestTableBodyTD
                          item={item}
                          type={column.type}
                          value={item[column.field]}
                          hasSuggestion={group.hasAlert && !isGKN}
                        />
                      </TD>
                    ))}
                  </TR>
                  {hasAlert && <ShippingRequestWarningTR item={item} />}
                  {isSuggestionTR && (
                    <ShippingRequestOldSaleOrderWarningTR
                      plant={plant}
                      bp={bpGroup.bp}
                      material={item.material}
                      materialClientID={lastShippingRequestItem?.customerProductCode}
                    />
                  )}
                </>
              );
            });
          });
        })}
      </>
    );
  }
};
