import { IWindowDimension } from '@app/utils/screen-resize.hook';
import { ARROW_DIMENSIONS, TOOLTIP_OFFSET, TOOLTIP_WIDTH } from './customized-tooltip.constants';
import { IDimensionLayout, TooltipPlacement } from './customized-tooltip.interfaces';

export const getTooltipX = (childrenLayout: IDimensionLayout, hasOffset: boolean): number => {
  let xAxis = childrenLayout.width / 2 - TOOLTIP_OFFSET;

  if (!hasOffset) {
    xAxis += childrenLayout.x;
  }

  return xAxis;
};

export const getTooltipY = (childrenLayout: IDimensionLayout, hasOffset: boolean, customHeight: number): number => {
  const tooltipHeight = customHeight ? customHeight : childrenLayout.height;

  let yAxis = tooltipHeight + 2 * ARROW_DIMENSIONS;

  if (!hasOffset) {
    yAxis += childrenLayout.y;
  }

  return yAxis;
};
export const getTooltipPlacement = (windowDimensions: IWindowDimension, tooltipX: number): TooltipPlacement => {
  if (tooltipX + TOOLTIP_WIDTH > windowDimensions.width) {
    return 'overflow-right';
  }
  if (tooltipX < 0) {
    return 'overflow-left';
  }
  return 'fit';
};

export const getFitTooltipXInWindow = (
  windowDimensions: IWindowDimension,
  childrenLayout: IDimensionLayout,
  hasOffset: boolean,
): number => {
  const tooltipX = getTooltipX(childrenLayout, hasOffset);
  const tooltipPlacement = getTooltipPlacement(windowDimensions, tooltipX);

  if (tooltipPlacement === 'fit') {
    return tooltipX;
  } else if (tooltipPlacement === 'overflow-right') {
    return windowDimensions.width - TOOLTIP_WIDTH - TOOLTIP_OFFSET;
  } else {
    return 0;
  }
};

export const getArrowXPosition = (
  windowDimensions: IWindowDimension,
  childrenLayout: IDimensionLayout,
  hasOffset: boolean,
): number => {
  const fitTooltipXInWindow = getFitTooltipXInWindow(windowDimensions, childrenLayout, hasOffset);
  let xAxis = childrenLayout.width / 2 - fitTooltipXInWindow - TOOLTIP_OFFSET / 2;

  if (!hasOffset) {
    xAxis += childrenLayout.x;
  }

  return xAxis;
};

export const isSameLayout = (layout1: IDimensionLayout, layout2: IDimensionLayout): boolean => {
  return Object.keys(layout1).every(key1 => layout1[key1] === layout2[key1]);
};

export const getXAxis = (
  positionXFromRight: boolean,
  customRightAlign: number,
  windowDimensions: IWindowDimension,
  childrenLayout: IDimensionLayout,
  hasOffset: boolean,
): number => {
  if (positionXFromRight) {
    return customRightAlign;
  }

  return getFitTooltipXInWindow(windowDimensions, childrenLayout, hasOffset);
};
