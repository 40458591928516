import * as React from 'react';
import { TabItemStyled, TabItemTextStyled, TabStyled } from './tab.component.style';

interface TabContextProps {
  onTap: (index: number) => void;
  activeIndex: number;
}

const TabContext = React.createContext<TabContextProps>(null);

interface TabItemProps {
  index: number;
  to?: string;
}
const TabItem: React.FC<TabItemProps> = props => {
  const ctx = React.useContext(TabContext);

  const handleClick = (url?: string) => {
    if (url) {
      window.location.href = url;
    } else {
      ctx.onTap(props.index);
    }
  };

  return (
    <TabItemStyled>
      <TabItemTextStyled onClick={() => handleClick(props?.to)} active={props.index === ctx.activeIndex}>
        {props.children}
      </TabItemTextStyled>
    </TabItemStyled>
  );
};
TabItem.displayName = 'TabItem';

interface TabProps {
  initialIndex?: number;
  to?: string;
  onIndexChanged?: (index: number) => void;
}

export const Tab: React.FC<TabProps> & { Item: any } = props => {
  const [activeIndex, setActiveIndex] = React.useState(props.initialIndex);

  React.useEffect(() => {
    if (props.initialIndex) {
      setActiveIndex(props.initialIndex);
    }
  });

  const handleTap = (index: number) => {
    setActiveIndex(index);

    if (props.onIndexChanged) {
      props.onIndexChanged(index);
    }
  };

  const tabs = React.Children.map(props.children, (child: React.ReactElement, index) => {
    if (child?.type === TabItem) {
      return React.cloneElement(child, { index }); // Add index props to child
    }
    return null;
  });

  return (
    <TabContext.Provider value={{ onTap: handleTap, activeIndex }}>
      <TabStyled>{tabs}</TabStyled>
    </TabContext.Provider>
  );
};
Tab.Item = TabItem;

Tab.defaultProps = {
  initialIndex: 0,
};
