import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { GridButtonWithIcon } from '@app/modules/components/grid-button-with-icon.component';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';
import { IOrderPriorizationStrings, Strings } from '../../order-priorization.string';
import { setShowConfirmModal } from '../../store/order-priorization.store';

interface OrderPriorizationActionsProps {
  selectedItems: number;
  canProceed: boolean;
}

export const OrderPriorizationActions: React.FC<OrderPriorizationActionsProps> = ({ selectedItems, canProceed }) => {
  const strings: IOrderPriorizationStrings = Strings.pt;
  const { criticalItemInfo, activedCriticalItem, showConfirmBtn } = useSelector(
    (state: RootState) => state.orderPriorization,
  );
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const dispatch = useDispatch();
  const showRemoveMessage =
    selectedItems + activedCriticalItem?.length > criticalItemInfo?.qtyTotalAvailableCriticalItem;

  return (
    <FlexRow mt mb hAlign='space-between'>
      <FlexColumn noGrow>
        <FlexRow>
          <Body size={FontSize.Large}>{strings.page.currentAvailableItems(clientBpsSelected[0]?.corpGroupName)}</Body>
          <Body pl={Spacing.Small} size={FontSize.Large} bold={700} color={Color.Black}>
            {`${criticalItemInfo?.qtyAvailableCriticalItem} ${strings.page.labelQtyItem}`}
          </Body>
        </FlexRow>
        {activedCriticalItem?.length ? (
          <FlexRow>
            <Body size={FontSize.Large}>{strings.page.currentActivatedItems}</Body>
            <Hbox.Separator small />
            <Body size={FontSize.Large} bold={700} color={Color.SuccessMain}>
              {selectedItems === 0 || !showRemoveMessage
                ? `${activedCriticalItem?.length} ${strings.page.labelQtyItem}`
                : strings.page.labelChangeQtyItem(activedCriticalItem?.length)}
            </Body>
          </FlexRow>
        ) : (
          <FlexRow>
            <Body size={FontSize.Large}>{strings.page.currentSelectedItems}</Body>
            <Hbox.Separator small />
            <Body size={FontSize.Large} bold={700} color={canProceed ? Color.SuccessMain : Color.Alert}>
              {canProceed || selectedItems === 0
                ? `${selectedItems} ${strings.page.labelQtyItem}`
                : `${selectedItems} ${strings.page.removeItemsLabel}`}
            </Body>
          </FlexRow>
        )}
      </FlexColumn>
      {showConfirmBtn && !activedCriticalItem?.length && (
        <FlexRow>
          <GridButtonWithIcon
            text={strings?.page.confirm}
            icon={FaIcon.Check}
            kind='primary'
            disabled={!canProceed}
            onClick={() => dispatch(setShowConfirmModal(true))}
          />
        </FlexRow>
      )}
    </FlexRow>
  );
};
