import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { UserData, UserListAllData } from '@app/models/quote.model';
import { Separator } from '@atomic/obj.box';
import { isMultipleEmailValid } from '@atomic/obj.form/regex-validator';
import { TechnicalAnalysisResponsibleField } from './technical-analysis-responsible.field';

interface EmailFieldsComponentProps {
  profile: string;
  users?: UserListAllData;
  loading?: boolean;
  initialValue: UserData;
  handleResponsibles: (user: UserData, action: string) => void;
  handleShowNewField: () => void;
}

export const ResponsibleFieldsComponent: React.FC<EmailFieldsComponentProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [showList, setShowList] = React.useState(false);
  const [userResponsible, setUserResponsible] = React.useState(
    props?.initialValue ? `${props.initialValue.firstName} ${props.initialValue.lastName}` : '',
  );
  const profile = userInfo?.profile?.profile;

  React.useEffect(() => {
    if (isMultipleEmailValid(userResponsible)) {
      setShowList(false);
    }
  }, [userResponsible, showList]);

  const handleValueChange = (value: string) => {
    setUserResponsible(value);
  };

  const handleSelectUser = (value: string) => {
    setShowList(false);
    setUserResponsible(value);

    const list = props.users[profile.toLowerCase()];

    const userCheck = val => {
      const nameArray = value.split(' ');
      const name = nameArray.shift();
      const surname = nameArray.join(' ');
      if (`${val.firstName} ${val.lastName}` === `${name} ${surname}`) {
        return val;
      }
    };

    const newUser = list.find(userCheck);
    props.handleResponsibles(newUser, 'add');
    setUserResponsible('');
  };

  const findUser = value => {
    return value.toLowerCase().includes(userResponsible.toLowerCase());
  };

  const filterList = (list: UserListAllData, role: string) => {
    let formattedLists = [];
    formattedLists = list[role];
    formattedLists = formattedLists.map(item =>
      item.firstName !== '' ? `${item.firstName} ${item.lastName}` : item.email,
    );
    formattedLists.sort();

    return formattedLists.filter(findUser);
  };

  const handleBlur = () => {
    setShowList(false);
  };

  const handleFocus = () => {
    setShowList(true);
  };

  const handleEmptyField = () => {
    setShowList(false);
    setUserResponsible('');
    props.handleShowNewField();
  };

  return (
    <>
      <TechnicalAnalysisResponsibleField
        label={null}
        name='user'
        value={userResponsible}
        show={showList}
        users={filterList(props.users, profile.toLowerCase())}
        loading={props.loading}
        onValueChange={value => handleValueChange(value)}
        onClose={() => setShowList(false)}
        onSelect={value => handleSelectUser(value)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onHandleEmptyField={handleEmptyField}
      />
      <Separator small />
    </>
  );
};
