import * as React from 'react';
import { NotesDataPropsComplain } from '@app/models/crm.model';
import { CrmNoteText } from '@app/modules/quote/crm/crm-detail/components/crmContent.style';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { CrmStrings } from '../../crmComplain.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

interface CrmNotesProps {
  data: NotesDataPropsComplain;
  loading: boolean;
}

export const CrmNotes = (props: CrmNotesProps) => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.NOTES} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>

      {props?.data?.infos?.length > 0 ? (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />

            <Table collapse>
              <TR bordered>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.type}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.text}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.language}</Body>
                </TD>
              </TR>
              {props.data.infos.map((item, index) => (
                <TR bordered key={`t-${index}`}>
                  <TD>
                    <Body>{item.value.type}</Body>
                  </TD>
                  <TD textAlign='left'>
                    <CrmNoteText>{item.value.text}</CrmNoteText>
                  </TD>
                  <TD>
                    <Body>{item.value.language}</Body>
                  </TD>
                </TR>
              ))}
            </Table>
          </Hbox>
        </CrmContentInfosWrapper>
      ) : (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />
            <Body centralized>{props.loading ? 'Carregando...' : 'Nenhum registro encontrado.'}</Body>
          </Hbox>
        </CrmContentInfosWrapper>
      )}
    </CrmContentWrapper>
  );
};
