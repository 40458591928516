import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { IAdminNPSUserList } from '@app/models/admin.model';
import { mapStatisticsGroup, mapUsers } from './nps.dto';
import {
  GetNPSResponse,
  GetNPSUsersParams,
  GetNPSUsersResponse,
  GetStatisticsGroupResponse,
  GetStatisticsParams,
  PutNPSUserParams,
  StatisticsGroup,
} from './nps.params.dto';

const endpoint = {
  NPS: '/nps',
  STATISTICS: '/statistics',
  GROUP: '/group',
};

export interface AdminUserResponse {
  success: boolean;
  message: string;
  status?: number;
}

export const NPSDataSource = {
  getNPS: (ssoAccess: boolean): Promise<GetNPSResponse> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.NPS}`)
      .headers({
        'Sso-Access': ssoAccess,
      })
      .execute();
  },

  putNPS: (params: PutNPSUserParams): Promise<GetNPSResponse> => {
    return Container.get(HttpRequestBuilder)
      .put(`${endpoint.NPS}`)
      .headers({
        'Sso-Access': params.ssoAccess,
      })
      .data({
        _id: params._id,
        response: params.answer,
        isTopAccount: params?.isTopAccount,
        salesOrg: params?.salesOrg,
        corpGroup: params?.corpGroup,
        note: params?.note,
        message: params?.message,
        optaramPorNaoResponder: params?.optaramPorNaoResponder,
      })
      .execute();
  },

  getStatisticsGroup: (params: GetStatisticsParams): Promise<GetStatisticsGroupResponse> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.NPS}${endpoint.STATISTICS}${endpoint.GROUP}/${params.group}`)
      .headers({
        'Sso-Access': params.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: GetStatisticsGroupResponse): Promise<StatisticsGroup> => mapStatisticsGroup(res),
      })
      .execute();
  },

  getGroup: (params: GetNPSUsersParams): Promise<GetNPSUsersResponse> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.NPS}${endpoint.GROUP}/${params.group}`)
      .headers({
        skip: params.skip,
        limit: params.limit,
        filterType: params.filterType,
        filterValue: params.filterValue,
        'Sso-Access': params.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: GetNPSUsersResponse): Promise<IAdminNPSUserList> => mapUsers(res),
      })
      .execute();
  },
};
