import { TextField, Button, Icon } from '@gerdau/hefesto';
import { Chips, Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { ProfileTypesId, TabInternalName } from '@app/models/admin.model';
import { hasScmAccesSelector } from '@app/modules/auth/store/auth.selectores';
import { removeItems } from '@app/utils/array-helper';
import { AdminDefaultLineWrapperStyled } from '../../admin.page.style';
import { handlePermissionSelectSelector } from '../../admin.selectors';
import { AdminStrings } from '../../admin.string';
import {
  clearSelectedBPOption,
  clearUser,
  setBPFormGroupSelectedOptions,
  setBPFormSelectedOptions,
  setFormBpFilterOption,
  setFormBpTextQuery,
  setShowConfirmModal,
  setShowDeleteModal,
  setShowUserForm,
  setUser,
  setUserProfile,
  updateSelectedBPOption,
} from '../../store/admin.store';
import { createUser } from '../../store/thunk/user-create.thunk';
import { updateUser } from '../../store/thunk/user-update.thunk';
import {
  AdminUserFormContainer,
  AdminUserFormEmailContainer,
  AdminUserFormEmailWrapperStyled,
  AdminUserFormLanguageContainer,
  AdminUserFormNameContainer,
  AdminUserFormSpan,
  PaddedBtnWrapper,
  ControlLineWrapperStyled,
  PermissionContainer,
  PermissionWrapper,
} from './admin-user-form.styles';
import { BPsFilterForm } from './partials/bp-filter/bp-filter.component';
import {
  BpFilterOptions,
  clearedSelectedBPs,
  getProfileTypeOptions,
  getSelectedProfileInfo,
  getTabs,
  LangOptions,
  permissionsOptions,
} from './utils/utils';

export const AdminUserForm: React.FC = () => {
  const { user, bpOptions, isEditing, tabChips, roles, loadingSubmit, bpFilter } = useSelector(
    (state: RootState) => state.admin,
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = AdminStrings.pt;
  const handlePermissionSelect = handlePermissionSelectSelector(user);
  const hasScmAcces = useSelector(hasScmAccesSelector);

  useEffect(() => {
    if (user?._id) {
      const bpOptionsToUpdate = [];
      const formSelectedBPs = [];

      if (user?.bps?.length) {
        user?.bps.forEach(bp => {
          const filteredBP = bpOptions.filter(opt => opt.code === bp.customerId);

          if (filteredBP.length) {
            bpOptionsToUpdate.push(filteredBP[0].code);
          }

          formSelectedBPs.push({
            code: bp.customerId,
            name: bp.customerName,
            id: bp._id,
            label: `${bp.customerId} - ${bp.customerName}`,
            corpGroup: bp.corporateGroup,
            corpGroupName: bp.corporateGroupName,
          });
        });
      }

      if (bpOptionsToUpdate.length) {
        dispatch(updateSelectedBPOption(bpOptionsToUpdate));
      }

      if (formSelectedBPs.length) {
        dispatch(setFormBpFilterOption(BpFilterOptions[0].value));
        dispatch(setBPFormSelectedOptions(formSelectedBPs));
      }
    }
  }, [user]);

  const renderPermission = () => {
    if (handlePermissionSelect) {
      const chipOptions = removeItems(
        [
          TabInternalName.IsaManager,
          TabInternalName.Crm,
          TabInternalName.Surrogate,
          TabInternalName.LackOfLoad,
          TabInternalName.SchedulingCompleted,
        ],
        'id',
        permissionsOptions,
      );

      return (
        <Chips
          chips={chipOptions}
          selectedChipIds={user?.profile.tabs?.map(t => t.tag)}
          handleSelect={e => {
            const tabsArray = getTabs(e, tabChips);
            dispatch(setUserProfile({ _id: user?.profile._id, profile: user?.profile.profile, tabs: tabsArray }));
          }}
          type='filter'
        ></Chips>
      );
    } else {
      const selectedRole = roles.find(r => r.profile === user?.profile.profile);

      if (selectedRole) {
        return permissionsOptions.map((p, i) => {
          if (selectedRole.tabs.find(t => t.tag === p.id)) {
            return (
              <PermissionContainer key={`${p}-${i}`}>
                <Tag variant='neutral'>{p.label}</Tag>
              </PermissionContainer>
            );
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    }
  };

  const isDisabled = () => {
    if (
      !user?.name ||
      !user?.email ||
      !user?.language ||
      !user?.profile?.profile ||
      !user?.profile?.profile ||
      (user?.profile?.profile === ProfileTypesId.Client && !bpFilter?.selectedOptions?.length) ||
      (user?.profile?.profile === ProfileTypesId.Client && user?.profile.tabs.length === 0)
    ) {
      return true;
    }

    return false;
  };

  const handleSubmit = () => {
    if (user?._id) {
      dispatch(updateUser());
    } else {
      dispatch(createUser());
    }
  };

  const handleCancel = () => {
    if (isEditing) {
      dispatch(setShowConfirmModal(true));
    } else {
      dispatch(clearUser(''));
      dispatch(setFormBpFilterOption(''));
      dispatch(setFormBpTextQuery(''));
      dispatch(setShowUserForm(false));
      dispatch(setBPFormSelectedOptions([]));
      dispatch(setBPFormSelectedOptions([]));
      dispatch(setBPFormGroupSelectedOptions([]));
    }
  };

  return (
    <AdminUserFormContainer>
      <AdminUserFormNameContainer>
        <TextField
          label={strings.editView.name}
          name='fullNameBtn'
          value={user?.name}
          onChange={e => dispatch(setUser({ ...user, name: e.target.value }))}
          type='text'
        />
      </AdminUserFormNameContainer>
      <AdminUserFormEmailWrapperStyled>
        <AdminUserFormEmailContainer>
          <TextField
            label='Email'
            name='emailBtn'
            value={user?.email}
            onChange={e => dispatch(setUser({ ...user, email: e.target.value }))}
            type='text'
          />
        </AdminUserFormEmailContainer>
        <AdminUserFormLanguageContainer>
          <AdminUserFormSpan>{strings.editView.language}</AdminUserFormSpan>
          <Chips
            chips={LangOptions}
            selectedChipIds={[user?.language]}
            handleSelect={e => {
              dispatch(setUser({ ...user, language: e[0] }));
            }}
            type='choice'
          />
        </AdminUserFormLanguageContainer>
      </AdminUserFormEmailWrapperStyled>
      <AdminDefaultLineWrapperStyled>
        <AdminUserFormSpan>{strings.editView.profile}</AdminUserFormSpan>
        <Chips
          chips={getProfileTypeOptions(hasScmAcces)}
          selectedChipIds={[user?.profile?.profile]}
          handleSelect={e => {
            dispatch(setUser({ ...user, profile: getSelectedProfileInfo(e[0], roles) }));
            dispatch(clearSelectedBPOption(clearedSelectedBPs(bpOptions)));
          }}
          type='choice'
        />
      </AdminDefaultLineWrapperStyled>
      {user?.profile.profile && (
        <AdminDefaultLineWrapperStyled>
          <AdminUserFormSpan>{strings.editView.permissions}</AdminUserFormSpan>
          <PermissionWrapper>{renderPermission()}</PermissionWrapper>
        </AdminDefaultLineWrapperStyled>
      )}
      {handlePermissionSelect && <BPsFilterForm />}
      <ControlLineWrapperStyled>
        <Button name='cancelBtn' variant='secondary' onClick={() => handleCancel()}>
          {strings.editView.actions.cancelBtn}
        </Button>
        {isEditing && (
          <PaddedBtnWrapper>
            <Button name='deleteUserBtn' variant='danger' onClick={() => dispatch(setShowDeleteModal(true))}>
              {strings.editView.actions.deleteBtn}
            </Button>
          </PaddedBtnWrapper>
        )}
        <PaddedBtnWrapper>
          <Button
            name='saveConfigUserBtn'
            icon={<Icon option='Save' />}
            loading={loadingSubmit}
            onClick={() => handleSubmit()}
            disabled={isDisabled()}
          >
            {strings.editView.actions.saveBtn}
          </Button>
        </PaddedBtnWrapper>
      </ControlLineWrapperStyled>
    </AdminUserFormContainer>
  );
};
