import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../nps-modal.constants';
import { INpsState } from '../nps-modal.interfaces';
import { initialState } from './nps-modal.initial';
import { reducers } from './nps-modal.reducers';
import { addThunkReducers } from './thunk/nps.thunks';

export const npsSlice = createSlice<INpsState, SliceCaseReducers<INpsState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const { setDontSeeAgain, setPhase, setNote, setMessage, submitNps } = npsSlice.actions;

export const npsReducer = npsSlice.reducer;
