import { optionsMI } from '../customer-search.constants';
import { ICustomerSearchState } from '../customer-search.interfaces';

export const initialState: ICustomerSearchState = {
  filterOptionSearch: optionsMI[0],
  salesOrgsSearch: [],
  corporateGroupSearch: [],
  salesGroupSearch: [],
  querySearch: '',
  clientBpsSearch: [],
  loading: false,
  error: null,
  currentRequestTime: null,
};
