import { SimilarMaterialsCol } from '@app/models/analysis.model';
import { GridColumnDefinition } from '@app/modules/components';

export const gridCaseComplainColumnsDef = (columns: SimilarMaterialsCol[]): GridColumnDefinition => {
  return {
    name: 'caseComplainColDef',
    doNotPersist: true,
    cols: columns?.map(col => ({
      field: col.key,
      headerName: col.label,
      headerTooltip: col.label,
      editable: false,
      hide: false,
      type: col.type,
    })),
  };
};
