import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { patchSchedulingCompleteModal } from '@app/modules/order/order-tabs/MI/tab-open/partials/scheduling-complete/store/patch-scheduling-complete.thunk';
import { setOpenSchedulingCompleteConfigModal } from '@app/modules/order/order-tabs/MI/tab-open/partials/scheduling-complete/store/scheduling-complete-modal.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { SCHEDULING_COMPLETE_KEY } from '../announcement-modal/announcement-modal.constants';
import { Strings, ISchedulingCompletedConfigModalStrings } from './scheduling-complete-config-modal.string';
import {
  SchedulingCompletedConfigModalBody,
  SchedulingCompletedConfigModalBodyWrapper,
  SchedulingCompletedConfigModalCloseWrapper,
  SchedulingCompletedConfigModalFooter,
  SchedulingCompletedConfigModalHeader,
  SchedulingCompletedConfigModalTitleWrapper,
  SchedulingCompletedConfigModalWrapper,
} from './scheduling-complete-config-modal.style';

export const SchedulingCompleteConfigModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { openedConfigModal, announcementData, loading, aditionalDays } = useSelector(
    (state: RootState) => state.schedulingComplete,
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings: ISchedulingCompletedConfigModalStrings = Strings[userInfo?.language];

  const handleClose = () => {
    dispatch(setOpenSchedulingCompleteConfigModal(false));
  };

  const handleConfirm = () => {
    dispatch(patchSchedulingCompleteModal({ key: SCHEDULING_COMPLETE_KEY, additionalDays: aditionalDays }));
  };

  const handleIsDisabled = () => {
    let isDisabled = false;

    if (announcementData && announcementData.runningAnnouncements.length > 0) {
      isDisabled = announcementData.runningAnnouncements.includes(SCHEDULING_COMPLETE_KEY);
    }

    return isDisabled;
  };

  return (
    <Modal small opened={openedConfigModal}>
      <SchedulingCompletedConfigModalHeader>
        <SchedulingCompletedConfigModalTitleWrapper>
          {strings?.title}
          <FlexRow pl={Spacing.Small}>
            <FaIcon.Bell size='1x' color={Color.Primary} />
          </FlexRow>
        </SchedulingCompletedConfigModalTitleWrapper>
        <SchedulingCompletedConfigModalCloseWrapper>
          <FlexColumn onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
            <Image.CloseIcon />
          </FlexColumn>
        </SchedulingCompletedConfigModalCloseWrapper>
      </SchedulingCompletedConfigModalHeader>
      <SchedulingCompletedConfigModalWrapper>
        <SchedulingCompletedConfigModalBody>
          <SchedulingCompletedConfigModalBodyWrapper>
            <Body size={FontSize.Large}>{strings?.message}</Body>
            {/* <AditionalDaysRow>
              <Body size={FontSize.Large} mt={Spacing.Medium}>
                {strings?.aditionalDays}
              </Body>
              <Hbox.Separator />
              <TextField
                type='number'
                small
                value={aditionalDays}
                onValueChange={value => dispatch(setAditionalDays(value))}
              />
            </AditionalDaysRow> */}
          </SchedulingCompletedConfigModalBodyWrapper>
          <SchedulingCompletedConfigModalFooter>
            <FlexRow hAlign='center' pr={Spacing.Small}>
              <Button
                kind='secondary'
                testid={`${TEST_ID_CLOSE_MODAL}-scheduling-complete-config-modal`}
                onClick={() => handleClose()}
              >
                {strings?.cancelButton}
              </Button>
            </FlexRow>
            <FlexRow hAlign='center'>
              <Button
                testid='confirm-scheduling-complete-config-modal'
                disabled={handleIsDisabled()}
                onClick={() => handleConfirm()}
                loading={loading}
              >
                {strings?.confirmButton}
              </Button>
            </FlexRow>
          </SchedulingCompletedConfigModalFooter>
        </SchedulingCompletedConfigModalBody>
      </SchedulingCompletedConfigModalWrapper>
    </Modal>
  );
};
