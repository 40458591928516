import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { isNextMultipleDownDisabledSelector } from '@app/modules/order-input/store/order-input.selectors';
import { setMultipleDownQuantity } from '@app/modules/order-input/store/order-input.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
import { ArrowDownButtonWrapper } from './arrow-down-button.style';

interface IArrowDownButtonField {
  index: number;
  material: Material;
}

export const ArrowDownButton: React.FC<IArrowDownButtonField> = ({ material, index }) => {
  const dispatch = useDispatch();
  const disabled = useSelector((state: RootState) => isNextMultipleDownDisabledSelector(state, material.tempId));

  const onClick = () => {
    if (!disabled) {
      dispatch(setMultipleDownQuantity(material.tempId));
    }
  };

  return (
    <ArrowDownButtonWrapper disabled={disabled} onClick={onClick} data-testid={`arrow-down-button-${index}`}>
      <FaIcon.ChevronDown clickable color={Color.Primary} />
    </ArrowDownButtonWrapper>
  );
};
