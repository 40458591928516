import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router';

export interface StatusProps {
  children: React.ReactNode;
  code: number;
}

/**
 * Component used to set status code to RouteComponentProps.staticContext
 *
 * This component was copied from
 * [here](https://reacttraining.com/react-router/web/guides/server-rendering/adding-app-specific-context-information)
 */
export class Status extends React.Component<StatusProps, undefined> {
  render() {
    return <Route render={this.renderRoute} />;
  }

  private renderRoute = (props: RouteComponentProps<any>) => {
    if (props.staticContext) {
      props.staticContext.statusCode = this.props.code;
    }
    return this.props.children;
  };
}
