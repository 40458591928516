import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Frame } from '@atomic/atm.frame';
import { H1 } from '@atomic/atm.typography';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Tab } from '@atomic/mol.tab';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { TechnicalAnalysisStrings } from './technical-analysis.string';

export const TechnicalAnalysisShimmer: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  return (
    <Frame>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <Hbox>
              <Hbox.Item>
                <H1>{TechnicalAnalysisStrings[userInfo.language].header.title}</H1>
                <TextShimmerBoxStyled height='40px' width='50%' margin='0' />
              </Hbox.Item>
              {Array(5)
                .fill('')
                .map((_, index) => (
                  <React.Fragment key={index}>
                    <Hbox.Separator />
                    <Hbox.Item noGrow vAlign='center'>
                      <TextShimmerBoxStyled height='40px' width='40px' margin='0' />
                    </Hbox.Item>
                  </React.Fragment>
                ))}
              <Hbox.Separator />
              <Hbox.Item noGrow vAlign='center'>
                <TextShimmerBoxStyled height='40px' width='199px' margin='0' />
              </Hbox.Item>
            </Hbox>
          </Col>
        </Row>
        <VSeparator />

        <Row mb>
          <Col xs={12}>
            <Hbox>
              <Hbox.Item noGrow>
                <TextShimmerBoxStyled height='30px' width='150px' margin='0' />
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <TextShimmerBoxStyled height='30px' width='150px' margin='0' />
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <TextShimmerBoxStyled height='30px' width='150px' margin='0' />
              </Hbox.Item>
            </Hbox>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Tab initialIndex={0}>
              <Tab.Item>
                <TextShimmerBoxStyled height='20px' width='100px' margin='0' />
              </Tab.Item>
              <Tab.Item>
                <TextShimmerBoxStyled height='20px' width='100px' margin='0' />
              </Tab.Item>
              <Tab.Item>
                <TextShimmerBoxStyled height='20px' width='100px' margin='0' />
              </Tab.Item>
            </Tab>
          </Col>
        </Row>
      </Grid>
    </Frame>
  );
};
