import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../sidebar-filters.constants';
import { IAdvancedFiltersState } from '../sidebar-filters.interfaces';
import { initialState } from './sidebar-filters.initial';
import { reducers } from './sidebar-filters.reducers';

export const advancedFiltersSlice = createSlice<IAdvancedFiltersState, SliceCaseReducers<IAdvancedFiltersState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
});

export const {
  resetOrderFilters,
  resetFinanceFilters,
  confirmFilters,
  setInputFilters,
  removeInputFilters,
  removeDateFilters,
  removeCheckboxFilters,
  removeRangeFilters,
  setStartDateFilter,
  setEndDateFilter,
  setCheckboxFilters,
  setRangeLimitFilter,
  setRangeValueFilter,
} = advancedFiltersSlice.actions;

export const advancedFiltersReducer = advancedFiltersSlice.reducer;
