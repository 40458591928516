import * as React from 'react';
import { FormFieldContext, FormFieldContextState } from '@atomic/obj.form';
import {
  RadioCheckedStyled,
  RadioFieldInputStyled,
  RadioFieldLabelStyled,
  RadioFieldStyled,
  RadioUncheckedStyled,
} from './radio-field.component.style';

interface RadioFieldProps {
  id: any;
  onClick?: (id: any) => void;
  disabled?: boolean;
  expanded?: boolean;
  mb?: boolean;
  testid?: string;
}

const DefaultRadioBullet = (props: any) => (props.checked ? <RadioCheckedStyled /> : <RadioUncheckedStyled />);

// tslint:disable-next-line:class-name
export class RadioField extends React.Component<RadioFieldProps, undefined> {
  private formFieldConsumer: FormFieldContextState;

  render() {
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;
          if (!formFieldConsumer) {
            throw new Error('<RadioField /> must be wrapped with a <Form.Field> tag');
          }
          return (
            <RadioFieldStyled mb={this.props.mb} expanded={this.props.expanded} onClick={this.handlePress}>
              <RadioFieldInputStyled
                type='radio'
                name={this.formFieldConsumer.name}
                id={this.formFieldConsumer.name + '_' + this.props.id}
                value={this.props.id}
                data-testid={this.props.testid}
              />
              <DefaultRadioBullet checked={this.formFieldConsumer.value === this.props.id} />
              <RadioFieldLabelStyled
                htmlFor={this.formFieldConsumer.name + '_' + this.props.id}
                expanded={this.props.expanded}
              >
                {this.props.children}
              </RadioFieldLabelStyled>
            </RadioFieldStyled>
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  private handlePress = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.id);
    }

    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(this.props.id, null);
    }
  };
}
