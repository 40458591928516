import styled from 'styled-components';
import { Color, FontFamily } from '@atomic/obj.constants';

export const TextAreaMessage = styled.textarea`
  width: 100%;
  height: 100px;
  border: 1px solid ${Color.Gray};
  font-family: ${FontFamily.Primary};
  resize: none;
`;
