import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  isEatonSelector,
  isGKNSelector,
} from '@app/modules/components/customer-search/store/customer-search.selectors';
import { IShippingRequestColumns } from '@app/modules/shipping-request/shipping-request.interfaces';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { getTableColumnsDefinitions } from '@app/modules/shipping-request/shipping-request.utils';
import {
  totalRequestedShippingQuantitySelector,
  totalStockQuantitySelector,
} from '@app/modules/shipping-request/store/shipping-request.selectors';
import { DT } from '@atomic/atm.typography';
import { TD, TR } from '@atomic/mol.table';
import { ShippingRequestTableFooterTD } from './shipping-request-table-footer-td.component';

interface IShippingRequestTableFooterProps {
  plant: string;
}

export const ShippingRequestTableFooter: React.FC<IShippingRequestTableFooterProps> = ({ plant }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IShippingRequestStrings = Strings[userInfo.language];

  const totalWished: number = useSelector((state: RootState) => totalRequestedShippingQuantitySelector(state, plant));
  const totalStock: number = useSelector((state: RootState) => totalStockQuantitySelector(state, plant));
  const isGKN = useSelector(isGKNSelector);
  const isEaton = useSelector(isEatonSelector);

  const tableColumnsDefinitions = getTableColumnsDefinitions(isGKN, isEaton);
  const total = isGKN ? totalWished : totalStock;

  return (
    <TR bordered>
      {tableColumnsDefinitions.map((column: IShippingRequestColumns) => {
        const showTotal =
          (isGKN && column.field === 'requestedShippingQuantity') || (!isGKN && column.field === 'stockQuantity');
        const showTotalTitle = column.field === 'customerProductCode';
        return (
          <TD key={column.field}>
            {showTotalTitle && <DT>{strings.page.total}</DT>}
            {showTotal && <ShippingRequestTableFooterTD total={total} field={column.field} />}
          </TD>
        );
      })}
    </TR>
  );
};
