import * as React from 'react';
import { Cell } from '@atomic/atm.cell';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { FlexColumn } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';

export const ScheduleModalListShimmer: React.FC = () => {
  return (
    <FlexColumn mt mb>
      {Array(5)
        .fill('')
        .map((_item, index) => (
          <Cell key={index}>
            <TextShimmerBoxStyled height='20px' width='100%' margin='0' />
            <VSeparator small />
          </Cell>
        ))}
    </FlexColumn>
  );
};
