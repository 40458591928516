import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { FilterOptions } from '@app/modules/components/customer-search/customer-search.constants';
import { SearchFieldBp } from './search-field-bp/search-field-bp.component';
import { SearchFieldCorporateGroup } from './search-field-corporate-group/search-field-corporate-group.component';
import { SearchFieldInitial } from './search-field-initial/search-field-initial.component';
import { SearchFieldSalesGroup } from './search-field-sales-group/search-field-sales-group.component';
import { SearchFieldSalesOrgs } from './search-field-sales-orgs/search-field-sales-orgs.component';

const searchFieldComponents = {
  [FilterOptions.BP]: SearchFieldBp,
  [FilterOptions.corporateGroup]: SearchFieldCorporateGroup,
  [FilterOptions.salesOrgs]: SearchFieldSalesOrgs,
  [FilterOptions.salesGroup]: SearchFieldSalesGroup,
};

export const SearchField: React.FC = () => {
  const { filterOptionSearch } = useSelector((state: RootState) => state.customerSearch);

  if (filterOptionSearch) {
    const SearchFieldComponent = searchFieldComponents[filterOptionSearch.value];
    return <SearchFieldComponent />;
  }

  return <SearchFieldInitial />;
};
