import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { OrderInputPhaseEnum } from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { Caption } from '@atomic/atm.typography';

const strings = OrderInputStrings.pt.selectItens;

export const CaptionSearchPartNumber: React.FC = () => {
  const { phase } = useSelector((state: RootState) => state.orderInput);

  if (phase !== OrderInputPhaseEnum.UploadSpreadsheet && phase !== OrderInputPhaseEnum.UploadPdfEmail) {
    return <Caption data-testid='order-input-caption'>{strings.stepOne.descriptionSearchByPN}</Caption>;
  }

  return null;
};
