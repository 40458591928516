import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { setTime } from '@app/modules/components/schedule-modal/store/schedule.store';
import { formatTime } from '@app/modules/components/schedule-modal/utils';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { TimeSelectOptions } from './time-select-optins';
import { TimeInput, TimeSelectWrapper } from './time-select.style';

export const TimeSelect: React.FC = () => {
  const { time } = useSelector((state: RootState) => state.schedule);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const dispatch = useDispatch();
  const onChange = (value: string) => {
    dispatch(setTime(value));
    setOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current) {
      const isOutsideClick = ref && !ref.current.contains(event.target);

      if (isOutsideClick) {
        setOpen(false);
      }
    }
  };

  useEventListener('click', handleClickOutside);

  return (
    <TimeSelectWrapper ref={ref}>
      <TimeInput onClick={() => setOpen(true)}>{formatTime(time)}</TimeInput>
      <FaIcon.Time size='sm' color={Color.Primary} />
      {open && <TimeSelectOptions onChange={onChange} />}
    </TimeSelectWrapper>
  );
};
