// eslint-disable-next-line prettier/prettier
import type { RootState } from '@app/core/redux/store';
import { CSATModule } from '@app/modules/admin-settings/admin-settings.modules';
import { FeedbackAnalytics } from '@atomic/obj.feedback/feedback.analytics';
import { FeedbackPhase } from '@atomic/obj.feedback/feedback.interfaces';

export const showFeedbackSelector = (
  state: RootState,
  contextFunctionality: CSATModule,
  feedbackPhase: FeedbackPhase,
  context: FeedbackAnalytics,
  opened: boolean,
) => {
  const { openNpsModal, openNewsModal } = state.navigation;
  const { csatUser } = state.csat;

  if (openNpsModal || openNewsModal) {
    return false;
  }

  if (!csatUser?.length) {
    return false;
  }

  if (!opened) {
    return false;
  }

  const persistedFunctionality = csatUser.find(item => item?.functionality === contextFunctionality);

  if (!persistedFunctionality) {
    return false;
  }

  const notAnswered = !persistedFunctionality?.answer;
  const ignoreAnswered = context === FeedbackAnalytics.TechnicalAnalysisChangeStatus;
  const isInSuccessMode = feedbackPhase === FeedbackPhase.Success;

  const showFeedback = notAnswered || ignoreAnswered || isInSuccessMode;

  return showFeedback;
};
