import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { PriorityTypes } from '@app/models/quote-filters.model';
import { PpapTypes } from '@app/models/technical-analysis.model';
import { Button } from '@atomic/atm.button';
import { RadioField } from '@atomic/atm.radio';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { H2, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';
import { TechnicalAnalysisComponentStrings } from './technical-analysis-component.strings';

interface AttributeStepProps {
  opened: boolean;
  market?: string;
  onModalClose: () => void;
  onNextClick: (ppap: string, priority: string, partNumber: string | number) => void;
}

const Option = ({ value, name }) => <option value={value}>{name}</option>;

export const AttributeStepModal: React.FC<AttributeStepProps> = props => {
  const [priority, setPriority] = React.useState<string>(PriorityTypes.C);
  const [ppap, setPpap] = React.useState<string>(PpapTypes.no);
  const [partNumberField, setPartNumberField] = React.useState(false);
  const [partNumber, setPartNumber] = React.useState(null);
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const strings = TechnicalAnalysisComponentStrings[userInfo.language].attributeStepModal;
  const stringsPartNumber = TechnicalAnalysisStrings[userInfo.language].status.peddingModal;
  const marketType = props.market ? (props.market.toUpperCase() === 'EXPORTACAO' ? 'ME' : 'MI') : 'MI';

  const priorityInformation = {
    [PriorityTypes.A]: { description: strings.priorityLabel[marketType].high },
    [PriorityTypes.B]: { description: strings.priorityLabel[marketType].medium },
    [PriorityTypes.C]: { description: strings.priorityLabel[marketType].low },
  };

  const ppapInformation = {
    [PpapTypes.no]: { description: strings.ppapLabel.no },
    [PpapTypes.firstLevel]: { description: strings.ppapLabel.firstLevel },
    [PpapTypes.secondLevel]: { description: strings.ppapLabel.secondLevel },
    [PpapTypes.thirdLevel]: { description: strings.ppapLabel.thirdLevel },
    [PpapTypes.fourthLevel]: { description: strings.ppapLabel.fourthLevel },
    [PpapTypes.fifthLevel]: { description: strings.ppapLabel.fifthLevel },
  };

  const handleSubmit = () => {
    props.onNextClick(ppap, priority, partNumber);
  };

  const handleOption = (value: string) => {
    if (value === 'n') {
      setPartNumberField(false);
    } else {
      setPartNumberField(true);
    }
  };

  const handlePartNumber = (value: string | number | string[]) => {
    setPartNumber(value);
  };

  return (
    <Modal small opened={props.opened} onClose={props.onModalClose}>
      <Grid fluid>
        <H2>{strings.title}</H2>
        <Form>
          <H3>{strings.priority}</H3>
          <VSeparator small />
          <Form.Field name='myGroup' value={priority} onValueChange={setPriority}>
            {Object.values(PriorityTypes).map(
              option =>
                option !== 'GQ' && (
                  <RadioField key={option} id={option}>
                    {priorityInformation[option]?.description}
                  </RadioField>
                ),
            )}
          </Form.Field>
          <VSeparator />

          <Form.Field name='pn' onValueChange={handleOption} initialValue='n'>
            <H3>{stringsPartNumber.partNumberQuestion}</H3>

            <Hbox>
              <Hbox.Item noGrow>
                <RadioField id='s'>{stringsPartNumber.options.yes}</RadioField>
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <RadioField id='n'>{stringsPartNumber.options.no}</RadioField>
              </Hbox.Item>
            </Hbox>
          </Form.Field>
          {partNumberField && (
            <Form.Field name='partNumber' label={stringsPartNumber.partNumberField.label}>
              <TextField onValueChange={e => handlePartNumber(e)} />
            </Form.Field>
          )}
          <VSeparator />

          <H3>{strings.ppap}</H3>
          <VSeparator small />
          <SelectField value={ppap} onValueChange={setPpap}>
            {Object.values(PpapTypes).map(option => (
              <Option key={option} value={option} name={ppapInformation[option]?.description} />
            ))}
          </SelectField>
        </Form>
        <VSeparator />
        <Hbox>
          <Hbox.Item hAlign='flex-end'>
            <Button onClick={handleSubmit}>{strings.next}</Button>
          </Hbox.Item>
        </Hbox>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
