import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IOrderInputStrings, OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { IAIconWrapper, ReadOrderLoaderWrapperStyled } from './read-order-loader.style';

export const ReadOrderLoader: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];

  return (
    <ReadOrderLoaderWrapperStyled>
      <IAIconWrapper>
        <Image.IALoader />
      </IAIconWrapper>
      <Body className='loading' mt={Spacing.Medium}>
        {strings.uploadPdfEmail.readOrderLoader}
      </Body>
      <Body mt={Spacing.Medium}>{strings.uploadPdfEmail.readOrderLoaderWarning}</Body>
    </ReadOrderLoaderWrapperStyled>
  );
};
