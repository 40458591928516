import { PayloadAction } from '@reduxjs/toolkit';
import { REDUX_DATE_ERROR_MESSAGE } from '@app/constants';
import { SLICE_NAME } from '../order-update.constants';
import { IAlertPayload, IOrderUpdateState } from '../order-update.interfaces';

export const RESET_ORDER_UPDATE_ACTION_TYPE = `${SLICE_NAME}/reset`;
export const RESET_ORDER_UPDATE_ACTION = { type: RESET_ORDER_UPDATE_ACTION_TYPE };

export const reducers = {
  reset: (state: IOrderUpdateState) => {
    state.generalComment = '';
    state.showOrderUpdate = false;
    state.isCommentModalOpen = false;
    state.finishModalOpened = false;
    state.loading = false;
    state.tableRows = [];
  },
  setShowOrderUpdate: (state: IOrderUpdateState, { payload }) => {
    state.showOrderUpdate = payload;
  },
  setOpenUpdateFeedback: (state: IOrderUpdateState, { payload }) => {
    state.openUpdateFeedback = payload;
  },
  setIsCommentModalOpen: (state: IOrderUpdateState, { payload }) => {
    state.isCommentModalOpen = payload;
  },
  setFinishModalOpened: (state: IOrderUpdateState, { payload }) => {
    state.finishModalOpened = payload;
  },
  setGeneralComment: (state: IOrderUpdateState, { payload }) => {
    state.generalComment = payload;
  },
  setNewQuantity: (state: IOrderUpdateState, { payload }) => {
    const { index, value } = payload;
    const newTable = [...state.tableRows];
    newTable[index].newQtdSolClient = value;
    state.tableRows = newTable;
  },
  setNewDates: (state: IOrderUpdateState, { payload }: PayloadAction<{ index: number; date: number }>) => {
    const { index, date } = payload;
    if (typeof date === 'number' || date == null) {
      const newTable = [...state.tableRows];
      newTable[index].newDateSolClient = date;
      state.tableRows = newTable;
    } else {
      throw Error(REDUX_DATE_ERROR_MESSAGE);
    }
  },
  setNewMessage: (state: IOrderUpdateState, { payload }) => {
    const { index, value } = payload;
    const newTable = [...state.tableRows];
    newTable[index].messageClient = value;
    state.tableRows = newTable;
  },
  setCanceled: (state: IOrderUpdateState, { payload }) => {
    const newTable = [...state.tableRows];
    newTable[payload].newDateSolClient = null;
    newTable[payload].newQtdSolClient = null;
    newTable[payload].cancelOrder = !newTable[payload].cancelOrder;
    state.tableRows = newTable;
  },
  setTableRows: (state: IOrderUpdateState, { payload }) => {
    state.tableRows = payload;
  },
  removeAlertStatus: (state: IOrderUpdateState, { payload }: PayloadAction<IAlertPayload>) => {
    const { ov, itemOv } = payload;

    if (state.tableRows?.length) {
      const index = state.tableRows.findIndex(row => row.itemOv === itemOv && row.ov === ov);
      state.tableRows[index].removedAlertStatus = true;
    }
  },
  removeAlertCancel: (state: IOrderUpdateState, { payload }: PayloadAction<IAlertPayload>) => {
    const { ov, itemOv } = payload;

    if (state.tableRows?.length) {
      const index = state.tableRows.findIndex(row => row.itemOv === itemOv && row.ov === ov);
      state.tableRows[index].removedAlertCancel = true;
    }
  },
  removeAlertDate: (state: IOrderUpdateState, { payload }: PayloadAction<IAlertPayload>) => {
    const { ov, itemOv } = payload;

    if (state.tableRows?.length) {
      const index = state.tableRows.findIndex(row => row.itemOv === itemOv && row.ov === ov);
      state.tableRows[index].removedAlertDate = true;
    }
  },
  deleteItem: (state: IOrderUpdateState, { payload }: PayloadAction<IAlertPayload>) => {
    const { ov, itemOv } = payload;
    const tableRows = [...state.tableRows];

    const filteredTableRows = tableRows.filter(row => row.ov !== ov || row.itemOv !== itemOv);
    state.tableRows = filteredTableRows;
  },
  revisionItem: (state: IOrderUpdateState, { payload }: PayloadAction<IAlertPayload>) => {
    const { ov, itemOv } = payload;

    if (state.tableRows?.length) {
      const index = state.tableRows.findIndex(row => row.itemOv === itemOv && row.ov === ov);

      state.tableRows[index].removedAlertStatus = true;
      state.tableRows[index].newDateSolClient = null;
    }
  },
};
