import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { hasInternalAccessSelector } from '@app/modules/auth/store/auth.selectores';
import { setOpenBPsModal } from '@app/providers/navigation/navigation.store';
import { CustomerSearchAdminComponent } from './customer-search-admin/customer-search-admin.component';
import { CustomerSearchClientComponent } from './customer-search-client/customer-search-client.component';

export const CustomerSearchContainer: React.FC = () => {
  const hasInternalAccess = useSelector(hasInternalAccessSelector);
  const { clientBps } = useSelector((state: RootState) => state.customerPersist);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!clientBps.length) {
      dispatch(setOpenBPsModal(true));
    }
  }, []);

  if (hasInternalAccess) {
    return <CustomerSearchAdminComponent />;
  }

  return <CustomerSearchClientComponent />;
};
