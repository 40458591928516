import * as React from 'react';
import { DatesDataProps } from '@app/models/crm.model';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { CrmStrings } from '../../crmComplain.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

interface CrmDataProps {
  data: DatesDataProps;
  loading: boolean;
}

export const CrmDates = (props: CrmDataProps) => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.DATES} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>

      {props?.data?.infos?.length > 0 ? (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />
            {props.data.infos.length > 0 && (
              <Table collapse>
                <TR bordered>
                  <TD color={Color.Secondary}>
                    <Body bold={600}>{strings.tables.cols.typeDate}</Body>
                  </TD>
                  <TD color={Color.Secondary}>
                    <Body bold={600}>{strings.tables.cols.date}</Body>
                  </TD>
                </TR>
                {props.data.infos.map((item, index) => (
                  <TR bordered key={`t-${index}`}>
                    <TD>
                      <Body>{item.value.type}</Body>
                    </TD>
                    <TD>
                      <Body>{item.value.date}</Body>
                    </TD>
                  </TR>
                ))}
              </Table>
            )}
          </Hbox>
        </CrmContentInfosWrapper>
      ) : (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />
            <Body centralized>{props.loading ? 'Carregando...' : 'Nenhum registro encontrado.'}</Body>
          </Hbox>
        </CrmContentInfosWrapper>
      )}
    </CrmContentWrapper>
  );
};
