import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { IsaHttpRequestBuilder } from '@app/data/http';
import { GetPlantParams } from '@app/data/http/quote-params.dto';
// import { mapAvailableStatus } from '@app/data/http/quote-status.dto';
// import { handleUnauthorized } from '@app/utils/http-utils';
//import { NewPlantsResponse } from '@app/data/http/quote.dto';

export async function getPlants(params?: GetPlantParams): Promise<any> {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  // const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
  //   endpoint.ANALYSIS_STATUS
  // }`;

  const url = `https://cxp-quote-api-cxp-dev.apps2.rosa-gerdau.bxnu.p1.openshiftapps.com/v3/api/analysis/${params.id}/plant/${params.plantKey}`;

  try {
    const { data, status } = await axios.get(url, { headers: defaultHeaders });

    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    //handleUnauthorized(error);
    return null;
  }
}

export function useNewPlants(params: GetPlantParams, onSuccess?, onError?) {
  return useQuery(['getPlants', params], () => getPlants(params), {
    onSuccess,
    onError,
    select: data => {
      return data.data[0];
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
}
