import * as React from 'react';
import { FixedFooterStyled, FixedFooterWrapperStyled } from './fixed-footer.component.style';

export interface FixedFooterProps {
  hasShadow?: boolean;
}

export const FixedFooter: React.FC<FixedFooterProps> = props => {
  return (
    <FixedFooterWrapperStyled>
      <FixedFooterStyled hasShadow={props.hasShadow}>{props.children}</FixedFooterStyled>
    </FixedFooterWrapperStyled>
  );
};
