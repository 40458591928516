import styled from 'styled-components';
import { BoxHeight, Color, Spacing } from '@atomic/obj.constants';

export const PriorizationOrderSlotStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${BoxHeight.Medium};
  border: 1px dashed ${Color.Gray};
  background-color: ${Color.GrayLight};
  margin-bottom: ${Spacing.Small};
`;
