/* eslint-disable prettier/prettier */
import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Grid, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { IAdminSettingsStrings, Strings } from './admin-settings.string';


interface ResetConfirmModalProps {
  onClose: () => void;
  onConfirm: () => void;
  modalType: 'information' | 'functionalities' | 'nps';
  loading: boolean;
}

export const ResetConfirmModal: React.FC<ResetConfirmModalProps> = props => {
  const strings: IAdminSettingsStrings = Strings.pt;

  return (
    <Modal small opened={!!props.modalType} onClose={props.onClose}>
      <Grid fluid>
        <Row>
          <H2>{strings.modalConfirm.title}</H2>
        </Row>
        <Row>
          <Body>{Strings[props.modalType]?.ConfirmationText}</Body>
        </Row>
        <Row mt mb>
          <Hbox style={{ flex: 1 }} hAlign='center'>
            <Button loading={props.loading} onClick={props.onClose} expanded kind='secondary'>{strings.modalConfirm.cancelButton}</Button>
            <Button loading={props.loading} onClick={props.onConfirm} expanded >{strings.modalConfirm.confirmButton}</Button>
          </Hbox>
        </Row>
      </Grid>
    </Modal>
  );
};
