import styled from 'styled-components';
import { Color, Spacing, TransitionDuration, ZIndex } from '@atomic/obj.constants';

interface GridColumnMenuStyledProps {
  show?: boolean;
}
export const GridColumnMenuStyled = styled.div`
  opacity: ${(props: GridColumnMenuStyledProps) => (props.show ? 1 : 0)};
  max-height: ${(props: GridColumnMenuStyledProps) => (props.show ? '600px' : 0)};
  z-index: ${ZIndex.GridColumnMenuStyled};
  width: auto;
  position: absolute;
  background-color: ${Color.White};
  left: -600px;
  top: 50px;
  transition: all ${TransitionDuration} ease-in-out;
  overflow: hidden;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
`;

export const CheckboxFieldWrapper = styled.div`
  & > div {
    white-space: nowrap;
  }
`;

export const ColumnFields = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnFieldsWrapper = styled.div`
  display: flex;
  & > div:not(:last-child) {
    margin-right: ${Spacing.XLarge};
  }
`;
