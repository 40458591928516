/* eslint-disable complexity */
import * as React from 'react';
import { H1, H1Sub } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { LoadingState } from '@atomic/obj.loading-state';
import { CrmShimmer } from './crm.shimmer';

interface CrmHeaderProps {
  loading: boolean;
  itemsData: any;
}

export const CrmDetailsHeader: React.FC<CrmHeaderProps> = props => {
  return (
    <LoadingState data={!!props.itemsData} loading={props.loading}>
      <LoadingState.Shimmer>
        <CrmShimmer tab='complain' />
      </LoadingState.Shimmer>

      <Hbox vAlign='center'>
        <Hbox.Item>
          <H1>RC CRM</H1>
          <H1Sub>{props?.itemsData?.value && `Reclamação ${props?.itemsData?.value}`}</H1Sub>
        </Hbox.Item>
      </Hbox>
    </LoadingState>
  );
};
