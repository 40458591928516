import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { RadioField } from '@atomic/atm.radio';
import { H2, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { TechnicalAnalysisComponentStrings } from './technical-analysis-component.strings';

export interface AnalysisTypeData {
  analysisType: string;
}

interface AttributeTypeProps {
  opened: boolean;
  onModalClose: () => void;
  onNextClick: (type: string) => void;
}

export const AnalysisTypeModal: React.FC<AttributeTypeProps> = props => {
  const [typeOption, setTypeOption] = React.useState<string>(null);
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const strings = TechnicalAnalysisComponentStrings[userInfo.language].analysisTypeModal;

  const questions = [
    { description: strings.options[0].label, value: strings.options[0].key },
    { description: strings.options[1].label, value: strings.options[1].key },
  ];

  const handleSubmit = (data: FormData<AnalysisTypeData>) => {
    if (Object.keys(data.error).length) {
      return;
    }
    props.onNextClick(
      data.data.analysisType === 'AT'
        ? TechnicalAnalysisComponentStrings.pt.analysisTypeModal.options[1].label
        : TechnicalAnalysisComponentStrings.pt.analysisTypeModal.options[0].label,
    );
    setTypeOption(null);
  };

  return (
    <Modal small opened={props.opened} onClose={props.onModalClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <H2>{strings.title}</H2>

          <H3>{strings.label}</H3>
          <VSeparator />

          <Form.Field
            name='analysisType'
            onValueChange={setTypeOption}
            initialValue={typeOption}
            validators={[Validators.Required('Este campo é obrigatório')]}
          >
            {Object.values(questions).map((option, index) => (
              <Hbox.Item key={`op-${index}`}>
                <RadioField key={index} id={option.value}>
                  {option.description}
                </RadioField>
              </Hbox.Item>
            ))}
          </Form.Field>
          <VSeparator />

          <VSeparator />
          <Hbox>
            <Hbox.Item hAlign='flex-end'>
              <Button type='submit'>{strings.next}</Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};
