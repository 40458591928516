import styled, { css } from 'styled-components';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { TD, TR } from '@atomic/mol.table';
import { Color, FontWeight, Spacing } from '@atomic/obj.constants';

const CANCEL_BUTTON_WIDTH = '200px';

export const OrderUpdateTableColumnStyled = styled(TD)`
  ${props => props.editable && `background-color: ${props.selected ? Color.AlertLight : Color.White}`}
`;

const cssHoverButton = css`
  :hover {
    background-color: ${props => (props.selected ? Color.Secondary : Color.AlertLight)};
    filter: brightness(90%);
    color: ${props => (props.selected ? Color.Primary : Color.Alert)};
  }
`;

export const CancelOrderButtonStyled = styled(Button)`
  border: none;
  background-color: ${props => (props.selected ? Color.Secondary : Color.AlertLight)};
  color: ${props => (props.selected ? Color.Primary : Color.Alert)};
  width: ${CANCEL_BUTTON_WIDTH};
  ${props => !props.disabled && cssHoverButton}
`;

export const ChangeOrderStyled = styled(TextField)`
  border: none;
  background-color: ${props => (props.selected ? Color.AlertLight : Color.White)};
  font-weight: ${FontWeight.Normal};
  text-align: center;
`;

const cssDisabled = css`
  td {
    background-color: ${Color.Black}60;
  }

  input,
  button,
  button:hover {
    background-color: transparent;
    color: ${Color.Black};
  }

  input:hover {
    outline: none;
    border: none;
    border-color: none;
    box-shadow: none;
  }
`;

export const TableTR = styled(TR)`
  margin-top: ${Spacing.Medium};
  ${props => props.disabled && cssDisabled};
`;
