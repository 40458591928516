import styled, { css } from 'styled-components';
import { Color, FontFamily, Spacing } from '@atomic/obj.constants';

export const GradeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Spacing.Medium};
  margin-bottom: ${Spacing.Medium};
`;

const cssSelected = css`
  border: 3px solid ${Color.Primary};
`;

export const GradeNote = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${({ color }) => color};

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Color.White};
  cursor: pointer;

  &:first-child {
    border-radius: 6px 0px 0px 6px;
  }

  &:last-child {
    border-radius: 0px 6px 6px 0px;
  }

  font-family: ${FontFamily.Primary};

  ${({ selected }) => selected && cssSelected};
`;
