/* eslint-disable prettier/prettier */
export interface InpsModalStrings {
  title: string;
  gradeQuestion: string;
  messageQuestion: string;
  successMessage: string;
  successSubMessage: string;
  forecastMessage: {
    forecastPart1: string;
    forecastPart2: string;
    forecastPart3: string;
  };
  forecastMessageSimpleMessage: string;
  dontSeeAgainMessage: string;
  closeButton: string;
  advanceButton: string;
  submitButton: string;
  npsAnswerError: string;
}

export interface InpsModalLanguages {
  pt: InpsModalStrings;
  es: InpsModalStrings;
  in: InpsModalStrings;
}

const pt: InpsModalStrings = {
  title: 'Participe do nosso NPS!',
  gradeQuestion: 'De 0 a 10 o quanto você recomendaria o portal Gerdau Mais?',
  messageQuestion: 'O que te motivou a avaliar dessa forma? (opcional)',
  successMessage: 'Obrigado pela participação.',
  successSubMessage: 'Sua contribuição nos ajuda a melhorar constantemente o portal Gerdau Mais',
  forecastMessage: {
    forecastPart1: 'Essa pesquisa estará disponível até: ',
    forecastPart2: ' - Faltam ',
    forecastPart3: ' dias',
  },
  forecastMessageSimpleMessage: 'Essa pesquisa estará disponível por 30 dias',
  dontSeeAgainMessage: 'Fechar e não ver novamente',
  closeButton: 'Fechar',
  advanceButton: 'Avançar',
  submitButton: 'Enviar resposta',
  npsAnswerError: 'Não foi possível responder a pesquisa, por favor tente novamente.',
};

const es: InpsModalStrings = {
  title: 'Únete a nuestro NPS!',
  gradeQuestion: 'De 0 a 10 cuánto recomendaría el portal Gerdau Plus?',
  messageQuestion: 'Qué te motivó a calificarlo de esa manera? (opcional)',
  successMessage: 'Gracias por su participación.',
  successSubMessage: 'Su contribución nos ayuda a mejorar constantemente el portal Gerdau Plus.',
  forecastMessage: {
    forecastPart1: 'Esta encuesta estará disponible hasta: ',
    forecastPart2: ' - Quedan ',
    forecastPart3: ' días',
  },
  forecastMessageSimpleMessage: 'Esta encuesta estará disponible durante 30 días.',
  dontSeeAgainMessage: 'Cerrar y no volver a ver',
  closeButton: 'Cerrar',
  advanceButton: 'Avance',
  submitButton: 'Enviar respuesta',
  npsAnswerError: 'No se puede responder a la encuesta, inténtalo de nuevo.',
};

const en: InpsModalStrings = {
  title: 'Join our NPS!',
  gradeQuestion: 'From 0 to 10 how much would you recommend the Gerdau Plus portal?',
  messageQuestion: 'What motivated you to rate it that way? (optional)',
  successMessage: 'Thank you for your participation.',
  successSubMessage: 'Your contribution helps us to constantly improve the Gerdau Plus portal.',
  forecastMessage: {
    forecastPart1: 'This survey will be available until: ',
    forecastPart2: ' - ',
    forecastPart3: ' days left',
  },
  forecastMessageSimpleMessage: 'This survey will be available for 30 days',
  dontSeeAgainMessage: 'Close and don\'t see again',
  closeButton: 'Close',
  advanceButton: 'Advance',
  submitButton: 'Send answer',
  npsAnswerError: 'Unable to respond to survey, please try again.',
};

export const Strings: InpsModalLanguages = {
  pt,
  es,
  in: en,
};
