import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import Container from 'typedi';
import { CustomerDataSource } from '@app/data/http';
import { GetCustomerParams } from '@app/data/http/customer-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { Customer } from '@app/models';
import {
  ICustomerPersistState,
  ICustomerSearchState,
  ICustomerSearchFullFiled,
} from '../../customer-search.interfaces';

export const fetchCustomerAdminAndInit = (params: GetCustomerParams, initCustomerSearch) => async dispatch => {
  await dispatch(fetchCustomersAdmin(params));
  return await dispatch(initCustomerSearch);
};

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const fetchCustomersAdmin = createAsyncThunk(
  'customer-search/fetchCustomersAdmin',
  async (params: GetCustomerParams, thunkAPI) => {
    const promiseRequestTime: number = new Date().getTime();
    const state: any = thunkAPI.getState();
    const customerPersistState: ICustomerPersistState = state.customerPersist;

    return await CustomerDataSource.getCustomer(params)
      .then((data: Customer[]) => {
        return { promiseRequestTime, data, customerPersistState };
      })
      .catch(err => {
        if (err.message.includes('401')) {
          authStorageService.logout();
        }
        return err;
      });
  },
);

export const addFetchCustomersAdminReducer = builder => {
  builder.addCase(fetchCustomersAdmin.pending, (state: ICustomerSearchState) => {
    state.currentRequestTime = new Date().getTime();
    state.loading = true;
  });
  builder.addCase(
    fetchCustomersAdmin.fulfilled,
    (state: ICustomerSearchState, action: PayloadAction<ICustomerSearchFullFiled>) => {
      const payload = action.payload;
      if (payload.promiseRequestTime >= state.currentRequestTime) {
        const bps: Customer[] = payload.data as Customer[];
        state.clientBpsSearch = bps;
        state.loading = false;
        state.error = false;
      }
    },
  );
  builder.addCase(fetchCustomersAdmin.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });
};
