import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { DeliveryStatus } from '@app/models';
import { Strings, IOrderTabOpenStrings } from '@app/modules/order/order-tabs/MI/tab-open/order-tab-open.string';
import { Badge } from '@atomic/atm.badge';

const statusColors = {
  [DeliveryStatus.Delay]: { color: 'delay', dark: true, textColor: 'delayText' },
  [DeliveryStatus.DelayTrend]: { color: 'delayTrend', dark: true, textColor: 'delayTrendText' },
  [DeliveryStatus.OnTime]: { color: 'success' },
  [DeliveryStatus.NoForecast]: { color: 'neutral' },
  [DeliveryStatus.NotConfirmed]: { color: 'warning', dark: true },
  [DeliveryStatus.NotAnalized]: { color: 'warning' },
  [DeliveryStatus.StockWaitingBilling]: { color: 'primary' },
  [DeliveryStatus.LackOfCharge]: { color: 'lackOfCharge', dark: true, textColor: 'whiteText' },
};

export const DeliveryStatusRenderer: React.FC<{ data: any; setValue: any }> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabOpenStrings = Strings[userInfo.language];

  const { data } = props;
  const deliveryStatus = data.deliveryStatus;

  if (!data || !deliveryStatus) {
    return null;
  }

  return (
    <Badge
      color={statusColors[deliveryStatus]?.color}
      dark={statusColors[deliveryStatus]?.dark}
      textColor={statusColors[deliveryStatus]?.textColor}
    >
      {strings?.deliveryStatus[deliveryStatus]}
    </Badge>
  );
};
