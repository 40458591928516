import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../scheduling-complete-modal.constants';
import { ISchedulingCompleteModalState } from '../scheduling-complete-modal.interfaces';
import { initialState } from './scheduling-complete-modal.initial';
import { reducers } from './scheduling-complete-modal.reducers';
import { addThunkReducers } from './scheduling-complete.thunks';

export const schedulingCompleteModalSlice = createSlice<
  ISchedulingCompleteModalState,
  SliceCaseReducers<ISchedulingCompleteModalState>
>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const {
  setSchedulingComplete,
  setOpenSchedulingCompleteConfigModal,
  setAditionalDays,
} = schedulingCompleteModalSlice.actions;

export const schedulingCompleteReducer = schedulingCompleteModalSlice.reducer;
