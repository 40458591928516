export const orderColumns = (columns: any[]) => {
  if (columns) {
    const length = columns.length;
    const sortedColumns = [...columns].sort((a, b) => {
      return a.headerName.localeCompare(b.headerName);
    });

    const first = sortedColumns.slice(0, Math.ceil(length / 3));
    const second = sortedColumns.slice(Math.ceil(length / 3), Math.ceil((2 * length) / 3));
    const third = sortedColumns.slice(Math.ceil((2 * length) / 3), length);
    return [[...first], [...second], [...third]];
  } else {
    return [[], [], []];
  }
};
