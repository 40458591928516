export interface ICustomerSearchStrings {
  label: (selectedBPs: number, totalBPs: number) => string;
}

interface ICustomerSearchLanguages {
  pt: ICustomerSearchStrings;
  es: ICustomerSearchStrings;
  in: ICustomerSearchStrings;
}

const pt: ICustomerSearchStrings = {
  label: (selectedBPs, totalBPs) => `Visão consolidada (${selectedBPs} de ${totalBPs})`,
};

const es: ICustomerSearchStrings = {
  label: (selectedBPs, totalBPs) => `Vista consolidada (${selectedBPs} de ${totalBPs})`,
};

const ing: ICustomerSearchStrings = {
  label: (selectedBPs, totalBPs) => `Consolidated view (${selectedBPs} out of ${totalBPs})`,
};

export const Strings: ICustomerSearchLanguages = {
  pt,
  es,
  in: ing,
};
