import React from 'react';
import { tableColumnRegions } from '@app/modules/shipping-request/shipping-request.constants';
import { TR } from '@atomic/mol.table';
import { ShippingRequestTablerHeaderRegion } from './shipping-request-table-header-region.component';

export const ShippingRequestTableHeaderRegions: React.FC = () => {
  return (
    <TR>
      {tableColumnRegions.map(columnRegion => (
        <ShippingRequestTablerHeaderRegion
          key={columnRegion.title}
          title={columnRegion.title}
          colSpan={columnRegion.colSpan}
          color={columnRegion.color}
          textColor={columnRegion.textColor}
        />
      ))}
    </TR>
  );
};
