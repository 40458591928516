import ColorFunc from 'color';
import styled from 'styled-components';
import { InputLabel } from '@atomic/atm.typography';
import { Color, Spacing } from '@atomic/obj.constants';

const HANDLE_DIAMETER = 20;
const SWITCH_HEIGHT = 14;
const SWITCH_WIDTH = 34;
const SWITCH_ICON_PADDING = '1px';

export interface SwitchStyledProps {
  disabled?: boolean;
  checked?: boolean;
  alert?: boolean;
}

export const SwitchWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & ${InputLabel} {
    margin-left: ${Spacing.Small};
  }
`;

export const SwitchRootStyled = styled.div`
  position: relative;
  display: inline-block;
  text-align: left;
  opacity: ${(props: SwitchStyledProps) => (props.disabled ? 0.5 : 1)};
  border-radius: ${SWITCH_HEIGHT / 2}px;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  transition: opacity 0.25s;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const SwitchBackgroundStyled = styled.div`
  height: ${SWITCH_HEIGHT}px;
  width: ${SWITCH_WIDTH}px;
  margin: ${Math.max(0, (HANDLE_DIAMETER - SWITCH_HEIGHT) / 2)}px;
  position: relative;
  background-color: ${(props: SwitchStyledProps) => {
    if (props.alert) {
      return props.checked
        ? ColorFunc(Color.Success)
            .lighten(0.4)
            .hsl()
            .string()
        : ColorFunc(Color.Alert)
            .lighten(0.4)
            .hsl()
            .string();
    } else {
      return props.checked
        ? ColorFunc(Color.Primary)
            .lighten(0.9)
            .hsl()
            .string()
        : Color.Gray;
    }
  }};
  border-radius: ${SWITCH_HEIGHT / 2}px;
  cursor: ${(props: SwitchStyledProps) => (props.disabled ? 'default' : 'pointer')};
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  transition: background-color 0.25s;
`;

export const SwitchHandleStyled = styled.div`
  height: ${HANDLE_DIAMETER}px;
  width: ${HANDLE_DIAMETER}px;
  background-color: ${(props: SwitchStyledProps) =>
    props.alert ? (props.checked ? Color.Success : Color.Alert) : props.checked ? Color.Primary : Color.GrayDark};
  cursor: ${(props: SwitchStyledProps) => (props.disabled ? 'default' : 'pointer')};
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: ${Math.max(0, (SWITCH_HEIGHT - HANDLE_DIAMETER) / 2)}px;
  outline: 0;
  border: 0;
  /* box-shadow: 0 0 5px 0px rgba(0,0,0,0.5); */
  transform: translate(${(props: SwitchStyledProps) => (props.checked ? SWITCH_WIDTH / 2 : 1)}px);
  -webkit-transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;
  -moz-transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;
  transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;
`;

export const SwitchIconWrapperStyled = styled.div`
  padding-top: ${SWITCH_ICON_PADDING};
  padding-left: ${SWITCH_ICON_PADDING};
`;
