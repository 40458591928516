export enum OrderInputEvents {
  OrderInputSuccess = 'OrderInputSuccess',
  OrderInputError = 'OrderInputError',
  ValidateMaterialError = 'ValidateMaterialError',
  ValidateMultipleError = 'ValidateMultipleError',
  OrderInputOptionClick = 'OrderInputOptionClick',
  OrderInputOptionAddMoreItemsClick = 'OrderInputOptionAddMoreItemsClick',
  DownloadOrderDetail = 'DownloadOrderDetail',
  MaterialCatalogDownload = 'MaterialCatalogDownload',
  OrderInputConfigColumnsClick = 'OrderInputConfigColumnsClick',
  ConfirmarPedido = 'ConfirmarPedido',
  OrderReadDataError = 'OrderReadDataError',
}
