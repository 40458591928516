import * as React from 'react';
import { TabItemStyled, TabItemTextStyled } from './tabs.component.style';

interface TabProps {
  handleClick: (index: number) => void;
  index: number;
  currentIndex: number;
}
export const Tab: React.FC<TabProps> = ({ children, index, currentIndex, handleClick }) => {
  return (
    <TabItemStyled onClick={() => handleClick(index)}>
      <TabItemTextStyled active={index === currentIndex}>{children}</TabItemTextStyled>
    </TabItemStyled>
  );
};
