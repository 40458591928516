import { SLICE_NAME } from '../scheduling-complete-modal.constants';
import { ISchedulingCompleteModalState } from '../scheduling-complete-modal.interfaces';

export const RESET_SCHEDULING_COMPLETE_ACTION_TYPE = `${SLICE_NAME}/reset`;
export const RESET_SCHEDULING_COMPLETE_ACTION = { type: RESET_SCHEDULING_COMPLETE_ACTION_TYPE };

export const reducers = {
  reset: (state: ISchedulingCompleteModalState) => {
    state.announcementData = null;
    state.loading = false;
    state.hasSchedulingCompleteAnnouncement = false;
  },
  setSchedulingComplete: (state: ISchedulingCompleteModalState, { payload }) => {
    state.announcementData = payload;
  },
  setOpenSchedulingCompleteConfigModal: (state: ISchedulingCompleteModalState, { payload }) => {
    state.openedConfigModal = payload;
  },
  setAditionalDays: (state: ISchedulingCompleteModalState, { payload }) => {
    state.aditionalDays = payload;
  },
};
