import * as React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { FlexColumn } from '@atomic/obj.box';

export const SelectFieldShimmer: React.FC = () => {
  return (
    <FlexColumn mr>
      <TextShimmerBoxStyled />
      <TextShimmerBoxStyled height='14px' width='100%' margin='0' />
    </FlexColumn>
  );
};
