/* eslint-disable complexity */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
//import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, NewFormHttpRequestBuilder } from '@app/data/http';
//import { ConfigurationsReponse } from '@app/data/http/quote-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
//import { handleUnauthorized } from '@app/utils/http-utils';
import {
  conditionalAways,
  mapAdditionalAttributesList,
  mapBendingFlow,
  mapFormFlow,
  mapLengthMultiplesFlow,
  mapLengthToleranceFlow,
  mapSpecificSpecification,
  mapSteelFlow,
} from '../utils/form.dto';
//import { FormStrings } from '../form.string';

interface CallbackParams {
  payload?: {
    field: string;
    corporateGroup?: string;
    steel?: string;
    specification?: string;
    length?: any;
    min?: string;
    max?: string;
    version?: number;
    step?: string;
    multiple?: number;
    shape?: string;
    gerdauProcess?: string;
    heatTreatment?: string;
    finishing?: string;
    diameter?: string;
    side?: string;
    width?: string;
    thickness?: string;
    isCustom?: boolean;
  };
  url: string;
  action: string;
}

export const FormFlowDatasource = {
  async getFormFlow(params): Promise<any> {
    const user = Container.get(AuthStorageService).getUser();

    const defaultHeaders = { ...Container.get(NewFormHttpRequestBuilder).config.headers, language: user.language };

    const flow = params?.flow && params?.flow !== undefined ? params.flow : 'NEW_PRODUCT';

    const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${endpoint.FORM}/forms/steps/${
      params.step
    }?flow=${flow}`;

    try {
      const { data, status } = await axios.get(url, { headers: defaultHeaders });
      if (status === 200) {
        return mapFormFlow(data.data, params.step);
      } else {
        return null;
      }
    } catch (error) {
      //handleUnauthorized(error);
      return null;
    }
  },
  async callAttributeCallback(params: CallbackParams): Promise<any> {
    const user = Container.get(AuthStorageService).getUser();

    const defaultHeaders = { ...Container.get(NewFormHttpRequestBuilder).config.headers, language: user.language };

    if (params.action === 'getSteelList') {
      const payload = { corporateGroup: params.payload.corporateGroup };
      params?.payload?.steel && Object.assign(payload, { steel: params.payload.steel });
      if (params?.payload?.isCustom) {
        Object.assign(payload, { isCustom: true });
        payload.corporateGroup && delete payload.corporateGroup;
      }
      const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${params.url}`;

      const { data, status } = await axios.post(url, payload, { headers: defaultHeaders });

      if (status === 200) {
        const otherOptions =
          params.payload.field === 'STEEL_NAME'
            ? { key: 'OTHER', label: 'Outro', value: 'OTHER', conditional: conditionalAways }
            : null;
        //: { key: 'NONE', label: 'Nenhuma das opções', value: 'NONE', conditional: conditionalAways };
        return { field: params.payload.field, list: mapSteelFlow(data.data, otherOptions) };
      } else {
        return null;
      }
    } else if (params.action === 'getSpecificSpecificationList') {
      const payload = { steel: params.payload.steel };
      if (params.payload.corporateGroup) {
        Object.assign(payload, { corporateGroup: params.payload.corporateGroup });
      }
      if (params.payload.isCustom) {
        Object.assign(payload, { isCustom: params.payload.isCustom });
      }
      const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${params.url}`;

      const { data, status } = await axios.post(url, payload, { headers: defaultHeaders });

      if (status === 200) {
        return { field: 'SPECIFIC_SPECIFICATION', data: data.data, options: mapSpecificSpecification(data.data) };
      } else {
        return [{ key: 'OTHER', label: 'Outra', value: 'OTHER', conditional: conditionalAways }];
      }
    } else if (params.action === 'getChemicalComposition') {
      const payload = { steel: params.payload.steel, specification: params.payload.specification };
      //params.payload.isCustom && Object.assign(payload, { isCustom: params.payload.isCustom });
      const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${params.url}`;

      const { data, status } = await axios.post(url, payload, { headers: defaultHeaders });

      if (status === 200) {
        return { field: params.payload.field, data: data.data };
      } else {
        return null;
      }
    } else if (params.action === 'getShorterAttributes') {
      const payload = {
        length: { min: parseFloat(params.payload.min), max: parseFloat(params.payload.max) },
        version: params.payload.version,
      };

      params?.payload?.shape && Object.assign(payload, { shape: params.payload.shape });
      params?.payload?.gerdauProcess && Object.assign(payload, { gerdauProcess: params.payload.gerdauProcess });
      params?.payload?.heatTreatment && Object.assign(payload, { heatTreatment: params.payload.heatTreatment });
      params?.payload?.finishing && Object.assign(payload, { finishing: params.payload.finishing });
      params?.payload?.diameter && Object.assign(payload, { diameter: params.payload.diameter });
      params?.payload?.side && Object.assign(payload, { side: params.payload.side });
      params?.payload?.width && Object.assign(payload, { width: params.payload.width });
      params?.payload?.thickness && Object.assign(payload, { thickness: params.payload.thickness });
      params?.payload?.diameter && Object.assign(payload, { diameter: params.payload.diameter });

      const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${params.url}`;

      const { data, status } = await axios.post(url, payload, { headers: defaultHeaders });

      if (status === 200) {
        return { field: 'LENGTH', data: mapLengthToleranceFlow(data.data) };
      } else {
        return null;
      }
    } else if (params.action === 'getLengthMultiples') {
      const payload = {
        length: params.payload.length,
        multiple: params.payload.multiple,
        version: params.payload.version,
      };

      const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${params.url}`;

      const { data, status } = await axios.post(url, payload, { headers: defaultHeaders });

      if (status === 200) {
        return { field: 'LENGTH_MULTIPLES', data: mapLengthMultiplesFlow(data.data) };
      } else {
        return null;
      }
    } else if (params.action === 'getBending') {
      const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${params.url}`;

      const { data, status } = await axios.post(url, params.payload, { headers: defaultHeaders });

      if (status === 200) {
        return { field: 'BENDING', data: mapBendingFlow(data.data) };
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
  async getAdditionalAttributesList(params): Promise<any> {
    const user = Container.get(AuthStorageService).getUser();

    const defaultHeaders = { ...Container.get(NewFormHttpRequestBuilder).config.headers, language: user.language };

    const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${endpoint.FORM}/forms/steps/${params.flow}`;

    try {
      const { data, status } = await axios.get(url, { headers: defaultHeaders });
      if (status === 200) {
        return mapFormFlow(data.data, params.flow);
      } else {
        return null;
      }
    } catch (error) {
      //handleUnauthorized(error);
      return null;
    }
  },
  async getClientList(params) {
    const user = Container.get(AuthStorageService).getUser();

    const defaultHeaders = { ...Container.get(NewFormHttpRequestBuilder).config.headers, language: user.language };

    const url = `${Container.get(NewFormHttpRequestBuilder).config.baseUrl}${endpoint.FORM}/clients/filter?name=${
      params.client
    }`;

    const { data, status } = await axios.get(url, { headers: defaultHeaders });

    if (status === 200) {
      return data.data;
    } else {
      return [];
    }
  },
};

export function useFormFlowInitial(params, onSuccess?, onError?) {
  return useQuery(['getFormFlow', params], () => FormFlowDatasource.getFormFlow(params), {
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
    retry: 2,
  });
}

export function useFormFlow(onSuccess?, onError?) {
  return useMutation(FormFlowDatasource.getFormFlow, {
    onSuccess,
    onError,
    retry: false,
  });
}

export function useGetAdditionalAttributesList(params, onSuccess?, onError?) {
  return useQuery(['getFormFlow', params], () => FormFlowDatasource.getAdditionalAttributesList(params), {
    onSuccess,
    onError,
    select: data => {
      return mapAdditionalAttributesList(data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useCallBackAttribute(onSuccess?, onError?, onSettled?) {
  return useMutation(FormFlowDatasource.callAttributeCallback, {
    onSuccess,
    onError,
    onSettled,
    retry: false,
  });
}

export function useGetClientsList(params?: any, onSuccess?) {
  return useQuery(['getClientsList'], () => FormFlowDatasource.getClientList(params), {
    onSuccess,
    enabled: params.enabled && params.client !== undefined,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
