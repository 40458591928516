import styled from 'styled-components';
import { Color, BrandColor, FontSize, Spacing } from '@atomic/obj.constants';

export const ColumnItemWrapper = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => (props.hasOpacity ? 'opacity: 1;' : 'opacity: 0')};
  cursor: move;
`;

export const ColumnIndexWrapper = styled.div`
  font-weight: bold;
  color: ${Color.GrayDark};
  margin: 0;
  font-size: ${FontSize.Small};
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  padding: ${Spacing.XSmall} ${Spacing.Small} ${Spacing.XSmall} ${Spacing.Small};
`;

export const ColumnBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${BrandColor.LightBlue};
  border-radius: ${Spacing.XSmall};
  padding: ${Spacing.Small};
`;

export const ColumnTitleWrapper = styled.div`
  color: ${Color.GrayDark};
  margin: 0;
  font-size: ${FontSize.Small};
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  margin-left: ${Spacing.Small};
  margin-right: ${Spacing.Small};
`;

export const ColumnActionWrapper = styled.div`
  cursor: pointer;
`;
