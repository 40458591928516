export const gridValueToCurrency = (value: string, currency: string) => {
  if (currency === 'USD') {
    return parseFloat(value).toLocaleString('es-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else {
    return parseFloat(value).toLocaleString('pt-Br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
};

export const toCurrency = (value: string, currency: string) => {
  const val = value ? value : '0';

  if (currency === 'USD') {
    return parseFloat(val).toLocaleString('es-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    return parseFloat(val).toLocaleString('pt-Br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};
