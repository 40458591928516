export interface IAdvancedFiltersButtonStrings {
  advancedFilters: string;
}

interface IAdvancedFiltersButtonLanguages {
  pt: IAdvancedFiltersButtonStrings;
  es: IAdvancedFiltersButtonStrings;
  in: IAdvancedFiltersButtonStrings;
}

const pt: IAdvancedFiltersButtonStrings = {
  advancedFilters: '+ Filtros',
};

const es: IAdvancedFiltersButtonStrings = {
  advancedFilters: '+ Filtros',
};

const ing: IAdvancedFiltersButtonStrings = {
  advancedFilters: '+ Filters',
};

export const Strings: IAdvancedFiltersButtonLanguages = {
  pt,
  es,
  in: ing,
};
