import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { CrmFilterData } from '@app/models/crm.model';
import {
  ButtonsWrapper,
  CrmFilterListContent,
  CrmFilterListWrapper,
} from '@app/modules/complain/crm/components/crmAdvancedFilter.style';
import { CrmAdvancedFilterFields } from '@app/modules/complain/crm/components/crmAdvancedFilterFields';
import { CrmAdvancedFilterTags } from '@app/modules/complain/crm/components/crmAdvancedFilterTags';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { Button } from '@atomic/atm.button';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { CrmSearchWrapper } from './crmAdvancedFilter.style';

export interface CrmFilterProps {
  currentFilter: CrmFilterData;
  setCurrentFilter: (data: CrmFilterData) => void;
  handleEditFilters: (value: string, key: string, action: string) => void;
  emptyFieldValue: (key: string) => void;
  onSubmit: (data: any) => void;
}

export const CrmSearchBar = (props: CrmFilterProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  return (
    <Form onSubmit={props.onSubmit}>
      <CrmSearchWrapper>
        <CrmAdvancedFilterFields
          label='Campo de busca'
          name='search'
          type='text'
          onBlur={props.handleEditFilters}
          onFocus={props.emptyFieldValue}
        />

        <Hbox grow>
          <Button expanded type='submit'>
            {QuoteStrings[userInfo.language].filterModal.research}
          </Button>
        </Hbox>

        <Body>
          Você pode buscar por nome do cliente, aço, norma, número do CRM, período de criação, planta de abertura, etc.
          É possível também combinar as informações inseridas nesse campo com os filtros localizados na lateral
          esquerda.
        </Body>
      </CrmSearchWrapper>
      {props.currentFilter?.search?.length > 0 && (
        <CrmFilterListContent>
          <CrmFilterListWrapper>
            <Hbox>
              {props.currentFilter?.search && (
                <CrmAdvancedFilterTags
                  dataKey={'search'}
                  data={props.currentFilter.search}
                  handleEditFilters={props.handleEditFilters}
                />
              )}
            </Hbox>
            <ButtonsWrapper>
              <Hbox grow hAlign='end'>
                <Hbox.Item>
                  <Button kind='link' onClick={() => props.handleEditFilters('', 'search', 'removeAll')}>
                    {QuoteStrings[userInfo.language].filterModal.filterReset}
                  </Button>
                </Hbox.Item>
              </Hbox>
            </ButtonsWrapper>
          </CrmFilterListWrapper>
        </CrmFilterListContent>
      )}
    </Form>
  );
};
