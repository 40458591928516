import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { AppThunkDispatch } from '@app/core/redux/store';
import { isQuoteChatComplainCrmPage } from '@app/utils/verify-quote-crm-complain-page';
import { getOnboarding } from './navigation/thunks/get-onboarding.thunk';

export interface IOnboardingContainerProps {
  children: JSX.Element;
  hasUserLoggedIn: boolean;
}

export const OnboardingContainer: React.FC<IOnboardingContainerProps> = ({ children, hasUserLoggedIn }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectUrl = searchParams.get('redirect');

  useEffect(() => {
    if (!redirectUrl && hasUserLoggedIn && !isQuoteChatComplainCrmPage()) {
      dispatch(getOnboarding());
    }
  }, [hasUserLoggedIn]);

  return <>{children}</>;
};
