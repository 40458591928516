export enum ErrorType {
  IncorrectData = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Timeout = 408,
  Internal = 500,
}

export enum CustomErrorType {
  WrongEmailPassword = 'UAE101',
}
