import React from 'react';
import { dateParserPTBR } from '@app/utils/date-parser';
import { Body } from '@atomic/atm.typography';
import { IOrderUpdateTableData } from '../../../order-update.interfaces';

interface OrderUpdateTableStaticDataProps {
  item: IOrderUpdateTableData;
  column: string;
}

export const OrderUpdateTableStaticData: React.FC<OrderUpdateTableStaticDataProps> = ({ item, column }) => {
  if (item[column]) {
    return <Body centralized>{dateParserPTBR({ unformattedDate: item[column], hideHours: true })}</Body>;
  }

  return null;
};
