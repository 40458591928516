import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TagCheckboxField, TagCheckboxGroup } from '@atomic/atm.tag-checkbox';
import { Form } from '@atomic/obj.form';
import {
  Strings,
  IOrderTabOpenInternationalStrings,
} from '../order-tabs/ME/road/tab-open-international/order-tab-open-international.string';
import { IStatusByLanguage, StatusByLanguage } from './grid-open-international-row-filter-options';

interface GridOpenRowFilterProps {
  value: string;
  onTagChanged: (value: string) => void;
}

export const GridOpenInternationalRowFilter: React.FC<GridOpenRowFilterProps> = ({ value, onTagChanged }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabOpenInternationalStrings = Strings[userInfo?.language];

  const enumStatusbyLanguage: IStatusByLanguage = StatusByLanguage[userInfo?.language];

  const options = [
    {
      id: enumStatusbyLanguage.InAnalysis,
      label: strings.status.inAnalysis,
      checked: true,
    },
    {
      id: enumStatusbyLanguage.NotConfirmed,
      label: strings.status.notConfirmed,
      checked: true,
    },
    {
      id: enumStatusbyLanguage.Confirmed,
      label: strings.status.confirmed,
      checked: true,
    },
  ];

  return (
    <Form>
      <Form.Field
        name='openIntertationalFilter'
        value={value.split(',')}
        onValueChange={changedValue => {
          if (changedValue.join() !== value) {
            onTagChanged(changedValue.join());
          }
        }}
      >
        <TagCheckboxGroup>
          {options.map(option => (
            <TagCheckboxField key={option.id} id={option.id}>
              {option.label}
            </TagCheckboxField>
          ))}
        </TagCheckboxGroup>
      </Form.Field>
    </Form>
  );
};
