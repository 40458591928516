import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { RootState } from '@app/core/redux/store';
import { DashboardDataSource } from '@app/data/http/dashboard.datasource';
import { useGetDashboard } from '@app/domain/get-dashboard.use-case';
import {
  DashboardGraphName,
  DashboardLegendData,
  StockOrderGraphs,
  StockStatusGraph,
  StockStepGraph,
} from '@app/models/dashboard.model';
import { setReloadStockDashboard } from '@app/providers/navigation/navigation.store';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Frame } from '@atomic/atm.frame';
import { H2 } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { DashboardBlockHeader } from './dashboard-block-header.component';
import { DashboardBlockShimmer } from './dashboard-block.shimmer';
import { DashboardGroupLegend } from './dashboard-group-legend';
import { Strings, IDashBoardStrings } from './dashboard.string';
import { COLORS, orderStockStage, orderStockStatus } from './random-data';
import { DashboardProps } from '.';

const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = '95%';

const strings: IDashBoardStrings = Strings.pt;
const { stockOrders: stockOrdersStrings } = strings.graphs;

const stockStepInfo = (data: StockStepGraph) => {
  return [
    { name: stockOrdersStrings.stockStep.inStock, value: data?.inStock },
    { name: stockOrdersStrings.stockStep.loading, value: data?.loading },
    { name: stockOrdersStrings.stockStep.shipping, value: data?.shipping },
  ];
};

const orderStatusInfo = (data: StockStatusGraph) => {
  return [
    { name: stockOrdersStrings.stockStatus.expired, value: data?.expired },
    { name: stockOrdersStrings.stockStatus.onTime, value: data?.onTime },
  ];
};

const STOCK_DASHBOARD_DATA = 'stock_dashboard_data';
const DO_REQUESTS_FOR_DASHBOARDS = 'do_requests_for_dashboards';

export const StockDashboard: React.FC<DashboardProps> = props => {
  const { reloadStockDashboard } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();

  const [stockDashboard, setStockDashboardData] = useLocalStorageState(STOCK_DASHBOARD_DATA, {});
  const doRequestForDashboards = window.localStorage.getItem(DO_REQUESTS_FOR_DASHBOARDS);

  const { data, loading, error, performRequest } = useGetDashboard<StockOrderGraphs>(
    DashboardDataSource.getStockOrders,
    props.onSuccess,
  );

  const stockStepGraphLegend: DashboardLegendData[] = [
    { title: stockOrdersStrings.total, unit: 't', amount: data?.stockStep?.total },
    {
      title: stockOrdersStrings.stockStep.inStock,
      unit: 't',
      amount: data?.stockStep.inStock,
      iconColor: Color.Accessory,
    },
    {
      title: stockOrdersStrings.stockStep.loading,
      unit: 't',
      amount: data?.stockStep.loading,
      iconColor: Color.Primary,
    },
    {
      title: stockOrdersStrings.stockStep.shipping,
      unit: 't',
      amount: data?.stockStep.shipping,
      iconColor: Color.PinkDark,
    },
  ];

  const stockStatusGraphLegend: DashboardLegendData[] = [
    { title: stockOrdersStrings.total, unit: 't', amount: data?.stockStatus?.total },
    {
      title: stockOrdersStrings.stockStatus.expired,
      unit: 't',
      amount: data?.stockStatus.expired,
      iconColor: Color.Accessory,
    },
    {
      title: stockOrdersStrings.stockStatus.onTime,
      unit: 't',
      amount: data?.stockStatus.onTime,
      iconColor: Color.Primary,
    },
  ];

  useEffect(() => {
    if (reloadStockDashboard) {
      performRequest(props.clientNumber);
      dispatch(setReloadStockDashboard(false));
    }
  }, [reloadStockDashboard]);

  useEffect(() => {
    if (props.clientNumber && doRequestForDashboards === 'true') {
      performRequest(props.clientNumber);
      dispatch(setReloadStockDashboard(false));
    }
  }, [props.clientNumber, doRequestForDashboards]);

  useEffect(() => {
    if (data) {
      setStockDashboardData(data);
    }
  }, [data]);

  return (
    <LoadingState loading={loading} data={!!stockDashboard} error={!!error}>
      <LoadingState.Shimmer>
        <DashboardBlockShimmer blocks={2} />
      </LoadingState.Shimmer>

      {!!stockDashboard && (
        <div key={props.screenWidth}>
          <H2 center>{strings.stockOrder.title}</H2>
          <Row>
            <Col xs={12} md={6}>
              <Frame>
                <VSeparator />

                <DashboardBlockHeader
                  title={strings.stockOrder.step}
                  description={strings.stockOrder.stepDescription}
                />
                <VSeparator />

                <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
                  <PieChart>
                    <Pie
                      data={stockStepInfo(stockDashboard.stockStep)}
                      isAnimationActive={false}
                      dataKey='value'
                      cx='50%'
                      cy='50%'
                      innerRadius={80}
                      outerRadius={110}
                      startAngle={90}
                      endAngle={450}
                      label
                    >
                      {orderStockStage.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
                <VSeparator />

                <DashboardGroupLegend legend={stockStepGraphLegend} graph={DashboardGraphName.StockStepGraph} />
                <VSeparator />
              </Frame>
            </Col>
            <Col xs={12} md={6}>
              <Frame>
                <VSeparator />

                <DashboardBlockHeader
                  title={strings.stockOrder.status}
                  description={strings.stockOrder.statusDescription}
                />
                <VSeparator />

                <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
                  <PieChart>
                    <Pie
                      data={orderStatusInfo(stockDashboard.stockStatus)}
                      isAnimationActive={false}
                      dataKey='value'
                      cx='50%'
                      cy='50%'
                      innerRadius={80}
                      outerRadius={110}
                      label
                    >
                      {orderStockStatus.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
                <VSeparator />

                <DashboardGroupLegend legend={stockStatusGraphLegend} graph={DashboardGraphName.StockStatusGraph} />
                <VSeparator />
              </Frame>
            </Col>
          </Row>
        </div>
      )}
    </LoadingState>
  );
};
