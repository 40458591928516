import * as React from 'react';
import { AttachedFilesDataProps } from '@app/models/crm.model';
import { AttachedWrapperLink } from '@app/modules/quote/crm/crm-detail/components/crmContent.style';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { CrmStrings } from '../../crmComplain.string';
import { useDownloadAttachedFileComplain } from '../hooks/useDownloadAttachedFileComplain';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

interface CrmAttachedFilesProps {
  data: AttachedFilesDataProps;
  loading: boolean;
}

export const CrmAttachedFilesComplain = (props: CrmAttachedFilesProps) => {
  const strings = CrmStrings.pt;

  const { mutate: handleDownloadAttached } = useDownloadAttachedFileComplain();

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.ATTACHED_FILES} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>
      {props?.data?.infos?.length > 0 ? (
        <CrmContentInfosWrapper>
          <Separator small />

          <Table collapse>
            <TR bordered>
              <TD color={Color.Secondary}>
                <Body bold={600}>{strings.tables.cols.fileName}</Body>
              </TD>
              <TD color={Color.Secondary}>
                <Body bold={600}>{strings.tables.cols.typeFile}</Body>
              </TD>
              <TD color={Color.Secondary}>
                <Body bold={600}>{strings.tables.cols.createdBy}</Body>
              </TD>
              <TD color={Color.Secondary}>
                <Body bold={600}>{strings.tables.cols.createdAt}</Body>
              </TD>
            </TR>
            {props.data.infos.length > 0 &&
              props.data.infos.map((item, index) => (
                <TR bordered key={`a-${index}`}>
                  <TD>
                    <Body>
                      <AttachedWrapperLink
                        onClick={() =>
                          handleDownloadAttached({
                            filename: item.value[0].fileName,
                            url: item.value[0].link,
                            type: item.value[0].typeFile,
                          })
                        }
                        rel='noreferrer'
                      >
                        {item.value[0].fileName}
                      </AttachedWrapperLink>
                    </Body>
                  </TD>
                  <TD>
                    <Body>{item.value[0].typeFile}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value[0].createdBy}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value[0].createdAt}</Body>
                  </TD>
                </TR>
              ))}
          </Table>
        </CrmContentInfosWrapper>
      ) : (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />
            <Body centralized>{props.loading ? 'Carregando...' : 'Nenhum registro encontrado.'}</Body>
          </Hbox>
        </CrmContentInfosWrapper>
      )}
    </CrmContentWrapper>
  );
};
