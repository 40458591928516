import { IAdminTab, TabInternalName } from '@app/models/admin.model';

export const getTabIdList = (newTabArray: any[]) => {
  const tabIdList = [];

  newTabArray.forEach(t => {
    tabIdList.push(t._id);
  });

  return tabIdList;
};

export const orderedTabs = (tab: IAdminTab) => {
  const orderedTab: IAdminTab = {
    _id: tab._id,
    tag: tab.tag,
    name: tab.name,
    description: tab.description,
    order: 0,
  };

  switch (tab.tag) {
    case TabInternalName.Dashboard:
      orderedTab.order = 1;
      return orderedTab;
    case TabInternalName.Quotation:
      orderedTab.order = 2;
      return orderedTab;
    case TabInternalName.OrderInput:
      orderedTab.order = 3;
      return orderedTab;
    case TabInternalName.Order:
      orderedTab.order = 4;
      return orderedTab;
    case TabInternalName.Finance:
      orderedTab.order = 5;
      return orderedTab;
    case TabInternalName.Manifest:
      orderedTab.order = 6;
      return orderedTab;
    case TabInternalName.IsaManager:
      orderedTab.order = 7;
      return orderedTab;
    case TabInternalName.Crm:
      orderedTab.order = 8;
      return orderedTab;
    case TabInternalName.Surrogate:
      orderedTab.order = 9;
      return orderedTab;
    case TabInternalName.LackOfLoad:
      orderedTab.order = 10;
      return orderedTab;
    case TabInternalName.SchedulingCompleted:
      orderedTab.order = 11;
      return orderedTab;
    default:
      orderedTab.order = 1;
      return orderedTab;
  }
};

export const compareTabOrder = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};
