import { AnalysisCreatedBy, AttributeComments, CommentsFullData, CommentsPermissions } from '@app/models/quote.model';
import { dateParser } from '@app/utils/date-parser';

export interface CommentsFullResponse {
  key: string;
  creation: CommentsCreationResponse;
  client_visible: boolean;
  value: string;
  permissions: CommentsPermissions;
}

export interface CommentsCreationResponse {
  origin: string;
  createdBy: AnalysisCreatedBy;
  createdDate: string;
  status_stage: {
    key: string;
    label: string;
  };
}

export interface CommentsTempResponse {
  comment?: {
    key: string;
    creation: CommentsCreationResponse;
    client_visible: boolean;
    value: string;
    permissions: CommentsPermissions;
  };
  plants?: string[];
  key?: string;
  creation?: CommentsCreationResponse;
  client_visible?: boolean;
  value?: string;
  permissions?: CommentsPermissions;
}

export const mapCommentsResponse = (res: CommentsFullResponse[]): CommentsFullData => {
  return {
    internalComments: mapComments(res.filter(comment => comment.client_visible === false)),
    externalComments: mapComments(res.filter(comment => comment.client_visible !== false)),
  };
};

export const mapComments = (res: CommentsFullResponse[]): AttributeComments[] => {
  return res.map(comment => {
    return {
      origin: comment.creation.origin,
      createdBy: comment.creation.createdBy,
      createdDate: dateParser({ unformattedDate: comment.creation.createdDate }),
      creationStatus: {
        key: comment.creation.status_stage.key,
        label: comment.creation.status_stage.label,
      },
      statusKey: comment.creation.status_stage.key,
      statusLabel: comment.creation.status_stage.label,
      key: comment.key,
      value: comment.value,
      permissions: comment.permissions,
    };
  });
};

export const mapTempComment = (res: CommentsTempResponse, replicate?: boolean): AttributeComments => {
  return {
    origin: replicate ? res.comment.creation.origin : res.creation.origin,
    createdBy: replicate ? res.comment.creation.createdBy : res.creation.createdBy,
    createdDate: dateParser({
      unformattedDate: replicate ? res.comment.creation.createdDate : res.creation.createdDate,
    }),
    creationStatus: {
      key: replicate ? res.comment.creation.status_stage.key : res.creation.status_stage.key,
      label: replicate ? res.comment.creation.status_stage.label : res.creation.status_stage.label,
    },
    statusKey: replicate ? res.comment.creation.status_stage.key : res.creation.status_stage.key,
    statusLabel: replicate ? res.comment.creation.status_stage.label : res.creation.status_stage.label,
    key: replicate ? res.comment.key : res.key,
    value: replicate ? res.comment.value : res.value,
    permissions: { isEditable: true, isDelible: true },
    externalComment: replicate ? res.comment.client_visible : res.client_visible,
    loading: true,
  };
};
