import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import { AppPath } from '@app/modules/app/route-constants';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { Button } from '@atomic/atm.button';
import { ISidebarFiltersStrings, Strings } from '../../sidebar-filters.strings';
import { hasSomeNoConfirmedFiltersSelector } from '../../store/sidebar-filters.selectors';
import { confirmFilters } from '../../store/sidebar-filters.store';
import { FooterWrapper } from './sidebar-filters-footer.style';

export const SidebarFiltersFooter: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const history = useHistory();
  const tab = history.location.pathname === AppPath.FINANCE ? LayoutTabs.FinanceMI : MItabIndex[orderTabIndex];
  const strings: ISidebarFiltersStrings = Strings[userInfo.language];
  const hasSomeNoConfirmedFilters = useSelector(state => hasSomeNoConfirmedFiltersSelector(state, tab));

  const dispatch = useDispatch();

  return (
    <FooterWrapper>
      <Button disabled={!hasSomeNoConfirmedFilters} onClick={() => dispatch(confirmFilters(tab))}>
        {strings.filterButton}
      </Button>
    </FooterWrapper>
  );
};
