import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Frame } from '@atomic/atm.frame';
import { H3 } from '@atomic/atm.typography';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Strings, ITruckTrackingStrings } from '../../../truck-tracking.string';
import { TruckTrackingStep } from '../truck-tracking-step/truck-tracking-step.component';

export const TruckTrackingData: React.FC = () => {
  const { generalInformations } = useSelector((state: RootState) => state.truckTracking);
  const strings: ITruckTrackingStrings = Strings.pt;
  const { truckData } = strings;

  return (
    <Frame>
      <VSeparator />
      <Grid fluid>
        <H3>{truckData?.title}</H3>
        <VSeparator />

        <TruckTrackingStep title={truckData?.plate} observation={generalInformations?.licensePlate} />
        <VSeparator />

        <TruckTrackingStep title={truckData?.route} observation={generalInformations?.route} />
        <VSeparator />
      </Grid>
    </Frame>
  );
};
