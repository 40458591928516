import { addCheckSurrogateReducer } from './check-surrogate.thunk';
import { addDeleteSurrogateReducer } from './delete-surrogate.thunks';
import { addGetSurrogateReducer } from './get-surrogate.thunks';
import { addPostSurrogateReducer } from './post-surrogate.thunks';
import { addPutSurrogateReducer } from './put-surrogate.thunks';

export const addThunkReducers = builder => {
  addGetSurrogateReducer(builder);
  addPostSurrogateReducer(builder);
  addPutSurrogateReducer(builder);
  addDeleteSurrogateReducer(builder);
  addCheckSurrogateReducer(builder);
};
