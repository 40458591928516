import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AttributesDetails, QuoteAttributes } from '@app/models/quote.model';
import { statusInfo, statusOptions } from '@app/modules/quote/quote.contants';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { SelectField } from '@atomic/atm.select';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { QuoteStrings } from '../../quote.string';
import { TechnicalAnalysisContext } from '../technical-analysis.context';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';
import { TagStyled } from '../technical-analysis.style';
import { checkPendingCharacteristcAnalysis } from '../utils/checkPendingCharacteristcAnalisys';
import { AttributeCritical } from './attribute-critical.component';
import { GerdauDefaultWrapper } from './complementaries.component.style';

export interface AttributeCellHeaderProps {
  title: string;
  isEditing: boolean;
  recurrentProduct: boolean;
  readOnly: boolean;
  status: string;
  attribute: QuoteAttributes;
  editedStatus: string;
  editedAttributes?: string[];
  flagInternationalStandard?: boolean;
  onEditClick: () => void;
  onDeleteAttribute: () => void;
  onAttributeStatusChange: (status: string) => void;
  onChangeCritical: (option: boolean) => void;
}
const Option = ({ value, name }) => <option value={value}>{name}</option>;

// eslint-disable-next-line complexity
export const AttributeCellHeader: React.FC<AttributeCellHeaderProps> = props => {
  const { contextLoading } = React.useContext(TechnicalAnalysisContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const visible = !props.readOnly && !props.recurrentProduct;
  const statusBadge = QuoteStrings[userInfo.language].constants.statusAttributes;
  const tagPendingStrings = TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell.tagPending;
  const btnAnalysis = TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell.btnAnalysis;

  const canEditDerogate = checkPendingCharacteristcAnalysis(
    props.attribute?.status,
    props.attribute?.derogate?.permissions?.isEditable,
    props.attribute?.derogate?.suggestionStatus,
  );

  return (
    <>
      <Row>
        <Col xs={9}>
          <Hbox>
            <Hbox.Item noGrow>
              <H3 data-testid='attribute-title'>{props.title}</H3>
            </Hbox.Item>
            <Hbox.Separator />

            {!props.recurrentProduct && (
              <Hbox.Item noGrow>
                <Badge color={statusInfo[props.status].badge}>{statusBadge[props.status]}</Badge>
              </Hbox.Item>
            )}
          </Hbox>
          {props.attribute.detail && (
            <Hbox>
              <Hbox.Item noGrow>
                <VSeparator />
                <Body>
                  {`${props.attribute.key === 'LENGTH_MULTIPLES' ? `${AttributesDetails[props.attribute.key]}:` : ''} ${
                    props.attribute.detail
                  } ${props.attribute.unit ? props.attribute.unit?.value?.min : ''}`}
                </Body>
              </Hbox.Item>
              <Hbox.Separator />

              {props.flagInternationalStandard && (
                <Hbox.Item noGrow>
                  <VSeparator />
                  <Badge color='warning'>{QuoteStrings[userInfo.language].constants.internationalStandard}</Badge>
                </Hbox.Item>
              )}
            </Hbox>
          )}
          {props.attribute.creation === 'GERDAU' && !props.editedAttributes?.includes(props.attribute.key) && (
            <GerdauDefaultWrapper>
              <Body>{QuoteStrings[userInfo.language].defaultMessageGerdau.analysisPage}</Body>
            </GerdauDefaultWrapper>
          )}
        </Col>

        <Col xs={3}>
          <LoadingState loading={contextLoading} enableActivityIndicator={true}>
            <Hbox hAlign='flex-end'>
              {canEditDerogate && (
                <TagStyled>
                  <Badge color='alert'>{tagPendingStrings}</Badge>
                </TagStyled>
              )}
              {props.attribute.permissions.isEditable && !props.isEditing && visible && (
                <Hbox.Item noGrow>
                  <Button kind='secondary' onClick={props.onEditClick}>
                    <Hbox>
                      <Hbox.Item noGrow vAlign='center'>
                        <FaIcon.Custom icon='pen' />
                      </Hbox.Item>
                      <Hbox.Separator />
                      {btnAnalysis}
                    </Hbox>
                  </Button>
                  <Hbox.Separator />
                </Hbox.Item>
              )}
              {props.attribute.permissions.isDelible && !props.isEditing && visible && (
                <Hbox.Item noGrow>
                  <FaIcon.Custom
                    onClick={props.onDeleteAttribute}
                    clickable='true'
                    size='1x'
                    icon='trash-alt'
                    color={Color.Alert}
                  />
                </Hbox.Item>
              )}
              {props.isEditing && (
                <Hbox.Item>
                  <SelectField
                    initialValue={props.editedStatus === 'non-status' ? 'non-analysed' : props.editedStatus}
                    onValueChange={props.onAttributeStatusChange}
                  >
                    {statusOptions.map((option, index) => (
                      <Option key={index} value={option.value} name={option.name} />
                    ))}
                  </SelectField>
                </Hbox.Item>
              )}
            </Hbox>
          </LoadingState>
        </Col>
      </Row>

      <AttributeCritical
        onChangeCritical={props.onChangeCritical}
        permission={props.attribute.permissions.isEditable && !props.isEditing && visible}
        attribute={props.attribute}
      />
    </>
  );
};
