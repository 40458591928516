import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { SearchMaterialField } from '@app/modules/order-input/components/search-material-field/search-material-field';
import { setEditNewLine, setQuery, setSelectedMaterials } from '@app/modules/order-input/store/order-input.store';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { RadioField } from '@atomic/atm.radio';
import { TD, TR } from '@atomic/mol.table';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { FormField } from '@atomic/obj.form';
import { CustomDateField } from '../custom-data-field/custom-date-field.component';
import { AmountInput, OrderNumberInput, UnityWrapper } from './amount-table-edit-row.style';

export const AmountTableEditRow: React.FC = () => {
  const [material, setMaterial] = useState<Material>({ orderItem: '' });
  const { materialOptions, selectedMaterials, searchBy } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch();

  const onSelectOption = (option: Material) => {
    const materialOption: Material = materialOptions.find(m => m.materialName === option.materialName);
    const materialSelected: Material = {
      tempId: new Date().getTime().toString(),
      materialCode: materialOption.materialCode,
      materialDescription: materialOption.materialDescription,
      materialName: materialOption.materialName,
      minMaxLenght: materialOption.minMaxLenght,
      multipleLength: materialOption.multipleLength,
      standardGrade: materialOption.standardGrade,
      plant: materialOption.plant,
      orderNumber: material.orderNumber,
      orderItem: material.orderItem,
      amount: material.amount,
      unity: material.unity,
      date: material.date,
      formatDescription: searchBy,
    };

    dispatch(setSelectedMaterials([...selectedMaterials, materialSelected]));
    dispatch(setQuery(''));
    dispatch(setEditNewLine(false));
  };

  const materialDate = material.date ? new Date(material.date) : null;

  return (
    <TR>
      <TD>
        <FlexRow vAlign='center' hAlign='center'>
          <CheckboxField id={`chk_${material.materialCode}_0}`} disabled={true}></CheckboxField>
        </FlexRow>
      </TD>
      <TD>
        <OrderNumberInput
          defaultValue={material.orderNumber}
          onBlur={e => {
            setMaterial({ ...material, orderNumber: e.target.value });
          }}
        />
      </TD>
      <TD>
        <input
          defaultValue={material.orderItem}
          onBlur={e => {
            setMaterial({ ...material, orderItem: e.target.value });
          }}
        />
      </TD>
      <TD>
        <FlexColumn hAlign='center'>
          <SearchMaterialField onClick={onSelectOption} />
        </FlexColumn>
      </TD>
      <TD>
        <AmountInput
          defaultValue={material.amount}
          onBlur={e => {
            setMaterial({ ...material, amount: e.target.value });
          }}
          type='number'
          step='0.01'
        />
      </TD>
      <TD>
        <UnityWrapper>
          <FormField
            name='unity'
            onValueChange={value => {
              setMaterial({ ...material, unity: value.toString() });
            }}
          >
            <RadioField id='T'>T</RadioField>
            <RadioField id='KG'> KG</RadioField>
          </FormField>
        </UnityWrapper>
      </TD>
      <TD>
        <CustomDateField
          selectedDate={materialDate}
          onValueChange={value => {
            setMaterial({ ...material, date: value });
          }}
        />
      </TD>
      <TD>
        <FaIcon.Trash
          color={Color.Alert}
          onClick={() => {
            setMaterial(null);
            dispatch(setEditNewLine(false));
          }}
        />
      </TD>
    </TR>
  );
};
