export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatQtyFieldString = (value: string): string => {
  if (value) {
    const floatValue = parseFloat(value.replace(',', '.'));
    return formatQtyField(floatValue);
  }
  return null;
};

export const formatQtyField = (value: number): string => {
  if (value === null) {
    return '';
  }

  let formatedValue: string = value.toFixed(3);

  if (formatedValue.charAt(formatedValue.length - 1) === '0') {
    formatedValue = value.toFixed(2);
    if (formatedValue.charAt(formatedValue.length - 1) === '0') {
      formatedValue = value.toFixed(1);
      if (formatedValue.charAt(formatedValue.length - 1) === '0') {
        formatedValue = value.toFixed(0);
      }
    }
  }
  return formatedValue.replace('.', ',');
};
