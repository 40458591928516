import * as React from 'react';
import { useStore } from '@app/core/global-store.service';
import { FlashMessageStoreContent, FlashMessageTargetName, useFlashStore, FlashStoreProps } from '@app/providers';
import { FlashMessageType } from '@atomic/mol.flash-message';

type FlashMessageHook = [
  (type: FlashMessageType, content: string, alignRight?: boolean, small?: boolean) => void,
  () => void,
  FlashMessageStoreContent,
];

export const useFlashMessage = (target: FlashMessageTargetName): FlashMessageHook => {
  const { flash, showMessage, hideMessage } = useStore<FlashStoreProps>(useFlashStore);

  const data = React.useMemo<FlashMessageStoreContent>(() => {
    return flash[target] || { type: 'info', content: '', hidden: true, alignRight: false, small: false };
  }, [flash, target]);

  const show = React.useCallback(
    (type: FlashMessageType, content: string, alignRight: boolean, small: boolean) => {
      showMessage(target, type, content, alignRight, small);
    },
    [showMessage, target],
  );

  const hide = () => {
    hideMessage(target);
  };

  return [show, hide, data];
};
