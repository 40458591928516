export interface ITruckTrackingStrings {
  previousPage: string;
  actualPage: string;
  header: {
    salesOrder: string;
    material: string;
    back: string;
  };
  updates: {
    title: string;
    shippingTime: string;
    deliveryTime: string;
    location: string;
    locationDetail: (city: string, state: string, remainingDistance: number) => string;
    invoice: (number: string) => string;
    invoiceMessage: (date: string) => string;
    shipped: string;
    delivered: string;
    formattedDate: {
      morning: string;
      afternoon: string;
      night: string;
    };
    lastUpdate: string;
  };
  truckData: {
    title: string;
    plate: string;
    route: string;
    vehicle: string;
  };
  subtitleData: {
    title: string;
    caption: {
      gerdau: string;
      truck: string;
      destination: string;
    };
    description: {
      gerdau: string;
      truck: string;
    };
  };
  noData: string;
  noTracking: string;
  unavalable: string;
  trackingFinished: string;
  feedback: string;
  expiredMessage: string;
}

interface ITruckTrackingLanguages {
  pt: ITruckTrackingStrings;
}

const pt: ITruckTrackingStrings = {
  previousPage: 'Carteira de pedidos',
  actualPage: 'Acompanhar entrega',
  header: {
    salesOrder: 'Ordem de compra:',
    material: 'Material cliente:',
    back: 'Voltar',
  },
  updates: {
    title: 'Últimas atualizações',
    shippingTime: 'Previsão de envio',
    deliveryTime: 'Previsão de entrega',
    location: 'Localização atual',
    locationDetail: (city: string, state: string, remainingDistance: number) =>
      `${city} - ${state} - à ${remainingDistance}km do destino`,
    invoice: number => `NF ${number}`,
    invoiceMessage: date => `Emitida em ${date}`,
    shipped: 'Saiu para entrega',
    delivered: 'Entregue',
    formattedDate: {
      morning: 'pela manhã',
      afternoon: 'pela tarde',
      night: 'pela noite',
    },
    lastUpdate: 'Atualizado em ',
  },
  truckData: {
    title: 'Dados do caminhão',
    plate: 'Placa do veículo',
    route: 'Itinerário',
    vehicle: 'Tipo de veículo',
  },
  subtitleData: {
    title: 'Detalhes',
    caption: {
      gerdau: 'Fornecedor',
      truck: 'Veículo',
      destination: 'Destino',
    },
    description: {
      gerdau: 'Gerdau',
      truck: 'Caminhão',
    },
  },
  noData:
    'Infelizmente não temos dados disponíveis sobre essa entrega, por favor entre em contato com nossa equipe para maiores informações',
  noTracking: 'Que pena! Essa entrega não é rastreável',
  unavalable: 'O sistema de rastreio está temporariamente indisponível, favor tentar novamente em alguns instantes.',
  trackingFinished: 'Oba! O pedido foi entregue',
  feedback: 'De 1 a 5, o quão você está satisfeito com o acompanhamento da entrega no Gerdau Mais?',
  expiredMessage: 'Data e hora da última atualização está no passado e pode não refletir a posição atual do caminhão.',
};

export const Strings: ITruckTrackingLanguages = {
  pt,
};
