import * as React from 'react';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Strings, ITruckTrackingStrings } from '../../../truck-tracking.string';
import { TrackingPlaceholderWrapperStyled } from '../../../truck-tracking.style';

const strings: ITruckTrackingStrings = Strings.pt;
export const TruckTrackingFinishedPlaceholder: React.FC = () => {
  return (
    <TrackingPlaceholderWrapperStyled>
      <Hbox>
        <Hbox.Item hAlign='center'>
          <Image.TrackingFinishedPlaceholder />
          <H2>{strings.trackingFinished}</H2>
        </Hbox.Item>
      </Hbox>
    </TrackingPlaceholderWrapperStyled>
  );
};
