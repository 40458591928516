import * as React from 'react';
import { Tag } from '@atomic/atm.tag/tag.component';
import { FormFieldContext, FormFieldContextState } from '@atomic/obj.form';

interface TagRadioFieldProps {
  id: any;
  onClick?: (id: any) => void;
  disabled?: boolean;
  type?: 'success' | 'alert' | 'analysis' | 'accepted' | 'other';
  label?: string;
}

export const TagRadioField: React.FC<TagRadioFieldProps> = props => {
  const formFieldConsumer = React.useContext<FormFieldContextState>(FormFieldContext);
  if (!formFieldConsumer) {
    throw new Error('<TagRadioField /> must be wrapped with a <Form.Field> tag');
  }

  const handlePress = () => {
    if (props.onClick) {
      props.onClick(props.id);
    }
    formFieldConsumer.onValueChange(props.id, null);
  };

  return (
    <Tag
      showCheck={false}
      selected={formFieldConsumer.value === props.id}
      onClick={handlePress}
      disabled={props.disabled}
      label={props.label}
      type={
        props.type === 'analysis' ||
        props.type === 'other' ||
        props.type === 'success' ||
        props.type === 'accepted' ||
        props.type === 'alert'
          ? props.type
          : null
      }
    >
      {props.children}
    </Tag>
  );
};
