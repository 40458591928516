import { AppConfig } from '@app/configure-app';
import { AppWindow } from '@app/utils/interfaces';

// QUERY PARAM VARIABLES
export const QUERY_KEY_CLIENT_ID = 'client_id';
export const QUERY_KEY_CLIENT_SECRET = 'client_secret';

// GERDAU KEYS
export const GERDAU_KEY_CLIENT_ID = 'client_id';
export const GERDAU_KEY_CLIENT_SECRET = 'client_secret';

// CXP KEYS VALUES
const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;
export const CXP_KEY_CLIENT_ID = config?.cxpClientId;
export const CXP_KEY_CLIENT_SECRET = config?.cxpClientSecret;

export const ISA_KEY_CLIENT_ID = '2a1565142b974b0bbc8681cad74ef38b';
export const ISA_KEY_CLIENT_SECRET = '44064989A8774DCaA6939C2015E1Ec03';

// AUTH NEW LOGIN KEYS
export const AUTH_KEY_CLIENT_ID = config?.authClientId;
export const AUTH_KEY_CLIENT_SECRET = config?.authClientSecret;

// AUTH NEW KEYS
// NOVA CREDENCIAL
export const BAGG_KEY_CLIENT_ID = 'c360aa0e40414bc3b84966e1e9898d9e';
export const BAGG_KEY_CLIENT_SECRET = '629fbA262Cc44Fb48eae93b17A60555b';
// export const BAGG_KEY_CLIENT_ID = '099772ba06504269a22b52390e6b6869';
// export const BAGG_KEY_CLIENT_SECRET = 'eb84AB00F82547aE84cCBF79bbec875b';
