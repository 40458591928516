import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Route } from '@app/core/route';
import { ProfileTypesId } from '@app/models/admin.model';
import { AppPath } from '../app/route-constants';
import { useQuoteGuard } from '../quote/quote.guard';
import { ChatPage } from './chat.page';

export const ChatRouterV2: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteAuthorized = useQuoteGuard();

  return (
    <Route
      exact
      path={AppPath.CHAT_V2.DETAIL}
      component={ChatPage}
      authorized={quoteAuthorized && userInfo?.profile?.profile === ProfileTypesId.Rtc}
      redirectPath={AppPath.QUOTE.BASE}
    />
  );
};
