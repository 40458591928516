import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';

export const AttributesTabsWrapperStyled = styled.div`
  width: 100%;
  ul {
    justify-content: space-between;
    li {
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0;
      text-align: center;
      a {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const AttributesContentWrapperStyled = styled.div`
  max-height: 400px;
  overflow: scroll;
  padding-right: 0px;
  & > div {
    border-left: none;
    border-right: none;
    &:hover {
      background-color: ${Color.GrayLight} !important;
    }
  }
`;
