import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Hbox } from '@atomic/obj.box';

interface BackButtonParams {
  handleClick: () => void;
}

export const BackButton: React.FC<BackButtonParams> = ({ handleClick }) => {
  return (
    <Hbox>
      <Hbox.Item noGrow>
        <Button kind='secondary' onClick={handleClick}>
          <Hbox>
            <Hbox.Item noGrow vAlign='center'>
              <FaIcon.LeftArrow />
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item>Voltar</Hbox.Item>
          </Hbox>
        </Button>
      </Hbox.Item>
    </Hbox>
  );
};
