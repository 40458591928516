import React from 'react';
import { VSeparator } from '@atomic/obj.grid';
import { OrderColumns } from './partials/order-columns/order-columns.component';
import { SelectColumns } from './partials/select-columns/select-columns.component';

export const ScheduleModalColumnsBody: React.FC = () => {
  return (
    <>
      <SelectColumns />
      <OrderColumns />
      <VSeparator lineVisible />
    </>
  );
};
