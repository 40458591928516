import { Pagination } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setBPActiveId } from '@app/modules/admin/store/admin.store';
import { getBPs } from '@app/modules/admin/store/thunk/bps-get.thunk';
import { LIMIT_ITEMS_PER_PAGE } from '@app/modules/admin/utils/utils';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';

export const AdminBPPagination: React.FC = () => {
  const { bpSearch } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handlePagination = page => {
    const query = {
      skip: 0,
      limit: LIMIT_ITEMS_PER_PAGE,
      filterType: bpSearch.filterOption,
      filterValue: bpSearch.textQuery,
    };

    if (bpSearch.selectedOptions?.length) {
      query.filterValue = bpSearch.selectedOptions.join();
    } else if (bpSearch.textQuery?.length > 2) {
      query.filterValue = bpSearch.textQuery;
    } else {
      query.filterValue = '';
    }

    query.skip = Math.ceil(page) * LIMIT_ITEMS_PER_PAGE - LIMIT_ITEMS_PER_PAGE;
    dispatch(setBPActiveId(''));
    dispatch(getBPs(query));
  };

  if (!bpSearch.filteredBPs?.length) {
    return null;
  }

  return (
    <FlexRow hAlign='center' mb={Spacing.Small}>
      <Pagination
        ariaLabel='pagination'
        onChange={e => handlePagination(e)}
        select={false}
        showTotal={false}
        total={bpSearch.pagination.count}
        pageSizeOptions={[LIMIT_ITEMS_PER_PAGE]}
      />
    </FlexRow>
  );
};
