import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { InternationalMaritimeTabIndex, InternationalTabIndex } from '@app/models/order.model';
import { setIsMaritime } from '@app/modules/components/customer-search/store/customer-persist.store';
import { Visibility } from '@atomic/obj.visibility';
import { hasMaritimeBps, hasRoadBps } from '../../../../utils/bps-utils';
import { OrderTabHarborMaritime } from './maritime/tab-harbor/order-tab-maritime-harbor.container';
import { OrderTabOpenMaritime } from './maritime/tab-open/order-tab-maritime-open.container';
import { OrderTabShippedMaritime } from './maritime/tab-shipped/order-tab-maritime-shipped.container';
import { OrderTabDocuments } from './road/tab-documents/order-tab-documents.component';
import { OrderTabFrontier } from './road/tab-frontier/order-tab-frontier.container';
import { OrderTabOpenInternational } from './road/tab-open-international/order-tab-open-international.container';
import { OrderTabShipped } from './road/tab-shipped/order-tab-shipped.container';

export const OrderTabsMEContent: React.FC = () => {
  const { isMaritime, clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const dispatch = useDispatch();
  const { orderTabIndex } = useSelector((state: RootState) => state.order);

  useEffect(() => {
    if (hasMaritimeBps(clientBpsSelected) && !hasRoadBps(clientBpsSelected)) {
      dispatch(setIsMaritime(true));
    }

    if (hasRoadBps(clientBpsSelected) && !hasMaritimeBps(clientBpsSelected)) {
      dispatch(setIsMaritime(false));
    }
  }, [clientBpsSelected]);

  if (isMaritime) {
    return (
      <>
        <Visibility visible={orderTabIndex === InternationalMaritimeTabIndex.Open}>
          <OrderTabOpenMaritime />
        </Visibility>
        <Visibility visible={orderTabIndex === InternationalMaritimeTabIndex.Harbor}>
          <OrderTabHarborMaritime />
        </Visibility>
        <Visibility visible={orderTabIndex === InternationalMaritimeTabIndex.Shipped}>
          <OrderTabShippedMaritime />
        </Visibility>
      </>
    );
  } else {
    return (
      <>
        <Visibility visible={orderTabIndex === InternationalTabIndex.Open}>
          <OrderTabOpenInternational />
        </Visibility>
        <Visibility visible={orderTabIndex === InternationalTabIndex.Frontier}>
          <OrderTabFrontier />
        </Visibility>
        <Visibility visible={orderTabIndex === InternationalTabIndex.Shipped}>
          <OrderTabShipped />
        </Visibility>
        <Visibility visible={orderTabIndex === InternationalTabIndex.Documents}>
          <OrderTabDocuments />
        </Visibility>
      </>
    );
  }
};
