import styled from 'styled-components';
import { Color, FontFamily, Spacing } from '@atomic/obj.constants';

interface DropzoneStyledProps {
  isDragAccept?: boolean;
  isDragReject?: boolean;
  isDragActive?: boolean;
  height?: string;
}

const getColor = (props: DropzoneStyledProps) => {
  if (props.isDragAccept) {
    return Color.Secondary;
  }
  if (props.isDragReject) {
    return Color.Alert;
  }
  return Color.Gray;
};

export const DropzoneStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.Medium};
  border-width: 1px;
  border-radius: 2px;
  border-color: ${(props: DropzoneStyledProps) => getColor(props)};
  border-style: dashed;
  background-color: ${Color.GrayLight};
  color: ${Color.GrayDropZone};
  outline: none;
  transition: border 0.24s ease-in-out;
  height: ${(props: DropzoneStyledProps) => (props.height ? props.height : 'auto')};
  cursor: pointer;
`;

export const DropzoneStyledLink = styled.span`
  font-family: ${FontFamily.Primary};
  color: ${Color.Primary};
  cursor: pointer;
`;
