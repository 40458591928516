export enum QuoteScreensAnalytics {
  Quote = 'Cotação',
  TechnicalAnalysis = 'AnaliseTecnica',
}

export enum QuoteEvents {
  StatusFilter = 'FiltroStatus',
  StartDateFilter = 'FiltroDataInicio',
  EndDateFilter = 'FiltroDataFim',
}

export enum TechnicalAnalysisEvents {
  TechnicalAnalysisView = 'AnaliseTecnicaVisualizada',
  StatusChangeError = 'AlteracaoStatusErro',
  PlantView = 'PlantaVisualizada',
  StatusChange = 'AlteracaoStatus',
  StatusChangeClosed = 'AlteracaoStatusFechado',
  EditAnalysis = 'EditarAnalise',
  DuplicateAnalysis = 'DuplicarAnalise',
  RefreshAnalysis = 'RecarregarAnalise',
  Assets = 'Anexos',
  PriorityPpapChange = 'AlteracaoPriorityPPAP',
  ApqpAlreadyAttached = 'ApqpJaEstaAnexo',
  ApqpNeed = 'PrecisaApqpNaoEstaAnexo',
  NoNeedApqp = 'NaoPrecisaApqp',
  DownloadApqpFile = 'DownloadApqpModelo',
  ResponsiblesChangeError = 'ErroTrocaDeResponsaveis',
  SendToBaggSuccess = 'ReenviadoParaBagg',
  ClickOnMESButton = 'AbrindoJanelaDeDadosDoMES',
  ClickOnSimilarButton = 'AbrindoJanelaSimilares',
}

export enum TechnicalAnalysisTabEvents {
  PlantEditionError = 'EdicaoPlantaErro',
  ReviewPlant = 'RevisaoPlanta',
  SelectPLant = 'SelecaoPlanta',
  ValidatePlant = 'ValidacaoPlanta',
  NewAttribute = 'NovoAtributo',
  ConsolidatedView = 'VisaoConsolidada',
}

export enum TechnicalAnalysusAttributeEvents {
  AttributeStatusChange = 'AlteracaoStatusAtributo',
  TableRowStatusChange = 'AlteracaoStatusLinhaTabela',
  TableRowReasonChange = 'AlteracaoMotivoLinhaTabela',
  TableRowSuggestionChange = 'AlteracaoSugestaoLinhaTabela',
  TableRowMinChange = 'AlteracaoMinimoLinhaTabela',
  TableRowMaxChange = 'AlteracaoMaximoLinhaTabela',
  CancelAttributeEdition = 'CancelarEdicaoAtributo',
  EditAttributeError = 'EdicaoAtributoErro',
  DeleteAttributeError = 'DeletarAtributoErro',
  DeleteImagesError = 'DeletarImagesErro',
  DeleteAttributeSuccess = 'DeletarAtributoSucesso',
  EditAttributeFormError = 'EdicaoAtributoFormErro',
  EditAttributeCriticalError = 'EdicaoAtributoCaracteristicaCriticaErro',
  EditAttribute = 'EdicaoAtributo',
  AcceptDerogate = 'AceiteDerroga',
  DeleteAttribute = 'DelecaoAtributo',
  ComplementaryInfo = 'InformaçõesAdicionais',
  ChangeTestMethod = 'EdicaoMetodoEnsaios',
}

export enum TechnicalAnalysisAboutEvents {
  DownloadAnalysis = 'DownloadAnalysis',
  DownloadAnalysisGerdau = 'DownloadAnalysisGerdau',
  DownloadAnalysisClient = 'DownloadAnalysisClient',
}

export enum TechnicalAnalysisAssetsEvents {
  BatchDownload = 'DownloadEmLote',
  AddAsset = 'AdicaoAnexo',
  AssetVisibility = 'VisibilidadeAnexo',
  DeleteAsset = 'DeletarAnexo',
}

export enum TechnicalAnalysisCommentsEvents {
  ExternalComment = 'ComentarioExterno',
  DeleteExternalComment = 'DelecaoComentarioExterno',
  EditExternalComment = 'EdicaoComentarioExterno',
  InternalComment = 'ComentarioInterno',
  DeleteInternalComment = 'DelecaoComentarioInterno',
  EditInternalComment = 'EdicaoComentarioInterno',
}

export enum TechnicalAnalysisNewAttributeEvents {
  NewAttributeError = 'NovoAtributoErro',
  NewAttribute = 'AdicaoNovoAtributo',
  CloseNewAttribute = 'FecharNovoAtributo',
  EditAttribute = 'EdicaoAtributo',
}
