import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { isGKNSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { H3 } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { nameBasedOnPlant } from '../../shipping-request.constants';
import { plantsDistinctSelector } from '../../store/shipping-request.selectors';
import { ShippingRequestTable } from './partials/shipping-request-table/shipping-request-table.component';
import { ShippingRequestWarning } from './partials/shipping-request-warning/shipping-request-warning.component';
import { ShippingBoxPlaceholder } from './shipping-request-body.style';

export const ShippingRequestBody: React.FC = () => {
  const plantsDistinct = useSelector(plantsDistinctSelector);
  const isGKN = useSelector(isGKNSelector);

  return (
    <>
      <Grid fluid>
        <VSeparator />
        <Row>
          <Col xs={12}>
            {plantsDistinct.map(plant => {
              return (
                <FlexColumn key={plant}>
                  <H3>
                    {plant} - {nameBasedOnPlant[plant]}
                  </H3>
                  <VSeparator />
                  {isGKN && <ShippingRequestWarning plant={plant} />}
                  <VSeparator />
                  <ShippingRequestTable plant={plant} />
                </FlexColumn>
              );
            })}
          </Col>
        </Row>
      </Grid>
      <ShippingBoxPlaceholder />
    </>
  );
};
