export interface OrderPriorizationRuleItem {
  qtdItensCarteira: number;
  qtdItensCriticosAtivos: number;
  qtdItensCriticosPossiveis: number;
  qtdItensCriticosDisponiveis: number;
}
export interface OrderPriorizationRuleItemDto {
  qtyOrderItem: number;
  qtyActiveCriticalItem: number;
  qtyTotalAvailableCriticalItem: number;
  qtyAvailableCriticalItem: number;
}

export interface CriticalItemsResponse {
  itensCriticos: CriticalItemResponse[];
}

export interface CriticalItemResponse {
  customerId: string;
  email: string;
  ov: string;
  ovItem: string;
  oc: string;
  codProdCliente: string;
  descMaterial: string;
  dataConf: string;
  qtdConf: string;
  qtdSolic: string;
  mensagem: string;
  dataAtualizacao: string;
  status: boolean;
}

export interface CriticalItemDto {
  customerId: string;
  email: string;
  ov: string;
  ovItem: string;
  oc: string;
  codProdClient: string;
  descMaterial: string;
  dataConf: string;
  qtyConf: string;
  qtySolic: string;
  mensagem: string;
  updateDate: string;
  status: boolean;
}

export const mapOrderPriorizationRule = (res: OrderPriorizationRuleItem): OrderPriorizationRuleItemDto => {
  const orderPriorizationRule: OrderPriorizationRuleItemDto = {
    qtyOrderItem: res.qtdItensCarteira,
    qtyActiveCriticalItem: res.qtdItensCriticosAtivos,
    qtyTotalAvailableCriticalItem: res.qtdItensCriticosPossiveis,
    qtyAvailableCriticalItem: res.qtdItensCriticosDisponiveis,
  };

  return orderPriorizationRule;
};

export const mapCriticalItems = (res: CriticalItemsResponse): CriticalItemDto[] => {
  const items: CriticalItemDto[] = [];

  res.itensCriticos.map(item => {
    items.push({
      customerId: item.customerId,
      email: item.email,
      ov: item.ov,
      ovItem: item.ovItem,
      oc: item.oc,
      codProdClient: item.codProdCliente,
      descMaterial: item.descMaterial,
      dataConf: item.dataConf,
      qtyConf: item.qtdConf,
      qtySolic: item.qtdSolic,
      mensagem: item.mensagem,
      updateDate: item.dataAtualizacao,
      status: item.status,
    });
  });

  return items;
};
