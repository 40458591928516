import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IAdminNPSUser } from '@app/models/admin.model';
import { AdminDataSeparator } from '@app/modules/admin/admin.page.style';
import { AdminStrings } from '@app/modules/admin/admin.string';
import { capitalizeFirstLetter } from '@app/utils/string-utils';
import { Body } from '@atomic/atm.typography';
import { AdminNPSContainer, AdminNPSDataContainer, AdminNPSInfoContainer } from '../admin-nps.styles';
import { formatNPSDate, NPSUserStatus } from '../utils/utils';

interface AdminNPSListContentProps {
  user: IAdminNPSUser;
}

export const AdminNPSListContent: React.FC<AdminNPSListContentProps> = props => {
  const { npsStatisticsGroup } = useSelector((state: RootState) => state.admin);
  const { status, lastResponse } = props.user;
  const strings = AdminStrings.pt.nps;

  const renderStatusInfo = () => {
    if (!npsStatisticsGroup.isRunning) {
      return null;
    }

    return (
      <>
        <Body>{`${strings.statusLabel} ${NPSUserStatus[capitalizeFirstLetter(status)]}`}</Body>
        <AdminDataSeparator />
      </>
    );
  };

  return (
    <AdminNPSContainer>
      <AdminNPSInfoContainer>
        <AdminNPSDataContainer>
          {renderStatusInfo()}
          <Body>{`${strings.lastAnswerLabel} ${formatNPSDate(lastResponse)}`}</Body>
        </AdminNPSDataContainer>
      </AdminNPSInfoContainer>
    </AdminNPSContainer>
  );
};
