import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { RootState } from '@app/core/redux/store';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { Body, Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, FontSize, FontWeight } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { ErrorHandlerModalStrings, Strings } from './error-handler-modal.strings';
import {
  ErrorHandlerCloseButton,
  ErrorHandlerMessageWrapper,
  ErrorHandlerModalFooter,
  ErrorHandlerModalHeader,
  LogoDefaultWrapper,
  LogoSeparator,
  LogosWrapper,
} from './error-handler-modal.style';

export const ErrorHandlerModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { openErrorHandlerModal } = useSelector((state: RootState) => state.navigation);
  const strings: ErrorHandlerModalStrings = Strings[userInfo.language];
  const dispatch = useDispatch();

  return (
    <Modal medium opened={openErrorHandlerModal}>
      <ErrorHandlerModalHeader>
        <Label color={Color.White} size={FontSize.XLarge} weight={FontWeight.Bold}>
          {strings.title}
        </Label>
        <ErrorHandlerCloseButton onClick={() => dispatch(setOpenErrorHandlerModal(false))}>
          <Image.NewsModalHeaderCloseButton />
        </ErrorHandlerCloseButton>
      </ErrorHandlerModalHeader>
      <FlexColumn pt pb>
        <LogosWrapper>
          <Image.LogoHorizontal width='157px' />
          <LogoSeparator />
          <LogoDefaultWrapper>
            <Image.NewLogo width='272px' />
          </LogoDefaultWrapper>
        </LogosWrapper>
        <FlexRow mb hAlign='center'>
          <Image.ErrorHandler />
        </FlexRow>
        <FlexRow mt mb hAlign='center'>
          <ErrorHandlerMessageWrapper>
            <Body size={FontSize.Medium} align='center' bold={FontWeight.Bold}>
              {strings.message}
            </Body>
          </ErrorHandlerMessageWrapper>
        </FlexRow>
        <ErrorHandlerModalFooter>
          <Button
            testid={`${TEST_ID_CLOSE_MODAL}-error-handler`}
            onClick={() => dispatch(setOpenErrorHandlerModal(false))}
          >
            {strings.confirmButton}
          </Button>
        </ErrorHandlerModalFooter>
      </FlexColumn>
    </Modal>
  );
};
