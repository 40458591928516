import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { DT } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { IShippingRequestStrings, Strings } from '../../shipping-request.string';
import { ShippingRequestResume } from './partials/shipping-request-resume/shipping-request-resume.component';
import { ShippingRequestSubmit } from './partials/shipping-request-submit/shipping-request-submit.component';
import { ShippingRequestFooterWrapper } from './shipping-request-footer.style';

export const ShippingRequestFooter: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IShippingRequestStrings = Strings[userInfo.language];

  return (
    <ShippingRequestFooterWrapper>
      <FlexRow pl={Spacing.Medium}>
        <DT>{strings.page.summary}</DT>
      </FlexRow>
      <FlexRow fullWidth pl={Spacing.Medium} pt={Spacing.Medium} pb={Spacing.Medium}>
        <ShippingRequestResume />
        <ShippingRequestSubmit />
      </FlexRow>
    </ShippingRequestFooterWrapper>
  );
};
