import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2, Label } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { CrmStrings } from '../../crmComplain.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

export const CrmTotalPrices = props => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.TOTAL_PRICES} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>
      {props?.data?.infos.length > 0 ? (
        <CrmContentInfosWrapper gridRepeat={2}>
          <Hbox>
            <Separator small />
            {props.data.infos.map(
              (item, index) =>
                index < 3 && (
                  <Body key={index}>
                    <Label>{strings.fields[item.label]} </Label>
                    {item.value}
                  </Body>
                ),
            )}
            <Separator small />
          </Hbox>
          <Hbox>
            <Separator small />
            {props.data.infos.map(
              (item, index) =>
                index >= 3 && (
                  <Body key={index}>
                    <Label>{strings.fields[item.label]} </Label>
                    {item.value}
                  </Body>
                ),
            )}
            <Separator small />
          </Hbox>
        </CrmContentInfosWrapper>
      ) : (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />
            <Body centralized>{props.loading ? 'Carregando...' : 'Nenhum registro encontrado.'}</Body>
          </Hbox>
        </CrmContentInfosWrapper>
      )}
    </CrmContentWrapper>
  );
};
