/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { OrderDataSource } from '@app/data/http/order-MI.datasource';
import { DeliveryForecastParams } from '@app/data/http/order-params.dto';
import { DeliveryForecastItem, SalesOrderSummaryItem } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { PopoverContext, PopoverContextState } from '@app/modules/components/popover/popover-container';
import { addLeftCharacters } from '@app/utils/array-helper';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { FlexRow } from '@atomic/obj.box';
import { isDateBRValid } from '@atomic/obj.form/regex-validator';
import { DeliveryForecastNumber } from './delivery-forecast-cell.style';
import { DeliveryForecastInfo } from './delivery-forecast-info.component';
import { SALES_DOCUMENT_CHARACTERS, SALES_DOCUMENT_ITEM_CHARACTERS } from './delivery-forecast.constants';

interface DeliveryForecastCellComponentProps {
  data: SalesOrderSummaryItem;
}

export const DeliveryForecastCellComponent: React.FC<DeliveryForecastCellComponentProps> = ({ data }) => {
  const pluralText = data.qtdeDatasCartCli === 1 ? 'data' : 'datas';
  const { openPopover, closePopover } = useContext<PopoverContextState>(PopoverContext);
  const customerIds = useSelector(customerIdsJoinedSelector);
  const onSuccess = (items: DeliveryForecastItem[]) => {
    openPopover(ref, <DeliveryForecastInfo items={items} />);
  };

  const { performRequest, loading } = useLazyRequest<DeliveryForecastParams, DeliveryForecastItem[]>(
    OrderDataSource.getDeliveryForecast,
    onSuccess,
  );

  const ref = useRef(null);

  useEffect(() => {
    closePopover();
  }, [ref]);

  const showLink = isDateBRValid(data.dateDeliveryForecast.substring(0, 10));

  return (
    <FlexRow>
      {data.dateDeliveryForecast}
      {showLink && (
        <DeliveryForecastNumber
          ref={ref}
          onClick={() =>
            performRequest({
              customerIds,
              salesDocument: addLeftCharacters(data.ov, SALES_DOCUMENT_CHARACTERS),
              salesDocumentItem: addLeftCharacters(data.itemOv, SALES_DOCUMENT_ITEM_CHARACTERS),
            })
          }
        >
          {loading ? <ActivityIndicator size='20px' type='spinner' /> : `${data.qtdeDatasCartCli} ${pluralText}`}
        </DeliveryForecastNumber>
      )}
    </FlexRow>
  );
};
