import { isValid } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { DatePicker } from '@atomic/atm.date-picker';
import { Label } from '@atomic/atm.typography';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { useFlashMessage } from '../components/flash-message.hook';

interface FilterComplainProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date) => void;
  showDateFilter: boolean;
  getDataDownload: () => void;
  strings: any;
}

export const FilterQuoteComplain = (props: FilterComplainProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const onHandleChange = (value: Date, field: string) => {
    const date = new Date(value);
    if (isValid(date)) {
      if (field === 'start') {
        props.setStartDate(value);
      } else {
        props.setEndDate(value);
      }
    } else {
      show('alert', props.strings.errorDateField);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Label>{props.strings.from}</Label>
          <DatePicker
            startDate={props?.startDate}
            onValueChange={value => onHandleChange(value, 'start')}
            language={userInfo.language}
            maxDate={new Date()}
          />
        </Col>
        <Col>
          <Label>{props.strings.to}</Label>
          <DatePicker
            startDate={props?.endDate}
            onValueChange={value => onHandleChange(value, 'end')}
            language={userInfo.language}
            minDate={props?.startDate}
            maxDate={new Date()}
          />
        </Col>
        <Col>
          <Button onClick={props.getDataDownload}>{props.strings.btnSearch}</Button>
        </Col>
      </Row>
      <VSeparator />
    </>
  );
};
