import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';

export const ShippingRequestFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.12);
  background-color: ${Color.GrayXLight};
`;
