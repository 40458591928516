import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route } from '@app/core/route';
import { AppPath } from '@app/modules/app/route-constants';
import { hasManifestTabAccessSelector } from '../auth/store/auth.selectores';
import { useQuoteGuard } from '../quote/quote.guard';
import { ComplainPage } from './complain.page';
import { CrmPageDetails } from './crm/crm-detail/crm.page.details';
import { CrmPage } from './crm/crm.page';
import { ReportPage } from './report/report.page';

export const ComplainRouter: React.FC<{}> = () => {
  const quoteAuthorized = useQuoteGuard();
  const hasManifestTabAccess = useSelector(hasManifestTabAccessSelector);

  return (
    <>
      <Route
        exact
        path={'./'}
        component={ComplainPage}
        authorized={quoteAuthorized && hasManifestTabAccess}
        redirectPath={AppPath.QUOTE.BASE}
      />
      <Route
        exact
        path={AppPath.COMPLAIN.REPORT}
        component={ReportPage}
        authorized={quoteAuthorized && hasManifestTabAccess}
        redirectPath={AppPath.QUOTE.BASE}
      />
      <Route
        exact
        path={AppPath.COMPLAIN.CRM}
        component={CrmPage}
        authorized={quoteAuthorized && hasManifestTabAccess}
        redirectPath={AppPath.QUOTE.BASE}
      />
      <Route
        exact
        path={AppPath.COMPLAIN.CRM_DETAIL}
        component={CrmPageDetails}
        authorized={quoteAuthorized && hasManifestTabAccess}
        redirectPath={AppPath.QUOTE.BASE}
      />
    </>
  );
};
