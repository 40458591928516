export interface IAnnouncementModalStrings {
  announcement: {
    title: string;
    confirmButton: string;
  };
}

interface IAnnouncementModalLanguages {
  pt: IAnnouncementModalStrings;
  es: IAnnouncementModalStrings;
  in: IAnnouncementModalStrings;
}

const pt: IAnnouncementModalStrings = {
  announcement: {
    title: 'Atenção!',
    confirmButton: 'Ok, entendido',
  },
};

const es: IAnnouncementModalStrings = {
  announcement: {
    title: '¡Atención!',
    confirmButton: 'Ok, entendido',
  },
};

const en: IAnnouncementModalStrings = {
  announcement: {
    title: 'Attention!',
    confirmButton: 'Ok, understood',
  },
};

export const Strings: IAnnouncementModalLanguages = {
  pt,
  es: es,
  in: en,
};
