/* eslint-disable complexity */
import * as React from 'react';
import { Container } from 'typedi/Container';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { Frame } from '@atomic/atm.frame';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { CrmContentContainer } from './components/crmContentContainer';
import { CrmDetailsHeader } from './components/crmDetailsHeader';
import { useGetAllCachedData } from './hooks/useGetAllCachedData';
import { useGetAttachedFiles } from './hooks/useGetAttachedFiles';
import { useGetManufacturingConsultation } from './hooks/useGetManufacturingConsultation';
import { useGetNotes } from './hooks/useGetNotes';
import { useGetOpportunityDetails } from './hooks/useGetOpportunityDetails';
import { useGetPlants } from './hooks/useGetPlants';

export const CrmPageDetails = props => {
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);
  const hasCache = window.localStorage.getItem('crmCache') === 'true' ? true : false;

  const crmId = props.match.params.cfId;

  const [enableRequest, setEnableRequest] = React.useState<boolean>(!hasCache);

  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const handleGetCachedSuccess = response => {
    setHasCachedData(response);
    window.localStorage.removeItem('crmCache');
  };

  const handleOpportunityDetailSuccess = () => {
    setEnableRequest(true);
  };

  const handleDataError = data => {
    if (data.message.includes('401')) {
      authStorageService.logout();
    } else {
      show('alert', data.message ? data.message : data.description);
    }
  };

  const { data: cachedData, isFetching: loadingCached } = useGetAllCachedData(
    { id: crmId, enable: hasCache },
    handleGetCachedSuccess,
    handleDataError,
  );

  const { data: opportunityDetailsData, isFetching: loadingOpportunityDetail } = useGetOpportunityDetails(
    { id: crmId, enable: enableRequest },
    handleOpportunityDetailSuccess,
    handleDataError,
  );

  const {
    data: manufacturingConsultationData,
    isFetching: loadingManufacturingConsultation,
  } = useGetManufacturingConsultation({ id: crmId, enable: enableRequest }, handleDataError);

  const { data: attachedFilesData, isFetching: loadingAttachedFiles } = useGetAttachedFiles(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: notesData, isFetching: loadingNotes } = useGetNotes(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: plantsData, isFetching: loadingPlants } = useGetPlants(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const [hasCachedData, setHasCachedData] = React.useState(cachedData);

  return (
    <>
      <Frame>
        <Grid fluid>
          <Row middle='xs'>
            <Col xs={12}>
              <CrmDetailsHeader
                loading={loadingManufacturingConsultation || loadingCached}
                manufacturingConsultationData={
                  hasCachedData?.MANUFACTURING_CONSULTATION
                    ? hasCachedData?.MANUFACTURING_CONSULTATION
                    : manufacturingConsultationData
                }
              />
            </Col>
          </Row>
        </Grid>
      </Frame>

      <CrmContentContainer
        opportunityDetailsData={
          hasCachedData?.OPPORTUNITY_DETAILS ? hasCachedData?.OPPORTUNITY_DETAILS : opportunityDetailsData
        }
        manufacturingConsultationData={
          hasCachedData?.MANUFACTURING_CONSULTATION
            ? hasCachedData?.MANUFACTURING_CONSULTATION
            : manufacturingConsultationData
        }
        attachedFilesData={hasCachedData?.ATTACHED_FILES ? hasCachedData.ATTACHED_FILES : attachedFilesData}
        notesData={hasCachedData?.NOTES ? hasCachedData?.NOTES : notesData}
        plantsData={hasCachedData?.PLANTS ? hasCachedData?.PLANTS : plantsData}
        loadingOpportunityDetail={loadingOpportunityDetail || loadingCached}
        loadingManufacturingConsultation={loadingManufacturingConsultation || loadingCached}
        loadingAttachedFiles={loadingAttachedFiles || loadingCached}
        loadingNotes={loadingNotes || loadingCached}
        loadingPlants={loadingPlants || loadingCached}
      />
      <Grid fluid mb={30}>
        <VSeparator />
      </Grid>
    </>
  );
};
