import React from 'react';
import { Body } from '@atomic/atm.typography';
import { IOrderPriorizationStrings, Strings } from '../../order-priorization.string';
import { PriorizationOrderSlotStyled } from './order-priorization-slot.style';

interface PriorizationOrderSlotComponentProps {
  slotNumbers: number;
}

export const PriorizationOrderSlotComponent: React.FC<PriorizationOrderSlotComponentProps> = ({ slotNumbers }) => {
  const strings: IOrderPriorizationStrings = Strings.pt;

  return (
    <PriorizationOrderSlotStyled>
      <Body>{strings.table.priorizationSlot(slotNumbers)}</Body>
    </PriorizationOrderSlotStyled>
  );
};
