import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setQuerySearch } from '@app/modules/components/customer-search/store/customer-search.store';
import { SearchButton } from '@app/modules/components/search-button/search-button.component';
import { TextField } from '@atomic/atm.text-field';
import { FlexRow } from '@atomic/obj.box';
import { FormField } from '@atomic/obj.form';
import { ICustomerSearchModalHeaderAdminStrings, Strings } from '../../../customer-search-modal-header-admin.string';
import { SearchFieldWrapper } from '../search-field.style';

export const SearchFieldBp: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ICustomerSearchModalHeaderAdminStrings = Strings[userInfo.language];

  const { filterOptionSearch, querySearch } = useSelector((state: RootState) => state.customerSearch);
  const [textSearch, setTextSearch] = useState(querySearch);
  const [lastMarket, setLastMarket] = useState(filterOptionSearch.market);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    setTextSearch(querySearch);
  }, [querySearch]);

  useEffect(() => {
    if (lastMarket !== filterOptionSearch.market) {
      setTextSearch('');
      setLastMarket(filterOptionSearch.market);
    }
  }, [filterOptionSearch.market]);

  const handleSearch = () => {
    dispatch(setQuerySearch(textSearch));
  };

  useEffect(() => {
    if (textSearch.length > 2 || querySearch.length > textSearch.length) {
      handleSearch();
    }

    if (!textSearch.length) {
      dispatch(setQuerySearch(''));
    }
  }, [textSearch]);

  const onValueChange = value => {
    setTextSearch(value.toString());
  };

  return (
    <SearchFieldWrapper>
      <FormField name='textSearch' value={textSearch}></FormField>
      <FlexRow fullWidth>
        <TextField
          data-testid='bp-search'
          value={textSearch}
          disabled={!filterOptionSearch}
          onValueChange={onValueChange}
          placeholder={`${strings.bpPlaceholder} (${filterOptionSearch?.market})`}
          hasButton
        >
          <SearchButton handleSearch={handleSearch} />
        </TextField>
      </FlexRow>
    </SearchFieldWrapper>
  );
};
