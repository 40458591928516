export interface IBrowserRecomendationString {
  title: string;
  textMessage: string;
  textRecomendation: string;
}

export interface IBrowserRecomendationLanguages {
  pt: IBrowserRecomendationString;
  es: IBrowserRecomendationString;
  in: IBrowserRecomendationString;
}

const pt: IBrowserRecomendationString = {
  title: 'Recomendação de navegador',
  textMessage:
    'Identificamos que você está utilizando o Microsoft EDGE como navegador, porém ele pode apresentar algumas incompatibilidades com o Gerdau Mais.',
  textRecomendation: 'Sugerimos a utilização do Google Chrome ou Firefox.',
};

const es: IBrowserRecomendationString = {
  title: 'Recomendación del navegador',
  textMessage:
    'Hemos identificado que está utilizando Microsoft EDGE como navegador, pero puede tener algunas incompatibilidades con Gerdau Mais.',
  textRecomendation: 'Sugerimos usar Google Chrome o Firefox.',
};

const en: IBrowserRecomendationString = {
  title: 'Browser recommendation',
  textMessage:
    'We have identified that you are using Microsoft EDGE as a browser, but it may have some incompatibilities with Gerdau Mais.',
  textRecomendation: 'We suggest using Google Chrome or Firefox.',
};

export const Strings: IBrowserRecomendationLanguages = {
  pt,
  es,
  in: en,
};
