import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { QuoteDataSource } from '@app/data/http';
import { GetAnalysisParams } from '@app/data/http/quote-params.dto';
import { FullAnalysisData } from '@app/models/quote.model';

export interface PartialTechnicalAnalysisValues {
  attributeLoading: boolean;
  attributeError: Error;
  getPartialAnalysis: (params: GetAnalysisParams) => void;
}

export function usePartialTechnicalAnalysis(
  setAnalysis: (analysis: FullAnalysisData) => void,
): PartialTechnicalAnalysisValues {
  const { performRequest: getPartialAnalysis, loading: attributeLoading, error: attributeError } = useLazyRequest<
    GetAnalysisParams,
    FullAnalysisData
  >(QuoteDataSource.getAnalysis, setAnalysis);

  return {
    attributeLoading,
    attributeError,
    getPartialAnalysis,
  };
}
