import * as React from 'react';
import { CookiesProvider } from 'react-cookie';
import { render } from 'react-dom';
import { Router } from 'react-router';
import { Container } from 'typedi';
import { HistoryToken } from '@app/core/route';
import { App } from '@app/modules/app';

export function bootstrapClient() {
  const history = Container.get(HistoryToken);

  render(
    <Router history={history}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Router>,
    document.getElementById('root'),
  );
}
