import * as React from 'react';
import { Tag, TagGroup } from '@atomic/atm.tag';
import { FormFieldContext } from '@atomic/obj.form';

interface TagCheckboxFieldProps {
  id: any;
  initialChecked?: boolean;
  disabled?: boolean;
  hide?: boolean;
  onClick?: (id?: any) => void;
  onValueChange?: (id: any, checked: boolean) => void;
}

export const TagCheckboxGroup = TagGroup;

export const TagCheckboxField: React.FC<TagCheckboxFieldProps> = props => {
  const [checked, setChecked] = React.useState(props.initialChecked);
  const formFieldConsumer = React.useContext(FormFieldContext);

  const getCurrentValue = () => {
    if (formFieldConsumer && formFieldConsumer.value) {
      return Array.isArray(formFieldConsumer.value) && formFieldConsumer.value.indexOf(props.id) > -1;
    }
    return checked;
  };

  const handlePress = () => {
    if (props.onClick) {
      props.onClick(props.id);
    }

    const ck = !getCurrentValue();

    if (props.onValueChange) {
      props.onValueChange(props.id, ck);
    }

    if (formFieldConsumer) {
      formFieldConsumer.onValueChange([props.id], ck);
    }

    setChecked(ck);
  };

  return (
    <Tag showCheck selected={getCurrentValue()} onClick={handlePress} disabled={props.disabled} hide={props.hide}>
      {props.children}
    </Tag>
  );
};
