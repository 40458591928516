import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { EmailFieldWrapper } from './email.form.style';

export const EmailForm: React.FC = () => {
  const { selectedEmails } = useSelector((state: RootState) => state.schedule);

  return (
    <FlexColumn fullWidth>
      <FlexRow vAlign='center' hAlign='space-between' pb={Spacing.XXSmall}>
        <FlexRow>
          <Body pr={Spacing.Small}>{strings.sendTo}</Body>
          <FaIcon.Envelope color={Color.Primary} />
        </FlexRow>
      </FlexRow>
      <EmailFieldWrapper>
        <TextField disabled readOnly value={selectedEmails[0]?.email} type='email' />
      </EmailFieldWrapper>
    </FlexColumn>
  );
};
