import { TableColumnType, TableColumns } from '@app/models';
import { IOrderUpdateTableRowData } from './order-update.interfaces';

export const SLICE_NAME = 'order-update';
export const TIME_6_DAYS = 6 * 24 * 60 * 60 * 1000;
export const TIME_1_DAYS = 1 * 24 * 60 * 60 * 1000;

export const orderUpdateTableRowData: IOrderUpdateTableRowData = {
  [TableColumns.ov]: { type: TableColumnType.StaticText },
  [TableColumns.ocClient]: { type: TableColumnType.StaticText },
  [TableColumns.codMaterialClient]: { type: TableColumnType.StaticText },
  [TableColumns.qtdConf]: { type: TableColumnType.StaticText },
  [TableColumns.qtdSolClient]: { type: TableColumnType.StaticText },
  [TableColumns.dateMaxDeliveryClient]: { type: TableColumnType.StaticDate },
  [TableColumns.dateSolClient]: { type: TableColumnType.StaticDate },
  [TableColumns.newQtdSolClient]: { type: TableColumnType.Number },
  [TableColumns.newDateSolClient]: { type: TableColumnType.Date },
  [TableColumns.cancelOrder]: { type: TableColumnType.Checkbox },
  [TableColumns.messageClient]: { type: TableColumnType.Text },
  [TableColumns.delete]: { type: TableColumnType.Delete },
};
