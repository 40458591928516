import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FormField } from '@atomic/obj.form';
import { OrderInputPhaseEnum, SearchByMaterial } from '../../../../order-input.constants';
import { setMaterialOptions } from '../../../../store/order-input.store';
import { CaptionSearchMaterial } from './partials/caption-search-material/caption-search-material';
import { CaptionSearchPartNumber } from './partials/caption-search-part-number/caption-search-part-number';
import { DownloadCatalog } from './partials/download-catalog/download-catalog';
import { SearchByFieldOption } from './partials/search-by-field-option/search-by-field-option';

const strings = OrderInputStrings.pt.selectItens;

export const SearchByField: React.FC = () => {
  const { searchBy, phase } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMaterialOptions([]));
  }, [searchBy]);

  const radioOption2 =
    phase === OrderInputPhaseEnum.UploadSpreadsheet || phase === OrderInputPhaseEnum.UploadPdfEmail
      ? strings.stepTwo.codeMaterialGerdauCatalog
      : strings.stepTwo.materialDescriptionText;

  return (
    <FlexRow mb mt vAlign='baseline' hAlign='space-between'>
      <FormField name='searchOptionsForm'>
        <FlexColumn>
          <SearchByFieldOption value={SearchByMaterial.CUSTOMER_MATERIAL_CODE} text={strings.stepTwo.codeProductText} />
          <CaptionSearchPartNumber />
        </FlexColumn>
        <FlexColumn ml>
          <SearchByFieldOption value={SearchByMaterial.SALES_DOCUMENT_ITEM_TEXT} text={radioOption2} />
          <CaptionSearchMaterial />
        </FlexColumn>
      </FormField>
      <DownloadCatalog />
    </FlexRow>
  );
};
