import Cookies from 'universal-cookie';
import { ForgotPasswordParameters } from '@app/data/http/login.datasource';
import { IAuthState } from '../../auth.interfaces';
import { forgotPassword } from './forgot-password.thunk';
import { getAzureTokenThunk } from './get-azure-token.thunk';

export const getTokenAndForgotPasswordThunk = (param: ForgotPasswordParameters) => async (dispatch, getState) => {
  await dispatch(getAzureTokenThunk());
  const { azureDateTimeExpiration, azureToken }: IAuthState = getState().auth;

  if (azureToken) {
    await dispatch(forgotPassword({ ...param, authorizationToken: azureToken }));
    const cookies = new Cookies();
    cookies.set('@authorization-token', azureToken, { domain: '.egerdau.com.br' });
    cookies.set('@authorization-token-expiration', azureDateTimeExpiration, { domain: '.egerdau.com.br' });
  }

  return;
};
