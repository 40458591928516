import { CrmPaginateData } from '@app/data/http/crm.dto';
import { CrmStrings } from '@app/modules/quote/crm/crm.string';

export interface CrmFilterData {
  rcId?: string[];
  saleDocument?: string[];
  clientName?: string[];
  batch?: string[];
  rcDescription?: string[];
  search?: string[];
}

export interface CrmParams {
  filters: {
    action: string;
    paginate: CrmPaginateData;
    enable?: boolean;
    currentFilter?: CrmFilterData;
    startDate?: string;
    endDate?: string;
    lang?: string;
  };
}

export enum CrmFilters {
  ClientName = 'clientName',
  ClientSteel = 'clientSteel',
  ClientReview = 'clientReview',
  RevisionReview = 'revisionReview',
  ClassificationProduct = 'classificationProduct',
  Colaborator = 'colaborator',
  CfId = 'cfId',
  Diameter = 'diameter',
  RcId = 'rcId',
  SaleDocument = 'saleDocument',
  Batch = 'batch',
  ClientNameComplain = 'clientName',
  RcDescription = 'rcDescription',
  Lang = 'lang',
}

export const filterFields = (language: string) => {
  return {
    mainFields: [
      {
        label: CrmStrings[language].filterFields.clientName,
        key: CrmFilters.ClientName,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.clientSteel,
        key: CrmFilters.ClientSteel,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.clientReview,
        key: CrmFilters.ClientReview,
        type: 'text',
      },
    ],
    extraFields: [
      {
        label: CrmStrings[language].filterFields.revisionReview,
        key: CrmFilters.RevisionReview,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.classificationProduct,
        key: CrmFilters.ClassificationProduct,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.colaborator,
        key: CrmFilters.Colaborator,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.cfId,
        key: CrmFilters.CfId,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.diameter,
        key: CrmFilters.Diameter,
        type: 'text',
      },
    ],
    mainFieldsComplain: [
      {
        label: CrmStrings[language].filterFields.rcId,
        key: CrmFilters.RcId,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.saleDocument,
        key: CrmFilters.SaleDocument,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.batch,
        key: CrmFilters.Batch,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.clientName,
        key: CrmFilters.ClientNameComplain,
        type: 'text',
      },
      {
        label: CrmStrings[language].filterFields.rcDescription,
        key: CrmFilters.RcDescription,
        type: 'text',
      },
    ],
  };
};

export interface AttachedFilesData {
  partnerRole: string;
  fileName: string;
  typeFile: string;
  createdAt: string;
  createdBy: string;
  link: string;
}

export interface NotesData {
  createdBy: string;
  date: string;
  hour: string;
  text: string;
  title: string;
}

export interface PlantsData {
  type: string;
  clientId: string;
  plant: string;
  name: string;
  isMainPartner: string;
  email: string;
}

export interface ItemsData {
  id: string;
  idHigher: string;
  itemHigher: string;
  quantity: string;
  unit: string;
  productName: string;
  categoryName: string;
  productNetValue: string;
  coin: string;
  idCostCenter: number;
}

export interface WorkflowData {
  status: string;
  responsibleCurrentPending: string;
  responsibleLastApproval: string;
  situation: string;
  identifyTask: string;
  createdAt: string;
}

export interface HistoryData {
  action: string;
  level: string;
  newValue: string;
  oldValue: string;
  updateBy: string;
  updatedAt: string;
}

export interface NotesDataComplain {
  type: string;
  text: string;
  language: string;
}

export interface PeopleInvolvedData {
  function: string;
  id: number;
  name: string;
}

export interface OrganizationalDataData {
  salesOrganizationalUnit: string;
  salesOrganization: string;
  distributionChannel: string;
  salesArea: string;
  salesOffice: string;
  salesTeam: string;
}

export interface ExpeditionData {
  shipToPart: string;
  shipToPartAddress: string;
  incoTerms1: string;
  incoTerms2: string;
  shippingConditions: string;
  shippingMode: string;
  shippingPriority: string;
  shippingStatus: string;
  supplyLockStatus: string;
  supplyComplete: string;
  groupOrders: string;
  maximumRemittances: number;
}

export interface DatesData {
  type: string;
  date: string;
}
export interface AttachedFilesDataProps {
  key: string;
  infos?: [{ label: string; value: [AttachedFilesData] }];
}
export interface NotesDataProps {
  key: string;
  infos?: [{ label: string; value: [NotesData] }];
}

export interface PlantsDataProps {
  key: string;
  infos?: [{ label: string; value: string }];
}

export interface ItemsDataProps {
  key: string;
  infos?: [{ label: string; value: ItemsData }];
}

export interface WorkflowDataProps {
  key: string;
  infos?: [{ label: string; value: WorkflowData }];
}

export interface HistoryDataProps {
  key: string;
  infos?: [{ label: string; value: HistoryData }];
}

export interface TotalPricesDataProps {
  key: string;
  infos?: [{ label: string; value: string }];
}

export interface NotesDataPropsComplain {
  key: string;
  infos?: [{ label: string; value: NotesDataComplain }];
}

export interface PeopleInvolvedDataProps {
  key: string;
  infos?: [{ label: string; value: PeopleInvolvedData }];
}

export interface OrganizationalDataProps {
  key: string;
  infos?: [{ label: string; value: OrganizationalDataData }];
}

export interface ExpeditionDataProps {
  key: string;
  infos?: [{ label: string; value: ExpeditionData }];
}

export interface DatesDataProps {
  key: string;
  infos?: [{ label: string; value: DatesData }];
}
