const onChangeRegex = (regex: RegExp) => (text: string, oldValue: string) => {
  const value = text.toString().toUpperCase();

  if (regex.test(value)) {
    return value;
  }

  return oldValue;
};

export const validColumnField = onChangeRegex(/^[A-Z]{0,1}$/);
export const validRowField = onChangeRegex(/^[1-9]{0,5}$/);

export const hasValues = (obj: any): boolean => {
  const itemIdentifierField = 'itemIdentifierColumn';
  let isValid = true;

  Object.entries(obj).forEach(entry => {
    if (entry[0] !== itemIdentifierField && !entry[1]) {
      isValid = false;
    }
  });

  return isValid;
};

export const onlyUnique = values =>
  values.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

const onlyUniqueFields = (fields): boolean => {
  const uniqueValues: string[] = onlyUnique(Object.values(fields));

  if (uniqueValues.length < 5) {
    return false;
  }
  return true;
};

export const validateAndConfirm = ({ strings, show, fields }) => {
  if (!hasValues(fields)) {
    show('alert', strings.validateRequiredFieldsMessage);
    return false;
  } else if (!onlyUniqueFields(fields)) {
    show('alert', strings.validateUniquefFieldsMessage);
    return false;
  }

  return true;
};
