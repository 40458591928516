import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppConfig } from '@app/configure-app';
import { LogAnalytics } from '@app/core/analytics';
import { LoginDataParam } from '@app/data/http/auth.params.dto';
import { LoginDataSource } from '@app/data/http/login.datasource';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { AppWindow } from '@app/utils/interfaces';
import { authErrorMessageMap } from '../../auth-error.mapper';
import { AuthEvents } from '../../auth.analytics';
import { SLICE_NAME } from '../../auth.constants';

const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;

export const loginData = createAsyncThunk(`${SLICE_NAME}/loginData`, async (login: LoginDataParam, thunkAPI) => {
  return LoginDataSource.login(login)
    .then(resp => {
      resp.ssoAccess = login.ssoAcces;
      return resp;
    })
    .catch(err => {
      LogAnalytics.error({
        tipo: AuthEvents.LoginError,
        message: authErrorMessageMap[parseInt(err.response.status)],
        errorMessage: err.response.message,
        errorDetails: JSON.stringify(err.response.data),
      });
      thunkAPI.dispatch(
        setToast({
          show: true,
          text: authErrorMessageMap[parseInt(err.response.status)],
          variant: ToastVariant.DANGER,
        }),
      );
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const addLoginReducers = builder => {
  builder.addCase(loginData.pending, state => {
    state.loading = true;
  });
  builder.addCase(loginData.fulfilled, (state, action) => {
    state.token = action.payload.token;
    state.tokenType = action.payload.tokenType;
    state.tokenExpiresIn = action.payload.tokenExpiresIn;
    state.refreshToken = action.payload.refreshToken;
    state.applications = action.payload.applications;
    state.ssoAccess = action.payload.ssoAccess;
    state.loading = false;
    const envVersion = config.version;
    localStorage.setItem('version', envVersion);
    state.isActive = true;
  });
  builder.addCase(loginData.rejected, state => {
    state.loading = false;
  });
};
