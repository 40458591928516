import styled from 'styled-components';

interface OrderStaturBarStyledProps {
  width?: boolean;
  background?: string;
  firstBar?: boolean;
  lastBar?: boolean;
}

export const OderStatusContainerWrapperStyledProps = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderStatusWrappedStyledProps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 88%;
  height: 26px;
  position: absolute;
  z-index: 9;
`;

export const OrderStatusDefaultWrappedStyledProps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  background: ${(props: OrderStaturBarStyledProps) => (props.background ? props.background : '')};
  position: absolute;
  border-radius: 4px;
  z-index: 1;
`;

export const OrderStatusBarStyledProps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: ${(props: OrderStaturBarStyledProps) => (props.width ? props.width : '0px')};
  background: ${(props: OrderStaturBarStyledProps) => (props.background ? props.background : '')};
  border-top-left-radius: ${(props: OrderStaturBarStyledProps) => (props.firstBar ? '4px' : '0px')};
  border-bottom-left-radius: ${(props: OrderStaturBarStyledProps) => (props.firstBar ? '4px' : '0px')};
  border-top-right-radius: ${(props: OrderStaturBarStyledProps) => (props.lastBar ? '4px' : '0px')};
  border-bottom-right-radius: ${(props: OrderStaturBarStyledProps) => (props.lastBar ? '4px' : '0px')};
`;
