import { HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterComplainSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Manual de manifestações',
      key: HelpCenterSectionKeys.Complain,
      internalLink: '/assets/data/help-documents/Manual de abertura e tratamento de Manifestações GSB.pdf',
      subsections: [],
      target: '_blank',
    }
  );
};
