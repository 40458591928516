import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { FeedbackStrings as strings } from '../../feedback.string';

interface FeedbackCommentProps {
  comment: string;
  setComment: (value) => void;
  selectedOption?: boolean | number;
  loading?: boolean;
}

export const FeedbackComment: React.FC<FeedbackCommentProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const letterCountColor = props.comment.length > 100 ? Color.Alert : null;

  return (
    <>
      <Form.Field
        name='comment'
        validators={[Validators.MaxLength(100, 'Comentário deve ter no máximo 100 caracteres')]}
        onValueChange={props.setComment}
      >
        <TextAreaField placeholder={strings[userInfo?.language]?.inputPlaceholder} />
      </Form.Field>
      <VSeparator small />

      <Hbox>
        <Hbox.Item hAlign='flex-end'>
          <Body color={letterCountColor}>{props.comment.length}/100</Body>
        </Hbox.Item>
      </Hbox>
      <VSeparator />

      <Form.Field name='email'>
        <TextField placeholder={strings[userInfo?.language]?.emailPlaceholder} />
      </Form.Field>
      <VSeparator />

      <Row end='xs'>
        <Col>
          <Button loading={props.loading} type='submit' kind='primary' disabled={!props.selectedOption}>
            {strings[userInfo?.language]?.submitButton}
          </Button>
        </Col>
      </Row>
    </>
  );
};
