import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { IScheduleLayout } from '@app/models/schedule.model';
import { checkColumnLayout, uncheckColumnLayout } from '@app/modules/components/schedule-modal/store/schedule.store';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FlexColumn } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { CheckboxFieldWrapper, ColumnFieldsWrapper } from '../../../../schedule-modal-columns.style';
import { divideColumns, filterSelectedColumns } from '../../../../utils';

export const SelectColumns: React.FC = () => {
  const { layout } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch<AppThunkDispatch>();
  const handleCheck = (value: string, checked: boolean) => {
    dispatch(checked ? checkColumnLayout(value) : uncheckColumnLayout(value));
  };
  const dividedColuns = divideColumns<IScheduleLayout>(layout.filter(filterSelectedColumns), 'label');

  return (
    <ColumnFieldsWrapper>
      {dividedColuns.map((item, index) => (
        <FlexColumn key={index}>
          {item.map(col => {
            return (
              <CheckboxFieldWrapper key={col.value}>
                <CheckboxField id={col.value} checked={col.checked} onValueChange={handleCheck}>
                  {col.label}
                </CheckboxField>
                <VSeparator small />
              </CheckboxFieldWrapper>
            );
          })}
        </FlexColumn>
      ))}
    </ColumnFieldsWrapper>
  );
};
