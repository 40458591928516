import React, { useEffect, useState } from 'react';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Grid, Row } from '@atomic/obj.grid';
import {
  ModalBoxStyled,
  ModalCloseStyled,
  ModalOpacityStyled,
  ModalStyled,
} from '@atomic/obj.modal/modal.component.style';
import { getStringsLanguage, isEdgeBrowser } from './utils';

const BROWSER_RECOMENDATION_MODAL_VIEW = 'broser_recomendation_modal_confirmed';
export const BrowserRecomendationModal: React.FC = () => {
  const [isConfirmed, setIsConfirmed] = useLocalStorageState(BROWSER_RECOMENDATION_MODAL_VIEW, false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!isConfirmed && isEdgeBrowser()) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [isConfirmed]);

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleConfirm = () => {
    setIsConfirmed(true);
    handleClose();
  };

  const strings = getStringsLanguage();

  return (
    <ModalStyled opened={openModal}>
      <ModalOpacityStyled opened={openModal} onClick={handleClose} />
      {openModal && (
        <ModalBoxStyled style={{ top: '20%' }} medium>
          <ModalCloseStyled>
            <FaIcon.Close onClick={handleClose} />
          </ModalCloseStyled>
          <Grid fluid>
            <Row>
              <H2>{strings.title}</H2>
            </Row>
            <Row>
              <Body>
                {strings.textMessage}
                <br /> <br /> {strings.textRecomendation}
              </Body>
            </Row>
            <Row mt mb>
              <Hbox style={{ flex: 1 }} hAlign='center'>
                <Hbox>
                  <Button testid={`${TEST_ID_CLOSE_MODAL}-browser-recomendation`} onClick={handleConfirm} expanded>
                    Entendi
                  </Button>
                </Hbox>
              </Hbox>
            </Row>
          </Grid>
        </ModalBoxStyled>
      )}
    </ModalStyled>
  );
};
