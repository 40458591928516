import { Credit } from '@app/models';

export interface CreditResponse extends Credit {
  credit: Credit[];
}

export const creditResponseToCreditList = (response: CreditResponse): Credit[] => {
  const creditListResponse: Credit[] = response.credit.filter(item => Object.keys(item).length !== 0);
  const creditList: Credit[] = creditListResponse.map(mapperCreditToCreditList);
  creditList.sort((a, b) => (a.percentUsedCredt > b.percentUsedCredt ? -1 : 1));

  return creditList.map(mapperCreditToCreditList);
};

const mapperCreditToCreditList = (credit: Credit, index: number) => {
  const creditFinal = credit;
  // tslint:disable-next-line:max-line-length
  creditFinal.percentUsedCredt =
    parseFloat(creditFinal.percentOtherObligations) + parseFloat(creditFinal.percentOpenInvoice);
  if (index === 0) {
    creditFinal.branch = false;
  } else {
    creditFinal.branch = true;
  }

  return creditFinal;
};
