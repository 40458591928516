import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { TechnicalAnalysisComponentStrings } from './technical-analysis-component.strings';

interface AnalysisAssetsAdviceModalProps {
  opened: boolean;
  onModalClose: () => void;
}

export const AnalysisAssetsAdviceModal: React.FC<AnalysisAssetsAdviceModalProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const strings = TechnicalAnalysisComponentStrings[userInfo.language].analysisAssetsAdviceModal;

  return (
    <Modal small opened={props.opened} onClose={() => props.onModalClose()}>
      <Grid fluid>
        <H2>{strings.title}</H2>

        <Body>{strings.label}</Body>
        <VSeparator />

        <VSeparator />
        <Hbox>
          <Hbox.Item hAlign='flex-end'>
            <Button type='button' onClick={() => props.onModalClose()}>
              {strings.btnClose}
            </Button>
          </Hbox.Item>
        </Hbox>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
