import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { RootState } from '@app/core/redux/store';
import { TabIndex } from '@app/models/order.model';
import { setOpenScheduleModal } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { SchedulePhase } from '../../../schedule.constants';
import { strings } from '../../../schedule.strings';
import { scheduleByTabSelector } from '../../../store/schedule.selectores';
import { setEditMode, setSchedulePhase, setSelectedEmails } from '../../../store/schedule.store';

export const ScheduleModalListFooter: React.FC = () => {
  const dispatch = useDispatch();
  const schedules = useSelector(scheduleByTabSelector);
  const { loading: loadingCustomers } = useSelector((state: RootState) => state.customerSearch);
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const disabledNewButton = schedules.length >= 5 || loadingCustomers;

  return (
    <FlexRow mt={Spacing.Small} mb hAlign='flex-end'>
      <Button
        testid={`${TEST_ID_CLOSE_MODAL}-schedule`}
        kind='secondary'
        onClick={() => dispatch(setOpenScheduleModal(false))}
      >
        {orderTabIndex === TabIndex.Stock ? strings.cancelButtonStock : strings.cancelButtonCart}
      </Button>
      <Button
        disabled={disabledNewButton}
        onClick={() => {
          dispatch(setEditMode(false));
          dispatch(setSelectedEmails([{ email: userInfo?.email, valid: true }]));
          dispatch(setSchedulePhase(SchedulePhase.Form));
        }}
      >
        <FaIcon.Plus icon='paper-plane' />
        <FlexRow>{strings.newButton}</FlexRow>
      </Button>
    </FlexRow>
  );
};
