import React from 'react';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';

interface PropsForm {
  setConfirmed?: (val: boolean) => void;
  setShowModalConfirmation: (val: boolean) => void;
  showModalConfirmation: boolean;
  messageAlert: string;
}

export const DocumentConfirmedModal = (props: PropsForm) => {
  const handleConfirmClose = () => {
    props.setShowModalConfirmation(false);
  };

  const handleConfirm = () => {
    props.setConfirmed(true);
    props.setShowModalConfirmation(false);
  };

  return (
    <Modal opened={props.showModalConfirmation} customHeight='20%' small shadow>
      <Grid fluid>
        <H2 color={Color.Alert}>Atenção!</H2>
        <VSeparator />

        <Hbox hAlign='flex-start'>
          <Body color={Color.Alert}>{props.messageAlert}</Body>
        </Hbox>
        <VSeparator />
        <VSeparator />

        <Hbox hAlign='flex-end'>
          <Hbox.Separator />

          <Hbox.Item noGrow>
            <Button kind='neutral' onClick={handleConfirmClose}>
              Cancelar
            </Button>
          </Hbox.Item>
          <Hbox.Separator />

          <Hbox.Item noGrow>
            <Button onClick={handleConfirm}>Sim</Button>
          </Hbox.Item>
        </Hbox>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
