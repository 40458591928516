import * as React from 'react';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { EnhancedError } from '@app/core/hook/request.model';

export function useGetDashboard<Response>(request: (params?: string) => Promise<Response>, onSuccess: () => void) {
  const [data, setData] = React.useState<Response>(null);

  const handleSuccess = (response: Response) => {
    setData(response);
    onSuccess();
  };

  const { performRequest: getDashboard, loading, error } = useLazyRequest<string, Response>(request, handleSuccess);

  const performRequest = React.useCallback(
    (clientNumber: string) => {
      setData(null);
      getDashboard(clientNumber);
    },
    [getDashboard],
  );

  return { data, loading, error, performRequest };
}

export function useGetGraph<Response, T>(
  datasourceRequests: ((params?: string) => Promise<Response>)[],
  mapFn: (...responses: any) => T[],
) {
  const [data, setData] = React.useState<T[]>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<EnhancedError>(null);

  const performRequest = (params?: string) => {
    const promises = datasourceRequests.map(configRequest => configRequest(params));
    setLoading(true);

    Promise.all(promises)
      .then(responses => {
        const mappedValues = mapFn(...responses);
        setLoading(false);
        setData(mappedValues);
      })
      .catch(err => {
        const enhancedError = { ...err, code: err.message.replace(/\D/g, '') };
        setError(enhancedError);
      });
  };

  return { data, loading, error, performRequest };
}
