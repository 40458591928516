import styled from 'styled-components';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Color, Spacing } from '@atomic/obj.constants';

export const FictionalExemple = styled(Body)`
  width: 100%;
  margin-bottom: ${Spacing.Large};
`;

export const BorderedTextField = styled(TextField)`
  border: ${props => (props.invalid ? `2px solid ${Color.Alert}` : `2px solid ${Color.MediumXGray}`)};
`;

export const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: ${Spacing.Large} 0;

  & > div {
    margin: 0 10px;
    max-width: ${Spacing.XXXLarge};
    align-items: center;
    input {
      background-color: ${Color.White};
    }
    label {
      text-align: center;
    }
  }
`;

export const ColumnsBorder = styled.div`
  display: flex;
  height: ${Spacing.Small};
  width: 560px;
  border: 1px solid ${Color.Black};
  border-bottom: none;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${Spacing.XLarge};

  input {
    max-width: ${Spacing.XXXLarge};
    margin-left: ${Spacing.Small};
    background-color: ${Color.White};
  }
`;
