import * as React from 'react';
import { NotificationConfigurationItem } from '@app/models/notification-center.model';
import { CheckboxField } from '@atomic/atm.checkbox';
import { CheckboxStyle } from '@atomic/atm.checkbox/checkbox-field.component.style';
import { Body, H3, InputLabel } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { NewsTag } from '../news-tag/news-tag';

interface NotificationConfigurationItemProps {
  notification: NotificationConfigurationItem;
  onChange: (key: string, checked: boolean) => void;
}

export const NotificationConfigurationItemCell: React.FC<NotificationConfigurationItemProps> = ({
  onChange,
  notification,
}) => {
  return (
    <>
      <FlexRow vAlign='center'>
        <FlexColumn>
          <H3>{notification.title}</H3>
          {notification.description && (
            <>
              <VSeparator small />
              <Body>{notification.description}</Body>
            </>
          )}
        </FlexColumn>
        {!notification.subItems && (
          <>
            <FlexRow.Separator />
            <FlexColumn noGrow>
              <CheckboxField
                id={notification.key}
                checked={notification.active}
                onValueChange={(id, checked) => onChange(id, checked)}
              />
            </FlexColumn>
          </>
        )}
      </FlexRow>

      <VSeparator />
      {notification.subItems?.map(subItem => (
        <div key={subItem.key}>
          <FlexRow vAlign='center'>
            <FlexColumn>
              <InputLabel>{subItem.title}</InputLabel>
            </FlexColumn>
            <FlexColumn noGrow>
              <CheckboxField
                id={subItem.key}
                checked={subItem.active}
                onValueChange={(id, checked) => onChange(id, checked)}
              />
            </FlexColumn>
          </FlexRow>
          <VSeparator small />
        </div>
      ))}
      {notification.subActions?.map(subAction => (
        <FlexColumn key={subAction.key} pl mb>
          <FlexRow vAlign='center'>
            <FlexColumn noGrow>
              <CheckboxField
                id={subAction.key}
                checked={subAction.active}
                onValueChange={(id, checked) => onChange(id, checked)}
                checkboxStyle={CheckboxStyle.Secondary}
              />
            </FlexColumn>
            <FlexRow vAlign='center'>
              <InputLabel>{subAction.title}</InputLabel>
              <FlexRow ml={Spacing.Small}>
                <NewsTag isNotAbsolute />
              </FlexRow>
            </FlexRow>
          </FlexRow>
          {subAction.description && (
            <FlexRow mt={Spacing.Small} pr={Spacing.XXLarge}>
              <VSeparator small />
              <Body>{subAction.description}</Body>
            </FlexRow>
          )}
          <VSeparator small />
        </FlexColumn>
      ))}
    </>
  );
};
