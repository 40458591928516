import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { isGKNSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { SummaryCardInfo } from '@app/modules/shipping-request/partials/shipping-request-footer/partials/shipping-request-resume/partials/summary-card-info.component';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import {
  plantsDistinctSelector,
  stockQunatityTotalRowsSelector,
  totalRequestedShippingQuantitySelector,
} from '@app/modules/shipping-request/store/shipping-request.selectors';
import { formatQtyField } from '@app/utils/string-utils';
import { Body, DT } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { ResumeFooterContainerWrapper, ResumeFooterSeparator } from './shipping-request-resume.style';

export const ShippingRequestResume: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const totalStockQuantity = useSelector(stockQunatityTotalRowsSelector);
  const plantsDistinct = useSelector(plantsDistinctSelector);
  const isGKN = useSelector(isGKNSelector);
  const qtyTotalWhished = useSelector((state: RootState) =>
    totalRequestedShippingQuantitySelector(state, plantsDistinct[0]),
  );

  return (
    <ResumeFooterContainerWrapper>
      {plantsDistinct.map(plant => (
        <SummaryCardInfo key={plant} plant={plant} />
      ))}
      <ResumeFooterSeparator />
      <FlexColumn>
        <Body bold='bold'>{strings.page.total}</Body>
        <DT>{`${isGKN ? formatQtyField(qtyTotalWhished) : formatQtyField(totalStockQuantity)}t`}</DT>
      </FlexColumn>
    </ResumeFooterContainerWrapper>
  );
};
