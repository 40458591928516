import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IShippingRequestRow } from '@app/modules/shipping-request/shipping-request.interfaces';
import { Strings } from '@app/modules/shipping-request/shipping-request.string';
import { selectedRowsByPlantSelector } from '@app/modules/shipping-request/store/shipping-request.selectors';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color, FontWeight, Spacing } from '@atomic/obj.constants';

interface ShippingRequestWarningProps {
  plant: string;
}

export const ShippingRequestWarning: React.FC<ShippingRequestWarningProps> = ({ plant }) => {
  const string = Strings.pt;
  const tableRows: IShippingRequestRow[] = useSelector((state: RootState) => selectedRowsByPlantSelector(state, plant));

  const breakingHeatRows = tableRows.filter(item => {
    const sameHeat = item.heat === item.lastHeat;
    const hasAlert = !sameHeat && item.hasQuantityLastHeat === 'Sim' && !item.removedAlert;
    return hasAlert;
  });

  if (breakingHeatRows.length) {
    const customerProductCodes = breakingHeatRows.map(item => item.customerProductCode);
    const uniqueValues = [...new Set(customerProductCodes)];
    const concatenedMaterials = uniqueValues.join(', ');
    const message = breakingHeatRows.length > 1 ? string.page.sequentialWarningPlural : string.page.sequentialWarning;

    return (
      <FlexRow>
        <FaIcon.Warning color={Color.Primary} />
        <Body ml={Spacing.Small} bold={FontWeight.Bold} color={Color.Black}>
          {`${message} ${concatenedMaterials}`}
        </Body>
      </FlexRow>
    );
  }

  return null;
};
