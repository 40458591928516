import styled from 'styled-components';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { TD } from '@atomic/mol.table';
import { Color, FontWeight } from '@atomic/obj.constants';

const CANCEL_BUTTON_WIDTH = '200px';
const REVIEW_MODAL_HEIGHT = '500px';

export const OrderUpdateTableColumnStyled = styled(TD)`
  ${props => props.editable && `background-color: ${props.selected ? Color.AlertLight : Color.White}`}
`;

export const ChangeOrderStyled = styled(TextField)`
  border: none;
  background-color: ${props => (props.selected ? Color.AlertLight : Color.White)};
  font-weight: ${FontWeight.Normal};
  text-align: center;
`;

export const CancelOrderButtonStyled = styled(Button)`
  border: none;
  background-color: ${props => (props.selected ? Color.Secondary : Color.AlertLight)};
  color: ${props => (props.selected ? Color.Primary : Color.Alert)};
  :hover {
    background-color: ${props => (props.selected ? Color.Secondary : Color.AlertLight)};
    filter: brightness(90%);
    color: ${props => (props.selected ? Color.Primary : Color.Alert)};
  }
  width: ${CANCEL_BUTTON_WIDTH};
`;

export const CancelOrderStyled = styled(Body)`
  background-color: ${Color.AlertLight};
  width: ${CANCEL_BUTTON_WIDTH};
  text-align: center;
  color: ${Color.Alert};
`;

export const ReviewDetailsWrapperStyled = styled.div`
  max-height: ${REVIEW_MODAL_HEIGHT};
  overflow: scroll;
`;
