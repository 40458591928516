import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { BodySecondary, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { Grid, Row } from '@atomic/obj.grid';
import { FeedbackButtonStyled, FeedbackCloseButtonStyled, FeedbackWrapper } from '../feedback.component.style';
import { FeedbackComponentProps, FeedbackPhase, FeedbackType } from '../feedback.interfaces';
import { FeedbackStrings as strings } from '../feedback.string';
import { FeedbackComment } from './partials/feedback-comment.component';
import { FeedbackSuccess } from './partials/feedback-success.component';

export const FeedbackBox: React.FC<FeedbackComponentProps> = ({
  onClose,
  feedbackPhase,
  message,
  comment,
  setComment,
  type,
  handleSubmit,
  answers,
  selected,
  setSelected,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { loadingPost } = useSelector((state: any) => state.csat);

  if (feedbackPhase === FeedbackPhase.Success) {
    return (
      <FeedbackWrapper>
        <FeedbackCloseButtonStyled>
          <FaIcon.Close onClick={() => onClose()} />
        </FeedbackCloseButtonStyled>
        <FeedbackSuccess />
      </FeedbackWrapper>
    );
  }

  return (
    <FeedbackWrapper>
      <FeedbackCloseButtonStyled>
        <FaIcon.Close onClick={() => onClose()} />
      </FeedbackCloseButtonStyled>
      <Grid fluid>
        <Hbox pb>
          <Hbox.Item hAlign='center'>
            <H2 style={{ marginLeft: '16px', marginRight: '16px', textAlign: 'center' }}>
              {message ? message : strings[userInfo?.language]?.title}
            </H2>
            <Form onSubmit={handleSubmit}>
              <Row center='xs'>
                {answers[type].map((answer, index) => (
                  <FeedbackButtonStyled key={index}>
                    <Button
                      kind='link'
                      onClick={() => {
                        setSelected(index + 1);
                      }}
                    >
                      {answer(!selected || selected === index + 1)}
                    </Button>
                  </FeedbackButtonStyled>
                ))}
              </Row>
              {type === FeedbackType.Numeric && (
                <Hbox mb hAlign='space-between'>
                  <BodySecondary>{strings[userInfo?.language]?.least}</BodySecondary>
                  <BodySecondary>{strings[userInfo?.language]?.most}</BodySecondary>
                </Hbox>
              )}
              {feedbackPhase === FeedbackPhase.Comment && (
                <FeedbackComment
                  loading={loadingPost}
                  comment={comment}
                  selectedOption={selected}
                  setComment={setComment}
                />
              )}
            </Form>
          </Hbox.Item>
        </Hbox>
      </Grid>
    </FeedbackWrapper>
  );
};
