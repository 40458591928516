import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';

export const BPsFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BPsFilterWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Large} 0px;
`;

export const BPsFilterStyled = styled.div`
  width: 20%;
`;

export const BPsSearchStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  width: 65%;
  margin-left: ${Spacing.XLarge};
  margin-right: ${Spacing.XLarge};
`;

export const BPsTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
