import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const GridColumnFilterStyled = styled.div`
  position: relative;
`;

export const QuoteAdvancedFilterWrapper = styled.div`
  padding: 0 ${Spacing.Small} ${Spacing.Medium} ${Spacing.Small};
  border: 1px solid;
  border-color: ${Color.GrayLight};
  background-color: ${Color.White};
  margin-bottom: ${Spacing.Medium};
  & > form > div {
    margin-left: 0;
    margin-right: 0;
    & > h2 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    & > div > div > div {
      white-space: wrap;
    }
  }
`;

export const HiddenFiltersWrapper = styled.div`
  width: 100%;
  display: ${props => (props.hide ? 'none' : 'block')};
  & > div > label {
    cursor: grab;
  }
`;

export const ButtonsWrapper = styled.div`
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  & > div {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const CheckboxFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: ${Spacing.Small};
  }
`;

export const CheckboxSubFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  & > div {
    padding-left: ${Spacing.Large};
  }
`;

export const WrapperFilterPlants = styled.div`
  display: flex;
  & svg {
    rotate: ${props => (props.selected ? '90deg' : '0deg')};
  }
  & > div > svg {
    display: none;
  }
  & div > label {
    padding-left: ${Spacing.Small};
  }
`;
