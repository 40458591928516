export enum TabIndex {
  Open = 0,
  Stock = 1,
  Billed = 2,
}

export enum InternationalTabIndex {
  Open = 0,
  Frontier = 1,
  Shipped = 2,
  Documents = 3,
}
export enum InternationalMaritimeTabIndex {
  Open = 0,
  Harbor = 1,
  Shipped = 2,
}
export interface OrderAction {
  icon: string;
  title: string;
  action?: () => void;
  disabled?: boolean;
  isNew?: boolean;
}
