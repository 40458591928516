/* eslint-disable prettier/prettier */
import { format, subDays } from 'date-fns';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { EnhancedError } from '@app/core/hook/request.model';
import { RootState } from '@app/core/redux/store';
import { MERoadOrderDataSource } from '@app/data/http/order-ME-road.datasource';
import { DocumentsOrderParams } from '@app/data/http/order-params.dto';
import { HanaLanguage } from '@app/models';
import { DocumentsProfileCard } from '@app/models/order-documents.model';

interface UseGetDocumentsOrder {
  data: DocumentsProfileCard[];
  handleDateChange: (value: number, start?: Date, end?: Date) => void;
  setDocumentsProfile: (documentsProfile: DocumentsProfileCard[]) => void;
  documentsResponse: DocumentsProfileCard[];
  loading: boolean;
  error: EnhancedError;
  retry: (params?: DocumentsOrderParams) => void;
}

const startDateAllHistory = new Date('01/01/2017');
const endDateAllHistory = new Date();

export function useGetDocumentsOrder(clientNumber: string): UseGetDocumentsOrder {
  const [documentsProfile, setDocumentsProfile] = React.useState<DocumentsProfileCard[]>([]);
  const { language } = useSelector((state: RootState) => state.user);
  const { performRequest, data: documentsResponse, loading, error } = useLazyRequest<
    DocumentsOrderParams,
    DocumentsProfileCard[]
  >(MERoadOrderDataSource.getDocumentsOrder, setDocumentsProfile);
  const [startDate, setStartDate] = React.useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = React.useState(new Date());

  useEffect(() => {
    if (clientNumber.length) {
      performRequest({
        customerIds: clientNumber,
        startApprovementDate: format(startDate, 'yyyyMMdd'),
        endApprovementDate: format(endDate, 'yyyyMMdd'),
        language: HanaLanguage[language],
      });
    }
  }, [clientNumber, startDate, endDate]);

  const handleDateChange = React.useCallback((value: number) => {
    if (value >= 0) {
      setStartDate(subDays(new Date(), value));
      setEndDate(new Date());
    } else {
      setStartDate(startDateAllHistory);
      setEndDate(endDateAllHistory);
    }
  }, []);

  return {
    data: documentsProfile,
    handleDateChange,
    setDocumentsProfile,
    documentsResponse,
    loading,
    error,
    retry: performRequest,
  };
}
