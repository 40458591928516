import { ColDef } from 'ag-grid-community';
import { addLeftCharacters, removeLeftCharacters } from '@app/utils/array-helper';
import { sizeInFormatter, valueQtyFormatter } from '@app/utils/order-column-formatter';

interface HandleStartDateChangeParams {
  date: Date;
  endDate: Date;
  setStartDate: (value: number) => void;
  setEndDate: (value: number) => void;
}

export const handleStartDateChange = ({ date, endDate, setStartDate, setEndDate }: HandleStartDateChangeParams) => {
  if (date) {
    setStartDate(date.getTime());
    if (date > endDate) {
      setEndDate(date.getTime());
    }
  }
};
interface HandleendDateChangeParams {
  date: Date;
  setEndDate: (value: number) => void;
}

export const handleEndDateChange = ({ date, setEndDate }: HandleendDateChangeParams) => {
  if (date) {
    setEndDate(date.getTime());
  }
};

export const handleBilledTabColumn = (columns: ColDef[], language: string) => {
  const valueColumnsFields = ['thickness, width'];

  if (columns) {
    columns.forEach(column => {
      if (valueColumnsFields.includes(column.field)) {
        column.valueFormatter =
          language === 'in' ? param => valueQtyFormatter(param, true) : param => valueQtyFormatter(param, false);
      }

      if (column.field === 'diameter') {
        column.valueFormatter = param => sizeInFormatter(param, language);
      }
    });
  }
};

export const handleItemOvToSalesDocumentItem = (itemOv: string) => {
  let formattedItemOv = itemOv;

  if (itemOv.length > 5) {
    formattedItemOv = removeLeftCharacters(itemOv, 5);
  } else if (itemOv.length < 5) {
    formattedItemOv = addLeftCharacters(itemOv, 5);
  }

  return formattedItemOv;
};

export const handleNumDeliveryItemToDeliveryDocumentItem = (numDeliveryItem: string) => {
  let formattedNumDeliveryItem = numDeliveryItem;

  if (numDeliveryItem.length > 6) {
    formattedNumDeliveryItem = removeLeftCharacters(numDeliveryItem, 6);
  } else if (numDeliveryItem.length < 5) {
    formattedNumDeliveryItem = addLeftCharacters(numDeliveryItem, 6);
  }

  return formattedNumDeliveryItem;
};
