import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Tab } from '@app/modules/components/tabs/tabs.component';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { UserScheduleType } from '../../../schedule.constants';
import { strings } from '../../../schedule.strings';
import { setCurrentTab } from '../../../store/schedule.store';
import { TabsHeaderScheduleModal } from '../schedule-modal-list.style';

export const ScheduleModalListHeader: React.FC = () => {
  const { currentTab } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();

  return (
    <>
      <TabsHeaderScheduleModal>
        <FlexRow>
          <Tab
            handleClick={() => dispatch(setCurrentTab(UserScheduleType.ORDER_OPEN))}
            currentIndex={currentTab}
            index={UserScheduleType.ORDER_OPEN}
          >
            {strings.orderOpenTab}
          </Tab>
          <Tab
            handleClick={() => dispatch(setCurrentTab(UserScheduleType.STOCK))}
            currentIndex={currentTab}
            index={UserScheduleType.STOCK}
          >
            {strings.stockStockTab}
          </Tab>
        </FlexRow>
      </TabsHeaderScheduleModal>
      <FlexRow mt>
        <Body>{strings.rulesDescription}</Body>
      </FlexRow>
    </>
  );
};
