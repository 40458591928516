export enum PpapTypes {
  no = 'NONE',
  firstLevel = 'LEVEL_1',
  secondLevel = 'LEVEL_2',
  thirdLevel = 'LEVEL_3',
  fourthLevel = 'LEVEL_4',
  fifthLevel = 'LEVEL_5',
}

export enum AnalysisApqpTypes {
  A = 'Sim, já está nos anexos',
  B = 'Sim, ainda não anexei',
  C = 'Não precisa de APQP',
}
