import styled from 'styled-components';
import { Border, FontFamily, Color, FontSize, FontWeight, Shadow, Spacing, ZIndex } from '@atomic/obj.constants';

interface FormResultWrapperStyledProps {
  initialized?: boolean;
  modified?: string;
}

export const FormWrapperStyled = styled.div`
  display: flex;
  flex: 1;
  width: 363px;
  box-shadow: ${Shadow.Small};
  overflow: hidden;
  flex-direction: column;
  background-color: white;
  transition: width 0.8s;
  & > div {
    padding-bottom: 5px;
    border-bottom: none;
  }
`;

export const FormResultLabelStyled = styled.div`
  & div {
    border-top: none;
    padding-top: 0px;
    font-style: italic;
  }
`;

interface ChatDrawerStyledProps {
  hasSubmitButton?: boolean;
  bg?: boolean;
  rotate?: boolean;
  percent?: number;
}

export const FormDrawerStyled = styled.div`
  padding: ${Spacing.Medium} 0px;
  overflow-y: scroll;
  height: ${(props: ChatDrawerStyledProps) => (props.hasSubmitButton ? 'calc(100vh - 227px)' : 'calc(100vh - 165px)')};
`;

export const ChatCreateButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: ${Spacing.Medium} ${Spacing.XLarge} ${Spacing.Medium} ${Spacing.XLarge};
  z-index: ${ZIndex.ChatCreateButtonWrapper};
  background-color: ${Color.White};
  border-top: ${Border.Width} solid ${Border.Color};
`;

export const FormResultAttributeWrapper = styled.div`
  padding: ${Spacing.Small} ${Spacing.Small} ${Spacing.Large} ${Spacing.Medium};
  ${(props: FormResultWrapperStyledProps) => props.modified && '& label { color: #000; font-weight: bold;}'}
  background-color: ${(props: FormResultWrapperStyledProps) =>
    props.modified === 'GERDAU' ? Color.GrayXLight : props.modified ? '#f0f4f7' : Color.White};

  & > div > p > span {
    font-size: ${FontSize.XSmall};
    font-weight: normal;
    color: ${Color.GrayDark};
  }
  & > div > div > button {
    padding: 0;
  }
`;

export const FormResultOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > label {
    width: 100%;
  }
  & > div > button {
    font-size: ${FontSize.XSmall};
    min-height: ${FontSize.XLarge};
    padding: 0 3px;
  }
`;

export const FormResultTable = styled.div`
  padding: 0px;
`;

export const AttributeDetailWrapper = styled.div`
  padding-top: 8px;
  & > p {
    color: ${Color.Accessory};
    font-weight: 600;
  }
`;

export const FormPageWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const FormWrapper = styled.div`
  width: 650px;
  margin: 0 auto;
  padding: ${Spacing.Medium};
`;

export const FormFieldWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Color.Gray};
  padding-bottom: ${Spacing.Large};
`;

export const FormOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const FormTagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Small};
`;

export const FormScrollWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 0 ${Spacing.XLarge} 0 ${Spacing.XLarge};
  margin-top: 60px;
`;

export const DetailsWrapper = styled.details`
  width: 100%;
  background-color: ${(props: ChatDrawerStyledProps) => (!props.bg ? 'transparent' : Color.White)};
  box-shadow: ${Shadow.Small};
  padding: ${Spacing.XLarge};
  margin-bottom: ${Spacing.Large};
  &[open] > summary > div > div > svg {
    transform: rotate(180deg);
  }
`;

export const SummaryWrapper = styled.summary`
  width: 100%;

  &::marker {
    content: '';
  }
  & > h3 {
    color: ${Color.Success};
    font-weight: 600;
  }
  & > div > div > h2 {
    ${(props: ChatDrawerStyledProps) => !props.bg && `color: ${Color.Gray}`};
    display: flex;
    flex-direction: row;
    font-weight: 700;
    margin: 0 0 0 0;
    & > span {
      font-weight: 400;
    }
  }
  & > div > div > span {
    margin-left: ${Spacing.XLarge};
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  & > svg {
    transition: transform 0.3s ease;
    ${(props: ChatDrawerStyledProps) => props.rotate && `transform: rotate(${props.rotate}deg)`};
  }
`;

export const QuestionWrapper = styled.div`
  margin: ${Spacing.XLarge} 0 ${Spacing.Small} 0;
`;

export const FieldWrapper = styled.div`
  margin-top: ${Spacing.Small};
  & > .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const MainQuestion = styled.p`
  color: ${Color.GrayDark};
  word-wrap: break-word;
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  line-height: 30px;
  margin: 0;
`;

export const SecondaryQuestion = styled.p`
  color: ${Color.GrayDark};
  word-wrap: break-word;
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  line-height: 30px;
  margin: 0;
  margin-bottom: ${Spacing.XSmall};
`;

export const CheckBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const StepsHeaderWrapper = styled.div`
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  background: ${Color.White};
  position: fixed;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Small} ${Spacing.Large};
  z-index: 9;
`;

export const StepHeader = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-width: 5px;
  border-top-style: solid;
  margin: 0 5px;
  cursor: pointer;
  padding: ${Spacing.XSmall} ${Spacing.Small};
  ${(props: ChatDrawerStyledProps) =>
    props.percent < 0
      ? `color: ${Color.Gray}; border-top-color: ${Color.Gray};`
      : props.percent === 100
      ? `color: ${Color.Success}; border-top-color: ${Color.Success};`
      : `color: ${Color.Warning}; border-top-color: ${Color.Warning};`}
   
  & > p {
    ${(props: ChatDrawerStyledProps) =>
      props.percent < 0
        ? `color: ${Color.Gray}`
        : props.percent === 100
        ? `color: ${Color.Success}`
        : `color: ${Color.Warning}`}
`;

export const IconStep = styled.div`
  margin-right: ${Spacing.Small};
  & > svg {
    width: 12px !important;
  }
`;

export const FormResultWrapper = styled.div`
  z-index: 10;
`;

export const CustomFieldWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${Spacing.Small};
  align-items: center;
`;
