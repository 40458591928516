import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { extraReducersConfig, reducers } from '../../../utils';
import { initialState } from './order-tab-stock.initial';
import { IOrderTabStockState } from './order-tab-stock.interface';
import { performRequest } from './order-tab-stock.thunks';

export const order = createSlice<IOrderTabStockState, SliceCaseReducers<IOrderTabStockState>>({
  name: 'order-tab-stock',
  initialState,
  reducers,
  extraReducers: extraReducersConfig(performRequest),
});

export const { setFilter, setFilteredRows, setSelectedRows, setShowActionBar } = order.actions;

export const orderTabStockReducer = order.reducer;
