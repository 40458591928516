import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminSettingsDataSource, CSATUserModule } from '@app/data/http/admin-settings.datasource';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from './csat.constants';

export const getCsatUser = createAsyncThunk(`${SLICE_NAME}/getCsatUser`, async () => {
  return await AdminSettingsDataSource.getCSATUser()
    .then(data => data.csats)
    .catch(err => {
      handleUnauthorized(err);
    });
});

export const addGetCsatUserReducers = builder => {
  builder.addCase(getCsatUser.pending, state => {
    state.loading = true;
  });
  builder.addCase(getCsatUser.fulfilled, (state, action) => {
    const payload: CSATUserModule[] = action.payload as CSATUserModule[];
    state.loading = false;
    state.csatUser = payload;
  });
  builder.addCase(getCsatUser.rejected, state => {
    state.loading = false;
  });
};
