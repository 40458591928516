import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Divisor } from '@atomic/atm.divisor';
import { Frame } from '@atomic/atm.frame';
import { H3 } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { OrderInputBaseboard } from '../../components/order-input-baseboard/order-input-baseboard';
import { OrderInputEvents } from '../../order-input.analytics';
import { OrderInputPhaseEnum, SearchByMaterial } from '../../order-input.constants';
import { OrderInputStrings } from '../../order-input.string';
import { mapMaterialToMaterialsParams } from '../../order-input.utils';
import { hasSomeMultipleMaterialSelector, totalItensSelector } from '../../store/order-input.selectors';
import { setPhase } from '../../store/order-input.store';
import { submitOrder } from '../../store/thunks/post-order.thunk';
import { ReceiverFormDisabled } from '../order-input-amount/partials/amount-table/receiver-form-disabled/receiver-form-disabled';
import { OrderInputResumeGridWrapper, OrderInputResumeWrapper } from './order-input-resume.style';
import { ResumeTable } from './resume-table/resume-table';

const Strings = OrderInputStrings.pt;

export const OrderInputResume: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const { selectedIssuer, searchBy, selectedMaterials, loadingPost } = useSelector(
    (state: RootState) => state.orderInput,
  );
  const totalItens = useSelector(totalItensSelector);
  const hasMultiple = useSelector(hasSomeMultipleMaterialSelector);

  const handleActionClick = () => {
    LogAnalytics.submit({
      tipo: OrderInputEvents.ConfirmarPedido,
      emissorPedido: `${selectedIssuer.value} - ${selectedIssuer.name} - ${selectedIssuer.city}`,
      nomenclaturaMaterial: searchBy === SearchByMaterial.SALES_DOCUMENT_ITEM_TEXT ? 'gerdauMaterialCode' : searchBy,
      total: `${totalItens.toString().replace('.', ',')}t`,
    });

    const materials = mapMaterialToMaterialsParams(selectedMaterials);

    materials.forEach(m => {
      LogAnalytics.orderInputMaterialItem({
        orderItemRef: m.orderItemRef,
        material: m.material,
        materialDesctiption: m.materialDesctiption,
        materialClientNumber: m.materialClientNumber,
        weight: m.weight,
        UM: m.UM,
        shippingDate: m.shippingDate,
        M2Date: m.isM2Date,
      });
    });

    dispatch(submitOrder());
  };

  const handleReturnClick = () => {
    LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: OrderInputPhaseEnum.DataAndAmout });
    const previousPhase = hasMultiple ? OrderInputPhaseEnum.Multiple : OrderInputPhaseEnum.DataAndAmout;
    dispatch(setPhase(previousPhase));
  };

  return (
    <OrderInputResumeWrapper data-testid='order-input-resume-phase'>
      <OrderInputResumeGridWrapper fluid>
        <Frame>
          <FlexColumn pt pl>
            <H3>{Strings.title}</H3>
            <FlexColumn fullWidth mt pb mr>
              <ReceiverFormDisabled title={OrderInputStrings.pt.phases.Revision} />
              <Divisor />
              <ResumeTable />
            </FlexColumn>
          </FlexColumn>
        </Frame>
      </OrderInputResumeGridWrapper>
      <OrderInputBaseboard
        loading={loadingPost}
        onActionClick={() => handleActionClick()}
        onReturnClick={() => handleReturnClick()}
      />
    </OrderInputResumeWrapper>
  );
};
