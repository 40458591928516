import * as React from 'react';
import { CellStyled } from './cell.component.style';

interface CellProps {
  onClick?: () => void;
  hideBorder?: boolean;
  hover?: boolean;
  onHover?: () => void;
  style?: object;
}

export const Cell: React.FC<CellProps> = props => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <CellStyled
      onMouseOver={props.onHover}
      hideBorder={props.hideBorder}
      onClick={handleClick}
      hover={props.hover}
      hasClick={!!props.onClick}
      style={props.style}
    >
      {props.children}
    </CellStyled>
  );
};
