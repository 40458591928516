import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route } from '@app/core/route';
import { AppPath } from '../app/route-constants';
import { hasOrdersTabAccessSelector } from '../auth/store/auth.selectores';
import { Order } from './order.page';

export const OrderRouter: React.FC<{}> = () => {
  const hasOrdersTabAccess = useSelector(hasOrdersTabAccessSelector);

  return <Route exact path='./' component={Order} authorized={hasOrdersTabAccess} redirectPath={AppPath.FINANCE} />;
};
