import { ErrorType } from '@app/models/error.model';
import { Strings, IAuthStrings } from './auth.string';

const strings: IAuthStrings = Strings.pt;
const authErrorStrings = strings.authError;

export const authErrorMessageMap: { [key in ErrorType]: string } = {
  [ErrorType.IncorrectData]: authErrorStrings.unauthorized,
  [ErrorType.Unauthorized]: authErrorStrings.unauthorized,
  [ErrorType.Forbidden]: authErrorStrings.forbidden,
  [ErrorType.NotFound]: authErrorStrings.notFound,
  [ErrorType.Internal]: authErrorStrings.internal,
  [ErrorType.Timeout]: authErrorStrings.timeout,
};
