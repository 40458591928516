import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { MEMaritimeOrderDataSource } from '@app/data/http/order-ME-maritime.datasource';
import { HanaLanguage } from '@app/models';
import { SalesOrdersMaritimeOpenItem } from '@app/models/maritime-order-item.model';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { defaultInitialState, IOrderTabState } from '../../../order.interfaces';
import { extraReducersConfig, reducers } from '../../../utils';

interface IOrderTabOpenMaritimeState extends IOrderTabState {
  data: SalesOrdersMaritimeOpenItem[];
  filteredRows: SalesOrdersMaritimeOpenItem[];
}
const SLICE_NAME = 'order-tab-open-maritime';

export const performRequest = createAsyncThunk(`${SLICE_NAME}/performRequest`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const promiseRequestTime = new Date().getTime();
  const customerIds = customerIdsJoinedSelector(thunkAPI.getState());
  return await MEMaritimeOrderDataSource.getMeMaritimeOpen({
    customerIds,
    language: HanaLanguage[state.auth?.userInfo?.language],
  })
    .then(data => ({ promiseRequestTime, data }))
    .catch(err => {
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const order = createSlice<IOrderTabOpenMaritimeState, SliceCaseReducers<IOrderTabOpenMaritimeState>>({
  name: SLICE_NAME,
  initialState: defaultInitialState,
  reducers,
  extraReducers: extraReducersConfig(performRequest),
});

export const { setFilter, setFilteredRows } = order.actions;

export const orderTabOpenMaritimeReducer = order.reducer;
