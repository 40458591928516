import { ColDef, GridReadyEvent } from 'ag-grid-community';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { TabIndex } from '@app/models/order.model';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { renderSubtotalField } from '@app/modules/order/grid-utils/grid-subtotal-field-render';
import { TruckTrackingPage } from '@app/modules/truck-tracking/truck-tracking.page';
import { Visibility } from '@atomic/obj.visibility';
import { OrderTabsHeaderMI } from '../order-tabs-header-MI.component';
import { OrderTabBilledComponent } from './order-tab-billed.component';
import { tabName } from './order-tab-billed.constants';
import { performRequest } from './store/order-tab-billed.thunks';
import { handleBilledTabColumn } from './utils/handler-utils';

export const OrderTabBilled: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const clientBpsSelectedJoined = useSelector(customerIdsJoinedSelector);
  const { data, lastUpdate, filteredRows } = useSelector((state: RootState) => state.orderTabBilled);
  const { showTruckTracking } = useSelector((state: RootState) => state.truckTracking);
  const { confirmedFilters } = useSelector((state: RootState) => state.advancedFilters[tabName]);
  const dispatch = useDispatch<AppThunkDispatch>();

  const columns: ColDef[] = useSelector(columnsTabLayoutSelector(tabName, userInfo?.language));
  const gridEvent = useRef<GridReadyEvent | null>(null);

  const calculateSubtotals = () =>
    renderSubtotalField({
      tab: tabName,
      itens: filteredRows,
      language: userInfo?.language,
      columnDef: columns,
    });

  useEffect(() => {
    dispatch(performRequest());
  }, [clientBpsSelectedJoined]);

  useEffect(() => {
    if (gridEvent.current) {
      gridEvent.current.api.onFilterChanged();
    }
  }, [confirmedFilters]);

  useEffect(() => {
    calculateSubtotals();
  }, [filteredRows, confirmedFilters]);

  useEffect(() => {
    if (gridEvent.current) {
      gridEvent.current.api.onFilterChanged();
    }
  }, [data]);

  handleBilledTabColumn(columns, userInfo?.language);

  return (
    <>
      {showTruckTracking && <TruckTrackingPage />}
      <Visibility visible={!showTruckTracking}>
        <OrderTabsHeaderMI lastUpdate={lastUpdate} tabIndex={TabIndex.Billed} />
        <OrderTabBilledComponent gridEvent={gridEvent} />
      </Visibility>
    </>
  );
};
