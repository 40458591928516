import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import type { RootState } from '@app/core/redux/store';
import { GetMaterialParams, MaterialDatasource } from '@app/data/http/material.datasource';
import { Material } from '@app/models';
import { MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID, SLICE_NAME, SearchByMaterial } from '../../order-input.constants';
import { IOrderInputState } from '../../order-input.interfaces';

export const getMaterialsThunk = createAsyncThunk(`${SLICE_NAME}/getMaterials`, async (_, thunkAPI) => {
  const state: RootState = thunkAPI.getState() as RootState;
  const { selectedIssuer }: IOrderInputState = state.orderInput;
  const preCustomerIds: string = selectedIssuer.value;
  const customerIds = preCustomerIds.replace(MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID);
  const { query, searchBy } = state.orderInput;

  const customerMaterialCode = searchBy === SearchByMaterial.CUSTOMER_MATERIAL_CODE ? query : null;
  const salesDocumentItemText = searchBy === SearchByMaterial.SALES_DOCUMENT_ITEM_TEXT ? query : null;
  const params: GetMaterialParams = { customerIds, customerMaterialCode, salesDocumentItemText };

  return await MaterialDatasource.getMaterials(params).then(data => {
    return data;
  }).catch(err => {
    return thunkAPI.rejectWithValue({ status: err.response.status, message: err.response.message });
  });
});

export const addMaterialsReducers = builder => {
  builder.addCase(getMaterialsThunk.pending, state => {
    state.loadingMaterials = true;
  });
  builder.addCase(getMaterialsThunk.fulfilled, (state: IOrderInputState, action) => {
    const payload: Material[] = action.payload as Material[];
    state.materialOptions = payload;
    state.loadingMaterials = false;
    state.openMaterials = true;
  });
  builder.addCase(getMaterialsThunk.rejected, (state) => {
    state.loadingMaterials = false;
  });
};
