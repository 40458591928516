import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGridLayout } from '@app/core/redux/grid-layout/thunks/grid-layout-get.thunk';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { EventTypes } from '@app/models/analytics.model';
import { MarketType } from '@app/providers';
import { SHOW_FEEDBACK_DELAY } from '@atomic/obj.constants';
import { Feedback, FeedbackAnalytics, FeedbackType } from '@atomic/obj.feedback';
import { LoadingState } from '@atomic/obj.loading-state';
import { ZeroState } from '../components/zero-state/zero-state.component';
import { OrderTabs } from './order-tabs/order-tabs.component';
import { OrderPageShimmer } from './order.page.shimmer';
import { setOpenFeedback } from './order.store';
import { OrdersStrings } from './orders.string';

const AnalyticsContext = {
  [MarketType.Internal]: FeedbackAnalytics.WalletMI,
  [MarketType.External]: FeedbackAnalytics.WalletME,
};

export const Order: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { currentMarket, clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const { tabs } = useSelector((state: RootState) => state.gridLayout);
  const { loading } = useSelector((state: RootState) => state.gridLayout);
  const { openFeedback } = useSelector((state: RootState) => state.order);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (userInfo?._id) {
      dispatch(getGridLayout());
    }
  }, [userInfo?._id, clientBpsSelected]);

  useEffect(() => {
    setTimeout(() => dispatch(setOpenFeedback(true)), SHOW_FEEDBACK_DELAY);
  }, []);

  if (!clientBpsSelected.length) {
    return <ZeroState />;
  }

  if (!tabs?.length || !userInfo?._id) {
    return null;
  }

  return (
    <>
      <LoadingState topMost loading={loading}>
        <LoadingState.Shimmer>
          <OrderPageShimmer />
        </LoadingState.Shimmer>
        <OrderTabs />
        <Feedback
          opened={openFeedback}
          setOpened={value => dispatch(setOpenFeedback(value))}
          context={AnalyticsContext[currentMarket]}
          type={FeedbackType.Numeric}
          message={OrdersStrings[userInfo?.language]?.common.feedback}
          analyticsType={EventTypes.WalletFeedback}
        />
      </LoadingState>
    </>
  );
};
