import { CriticalItemDto } from '@app/data/http/order-priorization.dto';
import { SalesOrderSummaryItem } from '@app/models';
import { IOrderPriorizationTableData } from '../order-priorization.interfaces';

export const mapSelectedRows = (
  rows: SalesOrderSummaryItem[],
  activatedCriticalItems: CriticalItemDto[],
): IOrderPriorizationTableData[] => {
  const mappedRows: IOrderPriorizationTableData[] = [];

  rows.forEach(row => {
    const activatedCriticalItem = activatedCriticalItems?.find(criticalItem => criticalItem.ov === row.ov);

    if (!activatedCriticalItem) {
      mappedRows.push({
        ovItem: row.ov,
        itemOv: row.itemOv,
        ocClient: row.ocClient,
        codMaterialClient: row.codMaterialClient,
        descMaterial: row.descMaterial,
        dateMaxDeliveryClient: row.dateMaxDeliveryClient,
        qtdConf: row.qtdConf,
        qtdSolClient: row.qtdSolClient,
        messageClient: '',
      });
    }
  });

  return mappedRows;
};

export const mapCriticalItemsRows = (rows: CriticalItemDto[]): IOrderPriorizationTableData[] => {
  return rows?.map(row => ({
    ovItem: row.ov,
    itemOv: row.ovItem,
    ocClient: row.oc,
    codMaterialClient: row.codProdClient,
    descMaterial: row.descMaterial,
    dateMaxDeliveryClient: row.dataConf,
    qtdConf: parseFloat(row.qtyConf),
    qtdSolClient: parseFloat(row.qtySolic),
    messageClient: row.mensagem,
  }));
};

export const getUpdatedSelectedRows = (
  mappedCriticalItems: IOrderPriorizationTableData[],
  selectedRows: SalesOrderSummaryItem[],
): SalesOrderSummaryItem[] => {
  const updatedSelectedRows: SalesOrderSummaryItem[] = [];

  selectedRows.forEach(s => {
    const mappedCriticalItem = mappedCriticalItems?.find(m => m.ovItem === s.ov);

    if (!mappedCriticalItem) {
      updatedSelectedRows.push(s);
    }
  });

  return updatedSelectedRows;
};

export const getUpdatedRows = (currentRows: IOrderPriorizationTableData[], row: IOrderPriorizationTableData) => {
  const updatedRows: IOrderPriorizationTableData[] = [];

  currentRows.forEach(r => {
    if (r.ovItem !== row.ovItem) {
      updatedRows.push(r);
    }
  });

  return updatedRows;
};
