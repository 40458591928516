import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { UserScheduleType } from '@app/modules/components/schedule-modal/schedule.constants';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { ScheduleWizard } from '../../../schedule-modal-wizard/schedule-modal-wizard.component';

export const ScheduleModalFormHeader: React.FC = () => {
  const { editMode, scheduleName, currentTab } = useSelector((state: RootState) => state.schedule);
  const subtitle = currentTab === UserScheduleType.ORDER_OPEN ? strings.subtitleOpenOrder : strings.subtitleStock;

  return (
    <>
      <FlexRow hAlign='space-between' vAlign='start'>
        <Body>{`${subtitle} ${scheduleName}`}</Body>
        {!editMode && <ScheduleWizard />}
      </FlexRow>
    </>
  );
};
