import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { initialStateWithDates } from '../../../order.interfaces';
import { extraReducersConfig, reducersWithDates } from '../../../utils';
import { SLICE_NAME } from '../order-tab-billed.constants';
import { IOrderTabBilledState } from './order-tab-billed.interface';
import { performRequest } from './order-tab-billed.thunks';

export const order = createSlice<IOrderTabBilledState, SliceCaseReducers<IOrderTabBilledState>>({
  name: SLICE_NAME,
  initialState: initialStateWithDates,
  reducers: reducersWithDates,
  extraReducers: extraReducersConfig(performRequest),
});

export const { setStartDate, setEndDate, setFilteredRows, setSelectedRows } = order.actions;

export const orderTabBilledReducer = order.reducer;
