import { FormData } from '@atomic/obj.form';
import { FeedbackAnalytics } from '.';

export enum FeedbackIndex {
  hate = 1,
  unsatisfied = 2,
  neutral = 3,
  like = 4,
  love = 5,
}

export enum FeedbackType {
  Img = 'img',
  Numeric = 'numeric',
}

export enum FeedbackContainerType {
  Box = 'Box',
  Modal = 'Modal',
}

export interface FeedbackData {
  comment: string;
}

export interface FeedbackComponentProps {
  onClose: () => void;
  context: FeedbackAnalytics;
  type: FeedbackType;
  message?: string;
  feedbackPhase: string;
  selected: FeedbackIndex;
  setSelected: (value) => void;
  answers: any;
  handleSubmit: (formData: FormData<FeedbackData>) => void;
  comment: string;
  setComment: (value) => void;
}

export enum FeedbackPhase {
  Initial = 'initial',
  Comment = 'comment',
  Success = 'success',
}
