export interface ILackOfChargeModalStrings {
  announcement: {
    title: string;
    confirmButton: string;
  };
}

interface ILackOfChargeModalLanguages {
  pt: ILackOfChargeModalStrings;
  es: ILackOfChargeModalStrings;
  in: ILackOfChargeModalStrings;
}

const pt: ILackOfChargeModalStrings = {
  announcement: {
    title: 'Atenção!',
    confirmButton: 'Ok, entendido',
  },
};

const es: ILackOfChargeModalStrings = {
  announcement: {
    title: '¡Atención!',
    confirmButton: 'Ok, entendido',
  },
};

const en: ILackOfChargeModalStrings = {
  announcement: {
    title: 'Attention!',
    confirmButton: 'Ok, understood',
  },
};

export const Strings: ILackOfChargeModalLanguages = {
  pt,
  es: es,
  in: en,
};
