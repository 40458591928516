import { addMonths, startOfMonth } from 'date-fns';
import { Material } from '@app/models';
import { MaterialCellError } from '@app/modules/order-input/order-input.constants';
import { Color } from '@atomic/obj.constants';

export const getMounthSum = (today: Date) => {
  const day = today.getDate();
  const dayOfWeek = today.getDay();
  if (day <= 5 || (day === 6 && (dayOfWeek === 0 || dayOfWeek === 1)) || (day === 7 && dayOfWeek === 1)) {
    return 2;
  }
  return 3;
};

export const isM2Date = (today: Date, day: Date, m2Months: number) => {
  const baseDay = addMonths(today, m2Months);

  if (day < baseDay) {
    return true;
  }
  return false;
};

export const isM2DateRule = (date: Date) => {
  if (!date) {
    return false;
  }
  const today = new Date();
  const firstDayOfMonth = startOfMonth(today);
  const m2Month = getMounthSum(today);
  const hasWarning = isM2Date(firstDayOfMonth, date, m2Month);
  return hasWarning;
};

export const hasErrorsOnLoad = (selectedMaterials: Material[]): boolean => {
  let showAlert = false;

  selectedMaterials.map((material: Material) => {
    const filteredMessages = material?.messages?.filter(m => m.code !== MaterialCellError.DateOutOfRange);
    if (filteredMessages && filteredMessages.length) {
      showAlert = true;
    }
  });

  return showAlert;
};

export const getRowColor = (material: Material, today: Date) => {
  const firstDayOfMonth = startOfMonth(today);
  const m2Month = getMounthSum(today);

  if (material.date) {
    if (isM2Date(firstDayOfMonth, new Date(material.date), m2Month)) {
      return Color.Yellow;
    }
  }

  if (material?.messages) {
    if (material.messages.filter(m => m.code !== MaterialCellError.DateOutOfRange)?.length) {
      return Color.AlertLight;
    }
  }

  return null;
};
