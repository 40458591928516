import { performRequest } from '@app/modules/order/order-tabs/MI/tab-open/store/order-tab-open.thunks';
import { getCriticalItemRules } from './get-critical-item-rule.thunk';
import { getCriticalItems } from './get-critical-item.thunk';

export const getUpdatedOrderTabOpen = () => async (dispatch, _) => {
  await dispatch(getCriticalItemRules());
  await dispatch(getCriticalItems());
  await dispatch(performRequest());

  return;
};
