import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Badge } from '@atomic/atm.badge';
import { Body, H2, H3, Label } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { CrmStrings } from '../../crm.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

export const CrmOpportunityDetails = props => {
  const strings = CrmStrings.pt;
  return (
    <CrmContentWrapper open={true}>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.OPPORTUNITY_DETAILS} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>

      {props.data && (
        <CrmContentInfosWrapper gridRepeat={2}>
          <Hbox>
            <H3 bold={800}>{strings.subSessions[props.data.GENERAL_DATA.label]}</H3>
            <Separator small />
            {props.data.GENERAL_DATA.infos.map((item, index) => (
              <Body className={item.label === 'clientAddress' && item.label} key={index}>
                <Label>{strings.fields[item.label]} </Label>
                {item.value}
              </Body>
            ))}
            <Separator small />
          </Hbox>
          <Hbox>
            <H3 bold={800}>{strings.subSessions[props.data.DATES.label]}</H3>
            <Separator small />
            {props.data.DATES.infos.map((item, index) => (
              <Body key={index}>
                <Label>{strings.fields[item.label]} </Label>
                {item.value}
              </Body>
            ))}

            <H3 bold={800}>{strings.subSessions[props.data.CYCLE_SALES.label]}</H3>
            <Separator small />
            {props.data.CYCLE_SALES.infos.map((item, index) => (
              <Body key={index}>
                <Label>{strings.fields[item.label]} </Label>
                <Badge color='warning'>{item.value}</Badge>
              </Body>
            ))}

            <H3 bold={800}>{strings.subSessions[props.data.CLASSIFICATION.label]}</H3>
            <Separator small />
            {props.data.CLASSIFICATION.infos.map((item, index) => (
              <Body key={index}>
                <Label>{strings.fields[item.label]} </Label>
                {item.value}
              </Body>
            ))}

            <Separator small />
          </Hbox>
        </CrmContentInfosWrapper>
      )}
    </CrmContentWrapper>
  );
};
