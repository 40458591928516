import styled from 'styled-components';
import { Color, FontFamily, Spacing } from '@atomic/obj.constants';

export const CustomerSearchFieldAdminWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${Spacing.Small};
`;

export const SelectSomeClientMessage = styled.span`
  font-family: ${FontFamily.Primary};
  color: ${Color.Alert};
  margin-bottom: ${Spacing.Medium};
  height: 20px;
`;
