import { ConfigColumnsModel } from '@app/models';

export interface ConfigColumnsDTO {
  identifier: string;
  inicioLinha: string;
  codInterno: string;
  quantidade: string;
  unidadeMedida: string;
  numeroPedido: string;
  dataDesejada: string;
  itemPedido: string;
}

export const mapConfigColumnsDTOToModel = (response: ConfigColumnsDTO): ConfigColumnsModel => {
  const mappedResponse: ConfigColumnsModel = {
    identifier: response.identifier,
    initRow: response.inicioLinha,
    materialCodeColumn: response.codInterno,
    amountColumn: response.quantidade,
    unityAmountColumn: response.unidadeMedida,
    orderNumberColumn: response.numeroPedido,
    dateWishedColumn: response.dataDesejada,
    itemIdentifierColumn: response.itemPedido,
  };

  return mappedResponse;
};

export const mapConfigColumnsModelToDTO = (model: ConfigColumnsModel): ConfigColumnsDTO => {
  const mappedDTO: ConfigColumnsDTO = {
    identifier: model.identifier,
    inicioLinha: model.initRow,
    codInterno: model.materialCodeColumn,
    quantidade: model.amountColumn,
    unidadeMedida: model.unityAmountColumn,
    numeroPedido: model.orderNumberColumn,
    dataDesejada: model.dateWishedColumn,
    itemPedido: model.itemIdentifierColumn,
  };

  return mappedDTO;
};
