export interface IFeedbackStrings {
  title: string;
  submitButton: string;
  inputPlaceholder: string;
  emailPlaceholder: string;
  successMessage: string;
  successMessageCaption: string;
  least: string;
  most: string;
}

interface IFeedbackLanguages {
  pt: IFeedbackStrings;
  es: IFeedbackStrings;
  in: IFeedbackStrings;
}

const pt: IFeedbackStrings = {
  title: 'Como você avalia a experiência no Gerdau Mais?',
  submitButton: 'Enviar',
  inputPlaceholder: 'Deixe um comentário (opcional)',
  emailPlaceholder: 'Email para contato (opcional)',
  successMessage: 'Obrigado pelo seu feedback.',
  successMessageCaption: 'Sua opinião é muito importante para nós.',
  least: 'Muito insatisfeito',
  most: 'Muito satisfeito',
};

const es: IFeedbackStrings = {
  title: '¿Cómo califica la experiencia en Gerdau Mais?',
  submitButton: 'Enviar',
  inputPlaceholder: 'Deja un comentario (opcional)',
  emailPlaceholder: 'Email de contacto (opcional)',
  successMessage: 'Gracias por tus feedback.',
  successMessageCaption: 'Su opinión es muy importante para nosotros.',
  least: 'Muy insatisfecho',
  most: 'Muy satisfecho',
};

const ing: IFeedbackStrings = {
  title: 'How do you rate the experience at Gerdau Mais?',
  submitButton: 'Send',
  inputPlaceholder: 'Leave a comment (optional)',
  emailPlaceholder: 'Contact email (optional)',
  successMessage: 'Thanks for your feedback.',
  successMessageCaption: 'Your opinion is very important to us.',
  least: 'Very unsatisfied',
  most: 'Very satisfied',
};

export const FeedbackStrings: IFeedbackLanguages = {
  pt,
  es,
  in: ing,
};
