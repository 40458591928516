import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { setInitRow } from '@app/modules/order-input/store/order-input.store';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import { ConfigColumnsModalStrings, Strings } from '../config-columns-modal.string';
import { RowWrapper } from '../config-columns-modal.style';
import { validRowField } from '../utils';

export const ConfigRow: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const userLanguage = userInfo.language;
  const strings: ConfigColumnsModalStrings = Strings[userLanguage];
  const {
    configColumnsSpreadsheet: { initRow },
    loadingGetConfigColumnsSpreadsheet,
  } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch();

  return (
    <RowWrapper>
      <Body>{strings.descriptionRow}</Body>
      <TextField
        style={{ border: !initRow ? `2px solid ${Color.Alert}` : `2px solid ${Color.MediumXGray}` }}
        disabled={loadingGetConfigColumnsSpreadsheet}
        type='text'
        align='center'
        value={initRow}
        onFocus={e => e.target.select()}
        onValueChange={(newValue: string) => dispatch(setInitRow(validRowField(newValue, initRow)))}
      />
    </RowWrapper>
  );
};
