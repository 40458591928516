export interface ErrorHandlerModalStrings {
  title: string;
  message: string;
  confirmButton: string;
}

interface ErrorHandlerModalLanguages {
  pt: ErrorHandlerModalStrings;
  es: ErrorHandlerModalStrings;
  in: ErrorHandlerModalStrings;
}

const pt: ErrorHandlerModalStrings = {
  title: 'Erro de sistema',
  message:
    'Infelizmente tivemos um erro interno no sistema e não conseguimos completar a sua solicitação agora. Por favor tente novamente em alguns instantes.',
  confirmButton: 'Ok, entendido',
};

export const Strings: ErrorHandlerModalLanguages = {
  pt,
  es: pt,
  in: pt,
};
