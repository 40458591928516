import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Button } from '@atomic/atm.button';
import { TextAreaField } from '@atomic/atm.text-field';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';

interface TechnicalAnalysisStatusModalProps {
  opened: boolean;
  status: string;
  onClose: () => void;
  onSubmit: (key: string, data: any) => void;
}

export const StatusCancelModal: React.FC<TechnicalAnalysisStatusModalProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = TechnicalAnalysisStrings[userInfo.language].status.cancelModal;

  const handleSubmit = form => {
    if (!(Object.keys(form.error).length > 0)) {
      props.onSubmit(props.status, { observation: form.data.observation });
    }
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Modal opened={props.opened} onClose={handleClose} small>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <VSeparator />

            <H3>{strings.title}</H3>
            <VSeparator />

            <Form onSubmit={handleSubmit}>
              <VSeparator small />
              <Body>{strings.text}</Body>

              <VSeparator />

              <Form.Field name='observation' validators={[Validators.Required(strings.alertMandatory)]}>
                <TextAreaField placeholder={strings.obervationLabel} disableResize />
              </Form.Field>
              <VSeparator small />

              <VSeparator />

              <Hbox hAlign='flex-end'>
                <Hbox.Item noGrow>
                  <Button type='button' kind='alertSecondary' onClick={handleClose}>
                    {strings.btnCancel}
                  </Button>
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item noGrow>
                  <Button type='submit' kind='primary'>
                    {strings.btnSubmit}
                  </Button>
                </Hbox.Item>
              </Hbox>
            </Form>

            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
