import React from 'react';
import { TableColumnType } from '@app/models/order-priorization.model';
import { IOrderPriorizationTableData } from '@app/modules/order-priorization/order-priorization.interfaces';
import { orderUpdateTableRowData } from '@app/modules/order-update/order-update.constants';
import { IOrderUpdateStrings, Strings } from '@app/modules/order-update/order-update.string';
import { Form } from '@atomic/obj.form';
import { PriorizationOrderMessageStyled } from '../order-priorization-table.style';
import { OrderPriorizationTableActionCell } from './order-priorization-table-action-cell.component';
import { OrderPriorizationTableIconCell } from './order-priorization-table-icon-cell.component';
import { OrderPriorizationTableStaticDate } from './order-priorization-table-static-data.component';
import { OrderPriorizationTableStaticText } from './order-priorization-table-static-text.component';

interface OrderUpdateTableCellProps {
  type: string;
  item: IOrderPriorizationTableData;
  column: string;
  index: number;
}

export const OrderPriorizationTableCell: React.FC<OrderUpdateTableCellProps> = ({ type, item, column, index }) => {
  const strings: IOrderUpdateStrings = Strings.pt;

  switch (type) {
    case TableColumnType.StaticText:
      return <OrderPriorizationTableStaticText item={item} column={column} />;
    case TableColumnType.StaticDate:
      return <OrderPriorizationTableStaticDate item={item} column={column} />;
    case TableColumnType.Icon:
      return <OrderPriorizationTableIconCell item={item} column={column} />;
    case TableColumnType.Action:
      return <OrderPriorizationTableActionCell item={item} />;
    default:
      return (
        <Form.Field name={`order[${index}].${column}`}>
          <PriorizationOrderMessageStyled
            type={orderUpdateTableRowData[column].type}
            placeholder={strings.table.newMsg}
            disabled={false}
            selected={false}
          />
        </Form.Field>
      );
  }
};
