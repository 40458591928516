import { Checkbox } from '@gerdau/hefesto';
import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { RoleDisplayName, RoleInternalName, TabInternalName } from '@app/models/admin.model';
import { setSellerRoleConfig } from '@app/modules/admin/store/admin.store';
import { updateRole } from '@app/modules/admin/store/thunk/profile-update.thunk';
import { addOrRemoveItem } from '@app/utils/array-helper';
import {
  AdminPermissionsRoleCol,
  PermissionRoleColCell,
  PermissionRoleTitleColCell,
} from '../../admin-profile-view.styles';
import { getTabIdList } from '../../utils/utils';

export const AdminProfileSellerRoleCol: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { tabChips, sellerRoleConfig } = useSelector((state: RootState) => state.admin);

  const handleChange = (tagName: string) => {
    const tab = { _id: tabChips.filter(t => t.tag === tagName)[0]._id, tag: tagName };
    const newTabArray = addOrRemoveItem(tagName, 'tag', sellerRoleConfig.tabs, tab);
    const updatedSellerRoleConfig = { _id: sellerRoleConfig._id, profile: sellerRoleConfig.profile, tabs: newTabArray };
    dispatch(setSellerRoleConfig(updatedSellerRoleConfig));
    dispatch(
      updateRole({ uid: sellerRoleConfig._id, profile: sellerRoleConfig.profile, tabs: getTabIdList(newTabArray) }),
    );
  };

  return (
    <AdminPermissionsRoleCol>
      <PermissionRoleTitleColCell>
        <Tag variant='neutral'>{sellerRoleConfig?.profile === RoleInternalName.Seller && RoleDisplayName.Seller}</Tag>
      </PermissionRoleTitleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerDashboardCb'
          checked={
            sellerRoleConfig?.tabs?.length &&
            !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.Dashboard)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Dashboard);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerQuotationtCb'
          checked={
            sellerRoleConfig?.tabs?.length &&
            !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.Quotation)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Quotation);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerOrderInputCb'
          checked={
            sellerRoleConfig?.tabs?.length &&
            !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.OrderInput)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.OrderInput);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerOrderCb'
          checked={
            sellerRoleConfig?.tabs?.length && !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.Order)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Order);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerFinanceCb'
          checked={
            sellerRoleConfig?.tabs?.length && !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.Finance)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Finance);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerManifestCb'
          checked={
            sellerRoleConfig?.tabs?.length && !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.Manifest)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Manifest);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerIsaManagerCb'
          checked={
            sellerRoleConfig?.tabs?.length &&
            !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.IsaManager)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.IsaManager);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerCrmCb'
          checked={
            sellerRoleConfig?.tabs?.length && !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.Crm)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Crm);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerSurrogateCb'
          checked={
            sellerRoleConfig?.tabs?.length &&
            !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.Surrogate)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Surrogate);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerLackOfLoadCb'
          checked={
            sellerRoleConfig?.tabs?.length &&
            !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.LackOfLoad)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.LackOfLoad);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='sellerSchedulingCompletedCb'
          checked={
            sellerRoleConfig?.tabs?.length &&
            !!sellerRoleConfig.tabs.filter(t => t.tag === TabInternalName.SchedulingCompleted)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.SchedulingCompleted);
          }}
        />
      </PermissionRoleColCell>
    </AdminPermissionsRoleCol>
  );
};
