import React from 'react';
import { useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { RootState } from '@app/core/redux/store';
import { EditConversationParams, TableRowData } from '@app/data/http/quote-params.dto';
import { Attributes } from '@app/models/chat.model';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Button } from '@atomic/atm.button';
import { Body, ButtonText, H3 } from '@atomic/atm.typography';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { Hbox } from '@atomic/obj.box';
import { IconSize } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { QuoteStrings } from '../quote/quote.string';
import { Attribute } from './chat-result-attribute.component';
import {
  ChatCreateButtonWrapper,
  ChatDrawerStyled,
  ChatResultLabelStyled,
  ChatResultWrapperStyled,
} from './chat-result.component.style';
import { ChatStrings } from './chat.string';

interface ChatResultProps {
  context: any;
  loading: boolean;
  systemAttributes: string[];
  initialized?: boolean;
  questions?: any;
  onRemoveContextAttribute: (key: string) => void;
  onAnalysisReset: () => void;
  onSubmit: () => void;
  attributes: Attributes[];
  onEditAttribute: (data: EditConversationParams) => void;
}

export const ChatResult: React.FC<ChatResultProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language].resumeAnalysis;
  const handleValueChange = (data: EditConversationParams) => {
    props.onEditAttribute({ attributeKey: data.attributeKey, value: data.value, type: data.type, unit: data.unit });
  };

  const handleModalData = (data: TableRowData[], key: string) => {
    props.onEditAttribute({ attributeKey: key, value: data, type: 'table' });
  };

  const handleRemove = (key: string) => {
    props.onRemoveContextAttribute(key);
  };

  const handleAnalysisReset = () => {
    props.onAnalysisReset();
  };

  React.useEffect(() => {
    Scroll.animateScroll.scrollToBottom({ containerId: 'result-analysis' });
  }, [props.attributes]);

  return (
    <ChatResultWrapperStyled initialized={props.initialized}>
      <DefaultListCell bg>
        <Hbox>
          <Hbox.Item>
            <H3>{strings.resume}</H3>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item vAlign='center'>
            {!props.loading ? (
              <ButtonText onClick={handleAnalysisReset}>{strings.reset}</ButtonText>
            ) : (
              <ActivityIndicator type='spinner' size={IconSize.Medium} />
            )}
          </Hbox.Item>
        </Hbox>
      </DefaultListCell>
      <ChatResultLabelStyled>
        <DefaultListCell bg>
          <Hbox noGrow>
            <Hbox.Item>
              <Body>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResult}</Body>
            </Hbox.Item>
          </Hbox>
        </DefaultListCell>
      </ChatResultLabelStyled>
      <ChatDrawerStyled id='result-analysis' hasSubmitButton={!!props.context.searchStatus}>
        <Scroll.Element>
          {props.attributes.map((item, index) => (
            <Hbox.Item key={index}>
              {item.key && item.permissions.isVisible && (
                <Attribute
                  attribute={item}
                  onRemove={handleRemove}
                  onValueChange={handleValueChange}
                  loading={props.loading}
                  onData={handleModalData}
                  questions={props.questions}
                />
              )}
            </Hbox.Item>
          ))}
        </Scroll.Element>
        <VSeparator />
      </ChatDrawerStyled>

      {!!props.context.searchStatus && (
        <ChatCreateButtonWrapper>
          <Button expanded onClick={props.onSubmit} kind='callToAction'>
            {strings.finish}
          </Button>
        </ChatCreateButtonWrapper>
      )}
    </ChatResultWrapperStyled>
  );
};
