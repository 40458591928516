import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { OrderInputImplantationTypeEnum } from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { H3 } from '@atomic/atm.typography';

const strings = OrderInputStrings.pt.selectItens;

export const OrderInputAmountTitle: React.FC = () => {
  const { implantationType } = useSelector((state: RootState) => state.orderInput);

  const title =
    implantationType === OrderInputImplantationTypeEnum.UploadSpreadsheet ? strings.uploadOptionTitle : strings.title;

  return <H3>{title}</H3>;
};
