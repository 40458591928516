import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import { SLICE_NAME } from '../auth.constants';
import { IAuthState } from '../auth.interfaces';
import { initialState } from './auth.initial';
import { reducers } from './auth.reducers';
import { addThunkReducers } from './thunk/login.thunks';

export const authSlice = createSlice<IAuthState, SliceCaseReducers<IAuthState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const {
  reset,
  setAuthData,
  setForgotPasswordLoading,
  setLoading,
  setLoadingProfile,
  setLoadingValidateToken,
  setForgotPasswordData,
  setUserLanguage,
} = authSlice.actions;

const persistConfig = {
  key: 'auth-persist-reducer',
  storage,
  blacklist: ['loading', 'loadingProfile'],
};

export const authPersistReducer = persistReducer(persistConfig, authSlice.reducer);
