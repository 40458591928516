import * as React from 'react';
import {
  AttachedFilesDataProps,
  DatesDataProps,
  ExpeditionDataProps,
  HistoryDataProps,
  ItemsDataProps,
  NotesDataPropsComplain,
  OrganizationalDataProps,
  PeopleInvolvedDataProps,
  TotalPricesDataProps,
  WorkflowDataProps,
} from '@app/models/crm.model';
import { Separator } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { CrmAttachedFilesComplain } from './crmAttachedFilesComplain';
import { CrmDates } from './crmDates';
import { CrmExpedition } from './crmExpedition';
import { CrmHistory } from './crmHistory';
import { CrmItems } from './crmItems';
import { CrmNotes } from './crmNotes';
import { CrmOpportunityDetails } from './crmOpportunityDetails';
import { CrmOrganizationalData } from './crmOrganizationalData';
import { CrmPeopleInvolved } from './crmPeopleInvoved';
import { CrmTotalPrices } from './crmTotalPrices';
import { CrmWorkflow } from './crmWorkflow';

interface CrmContentContainerProps {
  loadingAttachedFiles: boolean;
  loadingItems: boolean;
  loadingNotes: boolean;
  loadingOpportunityDetail: boolean;
  loadingHistory: boolean;
  loadingTotalPrices: boolean;
  loadingPeopleInvolved: boolean;
  loadingOrganizationalDataData: boolean;
  loadingExpedition: boolean;
  loadingDates: boolean;
  loadingWorkflow: boolean;
  attachedFilesData: AttachedFilesDataProps;
  notesData: NotesDataPropsComplain;
  opportunityDetailsData: any;
  historyData: HistoryDataProps;
  itemsData: ItemsDataProps;
  totalPricesData: TotalPricesDataProps;
  peopleInvolvedData: PeopleInvolvedDataProps;
  organizationalDataData: OrganizationalDataProps;
  expeditionData: ExpeditionDataProps;
  datesData: DatesDataProps;
  workflowData: WorkflowDataProps;
}

export const CrmContentContainer = (props: CrmContentContainerProps) => {
  return (
    <Grid fluid mb={30}>
      <Row mt>
        <Col xs={12}>
          <CrmOpportunityDetails data={props?.opportunityDetailsData} loading={props.loadingOpportunityDetail} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmWorkflow data={props?.workflowData} loading={props.loadingWorkflow} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmItems data={props?.itemsData} loading={props.loadingItems} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmNotes data={props?.notesData} loading={props.loadingNotes} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmAttachedFilesComplain data={props?.attachedFilesData} loading={props.loadingAttachedFiles} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmTotalPrices data={props?.totalPricesData} loading={props.loadingTotalPrices} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmPeopleInvolved data={props?.peopleInvolvedData} loading={props.loadingPeopleInvolved} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmOrganizationalData data={props?.organizationalDataData} loading={props.loadingOrganizationalDataData} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmExpedition data={props?.expeditionData} loading={props.loadingExpedition} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmDates data={props?.datesData} loading={props.loadingDates} />
        </Col>
      </Row>

      <Row mt>
        <Col xs={12}>
          <CrmHistory data={props?.historyData} loading={props.loadingHistory} />
        </Col>
      </Row>
      <Separator />
      <Separator />
    </Grid>
  );
};
