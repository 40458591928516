import React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Label } from '@atomic/atm.typography';
import { Color, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { BadgeWrapper } from './badge.style';

interface BadgeProps {
  text: string;
  onClick: () => void;
  valid?: boolean;
  type?: 'primary' | 'secondary';
}

export const Badge: React.FC<BadgeProps> = ({ text, onClick, valid = true, type = 'secondary' }) => {
  const typeColor = type === 'primary' ? Color.White : Color.Primary;
  const color = valid ? typeColor : Color.Alert;
  const typeBackgroundColor = type === 'primary' ? Color.Primary : Color.Secondary;
  const backgroundColor = valid ? typeBackgroundColor : Color.AlertLight;

  return (
    <BadgeWrapper type={type} color={color} backgroundColor={backgroundColor}>
      <Label size={FontSize.XSmall} weight={FontWeight.Normal} pl={Spacing.Small} pr={Spacing.Small} color={color}>
        {text}
      </Label>
      <FaIcon.Close color={color} style={{ cursor: 'pointer' }} onClick={onClick} size='sm' />
    </BadgeWrapper>
  );
};
