import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { InputValue } from '@atomic/atm.typography';
import { Color, Spacing } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';

const radioSize = 24;

interface RadioFieldStyledProps {
  expanded: boolean;
  mb?: boolean;
}

export const RadioFieldStyled = styled.span`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${(props: RadioFieldStyledProps) => (props.mb ? Spacing.Medium : 0)};
  ${(props: RadioFieldStyledProps) => (props.expanded ? 'flex: 1' : undefined)};

  & ~ * {
    margin-top: ${Spacing.Small};
  }
`;

export const RadioFieldLabelStyled = styled(InputValue)`
  line-height: ${radioSize}px !important;
  padding-left: 30px;
  cursor: pointer;
  ${(props: RadioFieldStyledProps) => (props.expanded ? 'flex: 1' : undefined)};
`;

export const RadioFieldContentStyled = styled.div`
  margin-left: ${Spacing.XLarge};
  ${highlightStyle};
`;

const RadioBulletStyled = styled(FontAwesomeIcon).attrs({ size: 'lg' })`
  position: absolute;
`;

export const RadioCheckedStyled = styled(RadioBulletStyled).attrs({ icon: 'dot-circle' })`
  color: ${Color.Primary};
`;

export const RadioUncheckedStyled = styled(RadioBulletStyled).attrs({ icon: ['far', 'circle'] })`
  color: ${Color.Black};
`;

export const RadioFieldInputStyled = styled.input`
  display: none;
`;
