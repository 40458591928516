import ColorFunc from 'color';
import styled from 'styled-components';
import { Link } from '@app/core/route';
import { Body } from '@atomic/atm.typography';
import {
  Border,
  BrandColor,
  Breakpoint,
  Color,
  FontFamily,
  FontSize,
  HeaderHeight,
  Spacing,
} from '@atomic/obj.constants';

export const MenuStyled = styled.div`
  display: grid;
  grid-gap: ${Spacing.Medium};
  grid-template: 'action search nav logo' 100% / min-content 2fr 3fr min-content;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media all and (min-width: ${Breakpoint.lg}em) {
    grid-template:
      'logo nav action' 100% /
      min-content 3fr min-content;
  }
`;

export const AdminMenuStyled = styled.div`
  display: grid;
  grid-gap: ${Spacing.Medium};
  grid-template: 'nav logo' 100% / 2fr min-content;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  height: ${HeaderHeight.DeskShort};

  @media all and (min-width: ${Breakpoint.lg}em) {
    grid-template: 'logo nav action' 100% / min-content 2fr min-content;
  }
`;

export interface MenuItemNavLinkStyledProps {
  active: boolean;
  shortHeader: boolean;
}

export const MenuItemLogoStyled = styled.div`
  grid-area: logo;
  padding: 0 ${Spacing.Medium};
`;

export const MenuItemNavStyled = styled.div`
  grid-area: nav;
`;
export const MenuItemNavLinkStyled = styled(Link)`
  position: relative;
  padding: ${Spacing.Small} ${Spacing.Large};
  border-radius: ${Border.Radius};
  margin: 0 0;
  color: ${Color.Accessory};
  font-family: ${FontFamily.Primary};
  white-space: nowrap;
  display: block;
  background-color: transparent;
  font-size: ${FontSize.Small};
  ${(props: MenuItemNavLinkStyledProps) => (props.active ? 'font-weight: bold;' : null)};
  @media all and (max-width: ${Breakpoint.lg}em) {
    padding: ${Spacing.Medium};
    background-color: ${Color.White};
    border-bottom: 1px solid ${Color.GrayLight};
    border-right: 1px solid ${Color.GrayLight};
    border-radius: 0;
    &:last {
      border-bottom: none;
    }
  }
  &:hover {
    background-color: ${ColorFunc(Color.CallToAction)
      .darken(0.1)
      .hsl()
      .string()};
    @media all and (max-width: ${Breakpoint.lg}em) {
      background-color: ${Color.GrayLight};
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -${Spacing.Small};
    left: 0;
    width: 100%;
    border-color: ${Color.Accessory};
    border-style: solid;
    border-width: ${(props: MenuItemNavLinkStyledProps) => (props.active ? '2px' : '0')};
    @media all and (max-width: ${Breakpoint.lg}em) {
      border: 1px solid ${Color.GrayLight};
      border-width: 0;
    }
  }
`;

export const MenuItemHelpStyled = styled(Link)`
  position: relative;
  color: ${BrandColor.Blue};
  white-space: nowrap;
`;

export const MenuItemActionStyled = styled.div`
  display: ${(props: MenuItemNavLinkStyledProps) => (props.shortHeader ? 'flex' : 'grid')};
  flex-direction: ${(props: MenuItemNavLinkStyledProps) => (props.shortHeader ? 'row' : 'column')};
  grid-template-rows: ${(props: MenuItemNavLinkStyledProps) => (props.shortHeader ? '100%' : '50%')};
  height: ${(props: MenuItemNavLinkStyledProps) => (props.shortHeader ? HeaderHeight.DeskShort : HeaderHeight.Desk)};
  padding: 0 ${Spacing.Large};
  background-color: ${Color.White};
  transition: height 0.3s;
  @media all and (max-width: ${Breakpoint.lg}em) {
    display: none;
  }
`;

export const MenuItemSearchStyled = styled.div`
  grid-area: search;
`;

export const MenuItemTitleStyled = styled(Body)`
  position: relative;
  margin-left: ${Spacing.Medium};
  color: ${Color.White};
`;
