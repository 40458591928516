export interface ICustomerSearchModalBodyAdminStrings {
  initialMessage: string;
  noBpFoundMessage: string;
  selectAll: string;
  bpShowedMessage: string;
}

interface ICustomerSearchModaBodyAdminLanguages {
  pt: ICustomerSearchModalBodyAdminStrings;
  es: ICustomerSearchModalBodyAdminStrings;
  in: ICustomerSearchModalBodyAdminStrings;
}

const pt: ICustomerSearchModalBodyAdminStrings = {
  initialMessage: 'Para iniciar, realize uma busca',
  noBpFoundMessage: 'Nenhum BP encontrado',
  selectAll: 'Selecionar todos',
  bpShowedMessage: 'filiais exibidas',
};

const es: ICustomerSearchModalBodyAdminStrings = {
  initialMessage: 'Para iniciar, realize uma busca',
  noBpFoundMessage: 'No se encontró ningún filial',
  selectAll: 'Seleccionar todo',
  bpShowedMessage: 'filiais mostradas',
};

const en: ICustomerSearchModalBodyAdminStrings = {
  initialMessage: 'Para iniciar, realize uma busca',
  noBpFoundMessage: 'No Business Partner found',
  selectAll: 'Select all',
  bpShowedMessage: 'Business Partner showed',
};

export const Strings: ICustomerSearchModaBodyAdminLanguages = {
  pt,
  es,
  in: en,
};
