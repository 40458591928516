import styled from 'styled-components';
import { Color, Shadow, TransitionDuration, ZIndex } from '@atomic/obj.constants';

export const TechnicalAnalysisUserListWrappperStyled = styled.div`
  position: relative;
`;

export const TechnicalAnalysisUserListStyled = styled.div`
  ${props => (props.show ? 'opacity: 1;' : 'visibility: hidden;')}
  z-index: ${ZIndex.TechnicalAnalysisUserListStyled};
  position: absolute;
  background-color: ${Color.White};
  width: 100%;
  transition: all ${TransitionDuration} ease-in-out;
  box-shadow: ${Shadow.Small};
  align-content: flex-start;
`;

export const UserListWrapperStyled = styled.div`
  display: flex;
  max-height: 250px;
`;

export const UserListStyled = styled.div`
  flex: 1;
  overflow: scroll;
`;
