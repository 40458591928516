/* eslint-disable prettier/prettier */
import type { RootState } from '@app/core/redux/store';
import { checkInvalidFields } from '../utils';


export const disableOrderUpdateSelector = (state: RootState): boolean => {
  const tableRows = state.orderUpdate.tableRows;
  return checkInvalidFields(tableRows);
};

