export enum EventTypes {
  Login = 'login',
  Click = 'click',
  Error = 'system_error',
  PageView = 'page_view',
  Upload = 'upload',
  Submit = 'submit',
  Success = 'success',
  Feedback = 'feedback',
  Nps = 'nps',
  WalletFeedback = 'wallet_feedback',
  FinanceFeedback = 'finance_feedback',
  TruckTrackingFeedback = 'truck_tracking_feedback',
  OrderUpdateFeedback = 'order_update_feedback',
  ShippingRequestFeedback = 'shipping_request_feedback',
  ActivateNotification = 'activate_notification',
  DeactivateNotification = 'deactivate_notification',
  NotificationFeedback = 'notification_feedback',
  OrderInputMaterialItem = 'orderInputMaterialItem',
  OrderPriorizationRemoveItem = 'orderPriorizationRemoveItem',
}

export enum UserAnalyticsType {
  Employee = 'employee',
  Client = 'client',
}
