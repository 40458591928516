import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { AdminSettingsDataSource, AdminSettingsResetCSATParameters } from '@app/data/http/admin-settings.datasource';

export const useResetCSATRequest = (onSuccess: () => void, onError: () => void) => {
  return useLazyRequest<AdminSettingsResetCSATParameters, void>(
    AdminSettingsDataSource.postResetCSAT,
    onSuccess,
    onError,
  );
};
