import { getAnalytics, setUserId, setUserProperties, logEvent } from 'firebase/analytics';
import { EventTypes } from '@app/models/analytics.model';

const analyticsInstance = getAnalytics();
export class LogAnalytics {
  static setUserId(userId) {
    setUserId(analyticsInstance, userId);
  }

  static setUserProperties(userProperties) {
    setUserProperties(analyticsInstance, userProperties);
  }

  static logEvent(eventType: string, payload: any) {
    logEvent(analyticsInstance, eventType, payload);
  }

  static setCurrentScreen(pageTitle: string) {
    this.logEvent(EventTypes.PageView, { page_title: pageTitle });
  }

  static login(payload: any) {
    this.logEvent(EventTypes.Login, payload);
  }

  static pageView(pageTitle: string) {
    this.logEvent(EventTypes.PageView, { page_title: pageTitle });
  }

  static click(payload: any) {
    this.logEvent(EventTypes.Click, payload);
  }

  static submit(payload: any) {
    this.logEvent(EventTypes.Submit, payload);
  }

  static success(payload: any) {
    this.logEvent(EventTypes.Success, payload);
  }

  static error(payload: any) {
    this.logEvent(EventTypes.Error, payload);
  }

  static upload(payload: any) {
    this.logEvent(EventTypes.Upload, payload);
  }

  static nps(payload: any) {
    this.logEvent(EventTypes.Nps, payload);
  }

  static feedback(payload: any) {
    this.logEvent(EventTypes.Feedback, payload);
  }
  static walletFeedback(payload: any) {
    this.logEvent(EventTypes.WalletFeedback, payload);
  }

  static financeFeedback(payload: any) {
    this.logEvent(EventTypes.FinanceFeedback, payload);
  }

  static truckTrackingFeedback(payload: any) {
    this.logEvent(EventTypes.TruckTrackingFeedback, payload);
  }

  static orderUpdateFeedback(payload: any) {
    this.logEvent(EventTypes.OrderUpdateFeedback, payload);
  }

  static shippingRequestFeedback(payload: any) {
    this.logEvent(EventTypes.ShippingRequestFeedback, payload);
  }

  static activateNotification(payload: any) {
    this.logEvent(EventTypes.ActivateNotification, payload);
  }

  static deactivateNotification(payload: any) {
    this.logEvent(EventTypes.DeactivateNotification, payload);
  }
  static activateShippingConfig(payload: any) {
    this.logEvent(EventTypes.Success, payload);
  }

  static deactivateShippingConfig(payload: any) {
    this.logEvent(EventTypes.Success, payload);
  }

  static notificationFeedback(payload: any) {
    this.logEvent(EventTypes.NotificationFeedback, payload);
  }

  static orderInputMaterialItem(payload: any) {
    this.logEvent(EventTypes.OrderInputMaterialItem, payload);
  }

  static orderPriorizationRemoveItem(payload: any) {
    this.logEvent(EventTypes.OrderPriorizationRemoveItem, payload);
  }
}
