import * as React from 'react';

export interface AccordionGroupProps {
  onHeaderTap?: (id: string) => void;
  accordionId?: string[];
}

export interface AccordionGroupState {
  accordionIds: Map<string, boolean>;
}

export class AccordionGroup extends React.Component<AccordionGroupProps, AccordionGroupState> {
  constructor(props) {
    super(props);

    const accordionIds = new Map();

    React.Children.map(props.children, (child: any, i: number) => {
      const accordionId = props.accordionId ? props.accordionId[i] : 'Accordion_' + i;

      if (!accordionIds.get(accordionId)) {
        accordionIds.set(accordionId, child.props.expanded ? true : false);
      }
    });

    this.state = {
      accordionIds,
    };
  }

  render() {
    return <div>{this.getAccordions()}</div>;
  }

  private accordionTapHandle = (key: string, expanded: boolean) => {
    const accordionIds = new Map();

    this.state.accordionIds.forEach((_expanded: boolean, accordionId: string) => {
      accordionIds.set(accordionId, key === accordionId ? expanded : false);
    });

    if (this.props.onHeaderTap && expanded) {
      this.props.onHeaderTap(key);
    }

    this.setState({ accordionIds });
  };

  private getAccordions() {
    return React.Children.map(this.props.children, (child: any, i: number) => {
      const accordionId = this.props.accordionId ? this.props.accordionId[i] : 'Accordion_' + i;

      return React.cloneElement(child, {
        accordionId,
        onHeaderTap: this.accordionTapHandle,
        expanded: this.state.accordionIds.get(accordionId),
      });
    });
  }
}
