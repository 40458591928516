export interface IOrderMenuMeStrings {
  orderMaritimeText: string;
  orderRoadText: string;
}

export interface IOrderMenuMeLanguages {
  pt: IOrderMenuMeStrings;
  es: IOrderMenuMeStrings;
  in: IOrderMenuMeStrings;
}

const pt: IOrderMenuMeStrings = {
  orderMaritimeText: 'Carteira maritima',
  orderRoadText: 'Carteira rodoviária',
};

const es: IOrderMenuMeStrings = {
  orderMaritimeText: 'Carteira maritima',
  orderRoadText: 'Carteira rodoviária',
};

const en: IOrderMenuMeStrings = {
  orderMaritimeText: 'Maritime order',
  orderRoadText: 'Road order',
};

export const Strings: IOrderMenuMeLanguages = {
  pt,
  es,
  in: en,
};
