import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';

export const ResumeFooterContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`;

export const ResumeFooterSeparator = styled.div`
  border-right: 1px solid ${Color.GrayXDark};
  margin-right: 3em;
`;
