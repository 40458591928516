import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { ChatStrings } from '../../chat.string';
import { ChatResultAttributeWrapper } from './chat-result.component.style';

interface MacroataqueItems {
  element: string;
  value: any;
}
interface MacroataqueTableProps {
  attrKey: string;
  attribute: {
    type: string;
    creation: string;
    visible: boolean;
    label: string;
    value: MacroataqueItems[];
  };
}

export const MacroataqueTable: React.FC<MacroataqueTableProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const strings = ChatStrings[userInfo.language];

  return props.attribute?.visible ? (
    <ChatResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.creation === 'NLU'}
    >
      <Hbox.Item>
        <InputLabel>
          {props.attribute?.label}{' '}
          {props.attribute?.creation === 'NLU' && (
            <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
          )}
        </InputLabel>
      </Hbox.Item>

      <Table>
        <TR>
          <TD />
          <TD>
            <H4>{strings.modalMacroataque.valueColumn}</H4>
          </TD>
        </TR>
        {props.attribute.value.map(item => (
          <TR key={item.element}>
            <TD>
              <H4>{item.element}</H4>
            </TD>
            <TD>{item.value}</TD>
          </TR>
        ))}
      </Table>
      <VSeparator />
    </ChatResultAttributeWrapper>
  ) : null;
};
