import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { scheduleByTabSelector } from '../../../store/schedule.selectores';
import { setEditSchedule } from '../../../store/schedule.store';
import { ScheduleItem, ScheduleModalWrapper } from '../schedule-modal-list.style';
import { ScheduleModalListEmpty } from './schedule-modal-list-empty';
import { ScheduleModalListShimmer } from './schedule-modal-list.shimmer';

export const ScheduleModalListBody: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state.schedule);
  const { loading: loadingCustomers } = useSelector((state: RootState) => state.customerSearch);
  const dispatch = useDispatch();

  const schedules = useSelector(scheduleByTabSelector);

  return (
    <ScheduleModalWrapper>
      <LoadingState loading={loading || loadingCustomers} data={schedules?.length > 0}>
        <LoadingState.Shimmer>
          <ScheduleModalListShimmer />
        </LoadingState.Shimmer>
        <LoadingState.NoData>
          <ScheduleModalListEmpty />
        </LoadingState.NoData>
        {schedules.map(schedule => {
          return (
            <ScheduleItem onClick={() => dispatch(setEditSchedule(schedule))} key={schedule.id}>
              <Body bold='bold'>{schedule.name}</Body>
              <FaIcon.ChevronRight color={Color.Primary} size='sm' />
            </ScheduleItem>
          );
        })}
      </LoadingState>
    </ScheduleModalWrapper>
  );
};
