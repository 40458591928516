export const finalApplicationList = [
  {
    label: 'AIR LINK',
  },
  {
    label: 'BARRA ESTABILIZADORA',
  },
  {
    label: 'BIELA',
  },
  {
    label: 'BRAÇO DE DIREÇÃO',
  },
  {
    label: 'CREMALHEIRA',
  },
  {
    label: 'CUBO DE RODA',
  },
  {
    label: 'EIXO',
    options: [
      {
        label: 'DIREÇÃO',
      },
      {
        label: 'FREIO',
      },
      {
        label: 'MOTOR',
      },
      {
        label: 'SUSPENSÃO',
      },
      {
        label: 'TRANSMISSÃO',
      },
    ],
  },
  {
    label: 'ENGRENAGEM',
    options: [
      {
        label: 'DIREÇÃO',
      },
      {
        label: 'FREIO',
      },
      {
        label: 'MOTOR',
      },
      {
        label: 'TRANSMISSÃO',
      },
    ],
  },
  {
    label: 'FEIXE DE MOLA',
  },
  {
    label: 'FIXADORES',
    options: [
      {
        label: 'DIREÇÃO',
      },
      {
        label: 'FREIO',
      },
      {
        label: 'MOTOR',
      },
      {
        label: 'SUSPENSÃO',
      },
      {
        label: 'TRANSMISSÃO',
      },
    ],
  },
  {
    label: 'GARFO',
  },
  {
    label: 'GRAMPO DE MOLA',
  },
  {
    label: 'MOLA HELICOIDAL',
  },
  {
    label: 'MOLA PARABÓLICA',
  },
  {
    label: 'PINHÃO',
    options: [
      {
        label: 'DIREÇÃO',
      },
      {
        label: 'TRANSMISSÃO',
      },
    ],
  },
  {
    label: 'PISTÃO DE FREIO',
  },
  {
    label: 'PONTA DE EIXO',
  },
  {
    label: 'SEMI EIXO',
  },
  {
    label: 'TULIPA',
  },
  {
    label: 'VIGA',
  },
  {
    label: 'VIRABREQUIM',
  },
];
