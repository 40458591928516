import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const MultipleTableStyled = styled.div`
  table {
    border-collapse: collapse;
    border: 2px solid ${Color.Secondary};
    th {
      background-color: ${Color.Secondary};
      padding: ${Spacing.Medium} 0px;
    }

    tr {
      line-height: ${Spacing.XLarge};
      border-bottom: 2px solid ${Color.Secondary};
    }
  }
`;
