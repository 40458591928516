import * as React from 'react';
import { useSelector } from 'react-redux';
import { isGKNSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { Table } from '@atomic/mol.table';
import { VSeparator } from '@atomic/obj.grid';
import { ShippingRequestTableBody } from './partials/shipping-request-table-body/shipping-request-table-body.component';
import { ShippingRequestTableFooter } from './partials/shipping-request-table-footer/shipping-request-table-footer.component';
import { ShippingRequestTableHeaderRegions } from './partials/shipping-request-table-header/shipping-request-table-header-regions.component';
import { ShippingRequestTableHeader } from './partials/shipping-request-table-header/shipping-request-table-header.component';
import { ShippingRequestTableScroll, ShippingRequestTableWrapper } from './shipping-request-table.style';

interface IShippingRequestTableProps {
  plant: string;
}

export const ShippingRequestTable: React.FC<IShippingRequestTableProps> = ({ plant }) => {
  const isGKN = useSelector(isGKNSelector);

  return (
    <ShippingRequestTableScroll>
      <ShippingRequestTableWrapper isGKN={isGKN}>
        <Table collapse>
          {isGKN && <ShippingRequestTableHeaderRegions />}
          <ShippingRequestTableHeader />
          <ShippingRequestTableBody plant={plant} />
          <ShippingRequestTableFooter plant={plant} />
          <VSeparator />
        </Table>
      </ShippingRequestTableWrapper>
    </ShippingRequestTableScroll>
  );
};
