import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { NotificationActivationParams } from '@app/data/http/notification.dto';
import { ShippingConfigDatasource } from '@app/data/http/shipping-config.datasource';

export const useDeactivateShippingConfigRequest = (onSuccess: () => void) => {
  return useLazyRequest<NotificationActivationParams, void>(
    ShippingConfigDatasource.deactivateShippingConfig,
    onSuccess,
  );
};
