import { SalesOrderSummaryItem } from '@app/models';
import { ORDER_PAGE_RANGE_FILTER_FIELDS } from '@app/modules/components/side-bar-filters/sidebar-filters.constants';
import { IOrderFilterModel } from '@app/modules/components/side-bar-filters/sidebar-filters.interfaces';
import { brDateFormatToDate } from '@app/utils/date-utils';
import { tabName } from '../order-tab-open.constants';

export const filteredDataSelector = (state, data) => {
  let filteredData = data;

  const { confirmedFilters } = state.advancedFilters[tabName];
  const { dateSolClient, dateConfirmed } = confirmedFilters;

  if (dateSolClient?.startDate && dateSolClient?.endDate) {
    filteredData = filteredData.filter((row: SalesOrderSummaryItem) => {
      let include = false;

      const date = brDateFormatToDate(row.dateSolClient);

      if (date.getTime() >= dateSolClient.startDate && date <= dateSolClient.endDate) {
        include = true;
      }

      return include;
    });
  }

  if (dateConfirmed?.startDate && dateConfirmed?.endDate) {
    filteredData = filteredData.filter((row: SalesOrderSummaryItem) => {
      let include = false;

      const date = brDateFormatToDate(row.dtConfirmedStock);

      if (date.getTime() >= dateConfirmed.startDate && date <= dateConfirmed.endDate) {
        include = true;
      }

      return include;
    });
  }

  if (confirmedFilters?.ov?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.ov.forEach(filterValue => {
        if (row.ov.toString().includes(filterValue.trim())) {
          include = true;
        }
      });
      return include;
    });
  }

  if (confirmedFilters?.material?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.material.forEach(filterValue => {
        if (row.material.toString().includes(filterValue.trim())) {
          include = true;
        }
      });

      return include;
    });
  }

  if (confirmedFilters?.descMaterial?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.descMaterial.forEach(filterValue => {
        if (
          row.descMaterial
            .toString()
            .toUpperCase()
            .includes(filterValue.trim().toUpperCase())
        ) {
          include = true;
        }
      });

      return include;
    });
  }

  if (confirmedFilters?.codMaterialClient?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.codMaterialClient.forEach(filterValue => {
        if (
          row.codMaterialClient
            .toString()
            .toUpperCase()
            .includes(filterValue.trim().toUpperCase())
        ) {
          include = true;
        }
      });

      return include;
    });
  }

  if (confirmedFilters?.plantName?.length) {
    filteredData = filteredData.filter(row => {
      let include = false;

      confirmedFilters.plantName.forEach(filterValue => {
        if (
          row.plantName
            .toString()
            .toUpperCase()
            .includes(filterValue.trim().toUpperCase())
        ) {
          include = true;
        }
      });

      return include;
    });
  }

  ORDER_PAGE_RANGE_FILTER_FIELDS.forEach(key => {
    filteredData = rangeFilterField(filteredData, confirmedFilters, key);
  });

  return filteredData;
};

export const rangeFilterField = (
  filteredData: SalesOrderSummaryItem[],
  confirmedFilters: IOrderFilterModel,
  key: string,
): SalesOrderSummaryItem[] => {
  if (
    confirmedFilters[key]?.minVal > confirmedFilters[key]?.minLimit ||
    confirmedFilters[key]?.maxVal < confirmedFilters[key]?.maxLimit
  ) {
    filteredData = filteredData.filter((row: SalesOrderSummaryItem) => {
      let include = false;

      if (row[key] > confirmedFilters[key]?.minVal && row[key] < confirmedFilters[key]?.maxVal) {
        include = true;
      }
      return include;
    });
  }

  return filteredData;
};
