import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { AnnouncementModalDatasource } from '@app/data/http/announcement-modal-datasource';
import { IPatchAnnouncementParams } from '@app/data/http/announcement.dto';
import { AnnouncementModalEvents } from '@app/modules/components/announcement-modal/announcement-modal.analytics';
import { LACK_OF_CHARGE_MI_KEY } from '@app/modules/components/announcement-modal/announcement-modal.constants';
import { SLICE_NAME } from '../lack-of-charge-modal.constants';
import { getLackOfChargeModal } from './get-lack-of-charge.thunk';

export const patchLackOfChargeModal = createAsyncThunk(
  `${SLICE_NAME}/patchLackOfChargeModal`,
  async (announcementParams: IPatchAnnouncementParams, thunkAPI) => {
    return AnnouncementModalDatasource.patchAnnouncement(announcementParams)
      .then(resp => {
        thunkAPI.dispatch(getLackOfChargeModal(LACK_OF_CHARGE_MI_KEY));
        return resp;
      })
      .catch(err => {
        LogAnalytics.error({
          tipo: AnnouncementModalEvents.PatchLackOfChargeAnnouncementeError,
          message: err.response.status,
        });
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addPatchLackOfChargeReducers = builder => {
  builder.addCase(patchLackOfChargeModal.pending, state => {
    state.loading = true;
  });
  builder.addCase(patchLackOfChargeModal.fulfilled, (state, _) => {
    state.openedConfigModal = false;
    state.loading = false;
  });
  builder.addCase(patchLackOfChargeModal.rejected, state => {
    state.loading = false;
  });
};
