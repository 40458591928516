import { addMonths } from 'date-fns';
import React, { useRef, useState } from 'react';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { getMounthSum } from '../../utils';
import { CustomCalendarWrapper } from './custom-calendar.style';
import { CustomCalendarDays } from './partials/custom-calendar-days.component';
import { CustomCalendarHeader } from './partials/custom-calendar-header.component';
import { CustomCalendarRulesMessage } from './partials/custom-calendar-rules-message.component';
import { CustomCalendarWeekDays } from './partials/custom-calendar-week-days.component';

interface CustomCalendarProps {
  selectedDate: Date;
  setSelectedDate: (value: Date) => void;
  setShowCalendar: (value: boolean) => void;
  openBottom: boolean;
}

export const CustomCalendar: React.FC<CustomCalendarProps> = ({
  selectedDate,
  setSelectedDate,
  setShowCalendar,
  openBottom,
}) => {
  const today = new Date();
  const [activeDate, setActiveDate] = useState(addMonths(today, getMounthSum(today)));

  const ref = useRef(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current) {
      const isOutsideClick = ref && !ref.current.contains(event.target);

      if (isOutsideClick) {
        setShowCalendar(false);
      }
    }
  };

  useEventListener('click', handleClickOutside);

  return (
    <CustomCalendarWrapper openBottom={openBottom} ref={ref}>
      <FlexColumn pl={Spacing.Small} pr={Spacing.Small} pt pb={Spacing.Small} hAlign='center'>
        <CustomCalendarHeader activeDate={activeDate} setActiveDate={setActiveDate} />
        <FlexColumn pt={Spacing.Small} pb={Spacing.Small}>
          <CustomCalendarWeekDays activeDate={activeDate} />
          <CustomCalendarDays activeDate={activeDate} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </FlexColumn>
        <CustomCalendarRulesMessage />
      </FlexColumn>
    </CustomCalendarWrapper>
  );
};
