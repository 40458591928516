import React from 'react';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { ButtonWrapper } from './search-button.style';

interface SearchButtonProps {
  handleSearch: () => void;
  icon?: string;
  text?: string;
  size?: 'small' | 'regular' | 'regularEqualSides';
  disabled?: boolean;
  loading?;
}

export const SearchButton: React.FC<SearchButtonProps> = ({
  handleSearch,
  icon = 'search',
  text = 'Consultar',
  size = 'small',
  disabled = false,
  loading = false,
}) => {
  return (
    <ButtonWrapper>
      <Button
        loading={loading}
        disabled={disabled}
        size={size}
        kind='primary'
        onClick={(e: Event) => {
          e.stopPropagation();
          handleSearch();
        }}
      >
        <FlexRow mr={Spacing.Small}>
          <FaIcon.Custom icon={icon} size='1x' />
        </FlexRow>
        {text}
      </Button>
    </ButtonWrapper>
  );
};
