export interface ConfigColumnsModalStrings {
  title: string;
  fictionalExample: string;
  descriptionColumn: string;
  descriptionRow: string;
  saveButton: string;
  confirmButton: string;
  cancelButton: string;
  validateRequiredFieldsMessage: string;
  validateUniquefFieldsMessage: string;
  columnsTitle: string;
  columns: {
    materialGerdau: string;
    materialClient: string;
    amount: string;
    unityAmount: string;
    dateWished: string;
    orderNumber: string;
    itemIndentifier: string;
  };
}

export interface IConfigColumnsModalLanguages {
  pt: ConfigColumnsModalStrings;
  es: ConfigColumnsModalStrings;
  in: ConfigColumnsModalStrings;
}

const pt: ConfigColumnsModalStrings = {
  title: 'Configurar planilha',
  fictionalExample: 'Exemplo fictício de preenchimento de colunas:',
  descriptionColumn: 'Informe em qual coluna da sua planilha está cada uma das informações abaixo:',
  descriptionRow: 'Em qual linha da planilha começam as informações dos itens?',
  saveButton: 'Salvar',
  confirmButton: 'Confirmar',
  cancelButton: 'Cancelar',
  validateRequiredFieldsMessage: 'Por favor, preencha todos os campos!',
  validateUniquefFieldsMessage: 'Não é possível repetir colunas!',
  columnsTitle: 'Colunas',
  columns: {
    materialGerdau: 'Cód Material Gerdau',
    materialClient: 'Cód Material Cliente',
    amount: 'Quantidade',
    unityAmount: 'Unidade de medida',
    dateWished: 'Data desejada',
    orderNumber: 'Nº do pedido',
    itemIndentifier: 'Item do pedido (opcional)',
  },
};

export const Strings = {
  pt,
  es: pt,
  in: pt,
};
