import styled from 'styled-components';
import { Breakpoint } from '@atomic/obj.constants';

export const JominyContentWrapperStyled = styled.div`
  max-height: 430px;
  overflow-y: scroll;
  @media all and (max-height: ${Breakpoint.md}em) {
    height: auto;
    max-height: 280px;
  }
  & > div {
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const JominyErrorStyled = styled.div`
  padding-top: 15px;
`;
