import styled from 'styled-components';

interface TrailingAccordionCollapsibleProps {
  expanded: boolean;
  contentHeight: number;
}

export const TrailingAccordionCollapsibleStyled = styled.div`
  max-height: ${(props: TrailingAccordionCollapsibleProps) => (props.expanded ? `${props.contentHeight}px` : '0px')};
  -moz-transition: max-height 0.3s ease-in-out;
  -ms-transition: max-height 0.3s ease-in-out;
  -o-transition: max-height 0.3s ease-in-out;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
`;
