import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { setOpenMaterials, setSelectedMaterials } from '@app/modules/order-input/store/order-input.store';
import { Label } from '@atomic/atm.typography';
import { FontSize } from '@atomic/obj.constants';
import { OrderInputStrings } from '../../order-input.string';
import { MaterialFormWrapper } from './material-form.style';
import { MaterialFormSearch } from './partials/material-form-search/matrial-form-search';
import { SearchByField } from './partials/search-by-field/search-by-field';

const strings = OrderInputStrings.pt.selectItens.stepTwo;

export const MaterialForm: React.FC = () => {
  const { searchBy, selectedMaterials, materialOptions } = useSelector((state: RootState) => state.orderInput);

  const dispatch = useDispatch();

  const onSelectItem = (materialOption: Material) => {
    const material: Material = materialOptions.find(m => m.materialName === materialOption.materialName);

    const materialSelected: Material = {
      tempId: new Date().getTime().toString(),
      orderItem: '',
      messages: [],
      unity: null,
      amount: null,
      date: null,
      formatDescription: searchBy,
      ...material,
    };

    dispatch(setSelectedMaterials([...selectedMaterials, materialSelected]));
    dispatch(setOpenMaterials(false));
  };

  return (
    <MaterialFormWrapper data-testid='order-input-material-form'>
      <Label size={FontSize.Medium}>{strings.title}</Label>
      <SearchByField />
      <MaterialFormSearch onSelectItem={onSelectItem} />
    </MaterialFormWrapper>
  );
};
