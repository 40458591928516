import * as React from 'react';
import { HistoryDataProps } from '@app/models/crm.model';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { CrmStrings } from '../../crmComplain.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

interface CrmHistoryProps {
  data: HistoryDataProps;
  loading: boolean;
}

export const CrmHistory = (props: CrmHistoryProps) => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.HISTORY} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>

      <CrmContentInfosWrapper>
        {props?.data && (
          <>
            <Separator small />
            {props.data.infos.length > 0 && (
              <Table collapse>
                <TR bordered>
                  <TD color={Color.Secondary} textAlign='left'>
                    <Body bold={600}>{strings.tables.cols.action}</Body>
                  </TD>
                  <TD color={Color.Secondary} textAlign='left'>
                    <Body bold={600}>{strings.tables.cols.newValue}</Body>
                  </TD>
                  <TD color={Color.Secondary} textAlign='left'>
                    <Body bold={600}>{strings.tables.cols.oldValue}</Body>
                  </TD>
                  <TD color={Color.Secondary} textAlign='left'>
                    <Body bold={600}>{strings.tables.cols.updateBy}</Body>
                  </TD>
                  <TD color={Color.Secondary} textAlign='left'>
                    <Body bold={600}>{strings.tables.cols.updatedAt}</Body>
                  </TD>
                </TR>
                {props.data.infos.map((item, index) => (
                  <TR bordered key={`t-${index}`}>
                    <TD textAlign='left'>
                      <Body>{item.value.action}</Body>
                    </TD>
                    <TD textAlign='left'>
                      <Body>{item.value.newValue}</Body>
                    </TD>
                    <TD textAlign='left'>
                      <Body>{item.value.oldValue}</Body>
                    </TD>
                    <TD textAlign='left'>
                      <Body>{item.value.updateBy}</Body>
                    </TD>
                    <TD textAlign='left'>
                      <Body>{item.value.updatedAt}</Body>
                    </TD>
                  </TR>
                ))}
              </Table>
            )}
          </>
        )}
      </CrmContentInfosWrapper>

      <Separator mb={50} />
    </CrmContentWrapper>
  );
};
