import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { IsaHttpRequestBuilder } from '@app/data/http';
import { GetAnalysisParams } from '@app/data/http/quote-params.dto';
import { FullAnalysisResponseNew } from '@app/data/http/quote.dto';
//import { handleUnauthorized } from '@app/utils/http-utils';

export async function getNewTechnicalAnalysis(params?: GetAnalysisParams): Promise<FullAnalysisResponseNew> {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  // const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.id}`;

  const url = `https://cxp-quote-api-cxp-dev.apps2.rosa-gerdau.bxnu.p1.openshiftapps.com/v3/api/analysis/${params.id}/general`;

  try {
    const { data, status } = await axios.get(url, { headers: defaultHeaders });
    if (status === 200) {
      return data.data;
    } else {
      return null;
    }
  } catch (error) {
    //handleUnauthorized(error);
    return null;
  }
}

export function useNewTechnicalAnalysis(params: GetAnalysisParams, onSuccess?, onError?) {
  return useQuery(['getNewTechnicalAnalysis', params], () => getNewTechnicalAnalysis(params), {
    onSuccess,
    onError,
    select: data => {
      return data;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });
}
