import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Divisor } from '@atomic/atm.divisor';
import { Body } from '@atomic/atm.typography';
import { FlexRow, Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { Strings, IOrderTabOpenStrings } from '../../../order-tab-open.string';
import {
  OrderStatusPopoverArrowStyled,
  OrderStatusPopoverBoxStyled,
  OrderStatusPopoverDotStyled,
  OrderStatusPopoverFooterStyled,
  OrderStatusPopoverRowStyled,
  OrderStatusPopoverWrapper,
} from './order-status-popover.style';

interface OrderStatusPopoverProps {
  data: any;
  x: number;
  y: number;
}

export const OrderStatusPopover: React.FC<OrderStatusPopoverProps> = props => {
  const ref = useRef(null);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabOpenStrings = Strings[userInfo?.language];

  if (!props.data) {
    return null;
  }

  return (
    <OrderStatusPopoverWrapper ref={ref} x={props.x} y={props.y}>
      <OrderStatusPopoverBoxStyled>
        <OrderStatusPopoverRowStyled>
          <FlexRow vAlign='center'>
            <OrderStatusPopoverDotStyled color='#47A947' />
            <Hbox.Separator small />
            <Body bold='bold'>{strings.tooltip.billed}</Body>
          </FlexRow>
          <FlexRow>
            <Body>{`${props.data.qtyTotalBilled.toString().replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverRowStyled>
        <OrderStatusPopoverRowStyled>
          <FlexRow vAlign='center'>
            <OrderStatusPopoverDotStyled color='#FECB2F' />
            <Hbox.Separator small />
            <Body bold='bold'>{strings.tooltip.stock}</Body>
          </FlexRow>
          <FlexRow>
            <Body>{`${props.data.qtyStock.toString().replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverRowStyled>
        <OrderStatusPopoverRowStyled>
          <FlexRow vAlign='center'>
            <OrderStatusPopoverDotStyled color='#3399CC' />
            <Hbox.Separator small />
            <Body bold='bold'>{strings.tooltip.delivery}</Body>
          </FlexRow>
          <FlexRow>
            <Body>{`${props.data.qtyDelivery.toString().replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverRowStyled>
        <OrderStatusPopoverRowStyled>
          <FlexRow vAlign='center'>
            <OrderStatusPopoverDotStyled color='#A480A3' />
            <Hbox.Separator small />
            <Body bold='bold'>{strings.tooltip.production}</Body>
          </FlexRow>
          <FlexRow>
            <Body>{`${props.data.qtyTotalProduction.toString().replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverRowStyled>
        <OrderStatusPopoverRowStyled>
          <FlexRow vAlign='center'>
            <OrderStatusPopoverDotStyled color='#BFC9D0' />
            <Hbox.Separator small />
            <Body bold='bold'>{strings.tooltip.notAllocated}</Body>
          </FlexRow>
          <FlexRow>
            <Body>{`${props.data.qtyNotAllocated.toString().replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverRowStyled>
        <VSeparator />
        <Divisor />
        <OrderStatusPopoverFooterStyled>
          <FlexRow vAlign='center'>
            <Body>{strings.tooltip.requested}</Body>
            <Body bold='bold'>{`${Math.round(props.data.qtdSolClient)
              .toString()
              .replace('.', ',')}t`}</Body>
          </FlexRow>
          <FlexRow vAlign='center'>
            <Body>{strings.tooltip.confirmed}</Body>
            <Body bold='bold'>{`${Math.round(props.data.qtdConf)
              .toString()
              .replace('.', ',')}t`}</Body>
          </FlexRow>
        </OrderStatusPopoverFooterStyled>
        <OrderStatusPopoverArrowStyled xAxis={100} alignBottom={true} />
      </OrderStatusPopoverBoxStyled>
    </OrderStatusPopoverWrapper>
  );
};
