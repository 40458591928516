export enum FeedbackAnalytics {
  TechnicalAnalysisChangeStatus = 'AlterarStatusAnaliseTecnica',
  WalletMI = 'CarteiraDePedidosInterna',
  WalletME = 'CarteiraDePedidosExterna',
  FinanceMI = 'FinancasInterna',
  FinanceME = 'FinancasExterna',
  TruckTracking = 'RastreioDeCaminhao',
  OrderUpdate = 'AlteracaoDeCarteira',
  ShippingRequest = 'SolicitacaoDeRemessa',
  NotificationPanel = 'NotificationPanel',
  Dashboard = 'PainelGestao',
  OrderInput = 'ImplantacaoDePedido',
}
