import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { Tab } from '@app/modules/components/tabs/tabs.component';
import { Frame } from '@atomic/atm.frame';
import { FlexRow } from '@atomic/obj.box';
import { setOrderTabIndex } from '../../order.store';
import { IOrderStrings } from '../../order.strings.interface';
import { OrdersStrings } from '../../orders.string';
import { OrderTabsLastUpdate } from '../order-tabs-last-update.component';
import { orderInternationalTabEvents } from './order-tabs-ME.constants';

interface OrderTabsHeaderMEProps {
  lastUpdate?: string;
  tabIndex: number;
}

export const OrderTabsHeaderME: React.FC<OrderTabsHeaderMEProps> = ({ lastUpdate, tabIndex }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { isMaritime } = useSelector((state: RootState) => state.customerPersist);
  const strings: IOrderStrings = OrdersStrings[userInfo?.language];
  const dispatch = useDispatch();

  const handleTabChange = (index: number) => {
    LogAnalytics.pageView(orderInternationalTabEvents[index]);
    dispatch(setOrderTabIndex(index));
  };

  return (
    <Frame>
      <Grid fluid>
        <Row>
          <Col xs={7}>
            {isMaritime ? (
              <FlexRow>
                <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={0}>
                  {strings.tabsTitle.MEMaritime.Opened}
                </Tab>
                <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={1}>
                  {strings.tabsTitle.MEMaritime.Harbor}
                </Tab>
                <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={2}>
                  {strings.tabsTitle.MEMaritime.Shipped}
                </Tab>
              </FlexRow>
            ) : (
              <FlexRow>
                <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={0}>
                  {strings.tabsTitle.ME.internationalOpened}
                </Tab>
                <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={1}>
                  {strings.tabsTitle.ME.frontier}
                </Tab>
                <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={2}>
                  {strings.tabsTitle.ME.shipped}
                </Tab>
                <Tab handleClick={handleTabChange} currentIndex={tabIndex} index={3}>
                  {strings.tabsTitle.ME.documents}
                </Tab>
              </FlexRow>
            )}
          </Col>
          <Col xs={5}>
            <FlexRow hAlign='flex-end' fullHeight>
              {tabIndex !== 3 && <OrderTabsLastUpdate lastUpdate={lastUpdate} />}
            </FlexRow>
          </Col>
        </Row>
      </Grid>
    </Frame>
  );
};
