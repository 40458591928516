export interface IAnnouncementParams {
  key?: string;
  section?: string;
}

export interface IAnnouncementDto {
  data: IAnnouncementData;
  errors: any[];
  success: boolean;
}

export interface IAnnouncementData {
  announcements: IAnnouncementItem[];
  runningAnnouncements: string[];
}

export interface IAnnouncementItem {
  _id: string;
  title: string;
  message: string;
  section: string;
  key: string;
  url: string[];
  expirationDate: string;
}

export const mapAnnouncementsResponse = (announcements: IAnnouncementDto): IAnnouncementData => {
  return announcements.data;
};

export interface IPutAnnouncementParams {
  key: string;
  email: string;
}

export interface IPatchAnnouncementParams {
  key: string;
  additionalDays: number;
}
