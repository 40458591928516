import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderDataSource } from '@app/data/http/order-MI.datasource';
import { DeliveryStatus, HanaLanguage } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { SLICE_NAME } from '../order-tab-open.constants';
import { setHasLackOfCharge } from '../partials/lack-of-charge-modal/store/lack-of-charge-modal.store';

export const performRequest = createAsyncThunk(`${SLICE_NAME}/performRequest`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const promiseRequestTime = new Date().getTime();
  const customerIds = customerIdsJoinedSelector(state);

  return await OrderDataSource.getOpenOrderSummary({
    customerIds,
    language: HanaLanguage[state.auth?.userInfo?.language],
  })
    .then(data => {
      const hasLackOfCharge = data.some(order => order.deliveryStatus === DeliveryStatus.LackOfCharge);
      thunkAPI.dispatch(setHasLackOfCharge(hasLackOfCharge));
      return { promiseRequestTime, data };
    })
    .catch(err => {
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});
