import * as React from 'react';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { DocumentDataSource, OrderInputDatasource } from '@app/data/http';
import { IDocumentCQParams, IDocumentMECQParams } from '@app/data/http/document.dto';
import { GetNFShippingRequestParams, GetNFxmlRequestParams, GetWaybillRequestParams } from '@app/models/document.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';

export const useNFDocumentQuery = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  const fetch = async (docNumber: string, customerIds: string) => {
    setLoading(true);
    try {
      const res = await DocumentDataSource.getNFPdf(docNumber, customerIds);
      setData(res);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { fetch, loading, error, data };
};
export const useNFxmlDocumentRequest = () => {
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  return useLazyRequest<GetNFxmlRequestParams, File>(
    DocumentDataSource.getNFxml,
    () => null,
    () => {
      show('warning', 'XML não encontrado!');
    },
  );
};
export const useNFShippingDocumentRequest = () => {
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  return useLazyRequest<GetNFShippingRequestParams, File>(
    DocumentDataSource.getNFshipping,
    () => null,
    () => {
      show('warning', 'NF de remessa não encontrado!');
    },
  );
};

export const useCQDocumentQuery = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  const fetch = async (params: IDocumentCQParams) => {
    setLoading(true);
    try {
      const res = await DocumentDataSource.getCQPdf(params);
      setData(res);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { fetch, loading, error, data };
};

export const useMECQDocumentQuery = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  const fetch = async (params: IDocumentMECQParams) => {
    setLoading(true);
    try {
      const res = await DocumentDataSource.getMECQPdf(params);
      setData(res);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { fetch, loading, error, data };
};

export const useWaybillDocumentRequest = () => {
  return useLazyRequest<GetWaybillRequestParams, File>(DocumentDataSource.getWaybill);
};

export const useCatalogRequest = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  const fetch = async (clientBP: string) => {
    setLoading(true);
    try {
      const res = await OrderInputDatasource.getCatalog({ customerIds: clientBP });
      setData(res);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { fetch, loading, error, data };
};
