import React from 'react';
import { FlexColumn } from '@atomic/obj.box';
import { Grid } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { CustomerSearchModalBodyAdmin } from './customer-search-modal-body-admin/customer-search-modal-body-admin.component';
import { CustomerSearchModalFooterAdmin } from './customer-search-modal-footer-admin/customer-search-modal-footer-admin.component';
import { CustomerSearchModalHeaderAdmin } from './customer-search-modal-header-admin/customer-search-modal-header-admin.component';

interface CustomerSearchModalComponentProps {
  opened: boolean;
  onClose: () => void;
}

export const CustomerSearchModalAdminComponent: React.FC<CustomerSearchModalComponentProps> = ({ opened, onClose }) => {
  return (
    <Modal overflow='hidden' customWidth='56%' opened={opened}>
      <Grid fluid>
        <FlexColumn>
          <CustomerSearchModalHeaderAdmin />
          <CustomerSearchModalBodyAdmin />
          <CustomerSearchModalFooterAdmin onClose={onClose} />
        </FlexColumn>
      </Grid>
    </Modal>
  );
};
