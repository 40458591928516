import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { setOpenBPsModal } from '@app/providers/navigation/navigation.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
import { ICustomerSearchStrings, Strings } from '../customer-search.string';
import { CustomerSelectH1Styled, CustomerSelectTextStyled } from './customer-search-admin.style';
import { CustomerSearchModalAdmin } from './customer-search-modal-admin/customer-search-modal-admin.container';

export const CustomerSearchAdminComponent: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ICustomerSearchStrings = Strings[userInfo.language];
  const { clientBpsSelected, clientBps } = useSelector((state: RootState) => state.customerPersist);
  const dispatch = useDispatch();

  return (
    <>
      <CustomerSelectH1Styled data-testid='customer-select' onClick={() => dispatch(setOpenBPsModal(true))}>
        <CustomerSelectTextStyled>{strings.label(clientBpsSelected.length, clientBps.length)}</CustomerSelectTextStyled>
        <FaIcon.Search size='sm' color={Color.GrayDark} />
      </CustomerSelectH1Styled>
      <CustomerSearchModalAdmin />
    </>
  );
};
