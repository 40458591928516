import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route } from '@app/core/route';
import { AppPath } from '../app/route-constants';
import { hasOrderInputTabAccessSelector } from '../auth/store/auth.selectores';
import { OrderInputPage } from './order-input.page';

export const OrderInputRouter: React.FC<{}> = () => {
  const hasOrderInputTabAccess = useSelector(hasOrderInputTabAccessSelector);

  return (
    <Route
      exact
      path='./'
      component={OrderInputPage}
      authorized={hasOrderInputTabAccess}
      redirectPath={AppPath.FINANCE}
    />
  );
};
