import React from 'react';
import { useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { SalesOrderItem } from '@app/models';
import { setShowTruckTracking, setTruckTrackingInfo } from '@app/modules/truck-tracking/store/truck-tracking.store';
import { TruckTrackingInfo } from '@app/modules/truck-tracking/truck-tracking.interfaces';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TruckTrackingRequestEvents } from '../../../../order.analytics';

interface TruckTrackingCellComponentProps {
  data: SalesOrderItem;
}

export const TruckTrackingCellComponent: React.FC<TruckTrackingCellComponentProps> = props => {
  const dispatch = useDispatch();

  const data: TruckTrackingInfo = {
    transportDocument: props.data?.transportDocument,
    clientNumber: props.data?.clientNumber,
    nameClient: props.data?.nameClient,
    material: props.data?.material,
    codMaterialClient: props.data?.codMaterialClient,
    descMaterial: props.data?.descMaterial,
    brand: props.data?.brand,
    invoice: props.data?.invoice?.toString(),
    ocClient: props.data?.ocClient,
    invoiceEmissionDate: props.data?.invoiceEmissionDate,
    plant: props.data?.plant,
    concatenatedBillingDocument: props.data?.concatenatedBillingDocument,
  };

  const handleCellClick = () => {
    LogAnalytics.click({ tipo: TruckTrackingRequestEvents.TruckTrackingClick });
    dispatch(setTruckTrackingInfo(data));
    dispatch(setShowTruckTracking(true));
  };

  if (props.data) {
    return <FaIcon.Truck clickable='true' onClick={handleCellClick} />;
  }
  return null;
};
