import { AnalysisCreatedBy, AssetData, AssetsPermissions } from '@app/models/quote.model';
import { dateParser } from '@app/utils/date-parser';

export interface GetAssetsResponse {
  client_visible: boolean;
  key: string;
  name: string;
  type: string;
  creation: {
    origin: string;
    createdBy: AnalysisCreatedBy;
    status_stage: {
      key: string;
      label: string;
    };
  };
  date: string;
  url: string;
  permissions: AssetsPermissions;
}

export const mapGetAssets = (response: GetAssetsResponse[]): AssetData[] => {
  return response.map(asset => mapAsset(asset));
};

export const mapAsset = (asset: GetAssetsResponse): AssetData => {
  return {
    isClientVisible: asset.client_visible !== false,
    key: asset.key,
    filename: asset.name,
    type: asset.type,
    provider: asset.creation.origin,
    user: asset.creation.createdBy,
    creationStatus: {
      key: asset.creation.status_stage.key,
      label: asset.creation.status_stage.label,
    },
    url: asset.url,
    date: dateParser({ unformattedDate: asset.date }),
    permissions: asset.permissions,
  };
};

export interface GetLogResponse {
  id: number;
  user: {
    name: string;
    email: string;
  };
  local: string;
  log: string;
  date: string;
}
