import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';

export const NpsModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Spacing.Medium};
  margin-bottom: ${Spacing.Medium};
  align-items: center;
  height: 220px;
`;
