import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';

export const makeVisibleToClient = async (params?: any) => {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const payload = {
    ids: params.ids,
    clientVisible: params.clientVisible,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}`;

  const { data } = await axios.put(url, payload, { headers: defaultHeaders });

  return data;
};

export const useMakeVisibleToClient = (onSuccess?, onError?) => {
  return useMutation(makeVisibleToClient, {
    onSuccess,
    onError,
  });
};
