import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  MarketOptions,
  salesOrgsME,
  salesOrgsMI,
} from '@app/modules/components/customer-search/customer-search.constants';
import { IOption } from '@app/modules/components/customer-search/customer-search.interfaces';
import { addSalesOrgsSearch } from '@app/modules/components/customer-search/store/customer-search.store';
import { Button } from '@atomic/atm.button';
import { Label } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { ICustomerSearchModalHeaderAdminStrings, Strings } from '../../../customer-search-modal-header-admin.string';
import { OptionItens, OptionsWrapper } from '../search-field-options.style';

interface SearchFieldOptionsSalesOrgsProps {
  setShowOptions: (value: boolean) => void;
}

export const SearchFieldOptionsSalesOrgs: React.FC<SearchFieldOptionsSalesOrgsProps> = ({ setShowOptions }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { salesOrgsSearch, filterOptionSearch } = useSelector((state: RootState) => state.customerSearch);
  const dispatch = useDispatch();
  const strings: ICustomerSearchModalHeaderAdminStrings = Strings[userInfo.language];
  const ref = useRef(null);

  const handleClick = option => {
    dispatch(addSalesOrgsSearch(option));
    setShowOptions(false);
  };

  const marketSalesOrgs = filterOptionSearch.market === MarketOptions.MI ? salesOrgsMI : salesOrgsME;
  const unselecteds = marketSalesOrgs.filter(saleOrg => !salesOrgsSearch.includes(saleOrg));

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current) {
      const isOutsideClick = ref && !ref.current.contains(event.target);

      if (isOutsideClick) {
        setShowOptions(false);
      }
    }
  };

  useEventListener('click', handleClickOutside);

  return (
    <OptionsWrapper ref={ref}>
      {unselecteds.map((option: IOption, index: number) => (
        <OptionItens onClick={() => handleClick(option)} key={index} mt={Spacing.Small} mb={Spacing.Small}>
          <Label>{option.name}</Label>
          <Button kind='link'>{strings.addfFilterButton}</Button>
        </OptionItens>
      ))}
    </OptionsWrapper>
  );
};
