import React from 'react';
import { selectedItemTableColumnsDefinitions } from '@app/modules/shipping-request/shipping-request.constants';
import { IShippingRequestColumns } from '@app/modules/shipping-request/shipping-request.interfaces';
import { Body } from '@atomic/atm.typography';
import { TD, TR } from '@atomic/mol.table';
import { Color, FontWeight } from '@atomic/obj.constants';

export const ShippingRequestSelectedItemTableHeader: React.FC = () => {
  return (
    <TR>
      {selectedItemTableColumnsDefinitions.map((column: IShippingRequestColumns, index: number) => {
        const tdColor = column.headerColor || Color.GrayLight;
        const textColor = column.textColor ? column.textColor : Color.Black;

        return (
          <TD key={`selected-item-${index}`} color={tdColor} width={column.width}>
            <Body align='center' centralized color={textColor} bold={FontWeight.Bold}>
              {column.title}
            </Body>
          </TD>
        );
      })}
    </TR>
  );
};
