import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { AnnouncementModalDatasource } from '@app/data/http/announcement-modal-datasource';
import { IAnnouncementItem, IPutAnnouncementParams } from '@app/data/http/announcement.dto';
import { UserInfo } from '@app/models';
import { AnnouncementModalEvents } from '@app/modules/components/announcement-modal/announcement-modal.analytics';
import { SLICE_NAME } from '../navigation.constants';
import { INavigationState } from '../navigation.interface';

export const putOnboarding = createAsyncThunk(
  `${SLICE_NAME}/putOnboarding`,
  async (onboardingKey: string, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const user: UserInfo = state.auth.userInfo;
    const onboarding: IAnnouncementItem[] = state.navigation?.onboarding?.announcements;
    const onboardingItem = onboarding.find(item => item.key === onboardingKey);
    const params: IPutAnnouncementParams = { key: onboardingItem.key, email: user.email };

    return AnnouncementModalDatasource.putAnnouncement(params)
      .then(resp => resp)
      .catch(err => {
        LogAnalytics.error({
          tipo: AnnouncementModalEvents.PutAnnouncementError,
          message: err.response.status,
        });
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addPutOnboardingReducers = builder => {
  builder.addCase(putOnboarding.pending, state => {
    state.loading = true;
  });
  builder.addCase(putOnboarding.fulfilled, (state: INavigationState, _) => {
    state.loading = false;
  });
  builder.addCase(putOnboarding.rejected, state => {
    state.loading = false;
  });
};
