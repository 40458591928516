import { addGetCriticalItemRulesReducers } from './get-critical-item-rule.thunk';
import { addGetCriticalItemsReducers } from './get-critical-item.thunk';
import { addPostCriticalItemsReducers } from './post-critical-items.thunk';

export * from './post-critical-items.thunk';

export const addThunkReducers = builder => {
  addGetCriticalItemRulesReducers(builder);
  addGetCriticalItemsReducers(builder);
  addPostCriticalItemsReducers(builder);
};
