import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { CSATUserModule } from '@app/data/http/admin-settings.datasource';
import { addGetCsatUserReducers } from './csat-get.thunk';
import { addPostAnswerCsatUserReducers } from './csat-post.thunk';
import { SLICE_NAME } from './csat.constants';
import { initialState } from './csat.initial';
import { ICsatState } from './csat.interfaces';

const reducers = {
  resetState: (state: ICsatState) => {
    state.loading = false;
    state.loadingPost = false;
    state.csatUser = [];
  },
  setCsatUser: (state: ICsatState, { payload }: PayloadAction<CSATUserModule[]>) => {
    state.csatUser = payload;
  },
  setAnswered: (state: ICsatState, { payload }: PayloadAction<string>) => {
    const index = state.csatUser.findIndex(item => item.functionality === payload);

    if (index !== null || index !== undefined) {
      state.csatUser[index].answer = true;
    }
  },
};

export const csatSlice = createSlice<ICsatState, SliceCaseReducers<ICsatState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: builder => {
    addGetCsatUserReducers(builder);
    addPostAnswerCsatUserReducers(builder);
  },
});

export const { setCsatUser, resetState, setAnswered } = csatSlice.actions;

export const csatReducer = csatSlice.reducer;
