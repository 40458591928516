import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { setOpenReceiverModalIndex, updateReceiver } from '@app/modules/shipping-request/store/shipping-request.store';
import { Button } from '@atomic/atm.button';
import { FlexRow } from '@atomic/obj.box';

interface IShippingRequestReceiverModalFooterProps {
  selectedReceiver: string;
}

export const ShippingRequestReceiverModalFooter: React.FC<IShippingRequestReceiverModalFooterProps> = ({
  selectedReceiver,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const dispatch = useDispatch();

  return (
    <FlexRow pb pr pt hAlign='flex-end'>
      <Button onClick={() => dispatch(updateReceiver(selectedReceiver))}>
        <FlexRow>{strings.receiverModal.confirmButton}</FlexRow>
      </Button>
      <Button kind='secondary' onClick={() => dispatch(setOpenReceiverModalIndex(null))}>
        {strings.receiverModal.cancelButton}
      </Button>
    </FlexRow>
  );
};
