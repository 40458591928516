import {
  ColDef,
  ExcelStyle,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
  GridApi,
  RowSelectedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  FrontierSalesOrderItem,
  InStockOrderItem,
  InternationalSalesOrderItemSummary,
  SalesOrderItem,
  SalesOrdersMaritimeHarborItem,
  SalesOrdersMaritimeOpenItem,
  SalesOrdersMaritimeShippedItem,
  SalesOrderSummaryItem,
  ShippedSalesOrderItem,
} from '@app/models';
import { SimilarMaterialsItem } from '@app/models/analysis.model';
import { OrdersStrings } from '@app/modules/order/orders.string';
import { dateFormatter, dotFormatter, visibilityDateFormatter } from './ag-grid-column.formatter';

export type RowData =
  | InStockOrderItem
  | SalesOrderItem
  | SalesOrderSummaryItem
  | SimilarMaterialsItem
  | InternationalSalesOrderItemSummary
  | ShippedSalesOrderItem
  | FrontierSalesOrderItem
  | SalesOrdersMaritimeOpenItem
  | SalesOrdersMaritimeShippedItem
  | SalesOrdersMaritimeHarborItem;

export interface AgGridError {
  error?: any;
  message?: string;
}

interface AgGridProps {
  error?: AgGridError;
  onGridReady?: (event: GridReadyEvent) => void;
  rowData: RowData[];
  loading: boolean;
  frameworkComponents?: { [renderer: string]: any };
  onColumnChange?: (e) => void;
  onSortChange?: (e) => void;
  onSelectionChange?: (event: SelectionChangedEvent) => void;
  onFilterChanged?: (event: GridApi) => void;
  columnDef?: ColDef[];
  onRowSelected?(event: RowSelectedEvent): void;
  gridId?: string;
  getRowClass?: (row: AgGridReactProps) => string;
}

enum GroupDefaultExpanded {
  EXPAND_EVERYTHING = -1,
  NONE = 0,
  FIRST_LEVEL = 1,
}

export const AgGrid: React.FC<AgGridProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const gridEvent = React.useRef<GridReadyEvent>(null);
  const Strings = OrdersStrings[userInfo?.language];

  const handleGridReady = (event: GridReadyEvent) => {
    gridEvent.current = event;

    if (props.onGridReady) {
      props.onGridReady(event);
    }
  };

  const handleOnFilterChanged = (gridApi: GridApi) => {
    if (props.onFilterChanged) {
      props.onFilterChanged(gridApi);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (!gridEvent.current) {
        return;
      }
      if (props.loading) {
        gridEvent.current.api.showLoadingOverlay();
      } else if (gridEvent.current.api.getDisplayedRowCount()) {
        gridEvent.current.api.hideOverlay();
      } else {
        gridEvent.current.api.showNoRowsOverlay();
      }
    }, 100);
  }, [props.loading]);

  const isRowSelectable = (rowNode: RowNode) => {
    if (!props.gridId) {
      return rowNode.data?.stockQuantity !== 0;
    }

    return (
      rowNode.data?.stockQuantity !== 0 &&
      rowNode.data.deliveryQuantity + rowNode.data.pickingQuantity < rowNode.data.stockQuantity
    );
  };

  const handleGridMessage = () => {
    let message = '';

    if (props.rowData && !props.rowData.length) {
      message = Strings?.common.agGrid.noRowsToShow;
    }

    if ((props.error && !props.rowData) || (props.error && !props.rowData.length)) {
      message = props.error.message;
    }

    return message;
  };

  const handleCellStyle = () => {
    if (props.columnDef) {
      props.columnDef.forEach(c => {
        if (c.field !== 'checkboxSelection') {
          c.cellStyle = { whiteSpace: 'break-spaces' };
        } else {
          c.pinned = true;
        }
      });
    }
    return props.columnDef;
  };

  const localeText = {
    rowGroupColumnsEmptyMessage: Strings?.common.agGrid.rowGroupColumnsEmptyMessage,
    noRowsToShow: handleGridMessage(),
    loadingOoo: Strings?.common.agGrid.loadingOoo,
    pinColumn: Strings?.common.agGrid.pinColumn,
    pinLeft: Strings?.common.agGrid.pinLeft,
    pinRight: Strings?.common.agGrid.pinRight,
    noPin: Strings?.common.agGrid.noPin,
    autosizeThiscolumn: Strings?.common.agGrid.autosizeThiscolumn,
    autosizeAllColumns: Strings?.common.agGrid.autosizeAllColumns,
    resetColumns: Strings?.common.agGrid.resetColumns,
    groupBy: Strings?.common.agGrid.groupBy,
    expandAll: Strings?.common.agGrid.expandAll,
    collapseAll: Strings?.common.agGrid.collapseAll,
    copy: Strings?.common.agGrid.copy,
    copyWithHeaders: Strings?.common.agGrid.copyWithHeaders,
    paste: Strings?.common.agGrid.paste,
    export: Strings?.common.agGrid.export,
    csvExport: Strings?.common.agGrid.csvExport,
    excelExport: Strings?.common.agGrid.excelExport,
    excelXmlExport: Strings?.common.agGrid.excelXmlExport,
  };

  return (
    <>
      <AgGridReact
        onSelectionChanged={props.onSelectionChange}
        onFilterChanged={e => handleOnFilterChanged(e.api)}
        onRowSelected={props.onRowSelected}
        isRowSelectable={isRowSelectable}
        rowSelection='multiple'
        rowMultiSelectWithClick
        onGridReady={handleGridReady}
        groupDefaultExpanded={GroupDefaultExpanded.EXPAND_EVERYTHING}
        suppressColumnVirtualisation
        suppressRowClickSelection
        suppressAggFuncInHeader
        rowGroupPanelShow='onlyWhenGrouping'
        suppressDragLeaveHidesColumns
        rowData={props.rowData}
        columnDefs={handleCellStyle()}
        defaultColDef={defaultColDef}
        frameworkComponents={props.frameworkComponents}
        components={agGridComponents}
        localeText={localeText}
        onColumnMoved={props.onColumnChange}
        onColumnResized={props.onColumnChange}
        onColumnPinned={props.onColumnChange}
        onSortChanged={props.onSortChange}
        excelStyles={excelStyles}
        tooltipShowDelay={0}
        groupDisplayType={'groupRows'}
        getRowClass={props.getRowClass}
      />
    </>
  );
};

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: 'agTextColumnFilter',
  enableRowGroup: true,
  menuTabs: ['generalMenuTab'],
  floatingFilter: true,
  floatingFilterComponentParams: { suppressFilterButton: true },
};

const agGridComponents = {
  dateRenderer: dateFormatter,
  dateVisibilityRenderer: visibilityDateFormatter,
  dotRender: dotFormatter,
};

const excelStyles: ExcelStyle[] = [
  {
    id: 'stringType',
    dataType: 'String',
  },
];
