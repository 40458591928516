import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { IOrderInputStrings, OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { setFiles } from '@app/modules/order-input/store/order-input.store';
import { FlashMessageTargetName } from '@app/providers';
import { DropzoneStrings } from '@atomic/atm.dropzone/dropzone.string';
import { FileUploaderWrapper } from '../../order-input-upload-file.style';
import { CustomDropZone } from './custom-drop-zone/custom-drop-zone';

export const OrderInputUploadFileDropZone: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];

  const [showDropzoneMessage, setShowDropzoneMessage] = useState(true);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const validateFile = filesArray => {
    let isValid = true;
    let size = 0;

    filesArray.forEach(file => {
      size += file.size;

      if (file.name.toLowerCase().indexOf('.pdf') === -1 && file.name.toLowerCase().indexOf('.msg') === -1) {
        show('alert', strings?.uploadSpreadSheet?.fileFormatAlert);
        isValid = false;
      }
    });

    if (size > 204000000) {
      show('alert', strings?.uploadSpreadSheet?.fileSizeAlert);
      isValid = false;
    }

    return isValid;
  };

  const handleDragginStateChange = isDraggin => {
    if (isDraggin) {
      setShowDropzoneMessage(false);
    } else {
      setShowDropzoneMessage(true);
    }
  };

  const handleChange = async files => {
    const filesArray = Object.values(files);

    if (!validateFile(filesArray)) {
      return null;
    }

    dispatch(setFiles(filesArray));
  };

  return (
    <FileUploaderWrapper>
      <FileUploader
        classes='order-input-file-uploader'
        handleChange={handleChange}
        name='file'
        multiple={true}
        hoverTitle={DropzoneStrings[userInfo.language].uploadClickButton}
        onDraggingStateChange={e => handleDragginStateChange(e)}
      >
        <CustomDropZone showDropzoneMessage={showDropzoneMessage} />
      </FileUploader>
    </FileUploaderWrapper>
  );
};
