import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import {
  setReceiverOptions,
  setSelectedIssuer,
  setSelectedReceiver,
} from '@app/modules/order-input/store/order-input.store';
import { getIssuersThunk } from '@app/modules/order-input/store/thunks/get-issuers.thunk';
import { getReceiverThunk } from '@app/modules/order-input/store/thunks/get-receivers.thunk';
import { SelectField } from '@atomic/atm.select';
import { InputLabel, Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontWeight } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { OrderInputStrings } from '../../../order-input.string';
import { ReceiverFormShimmer } from './receiver-form.shimmer';
import { SelectFieldShimmer } from './select-field-shimmer';

const Strings = OrderInputStrings.pt.selectItens;

export const ReceiverForm: React.FC = () => {
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const { receiverOptions, selectedReceiver, loadingReceivers, issuerOptions, loadingIssuers } = useSelector(
    (state: RootState) => state.orderInput,
  );
  const [localSelectedIssuer, setLocalSelectedIssuer] = useState<string>('');
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (clientBpsSelected) {
      setLocalSelectedIssuer('');
      dispatch(setSelectedReceiver(null));
      dispatch(setReceiverOptions([]));
      dispatch(getIssuersThunk());
    }
  }, [clientBpsSelected]);

  const onValueChange = value => {
    const issuer = issuerOptions.find(bp => bp.customerId === value);

    if (issuer) {
      dispatch(setSelectedIssuer({ value: issuer.customerId, name: issuer.name, city: issuer.city }));
    }

    setLocalSelectedIssuer(value);
    if (localSelectedIssuer) {
      dispatch(getReceiverThunk());
    }
  };

  return (
    <FlexColumn data-testid='order-input-receiver-form'>
      <Label>{Strings.stepOne.title}</Label>
      <LoadingState loading={loadingIssuers} data={!!issuerOptions.length}>
        <LoadingState.Shimmer>
          <ReceiverFormShimmer />
        </LoadingState.Shimmer>
        <FlexRow mb mt pr>
          <FlexColumn mr>
            <InputLabel weight={FontWeight.Normal}>{Strings.stepOne.orderIssuer}</InputLabel>
            <SelectField
              data-testid='order-input-issuer-select'
              value={localSelectedIssuer}
              onValueChange={onValueChange}
              placeholder={Strings.stepOne.orderIssuerPlaceHolder}
            >
              {issuerOptions.map((bp, index) => (
                <option className='option_issuer' key={index} value={bp.customerId}>
                  {bp.customerId} - {bp.name} - {bp.city}
                </option>
              ))}
            </SelectField>
          </FlexColumn>
          <FlexColumn mr>
            <InputLabel weight={FontWeight.Normal}>{Strings.stepOne.orderReceiver}</InputLabel>
            <LoadingState loading={loadingReceivers}>
              <LoadingState.Shimmer>
                <SelectFieldShimmer />
              </LoadingState.Shimmer>
              <SelectField
                data-testid='order-input-receiver-select'
                value={selectedReceiver !== null ? selectedReceiver : ''}
                onValueChange={value => dispatch(setSelectedReceiver(value))}
                placeholder={Strings.stepOne.orderReceiverPlaceHolder}
              >
                {receiverOptions.map((option, index) => (
                  <option className='option_receiver' key={index} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </SelectField>
            </LoadingState>
          </FlexColumn>
        </FlexRow>
      </LoadingState>
    </FlexColumn>
  );
};
