import React from 'react';

export const NotificationRenderer: React.FC<{ data: any; setValue: any }> = props => {
  const { data } = props;
  if (!data) {
    return null;
  }
  const activated = data.activateNotificationCol;
  return activated && <img src='../../../../../../assets/icons/notification-actived.svg' alt='Notification Actived' />;
};
