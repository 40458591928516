import * as React from 'react';
import { SubsectionData } from '@app/models/help-center.model';
import { Body } from '@atomic/atm.typography';
import { Accordion } from '@atomic/mol.accordion';
import { ComingSoonTag } from '../components/coming-soon-tag/coming-soon-tag';
import { SectionCellStyled } from './section-cell.style';

interface SubsectionsListProps {
  expanded: boolean;
  subsections: SubsectionData[];
  currentSubsection: string;
  onSubsectionClick: (subsection: string) => void;
}

export const SubsectionsList: React.FC<SubsectionsListProps> = props => {
  return (
    <Accordion hideBorder expanded={props.expanded}>
      {props.subsections.map(subsection => (
        <SectionCellStyled
          key={subsection.key}
          hover
          hasClick
          selected={props.currentSubsection === subsection.key}
          onClick={() => props.onSubsectionClick(subsection.key)}
        >
          <Body>
            {subsection.title}
            {subsection.hasComingSoonTag && <ComingSoonTag />}
          </Body>
        </SectionCellStyled>
      ))}
    </Accordion>
  );
};
