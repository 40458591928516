import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { LogAnalytics } from '@app/core/analytics';
import { EnhancedError } from '@app/core/hook/request.model';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { IsaLoading } from '@app/modules/components/isa-loading-component';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Hbox } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { LoadingState } from '@atomic/obj.loading-state';
import { validateTokens } from '../components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { UploadNormLoading } from '../components/uploadNormLoading';
import { useGetClientsList } from '../quote/hooks/useGetClientsList';
import { ChatContext, ChatProvider } from './chat.context';
import { ChatStrings } from './chat.string';
import { ChatClientList } from './components/chatClientList';
import { ChatFlowFields } from './components/chatFlowFields';
import { ChatResult } from './components/result/chat-result.component';
import {
  ChatWrapper,
  FieldWrapper,
  MainQuestion,
  QuestionWrapper,
  SecondaryQuestion,
} from './components/result/chat-result.component.style';
import { useChatCreate } from './hooks/useChatCreate';
import { useCreateAnalysis } from './hooks/useCreateAnalysis';
import { usePostFile } from './hooks/usePostFile';
import { ModalConfirmationForm } from './modal/modal-confirmation-form.component';
import { ModalErrorNluForm } from './modal/modal-error-nlu-form.component';

const getInitials = (name: string) => {
  return name.split(' ').shift();
};

export interface ClientList {
  bp: string;
  corporateGroup: string;
  formatList: string;
  name: string;
  type: string;
}

const REFRESH_INTERVAL = 60000;

export const ChatContainer = () => {
  const {
    chatQuestions: chatFlow,
    chatResult,
    setChatResult,
    enableClientSearch,
    setEnableClientSearch,
    showList,
    setShowList,
    fieldValue,
    setFieldValue,
    handleValueClientChange,
    timeQuote,
  } = React.useContext(ChatContext);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [conversationId, setConversationId] = React.useState(null);
  const [cfId, setCfId] = React.useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = React.useState(false);
  const [showModalErroNlu, setShowModalErroNlu] = React.useState(false);
  const [initialized, setInitialized] = React.useState(chatResult !== null);
  const [chatCreateV2, setChatCreateV2] = React.useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = React.useState<boolean>(false);

  const { isFirstPageLoad } = useSelector((state: RootState) => state.notification);
  const dispatch = useDispatch<AppThunkDispatch>();
  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (isFirstPageLoad) {
        await dispatch(validateTokens(isFirstPageLoad));
      }
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [isFirstPageLoad]);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language];

  useChatCreate(handleChatCreatedSucces);

  const { data: dataClientsList, isFetching: loadingClients } = useGetClientsList(
    {
      enabled: enableClientSearch,
      client: fieldValue,
      language: userInfo.language,
    },
    handleSuccessClientList,
  );

  const { isFetching: loadingCreateAnalysis, refetch: createAnalysis } = useCreateAnalysis(
    { data: chatResult, conversationId, chatCreateV2 },
    handleAnalysisCreationSuccess,
    handleAnalysisCreationFailure,
  );

  const { mutate: postFile, isLoading: loadingPostFile } = usePostFile(handleSendFileSuccess, handleSendFileError);

  function handleChatCreatedSucces(response) {
    setChatCreateV2(response.chatCreateV2);
  }

  function handleSuccessClientList() {
    setShowList(true);
    setEnableClientSearch(false);
  }

  function handleAnalysisCreationSuccess(data) {
    LogAnalytics.submit({
      tipo: 'CriarCotacaoForm',
      time: timeQuote,
      analysisId: data.data.cfId,
    });
    setCfId(data.data.cfId);
    setShowModalConfirmation(true);
    show('success', strings.newForm.messages.analysisCreatedSuccess);
  }

  function handleSendFileSuccess(response) {
    LogAnalytics.submit({
      tipo: 'SucessoLeituraNormaForm',
    });
    setDisableSubmit(false);

    response.data.nlu.map(item => {
      if (item) {
        if (item.key !== 'SPECIFIC_SPECIFICATION_NAME') {
          setChatResult(prevValue => ({
            ...prevValue,
            [item.key]: {
              type: item.type,
              creation: item.creation,
              visible: item.visible,
              value: item.value,
              label: item.label,
            },
          }));
          if (item.key === 'CHEMICAL_COMPOSITION') {
            const conditionalField = chatFlow.flow[0].fields.find(
              field => field?.conditional?.key === 'CHEMICAL_COMPOSITION',
            );
            conditionalField.display = true;
          }
        } else {
          setChatResult(prevValue => ({
            ...prevValue,
            ['SPECIFIC_SPECIFICATION_NAME']: { ...prevValue.SPECIFIC_SPECIFICATION_NAME, ...item },
          }));
        }
      }
    });
    setConversationId(response.data.conversationId);
    show('success', strings.newForm.messages.readNormSuccess);
    const conditionalFields = chatFlow.flow[0].fields.filter(field => field?.conditional?.key === 'CHECK_FILE');

    conditionalFields.forEach(field => {
      field.display = true;
    });
  }

  function handleAnalysisCreationFailure(err: EnhancedError) {
    LogAnalytics.error({
      tipo: 'ErroCriarCotacaoForm',
      message: err.message,
    });
    if (conversationId) {
      setShowModalErroNlu(true);
    } else {
      show('alert', strings.alertTry);
    }

    setDisableSubmit(true);

    const conditionalField = chatFlow.flow[0].fields.find(field => field.key === 'STEEL_NAME');
    conditionalField.display = false;
    setChatResult({
      CLIENT: chatResult.CLIENT,
      CLIENT_CODE: chatResult.CLIENT_CODE,
      CORPORATE_CODE: chatResult.CORPORATE_CODE,
      STANDARD: chatResult.STANDARD,
      WHEN_CLIENT_DATA: chatResult.WHEN_CLIENT_DATA,
    });
  }

  function handleSendFileError() {
    LogAnalytics.submit({
      tipo: 'ErroLeituraNormaForm',
    });
    show('warning', strings.newForm.message.readNormError);

    const conditionalFields = chatFlow.flow[0].fields.filter(field => field?.conditional?.key === 'CHECK_FILE');

    conditionalFields.forEach(field => {
      field.display = true;
    });
  }

  const handleSelectClient = (value: ClientList) => {
    setChatResult({
      ...chatResult,
      CLIENT: { value: value.name, label: strings.newForm.clientChatResult.client, visible: true, type: 'text' },
      CLIENT_CODE: { value: value.bp, label: strings.newForm.clientChatResult.clientCode, visible: true, type: 'text' },
      CORPORATE_CODE: {
        value: value.corporateGroup,
        label: strings.newForm.clientChatResult.corporateCode,
        visible: true,
        type: 'text',
      },
    });
    setEnableClientSearch(false);
    setFieldValue(value.formatList);
    setShowList(false);
    setInitialized(true);
  };

  const handleFocusClient = value => {
    const hasCode = value.split(' - ');
    if (typeof hasCode[0] !== 'number') {
      setFieldValue('');
    }
  };

  const deleteClient = () => {
    setFieldValue('');
    setChatResult({
      ...chatResult,
      CLIENT: { label: null, value: null, visible: false },
      CLIENT_CODE: { label: null, value: null, visible: false },
      CORPORATE_CODE: { label: null, value: null, visible: false },
    });

    setShowList(false);
  };

  const handleUpload = (file: File[], label?: string) => {
    if (!chatResult?.CLIENT_CODE?.value || !chatResult?.STANDARD?.value) {
      show('alert', strings.newForm.messages.uploadAlert);
    } else {
      setChatResult({
        CLIENT: chatResult.CLIENT,
        CLIENT_CODE: chatResult.CLIENT_CODE,
        CORPORATE_CODE: chatResult.CORPORATE_CODE,
        STANDARD: chatResult.STANDARD,
        WHEN_CLIENT_DATA: chatResult.WHEN_CLIENT_DATA,
        CHECK_FILE: chatResult.CHECK_FILE,
      });
      setConversationId(null);

      setChatResult(prevValue => ({
        ...prevValue,
        ['SPECIFIC_SPECIFICATION_NAME']: { file: file, label, visible: true },
        ['PRODUCT']: null,
      }));
      postFile({
        client: {
          code: chatResult.CLIENT_CODE.value,
          name: chatResult.CLIENT.value,
          corporateCode: chatResult.CORPORATE_CODE.value,
        },
        standard: {
          type: chatResult.STANDARD.value.key,
        },
        file: file[0],
        language: userInfo.language,
      });
    }
  };

  const findClient = (value: ClientList) => {
    if (value) {
      if (value?.formatList) {
        return value.formatList.toLowerCase().includes(fieldValue.toLowerCase());
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const filterClientList = (list: ClientList[]) => {
    return list.filter(findClient);
  };

  const handleValue = (value: any, keyField?: string, label?: string, conditional?: boolean, type?: string) => {
    setInitialized(true);

    if (keyField === 'CHECK_FILE' && disableSubmit) {
      setDisableSubmit(false);
    }

    if (
      chatResult?.CLIENT_CODE?.value &&
      chatResult?.STANDARD?.value &&
      keyField === 'CHECK_FILE' &&
      value?.key === 'NO'
    ) {
      setConversationId(null);
      clearNluAttributes();
      const conditionalField = chatFlow.flow[0].fields.find(field => field.key === 'STEEL_NAME');
      conditionalField.display = false;
    }

    if (chatResult) {
      setChatResult(prevValue => ({
        ...prevValue,
        [keyField]: {
          ...prevValue[keyField],
          value,
          label: chatResult[keyField]?.label ? chatResult[keyField].label : label,
          visible: value !== '' && value !== null,
          type: chatResult[keyField]?.type ? chatResult[keyField].type : type,
          creation: chatResult[keyField]?.value !== value ? 'USER' : chatResult[keyField]?.creation,
        },
      }));
    } else {
      setChatResult({
        [keyField]: {
          value,
          label,
          visible: value !== '' && value !== null,
          type,
          creation: 'USER',
        },
      });
    }

    if (conditional) {
      const conditionalFields = chatFlow.flow[0].fields.filter(field => field?.conditional?.key === keyField);

      conditionalFields.forEach(field => {
        if (field.conditional.conditionalValue === value.key) {
          field.display = true;
        } else {
          field.display = false;
          if (chatResult) {
            if (chatResult[field.key]?.value) {
              chatResult[field.key].value = null;
              chatResult[field.key].label = null;
            }
          }
        }
      });
    }
  };

  const handleSubmit = async formFields => {
    if (Object.keys(formFields.error).length <= 0) {
      if (chatResult?.CLIENT_CODE?.value) {
        if (chatResult.CHECK_FILE?.value?.key === 'YES') {
          if (chatResult.SPECIFIC_SPECIFICATION_NAME && chatResult.SPECIFIC_SPECIFICATION_NAME.file.length > 0) {
            const conditionalFields = chatFlow.flow[0].fields.filter(
              field => field?.conditional?.key === 'CHECK_FILE' || field?.conditional?.key === 'CHEMICAL_COMPOSITION',
            );
            conditionalFields.forEach(field => {
              field.display = false;
            });

            createAnalysis();
          } else {
            show('alert', strings.newForm.messages.attached);
          }
        } else {
          const conditionalFields = chatFlow.flow[0].fields.filter(field => field?.conditional?.key === 'CHECK_FILE');
          conditionalFields.forEach(field => {
            field.display = false;
          });
          createAnalysis();
        }
      } else {
        show('alert', strings.newForm.messages.alertClient);
      }
    }
  };

  const resetChat = () => {
    window.location.reload();
  };

  function clearNluAttributes() {
    setChatResult({
      CLIENT: chatResult.CLIENT,
      CLIENT_CODE: chatResult.CLIENT_CODE,
      CORPORATE_CODE: chatResult.CORPORATE_CODE,
      STANDARD: chatResult.STANDARD,
      WHEN_CLIENT_DATA: chatResult.WHEN_CLIENT_DATA,
    });
  }

  document.addEventListener('keydown', function(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  });

  return (
    <LoadingState loading={loadingCreateAnalysis || loadingPostFile} enableActivityIndicator={false}>
      {loadingCreateAnalysis ? (
        <LoadingState.CustomLoading>
          <IsaLoading message={loadingCreateAnalysis ? strings.loading.initialize : strings.loading.finish} />
        </LoadingState.CustomLoading>
      ) : (
        <LoadingState.CustomLoading>
          <UploadNormLoading message={strings.loading.analyzing} />
        </LoadingState.CustomLoading>
      )}

      <Hbox>
        <Hbox.Item>
          <Scroll.Element
            style={{
              height: 'calc(100vh - 60px)',
              overflow: 'scroll',
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            id='container-id'
          >
            <ChatWrapper>
              <Form id='formNewQuote' onSubmit={handleSubmit}>
                <QuestionWrapper>
                  <MainQuestion>
                    {strings.newForm.introdution} <b>{getInitials(userInfo.name)}</b>
                  </MainQuestion>
                </QuestionWrapper>

                <QuestionWrapper data-testid='fieldCLIENT'>
                  <MainQuestion>{strings.newForm.clientField.question}</MainQuestion>
                  <SecondaryQuestion>{strings.newForm.clientField.detail}</SecondaryQuestion>
                  <FieldWrapper>
                    <LoadingState loading={loadingClients}>
                      <Form.Field
                        name='CLIENT'
                        initialValue={fieldValue}
                        onValueChange={handleValueClientChange}
                        validators={[Validators.Required(strings.newForm.clientField.alertValidator)]}
                      >
                        <TextField hasButton onFocus={e => handleFocusClient(e.target.value)}>
                          <Button kind='link' onClick={() => deleteClient()}>
                            <FaIcon.Close size='1x' />
                          </Button>
                        </TextField>
                      </Form.Field>
                      <ChatClientList
                        show={showList}
                        users={dataClientsList && filterClientList(dataClientsList)}
                        onClose={() => setShowList(false)}
                        onSelect={handleSelectClient}
                        loading={loadingClients}
                      />
                    </LoadingState>
                  </FieldWrapper>
                </QuestionWrapper>

                {chatFlow &&
                  chatFlow?.flow[0]?.fields.map(field => (
                    <ChatFlowFields
                      key={field.key}
                      field={field}
                      handleUpload={handleUpload}
                      handleValue={handleValue}
                    />
                  ))}
                <Hbox>
                  <Hbox.Item grow>
                    <Button expanded kind='callToAction' type='submit' disabled={loadingPostFile || disableSubmit}>
                      {strings.newForm.submitButton}
                    </Button>
                  </Hbox.Item>
                  <Hbox.Separator small />
                </Hbox>
              </Form>
            </ChatWrapper>
          </Scroll.Element>
        </Hbox.Item>
        <Hbox.Item noGrow>
          <ChatResult initialized={initialized} resetChat={resetChat} />
        </Hbox.Item>
      </Hbox>

      <ModalConfirmationForm
        language={userInfo.language}
        setChatResult={setChatResult}
        opened={showModalConfirmation}
        cfId={cfId}
      />

      <ModalErrorNluForm
        language={userInfo.language}
        opened={showModalErroNlu}
        setShowModalErroNlu={setShowModalErroNlu}
        conversationId={conversationId}
      />
    </LoadingState>
  );
};

export const ChatPage = () => {
  return (
    <ChatProvider>
      <ChatContainer />
    </ChatProvider>
  );
};
