import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { Body, H2 } from '@atomic/atm.typography';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { Tab } from '@atomic/mol.tab';
import { Hbox } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { Visibility } from '@atomic/obj.visibility';
import { ChatStrings } from './chat.string';
import { AttributesContentWrapperStyled, AttributesTabsWrapperStyled } from './modal-attributes.component.style';

export interface Attributes {
  key: string;
  label: string;
  data: string[];
  ButtonsList_PropriedadesMecanicas: string[];
  ButtonsList_PropriedadesMetalurgicas: string[];
  ButtonsList_Dimensional: string[];
  ButtonsList_OutrasCaracteristicas: string[];
  ButtonsList_TiposdeEnsaio: string[];
  ButtonsList_Produto: string[];
}

interface ModalAttributeProps {
  openTableType: string;
  opened?: boolean;
  data: Attributes;
  onSubmit: (label: string, id: any, newAttribute: boolean) => void;
  onClose: () => void;
}

export const ModalAttributes: React.FC<ModalAttributeProps> = props => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [opened, setOpened] = React.useState(false);
  const [listAttributes, setListAttributes] = React.useState([]);
  const [selectedAttribute, setSelectedAttribute] = React.useState(null);
  const [newAttributeForm, setNewAttributeForm] = React.useState(false);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language];

  React.useEffect(() => {
    if (props.openTableType === 'ATRIBUTOS') {
      setListAttributes([
        {
          key: 'ButtonsList_Produtos',
          label: strings.product,
          data: props.data?.ButtonsList_Produto,
        },
        { key: 'ButtonsList_Dimensional', label: 'Dimensional', data: props.data?.ButtonsList_Dimensional },
        {
          key: 'ButtonsList_PropriedadesMecanicas',
          label: strings.modalMechanical.title,
          data: props.data?.ButtonsList_PropriedadesMecanicas,
        },
        {
          key: 'ButtonsList_PropriedadesMetalurgicas',
          label: strings.metallurgicalProperties,
          data: props.data?.ButtonsList_PropriedadesMetalurgicas,
        },
        {
          key: 'ButtonsList_TiposdeEnsaio',
          label: strings.testMethods,
          data: props.data?.ButtonsList_TiposdeEnsaio,
        },
        {
          key: 'ButtonsList_OutrasCaracteristicas',
          label: strings.other,
          data: props.data?.ButtonsList_OutrasCaracteristicas,
        },
      ]);
      setOpened(true);

      if (props.data?.ButtonsList_Produto.length === 0) {
        setTabIndex(1);
      }
    }
  }, [props.openTableType]);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const handleSubmit = data => {
    const attributeLabel = data.data?.label ? data.data?.label : data.data?.attribute;
    const attributeValue = data.data?.value ? data.data?.value : data.data?.attribute;
    if (Object.keys(data.error).length > 0) {
      return;
    }
    props.onSubmit(attributeLabel, attributeValue, newAttributeForm);
    setSelectedAttribute(null);
    setNewAttributeForm(false);
    setOpened(false);
  };

  const handleOption = (attribute: string) => {
    props.onSubmit(attribute, attribute, newAttributeForm);
    setSelectedAttribute(null);
    setNewAttributeForm(false);
    setOpened(false);
  };

  const handleClose = () => {
    props.onClose();
    setSelectedAttribute(null);
    setOpened(false);
  };

  return (
    <Modal preventOverlayClick medium opened={opened} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        {!newAttributeForm ? (
          <Grid fluid>
            <H2>{strings.modalAttributes.title}</H2>
            <AttributesTabsWrapperStyled>
              <Tab initialIndex={tabIndex} onIndexChanged={handleTabChange}>
                {listAttributes.map(tab => {
                  if (tab.data && tab.data?.length > 0) {
                    return (
                      <Tab.Item key={tab.key}>
                        <Hbox>
                          <Hbox.Item>{tab.label}</Hbox.Item>
                        </Hbox>
                      </Tab.Item>
                    );
                  } else {
                    return '';
                  }
                })}
              </Tab>
            </AttributesTabsWrapperStyled>
            <Row>
              <Col xs={12}>
                <Form.Field
                  name='attribute'
                  initialValue={selectedAttribute}
                  validators={[Validators.Required(strings.modalAttributes.alertMandatory)]}
                >
                  {listAttributes.map((list, index) => {
                    if (list.data && list.data.length > 0) {
                      return (
                        <Visibility key={index} visible={tabIndex === index}>
                          <AttributesContentWrapperStyled>
                            {list.data.map((item, index2) => (
                              <DefaultListCell
                                selected={selectedAttribute === item}
                                key={index2}
                                onClick={() => handleOption(item)}
                              >
                                <Body>{item}</Body>
                              </DefaultListCell>
                            ))}
                          </AttributesContentWrapperStyled>
                        </Visibility>
                      );
                    } else {
                      return '';
                    }
                  })}
                </Form.Field>
              </Col>
            </Row>
            <Hbox>
              {tabIndex === listAttributes.length - 1 && (
                <Hbox.Item hAlign='flex-start'>
                  <Button kind='link' onClick={() => setNewAttributeForm(true)}>
                    {strings.modalAttributes.btnAdd}
                  </Button>
                </Hbox.Item>
              )}
              {/* <Hbox.Item hAlign='flex-end'>
                <Button type='submit'>{strings.modalAttributes.btnSelect}</Button>
              </Hbox.Item> */}
            </Hbox>
            <VSeparator />
          </Grid>
        ) : (
          <Grid fluid>
            <H2>{strings.modalAttributes.titleAdd}</H2>
            <Row>
              <Col xs={12}>
                <Body>{strings.modalAttributes.fieldTitle.label}</Body>
                <VSeparator small />
                <Form.Field name='label' validators={[Validators.Required(strings.modalAttributes.alertMandatory2)]}>
                  <TextField placeholder={strings.modalAttributes.fieldTitle.placeholder} />
                </Form.Field>
                <VSeparator />
              </Col>

              <Col xs={12}>
                <Body>{strings.modalAttributes.fieldValue.label}</Body>
                <VSeparator small />
                <Form.Field name='value' validators={[Validators.Required(strings.modalAttributes.alertMandatory2)]}>
                  <TextAreaField placeholder={strings.modalAttributes.fieldValue.placeholder} disableResize />
                </Form.Field>
                <VSeparator />
              </Col>
            </Row>
            <Hbox hAlign='flex-end'>
              <Hbox.Item noGrow>
                <Button kind='secondary' expanded onClick={() => setNewAttributeForm(false)}>
                  {strings.modalAttributes.btnBack}
                </Button>
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <Button kind='primary' expanded type='submit'>
                  {strings.modalAttributes.btnAddSubmit}
                </Button>
              </Hbox.Item>
            </Hbox>
            <VSeparator />
          </Grid>
        )}
      </Form>
    </Modal>
  );
};

ModalAttributes.defaultProps = {
  openTableType: '',
};
