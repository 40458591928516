import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { ChatStrings } from '@app/modules/chat/chat.string';
import { FloatinActionButton } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Hbox } from '@atomic/obj.box';
import { Keys } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';
import { ChatFieldButtonWrapperStyled, ChatFieldStyled } from './chatfield.component.style';

interface ChatFieldProps {
  onMessageSend: (text: string) => void;
  onSetFocusFn?: (fn: any) => void;
  initialized?: boolean;
  loading?: boolean;
}

export const ChatField: React.FC<ChatFieldProps> = props => {
  const [text, setText] = React.useState('');
  const fieldRef = React.useRef(null);

  const { userInfo } = useSelector((state: RootState) => state.auth);

  const strings = ChatStrings[userInfo.language];

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleSend = () => {
    props.onMessageSend(text);
    setText('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === Keys.Enter) {
      handleSend();
    }
  };

  const setFocus = () => {
    if (fieldRef.current) {
      fieldRef.current.focus();
    }
  };

  React.useEffect(() => {
    if (props.onSetFocusFn) {
      props.onSetFocusFn(setFocus);
    }
  }, []);

  return (
    <Grid fluid>
      <Hbox>
        <Hbox.Item>
          <ChatFieldStyled
            ref={fieldRef}
            value={text}
            placeholder={strings.messageField}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
            disabled={props.loading}
          />
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item noGrow>
          <ChatFieldButtonWrapperStyled initialized={props.initialized}>
            <FloatinActionButton onClick={handleSend} disabled={props.loading}>
              <FaIcon.Plane size='sm' />
            </FloatinActionButton>
          </ChatFieldButtonWrapperStyled>
        </Hbox.Item>
      </Hbox>
    </Grid>
  );
};
