import { Title } from '@gerdau/hefesto';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AdminListHeaderSeparator } from '@app/modules/admin/admin.page.style';
import { AdminStrings } from '@app/modules/admin/admin.string';
import { NPSGroupOptions } from '@app/modules/admin/partials/admin-user-form/utils/utils';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { AdminNPSRowWrapper } from '../admin-nps.styles';
import { formatNPSDate, percentageValue } from '../utils/utils';
import { AdminNPSStatisticsShimmer } from './admin-nps-statistics.shimmer';

export const AdminNPSStatistics: React.FC = () => {
  const { loadingNPSStatistics, npsStatisticsGroup, selectedNPSGroup } = useSelector((state: RootState) => state.admin);
  const strings = AdminStrings.pt;

  const handleStatisticsFirstRow = () => {
    if (npsStatisticsGroup?.isRunning) {
      return (
        <AdminNPSRowWrapper>
          <Title level={3} variant='primary'>
            {NPSGroupOptions.find(option => option.id === selectedNPSGroup)?.label}
          </Title>
          <AdminListHeaderSeparator />
          <Body bold={700} color={Color.SuccessMain}>{`${strings.nps.running}`}</Body>
          <AdminListHeaderSeparator />
          <Body>{`${strings.nps.researchStartDate} ${formatNPSDate(npsStatisticsGroup?.startResearch)}`}</Body>
          <AdminListHeaderSeparator />
          <Body>{`${strings.nps.researchEndDate} ${formatNPSDate(npsStatisticsGroup?.endResearch)}`}</Body>
        </AdminNPSRowWrapper>
      );
    } else {
      return (
        <AdminNPSRowWrapper>
          <Title level={3} variant='primary'>
            {NPSGroupOptions.find(option => option.id === selectedNPSGroup)?.label}
          </Title>
          <AdminListHeaderSeparator />
          <Body bold={700} color={Color.GrayXDark}>{`${strings.nps.scheduled}`}</Body>
          <AdminListHeaderSeparator />
          <Body>{`${strings.nps.researchNextStartDate} ${formatNPSDate(npsStatisticsGroup?.startResearch)}`}</Body>
        </AdminNPSRowWrapper>
      );
    }
  };

  const handleStatisticsSecondRow = () => {
    if (npsStatisticsGroup?.isRunning) {
      return (
        <AdminNPSRowWrapper>
          <Body>{strings.nps.groupClients}</Body>
          <Body bold={700} ml={Spacing.XSmall}>
            {npsStatisticsGroup?.qtdUsers}
          </Body>
          <AdminListHeaderSeparator />
          <Body>{strings.nps.answered}</Body>
          <Body bold={700} ml={Spacing.XSmall}>
            {npsStatisticsGroup.answered}
          </Body>
          <Body ml={Spacing.XSmall}>
            {`(${percentageValue(npsStatisticsGroup?.answered, npsStatisticsGroup?.qtdUsers)}%)`}
          </Body>
          <AdminListHeaderSeparator />
          <Body>{strings.nps.visualized}</Body>
          <Body bold={700} ml={Spacing.XSmall}>
            {npsStatisticsGroup.viewed}
          </Body>
          <Body ml={Spacing.XSmall}>
            {`(${percentageValue(npsStatisticsGroup?.viewed, npsStatisticsGroup?.qtdUsers)}%)`}
          </Body>
          <AdminListHeaderSeparator />
          <Body>{strings.nps.notAnswered}</Body>
          <Body bold={700} ml={Spacing.XSmall}>
            {npsStatisticsGroup.canceled}
          </Body>
          <Body ml={Spacing.XSmall}>
            {`(${percentageValue(npsStatisticsGroup?.canceled, npsStatisticsGroup?.qtdUsers)}%)`}
          </Body>
        </AdminNPSRowWrapper>
      );
    } else {
      return (
        <AdminNPSRowWrapper>
          <Body>{strings.nps.groupClients}</Body>
          <Body bold={700} ml={Spacing.XSmall}>
            {npsStatisticsGroup?.qtdUsers}
          </Body>
        </AdminNPSRowWrapper>
      );
    }
  };

  return (
    <LoadingState loading={loadingNPSStatistics} data={!!npsStatisticsGroup} error={npsStatisticsGroup === null}>
      <LoadingState.Shimmer>
        <AdminNPSStatisticsShimmer />
      </LoadingState.Shimmer>

      <LoadingState.NoData>
        <FlexRow hAlign='center' mt={Spacing.XLarge} mb={Spacing.XLarge}>
          <Body size='16px' bold={600}>
            {strings.common.noResultsFound}
          </Body>
        </FlexRow>
      </LoadingState.NoData>
      <FlexColumn vAlign='center' mt={Spacing.XLarge} ml={Spacing.XLarge} mr={Spacing.XLarge}>
        {handleStatisticsFirstRow()}
        {handleStatisticsSecondRow()}
      </FlexColumn>
    </LoadingState>
  );
};
