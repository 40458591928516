import { Receiver } from '@app/models';

export interface ReceiverDTO {
  recebedor: string;
  recebedorName: string;
  cidade: string;
  corpGroupName: string;
}

export interface ReceiversDTO {
  implantationRequestRecebedor: ReceiverDTO[];
}

export const mapReceivers = (data: ReceiversDTO): Receiver[] => {
  return data.implantationRequestRecebedor.map(receiverDTO => ({
    receiverBpNumber: receiverDTO.recebedor,
    receiverName: receiverDTO.recebedorName,
    receiverCity: receiverDTO.cidade,
    corpGroupName: receiverDTO.corpGroupName,
  }));
};
