export interface DateParserParams {
  unformattedDate?: string;
  hideHours?: boolean;
  utc?: boolean;
  language?: string;
}

export const dateParser = (params: DateParserParams): string => {
  const { unformattedDate, hideHours, utc, language } = params;
  const creationDate = unformattedDate ? new Date(unformattedDate) : new Date();
  const data = getDateValues(creationDate, utc);

  const detailedDate = hideHours ? '' : ` às ${formatValue(data.hour)}:${formatValue(data.minutes)}`;

  if (language === 'in') {
    return `${formatValue(data.month)}/${formatValue(data.day)}/${data.year}` + detailedDate;
  } else {
    return `${formatValue(data.day)}/${formatValue(data.month)}/${data.year}` + detailedDate;
  }
};

export const dateParserPTBR = (params: DateParserParams): string => {
  const { unformattedDate, hideHours, utc } = params;
  const unformattedDateSplitted = unformattedDate.split('/');
  const formattedDate =
    unformattedDateSplitted[1] + '/' + unformattedDateSplitted[0] + '/' + unformattedDateSplitted[2];
  const creationDate = unformattedDate ? new Date(formattedDate) : new Date();
  const data = getDateValues(creationDate, utc);

  const detailedDate = hideHours ? '' : ` às ${formatValue(data.hour)}:${formatValue(data.minutes)}`;

  return `${formatValue(data.day)}/${formatValue(data.month)}/${data.year}` + detailedDate;
};

export const parseStringToDate = (stringDate: string): Date => {
  const unformattedDateSplitted = stringDate.split('/');
  const formattedDate =
    unformattedDateSplitted[1] + '/' + unformattedDateSplitted[0] + '/' + unformattedDateSplitted[2];
  const date = new Date(formattedDate);
  return date;
};

export const dateText = (params: DateParserParams): string => {
  const { unformattedDate, utc, language } = params;
  const creationDate = unformattedDate ? new Date(unformattedDate) : new Date();
  const data = getDateValues(creationDate, utc);

  switch (language) {
    case 'pt':
      return `${formatValue(data.day)} de ${getMonthText(creationDate.getMonth(), language)} de ${data.year}`;
    case 'in':
      return `${formatValue(data.day)} ${getMonthText(creationDate.getMonth(), language)}, ${data.year}`;
    case 'es':
      return `${formatValue(data.day)} de ${getMonthText(creationDate.getMonth(), language)} de ${data.year}`;
    default:
      return `${formatValue(data.day)} de ${getMonthText(creationDate.getMonth(), language)} de ${data.year}`;
  }
};

const getMonthText = (month: number, language: string): string => {
  let months = [];

  switch (language) {
    case 'pt':
      months = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
      break;
    case 'in':
      months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      break;
    case 'es':
      months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Novembre',
        'Decembre',
      ];
      break;
    default:
      break;
  }

  return months[month];
};

export const dateParserNotification = (params: DateParserParams): string => {
  const { unformattedDate, hideHours, utc } = params;
  const creationDate = unformattedDate ? new Date(unformattedDate) : new Date();
  const data = getDateValues(creationDate, utc);

  const detailedDate = hideHours ? '' : `${formatValue(data.hour)}:${formatValue(data.minutes)}`;

  let date;

  if (isToday(creationDate)) {
    date = 'hoje, ';
  } else if (isYesterday(creationDate)) {
    date = 'ontem, ';
  } else {
    date = `${formatValue(data.day)}/${formatValue(data.month)}, `;
  }

  return date + detailedDate;
};

const getDateValues = (date: Date, utc?: boolean) => {
  return utc
    ? {
        day: date.getUTCDate(),
        month: date.getUTCMonth() + 1,
        year: date.getUTCFullYear(),
        hour: date.getUTCHours(),
        minutes: date.getUTCMinutes(),
      }
    : {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        hour: date.getHours(),
        minutes: date.getMinutes(),
      };
};

const formatValue = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`;
};

export const localToInternationalDate = (date: string) => {
  const values = date.split('/');
  return `${values[1]}/${values[0]}/${values[2]}`;
};

export const isToday = someDate => {
  const today = new Date();

  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const isYesterday = someDate => {
  const date = new Date();
  date.setDate(date.getDate() - 1);

  return (
    someDate.getDate() === date.getDate() &&
    someDate.getMonth() === date.getMonth() &&
    someDate.getFullYear() === date.getFullYear()
  );
};

export const getWeekendDays = (startDate: Date, endDate: Date): Date[] => {
  const weekendDates = [];
  const dateRef: Date = new Date(startDate.getTime());

  while (dateRef <= endDate) {
    dateRef.setDate(dateRef.getDate() + 1);

    const dayOfWeek = dateRef.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      weekendDates.push(new Date(dateRef.getTime()));
    }
  }
  return weekendDates;
};

export const getDateString = (date: Date) => {
  const data = getDateValues(new Date(date), false);
  return `${formatValue(data.month)}/${formatValue(data.day)}/${data.year}`;
};

export const checkEqualDates = (date: Date, compareDate: Date) => {
  return new Date(getDateString(date)).getTime() === new Date(getDateString(compareDate)).getTime();
};
