import { AdminNPSFilterOptionsId, AdminNPSFilterOptionsLabel } from '@app/models/admin.model';

export const NPSUserFilterOptions = [
  {
    value: AdminNPSFilterOptionsId.Name,
    label: AdminNPSFilterOptionsLabel.Name,
  },
  {
    value: AdminNPSFilterOptionsId.Mail,
    label: AdminNPSFilterOptionsLabel.Mail,
  },
  {
    value: AdminNPSFilterOptionsId.Eligibility,
    label: AdminNPSFilterOptionsLabel.Eligibility,
  },
];

export const formatNPSDate = (date: string) => {
  let dateResult = '';

  if (date) {
    const dateSplit = date.split('T')[0];
    const dateAsStringSplit = dateSplit.split('-');
    dateResult = `${dateAsStringSplit[2]}/${dateAsStringSplit[1]}/${dateAsStringSplit[0]}`;
  }

  return dateResult;
};

export const percentageValue = (value: number, total: number) => {
  return ((value * 100) / total).toFixed(2);
};

export enum NPSUserStatus {
  Answered = 'Respondido',
  Viewed = 'Visualizado',
  Canceled = 'Optou por não responder',
  Waiting = 'Aguardando resposta',
  Invalid = 'Não elegível',
}
