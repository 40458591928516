export interface IOrderTabOpenInternationalStrings {
  title: string;
  filename: string;
  deliveryStatus: {
    delay: string;
    onTime: string;
    noForecast: string;
    notConfirmed: string;
    undefined: string;
  };
  status: {
    notConfirmed: string;
    inAnalysis: string;
    confirmed: string;
  };
  error: string;
}

interface IOrderTabOpenInternationalLanguages {
  pt: IOrderTabOpenInternationalStrings;
  es: IOrderTabOpenInternationalStrings;
  in: IOrderTabOpenInternationalStrings;
}

const pt: IOrderTabOpenInternationalStrings = {
  title: 'Em aberto',
  filename: 'Pedidos em aberto',
  deliveryStatus: {
    delay: 'Em atraso',
    onTime: 'Dentro do prazo',
    noForecast: 'Sem previsão - Consultar Gerdau',
    notConfirmed: 'Pedido ainda não confirmado',
    undefined: undefined,
  },
  status: {
    notConfirmed: 'Não confirmado',
    inAnalysis: 'Não confirmado - em análise',
    confirmed: 'Confirmado',
  },
  error: 'Ocorreu um erro ao carregar os dados da aba Em aberto! Por favor, tente novamente.',
};

const es: IOrderTabOpenInternationalStrings = {
  title: 'En Abierto',
  filename: 'Ordenes abiertas',
  deliveryStatus: {
    delay: 'Retraso',
    onTime: 'Dentro del plazo',
    noForecast: 'Sin pronóstico - Consultar Gerdau',
    notConfirmed: 'Pedido aún no confirmado',
    undefined: undefined,
  },
  status: {
    notConfirmed: 'No confirmado',
    inAnalysis: 'No confirmado - en análisis',
    confirmed: 'Confirmado',
  },
  error: '¡Ocurrió un error al cargar datos desde la pestaña Abrir! Inténtalo de nuevo.',
};

const ing: IOrderTabOpenInternationalStrings = {
  title: 'Open payment',
  filename: 'Purchase orders',
  deliveryStatus: {
    delay: 'Delay',
    onTime: 'On time',
    noForecast: 'No forecast - Consult Gerdau',
    notConfirmed: 'Order not yet confirmed',
    undefined: undefined,
  },
  status: {
    notConfirmed: 'Not confirmed',
    inAnalysis: 'Not confirmed - Under review',
    confirmed: 'Confirmed',
  },
  error: 'An error occurred while loading data from the Open tab! Please try again.',
};

export const Strings: IOrderTabOpenInternationalLanguages = {
  pt,
  es,
  in: ing,
};
