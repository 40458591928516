import { HelpCenterSubsectionKeys, HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterQuotationSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Cotação',
      key: HelpCenterSectionKeys.Quotation,
      subsections: [
        {
          title: 'Introdução à cotação',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.IntrodutionQuotation,
          informationBlocks: [
            {
              paragraph:
                'É possível solicitar uma cotação através da plataforma Gerdau Mais. A aba de cotação é o espaço de visualização, gestão e acompanhamento das cotações solicitadas.',
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/quotation/introduction-to-quotation/introduction-to-quotation-01.png',
              },
            },
            {
              paragraphBold: 'Na aba de Todas, é possível acompanhá-las e visualizar seus status e outras informações.',
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/quotation/introduction-to-quotation/introduction-to-quotation-02.png',
              },
            },
            {
              paragraphBold:
                'Na aba de Minhas pendências, estarão destacadas as consultas em que necessita de uma ação sua.',
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/quotation/introduction-to-quotation/introduction-to-quotation-03.png',
              },
            },
            {
              paragraphBold:
                'Na aba de rascunhos, serão apresentadas as consultas iniciadas, mas que não foram finalizadas no chat. Ao clicar no rascunho escolhido, o chat será retomado de onde parou.',
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/quotation/introduction-to-quotation/introduction-to-quotation-04.png',
              },
            },
          ],
        },
        {
          title: 'Como criar uma nova consulta?',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.NewQuotation,
          informationBlocks: [
            {
              paragraph:
                'Na plataforma Gerdau Mais, você tem a opção de solicitar uma cotação. Através de um chat disponível no site, você interage com a nossa inteligência artificial e compartilha todas as informações necessárias para a cotação.',
            },
            {
              paragraphBold: '1. Para iniciar, clique no botão de Nova Consulta e o Gerdau Mais direcionará ao chat.',
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/quotation/create-new-query/create-new-query-01.png',
              },
            },
            {
              paragraphBold:
                '2. Ao entrar no chat, a inteligência artificial solicitará as informações necessárias para a consulta.',
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/quotation/create-new-query/create-new-query-02.png',
              },
            },
            {
              paragraph:
                'Para entender um pouco mais sobre o chat, verifique o artigo sobre o fluxo do chat em nossa central de ajuda.',
            },
          ],
        },
        {
          title: 'Explicação do fluxo do chat',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.FlowQuotation,
          informationBlocks: [
            {
              paragraph:
                'É através do chat que as consultas são realizadas em nossa plataforma, respondendo as perguntas necessárias da inteligência artificial. É importante estar atento(a) a todas as perguntas e respondê-las de forma correta.',
            },
            {
              paragraphBold:
                '1. Seleção: algumas perguntas serão descritivas e outras será necessária a seleção de opções;',
            },
            {
              paragraphBold:
                '2. Resumo da análise: a cada pergunta respondida, as informações estarão à direita. Neste espaço, é possível editar as informações ou excluir e respondê-las novamente;',
            },
            {
              paragraphBold:
                '3. Resetar consulta: ao clicar nessa opção, no resumo da análise, o chat será reiniciado.',
            },
            {
              paragraph:
                'Ao sair da tela do chat, a interação será salva automaticamente e disponibilizada na aba de rascunhos para que seja retomada do ponto em que foi interrompida.',
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/quotation/chat-flow/chat-flow-01.png',
              },
            },
          ],
        },
      ],
    }
  );
};
