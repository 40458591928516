export interface ICustomerSearchModalFooterAdminStrings {
  totalText: string;
  cancelButton: string;
  confirmButton: string;
}

interface ICustomerSearchModalFooterAdminLanguages {
  pt: ICustomerSearchModalFooterAdminStrings;
  es: ICustomerSearchModalFooterAdminStrings;
  in: ICustomerSearchModalFooterAdminStrings;
}

const pt: ICustomerSearchModalFooterAdminStrings = {
  totalText: 'filiais selecionadas',
  cancelButton: 'Cancelar',
  confirmButton: 'Ver filiais selecionadas',
};
const es: ICustomerSearchModalFooterAdminStrings = {
  totalText: 'filiais seleccionadas',
  cancelButton: 'Cancelar',
  confirmButton: 'Ver filiais seleccionadas',
};

const en: ICustomerSearchModalFooterAdminStrings = {
  totalText: 'selected Business Partners',
  cancelButton: 'Cancel',
  confirmButton: 'See selected Business Partners',
};

export const Strings: ICustomerSearchModalFooterAdminLanguages = {
  pt,
  es,
  in: en,
};
