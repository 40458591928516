import { PayloadAction } from '@reduxjs/toolkit';
import { REDUX_DATE_ERROR_MESSAGE } from '@app/constants';
import { dateParser } from '@app/utils/date-parser';
import { LAST_UPDATE_REQUESTING } from '@atomic/obj.constants';
import { IOrderTabState } from './order.interfaces';

export const reducers = {
  setData: (state: IOrderTabState, { payload }) => {
    state.data = payload;
  },
  setFilter: (state: IOrderTabState, { payload }) => {
    state.filter = payload;
  },
  setFilteredRows: (state: IOrderTabState, { payload }) => {
    state.filteredRows = payload;
  },
  setSelectedRows: (state: IOrderTabState, { payload }) => {
    state.selectedRows = payload;
  },
  setShowActionBar: (state: IOrderTabState, { payload }) => {
    state.showActionBar = payload;
  },
  setCriticalFilter: (state: IOrderTabState, { payload }) => {
    state.criticalFilter = payload;
  },
  setLackOfChargeFilter: (state: IOrderTabState, { payload }) => {
    state.lackOfChargeFilter = payload;
  },
};

export const reducersWithDates = {
  ...reducers,
  setStartDate: (state: IOrderTabState, { payload }: PayloadAction<number>) => {
    if (typeof payload === 'number' || payload == null) {
      state.startDate = payload;
    } else {
      throw Error(REDUX_DATE_ERROR_MESSAGE);
    }
  },
  setEndDate: (state: IOrderTabState, { payload }: PayloadAction<number>) => {
    if (typeof payload === 'number' || payload == null) {
      state.endDate = payload;
    } else {
      throw Error(REDUX_DATE_ERROR_MESSAGE);
    }
  },
};

export const extraReducersConfig = performRequest => builder => {
  builder.addCase(performRequest.pending, state => {
    state.lastUpdate = LAST_UPDATE_REQUESTING;
    state.currentRequestTime = new Date().getTime();
    state.loading = true;
  });
  builder.addCase(performRequest.fulfilled, (state, action) => {
    if (action.payload.promiseRequestTime >= state.currentRequestTime) {
      state.data = action.payload.data;
      state.lastUpdate = action.payload.data[0]?.dateUpdate ? action.payload.data[0]?.dateUpdate : dateParser({});
      state.loading = false;
      state.error = null;
    }
  });
  builder.addCase(performRequest.rejected, (state, action) => {
    state.error = action.payload;
    state.loading = false;
  });
};
