import * as React from 'react';
import { FlashMessageType } from '@atomic/mol.flash-message';

export enum FlashMessageTargetName {
  APP = 'app',
}

export interface FlashMessageStoreContent {
  type?: FlashMessageType;
  hidden: boolean;
  content?: string;
  alignRight?: boolean;
  small?: boolean;
}

export interface FlashMessageStoreState {
  [key: string]: FlashMessageStoreContent;
}

export interface FlashStoreProps {
  flash: FlashMessageStoreState;
  showMessage: (key: string, type: FlashMessageType, content: string, alignRight: boolean, small: boolean) => void;
  hideMessage: (key: string) => void;
}

export const useFlashStore = (): FlashStoreProps => {
  const [flash, setFlash] = React.useState({});

  const showMessage = (key: string, type: FlashMessageType, content: string, alignRight: boolean, small: boolean) => {
    const newFlash = { ...flash };
    newFlash[key] = { type, content, hidden: false, alignRight, small };
    setFlash(newFlash);
  };

  const hideMessage = (key: string) => {
    const newFlash = { ...flash };
    newFlash[key] = { hidden: true };
    setFlash(newFlash);
  };

  // const handleHideMessage = (key: string) => {
  //   return () => this.hideMessage(key);
  // };

  return { flash, showMessage, hideMessage };
};
