import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { weekDays } from '@app/modules/components/schedule-modal/schedule.constants';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import {
  addSelectedDaysOfWeek,
  removeSelectedDaysOfWeek,
} from '@app/modules/components/schedule-modal/store/schedule.store';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { TimeSelect } from '../components/time-select/time-select';
import { ScheduleWeekDayWrapper } from './weekly-schedule.style';

export const WeeklySchedule: React.FC = () => {
  const { selectedDaysOfWeek } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();

  const onValueChange = (value, checked) => {
    if (checked) {
      dispatch(removeSelectedDaysOfWeek(value));
    } else {
      dispatch(addSelectedDaysOfWeek(value));
    }
  };

  return (
    <FlexRow hAlign='center'>
      <FlexColumn vAlign='start'>
        <FlexRow mb={Spacing.Medium} vAlign='start' hAlign='start'>
          <Body bold='bold'>{strings.weeklyInstructions}</Body>
        </FlexRow>
        <FlexRow vAlign='baseline' hAlign='start'>
          <FlexRow vAlign='start' hAlign='start'>
            {weekDays.map(day => {
              const checked = selectedDaysOfWeek.includes(day);
              return (
                <ScheduleWeekDayWrapper key={day}>
                  <CheckboxField checked={checked} onValueChange={() => onValueChange(day, checked)} id={day}>
                    <Body> {strings.daysOfWeek[day]}</Body>
                  </CheckboxField>
                </ScheduleWeekDayWrapper>
              );
            })}
          </FlexRow>
          <FlexRow>
            <TimeSelect />
          </FlexRow>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  );
};
