import { IToastState } from '../toast.interfaces';
import { initialState } from './toast.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setToast: (state: IToastState, { payload }) => {
    state.show = payload.show;
    state.text = payload.text;
    state.variant = payload.variant;
    state.actionOneLabel = payload.actionOneLabel;
  },
};
