import ColorFunc from 'color';
import styled, { css } from 'styled-components';
import { Link } from '@app/core/route/link.component';
import { Border, Color, Spacing, FontFamily, FontSize, FontWeight } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';
import { ButtonProps } from './button.component';

const btnColors = (kind: string, outlined: boolean): { bg: string; border: string; text: string } => {
  const solidColors = {
    primary: { bg: Color.Primary, border: Color.Primary, text: Color.Secondary },
    secondary: { bg: Color.Secondary, border: Color.Secondary, text: Color.Primary },
    neutral: { bg: Color.Neutral, border: Color.Neutral, text: Color.Black },
    callToAction: { bg: Color.CallToAction, border: Color.CallToAction, text: Color.Accessory },
    accessory: { bg: Color.Accessory, border: Color.Accessory, text: Color.White },
    alert: { bg: Color.Alert, border: Color.Alert, text: Color.White },
    alertSecondary: { bg: Color.AlertLight, border: Color.AlertLight, text: Color.Alert },
    link: { bg: 'transparent', border: 'transparent', text: Color.Primary },
  };

  const outlinedColors = {
    primary: { bg: Color.White, border: Color.Primary, text: Color.Secondary },
    secondary: { bg: Color.White, border: Color.Secondary, text: Color.Secondary },
    neutral: { bg: Color.White, border: Color.Neutral, text: Color.Neutral },
    callToAction: { bg: Color.White, border: Color.CallToAction, text: Color.CallToAction },
    accessory: { bg: Color.White, border: Color.Accessory, text: Color.Accessory },
    alert: { bg: Color.White, border: Color.Alert, text: Color.Alert },
    alertSecondary: { bg: Color.White, border: Color.AlertLight, text: Color.AlertLight },
    link: { bg: 'transparent', border: 'transparent', text: Color.Primary },
  };

  if (outlined) {
    return outlinedColors[kind];
  }

  return solidColors[kind];
};

export const buttonHeight = '40px';
export const buttonSmallHeight = '30px';
export const ButtonStyledCss = css`
  position: relative;
  width: ${(props: ButtonProps) => (props.expanded ? '100%' : props.size === 'regularEqualSides' ? '40px' : 'auto')};
  min-height: ${(props: ButtonProps) => (props.size === 'small' ? buttonSmallHeight : buttonHeight)};
  padding: 0 ${Spacing.Medium};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  font-size: ${FontSize.Small};
  text-decoration: none;
  text-align: ${(props: ButtonProps) => (props.expanded ? 'center' : 'left')};
  ${highlightStyle}
  cursor: ${(props: ButtonProps) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props: ButtonProps) => (props.disabled || props.loading ? 0.5 : 1)};

  /* this is useful when its father is pointer-events: none */
  pointer-events: auto;
  color: ${(props: ButtonProps) => btnColors(props.kind, props.outlined).text};
  background-color: ${(props: ButtonProps) => btnColors(props.kind, props.outlined).bg};
  border-color: ${(props: ButtonProps) => btnColors(props.kind, props.outlined).border};
  border-style: solid;
  border-width: ${Border.Width};
  border-radius: ${(props: ButtonProps) => (props.radius === 'regular' ? Border.Radius : Border.RadiusLarge)};

  ${(props: ButtonProps) =>
    props.disabled
      ? null
      : `
    &:hover {
      color: ${ColorFunc(btnColors(props.kind, props.outlined).text)
        .darken(0.2)
        .hsl()
        .string()};
      background-color: ${ColorFunc(btnColors(props.kind, props.outlined).bg)
        .darken(0.1)
        .hsl()
        .string()};
    }

    &:active {
      color: ${ColorFunc(btnColors(props.kind, props.outlined).text)
        .darken(0.3)
        .hsl()
        .string()};
      background-color: ${ColorFunc(btnColors(props.kind, props.outlined).bg)
        .darken(0.2)
        .hsl()
        .string()};
    }

    &.active-status {
      color: ${ColorFunc(btnColors(props.kind, props.outlined).text)
        .darken(0.2)
        .hsl()
        .string()};
      background-color: ${ColorFunc(btnColors(props.kind, props.outlined).bg)
        .darken(0.1)
        .hsl()
        .string()};
    }
  `}
`;

export const ButtonStyled = styled.button`
  ${ButtonStyledCss}

  &:focus {
    outline: 0;
  }

  & + & {
    margin-left: ${Spacing.Small};
  }
`;

export const LinkButtonStyled = styled(Link)`
  ${ButtonStyledCss}
  display: flex;

  // This style improves how links are shown inside texts
  p > &,
  label > & {
    padding: 0;
    min-height: ${FontSize.Medium};
  }
`;

interface ButtonContentProps {
  processing: boolean;
}

export const ButtonContentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  opacity: ${(props: ButtonContentProps) => (props.processing ? 0 : 1)};
  font-stretch: 100%;

  & svg + *,
  & img ~ * {
    padding-left: ${Spacing.Small};
  }
`;

export const ButtonSpinnerStyled = styled.span`
  display: ${(props: ButtonContentProps) => (props.processing ? 'inline-block' : 'none')} !important;
  position: absolute;
  right: calc(50% - 15px);
  top: 0;
`;
