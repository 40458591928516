import * as React from 'react';
import {
  AdminMenuStyled,
  MenuItemActionStyled,
  MenuItemHelpStyled,
  MenuItemLogoStyled,
  MenuItemNavLinkStyled,
  MenuItemNavStyled,
  MenuItemSearchStyled,
  MenuItemTitleStyled,
  MenuStyled,
} from './menu.component.style';

type MenuProps = React.FC<{ hideSearch?: boolean }> & {
  Logo: any;
  Nav: any;
  NavLink: any;
  Action: any;
  Title: any;
  Search: any;
  Help: any;
};

export const Menu: MenuProps = MenuStyled;
Menu.Logo = MenuItemLogoStyled;
Menu.Nav = MenuItemNavStyled;
Menu.NavLink = MenuItemNavLinkStyled;
Menu.Action = MenuItemActionStyled;
Menu.Search = MenuItemSearchStyled;
Menu.Help = MenuItemHelpStyled;

export const AdminMenu: MenuProps = AdminMenuStyled;
AdminMenu.Logo = MenuItemLogoStyled;
AdminMenu.Title = MenuItemTitleStyled;
AdminMenu.Action = MenuItemActionStyled;
AdminMenu.Nav = MenuItemNavStyled;
