import { GridReadyEvent } from 'ag-grid-community';
import _ from 'lodash';
import { MutableRefObject } from 'react';
import { LogAnalytics } from '@app/core/analytics';
import { SalesOrderSummaryItem } from '@app/models';
import { NotificationPanelSteps } from '@app/models/notification-center.model';
import { ShippingRequestEvents } from '@app/modules/order/order.analytics';

interface ConfigChangeColumnValueParams {
  selectedRows: SalesOrderSummaryItem[];
  setSelectedRows: (value: SalesOrderSummaryItem[]) => void;
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  data: any;
  setData: (value: any) => void;
}
interface ChangeColumnValueParams {
  column: string;
  value: boolean;
}

export const configChangeColumnValue = ({
  selectedRows,
  setSelectedRows,
  gridEvent,
  data,
  setData,
}: ConfigChangeColumnValueParams) => ({ column, value }: ChangeColumnValueParams) => {
  const mappedSelectedOvs = selectedRows.map(order => order.ov);

  const mappedData = data.map(row => {
    const columnValue = mappedSelectedOvs.includes(row.ov) ? value : row[column];

    return {
      ...row,
      [column]: columnValue,
    };
  });

  setData(mappedData);

  setSelectedRows([]);
  gridEvent.current.api.deselectAll();
};

interface HandleActionSelectionSuccessParams {
  changeColumnValue: ({ column, value }: ChangeColumnValueParams) => void;
  selectedRows: SalesOrderSummaryItem[];
  customerIds?: string;
}

interface HandleActivationFirstTimeSuccessParams extends HandleActionSelectionSuccessParams {
  notificationConfigured?: boolean;
  updateConfiguration?: ({ showNotificationConfig }: { showNotificationConfig: boolean }) => void;
  setCurrentStep?: (value: NotificationPanelSteps) => void;
  setNotificationPanelOpen?: (value: boolean) => void;
  getConfiguration?: () => void;
}

export const handleActivationSuccess = ({
  notificationConfigured,
  updateConfiguration,
  setCurrentStep,
  setNotificationPanelOpen,
  getConfiguration,
  changeColumnValue,
  selectedRows,
}: HandleActivationFirstTimeSuccessParams): void => {
  if (!notificationConfigured) {
    updateConfiguration({ showNotificationConfig: true });
    setCurrentStep(NotificationPanelSteps.Configuration);
    setNotificationPanelOpen(true);
    getConfiguration();
  }

  const deactivatedRows = selectedRows.filter((row: SalesOrderSummaryItem) => !row.activateNotificationCol);

  deactivatedRows.forEach(item => {
    LogAnalytics.activateNotification({ ov_selected: item.ov });
  });

  changeColumnValue({ column: 'activateNotificationCol', value: true });
};

export const handleDeactivateSuccess = ({
  selectedRows,
  changeColumnValue,
}: HandleActionSelectionSuccessParams): void => {
  const activatedRows = selectedRows.filter((row: SalesOrderSummaryItem) => row.activateNotificationCol);

  activatedRows.forEach(item => {
    LogAnalytics.deactivateNotification({ ov_selected: item.ov });
  });

  changeColumnValue({ column: 'activateNotificationCol', value: false });
};

export const handleActivationShippingConfigSuccess = ({
  selectedRows,
  changeColumnValue,
  customerIds,
}: HandleActionSelectionSuccessParams): void => {
  const deactivatedRows = selectedRows.filter((row: SalesOrderSummaryItem) => !row.activateShipping);

  deactivatedRows.forEach(item => {
    LogAnalytics.activateShippingConfig({
      tipo: ShippingRequestEvents.ShippingRequestProgrammed,
      salesOrderDocuments: item.ov,
      salesOrderDocumentItem: item.itemOv,
      customerIds,
    });
  });

  changeColumnValue({ column: 'activateShipping', value: true });
};

export const handleDeactivationShippingConfigSuccess = ({
  selectedRows,
  changeColumnValue,
  customerIds,
}: HandleActionSelectionSuccessParams): void => {
  const activatedRows = selectedRows.filter((row: SalesOrderSummaryItem) => row.activateShipping);
  activatedRows.forEach(item => {
    LogAnalytics.deactivateShippingConfig({
      tipo: ShippingRequestEvents.CancelShippingRequestProgrammed,
      salesOrderDocuments: item.ov,
      salesOrderDocumentItem: item.itemOv,
      customerIds,
    });
  });

  changeColumnValue({ column: 'activateShipping', value: false });
};

export const disableActivateNotificationButton = (notificationItem: SalesOrderSummaryItem[]): boolean => {
  return (
    !!notificationItem.find(item => item.activateNotificationCol === true) &&
    !notificationItem.find(item => item.activateNotificationCol === false)
  );
};

export const disableDeactivateNotificationButton = (notificationItem: SalesOrderSummaryItem[]): boolean => {
  if (notificationItem.length) {
    return (
      !!notificationItem.find(item => item.activateNotificationCol === false) &&
      !notificationItem.find(item => item.activateNotificationCol === true)
    );
  }
  return true;
};

export const disableActivateShippingConfigButton = (row: SalesOrderSummaryItem[]): boolean => {
  return !!row.find(item => item.activateShipping === true) && !row.find(item => item.activateShipping === false);
};

export const disableDeactivateShippingConfigButton = (row: SalesOrderSummaryItem[]): boolean => {
  if (row.length) {
    return !!row.find(item => item.activateShipping === false) && !row.find(item => item.activateShipping === true);
  }
  return true;
};
