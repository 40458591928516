import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { setOpenBPsModal } from '@app/providers/navigation/navigation.store';
import { CustomerSearchModalAdminComponent } from './customer-search-modal-admin.component';

export const CustomerSearchModalAdmin: React.FC = () => {
  const { openBPsModal } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();
  const onClose = () => dispatch(setOpenBPsModal(false));

  return <CustomerSearchModalAdminComponent opened={openBPsModal} onClose={onClose} />;
};
