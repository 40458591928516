import styled from 'styled-components';
import { Color, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';

export const DaySelectWrapper = styled.div`
  height: 30px;
  width: 120px;
  border: 1px solid ${Color.Gray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: ${Spacing.Small};
  cursor: pointer;
`;

export const DaysSelected = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 0;
  height: 25px;
  font-family: ${FontFamily.Primary};
  color: ${Color.GrayDark};
  font-size: ${FontSize.Small};
`;
