import { format, addDays, formatDistanceToNow, getDay } from 'date-fns';
import { ptBR, enUS, es } from 'date-fns/locale';
import * as React from 'react';
import { PriorityTimes, PriorityTypes } from '@app/models/quote-filters.model';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { QuoteStrings } from '../quote.string';
import { holidays } from '../utils/holidays';

interface QuoteTimeLimitProps {
  priority: string;
  language: string;
  createdDate?: Date;
  priorityDate?: Date;
}

interface TimeItemProps {
  dateString?: { label: string; diff?: number };
}

export const TimeItem = (props: TimeItemProps) => {
  const color = props.dateString.diff && props.dateString.diff <= 1 ? Color.Alert : Color.Black;
  return (
    <Body color={color}>
      <FaIcon.Clock size='1x' color={color} />
      {props.dateString.label}
    </Body>
  );
};

export const QuoteTimeLimit: React.FC<QuoteTimeLimitProps> = props => {
  let lang = ptBR;
  switch (props.language) {
    case 'pt':
      lang = ptBR;
      break;
    case 'in':
      lang = enUS;
      break;
    case 'es':
      lang = es;
      break;
  }
  const formatStringTime = (date: string, language: string) => {
    const stringArray = date.split(' ');

    if (stringArray[1] === '1') {
      return QuoteStrings[language].timeLimit.tomorrow;
    } else if (stringArray[1] === '0') {
      return QuoteStrings[language].timeLimit.today;
    } else {
      return `${QuoteStrings[language].timeLimit.deadline} ${date}`;
    }
  };

  const workDays = (dateStart: Date, days: number) => {
    let add = days;

    let weekEnds: number;
    for (let i = 1; i <= days; i++) {
      weekEnds = getDay(addDays(dateStart, i));
      const actualDate = format(addDays(dateStart, i), 'yyyy-MM-dd');

      if (holidays.includes(actualDate)) {
        if (weekEnds !== 0 && weekEnds === 6) {
          add = add + 1;
        }
      }

      if (weekEnds === 0 || weekEnds === 6) {
        add = add + 1;
      }
    }

    const provDate = getDay(addDays(dateStart, add));

    if (provDate === 0) {
      add = add + 1;
    } else if (provDate === 6) {
      add = add + 2;
    }
    return add;
  };

  const priorityCheck = (priorityOption: string) => {
    switch (priorityOption) {
      case `${PriorityTypes.A}`:
        return PriorityTimes.A;
      case `${PriorityTypes.B}`:
        return PriorityTimes.B;
      case `${PriorityTypes.C}`:
        return PriorityTimes.C;
      case `${PriorityTypes.GQ}`:
        return PriorityTimes.GQ;
      default:
        return null;
    }
  };

  const formatTime = (createdDate: Date, priorityDate: Date, priorityOption: string, language: string) => {
    const today = format(createdDate, language === 'in' ? 'MM/dd/yyyy' : 'dd/MM/yyyy');

    let daysPriority = 0;
    if (priorityOption) {
      daysPriority = priorityCheck(priorityOption);

      let daysToAdd = 0;

      if (!priorityDate) {
        daysToAdd = priorityOption === 'GQ' ? daysPriority : workDays(createdDate, daysPriority);
      } else {
        daysToAdd = 0;
      }

      const dataLimite = !priorityDate ? addDays(new Date(createdDate), daysToAdd) : priorityDate;

      if (new Date() < dataLimite) {
        return {
          label: `${formatStringTime(
            formatDistanceToNow(new Date(dataLimite), { addSuffix: true, locale: lang }),
            props.language,
          )}`,
          diff: 2,
        };
      } else {
        return {
          label: `${QuoteStrings[language].timeLimit.expired} ${formatDistanceToNow(new Date(dataLimite), {
            addSuffix: true,
            locale: lang,
          })}`,
          diff: -1,
        };
      }
    } else {
      return {
        label: `${QuoteStrings[language].timeLimit.date} ${today}`,
        diff: 2,
      };
    }
  };

  return (
    <>
      <TimeItem dateString={formatTime(props.createdDate, props.priorityDate, props.priority, props.language)} />
      <Separator small />
    </>
  );
};
