import React from 'react';
import { SelectOption } from '@app/modules/order-input/order-input.interfaces';
import { ShippingRequestReceiverOptions } from './shipping-request-receiver-options.component';
import { ShippingRequestReceiverShimmer } from './shipping-request-receiver-shimmer.component';

interface IShippingRequestReceiverModalBodyParams {
  receiverOptions: SelectOption[];
  loading: boolean;
  selectedReceiver: string;
  setselectedReceiver: (value: string) => void;
}

export const ShippingRequestReceiverModalBody: React.FC<IShippingRequestReceiverModalBodyParams> = ({
  receiverOptions,
  loading,
  selectedReceiver,
  setselectedReceiver,
}) => {
  if (loading) {
    return <ShippingRequestReceiverShimmer />;
  }

  return (
    <ShippingRequestReceiverOptions
      selectedReceiver={selectedReceiver}
      setselectedReceiver={setselectedReceiver}
      receiverOptions={receiverOptions}
    />
  );
};
