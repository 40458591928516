export const ChatStrings = {
  pt: {
    messageField: 'Digite aqui sua mensagem…',
    uploadClickButton: 'Clique aqui ou arraste o arquivo',
    uploadDropButton: 'Solte o arquivo aqui...',
    loading: {
      initialize: 'Carregando consulta técnica',
      finish: 'Aguarde enquanto analisamos sua solicitação',
      analyzing: 'Aguarde, estamos analisando o seu arquivo.',
    },
    error: {
      attributeEdition: 'Erro ao editar característica, favor conferir se os dados estão corretos.',
      attributeDeletion: 'Erro ao deletar característica, tente novamente mais tarde.',
      attributeReset: 'Erro ao resetar a consulta, tente novamente mais tarde.',
    },
    modalDragable: {
      labelDefault: 'Arraste aqui',
      label:
        'Para verificar o documento e ter mais flexibilidade, você pode mover esta janela para qualquer parte da tela.',
    },
    jominy: {
      title: 'Jominy',
      milimeters: 'Milímetros',
      inches: 'Polegadas',
      addMeasure: 'Adicionar medida',
      reset: 'Resetar',
      save: 'Salvar',
      errorData: 'Pelo menos um item deve ser preenchido.',
    },
    modalAttributes: {
      title: 'Selecione uma característica',
      titleAdd: 'Adicionar característica',
      btnSelect: 'Selecionar característica',
      btnAdd: 'Adicionar outra característica',
      btnAddSubmit: 'Adicionar',
      btnBack: 'Voltar',
      alertMandatory: 'Selecione uma característica',
      alertMandatory2: 'Este campo é obrigatório',
      alertInvalidEmail: 'E-mail inválido',
      alertSpecialChars: 'Não utilize caracteres especiais como % ! * ( ) ; : @ & = + $ , / ? # [ ] < >',
      fieldTitle: {
        label: 'Título',
        placeholder: 'Adicione aqui o nome da característica',
      },
      fieldValue: {
        label: 'Valores',
        placeholder: 'Adicione as informações necessárias à esta característica',
      },
    },
    modalComposition: {
      titleResume: 'Composição química',
      title: 'Adicionar composição química',
      text: 'Selecione os elementos químicos que deseja adicionar e suas especificações técnicas.',
      btnAdd: 'Adicionar elemento',
      btnSave: 'Salvar',
      btnSelectedElements: 'Adicionar elementos selecionados',
      btnBack: 'Voltar',
      fields: {
        element: 'Elemento',
        min: 'Mínimo',
        max: 'Máximo',
        unit: 'Unidade',
        alert: 'Obrigatório',
        alertMax: 'Verificar os valores máximos e mínimos digitados na Composição Química.',
        alertMinMoreMax: 'Os valores mínimos não podem ser maiores ou iguais aos valores máximos.',
      },
      tabs: ['Elementos simples', 'Elementos compostos'],
      searchPlaceholder: 'Busque pelo elemento',
      searchNoResults: 'Nenhum elemento encontrado.',
      typeElement: ['simples', 'composto'],
    },
    modalMicroinclusao: {
      title: 'Microinclusão',
      btnSubmit: 'Salvar',
      btnReset: 'Resetar',
      btnAdd: 'Adicionar outros valores',
      selectOptions: ['ASTM', 'Outros'],
      thin: 'Fino',
      thick: 'Grosso',
    },
    modalMechanical: {
      title: 'Propriedades mecânicas',
      btnSubmit: 'Salvar',
      btnReset: 'Resetar',
      selectOptions: ['Ensaios padrão', 'Outros'],
      units: { KGFMM: 'Kgf/mm²', KSI: 'ksi', MPA: 'MPa', NMM: 'N/mm²', PSI: 'psi' },
      mandatory: 'Medida obrigatória',
    },
    modalDiameter: {
      title: 'Adicionar Bitolas',
      to: 'a',
      addMeasure: 'Adicionar bitola',
      bitola: 'Bitola',
      main: 'Principal',
      espessura: 'Espessura',
      diameter: 'Diâmetro ou lado',
      largura: 'Largura',
      comprimento: 'Comprimento',
      consumo: 'Consumo',
      consumoTon: 'T/ano',
      min: 'Min.',
      max: 'Max.',
      remove: 'Excluir',
      save: 'Salvar',
      errorData: 'Pelo menos um item deve ser preenchido.',
      alertMandatory2: 'Esse campo é obrigatório',
      alertSpecialChars: 'Não utilize caracteres especiais como % ! * ( ) ; : @ & = + $ , / ? # [ ] < >',
    },
    modalGenericValues: {
      title: 'Adicionar valores',
      btnAdd: 'Adicionar valores',
      btnBack: 'Voltar',
      alertMandatory: 'Este campo é obrigatório',
      fieldTitle: {
        label: 'Título',
        placeholder: 'Adicione aqui o nome',
      },
      fieldValue: {
        label: 'Valores',
        placeholder: 'Adicione as informações necessárias',
      },
    },
    AUSTENITE_GRAIN_SIZE_STRING: 'ou mais fino',
    modalPlants: {
      btnSave: 'Salvar análise',
      btnCancel: 'Cancelar',
      resume: 'Gerdau - Resumo',
      anyPlant: 'Qualquer planta',
    },
    metallurgicalProperties: 'Propriedades Metalúrgicas',
    testMethods: 'Tipos de Ensaio',
    other: 'Outros',
    product: 'Produto',
    modalMacroataque: {
      title: 'Macroataque',
      btnSave: 'Salvar',
      btnReset: 'Resetar',
      mandatory: 'Obrigatório',
      valueColumn: 'Valor',
    },
    fileNorm: {
      title: 'Norma anexada',
    },
    resumeAnalysis: {
      tensile: 'Limite de resistência',
      yield: 'Limite de escoamento',
      reduction: 'Redução de área',
      elongation: 'Alongamento',
      resume: 'Resumo da análise',
      reset: 'Resetar consulta',
      finish: 'Finalizar consulta',
    },
    alertTry: 'Algo deu errado, tente novamente mais tarde',
    alertEdit:
      'Ao editar esta consulta, você perderá todos comentários, adições de características na tela de análise técnica e demais alterações realizadas',
    alertSelect: 'Selecione pelo menos uma opção antes de enviar',
  },
  in: {
    messageField: 'Enter your message here…',
    uploadClickButton: 'Click here or drag the file',
    uploadDropButton: 'Drop the file here...',
    loading: {
      initialize: 'Loading technical request',
      finish: 'Please wait while we review your request',
      analyzing: 'Please wait, we are analyzing your file.',
    },
    error: {
      attributeEdition: 'Editing error, please check if the information is correct.',
      attributeDeletion: 'Deleting error, please try again later.',
      attributeReset: 'Resetting error, please try again later.',
    },
    modalDragable: {
      labelDefault: 'Drag here',
      label: 'To check the document and have more flexibility, you can move this modal to any part of the screen.',
    },
    jominy: {
      title: 'Jominy',
      milimeters: 'Millimeter',
      inches: 'Inches',
      addMeasure: 'Add measure',
      reset: 'Reset',
      save: 'Save',
      errorData: 'At least one item must be completed.',
    },
    modalAttributes: {
      title: 'Select a requirement',
      titleAdd: 'Add requirement',
      btnSelect: 'Select requirement',
      btnAdd: 'Add another requirement',
      btnAddSubmit: 'Add',
      btnBack: 'Back',
      alertMandatory: 'Select a requirement',
      alertMandatory2: 'This field is mandatory',
      alertInvalidEmail: 'Invalid email',
      alertSpecialChars: 'Do not use special characters % ! * ( ) ; : @ & = + $ , / ? # [ ] < >',
      fieldTitle: {
        label: 'Title',
        placeholder: 'Add requirement name here',
      },
      fieldValue: {
        label: 'Values',
        placeholder: 'Add the required information to this feature',
      },
    },
    modalComposition: {
      titleResume: 'Chemical composition',
      title: 'Add chemical composition',
      text: 'Select the chemical elements and their technical specifications.',
      btnAdd: 'Add element',
      btnSave: 'Save',
      btnSelectedElements: 'Add selected elements',
      btnBack: 'Back',
      fields: {
        element: 'Element',
        min: 'Minimum',
        max: 'Maximum',
        unit: 'Unit',
        alert: 'Mandatory',
        alertMax: 'Check the maximum and minimum values in the Chemical Composition.',
        alertMinMoreMax: 'Minimum values cannot be greater than or equal to maximum values.',
      },
      tabs: ['Simple elements', 'Compound elements'],
      searchPlaceholder: 'Search for element',
      searchNoResults: 'No elements found.',
      typeElement: ['simple', 'compound'],
    },
    modalMicroinclusao: {
      title: 'Microinclusion',
      btnSubmit: 'Save',
      btnReset: 'Reset',
      btnAdd: 'Add others values',
      selectOptions: ['ASTM', 'Others'],
      thin: 'Thin',
      thick: 'Thick',
    },
    modalMechanical: {
      title: 'Mechanical properties',
      btnSubmit: 'Save',
      btnReset: 'Reset',
      selectOptions: ['Standard tests', 'Others'],
      units: { KGFMM: 'Kgf/mm²', KSI: 'ksi', MPA: 'MPa', NMM: 'N/mm²', PSI: 'psi' },
      mandatory: 'Mandatory measure',
    },
    modalDiameter: {
      title: 'Add Size',
      to: 'to',
      addMeasure: 'Add size',
      bitola: 'Size',
      main: 'Main',
      espessura: 'Thickness',
      diameter: 'Size or side',
      largura: 'Width',
      comprimento: 'Length',
      consumo: 'Consumption',
      consumoTon: 'T/year',
      min: 'Min.',
      max: 'Max.',
      remove: 'Delete',
      save: 'Save',
      errorData: 'At least one item must be completed.',
      alertMandatory2: 'This field is mandatory',
      alertSpecialChars: 'Do not use special characters % ! * ( ) ; : @ & = + $ , / ? # [ ] < >',
    },
    modalGenericValues: {
      title: 'Add values',
      btnAdd: 'Add values',
      btnBack: 'Back',
      alertMandatory: 'This field is mandatory',
      fieldTitle: {
        label: 'Title',
        placeholder: 'Add name here',
      },
      fieldValue: {
        label: 'Values',
        placeholder: 'Add the necessary information',
      },
    },
    AUSTENITE_GRAIN_SIZE_STRING: 'or thinner',
    modalPlants: {
      btnSave: 'Save analysis',
      btnCancel: 'Cancel',
      resume: 'Gerdau - Summary',
      anyPlant: 'Any plant',
    },
    metallurgicalProperties: 'Metallurgical Properties',
    testMethods: 'Test Types',
    other: 'Others',
    product: 'Product',
    modalMacroataque: {
      title: 'Macroattack',
      btnSave: 'Save',
      btnReset: 'Reset',
      mandatory: 'Mandatory',
      valueColumn: 'Value',
    },
    fileNorm: {
      title: 'Attached specification',
    },
    resumeAnalysis: {
      tensile: 'Tensile strength',
      yield: 'Yield strength',
      reduction: 'Reduction area',
      elongation: 'Elongation',
      resume: 'Analysis summary',
      reset: 'Reset request',
      finish: 'End request',
    },
    alertTry: 'Something went wrong, try again later',
    alertEdit:
      'When editing this request, you will lose all comments, feature additions on the technical analysis screen and other changes',
    alertSelect: 'Select at least one option before submitting',
  },
  es: {
    messageField: 'Escriba su mensaje aquí...',
    uploadClickButton: 'Haga clic aquí o arrastre el archivo',
    uploadDropButton: 'Suelta el archivo aquí...',
    loading: {
      initialize: 'Cargando consulta técnica',
      finish: 'Espere mientras revisamos su solicitud',
      analyzing: 'Espere, estamos analizando su expediente.',
    },
    error: {
      attributeEdition: 'Error de edición de la caracteristica, verifique si los datos son correctos.',
      attributeDeletion: 'No se ha podido eliminar la caracteristica. Vuelva a intentarlo más tarde.',
      attributeReset: 'Error al restablecer la consulta, inténtalo de nuevo más tarde.',
    },
    modalDragable: {
      labelDefault: 'Arrastre aquí',
      label:
        'Para consultar el documento y tener más flexibilidad, puede mover esta ventana a cualquier parte de la pantalla.',
    },
    jominy: {
      title: 'Jominy',
      milimeters: 'Milímetro',
      inches: 'Pulgadas',
      addMeasure: 'Agregar medida',
      reset: 'Reiniciar',
      save: 'Guardar',
      errorData: 'Se debe completar al menos un item.',
    },
    modalAttributes: {
      title: 'Seleccione una característica',
      titleAdd: 'Agregar característica',
      btnSelect: 'Seleccionar característica',
      btnAdd: 'Agregar otra característica',
      btnAddSubmit: 'Agregar',
      btnBack: 'Volver',
      alertMandatory: 'Seleccione una característica',
      alertMandatory2: 'Este campo es obligatorio',
      alertInvalidEmail: 'Email inválido',
      alertSpecialChars: 'No utilice caracteres especiales como % ! * ( ) ; : @ & = + $ , / ? # [ ] < >',
      fieldTitle: {
        label: 'Título',
        placeholder: 'Agregue aquí el nombre de la característica',
      },
      fieldValue: {
        label: 'Valores',
        placeholder: 'Agregue la información necesaria a esta característica',
      },
    },
    modalComposition: {
      titleResume: 'Composición química',
      title: 'Agregar composición química',
      text: 'Seleccione los elementos químicos que desea agregar y sus especificaciones técnicas.',
      btnAdd: 'Agregar elemento',
      btnSave: 'Guardar',
      btnSelectedElements: 'Agregar elementos seleccionados',
      btnBack: 'Volver',
      fields: {
        element: 'Elemento',
        min: 'Mínimo',
        max: 'Máximo',
        unit: 'Unidad',
        alert: 'Obligatorio',
        alertMax: 'Consultar los valores máximos y mínimos ingresados en la Composición Química.',
        alertMinMoreMax: 'Los valores mínimos no pueden ser mayores o iguales a los valores máximos.',
      },
      tabs: ['Elementos simples', 'Elementos compuestos'],
      searchPlaceholder: 'Busqueda de elemento',
      searchNoResults: 'Ningún elemento encontrado.',
      typeElement: ['simple', 'compuesto'],
    },
    modalMicroinclusao: {
      title: 'Microinclusión',
      btnSubmit: 'Guardar',
      btnReset: 'Resetar',
      btnAdd: 'Agregar otros valores',
      selectOptions: ['ASTM', 'Otros'],
      thin: 'Delgado',
      thick: 'Grueso',
    },
    modalMechanical: {
      title: 'Propiedades mecánicas',
      btnSubmit: 'Guardar',
      btnReset: 'Resetar',
      selectOptions: ['Pruebas estándar', 'Otros'],
      units: { KGFMM: 'Kgf/mm²', KSI: 'ksi', MPA: 'MPa', NMM: 'N/mm²', PSI: 'psi' },
      mandatory: 'Medida obligatoria',
    },
    modalDiameter: {
      title: 'Agregar Calibre',
      to: 'a',
      addMeasure: 'Adicionar calibre',
      bitola: 'Calibre',
      main: 'Principal',
      espessura: 'Espesor',
      diameter: 'Diámetro o lado',
      largura: 'Ancho',
      comprimento: 'Longitud',
      consumo: 'Consumo',
      consumoTon: 'T/año',
      min: 'Min.',
      max: 'Max.',
      remove: 'Suprimir',
      save: 'Salvar',
      errorData: 'Pelo menos um item deve ser preenchido.',
      alertMandatory2: 'Este campo es obligatorio',
      alertSpecialChars: 'No utilice caracteres especiales como % ! * ( ) ; : @ & = + $ , / ? # [ ] < >',
    },
    modalGenericValues: {
      title: 'Agregar valores',
      btnAdd: 'Agregar valores',
      btnBack: 'Volver',
      alertMandatory: 'Este campo es obligatorio',
      fieldTitle: {
        label: 'Título',
        placeholder: 'Agregue el nombre aquí',
      },
      fieldValue: {
        label: 'Valores',
        placeholder: 'Agregue la información necesaria',
      },
    },
    AUSTENITE_GRAIN_SIZE_STRING: 'o más delgado',
    modalPlants: {
      btnSave: 'Guardar análisis',
      btnCancel: 'Cancelar',
      resume: 'Gerdau - Resumen',
      anyPlant: 'Cualquier planta',
    },
    metallurgicalProperties: 'Propiedades metalúrgicas',
    testMethods: 'Tipo de Prueba',
    other: 'Otros',
    product: 'Producto',
    modalMacroataque: {
      title: 'Macroataque',
      btnSave: 'Guardar',
      btnReset: 'Reiniciar',
      mandatory: 'Obligatorio',
      valueColumn: 'Valor',
    },
    fileNorm: {
      title: 'Especificación adjunta',
    },
    resumeAnalysis: {
      tensile: 'Resistencia a la tracción',
      yield: 'Límite elástico',
      reduction: 'Reducción de área',
      elongation: 'Alargamiento',
      resume: 'Resumen de análisis',
      reset: 'Reiniciar consulta',
      finish: 'Cerrar consulta',
    },
    alertTry: 'Algo salió mal, inténtalo de nuevamente',
    alertEdit:
      'Al editar esta consulta, perderá todos los comentarios, funciones adicionales en la pantalla de análisis técnico y otros cambios realizados.',
    alertSelect: 'Seleccione al menos una opción antes de enviar',
  },
};
