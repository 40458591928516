import * as React from 'react';
import { __RouterContext as RouterContext } from 'react-router';
import { Link as LinkRouter, LinkProps } from 'react-router-dom';
import Container from 'typedi';
import { Router } from './router';

/**
 * This code is an adaptation of the original Link (from react-router-dom)
 * with minor adjustments to enable relative paths.
 */
export const Link: React.FC<LinkProps> = props => {
  const router: Router = Container.get(Router);
  const context = React.useContext(RouterContext);
  const isExternal = (toHref: any) => {
    if (typeof toHref === 'string') {
      return toHref.indexOf('://') > -1 || toHref.indexOf('//') > 0;
    }
    return false;
  };

  const { replace, to, target, ...others } = props;

  const stringProps = Object.keys(others).reduce((acc, key) => {
    if (typeof props[key] === 'boolean' && key !== 'disabled') {
      acc[key] = props[key] ? 'true' : 'false';
    } else {
      acc[key] = props[key];
    }
    return acc;
  }, {});

  const hRef = to === undefined ? '#' : to;

  if (!context) {
    return null;
  }
  const resolvedTo = router.resolve(context.match.url, hRef as string);

  if (isExternal(to)) {
    return <a href={hRef as string} target={target} {...stringProps} />;
  }

  return <LinkRouter {...stringProps} to={resolvedTo} target={target} />;
};
