import { ColDef } from 'ag-grid-community';
import { sizeInFormatter, valueQtyFormatter } from '@app/utils/order-column-formatter';

export const handleMaritmeHarborTabColumn = (columns: ColDef[], language: string) => {
  const valueColumnsFields = ['nfQuantity', 'size', 'width', 'thickness'];

  if (columns) {
    columns.forEach(column => {
      if (column.field === 'sizeIn') {
        column.valueFormatter = param => sizeInFormatter(param, language);
      }

      if (valueColumnsFields.includes(column.field)) {
        column.valueFormatter =
          language === 'in' ? param => valueQtyFormatter(param, true) : param => valueQtyFormatter(param, false);
      }
    });
  }
};
