import { ColDef, GridReadyEvent } from 'ag-grid-community';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { EventTypes } from '@app/models/analytics.model';
import { TabIndex } from '@app/models/order.model';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { isGKNSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { renderSubtotalField } from '@app/modules/order/grid-utils/grid-subtotal-field-render';
import { ShippingRequestPage } from '@app/modules/shipping-request';
import { setOpenShippingFeedback } from '@app/modules/shipping-request/store/shipping-request.store';
import { Feedback, FeedbackAnalytics, FeedbackType } from '@atomic/obj.feedback';
import { Visibility } from '@atomic/obj.visibility';
import { OrdersStrings } from '../../../orders.string';
import { OrderTabsHeaderMI } from '../order-tabs-header-MI.component';
import { OrderTabStockComponent } from './order-tab-stock.component';
import { tabName } from './order-tab-stock.constants';
import { performRequest } from './store/order-tab-stock.thunks';
import { setBackgroundCellRules } from './utils/action-utils';
import { handleFilter, handleFilterCheck } from './utils/handler-utils';

export const OrderTabStock = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const clientBpsSelectedJoined = useSelector(customerIdsJoinedSelector);
  const { data, lastUpdate, filter, filteredRows, selectedRows } = useSelector(
    (state: RootState) => state.orderTabStock,
  );
  const { confirmedFilters } = useSelector((state: RootState) => state.advancedFilters[tabName]);
  const { openShippingFeedback, showShippingRequest } = useSelector((state: RootState) => state.shippingRequest);
  const isGKN = useSelector(isGKNSelector);
  const dispatch = useDispatch<AppThunkDispatch>();

  const gridEvent = useRef<GridReadyEvent>(null);
  const columns: ColDef[] = useSelector(columnsTabLayoutSelector(tabName, userInfo?.language));

  const calculateSubtotals = () =>
    renderSubtotalField({
      tab: tabName,
      itens: filteredRows,
      language: userInfo?.language,
      columnDef: columns,
      isGKN,
    });

  setBackgroundCellRules(columns);

  useEffect(() => {
    dispatch(performRequest());
  }, [clientBpsSelectedJoined]);

  useEffect(() => {
    calculateSubtotals();
  }, [filteredRows]);

  useEffect(() => {
    handleFilter({ gridEvent, filter, handleFilterCheck });
  }, [filter, data, confirmedFilters]);

  useEffect(() => {
    if (selectedRows.length) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (!showShippingRequest && gridEvent) {
      gridEvent?.current?.api?.deselectAll();
    }
  }, [showShippingRequest, gridEvent]);

  useEffect(() => {
    if (gridEvent.current) {
      gridEvent.current.api.onFilterChanged();
    }
  }, [data]);

  return (
    <>
      {showShippingRequest && <ShippingRequestPage />}
      <Visibility visible={!showShippingRequest}>
        <OrderTabsHeaderMI lastUpdate={lastUpdate} tabIndex={TabIndex.Stock} />
        <OrderTabStockComponent gridEvent={gridEvent} />
        <Feedback
          opened={openShippingFeedback}
          setOpened={value => dispatch(setOpenShippingFeedback(value))}
          context={FeedbackAnalytics.ShippingRequest}
          type={FeedbackType.Numeric}
          message={OrdersStrings[userInfo?.language]?.common.shippingRequestFeedback}
          analyticsType={EventTypes.ShippingRequestFeedback}
        />
      </Visibility>
    </>
  );
};
