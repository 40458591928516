import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AppPath } from '@app/modules/app/route-constants';
import { setIsMaritime } from '@app/modules/components/customer-search/store/customer-persist.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Menu } from '@atomic/mol.menu';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { IOrderMenuMeStrings, Strings } from './order-menu-me.string';
import { AppConfigurationOptionsStyled } from './order-menu-me.style';

interface SubmenuProps {
  text: string;
  path: string;
}

const preventRerender = (e: React.MouseEvent<HTMLLinkElement>, to: string) => {
  if (location.pathname === to) {
    e.preventDefault();
  }
};

export const OrderMenuMe: React.FC<SubmenuProps> = ({ path }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { isMaritime } = useSelector((state: RootState) => state.customerPersist);
  const dispatch = useDispatch();

  const strings: IOrderMenuMeStrings = Strings[userInfo.language];

  const [show, setShow] = useState<boolean>(false);
  const submenuRef = useRef(null);

  const onClickMenu = e => {
    preventRerender(e, AppPath.ORDERS);
    setShow(!show);
  };

  return (
    <Menu.NavLink onClick={onClickMenu} active={location.pathname === path} to={path}>
      <FlexRow>
        {isMaritime ? strings.orderMaritimeText : strings.orderRoadText}
        <FlexRow ml={Spacing.Small}>
          <FaIcon.ChevronDown />
        </FlexRow>
      </FlexRow>
      <AppConfigurationOptionsStyled isMaritime={isMaritime} show={show} ref={submenuRef}>
        <VSeparator />
        <FlexRow onClick={() => dispatch(setIsMaritime(!isMaritime))} vAlign='center'>
          <FlexRow.Separator />
          <FlexColumn onClick={() => setShow(!show)} noGrow>
            {isMaritime ? <FaIcon.Truck size='1x' color={Color.Gray} /> : <FaIcon.Ship size='1x' color={Color.Gray} />}
          </FlexColumn>
          <FlexRow.Separator />
          <FlexColumn>
            <Body>{isMaritime ? strings.orderRoadText : strings.orderMaritimeText}</Body>
          </FlexColumn>
          <FlexRow.Separator />
        </FlexRow>
        <VSeparator />
      </AppConfigurationOptionsStyled>
    </Menu.NavLink>
  );
};
