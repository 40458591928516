import * as React from 'react';
import { DashboardLegendData } from '@app/models/dashboard.model';
import { Hbox } from '@atomic/obj.box';
import { Grid } from '@atomic/obj.grid';
import { DashboardLegend } from './dashboard-legend.component';

interface DashboardGroupLegendProps {
  legend: DashboardLegendData[];
  graph: string;
}

export const DashboardGroupLegend: React.FC<DashboardGroupLegendProps> = props => {
  return (
    <Grid fluid>
      <Hbox>
        {props.legend.map((item, index) => (
          <React.Fragment key={index}>
            <Hbox.Item vAlign='center'>
              <DashboardLegend legendData={item} graph={props.graph} />
            </Hbox.Item>
            <Hbox.Separator />
          </React.Fragment>
        ))}
      </Hbox>
    </Grid>
  );
};
