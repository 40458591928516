export const SLICE_NAME = 'order-input';

export const HANDLE_REGEX_NUMBER = /^\d{0,9}[,]?\d{0,9}$/;

export const AMOUNT_TABLE_AMOUNT_TOOLTIP_TOP_POSITION_REF = 44;
export const AMOUNT_TABLE_AMOUNT_TOOLTIP_LEFT_POSITION_REF = 195;
export const AMOUNT_TABLE_AMOUNT_TOOLTIP_ARROW_LEFT_POSITION_REF = 238;
export const AMOUNT_TABLE_AMOUNT_TOOLTIP_WIDTH = 300;

export const AMOUNT_TABLE_DATE_TOOLTIP_TOP_POSITION_REF = 52;
export const AMOUNT_TABLE_DATE_TOOLTIP_LEFT_POSITION_REF = 80;
export const AMOUNT_TABLE_DATE_TOOLTIP_ARROW_LEFT_POSITION_REF = 150;
export const AMOUNT_TABLE_DATE_TOOLTIP_WIDTH = 300;

export const MULTIPLE_ALERT_MODAL_WIDTH = '624px';

export enum MaterialUnity {
  Kg = 'KG',
  Ton = 'T',
}

export enum MaterialCell {
  Description = '1',
  Amount = '2',
  Unity = '3',
  Date = '4',
  OrderNumber = '5',
}

export enum MaterialCellError {
  MaterialNotFound = '1',
  DateOutOfRange = '2',
  TonQtyOutOfRange = '3',
  KgQtyOutOfRange = '4',
  InvalidUnity = '5',
  InvalidDateFormat = '6',
  RequiredField = '7',
  PastDate = '8',
  BusinessDay = '9',
  InvalidDate = '10',
  MinKGAmount = '11',
  MinTonAmount = '12',
}

export enum OrderInputPhaseEnum {
  Options = 'options',
  ItensSelection = 'itensSelection',
  UploadSpreadsheet = 'uploadSpreadsheet',
  UploadPdfEmail = 'uploadPdfEmail',
  DataAndAmout = 'dataAndAmout',
  Multiple = 'multiple',
  Resume = 'resume',
  Success = 'success',
  OrderInputAmount = 'OrderInputAmount',
}

export enum OrderInputImplantationTypeEnum {
  ItemSelection = 'selecao-itens',
  UploadSpreadsheet = 'upload-planilha',
}

export enum SearchByMaterial {
  CUSTOMER_MATERIAL_CODE = 'customerMaterialCode',
  SALES_DOCUMENT_ITEM_TEXT = 'salesDocumentItemText',
}

export const materialInitialTemplate = {
  amount: null,
  unity: null,
  date: null,
  messages: [],
};

export enum MaterialType {
  CodProdClient = 'codProdCliente',
  CodMatGerdau = 'codMatGerdau',
}

export const MUSASHI_CUSTOMER_ID = '0100500181';
export const MUSASHI_REPLACE_CUSTOMER_ID = '0000004498';

export const BRSD_SALES_ORG = 'BRSD';
export const BRSI_SALES_ORG = 'BRSI';
export const BRSA_SALES_ORG = 'BRSA';
