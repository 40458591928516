import styled from 'styled-components';
import { Border, Color, FontFamily, FontSize } from '@atomic/obj.constants';

export const AttributeCellFormWrapperStyled = styled.div`
  width: 100%;
  display: block;
`;

export const UploadedImages = styled.div`
  padding: 24px 0 16px 16px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const DropzoneWrapperStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  & > div {
    justify-content: center;
    text-align: center;
  }
`;

export const AddImageContainer = styled.div`
  position: relative;
  & > div {
    position: absolute;
    opacity: 0;
    z-index: 2;
    width: 24px;
    height: 24px;
    padding: 0;
    cursor: pointer;
  }
  & > button {
    min-height: 24px;
    width: 24px;
    border-radius: 50%;
    height: 24px;
    padding: 0;
    margin: 16px 0;
    color: ${Color.White};
    &:hover {
      color: ${Color.White};
    }
  }
`;

export const PreviewContainerStyled = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  ${props => props.bg && `background-image: url('${props.bg}'); background-size: cover;`}
  margin: 16px 20px 6px 6px;
  border: 2px solid ${Color.GrayLight};
  border-radius: ${Border.Radius};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    div {
      opacity: 1;
    }
  }
  & > button {
    min-height: 24px;
    width: 24px;
    border-radius: 50%;
    height: 24px;
    padding: 0;
    position: absolute;
    right: -10px;
    top: -10px;
    color: ${Color.White};
    background-color: ${Color.Gray};
    border-color: ${Color.Gray};
    &:hover {
      color: ${Color.White};
      background-color: ${Color.Alert};
      border-color: ${Color.Alert};
    }
  }
`;

export const ZoomIconWrapper = styled.div`
  width: 100px;
  height: 100px;
  background-color: rgba(2, 2, 2, 0.6);
  border-radius: ${Border.Radius};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  cursor: pointer;
`;

export const ChartWrapper = styled.div`
  tspan {
    font-family: ${FontFamily.Primary};
    font-size: ${FontSize.Small};
  }
`;
