import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { NoDataPlaceholder } from '@app/modules/components/no-data.placeholder';
import { CustomerSelection, MarketType } from '@app/providers';
import { capitalizeFirstLetter } from '@app/utils/string-utils';
import { isNullOrUndefined } from '@app/utils/validation';
import { Cell } from '@atomic/atm.cell';
import { CheckboxField } from '@atomic/atm.checkbox';
import { Body, DD, DL, DT, H3, Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { filterSelector } from '../../../store/customer-search.selectors';
import { toogleSelectClientBpLocal, TOOGLE_SELECT_ALL_ACTION } from '../../../store/customer-search.store';
import { CustomerSearchModalBodyAdminShimmer } from './customer-search-modal-body-admin.shimmer';
import { ICustomerSearchModalBodyAdminStrings, Strings } from './customer-search-modal-body-admin.string';
import { CustomerSearchBody, ListClientsWrapper } from './customer-search-modal-body-admin.style';

const renderCustomerState = customer => {
  if (customer.market === MarketType.External) {
    return '';
  } else {
    return '- ' + customer.state;
  }
};
export const CustomerSearchModalBodyAdmin: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ICustomerSearchModalBodyAdminStrings = Strings[userInfo.language];

  const { clientBpsSearch, loading, error } = useSelector((state: RootState) => state.customerSearch);
  const dispatch = useDispatch();
  const bpsFiltered = useSelector(filterSelector);

  const someBpUnselected = bpsFiltered.find(bp => !bp.selected);

  return (
    <CustomerSearchBody>
      <LoadingState loading={loading} data={clientBpsSearch?.length > 0 && !error} error={!!error}>
        <LoadingState.Shimmer>
          <CustomerSearchModalBodyAdminShimmer />
        </LoadingState.Shimmer>

        <LoadingState.NoData>
          <NoDataPlaceholder
            message={isNullOrUndefined(clientBpsSearch) ? strings.initialMessage : strings.noBpFoundMessage}
          />
        </LoadingState.NoData>

        <FlexColumn>
          <FlexRow mb={Spacing.Medium} vAlign='center'>
            <FlexRow data-testid='select-all' ml={Spacing.Small} mr={Spacing.Small}>
              <CheckboxField
                id='select_all_customer'
                checked={!someBpUnselected}
                onValueChange={() => dispatch(TOOGLE_SELECT_ALL_ACTION)}
              />
            </FlexRow>
            <FlexRow fullWidth>
              <Label>{`${strings.selectAll} (${bpsFiltered.length} ${strings.bpShowedMessage})`}</Label>
            </FlexRow>
          </FlexRow>
          <ListClientsWrapper data-testid='bps-wrapper'>
            {bpsFiltered.map((customer: CustomerSelection, index: number) => (
              <FlexRow fullWidth vAlign='center' key={index}>
                <FlexRow ml={Spacing.Small} mr={Spacing.Small}>
                  <CheckboxField
                    paddingLeft='0'
                    checked={customer.selected}
                    id={`customer_id_${customer.customerId}`}
                    onValueChange={() => dispatch(toogleSelectClientBpLocal(customer.customerId))}
                  />
                </FlexRow>
                <FlexRow ml={Spacing.XLarge} fullWidth>
                  <Cell style={{ width: '100%', backgroundColor: customer.selected ? Color.Secondary : 'unset' }} hover>
                    <H3 data-testid='customer-id-name'>
                      {customer.customerId} - {customer.name}
                    </H3>
                    <DL horizontal>
                      <DT>CNPJ:&nbsp;</DT>
                      <DD>{customer.cnpj}</DD>
                    </DL>
                    <Body
                      capitalize
                    >{`${customer?.address?.toLowerCase()} - ${customer?.city?.toLowerCase()} ${renderCustomerState(
                      customer,
                    )} (${capitalizeFirstLetter(customer?.bpType?.toLowerCase())})`}</Body>
                  </Cell>
                </FlexRow>
              </FlexRow>
            ))}
          </ListClientsWrapper>
        </FlexColumn>
      </LoadingState>
    </CustomerSearchBody>
  );
};
