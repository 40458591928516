export const CrmStrings = {
  pt: {
    sessions: {
      OPPORTUNITY_DETAILS: 'Detalhes da Oportunidade',
      ITEMS: 'ITENS',
      NOTES: 'NOTAS',
      ATTACHED_FILES: 'ANEXOS',
      TOTAL_PRICES: 'TOTAIS DE PREÇOS',
      PEOPLE_INVOLVED: 'PESSOAS/ORGANIZAÇÕES ENVOLVIDAS',
      ORGANIZATIONAL_DATA: 'DADOS ORGANIZACIONAIS',
      EXPEDITION: 'EXPEDIÇÃO',
      DATES: 'DATAS',
      HISTORY: 'HISTÓRICO',
      WROKFLOW: 'WORKFLOW',
    },
    subSessions: {
      GENERAL_DATA: 'Dados gerais',
      DATES: 'DATAS',
      WORKFLOW: 'Workflow',
      PROCESSING_DATA: 'Dados do Processamento',
      CATEGORIZATION: 'Categorização',
      QUESTIONS: 'Perguntas',
    },
    fields: {
      rcId: 'Número da Reclamação (ID):',
      rcDescription: 'Descrição:',
      clientName: 'Emissor da ordem:',
      colaborator: 'Empregado responsável:',
      referenceInvoiceDate: 'Data Fatura Referência:',
      referenceInvoiceExternal: 'Referência Fatura Externa:',
      operationInvoiceExternal: 'Operação/Fatura Externa:',
      saleDocument: 'Documento Venda:',
      run: 'Corrida:',
      batch: 'Lote:',

      initial: 'Entrada da Reclamação:',
      createdBy: 'Criado por:',
      updatedBy: 'Modificado por:',
      type: 'Tipo da Reclamação:',
      colaboratorClient: 'Empregado Resp/Cliente:',

      status: 'Status do Workflow:',
      responsibleCurrentPending: 'Responsável pela Pendência Atual:',
      responsibleLastApproval: 'Responsável pela Última Aprovação:',
      situation: 'Situação do Workflow:',
      identifyTask: 'Identificação Tarefa Workflow:',
      createdAt: 'Data de Criação Workflow:',

      priority: 'Prioridade:',
      statusPriority: 'Status da Reclamação:',
      netValue: 'Valor Líquido:',

      department: 'Departamento/Área:',
      symptom: 'Sintoma:',
      cause: 'Causa:',
      subsequentOrderReason: 'Motivo Ordem Subsequente:',
      financialImpact: 'Impacto Financeiro:',
      debtToCarry: 'Débito à Transport.:',

      immediateReturnWithNF: 'O retorno do material é imediato e com NF Gerdau?',
      isLogisticOrQualityAnalysis: 'A RC necessita de análise Logística ou de Qualidade?',
      rcIsValid: 'A reclamação é valida?',
      scenario: 'Cenário:',
      symptomRemovalAction: 'Ação da Remoção de Sintoma:',
      amountCreditToBeGrantedWithoutTax: 'Valor de crédito a conceder sem impostos:',

      grossValue: 'Valor bruto:',
      totalTaxes: 'Montante Imposto:',
      netValueWithoutFreight: 'Valor líquido sem frete:',
      shippingCost: 'Custo de Expedição:',
      calculationSchema: 'Esquema Cálculo:',

      salesOrganizationalUnit: 'Unidade organizacional de vendas:',
      salesOrganization: 'Organização de vendas:',
      distributionChannel: 'Canal de distribuição:',
      salesArea: 'Setor de atividade:',
      salesOffice: 'Escritório de vendas:',
      salesTeam: 'Equipe vendas',

      shipToPart: 'Recebedor da mercadoria:',
      shipToPartAddress: 'End. Recebedor da mercadoria:',
      incoTerms1: 'Incoterms (parte 1):',
      incoTerms2: 'Incoterms (parte 2):',
      shippingConditions: 'Condições de expedição:',
      shippingMode: 'Modo de transporte:',
      shippingPriority: 'Prioridade de remessa:',
      shippingStatus: 'Status da entrega:',
      supplyLockStatus: 'Status dos bloqueios do fornecimento:',
      supplyComplete: 'Fornecimento completo:',
      groupOrders: 'Agrupar ordens:',
      maximumRemittances: 'Max. remessas parc.:',
    },
    crmList: {
      rcId: 'Número da Reclamação',
      rcDescription: 'Descrição',
      clientName: 'Cliente Emissor',
      colaborator: 'Empregado Responsável',
      referenceInvoiceDate: 'Data Fatura Referência',
      referenceInvoiceExternal: 'Referência Fatura Externa',
      saleDocument: 'Documento Venda',
      lang: 'Idioma',
    },
    tables: {
      cols: {
        id: 'N do Item',
        idHigher: 'ID Superior',
        itemHigher: 'Item Superior',
        quantity: 'Quantidade',
        unit: 'Unidade',
        productName: 'Produto',
        categoryName: 'Categoria Item',
        productNetValue: 'Valor Líquido',
        coin: 'Moeda',
        idCostCenter: 'Centro',
        action: 'Ação',
        level: 'Nível modific.',
        newValue: 'Valor novo',
        oldValue: 'Valor antigo',
        updateBy: 'Modificado por',
        updatedAt: 'Modificado em',
        type: 'Tipo de Texto',
        text: 'Texto',
        language: 'Idioma',
        fileName: 'Nome',
        typeFile: 'Tipo Arquivo', //(PDF, EXCEL)
        createdBy: 'Criado por',
        createdAt: 'Data de Criação',
        function: 'Função do Parceiro',
        idPartner: 'ID do parceiro',
        name: 'Nome',
        typeDate: 'Tipo de data',
        date: 'Data',
        status: 'Status do Workflow:',
        responsibleCurrentPending: 'Responsável pela Pendência Atual:',
        responsibleLastApproval: 'Responsável pela Última Aprovação:',
        situation: 'Situação do Workflow:',
        identifyTask: 'Identificação Tarefa Workflow:',
        createdAtWorkflow: 'Data de Criação Workflow:',
      },
    },
    filterFields: {
      rcId: 'Número do RC',
      saleDocument: 'Nota Fiscal',
      batch: 'Lote',
      clientName: 'Cliente Emissor',
      rcDescription: 'Motivo',
    },
  },
  in: {
    sessions: {
      details: 'Detalhes da Oportunidade',
      mainFields: 'CONSULTA DE FABRICAÇÃO CAMPOS PRINCIPAIS',
      texts: 'TEXTOS',
      assets: 'ARQUIVOS ANEXADOS',
      plants: 'PLANTAS - Pessoas/organizações envolvidas',
    },
    subSessions: {
      general: 'Dados gerais',
      dates: 'DATAS',
      cicles: 'CICLO DE VENDAS',
      classification: 'CLASSIFICAÇÃO',
      notes: 'Notas',
    },
    fields: {
      tb: 'Tb: ',
      idCF: 'ID CF: ',
      client: 'Cliente potencial: ',
      descriptionCF: 'Descrição CF: ',
      derrogationDate: 'Dt. Envio Derroga: ',
      startDate: 'Dt. Início: ',
      endDate: 'Dt. Encerramento: ',
      statusCF: 'Status da CF: ',
      steel: 'DIN46MNVS6',
      reponsible: 'Empregado Responsável: ',
      addressClient: 'End. Cliente potencial: ',
      group: 'Grupo de Oportunidades: ',
    },
  },
  es: {
    sessions: {
      details: 'Detalhes da Oportunidade',
      mainFields: 'CONSULTA DE FABRICAÇÃO CAMPOS PRINCIPAIS',
      texts: 'TEXTOS',
      assets: 'ARQUIVOS ANEXADOS',
      plants: 'PLANTAS - Pessoas/organizações envolvidas',
    },
    subSessions: {
      general: 'Dados gerais',
      dates: 'DATAS',
      cicles: 'CICLO DE VENDAS',
      classification: 'CLASSIFICAÇÃO',
      notes: 'Notas',
    },
    fields: {
      tb: 'Tb: ',
      idCF: 'ID CF: ',
      client: 'Cliente potencial: ',
      descriptionCF: 'Descrição CF: ',
      derrogationDate: 'Dt. Envio Derroga: ',
      startDate: 'Dt. Início: ',
      endDate: 'Dt. Encerramento: ',
      statusCF: 'Status da CF: ',
      steel: 'DIN46MNVS6',
      reponsible: 'Empregado Responsável: ',
      addressClient: 'End. Cliente potencial: ',
      group: 'Grupo de Oportunidades: ',
    },
  },
};
