import { OpenMIStatusKey } from '@app/modules/order/grid-filters/grid-open-row-filter.component';
import { defaultInitialState } from '../../../order.interfaces';
import { IOrderTabOpenState } from './order-tab-open.interface';

export const initialState: IOrderTabOpenState = {
  ...defaultInitialState,
  filter: `${OpenMIStatusKey.Implanted},${OpenMIStatusKey.OnSchedule},${OpenMIStatusKey.Confirmed}`,
  criticalFilter: false,
  lackOfChargeFilter: false,
};
