import { IAdminNPSUser, IAdminNPSUserList } from '@app/models/admin.model';
import { GetNPSUsersResponse, GetStatisticsGroupResponse, StatisticsGroup } from './nps.params.dto';

export interface CreateUserData {
  name: string;
  email: string;
  phone?: string;
  language: string;
  profile: UserProfileData[];
}

export interface UserProfileData {
  gerdauSystem: string;
  profileSystem?: string;
  givenProfile: string;
  codSeller?: string;
  bps?: string[];
  roles?: string[];
}

export const mapStatisticsGroup = (response: GetStatisticsGroupResponse): StatisticsGroup => {
  return response.data;
};

export const mapUsers = (response: GetNPSUsersResponse): IAdminNPSUserList => {
  const users: IAdminNPSUser[] = [];
  response.data?.items?.map(user => {
    return users.push({
      _id: user._id,
      uid: user.uid,
      name: user.name,
      email: user.email,
      profile: user.profile,
      tabs: user.tabs,
      corpGroup: user.corpGroup,
      status: user.status,
      lastResponse: user.lastResponse,
    });
  });

  return { users: users, pagination: response.data.pagination };
};
