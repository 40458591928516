import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { useCatalogRequest } from '@app/domain/document.use-case';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { OrderInputEvents } from '@app/modules/order-input/order-input.analytics';
import { OrderInputPhaseEnum } from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { FlashMessageTargetName } from '@app/providers';
import { downloadFile } from '@app/utils/download-file';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color, IconSize, Spacing } from '@atomic/obj.constants';
import { CellWrapperStyled } from '../../../../../../../../../atomic/atm.cell-link/cell-link.component.style';
import { MaterialCatalogWrapper } from '../../../../../search-material-field/search-material.style';
import { ActivityIndicatorWrapper, CatalogInfoWrapperStyled } from './download-catalog.style';

const strings = OrderInputStrings.pt.selectItens;

export const DownloadCatalog: React.FC = () => {
  const { data, loading, fetch } = useCatalogRequest();
  const { selectedIssuer, phase } = useSelector((state: RootState) => state.orderInput);
  const [showCatalogToolTip, setShowCatalogToolTip] = useState<boolean>(false);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  useEffect(() => {
    if (data) {
      LogAnalytics.click({ tipo: OrderInputEvents.MaterialCatalogDownload });
      downloadFile(data, 'catalogo-de-materiais.xlsx');
    }
  }, [data]);

  const handleDownloadMaterialCatalog = () => {
    if (!selectedIssuer) {
      show('warning', strings.stepOne.catalogRequiredIssuerMessage);
    } else {
      fetch(selectedIssuer.value);
    }
  };

  if (phase === OrderInputPhaseEnum.UploadSpreadsheet) {
    if (loading) {
      return (
        <CellWrapperStyled>
          <ActivityIndicatorWrapper>
            <ActivityIndicator type='spinner' size={IconSize.Medium} />
          </ActivityIndicatorWrapper>
        </CellWrapperStyled>
      );
    }

    return (
      <CellWrapperStyled>
        <MaterialCatalogWrapper onClick={handleDownloadMaterialCatalog}>
          {strings.stepTwo.materialCatalogText}
          <FlexRow mb mt vAlign='center' hAlign='space-between'>
            <FaIcon.FlashInfo
              style={{ marginLeft: Spacing.Small }}
              onMouseOver={() => setShowCatalogToolTip(true)}
              onMouseOut={() => setShowCatalogToolTip(false)}
              color={Color.Primary}
            />
            {showCatalogToolTip && (
              <CatalogInfoWrapperStyled show={showCatalogToolTip}>
                <Body>{strings.stepThree.catalogToolTip}</Body>
              </CatalogInfoWrapperStyled>
            )}
          </FlexRow>
        </MaterialCatalogWrapper>
      </CellWrapperStyled>
    );
  }

  return null;
};
