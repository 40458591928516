import styled from 'styled-components';
import { TextField } from '@atomic/atm.text-field';
import { Color, FontWeight } from '@atomic/obj.constants';

export const ObsFieldStyled = styled(TextField)`
  border: none;
  background-color: ${props => (props.selected ? Color.AlertLight : Color.White)};
  font-weight: ${FontWeight.Normal};
  text-align: left;
  &::placeholder {
    position: absolute;
    top: 12px;
  }
`;
