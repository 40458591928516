import * as React from 'react';
import { Frame } from '@atomic/atm.frame';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';

export const OrderPageShimmer: React.FC = () => {
  return (
    <Frame>
      <Grid fluid>
        <Row>
          <Col xs={6}>
            <FlexRow>
              <FlexColumn>
                <TextShimmerBoxStyled height='20px' width='50px' margin='0' />
              </FlexColumn>
              <Hbox.Separator />
              <FlexColumn noGrow>
                <TextShimmerBoxStyled height='20px' width='50px' margin='0' />
              </FlexColumn>
            </FlexRow>
          </Col>
        </Row>
      </Grid>
    </Frame>
  );
};
