import React from 'react';
import { Body } from '@atomic/atm.typography';
import { TD } from '@atomic/mol.table';
import { FontWeight } from '@atomic/obj.constants';

interface ShippingRequestTablerHeaderRegionProps {
  title: string;
  colSpan: number;
  color: string;
  textColor: string;
}

export const ShippingRequestTablerHeaderRegion: React.FC<ShippingRequestTablerHeaderRegionProps> = ({
  title,
  colSpan,
  color,
  textColor,
}) => {
  return (
    <TD color={color} colSpan={colSpan}>
      <Body centralized align='center' bold={FontWeight.Bold} color={textColor}>
        {title}
      </Body>
    </TD>
  );
};
