import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setSelectedRows } from '@app/modules/order/order-tabs/MI/tab-open/store/order-tab-open.store';
import { Button } from '@atomic/atm.button';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { IOrderPriorizationStrings, Strings } from '../../order-priorization.string';
import { setRows, setShowRemoveItemModal } from '../../store/order-priorization.store';
import { deleteAndGetCriticalItemsThunk } from '../../store/thunks/delete-and-get-critical-items.thunk';
import { getUpdatedRows, getUpdatedSelectedRows } from '../../utils/utils';

export const RemoveItemModal: React.FC = () => {
  const strings: IOrderPriorizationStrings = Strings.pt;
  const { selectedRows, loading: orderTabLoading } = useSelector((state: RootState) => state.orderTabOpen);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { rows, itemToRemove, showRemoveItemModal, loading, activedCriticalItem } = useSelector(
    (state: RootState) => state.orderPriorization,
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const onCloseModal = () => dispatch(setShowRemoveItemModal(false));

  const handleRemoveItem = () => {
    const isActivated = activedCriticalItem?.find(a => a.ov === itemToRemove.ovItem);
    const orderItem = selectedRows?.find(s => s.ov === itemToRemove.ovItem);

    if (isActivated) {
      LogAnalytics.orderPriorizationRemoveItem({
        userName: userInfo?.name,
        email: userInfo?.email,
        bp: orderItem?.clientNumber,
        ov: itemToRemove.ovItem,
        ovItem: itemToRemove.itemOv,
        oc: itemToRemove.ocClient,
        codProdClient: itemToRemove.codMaterialClient,
        descMaterial: itemToRemove.descMaterial,
        dateConf: itemToRemove.dateMaxDeliveryClient,
        qtyConf: itemToRemove.qtdConf,
        qtySolic: itemToRemove.qtdSolClient,
        message: itemToRemove.messageClient,
      });
      dispatch(deleteAndGetCriticalItemsThunk({ critialItem: itemToRemove }));
    } else {
      dispatch(setRows(getUpdatedRows(rows, itemToRemove)));
      dispatch(setSelectedRows(getUpdatedSelectedRows([itemToRemove], selectedRows)));
      onCloseModal();
    }
  };

  return (
    <Modal small opened={showRemoveItemModal} preventOverlayClick={loading || orderTabLoading}>
      <Grid fluid>
        <FlexColumn mt mb>
          <FlexRow fullWidth>
            <FlexRow fullWidth>
              <Body bold={700} color={Color.Black}>
                {strings.modal.deleteConfirmMessageStart}
              </Body>
              <Body pl={Spacing.XSmall} bold={700} color={Color.Alert}>
                {`(OV ${itemToRemove?.ovItem})`}
              </Body>
              <Body pl={Spacing.XSmall} bold={700} color={Color.Black}>
                {strings.modal.deleteConfirmMessageEnd}
              </Body>
            </FlexRow>
          </FlexRow>
        </FlexColumn>
        <FlexRow hAlign='flex-end'>
          <FlexRow noGrow>
            <Button kind='alertSecondary' onClick={() => handleRemoveItem()} loading={loading || orderTabLoading}>
              {strings.modal.removeBtn}
            </Button>
          </FlexRow>
          <FlexRow ml={Spacing.Small}>
            <Button kind='secondary' onClick={onCloseModal} loading={loading || orderTabLoading}>
              {strings.modal.cancelBtn}
            </Button>
          </FlexRow>
        </FlexRow>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
