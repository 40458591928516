export const SLICE_NAME = 'nps-modal';

export const ANONYMOUS_ID = 'anonymous';

export const MESSAGE_MAXLENGTH = '10000';

export enum NpsPhase {
  Grade = 'Grade',
  Message = 'Message',
  Success = 'Success',
}

export const notes = [
  { value: 0, color: '#B72224' },
  { value: 1, color: '#D52029' },
  { value: 2, color: '#E95223' },
  { value: 3, color: '#EA6F22' },
  { value: 4, color: '#F6A726' },
  { value: 5, color: '#FDC729' },
  { value: 6, color: '#EBDB0A' },
  { value: 7, color: '#E5E044' },
  { value: 8, color: '#CADC80' },
  { value: 9, color: '#C2D234' },
  { value: 10, color: '#66B44E' },
];

export const NPS_MODAL_CUSTOM_WIDTH = '803px';

export const TOP_ACCOUNTS_CORPORATE_GROUPS = [
  '0000537166',
  '0000537116',
  '0000537150',
  '0000537157',
  '0000537133',
  '0000537142',
  '0000537152',
  '0000537128',
  '0000537102',
  '0000537984',
  '0000537146',
  '0000537172',
  '0000537205',
  '0000537394',
  '0000537208',
  '0000537081',
  '0000537326',
  '0000537547',
  '0000537204',
  '0000537098',
  '0000537050',
  '0000537095',
  '0000537168',
  '0000537160',
  '0000537231',
  '0000537314',
  '0000538060',
  '0000537360',
  '0000537309',
];

export const NPS_FORECAST_DATE = new Date(2023, 8, 20);

export const NPS_ANSWERED = 'answered';
export const NPS_VIEWED = 'viewed';
export const NPS_CANCELED = 'canceled';
