import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IOption } from '@app/modules/components/customer-search/customer-search.interfaces';
import { addSalesGroupSearch } from '@app/modules/components/customer-search/store/customer-search.store';
import { Button } from '@atomic/atm.button';
import { Label } from '@atomic/atm.typography';
import { Spacing } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { ICustomerSearchModalHeaderAdminStrings, Strings } from '../../../customer-search-modal-header-admin.string';
import { OptionItens, OptionsWrapper } from '../search-field-options.style';

interface SearchFieldOptionsSalesGroupParams {
  setShowOptions: (value: boolean) => void;
  loading?: boolean;
  options: IOption[];
}

export const SearchFieldOptionsSalesGroup: React.FC<SearchFieldOptionsSalesGroupParams> = ({
  setShowOptions,
  loading = false,
  options,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const ref = useRef(null);

  const dispatch = useDispatch();
  const strings: ICustomerSearchModalHeaderAdminStrings = Strings[userInfo.language];

  const handleClick = option => {
    dispatch(addSalesGroupSearch(option));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current) {
      const isOutsideClick = ref && !ref.current.contains(event.target);

      if (isOutsideClick) {
        setShowOptions(false);
      }
    }
  };

  useEventListener('click', handleClickOutside);

  if (loading || !options.length) {
    return null;
  }

  return (
    <OptionsWrapper ref={ref}>
      {options.map((option: IOption) => (
        <OptionItens key={option.value} mt={Spacing.Small} mb={Spacing.Small}>
          <Label onClick={() => handleClick(option)}>{option.name}</Label>
          <Button onClick={() => handleClick(option)} kind='link'>
            {strings.addfFilterButton}
          </Button>
        </OptionItens>
      ))}
    </OptionsWrapper>
  );
};
