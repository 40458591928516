import React, { useState } from 'react';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { SpreadsheetInfoWrapperStyled } from './order-input-upload-file-info.style';

const Strings = OrderInputStrings.pt.selectItens;

export const OrderInputUploadFileInfo: React.FC = () => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <FlexColumn>
      <FaIcon.FlashInfo
        onMouseOver={() => setShowDescription(true)}
        onMouseOut={() => setShowDescription(false)}
        color={Color.Primary}
      />
      {showDescription && (
        <SpreadsheetInfoWrapperStyled>
          <Body>{Strings.stepThree.infoDescription}</Body>
          <Body>{Strings.stepThree.infoDate}</Body>
          <Body>{Strings.stepThree.infoUnit}</Body>
          <Body>{Strings.stepThree.infoQtyLabel}</Body>
          <Body style={{ paddingLeft: Spacing.Small }}>{Strings.stepThree.infoTon}</Body>
          <Body style={{ paddingLeft: Spacing.Small }}>{Strings.stepThree.infoKG}</Body>
        </SpreadsheetInfoWrapperStyled>
      )}
    </FlexColumn>
  );
};
