import React from 'react';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { TooltipsWrapper } from '../../quote/components/quote.components.style';
import { OrdersStrings } from '../orders.string';

interface OnboardActionTooltipProps {
  onClose: () => void;
  onClick: () => void;
  visible: boolean;
}

export const OnboardActionTooltip: React.FC<OnboardActionTooltipProps> = props => {
  return (
    <TooltipsWrapper>
      <CustomizedTooltip
        step={2}
        totalSteps={4}
        title={OrdersStrings.pt.tooltips.actions.title}
        description={OrdersStrings.pt.tooltips.actions.description}
        visible={props.visible}
        onClick={props.onClick}
        onClose={props.onClose}
        customHeight={95}
        customLeftAlign={35}
        hasOffset
        alignBottom
        positionYFromBottom
      />
    </TooltipsWrapper>
  );
};
