import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Hbox } from '@atomic/obj.box';
import { Strings, INewsTagStrings } from './news-tag.string';
import { NewsTagActionButton, NewsTagBase, NewsTagBody, NewsTagHeaderIcon, NewsTagTab } from './news-tag.style';

interface NewsTagProps {
  isHeaderIcon?: boolean;
  isTab?: boolean;
  isActionButton?: boolean;
  isNotAbsolute?: boolean;
}

export const NewsTag: React.FC<NewsTagProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: INewsTagStrings = Strings[userInfo?.language];

  const handleNewsTag = () => {
    if (props.isHeaderIcon) {
      return <NewsTagHeaderIcon>{strings.text}</NewsTagHeaderIcon>;
    } else if (props.isTab) {
      return <NewsTagTab>{strings.text}</NewsTagTab>;
    } else if (props.isActionButton) {
      return <NewsTagActionButton>{strings.text}</NewsTagActionButton>;
    } else if (props.isNotAbsolute) {
      return <NewsTagBase>{strings.text}</NewsTagBase>;
    } else {
      return <NewsTagBody>{strings.text}</NewsTagBody>;
    }
  };

  return (
    <Hbox hAlighn='center' vAlighn='center'>
      {handleNewsTag()}
    </Hbox>
  );
};
