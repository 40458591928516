import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AvailableStatusNotification, AvailableStatusNotificationTypes, StatusUserList } from '@app/models/quote.model';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { isMultipleEmailValid } from '@atomic/obj.form/regex-validator';
import { VSeparator } from '@atomic/obj.grid';
import { TechnicalAnalysisStatusField } from './technical-analysis-status.field';

interface EmailFieldsComponentProps {
  notification: AvailableStatusNotification;
  users?: StatusUserList;
  loading: boolean;
}

enum ProfileType {
  PED = 'Ped',
  RTC = 'Rtc',
  EPP = 'Epp',
  CLIENT = 'Client',
  GQ = 'Gq',
  SELLER = 'Seller',
}

interface AvailableEmails {
  epp: string;
  rtc: string;
  ped: string;
  client: string;
  gq: string;
  seller: string;
}

const formatValues = (previousValue: string, currentValue: string) => {
  if (previousValue.length) {
    const emails = previousValue.split(',');
    const length = emails.length;
    emails[length - 1] = currentValue;
    return emails.join(',');
  }

  return currentValue;
};

export const EmailFieldsComponent: React.FC<EmailFieldsComponentProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = TechnicalAnalysisStrings[userInfo.language].status.statusModal.emailFields;

  const initialEmailsValue = {
    epp: props.notification.profilesEmail.EPP ? props.notification.profilesEmail.EPP : '',
    ped: props.notification.profilesEmail.PED ? props.notification.profilesEmail.PED : '',
    rtc: props.notification.profilesEmail.RTC ? props.notification.profilesEmail.RTC : '',
    client: props.notification.profilesEmail.CLIENT ? props.notification.profilesEmail.CLIENT : '',
    gq: props.notification.profilesEmail.GQ ? props.notification.profilesEmail.GQ : '',
    seller: props.notification.profilesEmail.SELLER ? props.notification.profilesEmail.SELLER : '',
  };

  const [currentList, setCurrentList] = React.useState(null);
  const [emails, setEmails] = React.useState<AvailableEmails>(initialEmailsValue);
  const profiles = props.notification.profiles;

  React.useEffect(() => {
    if (isMultipleEmailValid(emails[currentList])) {
      setCurrentList(null);
    }
  }, [emails, currentList]);

  const handleValueChange = (profile: string, value: string) => {
    if (!isMultipleEmailValid(value)) {
      setCurrentList(profile);
    }
    setEmails(prevValue => ({ ...prevValue, [profile]: value }));
  };

  const handleSelectUser = (profile: string, value: string) => {
    setCurrentList('');
    setEmails(prevValue => {
      return { ...prevValue, [profile]: formatValues(prevValue[profile], value) };
    });
  };

  const filterList = (list: string[], currentEmails: string) => {
    const query =
      currentEmails !== undefined && currentEmails !== '' ? currentEmails.split(',').map(item => item.trim()) : [];
    const length = query.length;

    return list.filter(item => item.includes(query[length - 1]));
  };

  return props.notification.type === AvailableStatusNotificationTypes.specific ? (
    <TechnicalAnalysisStatusField
      profile={ProfileType[profiles[0]]}
      label={strings.sendTo}
      name='email'
      value={emails[profiles[0].toLowerCase()]}
      show={currentList === profiles[0].toLowerCase()}
      users={filterList(props.users[profiles[0].toLowerCase()], emails[profiles[0].toLowerCase()])}
      loading={props.loading}
      placeholder={strings.emailMultiPlaceholder}
      onValueChange={value => handleValueChange(profiles[0].toLowerCase(), value)}
      onClose={() => setCurrentList(null)}
      onSelect={value => handleSelectUser(profiles[0].toLowerCase(), value)}
    />
  ) : (
    <>
      {props.notification.profiles.length > 0 &&
        props.notification.profiles.map(profile => (
          <React.Fragment key={profile}>
            <TechnicalAnalysisStatusField
              profile={ProfileType[profile]}
              label={strings.sendToProfile(ProfileType[profile])}
              name={'email' + `${ProfileType[profile]}`}
              value={emails[profile.toLowerCase()]}
              show={currentList === profile.toLowerCase()}
              users={filterList(props.users[profile.toLowerCase()], emails[profile.toLowerCase()])}
              loading={props.loading}
              placeholder={strings.emailMultiPlaceholder}
              onValueChange={value => handleValueChange(profile.toLowerCase(), value)}
              onClose={() => setCurrentList(null)}
              onSelect={value => handleSelectUser(profile.toLowerCase(), value)}
            />
            <VSeparator />
          </React.Fragment>
        ))}
    </>
  );
};
