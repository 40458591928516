import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { OrderCheckboxFilterKeyNames } from '@app/modules/components/side-bar-filters/sidebar-filters.constants';
import { setCheckboxFilters } from '@app/modules/components/side-bar-filters/store/sidebar-filters.store';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';

interface ICheckboxFilterContentProps {
  filterKey: string;
}

export const OrderCheckboxFilterContent: React.FC<ICheckboxFilterContentProps> = ({ filterKey }) => {
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const tab = MItabIndex[orderTabIndex];
  const { plantName } = useSelector((state: RootState) => state.advancedFilters[tab].filters);

  const dispatch = useDispatch();
  const onClick = key => {
    dispatch(setCheckboxFilters({ tab, filterKey, key }));
  };

  return (
    <FlexColumn mt={Spacing.Small} ml={Spacing.Medium}>
      {OrderCheckboxFilterKeyNames.map(item => {
        const checked = plantName.includes(item.key);
        return (
          <CheckboxField key={item.key} checked={checked} id={item.key} onClick={() => onClick(item.key)}>
            {item.name}
          </CheckboxField>
        );
      })}
    </FlexColumn>
  );
};
