import * as React from 'react';
import { LogAnalytics } from '@app/core/analytics';
import { GridButtonWithIcon } from '@app/modules/components/grid-button-with-icon.component';
import { FaIcon } from '@atomic/atm.fa-icon';
import { HelpCenterString } from '../help-center.string';

interface HelpCenterActionProps {
  action: string;
}

export const HelpCenterAction: React.FC<HelpCenterActionProps> = props => {
  const handleFraudAlertDownload = () => {
    LogAnalytics.click({ tipo: 'HelpCenterAntiFraudeDownload' });
    const data = '../../../../../assets/data/fraud-alert-newsletter.pdf';
    const a = document.createElement<'a'>('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = data;
    a.download = 'Informativo.pdf';
    a.click();
    window.URL.revokeObjectURL(data);
  };

  const renderAction = () => {
    switch (props.action) {
      case 'download':
        return (
          <GridButtonWithIcon
            text={HelpCenterString.action.download}
            icon={FaIcon.FileDownload}
            kind='primary'
            onClick={() => handleFraudAlertDownload()}
          />
        );
      default:
        return null;
    }
  };

  return <>{props.action && renderAction()}</>;
};
