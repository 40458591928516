import { HelpCenterSubsectionKeys, HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterOpenOrderSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Carteira - Em aberto',
      key: HelpCenterSectionKeys.OpenOrders,
      subsections: [
        {
          title: 'Dicionário',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.OpenOrdersGlossary,
          informationBlocks: [
            {
              blockTitle: 'Entenda melhor os termos usados na aba "Em aberto" do Gerdau Mais',
              table: {
                columns: ['Nome da coluna', 'Conceito'],
                rows: [
                  [
                    'Bitola (mm)',
                    'Valor numérico referente a bitola (seção transversal do material) para materiais redondos, quadrados e hexagonais.',
                  ],
                  ['Cliente', 'Nome do cliente emissor do pedido de compra.'],
                  ['Cod. cliente', 'Código interno Gerdau do emissor do pedido de compra.'],
                  ['Cod. prod. cliente', 'Também chamado de Part Number, é o código do material na visão do cliente.'],
                  ['Comp. mínimo', 'Comprimento mínimo possível do material.'],
                  ['Comp. máximo', 'Comprimento máximo possível do material.'],
                  ['Comp. múltiplo', 'Comprimento múltiplo do material.'],
                  ['Data confirmada min', 'Data confirmada pela Gerdau para entrega do pedido.'],
                  [
                    'Data confirmada max',
                    'Data confirmada pela Gerdau, com um range de alguns dias para garantir maior assertividade de entrega.',
                  ],
                  ['Data solicitada cliente', 'Data requerida pelo cliente ao emitir o pedido de compra.'],
                  [
                    'Desc. material',
                    'Descrição do material, contemplando as principais características, como produto, forma, processo, acabamento, bitola, liga, etc.',
                  ],
                  ['Espessura', 'Aplicável às barras chatas, informa o valor numérico da espessura da barra.'],
                  ['Forma', 'Forma do material, se é redondo, quadrado, hexagonal, chato, etc.'],
                  ['Grupo cliente', 'Código que simboliza o agrupamento de clientes pertencentes ao mesmo grupo.'],
                  ['Item ordem de compra', 'Item do pedido de compra emitido pelo cliente.'],
                  ['Item ordem de venda', 'Item da ordem de venda implantada pela Gerdau.'],
                  ['Largura', 'Aplicável às barras chatas, informa o valor numérico da largura da barra.'],
                  [
                    'Marca',
                    'Referência relacionada a norma indicando se é um aço padrão (norma internacional), customizado ou aplicação especial.',
                  ],
                  ['Material', 'Código interno Gerdau que simboliza a descrição do material.'],
                  ['Norma aço | Revisão', 'A identificação da norma e da revisão que o material está sendo produzido.'],
                  ['Ordem compra', 'Campo que simboliza o pedido de compra emitido pelo cliente.'],
                  ['Ordem venda', 'Campo que simboliza a ordem de venda implantada pela Gerdau.'],
                  ['Preço R$/t', 'Preço comercializado para o pedido, medido em Reais por toneladas.'],
                  [
                    'Previsão de entrega',
                    'Campo que informa a previsão de entrega ao cliente através de um range de datas.',
                  ],
                  ['Qtd. confirmada (t)', 'Quantidade confirmada pela Gerdau para cada pedido.'],
                  [
                    'Qtd. em estoque (t)',
                    'Quantidade do pedido que está em estoque na usina, desconsiderando a quantidade que está em Remessa.',
                  ],
                  ['Qtd. em produção (t)', 'Quantidade do pedido que está em processo de produção.'],
                  [
                    'Qtd. em remessa (t)',
                    'Quantidade do pedido que está com remessa gerada, ou seja, que já possui uma solicitação de carregamento para a Logística.',
                  ],
                  [
                    'Qtd. faturada (t)',
                    'Quantidade do pedido que já foi faturada, ou seja, que já possui nota fiscal emitida.',
                  ],
                  ['Qtd. pend. fat (t)', 'Quantidade prevista para faturamento para cada pedido.'],
                  ['Qtd. solicitada (t)', 'Quantidade requerida pelo cliente no pedido de compra.'],
                  ['Qualidade', 'Liga do aço referente ao material solicitado pelo cliente no pedido de compra.'],
                  [
                    'Recebedor',
                    'Parceiro sinalizado pelo cliente para receber a mercadoria, podendo ser o próprio cliente.',
                  ],
                  ['Status de entrega', 'Campo que sinaliza se o pedido está previsto para atrasar'],
                  [
                    'Status OV',
                    'Status da ordem de venda, implantada, aguardando aprazamento pelo PCP ou confirmada para produção e atendimento.',
                  ],
                  [
                    'Tipo mercado',
                    'Material será utilizado para atendimento ao mercado nacional, mercado internacional ou mercado nacional e posterior internacional.',
                  ],
                  ['Tipo produto', 'Tipo de produto, se é barra, fio máquina, arame, etc.'],
                  ['Usina', 'Planta que produzirá o material para atendimento do pedido.'],
                ],
              },
            },
          ],
        },
        {
          title: 'Customizar e salvar o layout',
          key: HelpCenterSubsectionKeys.SaveLayout,
          description: 'Salve a configuração de colunas da sua carteira da forma que deseja ver',
          informationBlocks: [
            {
              blockTitle:
                '1. Na aba ”Carteira” clique no botão ”Selecionar colunas” para escolher quais informações do seu pedido quer ver',
              media: {
                path: 'assets/img/help-center/order/save-layout/save-layout-01.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle: '2. Selecione as opções que deseja ver clicando na caixa de seleção',
              paragraph:
                'Caso não queira ver alguma informação já apresentada nas colunas, clique no que já está selecionado para retirar essa visualização da sua carteira. Após realizar todas as mudanças desejadas, clique no botão "Salvar” para confirmar as alterações',
              media: {
                path: 'assets/img/help-center/order/save-layout/save-layout-02.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle: '3. Pronto! Em instantes você vai receber uma mensagem de confirmação',
              paragraph:
                'E caso deseje reordenar as colunas com as novas informações, isso será salvo automaticamente e uma nova mensagem de configuração realizada com sucesso irá aparecer',
              media: {
                path: 'assets/img/help-center/order/save-layout/save-layout-03.png',
                type: 'stretch-image',
              },
            },
          ],
        },
        {
          title: 'Alteração de carteira',
          key: HelpCenterSubsectionKeys.OrderUpdate,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml:
                'Para realizar a alteração na carteira, basta selecionar na aba <b>Em aberto</b> os itens que deseja realizar alteração.',
              media: {
                path: 'assets/img/help-center/order/change-order/change-order-01.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml: `Preencha as alterações desejadas como: <b>nova data, nova quantidade ou cancelar pedido</b> e clique em <b>Finalizar solicitação</b>.<br />
                Obs: Você também pode deixar um comentário geral ou uma mensagem em alguma das linhas.`,
              media: {
                path: 'assets/img/help-center/order/change-order/change-order-02.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Por fim, revise as alterações que deseja solicitar e clique em <b>enviar</b> para confirmar.',
              media: {
                path: 'assets/img/help-center/order/change-order/change-order-03.png',
                type: 'stretch-image',
              },
            },
          ],
        },
        {
          title: 'Notificações de pedidos',
          key: HelpCenterSubsectionKeys.OrderNotification,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml:
                'A funcionalidade pode ser acessada através do ícone do <b>“sininho”</b> no menu superior direito.',
              media: {
                path: 'assets/img/help-center/order/order-notification/order-notification-01.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Basta ir na aba <b>Em aberto</b> da <b>Carteira</b> e selecionar os pedidos que deseja receber notificações e clicar no ícone do “sininho” no menu inferior que irá abrir.',
              media: {
                path: 'assets/img/help-center/order/order-notification/order-notification-02.png',
                type: 'stretch-image',
              },
            },
            {
              paragraph:
                'Após a confirmação, aparecerá um ícone de um “sininho” verde na coluna Notificação Ativa, mostrando que este pedido está ativado para receber notificações.',
              media: {
                path: 'assets/img/help-center/order/order-notification/order-notification-03.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml: `Por último, você deverá informar que tipo de notificação deseja receber. <br />
                Obs: Ao ativar o primeiro pedido para notificação, automaticamente será aberto o menu lateral direito com as configurações de notificações. Caso deseje alterar essa configuração no futuro, basta acessar esse menu manualmente, clicando no sininho.`,
              media: {
                path: 'assets/img/help-center/order/order-notification/order-notification-04.png',
                type: 'stretch-image',
              },
            },
            {
              paragraph:
                'Pronto! Agora é só aguardar a evolução dos seus pedidos sinalizados, você será notificado por e-mail e também no painel lateral direito, facilitando a gestão da sua carteira.',
              media: {
                path: 'assets/img/help-center/order/order-notification/order-notification-05.png',
                type: 'stretch-image',
              },
            },
          ],
        },
        {
          title: 'Programar geração de remessa automática',
          key: HelpCenterSubsectionKeys.ScheduleShipmentRequest,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml:
                'Dentro da aba <b>Carteira - Em aberto</b>, selecione os itens que deseja fazer a programação.',
              media: {
                path: 'assets/img/help-center/order/schedule-shipment-request/schedule-shipment-request-01.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml: 'Clique no botão <b>"Programar geração de remessa”</b>',
              media: {
                path: 'assets/img/help-center/order/schedule-shipment-request/schedule-shipment-request-02.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Pronto, agora os itens selecionados terão o <b>ícone</b> abaixo na coluna de <b>Remessas programadas</b> indicando que eles foram programados.',
              media: {
                path: 'assets/img/help-center/order/schedule-shipment-request/schedule-shipment-request-03.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Você também pode cancelar a programação selecionando os mesmos produtos e clicando no botão <b>”Remover geração automática de remessa"</b>.',
              media: {
                path: 'assets/img/help-center/order/schedule-shipment-request/schedule-shipment-request-04.png',
                type: 'stretch-image',
              },
            },
          ],
        },
        {
          title: 'Status do pedido',
          key: HelpCenterSubsectionKeys.OrderStatus,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml:
                'Com essa funcionalidade você poderá agendar e programar envios recorrentes de relatórios da sua carteira em aberto e em estoque sem a necessidade de ter alguém da equipe de atendimento que faça isso. Vejamos à seguir como funciona. Comece clicando no botão em destaque <b>Agendar envio</b> na aba de Carteira.',
              media: {
                path: 'assets/img/news-modal/MN-order-status.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Ao passar o mouse por cima das barras uma janela se abrirá com a legenda do que significa cada cor que seriam as quantidades em toneladas de: faturado, estoque, remessa, produção, saldo, solicitada e confirmada.',
              media: {
                path: 'assets/img/news-modal/MN-order-status-2.png',
                type: 'stretch-image',
              },
            },
          ],
        },
        {
          title: 'Agendamento do envio da carteira de pedidos',
          alternativeTitle: 'Envio automático de relatórios da carteira',
          key: HelpCenterSubsectionKeys.AutomaticReportSubmission,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml:
                'Com essa funcionalidade você terá a facilidade de receber por email a sua carteira de pedidos em aberto. Você poderá programar este envio da maneira que melhor lhe atender (frequência e horário de envio, seleção e ordenação das colunas). Comece clicando no botão em destaque <b>Agendar envio</b> na aba de Carteira.',
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-1.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Um pop-up irá aparecer informando que você ainda não tem agendamentos caso essa seja a sua primeira vez. Selecione agora se deseja fazer um relatório da <b>carteira</b> ou do <b>estoque</b> e em seguida clique em <b>Criar nova programação.</b>',
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-2.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Primeiramente você deve nomear esse agendamento da maneira que preferir. O segundo passo é adicionar os clientes dos quais deseja fazer os relatórios.',
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-3.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Feita a seleção dos clientes, eles aparecerão num box àbaixo, onde você poderá conferir, adicionar ou excluir caso necessite. O terceiro passo é selecionar a frequência que deseja receber esses relatórios, <b>seja diário, semanal ou mensal.</b>',
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-4.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Ao selecionar a opção <b>Mensal</b>, escolha o dia ou os dias no mês e o horário que deseja receber o relatório.',
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-5.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Ao selecionar a opção <b>Semanal</b>, escolha o dia ou os dias na semana e o horário que deseja receber o relatório.',
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-6.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Ao selecionar a opção <b>Diário</b>, todo os dias na semana já estarão pré-selecionados, escolha apenas o horário que deseja receber o relatório. Feito isso clique em <b>Selecionar colunas</b> para fazermos a personalização das informações que farão parte do relatório.',
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-7.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Agora você só precisa <b>selecionar</b> quais colunas de informação deseja adicionar à esse relatório e em seguida <b>arrastar as colunas</b> na ordem em que deseja que elas apareçam. Feito isso, conclua clicando em <b>Salvar agendamento.</b>',
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-8.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Pronto, agora seu agendamento estará salvo na primeira tela. Caso queira editar esse agendamento ou adicionar algum outro, basta acessar a janela de <b>Agendar envio</b> e selecionar o que deseja editar ou criar uma nova programação.',
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-9.png',
                type: 'stretch-image',
              },
            },
            {
              media: {
                path: 'assets/img/help-center/order/schedule-modal/schedule-modal-10.png',
                type: 'stretch-image',
              },
            },
          ],
        },
        {
          title: 'Filtros avançados',
          key: HelpCenterSubsectionKeys.AdvancedFilters,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml:
                'Com essa funcionalidade você poderá aplicar filtros avançados nas colunas de <b>Data solicitada, Data confirmada, OVs,  Material, Descrição do material, Part. Number e Usina.</b>',
              media: {
                path: 'assets/img/help-center/order/advanced-filters/advanced-filters-01.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Ao clicar no botão <b>+ Filtros</b> uma aba lateral surgirá com as opções de filtros que deseja utilizar. Clique em algum deles para configurar o filtro.',
              media: {
                path: 'assets/img/help-center/order/advanced-filters/advanced-filters-02.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Ao configurar os filtros que deseja, na parte superior da aba aparecerão os filtros que foram aplicados, após isso, clique em <b>Filtrar</b> para concluir a ação.',
              media: {
                path: 'assets/img/help-center/order/advanced-filters/advanced-filters-03.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Nos filtros de OVs, Materiais, Descrição de materiais e Part. number você poderá colar vários valores de uma só vez ao colar no primeiro campo, economizando o tempo de preenchimento.',
              media: {
                path: 'assets/img/help-center/order/advanced-filters/advanced-filters-04.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Caso deseje editar os filtros, basta clicar novamente no mesmo botão e configurar como deseja.',
              media: {
                path: 'assets/img/help-center/order/advanced-filters/advanced-filters-05.png',
                type: 'stretch-image',
              },
            },
          ],
        },
        {
          title: 'Sinalização de itens críticos',
          key: HelpCenterSubsectionKeys.CriticalItems,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml:
                'Com essa funcionalidade você terá a facilidade de sinalizar quais itens da sua carteira de pedidos você deseja que nossa equipe trate como prioritários. Basta selecionar quais são os itens e clicar no botão <b>Sinalizar itens críticos.</b>',
              media: {
                path: 'assets/img/help-center/order/critical-items/critical-items-01.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Com isso você será encaminhado para uma tela com os itens sinalizados. Acima dos itens sinalizados você verá quantos itens são permitidos no momento para o seu grupo e quantos itens foram selecionados. Para prosseguir basta clicar no botão <b>Confirmar.</b>',
              media: {
                path: 'assets/img/help-center/order/critical-items/critical-items-02.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Uma modal de confirmação surgirá para que você confirme a ação. Caso deseje prosseguir, clique em <b>Concluir.</b>',
              media: {
                path: 'assets/img/help-center/order/critical-items/critical-items-03.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Após isso uma tela com a confirmação aparecerá informando que sua ação foi concluída e que você receberá um email com o registro da ação.',
              media: {
                path: 'assets/img/help-center/order/critical-items/critical-items-04.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Ao voltar para a sua carteira você visualizará os itens que foram sinalizados como críticos destacados em <b>negrito.</b>',
              media: {
                path: 'assets/img/help-center/order/critical-items/critical-items-05.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Para visualizar somente os itens críticos, clique no filtro <b>Críticos</b> ao lado dos filtros acima da tabela.',
              media: {
                path: 'assets/img/help-center/order/critical-items/critical-items-06.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Caso queira acessar a área de edição dos itens críticos, basta selecionar os itens críticos na carteira. Você também pode selecionar outros itens para classificar como críticos e clicar no botão <b>Editar itens críticos.</b>',
              media: {
                path: 'assets/img/help-center/order/critical-items/critical-items-07.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Pode acontecer momentos em que se selecionam mais itens do que o permitidos para o seu grupo. Nesse caso você será alertado e deverá escolher quais itens deverão ser os críticos clicando em <b>Prosseguir.</b>',
              media: {
                path: 'assets/img/help-center/order/critical-items/critical-items-08.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Como no exemplo abaixo você pode remover itens que já foram selecionados anteriormente, ou dos que foram selecionados no momento para que eles caibam dentro do limite estabelecido para o seu grupo.',
              media: {
                path: 'assets/img/help-center/order/critical-items/critical-items-09.png',
                type: 'stretch-image',
              },
            },
          ],
        },
      ],
    }
  );
};
