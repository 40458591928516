export interface IAppStrings {
  tabs: {
    ordersInput: string;
    orders: string;
    finance: string;
    quote: string;
    helpCenter: string;
    dashboard: string;
    complain: string;
  };
  admin: {
    manager: string;
    title: string;
    backButton: string;
  };
  configuration: {
    settings: string;
    changePassword: string;
    manager: string;
    navigateToGAB: string;
    logout: string;
    language: string;
    languageWarning: string;
    surrogate: string;
    lackOfCharge: string;
    schedulingComplete: string;
  };
  newsModal: {
    title: string;
    hideThisNews: string;
    noData: string;
    confirm: string;
    close: string;
  };
  companyLogosTooltip: {
    title: string;
    description: string;
  };
  maintenanceModal: {
    title: string;
  };
  preventiveStopModal: {
    title: string;
    close: string;
  };
  genericError: string;
}

interface IAppLanguages {
  pt: IAppStrings;
  es: IAppStrings;
  in: IAppStrings;
}

const pt: IAppStrings = {
  tabs: {
    ordersInput: 'Implantação',
    orders: 'Carteira',
    finance: 'Finanças',
    quote: 'Cotação',
    helpCenter: 'Ajuda',
    dashboard: 'Painel de gestão',
    complain: 'Manifestação',
  },
  admin: {
    manager: 'Gerenciador de usuários',
    title: 'Gerenciador de usuários e perfis',
    backButton: 'Voltar para o Gerdau Mais',
  },
  configuration: {
    settings: 'Configurações do administrador',
    changePassword: 'Alterar senha',
    manager: 'Gerenciador de usuários',
    navigateToGAB: 'Acessar aços longos',
    logout: 'Logout',
    language: 'Idioma',
    languageWarning: 'Atenção! Algumas áreas do Gerdau Mais ainda não estão totalmente traduzidas.',
    surrogate: 'Configurar email adicional',
    lackOfCharge: 'Alerta de falta de carga',
    schedulingComplete: 'Alerta de aprazamento',
  },
  newsModal: {
    title: 'Novidades do Gerdau Mais!',
    hideThisNews: 'Não mostrar estas novidades novamente',
    noData: 'Nenhuma novidade encontrada',
    confirm: 'Ok, entendido',
    close: 'Fechar',
  },
  companyLogosTooltip: {
    title: '',
    description: 'Você conhece nossas marcas? Clique nos ícones e saiba mais.',
  },
  maintenanceModal: {
    title: 'Manutenção!',
  },
  preventiveStopModal: {
    title: 'Atenção!',
    close: 'Fechar',
  },
  genericError: 'Ocorreu um erro, tente novamente mais tarde',
};

const es: IAppStrings = {
  tabs: {
    ordersInput: 'Implantacion',
    orders: 'Cartera',
    finance: 'Finanzas',
    quote: 'Cotización',
    helpCenter: 'Ayuda',
    dashboard: 'Panel de gestión',
    complain: 'Manifestación',
  },
  admin: {
    manager: 'Administrador de usuarios',
    title: 'Administrador de usuarios y perfiles',
    backButton: 'Volver a Gerdau Mais',
  },
  configuration: {
    settings: 'Configuración de administrador',
    changePassword: 'Cambiar contraseña',
    manager: 'Administrador de usuarios',
    navigateToGAB: 'Acceso de acero largo',
    logout: 'Cerrar sesión',
    language: 'Idioma',
    languageWarning: '¡Atención! El cambio de idioma sólo se aplicará según el cliente seleccionado.',
    surrogate: 'Configurar correo electrónico adicional',
    lackOfCharge: 'Alerta de falta de carga',
    schedulingComplete: 'Alerta de aprazamento',
  },
  newsModal: {
    title: '¡Gerdau Mais noticias!',
    hideThisNews: 'No volver a mostrar estas noticias',
    noData: 'No se encontraron noticias',
    confirm: 'Ok, entendido',
    close: 'Cerrar',
  },
  companyLogosTooltip: {
    title: '',
    description: 'Você conhece nossas marcas?\n Clique nos ícones e saiba mais.',
  },
  maintenanceModal: {
    title: '¡Mantenimiento!',
  },
  preventiveStopModal: {
    title: '¡Atención!',
    close: 'Cerrar',
  },
  genericError: 'Ocorreu um erro, tente novamente mais tarde',
};

const en: IAppStrings = {
  tabs: {
    ordersInput: 'Implantation',
    orders: 'Purchase orders',
    finance: 'Finance',
    quote: 'Quotation',
    helpCenter: 'Help',
    dashboard: 'Management panel',
    complain: 'Complain',
  },
  admin: {
    manager: 'User manager',
    title: 'User and profile manager',
    backButton: 'Back to Gerdau Mais',
  },
  configuration: {
    settings: 'Admin settings',
    changePassword: 'Change password',
    manager: 'User manager',
    navigateToGAB: 'Access long steel',
    logout: 'Logout',
    language: 'Language',
    languageWarning: 'Attention! The language change will only be applied according to the selected client.',
    surrogate: 'Configure additional email',
    lackOfCharge: 'Alerta de falta de carga',
    schedulingComplete: 'Alerta de aprazamento',
  },
  newsModal: {
    title: 'Gerdau Mais news!',
    hideThisNews: 'Do not show these news again',
    noData: 'No news found',
    confirm: 'Ok, understood',
    close: 'Close',
  },
  companyLogosTooltip: {
    title: '',
    description: 'Você conhece nossas marcas?\n Clique nos ícones e saiba mais.',
  },
  maintenanceModal: {
    title: 'Maintenance!',
  },
  preventiveStopModal: {
    title: 'Attention!',
    close: 'Close',
  },
  genericError: 'Ocorreu um erro, tente novamente mais tarde',
};

export const Strings: IAppLanguages = {
  pt,
  es,
  in: en,
};
