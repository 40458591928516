import React from 'react';
import { Material } from '@app/models';
import { formatMaterialAmount } from '@app/modules/order-input/order-input.utils';
import { formatQtyField } from '@app/utils/string-utils';
import { Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { ArrowDownButton } from '../arrow-down-button/arrow-down-button';
import { ArrowUpButton } from '../arrow-up-button/arrow-up-button';
import { MultipleInput } from './multiple-field.style';

interface IMultipleFieldProps {
  material: Material;
  index: number;
}

export const MultipleField: React.FC<IMultipleFieldProps> = ({ material, index }) => {
  if (!material?.multipleValidation) {
    return (
      <Label weight={FontWeight.Normal} size={FontSize.Small}>
        {formatMaterialAmount(material.amount)}
      </Label>
    );
  }

  const floatStringValue = material.multipleValidation?.quantity.replace(',', '.');
  const floatValue = parseFloat(floatStringValue);
  const formatedValue = formatQtyField(floatValue);

  return (
    <FlexColumn hAlign='center' fullWidth>
      <FlexColumn pt={Spacing.Medium}>
        <FlexRow vAlign='center' hAlign='center'>
          <ArrowDownButton material={material} index={index} />
          <MultipleInput readOnly={true} data-testid={`multiple-field-${index}`} value={formatedValue} />
          <ArrowUpButton material={material} index={index} />
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};
