import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Body, BodySecondary } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { DropzoneWidth } from '@atomic/obj.constants';
import { DropzoneStyled, DropzoneStyledLink } from './dropzone.component.style';
import { DropzoneStrings } from './dropzone.string';

interface DropzoneProps {
  onDropAccepted: (file: File[], event: any) => void;
  limitAcceptance?: string;
  isOrderInputDropzone?: boolean;
  multiple?: boolean;
  label?: string;
}

export const Dropzone: React.FC<DropzoneProps> = (props: DropzoneProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = DropzoneStrings[userInfo.language];

  const handleDropAccepted = (file: File[], event: any) => {
    if (props.onDropAccepted) {
      props.onDropAccepted(file, event);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: props.limitAcceptance || null,
    onDropAccepted: handleDropAccepted,
  });

  const renderOrderInputDropzone = () => {
    if (props.isOrderInputDropzone) {
      if (isDragActive) {
        return (
          <FlexRow style={{ justifyContent: 'center' }}>
            <Body>{props.label ? props.label : strings.uploadClickButton}</Body>
          </FlexRow>
        );
      } else {
        return (
          <FlexColumn style={{ justifyContent: 'center' }}>
            <Body>
              <DropzoneStyledLink>{strings.inputOrderZoneClick}</DropzoneStyledLink>&nbsp;{strings.inputOrderZoneDrop}
            </Body>
            <Body>{strings.inputOrderAcceptedTypes}</Body>
          </FlexColumn>
        );
      }
    } else {
      return (
        <BodySecondary>
          {props.label ? props.label : isDragActive ? strings.uploadDropButton : strings.uploadClickButton}
        </BodySecondary>
      );
    }
  };

  return (
    <DropzoneStyled
      {...getRootProps({ isDragAccept, isDragReject, height: props.isOrderInputDropzone && DropzoneWidth })}
    >
      <input {...getInputProps()} multiple={props.multiple} />
      {renderOrderInputDropzone()}
    </DropzoneStyled>
  );
};
