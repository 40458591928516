export interface IOrderTabBilledStrings {
  title: string;
  filename: string;
  from: string;
  to: string;
  error: string;
  search: string;
}

export interface IOrderTabBilledLanguages {
  pt: IOrderTabBilledStrings;
  es: IOrderTabBilledStrings;
  in: IOrderTabBilledStrings;
}

const pt: IOrderTabBilledStrings = {
  title: 'Faturado',
  filename: 'Pedidos faturados',
  from: 'de',
  to: 'até',
  error: 'Ocorreu um erro ao carregar os dados da aba Faturado! Por favor, tente novamente.',
  search: 'Consultar',
};

export const Strings = {
  pt,
  es: pt,
  in: pt,
};
