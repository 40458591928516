import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IShippingRequestRow } from '@app/modules/shipping-request/shipping-request.interfaces';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { selectEatonTableItemSelector } from '@app/modules/shipping-request/store/shipping-request.selectors';
import {
  setOpenAlertQtyModal,
  updateEatonTableRequestedShippingQuantity,
} from '@app/modules/shipping-request/store/shipping-request.store';
import { EditQtyEatonTableFieldStyled } from './shipping-request-edit-qty-eaton-table.style';

interface ShippingRequestEditQtyFieldProps {
  item: IShippingRequestRow;
  hasSuggestion?: boolean;
}

export const ShippingRequestEditQtyEatonTableField: React.FC<ShippingRequestEditQtyFieldProps> = ({
  item,
  hasSuggestion,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { openShippingRequestSuggestionModalMaterial } = useSelector((state: RootState) => state.shippingRequest);
  const { material, salesOrder } = item;
  const eatonTableItem: IShippingRequestRow = useSelector(state =>
    selectEatonTableItemSelector(state, salesOrder, item.material),
  );
  const [value, setValue] = useState(null);

  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const dispatch = useDispatch();

  const onChange = newValue => {
    if (newValue >= 0) {
      setValue(newValue);
    } else {
      setValue(null);
    }
  };

  useEffect(() => {
    dispatch(updateEatonTableRequestedShippingQuantity({ salesOrder, value, material }));
  }, [value]);

  const hasError =
    eatonTableItem?.requestedShippingQuantity > eatonTableItem?.stockQuantity - eatonTableItem?.shipmentQuantity;

  useEffect(() => {
    if (hasError) {
      dispatch(setOpenAlertQtyModal(true));
    }
  }, [eatonTableItem]);

  useEffect(() => {
    if (!openShippingRequestSuggestionModalMaterial) {
      setValue(item.requestedShippingQuantity);
    } else {
      setValue(eatonTableItem.requestedShippingQuantity);
    }
  }, [item]);

  return (
    <EditQtyEatonTableFieldStyled
      hasError={hasError}
      placeholder={strings.page.placeholderQtyEdit}
      type='number'
      min='0'
      value={value}
      onValueChange={onChange}
      disabled={hasSuggestion}
    />
  );
};
