import { Strings, IFinanceStrings } from '../finance.string';

const getTotalDiv = () => {
  const totalElementDiv = document.createElement('div');
  totalElementDiv.style.width = '100%';
  totalElementDiv.style.display = 'flex';
  totalElementDiv.style.flexDirection = 'row';
  totalElementDiv.style.justifyContent = 'space-between';

  return totalElementDiv;
};

const getFinanceSubtotals = salesOrders => {
  let amountSum = 0;
  let amountBranchSum = 0;

  salesOrders.map(order => {
    if (order.amount) {
      amountSum = amountSum + parseFloat(order.amount.toString());
    }

    if (order.amountBranch) {
      amountBranchSum = amountBranchSum + parseFloat(order.amountBranch.toString());
    }
  });

  return {
    amountSum,
    amountBranchSum,
  };
};

export const renderFinanceSubtotalField = (onlineBillings, language, columnDef) => {
  if (columnDef) {
    const subTotals = getFinanceSubtotals(onlineBillings);

    renderAmountField(subTotals.amountSum, language, columnDef);
    renderAmountBranchField(subTotals.amountBranchSum, language, columnDef);
  }
};

const renderAmountField = (subTotal, language, columnDef) => {
  const strings: IFinanceStrings = Strings[language];
  const ariaLabeAmount = columnDef.find(c => c.field === 'amount');

  if (ariaLabeAmount && ariaLabeAmount.headerName) {
    const financeGridElement = document.getElementById('finance-grid');
    const amountElement = financeGridElement.querySelectorAll(`[aria-label*='${ariaLabeAmount.headerName}']`);

    if (amountElement && amountElement.length) {
      const amountParentElement = amountElement[0].parentElement;
      amountParentElement.removeChild(amountElement[0]);

      const totalDiv = getTotalDiv();
      const labelText = document.createElement('span');
      labelText.innerText = strings?.agGrid.subtotal;
      const spanText = document.createElement('span');
      spanText.setAttribute('id', 'finance-amount-span');
      spanText.innerText =
        subTotal === 0
          ? '0'
          : subTotal.toLocaleString(language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
      totalDiv.appendChild(labelText);
      totalDiv.appendChild(spanText);
      amountParentElement.appendChild(totalDiv);
    } else {
      const financeAmountSpan = document.getElementById('finance-amount-span');

      if (financeAmountSpan) {
        financeAmountSpan.innerText =
          subTotal === 0
            ? '0'
            : subTotal.toLocaleString(language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
      }
    }
  }
};

const renderAmountBranchField = (subTotal, language, columnDef) => {
  const strings: IFinanceStrings = Strings[language];
  const ariaLabeAmount = columnDef.find(c => c.field === 'amountBranch');

  if (ariaLabeAmount && ariaLabeAmount.headerName) {
    const financeGridElement = document.getElementById('finance-grid');
    const amountElement = financeGridElement.querySelectorAll(`[aria-label*='${ariaLabeAmount.headerName}']`);

    if (amountElement && amountElement.length) {
      const amountParentElement = amountElement[0].parentElement;
      amountParentElement.removeChild(amountElement[0]);

      const totalDiv = getTotalDiv();
      const labelText = document.createElement('span');
      labelText.innerText = strings?.agGrid.subtotal;
      const spanText = document.createElement('span');
      spanText.setAttribute('id', 'finance-amount-span');
      spanText.innerText =
        subTotal === 0
          ? '0'
          : subTotal.toLocaleString(language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
      totalDiv.appendChild(labelText);
      totalDiv.appendChild(spanText);
      amountParentElement.appendChild(totalDiv);
    } else {
      const financeAmountSpan = document.getElementById('finance-amount-span');

      if (financeAmountSpan) {
        financeAmountSpan.innerText =
          subTotal === 0
            ? '0'
            : subTotal.toLocaleString(language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
      }
    }
  }
};
