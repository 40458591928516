import * as React from 'react';
import { Allowed } from '@app/data/http/quote.dto';
import { QuoteAttributes, QuoteAttributeTypes } from '@app/models/quote.model';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { SelectField } from '@atomic/atm.select';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { Form, Validators } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import { mapFormOptions } from '../utils/mapFormOptions';

interface AttributeCellFormSuggestionProps {
  attribute: QuoteAttributes;
  title: string;
  derogateOptionsList: Allowed[];
  language: string;
}

export const AttributeFormSuggestion: React.FC<AttributeCellFormSuggestionProps> = ({
  attribute,
  title,
  derogateOptionsList,
  language,
}) => {
  const [min, setMin] = React.useState('');
  const [max, setMax] = React.useState('');
  const strings = TechnicalAnalysisStrings[language].attributes.attributeCell.form;

  const listOptions = mapFormOptions(attribute.attributeValue.key, derogateOptionsList, language);

  const defaultDerogateValue = attribute.derogate?.suggestion?.text
    ? attribute.derogate.suggestion.text
    : attribute.derogate.suggestion;

  let savedValue: string | number | string[];
  if (attribute.type === QuoteAttributeTypes.Number && defaultDerogateValue) {
    savedValue =
      typeof defaultDerogateValue === 'number' ? defaultDerogateValue : defaultDerogateValue.split(' ').shift();
  }

  switch (attribute?.type) {
    case QuoteAttributeTypes.Table:
    case QuoteAttributeTypes.Text:
    case QuoteAttributeTypes.Options:
    case QuoteAttributeTypes.TextArray:
    case QuoteAttributeTypes.Date:
      return (
        <Form.Field
          name='suggestion'
          initialValue={defaultDerogateValue !== '' ? defaultDerogateValue.trim() : ''}
          label={strings.generalTitle(title)}
          validators={[Validators.Required(strings.validator.alertMandatory)]}
        >
          {derogateOptionsList && derogateOptionsList.length > 0 ? (
            <SelectField>
              <option value=''>Selecione</option>
              {listOptions.map(el => (
                <option value={el.label.trim()} key={el.key}>
                  {el.label.trim()}
                </option>
              ))}
            </SelectField>
          ) : attribute.type !== QuoteAttributeTypes.Options ? (
            <TextAreaField />
          ) : (
            <TextField initialValue={defaultDerogateValue?.toString() !== '' ? defaultDerogateValue : null} />
          )}
        </Form.Field>
      );
    case QuoteAttributeTypes.Range:
      return (
        <>
          <Form.Field
            label={strings.minTitle(attribute.unit?.value?.min)}
            name='min'
            onValueChange={value => setMin(value)}
            validators={[Validators.HasAtLeastOne([min, max], strings.validator.range)]}
          >
            <TextField type='number' initialValue={attribute?.derogate?.suggestion?.min} />
          </Form.Field>
          <VSeparator />

          <Form.Field
            label={strings.maxTitle(attribute.unit?.value?.max)}
            name='max'
            onValueChange={value => setMax(value)}
            validators={[Validators.HasAtLeastOne([min, max], strings.validator.range)]}
          >
            <TextField type='number' initialValue={attribute?.derogate?.suggestion?.max} />
          </Form.Field>
        </>
      );
    case QuoteAttributeTypes.Number:
      return (
        <Form.Field
          label={strings.numberTitle(title, attribute.unit?.value)}
          name='suggestion'
          validators={[Validators.Required(strings.validator.alertMandatory)]}
        >
          {derogateOptionsList && derogateOptionsList.length > 0 ? (
            <SelectField>
              <option value=''>Selecione</option>
              {derogateOptionsList.map(el => (
                <option value={el.label} key={el.key}>
                  {el.label}
                </option>
              ))}
            </SelectField>
          ) : (
            <TextField type='number' initialValue={savedValue} />
          )}
        </Form.Field>
      );
    case QuoteAttributeTypes.Boolean:
    default:
      return null;
  }
};
