import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';

export const AdminDeleteModalWrapperStyled = styled.div`
  display: flex;
`;

export const BoldNameWrapperStyled = styled.div`
  padding-left: ${Spacing.Small};
`;
