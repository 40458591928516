import React from 'react';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { strings } from '../../../schedule.strings';

export const ScheduleModalSuccessDeleteBody: React.FC = () => {
  return (
    <>
      <FlexColumn pt={Spacing.XXLarge} pb hAlign='center'>
        <H2>{strings.deleteSuccessMesssage}</H2>
        <FlexRow pt pb>
          <Image.ScheduleDelete />
        </FlexRow>
      </FlexColumn>
      <VSeparator lineVisible />
    </>
  );
};
