import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontSize } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { IMG_RELATIVE_PATH } from './scheduling-complete-modal.constants';
import { ISchedulingCompleteModalStrings, Strings } from './scheduling-complete-modal.string';
import {
  SchedulingCompleteModalBody,
  SchedulingCompleteModalBodyWrapper,
  SchedulingCompleteModalCloseWrapper,
  SchedulingCompleteModalFooter,
  SchedulingCompleteModalHeader,
  SchedulingCompleteModalHeaderImage,
  SchedulingCompleteModalTextWrapper,
  SchedulingCompleteModalTitleWrapper,
  SchedulingCompleteModalWrapper,
  SchedulingComponentHtmlText,
} from './scheduling-complete-modal.style';
import { putSchedulingCompleteModal } from './store/put-scheduling-complete.thunk';

export const SchedulingCompleteModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { hasSchedulingCompleteAnnouncement, announcementData, openedConfigModal } = useSelector(
    (state: RootState) => state.schedulingComplete,
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings: ISchedulingCompleteModalStrings = Strings[userInfo?.language];

  const handleClose = () => {
    dispatch(putSchedulingCompleteModal({ key: announcementData?.announcements[0]?.key, email: userInfo?.email }));
  };

  return (
    <Modal small opened={hasSchedulingCompleteAnnouncement && !openedConfigModal}>
      <SchedulingCompleteModalHeader>
        <SchedulingCompleteModalTitleWrapper>
          {announcementData?.announcements[0]?.title}
        </SchedulingCompleteModalTitleWrapper>
        <SchedulingCompleteModalCloseWrapper>
          <FlexColumn onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
            <Image.NewsModalHeaderCloseButton />
          </FlexColumn>
        </SchedulingCompleteModalCloseWrapper>
      </SchedulingCompleteModalHeader>
      <SchedulingCompleteModalWrapper>
        <SchedulingCompleteModalBody>
          <SchedulingCompleteModalHeaderImage>
            <img width='300px' src={'assets/icons/brand/gerdau-logo-full.png'} />
          </SchedulingCompleteModalHeaderImage>
          <SchedulingCompleteModalBodyWrapper>
            <SchedulingCompleteModalTextWrapper>
              <SchedulingComponentHtmlText
                size={FontSize.Large}
                dangerouslySetInnerHTML={{ __html: announcementData?.announcements[0]?.message }}
              ></SchedulingComponentHtmlText>
            </SchedulingCompleteModalTextWrapper>
            <SchedulingCompleteModalTextWrapper>
              <img width='224px' src={IMG_RELATIVE_PATH + announcementData?.announcements[0]?.url[0]} />
            </SchedulingCompleteModalTextWrapper>
          </SchedulingCompleteModalBodyWrapper>
          <SchedulingCompleteModalFooter>
            <FlexRow hAlign='center'>
              <Button testid={`${TEST_ID_CLOSE_MODAL}-annoucement`} onClick={() => handleClose()}>
                {strings?.announcement.confirmButton}
              </Button>
            </FlexRow>
          </SchedulingCompleteModalFooter>
        </SchedulingCompleteModalBody>
      </SchedulingCompleteModalWrapper>
    </Modal>
  );
};
