import React from 'react';
import { useDispatch } from 'react-redux';
import { Material } from '@app/models';
import { setDeleteMaterialModalOpened, setMaterialDelete } from '@app/modules/order-input/store/order-input.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';

interface IDeleteFieldProps {
  material: Material;
}

export const DeleteField: React.FC<IDeleteFieldProps> = ({ material }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setMaterialDelete(material));
    dispatch(setDeleteMaterialModalOpened(true));
  };

  return <FaIcon.Trash color={Color.Alert} onClick={onClick} />;
};
