import styled from 'styled-components';
import { Border, Color, Spacing } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';

export const OrderInputOptionsWrapper = styled(Grid)`
  margin-top: ${Spacing.XXLarge};
`;

export const IATagRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IATagWrapper = styled.div`
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: ${Color.PurpleDark};
  border-radius: ${Border.Radius};
  padding: ${Spacing.XSmall} ${Spacing.XSmall} ${Spacing.XSmall} ${Spacing.XSmall};
`;
