import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { OrderDataSource } from '@app/data/http/order-MI.datasource';
import { HanaLanguage } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { SLICE_NAME } from '../order-tab-billed.constants';
import { IOrderTabBilledState } from './order-tab-billed.interface';
import { currentReducerSelector } from './order-tab-billed.selectors';

export const performRequest = createAsyncThunk(`${SLICE_NAME}/performRequest`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const promiseRequestTime = new Date().getTime();
  const customerIds = customerIdsJoinedSelector(state);
  const orderTabBilledState: IOrderTabBilledState = currentReducerSelector(state);
  const startInvoiceDate = format(orderTabBilledState.startDate, 'yyyyMMdd');
  const endInvoiceDate = format(orderTabBilledState.endDate, 'yyyyMMdd');

  return await OrderDataSource.getBilledOrder({
    customerIds,
    startInvoiceDate,
    endInvoiceDate,
    language: HanaLanguage[state.auth?.userInfo?.language],
  })
    .then(data => ({ promiseRequestTime, data }))
    .catch(err => {
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});
