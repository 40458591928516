import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { IZeroStateStrings, Strings } from './zero-state.string';

interface OnboardActionTooltipProps {
  onClose: () => void;
  onClick: () => void;
  visible: boolean;
}

export const ZeroStateTooltip: React.FC<OnboardActionTooltipProps> = props => {
  const { language } = useSelector((state: RootState) => state.auth.userInfo);
  const strings: IZeroStateStrings = Strings[language];

  return (
    <CustomizedTooltip
      step={1}
      totalSteps={1}
      title={strings.searchClientHere}
      description=''
      visible={props.visible}
      onClick={props.onClick}
      onClose={props.onClose}
      customHeight={-70}
      customLeftAlign={160}
      customArrowX={300}
      positionXFromRight={false}
      hasFooter={false}
    />
  );
};
