import { debounce } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { RootState } from '@app/core/redux/store';
import { useGetDocumentsOrder } from '@app/domain';
import { InternationalTabIndex } from '@app/models/order.model';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { DateFilter } from '@app/modules/components/date-filter.component';
import { UserStore, useUserStore } from '@app/providers';
import { TextField } from '@atomic/atm.text-field';
import { H2 } from '@atomic/atm.typography';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state/loading-state.component';
import { ErrorPlaceholder } from '../../../../../components/error.placeholder';
import { OrderDocumentsCell } from '../../../../order-documents-cell';
import { OrderDocumentsCellShimmer } from '../../../../order-documents-cell.shimmer';
import { OrderTabsHeaderME } from '../../order-tabs-header-ME.component';
import { Strings, IOrderTabDocumentsStrings } from './order-tab-documents.string';

const DebounceDelayTime = 500;

export const OrderTabDocuments: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const clientBpsSelectedJoined = useSelector(customerIdsJoinedSelector);
  const strings: IOrderTabDocumentsStrings = Strings[userInfo.language];

  const {
    data,
    handleDateChange,
    setDocumentsProfile,
    documentsResponse,
    loading,
    error,
    retry,
  } = useGetDocumentsOrder(clientBpsSelectedJoined);

  const handleValueChange = debounce(value => {
    const filteredList = documentsResponse.filter(documents => documents.boarding.includes(value));
    setDocumentsProfile(filteredList);
  }, DebounceDelayTime);

  return (
    <>
      <OrderTabsHeaderME tabIndex={InternationalTabIndex.Documents} />
      <Grid fluid>
        <Row mt>
          <Col xs={4}>
            <TextField onValueChange={handleValueChange} placeholder={strings.searchPlaceholder} icon='search' />
          </Col>

          <Col xs={4}>
            <DateFilter onDateChange={handleDateChange} statusDateFilter={loading} />
          </Col>
        </Row>

        <H2>{strings.mostRecent}</H2>
        <LoadingState loading={loading} data={data.length > 0} error={!!error}>
          <LoadingState.Shimmer>
            <OrderDocumentsCellShimmer />
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <ErrorPlaceholder onRetryClick={() => retry()} />
          </LoadingState.Error>

          <Row>
            {data?.map(item => (
              <Col md={3} key={item.boarding}>
                <OrderDocumentsCell boarding={item.boarding} registrationDate={item.registrationDate} user={user} />
                <VSeparator />
              </Col>
            ))}
          </Row>
        </LoadingState>
      </Grid>
    </>
  );
};
