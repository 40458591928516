import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { PatchAvailableStatusParams } from '@app/data/http/quote-status-params.dto';

export const patchStatus = async (params?: PatchAvailableStatusParams) => {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  let notification;

  if (!params.notification?.partNumber) {
    notification = params.notification
      ? {
          emails: params?.current?.external?.key === 'UNDER_ANALYSIS' ? [] : params.notification.emails,
          skus: params.notification.skus,
          observation: params.notification.observation,
          observationClient: params.notification.observationClient,
          newProductEppJustify: params.notification.NEWPRODUCTEPPJUSTIFY,
        }
      : { emails: [] };
  } else {
    notification = params.notification && {
      emails: params?.current?.external?.key === 'UNDER_ANALYSIS' ? [] : params.notification.emails,
      skus: params.notification.skus,
      part_number: params.notification.partNumber,
      observation: params.notification.observation,
      observationClient: params.notification.observationClient,
      newProductEppJustify: params.notification.NEWPRODUCTEPPJUSTIFY,
    };
  }
  const current = params.current;
  const extraInfos = params.extraInfos;
  const analysisType = params.analysisType;

  const data = { current, notification, extraInfos, analysisType };

  if (!params.extraInfos) {
    delete data.extraInfos;
  }

  if (!params.analysisType) {
    delete data.analysisType;
  }

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_STATUS
  }`;

  const { data: dataResponse, status } = await axios.patch(url, data, { headers: defaultHeaders });

  if (status === 200) {
    return dataResponse;
  } else {
    return null;
  }
};

export const useChangeStatus = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(patchStatus, {
    onSuccess,
    onError,
    onMutate: async () => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);

      return {
        previousData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
    retry: false,
  });
};
