import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { Link } from '@app/core/route';
import { Stages } from '@app/models/quote.model';
import { AnalysisApqpTypes } from '@app/models/technical-analysis.model';
import { Button } from '@atomic/atm.button';
import { RadioField } from '@atomic/atm.radio';
import { TextAreaField } from '@atomic/atm.text-field';
import { Body, H2, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { TechnicalAnalysisEvents } from '../../quote.analytics';
import { TechnicalAnalysisComponentStrings } from './technical-analysis-component.strings';

export interface ApqpData {
  apqpOption: string;
  peOption: string;
  fmeasOption: string;
  changeOption: string;
  newProductEppOption: string;
  newProductEppJustify: string;
}

interface AttributeStepProps {
  opened: boolean;
  data?: Stages;
  onModalClose: () => void;
  onNextClick: (
    apqpOption: string,
    peOption: string,
    fmeasOption?: string,
    changeOption?: string,
    newProductEpp?: string,
    newProductEppJustify?: string,
  ) => void;
}

export const AnalysisApqpModal: React.FC<AttributeStepProps> = props => {
  const [apqpOption, setApqpOption] = React.useState<string>(null);
  const [peOption, setPeOption] = React.useState<string>(null);
  const [showDownloadButton, setShowDownloadButton] = React.useState<boolean>(false);
  const [showPeObservation, setShowPeObservation] = React.useState<boolean>(false);
  const [showNewProductEppJustify, setShowNewProductEppJustify] = React.useState<boolean>(false);
  const [fmeasOption, setFmeasOption] = React.useState<string>(null);
  const [changeOption, setChangeOption] = React.useState<string>(null);
  const [newProductEppOption, setNewProductEppOption] = React.useState<string>(null);

  const { userInfo } = useSelector((state: RootState) => state.auth);

  const strings = TechnicalAnalysisComponentStrings[userInfo.language].analysisApqpModal;

  const analysisApqpInformation = {
    [AnalysisApqpTypes.A]: { description: strings.apqp.optionsLabel.A },
    [AnalysisApqpTypes.B]: { description: strings.apqp.optionsLabel.B },
    [AnalysisApqpTypes.C]: { description: strings.apqp.optionsLabel.C },
  };

  const peQuestion = [{ description: strings.pe.optionsLabel.yes }, { description: strings.pe.optionsLabel.no }];

  const fmeasQuestion = [
    { description: strings.fmeas.optionsLabel.yes },
    { description: strings.fmeas.optionsLabel.no },
  ];

  const changeQuestion = [
    { description: strings.change.optionsLabel.yes },
    { description: strings.change.optionsLabel.no },
  ];

  const newProductEpp = [
    { description: strings.newProductEpp.optionsLabel.yes },
    { description: strings.newProductEpp.optionsLabel.no },
  ];

  React.useEffect(() => {
    apqpOption === 'Sim, ainda não anexei' ? setShowDownloadButton(true) : setShowDownloadButton(false);
    peOption === 'Sim' ? setShowPeObservation(true) : setShowPeObservation(false);
    newProductEppOption === 'Sim' ? setShowNewProductEppJustify(true) : setShowNewProductEppJustify(false);
  }, [apqpOption, peOption, newProductEppOption]);

  const handleSubmit = (data: FormData<ApqpData>) => {
    if (Object.keys(data.error).length) {
      return;
    }
    props.onNextClick(
      data.data.apqpOption,
      data.data.peOption,
      data.data.fmeasOption,
      data.data.changeOption,
      data.data.newProductEppOption,
      data.data.newProductEppJustify,
    );
    setApqpOption(null);
    setPeOption(null);
    setFmeasOption(null);
    setChangeOption(null);
    setNewProductEppOption(null);
  };

  const handleAnalytics = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysisEvents.DownloadApqpFile });
  };

  return (
    <Modal small opened={props.opened} onClose={props.onModalClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <H2>{strings.title}</H2>

          <H3>{strings.apqp.label}</H3>
          <VSeparator small />
          <Form.Field
            name='apqpOption'
            onValueChange={setApqpOption}
            initialValue={apqpOption}
            validators={[Validators.Required('Este campo é obrigatório')]}
          >
            {Object.values(AnalysisApqpTypes).map((option, index) => (
              <Hbox.Item key={`c-${index}`}>
                <RadioField key={option} id={analysisApqpInformation[option]?.description}>
                  {analysisApqpInformation[option]?.description}
                </RadioField>
                {option === 'Sim, ainda não anexei' && showDownloadButton && (
                  <Hbox.Item hAlign='flex-start'>
                    <a
                      href='/assets/data/CF_ISA_XXX_XX_CLIENTEXXX_NormaXXXX_revisao_XXXX.xlsx'
                      download='CF_ISA_XXX_XX_CLIENTEXXX_NormaXXXX_revisao_XXXX.xlsx'
                      onClick={handleAnalytics}
                    >
                      <Button size='small' kind='secondary'>
                        <Hbox.Item>{strings.downloadButtonLabel}</Hbox.Item>
                      </Button>
                      <Separator small />
                    </a>
                  </Hbox.Item>
                )}
              </Hbox.Item>
            ))}
          </Form.Field>
          <VSeparator />
          <H3>{strings.pe.label}</H3>
          <Form.Field
            name='peOption'
            onValueChange={setPeOption}
            initialValue={peOption}
            validators={[Validators.Required('Este campo é obrigatório')]}
          >
            {Object.values(peQuestion).map((option, index) => (
              <Hbox.Item key={`d-${index}`}>
                <RadioField key={index} id={option.description}>
                  {peQuestion[index]?.description}
                </RadioField>
                {option.description === 'Sim' && showPeObservation && (
                  <>
                    <Hbox.Item key={`b-${index}`} hAlign='flex-start'>
                      <Body>{strings.pe.observation}</Body>
                    </Hbox.Item>
                    <Separator small />
                  </>
                )}
              </Hbox.Item>
            ))}
          </Form.Field>

          <VSeparator />
          <H3>{strings.fmeas.label}</H3>
          <Form.Field
            name='fmeasOption'
            onValueChange={setFmeasOption}
            initialValue={fmeasOption}
            validators={[Validators.Required('Este campo é obrigatório')]}
          >
            {Object.values(fmeasQuestion).map((option, index) => (
              <Hbox.Item key={`d-${index}`}>
                <RadioField key={index} id={option.description}>
                  {fmeasQuestion[index]?.description}
                </RadioField>
              </Hbox.Item>
            ))}
          </Form.Field>

          <VSeparator />
          <H3>{strings.change.label}</H3>
          <Form.Field
            name='changeOption'
            onValueChange={setChangeOption}
            initialValue={changeOption}
            validators={[Validators.Required('Este campo é obrigatório')]}
          >
            {Object.values(changeQuestion).map((option, index) => (
              <Hbox.Item key={`d-${index}`}>
                <RadioField key={index} id={option.description}>
                  {changeQuestion[index]?.description}
                </RadioField>
              </Hbox.Item>
            ))}
          </Form.Field>
          <VSeparator />

          <H3>{strings.newProductEpp.label}</H3>
          <Form.Field
            name='newProductEppOption'
            onValueChange={setNewProductEppOption}
            initialValue={newProductEppOption}
            validators={[Validators.Required('Este campo é obrigatório')]}
          >
            <Link to='' target='_blank'>
              <Body small></Body>
            </Link>
            <Hbox.Item hAlign='flex-start'>
              <a
                href='https://gerdau.greendocs.net/Itens/642439/4796545?o=0&e=0&admin=False&naolido=False&BloquearItemRevProtocolo=False&idDocRef=0&instancia=10463175&cache=True&_p=37&referer=lista&compartilhamento=False'
                target='_blank'
                rel='noreferrer'
              >
                <Button size='small' kind='secondary'>
                  <Hbox.Item>Padrão GSB-PR-261-001</Hbox.Item>
                </Button>
                <Separator small />
              </a>
            </Hbox.Item>

            {Object.values(newProductEpp).map((option, index) => (
              <Hbox.Item key={`d-${index}`}>
                <RadioField key={index} id={option.description}>
                  {newProductEpp[index]?.description}
                </RadioField>
              </Hbox.Item>
            ))}
          </Form.Field>

          {showNewProductEppJustify && (
            <>
              <VSeparator />
              <Form.Field name='newProductEppJustify' validators={[Validators.Required('Este campo é obrigatório')]}>
                <TextAreaField placeholder={strings.newProductEpp.labelJustify} disableResize />
              </Form.Field>
            </>
          )}

          <VSeparator />

          <VSeparator />
          <Hbox>
            <Hbox.Item hAlign='flex-end'>
              <Button type='submit'>{strings.next}</Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};
