import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { IAdminState } from '../../admin.interfaces';
import { AdminStrings } from '../../admin.string';
import { userInitialState } from '../../utils/utils';
import { getBPs } from './bps-get.thunk';
import { getUsers } from './users-get.thunk';

export const deleteUser = createAsyncThunk(`${SLICE_NAME}/deleteUser`, async (email: string, thunkAPI) => {
  const strings = AdminStrings.pt.deleteModal;
  const state: any = thunkAPI.getState();
  const { user, userSearch, bpSearch }: IAdminState = state.admin;

  return await AdminDataSource.deleteUser({ email })
    .then(data => {
      thunkAPI.dispatch(
        getUsers({
          skip: userSearch.pagination.skip - userSearch.pagination.limit,
          limit: userSearch.pagination.limit,
          filterType: userSearch.filterOption,
          filterValue: userSearch.textQuery,
        }),
      );
      thunkAPI.dispatch(
        getBPs({
          skip: bpSearch.pagination.skip - bpSearch.pagination.limit,
          limit: bpSearch.pagination.limit,
          filterType: bpSearch.filterOption,
          filterValue: bpSearch.textQuery,
        }),
      );
      thunkAPI.dispatch(setToast({ show: true, text: strings.success(user.name), variant: ToastVariant.SUCCESS }));
      return data;
    })
    .catch(err => {
      handleUnauthorized(err);
      if (err.response) {
        thunkAPI.dispatch(setToast({ show: true, text: strings.error(user.name), variant: ToastVariant.DANGER }));
      }
    });
});

export const addDeleteUserReducers = builder => {
  builder.addCase(deleteUser.pending, state => {
    state.loadingSubmit = true;
  });
  builder.addCase(deleteUser.fulfilled, state => {
    state.loadingSubmit = false;
    state.showForm = false;
    state.showDeleteModal = false;
    state.isEditing = false;
    state.user = userInitialState;
    state.bpFilter.filterOption = [];
    state.bpFilter.textQuery = '';
    state.bpFilter.selectedOptions = [];
    state.bpFilter.selectedCorpGroupOptions = [];
  });
  builder.addCase(deleteUser.rejected, state => {
    state.loadingSubmit = false;
  });
};
