import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';

export const ColumnDragWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  height: 150px;
  border: 1px solid ${Color.Gray};
  border-radius: 4px;
  overflow: auto;
`;
