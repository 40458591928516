import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { setShowDaySelect } from '@app/modules/components/schedule-modal/store/schedule.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexColumn } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { DaySelectWrapper, DaysSelected } from './day-select.style';
import { FixedCalendar } from './partials/fixed-calendar/fixed-calendar';

export const DaySelect: React.FC = () => {
  const { selectedDays, showDaySelect } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setShowDaySelect(true));
  };

  let selectedText = '';

  if (selectedDays.length) {
    selectedText = selectedDays.join(',');
  }

  return (
    <FlexColumn>
      <DaySelectWrapper onClick={onClick}>
        <DaysSelected>{selectedText.replace(/,([^,]*)$/, ' e $1')}</DaysSelected>
        <FaIcon.Calendar size='sm' color={Color.Primary} />
      </DaySelectWrapper>
      {showDaySelect && <FixedCalendar />}
    </FlexColumn>
  );
};
