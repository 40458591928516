import styled from 'styled-components';
import { BrandColor, Color, Spacing } from '@atomic/obj.constants';

export const ToolTipWrapper = styled.div`
  position: relative;
`;

export const ToolTipContainer = styled.div`
  position absolute;
  ${props => (props.align === 'left' ? 'left: 0;' : 'right: 0;')}
  top: ${props => (props.customVerticalAlign ? props.customVerticalAlign : 40)}px;
  ${props => props.customHorizontalAlign && `left: ${props.customHorizontalAlign}px`};
  padding: ${Spacing.Medium};    
  background-color: ${BrandColor.Blue};
  border-radius: ${Spacing.XSmall};
  width: 350px;
  z-index: 1;
  & > h3 {
    color: ${Color.White};
    margin-bottom: ${Spacing.Small};
    padding-right: 35px;
  }
  & > p {
    color: ${Color.White};
    padding-right: 35px;
  }
`;

export const Arrow = styled.div`
  top: -5px;
  width: 10px;
  height: 10px;
  margin: 0 ${Spacing.Medium};
  background-color: ${BrandColor.Blue};
  transform: rotate(45deg);
`;

export const ToolTipHeader = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: ${props => (props.alignArrow ? props.alignArrow : 'flex-start')};
  left: 0;
  top: -5px;
  & > svg {
    position: absolute;
    right: ${Spacing.Small};
    top: ${Spacing.Medium};
  }
`;

export const ToolTipFooter = styled.div`
  width: 100%;
  display: flex;
  padding-top: ${Spacing.Medium};
  justify-content: ${props => (props.hasSteps ? 'space-between' : 'flex-end')};
`;

export const Steps = styled.div`
  align-self: center;
  & > p {
    color: ${Color.White};
    opacity: 0.8;
  }
`;
