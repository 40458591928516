import * as React from 'react';
import { BadgeStyled, BadgeTextStyled } from './badge.component.style';

export interface BadgeProps {
  /** Colors supported in this badge. This should follow the color constants  */
  color:
    | 'primary'
    | 'secondary'
    | 'accessory'
    | 'alert'
    | 'success'
    | 'warning'
    | 'warningDark'
    | 'neutral'
    | 'pink'
    | 'purple'
    | 'brown'
    | 'orange'
    | 'delay'
    | 'delayTrend';
  dark?: boolean;
  textColor?: 'delayText' | 'delayTrendText';
  id?: string;
}

export const Badge: React.FC<BadgeProps> = props => {
  return (
    <BadgeStyled {...props}>
      <BadgeTextStyled {...props}>{props.children}</BadgeTextStyled>
    </BadgeStyled>
  );
};
