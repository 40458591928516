import * as React from 'react';
import { IAdminBP } from '@app/models/admin.model';
import { AdminListHeaderSeparator, AdminListHeaderWrapper, BpTypeSpan } from '@app/modules/admin/admin.page.style';
import { AdminStrings } from '@app/modules/admin/admin.string';
import { Body } from '@atomic/atm.typography';

interface AdminBPListHeaderProps {
  bp: IAdminBP;
}

export const AdminBPListHeader: React.FC<AdminBPListHeaderProps> = props => {
  const { name, type, bp, users } = props.bp;
  const strings = AdminStrings.pt.common;

  return (
    <AdminListHeaderWrapper>
      <Body bold={700}>
        {`${name} -`}
        <BpTypeSpan>{type}</BpTypeSpan>
      </Body>
      <AdminListHeaderSeparator />
      <Body>{`${strings.bpNumber} ${bp}`}</Body>
      <AdminListHeaderSeparator />
      <Body>{`${strings.userQty} ${users.length}`}</Body>
    </AdminListHeaderWrapper>
  );
};
