import React from 'react';
import { useSelector } from 'react-redux';
import { isEatonSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import {
  getSuggestionTableColumnsDefinitions,
  TableTDTypes,
} from '@app/modules/shipping-request/shipping-request.constants';
import {
  IShippingRequestColumns,
  IShippingRequestRow,
} from '@app/modules/shipping-request/shipping-request.interfaces';
import { getSuggestionValue } from '@app/modules/shipping-request/shipping-request.utils';
import { TD, TR } from '@atomic/mol.table';
import { ShippingRequestTableBodyTD } from '../../../shipping-request-body/partials/shipping-request-table/partials/shipping-request-table-body/partials/shipping-request-table-body-td/shipping-request-table-body-td.component';
import {
  SuggestionCheckBoxTD,
  SuggestionFirstTD,
  SuggestionTD,
  SuggestionTR,
} from './shipping-request-suggestion-table-body.style';

interface IShippingRequestSuggestionTableBodyProps {
  options: IShippingRequestRow[];
}

export const ShippingRequestSuggestionTableBody: React.FC<IShippingRequestSuggestionTableBodyProps> = ({ options }) => {
  const isEaton = useSelector(isEatonSelector);

  if (options.length < 0) {
    return null;
  }

  const renderMultipleOptions = () => {
    return (
      <>
        {options.map((option, index) => {
          return (
            <SuggestionTR key={`suggestion-tr-${index}`} bordered>
              {getSuggestionTableColumnsDefinitions(options.length > 1, isEaton).map(
                (column: IShippingRequestColumns, colIndex: number) => {
                  if (column.type === TableTDTypes.SuggestionCheckBox) {
                    return (
                      <SuggestionCheckBoxTD key={`suggestion-td-${column.field}`}>
                        <ShippingRequestTableBodyTD item={option} type={column.type} value={option[column.field]} />
                      </SuggestionCheckBoxTD>
                    );
                  } else if (colIndex === 0) {
                    return (
                      <SuggestionFirstTD key={`suggestion-td-${column.field}`}>
                        <ShippingRequestTableBodyTD item={option} type={column.type} value={option[column.field]} />
                      </SuggestionFirstTD>
                    );
                  } else if (column.type === TableTDTypes.SuggestionQty) {
                    const value = getSuggestionValue(option.stockQuantity, option.shipmentQuantity);
                    return (
                      <SuggestionTD key={`suggestion-td-${column.field}`}>
                        <ShippingRequestTableBodyTD item={option} type={TableTDTypes.Qty} value={value} />
                      </SuggestionTD>
                    );
                  } else {
                    return (
                      <SuggestionTD key={`suggestion-td-${column.field}`}>
                        <ShippingRequestTableBodyTD item={option} type={column.type} value={option[column.field]} />
                      </SuggestionTD>
                    );
                  }
                },
              )}
            </SuggestionTR>
          );
        })}
      </>
    );
  };

  const renderSingleOption = () => {
    return (
      <>
        {options.map((option, index) => {
          return (
            <TR key={`suggestion-tr-${index}`} bordered>
              {getSuggestionTableColumnsDefinitions(options.length > 1, isEaton).map(
                (column: IShippingRequestColumns) => (
                  <TD key={`suggestion-td-${column.field}`}>
                    <ShippingRequestTableBodyTD item={option} type={column.type} value={option[column.field]} />
                  </TD>
                ),
              )}
            </TR>
          );
        })}
      </>
    );
  };

  if (options.length > 1) {
    return renderMultipleOptions();
  } else {
    return renderSingleOption();
  }
};
