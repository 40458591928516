import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';

export const NewsTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const NewsTagBase = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Color.SuccessMain};
  color: ${Color.White};
  border-radius: 4px;
  width: 35px;
  height: 16px;
  padding: 2px 8px 2px 8px;
  font-family: 'Open Sans';
  font-size: small;
  font-size: 8px;
  font-weight: bold;
`;

export const NewsTagBody = styled(NewsTagBase)`
  position: absolute;
  top: 9px;
  left: -10px;
`;

export const NewsTagHeaderIcon = styled(NewsTagBase)`
  position: absolute;
  top: 9px;
  left: -20px;
`;

export const NewsTagTab = styled(NewsTagBase)`
  position: absolute;
  top: 6px;
  left: -20px;
`;

export const NewsTagActionButton = styled(NewsTagBase)`
  position: absolute;
  top: 11px;
  left: -25px;
`;
