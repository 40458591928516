import styled from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Border, Color, Spacing, ZIndex } from '@atomic/obj.constants';
import { fieldCss } from '@atomic/obj.form/field.component.styled';

export const MultipleSelectStyled = styled.div`
  ${fieldCss}

  &:hover {
    cursor: pointer;
  }
`;

interface OptionBoxStyledProps {
  show: boolean;
  offsetTop?: number;
}

export const OptionsBoxStyled = styled.div`
  position: absolute;
  border: 1px solid;
  border-color: ${Color.Gray};
  border-radius: ${Border.Radius};
  padding: ${Spacing.Small};
  visibility: ${(props: OptionBoxStyledProps) => (props.show ? 'visible' : 'hidden')};
  max-height: ${(props: OptionBoxStyledProps) => (props.show ? '300px' : 0)};
  overflow-y: scroll;
  z-index: ${ZIndex.ChatSelectWrapper};
  background-color: ${Color.GrayLight};
  width: ${(props: OptionBoxStyledProps) => (props.show ? '230px' : 0)};
  right: 5px;
  top: ${(props: OptionBoxStyledProps) =>
    props.offsetTop && props.offsetTop - 260 < 0 ? `${props.offsetTop - 260}px` : '10px'};
`;

export const OptionStyled = styled.div`
  position: relative;
  border: 1px solid;
  border-color: ${Border.Color};
  padding: ${Spacing.Small};
  background-color: ${Color.GrayLight};
  & + & {
    border-top: 0;
  }

  &:hover {
    background-color: ${Color.Secondary};
  }
`;

export const MultipleSelectIconStyled = styled(FaIcon.ChevronDown).attrs({ size: '1x' })`
  position: absolute;
  pointer-events: none;
  top: ${Spacing.Medium};
  right: ${Spacing.Small};
  color: ${Color.GrayDark};
`;
