import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { MEMaritimeOrderDataSource } from '@app/data/http/order-ME-maritime.datasource';
import { HanaLanguage } from '@app/models';
import { SalesOrdersMaritimeHarborItem } from '@app/models/maritime-order-item.model';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { defaultInitialState, IOrderTabState } from '../../../order.interfaces';
import { extraReducersConfig, reducers } from '../../../utils';

interface IOrderTabHarborState extends IOrderTabState {
  data: SalesOrdersMaritimeHarborItem[];
  filteredRows: SalesOrdersMaritimeHarborItem[];
}

const SLICE_NAME = 'order-tab-harbor';

export const performRequest = createAsyncThunk(`${SLICE_NAME}/performRequest`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const promiseRequestTime = new Date().getTime();
  const customerIds = customerIdsJoinedSelector(thunkAPI.getState());
  return await MEMaritimeOrderDataSource.getMeMaritimeHarbor({
    customerIds,
    language: HanaLanguage[state.auth?.userInfo?.language],
  })
    .then(data => ({ promiseRequestTime, data }))
    .catch(err => {
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const order = createSlice<IOrderTabHarborState, SliceCaseReducers<IOrderTabHarborState>>({
  name: SLICE_NAME,
  initialState: defaultInitialState,
  reducers,
  extraReducers: extraReducersConfig(performRequest),
});

export const { setData, setLastUpdate, setFilteredRows } = order.actions;

export const orderTabHarborReducer = order.reducer;
