import { PayloadAction } from '@reduxjs/toolkit';
import { REDUX_DATE_ERROR_MESSAGE } from '@app/constants';
import { LayoutTabs } from '@app/models/layout.model';
import {
  INPUT_FILTERS_INITIAL_VALUE,
  ORDER_PAGE_RANGE_FILTER_FIELDS,
  RANGE_FILTERS_INITIAL_VALUE,
} from '../sidebar-filters.constants';
import {
  IAdvancedFiltersState,
  IRangeState,
  IRemoveInputFiltersPayload,
  ISetCheckboxFilterPayload,
  ISetDateFilterPayload,
  ISetFilterPayload,
  ISetRangeFilterLimitPayload,
  ISetRangeFilterValuePayload,
} from '../sidebar-filters.interfaces';

export const reducers = {
  confirmFilters: (state: IAdvancedFiltersState, { payload }: PayloadAction<LayoutTabs>) => {
    Object.keys(state[payload].confirmedFilters).forEach(key => {
      if (Array.isArray(state[payload].filters[key])) {
        state[payload].confirmedFilters[key] = state[payload].filters[key].filter(value => value !== '');
      } else {
        state[payload].confirmedFilters[key] = state[payload].filters[key];
      }
    });
  },
  resetOrderFilters: (state: IAdvancedFiltersState, { payload }: PayloadAction<LayoutTabs>) => {
    Object.keys(state[payload].filters).forEach(key => {
      state[payload].confirmedFilters[key] = [];
    });
    state[payload].confirmedFilters.dateSolClient = { startDate: null, endDate: null };
    state[payload].confirmedFilters.dateConfirmed = { startDate: null, endDate: null };

    state[payload].filters.ov = INPUT_FILTERS_INITIAL_VALUE;
    state[payload].filters.material = INPUT_FILTERS_INITIAL_VALUE;
    state[payload].filters.descMaterial = INPUT_FILTERS_INITIAL_VALUE;
    state[payload].filters.codMaterialClient = INPUT_FILTERS_INITIAL_VALUE;
    state[payload].filters.plantName = [];
    state[payload].filters.dateSolClient = { startDate: null, endDate: null };
    state[payload].filters.dateConfirmed = { startDate: null, endDate: null };

    ORDER_PAGE_RANGE_FILTER_FIELDS.forEach(key => {
      state[payload].filters[key] = {
        minVal: state[payload].filters[key].minLimit,
        maxVal: state[payload].filters[key].maxLimit,
        minLimit: state[payload].filters[key].minLimit,
        maxLimit: state[payload].filters[key].maxLimit,
      };
      state[payload].confirmedFilters[key] = RANGE_FILTERS_INITIAL_VALUE;
    });
  },

  resetFinanceFilters: (state: IAdvancedFiltersState, { payload }: PayloadAction<LayoutTabs>) => {
    Object.keys(state[payload].filters).forEach(key => {
      state[payload].confirmedFilters[key] = [];
    });

    state.FinanceMI.filters.documentNumber = INPUT_FILTERS_INITIAL_VALUE;
    state.FinanceMI.filters.invoiceKey = INPUT_FILTERS_INITIAL_VALUE;
    state.FinanceMI.filters.launchDate = { startDate: null, endDate: null };
    state.FinanceMI.filters.experyDate = { startDate: null, endDate: null };
    state.FinanceMI.filters.rangeDaysOverdue = [];
    state.FinanceMI.filters.documentType = [];
  },
  removeInputFilters: (state: IAdvancedFiltersState, { payload }: PayloadAction<IRemoveInputFiltersPayload>) => {
    const { tab, filterKey } = payload;

    state[tab].confirmedFilters[filterKey] = [];
    state[tab].filters[filterKey] = INPUT_FILTERS_INITIAL_VALUE;
  },
  removeDateFilters: (state: IAdvancedFiltersState, { payload }: PayloadAction<IRemoveInputFiltersPayload>) => {
    const { tab, filterKey } = payload;

    state[tab].confirmedFilters[filterKey] = { startDate: null, endDate: null };
    state[tab].filters[filterKey] = { startDate: null, endDate: null };
  },
  removeCheckboxFilters: (state: IAdvancedFiltersState, { payload }: PayloadAction<IRemoveInputFiltersPayload>) => {
    const { tab, filterKey } = payload;

    state[tab].confirmedFilters[filterKey] = [];
    state[tab].filters[filterKey] = [];
  },
  removeRangeFilters: (state: IAdvancedFiltersState, { payload }: PayloadAction<IRemoveInputFiltersPayload>) => {
    const { tab, filterKey } = payload;
    const { minLimit, maxLimit }: IRangeState = state[tab].filters[filterKey];
    state[tab].filters[filterKey] = { minVal: minLimit, maxVal: maxLimit, minLimit, maxLimit };
    state[tab].confirmedFilters[filterKey] = RANGE_FILTERS_INITIAL_VALUE;
  },
  setInputFilters: (state: IAdvancedFiltersState, { payload }: PayloadAction<ISetFilterPayload>) => {
    const { tab, filterKey, value } = payload;

    state[tab].filters[filterKey] = value;
  },
  setStartDateFilter: (state: IAdvancedFiltersState, { payload }: PayloadAction<ISetDateFilterPayload>) => {
    const { tab, filterKey, value } = payload;

    if (typeof value === 'number' || value == null) {
      state[tab].filters[filterKey].startDate = value;
    } else {
      throw Error(REDUX_DATE_ERROR_MESSAGE);
    }
  },
  setEndDateFilter: (state: IAdvancedFiltersState, { payload }: PayloadAction<ISetDateFilterPayload>) => {
    const { tab, filterKey, value } = payload;
    state[tab].filters[filterKey].endDate = value;
  },

  setCheckboxFilters: (state: IAdvancedFiltersState, { payload }: PayloadAction<ISetCheckboxFilterPayload>) => {
    const { tab, filterKey, key } = payload;

    if (!state[tab].filters[filterKey].includes(key)) {
      const cloneArray = state[tab].filters[filterKey];
      cloneArray.push(key);
      state[tab].filters[filterKey] = cloneArray;
    } else {
      state[tab].filters[filterKey] = state[tab].filters[filterKey].filter(item => item !== key);
    }
  },
  setRangeLimitFilter: (state: IAdvancedFiltersState, { payload }: PayloadAction<ISetRangeFilterLimitPayload>) => {
    const { tab, filterKey, minLimit, maxLimit } = payload;
    state[tab].filters[filterKey].minLimit = minLimit;
    state[tab].filters[filterKey].maxLimit = maxLimit;
    state[tab].filters[filterKey].minVal = minLimit;
    state[tab].filters[filterKey].maxVal = maxLimit;
  },
  setRangeValueFilter: (state: IAdvancedFiltersState, { payload }: PayloadAction<ISetRangeFilterValuePayload>) => {
    const { tab, filterKey, minVal, maxVal } = payload;
    state[tab].filters[filterKey].minVal = minVal;
    state[tab].filters[filterKey].maxVal = maxVal;
  },
};
