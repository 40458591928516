import { Chips } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setBPFormGroupSelectedOptions, setBPFormSelectedOptions } from '@app/modules/admin/store/admin.store';
import { SelectedBPsChipContainer, SelectedBPsChipWrapperStyled } from './selected-bps-chips.component.styles';

export const SelectedBpsChips: React.FC = () => {
  const [chipArray, setChipArray] = React.useState([]);
  const { bpFilter } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (bpFilter?.selectedOptions?.length) {
      const chipsArray = [];
      bpFilter.selectedOptions.forEach(bp => {
        const selectedBPChip = {
          id: bp.id,
          label: bp.label,
          tooltipMessage: bp.label,
          tooltipPlacement: bp.label,
          corpGroup: bp.corporateGroup,
          corpGroupName: bp.corporateGroupName,
        };

        chipsArray.push(selectedBPChip);
      });

      setChipArray(chipsArray);
    } else {
      setChipArray([]);
    }
  }, [bpFilter.selectedOptions]);

  const handleUpdateChips = e => {
    const selectedBPsAux = [];
    const selectedCorGroup = [];

    if (bpFilter.filterOption === 'bp') {
      bpFilter.selectedOptions.forEach(b => {
        const stillSelected = e.find(selectedBP => selectedBP.id === b.id);

        if (stillSelected) {
          selectedBPsAux.push(b);
        }
      });

      dispatch(setBPFormSelectedOptions(selectedBPsAux));
    } else {
      bpFilter.selectedOptions.forEach(b => {
        const stillSelected = e.find(selectedBP => selectedBP.label === b.label);

        if (stillSelected) {
          selectedBPsAux.push(b);
          if (!selectedCorGroup.includes(b.corpGroup)) {
            selectedCorGroup.push(b.corpGroup);
          }
        }
      });

      dispatch(setBPFormSelectedOptions(selectedBPsAux));
      dispatch(setBPFormGroupSelectedOptions(selectedCorGroup));
    }
  };

  if (!chipArray?.length) {
    return null;
  }

  return (
    <SelectedBPsChipContainer>
      <SelectedBPsChipWrapperStyled>
        <Chips chips={chipArray} type='input' updateChips={e => handleUpdateChips(e)} />
      </SelectedBPsChipWrapperStyled>
    </SelectedBPsChipContainer>
  );
};
