import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TableRowData } from '@app/data/http/quote-params.dto';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { QuoteStrings } from '../quote/quote.string';
import { ChatResultAttributeWrapper, ChatResultTable } from './chat-result.component.style';
import { rangeLimits } from './chat.contants';
import { ModalJominy } from './modal-jominy.component';

interface JominyPoints {
  points: number | string;
  min: string;
  max: string;
  unit: string;
  hardenability_unit: string;
}
interface JominyTableProps {
  data?: JominyPoints[];
  onData: (data: TableRowData[], key: string) => void;
  disableAction?: boolean;
  editable?: boolean;
  delible?: boolean;
  onDelete?: () => void;
  modified?: string;
  disableButton?: boolean;
}

export const JominyTable: React.FC<JominyTableProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [edit, setEdit] = React.useState(false);

  const handleModalData = (data: TableRowData[], key: string) => {
    props.onData(data, key);
    setEdit(false);
  };

  const formatStringValue = (val: string | number, range: string) => {
    if (val) {
      if (range === 'max') {
        const newMask = val.toString().replace(rangeLimits.maxString, rangeLimits.dash);
        return newMask;
      } else {
        if (val === rangeLimits.min) {
          const newMask = val.toString().replace(rangeLimits.minString, rangeLimits.dash);
          return newMask;
        }
        return val;
      }
    } else {
      return rangeLimits.dash;
    }
  };

  const convertPointToNumber = (data: JominyPoints[]) => {
    const newJominyData = [];
    data.map(
      (item, index) =>
        (newJominyData[index] = {
          points: item.points
            .toString()
            .split('/')
            .shift(),
          max: item.max,
          min: item.min,
          unit: item.unit,
          hardenability_unit: item.hardenability_unit,
        }),
    );
    return newJominyData;
  };

  const orderData = (data: JominyPoints[]) => {
    return data.sort((a: JominyPoints, b: JominyPoints) =>
      +a.points < +b.points ? -1 : +a.points > +b.points ? 1 : 0,
    );
  };

  const orderingPoints = (values: JominyPoints[], unit: string) => {
    if (unit === 'pol') {
      return orderData(convertPointToNumber(values));
    } else {
      return orderData(values);
    }
  };

  return (
    <ChatResultAttributeWrapper modified={props.modified}>
      <ChatResultTable>
        <Hbox>
          <Hbox.Item vAlign='center'>
            <InputLabel>
              Jominy
              {props.modified !== '' &&
                (props.modified === 'GERDAU' ? (
                  <span title={QuoteStrings[userInfo.language].defaultMessageGerdau.tootipChatResult}>
                    {QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultGerdauAdvice}
                  </span>
                ) : (
                  <span>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}</span>
                ))}
            </InputLabel>
          </Hbox.Item>
          {props.editable && (
            <Hbox.Item noGrow>
              <Button kind='link' onClick={() => setEdit(true)} disabled={props.disableAction || props.disableButton}>
                <FaIcon.Pen />
              </Button>
            </Hbox.Item>
          )}
          {props.delible && (
            <>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <Button kind='link' onClick={props.onDelete} disabled={props.disableAction || props.disableButton}>
                  <FaIcon.Trash />
                </Button>
              </Hbox.Item>
            </>
          )}
        </Hbox>
        <Table>
          <TR>
            <TD>
              <H4>{props.data[0].unit}</H4>
            </TD>
            <TD>
              <H4>Min</H4>
            </TD>
            <TD>
              <H4>Max</H4>
            </TD>
            <TD></TD>
          </TR>
          {props.data &&
            orderingPoints(props.data, props.data[0].unit).map(row => (
              <TR key={row.points}>
                <TD>
                  <H4>{props.data[0].unit === 'pol' ? `${row.points}/16` : row.points}</H4>
                </TD>
                <TD>{formatStringValue(row.min, 'min')}</TD>
                <TD>{formatStringValue(row.max, 'max')}</TD>
                <TD>{row.hardenability_unit}</TD>
              </TR>
            ))}
        </Table>
        <VSeparator />
        <ModalJominy
          onClose={() => setEdit(false)}
          openTableType={edit ? 'jominy' : ''}
          onSubmit={handleModalData}
          data={{
            JOMINY: {
              MEDIDA: props.data[0].unit?.toString(),
              PONTOS: props.data.map(row => ({
                ponto: row.points?.toString(),
                min: row.min,
                max: row.max,
                hardenabilityUnit: row.hardenability_unit,
              })),
            },
          }}
        />
      </ChatResultTable>
    </ChatResultAttributeWrapper>
  );
};
