import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { setClearSurrogateInfo, setShowRemoveSurrogate } from '../../store/surrogate.store';
import { deleteSurrogate } from '../../store/thunks/delete-surrogate.thunks';
import { CUSTOMIZED_CONFIRM_MODAL_WIDTH } from '../../surrogate.constants';
import { ISurrogateStrings, Strings } from '../../surrogate.strings';

interface RemoveSurrogateModalProps {
  setAction: (hasInvalidEmail: boolean) => void;
}

export const RemoveSurrogateModal: React.FC<RemoveSurrogateModalProps> = ({ setAction }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { _id, showRemoveSurrogate, loading } = useSelector((state: RootState) => state.surrogate);
  const dispatch = useDispatch<AppThunkDispatch>();
  const onCloseModal = () => dispatch(setShowRemoveSurrogate(false));
  const strings: ISurrogateStrings = Strings[userInfo.language];

  const handleRemoveSurrogate = () => {
    setAction(false);
    dispatch(setClearSurrogateInfo(''));
    _id && dispatch(deleteSurrogate(_id));
  };

  return (
    <Modal customWidth={CUSTOMIZED_CONFIRM_MODAL_WIDTH} opened={showRemoveSurrogate} preventOverlayClick={loading}>
      <Grid fluid>
        <FlexColumn mt mb>
          <FlexRow fullWidth>
            <FlexRow fullWidth>
              <Body bold={700} color={Color.Black}>
                {strings.modal.message}
              </Body>
            </FlexRow>
          </FlexRow>
        </FlexColumn>
        <FlexRow hAlign='flex-end'>
          <FlexRow noGrow>
            <Button
              testid={`${TEST_ID_CLOSE_MODAL}-confirm-delete-surrogate`}
              kind='alertSecondary'
              onClick={() => handleRemoveSurrogate()}
              loading={loading}
            >
              {strings.modal.removeBtn}
            </Button>
          </FlexRow>
          <FlexRow ml={Spacing.Small}>
            <Button
              testid={`${TEST_ID_CLOSE_MODAL}-cancel-delete-surrogate`}
              kind='secondary'
              onClick={onCloseModal}
              loading={loading}
            >
              {strings.modal.cancelBtn}
            </Button>
          </FlexRow>
        </FlexRow>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
