import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppConfig } from '@app/configure-app';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Link } from '@app/core/route';
import { LoginDataParam } from '@app/data/http/auth.params.dto';
import { AppPath } from '@app/modules/app/route-constants';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { AppWindow } from '@app/utils/interfaces';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { TextField } from '@atomic/atm.text-field';
import { BodySecondary, ButtonText, H1, InputLabel } from '@atomic/atm.typography';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Row, VSeparator } from '@atomic/obj.grid';
import {
  EXTERNAL_GERDAU_EMAIL,
  GERDAU_COM_EMAIL,
  GERDAU_SUMMIT_EMAIL,
  INTERNAL_GERDAU_EMAIL,
} from '../../auth.constants';
import { AuthLanguages } from '../../auth.languages';
import { AuthStrings } from '../../auth.string';
import { getTokenAndLoginThunk } from '../../store/thunk/get-token-and-login.thunk';
import { botUserList } from '../../utils/utils';
import {
  ButtonWrapperStyled,
  SSOButtonWrapperStyled,
  SSOContainerWrapper,
  SSOLinkSpinnerStyled,
} from './auth-form.style';

const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;

export interface ILoginProfileParams {
  username: string;
  password: string;
  redirectUrl: string;
  access_token: string;
  ssoAcces: boolean;
}

export const AuthForm: React.FC = () => {
  const { loading, loadingProfile, loadingValidateToken } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [showPassword, setShowPassword] = React.useState(false);
  const [language, setLanguage] = React.useState('pt');
  const [disabledLogin, setDisabledLogin] = React.useState(false);
  const [disabledSSO, setDisabledSSO] = React.useState(false);
  const strings = AuthStrings[language];
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const codeParam = searchParams.get('code');

  const handleSubmit = (form?: FormData<LoginDataParam>) => {
    LogAnalytics.click({ tipo: 'SubmitLogin' });

    const loginProfileParams = {
      username: form?.data?.username,
      password: form?.data?.password,
      redirectUrl:
        window.location.origin.indexOf('localhost') > -1
          ? `${window.location.origin}/auth`
          : `${config.urlGerdauMais}/auth`,
      access_token: codeParam,
      ssoAcces: codeParam ? true : false,
    };

    const logout = window.location.search.indexOf('logout=true') > -1;

    if (!logout) {
      dispatch(getTokenAndLoginThunk(loginProfileParams));
    }
  };

  const handleEmail = (email: string) => {
    const isBotUser = botUserList.includes(email);

    if (isBotUser) {
      setDisabledSSO(false);
      setDisabledLogin(false);
    } else if (
      email.indexOf(INTERNAL_GERDAU_EMAIL) > -1 ||
      email.indexOf(EXTERNAL_GERDAU_EMAIL) > -1 ||
      email.indexOf(GERDAU_COM_EMAIL) > -1 ||
      email.indexOf(GERDAU_SUMMIT_EMAIL) > -1
    ) {
      setDisabledSSO(false);
      setDisabledLogin(true);
      dispatch(
        setToast({
          show: true,
          text: strings.ssoUserMessage,
          variant: ToastVariant.WARNING,
          actionOne: strings.toastConfirm,
        }),
      );
    } else if (email.length === 0) {
      setDisabledSSO(false);
      setDisabledLogin(false);
    } else {
      setDisabledSSO(true);
      setDisabledLogin(false);
    }
  };

  const handleRedirectURL = () => {
    let url = '';

    if (window.location.origin.indexOf('localhost') > -1) {
      url = `https://login.microsoftonline.com/${config.ssoTenantId}/oauth2/v2.0/authorize?client_id=${config.ssoAuthClientId}&response_type=code&redirect_uri=${window.location.origin}/auth&response_mode=query&scope=offline_access%20user.read%20mail.read`;
    } else {
      url = `https://login.microsoftonline.com/${config.ssoTenantId}/oauth2/v2.0/authorize?client_id=${config.ssoAuthClientId}&response_type=code&redirect_uri=${config.gsbAuthUrl}&response_mode=query&scope=offline_access%20user.read%20mail.read`;
    }

    return url;
  };

  useEffect(() => {
    if (codeParam) {
      handleSubmit();
    }
  }, [codeParam]);

  return (
    <>
      <Row>
        <Image.NewLogo />
        <AuthLanguages setLanguage={setLanguage} />
      </Row>
      <H1>{strings.title}</H1>
      <BodySecondary>{strings.introText}</BodySecondary>

      <VSeparator />

      <Form onSubmit={data => handleSubmit(data)}>
        <InputLabel>{strings.emailField}</InputLabel>
        <VSeparator small />

        <Form.Field
          name='username'
          validators={[Validators.EmailRegex(strings.invalidEmail), Validators.Required(strings.mandatoryField)]}
          onValueChange={email => handleEmail(email)}
        >
          <TextField data-testid='email' type='email' placeholder={strings.emailPlaceholder} />
        </Form.Field>
        <VSeparator />

        <InputLabel>{strings.passField}</InputLabel>
        <VSeparator small />

        <Form.Field name='password' validators={[Validators.Required(strings.mandatoryField)]}>
          <TextField
            data-testid='password'
            type={showPassword ? 'text' : 'password'}
            hasButton
            disabled={disabledLogin}
          >
            <Button
              type='button'
              kind='link'
              onClick={() => {
                setShowPassword(prevShowPassword => !prevShowPassword);
              }}
              disabled={disabledLogin}
            >
              {showPassword ? <FaIcon.Eye size='1x' /> : <FaIcon.EyeSlash size='1x' />}
            </Button>
          </TextField>
        </Form.Field>
        <VSeparator small />
        <Link to={AppPath.FORGOT_PASSWORD}>
          <ButtonText>{strings.forgotPassword}</ButtonText>
        </Link>
        <VSeparator />
        <ButtonWrapperStyled>
          <Button
            data-testid='login-button'
            type='submit'
            loading={loading || loadingProfile || loadingValidateToken}
            disabled={disabledLogin}
          >
            {strings.button}
          </Button>
          <SSOContainerWrapper>
            <Link to={disabledSSO ? '#' : handleRedirectURL()}>
              <SSOButtonWrapperStyled
                data-testid='sso-login-button'
                bold={700}
                textDecoration='underline'
                disabled={disabledSSO}
                loading={loading || loadingProfile || loadingValidateToken}
              >
                {strings.ssoButton}
              </SSOButtonWrapperStyled>
              <SSOLinkSpinnerStyled processing={loading || loadingProfile || loadingValidateToken}>
                <ActivityIndicator type='spinner' />
              </SSOLinkSpinnerStyled>
            </Link>
          </SSOContainerWrapper>
        </ButtonWrapperStyled>
      </Form>
      <VSeparator />

      <BodySecondary>{strings.footerText}</BodySecondary>
      <Link target='_blank' to='https://www2.gerdau.com.br/politica-de-privacidade/'>
        <ButtonText>{strings.privacyPolicy}</ButtonText>
      </Link>
      <VSeparator />

      <BodySecondary>{`© Gerdau ${new Date().getFullYear()} ${strings.copyright}`}</BodySecondary>
      <VSeparator />
    </>
  );
};
