import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H3 } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import { Arrow, Steps, ToolTipContainer, ToolTipFooter, ToolTipHeader, ToolTipWrapper } from './SimpleToolTip.style';

interface ToolTipBoxProps {
  customVerticalAlign?: number;
  customHorizontalAlign?: number;
  align: string;
  alignArrow?: string;
  title?: string;
  visible: boolean;
  description?: string;
  btnLabel?: string;
  steps?: string;
  onClick?: () => void;
  onClose: () => void;
}

export const SimpleToolTip = (props: ToolTipBoxProps) => {
  return (
    props.visible && (
      <ToolTipWrapper>
        <ToolTipContainer
          align={props.align}
          customVerticalAlign={props.customVerticalAlign}
          customHorizontalAlign={props.customHorizontalAlign}
          data-testid='tooltip-alert'
        >
          <ToolTipHeader alignArrow={props.alignArrow}>
            <Arrow />
            <FaIcon.Close color={Color.White} clickable onClick={props.onClose} />
          </ToolTipHeader>
          {props.title && <H3>props.title</H3>}
          <Body>{props.description}</Body>
          <ToolTipFooter hasSteps={props.steps}>
            {props.steps && (
              <Steps>
                <Body>{props.steps}</Body>
              </Steps>
            )}
            {props.btnLabel && (
              <Button kind='secondary' onClick={props.onClick}>
                {props.btnLabel}
              </Button>
            )}
          </ToolTipFooter>
        </ToolTipContainer>
      </ToolTipWrapper>
    )
  );
};
