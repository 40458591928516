export interface ISchedulingCompleteModalStrings {
  announcement: {
    title: string;
    confirmButton: string;
  };
}

interface ISchedulingCompleteModalLanguages {
  pt: ISchedulingCompleteModalStrings;
  es: ISchedulingCompleteModalStrings;
  in: ISchedulingCompleteModalStrings;
}

const pt: ISchedulingCompleteModalStrings = {
  announcement: {
    title: 'Atenção!',
    confirmButton: 'Ok, entendido',
  },
};

const es: ISchedulingCompleteModalStrings = {
  announcement: {
    title: '¡Atención!',
    confirmButton: 'Ok, entendido',
  },
};

const en: ISchedulingCompleteModalStrings = {
  announcement: {
    title: 'Attention!',
    confirmButton: 'Ok, understood',
  },
};

export const Strings: ISchedulingCompleteModalLanguages = {
  pt,
  es: es,
  in: en,
};
