import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { GetUsersParams } from '@app/data/http/admin.params.dto';
import { IAdminUserList } from '@app/models/admin.model';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { AdminStrings } from '../../admin.string';

export const getUsers = createAsyncThunk(`${SLICE_NAME}/getUsers`, async (params: GetUsersParams, thunkAPI) => {
  const strings = AdminStrings.pt.userView;

  return await AdminDataSource.getUsers(params)
    .then(data => data)
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: strings.listUsersError, variant: ToastVariant.DANGER }));
    });
});

export const addGetUsersReducers = builder => {
  builder.addCase(getUsers.pending, state => {
    state.loadingUsers = true;
  });
  builder.addCase(getUsers.fulfilled, (state, action) => {
    const payload: IAdminUserList = action.payload as IAdminUserList;
    state.loadingUsers = false;
    state.userSearch.filteredUsers = payload?.users;
    state.userSearch.pagination = payload?.pagination;
  });
  builder.addCase(getUsers.rejected, state => {
    state.loadingUsers = false;
  });
};
