import styled from 'styled-components';
import { FontFamily, FontWeight, Spacing } from '@atomic/obj.constants';

export const FinishStatusWrapper = styled.div`
  padding: 0 ${Spacing.XSmall};
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  border-radius: 4px;
  font-weight: ${FontWeight.Bold};
  font-family: ${FontFamily.Primary};
  font-size: 12px;
`;
