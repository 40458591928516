import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Grid } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { IOrderInputStrings, OrderInputStrings } from '../../../../../order-input.string';

interface BackStepConfirmModalConfirmModalProps {
  onClose: () => void;
  onConfirm: () => void;
  opened: boolean;
}

export const BackStepConfirmModal: React.FC<BackStepConfirmModalConfirmModalProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { files } = useSelector((state: RootState) => state.orderInput);
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];
  const isIAReader = files && files.length > 0;

  return (
    <Modal small opened={props.opened} onClose={props.onClose}>
      <Grid fluid>
        <FlexRow>
          <H2>
            {isIAReader
              ? strings.uploadSpreadSheet.backStepConfirmModal.IAOrderTitle
              : strings.uploadSpreadSheet.backStepConfirmModal.title}
          </H2>
        </FlexRow>
        <FlexRow mb>
          <FlexRow fullWidth hAlign='center'>
            <Button onClick={props.onClose} expanded kind='secondary'>
              {strings.uploadSpreadSheet.backStepConfirmModal.cancel}
            </Button>
            <Button onClick={props.onConfirm} expanded kind='alertSecondary'>
              {strings.uploadSpreadSheet.backStepConfirmModal.remove}
            </Button>
          </FlexRow>
        </FlexRow>
      </Grid>
    </Modal>
  );
};
