import * as React from 'react';
import { Redirect as RouterRedirect, RedirectProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Container } from 'typedi';
import { Router } from '@app/core/route/router';

const router = Container.get(Router);

export function Redirect({ to, from, ...others }: RedirectProps) {
  const Component = withRouter(props => (
    <RouterRedirect
      to={router.resolve(props.match.url, to)}
      from={from ? router.resolve(props.match.url, from).toString() : undefined}
      {...others}
    />
  ));
  return <Component />;
}
