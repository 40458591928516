import React from 'react';
import 'react-image-lightbox/style.css';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Cell } from '@atomic/atm.cell';
import { Frame } from '@atomic/atm.frame';
import { Body } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants/constants';
import { Col, Row } from '@atomic/obj.grid';
import { AttributeCellFormWrapperStyled } from './attribute-cell.form.style';

export const JominyAdvice = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const advice = TechnicalAnalysisStrings[userInfo.language].attributes.jominyAdvice;

  return (
    <AttributeCellFormWrapperStyled>
      <Frame>
        <Cell>
          <Row>
            <Col xs={12}>
              <Body color={Color.Alert} bold={600}>
                {advice}
              </Body>
            </Col>
          </Row>
        </Cell>
      </Frame>
    </AttributeCellFormWrapperStyled>
  );
};
