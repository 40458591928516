import { INotificationState } from '../notification.interfaces';
import { initialState } from './notification.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setIsFirstPageLoad: (state: INotificationState, { payload }) => {
    state.isFirstPageLoad = payload;
  },
};
