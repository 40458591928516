import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  TOOLTIP_X_POSITION_REF,
  TOOLTIP_Y_POSITION_REF,
} from '@app/modules/shipping-request/shipping-request.constants';
import { IShippingRequestRow, ISuggestionOption } from '@app/modules/shipping-request/shipping-request.interfaces';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import {
  selectEatonCheckedOrderSelector,
  selectEatonSuggestionItemSelector,
} from '@app/modules/shipping-request/store/shipping-request.selectors';
import { updateEatonSuggestionRequestedShippingQuantity } from '@app/modules/shipping-request/store/shipping-request.store';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { EditQtyEatonFieldStyled } from './shipping-request-edit-qty-eaton.style';

interface ShippingRequestEditQtyFieldProps {
  item: IShippingRequestRow;
  hasSuggestion?: boolean;
}

export const ShippingRequestSuggestionEditQtyEatonField: React.FC<ShippingRequestEditQtyFieldProps> = ({
  item,
  hasSuggestion,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { salesOrder } = item;
  const eatonSuggestionItem: ISuggestionOption = useSelector(state =>
    selectEatonSuggestionItemSelector(state, salesOrder, item.material),
  );
  const isCheckedRow: boolean = useSelector(state => selectEatonCheckedOrderSelector(state, salesOrder));

  const [value, setValue] = useState(eatonSuggestionItem?.item?.requestedShippingQuantity);
  const [hasError, setHasError] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);
  const ref = useRef(null);

  const onMouseOverInfoIcon = () => {
    if (hasError) {
      setOpenToolTip(true);
    }
  };

  const onMouseOutInfoIcon = () => {
    setOpenToolTip(false);
  };

  let topTooltip = 0;
  let leftTooltip = 0;

  if (ref?.current) {
    const iconBoundings = ref?.current?.getBoundingClientRect();
    topTooltip = iconBoundings.top;
    leftTooltip = iconBoundings.left;
  }

  const customTop = topTooltip - TOOLTIP_Y_POSITION_REF;
  const customLeft = leftTooltip - TOOLTIP_X_POSITION_REF;

  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const dispatch = useDispatch();

  const onChange = newValue => {
    setValue(newValue);
  };

  useEffect(() => {
    const hasQtyError = value > eatonSuggestionItem?.item?.stockQuantity - eatonSuggestionItem?.item?.shipmentQuantity;

    if (hasQtyError) {
      setHasError(true);
      setOpenToolTip(true);
    } else {
      setHasError(false);
    }

    dispatch(updateEatonSuggestionRequestedShippingQuantity({ salesOrder, value: value ? value : null, hasQtyError }));
  }, [eatonSuggestionItem, value]);

  useEffect(() => {
    if (!isCheckedRow) {
      setValue(null);
    }
  }, [isCheckedRow]);

  return (
    <div ref={ref} onMouseOver={onMouseOverInfoIcon} onMouseOut={onMouseOutInfoIcon}>
      <EditQtyEatonFieldStyled
        hasError={hasError}
        placeholder={strings.page.placeholderQtyEdit}
        type='number'
        min='0'
        value={value}
        onValueChange={onChange}
        disabled={!isCheckedRow || hasSuggestion}
      />
      <CustomizedTooltip
        title=''
        description={strings?.page.modalCustomQtyAlert}
        visible={openToolTip}
        onClick={() => setOpenToolTip(false)}
        onClose={() => setOpenToolTip(false)}
        customHeight={customTop}
        customLeftAlign={customLeft}
        customArrowX={180}
        hasFooter={false}
        hasHeader={false}
        hasOffset
      />
    </div>
  );
};
