import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Grid, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { IOrderInputStrings, OrderInputStrings } from '../../order-input.string';

interface DeleteSpreadsheetConfirmModalProps {
  onClose: () => void;
  onConfirm: () => void;
  opened: boolean;
}

export const DeleteSpreadsheetConfirmModal: React.FC<DeleteSpreadsheetConfirmModalProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];

  return (
    <Modal small opened={props.opened} onClose={props.onClose}>
      <Grid fluid>
        <Row>
          <H2>{strings.uploadSpreadSheet.deleteModal.title}</H2>
        </Row>
        <Row mt mb>
          <FlexRow fullWidth hAlign='center'>
            <Button onClick={props.onClose} expanded kind='secondary'>
              {strings.uploadSpreadSheet.deleteModal.cancel}
            </Button>
            <Button onClick={props.onConfirm} expanded kind='alertSecondary'>
              {strings.uploadSpreadSheet.deleteModal.remove}
            </Button>
          </FlexRow>
        </Row>
      </Grid>
    </Modal>
  );
};
