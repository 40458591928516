import { createAsyncThunk } from '@reduxjs/toolkit';
import Container from 'typedi';
import { LogAnalytics } from '@app/core/analytics';
import { ScheduleDataSource, ScheduleParams } from '@app/data/http/schedule.datasource';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { IScheduleClient, IScheduleLayout } from '@app/models/schedule.model';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import {
  LayoutBelongs,
  SLICE_NAME,
  ScheduleEvents,
  SchedulePhase,
  UserScheduleType,
  mapScheduleTypeToName,
  scheduleTypeToOccurenceType,
} from '../../schedule.constants';
import { ICrudScheduleParams, IScheduleState } from '../../schedule.interfaces';
import { formatTime, getSelectedDays } from '../../utils';
import { bpsSelectedSelector } from '../schedule.selectores';

const user = Container.get(AuthStorageService).getUser();

export const createSchedule = createAsyncThunk(
  `${SLICE_NAME}/createSchedule`,
  async ({ onSuccess }: ICrudScheduleParams, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const { scheduleName, selectedEmails, scheduleType, time, layout, currentTab }: IScheduleState = state.schedule;
    const clients: IScheduleClient[] = bpsSelectedSelector(state);
    const customerIds = clients.map(client => client.code).join();

    const payload: ScheduleParams = {
      name: scheduleName,
      mail: selectedEmails.map(badgeEmail => badgeEmail.email).join(),
      user_type: currentTab,
      occurrence_type: scheduleTypeToOccurenceType[scheduleType],
      send_day: getSelectedDays(state.schedule),
      hour_to_send: parseInt(time.substring(0, 2)),
      active: true,
      clients: clients.map(client => ({ code: client.code, name: client.name })),
      layout: layout.map(column => ({ ...column, uid: user?.uid, belongs: LayoutBelongs.CART })),
    };

    const tagPayload = {
      send_day: getSelectedDays(state.schedule),
      bps: clients.map(client => client.code).join(),
      user_type: mapScheduleTypeToName[UserScheduleType.ORDER_OPEN],
      frequency: scheduleType,
      column: layout
        .filter((column: IScheduleLayout) => !!column.checked)
        .map(col => col.value)
        .join(),
      mail: selectedEmails.map(badgeEmail => badgeEmail.email).join(),
      time: formatTime(time),
    };

    return await ScheduleDataSource.submitSchedule(payload, customerIds)
      .then(() => {
        onSuccess(tagPayload);
      })
      .catch(error => {
        LogAnalytics.error({
          tipo: ScheduleEvents.CreateScheduleError,
          responseStatus: error.response.status,
          responseData: error.response?.data?.message,
          customerIds,
        });
        thunkAPI.dispatch(setOpenErrorHandlerModal(true));
        return thunkAPI.rejectWithValue({});
      });
  },
);

export const addCreateScheduleReducers = builder => {
  builder.addCase(createSchedule.pending, state => {
    state.loadingSubmit = true;
  });
  builder.addCase(createSchedule.fulfilled, state => {
    state.loadingSubmit = false;
    state.loading = true;
    state.schedulePhase = SchedulePhase.Success;
  });
  builder.addCase(createSchedule.rejected, state => {
    state.loadingSubmit = false;
  });
};
