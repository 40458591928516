import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import * as React from 'react';
import { IAdminUser, ProfileTypes } from '@app/models/admin.model';
import {
  AdminListHeaderSeparator,
  AdminListHeaderWrapper,
  TagHeaderContainer,
} from '@app/modules/admin/admin.page.style';
import { AdminStrings } from '@app/modules/admin/admin.string';
import { capitalizeFirstLetter } from '@app/utils/string-utils';
import { Body } from '@atomic/atm.typography';

interface AdminUserListHeaderProps {
  user: IAdminUser;
}

export const AdminUserListHeader: React.FC<AdminUserListHeaderProps> = props => {
  const { name, email, oktaUid, profile } = props.user;
  const strings = AdminStrings.pt.common;

  return (
    <AdminListHeaderWrapper>
      <TagHeaderContainer>
        <Tag variant='neutral'>{ProfileTypes[capitalizeFirstLetter(profile.toLocaleLowerCase())]}</Tag>
      </TagHeaderContainer>
      <Body bold={true}>{name}</Body>
      <AdminListHeaderSeparator />
      <Body>{email}</Body>
      <AdminListHeaderSeparator />
      <Body>{`${strings.uid} ${oktaUid}`}</Body>
    </AdminListHeaderWrapper>
  );
};
