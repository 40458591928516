import * as React from 'react';
import { Table } from '@atomic/mol.table';
import { VSeparator } from '@atomic/obj.grid';
import { IShippingRequestRow } from '../../shipping-request.interfaces';
import { ShippingRequestSuggestionTableBody } from './partials/shipping-request-suggestion-table-body/shipping-request-suggestion-table-body.component';
import { ShippingRequestSuggestionTableHeader } from './partials/shipping-request-suggestion-table-header/shipping-request-suggestion-table-header.component';
import {
  ShippingRequestSuggestionTableScroll,
  ShippingRequestSuggestionTableWrapper,
} from './shipping-request-suggestion-table.style';

interface IShippingRequestSuggestionTableProps {
  options: IShippingRequestRow[];
}

export const ShippingRequestSuggestionTable: React.FC<IShippingRequestSuggestionTableProps> = ({ options }) => {
  return (
    <ShippingRequestSuggestionTableScroll>
      <ShippingRequestSuggestionTableWrapper>
        <Table collapse>
          <ShippingRequestSuggestionTableHeader options={options} />
          <ShippingRequestSuggestionTableBody options={options} />
          <VSeparator />
        </Table>
      </ShippingRequestSuggestionTableWrapper>
    </ShippingRequestSuggestionTableScroll>
  );
};
