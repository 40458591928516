import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';

export const NpsModalFooterWrapper = styled.div`
  width: 100%;
  padding-top: ${Spacing.Medium};
  padding-bottom: ${Spacing.Medium};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.12);
  gap: ${Spacing.Small};
`;
