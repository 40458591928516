export interface IFilterTypeStrings {
  titleMI: string;
  titleME: string;
}

interface IFilterTypeDropDownLanguages {
  pt: IFilterTypeStrings;
  es: IFilterTypeStrings;
  in: IFilterTypeStrings;
}

const pt: IFilterTypeStrings = {
  titleMI: '- Mercado interno -',
  titleME: '- Mercado externo -',
};

export const Strings: IFilterTypeDropDownLanguages = {
  pt,
  es: pt,
  in: pt,
};
