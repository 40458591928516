import React from 'react';
import { useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { Frame } from '@atomic/atm.frame';
import { Image } from '@atomic/atm.image';
import { H3 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hr } from '@atomic/obj.box';
import { OrderInputEvents } from '../../order-input.analytics';
import { OrderInputImplantationTypeEnum, OrderInputPhaseEnum } from '../../order-input.constants';
import { OrderInputStrings } from '../../order-input.string';
import { setImplantationType, setPhase } from '../../store/order-input.store';
import { OrderInputOption } from './order-input-option/order-input-option';
import { OrderInputOptionsWrapper } from './order-input-options.style';

const strings = OrderInputStrings.pt.options;
interface OrderInputOptionsProps {
  options: any[];
}

export const OrderInputOptions: React.FC<OrderInputOptionsProps> = ({ options }) => {
  const dispatch = useDispatch();

  const handleOptionSelect = option => {
    if (option.active) {
      if (option.phaseId === OrderInputPhaseEnum.ItensSelection) {
        dispatch(setImplantationType(OrderInputImplantationTypeEnum.ItemSelection));
      } else if (option.phaseId === OrderInputPhaseEnum.UploadSpreadsheet) {
        dispatch(setImplantationType(OrderInputImplantationTypeEnum.UploadSpreadsheet));
      }

      dispatch(setPhase(option.phaseId));
      LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: option.phaseId });
    }
  };

  return (
    <OrderInputOptionsWrapper data-testid='order-input-options-phase' fluid>
      <Frame>
        <FlexColumn pt pl>
          <H3>{strings.title}</H3>
          <FlexRow fullWidth mt pb>
            {options.map(option => (
              <OrderInputOption
                testid={`order-input-option-phase-${option.phaseId}`}
                key={option.phaseId}
                title={option.title}
                subtitle={option.subtitle}
                active={option.active}
                templateDownload={option.templateDownload}
                onClick={() => handleOptionSelect(option)}
                isNew={option.isNew}
                iaTag={option.iaTag}
              >
                {option.active ? option.activeIcon : option.icon}
              </OrderInputOption>
            ))}
          </FlexRow>
        </FlexColumn>
      </Frame>
      <Hr />
      <FlexRow hAlign='center'>
        <Image.OrderInputCalendar />
      </FlexRow>
    </OrderInputOptionsWrapper>
  );
};
