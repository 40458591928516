import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const ActiveFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${Spacing.Large};
  margin-bottom: 0;
  padding-bottom: ${Spacing.Large};
  border-bottom: 2px solid ${Color.GrayLight};
`;

export const BadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${Spacing.Small};
  margin-bottom: ${Spacing.Medium};
  max-height: 100px;
  overflow-y: auto;
`;
