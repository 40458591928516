import React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';

export const ShippingConfigRenderer: React.FC<{ data: any; setValue: any }> = props => {
  const { data } = props;

  if (data?.activateShipping) {
    return <FaIcon.Custom icon='file-import' color={Color.Success} />;
  }

  return null;
};
