import React from 'react';
import { IOrderPriorizationTableData } from '../../order-priorization.interfaces';
import { OrderPriorizationCriticalTableComponent } from './order-priorization-critical-table.component';
import { OrderPriorizationTableComponent } from './order-priorization-table.component';
import { OrderPriorizationHeaderControl } from './partials/order-priorization-table-control-header.component';

interface TableDataProps {
  tableRows: IOrderPriorizationTableData[];
  criticalItemsRows: IOrderPriorizationTableData[];
  slotNumbers: number;
  canProceed: boolean;
}

export const OrderPriorizationTable: React.FC<TableDataProps> = ({
  tableRows,
  criticalItemsRows,
  slotNumbers,
  canProceed,
}) => {
  return (
    <>
      {criticalItemsRows?.length > 0 && <OrderPriorizationCriticalTableComponent tableRows={criticalItemsRows} />}
      {tableRows.length > 0 && criticalItemsRows?.length > 0 && (
        <OrderPriorizationHeaderControl selectedItems={tableRows.length} canProceed={canProceed} />
      )}
      {tableRows.length > 0 && <OrderPriorizationTableComponent tableRows={tableRows} slotNumbers={slotNumbers} />}
    </>
  );
};
