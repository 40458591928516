import * as React from 'react';
import { MediaItem } from '@app/models/help-center.model';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { Video } from '@atomic/atm.video';
import { FlexRow, Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { ImageWrapper, StretchImageWrapper } from '../section-cell.style';

interface HelpCenterMediaProps {
  media: MediaItem;
}

export const HelpCenterMedia: React.FC<HelpCenterMediaProps> = props => {
  const handleDoubleMedia = paths => {
    const mediaPaths = paths.split(',');

    return (
      <FlexRow>
        <Image.HelpCenterCustomImage src={mediaPaths[0]} size='35%' />
        <Hbox.Separator small />
        <Image.HelpCenterCustomImage src={mediaPaths[1]} size='35%' />
      </FlexRow>
    );
  };

  const renderMedia = media => {
    switch (media) {
      case 'image':
        return (
          <ImageWrapper>
            <Image.HelpCenterCustom src={props.media.path} />
            <VSeparator />
            <VSeparator />
          </ImageWrapper>
        );
      case 'stretch-image':
        return (
          <StretchImageWrapper>
            <Image.HelpCenterStretchImage src={props.media.path} />
            <VSeparator />
            <VSeparator />
          </StretchImageWrapper>
        );
      case 'centered-image':
        return (
          <StretchImageWrapper>
            <Image.HelpCenterMediumImage src={props.media.path} />
            <VSeparator />
            <VSeparator />
          </StretchImageWrapper>
        );
      case 'double-image':
        return (
          <StretchImageWrapper>
            {handleDoubleMedia(props.media.path)}
            <VSeparator />
            <VSeparator />
          </StretchImageWrapper>
        );
      default:
        return (
          <>
            <Video.HelpCenterVideo src={props.media.path} />
            <VSeparator />
            <VSeparator />
          </>
        );
    }
  };

  return (
    <>
      {props.media.description && (
        <>
          <Body>{props.media.description}</Body>
          <VSeparator />
        </>
      )}
      {renderMedia(props.media.type)}
    </>
  );
};
