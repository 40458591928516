import React from 'react';
import { useHistory } from 'react-router';
import { AppPath } from '@app/modules/app/route-constants';
import { FinanceActiveFilters } from './finance-active-filters.component';
import { OrderActiveFilters } from './order-active-filters.component';

export const ActiveFilters: React.FC = () => {
  const history = useHistory();

  return history.location.pathname === AppPath.FINANCE ? <FinanceActiveFilters /> : <OrderActiveFilters />;
};
