import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const CustomerSearchFooter = styled.div`
  width: 100%;
  background-color: ${Color.White};
  padding: ${Spacing.Large};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
