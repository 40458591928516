import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { SearchMaterialField } from '@app/modules/order-input/components/search-material-field/search-material-field';
import { hasError, hasInputError, removeMaterialError } from '@app/modules/order-input/order-input-validation';
import { MaterialCell } from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { formatMaterialDescription } from '@app/modules/order-input/order-input.utils';
import { setEditNewLine, setQuery, updateMaterial } from '@app/modules/order-input/store/order-input.store';
import { Label } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { Color, FontSize, FontWeight } from '@atomic/obj.constants';
import { SearchMaterialFieldErrorWrapper, SearchMaterialFieldWrapper } from './material-field.style';

interface IMaterialProps {
  material: Material;
}

const strings = OrderInputStrings.pt.dateAndAmount;

export const MaterialField: React.FC<IMaterialProps> = ({ material }) => {
  const { materialOptions, searchBy } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch();

  const onSelectOption = (option: Material, currentMaterial: Material) => {
    const materialOption: Material = materialOptions.find(m => m.materialName === option.materialName);
    const updatedMaterial = { ...currentMaterial };
    updatedMaterial.materialCode = materialOption.materialCode;
    updatedMaterial.materialDescription = materialOption.materialDescription;
    updatedMaterial.materialName = materialOption.materialName;
    updatedMaterial.messages = removeMaterialError(updatedMaterial.messages, MaterialCell.Description);
    updatedMaterial.minMaxLenght = materialOption.minMaxLenght;
    updatedMaterial.multipleLength = materialOption.multipleLength;
    updatedMaterial.standardGrade = materialOption.standardGrade;
    updatedMaterial.plant = materialOption.plant;

    dispatch(updateMaterial(updatedMaterial));
    dispatch(setQuery(''));
    dispatch(setEditNewLine(false));
  };

  if (hasError(material, MaterialCell.Description)) {
    return (
      <FlexColumn>
        <SearchMaterialFieldWrapper>
          <SearchMaterialField onClick={e => onSelectOption(e, material)} hasError={true} tempID={material.tempId} />
        </SearchMaterialFieldWrapper>
        <SearchMaterialFieldErrorWrapper>
          {hasInputError(material, MaterialCell.Description)}
        </SearchMaterialFieldErrorWrapper>
      </FlexColumn>
    );
  }

  return (
    <FlexColumn hAlign='center' fullWidth>
      <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small}>
        {formatMaterialDescription(material, searchBy)}
      </Label>
      <Label size={FontSize.Small} fullWidth color={Color.Black}>
        {`${strings.codProdClient} ${material.materialCode}`}
      </Label>
    </FlexColumn>
  );
};
