import { PayloadAction } from '@reduxjs/toolkit';
import { IScheduleLayout } from '@app/models/schedule.model';
import { DragItem, IScheduleState } from '../../schedule.interfaces';

export const layoutReducers = {
  checkColumnLayout: (state: IScheduleState, { payload }: PayloadAction<string>) => {
    const index = state.layout.findIndex(column => column.value === payload);
    const layout: IScheduleLayout[] = [...state.layout];
    layout[index].checked = true;
    state.layout = layout;

    const col = layout[index];
    state.dragItems.push({ value: col.value, label: col.label, index: col.index });
  },
  uncheckColumnLayout: (state: IScheduleState, { payload }: PayloadAction<string>) => {
    const index = state.layout.findIndex(column => column.value === payload);
    const layout: IScheduleLayout[] = [...state.layout];
    layout[index].checked = false;
    state.layout = layout;

    const indexOrder = state.dragItems.findIndex(column => column.value === payload);
    const dragItems: DragItem[] = [...state.dragItems];
    dragItems.splice(indexOrder, 1);
    state.dragItems = dragItems;
  },
  setOrderColumnLayout: (state: IScheduleState, { payload }: PayloadAction<DragItem[]>) => {
    state.dragItems = payload;
    const layout: IScheduleLayout[] = [...state.layout];

    payload.forEach(columnOrder => {
      const indexDrag = layout.findIndex(column => column.value === columnOrder.value);
      layout[indexDrag].index = columnOrder.index;
    });

    state.layout = layout;
  },
};
