import { GridReadyEvent } from 'ag-grid-community';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { SimilarMaterials } from '@app/models/analysis.model';
import { useGridColumn } from '@app/modules/components';
import { AgGrid } from '@app/modules/components/ag-grid.component';
import { AgGridWrapperStyled } from '@app/modules/components/ag-grid.style';
import { GridDownload } from '@app/modules/components/grid-download/grid-download.component';
import { Button } from '@atomic/atm.button/button.component';
import { Body, H2 } from '@atomic/atm.typography/typography.component.style';
import { Hbox } from '@atomic/obj.box';
import { Col, Row, Grid } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal/modal.component';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';
import { LabelSimilarMaterialsModal } from '../technical-analysis.style';
import { gridSimilarMaterialsColumnsDef } from './grid-similar-materials-column.definition';

interface TechnicalAnalysisSimilarMaterialsModalProps {
  opened: boolean;
  onClose: () => void;
  data: SimilarMaterials;
  loading: boolean;
}

export const TechnicalAnalysisSimilarMaterialsModal: React.FC<TechnicalAnalysisSimilarMaterialsModalProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const gridEvent = React.useRef<GridReadyEvent>(null);
  const columnsDef = useGridColumn(gridSimilarMaterialsColumnsDef(props.data?.columns ? props.data.columns : []));
  const [isGridReady, setIsGridReady] = React.useState(false);
  const hasColumns = props.data?.columns === undefined ? false : true;

  const Strings = TechnicalAnalysisStrings[userInfo.language].modal.similarMaterials;

  const handleGridReady = (event: GridReadyEvent) => {
    gridEvent.current = event;
    setIsGridReady(true);
  };

  React.useEffect(() => {
    props?.data && isGridReady && columnsDef.setGrid(gridEvent.current);
  }, [isGridReady, props.data]);

  const handleClose = () => {
    props.onClose();
    setIsGridReady(false);
  };

  return (
    <Modal opened={props.opened} onClose={handleClose}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <H2>{Strings.title}</H2>
            {!hasColumns || props.loading ? (
              <Hbox>
                <Hbox.Item hAlign='center' vAlign='center'>
                  <Body>{Strings.noData}</Body>
                </Hbox.Item>
              </Hbox>
            ) : props.loading ? (
              <Hbox>
                <Hbox.Item hAlign='center' vAlign='center'>
                  <Body>{Strings.loading}</Body>
                </Hbox.Item>
              </Hbox>
            ) : (
              <AgGridWrapperStyled>
                <LabelSimilarMaterialsModal>{Strings.legend}</LabelSimilarMaterialsModal>

                <AgGrid onGridReady={handleGridReady} rowData={props.data.rows} loading={props.loading} />
              </AgGridWrapperStyled>
            )}
          </Col>
        </Row>
        <Row end='xs' mt mb>
          <Col>
            <Hbox>
              <Hbox.Item>
                <Button kind='link' onClick={handleClose}>
                  {Strings.cancel}
                </Button>
              </Hbox.Item>
              {hasColumns && (
                <>
                  <Hbox.Separator />
                  <Hbox.Item noGrow>
                    <GridDownload gridEvent={gridEvent.current} filename={Strings.filename} />
                  </Hbox.Item>
                </>
              )}
            </Hbox>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
