import React from 'react';
import { Divisor } from '@atomic/atm.divisor';
import { Frame } from '@atomic/atm.frame';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { OrderInputStrings } from '../../order-input.string';
import { OrderInputAmountGridRegion, OrderInputAmountWrapper } from './order-input-amount.style';
import { AddMoreButton } from './partials/add-more-button/add-more-button';
import { AmountTable } from './partials/amount-table/amount-table';
import { ReceiverFormDisabled } from './partials/amount-table/receiver-form-disabled/receiver-form-disabled';
import { OrderInputAmountBaseboard } from './partials/order-input-amount-baseboard/order-input-amount-baseboard';
import { OrderInputAmountTitle } from './partials/order-input-amount-title/order-input-amount-title';

export const OrderInputAmount: React.FC = () => {
  return (
    <OrderInputAmountWrapper data-testid='order-input-amount-phase'>
      <OrderInputAmountGridRegion fluid>
        <Frame>
          <FlexColumn pt pl>
            <OrderInputAmountTitle />
            <FlexColumn fullWidth mt pb mr>
              <ReceiverFormDisabled title={OrderInputStrings.pt.phases.DataAndAmout} />
              <Divisor />
              <AmountTable />
              <FlexRow mt hAlign='center'>
                <AddMoreButton />
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        </Frame>
      </OrderInputAmountGridRegion>
      <OrderInputAmountBaseboard />
    </OrderInputAmountWrapper>
  );
};
