export interface IOrderTabHarborMaritimeStrings {
  title: string;
  filename: string;
  error: string;
}

interface IOrderTabHarborMaritimeLanguages {
  pt: IOrderTabHarborMaritimeStrings;
  es: IOrderTabHarborMaritimeStrings;
  in: IOrderTabHarborMaritimeStrings;
}

const pt: IOrderTabHarborMaritimeStrings = {
  title: 'Em porto',
  filename: 'Pedidos em porto',
  error: 'Ocorreu um erro ao carregar os dados da aba Em porto! Por favor, tente novamente.',
};

const es: IOrderTabHarborMaritimeStrings = {
  title: 'En Puerto',
  filename: 'Ordenes en puerto',
  error: '¡Ocurrió un error al cargar datos desde la pestaña En Puerto! Inténtalo de nuevo.',
};

const ing: IOrderTabHarborMaritimeStrings = {
  title: 'Harbor',
  filename: 'Harbor',
  error: 'An error occurred while loading data from the Harbor tab! Please try again.',
};

export const Strings: IOrderTabHarborMaritimeLanguages = {
  pt,
  es,
  in: ing,
};
