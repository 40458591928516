import { addDays, endOfMonth, endOfWeek, format, isSameDay, isSameMonth, startOfMonth, startOfWeek } from 'date-fns';
import React from 'react';
import { getMounthSum, isM2Date } from '../../../utils';
import { Day, WeekContainer } from '../custom-calendar.style';
import { isD40Day } from '../utils';

interface CustomCalendarDaysProps {
  activeDate: Date;
  selectedDate: Date;
  setSelectedDate: (value: Date) => void;
}

export const CustomCalendarDays: React.FC<CustomCalendarDaysProps> = ({
  activeDate,
  selectedDate,
  setSelectedDate,
}) => {
  const today = new Date();
  const m2Month = getMounthSum(today);
  const firstDayOfMonth = startOfMonth(today);

  const generateDatesForCurrentWeek = (date: Date) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;

      const isInactiveDay = day === 0 || day === 6 || today > cloneDate || isD40Day(currentDate, today);
      const isDiferentMonth = !isSameMonth(currentDate, activeDate);
      const isSelectedDay = isSameDay(currentDate, selectedDate);
      const isToday = isSameDay(currentDate, today);
      const isM2 = isM2Date(firstDayOfMonth, currentDate, m2Month);

      week.push(
        <Day
          key={`current-week-${date.getTime()}-${day}`}
          isInactiveDay={isInactiveDay}
          isSelectedDay={isSelectedDay}
          isToday={isToday}
          isM2={isM2}
          isDiferentMonth={isDiferentMonth}
          onClick={() => {
            if (!isInactiveDay) {
              setSelectedDate(cloneDate);
            }
          }}
        >
          {format(currentDate, 'd')}
        </Day>,
      );
      currentDate = addDays(currentDate, 1);
    }
    return week;
  };

  const startOfTheSelectedMonth = startOfMonth(activeDate);
  const endOfTheSelectedMonth = endOfMonth(activeDate);
  const startDate = startOfWeek(startOfTheSelectedMonth);
  const endDate = endOfWeek(endOfTheSelectedMonth);

  let currentDate = startDate;

  const allWeeks = [];

  while (currentDate <= endDate) {
    allWeeks.push(generateDatesForCurrentWeek(currentDate));
    currentDate = addDays(currentDate, 7);
  }

  return (
    <WeekContainer>
      {allWeeks.map((week, index) => (
        <React.Fragment key={index}>{week}</React.Fragment>
      ))}
    </WeekContainer>
  );
};
