import { createAsyncThunk } from '@reduxjs/toolkit';
import { NPSDataSource } from '@app/data/http/nps.datasource';
import { PutNPSUserParams } from '@app/data/http/nps.params.dto';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../nps-modal.constants';
import { InpsModalStrings, Strings } from '../../nps.string';

export const putNPS = createAsyncThunk(`${SLICE_NAME}/putNPS`, async (params: PutNPSUserParams, thunkAPI) => {
  const strings: InpsModalStrings = Strings[params?.language];

  return await NPSDataSource.putNPS(params)
    .then(data => data)
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: strings.npsAnswerError, variant: ToastVariant.DANGER }));
    });
});

export const addPutNPSReducers = builder => {
  builder.addCase(putNPS.pending, state => {
    state.loadingNPS = true;
  });
  builder.addCase(putNPS.fulfilled, (state, _) => {
    state.hasNPS = false;
    state.loadingNPS = false;
  });
  builder.addCase(putNPS.rejected, state => {
    state.loadingNPS = false;
  });
};
