import styled from 'styled-components';
import { Color, TransitionDuration, ZIndex } from '@atomic/obj.constants';

export interface DateFilterStyledProps {
  show: boolean;
}

export const DateFilterStyled = styled.div`
  ${props => (props.show ? 'opacity: 1;' : 'opacity: 0; visibility: hidden;')}
  z-index: ${ZIndex.DateFilterStyled};
  position: absolute;
  background-color: ${Color.White};
  width: 400px;
  top: 50px;
  transition: all ${TransitionDuration} ease-in-out;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  align-content: flex-start;
`;

export const OrderingFilterStyled = styled.div`
  ${props => (props.show ? 'opacity: 1;' : 'opacity: 0; visibility: hidden;')}
  z-index: ${ZIndex.DateFilterStyled};
  position: absolute;
  background-color: ${Color.White};
  width: 250px;
  top: 50px;
  right: 0px;
  transition: all ${TransitionDuration} ease-in-out;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  align-content: flex-start;
`;
