import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { UserStore, useUserStore } from '@app/providers';
import { H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { MESSAGE_MAXLENGTH } from '../../../nps-modal.constants';
import { InpsModalStrings, Strings } from '../../../nps.string';
import { setMessage } from '../../../store/nps-modal.store';
import { TextAreaMessage } from './nps-modal-message.style';

export const NpsModalMessage: React.FC = () => {
  const [text, setText] = useState('');
  const { user } = useStore<UserStore>(useUserStore);
  const strings: InpsModalStrings = Strings[user.language];
  const dispatch = useDispatch();

  return (
    <>
      <H2>{strings.messageQuestion}</H2>
      <FlexRow fullWidth pl pr>
        <TextAreaMessage
          maxLength={MESSAGE_MAXLENGTH}
          onChange={e => setText(e.target.value)}
          onBlur={() => dispatch(setMessage(text))}
        />
      </FlexRow>
    </>
  );
};
