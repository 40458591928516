import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import {
  ConfigColumnsContext,
  ConfigColumnsContextState,
} from '../../components/config-columns-modal/config-columns-modal.context';
import { OrderInputBaseboard } from '../../components/order-input-baseboard/order-input-baseboard';
import { OrderInputEvents } from '../../order-input.analytics';
import { OrderInputPhaseEnum } from '../../order-input.constants';
import { setMaterialOptions, setPhase, setQuery, setSpreadSheetFileName } from '../../store/order-input.store';

export const OrderInputSelectItensBaseboard: React.FC = () => {
  const { setSpreadSheetFile } = useContext<ConfigColumnsContextState>(ConfigColumnsContext);
  const dispatch = useDispatch();

  const handleActionClick = () => {
    LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: OrderInputPhaseEnum.DataAndAmout });
    dispatch(setMaterialOptions([]));
    dispatch(setQuery(''));
    dispatch(setPhase(OrderInputPhaseEnum.DataAndAmout));
    dispatch(setSpreadSheetFileName(null));
    setSpreadSheetFile(null);
  };

  const handleReturnClick = () => {
    LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: OrderInputPhaseEnum.Options });
    dispatch(setPhase(OrderInputPhaseEnum.Options));
  };

  return <OrderInputBaseboard onActionClick={() => handleActionClick()} onReturnClick={() => handleReturnClick()} />;
};
