/* eslint-disable complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { FullAnalysisData } from '@app/models/quote.model';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';

interface EditQuotationProps {
  analysis: FullAnalysisData;
  onAnalysisEditClick: () => void;
}

export const EditQuotationButton: React.FC<EditQuotationProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = TechnicalAnalysisStrings[userInfo.language];

  return props.analysis?.permissions.isEditable ? (
    <Hbox>
      <Button kind='secondary' title={strings.components.about.editAnalysis} onClick={props.onAnalysisEditClick}>
        <Hbox hAlign='left' fullWidth>
          <Hbox.Item noGrow vAlign='center'>
            <FaIcon.Custom icon='pen' />
          </Hbox.Item>
          <Hbox.Separator />
          <Body bold color={Color.GrayXDark} noWrap>
            {strings.components.about.editAnalysis}
          </Body>
        </Hbox>
      </Button>
    </Hbox>
  ) : null;
};
