import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';

export const MaterialFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
`;

export const MaterialOptionsSelectedWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const OrderInputSelectItensWrapper = styled.div`
  width: 100%;
`;

export const OrderInputSelectItensGrid = styled(Grid)`
  margin-top: ${Spacing.XLarge};
`;
