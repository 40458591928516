import * as React from 'react';
import { ItemsDataProps } from '@app/models/crm.model';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { CrmStrings } from '../../crmComplain.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

interface CrmItemsProps {
  data: ItemsDataProps;
  loading: boolean;
}

export const CrmItems = (props: CrmItemsProps) => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.ITEMS} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>
      {props?.data?.infos?.length > 0 ? (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />

            <Table collapse>
              <TR bordered>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.id}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.idHigher}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.itemHigher}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.quantity}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.unit}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.productName}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.categoryName}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.productNetValue}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.coin}</Body>
                </TD>
                <TD color={Color.Secondary}>
                  <Body bold={600}>{strings.tables.cols.idCostCenter}</Body>
                </TD>
              </TR>
              {props.data.infos.map((item, index) => (
                <TR bordered key={`t-${index}`}>
                  <TD>
                    <Body>{item.value.id}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value.idHigher}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value.itemHigher}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value.quantity}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value.unit}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value.productName}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value.categoryName}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value.productNetValue}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value.coin}</Body>
                  </TD>
                  <TD>
                    <Body>{item.value.idCostCenter}</Body>
                  </TD>
                </TR>
              ))}
            </Table>
          </Hbox>
        </CrmContentInfosWrapper>
      ) : (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />
            <Body centralized>{props.loading ? 'Carregando...' : 'Nenhum registro encontrado.'}</Body>
          </Hbox>
        </CrmContentInfosWrapper>
      )}
    </CrmContentWrapper>
  );
};
