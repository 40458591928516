import styled, { css } from 'styled-components';
import { BrandColor, Color, FontFamily } from '@atomic/obj.constants';

export const DaysOfMonthGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 1px;
`;

const selectedDayCss = css`
  color: ${Color.White};
  background-color: ${BrandColor.Blue};
`;

export const DayWrapper = styled.div`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${FontFamily.Primary};
  border: 1px solid ${Color.Gray};
  ${props => props.selected && selectedDayCss}

  &:hover {
    background-color: ${Color.Gray};
  }
`;

export const FixedCalendarWrapper = styled.div`
  background-color: ${Color.White};
  width: 320px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.Gray};
  position: absolute;
  z-index: 5;
  top: -340px;
  left: 120px;
`;
