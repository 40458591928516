import { Customer } from '@app/models';
import { MarketOptions } from '@app/modules/components/customer-search/customer-search.constants';
import { IOption } from '@app/modules/components/customer-search/customer-search.interfaces';

interface GetUnselectedOptionsParams {
  corporateGroupSearch: IOption[];
  data: Customer[];
  market: MarketOptions;
}

export const getUnselectedOptions = ({ data, market, corporateGroupSearch }: GetUnselectedOptionsParams): IOption[] => {
  const corporateGroupsMarket: Customer[] = data.filter((customer: Customer) => customer.market === market);

  const uniqueGroups: string[] = [...new Set(corporateGroupsMarket.map((item: Customer) => item.corpGroup))];

  const uniqueOptions: IOption[] = uniqueGroups.map(
    (group: string): IOption => {
      const customer = data.find(item => item.corpGroup === group);
      return { name: customer.corpGroupName, value: group };
    },
  );

  const unselectedOptions: IOption[] = uniqueOptions.filter((option: IOption) => {
    const corporateGroup = corporateGroupSearch.find(corpGroup => corpGroup.value === option.value);
    return !corporateGroup;
  });

  return unselectedOptions;
};
