import React from 'react';
import { Label } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { FontSize, FontWeight } from '@atomic/obj.constants';
import { BorderedTextField } from '../config-columns-modal.style';
import { validColumnField } from '../utils';

interface ColumnInputProps {
  disabled: boolean;
  labelText: string;
  value: string;
  setValue: any;
  invalid?: boolean;
}

export const ColumnInput: React.FC<ColumnInputProps> = ({ labelText, value, setValue, disabled, invalid }) => {
  return (
    <FlexColumn>
      <BorderedTextField
        invalid={invalid}
        disabled={disabled}
        type='text'
        align='center'
        value={value}
        onValueChange={newValue => setValue(validColumnField(newValue, value))}
      />
      <Label size={FontSize.XSmall} weight={FontWeight.Normal}>
        {labelText}
      </Label>
    </FlexColumn>
  );
};
