import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { UserStore, useUserStore } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { NpsPhase, NPS_ANSWERED, TOP_ACCOUNTS_CORPORATE_GROUPS } from '../../nps-modal.constants';
import { InpsModalStrings, Strings } from '../../nps.string';
import { setPhase, submitNps } from '../../store/nps-modal.store';
import { putNPS } from '../../store/thunk/nps-put.thunk';

export const NpsModalAdvanceButton: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const strings: InpsModalStrings = Strings[user?.language];
  const { userInfo, ssoAccess } = useSelector((state: RootState) => state.auth);
  const { phase, note, dontSeeAgain, message } = useSelector((state: RootState) => state.nps);
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);

  const corporateGroup = clientBpsSelected[0]?.corpGroup;
  const salesOrg = clientBpsSelected[0]?.salesOrg;
  const corpGroup = clientBpsSelected[0]?.corpGroup;
  const isTopAccount = TOP_ACCOUNTS_CORPORATE_GROUPS.includes(corporateGroup);
  const optaramPorNaoResponder = dontSeeAgain;

  const dispatch = useDispatch<AppThunkDispatch>();

  const handleClickAdvance = () => {
    dispatch(setPhase(NpsPhase.Message));
  };

  const handleClickSubmit = () => {
    setTimeout(() => {
      dispatch(
        putNPS({
          _id: userInfo?._id,
          isTopAccount,
          salesOrg,
          corpGroup,
          optaramPorNaoResponder,
          note: note,
          message: message,
          answer: NPS_ANSWERED,
          ssoAccess: ssoAccess,
          language: user?.language,
        }),
      );
      dispatch(submitNps({ isTopAccount, salesOrg, corpGroup }));
    }, 0);
  };
  const disabled = note === null;

  if (phase === NpsPhase.Grade) {
    return (
      <Button kind='primary' disabled={disabled} onClick={handleClickAdvance}>
        <FlexRow mr={Spacing.Small}>
          <FaIcon.RightArrow />
        </FlexRow>
        {strings?.advanceButton}
      </Button>
    );
  } else {
    return (
      <Button kind='primary' disabled={disabled} onClick={handleClickSubmit}>
        <FlexRow mr={Spacing.Small}>
          <FaIcon.Custom icon='paper-plane' />
        </FlexRow>
        {strings?.submitButton}
      </Button>
    );
  }
};
