import styled from 'styled-components';
import { Color, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';

export const TimeSelectWrapper = styled.div`
  height: 30px;
  width: 120px;
  border: 1px solid ${Color.GrayDark};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: ${Spacing.Small};
  margin-left: ${Spacing.Small};
  position: relative;
  border-radius: 5px;
`;

export const TimeInput = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 0;
  outline: none;
  width: 80px;
  height: 25px;
  font-family: ${FontFamily.Primary};
  color: ${Color.GrayDark};
  font-size: ${FontSize.Small};
  cursor: pointer;
`;

export const TimeSelectOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: absolute;
  top: 28px;
  left: -1px;
  z-index: 6;
  background-color: ${Color.White};

  width: 120px;
  height: 200px;
  overflow: scroll;
  border: 1px solid ${Color.GrayDark};
  font-family: ${FontFamily.Primary};
  color: ${Color.GrayDark};
  font-size: ${FontSize.Small};
  border-radius: 5px;
`;

export const TimeSelectOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-left: ${Spacing.Small};
  padding-top: ${Spacing.XSmall};
  padding-bottom: ${Spacing.XSmall};
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${Color.Primary};
    color: ${Color.White};
  }
`;
