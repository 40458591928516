import * as React from 'react';
import { BodySecondary } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import {
  InternalBadgeContentStyled,
  InternalBadgeContentWrapperStyled,
  InternalBadgeWrapperStyled,
  InternalLargeBadgeContentStyled,
  InternalLargeBadgeContentWrapperStyled,
} from './internal-badge.component.style';

export interface InternalBadgeProps {
  notification: string;
}

export const InternalBadge: React.FC<InternalBadgeProps> = props => {
  const handleNotificationIndicator = () => {
    if (parseInt(props.notification) > 0 && parseInt(props.notification) < 100) {
      return (
        <InternalBadgeContentWrapperStyled>
          <InternalBadgeContentStyled>
            <BodySecondary color={Color.White}>{props.notification}</BodySecondary>
          </InternalBadgeContentStyled>
        </InternalBadgeContentWrapperStyled>
      );
    } else if (parseInt(props.notification) > 99) {
      return (
        <InternalLargeBadgeContentWrapperStyled>
          <InternalLargeBadgeContentStyled>
            <BodySecondary color={Color.White}>{'99+'}</BodySecondary>
          </InternalLargeBadgeContentStyled>
        </InternalLargeBadgeContentWrapperStyled>
      );
    } else {
      return null;
    }
  };

  return (
    <InternalBadgeWrapperStyled>
      {props.children}
      {handleNotificationIndicator()}
    </InternalBadgeWrapperStyled>
  );
};
