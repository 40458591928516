import styled from 'styled-components';
import { Border, Color, Spacing } from '@atomic/obj.constants';

interface CellStyledProps {
  hideBorder: boolean;
  hover: boolean;
  hasClick?: boolean;
}

export const CellStyled = styled.div`
  ${(props: CellStyledProps) =>
    !props.hideBorder &&
    `
    border: 1px solid;
    border-color: ${Border.Color};
    padding: ${Spacing.Medium};
  `}
  position: relative;
  background-color: ${Color.White};

  ${(props: CellStyledProps) => props.hasClick && 'cursor: pointer;'}

  & + & {
    border-top: 0;
  }

  ${(props: CellStyledProps) =>
    props.hover &&
    `
    &:hover {
      background-color: ${Color.Secondary};
    }
  `}
`;
