import { createAsyncThunk } from '@reduxjs/toolkit';
import { GKN_BPS } from '@app/constants';
import { OrderDataSource } from '@app/data/http/order-MI.datasource';
import { HanaLanguage } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { SLICE_NAME } from '../order-tab-stock.constants';

export const performRequest = createAsyncThunk(`${SLICE_NAME}/performRequest`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const promiseRequestTime = new Date().getTime();
  const customerIds = customerIdsJoinedSelector(thunkAPI.getState());
  const isGKN = state.customerPersist.clientBpsSelected.some(bp => GKN_BPS.includes(bp.customerId));

  const params = {
    customerIds,
    language: HanaLanguage[state.auth?.userInfo?.language],
  };

  return await OrderDataSource.getStockOrder(params, isGKN)
    .then(data => ({ promiseRequestTime, data }))
    .catch(err => {
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});
