import { format } from 'date-fns';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { Body, InputLabel } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { ChatStrings } from '../../chat.string';
import { ChatResultAttributeWrapper } from './chat-result.component.style';

interface AttributeProps {
  attribute?: {
    type: string;
    creation: string;
    visible: boolean;
    label: string;
    value: any;
  };
  attrKey?: string;
}

export const Attribute: React.FC<AttributeProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language];

  const convertValue = (value: any, type: string, key: string) => {
    const values = [];
    switch (type) {
      case 'date':
        return value && format(new Date(value), 'dd/MM/yyyy');
      case 'textArray':
        value !== undefined &&
          value.forEach(val => {
            if (strings[key]) {
              return values.push(strings[key][val]);
            } else {
              return values.push(val);
            }
          });
        return values.join(', ');
      case 'number':
        if (strings.resumeAnalysis[value]) {
          return strings.resumeAnalysis[value];
        } else {
          return value;
        }
      case 'range':
        if (strings.resumeAnalysis[value]) {
          return strings.resumeAnalysis[value];
        } else {
          return value;
        }
      case 'text':
        if (value?.label) {
          return value.label;
        } else {
          if (strings.resumeAnalysis[value]) {
            return strings.resumeAnalysis[value];
          } else {
            return value;
          }
        }
    }
  };

  return props?.attribute?.label && props.attribute.label !== undefined && props.attribute.visible ? (
    <ChatResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.creation === 'NLU'}
    >
      <Hbox.Item>
        <InputLabel>
          {props.attribute?.label}
          {props.attribute?.creation === 'NLU' && (
            <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
          )}
        </InputLabel>
        <Body bold={600} color={Color.Accessory}>
          {convertValue(props.attribute?.value, props.attribute?.type, props.attrKey)}
        </Body>
      </Hbox.Item>
    </ChatResultAttributeWrapper>
  ) : null;
};
