import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { IAdminBPList } from '@app/models/admin.model';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { AdminStrings } from '../../admin.string';

export const getAllBPs = createAsyncThunk(`${SLICE_NAME}/getAllBPs`, async (_, thunkAPI) => {
  const strings = AdminStrings.pt.bpView;

  return await AdminDataSource.getBPs({
    skip: 0,
    limit: 9999,
    filterType: '',
    filterValue: '',
  })
    .then(data => data)
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: strings.expotExcelError, variant: ToastVariant.DANGER }));
    });
});

export const addGetAllBPsReducers = builder => {
  builder.addCase(getAllBPs.pending, state => {
    state.loadingAllBps = true;
  });
  builder.addCase(getAllBPs.fulfilled, (state, action) => {
    const payload: IAdminBPList = action.payload as IAdminBPList;
    state.loadingAllBps = false;
    state.allBPs = payload.bps;
  });
  builder.addCase(getAllBPs.rejected, state => {
    state.loadingAllBps = false;
  });
};
