import * as React from 'react';
import { HeaderDeskStyled, HeaderMobileStyled, HeaderWrapperStyled } from './header.component.style';

const HeaderMobile = (props: any) => (
  <HeaderMobileStyled adminHeader={props.adminHeader}>{props.children}</HeaderMobileStyled>
);
const HeaderDesk = (props: any) => (
  <HeaderDeskStyled adminHeader={props.adminHeader}>{props.children}</HeaderDeskStyled>
);

export class Header extends React.Component<{}> {
  static Mobile = HeaderMobile;
  static Desk = HeaderDesk;

  render() {
    return <HeaderWrapperStyled>{this.props.children}</HeaderWrapperStyled>;
  }
}
