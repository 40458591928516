/* eslint-disable prettier/prettier */
import * as React from 'react';
import { Col, Row } from '@atomic/obj.grid';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-enterprise';
import { AuthBoxStyled, AuthBoxWrapperStyled, AuthFormStyled } from './auth-box.style';

export interface AuthBoxProps {
  gabMaintenancePage?: boolean;
}

export const AuthBox: React.FC<AuthBoxProps> = props => {
  return (
    <AuthBoxWrapperStyled>
      <AuthBoxStyled>
        <Row>
          <Col xs={12}>
            <AuthFormStyled>
              {props.children}
            </AuthFormStyled>
          </Col>
        </Row>
      </AuthBoxStyled>
    </AuthBoxWrapperStyled>
  );
};
