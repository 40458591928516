import { ITruckTrackingState } from '../truck-tracking.interfaces';
import { initialState } from './truck-tracking.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setShowTruckTracking: (state: ITruckTrackingState, { payload }) => {
    state.showTruckTracking = payload;
  },
  setTruckTrackingInfo: (state: ITruckTrackingState, { payload }) => {
    state.truckTrackingInfo = payload;
  },
};
