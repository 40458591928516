import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { useStore } from '@app/core/global-store.service';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { IAppStrings, Strings } from '@app/modules/app/app.string';
import { ONBOARDING_BRANDS_KEY } from '@app/modules/components/announcement-modal/announcement-modal.constants';
import { UserStore, useUserStore } from '@app/providers';
import { alredyOnboardingBrandsSelector } from '@app/providers/navigation/navigation.selectors';
import { setCompanyLogosTooltipVisible } from '@app/providers/navigation/navigation.store';
import { putOnboarding } from '@app/providers/navigation/thunks/put-onboarding.thunk';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { Image } from '@atomic/atm.image';
import { ContainerCompanyLogosWrapper } from './logos-header.style';

interface LogosAppHeaderProps {
  shortHeader: boolean;
}
export const LogosAppHeader: React.FC<LogosAppHeaderProps> = ({ shortHeader }) => {
  const { user } = useStore<UserStore>(useUserStore);
  const strings: IAppStrings = Strings[user?.language];

  const { openBPsModal, companyLogosTooltipVisible } = useSelector((state: RootState) => state.navigation);
  const { opened: openAnnouncementModal } = useSelector((state: RootState) => state.announcement);
  const hasBrandsAnnouncement = useSelector(alredyOnboardingBrandsSelector);

  const dispatch = useDispatch<AppThunkDispatch>();

  const closeTooTipCompanyLogos = () => {
    dispatch(setCompanyLogosTooltipVisible(false));
    dispatch(putOnboarding(ONBOARDING_BRANDS_KEY));
  };

  return (
    <ContainerCompanyLogosWrapper shortHeader={shortHeader}>
      <a
        href='https://mais.gerdau.com.br/automotivo'
        target='blank'
        onClick={() => LogAnalytics.click({ tipo: 'HeaderLinksClick' })}
      >
        <Image.LogoGerdauCustom />
        <Image.LogoGerdauMax />
        <Image.LogoGerdauTech />
        <Image.LogoGerdauStandard />
      </a>
      <>
        {hasBrandsAnnouncement && !openBPsModal && !openAnnouncementModal && (
          <CustomizedTooltip
            title=''
            description={strings?.companyLogosTooltip.description}
            visible={companyLogosTooltipVisible}
            onClick={() => closeTooTipCompanyLogos()}
            onClose={() => closeTooTipCompanyLogos()}
            customHeight={50}
            positionXFromRight={true}
            customArrowX={170}
            hasOffset
          />
        )}
      </>
    </ContainerCompanyLogosWrapper>
  );
};
