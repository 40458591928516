export interface IOrderTabDocumentsStrings {
  title: string;
  searchPlaceholder: string;
  boarding: string;
  regDate: string;
  mostRecent: string;
  error: string;
}

interface OrderTabDocumentsLanguageInterface {
  pt: IOrderTabDocumentsStrings;
  es: IOrderTabDocumentsStrings;
  in: IOrderTabDocumentsStrings;
}

const pt: IOrderTabDocumentsStrings = {
  title: 'Documentos',
  searchPlaceholder: 'Buscar por número de embarque',
  boarding: 'Embarque',
  regDate: 'Data de averbação',
  mostRecent: 'Mais recentes',
  error: 'Ocorreu um erro ao carregar os dados da aba Documentos! Por favor, tente novamente.',
};

const es: IOrderTabDocumentsStrings = {
  title: 'Documentos',
  searchPlaceholder: 'Buscar por número de embarque',
  boarding: 'Embarque',
  regDate: 'Fecha de Registro',
  mostRecent: 'Más reciente',
  error: '¡Ocurrió un error al cargar datos desde la pestaña Documentos! Inténtalo de nuevo.',
};

const ing: IOrderTabDocumentsStrings = {
  title: 'Documents',
  searchPlaceholder: 'Search by boarding number',
  boarding: 'Boarding',
  regDate: 'Registration Date',
  mostRecent: 'Most Recent',
  error: 'An error occurred while loading data from the Documents tab! Please try again.',
};

export const Strings: OrderTabDocumentsLanguageInterface = {
  pt,
  es,
  in: ing,
};
