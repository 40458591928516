/* eslint-disable prettier/prettier */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { IPostFileParams, OrderInputDatasource } from '@app/data/http';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import { OrderInputEvents } from '../../order-input.analytics';
import { OrderInputPhaseEnum, SLICE_NAME } from '../../order-input.constants';
import type { IOrderInputState } from '../../order-input.interfaces';

export const postFiles = createAsyncThunk(`${SLICE_NAME}/postFiles`, async (files: IPostFileParams, thunkAPI) => {
  return await OrderInputDatasource.postFiles(files)
    .then(data => data)
    .catch(error => {
      LogAnalytics.error({
        tipo: OrderInputEvents.OrderReadDataError,
        responseStatus: error.response.status,
        responseData: error.response.data?.message,
      });

      thunkAPI.dispatch(setOpenErrorHandlerModal(true));
      return thunkAPI.rejectWithValue({});
    });
});

export const addPostFilesReducers = builder => {
  builder.addCase(postFiles.pending, (state: IOrderInputState) => {
    state.loadingPostFiles = true;
  });
  builder.addCase(postFiles.fulfilled, (state: IOrderInputState, action) => {
    state.loadingPostFiles = false;
    state.orderReadData = action.payload.data;
    state.phase = OrderInputPhaseEnum.DataAndAmout;
  });
  builder.addCase(postFiles.rejected, (state: IOrderInputState) => {
    state.loadingPostFiles = false;
    state.orderReadData = [];
  });
};
