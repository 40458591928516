import { GridReadyEvent } from 'ag-grid-community';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { MarketType } from '@app/providers';
import { TagCheckboxField } from '@atomic/atm.tag-checkbox';
import { Strings, IFinanceStrings } from './finance.string';

interface FinanceGridRowFilterProps {
  gridEvent: GridReadyEvent;
  market: string;
}

enum FinanceStatus {
  notExpired = 'not expired',
  expired = 'expired',
  paid = 'paid',
}

export const FinanceGridRowFilter: React.FC<FinanceGridRowFilterProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IFinanceStrings = Strings[userInfo?.language];

  const [check, setCheck] = React.useState({
    [FinanceStatus.notExpired]: true,
    [FinanceStatus.expired]: true,
    [FinanceStatus.paid]: true,
  });

  const handleCheckChanged = (id: string, checked: boolean) => {
    LogAnalytics.click({ tipo: 'FiltroFinancas' + id });
    setCheck({ ...check, [id]: checked });
  };

  React.useEffect(() => {
    if (!props.gridEvent) {
      return;
    }

    let filter = props.gridEvent.api.getFilterInstance('status');

    if (props.market === MarketType.Internal) {
      filter = props.gridEvent.api.getFilterInstance('statusCompensation');
    }

    const model = Object.keys(check).reduce((acc, cur) => {
      if (check[cur]) {
        acc.push(cur.toUpperCase());
      }
      return acc;
    }, []);

    filter.setModel({ values: model });
    props.gridEvent.api.onFilterChanged();
  }, [check]);

  return (
    <>
      <TagCheckboxField id={FinanceStatus.notExpired} initialChecked onValueChange={handleCheckChanged}>
        {strings.status.notExpired}
      </TagCheckboxField>
      <TagCheckboxField id={FinanceStatus.expired} initialChecked onValueChange={handleCheckChanged}>
        {strings.status.expired}
      </TagCheckboxField>
      <TagCheckboxField id={FinanceStatus.paid} initialChecked onValueChange={handleCheckChanged}>
        {strings.status.paid}
      </TagCheckboxField>
    </>
  );
};
