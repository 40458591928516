import { Surrogate } from '@app/models/surrogate.model';

export interface SurrogateDto {
  data: Surrogate[];
  errors: any;
  success: boolean;
}

export const mapSurrogate = (response: SurrogateDto): Surrogate => {
  let surrogate: Surrogate = null;

  if (response.data.length > 0) {
    surrogate = {
      _id: response.data[0]._id,
      from: response.data[0].from,
      surrogatesTo: response.data[0].surrogatesTo,
      isIndeterminateTime: response.data[0].isIndeterminateTime,
      expiresFrom: response.data[0].expiresFrom,
      expiresTo: response.data[0].expiresTo,
    };
  }

  return surrogate;
};
