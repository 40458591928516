import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { IOrderPriorizationTableData } from '../../order-priorization.interfaces';
import { IOrderPriorizationStrings, Strings } from '../../order-priorization.string';
import { RESET_ORDER_PRIORIZATION_ACTION } from '../../store/order-priorization.reducers';
import { setAlreadyOpenedModalMessage, setShowConfirmModal } from '../../store/order-priorization.store';

interface ModalMessageProps {
  tableRows: IOrderPriorizationTableData[];
  slotNumbers: number;
  showWarningMessage: boolean;
}

export const ModalMessage: React.FC<ModalMessageProps> = ({ tableRows, slotNumbers, showWarningMessage }) => {
  const strings: IOrderPriorizationStrings = Strings.pt;
  const { showConfirmModal, loading, criticalItemInfoLoading } = useSelector(
    (state: RootState) => state.orderPriorization,
  );
  const { loading: orderTabLoading } = useSelector((state: RootState) => state.orderTabOpen);
  const dispatch = useDispatch<AppThunkDispatch>();
  const onCloseModal = () => {
    if (showWarningMessage) {
      dispatch(RESET_ORDER_PRIORIZATION_ACTION);
    } else {
      dispatch(setShowConfirmModal(false));
    }
  };

  useEffect(() => {
    dispatch(setAlreadyOpenedModalMessage(true));
  }, []);

  return (
    <Modal small opened={showConfirmModal} preventOverlayClick={loading || orderTabLoading || criticalItemInfoLoading}>
      <Grid fluid>
        <FlexColumn mt mb>
          <FlexRow fullWidth>
            <Body>{strings.modal.comment}</Body>
          </FlexRow>
          <VSeparator />
          <FlexColumn>
            <Body bold={700} color={showWarningMessage ? Color.Alert : Color.Black}>
              {showWarningMessage ? strings.modal.warningMessage : strings.modal.confirmMessage(tableRows?.length)}
            </Body>
          </FlexColumn>
          <VSeparator />
          <FlexRow>
            <FaIcon.Custom icon='exclamation-triangle' color={Color.Primary} />
            <Body ml={Spacing.Small}>{strings.modal.alertMessage}</Body>
            <Body ml={Spacing.Small} bold={700} color={Color.Black}>
              {`${slotNumbers} ${strings.modal.itemLabel}`}
            </Body>
          </FlexRow>
        </FlexColumn>
        <FlexRow hAlign='flex-end'>
          <FlexRow noGrow>
            <Button
              type='submit'
              loading={loading || orderTabLoading || criticalItemInfoLoading}
              disabled={loading || orderTabLoading || criticalItemInfoLoading}
            >
              {strings.modal.proceedBtn}
            </Button>
          </FlexRow>
          <FlexRow ml={Spacing.Small}>
            <Button
              kind='secondary'
              loading={loading || orderTabLoading || criticalItemInfoLoading}
              onClick={onCloseModal}
            >
              {strings.modal.cancelBtn}
            </Button>
          </FlexRow>
        </FlexRow>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
