export enum TableColumns {
  ov = 'ov',
  ocClient = 'ocClient',
  codMaterialClient = 'codMaterialClient',
  qtdConf = 'qtdConf',
  qtdSolClient = 'qtdSolClient',
  dateMaxDeliveryClient = 'dateMaxDeliveryClient',
  dateSolClient = 'dateSolClient',
  newQtdSolClient = 'newQtdSolClient',
  newDateSolClient = 'newDateSolClient',
  cancelOrder = 'cancelOrder',
  messageClient = 'messageClient',
  delete = 'delete',
}

export enum TableColumnType {
  StaticText = 'staticText',
  StaticDate = 'staticDate',
  Number = 'number',
  Checkbox = 'checkbox',
  Text = 'text',
  Date = 'date',
  Delete = 'delete',
}
