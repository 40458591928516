import React from 'react';
import { useDispatch } from 'react-redux';
import { AppThunkDispatch } from '@app/core/redux/store';
import { IOrderPriorizationTableData } from '@app/modules/order-priorization/order-priorization.interfaces';
import {
  setItemToRemove,
  setShowRemoveItemModal,
} from '@app/modules/order-priorization/store/order-priorization.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';

interface OrderPriorizationTableActionCellProps {
  item: IOrderPriorizationTableData;
}

export const OrderPriorizationTableActionCell: React.FC<OrderPriorizationTableActionCellProps> = ({ item }) => {
  const dispatch = useDispatch<AppThunkDispatch>();

  if (item) {
    return (
      <FaIcon.Trash
        color={Color.Alert}
        icon='trash'
        size='1x'
        clickable
        onClick={() => {
          dispatch(setItemToRemove(item));
          dispatch(setShowRemoveItemModal(true));
        }}
      />
    );
  }

  return null;
};
