import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Body } from '@atomic/atm.typography';
import { StockTabTooltipStyled } from './order-tab-stock-tooltip.style';

// eslint-disable-next-line import/no-default-export
export default forwardRef((props: any, ref) => {
  const isHeader = props.location === 'header';
  const [row] = useState(props.api.getDisplayedRowAtIndex(props.rowIndex));
  const [data, setData] = useState(null);

  useEffect(() => {
    if (row && row.data) {
      setData(row.data);
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-tooltip'];
      },
    };
  });

  const canSelectRow = () => {
    let displayeChekboxSelection = true;

    if (data && data.deliveryQuantity + data.pickingQuantity >= data.stockQuantity) {
      displayeChekboxSelection = false;
    }

    return displayeChekboxSelection;
  };

  if (!isHeader && !canSelectRow()) {
    return (
      <StockTabTooltipStyled>
        <Body>Esse pedido já possui 100% do estoque</Body>
        <Body>com remessa gerada.</Body>
      </StockTabTooltipStyled>
    );
  } else {
    return null;
  }
});
