import * as React from 'react';
import { FrameStyled, FrameStyledProps } from './frame.component.style';

export const Frame: React.FC<FrameStyledProps> = props => {
  return (
    <FrameStyled
      rounded={props.rounded}
      color={props.color}
      noShadow={props.noShadow}
      padded={props.padded}
      height={props.height}
      zIndex={props.zIndex}
    >
      {props.children}
    </FrameStyled>
  );
};
