import { ColDef } from 'ag-grid-community';
import { sizeInFormatter, valueQtyFormatter } from '@app/utils/order-column-formatter';

interface HandleStartDateChangeParams {
  date: number;
  endDate: number;
  setStartDate: (value: number) => void;
  setEndDate: (value: number) => void;
}

export const handleStartDateChange = ({ date, endDate, setStartDate, setEndDate }: HandleStartDateChangeParams) => {
  if (date) {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  }
};

export const handleMaritmeShippedTabColumn = (columns: ColDef[], language: string) => {
  const valueColumnsFields = ['nfQuantity', 'size', 'width', 'thickness'];

  if (columns) {
    columns.forEach(column => {
      if (column.field === 'sizeIn') {
        column.valueFormatter = param => sizeInFormatter(param, language);
      }

      if (valueColumnsFields.includes(column.field)) {
        column.valueFormatter =
          language === 'in' ? param => valueQtyFormatter(param, true) : param => valueQtyFormatter(param, false);
      }
    });
  }
};

interface HandleEndDateChangeParams {
  date: Date;
  setEndDate: (value: Date) => void;
}

export const handleEndDateChange = ({ date, setEndDate }: HandleEndDateChangeParams) => {
  if (date) {
    setEndDate(date);
  }
};
