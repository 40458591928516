import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IOrderUpdateStrings, Strings } from '@app/modules/order-update/order-update.string';
import { setCanceled } from '@app/modules/order-update/store/order-update.store';
import { CancelOrderButtonStyled } from '../order-update-table.style';

export interface IOrderUpdateCancelButtonCellProps {
  index: number;
  disabled: boolean;
}

const strings: IOrderUpdateStrings = Strings.pt;

export const OrderUpdateCancelButtonCell: React.FC<IOrderUpdateCancelButtonCellProps> = ({ index, disabled }) => {
  const { tableRows } = useSelector((state: RootState) => state.orderUpdate);
  const dispatch = useDispatch();

  return (
    <CancelOrderButtonStyled
      id={index}
      type='button'
      kind='primary'
      size='small'
      selected={tableRows[index].cancelOrder}
      onClick={() => dispatch(setCanceled(index))}
      disabled={disabled}
    >
      {tableRows[index].cancelOrder ? strings.table.undoRequestCancel : strings.table.requestCancel}
    </CancelOrderButtonStyled>
  );
};
