import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import { AppPath } from '@app/modules/app/route-constants';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { setOpenFinanceSidebarFilters, setOpenSidebarFilters } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';
import { countFiltersSelector } from '../side-bar-filters/store/sidebar-filters.selectors';
import { IAdvancedFiltersButtonStrings, Strings } from './advanced-filters-button.string';
import { AdvancedFiltersButtonWrapper, FilterCounterWrapper } from './advanced-filters-button.style';

export const AdvancedFiltersButton: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const history = useHistory();
  const tab = history.location.pathname === AppPath.FINANCE ? LayoutTabs.FinanceMI : MItabIndex[orderTabIndex];
  const filtersCount: number = useSelector(state => countFiltersSelector(state, tab));
  const { openSidebarFilters, openFinanceSidebarFilters } = useSelector((state: RootState) => state.navigation);

  const strings: IAdvancedFiltersButtonStrings = Strings[userInfo?.language];
  const dispatch = useDispatch();

  const handleShowFilterCount = () => {
    if (
      (history.location.pathname === AppPath.FINANCE && !openFinanceSidebarFilters && filtersCount > 0) ||
      (history.location.pathname === AppPath.ORDERS && !openSidebarFilters && filtersCount > 0)
    ) {
      return true;
    }

    return false;
  };

  return (
    <AdvancedFiltersButtonWrapper>
      <Button
        kind='secondary'
        onClick={() => {
          if (history.location.pathname === AppPath.FINANCE) {
            dispatch(setOpenFinanceSidebarFilters(true));
          } else {
            dispatch(setOpenSidebarFilters(true));
          }
        }}
      >
        <FaIcon.Filter />
        <FlexRow>{strings.advancedFilters}</FlexRow>
      </Button>
      {handleShowFilterCount() && <FilterCounterWrapper>{filtersCount}</FilterCounterWrapper>}
    </AdvancedFiltersButtonWrapper>
  );
};
