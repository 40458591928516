import ColorFunc from 'color';
import styled from 'styled-components';
import { Border, Color, DesignSystemColor, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { BadgeProps } from './badge.component';

export const badgeColors = {
  primary: Color.Primary,
  secondary: Color.Secondary,
  accessory: Color.Accessory,
  alert: Color.Alert,
  success: Color.SuccessMain,
  warning: Color.Warning,
  neutral: Color.GrayDark,
  pink: Color.Pink,
  purple: Color.Purple,
  orange: Color.Orange,
  brown: Color.Brown,
  delay: Color.Delay,
  delayTrend: Color.DelayTrend,
  lackOfCharge: Color.Alert,
};

const badgeTextColors = {
  delayText: Color.DelayText,
  delayTrendText: Color.DelayTrendText,
  whiteText: DesignSystemColor.White,
};

export const BadgeStyled = styled.span`
  padding: ${Spacing.XSmall} ${Spacing.Small};
  border-radius: ${Border.Radius};
  border-color: ${(props: BadgeProps) => (props.color ? badgeColors[props.color] : Color.Primary)};
  background-color: ${(props: BadgeProps) =>
    props.color
      ? props.dark
        ? badgeColors[props.color]
        : ColorFunc(badgeColors[props.color])
            .lighten(0.9)
            .blacken(0.1)
            .hsl()
            .string()
      : Color.Primary};
  align-self: flex-start;
  justify-content: center;
`;

export const BadgeTextStyled = styled.span`
  color: ${(props: BadgeProps) =>
    !props.textColor
      ? ColorFunc(badgeColors[props.color])
          .darken(props.dark ? 0.8 : 0.5)
          .hsl()
          .string()
      : badgeTextColors[props.textColor]};
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.XSmall};
  font-weight: ${FontWeight.Bold};
`;
