import { GridColumnDefinition } from '@app/modules/components';

export interface LayoutModel {
  tabs: GridColumnDefinition[];
}

export const SAVE_LAYOUT_DEBOUNCE_TIME = 5000;
export const MOVE_COLUMN_DEBOUNCE_TIME = 3000;

export const FILTER_DEBOUNCE_TIME = 3000;

export enum LayoutTabs {
  OpenMI = 'OpenMI',
  StockMI = 'StockMI',
  BilledMI = 'BilledMI',
  OpenME = 'OpenME',
  OpenMEMaritime = 'OpenMEMaritime',
  ShippedMEMaritime = 'ShippedMEMaritime',
  HarborMEMaritime = 'HarborME',
  FrontierME = 'FrontierME',
  ShippedME = 'ShippedME',
  FinanceMI = 'FinanceMI',
  FinanceME = 'FinanceME',
}
