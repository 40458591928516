export interface TruckTrackingInfo {
  transportDocument: string;
  clientNumber: string;
  nameClient: string;
  material: string;
  codMaterialClient: string;
  descMaterial: string;
  brand: string;
  invoice: string;
  ocClient: string;
  invoiceEmissionDate: string;
  plant: string;
  concatenatedBillingDocument: string;
}

export interface IGeneralInformations {
  licensePlate: string;
  route: string;
  transportType: string;
  travelStatus: string;
  truckLicensePlate: string;
}

interface IDestination {
  lat: string;
  long: string;
}

interface IOrigin {
  lat: string;
  long: string;
}

interface ITruck {
  lat: string;
  long: string;
}

interface ICurrentLocation {
  city: string;
  lastUpdate: string;
  remainingDistance: number;
  state: string;
  vehicleLastUpdate: string;
}

interface IShippingInformation {
  message: string;
  date: string;
  timeHasPassed: boolean;
}

interface IRealInformation {
  deliveryInformation: any;
  shippingInformation: IShippingInformation;
}

interface IDeliveryInformation {
  message: string;
  date: string;
  timeHasPassed: boolean;
}

interface IScheduledInformation {
  deliveryInformation: IDeliveryInformation;
  shippingInformation: IShippingInformation;
}
export interface ITrackingUpdateInformations {
  currentLocation: ICurrentLocation;
  real: IRealInformation;
  scheduled: IScheduledInformation;
}

export interface IMapInformations {
  destination: IDestination;
  origin: IOrigin;
  truck: ITruck;
}

export enum TravelStatus {
  Scheduled = 'agendado',
  InTransit = 'em_transito',
  Delivering = 'entregando',
  Done = 'encerrada',
}

export interface ITruckTrackingState {
  showTruckTracking: boolean;
  truckTrackingInfo: TruckTrackingInfo;
  generalInformations: IGeneralInformations;
  mapInformations: IMapInformations;
  trackingUpdateInformations: ITrackingUpdateInformations;
  map: any;
  loading: boolean;
  loadingMap: boolean;
  error: unknown;
}
