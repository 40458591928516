import styled from 'styled-components';
import { Breakpoint, HeaderHeight, Spacing } from '@atomic/obj.constants';
import { SearchBarProps } from '../../app-header.interfaces';

export const ContainerMenu = styled.div`
  ${(props: SearchBarProps) =>
    props.shortHeader ? `justify-content: center; height: ${HeaderHeight.DeskShort}` : `height: ${HeaderHeight.Desk}`};
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 0 0 0;
  transition: height 0.3s;
  @media all and (max-width: ${Breakpoint.lg}em) {
    height: ${HeaderHeight.DeskShort};
    padding: 0;
    order: 2;
  }
`;

export const SearchBarWrapper = styled.div`
  display: block;
  ${(props: SearchBarProps) => (props.shortHeader ? 'padding: 0;' : 'padding: 10px 0;')};
  display: ${(props: SearchBarProps) => (props.shortHeader ? 'none' : 'block')};

  @media all and (max-width: ${Breakpoint.lg}em) {
    border-bottom: none;
    padding: 0 0 0 ${Spacing.XLarge};
    height: ${HeaderHeight.DeskShort};
    display: flex;
    align-items: center;
  }
`;

export const HeaderFirstLineWrapper = styled.div`
  display: flex;
  flex-direction: ${(props: SearchBarProps) => (props.shortHeader ? 'row-reverse' : 'row')};
  justify-content: space-between;
  border-bottom: 1px solid #fbf8dd;
  margin-right: ${Spacing.XLarge};
`;

export const HeaderSecondLineWrapper = styled.div`
  display: flex;
`;
