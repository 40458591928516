import { createAsyncThunk } from '@reduxjs/toolkit';
import { ForgotPasswordParameters, LoginDataSource } from '@app/data/http/login.datasource';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { authErrorMessageMap } from '../../auth-error.mapper';
import { SLICE_NAME } from '../../auth.constants';

export const forgotPassword = createAsyncThunk(
  `${SLICE_NAME}/forgotPassword`,
  async (params: ForgotPasswordParameters, thunkAPI) => {
    return await LoginDataSource.forgotPassword(params)
      .then(data => data)
      .catch(error => {
        thunkAPI.dispatch(
          setToast({
            show: true,
            text: authErrorMessageMap[parseInt(error.response.status)],
            variant: ToastVariant.DANGER,
          }),
        );
      });
  },
);

export const addForgotPasswordReducers = builder => {
  builder.addCase(forgotPassword.pending, state => {
    state.forgotPasswordLoading = true;
  });
  builder.addCase(forgotPassword.fulfilled, (state, action) => {
    state.forgotPasswordData = action.payload;
    state.forgotPasswordLoading = false;
  });
  builder.addCase(forgotPassword.rejected, state => {
    state.forgotPasswordLoading = false;
  });
};
