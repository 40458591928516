export interface IZeroStateStrings {
  zeroStateMsg1: string;
  zeroStateMsg2: string;
  consolidatedView: string;
  searchClientHere: string;
}

interface IZeroStateLanguage {
  pt: IZeroStateStrings;
  es: IZeroStateStrings;
  in: IZeroStateStrings;
}

const pt: IZeroStateStrings = {
  zeroStateMsg1: 'Faça uma consulta dos clientes que deseja',
  zeroStateMsg2: 'na barra de buscas do menu superior',
  consolidatedView: ' Visão consolidada (0 de 0)',
  searchClientHere: 'Realize a busca de clientes aqui',
};

const es: IZeroStateStrings = {
  zeroStateMsg1: 'Busca los clientes que quieras',
  zeroStateMsg2: 'en la barra de búsqueda del menú superior',
  consolidatedView: ' Vista consolidada (0 de 0)',
  searchClientHere: 'Busque clientes aquí',
};

const en: IZeroStateStrings = {
  zeroStateMsg1: 'Search for the customers you want',
  zeroStateMsg2: 'in the search bar on the top menu',
  consolidatedView: ' Consolidated view (0 of 0)',
  searchClientHere: 'Search for clients here',
};

export const Strings: IZeroStateLanguage = {
  pt,
  es,
  in: en,
};
