import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants/constants';

export const HrWrapper = styled.div`
  display: flex;
  margin-bottom: ${Spacing.Small};
  align-items: baseline;
  flex: 1;
  margin-left: 43%;

  @media all and (max-width: 1850px) {
    margin-left: 42.5%;
  }
  @media all and (max-width: 1850px) {
    margin-left: 42%;
  }
  @media all and (max-width: 1800px) {
    margin-left: 41.5%;
  }
  @media all and (max-width: 1750px) {
    margin-left: 41%;
  }
  @media all and (max-width: 1700px) {
    margin-left: 40%;
  }
  @media all and (max-width: 1650px) {
    margin-left: 39.5%;
  }
  @media all and (max-width: 1600px) {
    margin-left: 39%;
  }
  @media all and (max-width: 1550px) {
    margin-left: 38%;
  }
  @media all and (max-width: 1500px) {
    margin-left: 37%;
  }
  @media all and (max-width: 1450px) {
    margin-left: 36.5%;
  }
  @media all and (max-width: 1400px) {
    margin-left: 35.5%;
  }
  @media all and (max-width: 1350px) {
    margin-left: 34%;
  }
  @media all and (max-width: 1300px) {
    margin-left: 33%;
  }
  @media all and (max-width: 1250px) {
    margin-left: 32%;
  }
  @media all and (max-width: 1200px) {
    margin-left: 31%;
  }
  @media all and (max-width: 1150px) {
    margin-left: 30%;
  }
`;

export const Hr = styled.hr`
  flex: 1;
  background-color: ${Color.GrayLight};
  height: 4px;
  border: none;
`;
