/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { SEARCH_DEBOUNCE_TIME } from '@app/modules/admin/utils/utils';
import { TextField } from '@atomic/atm.text-field';
import { FlexRow } from '@atomic/obj.box';
import { SearchByMaterial } from '../../order-input.constants';
import { OrderInputStrings } from '../../order-input.string';
import { setMaterialFieldID, setMaterialOptions, setOpenMaterials, setQuery } from '../../store/order-input.store';
import { getMaterialsThunk } from '../../store/thunks/get-materials.thunk';
import { MaterialOptions } from './material-options/material-options';
import { SearchMaterialButton } from './search-material-button/search-material-button';
import { SearchMaterialWrapper } from './search-material.style';

const Strings = OrderInputStrings.pt.selectItens;
interface ISearchMaterialFieldProps {
  tempID?: string;
  hasError?: boolean;
  onClick: (material: Material) => void;
}

export const SearchMaterialField: React.FC<ISearchMaterialFieldProps> = ({ tempID, hasError, onClick }) => {
  const { searchBy, selectedIssuer, materialFieldID, loadingMaterials, openMaterials } = useSelector(
    (state: RootState) => state.orderInput,
  );

  const [localQuery, setLocalQuery] = useState('');
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (localQuery.length > 2 && !!selectedIssuer) {
        dispatch(setQuery(localQuery));
        handleSearch();
      } else {
        dispatch(setMaterialOptions([]));
      }
    }, SEARCH_DEBOUNCE_TIME);

    return () => clearTimeout(delayDebounceFn);
  }, [localQuery]);

  const searchByPlaceHolder = {
    [SearchByMaterial.CUSTOMER_MATERIAL_CODE]: Strings.stepTwo.searchPlaceholderCode,
    [SearchByMaterial.SALES_DOCUMENT_ITEM_TEXT]: Strings.stepTwo.searchPlaceholderText,
  };

  const handleSearch = () => {
    dispatch(setQuery(localQuery));
    dispatch(getMaterialsThunk());
  };

  const onBlur = () =>
    setTimeout(() => {
      if (materialFieldID === tempID) {
        dispatch(setOpenMaterials(false));
      }
    }, 500);

  const onFocus = () => {
    dispatch(setMaterialFieldID(tempID));
    dispatch(setOpenMaterials(true));
  };

  const onKeyPress = event => {
    if (event.charCode === 13 && !!selectedIssuer) {
      handleSearch();
    }
  };

  const onValueChange = value => setLocalQuery(value.toString());

  return (
    <SearchMaterialWrapper hasError={hasError}>
      <FlexRow>
        <TextField
          testid='order-input-search-material-field'
          disabled={!searchBy}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          onValueChange={onValueChange}
          placeholder={searchByPlaceHolder[searchBy]}
          hasButton
        >
          <SearchMaterialButton tempID={tempID} handleSearch={handleSearch} />
        </TextField>
      </FlexRow>
      <MaterialOptions
        loading={materialFieldID === tempID && loadingMaterials}
        open={materialFieldID === tempID && openMaterials}
        tempID={tempID}
        onClick={onClick}
      />
    </SearchMaterialWrapper>
  );
};
