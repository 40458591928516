import React from 'react';
import { InStockOrderItem } from '@app/models';
import { FlexRow } from '@atomic/obj.box';
import { FinishStatusWrapper } from './finish-status-cell.style';

interface FinishStatusCellRendererProps {
  data: InStockOrderItem;
}

const finishStatusProps = {
  ACABADO: { value: 'Acabado', color: '#49106C', bgColor: '#E6E5FF' },
  BRUTO: { value: 'Bruto', color: '#6C2C10', bgColor: '#FFE4D0' },
};

export const FinishStatusCellRenderer: React.FC<FinishStatusCellRendererProps> = ({ data }) => {
  const finishStatus = finishStatusProps[data.finish];

  if (finishStatus) {
    return (
      <FlexRow hAlign='center' vAlign='center'>
        <FinishStatusWrapper bgColor={finishStatus.bgColor} color={finishStatus.color}>
          {finishStatus.value}
        </FinishStatusWrapper>
      </FlexRow>
    );
  }
  return null;
};
