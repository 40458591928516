import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { H2 } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { ConfigColumnsModalStrings, Strings } from '../config-columns-modal.string';

export const ConfigColumnsModalHeader: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const userLanguage = userInfo.language;
  const strings: ConfigColumnsModalStrings = Strings[userLanguage];

  return (
    <FlexColumn>
      <H2>{strings.title}</H2>
    </FlexColumn>
  );
};
