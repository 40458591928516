import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from 'react-styled-flexboxgrid';
import { RootState } from '@app/core/redux/store';
import { setOpenScheduleModal } from '@app/providers/navigation/navigation.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { ScheduleModalColumns } from './partials/schedule-modal-columns/schedule-modal-columns';
import { ScheduleModalForm } from './partials/schedule-modal-form/schedule-modal-form';
import { ScheduleModalList } from './partials/schedule-modal-list/schedule-modal-list.component';
import { ScheduleModalSuccess } from './partials/schedule-modal-success/schedule-modal-success';
import { CUSTOM_MODAL_WIDTH, SUCCESS_PHASE_CUSTOM_MODAL_WIDTH, SchedulePhase } from './schedule.constants';
import { strings } from './schedule.strings';

const schedulePhaseComponent = {
  [SchedulePhase.List]: <ScheduleModalList />,
  [SchedulePhase.Form]: <ScheduleModalForm />,
  [SchedulePhase.SelectColumns]: <ScheduleModalColumns />,
  [SchedulePhase.Success]: <ScheduleModalSuccess />,
  [SchedulePhase.SuccessDelete]: <ScheduleModalSuccess showDeleteMessage />,
};

export const ScheduleModalComponent = () => {
  const { openBPsModal, openScheduleModal } = useSelector((state: RootState) => state.navigation);
  const { schedulePhase } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();
  const isSuccessPhase = schedulePhase === SchedulePhase.Success || schedulePhase === SchedulePhase.SuccessDelete;
  const customWidth = isSuccessPhase ? SUCCESS_PHASE_CUSTOM_MODAL_WIDTH : CUSTOM_MODAL_WIDTH;

  return (
    <div style={openBPsModal ? { display: 'none' } : null}>
      <Modal customWidth={customWidth} opened={openScheduleModal} onClose={() => dispatch(setOpenScheduleModal(false))}>
        <Grid fluid>
          <FlexRow vAlign='center'>
            {schedulePhase !== SchedulePhase.Success && (
              <H2>
                <FlexRow>
                  <FlexRow>{strings.title}</FlexRow>
                  <FlexRow ml={Spacing.Small}>
                    <FaIcon.Custom icon='paper-plane' />
                  </FlexRow>
                </FlexRow>
              </H2>
            )}
          </FlexRow>
          {schedulePhaseComponent[schedulePhase]}
        </Grid>
      </Modal>
    </div>
  );
};
