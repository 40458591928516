import { Column, GridReadyEvent } from 'ag-grid-community';
import { LayoutTabs } from '@app/models/layout.model';
import { IFinanceStrings, Strings } from '@app/modules/finance/finance.string';
import {
  IOrderTabOpenStrings,
  Strings as StringsMI,
} from '@app/modules/order/order-tabs/MI/tab-open/order-tab-open.string';
import { MarketType } from '@app/providers';
import { gridValueToCurrency, toCurrency } from '@atomic/obj.utils';
import {
  ACTIVATE_CRITICAL_COLUMN,
  BOOLEAN_COLUMNS,
  CHECKBOX_SELECTION_COLUMN,
  DEFAULT_TRUE_VALUE,
  DELIVERY_STATUS_COLUMN,
  QTY_ORDER_COLUMNS,
  QTY_STOCK_COLUMNS,
  QTY_BILLED_COLUMNS,
  AMOUNT_COLUMN,
  AMOUNT_BRANCH_COLUMN,
  BRL_CURRENCY_TAG,
  USD_CURRENCY_TAG,
  FINANCE_STATUS_COMPENSATION_COLUMN,
  FINANCE_STATUS_COLUMN,
  SIZE_IN_COLUMN,
  OPEN_MI_DATE_COLUMNS,
  OPEN_ME_DATE_COLUMNS,
  BILLED_MI_DATE_COLUMNS,
  FRONTIER_ME_DATE_COLUMNS,
  SHIPPED_ME_DATE_COLUMNS,
  STOCK_MI_DATE_COLUMNS,
  FINANCE_MI_DATE_COLUMNS,
  FINANCE_ME_DATE_COLUMNS,
  OPEN_ME_MARITIME_DATE_COLUMNS,
  SHIPPED_ME_MARITIME_DATE_COLUMNS,
  HARBOR_ME_DATE_COLUMNS,
} from '../grid-download.constants';
import { IDisplayedColumn } from '../grid-download.interface';

interface IRowsToExcellDataParams {
  gridEvent: GridReadyEvent;
  tabName: string;
  currentMarket: string;
  language: string;
}

interface IDisplayedColumnsParams {
  field: string;
  headerName: string;
}

export const mapRowsToExcellData = ({ gridEvent, tabName, currentMarket, language }: IRowsToExcellDataParams) => {
  const rows: any[] = [];
  let displayedColumns: IDisplayedColumn[] = [];
  let rowMap: { [fieldName: string]: any };
  const orderedDisplayedColumns = [];

  if (gridEvent && gridEvent?.columnApi) {
    const columns = gridEvent?.columnApi?.getColumns();

    const allDisplayedColumns = gridEvent?.columnApi.getAllDisplayedColumns();

    allDisplayedColumns.forEach(displayedColumn => {
      orderedDisplayedColumns.push(displayedColumn.getColId());
    });

    displayedColumns = getVisibleColumns(columns, tabName, orderedDisplayedColumns);
  }

  gridEvent?.api?.forEachNodeAfterFilterAndSort(node => {
    rowMap = {};

    displayedColumns.forEach(displayedCol => {
      const valueAux = handleValue(displayedCol.field, node.data[displayedCol.field], currentMarket, language, tabName);
      rowMap[displayedCol.headerName] = valueAux;
    });

    if (Object.keys(rowMap).length) {
      rows.push(rowMap);
    }
  });

  if (!rows.length) {
    rowMap = {};

    displayedColumns.forEach(displayedCol => {
      rowMap[displayedCol.headerName] = null;
    });

    if (Object.keys(rowMap).length) {
      rows.push(rowMap);
    }
  }

  return { rows, header: getHeader(displayedColumns) };
};

export const getVisibleColumns = (columns: Column[], tabName: string, orderedDisplayedColumns: string[]) => {
  const visibleColumns: IDisplayedColumn[] = [];

  columns.forEach(col => {
    if (
      (col.isVisible() && col.getColId() !== CHECKBOX_SELECTION_COLUMN) ||
      (col.getColId() === ACTIVATE_CRITICAL_COLUMN && tabName === LayoutTabs.OpenMI)
    ) {
      const colDefinition = col.getColDef();
      visibleColumns.push({ field: colDefinition.field, headerName: colDefinition.headerName });
    }
  });

  return sortColumnsByList(visibleColumns, orderedDisplayedColumns, tabName);
};

export const sortColumnsByList = (visibleColumns, orderedDisplayedColumns, tabName: string) => {
  const columns = [];

  orderedDisplayedColumns.forEach(orderedDisplayedColumn => {
    const auxCol = visibleColumns.find(visibleColumn => visibleColumn.field === orderedDisplayedColumn);
    if (auxCol) {
      columns.push(auxCol);
    }
  });

  const activateCriticalCol = visibleColumns.find(visibleColumn => visibleColumn.field === ACTIVATE_CRITICAL_COLUMN);

  if (activateCriticalCol && tabName === LayoutTabs.OpenMI) {
    columns.push(activateCriticalCol);
  }

  return columns;
};

// eslint-disable-next-line complexity
export const handleValue = (column: string, value: any, currentMarket: string, language: string, tabName: string) => {
  if (column === DELIVERY_STATUS_COLUMN && currentMarket === MarketType.Internal) {
    return getMIDeliveryStatus(value, language);
  }

  if (BOOLEAN_COLUMNS.includes(column)) {
    return getDefaultValueOrEmpty(value);
  }

  if (QTY_ORDER_COLUMNS.includes(column) || QTY_STOCK_COLUMNS.includes(column) || QTY_BILLED_COLUMNS.includes(column)) {
    return getQtyValue(value);
  }

  if (column === SIZE_IN_COLUMN) {
    return getDiameterValue(value, language);
  }

  if (
    (tabName === LayoutTabs.FinanceMI && column === AMOUNT_COLUMN) ||
    (tabName === LayoutTabs.FinanceME && column === AMOUNT_BRANCH_COLUMN)
  ) {
    return getAmountValue(value, language, currentMarket);
  }

  if (
    OPEN_MI_DATE_COLUMNS.includes(column) ||
    OPEN_ME_DATE_COLUMNS.includes(column) ||
    BILLED_MI_DATE_COLUMNS.includes(column) ||
    FRONTIER_ME_DATE_COLUMNS.includes(column) ||
    SHIPPED_ME_DATE_COLUMNS.includes(column) ||
    STOCK_MI_DATE_COLUMNS.includes(column) ||
    FINANCE_MI_DATE_COLUMNS.includes(column) ||
    FINANCE_ME_DATE_COLUMNS.includes(column) ||
    OPEN_ME_MARITIME_DATE_COLUMNS.includes(column) ||
    SHIPPED_ME_MARITIME_DATE_COLUMNS.includes(column) ||
    HARBOR_ME_DATE_COLUMNS.includes(column)
  ) {
    return getDateValue(value, language);
  }

  if (
    (tabName === LayoutTabs.FinanceMI && column === FINANCE_STATUS_COMPENSATION_COLUMN) ||
    (tabName === LayoutTabs.FinanceME && column === FINANCE_STATUS_COLUMN)
  ) {
    return getFinanceStatus(value, language);
  }

  return value;
};

const getMIDeliveryStatus = (value: any, language: string) => {
  const strings: IOrderTabOpenStrings = StringsMI[language];
  return strings?.deliveryStatus[value];
};

const getDefaultValueOrEmpty = value => {
  if (value) {
    return DEFAULT_TRUE_VALUE;
  } else {
    return '';
  }
};

const getQtyValue = value => {
  if (value) {
    return parseFloat(value);
  } else {
    return value;
  }
};

const getDiameterValue = (value, language) => {
  if (!value) {
    return value;
  }

  if (language !== 'in' && value.toString().indexOf('.') > -1) {
    return value?.toFixed(4)?.replace('.', ',');
  } else {
    return parseFloat(value)
      ?.toFixed(4)
      ?.replace(',', '.');
  }
};

const getAmountValue = (value, language: string, currentMarket: string) => {
  if (currentMarket === MarketType.Internal) {
    return toCurrency(value?.toString(), BRL_CURRENCY_TAG);
  } else {
    return gridValueToCurrency(value?.toString(), language === 'in' ? USD_CURRENCY_TAG : BRL_CURRENCY_TAG);
  }
};

const getFinanceStatus = (value, language: string) => {
  const financeStrings: IFinanceStrings = Strings[language];

  switch (value) {
    case 'PAID':
      return financeStrings?.paid;
    case 'EXPIRED':
      return financeStrings?.expired;
    case 'NOT EXPIRED':
      return financeStrings?.notExpired;
    default:
      return value;
  }
};

const getDateValue = (value, language: string) => {
  if (!value) {
    return value;
  }

  if (language === 'in') {
    return getDate(value, true);
  } else {
    return getDate(value, false);
  }
};

const getHeader = (columns: IDisplayedColumnsParams[]): string[] => {
  const header: string[] = [];

  columns.forEach(col => {
    header.push(col.headerName);
  });

  return header;
};

const getDate = (currentDate: string, isUSDate: boolean) => {
  const hasDashSplit = currentDate.indexOf('-') > -1;
  let dateSplit = [];

  if (isUSDate) {
    if (hasDashSplit) {
      dateSplit = currentDate.split('-');
      return `${dateSplit[1]}/${dateSplit[2]}/${dateSplit[0]}`;
    } else {
      dateSplit = currentDate.split('/');
      return `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`;
    }
  } else {
    if (hasDashSplit) {
      dateSplit = currentDate.split('-');
      return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
    } else {
      return currentDate;
    }
  }
};
