import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminSettingsDataSource } from '@app/data/http/admin-settings.datasource';
import { CSATModule } from '@app/modules/admin-settings/admin-settings.modules';
import { SLICE_NAME } from './csat.constants';
import { ICsatState } from './csat.interfaces';

interface IanswerCsatUserParams {
  functionality: CSATModule;
  handleSuccess?: () => void;
}

export const postAnswerCsatUser = createAsyncThunk(
  `${SLICE_NAME}/answerCsatUser`,
  async ({ functionality, handleSuccess }: IanswerCsatUserParams) => {
    return await AdminSettingsDataSource.answerCSATUser({ functionality }).then(() => {
      if (handleSuccess) {
        handleSuccess();
      }
      return functionality;
    });
  },
);

export const addPostAnswerCsatUserReducers = builder => {
  builder.addCase(postAnswerCsatUser.pending, state => {
    state.loadingPost = true;
  });
  builder.addCase(postAnswerCsatUser.fulfilled, (state: ICsatState, action: { payload: string }) => {
    state.loadingPost = false;
    const csatUser = state.csatUser;
    const index = csatUser.findIndex(item => item.functionality === action.payload);

    if (index !== null || index !== undefined) {
      csatUser[index].answer = true;
      state.csatUser = csatUser;
    }
  });
  builder.addCase(postAnswerCsatUser.rejected, state => {
    state.loadingPost = false;
  });
};
