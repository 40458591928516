/* eslint-disable complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { ComplainDataSource } from '@app/data/http/complain.datasource';
import { ComplainClientsData } from '@app/models/complain.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { Dropzone } from '@atomic/atm.dropzone';
import { FaIcon } from '@atomic/atm.fa-icon';
import { RadioField } from '@atomic/atm.radio';
import { SelectField } from '@atomic/atm.select';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Form, FormField, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Modal } from '@atomic/obj.modal';
import { SimpleToolTip } from '../components/SimpleToolTip';
import { TechnicalAnalysisStatusUserList } from '../quote/technical-analysis/status/technical-analysis-user-list.component';
import { useComplainClients } from './hooks/useComplainClients';
import { usePostComplain } from './hooks/usePostComplain';
import { QuoteComplainModalStrings } from './quoteComplainModal.strings';
import { FileFieldWrapper, FileListWrapper, TooltipWrapper } from './quoteComplainModal.style';

const Option = ({ value, name }) => {
  return <option value={value}>{name}</option>;
};
interface PropsForm {
  setOpenComplain: (val: boolean) => void;
  openComplain: boolean;
}

interface FilesProps {
  name: string;
  type: string;
  base64: string;
  size: number;
}

export const QuoteComplainModal = (props: PropsForm) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [showForm, setShowForm] = React.useState(true);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [motivo, setMotivo] = React.useState(null);
  const [justificativa, setJustificativa] = React.useState(null);
  const [showList, setShowList] = React.useState(false);
  const [element, setElement] = React.useState(null);
  const [fieldValue, setFieldValue] = React.useState('');
  const [accountId, setAccountId] = React.useState(null);
  const [formValues, setFormValues] = React.useState({ cnpj: null, emailGAT: null, emailSeller: null, type: null });
  const [files, setFiles] = React.useState<FilesProps[]>([]);
  const [fileAlert, setFileAlert] = React.useState(false);
  const [notasFiscais, setNotasFiscais] = React.useState([]);
  const [modalNotas, setModalNotas] = React.useState(false);
  const [showTooltipNF, setShowTooltipNF] = React.useState(false);
  const [editedNota, setEditedNota] = React.useState({
    N_Documento_de_Faturamento: null,
    N_referencia_NF: null,
    N_Lote_Envolvido: null,
    N_Peso_Envolvido: null,
    N_Skus: null,
    N_Numero_Embarque: null,
    index: null,
  });

  const [valorEnvolvido, setValorEnvolvido] = React.useState(null);
  const [numFieldsNotas, setNumFieldsNotas] = React.useState(['ini']);

  const [marketType, setMarketType] = React.useState('MI');

  const [clienteDeseja, setClienteDeseja] = React.useState<string[]>(
    QuoteComplainModalStrings[userInfo.language].clientedeseja,
  );
  const motivoOptions = QuoteComplainModalStrings[userInfo.language].motivoOptions;
  const justificativaOptions = QuoteComplainModalStrings[userInfo.language].justificativaOptions;
  const priorityOptions = QuoteComplainModalStrings[userInfo.language].priority;
  const destino = QuoteComplainModalStrings[userInfo.language].destino;

  const onErrorClientList = () => {
    show('alert', 'Ocorreu um erro ao buscar a lista de clientes');
  };
  const {
    data: clientList,
    isLoading: loadingComplainClients,
    refetch: refetchClients,
    isRefetching,
  } = useComplainClients({ market: marketType }, onErrorClientList);

  React.useEffect(() => {
    setFieldValue('');
    refetchClients();
    setMotivo(null);
    setNotasFiscais([]);
    setValorEnvolvido(null);
    setEditedNota({
      N_Documento_de_Faturamento: null,
      N_referencia_NF: null,
      N_Lote_Envolvido: null,
      N_Peso_Envolvido: null,
      N_Skus: null,
      N_Numero_Embarque: null,
      index: null,
    });
    if (marketType === 'ME') {
      const optionME = [];
      QuoteComplainModalStrings[userInfo.language].clientedeseja.map(item => {
        if (item === 'Alterar Condição Comercial' || item === 'Crédito/Reembolso') {
          optionME.push(item);
        }
        setClienteDeseja(optionME);
      });
    } else {
      setClienteDeseja(QuoteComplainModalStrings[userInfo.language].clientedeseja);
    }
  }, [marketType]);

  const handleClose = () => {
    setFileAlert(false);
    props.setOpenComplain(false);
  };

  const handleCloseNotas = () => {
    setModalNotas(false);
    setEditedNota({
      N_Documento_de_Faturamento: null,
      N_referencia_NF: null,
      N_Lote_Envolvido: null,
      N_Peso_Envolvido: null,
      N_Skus: null,
      N_Numero_Embarque: null,
      index: null,
    });
  };

  const handleChangeClientWhish = val => {
    const option = typeof val === 'string' ? val : val.target.value;

    const indexValue = clienteDeseja.findIndex(item => item === option);

    setMotivo(indexValue);
  };

  const handleChangeReason = val => {
    const option = typeof val === 'string' ? val : val.target.value;
    const indexValue = motivoOptions[motivo].findIndex(item => item === option);

    setJustificativa([motivo, indexValue]);
  };

  const onSuccessPost = (res: any) => {
    if (res.compositeResponse[1].httpStatusCode === 400) {
      props.setOpenComplain(false);

      let errorMsg = `Erro ao enviar. Tente novamente mais tarde: ${res.compositeResponse[1].body[0].message}`;
      const error = res.compositeResponse[1].body[0].message;
      if (error.includes('not found for field Email')) {
        errorMsg = 'Manifestação não enviada: O email do GAT digitado não está cadastrado no Smart Sales';
      }

      show('alert', errorMsg);
    } else if (res.compositeResponse[1].httpStatusCode === 201) {
      show('success', 'Manifestação aberta com sucesso.');
      if (files.length > 0) {
        files.forEach(file => {
          const payload = {
            PathOnClient: file.name,
            ContentLocation: 'S',
            FirstPublishLocationId: res.compositeResponse[1].body.id,
            VersionData: file.base64,
          };

          postFilesComplain(payload);
        });
      } else {
        setShowForm(false);
        setFiles([]);
      }
    }
    setMarketType('MI');
    setNotasFiscais([]);
    setValorEnvolvido(null);
    setModalNotas(false);
    setEditedNota({
      N_Documento_de_Faturamento: null,
      N_referencia_NF: null,
      N_Lote_Envolvido: null,
      N_Peso_Envolvido: null,
      N_Skus: null,
      N_Numero_Embarque: null,
      index: null,
    });
    setFieldValue('');
  };

  const onErrorPost = (res: any) => {
    if (files.length > 0) {
      files.forEach(file => {
        const payload = {
          PathOnClient: file.name,
          ContentLocation: 'S',
          FirstPublishLocationId: '',
          VersionData: file.base64,
        };

        postFilesComplain(payload);
      });
    }
    show('alert', `Erro ao enviar. Tente novamente mais tarde: ${res}`);
    setFieldValue('');
    setMarketType('MI');
    setFiles([]);
    setNotasFiscais([]);
    setValorEnvolvido(null);
    setModalNotas(false);
    setEditedNota({
      N_Documento_de_Faturamento: null,
      N_referencia_NF: null,
      N_Lote_Envolvido: null,
      N_Peso_Envolvido: null,
      N_Skus: null,
      N_Numero_Embarque: null,
      index: null,
    });
  };

  const onErrorPostFiles = () => {
    show('alert', 'Sua manifestação foi enviada porém ocorreu um erro ao enviar os arquivos. Contate um administrador');
    setFieldValue('');
    setFiles([]);
  };

  let NUM_FILES_SENDED = 0;
  const onSuccessPostFiles = () => {
    NUM_FILES_SENDED++;

    if (NUM_FILES_SENDED === files.length) {
      setFiles([]);
      setShowForm(false);
      NUM_FILES_SENDED = 0;
    }
  };

  const { performRequest: postFilesComplain, loading: loadingFilesComplain } = useLazyRequest<any, void>(
    ComplainDataSource.postFilesComplain,
    onSuccessPostFiles,
    onErrorPostFiles,
  );

  const { mutate: postComplain, isLoading: loadingComplain } = usePostComplain(onSuccessPost, onErrorPost);

  const handleSubmit = async formFields => {
    if (Object.keys(formFields.error).length <= 0) {
      const N_Documento_de_Faturamento = [];
      const N_referencia_NF = [];
      const N_Lote_Envolvido = [];
      const N_Peso_Envolvido = [];
      const N_Skus = [];
      const N_Numero_Embarque = [];

      notasFiscais.forEach(doc => {
        N_Documento_de_Faturamento.push(doc.N_Documento_de_Faturamento ? doc.N_Documento_de_Faturamento : '-');
        N_referencia_NF.push(doc.N_referencia_NF ? doc.N_referencia_NF : '-');
        N_Lote_Envolvido.push(doc.N_Lote_Envolvido ? doc.N_Lote_Envolvido : '-');
        N_Peso_Envolvido.push(doc.N_Peso_Envolvido ? doc.N_Peso_Envolvido : '-');
        N_Skus.push(doc.N_Skus ? doc.N_Skus : '-');
        N_Numero_Embarque.push(doc.N_Numero_Embarque ? doc.N_Numero_Embarque : '-');
      });

      const fields = {
        data: {
          Subject: formFields.data.Subject,
          Description: formFields.data.Description,
          CNPJ__c: formFields.data.CNPJ__c,
          Cliente_Deseja__c: formFields.data.Cliente_Deseja__c,
          Motivo__c: formFields.data.Motivo__c,
          Causa_aparente__c: formFields.data.Causa_aparente__c,

          N_referencia_NF__c: N_referencia_NF.join(),
          N_Documento_de_Faturamento__c: N_Documento_de_Faturamento.join(),
          Lote_Envolvido__c: N_Lote_Envolvido.join(),
          Peso_Envolvido__c: N_Peso_Envolvido.join(),
          SKUs__c: N_Skus.join(),
          Numero_Embarque__c: N_Numero_Embarque.join(),

          Numero_do_tratamento_de_falha__c: formFields.data.Numero_do_tratamento_de_falha__c,
          Destino__c: formFields.data.Destino__c,
          SuppliedEmail: formFields.data.SuppliedEmail,
          SuppliedName: formFields.data.SuppliedName,
          SuppliedPhone: formFields.data.SuppliedPhone,
          Status: 'Aberto',
          Priority: formFields.data.Priority,
          Tipo_NF__c: formFields.data.Tipo_NF__c,
          Origin: 'Gerdau Mais',
          Owner: {
            attributes: {
              type: formValues.type,
            },
            Email: formFields.data.emailGAT,
          },
          Account: {
            Id_Externo__c: accountId,
          },
          Email_do_vendedor__c: formFields.data.Email_do_vendedor__c,
          RecordTypeId: '@{recType.records[0].Id}',
          Formulario_E_Gerdau__c: true,
          Ecasogsb__c: true,
          Usuario_eGerdau__c: userInfo?.email,
          Valor_Envolvido__c: formFields.data.Valor_Envolvido__c
            ? formFields.data.Valor_Envolvido__c.replaceAll('.', '').replace(',', '.')
            : formFields.data.Valor_Envolvido__c,
          Moeda__c: formFields.data.Moeda__c,
        },
        market: marketType === 'MI' ? 'RC' : 'RC_GSB_ME',
      };

      if (accountId) {
        postComplain(fields);
        setAccountId(null);
        setFormValues({ cnpj: null, emailGAT: null, emailSeller: null, type: null });
        setFileAlert(false);
      } else {
        show('alert', 'Selecione uma empresa da lista.');
      }
    }
  };

  const verifySelectedOption = (value: string) => {
    return clientList.find(item => item.name === value.toLocaleUpperCase());
  };

  const handleValueChange = (value: string) => {
    setFieldValue(value);
    const selectedOption = verifySelectedOption(value);

    if (selectedOption) {
      setAccountId(selectedOption.idExternoC);
      setFormValues({
        cnpj: selectedOption.cnpj,
        emailGAT: selectedOption.GATEmail,
        emailSeller: selectedOption.sellerEmail,
        type: selectedOption.type,
      });
    }
  };

  const findUser = value => {
    return value && value.toLowerCase().includes(fieldValue.toLowerCase());
  };

  const handleSelectUser = (value: string) => {
    setElement(null);
    setFieldValue(value);
    element.blur();
    handleBlur();
  };

  const handleFocus = elementFocus => {
    setElement(elementFocus);
    setShowList(true);
  };

  const handleBlur = () => {
    setShowList(false);
  };

  const handleEmpty = () => {
    setFieldValue('');
    setShowList(false);
  };

  const filterList = (clients: ComplainClientsData[]) => {
    let formattedLists = [];
    formattedLists = clients.map(item => item.name);
    formattedLists.sort();

    return formattedLists.filter(findUser);
  };

  const handleFiles = (arqs: File[]) => {
    for (const arq of arqs) {
      const handleReaderLoaded = readerEvt => {
        const binaryString = readerEvt.target.result.replace('data:', '').replace(/^.+,/, '');
        setFiles(prev => [...prev, { name: arq.name, type: arq.type, base64: binaryString, size: arq.size }]);
      };

      const reader = new FileReader();
      reader.onload = handleReaderLoaded.bind(this);
      reader.readAsDataURL(arq);
    }
  };

  const handleRemoveFile = (name: string, size: number) => {
    const filteredFiles = files.filter(file => file.name !== name && file.size !== size);
    setFiles(filteredFiles);
  };

  const handleRemoveField = (field: string) => {
    const elementLeft = numFieldsNotas.filter(el => el !== field);
    setNumFieldsNotas(elementLeft);
  };

  const handleChangeNotasFiscais = (
    doc_Faturamento?: string | number,
    N_referencia?: string | number,
    N_Lote_Envolvido?: string | number,
    N_Peso_Envolvido?: string | number,
    N_Skus?: string | number,
    N_Numero_Embarque?: string | number,
    action?: string,
    index?: number,
  ) => {
    if (action === 'add') {
      setNotasFiscais(prevValue => [
        ...prevValue,
        {
          N_Documento_de_Faturamento: doc_Faturamento,
          N_referencia_NF: N_referencia,
          N_Lote_Envolvido: N_Lote_Envolvido,
          N_Peso_Envolvido: N_Peso_Envolvido,
          N_Skus: N_Skus,
          N_Numero_Embarque: N_Numero_Embarque,
        },
      ]);
    } else if (action === 'edit') {
      setEditedNota({
        N_Documento_de_Faturamento: doc_Faturamento,
        N_referencia_NF: N_referencia,
        N_Lote_Envolvido: N_Lote_Envolvido,
        N_Peso_Envolvido: N_Peso_Envolvido,
        N_Skus: N_Skus,
        N_Numero_Embarque: N_Numero_Embarque,
        index: index,
      });
      setModalNotas(true);
    } else {
      const filteredNotas = notasFiscais.filter((_, indexEl) => indexEl !== index);
      setNotasFiscais(filteredNotas);
    }
  };

  const CHARS_VERIFY = ['\n', ';', ' '];

  const handleSubmitNotas = async formFields => {
    if (!(Object.keys(formFields.error).length > 0)) {
      let doc_Faturamento = formFields.data.doc_Faturamento;
      let N_referencia = formFields.data.N_referencia;
      let Lote_Envolvido = formFields.data.Lote_Envolvido__c;
      let Peso_Envolvido = formFields.data.Peso_Envolvido__c;
      let Skus = formFields.data.SKUs__c;
      let Numero_Embarque = formFields.data.Numero_Embarque__c;

      CHARS_VERIFY.forEach(char => {
        doc_Faturamento = doc_Faturamento && doc_Faturamento.replaceAll(char, '|');
        N_referencia = N_referencia && N_referencia.replaceAll(char, '|');
        Lote_Envolvido = Lote_Envolvido && Lote_Envolvido.replaceAll(char, '|');
        Peso_Envolvido = Peso_Envolvido && Peso_Envolvido.replaceAll(char, '|');

        Skus = Skus && Skus.replaceAll(char, '|');
        Numero_Embarque = Numero_Embarque && Numero_Embarque.replaceAll(char, '|');
      });

      if (formFields.data.action === 'add') {
        doc_Faturamento = doc_Faturamento && doc_Faturamento.split('|');
        N_referencia = N_referencia && N_referencia.split('|');
        Lote_Envolvido = Lote_Envolvido && Lote_Envolvido.split('|');
        Peso_Envolvido =
          Peso_Envolvido &&
          Peso_Envolvido.split('|').map(item =>
            item
              .replaceAll('.', '')
              .replace(',', '.')
              .replace(/[^0-9.,]/g, ''),
          );
        Skus = Skus && Skus.split('|');
        Numero_Embarque = Numero_Embarque && Numero_Embarque.split('|');

        const total_Doc_Faturamento =
          doc_Faturamento !== null && doc_Faturamento !== undefined ? doc_Faturamento.length : 0;
        const total_N_referencia = N_referencia !== null ? N_referencia.length : 0;
        const total_Lote_Envolvido = Lote_Envolvido !== null ? Lote_Envolvido.length : 0;
        const total_Peso_Envolvido = Peso_Envolvido !== null ? Peso_Envolvido.length : 0;
        const total_Skus = Skus !== null && Skus !== undefined ? Skus.length : 0;
        const total_Numero_Embarque =
          Numero_Embarque !== null && Numero_Embarque !== undefined ? Numero_Embarque.length : 0;

        const maxDocs = [
          total_Doc_Faturamento,
          total_N_referencia,
          total_Lote_Envolvido,
          total_Peso_Envolvido,
          total_Skus,
          total_Numero_Embarque,
        ]
          .sort()
          .pop();

        for (let i = 0; i < maxDocs; i++) {
          setNotasFiscais(prevValue => [
            ...prevValue,
            {
              N_Documento_de_Faturamento: doc_Faturamento && doc_Faturamento[i],
              N_referencia_NF: N_referencia && N_referencia[i],
              N_Lote_Envolvido: Lote_Envolvido && Lote_Envolvido[i],
              N_Peso_Envolvido: Peso_Envolvido && Peso_Envolvido[i],
              N_Skus: Skus && Skus[i],
              N_Numero_Embarque: Numero_Embarque && Numero_Embarque[i],
            },
          ]);
        }
      } else if (formFields.data.action === 'edit') {
        notasFiscais[formFields.data.index].N_Documento_de_Faturamento = formFields.data.doc_Faturamento;
        notasFiscais[formFields.data.index].N_referencia_NF = formFields.data.N_referencia;
        notasFiscais[formFields.data.index].N_Lote_Envolvido = formFields.data.Lote_Envolvido__c;
        notasFiscais[formFields.data.index].N_Peso_Envolvido = formFields.data.Peso_Envolvido__c.replaceAll('.', '')
          .replace(',', '.')
          .replace(/[^0-9.,]/g, '');
        notasFiscais[formFields.data.index].N_Skus = formFields.data.SKUs__c;
        notasFiscais[formFields.data.index].N_Numero_Embarque = formFields.data.Numero_Embarque__c;
      }
      setModalNotas(false);
      setEditedNota({
        N_Documento_de_Faturamento: null,
        N_referencia_NF: null,
        N_Lote_Envolvido: null,
        N_Peso_Envolvido: null,
        N_Skus: null,
        N_Numero_Embarque: null,
        index: null,
      });
    }
  };

  const moneyMask = (value: string) => {
    value = value
      .replace('.', '')
      .replace(',', '')
      .replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(parseFloat(value) / 100);

    setValorEnvolvido(result);

    return result;
  };

  return (
    <>
      <Modal opened={props.openComplain} onClose={handleClose} medium preventOverlayClick>
        <Grid fluid>
          <H2>Formulário de Manifestação</H2>
          <VSeparator />
          {showForm ? (
            <Form onSubmit={handleSubmit}>
              <FormField initialValue='00DJ0000003R9Su' name='orgid'>
                <TextField id='orgid' type='hidden' />
              </FormField>

              <FormField initialValue='012J0000002yhso' name='recordType'>
                <TextField id='recordType' type='hidden' />
              </FormField>

              <FormField initialValue='Aberto' name='status'>
                <TextField id='status' type='hidden' />
              </FormField>

              <Row>
                <Col xs={12}>
                  <LoadingState loading={loadingComplainClients || isRefetching}>
                    <Form.Field
                      label={'Mercado'}
                      name='market'
                      onValueChange={value => setMarketType(value)}
                      initialValue={marketType}
                    >
                      <Row>
                        <Col xs={6} sm={2}>
                          <RadioField disabled={loadingComplainClients || isRefetching} id='MI'>
                            MI
                          </RadioField>
                        </Col>
                        <Col xs={6} sm={10}>
                          <RadioField disabled={loadingComplainClients || isRefetching} id='ME'>
                            ME
                          </RadioField>
                        </Col>
                      </Row>
                    </Form.Field>
                    <VSeparator />
                  </LoadingState>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField
                    label={'Assunto *'}
                    name='Subject'
                    validators={[Validators.Required('Este campo é obrigatório')]}
                  >
                    <TextField id='Subject' maxLength={255} />
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormField
                    name='Cliente_Deseja__c'
                    label='O que você deseja? *'
                    onValueChange={handleChangeClientWhish}
                    validators={[Validators.Required('Este campo é obrigatório')]}
                  >
                    <SelectField id='Cliente_Deseja__c'>
                      <Option key='n-c' value='' name='-- Nenhum --' />
                      {clienteDeseja.map((option: string, index: number) => (
                        <Option key={`n-c-${index}`} value={option} name={option} />
                      ))}
                    </SelectField>
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormField
                    name='Motivo__c'
                    label='Motivo *'
                    onValueChange={handleChangeReason}
                    validators={[Validators.Required('Este campo é obrigatório')]}
                  >
                    <SelectField id='Motivo__c'>
                      <Option key='n-m' value='' name='-- Nenhum --' />
                      {motivoOptions[motivo]?.map((option: string, index: number) => (
                        <Option key={`n-m-${index}`} value={option} name={option} />
                      ))}
                    </SelectField>
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormField name='Causa_aparente__c' label='Justificativa'>
                    <SelectField id='Causa_aparente__c'>
                      <Option key='n-j' value='' name='-- Nenhum --' />
                      {justificativa &&
                        justificativaOptions[justificativa[0]][
                          justificativa[1]
                        ].map((option: string, index: number) => (
                          <Option key={`n-j-${index}`} value={option} name={option} />
                        ))}
                    </SelectField>
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <LoadingState loading={loadingComplainClients || isRefetching}>
                    <Form.Field
                      label='Nome da empresa *'
                      name='company'
                      value={fieldValue}
                      onValueChange={handleValueChange}
                      validators={[Validators.Required('Selecione a empresa clicando na lista')]}
                    >
                      <TextField hasButton onFocus={e => handleFocus(e.target)}>
                        <Button kind='link' onClick={() => handleEmpty()}>
                          <FaIcon.Close size='1x' />
                        </Button>
                      </TextField>
                    </Form.Field>
                    <TechnicalAnalysisStatusUserList
                      show={showList}
                      users={clientList && filterList(clientList)}
                      onClose={() => setShowList(false)}
                      onSelect={handleSelectUser}
                      loading={loadingComplainClients}
                    />
                  </LoadingState>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField label={marketType === 'MI' ? 'CNPJ' : 'NIF'} name='CNPJ__c' initialValue={formValues.cnpj}>
                    <TextField id='CNPJ__c' type='tel' readOnly />
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField
                    label='Email do CS *'
                    name='emailGAT'
                    initialValue={formValues.emailGAT}
                    validators={[
                      Validators.Required('Este campo é obrigatório'),
                      Validators.EmailRegex('Email inválido'),
                    ]}
                  >
                    <TextField id='emailGAT' type='email' maxLength={255} />
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField
                    label='Email do vendedor'
                    name='Email_do_vendedor__c'
                    initialValue={formValues.emailSeller}
                    validators={[Validators.EmailRegexWithEmpty('Email inválido')]}
                  >
                    <TextField id='emailSeller' type='email' maxLength={255} />
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField label='Nome do contato cliente' name='SuppliedName'>
                    <TextField id='SuppliedName' maxLength={255} />
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField
                    label='Email do contato cliente'
                    name='SuppliedEmail'
                    validators={[Validators.EmailRegexWithEmpty('Email inválido')]}
                  >
                    <TextField id='SuppliedEmail' type='email' maxLength={255} />
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField label='Telefone do contato cliente' name='SuppliedPhone'>
                    <TextField id='SuppliedPhone' type='tel' />
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField label='Notas fiscais' name='notas_fiscais'>
                    <Button onClick={() => setModalNotas(true)} expanded kind='secondary'>
                      Clique para adicionar notas fiscais
                    </Button>
                  </FormField>
                  <VSeparator />
                  {notasFiscais.length > 0 ? (
                    <FileListWrapper>
                      <Table>
                        <TR bordered>
                          <TD color={Color.Secondary}>
                            <Body>Nº referência NF</Body>
                          </TD>
                          {marketType === 'MI' ? (
                            <TD color={Color.Secondary}>
                              <Body>Nº doc. de faturamento</Body>
                            </TD>
                          ) : (
                            <TD color={Color.Secondary}>
                              <Body>Nº SKUs</Body>
                            </TD>
                          )}

                          <TD color={Color.Secondary}>
                            <Body>Lote Envolvido</Body>
                          </TD>
                          <TD color={Color.Secondary}>
                            <Body>Peso Envolvido (Ton.)</Body>
                          </TD>
                          <TD color={Color.Secondary}>
                            <Body>Nº Embarque</Body>
                          </TD>
                          <TD color={Color.Secondary}></TD>
                        </TR>
                        {notasFiscais.map((el, indexEl) => (
                          <TR bordered key={`${el.N_Documento_de_Faturamento}-${el.N_referencia_NF}-${indexEl}`}>
                            <TD>
                              <Body>
                                {el.N_referencia_NF ? (
                                  el.N_referencia_NF
                                ) : (
                                  <Body bold color={Color.Alert}>
                                    Não informado.
                                  </Body>
                                )}
                              </Body>
                            </TD>
                            {marketType === 'MI' ? (
                              <TD>
                                <Body>
                                  {el.N_Documento_de_Faturamento ? (
                                    el.N_Documento_de_Faturamento
                                  ) : (
                                    <Body bold color={Color.Alert}>
                                      Não informado.
                                    </Body>
                                  )}
                                </Body>
                              </TD>
                            ) : (
                              <TD>
                                <Body>
                                  {el.N_Skus ? (
                                    el.N_Skus
                                  ) : (
                                    <Body bold color={Color.Alert}>
                                      Não informado.
                                    </Body>
                                  )}
                                </Body>
                              </TD>
                            )}

                            <TD>
                              <Body>
                                {el.N_Lote_Envolvido ? (
                                  el.N_Lote_Envolvido
                                ) : (
                                  <Body bold color={Color.Alert}>
                                    Não informado.
                                  </Body>
                                )}
                              </Body>
                            </TD>
                            <TD>
                              <Body>
                                {el.N_Peso_Envolvido ? (
                                  el.N_Peso_Envolvido
                                ) : (
                                  <Body bold color={Color.Alert}>
                                    Não informado.
                                  </Body>
                                )}
                              </Body>
                            </TD>

                            {marketType === 'ME' && (
                              <TD>
                                <Body>
                                  {el.N_Numero_Embarque ? (
                                    el.N_Numero_Embarque
                                  ) : (
                                    <Body bold color={Color.Alert}>
                                      Não informado.
                                    </Body>
                                  )}
                                </Body>
                              </TD>
                            )}
                            <TD>
                              <Hbox hAlign='flex-end'>
                                <Hbox.Item noGrow>
                                  <Button
                                    size='small'
                                    kind='link'
                                    onClick={() =>
                                      handleChangeNotasFiscais(
                                        el.N_Documento_de_Faturamento,
                                        el.N_referencia_NF,
                                        el.N_Lote_Envolvido,
                                        el.N_Peso_Envolvido,
                                        el.N_Skus,
                                        el.N_Numero_Embarque,
                                        'edit',
                                        indexEl,
                                      )
                                    }
                                    title='Editar'
                                  >
                                    <FaIcon.EditUser size='1x' />
                                  </Button>
                                </Hbox.Item>
                                <Hbox.Item noGrow>
                                  <Button
                                    size='small'
                                    kind='link'
                                    onClick={() =>
                                      handleChangeNotasFiscais(
                                        el.N_Documento_de_Faturamento,
                                        el.N_referencia_NF,
                                        el.N_Lote_Envolvido,
                                        el.N_Peso_Envolvido,
                                        el.N_Skus,
                                        el.N_Numero_Embarque,
                                        'delete',
                                        indexEl,
                                      )
                                    }
                                    title='Remover da lista'
                                  >
                                    <FaIcon.Close size='1x' color={Color.Alert} />
                                  </Button>
                                </Hbox.Item>
                              </Hbox>
                            </TD>
                          </TR>
                        ))}
                      </Table>
                    </FileListWrapper>
                  ) : null}
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <TooltipWrapper onMouseOver={() => setShowTooltipNF(true)} onMouseOut={() => setShowTooltipNF(false)}>
                    <SimpleToolTip
                      visible={showTooltipNF}
                      description='Campo para informarmos, em caso de devolução de material a usina, o tipo de NF que será feita a devolução.'
                      align='left'
                      customVerticalAlign={30}
                      customHorizontalAlign={-13}
                      onClick={() => setShowTooltipNF(false)}
                      onClose={() => setShowTooltipNF(false)}
                    />
                    <Button kind='primary'>i</Button>
                  </TooltipWrapper>
                  <FormField name='Tipo_NF__c' label='Tipo NF'>
                    <SelectField id='Tipo_NF__c'>
                      <Option value='' name='-- Selecione --' />
                      <Option value='Gerdau' name='Gerdau' />
                      <Option value='Cliente' name='Cliente' />
                    </SelectField>
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField label='Nº do tratamento de falha TF' name='Numero_do_tratamento_de_falha__c'>
                    <TextField id='Numero_do_tratamento_de_falha__c' maxLength={255} />
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField name='Destino__c' label='Destino'>
                    <SelectField id='Destino__c'>
                      <Option key='des-c' value='' name='-- Nenhum --' />
                      {destino.map((option: string, index: number) => (
                        <Option key={`des-c-${index}`} value={option} name={option} />
                      ))}
                    </SelectField>
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField name='Priority' label='Prioridade' initialValue={'Média'}>
                    <SelectField id='Priority'>
                      {priorityOptions.map((option: string, index: number) => (
                        <Option key={`p-${index}`} value={option} name={option} />
                      ))}
                    </SelectField>
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>
              {marketType === 'ME' && (
                <Row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={6} sm={3}>
                        <FormField initialValue='' name='Moeda__c' label='Moeda'>
                          <SelectField id='Moeda__c'>
                            <Option value='' name='Selecione' />
                            <Option value='Dolar' name='Dolar' />
                            <Option value='Euro' name='Euro' />
                          </SelectField>
                        </FormField>
                      </Col>
                      <Col xs={6} sm={9}>
                        <FormField
                          label='Valor envolvido'
                          name='Valor_Envolvido__c'
                          onValueChange={value => moneyMask(value)}
                          value={valorEnvolvido}
                        >
                          <TextField id='Valor_Envolvido__c' maxLength={255} type='text' />
                        </FormField>
                      </Col>
                    </Row>

                    <VSeparator />
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={12}>
                  <FileFieldWrapper fileAlert={fileAlert}>
                    <FormField label='Deseja anexar um arquivo?' name='anexo'>
                      <Dropzone onDropAccepted={handleFiles} label='Clique aqui para adicionar arquivos' />
                    </FormField>
                    {files.length > 0 ? (
                      <FileListWrapper>
                        <Table>
                          {files.map(arq => (
                            <TR bordered key={`${arq.name}-${arq.size}`}>
                              <TD>
                                <Body>{arq.name}</Body>
                              </TD>
                              <TD>
                                <Button
                                  size='small'
                                  kind='link'
                                  onClick={() => handleRemoveFile(arq.name, arq.size)}
                                  title='Remover da lista'
                                >
                                  <FaIcon.Close size='1x' color={Color.Alert} />
                                </Button>
                              </TD>
                            </TR>
                          ))}
                        </Table>
                      </FileListWrapper>
                    ) : null}
                  </FileFieldWrapper>
                  <VSeparator />
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <FormField
                    label='Descrição do motivo *'
                    name='Description'
                    validators={[Validators.Required('Este campo é obrigatório')]}
                  >
                    <TextAreaField id='Description' disableResize />
                  </FormField>
                  <VSeparator />
                </Col>
              </Row>

              <FormField initialValue={1} name='external'>
                <TextField id='external' type='hidden' />
              </FormField>
              <Hbox hAlign='flex-start'>
                <Body>* Campos obrigatórios</Body>
              </Hbox>
              <Hbox hAlign='flex-end'>
                <Hbox.Separator />
                <Hbox.Item noGrow>
                  <Button type='submit' loading={loadingComplain || loadingFilesComplain}>
                    Enviar
                  </Button>
                </Hbox.Item>
              </Hbox>
              <VSeparator />
            </Form>
          ) : (
            <Hbox.Item>
              <Hbox.Item noGrow>
                <Body>Sua mensagem foi enviada com sucesso!</Body>
              </Hbox.Item>
              <Separator />

              <Hbox hAlign='flex-end'>
                <Hbox.Separator />
                <Hbox.Item noGrow>
                  <Button onClick={() => setShowForm(true)}>Voltar</Button>
                </Hbox.Item>
              </Hbox>
            </Hbox.Item>
          )}

          <VSeparator />
        </Grid>
      </Modal>

      <Modal opened={modalNotas} onClose={handleCloseNotas} medium={marketType === 'MI'} customHeight='20%' shadow>
        <Grid fluid>
          <H2>Adicionar notas fiscais</H2>
          <Body>Você pode informar mais de um documento inserindo um abaixo do outro.</Body>
          <VSeparator />

          <Form onSubmit={handleSubmitNotas}>
            <FormField
              name='action'
              value={
                editedNota?.N_referencia_NF ||
                editedNota?.N_Documento_de_Faturamento ||
                editedNota?.N_Skus ||
                editedNota?.N_Peso_Envolvido ||
                editedNota?.N_Lote_Envolvido
                  ? 'edit'
                  : 'add'
              }
            >
              <TextField hidden id='action' />
            </FormField>
            <FormField name='index' value={editedNota?.index}>
              <TextField hidden id='index' />
            </FormField>

            <FieldsNotas marketType={marketType} editedNota={editedNota} handleRemoveField={handleRemoveField} />

            <Hbox hAlign='flex-end'>
              <Hbox.Item noGrow>
                <Button type='submit' loading={loadingComplain || loadingFilesComplain}>
                  {editedNota?.N_referencia_NF ||
                  editedNota?.N_Documento_de_Faturamento ||
                  editedNota?.N_Skus ||
                  editedNota?.N_Peso_Envolvido ||
                  editedNota?.N_Lote_Envolvido
                    ? 'Editar'
                    : 'Adicionar'}
                </Button>
              </Hbox.Item>
            </Hbox>
            <VSeparator />
          </Form>
          <VSeparator />
        </Grid>
      </Modal>
    </>
  );
};

export const FieldsNotas = props => {
  return (
    <Row>
      <Col xs={3}>
        <FormField label='Nº referência NF' name={'N_referencia'} initialValue={props.editedNota?.N_referencia_NF}>
          <TextAreaField id={`N_referencia-${props.index}`} />
        </FormField>
        <VSeparator />
      </Col>

      {props.marketType === 'MI' ? (
        <Col xs={3}>
          <FormField
            label='Nº doc. de faturamento'
            name={'doc_Faturamento'}
            initialValue={props.editedNota?.N_Documento_de_Faturamento}
          >
            <TextAreaField id={`doc_Faturamento-${props.index}`} />
          </FormField>
          <VSeparator />
        </Col>
      ) : (
        <Col xs={3}>
          <FormField label='SKUs' name={'SKUs__c'} initialValue={props.editedNota?.N_Skus}>
            <TextAreaField id='SKUs__c' />
          </FormField>
          <VSeparator />
        </Col>
      )}

      <Col xs={props.marketType === 'MI' ? 3 : 2}>
        <FormField label='Lote envolvido' name={'Lote_Envolvido__c'} initialValue={props.editedNota?.N_Lote_Envolvido}>
          <TextAreaField id={`Lote_Envolvido-${props.index}`} />
        </FormField>
        <VSeparator />
      </Col>

      <Col xs={props.marketType === 'MI' ? 3 : 2}>
        <FormField
          label='Peso Envolvido (Ton.)'
          name={'Peso_Envolvido__c'}
          initialValue={props.editedNota?.N_Peso_Envolvido && props.editedNota.N_Peso_Envolvido.replace('.', ',')}
        >
          <TextAreaField id={`Peso_Envolvido-${props.index}`} />
        </FormField>
        <VSeparator />
      </Col>
      {props.marketType === 'ME' && (
        <Col xs={2}>
          <FormField label='Nº Embarque' name={'Numero_Embarque__c'} initialValue={props.editedNota?.N_Numero_Embarque}>
            <TextAreaField id={`Numero_Embarque-${props.index}`} />
          </FormField>
          <VSeparator />
        </Col>
      )}
    </Row>
  );
};
