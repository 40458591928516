import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { strings } from '../../../schedule.strings';

export const ScheduleModalSuccessBody: React.FC = () => {
  const { editMode } = useSelector((state: RootState) => state.schedule);

  return (
    <>
      <FlexColumn pt={Spacing.XXLarge} pb hAlign='center'>
        <H2>{editMode ? strings.editSuccessMessage : strings.newSuccessMessage}</H2>
        <FlexRow pt pb>
          <Image.ScheduleSuccess />
        </FlexRow>
      </FlexColumn>
      <VSeparator lineVisible />
    </>
  );
};
