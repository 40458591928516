import styled from 'styled-components';
import { Border, Color, Shadow, Spacing } from '@atomic/obj.constants';

export interface FrameStyledProps {
  noShadow?: boolean;
  padded?: boolean;
  color?: string;
  rounded?: boolean;
  height?: string;
  zIndex?: number;
}

export const FrameStyled = styled.div`
  padding: ${(props: FrameStyledProps) => (props.padded ? Spacing.Medium : 0)};
  box-shadow: ${(props: FrameStyledProps) => (props.noShadow ? 0 : Shadow.Small)};
  ${(props: FrameStyledProps) => props.rounded && `border-radius: ${Border.Radius}`};
  background-color: ${(props: FrameStyledProps) => (props.color ? props.color : Color.White)};
  position: relative;
  height: ${(props: FrameStyledProps) => (props.height ? props.height : 'auto')};
  ${(props: FrameStyledProps) => props.zIndex && `z-index: ${props.zIndex}`};
`;
