import * as React from 'react';
import { ClipLoader, MoonLoader, SyncLoader } from 'react-spinners';
import { Color } from '@atomic/obj.constants';

export interface ActivityIndicatorProps {
  type: 'spinner' | 'circle-notch' | 'sync';
  size?: string;
  light?: boolean;
}

const Indicators = {
  spinner: ClipLoader,
  'circle-notch': MoonLoader,
  sync: SyncLoader,
};

export const ActivityIndicator: React.FC<ActivityIndicatorProps> = (props: ActivityIndicatorProps) => {
  const { type } = props;
  const Activity = Indicators[type];
  return <Activity color={Color.Accessory} size={props?.size} />;
};
