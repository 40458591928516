export interface ScheduleModalStrings {
  title: string;
  subtitleOpenOrder: string;
  subtitleStock: string;
  orderOpenTab: string;
  stockStockTab: string;
  rulesDescription: string;
  emptyMessage: string;
  cancelButtonCart: string;
  cancelButtonStock: string;
  cancelButtonCreate: string;
  deleteButton: string;
  deleteSuccessMesssage: string;
  cancelButtonSelectColumns: string;
  newButton: string;
  newSuccessMessage: string;
  editSuccessMessage: string;
  saveButton: string;
  columnSelectionButton: string;
  descriptionName: string;
  columnDescription: string;
  columnOrderDescription: string;
  labelName: string;
  labelSelectBps: string;
  placeholderName: string;
  placeholderSelectBps: string;
  selectBpsButton: string;
  frequencySend: string;
  validationDuplicateMessage: string;
  scheduleType: {
    monthly: string;
    weekly: string;
    daily: string;
  };
  selectDaysButton: string;
  calendarMessage: string;
  calendarRules: string;
  weeklyInstructions: string;
  monthlyinstructions: string;
  daysOfWeek: {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
  };
  sendTo: string;
  emailAddButton: string;
  invalidEmail: string;
  requiredEmailCharacter: string;
  requiredEmailPoint: string;
  emailDuplicated: string;
  makeAnotherSchedule: string;
  returnToOrders: string;
  returnToStock: string;
  returnToList: string;
  phases: {
    scheduleForm: string;
    selectColumns: string;
  };
}

export const strings: ScheduleModalStrings = {
  title: 'Agendamento de envio recorrente de relatório',
  subtitleOpenOrder: 'Carteira / Agendamento',
  subtitleStock: 'Estoque / Agendamento',
  orderOpenTab: 'Carteira',
  stockStockTab: 'Estoque',
  rulesDescription: 'Crie até o máximo de 5 agendamentos',
  emptyMessage: 'Você ainda não possui nenhum agendamento',
  cancelButtonCart: 'Voltar para a carteira',
  cancelButtonStock: 'Voltar para o estoque',
  cancelButtonCreate: 'Voltar',
  deleteButton: 'Deletar',
  deleteSuccessMesssage: 'Seu agendamento foi deletado',
  cancelButtonSelectColumns: 'Voltar para edição do agendamento',
  newButton: 'Criar novo agendamento',
  newSuccessMessage: 'Agendamento programado com sucesso!',
  editSuccessMessage: 'Edição do agendamento feita com sucesso!',
  saveButton: 'Salvar agendamento',
  columnSelectionButton: 'Selecionar Colunas',
  descriptionName: 'Carteira / Agendamento',
  columnDescription: 'Selecione quais colunas gostaria de receber na planilha',
  columnOrderDescription: 'Clique e arraste para ordenar a visualização das colunas',
  labelName: 'Nome do agendamento',
  labelSelectBps: 'Adicionar filiais',
  placeholderName: 'Nome do agendamento',
  placeholderSelectBps: 'Selecionar filiais',
  selectBpsButton: 'Adicionar Clientes',
  frequencySend: 'Frequência de envio',
  validationDuplicateMessage: 'Este nome de agendamento já existe',
  scheduleType: {
    monthly: 'Mensal',
    weekly: 'Semanal',
    daily: 'Diário',
  },
  selectDaysButton: 'Selecionar',
  calendarMessage: 'Selecione um ou mais dias no mês',
  calendarRules: 'Caso o mês não possua os dias 31, 30 ou 29, será encaminhado no dia anterior',
  weeklyInstructions: 'Selecione um ou mais dias na semana',
  monthlyinstructions: 'Selecione um ou mais dias do mês',
  daysOfWeek: {
    0: 'Seg',
    1: 'Ter',
    2: 'Qua',
    3: 'Qui',
    4: 'Sex',
    5: 'Sáb',
    6: 'Dom',
  },
  sendTo: 'Destinatário:',
  invalidEmail: 'Existem emails inválidos para envio.',
  emailAddButton: 'Adicionar email',
  requiredEmailCharacter: 'O email obrigatóriamente deve possuir "@".',
  requiredEmailPoint: 'O email obrigatóriamente deve possuir ".".',
  emailDuplicated: 'Este email já foi adionado.',
  makeAnotherSchedule: 'Fazer outro agendamento',
  returnToOrders: 'Voltar para a carteira',
  returnToStock: 'Voltar para o estoque',
  returnToList: 'Voltar para a lista de agendamentos',
  phases: {
    scheduleForm: 'Agendamento',
    selectColumns: 'Colunas',
  },
};
