import { ColDef, GridReadyEvent } from 'ag-grid-community';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { renderSubtotalField } from '@app/modules/order/grid-utils/grid-subtotal-field-render';
import { removeFilterInput } from '@app/modules/order/grid-utils/utils';
import { OrderTabsHeaderME } from '../../order-tabs-header-ME.component';
import { OrderTabOpenMaritimeComponent } from './order-tab-maritime-open.component';
import { tabName } from './order-tab-maritime-open.constants';
import { filterSelector } from './order-tab-maritime-open.selectors';
import { performRequest } from './order-tab-maritime-open.store';
import { handleFilter, handleFilterCheck, handleMaritmeOpenTabColumn } from './utils/handler-utils';

export const OrderTabOpenMaritime: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const clientBpsSelectedJoined = useSelector(customerIdsJoinedSelector);
  const filter = useSelector(filterSelector);
  const { data, lastUpdate, filteredRows } = useSelector((state: RootState) => state.orderTabOpenMaritime);
  const dispatch = useDispatch<AppThunkDispatch>();

  const gridEvent = useRef<GridReadyEvent>(null);
  const columns: ColDef[] = useSelector(columnsTabLayoutSelector(tabName, userInfo?.language));

  const calculateSubtotals = () =>
    renderSubtotalField({
      tab: tabName,
      itens: filteredRows,
      language: userInfo?.language,
      columnDef: columns,
    });

  useEffect(() => {
    dispatch(performRequest());
  }, [clientBpsSelectedJoined]);

  useEffect(() => {
    handleFilter({ gridEvent, filter, language: userInfo.language, handleFilterCheck });
  }, [filter, data]);

  useEffect(() => {
    calculateSubtotals();
  }, [filteredRows]);

  useEffect(() => {
    removeFilterInput(tabName, 'salesOrderStatus');
  }, [columns]);

  handleMaritmeOpenTabColumn(columns, userInfo?.language);

  return (
    <>
      <OrderTabsHeaderME tabIndex={0} lastUpdate={lastUpdate} />
      <OrderTabOpenMaritimeComponent gridEvent={gridEvent} />
    </>
  );
};
