import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { TruckTrackingDataSource, TruckTrackingDataSourceParams } from '@app/data/http/truck-tracking.datasource';
import { TruckTrackingRequestEvents } from '@app/modules/order/order.analytics';
import { SLICE_NAME } from '../../truck-tracking.constants';

export const getTruckTrackingInfo = createAsyncThunk(
  `${SLICE_NAME}/getTruckTrackingInfo`,
  async (params: TruckTrackingDataSourceParams, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const invoice = state.truckTracking?.truckTrackingInfo?.invoice;

    return TruckTrackingDataSource.getTrackingInformation(params)
      .then(resp => {
        LogAnalytics.success({
          tipo: TruckTrackingRequestEvents.TruckTrackingSuccess,
          identifier: invoice,
        });

        return resp;
      })
      .catch(err => {
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addGetTruckTrackingInfoReducers = builder => {
  builder.addCase(getTruckTrackingInfo.pending, state => {
    state.loading = true;
  });
  builder.addCase(getTruckTrackingInfo.fulfilled, (state, action) => {
    state.generalInformations = action.payload.generalInformations;
    state.mapInformations = action.payload.mapInformations;
    state.trackingUpdateInformations = action.payload.trackingUpdateInformations;
    state.loading = false;
  });
  builder.addCase(getTruckTrackingInfo.rejected, (state, action) => {
    state.error = action.payload?.status ? action.payload.status : 404;
    state.loading = false;
  });
};
