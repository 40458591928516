import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { TabInternalName } from '@app/models/admin.model';
import { preventiveStopOpenedSelector } from '@app/providers/navigation/navigation.selectors';
import { setOpenPreventiveStopModal } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { FlexRow } from '@atomic/obj.box';
import { Modal } from '@atomic/obj.modal';
import { Strings, IAppStrings } from '../../app/app.string';
import { ORDER_PAGE_PATH_NAME, PREVENTIVE_STOP_ANNOUNCEMENT_KEY } from './preventive-stop-modal.constants';
import {
  PreventiveStopModalBody,
  PreventiveStopModalCloseWrapper,
  PreventiveStopModalFooter,
  PreventiveStopModalHeader,
  PreventiveStopModalImage,
  PreventiveStopModalTitleWrapper,
  PreventiveStopModalWrapper,
} from './preventive-stop-modal.style';
import { getPreventiveStopModal } from './store/thunk/get-preventive-stop.thunk';
import { putPreventiveStopModal } from './store/thunk/put-preventive-stop.thunk';

export const PreventiveStopModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const { hasPreventiveStopAnnouncement, preventiveStopData } = useSelector(
    (state: RootState) => state.preventiveStopPersist,
  );
  const strings: IAppStrings = Strings[userInfo?.language];
  const preventiveStopOpened = useSelector(preventiveStopOpenedSelector);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleImage = () => {
    return (
      <PreventiveStopModalImage>
        <img width='600px' src={`assets/img/preventive-stop-modal/${userInfo?.language}-preventive-stop-img.png`} />
      </PreventiveStopModalImage>
    );
  };

  useEffect(() => {
    dispatch(getPreventiveStopModal(PREVENTIVE_STOP_ANNOUNCEMENT_KEY));
  }, []);

  useEffect(() => {
    const hasOrderAccess = userInfo.profile?.tabs?.find(tab => tab.tag === TabInternalName.Order);
    const isOrderPage = window.location.pathname.toString() === ORDER_PAGE_PATH_NAME;

    if (clientBpsSelected?.length && hasPreventiveStopAnnouncement && hasOrderAccess && isOrderPage) {
      dispatch(setOpenPreventiveStopModal(true));
    }
  }, [clientBpsSelected, hasPreventiveStopAnnouncement, userInfo]);

  const handleClose = () => {
    dispatch(putPreventiveStopModal({ key: preventiveStopData?.announcements[0]?.key, email: userInfo?.email }));
    dispatch(setOpenPreventiveStopModal(false));
  };

  return (
    <Modal small opened={preventiveStopOpened}>
      <PreventiveStopModalHeader>
        <PreventiveStopModalTitleWrapper>{strings?.preventiveStopModal.title}</PreventiveStopModalTitleWrapper>
        <PreventiveStopModalCloseWrapper>
          <FlexRow onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
            <Image.NewsModalHeaderCloseButton />
          </FlexRow>
        </PreventiveStopModalCloseWrapper>
      </PreventiveStopModalHeader>

      <PreventiveStopModalWrapper>
        <PreventiveStopModalBody>
          <PreventiveStopModalImage>{handleImage()}</PreventiveStopModalImage>
          <PreventiveStopModalFooter>
            <FlexRow hAlign='center'>
              <Button testid={`${TEST_ID_CLOSE_MODAL}-preventive-stop`} onClick={() => handleClose()}>
                {strings?.preventiveStopModal.close}
              </Button>
            </FlexRow>
          </PreventiveStopModalFooter>
        </PreventiveStopModalBody>
      </PreventiveStopModalWrapper>
    </Modal>
  );
};
