import * as React from 'react';
import {
  RECEIVER_SHIMMER_HEIGHT,
  RECEIVER_SHIMMER_WIDTH,
} from '@app/modules/shipping-request/shipping-request.constants';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { FlexColumn, FlexRow } from '@atomic/obj.box';

export const ShippingRequestReceiverShimmer: React.FC = () => {
  return (
    <FlexColumn pl pb>
      <FlexRow>
        <TextShimmerBoxStyled height={RECEIVER_SHIMMER_HEIGHT} width={RECEIVER_SHIMMER_WIDTH} />
      </FlexRow>
      <FlexRow>
        <TextShimmerBoxStyled height={RECEIVER_SHIMMER_HEIGHT} width={RECEIVER_SHIMMER_WIDTH} />
      </FlexRow>
      <FlexRow>
        <TextShimmerBoxStyled height={RECEIVER_SHIMMER_HEIGHT} width={RECEIVER_SHIMMER_WIDTH} />
      </FlexRow>
    </FlexColumn>
  );
};
