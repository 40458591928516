import { TruckTrackingDataSourceParams } from '@app/data/http/truck-tracking.datasource';
import { ITruckTrackingState, TravelStatus } from '../../truck-tracking.interfaces';
import { getTruckTrackingInfo } from './truck-tracking-information.thunk';
import { getTruckTrackingMap } from './truck-tracking-map.thunk';

export const getTruckTrackingInfoAndMap = (params: TruckTrackingDataSourceParams) => async (dispatch, getState) => {
  await dispatch(getTruckTrackingInfo(params));

  const { mapInformations, generalInformations }: ITruckTrackingState = getState().truckTracking;
  const isFinalizedTravel: boolean =
    generalInformations?.travelStatus === TravelStatus.Done ||
    generalInformations?.travelStatus === TravelStatus.Delivering;

  if (
    mapInformations &&
    mapInformations?.truck?.lat &&
    mapInformations?.truck?.long &&
    mapInformations?.destination?.lat &&
    mapInformations?.destination?.long &&
    mapInformations?.origin?.lat &&
    mapInformations?.origin?.long &&
    !isFinalizedTravel
  ) {
    await dispatch(
      getTruckTrackingMap({
        destLat: mapInformations?.destination?.lat,
        destLong: mapInformations?.destination?.long,
        truckLat: mapInformations?.truck?.lat,
        truckLong: mapInformations?.truck?.long,
        originLat: mapInformations?.origin?.lat,
        originLong: mapInformations?.origin?.long,
      }),
    );
  }
  return;
};
