import { Select, SearchField, Button, Icon } from '@gerdau/hefesto';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AdminBPsFilterOptionsId, ExcelType } from '@app/models/admin.model';
import { getAllBPs } from '@app/modules/admin/store/thunk/bps-get-all.thunk';
import { getBPs } from '@app/modules/admin/store/thunk/bps-get.thunk';
import { LIMIT_ITEMS_PER_PAGE, SEARCH_DEBOUNCE_TIME } from '@app/modules/admin/utils/utils';
import { AdminFilterStyled, AdminSearchStyled } from '../../../../admin.page.style';
import { AdminStrings } from '../../../../admin.string';
import {
  clearAllBPs,
  setBpFilterOption,
  setBpTextQuery,
  setClearBPFilterQuery,
  setSelectedBPFilterOptions,
} from '../../../../store/admin.store';
import { exportToCSV } from '../utils/export-excel';
import { AdminBPList } from './admin-bp-list/admin-bp-list.component';
import { AdminBPPagination } from './admin-bp-pagination/admin-bp-pagination.component';
import { AdminBPsContainer, AdminBPsExportStyled, AdminBPsViewWrapperStyled } from './admin-bps-view.styles';
import { BpFilterOptions } from './utils/utils';

export const AdminBPComponent: React.FC = () => {
  const { bpSearch, corpGroupSelect, salesOrgSelect, allBPs, loadingAllBps } = useSelector(
    (state: RootState) => state.admin,
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = AdminStrings.pt;

  useEffect(() => {
    dispatch(setClearBPFilterQuery(''));
  }, [bpSearch.filterOption]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const query = {
        skip: 0,
        limit: LIMIT_ITEMS_PER_PAGE,
        filterType: bpSearch.filterOption,
        filterValue: bpSearch.textQuery,
      };

      if (bpSearch.selectedOptions?.length) {
        query.filterValue = bpSearch.selectedOptions.join();
      } else if (bpSearch.textQuery?.length > 2) {
        query.filterValue = bpSearch.textQuery;
      } else {
        query.filterValue = '';
      }

      dispatch(getBPs(query));
    }, SEARCH_DEBOUNCE_TIME);

    return () => clearTimeout(delayDebounceFn);
  }, [bpSearch.textQuery, bpSearch.selectedOptions]);

  useEffect(() => {
    if (allBPs.length) {
      exportToCSV(allBPs, strings.bpView.actions.exportFileName, ExcelType.Bps);
      dispatch(clearAllBPs([]));
    }
  }, [allBPs]);

  const handleSearchField = () => {
    switch (bpSearch.filterOption) {
      case AdminBPsFilterOptionsId.CorpGroup:
        return (
          <Select
            isMultipleSelect
            label={strings.common.corpGroupSelect}
            name='bpCorpGroupSelect'
            value={bpSearch.selectedOptions}
            disabled={!bpSearch.filterOption || bpSearch.filterOption !== AdminBPsFilterOptionsId.CorpGroup}
            onSelect={e => dispatch(setSelectedBPFilterOptions(e))}
            options={corpGroupSelect}
          />
        );
      case AdminBPsFilterOptionsId.SalesOrg:
        return (
          <Select
            isMultipleSelect
            label={strings.common.salesOrgSelect}
            name='bpSalesOrgSelect'
            value={bpSearch.selectedOptions}
            disabled={!bpSearch.filterOption || bpSearch.filterOption !== AdminBPsFilterOptionsId.SalesOrg}
            onSelect={e => dispatch(setSelectedBPFilterOptions(e))}
            options={salesOrgSelect}
          />
        );
      default:
        return (
          <SearchField
            label={strings.common.search}
            name='bpSearchField'
            inputValue={bpSearch.textQuery}
            disabled={!bpSearch.filterOption}
            onChange={e => dispatch(setBpTextQuery(e.target.value))}
          />
        );
    }
  };

  const downloadExcel = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(getAllBPs());
  };

  return (
    <AdminBPsContainer>
      <AdminBPsViewWrapperStyled>
        <AdminFilterStyled>
          <Select
            label={strings.common.filter}
            name='bpFilterSelect'
            value={bpSearch.filterOption}
            onSelect={e => dispatch(setBpFilterOption(e))}
            options={BpFilterOptions}
          />
        </AdminFilterStyled>
        <AdminSearchStyled>{handleSearchField()}</AdminSearchStyled>
        <AdminBPsExportStyled>
          <Button
            name='bpExcellExportBtn'
            variant='secondary'
            loading={loadingAllBps}
            icon={<Icon option='XLS' />}
            fullWidth={true}
            onClick={e => {
              downloadExcel(e);
            }}
          >
            {strings.userView.actions.exportButton}
          </Button>
        </AdminBPsExportStyled>
      </AdminBPsViewWrapperStyled>
      <AdminBPList />
      <AdminBPPagination />
    </AdminBPsContainer>
  );
};
