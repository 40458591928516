import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { BodySecondary } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { QuoteItemAdditionalInfoWrapper } from './quote.components.style';

interface QuoteListItemAdditionalInfoProps {
  flow?: string;
  client?: string;
  dimensional?: string;
  plants?: string;
}

export const QuoteListItemAdditionalInfo: React.FC<QuoteListItemAdditionalInfoProps> = props => {
  return (
    <QuoteItemAdditionalInfoWrapper>
      {props.client && (
        <>
          <Hbox.Item noGrow>
            <BodySecondary id='quote_client'>
              <FaIcon.User size='1x' color={Color.Gray} /> {props.client}
            </BodySecondary>
          </Hbox.Item>
          <Hbox.Separator />
        </>
      )}
      {props.dimensional && (
        <>
          <Hbox.Item noGrow>
            <BodySecondary>
              <FaIcon.Measure size='1x' color={Color.Gray} /> {props.dimensional}
            </BodySecondary>
          </Hbox.Item>
          <Hbox.Separator />
        </>
      )}
      {props.flow && (
        <>
          <Hbox.Item noGrow>
            <BodySecondary>
              <FaIcon.Search size='1x' color={Color.Gray} /> {props.flow}
            </BodySecondary>
          </Hbox.Item>
          <Hbox.Separator />
        </>
      )}
      {props?.plants && (
        <Hbox.Item noGrow>
          <BodySecondary>
            <FaIcon.Industry size='1x' color={Color.Gray} /> {props.plants}
          </BodySecondary>
        </Hbox.Item>
      )}
    </QuoteItemAdditionalInfoWrapper>
  );
};
