import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TabIndex } from '@app/models/order.model';
import { SidebarFilters } from '@app/modules/components/side-bar-filters/sidebar-filters.component';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Visibility } from '@atomic/obj.visibility';
import { OrderTabBilled } from './tab-billed/order-tab-billed.container';
import { OrderTabOpen } from './tab-open/order-tab-open.container';
import { OrderTabStock } from './tab-stock/order-tab-stock.container';

export const OrderTabsMIContent: React.FC = () => {
  const { orderTabIndex } = useSelector((state: RootState) => state.order);

  return (
    <FlexRow>
      <SidebarFilters />
      <FlexColumn>
        <Visibility visible={orderTabIndex === TabIndex.Open}>
          <OrderTabOpen />
        </Visibility>
        <Visibility visible={orderTabIndex === TabIndex.Stock}>
          <OrderTabStock />
        </Visibility>
        <Visibility visible={orderTabIndex === TabIndex.Billed}>
          <OrderTabBilled />
        </Visibility>
      </FlexColumn>
    </FlexRow>
  );
};
