import React from 'react';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Caption } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';

const strings = OrderInputStrings.pt.selectItens.stepTwo;

export const MaterialFormInfo: React.FC = () => {
  return (
    <FlexRow data-testid='material-form-info' mt vAlign='center'>
      <FaIcon.FlashInfo color={Color.GrayDark} />
      <Caption ml={Spacing.Small}>
        {strings.materialFormLine1} <br />
        {strings.materialFormLine2} <br />
        {strings.materialFormLine3}
      </Caption>
    </FlexRow>
  );
};
