import { IFilterOption } from './customer-search.interfaces';

export enum FilterOptions {
  BP = 'BP',
  corporateGroup = 'corporateGroup',
  salesOrgs = 'salesOrgs',
  salesGroup = 'salesGroup',
}

export enum MarketOptions {
  MI = 'MI',
  ME = 'ME',
}

export const marketMap = {
  MI: 'internal',
  ME: 'external',
};

export enum SalesOrgs {
  BRSA = 'BRSA',
  BRSI = 'BRSI',
  BRSD = 'BRSD',
  BRSE = 'BRSE',
}

export const salesOrgsMI = [
  { value: 'BRSA', name: 'BRSA - Mobilidade' },
  { value: 'BRSI', name: 'BRSI - Indústria' },
  { value: 'BRSD', name: 'BRSD - Centro de serviço' },
];

export const salesOrgsME = [{ value: 'BRSE', name: 'BRSE - Exportação' }];

const optionsName = {
  BP: 'BP',
  corporateGroup: 'Grupo Cliente',
  salesOrgs: 'Organização de vendas',
  salesGroup: 'Cód. Vendedor(a)',
};

export const optionsMI: IFilterOption[] = [
  { name: optionsName.BP, value: FilterOptions.BP, market: MarketOptions.MI },
  { name: optionsName.corporateGroup, value: FilterOptions.corporateGroup, market: MarketOptions.MI },
  { name: optionsName.salesOrgs, value: FilterOptions.salesOrgs, market: MarketOptions.MI },
  { name: optionsName.salesGroup, value: FilterOptions.salesGroup, market: MarketOptions.MI },
];

export const optionsME: IFilterOption[] = [
  { name: optionsName.BP, value: FilterOptions.BP, market: MarketOptions.ME },
  { name: optionsName.corporateGroup, value: FilterOptions.corporateGroup, market: MarketOptions.ME },
  { name: optionsName.salesOrgs, value: FilterOptions.salesOrgs, market: MarketOptions.ME },
  { name: optionsName.salesGroup, value: FilterOptions.salesGroup, market: MarketOptions.ME },
];
