import { INavigationState } from './navigation.interface';

export const reducers = {
  setReloadOpenDashboard: (state: INavigationState, { payload }) => {
    state.reloadOpenDashboard = payload;
  },
  setReloadConfirmedDashboard: (state: INavigationState, { payload }) => {
    state.reloadConfirmedDashboard = payload;
  },
  setReloadStockDashboard: (state: INavigationState, { payload }) => {
    state.reloadStockDashboard = payload;
  },
  setReloadBilledDashboard: (state: INavigationState, { payload }) => {
    state.reloadBilledDashboard = payload;
  },
  setReloadFinanceDashboard: (state: INavigationState, { payload }) => {
    state.reloadFinanceDashboard = payload;
  },
  setOpenNpsModal: (state: INavigationState, { payload }) => {
    state.openNpsModal = payload;
  },
  setOpenNewsModal: (state: INavigationState, { payload }) => {
    state.openNewsModal = payload;
  },
  setOpenNewsModalHistory: (state: INavigationState, { payload }) => {
    state.openNewsModalHistory = payload;
  },
  setCompanyLogosTooltipVisible: (state: INavigationState, { payload }) => {
    state.companyLogosTooltipVisible = payload;
  },
  setOpenWarningModal: (state: INavigationState, { payload }) => {
    state.openWarningModal = payload;
  },
  setOpenBPsModal: (state: INavigationState, { payload }) => {
    state.openBPsModal = payload;
  },
  setOpenScheduleModal: (state: INavigationState, { payload }) => {
    state.openScheduleModal = payload;
  },
  setOpenHambuguerMenu: (state: INavigationState, { payload }) => {
    state.openHamburguerMenu = payload;
  },
  setOpenMaintenanceModal: (state: INavigationState, { payload }) => {
    state.openMaintenanceModal = payload;
  },
  setOpenPreventiveStopModal: (state: INavigationState, { payload }) => {
    state.openPreventiveStopModal = payload;
  },
  setOpenSidebarFilters: (state: INavigationState, { payload }) => {
    state.openSidebarFilters = payload;
  },
  setOpenFinanceSidebarFilters: (state: INavigationState, { payload }) => {
    state.openFinanceSidebarFilters = payload;
  },
  setOpenErrorHandlerModal: (state: INavigationState, { payload }) => {
    state.openErrorHandlerModal = payload;
  },
};
