import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../order-update.constants';
import { IOrderUpdateState } from '../order-update.interfaces';
import { initialState } from './order-update.initial';
import { reducers } from './order-update.reducers';
import { addUpdateOrderReducers } from './order-update.thunk';

export const orderUpdateSlice = createSlice<IOrderUpdateState, SliceCaseReducers<IOrderUpdateState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addUpdateOrderReducers,
});

export const {
  setShowOrderUpdate,
  setOpenUpdateFeedback,
  setIsCommentModalOpen,
  setFinishModalOpened,
  setGeneralComment,
  setNewQuantity,
  setNewDates,
  setNewMessage,
  setCanceled,
  handleOnboardNextStep,
  handleOnboardDismiss,
  setTableRows,
  removeAlertStatus,
  removeAlertDate,
  removeAlertCancel,
  deleteItem,
  revisionItem,
} = orderUpdateSlice.actions;

export const orderUpdateReducer = orderUpdateSlice.reducer;
