import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface FontAwesomeIconStyledProps {
  clickable?: boolean;
}

export const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  ${(props: FontAwesomeIconStyledProps) =>
    props.clickable &&
    `
    cursor: pointer;
  `}
`;
