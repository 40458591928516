import { createAsyncThunk } from '@reduxjs/toolkit';
import Container from 'typedi';
import { CustomerDataSource } from '@app/data/http';
import { GetCustomerParams } from '@app/data/http/customer-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { Customer } from '@app/models';
import { MarketType } from '@app/providers';
import { confirmSelectedBpsClient, setCurrentMarket } from '../customer-persist.store';

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const fetchCustomersClient = createAsyncThunk(
  'customer-search/fetchCustomersClient',
  async (params: GetCustomerParams, thunkAPI) => {
    const promiseRequestTime: number = new Date().getTime();
    const bps = await CustomerDataSource.getCustomer(params)
      .then((data: Customer[]) => {
        if (data.length) {
          if (data[0].market === MarketType.External) {
            thunkAPI.dispatch(setCurrentMarket(MarketType.External));
          } else {
            thunkAPI.dispatch(setCurrentMarket(MarketType.Internal));
          }
        }

        thunkAPI.dispatch(confirmSelectedBpsClient({ clientBpsSearch: data.map(d => ({ ...d, selected: true })) }));
        return { promiseRequestTime, data };
      })
      .catch(err => {
        if (err.message.includes('401')) {
          authStorageService.logout();
        }
        return err;
      });

    return bps;
  },
);

export const addFetchCustomersClientReducer = builder => {
  builder.addCase(fetchCustomersClient.pending, state => {
    state.currentRequestTime = new Date().getTime();
    state.loading = true;
  });
  builder.addCase(fetchCustomersClient.fulfilled, (state, action) => {
    const payload = action.payload;
    if (payload.promiseRequestTime >= state.currentRequestTime) {
      state.loading = false;
      state.error = false;
      state.clientBpsSearch = payload.data.map(d => ({ ...d, selected: true }));
    }
  });
  builder.addCase(fetchCustomersClient.rejected, (state, action) => {
    state.error = action.payload;
    state.loading = false;
  });
};
