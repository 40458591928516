import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../surrogate.constants';
import { ISurrogateState } from '../surrogate.interfaces';
import { initialState } from './surrogate.initial';
import { reducers } from './surrogate.reducers';
import { addThunkReducers } from './thunks/surrogate.thunks';

export const surrogateSlice = createSlice<ISurrogateState, SliceCaseReducers<ISurrogateState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const {
  reset,
  setEmail,
  setSurrogate,
  setExpires,
  setExpiresFrom,
  setExpiresTo,
  setClearError,
  setClearSurrogateInfo,
  setSuccessMessage,
  removeSurrogate,
  setShowRemoveSurrogate,
  setShowSurrogateInfoModal,
} = surrogateSlice.actions;

export const surrogateReducer = surrogateSlice.reducer;
