import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${Spacing.Medium};
  padding: ${Spacing.Small};
  border: 1px solid ${Color.GrayLight};
  border-radius: 4px;
`;

export const ListFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  gap: ${Spacing.Small};
`;

export const AddMoreButtonWrapper = styled.div`
  margin-top: ${Spacing.Medium};

  button {
    width: 100%;
  }
`;

export const InputFilterWrapper = styled.div`
  display: flex;

  div {
    width: 100%;

    input {
      height: 30px;
      background-color: ${Color.White};
      border-color: ${Color.Gray};
      border-radius: 4px;
    }
  }
`;
