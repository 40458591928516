import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import { AppPath } from '@app/modules/app/route-constants';
import { Badge } from '@app/modules/components/badge/badge.component';
import {
  FINANCE_PAGE_CHECKBOX_FILTER_FIELDS,
  FINANCE_PAGE_DATE_FILTER_FIELDS,
  FINANCE_PAGE_INPUT_FILTER_FIELDS,
} from '@app/modules/components/side-bar-filters/sidebar-filters.constants';
import { ISidebarFiltersStrings, Strings } from '@app/modules/components/side-bar-filters/sidebar-filters.strings';
import {
  removeCheckboxFilters,
  removeDateFilters,
  removeInputFilters,
  resetFinanceFilters,
} from '@app/modules/components/side-bar-filters/store/sidebar-filters.store';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { Button } from '@atomic/atm.button';
import { Label } from '@atomic/atm.typography';
import { FontSize } from '@atomic/obj.constants';
import { ActiveFiltersWrapper, BadgesWrapper } from './active-filters.style';

export const FinanceActiveFilters: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const history = useHistory();
  const tab = history.location.pathname === AppPath.FINANCE ? LayoutTabs.FinanceMI : MItabIndex[orderTabIndex];
  const { confirmedFilters } = useSelector((state: RootState) => state.advancedFilters[tab]);
  const strings: ISidebarFiltersStrings = Strings[userInfo.language];
  const dispatch = useDispatch();

  return (
    <ActiveFiltersWrapper>
      <Label size={FontSize.Small}>{strings.activeFilters}</Label>
      <BadgesWrapper>
        {FINANCE_PAGE_DATE_FILTER_FIELDS.map(key =>
          !!confirmedFilters[key].startDate && !!confirmedFilters[key].startDate ? (
            <Badge
              key={key}
              type='primary'
              text={strings.orderTabsTitles[tab][key]}
              onClick={() => dispatch(removeDateFilters({ tab, filterKey: key }))}
            />
          ) : null,
        )}
        {FINANCE_PAGE_INPUT_FILTER_FIELDS.map(key =>
          confirmedFilters[key].some(filter => !!filter.length) ? (
            <Badge
              key={key}
              type='primary'
              text={strings.orderTabsTitles[tab][key]}
              onClick={() => dispatch(removeInputFilters({ tab, filterKey: key }))}
            />
          ) : null,
        )}
        {FINANCE_PAGE_CHECKBOX_FILTER_FIELDS.map(key =>
          confirmedFilters[key].length ? (
            <Badge
              key={key}
              type='primary'
              text={strings.orderTabsTitles[tab][key]}
              onClick={() => dispatch(removeCheckboxFilters({ tab, filterKey: key }))}
            />
          ) : null,
        )}
      </BadgesWrapper>
      <Button size='small' kind='secondary' onClick={() => dispatch(resetFinanceFilters(tab))}>
        {strings.cleanAll}
      </Button>
    </ActiveFiltersWrapper>
  );
};
