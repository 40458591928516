import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { FileData } from './documentContainer';

interface SearchProps {
  documentType: string;
  initialDocumentList: any;
  setSearchResults: (results: FileData[]) => void;
  strings: any;
}

export const DocumentSearch = (props: SearchProps) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  React.useEffect(() => {
    if (props.initialDocumentList) {
      const list = props.initialDocumentList.data;
      const results = list.filter(doc => doc.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
      props.setSearchResults(results);
    }
  }, [searchTerm, props.initialDocumentList]);

  const searchDocument = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <Row>
      <Col xs={12}>
        <VSeparator />
        <TextField
          placeholder={props.strings[props.documentType].searchBar}
          hasButton
          onValueChange={e => searchDocument(e.toLocaleString())}
        >
          <Button type='button' kind='link'>
            <FaIcon.Search size='1x' />
          </Button>
        </TextField>
      </Col>
    </Row>
  );
};
