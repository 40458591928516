import { NoficationStepKeys, NotificationItem, NotificationStep } from '@app/models/notification-center.model';

export interface NotificationDto {
  notification: NotificationItemDto[];
}

interface NotificationItemDto {
  notificationId: string;
  salesDocument: string;
  salesDocumentItem: string;
  soldToParty: string;
  customerPurchaseOrderNumber: string;
  materialDescription: string;
  steps: NotificationStepDto[];
  read: boolean;
  date: string;
  id: string;
}

interface NotificationStepDto {
  status: string;
  dateUpdated: string;
}

export interface ConfigureNotificationSettingsParams {
  arrived?: boolean;
  billed?: boolean;
  inStock?: boolean;
  orderConfirmation?: boolean;
  sentForDelivery?: boolean;
  shipCreated?: boolean;
  shipmentStarted?: boolean;
  startProduction?: boolean;
  statusChange?: boolean;
  showNotificationConfig?: boolean;
}

export interface NotificationActivationParams {
  items: NotificationActivationItem[];
}

export interface NotificationActivationItem {
  salesDocument: string;
  salesDocumentItem: string;
  soldToPartyCG: string;
}

export interface ShipmentActivationParams {
  items: ShipmentActivationItem[];
}

export interface ShipmentActivationItem {
  salesDocument: string;
  salesDocumentItem: string;
  soldToPartyCG: string;
}

export interface CleanNotificationParams {
  notificationIds: string[];
}

export const mapNotifications = (response: NotificationDto): NotificationItem[] => {
  return response.notification?.map(notification => {
    return {
      aboveTitle: notification.customerPurchaseOrderNumber,
      title: notification.materialDescription,
      subTitle: `${notification.salesDocument}/${notification.salesDocumentItem}`,
      read: notification.read,
      steps: mapSteps(notification.steps),
      id: notification.notificationId,
    };
  });
};

const mapSteps = (steps: NotificationStepDto[]): NotificationStep[] => {
  return steps.map(step => {
    return {
      key: NoficationStepKeys[step.status],
      time: step.dateUpdated,
    };
  });
};
