import styled from 'styled-components';
import { Border, Color, Shadow, Spacing, ZIndex } from '@atomic/obj.constants';

export const PopUpStyled = styled.div`
  position: absolute;
  visibility: ${props => (props.opened ? 'visible' : 'hidden')};
`;

export const PopUpCloseStyled = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${ZIndex.ModalCloseStyled};
  padding: ${Spacing.Large};
  color: ${Color.GrayDark};
  cursor: pointer;
`;

export const PopUpBoxStyled = styled.div`
  position: fixed;
  bottom: 40px;
  left: 80%;
  z-index: ${ZIndex.ModalBoxStyled};
  transform: translate(-50%, 0);
  width: 40%;
  background-color: ${Color.White};
  border-radius: ${Border.Radius};
  box-shadow: ${Shadow.Medium};
`;
