import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import * as React from 'react';
import { IAdminNPSUser } from '@app/models/admin.model';
import { NPS_USER_INVALID_STATUS } from '@app/modules/admin/admin.constants';
import {
  AdminListHeaderSeparator,
  AdminListHeaderWrapper,
  TagHeaderContainer,
} from '@app/modules/admin/admin.page.style';
import { AdminStrings } from '@app/modules/admin/admin.string';
import { Body } from '@atomic/atm.typography';

interface AdminNPSListHeaderProps {
  user: IAdminNPSUser;
}

export const AdminNPSListHeader: React.FC<AdminNPSListHeaderProps> = props => {
  const { name, email, corpGroup, status } = props.user;
  const strings = AdminStrings.pt.nps;

  const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };

  return (
    <AdminListHeaderWrapper>
      <TagHeaderContainer>
        {status !== NPS_USER_INVALID_STATUS ? (
          <Tag variant='success'>{strings.elegible}</Tag>
        ) : (
          <Tag variant='neutral'>{strings.notElegible}</Tag>
        )}
      </TagHeaderContainer>
      <Body bold={true}>{name}</Body>
      <AdminListHeaderSeparator />
      <Body>{email}</Body>
      <AdminListHeaderSeparator />
      <Body>{`${strings.corpGroupLabel} ${corpGroup.filter(onlyUnique)}`}</Body>
    </AdminListHeaderWrapper>
  );
};
