import * as React from 'react';
import { Route } from '@app/core/route';
import { AppPath } from '@app/modules/app/route-constants';
import { useAdminGuard } from './admin.guard';
import { AdminPage } from './admin.page';

export const AdminRouter: React.FC = () => {
  const adminAuthorized = useAdminGuard();

  return (
    <>
      <Route exact path='./' component={AdminPage} authorized={adminAuthorized} redirectPath={AppPath.ORDERS} />
    </>
  );
};
