import * as React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { QuoteAttributes } from '@app/models/quote.model';
import { statusInfo } from '@app/modules/quote/quote.contants';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { Body } from '@atomic/atm.typography';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { EditIconWrapperStyled } from '@atomic/mol.default-list-cell/default-list-cell.component.style';
import { Hbox } from '@atomic/obj.box';
import { LoadingState } from '@atomic/obj.loading-state';
import { attributeEditList, attributeEditStatusList } from './attributes/utils/attributes.constants';
import { NewAttributesResumedState, NewPlantsState } from './technical-analysis.context';
import { TechnicalAnalysisAttributeWrapperStyled } from './technical-analysis.style';

interface TechnicalAnalysisTabProps {
  onHandleAttribute: any;
  recurrentProduct?: boolean;
  editedAttributes?: string[];
  onDragEnd: any;
  selectedAttribute: QuoteAttributes;
  loading: boolean;
  plant: NewPlantsState;
  loadingAttributes?: any;
  loadingTestMethod?: string[];
  list?: NewAttributesResumedState[];
  internalUser: boolean;
  onHandleEditAttribute: (value: any) => void;
  handleGetAttribute: (value: string) => void;
}

export const TechnicalAnalysisAttributesList: React.FC<TechnicalAnalysisTabProps> = props => {
  const editAttribute = attribute => {
    props.onHandleEditAttribute(attribute);
  };

  return (
    <LoadingState loading={props.loading}>
      <Frame>
        {props.list && (
          <TechnicalAnalysisAttributeWrapperStyled id='list'>
            <DragDropContext onDragEnd={props.onDragEnd}>
              <Droppable droppableId='droppable'>
                {provided => (
                  <Hbox.Item {...provided.droppableProps} ref={provided.innerRef}>
                    {props.list.map((attribute, index) => {
                      const hasComments = 0;
                      // const hasComments = props.internalUser
                      //   ? attribute.comments.externalComments.length + attribute.comments.internalComments.length
                      //   : attribute.comments.externalComments.length;
                      return (
                        <Draggable key={attribute.id} draggableId={attribute.key} index={index}>
                          {providedChilds => (
                            <Hbox
                              ref={providedChilds.innerRef}
                              {...providedChilds.draggableProps}
                              {...providedChilds.dragHandleProps}
                            >
                              <DefaultListCell
                                key={attribute.key}
                                hover
                                selected={attribute.key === props.selectedAttribute?.key}
                                iconName={!props?.recurrentProduct && statusInfo[attribute.status].icon}
                                iconColor={statusInfo[attribute.status].color}
                                onClick={() => props.handleGetAttribute(attribute.key)}
                                bg={
                                  attribute.readBy === 'gerdau' &&
                                  !props.editedAttributes?.includes(attribute.key) &&
                                  true
                                }
                                hasComments={hasComments}
                                canEdit={attributeEditList.includes(attribute.key) && props.internalUser}
                              >
                                <>
                                  <Body>
                                    {`${attribute.label}${
                                      attribute.readBy === 'gerdau' && !props.editedAttributes?.includes(attribute.key)
                                        ? ' *'
                                        : ''
                                    }`}
                                  </Body>
                                  {attributeEditList.includes(attribute.key) &&
                                    props.internalUser &&
                                    !attributeEditStatusList.includes(attribute.status) && (
                                      <EditIconWrapperStyled>
                                        <Button
                                          onClick={() => editAttribute(attribute)}
                                          size='small'
                                          kind='link'
                                          title='Editar'
                                        >
                                          <FaIcon.Pen size='1x' />
                                        </Button>
                                      </EditIconWrapperStyled>
                                    )}
                                </>
                              </DefaultListCell>
                            </Hbox>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Hbox.Item>
                )}
              </Droppable>
            </DragDropContext>
          </TechnicalAnalysisAttributeWrapperStyled>
        )}
      </Frame>
    </LoadingState>
  );
};
