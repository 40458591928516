import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AppThunkDispatch } from '@app/core/redux/store';
import {
  FilterOptions,
  MarketOptions,
  salesOrgsME,
} from '@app/modules/components/customer-search/customer-search.constants';
import {
  addSalesOrgsSearch,
  setFilterOptionSearch,
} from '@app/modules/components/customer-search/store/customer-search.store';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { IFilterOption } from '../../../../../../customer-search.interfaces';
import { OptionsWrapper } from './filter-type-drop-down-options.style';
import { FilterTypeDropDownOptionsME } from './partials/filter-type-drop-down-options-ME.component';
import { FilterTypeDropDownOptionsMI } from './partials/filter-type-drop-down-options-MI.component';

interface FilterTypeDropDownOptionsProps {
  setOpen: (value: boolean) => void;
}

export const FilterTypeDropDownOptions: React.FC<FilterTypeDropDownOptionsProps> = ({ setOpen }) => {
  const ref = useRef(null);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current) {
      const isOutsideClick = ref && !ref.current.contains(event.target);

      if (isOutsideClick) {
        setOpen(false);
      }
    }
  };

  useEventListener('click', handleClickOutside);

  const handleOptionSelected = (option: IFilterOption) => {
    dispatch(setFilterOptionSearch(option));
    if (option.value === FilterOptions.salesOrgs && option.market === MarketOptions.ME) {
      dispatch(addSalesOrgsSearch(salesOrgsME[0]));
    }
    setOpen(false);
  };

  return (
    <OptionsWrapper ref={ref}>
      <FilterTypeDropDownOptionsMI handleOptionSelected={handleOptionSelected} />
      <FilterTypeDropDownOptionsME handleOptionSelected={handleOptionSelected} />
    </OptionsWrapper>
  );
};
