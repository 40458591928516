import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { MERoadOrderDataSource } from '@app/data/http';
import { FrontierSalesOrderItem, HanaLanguage } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { defaultInitialState, IOrderTabState } from '../../../order.interfaces';
import { extraReducersConfig, reducers } from '../../../utils';

interface IOrderTabFrontierState extends IOrderTabState {
  data: FrontierSalesOrderItem[];
  filteredRows: FrontierSalesOrderItem[];
}

const SLICE_NAME = 'order-tab-frontier';

export const performRequest = createAsyncThunk(`${SLICE_NAME}/performRequest`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const promiseRequestTime = new Date().getTime();
  const customerIds = customerIdsJoinedSelector(thunkAPI.getState());

  return await MERoadOrderDataSource.getFrontierOrder({
    customerIds,
    language: HanaLanguage[state.auth?.userInfo?.language],
  })
    .then(data => ({ promiseRequestTime, data }))
    .catch(err => {
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const order = createSlice<IOrderTabFrontierState, SliceCaseReducers<IOrderTabFrontierState>>({
  name: SLICE_NAME,
  initialState: defaultInitialState,
  reducers,
  extraReducers: extraReducersConfig(performRequest),
});

export const { setFilteredRows } = order.actions;

export const orderTabFrontierReducer = order.reducer;
