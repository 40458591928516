import { ISchedule, IScheduleClient } from '@app/models/schedule.model';
import { SCHEDULE_TYPE } from '../schedule.constants';
import { IEmailBadge, IScheduleState } from '../schedule.interfaces';

export const requiredFieldsSelector = (state): boolean => {
  const {
    scheduleName,
    clientBpsSchedule,
    selectedEmails,
    time,
    scheduleId,
    schedules,
    selectedDays,
    selectedDaysOfWeek,
    scheduleType,
    currentTab,
  }: IScheduleState = state.schedule;

  const othersSchedules = schedules.filter((schedule: ISchedule) => schedule.id !== scheduleId);
  const tabSchedules = othersSchedules.filter((schedule: ISchedule) => schedule.user_type === currentTab);
  const otherScheduleNames = tabSchedules.map((schedule: ISchedule) => schedule.name);
  const nonDuplicateNames = !otherScheduleNames.includes(scheduleName);
  const hasBpsSelected = clientBpsSchedule.filter(bp => bp.selected).length;
  const hasEmailValidSelect = selectedEmails.filter(badge => !!badge.valid).length;
  const noHasEmailInValidSelect = !selectedEmails.filter(badge => !badge.valid).length;
  const hasSomeDaySelected =
    (!!selectedDays.length && scheduleType === SCHEDULE_TYPE.MONTHLY) ||
    (!!selectedDaysOfWeek.length && scheduleType === SCHEDULE_TYPE.WEEKLY) ||
    scheduleType === SCHEDULE_TYPE.DAILY;

  return !!(
    scheduleName.trim().length &&
    nonDuplicateNames &&
    hasBpsSelected &&
    hasSomeDaySelected &&
    time.length &&
    hasEmailValidSelect &&
    noHasEmailInValidSelect
  );
};

export const hasSomeInvalidEmailSelector = state =>
  !!state.schedule.selectedEmails.find((selectedEmail: IEmailBadge) => !selectedEmail.valid);

export const bpsSelectedSelector = state =>
  state.schedule.clientBpsSchedule.filter((bp: IScheduleClient) => bp.selected);

export const filteredClientBpsScheduleSelector = (state): IScheduleClient[] => {
  const { clientBpsSchedule, querySearch } = state.schedule;

  const filteredBps = clientBpsSchedule.filter(
    bp => bp.code.includes(querySearch) || bp.name.toUpperCase().includes(querySearch.toUpperCase()),
  );

  return filteredBps;
};

export const hasSomeColumnSelected = state => !!state.schedule.layout.find(column => column.checked);

export const scheduleByTabSelector = state =>
  state.schedule.schedules.filter((schedule: ISchedule) => schedule.user_type === state.schedule.currentTab);
