import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { EditConversationParams, TableRowData } from '@app/data/http/quote-params.dto';
import { Attributes } from '@app/models/chat.model';
import { AttributesDetails, QuoteAttributeTypes } from '@app/models/quote.model';
import { Body, InputLabel } from '@atomic/atm.typography';
import { InlineEdit } from '@atomic/mol.inline-edit';
import { QuoteStrings } from '../quote/quote.string';
import { CompositionTable } from './chat-result-composition.component';
import { FileNorm } from './chat-result-file.component';
import { JominyTable } from './chat-result-jominy.component';
import { MacroataqueTable } from './chat-result-macroataque.component';
import { MicroinclusaoTable } from './chat-result-microinclusao.component';
import { AttributeDetailWrapper, ChatResultAttributeWrapper } from './chat-result.component.style';

interface AttributeProps {
  attribute: Attributes;
  onValueChange: (data: EditConversationParams) => void;
  onRemove: (key: string) => void;
  loading: boolean;
  onData: (data: TableRowData[], key: string) => void;
  questions?: any;
}

export const Attribute: React.FC<AttributeProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const handleValueChange = (value: string, unit: string) => {
    props.onValueChange({ attributeKey: props.attribute.key, type: props.attribute.type, value: value, unit: unit });
  };

  const handleRemove = () => {
    props.onRemove(props.attribute.key);
  };

  const questionIndex = props?.questions.length - 1;
  const checkUploadNormQuestion = index => {
    return (
      (props?.questions[index]?.options[0]?.label === 'Utilizar informações' &&
        props?.questions[index]?.options[1]?.label === 'Descartar informações') ||
      (props?.questions[index]?.options[0]?.label === 'Utilizar la información' &&
        props?.questions[index]?.options[1]?.label === 'Descartar la información') ||
      (props?.questions[index]?.options[0]?.label === 'Use information' &&
        props?.questions[index]?.options[1]?.label === 'Discard information') ||
      props?.questions[index]?.options[0]?.label === 'Abrir tabela de composição química.' ||
      props?.questions[index]?.options[0]?.label === 'Abrir tabla de composición química.' ||
      props?.questions[index]?.options[0]?.label === 'Open chemical composition table.'
    );
  };

  const modified =
    props.attribute.modifiedBy === 'USER' ? '' : props.attribute.creation !== 'USER' ? props.attribute.creation : '';
  const Component = GetAttributeTable[props.attribute.key];

  return props.attribute.type === QuoteAttributeTypes.Table ? (
    <Component
      data={props.attribute?.tableData}
      editable={props.attribute.permissions.isEdible}
      delible={props.attribute.permissions.isDelible}
      onData={props.onData}
      disableAction={props.loading}
      onDelete={handleRemove}
      modified={modified}
      disableButton={checkUploadNormQuestion(questionIndex)}
    />
  ) : props.attribute.type === QuoteAttributeTypes.Object && props.attribute?.value ? (
    <FileNorm
      data={props.attribute.value}
      delible={props.attribute.permissions.isDelible}
      disableAction={props.loading}
      onDelete={handleRemove}
      modified={modified}
      disableButton={checkUploadNormQuestion(questionIndex)}
    />
  ) : props.attribute.key === 'LENGTH' ? (
    props.attribute.rangedValue.min !== null && props.attribute.rangedValue.max !== null ? (
      <ChatResultAttributeWrapper modified={modified}>
        <InputLabel>
          {props.attribute.label}
          {modified !== '' &&
            (modified === 'GERDAU' ? (
              <span title={QuoteStrings[userInfo.language].defaultMessageGerdau.tootipChatResult}>
                {QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultGerdauAdvice}
              </span>
            ) : (
              <span>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}</span>
            ))}
        </InputLabel>
        {props.attribute.detail && (
          <AttributeDetailWrapper>
            <Body>{`${AttributesDetails[props.attribute.key]}: ${props.attribute.detail} ${
              props.attribute?.unit
            }`}</Body>
          </AttributeDetailWrapper>
        )}
        <InlineEdit
          editable={props.attribute.permissions?.isEdible}
          deletable={props.attribute.permissions?.isDelible}
          unit={props.attribute?.unit}
          value={props.attribute.value}
          rangedValue={props.attribute.rangedValue}
          type={props.attribute.type}
          attrName={props.attribute.key}
          options={props.attribute?.allowed}
          onCommitValue={handleValueChange}
          onRemove={handleRemove}
          disableAction={props.loading || checkUploadNormQuestion(questionIndex)}
          strings={QuoteStrings[userInfo.language]}
        />
      </ChatResultAttributeWrapper>
    ) : null
  ) : (
    <ChatResultAttributeWrapper modified={modified}>
      <InputLabel>
        {props.attribute.label}
        {modified !== '' &&
          (modified === 'GERDAU' ? (
            <span title={QuoteStrings[userInfo.language].defaultMessageGerdau.tootipChatResult}>
              {QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultGerdauAdvice}
            </span>
          ) : (
            <span>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}</span>
          ))}
      </InputLabel>
      {props.attribute.detail && (
        <AttributeDetailWrapper>
          <Body>{`${AttributesDetails[props.attribute.key]}: ${props.attribute.detail} ${props.attribute?.unit}`}</Body>
        </AttributeDetailWrapper>
      )}
      <InlineEdit
        editable={props.attribute.permissions?.isEdible}
        deletable={props.attribute.permissions?.isDelible}
        unit={props.attribute?.unit}
        value={props.attribute.value}
        rangedValue={props.attribute.rangedValue}
        type={props.attribute.type}
        attrName={props.attribute.key}
        options={props.attribute?.allowed}
        onCommitValue={handleValueChange}
        onRemove={handleRemove}
        disableAction={props.loading || checkUploadNormQuestion(questionIndex)}
        strings={QuoteStrings[userInfo.language]}
      />
    </ChatResultAttributeWrapper>
  );
};

const GetAttributeTable = {
  CHEMICAL_COMPOSITION: CompositionTable,
  MICRO_INCLUSION: MicroinclusaoTable,
  MACRO_ETCH: MacroataqueTable,
  HARDENABILITY: JominyTable,
};
