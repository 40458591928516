import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TableRowData } from '@app/data/http/quote-params.dto';
import { AttributesTableItem } from '@app/models/chat.model';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { QuoteStrings } from '../quote/quote.string';
import { ChatResultAttributeWrapper, ChatResultTable } from './chat-result.component.style';
import { ChatStrings } from './chat.string';
import { MacroataqueField, ModalMacroataque } from './modal-macroataque.component';

interface MacroataqueTableProps {
  data: AttributesTableItem[];
  onData?: (data: TableRowData[], key: string) => void;
  disableAction?: boolean;
  editable?: boolean;
  delible?: boolean;
  onDelete?: () => void;
  modified?: string;
  disableButton?: boolean;
}

export const MacroataqueTable: React.FC<MacroataqueTableProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [edit, setEdit] = React.useState(false);

  const strings = ChatStrings[userInfo.language];

  if (!props.data) {
    return null;
  }

  const handleModalData = (data: TableRowData[], key: string) => {
    props.onData(data, key);
  };

  return (
    <ChatResultAttributeWrapper modified={props.modified}>
      <ChatResultTable>
        <Hbox>
          <Hbox.Item>
            <InputLabel>
              {strings.modalMacroataque.title}
              {props.modified !== '' &&
                (props.modified === 'GERDAU' ? (
                  <span title={QuoteStrings[userInfo.language].defaultMessageGerdau.tootipChatResult}>
                    {QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultGerdauAdvice}
                  </span>
                ) : (
                  <span>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}</span>
                ))}
            </InputLabel>
          </Hbox.Item>
          {props.editable && (
            <Hbox.Item noGrow>
              <Button kind='link' onClick={() => setEdit(true)} disabled={props.disableAction || props.disableButton}>
                <FaIcon.Pen />
              </Button>
            </Hbox.Item>
          )}
          {props.delible && (
            <>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <Button kind='link' onClick={props.onDelete} disabled={props.disableAction || props.disableButton}>
                  <FaIcon.Trash />
                </Button>
              </Hbox.Item>
            </>
          )}
        </Hbox>
        <Table>
          <TR>
            <TD />
            <TD>
              <H4>{strings.modalMacroataque.valueColumn}</H4>
            </TD>
          </TR>
          {props.data?.map((element, index) => (
            <TR key={index}>
              <TD>
                <H4>{element.elements}</H4>
              </TD>
              <TD>{element.value}</TD>
            </TR>
          ))}
        </Table>
        <VSeparator />
        <ModalMacroataque
          onClose={() => setEdit(false)}
          openTableType={edit ? 'macroataque' : ''}
          onSubmit={handleModalData}
          data={{
            [MacroataqueField.MACROATAQUE_S]: props.data[0]?.value ? +props.data[0].value : null,
            [MacroataqueField.MACROATAQUE_C]: props.data[2]?.value ? +props.data[2].value : null,
            [MacroataqueField.MACROATAQUE_R]: props.data[1]?.value ? +props.data[1].value : null,
          }}
        />
      </ChatResultTable>
    </ChatResultAttributeWrapper>
  );
};
