import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const AdminBPsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdminBPInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AdminBPsViewWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px ${Spacing.XLarge};
`;

export const AdminBPsExportStyled = styled.div`
  width: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const AdminBPsUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.Small};
  padding-bottom: ${Spacing.Small};
`;

export const AdminBPsUserDataRow = styled.div`
  padding-top: ${Spacing.Small};
  padding-bottom: ${Spacing.Small};
`;

export const AdminBPsUserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${Spacing.Small};
`;

export const AdminBPsUserData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${Spacing.Small};
`;

export const AdminBPsUserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;

export const AdminBPsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 50%;
  padding-left: ${Spacing.Medium};
`;

export const AdminBPContainerShimmer = styled.div`
  border: 1px solid ${Color.MediumGray};
  border-radius: 4px;
  margin: ${Spacing.XLarge};
  padding: 0px ${Spacing.Small} ${Spacing.Small} 0px;
`;

interface AdminUserItemShimmerProps {
  lastItem: boolean;
}

export const AdminBPItemShimmer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Small} 0px ${Spacing.Small} ${Spacing.Small};
  border-bottom: ${(props: AdminUserItemShimmerProps) => (props.lastItem ? '' : `1px solid ${Color.MediumGray}`)};
`;
