import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { formatMaterialDescription } from '@app/modules/order-input/order-input.utils';
import { Button } from '@atomic/atm.button';
import { Label } from '@atomic/atm.typography';
import { MaterialsWrapper, OptionItens } from './material-options.style';

interface IMaterialOptionsProps {
  onClick: (option) => void;
  tempID: string;
  open: boolean;
  loading: boolean;
}
const strings = OrderInputStrings.pt.selectItens.stepTwo;

export const MaterialOptions: React.FC<IMaterialOptionsProps> = ({ open, loading, onClick }) => {
  const { materialOptions, searchBy } = useSelector((state: RootState) => state.orderInput);

  if (materialOptions.length && open && !loading) {
    return (
      <MaterialsWrapper>
        {materialOptions.map((material: Material, index: number) => (
          <OptionItens onClick={() => onClick(material)} key={index} value={material.materialName}>
            <Label>{formatMaterialDescription(material, searchBy)}</Label>
            <Button kind='link'>{strings.addButton}</Button>
          </OptionItens>
        ))}
      </MaterialsWrapper>
    );
  }

  return null;
};
