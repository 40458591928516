import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Badge } from '@atomic/atm.badge';
import { InternalBadge } from '@atomic/atm.internal-badge/internal-badge.component';
import { quoteBadges } from '../quote.contants';
import { QuoteStrings } from '../quote.string';
import { HeaderCol } from './kanban.components.style';

interface ColsKanbanProps {
  totalItems: number;
  item: any;
}

export const HeaderColumn = (props: ColsKanbanProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = QuoteStrings[userInfo.language].constants.stage.label;
  return (
    <HeaderCol>
      <InternalBadge notification={`${props.totalItems}`}></InternalBadge>
      <Badge color={quoteBadges[props.item.key]?.color}>{strings[props.item.key]}</Badge>
    </HeaderCol>
  );
};
