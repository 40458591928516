import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { UserData } from '@app/models/quote.model';
import { AuthStorageService } from '../storage/auth.storage';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';
import {
  EditPlant,
  EditSequence,
  EditResponsibles,
  GetResponsibles,
  EditBrand,
  mapUser,
} from './quote-plant-params.dto';

const endpoint = {
  ANALYSIS: '/analyses',
  ANALYSIS_PLANT: '/plants',
  ANALYSIS_RESPONSIBLES: '/status/responsibles',
  ANALYSIS_BRAND: '/updateProperty',
};

const user = Container.get(AuthStorageService).getUser();
const defaultHeaders = {
  client_id: CXP_KEY_CLIENT_ID,
  client_secret: CXP_KEY_CLIENT_SECRET,
  'Content-Type': 'application/json',
  Accept: '*/*',
};

export const QuotePlantDataSource = {
  editPlant: (params: EditPlant): Promise<void> => {
    const data = {
      review: params.review,
      selection: params.selection,
      validation: params.validation,
    };
    return Container.get(HttpRequestBuilder)
      .patch(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}`)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .data(data)
      .execute();
  },

  getResponsibles: (params: GetResponsibles): Promise<UserData[]> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_RESPONSIBLES}`)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .interceptors({
        afterSuccess: async (res: UserData[]): Promise<UserData[]> => mapUser(res),
      })
      .execute();
  },

  editResponsibles: (params: EditResponsibles): Promise<UserData[]> => {
    const data = {
      responsible: params.responsible,
      action: params.action,
    };
    return Container.get(HttpRequestBuilder)
      .patch(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_RESPONSIBLES}`)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .data(data)
      .execute();
  },

  editSequence: (params: EditSequence): Promise<void> => {
    const data = { attributes_sequence: params.attributesSequence };
    return Container.get(HttpRequestBuilder)
      .patch(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}`)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .data(data)
      .execute();
  },

  editBrand: (params: EditBrand): Promise<void> => {
    return Container.get(HttpRequestBuilder)
      .put(`${endpoint.ANALYSIS}/${params.id}${endpoint.ANALYSIS_BRAND}`)
      .headers({ ...defaultHeaders, 'sso-access': user.ssoAccess })
      .data({ branding: params.branding })
      .execute();
  },
};
