import styled from 'styled-components';
import { Border, Color } from '@atomic/obj.constants';
import { fieldCss } from '@atomic/obj.form/field.component.styled';

interface TextAreaStyledProps {
  disableResize?: boolean;
  invalid?: boolean;
  hideBorder?: boolean;
}

export const TextAreaStyled = styled.textarea`
  ${fieldCss};
  min-height: 100px;
  max-width: 100%;
  resize: ${(props: TextAreaStyledProps) => props.disableResize && 'none'};
  overflow: hidden;

  ${(props: TextAreaStyledProps) =>
    !props.hideBorder
      ? `
    border-radius: ${Border.Radius};
    border: ${Border.Width} solid;
    border-color: ${props.invalid ? Color.Alert : Color.GrayLight};

    :focus {
      border-color: ${Color.Primary};
      /* Box-shadow instead of border-width, so the text won't dance */
      box-shadow: inset 0 0 0 ${Border.Width} ${Color.Primary};
    }

    @media all and (min-width: 48em) {
      :hover {
        box-shadow: inset 0 0 0 ${Border.Width} ${Color.Gray};
        border-color: ${Color.Gray};
      };
    }
  `
      : 'border: none;'}
`;
