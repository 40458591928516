export interface ICustomerSearchModalHeaderClientStrings {
  placeholder: string;
  titleClient: string;
  validationMessage: string;
}

interface ICustomerSearchModalHeaderClientLanguages {
  pt: ICustomerSearchModalHeaderClientStrings;
  es: ICustomerSearchModalHeaderClientStrings;
  in: ICustomerSearchModalHeaderClientStrings;
}

const pt: ICustomerSearchModalHeaderClientStrings = {
  placeholder: 'Filtre pelo nome ou pelo número do BP',
  titleClient: 'Seleção de Filiais',
  validationMessage: 'Selecione ao menos uma filial.',
};

const es: ICustomerSearchModalHeaderClientStrings = {
  placeholder: 'Filtrar por nombre o número de la filial',
  titleClient: 'Selección de Filial',
  validationMessage: 'Seleccione al menos una filial.',
};

const en: ICustomerSearchModalHeaderClientStrings = {
  placeholder: 'Filter by name or business partner number',
  titleClient: 'Business Partner Selection',
  validationMessage: 'Select at least one business partner.',
};

export const Strings: ICustomerSearchModalHeaderClientLanguages = {
  pt,
  es,
  in: en,
};
