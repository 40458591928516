import React, { ReactElement, useRef, useState } from 'react';
import {
  TOOLTIP_LEFT_POSITION_REF,
  TOOLTIP_TOP_POSITION_REF,
} from '@app/modules/components/side-bar-filters/sidebar-filters.constants';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Label } from '@atomic/atm.typography';
import { Accordion } from '@atomic/mol.accordion';
import { FlexRow } from '@atomic/obj.box';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';
import { AccordionHeaderWrapper } from './filter-accordion.style';

interface IFilterAccordionProps {
  title: string;
  children: ReactElement;
  tooltipInfo?: string;
  overflowVisible?: boolean;
}

export const FilterAccordion: React.FC<IFilterAccordionProps> = ({
  title,
  children,
  tooltipInfo,
  overflowVisible = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const ref = useRef(null);

  const onMouseOverInfoIcon = () => {
    setTooltipVisible(true);
  };

  const onMouseOutInfoIcon = () => {
    setTooltipVisible(false);
  };

  let topTooltip = 0;
  let leftTooltip = 0;

  if (ref?.current) {
    const iconBoundings = ref?.current?.getBoundingClientRect();
    topTooltip = iconBoundings.top;
    leftTooltip = iconBoundings.left;
  }

  const customTop = topTooltip - TOOLTIP_TOP_POSITION_REF;
  const customLeft = leftTooltip - TOOLTIP_LEFT_POSITION_REF;

  return (
    <Accordion
      hideBorder
      disabledHeader
      overflowVisible={overflowVisible && expanded}
      expanded={expanded}
      backgroundColor={Color.White}
    >
      <Accordion.Header>
        <>
          <AccordionHeaderWrapper>
            <FlexRow vAlign='center'>
              <Label size={FontSize.Small} mr={Spacing.Small}>
                {title}:
              </Label>
              <FlexRow ref={ref}>
                {expanded && tooltipInfo && (
                  <FaIcon.FlashInfo
                    size='sm'
                    color={Color.Primary}
                    onMouseOver={onMouseOverInfoIcon}
                    onMouseOut={onMouseOutInfoIcon}
                  />
                )}
              </FlexRow>
            </FlexRow>
            {expanded ? (
              <FaIcon.ChevronUp color={Color.Primary} clickable='true' onClick={() => setExpanded(false)} />
            ) : (
              <FaIcon.ChevronDown color={Color.Primary} clickable='true' onClick={() => setExpanded(true)} />
            )}
          </AccordionHeaderWrapper>
          {tooltipInfo && (
            <CustomizedTooltip
              description={tooltipInfo}
              visible={tooltipVisible}
              onClick={() => setTooltipVisible(true)}
              onClose={() => setTooltipVisible(false)}
              customHeight={customTop}
              customLeftAlign={customLeft}
              customArrowX={45}
              hasFooter={false}
              hasHeader={false}
              hasOffset
            />
          )}
        </>
      </Accordion.Header>
      {children}
    </Accordion>
  );
};
