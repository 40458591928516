import styled from 'styled-components';
import { ButtonStyled } from '@atomic/atm.button/button.component.style';
import { Color, Spacing } from '@atomic/obj.constants';
import { PageItemProps } from './pagination.component';

export const PaginationStyled = styled.div`
  text-align: center;
`;

export const PageStyled = styled(ButtonStyled)`
  border: none;
  background-color: ${(props: PageItemProps) => (props.active ? Color.Primary : 'transparent')};
  color: ${(props: PageItemProps) => (props.active ? Color.White : Color.Primary)};
  content: ${(props: PageItemProps) => (props.first ? 'true' : 'false')};

  & + & {
    margin-left: 0;
  }
`;

export const PageDisabledStyled = styled.label`
  padding: ${Spacing.Medium};
  border-width: 0;
`;
