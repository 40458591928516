import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const AdminPermissionsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px ${Spacing.XLarge};
`;

export const AdminPermissionsTableWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const AdminPermissionsCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${Spacing.Medium} 0px;
  width: 20%;
`;

export const PermissionTitleColCell = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #dad6d6;
  padding: ${Spacing.Medium} 0px;
  min-height: 85px;
`;

export const AdminPermissionsRoleCol = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Medium} 0px;
  width: 10%;
`;

export const PermissionRoleTitleColCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #dad6d6;
  padding: ${Spacing.Medium} 0px;
  min-height: 85px;
`;

export const PermissionRoleColCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #dad6d6;
  padding: ${Spacing.Medium} 0px;
  min-height: 85px;
`;

export const AdminRoleContainerShimmer = styled.div`
  margin: ${Spacing.XLarge};
  padding: 0px ${Spacing.Small} ${Spacing.Small} 0px;
`;

interface AdminUserItemShimmerProps {
  firstItem: boolean;
}

export const AdminRoleLineShimmer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Spacing.Small} 0px ${Spacing.Small} ${Spacing.Small};
  border-bottom: ${(props: AdminUserItemShimmerProps) => (props.firstItem ? '' : `1px solid ${Color.MediumGray}`)};
`;
