export enum NotificationConfigurationKeys {
  arrived = 'arrived',
  billed = 'billed',
  identifier = 'identifier',
  inStock = 'inStock',
  sendShippingStock = 'sendShippingStock',
  orderConfirmation = 'orderConfirmation',
  sentForDelivery = 'sentForDelivery',
  shipCreated = 'shipCreated',
  shipmentStarted = 'shipmentStarted',
  startProduction = 'startProduction',
  statusChange = 'statusChange',
  steps = 'steps',
}

export interface NotificationConfiguration {
  arrived: boolean;
  billed: boolean;
  identifier: string;
  inStock: boolean;
  orderConfirmation: boolean;
  sentForDelivery: boolean;
  shipCreated: boolean;
  shipmentStarted: boolean;
  startProduction: boolean;
  statusChange: boolean;
  sendShippingStock: boolean;
  showNotificationConfig: boolean;
}
