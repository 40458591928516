import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderPriorizationDataSource } from '@app/data/http/order-priorization.datasource';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { SLICE_NAME } from '../../order-priorization.constants';

export const getCriticalItems = createAsyncThunk(`${SLICE_NAME}/getCriticalItem`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const customerIds: string = customerIdsJoinedSelector(state);

  return OrderPriorizationDataSource.getCriticalItems({ customerIds })
    .then(resp => resp)
    .catch(err => err.message);
});

export const addGetCriticalItemsReducers = builder => {
  builder.addCase(getCriticalItems.pending, state => {
    state.loading = true;
  });
  builder.addCase(getCriticalItems.fulfilled, (state, action) => {
    const { payload } = action;
    state.activedCriticalItem = payload;
    state.loading = false;
  });
  builder.addCase(getCriticalItems.rejected, state => {
    state.loading = false;
  });
};
