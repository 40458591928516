import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from 'react-styled-flexboxgrid';
import { Link } from '@app/core/route';
import { BackButton } from '@app/modules/components/back-button/back-button.component';
import { Breadcrumb } from '@atomic/atm.breadcrumb';
import { Frame } from '@atomic/atm.frame';
import { H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { IOrderUpdateStrings, Strings } from '../../order-update.string';
import { RESET_ORDER_UPDATE_ACTION } from '../../store/order-update.reducers';
import { setShowOrderUpdate } from '../../store/order-update.store';
import { HowUpdateOrderStyled } from './order-update-header.style';

export const OrderUpdateHeader: React.FC = () => {
  const strings: IOrderUpdateStrings = Strings.pt;
  const dispatch = useDispatch();
  const helpCenterLink = '/help-center?section=openOrders&subSection=orderUpdate';

  const handleClickBackButton = () => {
    dispatch(setShowOrderUpdate(false));
    dispatch(RESET_ORDER_UPDATE_ACTION);
  };

  const breadCrumbData = strings.page.breadcrumbs.map(breadCrumb => ({
    label: breadCrumb.label,
    to: breadCrumb?.path,
    onLinkClick: handleClickBackButton,
  }));

  return (
    <Frame>
      <Grid fluid>
        <FlexRow pt={Spacing.Small} vAlign='center' hAlign='space-between'>
          <Breadcrumb data={breadCrumbData} />
          <BackButton handleClick={handleClickBackButton} />
        </FlexRow>
        <FlexRow vAlign='baseline'>
          <H2>{strings.page.title}</H2>
          <FlexRow ml>
            <Link to={helpCenterLink}>
              <HowUpdateOrderStyled>{strings.page.help}</HowUpdateOrderStyled>
            </Link>
          </FlexRow>
        </FlexRow>
      </Grid>
    </Frame>
  );
};
