import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { useDownloadAsset } from '@app/modules/quote/technical-analysis/hooks/useDownloadAsset';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputLabel } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { ChatStrings } from '../../chat.string';
import { ChatResultAttributeWrapper } from './chat-result.component.style';

interface FileNormProps {
  attrKey: string;
  attribute: {
    type: string;
    creation: string;
    visible: boolean;
    label: string;
    value: string;
    file: File[];
  };
}

export const FileNorm: React.FC<FileNormProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language].fileNorm;

  const { mutate: handleDownload } = useDownloadAsset();

  return props.attribute?.visible ? (
    <ChatResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.creation === 'NLU'}
    >
      <InputLabel>
        {strings.title}
        {props.attribute?.creation === 'NLU' && (
          <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
        )}
      </InputLabel>
      <Hbox>
        <Hbox.Item hAlign='flex-start'>
          <Button
            kind='link'
            onClick={() =>
              handleDownload({
                filename: props.attribute.file[0].name,
                url: props.attribute.value,
                language: userInfo.language,
                type: 'pdf',
              })
            }
            size='small'
          >
            <Hbox>
              <Hbox.Item noGrow vAlign='center'>
                <FaIcon.PDF small />
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item>{props.attribute.file[0].name}</Hbox.Item>
            </Hbox>
          </Button>
        </Hbox.Item>
      </Hbox>
    </ChatResultAttributeWrapper>
  ) : null;
};
