import React from 'react';
import { TableColumnType } from '@app/models';
import { IOrderUpdateTableData } from '@app/modules/order-update/order-update.interfaces';
import { OrderUpdateCancelButtonCell } from './order-update-cancel-button-cell.component';
import { OrderUpdateDateCell } from './order-update-date-cell.component';
import { OrderUpdateDeleteButtonCell } from './order-update-delete-button-cell.component';
import { OrderUpdateMessageCell } from './order-update-message-cell.component';
import { OrderUpdateQtyCell } from './order-update-qty-cell.component';
import { OrderUpdateTableStaticData } from './order-update-table-static-data.component';
import { OrderUpdateTableStaticText } from './order-update-table-static-text.component';

interface OrderUpdateTableCellProps {
  type: string;
  item: IOrderUpdateTableData;
  column: string;
  index: number;
  disabled: boolean;
}

export const OrderUpdateTableCell: React.FC<OrderUpdateTableCellProps> = ({ type, item, column, index, disabled }) => {
  switch (type) {
    case TableColumnType.StaticText:
      return <OrderUpdateTableStaticText item={item} column={column} />;
    case TableColumnType.StaticDate:
      return <OrderUpdateTableStaticData item={item} column={column} />;
    case TableColumnType.Checkbox:
      return <OrderUpdateCancelButtonCell disabled={disabled} index={index} />;
    case TableColumnType.Date:
      return <OrderUpdateDateCell disabled={disabled} index={index} />;
    case TableColumnType.Number:
      return <OrderUpdateQtyCell disabled={disabled} index={index} />;
    case TableColumnType.Delete:
      return <OrderUpdateDeleteButtonCell disabled={disabled} item={item} />;
    default:
      return <OrderUpdateMessageCell disabled={disabled} index={index} />;
  }
};
