import React from 'react';
import { useSelector } from 'react-redux';
import { isEatonSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import {
  getSuggestionTableColumnsDefinitions,
  TableTDTypes,
} from '@app/modules/shipping-request/shipping-request.constants';
import {
  IShippingRequestColumns,
  IShippingRequestRow,
} from '@app/modules/shipping-request/shipping-request.interfaces';
import { Body } from '@atomic/atm.typography';
import { TD, TR } from '@atomic/mol.table';
import { Color, FontWeight } from '@atomic/obj.constants';
import { SuggestionTH } from './shipping-request-suggestion-table-header.style';

interface IShippingRequestSuggestionTableBodyProps {
  options: IShippingRequestRow[];
}

export const ShippingRequestSuggestionTableHeader: React.FC<IShippingRequestSuggestionTableBodyProps> = ({
  options,
}) => {
  const isEaton = useSelector(isEatonSelector);

  const handleBody = (column: IShippingRequestColumns, textColor: string) => {
    return (
      <Body align='center' centralized color={textColor} bold={FontWeight.Bold}>
        {column.title}
      </Body>
    );
  };

  return (
    <TR>
      {getSuggestionTableColumnsDefinitions(options.length > 1, isEaton).map(
        (column: IShippingRequestColumns, index: number) => {
          const tdColor = column.headerColor || Color.GrayLight;
          const textColor = column.textColor ? column.textColor : Color.Black;

          if (column.type === TableTDTypes.SuggestionCheckBox) {
            return (
              <SuggestionTH key={index} color={tdColor} width={column.width}>
                {handleBody(column, textColor)}
              </SuggestionTH>
            );
          } else {
            return (
              <TD key={index} color={tdColor} width={column.width}>
                {handleBody(column, textColor)}
              </TD>
            );
          }
        },
      )}
    </TR>
  );
};
