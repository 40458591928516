import { Strings as OrderTabDocumentsStrings } from './order-tabs/ME/road/tab-documents/order-tab-documents.string';
import { Strings as OrderTabFrontierStrings } from './order-tabs/ME/road/tab-frontier/order-tab-frontier.string';
import { Strings as OrderTabOpenInternationalStrings } from './order-tabs/ME/road/tab-open-international/order-tab-open-international.string';
import { Strings as OrderTabShippedStrings } from './order-tabs/ME/road/tab-shipped/order-tab-shipped.string';
import { Strings as OrderTabBilledstrings } from './order-tabs/MI/tab-billed/order-tab-billed.string';
import { Strings as OrderTabOpenStrings } from './order-tabs/MI/tab-open/order-tab-open.string';
import { Strings as OrderTabStockStrings } from './order-tabs/MI/tab-stock/order-tab-stock.string';
import { IOrderLanguages, IOrderStrings } from './order.strings.interface';

const pt: IOrderStrings = {
  opened: OrderTabOpenStrings.pt,
  stock: OrderTabStockStrings.pt,
  billed: OrderTabBilledstrings.pt,
  internationalOpened: OrderTabOpenInternationalStrings.pt,
  frontier: OrderTabFrontierStrings.pt,
  shipped: OrderTabShippedStrings.pt,
  documents: OrderTabDocumentsStrings.pt,
  tabsTitle: {
    MI: {
      opened: 'Em aberto',
      stock: 'Em estoque',
      billed: 'Faturado',
    },
    ME: {
      internationalOpened: 'Em aberto',
      frontier: 'Em fronteira',
      shipped: 'Embarcado',
      documents: 'Documentos',
    },
    MEMaritime: {
      Opened: 'Em aberto',
      Shipped: 'Embarcado',
      Harbor: 'Em porto',
    },
  },
  maritimeFlag: 'Carteira maritima',
  tooltips: {
    selection: {
      title: '',
      description: 'Selecione seu pedido através da caixa de seleção.',
    },
    actions: {
      title: '',
      description: 'Após selecionar os pedidos, clique na ação que queira realizar.',
    },
    notificationPanel: {
      title: '',
      description: 'Para visualizar as notificações clique no icone do sino.',
    },
    notificationConfig: {
      title: '',
      description: 'Clique aqui para configurar os tipos de notificação que deseja receber.',
    },
  },
  modal: {
    title: 'Agora você pode acompanhar a entrega pelo Gerdau Mais',
    description: 'Basta clicar no ícone do caminhão e não perder as atualizações do seu pedido',
    confirm: 'Entendi',
  },
  common: {
    new: 'Novo',
    download: 'Download',
    feedback: 'De 1 a 5, o quão você está satisfeito com as informações da sua carteira nessa página?',
    orderUpdateFeedback: 'De 1 a 5, o quão você está satisfeito com a alteração de carteira nessa página?',
    shippingRequestFeedback: 'De 1 a 5, o quão você está satisfeito com a solicitação de remessas nessa página?',
    refresh: 'Atualizar',
    tryAgain: 'Tentar novamente',
    agGrid: {
      rowGroupColumnsEmptyMessage: 'Arraste a coluna aqui para agrupar',
      noRowsToShow: 'Nenhum registro para exibir',
      loadingOoo: 'Carregando...',
      updating: 'Atualizando...',
      pinColumn: 'Travar coluna',
      pinLeft: 'A esquerda',
      pinRight: 'A direita',
      noPin: 'Destravar',
      autosizeThiscolumn: 'Auto-redimensionar esta coluna',
      autosizeAllColumns: 'Auto-redimensionar todas as colunas',
      resetColumns: 'Resetar colunas',
      groupBy: 'Agrupar por',
      expandAll: 'Expandir tudo',
      collapseAll: 'Colapsar tudo',
      copy: 'Copiar',
      copyWithHeaders: 'Copiar com cabeçalhos',
      paste: 'Colar',
      export: 'Exportar',
      csvExport: 'Em .csv',
      excelExport: 'Em  .xlsx',
      excelXmlExport: 'Em .xml',
      shippingRequest: 'Solicitar remessa',
      orderUpdate: 'Alterar Carteira',
      tooltip: {
        title: 'Novo!',
        description: 'Faça sua alteração de carteira!',
        buttonText: 'Ver como',
      },
      subtotal: 'Subtotal:',
    },
    scheduleSend: 'Agendar',
  },
  successSaveLayout: 'Fique tranquilo! A configuração das colunas acabou de ser salva.',
  rowCounter: 'Exibindo',
  rowCounterText: 'registro(s)',
  criticalItensCounter: 'item(s) crítico(s)',
};

const es: IOrderStrings = {
  opened: OrderTabOpenStrings.es,
  stock: OrderTabStockStrings.es,
  billed: OrderTabBilledstrings.es,
  internationalOpened: OrderTabOpenInternationalStrings.es,
  frontier: OrderTabFrontierStrings.es,
  shipped: OrderTabShippedStrings.es,
  documents: OrderTabDocumentsStrings.es,
  tabsTitle: {
    MI: {
      opened: 'Em aberto',
      stock: 'Em estoque',
      billed: 'Faturado',
    },
    ME: {
      internationalOpened: 'En abierto',
      frontier: 'En frontera',
      shipped: 'Abordó',
      documents: 'Documentos',
    },
    MEMaritime: {
      Opened: 'En abierto',
      Shipped: 'Embarcado',
      Harbor: 'En puerto',
    },
  },
  maritimeFlag: 'Cartera maritima',
  tooltips: {
    selection: {
      title: '',
      description: '',
    },
    actions: {
      title: '',
      description: '',
    },
    notificationPanel: {
      title: '',
      description: '',
    },
    notificationConfig: {
      title: '',
      description: '',
    },
  },
  modal: {
    title: '',
    description: '',
    confirm: '',
  },
  common: {
    new: 'Nuevo',
    download: 'Download',
    feedback: 'De 1 al 5, ¿qué tan satisfecho está con la información de su cartera en esta página?',
    orderUpdateFeedback: 'De 1 al 5, ¿qué tan satisfecho está con el cambio de cartera en esta página?',
    shippingRequestFeedback: 'De 1 al 5, ¿qué tan satisfecho está con realizar pedidos de envíos en esta página?',
    refresh: 'Actualizar',
    tryAgain: 'Intentar nuevamente',
    agGrid: {
      rowGroupColumnsEmptyMessage: 'Arrastre la columna aquí para agrupar',
      noRowsToShow: 'No hay registros que mostrar',
      loadingOoo: 'Cargando...',
      updating: 'Actualizando...',
      pinColumn: 'Bloquear columna',
      pinLeft: 'A la izquierda',
      pinRight: 'A la derecha',
      noPin: 'Desbloquear',
      autosizeThiscolumn: 'Cambiar el tamaño de esta columna automáticamente',
      autosizeAllColumns: 'Redimensionar automáticamente todas las columnas',
      resetColumns: 'Restablecer columnas',
      groupBy: 'Agrupar por',
      expandAll: 'Expandir todo',
      collapseAll: 'Desplegar todo',
      copy: 'Copiar',
      copyWithHeaders: 'Copiar con encabezados',
      paste: 'Collar',
      export: 'Exportar',
      csvExport: 'En .csv',
      excelExport: 'En  .xlsx',
      excelXmlExport: 'En .xml',
      shippingRequest: 'Solicitar envío',
      orderUpdate: 'Actualizar orden',
      tooltip: {
        title: '¡Nuevo!',
        description: '¡Haz tu cambio de cartera!',
        buttonText: 'Ver como',
      },
      subtotal: 'Subtotal:',
    },
    scheduleSend: 'Programar',
  },
  successSaveLayout: '¡Quédate tranquilo! La configuración de los altavoces se acaba de guardar.',
  rowCounter: 'Mostrando',
  rowCounterText: 'registro(s)',
  criticalItensCounter: 'elemento(s) crítico(s)',
};

const ing: IOrderStrings = {
  opened: OrderTabOpenStrings.in,
  stock: OrderTabStockStrings.in,
  billed: OrderTabBilledstrings.in,
  internationalOpened: OrderTabOpenInternationalStrings.in,
  frontier: OrderTabFrontierStrings.in,
  shipped: OrderTabShippedStrings.in,
  documents: OrderTabDocumentsStrings.in,
  tabsTitle: {
    MI: {
      opened: 'Open purchase orders',
      stock: 'In stock',
      billed: 'Faturado',
    },
    ME: {
      internationalOpened: 'Open purchase orders',
      frontier: 'In frontier',
      shipped: 'Shipped',
      documents: 'Documents',
    },
    MEMaritime: {
      Opened: 'Open purchase orders',
      Shipped: 'Shipped',
      Harbor: 'Harbor',
    },
  },
  maritimeFlag: 'Maritime order',
  tooltips: {
    selection: {
      title: '',
      description: '',
    },
    actions: {
      title: '',
      description: '',
    },
    notificationPanel: {
      title: '',
      description: '',
    },
    notificationConfig: {
      title: '',
      description: '',
    },
  },
  modal: {
    title: '',
    description: '',
    confirm: '',
  },
  common: {
    download: 'Download',
    new: 'New',
    feedback: 'From 1 to 5, how satisfied are you with your purchase orders informations?',
    orderUpdateFeedback: 'From 1 to 5, how satisfied are you with the wallet change in this page?',
    shippingRequestFeedback: 'From 1 to 5, how satisfied are you with ordering shipments on this page?',
    refresh: 'Refresh',
    tryAgain: 'Try again',
    agGrid: {
      rowGroupColumnsEmptyMessage: 'Drag the column here to group',
      noRowsToShow: 'No records to display',
      loadingOoo: 'Loading...',
      updating: 'Updating...',
      pinColumn: 'Lock column',
      pinLeft: 'To the left',
      pinRight: 'To the right',
      noPin: 'Unlock',
      autosizeThiscolumn: 'Auto-resize this column',
      autosizeAllColumns: 'Auto-resize all columns',
      resetColumns: 'Reset columns',
      groupBy: 'Group by',
      expandAll: 'Expand all',
      collapseAll: 'CollapseAll',
      copy: 'Copy',
      copyWithHeaders: 'Copy with headers',
      paste: 'Paste',
      export: 'Export',
      csvExport: 'To .csv',
      excelExport: 'To  .xlsx',
      excelXmlExport: 'To .xml',
      shippingRequest: 'Shipping request',
      orderUpdate: 'Order Update',
      tooltip: {
        title: 'News!',
        description: 'Change your shipping request!',
        buttonText: 'Check it out',
      },
      subtotal: 'Subtotal:',
    },
    scheduleSend: 'Schedule',
  },
  successSaveLayout: 'Do not worry! The speaker configuration has just been saved.',
  rowCounter: 'Displaying',
  rowCounterText: 'record(s)',
  criticalItensCounter: 'elemento(s) crítico(s)',
};

export const OrdersStrings: IOrderLanguages = {
  pt,
  es,
  in: ing,
};
