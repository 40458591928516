import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setQuerySearch } from '@app/modules/components/customer-search/store/customer-search.store';
import { Body, H2 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Form } from '@atomic/obj.form';
import { FilterOptions } from '../../../customer-search.constants';
import { CustomerSearchFieldAdminWrapper, SelectSomeClientMessage } from '../customer-search-modal-admin.style';
import { ICustomerSearchModalHeaderAdminStrings, Strings } from './customer-search-modal-header-admin.string';
import { FilterBadges } from './partials/filter-badges/filter-badges.component';
import { FilterTypeDropDown } from './partials/filter-type-drop-down/filter-type-drop-down.component';
import { SearchField } from './partials/search-field/search-field.component';

export const CustomerSearchModalHeaderAdmin: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ICustomerSearchModalHeaderAdminStrings = Strings[userInfo.language];

  const { filterOptionSearch, clientBpsSearch } = useSelector((state: RootState) => state.customerSearch);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleSubmit = form => {
    if (filterOptionSearch?.value === FilterOptions.BP) {
      dispatch(setQuerySearch(form.data?.textSearch));
    }
  };

  const someBpSelected = clientBpsSearch.find(bp => !!bp.selected);

  return (
    <FlexColumn>
      <H2>{strings.titleAdmin}</H2>
      {
        <FlexColumn data-testid='bp-search-form-wrapper'>
          <Form onSubmit={handleSubmit}>
            <FlexRow vAlign='center' hAlign='flex-start' fullWidth>
              <CustomerSearchFieldAdminWrapper>
                <FlexColumn>
                  <Body pb={Spacing.XSmall}>{strings.filterBy}</Body>
                  <FilterTypeDropDown />
                </FlexColumn>
                <FlexColumn noGrow fullWidth>
                  <Body pb={Spacing.XSmall}>{strings.searchBy}</Body>
                  <SearchField />
                </FlexColumn>
              </CustomerSearchFieldAdminWrapper>
            </FlexRow>
          </Form>
          <FilterBadges />
        </FlexColumn>
      }
      {!someBpSelected && <SelectSomeClientMessage>{strings.validationMessage}</SelectSomeClientMessage>}
    </FlexColumn>
  );
};
