import styled from 'styled-components';
import { InputLabel } from '@atomic/atm.typography';
import { BrandColor, Color, FontFamily, Spacing } from '@atomic/obj.constants';

export const SurrogateBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 300px;
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const SelectedEmailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-right: ${Spacing.Large};
  font-family: ${FontFamily.Primary};
  display: flex;
  flex-direction: column;
  border: 1px solid ${Color.GrayLight};
  overflow-y: auto;
  width: calc(100vw - 860px);
  max-width: 770px;
  min-height: 300px;
`;

export const EmailFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextFieldWrapper = styled.div`
  height: 44px;
  width: 58%;
`;

export const SelectedEmail = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.Small} 0 ${Spacing.Small} ${Spacing.Small};
  margin: ${Spacing.XSmall};
  color: ${Color.GrayDark};
  background-color: ${BrandColor.LightBlue};
  width: fit-content;
  height: auto;
  max-height: 62px;
`;

export const EmailLabel = styled.div``;

export const DateLabel = styled(InputLabel)`
  width: 50px;
`;

export const HeightSeparator = styled.div`
  height: 50%;
`;
