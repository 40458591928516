export const SLICE_NAME = 'toast';

export const PADDING_TOP = 90;

export enum ToastVariant {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
}
