import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IOrderUpdateStrings, Strings } from '@app/modules/order-update/order-update.string';
import { setNewQuantity } from '@app/modules/order-update/store/order-update.store';
import { ChangeOrderStyled } from '../order-update-table.style';

export interface IOrderUpdateQtyCellProps {
  index: number;
  disabled: boolean;
}

const strings: IOrderUpdateStrings = Strings.pt;

export const OrderUpdateQtyCell: React.FC<IOrderUpdateQtyCellProps> = ({ index, disabled }) => {
  const { tableRows } = useSelector((state: RootState) => state.orderUpdate);
  const isSelected = tableRows[index].cancelOrder;
  const dispatch = useDispatch();

  return (
    <ChangeOrderStyled
      placeholder={strings.table.newQtd}
      disabled={isSelected || disabled}
      selected={isSelected}
      onValueChange={value => {
        let positiveValue = null;

        if (value >= 0) {
          positiveValue = value;
        }

        dispatch(setNewQuantity({ value: positiveValue, index }));
      }}
      value={tableRows[index].newQtdSolClient}
    />
  );
};
