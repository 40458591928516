import { format, parse } from 'date-fns';
import {
  BilledGraphData,
  BilledOrderGraph,
  ConfirmedDeliveryByStatus,
  ConfirmedOrderGraphs,
  FinanceMatchedMatchesGraphs,
  FinanceOpenMatchesGraphs,
  MatchedMatch,
  MonthlyDeliveryForecast,
  MonthlyDeliveryForecastMonth,
  OpenMatch,
  OpenOrderGraphs,
  OpenOrderStatusGraph,
  OrderStatusGraph,
  RequestedVsConfirmedOrderGraph,
  StockOrderGraphs,
  StockStatusGraph,
  StockStepGraph,
} from '@app/models/dashboard.model';
import { getCollapsedMonthName } from '@app/utils/date-utils';

const DEFAULT_NUMBER_OF_MONTHS = 10;

interface RequestedDto {
  requestedQty: number;
  date: string;
}

interface ConfirmedDto {
  confirmedQty: number;
  date: string;
}

export interface OpenOrderGraphsDto {
  openOrders: {
    statusPedidoAberto: {
      qtdSolicitada: number;
      qtdConfirmada: number;
      qtdFaturada: number;
      qtdPendFaturamento: number;
    };
  };
  statusMensal: any[];
  statusPedido: {
    implantado: {
      qtdSolicitada: number;
    };
    emAprazamento: {
      qtdSolicitada: number;
    };
    confirmado: {
      qtdConfirmada: number;
    };
    total: {
      qtd: number;
    };
  };
}

export interface ConfirmedOrderGraphsDto {
  faturamento: {
    atual: {
      dtFaturada: string;
      qtdFaturada: number;
    };
    previsao: {
      mensal: {
        dtPrevisao: string;
        qtdPendFaturamento: number;
      }[];
    };
  };
  statusEntrega: {
    atraso: {
      qtdPendFaturamento: number;
    };
    dentroPrazo: {
      qtdPendFaturamento: number;
    };
    semPrevisao: {
      qtdPendFaturamento: number;
    };
    tendenciaAtraso: {
      qtdTendAtraso: number;
    };
    total: {
      qtdPendFaturamento: number;
    };
  };
}

export interface StockOrderGraphsDto {
  estoqueEstagio: {
    emEstoque: {
      qtd: number;
    };
    emCarregamento: {
      qtd: number;
    };
    emRemessa: {
      qtd: number;
    };
    total: {
      qtd: number;
    };
  };
  estoqueStatus: {
    vencido: {
      qtd: number;
    };
    noPrazo: {
      qtd: number;
    };
    total: {
      qtd: number;
    };
  };
}

export interface BilledOrderGraphDto {
  faturamento: {
    diario: { diaFaturamento: string; qtdFaturada: number }[];
    mensal: { mesFaturamento: string; qtdFaturada: number }[];
  };
}

export interface FinanceMatchedMatchesGraphDto {
  partidasCompensadas: {
    dataMesCompensacao: string;
    numMesDocumentos: number;
    totalMesGeral: number;
  }[];
}

export interface FinanceOpenMatchesGraphDto {
  partidasAbertas: {
    expiredTotalGeral: number;
    notExpiredTotalGeral: number;
    expiredNumDocumentos: number;
    notExpiredNumDocumentos: number;
    expiredStatus: string;
    notExpiredStatus: string;
  }[];
}

export const mapOpenOrdersDashboard = (data: OpenOrderGraphsDto): OpenOrderGraphs => {
  return {
    openOrderStatusGraph: mapOpenOrderStatusGraph(data),
    requestedVsConfirmedOrderGraph: mapRequestedVsConfirmedOrderGraph(data),
    orderStatusGraph: mapOrderStatusGraph(data),
  };
};

const mapOpenOrderStatusGraph = (data: OpenOrderGraphsDto): OpenOrderStatusGraph => {
  const graphInfo = data.openOrders.statusPedidoAberto;
  return {
    confirmed: graphInfo?.qtdConfirmada ? +graphInfo?.qtdConfirmada?.toFixed(0) : 0,
    requested: graphInfo?.qtdSolicitada ? +graphInfo?.qtdSolicitada?.toFixed(0) : 0,
    billed: graphInfo?.qtdFaturada ? +graphInfo?.qtdFaturada?.toFixed(0) : 0,
    pendingBilling: graphInfo?.qtdPendFaturamento ? +graphInfo?.qtdPendFaturamento?.toFixed(0) : 0,
  };
};

const mapOrderStatusGraph = (data: OpenOrderGraphsDto): OrderStatusGraph => {
  return {
    deployed: data.statusPedido.implantado?.qtdSolicitada
      ? +data.statusPedido.implantado?.qtdSolicitada?.toFixed(0)
      : 0,
    onSchedule: data.statusPedido.emAprazamento?.qtdSolicitada
      ? +data.statusPedido.emAprazamento?.qtdSolicitada?.toFixed(0)
      : 0,
    confirmed: data.statusPedido.confirmado?.qtdConfirmada
      ? +data.statusPedido.confirmado?.qtdConfirmada?.toFixed(0)
      : 0,
    total: data.statusPedido.total?.qtd ? +data.statusPedido.total?.qtd?.toFixed(0) : 0,
  };
};

const mapRequestedVsConfirmedOrderGraph = (data: OpenOrderGraphsDto): RequestedVsConfirmedOrderGraph[] => {
  const requested: RequestedDto[] = data.statusMensal[0].solicitado.map(item => {
    const splitedDate = item.dtQtdSolicitada.split('-');
    return {
      requestedQty: item?.qtdSolicitada ? +item?.qtdSolicitada.toFixed(0) : 0,
      date: `${splitedDate[1]}/${splitedDate[0]}`,
    };
  });

  const confirmed: ConfirmedDto[] = data.statusMensal[1].confirmado.map(item => {
    const splitedDate = item.dtQtdConfirmada.split('-');
    return {
      confirmedQty: item?.qtdConfirmada ? +item?.qtdConfirmada?.toFixed(0) : 0,
      date: `${splitedDate[1]}/${splitedDate[0]}`,
    };
  });

  return lastMonths(DEFAULT_NUMBER_OF_MONTHS)
    .map(month => {
      return {
        requested: requested.find(item => item.date === month)?.requestedQty,
        confirmed: confirmed.find(item => item.date === month)?.confirmedQty,
        date: month,
      };
    })
    .sort(
      (a, b) =>
        new Date(format(parse(a.date, 'MM/yyyy', 0), 'yyyy/MM')).getTime() -
        new Date(format(parse(b.date, 'MM/yyyy', 0), 'yyyy/MM')).getTime(),
    );
};

const lastMonths = (monthQty: number) => {
  return Array(monthQty)
    .fill('')
    .map((_month, index) => {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getUTCMonth() + 3 - index);
      const currentMonth = currentDate.getUTCMonth() + 1;
      const currentYear = currentDate.getUTCFullYear();
      return `${currentMonth.toString().padStart(2, '0')}/${currentYear}`;
    });
};

const nextMonths = (monthQty: number) => {
  return Array(monthQty)
    .fill('')
    .map((_month, index) => {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getUTCMonth() + index);
      const currentMonth = currentDate.getUTCMonth() + 1;
      const currentYear = currentDate.getUTCFullYear();
      return `${currentMonth.toString().padStart(2, '0')}/${currentYear}`;
    });
};

export const mapConfirmedOrdersDashboard = (data: ConfirmedOrderGraphsDto): ConfirmedOrderGraphs => {
  return {
    monthlyDeliveryForecast: mapMonthlyDeliveryForecast(data),
    confirmedDeliveryByStatus: mapConfirmedDeliveryByStatus(data),
  };
};

const mapMonthlyDeliveryForecast = (data: ConfirmedOrderGraphsDto): MonthlyDeliveryForecast => {
  const billedData = data.faturamento;
  const currentSplitedDate = billedData.atual.dtFaturada.split('-');
  const currentDate = `${currentSplitedDate[1]}/${currentSplitedDate[0]}`;

  const forecast = billedData.previsao.mensal.map(item => {
    const splitedDate = item.dtPrevisao.split('-');
    return {
      pendingBillingQty: item?.qtdPendFaturamento ? +item?.qtdPendFaturamento?.toFixed(0) : 0,
      date: `${splitedDate[1]}/${splitedDate[0]}`,
    };
  });

  const months = nextMonths(DEFAULT_NUMBER_OF_MONTHS)
    .map(month => {
      return {
        date: month,
        billed: currentDate === month ? +billedData.atual?.qtdFaturada?.toFixed(0) : null,
        pendingBilling: forecast.find(item => item.date === month)?.pendingBillingQty,
      };
    })
    .sort(
      (a, b) =>
        new Date(format(parse(a.date, 'MM/yyyy', 0), 'yyyy/MM')).getTime() -
        new Date(format(parse(b.date, 'MM/yyyy', 0), 'yyyy/MM')).getTime(),
    );

  return {
    total: totalDeliveryForecast(months),
    months,
  };
};

const totalDeliveryForecast = (data: MonthlyDeliveryForecastMonth[]) => {
  const total = data?.reduce((previous, current) => {
    let value = previous;
    if (current.pendingBilling) {
      value += current.pendingBilling;
    }

    if (current.billed) {
      value += current.billed;
    }
    return value;
  }, 0);

  return +total?.toFixed(0);
};

const mapConfirmedDeliveryByStatus = (data: ConfirmedOrderGraphsDto): ConfirmedDeliveryByStatus => {
  const confirmedDeliveryQty = data.statusEntrega;

  return {
    valueByStatus: {
      delayed: confirmedDeliveryQty.atraso?.qtdPendFaturamento
        ? +confirmedDeliveryQty.atraso?.qtdPendFaturamento?.toFixed(0)
        : 0,
      onTime: confirmedDeliveryQty.dentroPrazo?.qtdPendFaturamento
        ? +confirmedDeliveryQty.dentroPrazo?.qtdPendFaturamento?.toFixed(0)
        : 0,
      noForecast: confirmedDeliveryQty.semPrevisao?.qtdPendFaturamento
        ? +confirmedDeliveryQty.semPrevisao?.qtdPendFaturamento?.toFixed(0)
        : 0,
      delayTrend: confirmedDeliveryQty.tendenciaAtraso?.qtdTendAtraso
        ? +confirmedDeliveryQty.tendenciaAtraso?.qtdTendAtraso.toFixed(0)
        : 0,
    },
    total: +confirmedDeliveryQty.total?.qtdPendFaturamento?.toFixed(0),
  };
};

export const mapFinanceMatchedMatchesDashboard = (data: FinanceMatchedMatchesGraphDto): FinanceMatchedMatchesGraphs => {
  return {
    matchedMatches: mapMatchedMatches(data),
  };
};

export const mapMatchedMatches = (data: FinanceMatchedMatchesGraphDto): MatchedMatch[] => {
  const matchedMatches = [];

  if (data && data.partidasCompensadas && data.partidasCompensadas.length) {
    data.partidasCompensadas.forEach(p => {
      matchedMatches.push({
        compensationMonthDate: handleMatchedMatchDate(p.dataMesCompensacao),
        documentMonthNumber: p.numMesDocumentos,
        generalTotalMonth: p.totalMesGeral,
      });
    });
  }

  return matchedMatches;
};

const handleMatchedMatchDate = (date: string) => {
  if (date && date.length === 6) {
    return date.substring(4, 6) + '/' + date.substring(0, 4);
  } else {
    return date;
  }
};

export const mapFinanceOpenMatchesDashboard = (data: FinanceOpenMatchesGraphDto): FinanceOpenMatchesGraphs => {
  return {
    openMatches: mapOpenMatches(data),
  };
};

export const mapOpenMatches = (data: FinanceOpenMatchesGraphDto): OpenMatch[] => {
  const openMatches = [];

  if (data && data.partidasAbertas && data.partidasAbertas.length) {
    data.partidasAbertas.forEach(p => {
      openMatches.push({
        expiredGeneralTotal: p.expiredTotalGeral ? p.expiredTotalGeral : 0,
        notExpiredGeneralTotal: p.notExpiredTotalGeral ? p.notExpiredTotalGeral : 0,
        expiredDocumentNumbers: p.expiredNumDocumentos ? p.expiredNumDocumentos : 0,
        notExpiredDocumentNumbers: p.notExpiredNumDocumentos ? p.notExpiredNumDocumentos : 0,
        expiredStatus: p.expiredStatus ? p.expiredStatus : '',
        notExpiredStatus: p.notExpiredStatus ? p.notExpiredStatus : '',
      });
    });
  }

  return openMatches;
};

export const mapBilledOrdersDashboard = (data: BilledOrderGraphDto): BilledOrderGraph => {
  return {
    daily: mapDailyBilledOrdersGraph(data),
    monthly: mapMonthlyBilledOrdersGraph(data),
  };
};

const mapDailyBilledOrdersGraph = (data: BilledOrderGraphDto): BilledGraphData[] => {
  return data.faturamento.diario.map(item => {
    return {
      date: format(parse(item.diaFaturamento, 'yyyy-MM-dd', 0), 'dd/MM'),
      billedQty: +item.qtdFaturada.toFixed(0),
    };
  });
};

const mapMonthlyBilledOrdersGraph = (data: BilledOrderGraphDto): BilledGraphData[] => {
  return data.faturamento.mensal.map(item => {
    const formatedDate = format(parse(item.mesFaturamento, 'yyyy-MM', 0), 'MM/yyyy');

    return {
      date: formatedDate,
      billedQty: +item.qtdFaturada.toFixed(0),
      monthName: getCollapsedMonthName(parseInt(formatedDate.split('/')[0])),
    };
  });
};

export const mapStockOrdersDashboard = (data: StockOrderGraphsDto): StockOrderGraphs => {
  return {
    stockStep: mapStockStepGraph(data),
    stockStatus: mapStockStatusGraph(data),
  };
};

const mapStockStepGraph = (data: StockOrderGraphsDto): StockStepGraph => {
  return {
    inStock: data.estoqueEstagio.emEstoque?.qtd ? +data.estoqueEstagio.emEstoque.qtd.toFixed(0) : 0,
    loading: data.estoqueEstagio.emCarregamento?.qtd ? +data.estoqueEstagio.emCarregamento.qtd.toFixed(0) : 0,
    shipping: data.estoqueEstagio.emRemessa?.qtd ? +data.estoqueEstagio.emRemessa.qtd.toFixed(0) : 0,
    total: data.estoqueEstagio.total?.qtd ? +data.estoqueEstagio.total.qtd.toFixed(0) : 0,
  };
};

const mapStockStatusGraph = (data: StockOrderGraphsDto): StockStatusGraph => {
  return {
    expired: data.estoqueStatus.vencido?.qtd ? +data.estoqueStatus.vencido.qtd.toFixed(0) : 0,
    onTime: data.estoqueStatus.noPrazo?.qtd ? +data.estoqueStatus.noPrazo.qtd.toFixed(0) : 0,
    total: data.estoqueStatus.total?.qtd ? +data.estoqueStatus.total.qtd.toFixed(0) : 0,
  };
};
