import styled from 'styled-components';
import { Border, Color, FontSize, Shadow, Spacing, ZIndex } from '@atomic/obj.constants';

interface ChatResultWrapperStyledProps {
  initialized?: boolean;
  modified?: string;
}

export const ChatResultWrapperStyled = styled.div`
  display: flex;
  flex: 1;
  width: ${(props: ChatResultWrapperStyledProps) => (props.initialized ? '400px' : 0)};
  box-shadow: ${Shadow.Medium};
  overflow: hidden;
  flex-direction: column;
  background-color: white;
  transition: width 0.8s;
  & > div {
    padding-bottom: 5px;
    border-bottom: none;
  }
`;

export const ChatResultLabelStyled = styled.div`
  & div {
    border-top: none;
    padding-top: 0px;
    font-style: italic;
  }
`;

interface ChatDrawerStyledProps {
  hasSubmitButton?: boolean;
}

export const ChatDrawerStyled = styled.div`
  padding: ${Spacing.Medium} 0px;
  overflow-y: scroll;
  height: ${(props: ChatDrawerStyledProps) => (props.hasSubmitButton ? 'calc(100vh - 227px)' : 'calc(100vh - 165px)')};
`;

export const ChatCreateButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: ${Spacing.Medium} ${Spacing.XLarge} ${Spacing.Medium} ${Spacing.XLarge};
  z-index: ${ZIndex.ChatCreateButtonWrapper};
  background-color: ${Color.White};
  border-top: ${Border.Width} solid ${Border.Color};
`;

export const ChatResultAttributeWrapper = styled.div`
  padding: ${Spacing.Small} ${Spacing.Small} ${Spacing.Small} ${Spacing.Medium};
  ${(props: ChatResultWrapperStyledProps) => props.modified === 'NLU' && '& label { color: #000; font-weight: bold;}'}
  background-color: ${(props: ChatResultWrapperStyledProps) =>
    props.modified === 'GERDAU' ? Color.GrayXLight : props.modified === 'NLU' ? '#f0f4f7' : Color.White};
  & > label > span {
    font-size: ${FontSize.XSmall};
    font-weight: normal;
    color: ${Color.GrayDark};
  }
  & > div > div > div:first-child > button {
    padding: 0;
  }
`;

export const ChatResultTable = styled.div`
  padding: 0px;
`;

export const AttributeDetailWrapper = styled.div`
  padding-top: 8px;
  & > p {
    color: ${Color.Accessory};
    font-weight: 600;
  }
`;
