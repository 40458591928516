export interface IAuthStrings {
  authError: {
    custom: {
      UAE101: string;
    };
    unauthorized: string;
    forbidden: string;
    notFound: string;
    timeout: string;
    internal: string;
  };
}

export interface IAuthStringsLanguages {
  pt: IAuthStrings;
}

const pt: IAuthStrings = {
  authError: {
    custom: {
      UAE101: 'E-mail ou senha estão incorretos. Verifique e tente novamente.',
    },
    unauthorized: 'E-mail ou senha incorretos',
    forbidden: 'Você não tem permissão para realizar está ação',
    notFound: 'Desculpa, não encontramos o que você solicitou',
    timeout:
      'O tempo máximo de resposta do serviço foi atingido. Por favor, recarregue a página ou tente novamente mais tarde.',
    internal: 'Ops, ocorreu um erro, mas não se procupe, nosso time já está trabalhando para resolver o problema.',
  },
};

export const Strings: IAuthStringsLanguages = {
  pt,
};

export const AuthStrings = {
  pt: {
    title: 'Fazer login',
    introText: 'Faça seu login para acessar a plataforma Gerdau Mais',
    emailField: 'Email',
    emailPlaceholder: 'Digite seu email',
    passField: 'Senha',
    forgotPassword: 'Esqueci minha senha',
    button: 'Fazer Login',
    footerText: 'Ao continuar, você concorda com a ',
    privacyPolicy: 'Política de Privacidade e Termos de Uso.',
    copyright: '(Todos os direitos reservados)',
    mandatoryField: 'Obrigatório',
    invalidEmail: 'Email inválido',
    ssoButton: 'Acesso colaborador Gerdau',
    ssoUserMessage: 'Colaborador, clique no link para acessar o portal',
    toastConfirm: 'Ok, entendido',
  },
  in: {
    title: 'Login',
    introText: 'Log in to access the Gerdau Mais platform',
    emailField: 'Email',
    emailPlaceholder: 'Type your email',
    passField: 'Password',
    forgotPassword: 'I forgot my password',
    button: 'Login',
    footerText: 'By continuing, you agree to the ',
    privacyPolicy: 'Privacy Policy and Terms of Use.',
    copyright: '(All rights reserved)',
    mandatoryField: 'Mandatory',
    invalidEmail: 'Invalid email',
    ssoButton: 'Gerdau employee access',
    ssoUserMessage: 'Collaborator, click on the link to access the portal',
    toastConfirm: 'Ok, understood',
  },
  es: {
    title: 'Inicie la sesión',
    introText: 'Inicie sesión para acceder a la plataforma Gerdau Mais',
    emailField: 'Email',
    emailPlaceholder: 'Escriba su correo electrónico',
    passField: 'Contraseña',
    forgotPassword: 'Olvide mi contraseña',
    button: 'Inicie la sesión',
    footerText: 'Al continuar, acepta la ',
    privacyPolicy: 'Política de Privacidad y los Términos de Uso.',
    copyright: '(Reservados todos los derechos)',
    mandatoryField: 'Obligatorio',
    invalidEmail: 'Email inválido',
    ssoButton: 'Acceso de empleados Gerdau',
    ssoUserMessage: 'Colaborador, haga clic en el enlace para acceder al portal',
    toastConfirm: 'Ok, entendido',
  },
};
