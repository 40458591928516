import styled, { css } from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

interface OptionWrapperProps {
  activate: boolean;
}

export const optionActiveCss = css`
  background-color: ${Color.White};
  cursor: pointer;

  h4 {
    color: ${Color.Black};
  }
`;

export const OptionWrapper = styled.div<OptionWrapperProps>`
  flex: 1;
  margin-right: ${Spacing.Large};
  height: 220px;
  width: 450px;
  border-radius: 4px;
  padding: ${Spacing.XLarge}, ${Spacing.Medium}, ${Spacing.Medium}, ${Spacing.Large};
  background-color: ${Color.GrayXLight};
  border: 1px solid ${Color.Gray};

  h4 {
    color: ${Color.GrayDark};
  }

  ${props => props.active && optionActiveCss}
`;

export const NewTag = styled.div`
  color: ${Color.White};
  background-color: ${Color.SuccessMain};
  padding-left: ${Spacing.Small};
  padding-right: ${Spacing.Small};
  border-radius: ${Spacing.XSmall};
`;
