import { HelpCenterSubsectionKeys, HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterStockOrderSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Carteira - Em estoque',
      key: HelpCenterSectionKeys.StockOrders,
      subsections: [
        {
          title: 'Dicionário',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.StockOrdersGlossary,
          informationBlocks: [
            {
              blockTitle: 'Entenda melhor os termos usados nas tabelas do Gerdau Mais',
              table: {
                columns: ['Nome da coluna', 'Conceito'],
                rows: [
                  ['Cliente', 'Nome do cliente emissor do pedido de compra.'],
                  ['Cod. cliente', 'Código interno Gerdau do emissor do pedido de compra.'],
                  ['Cod. prod. cliente', 'Também chamado de Part Number, é o código do material na visão do cliente.'],
                  [
                    'Cód. recebedor',
                    'Código interno Gerdau do parceiro que irá receber a mercadoria, podendo ser o próprio cliente.',
                  ],
                  ['Data confirmada', 'Data confirmada pela Gerdau para entrega do pedido.'],
                  ['Data do pedido do cliente', 'Data que o cliente emitiu o pedido de compras.'],
                  [
                    'Data saída mercadoria',
                    'Data que o material deve ser faturado para ser possível entregar ao cliente na data confirmada.',
                  ],
                  ['Data solicitada cliente', 'Data requerida pelo cliente ao emitir o pedido de compra.'],
                  [
                    'Desc. material',
                    'Descrição do material, contemplando as principais características, como produto, forma, processo, acabamento, bitola, liga, etc.',
                  ],
                  ['Item ordem de compra', 'Item do pedido de compra emitido pelo cliente.'],
                  ['Item ordem de venda', 'Item da ordem de venda implantada pela Gerdau.'],
                  ['Material', 'Código interno Gerdau que simboliza a descrição do material.'],
                  [
                    'Ordem compra',
                    'Campo que simboliza o pedido de compra emitido pelo cliente. Campo que simboliza o pedido de compra emitido pelo cliente.',
                  ],
                  ['Ordem venda', 'Campo que simboliza a ordem de venda implantada pela Gerdau.'],
                  ['Qtd. confirmada (t)', 'Quantidade confirmada pela Gerdau para cada pedido.'],
                  [
                    'Qtd. em estoque (t)',
                    'Quantidade do pedido que está em estoque na usina, desconsiderando a quantidade que está em Remessa.',
                  ],
                  [
                    'Qtd. em remessa (t)',
                    'Quantidade do pedido que está com remessa gerada, ou seja, que já possui uma solicitação de carregamento para a Logística.',
                  ],
                  ['Qtd. em separação (t)', 'Quantidade do pedido que está com caminhão em processo de carregamento.'],
                  [
                    'Recebedor',
                    'Parceiro sinalizado pelo cliente para receber a mercadoria, podendo ser o próprio cliente.',
                  ],
                  [
                    'Seleção',
                    'Caixa de seleção com a escolha dos pedidos que serão solicitados para a geração de remessa.',
                  ],
                  ['Status entrega', 'Campo que sinaliza se o pedido está previsto para atrasar'],
                  [
                    'Status ordem de venda',
                    'Status da ordem de venda, implantada, aguardando aprazamento pelo PCP ou confirmada para produção e atendimento.',
                  ],
                  ['Usina', 'Código interno Gerdau da planta que produzirá o material.'],
                  ['Usina descrição', 'Planta que produzirá o material para atendimento do pedido.'],
                ],
              },
            },
          ],
        },
        {
          title: 'Solicitacão de geração de remessa',
          key: HelpCenterSubsectionKeys.RemittanceGenerationRequest,
          description: 'Como solicitar geração de remessa dos seus materiais em estoque',
          informationBlocks: [
            {
              blockTitle: '1. Vá até a aba da Carteira e em seguida clique em "Em estoque"',
              media: {
                path: 'assets/img/help-center/order/remittance-generation/remittance-generation-01.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle:
                '2. Selecione os seus pedidos em estoque que deseja solicitar a geração de remessas para carregamento',
              media: {
                path: 'assets/img/help-center/order/remittance-generation/remittance-generation-02.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle: '3. Com os pedidos selecionados, clicar no botão "Solicitar remessa"',
              media: {
                path: 'assets/img/help-center/order/remittance-generation/remittance-generation-03.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle:
                '4. Revise as informações dos seus pedidos selecionados e se estiver tudo de acordo, basta clicar no botão "Solicitar remessa"',
              media: {
                path: 'assets/img/help-center/order/remittance-generation/remittance-generation-04.png',
                type: 'stretch-image',
              },
            },
            {
              paragraph:
                'Você irá receber um e-mail com registro da sua solicitação e a pessoa responsável pelo atendimento (CS) da sua carteira também irá receber um e-mail para providenciar os próximos passos.',
            },
            {
              paragraph:
                'Pronto! Agora só esperar o faturamento do seu pedido e em breve ele estará chegando para você.',
            },
          ],
        },
        {
          title: 'Data Real no Estoque',
          key: HelpCenterSubsectionKeys.StockRealDate,
          description: '',
          informationBlocks: [
            {
              paragraphHtml: 'Dentro da aba <b>Carteira - Em estoque</b>, na coluna de <b>Data Real no Estoque</b>.',
              media: {
                path: 'assets/img/news-modal/MN-data-real-stock.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Você pode visualizar a data real da entrada daquele material no estoque, caso haja mais de uma data de entrada, é possível visualizar todas elas clicando no link ao lado da data visível. Aguarde o carregamento e uma janela irá se abrir sobre o link mostrando as Quantidades, Datas e Lotes.',
              media: {
                path: 'assets/img/news-modal/MN-data-real-stock-2.png',
                type: 'stretch-image',
              },
            },
          ],
        },
      ],
    }
  );
};
