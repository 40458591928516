import React from 'react';
import { useDispatch } from 'react-redux';
import { BackButton } from '@app/modules/components/back-button/back-button.component';
import { Breadcrumb } from '@atomic/atm.breadcrumb';
import { Frame } from '@atomic/atm.frame';
import { Image } from '@atomic/atm.image';
import { DD, DL, DT, H1 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { RESET_TRUCK_TRACKING_ACTION, setShowTruckTracking } from '../../store/truck-tracking.store';
import { Strings, ITruckTrackingStrings } from '../../truck-tracking.string';
import { TruckTrackingHeaderShimmer } from './truck-tracking-header.shimmer';

interface TruckTrackingHeaderProps {
  title: string;
  buyOrderCode: string;
  clientMaterial: string;
  loading: boolean;
  brand: string;
}

const strings: ITruckTrackingStrings = Strings.pt;
export const TruckTrackingHeader: React.FC<TruckTrackingHeaderProps> = props => {
  const dispatch = useDispatch();

  const handleClickBackButton = () => {
    dispatch(RESET_TRUCK_TRACKING_ACTION);
    dispatch(setShowTruckTracking(false));
  };

  const breadCrumbData = [
    {
      label: strings.previousPage,
      onLinkClick: handleClickBackButton,
    },
    { label: strings.actualPage },
  ];

  const getBrandIcon = () => {
    switch (props.brand) {
      case 'CUSTOM':
      case 'GG CUSTOM':
      case 'GERDAU CUSTOM':
        return <Image.LogoGerdauCustom />;
      case 'MAX PREMIUM FLAT':
      case 'GG MAX PREMIUM FLAT':
        return <Image.LogoGerdauMaxPremiumFlat />;
      case 'STANDARD':
      case 'GG STANDARD':
      case 'STANDARD 5160':
        return <Image.LogoGerdauStandard />;
      case 'MAX MACHINITY':
      case 'GG MAX MACHINITY':
        return <Image.LogoGerdauMaxMachinity />;
      case 'TECH INDUHEAT':
        return <Image.LogoGerdauTechInduheat />;
      case 'MAX CLEANITY':
      case 'GG CLEANITY':
        return <Image.LogoGerdauMaxCleanity />;
      case 'TECH ALLOYED FLAT':
        return <Image.LogoGerdauTechAlloyedFlat />;
      case 'TECH ECOMACHINING':
        return <Image.LogoGerdauTechEcomachining />;
      default:
        return <Image.LogoGerdauDefault />;
    }
  };

  return (
    <Frame>
      <Grid fluid>
        <VSeparator />
        <Row style={{ 'justify-content': 'space-between' }}>
          <Breadcrumb data={breadCrumbData} />
          <BackButton handleClick={handleClickBackButton} />
        </Row>
        <VSeparator />

        <LoadingState loading={props.loading} data={!!props.title}>
          <LoadingState.Shimmer>
            <TruckTrackingHeaderShimmer />
          </LoadingState.Shimmer>

          <FlexRow vAlign='center'>
            <H1 noMargin={true}>{props.title}</H1>
            <FlexRow ml={Spacing.Medium} vAlign='center'>
              {getBrandIcon()}
            </FlexRow>
          </FlexRow>
          <VSeparator />
          <Row style={{ 'align-items': 'center' }}>
            <Col xs={6}>
              <DL horizontal>
                <DT>{strings.header.salesOrder}</DT>
                <DD>{props.buyOrderCode}</DD>
              </DL>
            </Col>
            <Col xs={6}>
              <DL horizontal>
                <DT>{strings.header.material}</DT>
                <DD>{props.clientMaterial}</DD>
              </DL>
            </Col>
          </Row>
          <VSeparator />
        </LoadingState>
      </Grid>
    </Frame>
  );
};
