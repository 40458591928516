import { useState } from 'react';
import { Container } from 'typedi';
import Cookies from 'universal-cookie';
import { isNullOrUndefined } from 'util';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { AuthGroups, AuthIsaGroupData, Customer } from '@app/models';
import { IsaInternalRoles } from '@app/models/quote.model';
import { AppPath } from '@app/modules/app/route-constants';

export interface UserData {
  clientNumber?: string;
  userName?: string;
  uid?: string;
  accessToken: string;
  azureToken?: string;
  userAccess?: UserAccess;
  clientNumberListOrders?: string;
  clientNumberListFinancial?: string;
  gsbGroups?: AuthGroups;
  isaGroup?: AuthIsaGroupData;
  gabAccess?: boolean;
  language?: string;
  isEmployee?: boolean;
  email?: string;
  isSeller?: boolean;
  isTae?: boolean;
  isScm?: boolean;
  ssoAccess?: boolean;
  role?: string;
}

export enum MarketType {
  Internal = 'internal',
  External = 'external',
}

export enum MarketTypeAcronym {
  Internal = 'MI',
  External = 'ME',
}

export interface UserAccess {
  ordersAccess: boolean;
  financialAccess: boolean;
  quoteAccess: boolean;
  adminGsb: boolean;
  adminGab: boolean;
  adminGerdau: boolean;
  adminCustomerGsb: boolean;
  adminCustomerGab: boolean;
  adminBpGsb: boolean;
  adminBpGab: boolean;
}

export interface CustomerSelection extends Customer {
  selected?: boolean;
  disabled?: boolean;
}

export interface UserStore {
  user: UserData;
  hasOrdersAccess: () => boolean;
  hasOrdersInputAccess: () => boolean;
  hasFinancialAccess: () => boolean;
  hasAdminGerdauAccess: () => boolean;
  hasSellerAccess: () => boolean;
  hasTaeAccess: () => boolean;
  hasScmAccess: () => boolean;
  isQuoteChatComplainCrmPage: () => boolean;
  hasLongSteelPermission: () => boolean;
  hasSpecialSteelPermission: () => boolean;
  hasAdminProfileAccess: () => boolean;
  quoteIsInternalUser: boolean;
  isIsaAdmin: boolean;
  login: (userData: UserData) => void;
  logout: () => void;
  setCookies: (accessToken: string, uid: string) => void;
  isLogged: () => boolean;
  completeAccessList: () => string[];
}

export const useUserStore = (): UserStore => {
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);
  const [user, setUser] = useState(authStorageService.getUser());

  const cookies = new Cookies();

  authStorageService.addObserver({
    hasLoggedIn: (userData: UserData) => {
      setUser(userData);
    },
    hasLoggedOut: () => {
      setUser(null);
    },
  });

  const login = (userData: UserData) => {
    authStorageService.login(userData);
    setUser(userData);
  };

  const setCookies = (accessToken: string, uid: string) => {
    cookies.set('@access_token', accessToken, { domain: '.egerdau.com.br' });
    cookies.set('@uid', uid, { domain: '.egerdau.com.br' });
  };

  const logout = () => {
    authStorageService.logout();
  };

  const isLogged = () => {
    if (typeof localStorage === 'undefined') {
      return false;
    }
    return !isNullOrUndefined(user);
  };

  const completeAccessList = () => {
    return user?.gsbGroups?.group
      ?.filter(
        item =>
          item.gsbRoles[0].financialCustomers || item.gsbRoles[0].ordersViewCustomers || item.gsbRoles[0].priceView,
      )
      .map(item => item.groupId);
  };

  const hasOrdersAccess = (): boolean => {
    return (
      !!user?.userAccess?.ordersAccess && !!user?.gsbGroups?.group?.find(item => item.gsbRoles[0].ordersViewCustomers)
    );
  };

  const hasOrdersInputAccess = (): boolean => {
    return !!user?.gsbGroups?.group?.find(item => item.gsbRoles[0].ordersInputCustomers);
  };

  const hasFinancialAccess = (): boolean => {
    return (
      user?.userAccess?.financialAccess && !!user?.gsbGroups?.group?.find(item => item.gsbRoles[0].financialCustomers)
    );
  };

  const isQuoteChatComplainCrmPage = (): boolean => {
    return (
      location.pathname.includes(AppPath.QUOTE.BASE) ||
      location.pathname.includes(AppPath.CHAT.BASE) ||
      location.pathname.includes(AppPath.COMPLAIN.BASE) ||
      location.pathname.includes(AppPath.QUOTE.CRM)
    );
  };

  const hasLongSteelPermission = () => {
    return (
      user.userAccess.adminBpGab ||
      user.userAccess.adminCustomerGab ||
      user.userAccess.adminGab ||
      user.userAccess.adminGerdau
    );
  };

  const hasSpecialSteelPermission = () => {
    return (
      user.userAccess.adminBpGsb ||
      user.userAccess.adminCustomerGsb ||
      user.userAccess.adminGsb ||
      user.userAccess.adminGerdau
    );
  };

  const quoteIsInternalUser = !!IsaInternalRoles[user?.isaGroup?.role];

  const isIsaAdmin = user?.userAccess?.adminGsb;

  const hasSellerAccess = (): boolean => user?.isSeller;

  const hasTaeAccess = (): boolean => user?.isTae;
  const hasScmAccess = (): boolean => user?.isScm;

  const hasAdminGerdauAccess = (): boolean => {
    const access = user?.userAccess;
    return access?.adminGerdau || access?.adminGsb || user?.isSeller || user?.isTae || user?.isScm;
  };

  const hasAdminProfileAccess = (): boolean => user?.userAccess?.adminGerdau;

  return {
    user,
    hasOrdersAccess,
    hasOrdersInputAccess,
    hasFinancialAccess,
    isQuoteChatComplainCrmPage,
    hasAdminGerdauAccess,
    hasSellerAccess,
    hasTaeAccess,
    hasScmAccess,
    hasLongSteelPermission,
    hasSpecialSteelPermission,
    hasAdminProfileAccess,
    login,
    logout,
    setCookies,
    isLogged,
    completeAccessList,
    quoteIsInternalUser,
    isIsaAdmin,
  };
};
