export interface IAdminSettingsStrings {
  title: string;
  subTitle: string;
  reset: string;
  success: string;
  error: string;
  tabs: {
    customerSatisfaction: string;
  };
  modalConfirm: {
    title: string;
    cancelButton: string;
    confirmButton: string;
  };
  functionalities: {
    title: string;
    shippingRequest: string;
    orderUpdate: string;
    truckTracking: string;
    notifications: string;
    technicalAnalysis: string;
    orderInput: string;
    ConfirmationText: string;
  };
  information: {
    title: string;
    nationalOrders: string;
    internationalOrders: string;
    nationalFinance: string;
    internationalFinance: string;
    dashboard: string;
    ConfirmationText: string;
  };
  nps: {
    title: string;
    nps: string;
    ConfirmationText: string;
  };
  lastUpdate: string;
}

export interface IAdminSettingsLanguages {
  pt: IAdminSettingsStrings;
}

export const pt: IAdminSettingsStrings = {
  title: 'Configurações',
  subTitle: 'Ative ou desative os seguintes pop-ups de satisfação do cliente',
  reset: 'Reset',
  success: 'Pesquisa resetada com successo!',
  error: 'Erro ao tentar resetar a pesquisa!',
  tabs: {
    customerSatisfaction: 'Customer satisfaction',
  },
  modalConfirm: {
    title: 'Resetar configurações',
    cancelButton: 'Cancelar',
    confirmButton: 'Resetar configurações',
  },
  functionalities: {
    title: 'Funcionalidades',
    shippingRequest: 'Solicitação de remessas',
    orderUpdate: 'Alteração de carteira',
    truckTracking: 'Truck tracking',
    notifications: 'Notificações',
    technicalAnalysis: 'Análise técnica',
    orderInput: 'Implantação de pedido',
    ConfirmationText: 'Tem certeza que deseja reiniciar a pesquisa sobre essa funcionalide?',
  },
  information: {
    title: 'Informações',
    nationalOrders: 'Carteira MI',
    internationalOrders: 'Carteira ME',
    nationalFinance: 'Finanças MI',
    internationalFinance: 'Finanças ME',
    dashboard: 'Painel de gestão',
    ConfirmationText: 'Tem certeza que deseja reiniciar a pesquisa sobre essa informação?',
  },
  nps: {
    title: 'NPS',
    nps: 'Pesquisa NPS',
    ConfirmationText: 'Tem certeza que deseja reiniciar a pesquisa NPS?',
  },
  lastUpdate: 'Última atualização:',
};

export const Strings: IAdminSettingsLanguages = {
  pt,
};
