/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';

export const NewsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NewsModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  background: ${Color.Primary};
  flex: 1;
`;

export const NewsModalHeaderIconWrapper = styled.div`
  flex: 1;
  padding-left: ${Spacing.XLarge};
`;

export const NewsModalTitleWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;

  font-size: ${FontSize.XLarge};
  font-weight: 700;
  color: ${Color.White};
`;

export const NewsModalCloseWrapper = styled.div`
  width: 33.33%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-right: ${Spacing.XLarge};
`;

export const NewsModalBody = styled.div`
  min-height: 64vh;
  height: auto;
`;

export const NewsModalFooter = styled.div`
  width: 100%;
  background-color: ${Color.White};
  padding: ${Spacing.Medium};
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.12);
`;

export const NewsListWrapper = styled.div`
  max-height: 55vh;
  overflow: auto;
  padding-top: ${Spacing.Small};
`;

export const NewsModalText = styled.div`
  text-transform: none;
  color: ${Color.GrayDark};
  margin: 0;
  font-size: 14px;
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  white-space: normal;
`;

export const NewsModalImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
