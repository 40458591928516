import styled from 'styled-components';
import { BrandColor, Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export const DropDownWrapper = styled.div`
  position: relative;
`;
export const DropDownSelectedWrapper = styled.div`
  display: flex;
  border: 2px solid ${Color.Primary};
  border-radius: 5px;
  margin-right: ${Spacing.Small};
  width: 100%;
`;

export const SelectedText = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-left: ${Spacing.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Small};
`;

export const SearchFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  & > div::first-child {
    width: 0%;
  }
  & > div > div {
    width: 100%;
    input {
      height: 44px;
      width: 100%;
    }
  }
`;

export const ResultWrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${BrandColor.Blue};
  font-size: 15px;
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  cursor: pointer;
`;
