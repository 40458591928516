import React from 'react';
import { useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { ShippingRequestEvents } from '@app/modules/order/order.analytics';
import { Strings } from '@app/modules/shipping-request/shipping-request.string';
import { setOpenShippingRequestSuggestionModalMaterial } from '@app/modules/shipping-request/store/shipping-request.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { TD, TR } from '@atomic/mol.table';
import { FlexRow } from '@atomic/obj.box';
import { BrandColor, Color, Spacing } from '@atomic/obj.constants';

interface IShippingRequestOldSaleOrderWarningTRProps {
  plant: string;
  bp: string;
  material: string;
  materialClientID: string;
}

export const ShippingRequestOldSaleOrderWarningTR: React.FC<IShippingRequestOldSaleOrderWarningTRProps> = ({
  plant,
  bp,
  material,
  materialClientID,
}) => {
  const strings = Strings.pt.page;
  const dispatch = useDispatch();

  return (
    <TR key={`warning-${material}`} color={Color.Yellow}>
      <TD colSpan={12}>
        <FlexRow fullWidth vAlign='center' hAlign='center'>
          <FaIcon.Warning size='sm' color={BrandColor.Yellow} />
          <>
            <Body ml={Spacing.Small} mr={Spacing.Small}>
              {strings.trOVWarning}
            </Body>
            <Body bold={700}>{strings.trOVWarningMaterial(materialClientID ? materialClientID : material)}</Body>
            <Body ml={Spacing.Small} mr={Spacing.Small}>
              {strings.trOVWarningEnd}
            </Body>
            <Body bold={700} mr={Spacing.Small}>
              {strings.trOVWarningBP(bp)}
            </Body>
          </>
          <FlexRow mr={Spacing.Small}>
            <Button
              size='small'
              kind='secondary'
              onClick={() => {
                LogAnalytics.click({
                  tipo: ShippingRequestEvents.SuggestionAlertClick,
                });
                dispatch(setOpenShippingRequestSuggestionModalMaterial({ plant, bp, material }));
              }}
            >
              {strings.trSuggestionButton}
            </Button>
          </FlexRow>
        </FlexRow>
      </TD>
    </TR>
  );
};
