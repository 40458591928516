import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { H4 } from '@atomic/atm.typography';
import { Table, TD, TH, TR } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants';
import { orderUpdateTableRowData } from '../../order-update.constants';
import { IOrderUpdateTableData } from '../../order-update.interfaces';
import { IOrderUpdateStrings, Strings } from '../../order-update.string';
import { OrderUpdateTableColumnStyled, TableTR } from './order-update-table.style';
import { OrderUpdateTableCell } from './partials/order-update-table-cell.component';
import { OrderUpdateWarningTR } from './partials/order-update-warning.component';
import { hasCanceledItemWithQuantity, hasInPleasureStatus, hasLessThan5DaysDiff, isStaticColumn } from './utils';

const strings: IOrderUpdateStrings = Strings.pt;

export const OrderUpdateTable: React.FC = () => {
  const { tableRows } = useSelector((state: RootState) => state.orderUpdate);

  return (
    <Table collapse>
      <TR bordered>
        {Object.keys(orderUpdateTableRowData).map(column => {
          return (
            <TH key={column} color={isStaticColumn(column) ? Color.Primary : Color.Secondary}>
              <H4 white={isStaticColumn(column)}>{strings.table.headerRow[column]}</H4>
            </TH>
          );
        })}
      </TR>
      {tableRows.map((item: IOrderUpdateTableData, index: number) => {
        const hasMessageStatus = hasInPleasureStatus(tableRows, index);
        const hasMessageDate = hasLessThan5DaysDiff(tableRows, index);
        const hasMessageCancel = hasCanceledItemWithQuantity(tableRows, index);
        const disabled = hasMessageStatus || hasMessageDate || (hasMessageCancel && item.cancelOrder);

        return (
          <>
            <TableTR disabled={disabled} borderedkey={`tr-${item.ov}-${item.itemOv}`}>
              {Object.keys(orderUpdateTableRowData).map(column => {
                const isSelected = item.cancelOrder;
                return (
                  <OrderUpdateTableColumnStyled
                    key={column}
                    textAlign='center'
                    editable={isStaticColumn(column)}
                    color={isSelected ? Color.AlertLight : Color.GrayLight}
                    selected={isSelected}
                  >
                    {
                      <OrderUpdateTableCell
                        type={orderUpdateTableRowData[column].type}
                        item={item}
                        column={column}
                        index={index}
                        disabled={disabled}
                      />
                    }
                  </OrderUpdateTableColumnStyled>
                );
              })}
            </TableTR>
            {hasMessageDate && <OrderUpdateWarningTR item={item} type='dateWarning' />}
            {hasMessageStatus && <OrderUpdateWarningTR item={item} type='statusWarning' />}
            {hasMessageCancel && item.cancelOrder && <OrderUpdateWarningTR item={item} type='cancelWarning' />}
            <TR>
              <TD colSpan={11}></TD>
            </TR>
          </>
        );
      })}
    </Table>
  );
};
