/* eslint-disable prettier/prettier */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TruckTrackingStepStatus } from '@app/models/truck-tracking.model';
import { formatInvoiceDate } from '@app/modules/truck-tracking/truck-tracking.utils';
import { Frame } from '@atomic/atm.frame';
import { H3 } from '@atomic/atm.typography';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { TravelStatus } from '../../../truck-tracking.interfaces';
import { Strings, ITruckTrackingStrings } from '../../../truck-tracking.string';
import { TruckTrackingStep } from '../truck-tracking-step/truck-tracking-step.component';

const strings: ITruckTrackingStrings = Strings.pt;
const { updates: updatesStrings } = strings;

export const TruckTrackingUpdate: React.FC = () => {
  const { truckTrackingInfo, generalInformations, trackingUpdateInformations } = useSelector(
    (state: RootState) => state.truckTracking,
  );
  const trackingShippingInfo =
    trackingUpdateInformations?.real?.shippingInformation || trackingUpdateInformations?.scheduled.shippingInformation;

  const isFinalizedTravel: boolean =
    generalInformations?.travelStatus === TravelStatus.Done ||
    generalInformations?.travelStatus === TravelStatus.Delivering;
  const trackingDeliveryInfo = isFinalizedTravel
    ? trackingUpdateInformations?.real?.deliveryInformation
    : trackingUpdateInformations?.scheduled.deliveryInformation;

  return (
    <Frame>
      <VSeparator />

      <Grid fluid>
        <H3>{updatesStrings.title}</H3>
        <VSeparator />

        <TruckTrackingStep
          title={updatesStrings.invoice(truckTrackingInfo?.invoice)}
          status={
            truckTrackingInfo.invoiceEmissionDate ? TruckTrackingStepStatus.Finished : TruckTrackingStepStatus.Pending
          }
          observation={updatesStrings.invoiceMessage(formatInvoiceDate(truckTrackingInfo?.invoiceEmissionDate))}
        />
        <VSeparator />

        <TruckTrackingStep
          title={trackingShippingInfo?.message}
          status={
            trackingUpdateInformations?.real.shippingInformation
              ? TruckTrackingStepStatus.Finished
              : TruckTrackingStepStatus.Current
          }
          observation={trackingShippingInfo?.date}
        />
        <VSeparator />

        <TruckTrackingStep
          title={isFinalizedTravel ? updatesStrings.delivered : updatesStrings.location}
          status={isFinalizedTravel ? TruckTrackingStepStatus.Finished : TruckTrackingStepStatus.Current}
          observation={
            isFinalizedTravel
              ? trackingDeliveryInfo?.date
              : updatesStrings.locationDetail(
                  trackingUpdateInformations?.currentLocation.city,
                  trackingUpdateInformations?.currentLocation.state,
                  trackingUpdateInformations?.currentLocation.remainingDistance,
                )
          }
        />
        <VSeparator />

        {!isFinalizedTravel && trackingDeliveryInfo?.date && (
          <TruckTrackingStep
            title={updatesStrings?.deliveryTime}
            status={TruckTrackingStepStatus.Pending}
            observation={trackingUpdateInformations?.scheduled?.deliveryInformation?.date}
          />
        )}
        <VSeparator />
      </Grid>
    </Frame>
  );
};
