import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { setSelectedDays, setShowDaySelect } from '@app/modules/components/schedule-modal/store/schedule.store';
import { Button } from '@atomic/atm.button';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { DaysOfMonthGrid, DayWrapper, FixedCalendarWrapper } from './fixed-calendar.style';

export const FixedCalendar: React.FC = () => {
  const { selectedDays } = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch();
  const [days, setDays] = useState<string[]>(selectedDays);

  const ref = useRef(null);
  const daysArray: string[] = [];

  for (let index = 1; index <= 31; index++) {
    daysArray.push(index.toString());
  }

  const onClick = (day: string, selected: boolean) => {
    if (selected) {
      const filteredDays = days.filter(selectedDay => selectedDay !== day);
      setDays(filteredDays);
    } else {
      const updatedDays = [...days];
      updatedDays.push(day);
      setDays(updatedDays);
    }
  };

  const onSelectDays = () => {
    dispatch(setSelectedDays(days));
    dispatch(setShowDaySelect(false));
  };

  return (
    <FixedCalendarWrapper ref={ref}>
      <FlexColumn pl={Spacing.Small} pr={Spacing.Small} pt pb hAlign='center'>
        <Body bold={FontWeight.Bold}>{strings.calendarMessage}</Body>
        <FlexRow pt={Spacing.Small} pb={Spacing.Small}>
          <DaysOfMonthGrid>
            {daysArray.map(day => {
              const selected = days.includes(day);
              return (
                <DayWrapper onClick={() => onClick(day, selected)} selected={selected} key={day}>
                  {day}
                </DayWrapper>
              );
            })}
          </DaysOfMonthGrid>
        </FlexRow>
        <FlexRow pb={Spacing.Small} pl pr>
          <Body fontSize={FontSize.XSmall}>{strings.calendarRules}</Body>
        </FlexRow>
        <Button size='small' kind='secondary' onClick={onSelectDays}>
          {strings.selectDaysButton}
        </Button>
      </FlexColumn>
    </FixedCalendarWrapper>
  );
};
