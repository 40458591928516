import styled from 'styled-components';
import { Color, FontFamily, Spacing } from '@atomic/obj.constants';

export const CustomerSearchFieldClientWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${Spacing.Small};

  & > div {
    width: 100%;
  }
`;

export const SelectSomeClientMessage = styled.span`
  font-family: ${FontFamily.Primary};
  color: ${Color.Alert};
  margin-bottom: ${Spacing.Medium};
  height: 20px;
`;

export const CustomerSearchModalHeaderClientWrapper = styled.div`
  padding-left: 48px;
  padding-bottom: ${Spacing.Medium};
`;
