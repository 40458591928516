import { AdminUserFilterOptionsId, AdminUserFilterOptionsLabel } from '@app/models/admin.model';

export const UserFilterOptions = [
  {
    value: AdminUserFilterOptionsId.Name,
    label: AdminUserFilterOptionsLabel.Name,
  },
  {
    value: AdminUserFilterOptionsId.Id,
    label: AdminUserFilterOptionsLabel.Id,
  },
  {
    value: AdminUserFilterOptionsId.Mail,
    label: AdminUserFilterOptionsLabel.Mail,
  },
  {
    value: AdminUserFilterOptionsId.Profile,
    label: AdminUserFilterOptionsLabel.Profile,
  },
  {
    value: AdminUserFilterOptionsId.Tab,
    label: AdminUserFilterOptionsLabel.Tab,
  },
];
