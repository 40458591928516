import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { AnnouncementModalDatasource } from '@app/data/http/announcement-modal-datasource';
import { AnnouncementModalEvents } from '@app/modules/components/announcement-modal/announcement-modal.analytics';
import { ONBOARDING_SECTION } from '@app/modules/components/announcement-modal/announcement-modal.constants';
import { SLICE_NAME } from '../navigation.constants';
import { INavigationState } from '../navigation.interface';

export const getOnboarding = createAsyncThunk(`${SLICE_NAME}/getOnboarding`, async (_, thunkAPI) => {
  return AnnouncementModalDatasource.getAnnouncements({ section: ONBOARDING_SECTION })
    .then(resp => resp)
    .catch(err => {
      LogAnalytics.error({
        tipo: AnnouncementModalEvents.GetAnnouncementError,
        message: err.response.status,
      });
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const addGetOnboardingReducers = builder => {
  builder.addCase(getOnboarding.pending, state => {
    state.loading = true;
  });
  builder.addCase(getOnboarding.fulfilled, (state: INavigationState, action) => {
    state.onboarding = action.payload;
    state.loading = false;
  });
  builder.addCase(getOnboarding.rejected, state => {
    state.loading = false;
  });
};
