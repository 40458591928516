export interface IOrderTabOpenStrings {
  lastUpdate: (date: string) => string;
  updating: string;
  title: string;
  filename: string;
  deliveryStatus: {
    delay: string;
    delayTrend: string;
    onTime: string;
    noForecast: string;
    notConfirmed: string;
    notAnalized: string;
    stockWaitingBilling: string;
    lackOfCharge: string;
  };
  status: {
    implanted: string;
    schedule: string;
    production: string;
    stored: string;
    confirmed: string;
  };
  actions: {
    orderUpdate: string;
    activateNotification: string;
    deactivateNotification: string;
    activateShippingConfig: string;
    deactivateShippingConfig: string;
    flagCriticalItems: string;
    editCriticalItems: string;
  };
  error: string;
  tooltip: {
    billed: string;
    stock: string;
    delivery: string;
    production: string;
    notAllocated: string;
    requested: string;
    confirmed: string;
  };
  criticalCheck: string;
  criticalCheckDesc: string;
  lackOfChargeCheck: string;
  lackOfChargeDesc: string;
}

interface IOrderTabOpenLanguages {
  pt: IOrderTabOpenStrings;
  es: IOrderTabOpenStrings;
  in: IOrderTabOpenStrings;
}

const pt: IOrderTabOpenStrings = {
  lastUpdate: date => `Última atualização em ${date}`,
  updating: 'Atualizando',
  title: 'Em aberto',
  filename: 'Pedidos em aberto',
  deliveryStatus: {
    delay: 'Em atraso',
    delayTrend: 'Tendência de atraso',
    onTime: 'Dentro do prazo',
    noForecast: 'Sem previsão - Consultar Gerdau',
    notConfirmed: 'Pedido analisado e não confirmado',
    notAnalized: 'Pedido ainda não analisado',
    stockWaitingBilling: 'Estoque aguardando faturamento',
    lackOfCharge: 'Falta de carga',
  },
  status: {
    implanted: 'Implantado',
    schedule: 'Em aprazamento',
    production: 'Em produção',
    stored: 'Em estoque',
    confirmed: 'Confirmado',
  },
  actions: {
    orderUpdate: 'Alteração de Carteira',
    activateNotification: 'Receber notificações',
    deactivateNotification: 'Remover notificações',
    activateShippingConfig: 'Programar geração de remessa',
    deactivateShippingConfig: 'Remover geração automática de remessa',
    flagCriticalItems: 'Sinalizar itens críticos',
    editCriticalItems: 'Editar itens críticos',
  },
  error: 'Ocorreu um erro ao carregar os dados da aba Em aberto! Por favor, tente novamente.',
  tooltip: {
    billed: 'Faturado',
    stock: 'Estoque',
    delivery: 'Remessa',
    production: 'Produção',
    notAllocated: 'Saldo',
    requested: 'Solicitado:',
    confirmed: 'Confirmado:',
  },
  criticalCheck: 'Críticos',
  criticalCheckDesc: 'Seus itens elencados como críticos aparecerão em negrito na tabela.',
  lackOfChargeCheck: 'Falta de carga',
  lackOfChargeDesc: 'Seus itens que estão marcados com falta de carga serão filtrados.',
};

const es: IOrderTabOpenStrings = {
  lastUpdate: date => `Última actualización en ${date}`,
  updating: 'Actualizando',
  title: 'En abierto',
  filename: 'Ordenes abiertas',
  deliveryStatus: {
    delay: 'Em atraso',
    delayTrend: 'Tendência de atraso',
    onTime: 'Dentro do prazo',
    noForecast: 'Sem previsão - Consultar Gerdau',
    notConfirmed: 'Pedido analisado e não confirmado',
    notAnalized: 'Pedido ainda não analisado',
    stockWaitingBilling: 'Estoque aguardando faturamento',
    lackOfCharge: 'Falta de carga',
  },
  status: {
    implanted: 'Implantado',
    schedule: 'En la fecha prevista',
    production: 'Producción',
    stored: 'En stock',
    confirmed: 'Confirmado',
  },
  actions: {
    orderUpdate: 'Alteração de Carteira',
    activateNotification: 'Receber notificações',
    deactivateNotification: 'Remover notificações',
    activateShippingConfig: 'Programar geração de remessa',
    deactivateShippingConfig: 'Remover geração automática de remessa',
    flagCriticalItems: 'Sinalizar itens críticos',
    editCriticalItems: 'Editar itens críticos',
  },
  error: '¡Ocurrió un error al cargar datos desde la pestaña Abrir! Inténtalo de nuevo.',
  tooltip: {
    billed: 'Facturado',
    stock: 'Stock',
    delivery: 'Transporte',
    production: 'Producción',
    notAllocated: 'No asignado',
    requested: 'Solicitado:',
    confirmed: 'Confirmado:',
  },
  criticalCheck: 'Crítico',
  criticalCheckDesc: 'Los elementos enumerados como críticos aparecerán en negrita en la tabla.',
  lackOfChargeCheck: 'Falta de carga',
  lackOfChargeDesc: 'Seus itens que estão marcados com falta de carga serão filtrados.',
};

const ing: IOrderTabOpenStrings = {
  lastUpdate: date => `Last update on ${date}`,
  updating: 'Updating',
  title: 'Opened',
  filename: 'Open orders',
  deliveryStatus: {
    delay: 'Em atraso',
    delayTrend: 'Tendência de atraso',
    onTime: 'Dentro do prazo',
    noForecast: 'Sem previsão - Consultar Gerdau',
    notConfirmed: 'Pedido analisado e não confirmado',
    notAnalized: 'Pedido ainda não analisado',
    stockWaitingBilling: 'Estoque aguardando faturamento',
    lackOfCharge: 'Falta de carga',
  },
  status: {
    implanted: 'Implanted',
    schedule: 'On schedule',
    production: 'In production',
    stored: 'In stock',
    confirmed: 'Confirmed',
  },
  actions: {
    orderUpdate: 'Alteração de Carteira',
    activateNotification: 'Receber notificações',
    deactivateNotification: 'Remover notificações',
    activateShippingConfig: 'Programar geração de remessa',
    deactivateShippingConfig: 'Remover geração automática de remessa',
    flagCriticalItems: 'Sinalizar itens críticos',
    editCriticalItems: 'Editar itens críticos',
  },
  error: 'An error occurred while loading data from the Open tab! Please try again.',
  tooltip: {
    billed: 'Billed',
    stock: 'Stock',
    delivery: 'Delivery',
    production: 'Production',
    notAllocated: 'Not allocated',
    requested: 'Requested:',
    confirmed: 'Confirmed:',
  },
  criticalCheck: 'Critics',
  criticalCheckDesc: 'Your items listed as critical will appear in bold in the table.',
  lackOfChargeCheck: 'Lack of charge',
  lackOfChargeDesc: 'Seus itens que estão marcados com falta de carga serão filtrados.',
};

export const Strings: IOrderTabOpenLanguages = {
  pt,
  es,
  in: ing,
};
