import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IScheduleClient } from '@app/models/schedule.model';
import { Badge } from '@app/modules/components/badge/badge.component';
import { bpsSelectedSelector } from '@app/modules/components/schedule-modal/store/schedule.selectores';
import { removeSelectedBpSchedule } from '../../../../../../store/schedule.store';
import { BadgesBpsSelectWrapper } from './badges-bps-selected.style';

export const BadgesBpsSelected: React.FC = () => {
  const bpsSelected: IScheduleClient[] = useSelector(bpsSelectedSelector);
  const dispatch = useDispatch();

  return (
    <BadgesBpsSelectWrapper>
      {bpsSelected.map(bp => (
        <Badge
          key={bp.code}
          text={`${bp.code} - ${bp.name} - ${bp.city}`}
          onClick={() => dispatch(removeSelectedBpSchedule(bp.code))}
        />
      ))}
    </BadgesBpsSelectWrapper>
  );
};
