import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AdminPermissionsTableWrapperStyled } from '../admin-profile-view.styles';
import { AdminProfileAdminRoleCol } from './partials/admin-profile-admin-role-col.component';
import { AdminProfileEppRoleCol } from './partials/admin-profile-epp-role-col.component';
import { AdminProfileGqRoleCol } from './partials/admin-profile-gq-role-col.component';
import { AdminProfilePedRoleCol } from './partials/admin-profile-ped-role-col.component';
import { AdminProfileRtcRoleCol } from './partials/admin-profile-rtc-role-col.component';
import { AdminProfileScmRoleCol } from './partials/admin-profile-scm-role-col.component';
import { AdminProfileSellerRoleCol } from './partials/admin-profile-seller-role-col.component';
import { AdminProfileSquadRoleCol } from './partials/admin-profile-squad-role-col.component';
import { AdminProfileTabCol } from './partials/admin-profile-tab-col.component';

export const AdminProfileList: React.FC = () => {
  const { tabChips } = useSelector((state: RootState) => state.admin);

  if (tabChips?.length) {
    return (
      <AdminPermissionsTableWrapperStyled>
        <AdminProfileTabCol />
        <AdminProfileEppRoleCol />
        <AdminProfilePedRoleCol />
        <AdminProfileRtcRoleCol />
        <AdminProfileGqRoleCol />
        <AdminProfileAdminRoleCol />
        <AdminProfileSellerRoleCol />
        <AdminProfileScmRoleCol />
        <AdminProfileSquadRoleCol />
      </AdminPermissionsTableWrapperStyled>
    );
  } else {
    return null;
  }
};
