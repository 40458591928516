import React from 'react';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { TooltipsWrapper } from '../../quote/components/quote.components.style';
import { OrdersStrings } from '../orders.string';

interface OnboardSelectionTooltipProps {
  onClose: () => void;
  onClick: () => void;
  visible: boolean;
}

export const OnboardSelectionTooltip: React.FC<OnboardSelectionTooltipProps> = props => {
  return (
    <TooltipsWrapper>
      <CustomizedTooltip
        step={1}
        totalSteps={4}
        title={OrdersStrings.pt.tooltips.selection.title}
        description={OrdersStrings.pt.tooltips.selection.description}
        visible={props.visible}
        onClick={props.onClick}
        onClose={props.onClose}
        customHeight={300}
        customLeftAlign={35}
        hasOffset
      />
    </TooltipsWrapper>
  );
};
