import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import { CustomerSelection } from '@app/providers';
import { marketMap } from '../customer-search.constants';
import { ICustomerSearchState, ICustomerPersistState, IConfirmSelectedBpsPayload } from '../customer-search.interfaces';
import { initialState } from './customer-persist.initial';

const reducers = {
  setIsMaritime: (state: ICustomerPersistState, { payload }: PayloadAction<boolean>) => {
    state.isMaritime = payload;
  },
  setBps: (state: ICustomerPersistState, { payload }: PayloadAction<CustomerSelection[]>) => {
    state.clientBps = payload;
  },
  setBpsSelected: (state: ICustomerPersistState, { payload }: PayloadAction<CustomerSelection[]>) => {
    state.clientBpsSelected = payload;
  },
  setCurrentMarket: (state: ICustomerPersistState, { payload }) => {
    state.currentMarket = payload;
  },
  confirmSelectedBpsAdmin: (state: ICustomerPersistState, { payload }: PayloadAction<IConfirmSelectedBpsPayload>) => {
    state.filterOption = payload.filterOptionSearch;
    state.query = payload.querySearch;
    state.corporateGroup = payload.corporateGroupSearch;
    state.salesOrgs = payload.salesOrgsSearch;
    state.salesGroup = payload.salesGroupSearch;
    state.currentMarket = marketMap[payload.filterOptionSearch.market];
    state.clientBps = payload.bpsFiltered;
    state.clientBpsSelected = payload.bpsFiltered.filter(bp => bp.selected);
  },
  confirmSelectedBpsClient: (state: ICustomerPersistState, { payload }: PayloadAction<ICustomerSearchState>) => {
    state.clientBps = payload.clientBpsSearch;
    state.clientBpsSelected = payload.clientBpsSearch?.filter(bp => bp.selected);
  },
  reset: () => {
    return initialState;
  },
};
export const customerIdsJoinedSelector = state =>
  state.customerPersist.clientBpsSelected.map(client => client.customerId).join(',');

const SLICE_NAME = 'customer';

const RESET_CUSTOMER = `${SLICE_NAME}/reset`;

export const RESET_CUSTOMER_ACTION = { type: RESET_CUSTOMER };

export const customerPersistSlice = createSlice<ICustomerPersistState, SliceCaseReducers<ICustomerPersistState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
});

export const {
  setIsMaritime,
  setCurrentMarket,
  setBps,
  setBpsSelected,
  confirmSelectedBpsAdmin,
  confirmSelectedBpsClient,
} = customerPersistSlice.actions;

const persistConfig = {
  key: 'customer-persist-reducer',
  storage,
};

export const customerPersistReducer = persistReducer(persistConfig, customerPersistSlice.reducer);
