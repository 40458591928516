import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppPath } from '@app/modules/app/route-constants';
import { hasCrmTabAccessSelector } from '@app/modules/auth/store/auth.selectores';
import { Tab } from '@atomic/mol.tab';
import { MenuQuoteWrapper } from '../quote/components/menu-quote-page.components.style';
import { QuoteComplainModal } from './quoteComplainModal';

interface MenuComplainProps {
  tabIndex: number;
}

export const MenuComplainPage = (props: MenuComplainProps) => {
  const [openComplain, setOpenComplain] = React.useState(false);
  const [complainTab, setComplainTab] = React.useState(props.tabIndex);
  const [showDateFilter, setShowDateFilter] = React.useState(false);
  const hasCrmTabAccess = useSelector(hasCrmTabAccessSelector);

  const callModal = index => {
    if (index === 0) {
      setShowDateFilter(false);
      setOpenComplain(true);
    } else {
      setShowDateFilter(!showDateFilter);
      setOpenComplain(false);
    }
    setComplainTab(index);
  };

  const handleCloseComplainModal = () => {
    setOpenComplain(false);
  };

  return (
    <MenuQuoteWrapper>
      {hasCrmTabAccess ? (
        <Tab key={0} initialIndex={complainTab} onIndexChanged={callModal}>
          <Tab.Item>Abrir Manifestação</Tab.Item>
          <Tab.Item to={`${AppPath.COMPLAIN.REPORT}`}>Gerar Relatório</Tab.Item>
          <Tab.Item to={`${AppPath.COMPLAIN.CRM}`}>CRM</Tab.Item>
        </Tab>
      ) : (
        <Tab key={0} initialIndex={complainTab} onIndexChanged={callModal}>
          <Tab.Item>Abrir Manifestação</Tab.Item>
          <Tab.Item to={`${AppPath.COMPLAIN.REPORT}`}>Gerar Relatório</Tab.Item>
        </Tab>
      )}
      <QuoteComplainModal openComplain={openComplain} setOpenComplain={handleCloseComplainModal} />
    </MenuQuoteWrapper>
  );
};
