import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const TechnicalAnalysisGeneralInfoWrapper = styled.div`
  padding: 16px;
  margin-bottom: 16px;
  & > div {
    align-items: center;
  }
  h3 {
    font-size: 14px;
  }
  span {
    margin-right: 10px;
  }
`;

export const AddFieldWrapper = styled.div`
  & > button {
    min-height: 24px;
    width: 24px;
    border-radius: 50%;
    padding: 0px 0px;
    margin-left: 16px;
  }
`;

export const EditBrandFieldWrapper = styled.div`
  & > button {
    min-height: 24px;
    width: 24px;
    border-radius: 50%;
    padding: 0px 0px;
    margin-left: 16px;
  }
`;

interface SelectBrandProps {
  brand: string;
}
export const SelectBrandFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > svg {
    margin-left: ${Spacing.Small};
    color: ${Color.Alert};
    cursor: pointer;
  }
  & > div > select {
    ${(props: SelectBrandProps) =>
      props?.brand === null && `border: 1px solid ${Color.Alert}; background-color: ${Color.AlertLight};`};
  }
`;

export const RemoveFieldWrapper = styled.div`
  & > button {
    min-height: 24px;
    width: 24px;
    border-radius: 50%;
    padding: 0px 0px;
    margin-left: 5px;
    background-color: transparent;
    color: ${Color.GrayDark};
    border-color: transparent;
    &:hover {
      background-color: transparent;
      color: ${Color.Alert};
      border-color: transparent;
    }
  }
`;
