import { LayoutTabs } from '@app/models/layout.model';

export const SLICE_NAME = 'grid-layout';

export const CUSTOMER_PURCHASE_COLUMN = 'customerPurchaseOrderItemNumber';

export const UNAVAILABLE_COLUMNS = ['checkboxSelection', 'qtyNotAllocated', 'qtyTotalStatus', 'activateCritical'];

export const GKN_COLUMNS = ['lastHeat', 'breakHeat', 'finish', 'hasQuantityLastHeat', 'qtyStockLatHeat'];

export const LAYOUT_TABS_TO_REDUCER_NAME = {
  [LayoutTabs.OpenMI]: 'orderTabOpen',
  [LayoutTabs.StockMI]: 'orderTabStock',
  [LayoutTabs.BilledMI]: 'orderTabBilled',
  [LayoutTabs.OpenME]: 'orderTabOpenInternational',
  [LayoutTabs.FrontierME]: 'orderTabFrontier',
  [LayoutTabs.ShippedME]: 'orderTabShippedRoad',
  [LayoutTabs.OpenMEMaritime]: 'orderTabOpenMaritime',
  [LayoutTabs.ShippedMEMaritime]: 'orderTabShippedMaritime',
  [LayoutTabs.HarborMEMaritime]: 'orderTabHarbor',
};
