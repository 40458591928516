import styled, { css } from 'styled-components';
import { Border, Color, Spacing, ZIndex } from '@atomic/obj.constants';
import { ARROW_DIMENSIONS, TOOLTIP_WIDTH } from './customized-tooltip.constants';

interface CustomizedTooltipWrapperProps {
  xAxis: number;
  yAxis: number;
  alignRight?: boolean;
  alignLeft?: number;
  positionYFromBottom?: boolean;
  positionXFromRight?: boolean;
  customWidth: number;
}

const positionYFromTop = css`
  top: ${(props: CustomizedTooltipWrapperProps) => props.yAxis}px;
`;

const positionYFromBottom = css`
  position: fixed;
  bottom: ${(props: CustomizedTooltipWrapperProps) => props.yAxis}px;
`;

const positionXFromLeft = css`
  left: ${(props: CustomizedTooltipWrapperProps) =>
    props.alignRight ? 'auto' : `${props.alignLeft ? props.alignLeft : props.xAxis}px`};
  right: ${(props: CustomizedTooltipWrapperProps) => (props.alignRight ? '0px' : 'auto')};
`;

const positionXFromRight = css`
  left: auto;
  right: ${(props: CustomizedTooltipWrapperProps) => (props.xAxis ? `${props.xAxis}px` : 'auto')};
`;

export const CustomizedTooltipWrapper = styled.div`
  position: absolute;
  cursor: default;

  ${(props: CustomizedTooltipWrapperProps) => (props.positionXFromRight ? positionXFromRight : positionXFromLeft)};
  ${(props: CustomizedTooltipWrapperProps) => (props.positionYFromBottom ? positionYFromBottom : positionYFromTop)};

  width: ${(props: CustomizedTooltipWrapperProps) =>
    props.customWidth ? `${props.customWidth}px` : `${TOOLTIP_WIDTH}px`};
  z-index: ${ZIndex.CustomizedTooltipWrapper};
  padding: ${Spacing.Medium};
  background-color: ${Color.Primary};
  border-radius: ${Border.Radius};
  padding-bottom: ${Spacing.Small};
`;

interface CustomizedTooltipArrowProps {
  xAxis: number;
  alignRight?: boolean;
  alignBottom?: boolean;
}

const arrowUpCss = css`
  border-bottom-color: ${Color.Primary};
  border-width: 0 ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px;
  top: -9px;
`;

const arrowDownCss = css`
  border-top-color: ${Color.Primary};
  border-width: ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px 0 ${ARROW_DIMENSIONS}px;
  top: 151px;
`;

export const CustomizedTooltipArrowStyled = styled.div`
  height: ${ARROW_DIMENSIONS}px;
  width: ${ARROW_DIMENSIONS}px;
  border-color: transparent;
  border-style: solid;
  position: absolute;
  transform-origin: center bottom;
  ${(props: CustomizedTooltipArrowProps) => (props.alignBottom ? arrowDownCss : arrowUpCss)};
  left: ${(props: CustomizedTooltipArrowProps) => (props.alignRight ? 'auto' : `${props.xAxis}px`)};
  right: ${(props: CustomizedTooltipArrowProps) => (props.alignRight ? '20px' : 'auto')};
`;
