import React from 'react';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';

interface CustomizedTooltipBodyProps {
  description: string;
  fontSize: string;
}

export const CustomizedTooltipBody: React.FC<CustomizedTooltipBodyProps> = ({ description, fontSize }) => {
  if (!description) {
    return null;
  }

  return (
    <>
      <FlexRow>
        <FlexColumn hAlign='flex-start'>
          <Body size={fontSize} color={Color.White}>
            {description}
          </Body>
        </FlexColumn>
      </FlexRow>
      <VSeparator />
    </>
  );
};
