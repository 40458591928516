import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { CSATUserModule } from '@app/data/http/admin-settings.datasource';
import { useResetCSATRequest } from '@app/domain';
import { FlashMessageTargetName } from '@app/providers';
import { Frame } from '@atomic/atm.frame';
import { Body, H1, H3 } from '@atomic/atm.typography';
import { Tab } from '@atomic/mol.tab';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { useFlashMessage } from '../components/flash-message.hook';
import { ResetConfirmModal } from './admin-reset-confirm-modal.component';
import { AdminSettingsCards } from './admin-settings-card.component';
import { CSATModule, CSATModuleType, funcionalitiesData, informationData } from './admin-settings.modules';
import { Strings, IAdminSettingsStrings } from './admin-settings.string';

export const AdminSettingsPage: React.FC = () => {
  const strings: IAdminSettingsStrings = Strings.pt;

  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [modalType, setModalType] = useState(null);
  const [moduleReset, setModuleReset] = useState<CSATModule>(null);
  const { csatUser } = useSelector((state: RootState) => state.csat);

  const handleSuccess = () => {
    show('success', strings.success);
    setModalType(null);
  };

  const handleError = () => {
    show('warning', strings.error);
    setModalType(null);
  };

  const { performRequest, loading } = useResetCSATRequest(handleSuccess, handleError);

  const handleSubmit = () => {
    performRequest({
      functionality: moduleReset.toString(),
    });
  };

  return (
    <>
      <Frame>
        <Grid fluid>
          <VSeparator />

          <H1 noMargin>{strings.title}</H1>
          <Body>{strings.subTitle}</Body>
          <VSeparator />

          <Tab initialIndex={0}>
            <Tab.Item>{strings.tabs.customerSatisfaction}</Tab.Item>
          </Tab>
        </Grid>
      </Frame>
      <VSeparator />
      <Grid fluid>
        <H3>{strings.functionalities.title}</H3>
        <VSeparator />

        <Row>
          {funcionalitiesData.map(item => {
            const module: CSATUserModule = csatUser.find(csat => csat.functionality === item);

            return (
              <Col key={item} xs={12} md={6}>
                <AdminSettingsCards
                  type={CSATModuleType.funcionalities}
                  csatModule={item}
                  csatLastResetDate={module?.resetDate}
                  onClick={() => {
                    setModalType(CSATModuleType.funcionalities);
                    setModuleReset(item);
                  }}
                />
                <VSeparator />
              </Col>
            );
          })}
        </Row>
        <VSeparator />

        <H3>{strings.information.title}</H3>
        <VSeparator />

        <Row>
          {informationData.map(item => {
            const module: CSATUserModule = csatUser.find(csat => csat.functionality === item);

            return (
              <Col key={item} xs={12} md={6}>
                <AdminSettingsCards
                  type={CSATModuleType.information}
                  csatModule={item}
                  csatLastResetDate={module?.resetDate}
                  onClick={() => {
                    setModalType(CSATModuleType.information);
                    setModuleReset(item);
                  }}
                />
                <VSeparator />
              </Col>
            );
          })}
        </Row>
        {/* <H3>{strings.nps.title}</H3>
        <VSeparator /> */}

        {/* <Row>
          <Col xs={12} md={6}>
            <AdminSettingsCards
              type={CSATModuleType.nps}
              csatLastResetDate={csatUser.find(csat => csat.functionality === CSATModule.nps)?.resetDate}
              csatModule={CSATModule.nps}
              onClick={() => {
                setModalType(CSATModuleType.nps);
                setModuleReset(CSATModule.nps);
              }}
            />
            <VSeparator />
          </Col>
        </Row> */}
      </Grid>
      <ResetConfirmModal
        loading={loading}
        modalType={modalType}
        onClose={() => setModalType(null)}
        onConfirm={() => handleSubmit()}
      />
    </>
  );
};
