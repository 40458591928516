import { HelpCenterSubsectionKeys, HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterImplantationSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Implantação',
      key: HelpCenterSectionKeys.Implantation,
      subsections: [
        {
          title: 'Via seleção de itens',
          alternativeTitle: 'Implantação de pedidos via seleção de itens',
          key: HelpCenterSubsectionKeys.ViaItenSelection,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml: 'Basta ir na aba <b>Implantação</b>, selecionar a opção via <b>Seleção de itens</b>.',
              media: {
                path: 'assets/img/help-center/implantation/item-selection/order-via-item-01.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml: `Na etapa 1, selecionar o <b>Emissor</b>, <b>Recebedor</b> e <b>Número do pedido</b>. <br />
                Na etapa 2, selecionar o material através do <b>Part Number</b> (Código Produto Cliente) ou <b>Descrição do material</b>.`,
              media: {
                path: 'assets/img/help-center/implantation/item-selection/order-via-item-02.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Na tela seguinte, preencher <b>Quantidade</b> e <b>Data</b>, além da <b>Unidade de medida</b> e <b>Item de pedido</b> (opcional).',
              media: {
                path: 'assets/img/help-center/implantation/item-selection/order-via-item-03.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml: 'Fazer a revisão das informações preenchidas e <b>confirmar</b> a solicitação.',
              media: {
                path: 'assets/img/help-center/implantation/item-selection/order-via-item-04.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'O CS vai receber um e-mail com a sua solicitação e você irá receber um PDF com a confirmação do envio do pedido.',
              media: {
                path: 'assets/img/help-center/implantation/item-selection/order-via-item-05.png',
                type: 'stretch-image',
              },
            },
          ],
        },
        {
          title: 'Via planilha',
          alternativeTitle: 'Implantação de pedidos via planilha',
          key: HelpCenterSubsectionKeys.ViaSpreadSheet,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml:
                'A implantação de pedidos via planilha é uma funcionalidade que garante mais agilidade na implantação de uma grande quantidade de itens através de um template de planilha oferecido pelo portal, ou através da sua planilha de pedidos padrão, você só precisará fazer uma breve configuração como explicaremos à seguir: <br /><br />Na aba de <b>Implantação</b>, selecione a opção "<b>Solicite um pedido via planilha</b>"',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-1.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml: 'Em seguida, selecione o <b>Emissor do pedido</b> e o <b>Recebedor do pedido.</b>',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-2.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Agora você deve escolher qual nomenclatura deseja para os materiais que você está implantando, seja por <b>Código de produção do cliente (Part number)</b> ou através dos Códigos de material Gerdau nosso catálogo que você poderá baixar na seção em destaque.',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-3.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Na etapa 3 você tem duas opções, a primeira seria fazer o download do nosso <b>Modelo de planilha</b> e preencher as informações dos materiais nos campos já determinados de A à F.',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-4.png',
                type: 'stretch-image',
              },
            },
            {
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-5.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Em seguida você deve fazer o upload dessa planilha no campo em destaque e clicar em <b>avançar.</b>',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-6.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'A segunda opção seria fazer o upload da planilha da sua própria empresa e fazer a <b>Configuração da planilha</b> para que o nosso sistema consiga ler em que campos estão preenchidas as informações necessárias para fazer a implantação como veremos à seguir:',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-7.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'É bem simples, basta você nos informar em quais colunas da sua planilha se encontram as informações de: Código material cliente, Quantidade, Unidade de medida, Datas desejada, Nº do pedido, Item do pedido (opcional) e em qual linha da planilha começam essas informações e ao finalizar o preenchimento, clicar em <b>salvar.</b>',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-8.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Agora você deve fazer o upload da sua planilha no campo de upload da janela anterior e clicar em <b>avançar.</b>',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-9.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Na tela seguinte você irá fazer a conferência de tudo o que foi implantado, podendo fazer a alterações caso seja necessário. Após essa etapa, clique em <b>ir para a revisão do pedido.</b>',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-10.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Caso opte por clicar em <b>Voltar</b>, saiba que as edições colocadas nesse pedido serão perdidas e a planilha será revalidada.',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-13.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Na etapa de revisão, você tem a oportunidade de reavaliar todo o seu pedido. Caso esteja tudo certo, basta clicar em <b>Confirmar pedido.</b> Se ainda deseja fazer alguma alteração, retorne à etapa anterior para realizar as edições no botão <b>Voltar (Nesse caso as edições feitas ainda estarão salvas).</b>',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-11.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Ao final uma tela de confirmação irá aparecer informando que sua solicitação de pedidos foi concluída. Você poderá baixar um PDF com todo o detalhamento ou seguir para a implantação de um novo pedido.',
              media: {
                path: 'assets/img/help-center/implantation/upload-spreadsheet/upload-by-spreadsheet-12.png',
                type: 'stretch-image',
              },
            },
          ],
        },
      ],
    }
  );
};
