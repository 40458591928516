import * as React from 'react';
import { TruckTrackingStepStatus } from '@app/models/truck-tracking.model';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { TruckTrackingStepIconStyled, TruckTrackingStepIconWrapperStyled } from '../../../truck-tracking.style';

interface TruckTrackingStepProps {
  title: string;
  status?: TruckTrackingStepStatus;
  observation: string;
}

const StepIcon: React.FC<{ status: TruckTrackingStepStatus }> = ({ status }) => {
  switch (status) {
    case TruckTrackingStepStatus.Finished:
      return <FaIcon.Check size='sm' color={Color.White} />;
    case TruckTrackingStepStatus.Current:
      return <FaIcon.ChevronRight size='sm' color={Color.White} />;
    default:
      return <FaIcon.Close size='sm' color={Color.Gray} />;
  }
};

export const TruckTrackingStep: React.FC<TruckTrackingStepProps> = ({ title, status, observation = ' - ' }) => (
  <Hbox vAlign='center'>
    {status && (
      <>
        <Hbox.Item noGrow>
          <TruckTrackingStepIconStyled status={status}>
            <TruckTrackingStepIconWrapperStyled>
              <StepIcon status={status} />
            </TruckTrackingStepIconWrapperStyled>
          </TruckTrackingStepIconStyled>
        </Hbox.Item>
        <Hbox.Separator small />
      </>
    )}
    <Hbox.Item>
      <Body>{title}</Body>
    </Hbox.Item>
    <Hbox.Item noGrow>
      <Body>{observation}</Body>
    </Hbox.Item>
  </Hbox>
);
