import styled from 'styled-components';
import { Color, Spacing, ZIndex } from '@atomic/obj.constants';

interface NonBindingCreditTipWrapperStyledProps {
  show: boolean;
}

export const NonBindingCreditTipWrapperStyled = styled.div`
  opacity: ${(props: NonBindingCreditTipWrapperStyledProps) => (props.show ? 1 : 0)};
  max-height: ${(props: NonBindingCreditTipWrapperStyledProps) => (props.show ? '90px' : 0)};
  z-index: ${ZIndex.DashboardHeaderWrapperStyled};
  position: absolute;
  background-color: ${Color.White};
  width: ${(props: NonBindingCreditTipWrapperStyledProps) => (props.show ? '250px' : 0)};
  right: 0px;
  top: 32px;
  padding: ${Spacing.Medium};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  height: 90px;
`;
