import * as React from 'react';
import { Cell } from '@atomic/atm.cell';
import { H4 } from '@atomic/atm.typography';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { LoadingState } from '@atomic/obj.loading-state';
import {
  TechnicalAnalysisUserListStyled,
  TechnicalAnalysisUserListWrappperStyled,
  UserListStyled,
  UserListWrapperStyled,
} from './technical-analysis-status.style';

interface TechnicalAnalysisUserProps {
  users: string[];
  show: boolean;
  loading: boolean;
  onSelect: (user: string) => void;
  onClose: () => void;
}

export const TechnicalAnalysisStatusUserList: React.FC<TechnicalAnalysisUserProps> = props => {
  const menuRef = React.useRef(null);

  const handleMouseDown = (event: MouseEvent) => {
    if (props.show && menuRef && !menuRef.current?.contains(event.target)) {
      props.onClose();
    }
  };
  useEventListener('mousedown', handleMouseDown);

  return (
    <TechnicalAnalysisUserListWrappperStyled>
      <TechnicalAnalysisUserListStyled ref={menuRef} show={props.show}>
        <LoadingState loading={props.loading} data={props.users?.length > 0}>
          <LoadingState.Shimmer>{/* <TechnicalAnalysisUserShimmer /> */}</LoadingState.Shimmer>

          <UserListWrapperStyled>
            <UserListStyled>
              {props.users?.map(user => (
                <Cell hover key={`user-${user}`} onClick={() => props.onSelect(user)}>
                  <H4>{user}</H4>
                </Cell>
              ))}
            </UserListStyled>
          </UserListWrapperStyled>
        </LoadingState>
      </TechnicalAnalysisUserListStyled>
    </TechnicalAnalysisUserListWrappperStyled>
  );
};
