import * as React from 'react';
import { DrawerMenuStyled, DrawerOverlayStyled, DrawerStyled } from './drawer.component.style';

export interface DrawerProps {
  active?: boolean;
  onClick?: () => void;
  right?: boolean;
  large?: boolean;
}

export class Drawer extends React.Component<DrawerProps, undefined> {
  static defaultProps = {
    active: false,
  };

  render() {
    return (
      <DrawerStyled active={this.props.active}>
        <DrawerMenuStyled active={this.props.active} right={this.props.right} large={this.props.large}>
          {this.props.children}
        </DrawerMenuStyled>
        <DrawerOverlayStyled active={this.props.active} onClick={this.props.onClick ? this.props.onClick : null} />
      </DrawerStyled>
    );
  }
}
