import styled, { css } from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Border, Color, IconSize, Spacing, TransitionDuration } from '@atomic/obj.constants';

export interface SectionCellStyledProps {
  hover?: boolean;
  selected?: boolean;
  hasClick?: boolean;
}

export const SectionCellStyled = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  white-space: nowrap;
  text-decoration: none;
  padding: ${Spacing.Medium};
  border: 1px solid;
  border-color: ${Border.Color};
  ${(props: SectionCellStyledProps) => props.hasClick && 'cursor: pointer;'}

  ${(props: SectionCellStyledProps) =>
    props.hover &&
    `
    &:hover {
      background-color: ${Color.Secondary};
    }
  `}

  background-color: ${(props: SectionCellStyledProps) => (props.selected ? Color.Secondary : 'transparent')};

  & + & {
    border-top: 0;
  }
  & > a {
    width: 100%;
  }
`;

export const SectionCellIconStyled = css`
  position: absolute;
  right: 0;
  transition: ${TransitionDuration};
  text-align: center;
  font-size: ${IconSize.Medium};
  vertical-align: middle;
  margin-right: ${Spacing.Medium};
`;

export const SectionCellShowStyled = styled(FaIcon.ChevronUp)`
  ${SectionCellIconStyled};
  color: ${Color.Primary};
`;

export const SectionCellHideStyled = styled(FaIcon.ChevronDown)`
  ${SectionCellIconStyled};
  color: ${Color.Primary};
`;

export const SectionListWrapperStyled = styled.ul`
  margin: 0;
`;

export const ImageWrapper = styled.div`
  & > img {
    max-width: 820px;
  }
`;

export const StretchImageWrapper = styled.div`
  & > img {
    max-width: 100%;
  }
`;
