import ColorFunc from 'color';
import styled, { css } from 'styled-components';
import { MarginPaddingCSS } from '@atomic/obj.box';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

interface TextProps {
  white?: boolean;
  color?: string;
  center?: boolean;
  noMargin?: boolean;
}

export const HDisplay = styled.label`
  color: ${Color.Black};
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
`;
HDisplay.displayName = 'HDisplay';

export const H1 = styled.h1`
  color: ${Color.GrayXDark};
  font-size: ${FontSize.XLarge};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  margin: ${(props: TextProps) => (props.noMargin ? 0 : `${Spacing.XLarge} 0 ${Spacing.Small} 0`)};
`;

export const H1Sub = styled.p`
  color: ${Color.GrayXDark};
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  margin: ${(props: TextProps) => (props.noMargin ? 0 : `${Spacing.Small} 0 ${Spacing.Medium} 0`)};
`;

export const H2 = styled.h2`
  color: ${(props: TextProps) => (props.color ? props.color : Color.Black)};
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  ${(props: TextProps) => props.center && 'text-align: center'};
  margin: ${(props: TextProps) => (props.noMargin ? 0 : `${Spacing.Large} 0 ${Spacing.Medium} 0`)};
`;
H2.displayName = 'H2';

export const H3Height = FontSize.Medium;
export const H3 = styled.h3`
  color: ${(props: TextProps) => (props.color ? props.color : Color.Black)};
  font-size: ${H3Height};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  margin: 0;
  ${(props: TextProps) => props.center && 'text-align: center'};
`;
H3.displayName = 'H3';

export const H4Height = FontSize.XSmall;
export const H4 = styled.h4`
  margin: 0;
  color: ${(props: TextProps) => (props.white ? Color.White : Color.Black)};
  font-size: ${H4Height};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
`;
H4.displayName = 'H4';

interface BodyProps {
  noWrap?: boolean;
  color?: boolean;
  bold?: string;
  centralized?: boolean;
  align: string;
  size?: string;
  fullWidth?: boolean;
}
export const Body = styled.p`
  display: flex;
  align-items: center;
  text-transform: ${(props: { capitalize?: boolean }) => (props.capitalize ? 'capitalize' : 'none')};
  color: ${(props: BodyProps) => (props.color ? props.color : Color.GrayDark)};
  margin: 0;
  font-size: ${(props: BodyProps) => (props?.size ? props?.size : FontSize.Small)};
  font-family: ${FontFamily.Primary};
  font-weight: ${(props: BodyProps) => (props.bold ? props.bold : FontWeight.Normal)};
  white-space: ${(props: BodyProps) => (props.noWrap ? 'nowrap' : 'normal')};
  justify-content: ${(props: BodyProps) => (props.centralized ? 'center' : 'initial')};
  text-align: ${(props: BodyProps) => props.align || 'left'};
  width: ${(props: BodyProps) => (props.fullWidth ? '100%' : 'auto')};
  ${MarginPaddingCSS};
`;
Body.displayName = 'Body';

export const BodySecondaryCss = css`
  color: ${(props: BodyProps) => (props.color ? props.color : Color.GrayDark)};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  line-height: 1.5;
  margin: 0;
`;

export const BodySecondary = styled.label`
  ${BodySecondaryCss}
`;

export const ButtonText = styled(Body)`
  color: ${Color.Primary};
  font-weight: ${FontWeight.Medium};
  cursor: pointer;

  :hover {
    color: ${ColorFunc(Color.Accessory)
      .darken(0.2)
      .hsl()
      .string()};
  }
`;

interface BodyChatProps {
  hasDescription: boolean;
}

export const BodyChat = styled.p`
  color: ${Color.GrayDark};
  word-wrap: break-word;
  max-width: 100vh;
  font-size: ${FontSize.Large};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  line-height: 30px;
  margin: 0;
  margin-bottom: ${(props: BodyChatProps) => (props.hasDescription ? 0 : Spacing.Medium)};
`;

export const BodySecondaryChat = styled.p`
  color: ${Color.GrayDark};
  word-wrap: break-word;
  max-width: 100vh;
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  line-height: 30px;
  margin: 0;
  margin-bottom: ${Spacing.XSmall};
`;

export const BodySecondaryCentered = styled(BodySecondary)`
  text-align: center;
`;

interface CaptionProps {
  medium: boolean;
  textAlign?: 'center' | 'left' | 'right';
}

export const Caption = styled.p`
  color: ${Color.GrayXDark};
  font-size: ${(props: CaptionProps) => (props.medium ? FontSize.Medium : FontSize.XSmall)};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin: 0;
  ${MarginPaddingCSS};
  text-align: ${(props: CaptionProps) => (props.textAlign ? props.textAlign : 'left')};
`;

interface InputLabelProps {
  hasError?: boolean;
  small?: boolean;
}
export const InputLabel = styled.label`
  color: ${(props: InputLabelProps) => (props.hasError ? Color.Alert : Color.GrayXDark)};
  display: block;
  font-size: ${(props: InputLabelProps) => (props.small ? FontSize.XSmall : FontSize.Small)};
  font-family: ${FontFamily.Primary};
  font-weight: ${props => (props.weight ? props.weight : FontWeight.Medium)};
  margin-bottom: ${Spacing.XSmall};
  & > span {
    font-size: ${FontSize.XSmall};
    font-weight: normal;
    color: ${Color.GrayDark};
  }
  ${MarginPaddingCSS};
`;

export const InputValueCss = css`
  color: ${Color.Black};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;

export const InputValue = styled.label`
  ${InputValueCss};
`;

export const InputDisabledCss = css`
  color: ${Color.GrayDark};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};

  /* https://stackoverflow.com/a/45209441/3670829 */
  -webkit-text-fill-color: ${Color.GrayDark};
  -webkit-opacity: 1;
`;

export const InputDisabled = styled.label`
  ${InputDisabledCss};
`;

export const InputCaption = styled.label`
  color: ${Color.GrayXDark};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  margin-top: ${Spacing.XSmall};
  display: block;
`;

export const InputCaptionError = styled(InputCaption)`
  color: ${Color.Alert};
`;

export const InputPlaceholderCss = css`
  color: ${Color.GrayDark};
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
`;

export const InputPlaceholder = styled.label`
  pointer-events: none;
  ${InputPlaceholderCss};
`;

export const ListItem = styled.li`
  ${BodySecondaryCss};
`;

export const ProductPricePrefix = styled.span`
  color: ${Color.GrayDark};
  font-weight: ${FontWeight.Bold};
`;

export interface PriceProps {
  isFeatured?: boolean;
}

export const ProductPrice = styled.label`
  color: ${(props: PriceProps) => (props.isFeatured ? Color.Alert : Color.Primary)};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
`;

export const ProductOldPrice = styled.label`
  color: ${Color.GrayDark};
  text-decoration: line-through;
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Lighter};
`;

export const DT = styled.dt`
  display: block;
  color: ${Color.GrayXDark};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
`;

interface DLProps {
  horizontal?: boolean;
  hAlign?: string;
}

export const DL = styled.dl`
  flex-direction: ${(props: DLProps) => (props.horizontal ? 'row' : 'column')};
  ${(props: DLProps) => {
    if (props.hAlign) {
      return props.horizontal ? `justify-content: ${props.hAlign}` : `align-items: ${props.hAlign}`;
    }
    return null;
  }};
  display: flex;
  margin: 0;
`;

export const DD = styled.dd`
  padding-inline-start: ${Spacing.XSmall};
  display: block;
  color: ${Color.GrayDark};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin: 0;
`;

// Utilities

export const Ellipsed = styled.span`
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Label = styled.label`
  font-size: ${props => (props.size ? props.size : FontSize.Medium)};
  font-weight: ${props => (props.weight ? props.weight : FontWeight.Medium)};
  font-family: ${FontFamily.Primary};
  color: ${props => (props.color ? props.color : Color.GrayDark)};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  ${MarginPaddingCSS};
`;
