import { createAsyncThunk } from '@reduxjs/toolkit';
import { LayoutDataSource } from '@app/data/http/layout.datasource';
import { LayoutModel } from '@app/models/layout.model';
import { GridColumnDefinition } from '@app/modules/components';
import { manageFieldsGKN, removeFilterGKN } from '../grid-layout-special-columns/GKN/gkn-special-columns';
import { manageFieldsTHYSSENKRUPP } from '../grid-layout-special-columns/THYSSENKRUPP/thyssenkrupp-special-columns';
import { SLICE_NAME } from '../grid-layout.constants';

export const getGridLayout = createAsyncThunk(`${SLICE_NAME}/get`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const clientBpsSelected = state.customerPersist.clientBpsSelected;
  const language = state.auth.userInfo.language;

  return await LayoutDataSource.getLayout({ language })
    .then((data: LayoutModel) => ({
      data,
      clientBpsSelected,
    }))
    .catch(err => err.message);
});

export const addGetGridLayoutReducers = builder => {
  builder.addCase(getGridLayout.pending, state => {
    state.loading = true;
  });
  builder.addCase(getGridLayout.fulfilled, (state, action) => {
    const tabs: GridColumnDefinition[] = action.payload.data?.tabs;
    const clientBpsSelected = action.payload.clientBpsSelected;
    let configuredTabs = [];

    configuredTabs = manageFieldsTHYSSENKRUPP(tabs, clientBpsSelected);
    configuredTabs = manageFieldsGKN(configuredTabs, clientBpsSelected);
    removeFilterGKN(configuredTabs, clientBpsSelected);

    state.toogleVisible = !state.toogleVisible;

    state.loading = false;
    state.tabs = configuredTabs;
  });
  builder.addCase(getGridLayout.rejected, state => {
    state.loading = false;
  });
};
