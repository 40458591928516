import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { InversedAttributeStatus } from '@app/models/quote.model';
import { rangeLimits } from '@app/modules/chat/chat.contants';
import { tableStatusOptions } from '@app/modules/quote/quote.contants';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Color } from '@atomic/obj.constants';
import { QuoteStrings } from '../../quote.string';
import { AttributeSpecificProps } from './technical-analysis-attribute.component';
import { TechnicalAnalysisAttributeContext } from './technical-analysis-attribute.context';

const status = {
  key: 'status',
  label: 'Status',
  type: 'number',
  isVisible: true,
  isEditable: false,
  isDeletable: false,
};

const Option = ({ value, name }) => <option value={value}>{name}</option>;

export const TechnicalAnalysisTableAttribute: React.FC<AttributeSpecificProps> = props => {
  const {
    isEditing,
    editedStatus,
    rowsStatus,
    onRowStatusChanged,
    onRowReasonChanged,
    onRowSuggestionChanged,
    onRowMinChanged,
    onRowMaxChanged,
  } = React.useContext(TechnicalAnalysisAttributeContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const attribute = { ...props.attribute };
  const isRangeDerogate = attribute.attributeValue.columns.some(item => item.type === 'range');
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.tableAttribute;

  const columns = attribute.attributeValue.columns.filter(
    column => !column.isDerogateColumn || (!isEditing && column.isVisible),
  );

  let unitJominy = '';
  const rows = attribute.attributeValue.rows.map(row => {
    return row
      .filter((_, index) => !!columns[index]?.key)
      .map(item => {
        if (item.columnRef === 'unit') {
          unitJominy = item.value;
        }
        return {
          key: item.key,
          columnRef: item.columnRef,
          value:
            typeof item.value === 'string' || typeof item.value === 'number'
              ? item.columnRef === 'unit' && attribute.key === 'HARDENABILITY'
                ? 'HRC'
                : item.value
              : `Min: ${item.value.min} - Max: ${item.value.max}`,
          colSpan: 1,
          rowSpan: 1,
        };
      });
  });

  const checkStatusOption = options => {
    if (editedStatus === 'non-analysed' || editedStatus === 'accepted') {
      return options.filter(item => item.value !== 'derogate');
    } else {
      return options;
    }
  };

  return (
    <Table collapse>
      <TR bordered>
        {columns.map(column => (
          <TD key={column?.key} color={Color.Secondary}>
            <Body>
              {column.label}
              {attribute.key === 'HARDENABILITY' && column?.key === 'points' && ` (${unitJominy})`}
            </Body>
          </TD>
        ))}
        {isEditing && (
          <>
            <TD key={status?.key} color={Color.Secondary}>
              <Body>{status.label}</Body>
            </TD>
            {rowsStatus.some(rowStatus => rowStatus.status === InversedAttributeStatus.derogate) && (
              <>
                <TD key='reason' color={Color.Secondary}>
                  <Body>{strings.reason}</Body>
                </TD>
                {!isRangeDerogate ? (
                  <TD key={'suggestion'} color={Color.Secondary}>
                    <Body>{strings.suggestion}</Body>
                  </TD>
                ) : (
                  <>
                    <TD key={'min'} color={Color.Secondary}>
                      <Body>{strings.min}</Body>
                    </TD>
                    <TD key={'max'} color={Color.Secondary}>
                      <Body>{strings.max}</Body>
                    </TD>
                  </>
                )}
              </>
            )}
          </>
        )}
      </TR>
      {rows.map((row, index) => {
        const statusValue =
          rowsStatus[index]?.status !== InversedAttributeStatus['non-status']
            ? rowsStatus[index]?.status
            : InversedAttributeStatus['non-analysed'];
        return (
          <TR key={index} bordered>
            {row.map(element => {
              const value =
                element?.columnRef === status.key
                  ? QuoteStrings[userInfo.language].constants.status.label[element.value]
                  : element?.value;
              return (
                <TD key={element?.key + element.columnRef}>
                  <Body>{value === rangeLimits.max ? '-' : value}</Body>
                </TD>
              );
            })}
            {isEditing && (
              <>
                <TD key={status?.key + index}>
                  <SelectField value={statusValue} onValueChange={value => onRowStatusChanged(value, index)}>
                    {checkStatusOption(tableStatusOptions).map((option, optionIndex) => (
                      <Option key={optionIndex} value={option.value} name={option.name} />
                    ))}
                  </SelectField>
                </TD>
                {rowsStatus[index]?.status === InversedAttributeStatus.derogate && (
                  <>
                    <TD key={'reason' + index}>
                      <TextField
                        value={rowsStatus[index]?.reason}
                        onValueChange={value => onRowReasonChanged(`${value}`, index)}
                        invalid={isRangeDerogate && !rowsStatus[index]?.reason}
                      />
                    </TD>
                    {!isRangeDerogate ? (
                      <TD key={'suggestion' + index}>
                        <TextField
                          value={rowsStatus[index]?.suggestion}
                          onValueChange={value => onRowSuggestionChanged(`${value}`, index)}
                        />
                      </TD>
                    ) : (
                      <>
                        <TD key={'min' + index}>
                          <TextField
                            small
                            type='number'
                            value={rowsStatus[index]?.minSuggestion}
                            onValueChange={value => onRowMinChanged(`${value}`, index)}
                            invalid={rowsStatus[index]?.minSuggestion > rowsStatus[index]?.maxSuggestion}
                          />
                        </TD>
                        <TD key={'max' + index}>
                          <TextField
                            small
                            type='number'
                            value={rowsStatus[index]?.maxSuggestion}
                            onValueChange={value => onRowMaxChanged(`${value}`, index)}
                            invalid={rowsStatus[index]?.minSuggestion > rowsStatus[index]?.maxSuggestion}
                          />
                        </TD>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </TR>
        );
      })}
    </Table>
  );
};
