import { format } from 'date-fns';
import { StatusInformation, TrackingInformations } from '@app/models/truck-tracking.model';
import { Strings, ITruckTrackingStrings } from '@app/modules/truck-tracking/truck-tracking.string';
import { dateParser } from '@app/utils/date-parser';

export interface GetTrackingInformationResponse {
  tracking: TrackingDTO;
  order: OrderDTO;
}

interface OrderDTO {
  nameClient: string;
  codMaterialClient: string;
  descMaterial: string;
  invoice: string;
  ocClient: string;
  invoiceEmissionDate: string;
  brand: string;
}

interface TrackingDTO {
  typeTransportation: string;
  plate: string;
  plateCar: string;
  cityCurrent: string;
  stateCurrent: string;
  truckDateCurrent: string;
  latitudeCurrent: string;
  longitudeCurrent: string;
  originLatitude: string;
  originLongitude: string;
  targetLatitude: string;
  targetLongitude: string;
  targetNeighborhood: string;
  targetState: string;
  targetCity: string;
  forecastStart: string;
  arrivalForecast: string;
  realStart: string;
  realArrival: string;
  estimatedArrival: string;
  kmRemaining: string;
  notes: InvoiceDTO;
  vehicleLastUpdate: string;
  travelStatus: string;
}

interface InvoiceDTO {
  note: {
    number: string;
    order: string;
    product: string;
    value: string;
  };
}

const strings: ITruckTrackingStrings = Strings.pt;

export const mapTrackingInformation = (res: GetTrackingInformationResponse): TrackingInformations => {
  return {
    generalInformations: {
      travelStatus: res?.tracking?.travelStatus?.toLowerCase(),
      transportType: res.tracking.typeTransportation,
      licensePlate: res.tracking.plate,
      truckLicensePlate: res.tracking.plateCar,
      route: `${res.tracking.targetCity} - ${res.tracking.targetState}`,
    },
    trackingUpdateInformations: {
      scheduled: {
        shippingInformation: mapTrackingUpdateInformations({
          date: res.tracking.forecastStart,
          isDeliveryInformation: false,
          isRealInformation: false,
        }),
        deliveryInformation: formatDateWithPeriod({
          unformattedDate: res.tracking.estimatedArrival,
          isDeliveryInformation: true,
          isRealInformation: false,
        }),
      },
      real: {
        shippingInformation: mapTrackingUpdateInformations({
          date: res.tracking.realStart,
          isDeliveryInformation: false,
          isRealInformation: true,
        }),
        deliveryInformation: mapTrackingUpdateInformations({
          date: res.tracking.realArrival,
          isDeliveryInformation: true,
          isRealInformation: true,
        }),
      },
      currentLocation: {
        city: res.tracking.cityCurrent,
        state: res.tracking.stateCurrent,
        remainingDistance: +res.tracking.kmRemaining,
        vehicleLastUpdate: res.tracking.vehicleLastUpdate,
        lastUpdate: res.tracking.vehicleLastUpdate.trim()
          ? dateParser({ unformattedDate: res.tracking.vehicleLastUpdate })
          : null,
      },
    },
    mapInformations: {
      truck: {
        lat: res.tracking.latitudeCurrent,
        long: res.tracking.longitudeCurrent,
      },
      origin: {
        lat: res.tracking.originLatitude,
        long: res.tracking.originLongitude,
      },
      destination: {
        lat: res.tracking.targetLatitude,
        long: res.tracking.targetLongitude,
      },
    },
  };
};

const formatDateWithPeriod = (params: {
  unformattedDate: string;
  isRealInformation: boolean;
  isDeliveryInformation: boolean;
}): StatusInformation => {
  const [date, period] = params.unformattedDate.split(' - ');
  const [day, month, year] = date.split('/');
  return {
    message: getMessage(params.isRealInformation, params.isDeliveryInformation),
    date: `${date} pela ${period}`,
    timeHasPassed: new Date(+year, +month - 1, +day).getTime() < new Date().getTime(),
  };
};

const mapTrackingUpdateInformations = (params: {
  date: string;
  isRealInformation: boolean;
  isDeliveryInformation: boolean;
}): StatusInformation => {
  const dateToCompare = new Date(params.date);
  const time = dateToCompare.getTime();
  if (isNaN(time)) {
    return null;
  }
  const message = getMessage(params.isRealInformation, params.isDeliveryInformation);
  const formattedDate = getFormattedDate(dateToCompare);
  const timeHasPassed = time < new Date().getTime();

  return {
    message,
    date: formattedDate,
    timeHasPassed,
  };
};

const getMessage = (isRealInformation: boolean, isDeliveryInformation: boolean): string => {
  if (isRealInformation) {
    return isDeliveryInformation ? strings.updates.delivered : strings.updates.shipped;
  }

  return isDeliveryInformation ? strings.updates.deliveryTime : strings.updates.shippingTime;
};

const getFormattedDate = (date: Date): string => {
  const isDay = date.getHours() < 12;
  const isAfternoon = 12 <= date.getHours() && date.getHours() < 18;
  const timeStrings = strings.updates.formattedDate;

  return `${format(date, 'dd/MM/yyyy')} ${
    isDay ? timeStrings.morning : isAfternoon ? timeStrings.afternoon : timeStrings.night
  }`;
};
