import * as React from 'react';
import { useParams } from 'react-router';
import { useStore } from '@app/core/global-store.service';
import { AppPath } from '@app/modules/app/route-constants';
import { useUserStore } from '@app/providers';

export enum AvailablePages {
  Analyses = 'analyses',
}

interface NavigationParams {
  page: string;
  detail: string;
}

export const RedirectPage: React.FC = () => {
  const { page, detail } = useParams<NavigationParams>();
  const { isLogged } = useStore(useUserStore);
  const redirect = (url: string) => {
    if (window) {
      window.location.href = url;
    }
  };

  const redirectPages = (): string => {
    switch (page) {
      case AvailablePages.Analyses:
        return `${AppPath.QUOTE.ANALYSIS}/${detail}`;
      default:
        return AppPath.ORDERS;
    }
  };

  React.useEffect(() => {
    if (isLogged()) {
      redirect(redirectPages());
    } else {
      redirect(AppPath.AUTHENTICATION + `?page=${redirectPages()}`);
    }
  }, []);

  return <React.Fragment />;
};
