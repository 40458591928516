import styled from 'styled-components';
import { FlexColumn } from '@atomic/obj.box';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';

export const LackOfChargeConfigModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: ${Spacing.XXLarge};
`;

export const LackOfChargeConfigModalHeaderIconWrapper = styled.div`
  flex: 1;
  padding-left: ${Spacing.XLarge};
`;

export const LackOfChargeConfigModalTitleWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-size: ${FontSize.XLarge};
  font-weight: 700;
  padding-left: ${Spacing.Large};
  color: ${Color.Black};
`;

export const LackOfChargeConfigModalCloseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-right: ${Spacing.XLarge};
`;

export const CloseButtonWrapper = styled(FlexColumn)`
  cursor: pointer;
`;

export const LackOfChargeConfigModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const LackOfChargeConfigModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.Large};
  padding-bottom: ${Spacing.Large};
`;

export const LackOfChargeConfigModalFooter = styled.div`
  width: 100%;
  background-color: ${Color.White};
  padding: ${Spacing.Medium};
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.12);
`;

export const LackOfChargeConfigModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 90px;
  padding-left: 10%;
  padding-right: 10%;
`;
