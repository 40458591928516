import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { ChatStrings } from './chat.string';

export interface MicroinclusaoData {
  label: string;
  value: string;
}

interface ModalMicroinclusaoProps {
  type: string;
}

export const ModalGenericValue: React.FC<ModalMicroinclusaoProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language];

  return (
    <Row>
      <Col xs={12}>
        <Body>{strings.modalGenericValues.fieldTitle.label}</Body>
        <VSeparator small />
        <Form.Field
          name='label'
          initialValue={props.type}
          validators={[Validators.Required(strings.modalGenericValues.alertMandatory)]}
        >
          <TextField placeholder={strings.modalGenericValues.fieldTitle.placeholder} disabled />
        </Form.Field>
        <VSeparator />
      </Col>
      <Col xs={12}>
        <Body>{strings.modalGenericValues.fieldValue.label}</Body>
        <VSeparator small />
        <Form.Field name='values' validators={[Validators.Required(strings.modalGenericValues.alertMandatory)]}>
          <TextAreaField placeholder={strings.modalGenericValues.fieldValue.placeholder} disableResize />
        </Form.Field>
        <VSeparator />
      </Col>
    </Row>
  );
};
