import { createAsyncThunk } from '@reduxjs/toolkit';
import { ScheduleDataSource } from '@app/data/http/schedule.datasource';
import { ISchedule } from '@app/models/schedule.model';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { SLICE_NAME } from '../../schedule.constants';

export const getSchedules = createAsyncThunk(`${SLICE_NAME}/getSchedules`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const customerIds: string = customerIdsJoinedSelector(state);

  return await ScheduleDataSource.getSchedules(customerIds).then(data => data);
});

export const addGetScheduleReducers = builder => {
  builder.addCase(getSchedules.pending, state => {
    state.loading = true;
  });
  builder.addCase(getSchedules.fulfilled, (state, action) => {
    const payload: ISchedule[] = action.payload as ISchedule[];
    state.loading = false;
    state.schedules = payload;
  });
  builder.addCase(getSchedules.rejected, state => {
    state.loading = false;
  });
};
