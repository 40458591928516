import * as React from 'react';
import Container from 'typedi';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { AppPath } from '@app/modules/app/route-constants';
import { Redirect } from './redirect.component';

export const RedirectCache: React.FC = () => {
  let redirectInternalPath = AppPath.ORDERS;
  const logoutQryParam = window.location.search.indexOf('logout') > -1;
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);

  if (window.localStorage.getItem('path')) {
    const localStoragePath = window.localStorage.getItem('path');

    if (localStoragePath) {
      redirectInternalPath = JSON.parse(localStoragePath);
    }
  }

  if (logoutQryParam) {
    authStorageService.logout();
  }

  return <Redirect to={redirectInternalPath} />;
};
