import * as React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { VSeparator } from '@atomic/obj.grid';

export const NewsModalShimmer: React.FC = () => {
  return (
    <>
      <VSeparator medium />
      <TextShimmerBoxStyled height='20px' width='20%' margin='0' />
      <VSeparator medium />

      <TextShimmerBoxStyled height='20px' width='40%' margin='0' />
      <VSeparator medium />

      <TextShimmerBoxStyled height='20px' width='100%' margin='0' />
      <VSeparator small />

      <TextShimmerBoxStyled height='20px' width='100%' margin='0' />
      <VSeparator small />

      <TextShimmerBoxStyled height='20px' width='100%' margin='0' />
      <VSeparator medium />

      <TextShimmerBoxStyled height='200px' width='100%' margin='0' />
    </>
  );
};
