import { startOfMonth } from 'date-fns';
import {
  FrontierSalesOrderItem,
  InStockOrderItem,
  InternationalSalesOrderItemSummary,
  SalesOrderItem,
  SalesOrderSummaryItem,
  ShippedSalesOrderItem,
} from '@app/models';
import {
  SalesOrdersMaritimeHarborItem,
  SalesOrdersMaritimeOpenItem,
  SalesOrdersMaritimeShippedItem,
} from '@app/models/maritime-order-item.model';

type orderDataItemTypes =
  | SalesOrderSummaryItem
  | InStockOrderItem
  | SalesOrderItem
  | InternationalSalesOrderItemSummary
  | ShippedSalesOrderItem
  | FrontierSalesOrderItem
  | SalesOrdersMaritimeOpenItem
  | SalesOrdersMaritimeShippedItem
  | SalesOrdersMaritimeHarborItem;

export interface IOrderTabState {
  data: orderDataItemTypes[];
  filteredRows: orderDataItemTypes[];
  selectedRows: orderDataItemTypes[];
  currentRequestTime: number;
  lastUpdate: string;
  loading: boolean;
  error: unknown;
  filter?: string;
  showActionBar?: boolean;
  startDate?: number;
  endDate?: number;
  criticalFilter?: boolean;
  lackOfChargeFilter?: boolean;
}

export const defaultInitialState = {
  data: [],
  currentRequestTime: null,
  lastUpdate: null,
  filteredRows: [],
  selectedRows: [],
  showActionBar: false,
  loading: false,
  error: null,
  filter: null,
  criticalFilter: true,
  lackOfChargeFilter: true,
};

export const initialStateWithDates = {
  ...defaultInitialState,
  startDate: startOfMonth(new Date()).getTime(),
  endDate: new Date().getTime(),
};
