import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IAppStrings, Strings } from '@app/modules/app/app.string';
import { AppPath } from '@app/modules/app/route-constants';
import { setUserLanguage } from '@app/modules/auth/store/auth.store';
import { redirect } from '@app/modules/auth/utils/utils';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { RadioField } from '@atomic/atm.radio';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { Form } from '@atomic/obj.form';
import { LanguageSelectorBody, LanguageSelectorHeader, LanguageSelectorStyled } from './language-selector.style';

interface LanguageSelectorProps {
  title: string;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ title }) => {
  const [opened, setOpened] = useState(false);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IAppStrings = Strings[userInfo?.language];
  const dispatch = useDispatch();

  const handleSetLanguageOption = (language: string) => {
    dispatch(setUserLanguage(language));
    redirect(AppPath.CACHE_ROUTE);
  };

  const handleOpen = () => {
    setOpened(!opened);
  };

  return (
    <LanguageSelectorStyled opened={opened}>
      <LanguageSelectorHeader onClick={() => handleOpen()}>
        <FlexRow vAlign='center' ml={Spacing.Medium}>
          <FlexColumn noGrow vAlign='center'>
            <FaIcon.Language size='1x' color={Color.Gray} />
          </FlexColumn>
          <FlexRow ml={Spacing.Medium}>
            <Body>{title}</Body>
          </FlexRow>
        </FlexRow>
      </LanguageSelectorHeader>
      <LanguageSelectorBody opened={opened}>
        <FlexRow hAlign='space-between'>
          <Form.Field name='languageOption' onValueChange={handleSetLanguageOption} initialValue={userInfo?.language}>
            <FlexColumn>
              <RadioField key='pt' id='pt'>
                <Image.BrFlagIcon />
              </RadioField>
            </FlexColumn>
            <FlexColumn>
              <RadioField key='in' id='in'>
                <Image.UsFlagIcon />
              </RadioField>
            </FlexColumn>
            <FlexColumn>
              <RadioField key='es' id='es'>
                <Image.EsFlagIcon />
              </RadioField>
            </FlexColumn>
          </Form.Field>
        </FlexRow>
        <FlexRow mt={Spacing.Small}>
          <Body>{strings.configuration.languageWarning}</Body>
        </FlexRow>
      </LanguageSelectorBody>
    </LanguageSelectorStyled>
  );
};
