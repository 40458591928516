/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { OrderDataSource } from '@app/data/http/order-MI.datasource';
import { StockRealDateParams } from '@app/data/http/order-params.dto';
import { InStockOrderItem, StockRealDateItem } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { PopoverContext, PopoverContextState } from '@app/modules/components/popover/popover-container';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { FlexRow } from '@atomic/obj.box';
import { DateStockNumber } from './date-stock-entry-cell.style';
import { DateStockEntryInfo } from './date-stock-entry-info.component';

interface DateStockEntryCellComponentProps {
  data: InStockOrderItem;
}

export const DateStockEntryCellComponent: React.FC<DateStockEntryCellComponentProps> = ({ data }) => {
  const pluralText = data.qtyDateOfStockEntry === 1 ? 'data' : 'datas';
  const { openPopover, closePopover } = useContext<PopoverContextState>(PopoverContext);
  const customerIds = useSelector(customerIdsJoinedSelector);
  const onSuccess = (items: StockRealDateItem[]) => {
    openPopover(ref, <DateStockEntryInfo items={items} />);
  };

  const { performRequest, loading } = useLazyRequest<StockRealDateParams, StockRealDateItem[]>(
    OrderDataSource.getStockRealDate,
    onSuccess,
  );

  const ref = useRef(null);

  useEffect(() => {
    closePopover();
  }, [ref]);

  if (data.dateOfStockEntry && data.qtyDateOfStockEntry) {
    return (
      <FlexRow>
        {data.dateOfStockEntry}
        <DateStockNumber
          ref={ref}
          onClick={() =>
            performRequest({ customerIds, salesDoc: data.salesDocument, salesDocItem: data.salesDocumentItem })
          }
        >
          {loading ? <ActivityIndicator size='20px' type='spinner' /> : `${data.qtyDateOfStockEntry} ${pluralText}`}
        </DateStockNumber>
      </FlexRow>
    );
  }
  return null;
};
