import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Link } from '@app/core/route';
import { AnalysisResume, AnalysisStatus } from '@app/models/analysis.model';
import { AppPath } from '@app/modules/app/route-constants';
import { quoteBadges } from '@app/modules/quote/quote.contants';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { Cell } from '@atomic/atm.cell';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { QuoteStrings } from '../quote.string';
import { QuoteListItemAdditionalInfo } from './quote-list-item-additional-info';
import { DraftBadgeWrapperStyled, DraftDeleteWrapperStyled, QuoteItemWrapperStyled } from './quote.components.style';

interface QuoteListItemProps {
  item: AnalysisResume;
  status: AnalysisStatus;
  id: number | string;
  selectedDrafts: string[] | number[];
  handleDeleteDraft: (confirmationId: string) => void;
  handleSelectDraft: (confirmationId: string | number, checked: boolean) => void;
}

interface PropsDelete {
  status: AnalysisStatus;
  confirmationId: string | number;
  handleDeleteDraft: (confirmationId: string | number) => void;
}

export const DeleteDraftButtons = (props: PropsDelete) => {
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const { userInfo } = useSelector((state: RootState) => state.auth);

  return (
    <Hbox.Item>
      <DraftBadgeWrapperStyled>
        <Badge color={quoteBadges[props.status.key]?.color}>{QuoteStrings[userInfo.language].draft}</Badge>
      </DraftBadgeWrapperStyled>
      <DraftDeleteWrapperStyled>
        {!confirmDelete ? (
          <Button kind='link' onClick={() => setConfirmDelete(true)}>
            <FaIcon.Custom
              icon='trash-alt'
              color={Color.Alert}
              alt={QuoteStrings[userInfo.language].draftPage.removeBtn}
            />
          </Button>
        ) : (
          <>
            <Button kind='link' onClick={() => props.handleDeleteDraft(props.confirmationId)}>
              <Body color={Color.Alert}>{QuoteStrings[userInfo.language].draftPage.removeBtn}</Body>
            </Button>
            /
            <Button kind='link' onClick={() => setConfirmDelete(false)}>
              <Body color={Color.Primary}>{QuoteStrings[userInfo.language].draftPage.cancelBtn}</Body>
            </Button>
          </>
        )}
      </DraftDeleteWrapperStyled>
    </Hbox.Item>
  );
};

export const DraftListItem: React.FC<QuoteListItemProps> = props => {
  return (
    <QuoteItemWrapperStyled key={props.item.id} id={props.item.id}>
      <Cell hover>
        <Hbox>
          <CheckboxField
            id={`el-${props.item.id}`}
            onValueChange={(_, checked) => props.handleSelectDraft(props.item.id, checked)}
            checked={props.selectedDrafts.some(item => item === props.item.id)}
          />
          <Link
            to={`${AppPath.CHAT.DETAIL.replace(':action', 'recovery').replace(
              ':analysisId',
              props.item.id.toString(),
            )}`}
          >
            <Hbox.Item>
              <H3>{props.item.title ? props.item.title : '--'}</H3>
              <Hbox noGrow vAlign='flex-end'>
                <Hbox.Item noGrow>
                  <Body>{props.item.product.steelName ? props.item.product.steelName : '--'}</Body>
                </Hbox.Item>
                <Hbox.Separator />
                <QuoteListItemAdditionalInfo
                  flow={props.item.flow.label ? props.item.flow.label : '--'}
                  client={props.item.client.code ? props.item.client.code : '--'}
                  dimensional={props.item.product.shape ? props.item.product.shape : '--'}
                />
                <Hbox.Separator />
              </Hbox>
            </Hbox.Item>
          </Link>
          <DeleteDraftButtons
            status={props.status}
            confirmationId={props.item.id}
            handleDeleteDraft={props.handleDeleteDraft}
          />
        </Hbox>
      </Cell>
    </QuoteItemWrapperStyled>
  );
};
