import { IAuthState } from '../auth.interfaces';
import { initialState } from './auth.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setAuthData: (state: IAuthState, { payload }) => {
    state.token = payload.token;
    state.tokenType = payload.tokenType;
    state.tokenExpiresIn = payload.tokenExpiresIn;
    state.refreshToken = payload.refreshToken;
    state.applications = payload.applications;
  },
  setForgotPasswordLoading: (state: IAuthState, { payload }) => {
    state.forgotPasswordLoading = payload;
  },
  setForgotPasswordData: (state: IAuthState, { payload }) => {
    state.forgotPasswordData = payload;
  },
  setLoading: (state: IAuthState, { payload }) => {
    state.loading = payload;
  },
  setLoadingProfile: (state: IAuthState, { payload }) => {
    state.loadingProfile = payload;
  },
  setLoadingValidateToken: (state: IAuthState, { payload }) => {
    state.loadingValidateToken = payload;
  },
  setUserLanguage: (state: IAuthState, { payload }) => {
    state.userInfo.language = payload;
  },
};
