import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '@app/core/redux/store';
import { OpenOrdersGraphDataSource } from '@app/data/http/dashboard/open-orders-graph/open-orders-graph.datasource';
import { mapRequestedVsConfirmedOrderGraph } from '@app/data/http/dashboard/open-orders-graph/qtd.dto';
import { useGetGraph } from '@app/domain/get-dashboard.use-case';
import { DashboardGraphName, DashboardLegendData, RequestedVsConfirmedOrderGraph } from '@app/models/dashboard.model';
import { setReloadOpenDashboard } from '@app/providers/navigation/navigation.store';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Frame } from '@atomic/atm.frame';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { DashboardBlockHeader } from '../dashboard-block-header.component';
import { DashboardBlockShimmer } from '../dashboard-block.shimmer';
import { DashboardGroupLegend } from '../dashboard-group-legend';
import { Strings, IDashBoardStrings } from '../dashboard.string';

const strings: IDashBoardStrings = Strings.pt;
const { openOrders: openOrdersGraphStrings } = strings.graphs;

const requestedVsConfirmedOrderGraphLegend: DashboardLegendData[] = [
  {
    title: openOrdersGraphStrings.openOrderStatus.requested,
    iconColor: Color.Primary,
  },
  {
    title: openOrdersGraphStrings.openOrderStatus.confirmed,
    iconColor: Color.PinkDark,
  },
];

const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = '95%';

interface QtdGraphProps {
  clientNumber: string;
  screenWidth: number;
  handleClick: (modalData) => void;
}

const QTD_GRAPH_DATA = 'qtd_graph_data';
const DO_REQUESTS_FOR_DASHBOARDS = 'do_requests_for_dashboards';

export const QtdGraph: React.FC<QtdGraphProps> = props => {
  const { reloadOpenDashboard } = useSelector((state: RootState) => state.navigation);
  const [qtdGraphData, setQtdGraphData] = useLocalStorageState(QTD_GRAPH_DATA, {});
  const doRequestForDashboards = window.localStorage.getItem(DO_REQUESTS_FOR_DASHBOARDS);
  const dispatch = useDispatch();

  const { data, loading, error, performRequest } = useGetGraph<any, RequestedVsConfirmedOrderGraph>(
    [OpenOrdersGraphDataSource.qtd.qtdRequested, OpenOrdersGraphDataSource.qtd.qtdConfirmed],
    mapRequestedVsConfirmedOrderGraph,
  );

  useEffect(() => {
    if (reloadOpenDashboard) {
      performRequest(props.clientNumber);
      dispatch(setReloadOpenDashboard(false));
    }
  }, [reloadOpenDashboard]);

  useEffect(() => {
    if (props.clientNumber && doRequestForDashboards === 'true') {
      performRequest(props.clientNumber);
      dispatch(setReloadOpenDashboard(false));
    }
  }, [props.clientNumber, doRequestForDashboards]);

  useEffect(() => {
    if (data && data.length > 0) {
      setQtdGraphData(data);
    }
  }, [data]);

  return (
    <LoadingState loading={loading} data={!!qtdGraphData} error={!!error}>
      <LoadingState.Shimmer>
        <DashboardBlockShimmer blocks={1} />
      </LoadingState.Shimmer>

      {!!qtdGraphData && (
        <Frame>
          <VSeparator />
          <DashboardBlockHeader
            title={strings.orderOpen.solXCon}
            description={openOrdersGraphStrings.requestedVsConfirmedOrder.description}
          />
          <VSeparator />

          <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
            <BarChart data={qtdGraphData?.length && qtdGraphData}>
              <XAxis dataKey='date' />
              <YAxis domain={[0, datamax => Math.floor(datamax * 1.3)]} />
              <Tooltip />
              <Bar
                dataKey='requested'
                isAnimationActive={false}
                name={openOrdersGraphStrings.requestedVsConfirmedOrder.requested}
                stackId='a'
                fill={Color.Primary}
                legendType={'circle'}
                radius={4}
                unit='t'
                onClick={props.handleClick}
              >
                <LabelList dataKey='requested' position='top' formatter={value => value + 't'} />
              </Bar>
              <Bar
                dataKey='confirmed'
                isAnimationActive={false}
                name={openOrdersGraphStrings.requestedVsConfirmedOrder.confirmed}
                stackId='b'
                fill={Color.PinkDark}
                legendType='circle'
                radius={4}
                unit='t'
                onClick={props.handleClick}
              >
                <LabelList dataKey='confirmed' position='top' formatter={value => value + 't'} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <VSeparator />

          <DashboardGroupLegend
            legend={requestedVsConfirmedOrderGraphLegend}
            graph={DashboardGraphName.requestedVsConfirmedOrderGraph}
          />
          <VSeparator />
        </Frame>
      )}
    </LoadingState>
  );
};
