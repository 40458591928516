import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setOpenLackOfChargeActionModal } from '@app/modules/order/order-tabs/MI/tab-open/partials/lack-of-charge-modal/store/lack-of-charge-modal.store';
import { patchLackOfChargeModal } from '@app/modules/order/order-tabs/MI/tab-open/partials/lack-of-charge-modal/store/patch-lack-of-charge.thunk';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { LACK_OF_CHARGE_MI_KEY } from '../announcement-modal/announcement-modal.constants';
import { Strings, ILackOfChargeConfigModalStrings } from './lack-of-charge-config-modal.string';
import {
  LackOfChargeConfigModalBody,
  LackOfChargeConfigModalBodyWrapper,
  LackOfChargeConfigModalCloseWrapper,
  LackOfChargeConfigModalFooter,
  LackOfChargeConfigModalHeader,
  LackOfChargeConfigModalTitleWrapper,
  LackOfChargeConfigModalWrapper,
} from './lack-of-charge-config-modal.style';

export const LackOfChargeConfigModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { openedConfigModal, announcementData, loading } = useSelector((state: RootState) => state.lackOfCharge);
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings: ILackOfChargeConfigModalStrings = Strings[userInfo?.language];

  const handleClose = () => {
    dispatch(setOpenLackOfChargeActionModal(false));
  };

  const handleConfirm = () => {
    dispatch(patchLackOfChargeModal({ key: LACK_OF_CHARGE_MI_KEY, additionalDays: 3 }));
  };

  const handleIsDisabled = () => {
    let isDisabled = false;

    if (announcementData && announcementData.runningAnnouncements.length > 0) {
      isDisabled = announcementData.runningAnnouncements.includes(LACK_OF_CHARGE_MI_KEY);
    }

    return isDisabled;
  };

  return (
    <Modal small opened={openedConfigModal}>
      <LackOfChargeConfigModalHeader>
        <LackOfChargeConfigModalTitleWrapper>
          {strings?.title}
          <FlexRow pl={Spacing.Small}>
            <FaIcon.Bell size='1x' color={Color.Primary} />
          </FlexRow>
        </LackOfChargeConfigModalTitleWrapper>
        <LackOfChargeConfigModalCloseWrapper>
          <FlexColumn onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
            <Image.CloseIcon />
          </FlexColumn>
        </LackOfChargeConfigModalCloseWrapper>
      </LackOfChargeConfigModalHeader>
      <LackOfChargeConfigModalWrapper>
        <LackOfChargeConfigModalBody>
          <LackOfChargeConfigModalBodyWrapper>
            <Body size={FontSize.Large}>{strings?.message}</Body>
          </LackOfChargeConfigModalBodyWrapper>
          <LackOfChargeConfigModalFooter>
            <FlexRow hAlign='center' pr={Spacing.Small}>
              <Button
                kind='secondary'
                testid={`${TEST_ID_CLOSE_MODAL}-lack-of-charge-config-modal`}
                onClick={() => handleClose()}
              >
                {strings?.cancelButton}
              </Button>
            </FlexRow>
            <FlexRow hAlign='center'>
              <Button
                testid='confirm-lack-of-charge-config-modal'
                disabled={handleIsDisabled()}
                onClick={() => handleConfirm()}
                loading={loading}
              >
                {strings?.confirmButton}
              </Button>
            </FlexRow>
          </LackOfChargeConfigModalFooter>
        </LackOfChargeConfigModalBody>
      </LackOfChargeConfigModalWrapper>
    </Modal>
  );
};
