import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { ReceiverDataSource } from '@app/data/http';
import { Receiver } from '@app/models';
import { SelectOption } from '@app/modules/order-input/order-input.interfaces';
import { Modal } from '@atomic/obj.modal';
import { RECEIVER_MODAL_WIDTH } from '../../shipping-request.constants';
import { setOpenReceiverModalIndex, setReceiverOptions } from '../../store/shipping-request.store';
import { ShippingRequestReceiverModalBody } from './partials/shipping-request-receiver-modal-body/shipping-request-receiver-modal-body.component';
import { ShippingRequestReceiverModalFooter } from './partials/shipping-request-receiver-modal-footer/shipping-request-receiver-modal-footer.component';
import { ShippingRequestReceiverModalHeader } from './partials/shipping-request-receiver-modal-header/shipping-request-receiver-modal-header.component';

export const ShippingRequestReceiverModal: React.FC = () => {
  const { openReceiverModalIndex, tableRows, receiverOptions } = useSelector(
    (state: RootState) => state.shippingRequest,
  );
  const [selectedReceiver, setSelectedReceiver] = useState<string>(
    tableRows[openReceiverModalIndex].newReceiver
      ? tableRows[openReceiverModalIndex].newReceiver
      : tableRows[openReceiverModalIndex].receiver,
  );
  const dispatch = useDispatch();

  const onSuccessReceivers = (data: Receiver[]) => {
    const mapedOptions: SelectOption[] = data.map(item => ({
      value: item.receiverBpNumber,
      name: `${item.receiverBpNumber} - ${item.receiverName} - ${item.receiverCity}`,
      city: item.receiverCity,
    }));

    dispatch(setReceiverOptions(mapedOptions));
  };

  useEffect(() => {
    getReceivers({ customerIds: tableRows[openReceiverModalIndex].receiver });
  }, []);

  const { performRequest: getReceivers, loading: receiversLoading } = useLazyRequest(
    ReceiverDataSource.getReceivers,
    onSuccessReceivers,
  );

  return (
    <Modal customWidth={RECEIVER_MODAL_WIDTH} opened onClose={() => dispatch(setOpenReceiverModalIndex(null))}>
      <ShippingRequestReceiverModalHeader />
      <ShippingRequestReceiverModalBody
        selectedReceiver={selectedReceiver}
        setselectedReceiver={setSelectedReceiver}
        loading={receiversLoading}
        receiverOptions={receiverOptions}
      />
      {!receiversLoading && <ShippingRequestReceiverModalFooter selectedReceiver={selectedReceiver} />}
    </Modal>
  );
};
