import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { RootState } from '@app/core/redux/store';
import { formatQtyField } from '@app/utils/string-utils';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Separator } from '@atomic/obj.box';
import { Color, FontSize } from '@atomic/obj.constants';
import { OrderInputPhaseEnum } from '../../order-input.constants';
import { IOrderInputStrings, OrderInputStrings } from '../../order-input.string';
import {
  hasSpreadSheetFormCompletedSelector,
  hasItemsCompletedSelector,
  hasSelectionCompletedSelector,
  totalItensSelector,
  hasQtyMultipleAjustedSelector,
  solicitedTotalItensSelector,
  hasPdfEmailFormCompletedSelector,
} from '../../store/order-input.selectors';
import {
  AdjustedTotalWrapper,
  ItemAmountWrapper,
  LabelAmount,
  OrderInputBaseboardWrapper,
} from './order-input-baseboard.style';

interface OrderInputBaseboardProps {
  onActionClick: () => void;
  onReturnClick: () => void;
  loading?: boolean;
}

export const OrderInputBaseboard: React.FC<OrderInputBaseboardProps> = ({ onActionClick, onReturnClick, loading }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];

  const { phase, selectedMaterials } = useSelector((state: RootState) => state.orderInput);

  const hasSelectionCompleted = useSelector(hasSelectionCompletedSelector);
  const hasItemsCompleted = useSelector(hasItemsCompletedSelector);
  const hasFormCompleted = useSelector(hasSpreadSheetFormCompletedSelector);
  const hasPdfEmailFormCompleted = useSelector(hasPdfEmailFormCompletedSelector);
  const totalSolicited = useSelector(solicitedTotalItensSelector);
  const totalItens = useSelector(totalItensSelector);
  const hasQtyMultipleAjusted = useSelector(hasQtyMultipleAjustedSelector);

  const phaseButtonText = {
    [OrderInputPhaseEnum.ItensSelection]: {
      text: strings.baseboard.advanceButton,
      enableAdvancedButton: hasSelectionCompleted,
    },
    [OrderInputPhaseEnum.UploadSpreadsheet]: {
      text: strings.baseboard.advanceButton,
      enableAdvancedButton: hasFormCompleted,
    },
    [OrderInputPhaseEnum.UploadPdfEmail]: {
      text: strings.baseboard.advanceButton,
      enableAdvancedButton: hasPdfEmailFormCompleted,
    },
    [OrderInputPhaseEnum.DataAndAmout]: {
      text: strings.baseboard.qtyValidationButton,
      enableAdvancedButton: hasItemsCompleted,
    },
    [OrderInputPhaseEnum.Multiple]: {
      text: strings.baseboard.goToRevisionButton,
      enableAdvancedButton: hasQtyMultipleAjusted,
    },
    [OrderInputPhaseEnum.Resume]: {
      text: strings.baseboard.confirmButton,
      enableAdvancedButton: true,
    },
  };

  const showTotal = [
    OrderInputPhaseEnum.DataAndAmout,
    OrderInputPhaseEnum.Multiple,
    OrderInputPhaseEnum.Resume,
  ].includes(phase);

  const showAdjustedTotal = [OrderInputPhaseEnum.Multiple, OrderInputPhaseEnum.Resume].includes(phase);

  return (
    <OrderInputBaseboardWrapper>
      <Frame>
        <Grid fluid>
          <Row>
            <Separator />
            <Col lg={12}>
              <FlexRow pt pb hAlign='space-between'>
                <FlexRow>
                  {showTotal && (
                    <>
                      <ItemAmountWrapper>
                        <LabelAmount>{strings.baseboard.itemAmount}</LabelAmount>
                        <Label size={FontSize.Small} color={Color.Black}>
                          {selectedMaterials.length}
                        </Label>
                      </ItemAmountWrapper>
                      <FlexColumn ml>
                        <Label size={FontSize.Small}>{strings.baseboard.total}</Label>
                        <Label size={FontSize.Small} color={Color.Black}>
                          {formatQtyField(totalSolicited)}
                        </Label>
                      </FlexColumn>
                      {showAdjustedTotal && (
                        <AdjustedTotalWrapper>
                          <Label size={FontSize.Small}>{strings.baseboard.adjustedTotal}</Label>
                          <Label size={FontSize.Small} color={Color.Black}>
                            {formatQtyField(totalItens)}
                          </Label>
                        </AdjustedTotalWrapper>
                      )}
                    </>
                  )}
                </FlexRow>
                <FlexRow>
                  <Button onClick={() => onReturnClick()} kind='secondary'>
                    {strings.baseboard.backButton}
                  </Button>
                  <Button
                    loading={loading}
                    disabled={!phaseButtonText[phase].enableAdvancedButton}
                    onClick={() => onActionClick()}
                    kind='callToAction'
                  >
                    {phaseButtonText[phase].text}
                  </Button>
                </FlexRow>
              </FlexRow>
            </Col>
            <Separator />
          </Row>
        </Grid>
      </Frame>
    </OrderInputBaseboardWrapper>
  );
};
