import { DocumentsResponse } from '@app/data/http/order-params.dto';
import { DocumentsProfileCard } from '@app/models/order-documents.model';

export const mapDocumentsResult = (response: DocumentsResponse): DocumentsProfileCard[] => {
  const responseFiltered = response.onlineBillings.filter(
    (order, index, self) => index === self.findIndex(item => item.boarding === order.boarding),
  );

  return responseFiltered.map(item => ({
    boarding: item.boarding,
    registrationDate: item.dateSolClient,
  }));
};
