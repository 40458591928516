import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TableRowData } from '@app/data/http/quote-params.dto';
import { AttributesTableItem } from '@app/models';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { QuoteStrings } from '../quote/quote.string';
import { ChatResultAttributeWrapper, ChatResultTable } from './chat-result.component.style';
import { ChatStrings } from './chat.string';
import { MicroinclusaoField, ModalMicroinclusao } from './modal-microinclusao.component';

interface MicroinclusaoTableProps {
  data: AttributesTableItem[];
  onData: (data: TableRowData[], key: string) => void;
  disableAction?: boolean;
  editable?: boolean;
  delible?: boolean;
  onDelete?: () => void;
  modified?: string;
  disableButton?: boolean;
}

export const MicroinclusaoTable: React.FC<MicroinclusaoTableProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language].modalMicroinclusao;
  const [edit, setEdit] = React.useState(false);

  if (!props.data) {
    return null;
  }

  const handleModalData = (data: TableRowData[], key: string) => {
    props.onData(data, key);
    setEdit(false);
  };

  const convertDataToNumber = (val: string | number) => {
    if (val) {
      return val.toString().replace(',', '.');
    }
    return val;
  };

  return (
    <ChatResultAttributeWrapper modified={props.modified}>
      <ChatResultTable>
        <Hbox>
          <Hbox.Item>
            <InputLabel>
              {strings.title}
              {props.modified !== '' &&
                (props.modified === 'GERDAU' ? (
                  <span title={QuoteStrings[userInfo.language].defaultMessageGerdau.tootipChatResult}>
                    {QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultGerdauAdvice}
                  </span>
                ) : (
                  <span>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}</span>
                ))}
            </InputLabel>
          </Hbox.Item>
          {props.editable && (
            <Hbox.Item noGrow>
              <Button kind='link' onClick={() => setEdit(true)} disabled={props.disableAction || props.disableButton}>
                <FaIcon.Pen />
              </Button>
            </Hbox.Item>
          )}
          {props.delible && (
            <Hbox.Item noGrow>
              <Button kind='link' onClick={props.onDelete} disabled={props.disableAction || props.disableButton}>
                <FaIcon.Trash />
              </Button>
            </Hbox.Item>
          )}
        </Hbox>
        <Table>
          <TR>
            <TD />
            <TD>
              <H4>{strings.thin}</H4>
            </TD>
            <TD>
              <H4>{strings.thick}</H4>
            </TD>
          </TR>
          <TR>
            <TD>
              <H4>A</H4>
            </TD>
            <TD>{convertDataToNumber(props.data[0].value)}</TD>
            <TD>{convertDataToNumber(props.data[4].value)}</TD>
          </TR>
          <TR>
            <TD>
              <H4>B</H4>
            </TD>
            <TD>{convertDataToNumber(props.data[1].value)}</TD>
            <TD>{convertDataToNumber(props.data[5].value)}</TD>
          </TR>
          <TR>
            <TD>
              <H4>C</H4>
            </TD>
            <TD>{convertDataToNumber(props.data[2].value)}</TD>
            <TD>{convertDataToNumber(props.data[6].value)}</TD>
          </TR>
          <TR>
            <TD>
              <H4>D</H4>
            </TD>
            <TD>{convertDataToNumber(props.data[3].value)}</TD>
            <TD>{convertDataToNumber(props.data[7].value)}</TD>
          </TR>
        </Table>
        <VSeparator />
        <ModalMicroinclusao
          openTableType={edit ? 'microinclusao' : ''}
          onSubmit={handleModalData}
          data={{
            [MicroinclusaoField.MICRO_A_FINO]: props.data[0].value,
            [MicroinclusaoField.MICRO_A_GROSSO]: props.data[4].value,
            [MicroinclusaoField.MICRO_B_FINO]: props.data[1].value,
            [MicroinclusaoField.MICRO_B_GROSSO]: props.data[5].value,
            [MicroinclusaoField.MICRO_C_FINO]: props.data[2].value,
            [MicroinclusaoField.MICRO_C_GROSSO]: props.data[6].value,
            [MicroinclusaoField.MICRO_D_FINO]: props.data[3].value,
            [MicroinclusaoField.MICRO_D_GROSSO]: props.data[7].value,
          }}
          onClose={() => setEdit(false)}
        />
      </ChatResultTable>
    </ChatResultAttributeWrapper>
  );
};
