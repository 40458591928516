import * as React from 'react';
import {
  AccordionCollapsibleStyled,
  AccordionContentStyled,
  AccordionHeaderStyled,
  AccordionStyled,
} from './accordion.component.style';

export interface AccordionProps {
  accordionId?: string;
  onHeaderTap?: (key: string, expanded: boolean) => void;
  expanded?: boolean;
  hideBorder?: boolean;
  backgroundColor?: string;
  onContentResize?: (size: number) => void;
  disabledHeader?: boolean;
  overflowVisible?: boolean;
}

export interface AccordionState {
  expanded: boolean;
}

export interface AccordionHeaderProps {
  expanded?: boolean;
  children?: JSX.Element | ((expanded: boolean) => JSX.Element);
  backgroundColor?: string;
}

const AccordionHeader: React.FC<AccordionHeaderProps> = (props: AccordionHeaderProps) => (
  <>{typeof props.children === 'function' ? props.children(props.expanded) : props.children}</>
);
AccordionHeader.displayName = 'Accordion.AccordionHeader';

export class Accordion extends React.Component<AccordionProps, AccordionState> {
  static Header = AccordionHeader;

  private contentElement: React.RefObject<HTMLElement>;

  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded ? true : false,
    };

    this.contentElement = React.createRef();
  }

  componentDidUpdate(prevProps: AccordionProps) {
    if (prevProps.expanded !== this.props.expanded && this.props.expanded !== this.state.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  handleHeaderTap = () => {
    const shouldExpand = !this.state.expanded;
    if (this.props.onHeaderTap) {
      this.props.onHeaderTap(this.props.accordionId, shouldExpand);
    }

    this.setState({ expanded: !this.state.expanded });

    if (shouldExpand && this.props.onContentResize) {
      this.props.onContentResize(this.contentElement.current.clientHeight);
    }
  };

  render() {
    const header = React.Children.map(this.props.children, (child: any) => {
      if (child && child.type === AccordionHeader) {
        return React.cloneElement(child, { expanded: this.state.expanded });
      } else {
        return '';
      }
    });

    const wrapped = React.Children.map(this.props.children, (child: any, i: number) => {
      if (child && child.type === Accordion) {
        const key = 'Accordion_' + i;
        return React.cloneElement(child, {
          key: child.key || key,
        });
      } else if (child && child.type === AccordionHeader) {
        return '';
      } else {
        return child;
      }
    });

    return (
      <AccordionStyled>
        <AccordionHeaderStyled
          hideBorder={this.props.hideBorder}
          onClick={!this.props.disabledHeader && this.handleHeaderTap}
          backgroundColor={this.props.backgroundColor}
        >
          {header}
        </AccordionHeaderStyled>
        <AccordionCollapsibleStyled overflowVisible={this.props.overflowVisible} expanded={this.state.expanded}>
          <AccordionContentStyled ref={this.contentElement}>{wrapped}</AccordionContentStyled>
        </AccordionCollapsibleStyled>
      </AccordionStyled>
    );
  }
}
