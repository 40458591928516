import { Customer } from '@app/models';
import { MarketOptions } from '@app/modules/components/customer-search/customer-search.constants';
import { IOption } from '@app/modules/components/customer-search/customer-search.interfaces';

interface GetUnselectedOptionsParams {
  salesGroupSearch: IOption[];
  data: Customer[];
  market: MarketOptions;
}

export const getUnselectedOptions = ({ data, market, salesGroupSearch }: GetUnselectedOptionsParams): IOption[] => {
  const corporateGroupsMarket: Customer[] = data.filter((customer: Customer) => customer.market === market);

  const uniqueGroups: string[] = [...new Set(corporateGroupsMarket.map((item: Customer) => item.salesGroup))];

  const uniqueOptions: IOption[] = uniqueGroups.map(
    (group: string): IOption => {
      const customer = data.find(item => item.salesGroup === group);
      return { name: customer.salesGroup, value: customer.salesGroup };
    },
  );

  const unselectedOptions: IOption[] = uniqueOptions.filter((option: IOption) => {
    const salesGroupFinded = salesGroupSearch.find(salesGroup => salesGroup.value === option.value);
    return !salesGroupFinded;
  });

  return unselectedOptions;
};
