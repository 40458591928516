import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { FlashWrapperStatic } from '@app/modules/components/flash-wrapper-static/flash-wrapper-static.component';
import { OrderTabGrid } from '@app/modules/order/order-tab-grid.component';
import { Button } from '@atomic/atm.button';
import { DatePicker } from '@atomic/atm.date-picker';
import { InputLabel } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Grid } from '@atomic/obj.grid/grid.component';
import { OrderTabsActionsME } from '../../order-tabs-actions-ME.component';
import { CertificateMECellComponent } from './certificate-cell';
import { tabName } from './order-tab-maritime-shipped.constants';
import { performRequest, setEndDate, setFilteredRows, setStartDate } from './order-tab-maritime-shipped.store';
import { IOrderTabShippedMaritimeStrings, Strings } from './order-tab-maritime-shipped.string';
import { handleEndDateChange, handleStartDateChange } from './utils/handler-utils';

interface OrderTabShippedMaritimeComponentProps {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
}

export const OrderTabShippedMaritimeComponent: React.FC<OrderTabShippedMaritimeComponentProps> = ({ gridEvent }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabShippedMaritimeStrings = Strings[userInfo.language];
  const { data, loading, error, startDate, endDate } = useSelector((state: RootState) => state.orderTabShippedMaritime);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleStartDateConfigured = date => {
    handleStartDateChange({
      date,
      endDate: endDate,
      setStartDate: value => dispatch(setStartDate(value.valueOf())),
      setEndDate: value => dispatch(setEndDate(value.valueOf())),
    });
  };

  const handleEndDateConfigured = date =>
    handleEndDateChange({ date, setEndDate: value => dispatch(setEndDate(value.valueOf())) });

  return (
    <>
      <FlashWrapperStatic opened={!!error} type='alert' content={strings.error} />
      <Grid fluid>
        <OrderTabsActionsME
          tabName={tabName}
          refresh={() => dispatch(performRequest())}
          gridEvent={gridEvent}
          filename={strings.filename}
        >
          <FlexRow vAlign='center'>
            <FlexColumn vAlign='center' noGrow>
              <InputLabel>{strings.from}</InputLabel>
            </FlexColumn>
            <Hbox.Separator />
            <FlexColumn noGrow>
              <DatePicker
                language={userInfo.language}
                startDate={new Date(startDate)}
                onValueChange={handleStartDateConfigured}
              />
            </FlexColumn>
            <Hbox.Separator />
            <FlexColumn vAlign='center' noGrow>
              <InputLabel>{strings.to}</InputLabel>
            </FlexColumn>
            <Hbox.Separator />
            <FlexColumn noGrow>
              <DatePicker
                language={userInfo.language}
                minDate={new Date(startDate)}
                startDate={new Date(endDate)}
                onValueChange={handleEndDateConfigured}
              />
            </FlexColumn>
            <FlexColumn ml noGrow>
              <Button kind='primary' onClick={() => dispatch(performRequest())}>
                {strings.search}
              </Button>
            </FlexColumn>
          </FlexRow>
        </OrderTabsActionsME>
        <OrderTabGrid
          gridEvent={gridEvent}
          performRequest={performRequest}
          setFilteredRows={setFilteredRows}
          strings={strings}
          data={data}
          loading={loading}
          error={error}
          tabName={tabName}
          frameworkComponents={{
            certificateCellRenderer: CertificateMECellComponent,
          }}
        />
      </Grid>
    </>
  );
};
