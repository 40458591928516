import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
// import { format } from 'date-fns';
import Container from 'typedi';
// import { HttpRequestBuilder } from '@app/core/http';
import { DirectIsaHttpRequestBuilder } from '@app/data/http';
import { NewChatCreateAnalysisParams } from '@app/data/http/quote-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
//import { handleUnauthorized } from '@app/utils/http-utils';

interface Params {
  client: {
    name: string;
    code: string;
    corporateCode: string;
    dateQuote: string;
  };
  standard: {
    type: string;
    name: string;
    date: string;
    revision: string;
  };
  product: {
    type: string[];
  };
  conversationId?: string;
}

export async function postCreateAnalysis(params?: NewChatCreateAnalysisParams): Promise<Params> {
  const user = Container.get(AuthStorageService).getUser();

  const defaultHeaders = { ...Container.get(DirectIsaHttpRequestBuilder).config.headers, language: user.language };

  const url = `${Container.get(DirectIsaHttpRequestBuilder).config.baseUrl}/analyses/forms`;

  try {
    const { data, status } = await axios.post(url, { query: params.payload }, { headers: defaultHeaders });
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    //handleUnauthorized(error);
    return null;
  }
}

export function useCreateAnalysis(onSuccess?, onError?) {
  return useMutation(postCreateAnalysis, {
    onSuccess,
    onError,
    retry: false,
  });
}
