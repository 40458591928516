import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { IOrderTabOpenInternationalStrings, Strings } from '../order-tab-open-international.string';

export const configTanslateDeliveryStatusFilterInternationalOpened = (columns: ColDef[], language: string) => {
  const strings: IOrderTabOpenInternationalStrings = Strings[language];
  if (columns) {
    columns.forEach(column => {
      if (column.field === 'deliveryStatus') {
        column.filterValueGetter = (params: ValueGetterParams) => {
          return strings.deliveryStatus[params.data.deliveryStatus];
        };
      }
    });
  }
};
