import { format } from 'date-fns';
import { ptBR } from 'date-fns/esm/locale';
import { ComplainClientsData } from '@app/models/complain.model';

export interface Owner {
  Email: string;
  attributes: {
    type: string;
  };
}
export interface ComplainClient {
  GAT_Interno_Responsavel__r?: {
    Email__c: string;
  };
  ID_Externo__c: string;
  Id: string;
  Name: string;
  Numero_ID_Fiscal__c: number;
  Owner: Owner;
}

export interface ComplainClientResponse {
  records: ComplainClient[];
  totalSize: number;
  done: boolean;
}

export const mapComplainClients = (res: ComplainClientResponse): ComplainClientsData[] => {
  return res.records.map(item => {
    return {
      GATEmail: item.GAT_Interno_Responsavel__r?.Email__c,
      type: item.Owner.attributes.type,
      idExternoC: item.ID_Externo__c,
      id: item.Id,
      name: `${item.Name} - ${item.ID_Externo__c}`,
      cnpj: item.Numero_ID_Fiscal__c,
      sellerEmail: item.Owner?.Email,
    };
  });
};

interface ContactComplain {
  Email?: string;
  Name?: string;
}

interface Account {
  ID_Externo__c?: string;
  Name?: string;
}
interface OwnerComplain {
  Email?: string;
}
interface CreatedBy {
  Email?: string;
  FirstName?: string;
  LastName?: string;
}
export interface CaseComplain {
  Account?: Account;
  Subject?: string;
  Area_Responsavel__c?: string;
  CaseNumber?: string;
  Causa_aparente__c?: string;
  Cliente_Deseja__c?: string;
  Contact?: ContactComplain;
  Description?: string;
  Ecasogsb__c?: string;
  Email_do_vendedor__c?: string;
  Envolve_Fluxo_Financeiro__c?: boolean;
  Formulario_E_Gerdau__c?: boolean;
  Lote_Envolvido__c?: string;
  Motivo__c?: string;
  N_Documento_de_Faturamento__c?: string;
  N_referencia_NF__c?: string;
  Nome_do_Vendedor__c?: string;
  Numero_do_tratamento_de_falha__c?: string;
  Origin?: string;
  Owner?: OwnerComplain;
  Peso_Envolvido__c?: string;
  Priority?: string;
  Status?: string;
  SuppliedEmail?: string;
  SuppliedName?: string;
  SuppliedPhone?: string;
  Tipo_NF__c?: string;
  Usuario_eGerdau__c?: string;
  CreatedBy?: CreatedBy;
  CreatedDate?: string;
  ClosedDate?: string;
  Valor_Total_Produtos__c?: number;
  Valor_Envolvido__c?: number;
  Moeda__c?: string;
  Destino__c?: string;
  RecordType?: { Name: string };
  SKUs__c?: string;
  Numero_Embarque__c?: string;
  N_Chamado_Service_Desk__c?: string;
}

export const CaseComplainKeys = [
  { key: 'Client', label: 'Nome do cliente', type: 'staticText' },
  { key: 'ID_Externo__c', label: 'Código do cliente', type: 'number' },
  { key: 'Market', label: 'Mercado', type: 'string' },
  { key: 'Subject', label: 'Assunto', type: 'staticText' },
  { key: 'Status', label: 'Status', type: 'staticText' },
  { key: 'Area_Responsavel__c', label: 'Área Responsável', type: 'staticText' },
  { key: 'CaseNumber', label: 'Número da manifestação', type: 'number' },
  { key: 'CreatedDate', label: 'Data de criação', type: 'date' },
  { key: 'ClosedDate', label: 'Data de fechamento', type: 'date' },
  { key: 'Cliente_Deseja__c', label: 'Cliente deseja', type: 'staticText' },
  { key: 'Motivo__c', label: 'Motivo', type: 'staticText' },
  { key: 'Causa_aparente__c', label: 'Causa aparente', type: 'staticText' },
  { key: 'Description', label: 'Descrição', type: 'staticText' },
  { key: 'Contact_email', label: 'Email do contato do cliente', type: 'staticText' },
  { key: 'Contact_name', label: 'Nome do contato do cliente', type: 'staticText' },
  { key: 'Email_do_vendedor__c', label: 'Email do vendedor', type: 'staticText' },
  { key: 'Lote_Envolvido__c', label: 'Lote envolvido', type: 'staticText' },
  { key: 'N_Documento_de_Faturamento__c', label: 'Nº Documento de faturamento', type: 'staticText' },
  { key: 'N_referencia_NF__c', label: 'Nº Referência NF', type: 'staticText' },
  { key: 'Nome_do_Vendedor__c', label: 'Nome do vendedor', type: 'staticText' },
  { key: 'Numero_do_tratamento_de_falha__c', label: 'Nº Tratamento de falha', type: 'staticText' },
  { key: 'Owner_email', label: 'Email do GAT responsável', type: 'staticText' },
  { key: 'Peso_Envolvido__c', label: 'Peso envolvido (Ton.)', type: 'staticText' },
  { key: 'Priority', label: 'Prioridade', type: 'staticText' },
  { key: 'Tipo_NF__c', label: 'Tipo NF', type: 'staticText' },
  { key: 'Origin', label: 'Origem', type: 'staticText' },
  { key: 'Usuario_eGerdau__c', label: 'Criado por', type: 'staticText' },
  { key: 'SKUs__c', label: 'SKUs', type: 'staticText' },

  { key: 'Numero_Embarque__c', label: 'Nº de embarque', type: 'staticText' },
  { key: 'N_Chamado_Service_Desk__c', label: 'Nº chamado Service Desk', type: 'staticText' },

  { key: 'Valor_Total_Produtos__c', label: '(R$) Valor Total dos Produtos', type: 'number' },
  { key: 'Moeda__c', label: 'Moeda', type: 'staticText' },
  { key: 'Valor_Envolvido__c', label: 'Valor envolvido', type: 'number' },
  { key: 'Destino__c', label: 'Destino', type: 'staticText' },
];

export interface CaseComplainResponse {
  records: CaseComplain[];
  totalSize: number;
  done: boolean;
}

const convertUnit = value => {
  const convertedNumber = value ? value.toLocaleString() : (0).toLocaleString();
  return convertedNumber;
};

const mapOrigin = (origin: string) => {
  if (origin === 'Gerdau Mais' || origin === 'E-Gerdau') {
    return 'Gerdau Mais';
  } else if (origin === 'Solicitação Interna') {
    return 'Salesforce';
  } else {
    return origin;
  }
};

const compareDates = (a, b) => {
  if (a.CreatedDate > b.CreatedDate) {
    return -1;
  } else if (a.CreatedDate < b.CreatedDate) {
    return 1;
  }
  return 0;
};

const verifyPeso = (pesos, numRef) => {
  let qtd = 0;
  if (numRef) {
    qtd = numRef.split(',').length;
  }
  if (pesos.split(',').length !== qtd) {
    const valRemovedChars = pesos.replaceAll(/[^0-9,]/g, '-');
    const valSeparator = valRemovedChars.replaceAll(',-', '|');
    const valArray = valSeparator.split('|');
    const valores = valArray.map(val => val.replaceAll('-', '').replaceAll(',', '.'));

    return valores.join();
  } else {
    return pesos;
  }
};

const mapCaseComplainRows = (res: CaseComplainResponse) => {
  res.records.sort(compareDates);

  return res.records.map(col => ({
    Client: col?.Account?.Name ? col.Account.Name : '',
    ID_Externo__c: col?.Account?.ID_Externo__c,
    Market: col.RecordType.Name === 'RC GSB ME' ? 'ME' : 'MI',
    Subject: `${col.Subject[0].toUpperCase()}${col.Subject.substring(1)}`,
    Status: col.Status,
    Area_Responsavel__c: col.Area_Responsavel__c,
    CaseNumber: col.CaseNumber,
    CreatedDate: col.CreatedDate && format(new Date(col.CreatedDate), 'dd/MM/yyyy HH:mm', { locale: ptBR }),
    ClosedDate: col.ClosedDate && format(new Date(col.ClosedDate), 'dd/MM/yyyy HH:mm', { locale: ptBR }),
    Cliente_Deseja__c: col.Cliente_Deseja__c,
    Motivo__c: col.Motivo__c,
    Causa_aparente__c: col.Causa_aparente__c,
    Description: `${col.Description[0].toUpperCase()}${col.Description.substring(1)}`,
    Contact_email: col?.Contact?.Email ? col.Contact.Email : '',
    Contact_name: col?.Contact?.Name ? col.Contact.Name : '',
    Email_do_vendedor__c: col.Email_do_vendedor__c,
    Lote_Envolvido__c: col.Lote_Envolvido__c,
    N_Documento_de_Faturamento__c: col.N_Documento_de_Faturamento__c,
    N_referencia_NF__c: col.N_referencia_NF__c,
    Nome_do_Vendedor__c: col.Nome_do_Vendedor__c,
    Numero_do_tratamento_de_falha__c: col.Numero_do_tratamento_de_falha__c,
    Owner_email: col.Owner.Email,
    Peso_Envolvido__c: col.Peso_Envolvido__c
      ? verifyPeso(col.Peso_Envolvido__c, col.N_referencia_NF__c)
      : col.Peso_Envolvido__c,
    Priority: col.Priority,
    Tipo_NF__c: col.Tipo_NF__c,
    Origin: mapOrigin(col.Origin),
    Usuario_eGerdau__c:
      col.Origin === 'Gerdau Mais' || col.Origin === 'E-Gerdau' ? col.Usuario_eGerdau__c : col.CreatedBy.Email,
    Valor_Total_Produtos__c: convertUnit(col.Valor_Total_Produtos__c),
    Moeda__c: col.Moeda__c,
    Valor_Envolvido__c: convertUnit(col.Valor_Envolvido__c),
    Destino__c: col.Destino__c,
    SKUs__c: col.SKUs__c,
    Numero_Embarque__c: col.Numero_Embarque__c,
    N_Chamado_Service_Desk__c: col.N_Chamado_Service_Desk__c,
  }));
};

export const mapComplainCases = response => {
  const responseCases = {
    complainCases: {
      total: response.totalSize,
      columns: CaseComplainKeys,
      rows: mapCaseComplainRows(response),
    },
  };

  return responseCases;
};
