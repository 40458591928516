import { format } from 'date-fns';
import { CollapsedMonthNames } from '@app/models/dashboard.model';
import { dateParser } from './date-parser';

export const getNewFormatedDateByLanguage = language => {
  let formatedDate;
  if (language === 'in') {
    formatedDate = format(new Date(), 'MMM Mo, yyyy - hh:mm aa');
  } else {
    formatedDate = dateParser({ language });
  }
  return formatedDate;
};

export const getCollapsedMonthName = (month: number) => {
  const collapsedName = CollapsedMonthNames[month - 1];
  return collapsedName;
};

export const getDateStringFilename = () => {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const monthString = date.getMonth().toString().length === 1 ? `0${currentMonth}` : currentMonth.toString();
  const dateString = `${date.getDate()}${monthString}${date.getFullYear()}`;
  return dateString;
};

export const dateComparator = (date1, date2, language) => {
  const date1Number = _monthToNum(date1, language);
  const date2Number = _monthToNum(date2, language);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
};

const _monthToNum = (date, language) => {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }

  let yearNumber;
  let monthNumber;
  let dayNumber;

  if (language === 'in') {
    if (date.includes('-')) {
      yearNumber = date.substring(0, 4);
      monthNumber = date.substring(5, 7);
      dayNumber = date.substring(8, 10);
    } else {
      yearNumber = date.substring(6, 10);
      dayNumber = date.substring(0, 2);
      monthNumber = date.substring(3, 5);
    }
  } else {
    yearNumber = date.substring(6, 10);
    monthNumber = date.substring(3, 5);
    dayNumber = date.substring(0, 2);
  }
  const result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

  return result;
};

export const brDateFormatToDate = stringDate => {
  const day = stringDate.substring(0, 2);
  const month = stringDate.substring(3, 5);
  const year = stringDate.substring(6, 20);
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  return date;
};

export const getNewDateISOString = () => {
  return new Date().toISOString();
};
