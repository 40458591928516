interface HandleStartDateChangeParams {
  date: Date;
  endDate: Date;
  setStartDate: (value: number) => void;
  setEndDate: (value: number) => void;
}

export const handleStartDateChange = ({ date, endDate, setStartDate, setEndDate }: HandleStartDateChangeParams) => {
  if (date) {
    setStartDate(date.getTime());
    if (date > endDate) {
      setEndDate(date.getTime());
    }
  }
};
interface HandleendDateChangeParams {
  date: Date;
  setEndDate: (value: number) => void;
}

export const handleEndDateChange = ({ date, setEndDate }: HandleendDateChangeParams) => {
  if (date) {
    setEndDate(date.getTime());
  }
};
