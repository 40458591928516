import * as React from 'react';
import { MatchedMatch } from '@app/models/dashboard.model';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Strings, IDashBoardStrings } from '../dashboard.string';

interface LegendProps {
  data: MatchedMatch[];
}

export const FinanceBarChartLegend: React.FC<LegendProps> = props => {
  const strings: IDashBoardStrings = Strings.pt;

  return (
    <>
      <Hbox pl={'20px'} pr={'30px'} pb={'15px'}>
        <Hbox.Item vAlign='center' style={{ maxWidth: '12%', marginRight: '10px' }}>
          <Body>{strings.graphs.finance.matchedMatches.documentNumberQty}</Body>
        </Hbox.Item>
        {props.data?.map((m, i) => {
          return (
            <Hbox.Item vAlign='center' hAlign='center' key={`col-${i}`} style={{ maxWidth: '22%' }}>
              <H3>{m.documentMonthNumber}</H3>
            </Hbox.Item>
          );
        })}
      </Hbox>
    </>
  );
};
