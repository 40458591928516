import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { REMOVE_MATERIAL_OPTION_ARRAY_ACTION } from '@app/modules/order-input/store/order-input.reducers';
import {
  removeSelectedMaterialOption,
  setDeleteMaterialModalOpened,
  setMaterialDelete,
  setSelectAll,
} from '@app/modules/order-input/store/order-input.store';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Grid, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';

const strings = OrderInputStrings.pt.dateAndAmount.modalActions;

export const DeleteMaterialConfirmModal: React.FC = () => {
  const { materialDelete, deleteMaterialModalOpened } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch();

  const onConfirm = () => {
    dispatch(setSelectAll(false));
    dispatch(setDeleteMaterialModalOpened(false));
    if (materialDelete) {
      dispatch(removeSelectedMaterialOption(materialDelete.tempId));
    } else {
      dispatch(REMOVE_MATERIAL_OPTION_ARRAY_ACTION);
    }
  };

  const onClose = () => {
    dispatch(setMaterialDelete(null));
    dispatch(setDeleteMaterialModalOpened(false));
  };

  const message = materialDelete ? strings.confirmDelete : strings.confirmDeletePlural;

  return (
    <Modal small opened={deleteMaterialModalOpened} onClose={onClose}>
      <Grid fluid>
        <Row>
          <H2>{strings.deleteTitle}</H2>
        </Row>
        <Row>
          <Body>{message}</Body>
        </Row>
        <Row mt mb>
          <FlexRow fullWidth hAlign='center'>
            <Button onClick={onClose} expanded kind='secondary'>
              {strings.cancelButton}
            </Button>
            <Button onClick={onConfirm} expanded>
              {strings.confirmButton}
            </Button>
          </FlexRow>
        </Row>
      </Grid>
    </Modal>
  );
};
