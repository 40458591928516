import React from 'react';
import { TableTDTypes } from '@app/modules/shipping-request/shipping-request.constants';
import { IShippingRequestRow } from '@app/modules/shipping-request/shipping-request.interfaces';
import { formatQtyField } from '@app/utils/string-utils';
import { Body } from '@atomic/atm.typography';
import { ShippingRequestSuggestionEditQtyEatonField } from './partials/shipping-request-edit-qty-eaton-field/shipping-request-edit-qty-eaton-field.component';
import { ShippingRequestEditQtyEatonTableField } from './partials/shipping-request-edit-qty-eaton-table/shipping-request-edit-qty-eaton-table-field.component';
import { ShippingRequestEditQtyField } from './partials/shipping-request-edit-qty-field/shipping-request-edit-qty-field.component';
import { ShippingRequestEditReceiverField } from './partials/shipping-request-edit-receiver-field/shipping-request-edit-receiver-field.component';
import { ShippingRequestObsField } from './partials/shipping-request-obs-field/shipping-request-obs-field.component';
import { ShippingRequestSuggestionCheckBoxField } from './partials/shipping-request-suggestion-checkbox-field/shipping-request-suggestion-checkbox-field.component';
import { QtyFieldStyled } from './shipping-request-table-body-td.style';

interface IShippingRequestTableBodyTDProps {
  type: TableTDTypes;
  value: number;
  item: IShippingRequestRow;
  hasSuggestion?: boolean;
}

export const ShippingRequestTableBodyTD: React.FC<IShippingRequestTableBodyTDProps> = ({
  type,
  value,
  item,
  hasSuggestion,
}) => {
  switch (type) {
    case TableTDTypes.Qty:
      if (value) {
        return <QtyFieldStyled>{formatQtyField(value)}</QtyFieldStyled>;
      } else {
        return null;
      }
    case TableTDTypes.Obs:
      return <ShippingRequestObsField item={item} hasSuggestion={hasSuggestion} />;
    case TableTDTypes.QtyEdit:
      return <ShippingRequestEditQtyField item={item} hasSuggestion={hasSuggestion} />;
    case TableTDTypes.QtyEditEatonTable:
      return <ShippingRequestEditQtyEatonTableField item={item} hasSuggestion={hasSuggestion} />;
    case TableTDTypes.QtyEditEaton:
      return <ShippingRequestSuggestionEditQtyEatonField item={item} hasSuggestion={hasSuggestion} />;
    case TableTDTypes.Button:
      return <ShippingRequestEditReceiverField item={item} hasSuggestion={hasSuggestion} />;
    case TableTDTypes.SuggestionCheckBox:
      return <ShippingRequestSuggestionCheckBoxField item={item} />;
    default:
      return <Body centralized>{value}</Body>;
  }
};
