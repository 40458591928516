export interface SalesOrderItem {
  product: string;
  marketDest: string;
  width: number;
  alloyClient: string;
  netPrice: number;
  ocClient: string;
  dateMaxDeliveryClient: string;
  process: string;
  dateSolClient: string;
  lengthMin: number;
  docQtdOri: number;
  codGroupRentability: string;
  standardSteel: string;
  standardRef: string;
  dateDeliveryClient: string;
  invoiceType: string;
  docCategory: string;
  lengthMax: number;
  docQtdT: number;
  docDate: string;
  standardProdId: string;
  plant: string;
  descMaterial: string;
  clientNumber: string;
  taker: string;
  visDeliveryMax: string;
  lengthMult: number;
  shape: string;
  material: string;
  diameter: number;
  qtdConf: number;
  ocClientItem: string;
  invoiceDate: string;
  brand: string;
  ov: string;
  codMaterialClient: string;
  alloyGsb: string;
  dtStandardRef: string;
  visDeliveryMin: string;
  status: string;
  invoice: number;
  dateOfDeparture: string;
  qtdSolClient: number;
  thickness: number;
  numDocument: string;
  boarding: string;
  nameClient: string;
  numDeliveryItem: number;
  bookingId: number;
  bookingName: string;
  processId: number;
  ETD: string;
  ETA: string;
  ETC: string;
  freetimeDestination: number;
  shipName: string;
  dateDeliveryForecast: string;
  deliveryStatus: string;
  invoiceEmissionDate: string;
  takerId: string;
  concatenatedBillingDocument: string;
  batch: string;
  truckPlate: string;
  heat: string;
  ovType: string;
  truck?: string;
  transportDocument?: string;
  CRT?: string;
  CRTDate?: string;
  customerPurchaseOrderItemNumber?: string;
  standardGradeIssueDateStandardGrade: string;
}

export interface SalesOrderSummaryItem {
  product: string;
  marketDest: string;
  width: number;
  alloyClient: string;
  ocClient: string;
  dateMaxDeliveryClient: string;
  process: string;
  dateSolClient: string;
  lengthMin: number;
  codGroupRentability: string;
  standardSteel: string;
  standardRef: string;
  dateDeliveryClient: string;
  lengthMax: number;
  docDate: string;
  standardProdId: string;
  plant: string;
  plantName: string;
  descMaterial: string;
  clientNumber: string;
  taker: string;
  visDeliveryMax: string;
  lengthMult: number;
  shape: string;
  material: string;
  diameter: number;
  qtdConf: number;
  ocClientItem: string;
  brand: string;
  ov: string;
  codMaterialClient: string;
  alloyGsb: string;
  dtStandardRef: string;
  visDeliveryMin: string;
  status: string;
  dateOfDeparture: string;
  qtdSolClient: number;
  thickness: number;
  nameClient: string;
  ETD: string;
  ETA: string;
  ETC: string;
  dateDeliveryForecast: string;
  deliveryStatus: string;
  qtyTotalBilled: number;
  qtyBillingOpen: number;
  qtyDelivery: number;
  qtyStock: number;
  qtyTotalProduction: number;
  itemOv: string;
  activateNotificationCol: boolean;
  netPrice?: number;
  docQtdOri?: number;
  invoiceType?: string;
  docCategory?: string;
  invoiceItem?: number;
  invoiceDate?: string;
  invoice?: number;
  numDocument?: string;
  boarding?: string;
  numDeliveryItem?: number;
  bookingId?: number;
  bookingName?: string;
  processId?: number;
  freetimeDestination?: number;
  shipName?: string;
  truck?: string;
  transportDocument?: string;
  invoiceEmissionDate?: string;
  CRT?: string;
  CRTDate?: string;
  activeSector?: string;
  activateShipping?: boolean;
  dateProcessJob?: string;
  dtConfirmedStock?: string;
  dtCustomerRequiredOriginal?: string;
  qtyNotAllocated?: number;
  qtyTotalStatus?: number;
  qtdeDatasCartCli?: number;
  dateUpdate: string;
  activateCritical?: boolean;
  customerPurchaseOrderItemNumber?: string;
  standardGradeIssueDateStandardGrade?: string;
  dtPurchase?: string;
}

export interface InStockOrderItem {
  salesOrderStatus: string;
  shipToPartyName: string;
  materialName: string;
  customerRequiredDate: string;
  material: string;
  agreedQuantity: number;
  storageLocation: string;
  customerAgreedDate: string;
  language: string;
  soldToPartyName: string;
  shipToParty: string;
  customerPurchaseOrderItem: string;
  plantName: string;
  customerMaterialCode: string;
  salesDocumentItem: string;
  soldToParty: string;
  salesDocument: string;
  plant: string;
  customerPurchaseOrderNumber: number;
  confirmedShippingDate: string;
  purchaseDate: string;
  billingOpenQuantity: number;
  delivPendQtyGen: number;
  deliveryQuantity: number;
  totalBilledQuantity: number;
  pickingQuantity: number;
  status: string;
  stockQuantity: number;
  batch: string;
  qtyCustomerRequiredTons: number;
  heat: string;
  typeStock: string;
  dateOfStockEntry: string;
  qtyDateOfStockEntry: number;
  soldToCGCityName: string;
  shipToCityName: string;
  customerPurchaseOrderItemNumber?: string;
  breakHeat?: string;
  lastHeat?: string;
  finish?: string;
  hasQuantityLastHeat?: string;
  qtyStockLastHeat?: number;
}

export interface StockRealDateItem {
  totalStockQuantity: number;
  batch: string;
  dateOfStockEntry: string;
}

export enum DeliveryStatus {
  Delay = 'delay',
  DelayTrend = 'delayTrend',
  OnTime = 'onTime',
  NotAnalized = 'notAnalized',
  NotConfirmed = 'notConfirmed',
  NoForecast = 'noForecast',
  StockWaitingBilling = 'stockWaitingBilling',
  LackOfCharge = 'lackOfCharge',
}
export interface DeliveryForecast {
  deliveryForecasts: DeliveryForecastItem[];
}
export interface DeliveryForecastItem {
  salesDocument: number;
  salesDocumentItem: string;
  soldToParty: string;
  material: number;
  plant: string;
  baseUnit: string;
  dtEstimateDeliveryMin: string;
  dtEstimateDeliveryMax: string;
  docQuantity: string;
}
