import { NpsPhase } from '../nps-modal.constants';
import { INpsState } from '../nps-modal.interfaces';

export const initialState: INpsState = {
  phase: NpsPhase.Grade,
  note: null,
  message: '',
  dontSeeAgain: false,
  loadingNPS: false,
  hasNPS: false,
};
