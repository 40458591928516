import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import { AppPath } from '@app/modules/app/route-constants';
import { GridButtonWithIcon } from '@app/modules/components/grid-button-with-icon.component';
import {
  INPUT_FILTERS_INITIAL_VALUE,
  MAX_SIZE_FILTDS,
} from '@app/modules/components/side-bar-filters/sidebar-filters.constants';
import { ISidebarFiltersStrings, Strings } from '@app/modules/components/side-bar-filters/sidebar-filters.strings';
import { handleDebounceInputUpdate } from '@app/modules/components/side-bar-filters/sidebar-filters.utils';
import { setInputFilters } from '@app/modules/components/side-bar-filters/store/sidebar-filters.store';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import {
  AddMoreButtonWrapper,
  FilterWrapper,
  InputFilterWrapper,
  ListFilterWrapper,
} from './input-filter-content.style';

interface InputFilterContentProps {
  filterKey: string;
}

export const InputFilterContent: React.FC<InputFilterContentProps> = ({ filterKey }) => {
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const history = useHistory();
  const tab = history.location.pathname === AppPath.FINANCE ? LayoutTabs.FinanceMI : MItabIndex[orderTabIndex];
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ISidebarFiltersStrings = Strings[userInfo.language];
  const { filters } = useSelector((state: RootState) => state.advancedFilters[tab]);

  const [inputValues, setInputValues] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setInputValues(filters[filterKey]);
  }, [filters[filterKey]]);

  const onCLickAddMoreFileds = () => {
    const inputValuesClone = [...inputValues];
    const concatenatedArray = inputValuesClone.concat(INPUT_FILTERS_INITIAL_VALUE);

    if (concatenatedArray.length <= MAX_SIZE_FILTDS) {
      dispatch(setInputFilters({ tab, filterKey, value: concatenatedArray }));
    }
  };

  const onValueChange = (index, value) => {
    const inputValuesClone = [...inputValues];
    inputValuesClone[index] = value;
    setInputValues(inputValuesClone);
    handleDebounceInputUpdate(inputValues, () => dispatch(setInputFilters({ tab, filterKey, value: inputValues })));
  };

  const onPaste = event => {
    event.preventDefault();
    const pastedValue = event.clipboardData.getData('text');
    const splitedValues = pastedValue.split('\n');

    if (splitedValues.length <= MAX_SIZE_FILTDS) {
      const inputValuesClone = [...inputValues];

      splitedValues.forEach((value, index) => {
        inputValuesClone[index] = value;
      });

      dispatch(setInputFilters({ tab, filterKey, value: inputValuesClone }));
    }
  };

  return (
    <FilterWrapper>
      <ListFilterWrapper>
        {inputValues.map((inputValue, index) => (
          <InputFilterWrapper key={index}>
            <TextField
              value={inputValue}
              onValueChange={value => onValueChange(index, value)}
              onPaste={index === 0 ? onPaste : null}
              maxLength={200}
            />
          </InputFilterWrapper>
        ))}
      </ListFilterWrapper>
      <AddMoreButtonWrapper>
        <GridButtonWithIcon
          text={strings.addMoreFilter}
          icon={FaIcon.Plus}
          kind='secondary'
          onClick={onCLickAddMoreFileds}
        />
      </AddMoreButtonWrapper>
    </FilterWrapper>
  );
};
