import { AppPath } from '../app/route-constants';

interface IBreadCrumbs {
  label: string;
  path?: string;
}

export interface IOrderUpdateStrings {
  page: {
    breadcrumbs: IBreadCrumbs[];
    title: string;
    help: string;
    goBack: string;
    finish: string;
    instructions: string;
    instructionColor: string;
    posibleUpdates: string;
  };
  table: {
    headerRow: {
      ov: string;
      ocClient: string;
      codMaterialClient: string;
      qtdConf: string;
      qtdSolClient: string;
      dateMaxDeliveryClient: string;
      dateSolClient: string;
      newQtdSolClient: string;
      newDateSolClient: string;
      cancelOrder: string;
      messageClient: string;
    };
    title: {
      primary: string;
      comment: string;
    };
    requestCancel: string;
    undoRequestCancel: string;
    newQtd: string;
    newMsg: string;
    newDate: string;
    statusWarning: string;
    dateWarning: string;
    cancelWarning: string;
    trIgnoreButton: string;
    trDeleteItemButton: string;
    trRevisionItemButton: string;
  };
  modal: {
    title: string;
    comment: string;
    item: (ov: string, oc: string) => string;
    changeDate: (date: string) => string;
    changeQuantity: (quantity: number) => string;
    newDate: (date: string) => string;
    newQuantity: (quantity: number) => string;
    goBack: string;
    submit: string;
  };
  help: {
    title: string;
    instructions: string;
    close: string;
    continue: string;
  };
  comment: {
    title: string;
    placeholder: string;
    goBack: string;
    save: string;
  };
  finish: {
    success: string;
    error: string;
  };
}

export interface IOrderUpdateLanguages {
  pt: IOrderUpdateStrings;
  es: IOrderUpdateStrings;
  in: IOrderUpdateStrings;
}

export const pt: IOrderUpdateStrings = {
  page: {
    breadcrumbs: [{ label: 'Voltar para carteira de pedidos', path: AppPath.ORDERS }, { label: 'Alterar carteira' }],
    title: 'Alterar carteira',
    help: 'Como funciona?',
    goBack: 'Voltar',
    finish: 'Finalizar solicitação',
    instructions: 'As colunas que podem ser alteradas se encontram na ',
    instructionColor: ' cor azul.',
    posibleUpdates: 'Alterações possíveis*',
  },
  table: {
    headerRow: {
      ov: 'Ordem de venda',
      ocClient: 'Ordem de compra',
      codMaterialClient: 'Cod. Prod. Cliente',
      qtdConf: 'Quantidade confirmada (t)',
      qtdSolClient: 'Quantidade solicitada (t)*',
      dateMaxDeliveryClient: 'Data confirmada',
      dateSolClient: 'Data solicitada*',
      newDateSolClient: 'Nova data solicitada',
      newQtdSolClient: 'Nova quantidade solicitada (t)',
      cancelOrder: 'Solicitação de cancelamento',
      messageClient: 'Mensagem (opcional)',
    },
    title: {
      primary: 'Agora inclua as alterações necessárias',
      comment: 'Adicionar comentário geral',
    },
    requestCancel: 'Solicitar cancelamento',
    undoRequestCancel: 'Desfazer cancelamento',
    newQtd: 'Nova qtd',
    newMsg: 'Digite sua mensagem',
    newDate: 'Nova data',
    statusWarning:
      'Atenção! Esta ordem de venda está &nbsp;<b> em aprazamento </b>&nbsp; (em análise pela usina) e ela será analisada após a conclusão desta etapa, prevista para a última semana do mês.',
    dateWarning: 'Atenção! Esta solicitação de nova data possui &nbsp;<b> uma diferença de dias muito pequena </b>.',
    cancelWarning:
      'Atenção! Esta ordem de venda possui &nbsp;<b> material em processo </b>&nbsp; (em produção ou estoque) e por isso &nbsp;<b> não é indicado </b>&nbsp; realizar o cancelamento.',
    trIgnoreButton: 'Prosseguir',
    trDeleteItemButton: 'Excluir',
    trRevisionItemButton: 'Revisar',
  },
  modal: {
    title: 'Revise a sua solicitação',
    comment: 'Comentário geral',
    item: (ov: string, oc: string) => `Ordem de venda ${ov} (Ordem de compra ${oc})`,
    changeDate: (date: string) => `Alterar data de solicitação de ${date} `,
    changeQuantity: (quantity: number) => `Alterar toneladas de ${quantity} `,
    newDate: (date: string) => ` Para ${date}`,
    newQuantity: (quantity: number) => ` Para ${quantity}`,
    goBack: 'Voltar para edição',
    submit: 'Enviar',
  },
  help: {
    title: 'Solicite uma alteração de carteira',
    instructions:
      'Altere a quantidade, data de entrega ou cancele o pedido. Receberemos o seu pedido e você terá uma resposta em até 4 dias úteis via e-mail.',
    close: 'Pular instrução',
    continue: 'Veja como',
  },
  comment: {
    title: 'Adicionar comentário geral (opcional)',
    placeholder: 'Escreva uma mensagem geral no corpo de e-mail para o vendedor.',
    goBack: 'Voltar para edição',
    save: 'Salvar',
  },
  finish: {
    success:
      'Mensagem enviada com sucesso! Você receberá uma cópia via e-mail e terá uma resposta em até 4 dias úteis.',
    error: 'Falha no envio da mensagem. Verifique os dados preenchidos e tente novamente.',
  },
};

export const Strings: IOrderUpdateLanguages = {
  pt,
  es: pt,
  in: pt,
};
