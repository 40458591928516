import styled from 'styled-components';
import { FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export const AgGridRowCounterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${Spacing.Medium};
  font-size: ${FontSize.XSmall};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
`;

export const AgGridBoldCounter = styled.div`
  margin-left: ${Spacing.XSmall};
  margin-right: ${Spacing.XSmall};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.XSmall};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
`;
