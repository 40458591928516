import {
  SalesOrdersMaritimeHarborItem,
  SalesOrdersMaritimeOpenItem,
  SalesOrdersMaritimeShippedItem,
} from '@app/models/maritime-order-item.model';
import { parseOrderDate } from './order-date.mapper';

export interface SalesOrdersMaritimeOpenDto {
  salesOrdersMaritime: SalesOrdersMaritimeOpenItemDto[];
}

export interface SalesOrdersMaritimeOpenItemDto {
  concatenatedSalesDocument: string;
  shipment: string;
  material: string;
  materialName: string;
  plantName: string;
  soldToParty: string;
  corporateGroup: string;
  soldToPartyName: string;
  shipToPartyName: string;
  customerPurchaseOrderNumber: string;
  customerMaterialCode: string;
  dtPurchase: string;
  salesOrderStatus: string;
  size: number;
  sizeIn: number;
  width: number;
  thickness: number;
  minMaxLenght: string;
  multipleLength: number;
  standardGrade: string;
  externalGrade: string;
  product: string;
  shape: string;
  brand: string;
  surfaceFinishin: string;
  heatTreatment: string;
  country: string;
  dtCustomerRequired: string;
  dtEstimateStock: string;
  dtEstimatedAtCustomer: string;
  qtyCustomerRequiredTons: number;
  qtyAgreedTons: number;
  qtyStockPendOvItem: number;
  qtyTotalStockOvItem: number;
  qtyShippedOvItem: number;
  qtyPortoFronteiraOvItem: number;
  dtConfirmedStock: string;
  heat: string;
}

const fromToStatusMap = (salesOrderStatus): string => {
  if (salesOrderStatus === 'Implantado') {
    return 'Não Confirmado';
  } else if (salesOrderStatus === 'Em Aprazamento') {
    return 'Não confirmado - em análise';
  } else {
    return salesOrderStatus;
  }
};

export const mapSalesOrdersMaritimeOpenItem = (
  orders: SalesOrdersMaritimeOpenItemDto[],
): SalesOrdersMaritimeOpenItem[] => {
  return orders.map(order => {
    return {
      ...order,
      dtCustomerRequired: parseOrderDate(order.dtCustomerRequired),
      dtEstimateStock: parseOrderDate(order.dtEstimateStock),
      dtEstimatedAtCustomer: parseOrderDate(order.dtEstimatedAtCustomer),
      dtConfirmedStock: parseOrderDate(order.dtConfirmedStock),
      salesOrderStatus: fromToStatusMap(order.salesOrderStatus),
    };
  });
};

export interface SalesOrdersMaritimeShippedDto {
  shippedMaritime: SalesOrdersMaritimeShippedItemDto[];
}

export interface SalesOrdersMaritimeShippedItemDto {
  shipment: string;
  customerPurchaseOrderNumber: string;
  plantName: string;
  soldToParty: string;
  soldToPartyName: string;
  corporateGroup: string;
  shipToPartyName: string;
  customerMaterialCode: string;
  material: string;
  materialName: string;
  minMaxLenght: string;
  multipleLength: string;
  product: string;
  shape: string;
  surfaceFinishin: string;
  externalGrade: string;
  heatTreatment: string;
  size: number;
  sizeIn: number;
  thickness: string;
  width: string;
  standardGrade: string;
  brand: string;
  nFNumber: string;
  heat: string;
  country: string;
  concatenatedSalesDocument: string;
  shipmentDate: string;
  shipmentStatus: string;
  dtPurchase: string;
  dtCustomerRequired: string;
  nfDate: string;
  nfQuantity: number;
  etd: string;
  eta: string;
  etc: string;
  booking: string;
  shipName: string;
  blDate: string;
  locationDestinationDate: string;
  bookingId: string;
  processId: string;
}

export const mapSalesOrdersMaritimeShippedItem = (
  orders: SalesOrdersMaritimeShippedItemDto[],
): SalesOrdersMaritimeShippedItem[] => {
  return orders.map(order => {
    return {
      ...order,
      nfNumber: order.nFNumber,
      dtPurchase: parseOrderDate(order.dtPurchase),
      dtCustomerRequired: parseOrderDate(order.dtCustomerRequired),
      etd: parseOrderDate(order.etd),
      eta: parseOrderDate(order.eta),
      etc: parseOrderDate(order.etc),
      locationDestinationDate: parseOrderDate(order.locationDestinationDate),
      shipmentDate: parseOrderDate(order.shipmentDate),
    };
  });
};
export interface SalesOrdersMaritimeHarborDto {
  harborMaritime: SalesOrdersMaritimeHarborItemDto[];
}

export interface SalesOrdersMaritimeHarborItemDto {
  shipment: string;
  customerPurchaseOrderNumber: string;
  plantName: string;
  soldToParty: string;
  soldToPartyName: string;
  corporateGroup: string;
  shipToPartyName: string;
  customerMaterialCode: string;
  material: string;
  materialName: string;
  minMaxLenght: string;
  multipleLength: string;
  product: string;
  shape: string;
  surfaceFinishin: string;
  externalGrade: string;
  heatTreatment: string;
  size: number;
  sizeIn: number;
  thickness: string;
  width: string;
  standardGrade: string;
  brand: string;
  nfNumber: string;
  heat: string;
  country: string;
  concatenatedSalesDocument: string;
  shipmentStatus: string;
  dtPurchase: string;
  dtCustomerRequired: string;
  nfDate: string;
  nfQuantity: number;
  etd: string;
  eta: string;
  etc: string;
  booking: string;
  shipName: string;
  bookingId: string;
  processId: string;
}

export const mapSalesOrdersMaritimeHarborItem = (
  orders: SalesOrdersMaritimeHarborItemDto[],
): SalesOrdersMaritimeHarborItem[] => {
  return orders.map(order => {
    return {
      ...order,
      nFNumber: order.nfNumber,
      dtPurchase: parseOrderDate(order.dtPurchase),
      dtCustomerRequired: parseOrderDate(order.dtCustomerRequired),
      etd: parseOrderDate(order.etd),
      eta: parseOrderDate(order.eta),
      etc: parseOrderDate(order.etc),
    };
  });
};
