import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderPriorizationDataSource } from '@app/data/http/order-priorization.datasource';
import { IAuthState } from '@app/modules/auth/auth.interfaces';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { IOrderTabOpenState } from '@app/modules/order/order-tabs/MI/tab-open/store/order-tab-open.interface';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import { SLICE_NAME } from '../../order-priorization.constants';
import {
  ICriticalItem,
  IOrderPriorizationState,
  IOrderPriorizationTableData,
} from '../../order-priorization.interfaces';

export interface IDeleteCriticalItemsParams {
  critialItem: IOrderPriorizationTableData;
}

export const deleteCriticalItems = createAsyncThunk(
  `${SLICE_NAME}/deleteCriticalItems`,
  async ({ critialItem }: IDeleteCriticalItemsParams, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const customerIds = customerIdsJoinedSelector(thunkAPI.getState());
    const { userInfo }: IAuthState = state.auth;
    const { activedCriticalItem }: IOrderPriorizationState = state.orderPriorization;
    const { data }: IOrderTabOpenState = state.orderTabOpen;
    const criticalItems: ICriticalItem[] = [];

    const criticalItem = activedCriticalItem.find(s => s.ov === critialItem.ovItem);
    const orderItem = data.find(s => s.ov === critialItem.ovItem);

    criticalItems.push({
      customerId: criticalItem?.customerId,
      customerName: orderItem?.nameClient,
      ov: criticalItem?.ov,
      ovItem: criticalItem?.ovItem,
      oc: criticalItem?.oc,
      codProdCliente: criticalItem?.codProdClient,
      descMaterial: criticalItem?.descMaterial,
      dataConf: criticalItem?.dataConf,
      qtdConf: criticalItem?.qtyConf,
      qtdSolic: criticalItem?.qtySolic,
      qtdPendFat: orderItem?.qtyBillingOpen.toString(),
      qtdEstoq: orderItem?.qtyStock.toString(),
      mensagem: criticalItem?.mensagem,
    });

    return await OrderPriorizationDataSource.deleteCriticalItems({
      clientName: userInfo.name,
      clientEmail: userInfo.email,
      emissionCode: customerIds,
      itensCriticos: criticalItems,
    })
      .then(resp => resp)
      .catch(err => {
        thunkAPI.dispatch(setOpenErrorHandlerModal(true));
        return err.message;
      });
  },
);
