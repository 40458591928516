export interface IOrderTabShippedStrings {
  title: string;
  filename: string;
  from: string;
  to: string;
  error: string;
  search: string;
}

interface IOrderTabShippedLanguages {
  pt: IOrderTabShippedStrings;
  es: IOrderTabShippedStrings;
  in: IOrderTabShippedStrings;
}

const pt: IOrderTabShippedStrings = {
  title: 'Embarcado',
  filename: 'Pedidos embarcados',
  from: 'de',
  to: 'até',
  error: 'Ocorreu um erro ao carregar os dados da aba Embarcado! Por favor, tente novamente.',
  search: 'Consultar',
};

const es: IOrderTabShippedStrings = {
  title: 'Abordó',
  filename: 'Ordenes embarcadas',
  from: 'de',
  to: 'até',
  error: '¡Ocurrió un error al cargar datos desde la pestaña Embarcados! Inténtalo de nuevo.',
  search: 'Consultar',
};

const ing: IOrderTabShippedStrings = {
  title: 'Shipped',
  filename: 'Shipped',
  from: 'from',
  to: 'to',
  error: 'An error occurred while loading data from the Shipped tab! Please try again.',
  search: 'Search',
};

export const Strings: IOrderTabShippedLanguages = {
  pt,
  es,
  in: ing,
};
