import * as React from 'react';
import { Route } from '@app/core/route';
import { AppPath } from '../app/route-constants';
import { useQuoteGuard } from '../quote/quote.guard';
import { ChatPage } from './chat.page';

export const ChatRouter: React.FC = () => {
  const quoteAuthorized = useQuoteGuard();

  return (
    <>
      <Route
        exact
        path={AppPath.CHAT.DETAIL}
        component={ChatPage}
        authorized={quoteAuthorized}
        redirectPath={AppPath.ORDERS}
      />
    </>
  );
};
