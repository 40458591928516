import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { ComingSoonTagStrings } from './coming-soon-tag.string';
import { ComingSoonTagBody } from './coming-soon-tag.style';

export const ComingSoonTag: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  return <ComingSoonTagBody>{ComingSoonTagStrings[userInfo?.language]?.text}</ComingSoonTagBody>;
};
