export interface IDashBoardStrings {
  soon: string;
  soonTobeAdded: {
    inStock: string;
    billed: string;
    finance: string;
  };
  futureBlocks: string;
  noExternalBlocks: string;
  orderOpen: {
    title: string;
    status: string;
    solXCon: string;
    orderStatus: string;
  };
  confirmedOrder: {
    title: string;
    forecast: string;
    forecastStatus: string;
  };
  stockOrder: {
    title: string;
    step: string;
    stepDescription: string;
    status: string;
    statusDescription: string;
  };
  billed: {
    title: string;
    monthly: string;
    monthlyDescription: string;
    daily: string;
    dailyDescription: string;
  };
  finance: {
    title: string;
    matchedMatches: string;
    openMatches: string;
    expiredOpenMatches: string;
  };
  graphs: {
    openOrders: {
      value: string;
      openOrderStatus: {
        requested: string;
        confirmed: string;
        pendingBilling: string;
        billed: string;
        description: string;
      };
      requestedVsConfirmedOrder: {
        requested: string;
        confirmed: string;
        description: string;
      };
      orderStatus: {
        deployed: string;
        onSchedule: string;
        confirmed: string;
        total: string;
        description: string;
      };
    };
    confirmedOrders: {
      total: string;
      monthlyDeliveryForecast: {
        billedQty: string;
        pendingQty: string;
        pendingBilling: string;
        billed: string;
        description: string;
      };
      confirmedDeliveryByStatus: {
        delayed: string;
        onTime: string;
        noForecast: string;
        delayTrend: string;
        description: string;
      };
    };
    stockOrders: {
      total: string;
      stockStep: {
        inStock: string;
        loading: string;
        shipping: string;
      };
      stockStatus: {
        expired: string;
        onTime: string;
      };
    };
    billedOrders: {
      total: string;
      billedQty: string;
    };
    finance: {
      generalTotal: string;
      documentNumbers: string;
      matched: string;
      matchedMatches: {
        documentNumberQty: string;
        description: string;
      };
      openMatch: {
        expiredGeneralTotal: string;
        notExpiredGeneralTotal: string;
        documentNumberQty: string;
        expiredDocumentNumber: string;
        notExpiredDocumentNumber: string;
        description: string;
      };
    };
  };
  modal: {
    name: (name, category) => string;
    quantity: (quantity) => string;
    return: string;
  };
  warning: {
    text: string;
  };
  feedback: string;
  refresh: string;
  headerMessage: string;
}

interface IDashBoardLanguages {
  pt: IDashBoardStrings;
}

export const pt: IDashBoardStrings = {
  soon: 'Em breve!',
  soonTobeAdded: {
    inStock: 'Em estoque',
    billed: 'Faturado',
    finance: 'Finanças',
  },
  futureBlocks: 'Em breve novos indicadores serão adicionados.',
  noExternalBlocks: 'Em breve os indicadores de mercado externo estarão disponíveis.',
  orderOpen: {
    title: 'Pedidos em aberto',
    status: 'Status dos pedidos em aberto',
    solXCon: 'Qtd solicitada x qtd confirmada',
    orderStatus: 'Status dos pedidos',
  },
  confirmedOrder: {
    title: 'Pedidos confirmados',
    forecast: 'Previsão de entrega mensal',
    forecastStatus: 'Status da previsão de entrega',
  },
  stockOrder: {
    title: 'Pedidos em estoque',
    step: 'Estágio dos pedidos em estoque',
    stepDescription:
      'Este gráfico mostra quantas toneladas estão em cada estágio após entrar no estoque. Em estoque: não tem remessa nem transporte contratado. Em remessa: tem remessa criada. Em separação: já possui remessa e transporte contratado.',
    status: 'Status dos pedidos em estoque',
    statusDescription:
      'Este gráfico mostra o status dos pedidos em estoque, separando entre estoque "Vencido" (data confirmada no passado) e estoque "Não vencido" (data confirmada no futuro).',
  },
  billed: {
    title: 'Faturamento',
    monthly: 'Volume faturado por mês',
    monthlyDescription: 'Este gráfico mostra o volume faturado por mês, em toneladas, nos últimos 12 meses.',
    daily: 'Volume faturado por dia',
    dailyDescription: 'Este gráfico mostra o volume faturado por dia, em toneladas, nos últimos 31 dias.',
  },
  finance: {
    title: 'Finanças',
    matchedMatches: 'Partidas compensadas',
    openMatches: 'Partidas em aberto',
    expiredOpenMatches: 'Em aberto',
  },
  graphs: {
    openOrders: {
      value: 'valor',
      openOrderStatus: {
        requested: 'Qtd. solicitada',
        confirmed: 'Qtd. confirmada',
        pendingBilling: 'Qtd. pend. fat.',
        billed: 'Qtd. faturada',
        description:
          'Este gráfico mostra o cenário atual dos seus pedidos em aberto. A coluna "Qtd. Faturada" mostra quantas toneladas já foram faturadas apenas dos pedidos em aberto.',
      },
      requestedVsConfirmedOrder: {
        requested: 'Qtd. solicitada',
        confirmed: 'Qtd. confirmada',
        description:
          'Este gráfico mostra quantas toneladas foram solicitadas pelo cliente e quantas toneladas foram confirmadas pela Gerdau, mensalmente. Obs: O período deste gráfico compreende um histórico de 6 meses, o mês atual e os 3 meses seguintes.',
      },
      orderStatus: {
        deployed: 'Implantado',
        onSchedule: 'Em aprazamento',
        confirmed: 'Confirmado',
        total: 'Total',
        description:
          'Este gráfico mostra quantas toneladas estão em cada estágio do pedido. Implantado: GAT já implantou no sistema. Em aprazamento: PCP está realizando a confirmação das datas para M+2 Confirmado: PCP já confirmou as datas.',
      },
    },
    confirmedOrders: {
      total: 'Total',
      monthlyDeliveryForecast: {
        billedQty: 'Qtd. faturada',
        pendingQty: 'Qtd. prevista de faturamento',
        pendingBilling: 'Pendente de faturamento',
        billed: 'Faturado',
        description:
          'Este gráfico mostra a previsão de quantas toneladas o cliente vai receber mensalmente, sendo que no mês atual aparece a quantidade já faturada.',
      },
      confirmedDeliveryByStatus: {
        delayed: 'Atraso',
        onTime: 'Dentro do prazo',
        noForecast: 'Sem previsão',
        delayTrend: 'Tendência de atraso',
        description:
          'Este gráfico mostra quantas toneladas estão previstas para ser entregues no prazo e quantas provavelmente irão atrasar. Quando não foi possível calcular a previsão, aparecerá "Consultar GAT" para poder obter mais detalhes.',
      },
    },
    stockOrders: {
      total: 'Total',
      stockStep: {
        inStock: 'Em estoque',
        loading: 'Em carregamento',
        shipping: 'Em remessa',
      },
      stockStatus: {
        expired: 'Vencido',
        onTime: 'Dentro do prazo',
      },
    },
    billedOrders: {
      total: 'Total',
      billedQty: 'Quantidade faturada',
    },
    finance: {
      generalTotal: 'Total geral',
      documentNumbers: 'Nº de documentos',
      matched: 'Compensadas',
      matchedMatches: {
        documentNumberQty: 'Nº de docs',
        description:
          'Este gráfico mostra o resultado das partidas compensadas nos últimos meses, em valor R$ e em quantidade.',
      },
      openMatch: {
        expiredGeneralTotal: 'Vencidas',
        notExpiredGeneralTotal: 'À vencer',
        documentNumberQty: 'Nº de docs',
        expiredDocumentNumber: 'Vencidas',
        notExpiredDocumentNumber: 'À vencer',
        description:
          'Este gráfico mostra o status das partidas em aberto, à vencer e vencidas, em valor R$ e em quantidade.',
      },
    },
  },
  modal: {
    name: (name, category) => `${name} - categoria: ${category}`,
    quantity: quantity => `QUANTIDADE: ${quantity}t`,
    return: 'Voltar',
  },
  warning: {
    text: 'Bem vindos à nossa página de indicadores, ',
  },
  feedback: 'De 1 a 5, o quão você está satisfeito com as informações do Painel de gestão no Gerdau Mais?',
  refresh: 'Atualizar',
  headerMessage: 'As informações desta página são atualizadas 1 vez por dia',
};

export const Strings: IDashBoardLanguages = {
  pt,
};
