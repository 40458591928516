import styled from 'styled-components';
import { Breakpoint, Color, FontSize, Spacing } from '@atomic/obj.constants';

export const CrmContentWrapper = styled.details`
  background-color: ${Color.White};
  &[open] summary:after {
    content: '▾';
  }
`;

export const CrmContentHeaderWrapper = styled.summary`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.Small} ${Spacing.Medium};
  background-color: ${Color.Gray};
  cursor: pointer;
  & > h2 {
    font-size: ${FontSize.Medium};
    text-transform: uppercase;
    position: relative;
    margin: 0;
    font-weight: 800;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > span {
      width: 20px;
      height: 20px;
      margin-right: ${Spacing.Medium};
    }
  }
  &::after {
    content: '▸';
  }
`;

interface CrmContentInfosWrapperProps {
  gridRepeat?: number;
}

export const CrmContentInfosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-columns: ${(props: CrmContentInfosWrapperProps) =>
    `repeat(${props.gridRepeat ? props.gridRepeat : 1}, 1fr)`};
  grid-template-rows: 1fr;
  grid-column-gap: ${Spacing.Medium};
  grid-row-gap: ${Spacing.Medium};
  padding: 0 ${Spacing.Medium} ${Spacing.Medium} ${Spacing.Medium};
  @media all and (max-width: ${Breakpoint.lg}em) {
    grid-template-columns: 1fr;
  }
  & > div {
    display: flex;
    flex-direction: column;
    line-height: ${Spacing.Large};
  }
  & > div > h3 {
    background-color: ${Color.Secondary};
    width: 100%;
    font-size: ${FontSize.Small};
    padding: ${Spacing.Small};
    margin-top: ${Spacing.Medium};
  }
  & > div > p {
    font-size: ${FontSize.Small};
    padding: 0 ${Spacing.Small};
    &.limitchars {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 40%;
    }
  }
  & > div > p > label {
    font-size: ${FontSize.Small};
    white-space: pre;
  }
  select {
    min-width: 200px;
  }
`;

export const CrmBoxWrapper = styled.div`
  background-color: ${Color.GrayXLight};
  border: 1px solid ${Color.GrayLight};
  padding: ${Spacing.Small} ${Spacing.Medium};
  width: 100%;
`;

export const CrmBackButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
