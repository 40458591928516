import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { useWaybillDocumentRequest } from '@app/domain/document.use-case';
import { CellLink } from '@atomic/atm.cell-link/cell-link.component';
import { FaIcon } from '@atomic/atm.fa-icon';

interface DataObject {
  plant: string;
  activeSector: string;
  invoice: string;
  invoiceDate: string;
}

interface WayBillCellComponentProps {
  data: DataObject;
}

const downloadFile = (data: any, fileName: string) => {
  const a = document.createElement<'a'>('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.href = data;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(data);
};

export const WayBillCellComponent: React.FC<WayBillCellComponentProps> = props => {
  const { plant, activeSector, invoice, invoiceDate } = props.data || {};
  const { data, loading, performRequest } = useWaybillDocumentRequest();
  const [filename, setFilename] = React.useState('Romaneio');
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const customerIds = clientBpsSelected.map(client => client.customerId).join(',');

  React.useEffect(() => {
    if (data) {
      LogAnalytics.click({ tipo: 'DownloadRomaneio' });
      downloadFile(data, filename);
    }
  }, [data]);

  const handleDocumentView = () => () => {
    setFilename(`Romaneio - ${invoice}`);
    performRequest({ plant, activeSector, invoice, invoiceDate, customerIds });
  };

  if (!props.data) {
    return null;
  }

  return (
    <CellLink loading={loading}>
      <FaIcon.FileDownload onClick={handleDocumentView()} />
    </CellLink>
  );
};
