import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { RadioField } from '@atomic/atm.radio';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { H1, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { rangeLimits } from './chat.contants';
import { ChatStrings } from './chat.string';
import { ModalGenericValue } from './modal-generic-value.component';

enum Medida {
  KGFMM = 'KGFMM',
  KSI = 'KSI',
  MPA = 'MPA',
  NMM = 'NMM',
  PSI = 'PSI',
}

export enum MechanicalField {
  ALONGAMENTO = 'ALONGAMENTO',
  REDUCAO_AREA = 'REDUCAO_AREA',
  MEDIDA_LIMITE_ESCOAMENTO = 'MEDIDA_LIMITE_ESCOAMENTO',
  MEDIDA_LIMITE_RESISTENCIA = 'MEDIDA_LIMITE_RESISTENCIA',
  LIMITE_ESCOAMENTOMIN = 'LIMITE_ESCOAMENTOMIN',
  LIMITE_ESCOAMENTOMAX = 'LIMITE_ESCOAMENTOMAX',
  LIMITE_RESISTENCIAMIN = 'LIMITE_RESISTENCIAMIN',
  LIMITE_RESISTENCIAMAX = 'LIMITE_RESISTENCIAMAX',
  TRACTION = 'TRACTION',
}

export interface MechanicalPropertiesData {
  values?: any;
  [MechanicalField.TRACTION]?: any;
  [MechanicalField.ALONGAMENTO]?: number;
  [MechanicalField.REDUCAO_AREA]?: number;
  [MechanicalField.MEDIDA_LIMITE_ESCOAMENTO]?: Medida;
  [MechanicalField.LIMITE_ESCOAMENTOMIN]?: number;
  [MechanicalField.LIMITE_ESCOAMENTOMAX]?: number;
  [MechanicalField.MEDIDA_LIMITE_RESISTENCIA]?: Medida;
  [MechanicalField.LIMITE_RESISTENCIAMIN]?: number;
  [MechanicalField.LIMITE_RESISTENCIAMAX]?: number;
}

interface MechanicalPropertiesRowProps {
  label: string;
  unit: string;
  minInitialValue?: string | number;
  maxInitialValue?: string | number;
  minName?: string;
  maxName?: string;
}
const MechanicalPropertiesRow: React.FC<MechanicalPropertiesRowProps> = props => {
  const formatStringValue = (val: string | number) => {
    if (val) {
      const newMask = val.toString().replace(rangeLimits.maxString, '-');
      return newMask;
    }
    return val;
  };

  return (
    <TR>
      <TD>
        <H3>{props.label}</H3>
      </TD>

      {props.maxName ? (
        <>
          <TD>
            <Form.Field name={props.minName} initialValue={props.minInitialValue} hideErrorCaption>
              <TextField type='number' placeholder='Min.' />
            </Form.Field>
          </TD>
          <TD> - </TD>
          <TD>
            <Form.Field name={props.maxName} initialValue={formatStringValue(props.maxInitialValue)} hideErrorCaption>
              <TextField type='number' placeholder='Máx.' />
            </Form.Field>
          </TD>
        </>
      ) : (
        <TD>
          <Form.Field name={props.minName} initialValue={props.minInitialValue} hideErrorCaption>
            <TextField type='number' placeholder='Min.' />
          </Form.Field>
        </TD>
      )}
      <TD>
        <H3>{props.unit}</H3>
      </TD>
    </TR>
  );
};

interface ModalMechanicalPropertiesProps {
  openTableType: string;
  data: MechanicalPropertiesData;
  onSubmit: (MechanicalPropertiesData: MechanicalPropertiesData) => void;
  onClose: () => void;
}

export const ModalMechanicalProperties: React.FC<ModalMechanicalPropertiesProps> = props => {
  const [reset, setReset] = React.useState(0);
  const [opened, setOpened] = React.useState(false);
  const [newAttributeForm, setNewAttributeForm] = React.useState(false);
  const [unit, setUnit] = React.useState(null);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language];

  React.useEffect(() => {
    if (props.openTableType.toLowerCase() === 'propriedades_mecanicas') {
      setOpened(true);
    }
  }, [props.openTableType]);

  const handleSubmit = (data: FormData<MechanicalPropertiesData>) => {
    if (Object.keys(data.error).length > 0) {
      return;
    }
    const traction: MechanicalPropertiesData = {
      TRACTION: data.data?.values,
    };

    const mechanicalProperties: MechanicalPropertiesData = {
      ALONGAMENTO: !data.data.ALONGAMENTO ? rangeLimits.max : +data.data.ALONGAMENTO,
      REDUCAO_AREA: !data.data.REDUCAO_AREA ? rangeLimits.max : data.data.REDUCAO_AREA,
      MEDIDA_LIMITE_ESCOAMENTO: data.data.MEDIDA_LIMITE_ESCOAMENTO,
      LIMITE_ESCOAMENTOMIN: !data.data.LIMITE_ESCOAMENTOMIN ? rangeLimits.min : +data.data.LIMITE_ESCOAMENTOMIN,
      LIMITE_ESCOAMENTOMAX: !data.data.LIMITE_ESCOAMENTOMAX ? rangeLimits.max : +data.data.LIMITE_ESCOAMENTOMAX,
      MEDIDA_LIMITE_RESISTENCIA: data.data.MEDIDA_LIMITE_ESCOAMENTO,
      LIMITE_RESISTENCIAMIN: !data.data.LIMITE_RESISTENCIAMIN ? rangeLimits.min : +data.data.LIMITE_RESISTENCIAMIN,
      LIMITE_RESISTENCIAMAX: !data.data.LIMITE_RESISTENCIAMAX ? rangeLimits.max : +data.data.LIMITE_RESISTENCIAMAX,
    };
    props.onSubmit(data.data?.values ? traction : mechanicalProperties);

    setOpened(false);
    setNewAttributeForm(false);
  };

  const handleReset = () => {
    setReset(reset + 1);
  };

  const handleClose = () => {
    setOpened(false);
    props.onClose();
  };

  const onSelectChange = value => {
    if (value === 'Outros') {
      setNewAttributeForm(true);
    } else {
      setNewAttributeForm(false);
    }
  };

  const handleChangeUnit = value => {
    setUnit(strings.modalMechanical.units[value]);
  };

  return (
    <Modal preventOverlayClick opened={opened} small onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row mb>
            <Col xs={12}>
              <H1>{!newAttributeForm ? strings.modalMechanical.title : strings.modalGenericValues.title}</H1>
            </Col>
            <Col xs={4}>
              <Form.Field
                name={'filter'}
                initialValue={strings.modalMechanical.selectOptions[0]}
                hideErrorCaption
                onValueChange={e => onSelectChange(e)}
              >
                <SelectField>
                  {strings.modalMechanical.selectOptions.map(el => (
                    <option value={el} key={el}>
                      {el}
                    </option>
                  ))}
                </SelectField>
              </Form.Field>
            </Col>
          </Row>
          {!newAttributeForm ? (
            <>
              <Row mb>
                <Col xs={12}>
                  <VSeparator />
                  <Form.Field
                    name={MechanicalField.MEDIDA_LIMITE_ESCOAMENTO}
                    initialValue={props.data?.MEDIDA_LIMITE_ESCOAMENTO}
                    validators={[Validators.Required(strings.modalMechanical.mandatory)]}
                    onValueChange={e => handleChangeUnit(e)}
                  >
                    <Hbox>
                      <Hbox.Item noGrow>
                        <RadioField id={Medida.KGFMM}>{strings.modalMechanical.units[Medida.KGFMM]}</RadioField>
                      </Hbox.Item>
                      <Hbox.Separator />
                      <Hbox.Item noGrow>
                        <RadioField id={Medida.KSI}>{strings.modalMechanical.units[Medida.KSI]}</RadioField>
                      </Hbox.Item>
                      <Hbox.Separator />
                      <Hbox.Item noGrow>
                        <RadioField id={Medida.MPA}>{strings.modalMechanical.units[Medida.MPA]}</RadioField>
                      </Hbox.Item>
                      <Hbox.Separator />
                      <Hbox.Item noGrow>
                        <RadioField id={Medida.NMM}>{strings.modalMechanical.units[Medida.NMM]}</RadioField>
                      </Hbox.Item>
                      <Hbox.Separator />
                      <Hbox.Item noGrow>
                        <RadioField id={Medida.PSI}>{strings.modalMechanical.units[Medida.PSI]}</RadioField>
                      </Hbox.Item>
                    </Hbox>
                  </Form.Field>
                </Col>
              </Row>
              <Row mb key={reset}>
                <Col xs={12}>
                  <Table>
                    <MechanicalPropertiesRow
                      label={strings.resumeAnalysis.tensile}
                      minInitialValue={props.data?.LIMITE_RESISTENCIAMIN}
                      maxInitialValue={props.data?.LIMITE_RESISTENCIAMAX}
                      minName={MechanicalField.LIMITE_RESISTENCIAMIN}
                      maxName={MechanicalField.LIMITE_RESISTENCIAMAX}
                      unit={unit}
                    />
                    <MechanicalPropertiesRow
                      label={strings.resumeAnalysis.yield}
                      minInitialValue={props.data?.LIMITE_ESCOAMENTOMIN}
                      maxInitialValue={props.data?.LIMITE_ESCOAMENTOMAX}
                      minName={MechanicalField.LIMITE_ESCOAMENTOMIN}
                      maxName={MechanicalField.LIMITE_ESCOAMENTOMAX}
                      unit={unit}
                    />
                    <MechanicalPropertiesRow
                      label={strings.resumeAnalysis.reduction}
                      minInitialValue={props.data?.REDUCAO_AREA}
                      minName={MechanicalField.REDUCAO_AREA}
                      unit='%'
                    />
                    <MechanicalPropertiesRow
                      label={strings.resumeAnalysis.elongation}
                      minInitialValue={props.data?.ALONGAMENTO}
                      minName={MechanicalField.ALONGAMENTO}
                      unit='%'
                    />
                  </Table>
                </Col>
              </Row>
            </>
          ) : (
            <ModalGenericValue type={strings.modalMechanical.title} />
          )}
          <Hbox hAlign='flex-end'>
            <Hbox.Item noGrow>
              <Button kind='secondary' expanded onClick={handleReset}>
                {strings.modalMechanical.btnReset}
              </Button>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item noGrow>
              <Button kind='primary' expanded type='submit'>
                {strings.modalMechanical.btnSubmit}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};

ModalMechanicalProperties.defaultProps = {
  openTableType: '',
};
