import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../toast.constants';
import { IToastState } from '../toast.interfaces';
import { initialState } from './toast.initial';
import { reducers } from './toast.reducers';

export const toastSlice = createSlice<IToastState, SliceCaseReducers<IToastState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
});

export const { setToast } = toastSlice.actions;

export const toastReducer = toastSlice.reducer;
