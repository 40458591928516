import styled from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight } from '@atomic/obj.constants';

export const ChatFieldStyled = styled.input`
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Large};
  border: none;
  height: 50px;

  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${Color.White};
    &::placeholder {
      color: ${Color.MediumGray};
    }
  }
`;

interface ChatFieldButtonWrapperStyledProps {
  initialized?: boolean;
}

export const ChatFieldButtonWrapperStyled = styled.div`
  width: ${(props: ChatFieldButtonWrapperStyledProps) => (props.initialized ? '420px' : '50px')};
  transition: width 0.8s;
`;
