import styled from 'styled-components';
import { Border, Color, FontSize, Shadow, Spacing, ZIndex } from '@atomic/obj.constants';

export const FeedbackBoxStyled = styled.div`
  width: ${Spacing.XXLarge};
  height: ${Spacing.XXLarge};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${Border.Width} solid ${Color.Secondary};
  border-radius: ${Border.Radius};
  ${props =>
    props.selected
      ? `
  background-color: ${Color.Primary};
  color: ${Color.White};
  `
      : `background-color: ${Color.White}
    color: ${Color.Black};`};
`;

export const FeedbackNumberStyled = styled.span`
  font-size: ${FontSize.Medium};
  cursor: pointer;
`;

export const FeedbackWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${Color.White};
  padding: 0 ${Spacing.Small};
  border-radius: ${Border.Radius};
  box-shadow: ${Shadow.Medium};
`;

export const FeedbackButtonStyled = styled.div`
  button {
    margin: 0 2px;
    padding: 0;
  }
`;

export const FeedbackCloseButtonStyled = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${ZIndex.ModalCloseStyled};
  padding: ${Spacing.Large};
  color: ${Color.GrayDark};
  cursor: pointer;
`;
