import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const AdminTabWrapperStyled = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: ${Color.White};
  padding-top: 20px;
`;

export const AdminControlWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${Spacing.Medium};
  padding-bottom: ${Spacing.XLarge};
  padding-left: ${Spacing.XLarge};
  padding-right: ${Spacing.XLarge};
`;
