/* eslint-disable complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AppPath } from '@app/modules/app/route-constants';
import { hasScmAccesSelector } from '@app/modules/auth/store/auth.selectores';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';

export const NewQuotationButton = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const hasScmAcces = useSelector(hasScmAccesSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language];

  const handleRedirect = () => {
    if (window) {
      window.location.href = AppPath.CHAT.NEW;
    }
  };

  return !hasScmAcces ? (
    <Hbox>
      <Button kind='secondary' onClick={handleRedirect}>
        <Hbox hAlign='left' fullWidth>
          <Hbox.Item noGrow vAlign='center'>
            <Image.NewQuotation />
          </Hbox.Item>
          <Hbox.Separator />
          <Body bold color={Color.GrayXDark} noWrap>
            {strings.header.tooltip.newAnalysis}
          </Body>
        </Hbox>
      </Button>
    </Hbox>
  ) : null;
};
