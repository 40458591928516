export interface IOrderTabFrontierStrings {
  title: string;
  filename: string;
  from: string;
  to: string;
  error: string;
}

interface IOrderTabFrontierLanguages {
  pt: IOrderTabFrontierStrings;
  es: IOrderTabFrontierStrings;
  in: IOrderTabFrontierStrings;
}

const pt: IOrderTabFrontierStrings = {
  title: 'Em Fronteira',
  filename: 'Pedidos em fronteira',
  from: 'de',
  to: 'até',
  error: 'Ocorreu um erro ao carregar os dados da aba Em Fronteira! Por favor, tente novamente.',
};

const es: IOrderTabFrontierStrings = {
  title: 'En Frontera',
  filename: 'Ordenes en frontera',
  from: 'de',
  to: 'até',
  error: '¡Hubo un error al cargar los datos de la pestaña Em Fronteira! Inténtalo de nuevo.',
};

const ing: IOrderTabFrontierStrings = {
  title: 'Frotier',
  filename: 'Bordering',
  from: 'de',
  to: 'até',
  error: 'There was an error loading data from the Em Fronteira tab! Please try again.',
};

export const Strings: IOrderTabFrontierLanguages = {
  pt,
  es,
  in: ing,
};
