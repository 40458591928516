import { ColDef, GridReadyEvent } from 'ag-grid-community';
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { InternationalTabIndex } from '@app/models/order.model';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { renderSubtotalField } from '@app/modules/order/grid-utils/grid-subtotal-field-render';
import { removeFilterInput } from '@app/modules/order/grid-utils/utils';
import { OrderTabsHeaderME } from '../../order-tabs-header-ME.component';
import { OrderTabOpenInternationalComponent } from './order-tab-open-international.component';
import { tabName } from './order-tab-open-international.constants';
import { filterSelector } from './order-tab-open-international.selectors';
import { performRequest } from './order-tab-open-international.store';
import { configTanslateDeliveryStatusFilterInternationalOpened } from './utils/action-utils';
import { handleFilter, handleFilterCheck, handleOpenInternationalTabColumn } from './utils/handler-utils';

export const OrderTabOpenInternational: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const clientBpsSelectedJoined = useSelector(customerIdsJoinedSelector);
  const filter = useSelector(filterSelector);
  const { data, lastUpdate, filteredRows } = useSelector((state: RootState) => state.orderTabOpenInternational);
  const dispatch = useDispatch<AppThunkDispatch>();

  const gridEvent = useRef<GridReadyEvent>(null);
  const columns: ColDef[] = useSelector(columnsTabLayoutSelector(tabName, userInfo?.language));

  const calculateSubtotals = () =>
    renderSubtotalField({
      tab: tabName,
      itens: filteredRows,
      language: userInfo?.language,
      columnDef: columns,
    });

  useEffect(() => {
    dispatch(performRequest());
  }, [clientBpsSelectedJoined]);

  useEffect(() => {
    handleFilter({ gridEvent, filter, language: userInfo.language, handleFilterCheck });
  }, [filter, data]);

  useEffect(() => {
    calculateSubtotals();
  }, [filteredRows]);

  useEffect(() => {
    removeFilterInput(tabName, 'salesOrderStatus');
  }, [columns]);

  handleOpenInternationalTabColumn(columns, userInfo?.language);

  configTanslateDeliveryStatusFilterInternationalOpened(columns, userInfo?.language);

  return (
    <>
      <OrderTabsHeaderME tabIndex={InternationalTabIndex.Open} lastUpdate={lastUpdate} />
      <OrderTabOpenInternationalComponent gridEvent={gridEvent} />
    </>
  );
};
