export function debounce<T>(func: (value: T, execFunc: (newValue: T) => void) => void, wait: number) {
  let timeout: number;

  return function(value: T, execFunc: (newValue: T) => void) {
    clearTimeout(timeout);
    timeout = window.setTimeout(function() {
      timeout = null;

      func(value, execFunc);
    }, wait);
  };
}
