import styled from 'styled-components';
import { TextAlignProps } from '@atomic/mol.table';
import { Border, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export const SuggestionTR = styled.tr`
  border-right: 0px;
  border-top: 0px;
  border-bottom: 0px;
`;

export const SuggestionCheckBoxTD = styled.td`
  align-items: center;
  min-height: 200px;
  padding: ${Spacing.XSmall};
  width: auto;
  text-align: ${(props: TextAlignProps) => props.textAlign || 'center'};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  vertical-align: middle;
  ${(props: TextAlignProps) => props.width && `width: ${props.width}%;`}
  border-left: 2px solid ${Border.Color};
`;

export const SuggestionFirstTD = styled.td`
  align-items: center;
  min-height: 200px;
  padding: ${Spacing.XSmall};
  width: auto;
  text-align: ${(props: TextAlignProps) => props.textAlign || 'center'};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  vertical-align: middle;
  ${(props: TextAlignProps) => props.width && `width: ${props.width}%;`}
  border-left: 2px solid ${Border.Color};
  border-bottom: 2px solid ${Border.Color};
`;

export const SuggestionTD = styled.td`
  align-items: center;
  min-height: 200px;
  padding: ${Spacing.XSmall};
  width: auto;
  text-align: ${(props: TextAlignProps) => props.textAlign || 'center'};
  font-size: ${FontSize.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  vertical-align: middle;
  ${(props: TextAlignProps) => props.width && `width: ${props.width}%;`}
  border-bottom: 2px solid ${Border.Color};
`;
