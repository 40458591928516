import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';

export const DiameterRowWrapper = styled.div`
  td {
    text-align: left;
    padding-top: ${Spacing.Medium};
    & > div > div > p {
      padding: 0 ${Spacing.Small} ${Spacing.Small} ${Spacing.Small};
    }
    & > div > div > div > div > input {
      -moz-appearance: textfield;
      appearance: textfield;
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }
  tr {
    &:nth-child(2) {
      td {
        padding-top: ${Spacing.Small};
        &:nth-child(4) {
          label {
            color: ${Color.White};
          }
        }
      }
    }
  }
  td label {
    font-weight: normal;
    font-size: ${FontSize.XSmall};
  }
  th {
    text-align: left;
    font-weight: 600;
    padding-left: ${Spacing.XSmall};
  }
  p {
    white-space: nowrap;
  }
`;

interface PropsCompositionTab {
  step?: number;
}

export const CompositionTabForm = styled.div`
  display: ${(props: PropsCompositionTab) => (props.step === 1 ? 'block' : 'none')};
`;
export const CompositionTabList = styled.div`
  display: ${(props: PropsCompositionTab) => (props.step !== 1 ? 'block' : 'none')};
`;
