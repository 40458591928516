import React from 'react';
import { StockRealDateItem } from '@app/models';
import { Table, TD, TH, THead, TR } from '@atomic/mol.table';

interface DateStockEntryInfoParams {
  items: StockRealDateItem[];
}

export const DateStockEntryInfo: React.FC<DateStockEntryInfoParams> = ({ items }) => {
  return (
    <Table>
      <THead>
        <TH>Qtd</TH>
        <TH>Data</TH>
        <TH>Lote</TH>
      </THead>
      {items.map((item, index) => (
        <TR key={index}>
          <TD>{item.totalStockQuantity}t</TD>
          <TD>{item.dateOfStockEntry}</TD>
          <TD>{item.batch}</TD>
        </TR>
      ))}
    </Table>
  );
};
