import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Tab } from '@atomic/mol.tab';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { TechnicalAnalysisContext } from './technical-analysis.context';
// interface TechnicalAnalysisPlantsTabProps {
//   handleTabChange: (index: number) => void;
// }

export const TechnicalAnalysisPlantsTab = () => {
  const { newPlants, tabIndex, setTabIndex } = React.useContext(TechnicalAnalysisContext);

  return (
    <>
      {newPlants && (
        <Tab initialIndex={tabIndex} onIndexChanged={value => setTabIndex(value)}>
          {newPlants.map(plant => (
            <Tab.Item key={plant.label} id={plant.label}>
              <Hbox vAlign='center' fullHeight={true}>
                {plant.status?.isSelected && plant.status?.isReviewed && (
                  <>
                    <Hbox.Item vAlign='center' noGrow>
                      <FaIcon.Check size='1x' color={Color.Success} />
                    </Hbox.Item>
                    <Hbox.Separator small />
                  </>
                )}
                <Hbox.Item>
                  {plant.label}
                  {plant.status?.isRequested && (!plant.status?.isSelected || !plant.status?.isReviewed) && '*'}
                </Hbox.Item>
              </Hbox>
            </Tab.Item>
          ))}
        </Tab>
      )}
    </>
  );
};
