import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { MULTIPLE_ALERT_MODAL_WIDTH, OrderInputPhaseEnum } from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { setOpenMultipleAlertModal, setPhase } from '@app/modules/order-input/store/order-input.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { MessageWrapper } from './multriple-alert-modal.style';

const strings = OrderInputStrings.pt.multiple.modal;

export const MultipleAlertModal: React.FC = () => {
  const { openMultipleAlertModal, loadingMultiple, selectedMaterials } = useSelector(
    (state: RootState) => state.orderInput,
  );
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setOpenMultipleAlertModal(false));
    const noHasMultiples = !selectedMaterials.some((material: Material) => !!material.multipleValidation);

    if (noHasMultiples) {
      dispatch(setPhase(OrderInputPhaseEnum.Resume));
    }
  };

  return (
    <Modal shadow={true} customWidth={MULTIPLE_ALERT_MODAL_WIDTH} opened={openMultipleAlertModal}>
      <Grid fluid>
        <FlexColumn mt mb>
          <FlexRow vAlign='center' hAlign='center' fullWidth>
            <FaIcon.Warning color={Color.Primary} />
            <Body ml={Spacing.Small} size={FontSize.Large} bold={FontWeight.Bold}>
              {strings.title}
            </Body>
          </FlexRow>
        </FlexColumn>
        <FlexRow>
          <MessageWrapper mr={Spacing.Large}>
            <Body mb>{strings.message1}</Body>
            <Body bold={FontWeight.Bold}> {strings.message2}</Body>
          </MessageWrapper>
          <Image.OrderInputMultipleAlert />
        </FlexRow>
        <FlexColumn mt mb>
          <FlexRow fullWidth hAlign='center'>
            <Button loading={loadingMultiple} onClick={onClick}>
              {strings.button}
            </Button>
          </FlexRow>
        </FlexColumn>
      </Grid>
    </Modal>
  );
};
