import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../announcement-modal.constants';
import { IAnnouncementModalState } from '../announcement-modal.interfaces';
import { initialState } from './announcement-modal.initial';
import { reducers } from './announcement-modal.reducers';
import { addThunkReducers } from './announcement.thunks';

export const announcementModalSlice = createSlice<IAnnouncementModalState, SliceCaseReducers<IAnnouncementModalState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const { reset, setAnnouncements, setOpened } = announcementModalSlice.actions;

export const announcementReducer = announcementModalSlice.reducer;
