import React from 'react';
import { formatQtyField } from '@app/utils/string-utils';
import { DT } from '@atomic/atm.typography';

interface IShippingRequestFooterTDProps {
  total: number;
  field: string;
}

export const ShippingRequestTableFooterTD: React.FC<IShippingRequestFooterTDProps> = ({ total }) => {
  return <DT>{formatQtyField(total) + 't'}</DT>;
};
