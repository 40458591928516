import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { GetBPsParams } from '@app/data/http/admin.params.dto';
import { IAdminBPList } from '@app/models/admin.model';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { AdminStrings } from '../../admin.string';

export const getBPs = createAsyncThunk(`${SLICE_NAME}/getBPs`, async (params: GetBPsParams, thunkAPI) => {
  const strings = AdminStrings.pt.bpView;

  return await AdminDataSource.getBPs(params)
    .then(data => data)
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: strings.listBPsError, variant: ToastVariant.DANGER }));
    });
});

export const addGetBPsReducers = builder => {
  builder.addCase(getBPs.pending, state => {
    state.loadingBPs = true;
  });
  builder.addCase(getBPs.fulfilled, (state, action) => {
    const payload: IAdminBPList = action.payload as IAdminBPList;
    state.loadingBPs = false;
    state.bpSearch.filteredBPs = payload?.bps;
    state.bpSearch.pagination = payload?.pagination;
  });
  builder.addCase(getBPs.rejected, state => {
    state.loadingBPs = false;
  });
};
