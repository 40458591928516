import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { ScheduleDataSource } from '@app/data/http/schedule.datasource';
import { IScheduleClient } from '@app/models/schedule.model';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import { SLICE_NAME, ScheduleEvents, SchedulePhase } from '../../schedule.constants';
import { ICrudScheduleParams, IScheduleState } from '../../schedule.interfaces';
import { bpsSelectedSelector } from '../schedule.selectores';

export const deleteSchedule = createAsyncThunk(
  `${SLICE_NAME}/deleteSchedule`,
  async ({ onSuccess }: ICrudScheduleParams, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const clients: IScheduleClient[] = bpsSelectedSelector(state);
    const customerIds = clients.map(client => client.code).join();
    const { scheduleId }: IScheduleState = state.schedule;
    try {
      await ScheduleDataSource.deleteSchedule(scheduleId, customerIds);
      return onSuccess(scheduleId);
    } catch (error) {
      thunkAPI.dispatch(setOpenErrorHandlerModal(true));
      LogAnalytics.error({
        tipo: ScheduleEvents.DeleteScheduleError,
        responseStatus: error.response.status,
        responseData: error.response?.data?.message,
        customerIds,
      });
      return thunkAPI.rejectWithValue({});
    }
  },
);

export const addDeleteScheduleReducers = builder => {
  builder.addCase(deleteSchedule.pending, state => {
    state.loadingDelete = true;
  });
  builder.addCase(deleteSchedule.fulfilled, state => {
    state.schedulePhase = SchedulePhase.SuccessDelete;
    state.loadingDelete = false;
  });
  builder.addCase(deleteSchedule.rejected, state => {
    state.loadingDelete = false;
  });
};
