import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2, H3, Label } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { CrmStrings } from '../../crmComplain.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

export const CrmOpportunityDetails = props => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper open={true}>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.OPPORTUNITY_DETAILS} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>
      {props?.data?.GENERAL_DATA?.infos.length > 0 ? (
        <CrmContentInfosWrapper gridRepeat={2}>
          <Hbox>
            <H3 bold={800}>{strings.subSessions[props.data.GENERAL_DATA.label]}</H3>
            <Separator small />
            {props.data.GENERAL_DATA.infos.map((item, index) => (
              <Body key={index}>
                <Label>{strings.fields[item.label]} </Label>
                {item.value}
              </Body>
            ))}

            <H3 bold={800}>{strings.subSessions[props.data.DATES.label]}</H3>
            <Separator small />
            {props.data.DATES.infos.map((item, index) => (
              <Body key={index}>
                <Label>{strings.fields[item.label]} </Label>
                {item.value}
              </Body>
            ))}

            <H3 bold={800}>{strings.subSessions[props.data.WORKFLOW.label]}</H3>
            <Separator small />
            {props.data.WORKFLOW.infos.map((item, index) => (
              <Body key={index}>
                <Label>{strings.fields[item.label]} </Label>
                {item.value}
              </Body>
            ))}

            <Separator small />
          </Hbox>
          <Hbox>
            <H3 bold={800}>{strings.subSessions[props.data.PROCESSING_DATA.label]}</H3>
            <Separator small />
            {props.data.PROCESSING_DATA.infos.map((item, index) => (
              <Body key={index}>
                <Label>{strings.fields[item.label]} </Label>
                {item.value}
              </Body>
            ))}

            <H3 bold={800}>{strings.subSessions[props.data.CATEGORIZATION.label]}</H3>
            <Separator small />
            {props.data.CATEGORIZATION.infos.map((item, index) => (
              <Body key={index}>
                <Label>{strings.fields[item.label]} </Label>
                {item.value}
              </Body>
            ))}

            <H3 bold={800}>{strings.subSessions[props.data.QUESTIONS.label]}</H3>
            <Separator small />
            {props.data.QUESTIONS.infos.map((item, index) => (
              <Body key={index}>
                <Label>{strings.fields[item.label]} </Label>
                {item.value}
              </Body>
            ))}

            <Separator small />
          </Hbox>
        </CrmContentInfosWrapper>
      ) : (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />
            <Body centralized>{props.loading ? 'Carregando...' : 'Nenhum registro encontrado.'}</Body>
          </Hbox>
        </CrmContentInfosWrapper>
      )}
    </CrmContentWrapper>
  );
};
