export enum OrderTabOpenInternationalStatusKeyPt {
  NotConfirmed = 'Não confirmado',
  InAnalysis = 'Não confirmado - em análise',
  Confirmed = 'Confirmado',
}

export enum OrderTabOpenInternationalStatusKeyEs {
  NotConfirmed = 'No confirmado',
  InAnalysis = 'No confirmado - en análisis',
  Confirmed = 'Confirmado',
}

export enum OrderTabOpenInternationalStatusKeyIn {
  NotConfirmed = 'Not confirmed',
  InAnalysis = 'Not confirmed - under review',
  Confirmed = 'Confirmed',
}

export const StatusByLanguage = {
  pt: OrderTabOpenInternationalStatusKeyPt,
  es: OrderTabOpenInternationalStatusKeyEs,
  in: OrderTabOpenInternationalStatusKeyIn,
};

export interface IStatusByLanguage {
  NotConfirmed: string;
  InAnalysis: string;
  Confirmed: string;
}
