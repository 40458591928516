import React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Hbox } from '@atomic/obj.box';
import { AdminNPSContainerShimmer, AdminNPSItemShimmer } from '../admin-nps.styles';

export const AdminNPSListShimmer: React.FC = () => {
  return (
    <AdminNPSContainerShimmer>
      {Array(10)
        .fill('')
        .map((_item, index) => (
          <AdminNPSItemShimmer key={index} lastItem={index === 9}>
            <TextShimmerBoxStyled height='25px' width='5%' margin='0' />
            <Hbox.Separator />

            <TextShimmerBoxStyled height='25px' width='20%' margin='0' />
            <Hbox.Separator />

            <TextShimmerBoxStyled height='25px' width='10%' margin='0' />
            <Hbox.Separator />

            <TextShimmerBoxStyled height='25px' width='10%' margin='0' />
          </AdminNPSItemShimmer>
        ))}
    </AdminNPSContainerShimmer>
  );
};
