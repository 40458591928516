import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Divisor } from '@atomic/atm.divisor';
import { Frame } from '@atomic/atm.frame';
import { H3 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { ConfigColumnsModal } from '../../components/config-columns-modal/config-columns-modal.componet';
import { MaterialForm } from '../../components/material-form/material-form';
import { OrderInputBaseboard } from '../../components/order-input-baseboard/order-input-baseboard';
import { OrderInputEvents } from '../../order-input.analytics';
import { OrderInputPhaseEnum } from '../../order-input.constants';
import { OrderInputStrings } from '../../order-input.string';
import { setFiles, setPhase, setSearchBy } from '../../store/order-input.store';
import { postOrderAndValidateMaterial } from '../../store/thunks/post-order-and-validate-material.thunk';
import { ReceiverForm } from '../order-input-select-itens/receiver-form/receiver-form';
import { OrderInputUploadFile } from './order-input-upload-file/order-input-upload-file';
import { ReadOrderLoader } from './order-input-upload-file/partials/read-order-loader/read-order-loader.component';
import {
  MaterialFormOffSet,
  MaterialFormWrapper,
  OrderInputUploadSpreadSheetWrapper,
} from './order-input-upload-pdf-email.style';

const strings = OrderInputStrings.pt.selectItens;

export const OrderInputUploadPdfEmail: React.FC = () => {
  const { loadingPostFiles, loadingValidateMaterials } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    dispatch(setSearchBy(null));
  }, []);

  return (
    <>
      <OrderInputUploadSpreadSheetWrapper fluid data-testid='order-input-upload-pdf-email-phase'>
        <Frame>
          <FlexColumn pt pl>
            <H3>{strings.uploadIAReadOptionTitle}</H3>
            {loadingPostFiles || loadingValidateMaterials ? (
              <ReadOrderLoader />
            ) : (
              <FlexColumn fullWidth mt pb>
                <ReceiverForm />
                <Divisor />
                <Form>
                  <FlexRow mt>
                    <MaterialFormWrapper>
                      <MaterialForm />
                    </MaterialFormWrapper>
                    <MaterialFormOffSet />
                  </FlexRow>
                </Form>
                <Divisor />
                <Form>
                  <FlexRow mt>
                    <OrderInputUploadFile />
                  </FlexRow>
                </Form>
              </FlexColumn>
            )}
          </FlexColumn>
        </Frame>
      </OrderInputUploadSpreadSheetWrapper>
      <OrderInputBaseboard
        onActionClick={async () => {
          LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: OrderInputPhaseEnum.DataAndAmout });
          dispatch(postOrderAndValidateMaterial());
        }}
        onReturnClick={() => {
          LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionClick, phase: OrderInputPhaseEnum.Options });
          dispatch(setFiles([]));
          dispatch(setPhase(OrderInputPhaseEnum.Options));
        }}
        loading={loadingPostFiles || loadingValidateMaterials}
      />
      <ConfigColumnsModal />
    </>
  );
};
