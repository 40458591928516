import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppPath } from '@app/modules/app/route-constants';
import { hasCrmTabAccessSelector, quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { Tab } from '@atomic/mol.tab';
import { LoadingState } from '@atomic/obj.loading-state';
import { QuoteStrings } from '../quote.string';
import { MenuQuoteWrapper } from './menu-quote-page.components.style';

interface TabProps {
  id: string;
  tabIndex: number;
  setTabIndex?: (tab: number) => void;
  loading: boolean;
  language?: string;
  totalData?: number;
  handleTabChange?: (index: number) => void;
}

export const MenuQuotePage = (props: TabProps) => {
  const hasCrmTabAccess = useSelector(hasCrmTabAccessSelector);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);

  return (
    <LoadingState loading={props.loading}>
      <MenuQuoteWrapper>
        <Tab key={0} initialIndex={props.tabIndex} onIndexChanged={props.handleTabChange}>
          <Tab.Item to={`${AppPath.QUOTE.BASE}`}>{QuoteStrings[props.language].tabs.all}</Tab.Item>
          <Tab.Item to={`${AppPath.QUOTE.PENDINGS}`}>{QuoteStrings[props.language].tabs.myAnalysis}</Tab.Item>
          {quoteIsInternalUser && (
            <Tab.Item to={`${AppPath.QUOTE.CLIENTS}`}>{QuoteStrings[props.language].tabs.myClients}</Tab.Item>
          )}
          <Tab.Item to={`${AppPath.QUOTE.DRAFTS}`}>{QuoteStrings[props.language].tabs.draft}</Tab.Item>
          {hasCrmTabAccess && <Tab.Item to={`${AppPath.QUOTE.CRM}`}>{QuoteStrings[props.language].tabs.crm}</Tab.Item>}
        </Tab>
      </MenuQuoteWrapper>
    </LoadingState>
  );
};
