import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { HelpCenterPageState } from '@app/models/help-center.model';
import { LayoutTabs } from '@app/models/layout.model';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Strings, IComponentsStrings } from '../components.string';
import { GridButtonWithIcon } from '../grid-button-with-icon.component';
import { GridColumnFilterBody } from './grid-column-filter-body.component';
import { GridColumnFilterStyled } from './grid-column-filter.component.style';

interface IGridColumnFilterProps {
  tabName: LayoutTabs;
  helpRedirect?: HelpCenterPageState;
}

export const GridColumnFilter: React.FC<IGridColumnFilterProps> = ({ tabName, helpRedirect }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IComponentsStrings = Strings[userInfo?.language];
  const [show, setShow] = useState(false);

  const handleFilterClick = () => {
    LogAnalytics.click({ tipo: 'GridFiltro' });
    setShow(show ? false : true);
  };

  return (
    <GridColumnFilterStyled>
      <GridButtonWithIcon
        text={strings?.gridColumnFilter}
        icon={FaIcon.Columns}
        kind='secondary'
        onClick={handleFilterClick}
      />
      {show && <GridColumnFilterBody helpRedirect={helpRedirect} tabName={tabName} show={show} setShow={setShow} />}
    </GridColumnFilterStyled>
  );
};
