import styled from 'styled-components';
import { FlexColumn } from '@atomic/obj.box';

export const OrderNumberInput = styled.input`
  width: 100%;
`;

export const AmountInput = styled.input`
  width: 85%;
  margin-left: -12px;
`;

export const UnityWrapper = styled(FlexColumn)`
  padding-left: 15%;
`;
