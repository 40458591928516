import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  hasEPPAccessSelector,
  hasGQAccessSelector,
  hasPEDAccessSelector,
  hasRTCAccessSelector,
  hasSellerAccessSelector,
} from '@app/modules/auth/store/auth.selectores';
import {
  RTC_PERMISSIONS,
  EPP_PERMISSIONS,
  PED_PERMISSIONS,
  GQ_PERMISSIONS,
  SELLER_PERMISSIONS,
} from '../quote.contants';
import { CadtItem } from './cardItem';
import { HeaderColumn } from './headerColumn';
import { ColumnWrapper, ItemsCol, KanbanWrapper, SeparatorCol } from './kanban.components.style';
import { makeOrderingByRole } from './utils/makeOrderingByRole';
interface KanbanProps {
  dataAnalysis: any;
}
interface Col {
  key: string;
  label: string;
}

export const Kanban = (props: KanbanProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const hasRTCAccess = useSelector(hasRTCAccessSelector);
  const hasSellerAccess = useSelector(hasSellerAccessSelector);
  const hasEPPAccess = useSelector(hasEPPAccessSelector);
  const hasPEDAccess = useSelector(hasPEDAccessSelector);
  const hasGQAccess = useSelector(hasGQAccessSelector);

  const colsKanban = {
    PENDING: [],
    INTERNAL_QUERY: [],
    INTERNAL_QUERY_SELLER: [],
    UNDER_ANALYSIS_EPP: [],
    IN_VALIDATION_EPP: [],
    UNDER_ANALYSIS_RTC: [],
    VALIDATION_RTC: [],
    DATA_VALIDATION_RTC: [],
    UNDER_ANALYSIS_PED: [],
    IN_VALIDATION_PED: [],
    UNDER_ANALYSIS_GQ: [],
    IN_VALIDATION_GQ: [],
    VALIDATION_SELLER: [],
    WAITING_CLIENT_REVIEW: [],
    CLIENT_EDITION: [],
    CANCELED: [],
    REJECTED: [],
    FINALIZED: [],
    FINALIZED_WITH_DEROGATION: [],
    WAITING_PRICE: [],
    AVAILABLE_PRICE: [],
    NO_CUSTOMER_RESPONSE: [],
  };

  // eslint-disable-next-line complexity
  props.dataAnalysis.map(item => {
    switch (item.status.internal.key) {
      case 'PENDING':
        colsKanban.PENDING.push(item);
        break;
      case 'INTERNAL_QUERY':
        colsKanban.INTERNAL_QUERY.push(item);
        break;
      case 'UNDER_ANALYSIS_EPP':
        colsKanban.UNDER_ANALYSIS_EPP.push(item);
        break;
      case 'UNDER_ANALYSIS_PED':
        colsKanban.UNDER_ANALYSIS_PED.push(item);
        break;
      case 'UNDER_ANALYSIS_RTC':
        colsKanban.UNDER_ANALYSIS_RTC.push(item);
        break;
      case 'UNDER_ANALYSIS_GQ':
        colsKanban.UNDER_ANALYSIS_GQ.push(item);
        break;
      case 'DATA_VALIDATION_RTC':
        colsKanban.DATA_VALIDATION_RTC.push(item);
        break;
      case 'IN_VALIDATION_EPP':
        colsKanban.IN_VALIDATION_EPP.push(item);
        break;
      case 'IN_VALIDATION_PED':
        colsKanban.IN_VALIDATION_PED.push(item);
        break;
      case 'IN_VALIDATION_GQ':
        colsKanban.IN_VALIDATION_GQ.push(item);
        break;
      case 'WAITING_CLIENT_REVIEW':
        colsKanban.WAITING_CLIENT_REVIEW.push(item);
        break;
      case 'INTERNAL_QUERY_SELLER':
        colsKanban.INTERNAL_QUERY_SELLER.push(item);
        break;
      case 'VALIDATION_RTC':
        colsKanban.VALIDATION_RTC.push(item);
        break;
      case 'VALIDATION_SELLER':
        colsKanban.VALIDATION_SELLER.push(item);
        break;
      case 'CLIENT_EDITION':
        colsKanban.CLIENT_EDITION.push(item);
        break;
      case 'CANCELED':
        colsKanban.CANCELED.push(item);
        break;
      case 'REJECTED':
        colsKanban.REJECTED.push(item);
        break;
      case 'FINALIZED':
        colsKanban.FINALIZED.push(item);
        break;
      case 'FINALIZED_WITH_DEROGATION':
        colsKanban.FINALIZED_WITH_DEROGATION.push(item);
        break;
      case 'WAITING_PRICE':
        colsKanban.WAITING_PRICE.push(item);
        break;
      case 'AVAILABLE_PRICE':
        colsKanban.AVAILABLE_PRICE.push(item);
        break;
      case 'NO_CUSTOMER_RESPONSE':
        colsKanban.NO_CUSTOMER_RESPONSE.push(item);
        break;
      default:
        break;
    }
  });

  const ordenado = makeOrderingByRole(userInfo?.profile?.profile.toUpperCase());

  const columnWrapperFunc = (index: number, col: Col) => {
    return (
      <ColumnWrapper key={index}>
        <HeaderColumn totalItems={colsKanban[col.key].length} item={col} />
        <SeparatorCol />
        <ItemsCol>
          {colsKanban[col.key].map((item, index2) => (
            <CadtItem key={`i-${index2}`} item={item} />
          ))}
        </ItemsCol>
      </ColumnWrapper>
    );
  };

  const permissions = (col: Col, index: number) => {
    if (hasRTCAccess) {
      return RTC_PERMISSIONS.includes(col.key) && columnWrapperFunc(index, col);
    } else if (hasEPPAccess) {
      return EPP_PERMISSIONS.includes(col.key) && columnWrapperFunc(index, col);
    } else if (hasPEDAccess) {
      return PED_PERMISSIONS.includes(col.key) && columnWrapperFunc(index, col);
    } else if (hasGQAccess) {
      return GQ_PERMISSIONS.includes(col.key) && columnWrapperFunc(index, col);
    } else if (hasSellerAccess) {
      return SELLER_PERMISSIONS.includes(col.key) && columnWrapperFunc(index, col);
    } else {
      return '';
    }
  };

  return (
    <KanbanWrapper>
      {ordenado.map((col: Col, index: number) => {
        return permissions(col, index);
      })}
    </KanbanWrapper>
  );
};
