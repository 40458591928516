import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { IUser } from '../../admin.interfaces';
import { AdminStrings } from '../../admin.string';

export const getUser = createAsyncThunk(`${SLICE_NAME}/getUser`, async (email: string, thunkAPI) => {
  const strings = AdminStrings.pt.editView;

  return await AdminDataSource.getUser(email)
    .then(data => data)
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: strings.getUserError, variant: ToastVariant.DANGER }));
    });
});

export const addGetUserReducers = builder => {
  builder.addCase(getUser.pending, state => {
    state.loadingUser = true;
  });
  builder.addCase(getUser.fulfilled, (state, action) => {
    const payload: IUser = action.payload as IUser;
    state.user = payload;
    state.loadingUser = false;
    state.showForm = true;
    state.isEditing = true;
  });
  builder.addCase(getUser.rejected, state => {
    state.loadingUser = false;
  });
};
