import * as React from 'react';
import { NoficationStepKeys, NotificationItem } from '@app/models/notification-center.model';
import { dateParserNotification } from '@app/utils/date-parser';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { Body, BodySecondary, H3 } from '@atomic/atm.typography';
import { TrailingAccordion } from '@atomic/mol.trailing-accordion/trailing-accordion.component';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { Strings, INotificationCenterStrings } from './notification.string';

interface NotificationCellProps {
  notification: NotificationItem;
  onClearClick: () => void;
}

const strings: INotificationCenterStrings = Strings.pt;
const { notificationCell } = strings;

const notificationStepData = {
  [NoficationStepKeys.orderConfirmation]: { text: notificationCell.step.orderConfirmation, icon: 'check-circle' },
  [NoficationStepKeys.startProduction]: { text: notificationCell.step.startProduction, icon: 'industry' },
  [NoficationStepKeys.inStock]: { text: notificationCell.step.inStock, icon: 'box' },
  [NoficationStepKeys.statusChange]: { text: notificationCell.step.statusChange, icon: 'bullhorn' },
  [NoficationStepKeys.shipCreated]: { text: notificationCell.step.shipCreated, icon: 'file-import' },
  [NoficationStepKeys.shipmentStarted]: { text: notificationCell.step.shipmentStarted, icon: 'truck-loading' },
  [NoficationStepKeys.billed]: { text: notificationCell.step.billed, icon: 'file-alt' },
  [NoficationStepKeys.sentForDelivery]: { text: notificationCell.step.sentForDelivery, icon: 'truck' },
  [NoficationStepKeys.arrived]: { text: notificationCell.step.arrived, icon: 'map-marker-alt' },
};

export const NotificationCell: React.FC<NotificationCellProps> = props => {
  const [expanded, setExpanded] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  const hasMore = props.notification.steps.length > 1;

  return (
    <div onMouseEnter={() => setFocused(true)} onMouseLeave={() => setFocused(false)}>
      <Frame padded rounded>
        <Hbox>
          <Hbox.Item>
            <H3>{props.notification.aboveTitle}</H3>
          </Hbox.Item>
          {focused && (
            <Hbox.Item noGrow>
              <FaIcon.Close color={Color.GrayDark} clickable='true' onClick={props.onClearClick} />
            </Hbox.Item>
          )}
        </Hbox>
        <Hbox>
          <Hbox.Item>
            <H3> {props.notification.title}</H3>
          </Hbox.Item>
        </Hbox>
        <VSeparator small />

        <Body>OV/Item: {props.notification.subTitle} </Body>
        <VSeparator />

        {props.notification.steps?.[0] && (
          <Hbox>
            <Hbox.Item noGrow>
              {props.notification.steps[0].key && (
                <FaIcon.Custom
                  color={Color.GrayDark}
                  icon={notificationStepData[props.notification.steps[0].key]?.icon}
                />
              )}
            </Hbox.Item>
            <Hbox.Separator small />
            <Hbox.Item vAlign='center'>
              <BodySecondary>{notificationStepData[props.notification.steps[0].key]?.text}</BodySecondary>
            </Hbox.Item>
            <Hbox.Item vAlign='center' noGrow>
              <BodySecondary>
                {dateParserNotification({ unformattedDate: props.notification.steps[0].time })}
              </BodySecondary>
            </Hbox.Item>
          </Hbox>
        )}
        {hasMore && (
          <>
            <VSeparator />
            <TrailingAccordion
              expanded={expanded}
              openedTitle={notificationCell.showLess}
              closedTitle={notificationCell.showMore(props.notification.steps.length - 1)}
              onClick={() => setExpanded(prevExpanded => !prevExpanded)}
            >
              {props.notification.steps.slice(1).map((step, index, array) => (
                <React.Fragment key={index}>
                  <Hbox>
                    <Hbox.Item noGrow>
                      <FaIcon.Custom color={Color.GrayDark} icon={notificationStepData[step.key]?.icon} />
                    </Hbox.Item>
                    <Hbox.Separator small />
                    <Hbox.Item vAlign='center'>
                      <BodySecondary>{notificationStepData[step.key]?.text}</BodySecondary>
                    </Hbox.Item>
                    <Hbox.Item vAlign='center' noGrow>
                      <BodySecondary>{dateParserNotification({ unformattedDate: step.time })}</BodySecondary>
                    </Hbox.Item>
                  </Hbox>
                  {index < array.length && <VSeparator />}
                </React.Fragment>
              ))}
            </TrailingAccordion>
          </>
        )}
      </Frame>
    </div>
  );
};
