import { ptBR, es, enUS } from 'date-fns/esm/locale';
import * as React from 'react';
import { registerLocale } from 'react-datepicker';
import { DatePickerStyled } from './date-picker.component.style';

// Based on https://reactdatepicker.com/
export interface DatePickerProps {
  minDate?: Date | null;
  maxDate?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  placeholder?: string;
  onValueChange?: (date: any) => void;
  onFocusChange?: (focused: boolean) => void;
  showTimeSelect?: boolean;
  disabled?: boolean;
  endSelect?: boolean;
  filled?: boolean;
  color?: string;
  defineDate?: boolean;
  excludeDates?: Date[];
  opened?: boolean;
  close?: () => void;
  centralized?: boolean;
  language?: string;
  popperPlacement?: string;
}

export interface DatePickerState {
  startDate: Date | null;
  endDate: Date | null;
}

export class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
  static defaultProps = {
    endSelect: false,
    defineDate: false,
  };
  calendarRef: any;

  constructor(props: DatePickerProps) {
    super(props);

    registerLocale('pt-br', ptBR);
    registerLocale('es', es);
    registerLocale('en', enUS);

    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
    };
    this.calendarRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.opened) {
      this.calendarRef.current.setOpen(this.props.opened);
      this.props.close();
    }
  }

  render() {
    const {
      placeholder,
      showTimeSelect,
      disabled,
      endSelect,
      filled,
      color,
      defineDate,
      excludeDates,
      minDate,
      maxDate,
      centralized,
      language,
    } = this.props;

    const startDate = defineDate || this.isStartDateControlled() ? this.props.startDate : null;
    const endDate = this.isEndDateControlled() ? this.props.endDate : this.state.endDate;

    const locales = {
      pt: 'pt-br',
      es: 'es',
      in: 'en',
    };

    return (
      <DatePickerStyled
        ref={this.calendarRef}
        locale={locales[language]}
        dateFormat={language === 'in' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
        onChange={this.handleDateChange}
        minDate={minDate}
        maxDate={maxDate}
        startDate={startDate}
        endDate={endDate}
        excludeDates={excludeDates}
        selected={endSelect ? endDate : startDate}
        onFocus={this.handleFocusChange(true)}
        onBlur={this.handleFocusChange(false)}
        placeholderText={placeholder}
        showTimeSelect={showTimeSelect}
        disabled={disabled}
        selectsStart={!endSelect}
        selectsEnd={endSelect}
        filled={filled}
        color={color}
        centralized={centralized}
        popperPlacement={this.props.popperPlacement}
      />
    );
  }

  private isStartDateControlled() {
    return this.props.startDate !== undefined && this.props.startDate !== null;
  }

  private isEndDateControlled() {
    return this.props.endDate !== undefined && this.props.endDate !== null;
  }

  private handleDateChange = (date: Date) => {
    if (this.props.endSelect) {
      if (!this.isEndDateControlled()) {
        this.setState({ endDate: date });
      }
    } else {
      this.setState({ startDate: date });
    }

    if (this.props.onValueChange) {
      this.props.onValueChange(date);
    }
  };

  private handleFocusChange = (focused: boolean) => () => {
    if (this.props.onFocusChange) {
      this.props.onFocusChange(focused);
    }
  };
}
