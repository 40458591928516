import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';

interface QtyFieldStyledProps {
  color?: string;
}

export const QtyFieldStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${(props: QtyFieldStyledProps) => (props.color ? props.color : Color.GrayDark)};
`;
