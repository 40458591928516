import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';

export const OrderInputUploadFileWrapper = styled(FlexRow)`
  width: 100%;
  justify-content: flex-start;
  margin-top: ${Spacing.Small};
  margin-left: -${Spacing.Small};
`;

export const SpreasheetFileWrapperStyled = styled.div`
  width: 20%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px 20px 10px;
  border: 1px solid ${Color.Gray};
  border-radius: 5px;
  margin: 0;
  margin-left: ${Spacing.Small};
  & > div {
    justify-content: center;
    text-align: center;
  }
`;
