import * as React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { VSeparator } from '@atomic/obj.grid';

export const NotificationListShimmer: React.FC = () => {
  return (
    <>
      {Array(4)
        .fill('')
        .map((_, index) => (
          <React.Fragment key={index}>
            <TextShimmerBoxStyled height='100px' width='100%' margin='0' />
            <VSeparator />
          </React.Fragment>
        ))}
    </>
  );
};
