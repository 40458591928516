import { History, LocationDescriptorObject, Path } from 'history';
import { Container, Service, Token } from 'typedi';

export const HistoryToken = new Token<History>();

export enum HistoryAction {
  PUSH = 'PUSH',
  POP = 'POP',
}

@Service()
// tslint:disable-next-line:class-name
export class Router {
  private _history: History;
  private historyStack = [];

  // Lazy-loading
  private get history(): History {
    if (!this._history) {
      this._history = Container.get(HistoryToken);
      this._history.listen(this.handleHistoryChange);

      // disable Chrome's auto scrollRestoration:
      // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
      if (typeof history !== 'undefined' && 'scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }
    }
    return this._history;
  }

  canGoBack() {
    return this.historyStack.length === 0;
  }

  push(path: Path | LocationDescriptorObject): void {
    if (typeof path === 'object') {
      this.history.push(this.computePath(path) as LocationDescriptorObject);
    } else {
      this.history.push(this.computePath(path) as string, undefined);
    }
  }

  replace(path: Path | LocationDescriptorObject): void {
    if (typeof path === 'object') {
      this.history.replace(this.computePath(path) as LocationDescriptorObject);
    } else {
      this.history.replace(this.computePath(path) as string, undefined);
    }
  }

  go(n: number): void {
    this.history.go(n);
  }
  goBack(): void {
    this.history.goBack();
  }

  goForward(): void {
    this.history.goForward();
  }

  computePath(path: Path | LocationDescriptorObject): Path | LocationDescriptorObject {
    return this.resolve(undefined, path);
  }

  resolve(basePath: string, path: Path | LocationDescriptorObject): Path | LocationDescriptorObject {
    if (typeof path === 'object') {
      const pathname = path.pathname;
      if (isRelative(pathname)) {
        path.pathname = this.resolveRelativePath(basePath, pathname);
      }
      return path;
    }

    if (isRelative(path)) {
      return this.resolveRelativePath(basePath, path);
    }

    return path;
  }

  private resolveRelativePath(basePath: string, path: Path): Path {
    basePath = basePath || this.history.location.pathname;

    if (isRelativeToLocation(path)) {
      return basePath + path.substr(1);
    }

    const pathSegments = path.split('/');
    const trimCount = pathSegments.filter(segment => segment === '..').length;
    const locationPathSegments = basePath.split('/').filter(segment => segment.length > 0);

    locationPathSegments.splice(trimCount);

    const resolvedPath = '/' + [...locationPathSegments, ...pathSegments.slice(trimCount)].join('/');
    return resolvedPath.replace(/^\/\./, '.');
  }

  private handleHistoryChange = (location, action) => {
    switch (action) {
      case HistoryAction.PUSH:
        this.historyStack.push(`${location.pathname}${location.search}`);
        break;
      case HistoryAction.POP:
        this.historyStack.pop();
        break;
    }
  };
}

function isRelative(path: Path): boolean {
  return /^\.{1,2}\/\S*$/.test(path) || /^\.$/.test(path);
}

function isRelativeToLocation(path: Path): boolean {
  return /^\.{1}\/\S*$/.test(path) || /^\.$/.test(path);
}
