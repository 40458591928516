import DatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';
//import { Color, FontSize } from '@atomic/obj.constants';
import { InputPlaceholderCss } from '@atomic/atm.typography';
import { fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';
import 'react-datepicker/dist/react-datepicker.css';

// const DATE_PICKER_COLOR = Color.Primary;

interface DatePickerProps {
  filled: boolean;
  color: string;
  centralized?: boolean;
}

export const DatePickerStyled = styled(DatePicker)`
  ${fieldCss}
  ${(props: DatePickerProps) => (props.filled ? DatePickerFilledCss : fieldBorderCss)}
  text-align: ${(props: DatePickerProps) => (props.centralized ? 'center' : 'initial')};
`;

export const DatePickerFilledCss = css`
  ${(props: DatePickerProps) => `background-color: ${props.color}`};
  border: none;
  ${InputPlaceholderCss};
`;
