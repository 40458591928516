import { HttpInterceptor } from '@app/core/http';
import { Response } from '../dto';

export class UnwrapDataInterceptor implements HttpInterceptor {
  async afterSuccess(response: Response<any>): Promise<any> {
    if (response.hasOwnProperty('data')) {
      return response.data;
    }
    return response;
  }
}
