/* eslint-disable complexity */
import * as React from 'react';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
import { PreviewContainerStyled, ZoomIconWrapper } from './attribute-cell.form.style';

export interface PrevImageProps {
  position: number;
  img: string;
  language: string;
  handleDeleteImage: (index: number | string) => void;
  handleZoom: (value: number) => void;
  readOnly?: boolean;
  idImg?: string;
}

export const PreviewImage = (props: PrevImageProps) => {
  const strings = TechnicalAnalysisStrings[props.language].attributes.attributeCell;
  return (
    <PreviewContainerStyled bg={props.img}>
      <ZoomIconWrapper onClick={() => props.handleZoom(props.position)}>
        <FaIcon.Search color={Color.White} size='2x' />
      </ZoomIconWrapper>
      {!props.readOnly ? (
        <Button
          onClick={() => props.handleDeleteImage(props?.idImg ? props.idImg : props.position)}
          title={strings.removeImage}
        >
          <FaIcon.Close size='1x' />
        </Button>
      ) : (
        <Button title={strings.image.alertImage} kind='alert'>
          <FaIcon.Warning size='1x' />
        </Button>
      )}
    </PreviewContainerStyled>
  );
};
