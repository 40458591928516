import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '@app/core/redux/store';
import { AnalysisResume, AnalysisStatus } from '@app/models/analysis.model';
import { AppPath } from '@app/modules/app/route-constants';
import {
  hasRTCAccessSelector,
  hasSellerAccessSelector,
  quoteIsInternalUserSelector,
} from '@app/modules/auth/store/auth.selectores';
import { quoteBadges } from '@app/modules/quote/quote.contants';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { Badge } from '@atomic/atm.badge';
import { Cell } from '@atomic/atm.cell';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, ButtonText, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { QuoteListItemAdditionalInfo } from './quote-list-item-additional-info';
import {
  BrandWrapperStyled,
  QuoteItemIsaNumberWrapper,
  QuoteItemPriceStyled,
  QuoteItemWrapperStyled,
  QuoteTimeLimitWrapper,
  TagStyled,
  VisibleButtonStyled,
} from './quote.components.style';
import { QuoteTimeLimit } from './quoteTimeLimit';

export interface QuoteListItemProps {
  id: string;
  item: AnalysisResume;
  status: AnalysisStatus;
  waitingClient: boolean;
  onPriceClick: () => void;
  selectedItems: string[] | number[];
  handleSelectItem: (confirmationId: string | number, checked: boolean) => void;
}

interface VisibleToClientProps {
  item: number;
  clientVisible?: boolean | string;
}

export const VisibleToClientIcon = (props: VisibleToClientProps) => {
  return (
    <VisibleButtonStyled>
      {props?.clientVisible && <FaIcon.Eye color={Color.Primary} title='Visível para o cliente' size='1x' />}
    </VisibleButtonStyled>
  );
};

export const QuoteListItem = (props: QuoteListItemProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const hasRTCAccess = useSelector(hasRTCAccessSelector);
  const hasSellerAccess = useSelector(hasSellerAccessSelector);
  const strings = QuoteStrings[userInfo.language].components;
  const quoteStatus = QuoteStrings[userInfo.language];

  const statusToShowLimitDate = [
    'CANCELED',
    'REJECTED',
    'FINALIZED',
    'FINALIZED_WITH_DEROGATION',
    'WAITING_CLIENT_REVIEW',
    'AVAILABLE_PRICE',
    'NO_CUSTOMER_RESPONSE',
  ];

  const statusToBlockClientVisible = ['INTERNAL_QUERY', 'INTERNAL_QUERY_SELLER'];

  return (
    <QuoteItemWrapperStyled key={props.item.id}>
      <Cell hover>
        <Hbox>
          {hasRTCAccess || hasSellerAccess ? (
            <CheckboxField
              id={`el-${props.item.id}`}
              onValueChange={(_, checked) => props.handleSelectItem(props.item.id, checked)}
              checked={props.selectedItems.some(item => item === props.item.id)}
              disabled={
                props.item.userCreator.profile === 'CLIENT' || statusToBlockClientVisible.includes(props.status.key)
              }
            />
          ) : null}

          <Link to={`${AppPath.QUOTE.ANALYSIS}/${props.item.id}`} target='_blank'>
            {props.item.branding && (
              <BrandWrapperStyled>
                <img
                  src={`assets/icons/brand/logo-${props.item.branding
                    .replaceAll(' ', '-')
                    .toLocaleLowerCase()}-vertical.png`}
                  title={props.item.branding.replaceAll('_', ' ')}
                />
              </BrandWrapperStyled>
            )}

            <Hbox.Item>
              <H3>
                {props.item.title}
                {props.item.client.visible && quoteIsInternalUser && (
                  <TagStyled>
                    <FaIcon.Eye size='1x' color={Color.White} /> {strings.tagsLabel.clientVisible}
                  </TagStyled>
                )}
                {props.item.userCreator.profile === 'CLIENT' && quoteIsInternalUser && (
                  <TagStyled disabled={true}>
                    <FaIcon.User size='1x' color={Color.WarningDark} /> {strings.tagsLabel.clientCreator}
                  </TagStyled>
                )}
              </H3>
              <Hbox noGrow vAlign='flex-end'>
                <QuoteItemIsaNumberWrapper>
                  <Body>{props.item.product.steelName}</Body>
                  <Body>ISA {props.item.formattedId}</Body>
                </QuoteItemIsaNumberWrapper>
                <Hbox.Separator />
                <QuoteListItemAdditionalInfo
                  flow={props.item.flow.label}
                  client={props.item.client.name}
                  dimensional={props.item.product.dimensional}
                  plants={props.item.plants}
                />
                <Hbox.Separator />
              </Hbox>
            </Hbox.Item>
          </Link>

          <QuoteTimeLimitWrapper>
            {quoteIsInternalUser && !statusToShowLimitDate.includes(props.item.status.internal.key) && (
              <QuoteTimeLimit
                language={userInfo.language}
                createdDate={props.item.createdDate}
                priorityDate={props.item.priority.date}
                priority={props.item.flow.type === 'CLIENT_MANUAL' ? 'GQ' : props.item.priority.label}
              />
            )}
            <Badge id='quote_status' color={quoteBadges[props.status.key]?.color}>
              {
                quoteStatus.constants.stage.label[
                  !props.waitingClient ? props.status.key : props.status.key + '_CLIENT'
                ]
              }
            </Badge>
            {props.item.hasPrice && (
              <QuoteItemPriceStyled>
                <ButtonText onClick={props.onPriceClick}>{strings.price}</ButtonText>
              </QuoteItemPriceStyled>
            )}
          </QuoteTimeLimitWrapper>
        </Hbox>
      </Cell>
    </QuoteItemWrapperStyled>
  );
};
