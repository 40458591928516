import { addMonths, format, subMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color, FontWeight } from '@atomic/obj.constants';

interface CustomCalendarHeaderProps {
  activeDate: Date;
  setActiveDate: (value: Date) => void;
}

export const CustomCalendarHeader: React.FC<CustomCalendarHeaderProps> = ({ activeDate, setActiveDate }) => {
  return (
    <FlexRow pl pr fullWidth hAlign='space-between'>
      <FaIcon.ChevronLeft size='sm' color={Color.Primary} onClick={() => setActiveDate(subMonths(activeDate, 1))} />
      <Body bold={FontWeight.Bold}>{format(activeDate, 'MMMM yyyy', { locale: ptBR })}</Body>
      <FaIcon.ChevronRight size='sm' color={Color.Primary} onClick={() => setActiveDate(addMonths(activeDate, 1))} />
    </FlexRow>
  );
};
