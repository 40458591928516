export enum TableColumns {
  iconCell = 'iconCell',
  ovItem = 'ovItem',
  itemOv = 'itemOv',
  ocClient = 'ocClient',
  codMaterialClient = 'codMaterialClient',
  descMaterial = 'descMaterial',
  dateMaxDeliveryClient = 'dateMaxDeliveryClient',
  qtdConf = 'qtdConf',
  qtdSolClient = 'qtdSolClient',
  messageClient = 'messageClient',
  actionCell = 'actionCell',
}

export enum TableColumnType {
  Icon = 'icon',
  StaticText = 'staticText',
  StaticDate = 'staticDate',
  Number = 'number',
  Checkbox = 'checkbox',
  Text = 'text',
  Date = 'date',
  Action = 'action',
}
