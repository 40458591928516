import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { DashboardHeaderWrapperStyled } from './dashboard.style';

interface DashboardBlockHeaderProps {
  title: string;
  description?: string;
}

export const DashboardBlockHeader: React.FC<DashboardBlockHeaderProps> = props => {
  const [showDescription, setShowDescription] = React.useState(false);

  return (
    <Hbox>
      <Hbox.Separator />
      <Hbox.Item vAlign='center'>
        <H3>{props.title}</H3>
      </Hbox.Item>
      {props.description && (
        <>
          <Hbox.Item noGrow>
            <FaIcon.Question
              onMouseOver={() => setShowDescription(true)}
              onMouseOut={() => setShowDescription(false)}
              color={Color.Primary}
            />
            {showDescription && (
              <DashboardHeaderWrapperStyled show={showDescription}>
                <Body>{props.description}</Body>
              </DashboardHeaderWrapperStyled>
            )}
          </Hbox.Item>
          <Hbox.Separator />
        </>
      )}
    </Hbox>
  );
};
