import * as React from 'react';
import Lottie from 'react-lottie';
import loadingData from '@assets/data/isa-loading.json';
import { H2 } from '@atomic/atm.typography';
import { LoadingWrapperStyled } from './placeholder.style';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface IsaLoadingProps {
  message: string;
}

export const IsaLoading: React.FC<IsaLoadingProps> = props => {
  return (
    <LoadingWrapperStyled>
      <Lottie options={defaultOptions} height={300} width={300} />
      <H2>{props.message}</H2>
    </LoadingWrapperStyled>
  );
};
