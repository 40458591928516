import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { RootState } from '@app/core/redux/store';
import { InStockOrderItem } from '@app/models';
import { LayoutTabs } from '@app/models/layout.model';
import { PopoverContext, PopoverContextState } from '@app/modules/components/popover/popover-container';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { BrandColor } from '@atomic/obj.constants';
import { PARTY_NUMBERS_GKN_ALERT } from '../../order-tab-stock.constants';
import { AlertCellWrapper } from './alert-cell.style';
interface IAlertCellRendererProps {
  data: InStockOrderItem;
}

const ARROW_MIDDLE = 300;
const ARROW_LEFT = 2;

export const AlertCellRenderer: React.FC<IAlertCellRendererProps> = ({ data }) => {
  const strings: IShippingRequestStrings = Strings.pt;
  const { openPopover, closePopover } = useContext<PopoverContextState>(PopoverContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const columnDef = useSelector(columnsTabLayoutSelector(LayoutTabs.StockMI, userInfo?.language));

  const showedColumns = columnDef.filter(col => !col.hide);
  const pinnedShowedColumns = showedColumns.filter(col => col.pinned);
  const column = showedColumns.find(col => col.field === 'breakHeat');
  const colIndex = showedColumns.findIndex(col => col.field === 'breakHeat');
  const colIndexPinned = pinnedShowedColumns.findIndex(col => col.field === 'breakHeat');

  const openRight =
    ((colIndex > -1 && colIndex < 2) || (colIndexPinned > -1 && colIndexPinned < 2)) && column.pinned !== 'right';

  const arrowLeft = openRight ? ARROW_LEFT : ARROW_MIDDLE;
  const ref = useRef(null);

  const onMouseOver = () => {
    openPopover(ref, <Body>{strings.page.sequentialWarning}</Body>, null, arrowLeft, openRight);
  };

  const onMouseOut = () => {
    closePopover();
  };

  useEffect(() => {
    closePopover();
  }, [ref]);

  if (PARTY_NUMBERS_GKN_ALERT.includes(data.customerMaterialCode)) {
    if (data.breakHeat === 'Sim') {
      return (
        <FlexRow fullWidth hAlign='center'>
          <AlertCellWrapper ref={ref} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
            <FaIcon.Warning color={BrandColor.Yellow} />
          </AlertCellWrapper>
        </FlexRow>
      );
    }
  }

  return null;
};
