import { GridReadyEvent } from 'ag-grid-community';
import * as FileSaver from 'file-saver';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { UserAnalyticsType } from '@app/models/analytics.model';
import { OrdersStrings } from '@app/modules/order/orders.string';
import { FaIcon } from '@atomic/atm.fa-icon';
import { hasInternalAccessSelector } from '../../auth/store/auth.selectores';
import { GridButtonWithIcon } from '../grid-button-with-icon.component';
import { FILE_NAME_PREFIX, WORKBOOK_TYPE, XLSX_FILE_EXTENSION, XLSX_MIME_FILE_TYPE } from './grid-download.constants';
import { mapRowsToExcellData } from './utils/utils';

interface GridDownloadProps {
  gridEvent: GridReadyEvent;
  filename: string;
  tabName?: string;
}

export const GridDownload: React.FC<GridDownloadProps> = ({ gridEvent, filename, tabName }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { currentMarket } = useSelector((state: RootState) => state.customerPersist);
  const hasInternalAccess = useSelector(hasInternalAccessSelector);

  const handleDownload = () => {
    const excellData = mapRowsToExcellData({ gridEvent, tabName, currentMarket, language: userInfo.language });
    const worksheet = XLSX.utils.json_to_sheet(excellData.rows, { header: excellData.header });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    const excelBuffer = XLSX.write(workbook, { bookType: XLSX_FILE_EXTENSION, type: WORKBOOK_TYPE });
    const data = new Blob([excelBuffer], { type: XLSX_MIME_FILE_TYPE });
    FileSaver.saveAs(data, `${FILE_NAME_PREFIX} - ${filename}.${XLSX_FILE_EXTENSION}`);

    LogAnalytics.click({
      tipo: 'ExcelDownload',
      kind: hasInternalAccess ? UserAnalyticsType.Employee : UserAnalyticsType.Client,
      userId: userInfo.oktaUid,
    });
  };

  return (
    <GridButtonWithIcon
      text={OrdersStrings[userInfo?.language]?.common.download}
      icon={FaIcon.FileDownload}
      kind='callToAction'
      onClick={handleDownload}
    />
  );
};
