import { SalesOrderSummaryItem } from '@app/models';
import { dateParser } from '@app/utils/date-parser';
import { FormDataTuple, IOrderUpdateTableData } from './order-update.interfaces';

export interface IGetFormDataParams {
  tableRows: IOrderUpdateTableData[];
  language: string;
}
export const getFormData = ({ tableRows, language }: IGetFormDataParams): FormDataTuple[] => {
  const rows: FormDataTuple[] = tableRows.map((item: IOrderUpdateTableData) => {
    return [
      item.plant,
      item.ov,
      item.itemOv,
      item.material,
      item.descMaterial,
      item.codMaterialClient,
      item.ocClient,
      item.ocClientItem,
      item.dateDeliveryClient,
      item.dateMaxDeliveryClient,
      item.dateSolClient,
      item.newDateSolClient
        ? dateParser({ unformattedDate: new Date(item.newDateSolClient).toString(), hideHours: true, language })
        : '',
      item.qtdConf,
      item.qtdSolClient,
      item.newQtdSolClient ? item.newQtdSolClient : '',
      item.messageClient ? item.messageClient : '',
      item.cancelOrder,
    ];
  });
  return rows;
};

export const handleUnformatedDate = (date: string): string => {
  if (date) {
    const dateArray = date.split('/');
    const returnValue = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    return returnValue;
  }

  return null;
};

export const mapSelectedRows = (rows: SalesOrderSummaryItem[]): IOrderUpdateTableData[] => {
  return rows.map(row => ({
    ov: row.ov,
    itemOv: row.itemOv,
    plant: row.plant,
    status: row.status,
    ocClient: row.ocClient,
    ocClientItem: row.ocClientItem,
    qtdConf: row.qtdConf,
    qtdSolClient: row.qtdSolClient,
    codMaterialClient: row.codMaterialClient,
    dateMaxDeliveryClient: row.dateMaxDeliveryClient,
    dateSolClient: row.dateSolClient,
    material: row.material,
    descMaterial: row.descMaterial,
    dateDeliveryClient: row.dateMaxDeliveryClient,
    qtyStock: row.qtyStock,
    qtyTotalProduction: row.qtyTotalProduction,
    clientNumber: row.clientNumber,
    nameClient: row.nameClient,
    newQtdSolClient: null,
    newDateSolClient: null,
    cancelOrder: false,
    messageClient: '',
    removedAlertStatus: false,
    removedAlertDate: false,
    removedAlertCancel: false,
  }));
};

export const checkInvalidFields = (tableRows: IOrderUpdateTableData[]): boolean => {
  let hasInvalidOrderToUpdade = false;

  if (!tableRows.length) {
    return true;
  }

  tableRows.forEach((item: IOrderUpdateTableData) => {
    const newDateSolClientAux = item.newDateSolClient ? item.newDateSolClient : '';
    const newQtdSolClientAux = item.newQtdSolClient ? item.newQtdSolClient : '';
    const canceledAux = item.cancelOrder;

    if (!newDateSolClientAux && !newQtdSolClientAux && !canceledAux) {
      hasInvalidOrderToUpdade = true;
    }
  });

  return hasInvalidOrderToUpdade;
};
