import { Storage } from './storage';
import { getStorage, StorageType } from './storage.provider';

const localStorage = getStorage(StorageType.Local);
const sessionStorage = getStorage(StorageType.Session);

/**
 * Decorator to make a property automatically bound to a browser local storage.
 * The first argument is the storage key.
 *
 * ```typescript
 * import { LocalStorage } from '@app/data/storage'
 *
 * class NeedsLocalStorage {
 *   @LocalStorage('my-prop-stored-in-local-storage')
 *   private myProp: any;
 *
 *   constructor() {}
 * }
 * ```
 */
export function LocalStorage(key: string) {
  return decorateWithStorage(key, localStorage);
}

/**
 * Decorator to make a property automatically bound to a browser session storage.
 * The first argument is the storage key.
 *
 * ```typescript
 * import { SessionStorage } from '@app/data/storage'
 *
 * class NeedsSessionStorage {
 *   @SessionStorage('my-prop-stored-in-session-storage')
 *   private myProp: any;
 *
 *   constructor() {}
 * }
 * ```
 */
export function SessionStorage(key: string) {
  return decorateWithStorage(key, sessionStorage);
}

function decorateWithStorage(key: string, storage: Storage) {
  return (_target: any, _propKey: string, descriptor?: any) => {
    if (!descriptor) {
      return;
    }
    delete descriptor.initializer;
    delete descriptor.writable;
    descriptor.get = () => storage.get(key);
    descriptor.set = value => storage.put(key, value);

    return descriptor;
  };
}
