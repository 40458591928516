const atendimentoComercial = [];
const atrasoDeEntrega = [];
const desistenciaDaCompra = [
  'Carregamento - Atraso',
  'Cliente efetuou pedido errado',
  'Divergência de preço',
  'Erro de não cancelamento pedido',
  'Falha de sistema - Incluir número de Chamado',
  'Falta de informações no pedido',
  'Faturamento fora do prazo - antecipação',
  'Faturamento fora do prazo - atraso',
  'Outras causas Comerciais',
  'Outras causas de Qualidade',
  'Outras causas Logística',
  'Outros motivos para desistência',
  'Pedido duplicado',
];
const enderecoDeEntrega = [
  'Cliente efetuou pedido errado',
  'Divergência no código do cliente (BP)',
  'Divergência no tipo da ordem',
  'Falha de sistema - Incluir número de Chamado',
  'Falta de informações no pedido',
  'Outras causas Logística',
  'Transportadora - Material entregue em local errado',
];
const portfolioProduto = [];
const prazoDeEntrega = [
  'Atraso no Trajeto - Quebra de Veículo',
  'Erro na geração de remessa',
  'Faturamento fora prazo - antecip/atraso',
];
const prazoDePagamento = [
  'Carregamento - Atraso',
  'Dificuldade de caixa do cliente',
  'Divergência no prazo de pagamento',
  'Erro de não cancelamento pedido',
  'Falha de sistema - Incluir número de Chamado',
  'Faturamento fora do prazo - antecipação',
  'Faturamento fora do prazo - atraso',
  'Outras causas Comerciais',
  'Outras causas Logística',
  'Pedido duplicado',
  'Divergências nas Condições Comerciais',
];
const preco = ['Diferença de preço', 'Erro no registro do desconto adicional', 'Divergências nas Condições Comerciais'];
const precoErrado = [
  'Divergência de desconto ou extra',
  'Divergência de preço',
  'Divergências Fiscais',
  'Falha de sistema - Incluir número de Chamado',
  'Outras causas Comerciais',
];
const produtoComDefeito = [
  'Carepa',
  'Carregamento - Avaria na carga',
  'Composição Química',
  'Defeitos internos',
  'Defeitos superficiais',
  'Dimensional',
  'Embalagem',
  'Falha de sistema - Incluir número de Chamado',
  'Forma',
  'Identificação',
  'Material molhado',
  'Mistura',
  'Outras causas de Qualidade',
  'Oxidação',
  'Peso',
  'Propriedade Mecânica',
  'Transportadora - Material Danificado',
  'Trincas',
];
const produtoErrado = [
  'Carregamento - Troca de Produto',
  'Cliente efetuou pedido errado',
  'Divergência no código de material do pedido',
  'Falha de sistema - Incluir número de Chamado',
  'Falta de informações no pedido',
  'Identificação',
  'Outras causas de Qualidade',
  'Outras causas Logística',
  'Transportadora - Troca de Produto na Entrega',
];
const qualidadeProduto = [];
const quantidadePesoDivergente = [
  'Carregamento - Falta de Produto',
  'Carregamento - Material em Excesso',
  'Cliente efetuou pedido errado',
  'Diferença de quantidade',
  'Erro de não cancelamento pedido',
  'Falha de sistema - Incluir número de Chamado',
  'Falta de informações no pedido',
  'Faturamento - Quantidade incorreta',
  'Outras causas de Qualidade',
  'Outras causas Logística',
  'Pedido duplicado',
  'Transportadora - Diferença de Peso no Trajeto',
  'Transportadora - Troca de Produto na Entrega',
];
const recebimentoAntecipadoAtrasado = [
  'Carregamento - Restrições logística cliente',
  'Cliente efetuou pedido errado',
  'Falha de sistema - Incluir número de Chamado',
  'Falta de informações no pedido',
  'Faturamento fora do prazo - antecipação',
  'Faturamento fora do prazo - atraso',
  'Outras causas Logística',
  'Transportadora - Atendimento ao cliente',
  'Transportadora - Material entregue em local errado',
];
const outros = [
  'Carepa',
  'Carregamento - Atraso',
  'Carregamento - Avaria na carga',
  'Carregamento - Falta de Produto',
  'Carregamento - Material em Excesso',
  'Carregamento - Restrições logística cliente',
  'Carregamento - Troca de Produto',
  'Cliente efetuou pedido errado',
  'Composição Química',
  'Defeitos internos',
  'Defeitos superficiais',
  'Diferença de quantidade',
  'Dificuldade de caixa do cliente',
  'Dimensional',
  'Divergência de desconto ou extra',
  'Divergência de preço',
  'Divergência no código de material do pedido',
  'Divergência no código do cliente (BP)',
  'Divergência no prazo de pagamento',
  'Divergência no tipo da ordem',
  'Divergências Fiscais',
  'Embalagem',
  'Erro de não cancelamento pedido',
  'Falha de sistema - Incluir número de Chamado',
  'Falha no certificado de qualidade',
  'Falta de informações no pedido',
  'Faturamento fora do prazo - antecipação',
  'Faturamento fora do prazo - atraso',
  'Faturamento - Quantidade incorreta',
  'Forma',
  'Identificação',
  'Material molhado',
  'Mistura',
  'Outras causas Comerciais',
  'Outras causas de Qualidade',
  'Outras causas Logística',
  'Outros',
  'Outros motivos para desistência',
  'Oxidação',
  'Pedido duplicado',
  'Peso',
  'Propriedade Mecânica',
  'Sinistro - Acidente no trajeto',
  'Sinistro - Roubo da carga',
  'Transportadora - Atendimento ao cliente',
  'Transportadora - Atraso na Entrega',
  'Transportadora - Diferença de Peso no Trajeto',
  'Transportadora - Material Danificado',
  'Transportadora - Material entregue em local errado',
  'Transportadora - Troca de Produto na Entrega',
  'Trincas',
  'Outras causas',
];
const outrosTrocaDevolucao = [
  'Embalagem',
  'Erro de não cancelamento pedido',
  'Falha de sistema - Incluir número de Chamado',
  'Falha no certificado de qualidade',
  'Identificação',
  'Outras causas Comerciais',
  'Outras causas de Qualidade',
  'Outras causas Logística',
  'Transportadora - Atendimento ao cliente',
  'Transportadora - Atraso na Entrega',
];
const outrosCondComercial = [
  'Divergência no tipo da ordem',
  'Erro de não cancelamento pedido',
  'Falha de sistema - Incluir número de Chamado',
  'Falha no certificado de qualidade',
  'Outras causas Comerciais',
  'Outras causas de Qualidade',
  'Outras causas Logística',
  'Pedido duplicado',
  'Sinistro - Acidente no trajeto',
  'Sinistro - Roubo da carga',
];
const comercial = [
  'Diferença de quantidade',
  'Dificuldade de caixa do cliente',
  'Divergência de desconto ou extra',
  'Divergência de preço',
  'Divergência no código de material do pedido',
  'Divergência no código do cliente (BP)',
  'Divergência no prazo de pagamento',
  'Divergência no tipo da ordem',
  'Divergências Fiscais',
  'Erro de não cancelamento pedido',
  'Falha de sistema - Incluir número de Chamado',
  'Falta de informações no pedido',
  'Faturamento fora do prazo - antecipação',
  'Outras causas Comerciais',
  'Pedido duplicado',
];
const logistica = [
  'Carregamento - Atraso',
  'Carregamento - Avaria na carga',
  'Carregamento - Falta de Produto',
  'Carregamento - Material em Excesso',
  'Carregamento - Restrições logística cliente',
  'Carregamento - Troca de Produto',
  'Faturamento - Quantidade incorreta',
  'Outras causas Logística',
  'Sinistro - Acidente no trajeto',
  'Sinistro - Roubo da carga',
];
const qualidade = [
  'Carepa',
  'Composição Química',
  'Defeitos internos',
  'Defeitos superficiais',
  'Dimensional',
  'Embalagem',
  'Falha no certificado de qualidade',
  'Forma',
  'Identificação',
  'Material molhado',
  'Mistura',
  'Outras causas de Qualidade',
  'Oxidação',
  'Peso',
  'Propriedade Mecânica',
  'Trincas',
];
const entradaDePedido = [
  'Erro de não cancelamento pedido',
  'Erro de quantidade',
  'Erro no código de item',
  'Erro no código do cliente',
  'Erro no tipo de ordem',
  'Pedido implantado incorretam/duplicado',
];
const marketing = ['Antecipação do faturamento'];
const definicaoDoProcessoComercial = [
  'Erro de abertura - Comercial',
  'Erro de abertura - Técnico',
  'Restrições logística',
  'Sinistro com Atend. Prazo - Cliente',
];
const concessaoComercial = ['Cancelamento do pedido após embarque', 'Estoque elevado do cliente'];
const improcedente = ['Improcedente'];
const produtoTrocado = [
  'Erro no Carregamento -Troca de Produto',
  'Erro no Faturamento - Produto Trocado',
  'Transportadora-Troca de Prod. na Entrega',
];
const quantidadeIncorreta = ['Erro no Carregamento-Material em Excesso'];
const identificacao = ['Erro Carregamento - Identif. Inadequada'];
const produtoDanificado = [
  'Danos no Transporte - Material Molhado',
  'Danos no Transporte - Oxidadção/Inv Térm',
  'Danos no Transporte-Material Danificado',
  'Erro carregamento - Armazenamento inadeq',
  'Erro carregamento - Avaria no Carregam.',
];
const sinistro = [
  'Sinistro - Acidente no trajeto',
  'Sinistro - Extravio de Carga',
  'Sinistro - Furto Evidenciado de Carga',
  'Sinistro - Roubo de Carga',
];
const causasOperacionais = ['Erro de inspeção', 'Erro na especificação'];
const composicaoQuimica = ['Fora de spec - Composição Química', 'Heterogeneidade da composição química'];
const dimensoes = ['Dimensão fora da especificação', 'Massa linear fora da especificação'];
const embalagem = [
  'Cintas/amarras fora da especificação',
  'Embalagem fora da especificação',
  'Erro de identificação/etiqueta',
];
const ensaiosFisicos = [
  'Baixa carga de desenrolamento',
  'Charpy fora da especificação',
  'Dobra fora da especificação',
  'Dureza fora da especificação',
  'DWTT fora da especificação',
  'Ensaio de tração fora de especificação',
  'Prova de escada fora de especificação',
  'Ruptura do material',
  'Temperabilidade fora da especificação',
  'Teste de recalque fora de especificação',
];
const forma = [
  'Alongamento',
  'Amassado/Enrugado',
  'Bobina/rolo com defeito',
  'Corte irregular',
  'Deformado/abaulado',
  'Esquadros',
  'Estribo fora de especificação',
  'Expansão',
  'Franges diferentes',
  'Friso',
  'Nervuras fora de espaçamento',
  'Paralelismo',
  'Prego sem cabeça',
  'Romboidade',
  'Torção',
];
const interno = [
  'Agarramento',
  'Descarbonetação',
  'Fissuras/Tricas internas',
  'Grãos fora de especificação',
  'Inclusões',
  'Microestructura inadequada',
  'Outras causas',
  'Petrificação',
  'Rechupe/vazio',
  'Segregação',
];
const peso = ['Peso'];
const soldagem = ['Solda fora de especificação'];
const superficiais = [
  'Oxidação',
  'Aderência da Camada de Zinco',
  'Esfoliação',
  'Incrustações',
  'Manchas',
  'Rebarbas',
  'Trincas/Rachaduras',
  'Marcas',
];

export const QuoteComplainModalStrings = {
  pt: {
    clientedeseja: [
      'Alterar Condição Comercial',
      'Crédito/Reembolso',
      'Dúvidas',
      'Elogio',
      'Fluxo Interno',
      'Outros',
      'Reclamação',
      'Sugestão',
      'Troca/Devolução',
    ],
    motivoOptions: [
      ['Prazo de pagamento', 'Preço', 'Preço errado', 'Outros Cond. Comercial'],
      [
        'Prazo de pagamento',
        'Preço errado',
        'Produto com defeito',
        'Produto errado',
        'Quantidade/Peso divergente',
        'Recebimento antecipado/atrasado',
        'Outros',
        'Marketing',
      ],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
      ],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
      ],
      ['Comercial', 'Logística', 'Qualidade'],
      ['Outros'],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
        'Entrada de pedido',
        'Definição do Processo Comercial',
        'Concessão Comercial',
        'Improcedente',
        'Produto Trocado',
        'Quantidade Incorreta',
        'Identificação',
        'Produto Danificado',
        'Sinistro',
        'Causas Operacionais',
        'Composição Química',
        'Dimensões',
        'Embalagem',
        'Ensaios Físicos',
        'Forma',
        'Interno',
        'Peso',
        'Soldagem',
        'Superficiais',
      ],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
      ],
      [
        'Desistência da compra',
        'Endereço de entrega',
        'Preço errado',
        'Produto com defeito',
        'Produto errado',
        'Quantidade/Peso divergente',
        'Recebimento antecipado/atrasado',
        'Outros Troca/Devolução',
      ],
    ],
    justificativaOptions: [
      [prazoDePagamento, preco, precoErrado, outrosCondComercial],
      [
        prazoDePagamento,
        precoErrado,
        produtoComDefeito,
        produtoErrado,
        quantidadePesoDivergente,
        recebimentoAntecipadoAtrasado,
        outros,
        marketing,
      ],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
      ],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
      ],
      [comercial, logistica, qualidade],
      [outros],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
        entradaDePedido,
        definicaoDoProcessoComercial,
        concessaoComercial,
        improcedente,
        produtoTrocado,
        quantidadeIncorreta,
        identificacao,
        produtoDanificado,
        sinistro,
        causasOperacionais,
        composicaoQuimica,
        dimensoes,
        embalagem,
        ensaiosFisicos,
        forma,
        interno,
        peso,
        soldagem,
        superficiais,
      ],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
      ],
      [
        desistenciaDaCompra,
        enderecoDeEntrega,
        precoErrado,
        produtoComDefeito,
        produtoErrado,
        quantidadePesoDivergente,
        recebimentoAntecipadoAtrasado,
        outrosTrocaDevolucao,
      ],
    ],
    priority: ['Baixa', 'Média', 'Alta', 'Crítica'],
    modalDownload: {
      title: 'Informe o período',
      from: 'De: ',
      to: 'Até: ',
      btnSearch: 'Buscar',
      noData: 'Nenhum item encontrado',
      loading: 'Carregando...',
      errorData: 'Ocorreu um erro ao buscar a lista de clientes',
      errorDataCases: 'Ocorreu um erro ao buscar as manifestações. Tente novamente',
      errorDateField: 'Data inválida',
      cancel: 'Cancelar',
      filename: 'Manifestacoes',
      total: ' registros encontrados.',
    },
    destino: ['Reprocesso', 'Sucateamento', 'Avaliação EPP', 'Avaliação Garantia da Qualidade'],
  },
  in: {
    clientedeseja: [
      'Alterar Condição Comercial',
      'Crédito/Reembolso',
      'Dúvidas',
      'Elogio',
      'Fluxo Interno',
      'Outros',
      'Reclamação',
      'Sugestão',
      'Troca/Devolução',
    ],
    motivoOptions: [
      ['Prazo de pagamento', 'Preço', 'Preço errado', 'Outros Cond. Comercial'],
      [
        'Prazo de pagamento',
        'Preço errado',
        'Produto com defeito',
        'Produto errado',
        'Quantidade/Peso divergente',
        'Recebimento antecipado/atrasado',
        'Outros',
        'Marketing',
      ],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
      ],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
      ],
      ['Comercial', 'Logística', 'Qualidade'],
      ['Outros'],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
        'Entrada de pedido',
        'Definição do Processo Comercial',
        'Concessão Comercial',
        'Improcedente',
        'Produto Trocado',
        'Quantidade Incorreta',
        'Identificação',
        'Produto Danificado',
        'Sinistro',
        'Causas Operacionais',
        'Composição Química',
        'Dimensões',
        'Embalagem',
        'Ensaios Físicos',
        'Forma',
        'Interno',
        'Peso',
        'Soldagem',
        'Superficiais',
      ],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
      ],
      [
        'Desistência da compra',
        'Endereço de entrega',
        'Preço errado',
        'Produto com defeito',
        'Produto errado',
        'Quantidade/Peso divergente',
        'Recebimento antecipado/atrasado',
        'Outros Troca/Devolução',
      ],
    ],
    justificativaOptions: [
      [prazoDePagamento, preco, precoErrado, outrosCondComercial],
      [
        prazoDePagamento,
        precoErrado,
        produtoComDefeito,
        produtoErrado,
        quantidadePesoDivergente,
        recebimentoAntecipadoAtrasado,
        outros,
        marketing,
      ],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
      ],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
      ],
      [comercial, logistica, qualidade],
      [outros],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
        entradaDePedido,
        definicaoDoProcessoComercial,
        concessaoComercial,
        improcedente,
        produtoTrocado,
        quantidadeIncorreta,
        identificacao,
        produtoDanificado,
        sinistro,
        causasOperacionais,
        composicaoQuimica,
        dimensoes,
        embalagem,
        ensaiosFisicos,
        forma,
        interno,
        peso,
        soldagem,
        superficiais,
      ],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
      ],
      [
        desistenciaDaCompra,
        enderecoDeEntrega,
        precoErrado,
        produtoComDefeito,
        produtoErrado,
        quantidadePesoDivergente,
        recebimentoAntecipadoAtrasado,
        outrosTrocaDevolucao,
      ],
    ],
    priority: ['Baixa', 'Média', 'Alta', 'Crítica'],
    modalDownload: {
      title: 'Informe o período',
      from: 'De: ',
      to: 'Até: ',
      btnSearch: 'Buscar',
      noData: 'Nenhum item encontrado',
      loading: 'Carregando...',
      errorData: 'Ocorreu um erro ao buscar a lista de clientes',
      errorDataCases: 'Ocorreu um erro ao buscar as manifestações. Tente novamente',
      errorDateField: 'Data inválida',
      cancel: 'Cancelar',
      filename: 'Manifestacoes',
      total: ' registros encontrados.',
    },
    destino: ['Reprocesso', 'Sucateamento', 'Avaliação EPP', 'Avaliação Garantia da Qualidade'],
  },
  es: {
    clientedeseja: [
      'Alterar Condição Comercial',
      'Crédito/Reembolso',
      'Dúvidas',
      'Elogio',
      'Fluxo Interno',
      'Outros',
      'Reclamação',
      'Sugestão',
      'Troca/Devolução',
    ],
    motivoOptions: [
      ['Prazo de pagamento', 'Preço', 'Preço errado', 'Outros Cond. Comercial'],
      [
        'Prazo de pagamento',
        'Preço errado',
        'Produto com defeito',
        'Produto errado',
        'Quantidade/Peso divergente',
        'Recebimento antecipado/atrasado',
        'Outros',
        'Marketing',
      ],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
      ],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
      ],
      ['Comercial', 'Logística', 'Qualidade'],
      ['Outros'],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
        'Entrada de pedido',
        'Definição do Processo Comercial',
        'Concessão Comercial',
        'Improcedente',
        'Produto Trocado',
        'Quantidade Incorreta',
        'Identificação',
        'Produto Danificado',
        'Sinistro',
        'Causas Operacionais',
        'Composição Química',
        'Dimensões',
        'Embalagem',
        'Ensaios Físicos',
        'Forma',
        'Interno',
        'Peso',
        'Soldagem',
        'Superficiais',
      ],
      [
        'Atendimento Comercial',
        'Atraso de entrega',
        'Portfólio Produto',
        'Prazo de entrega',
        'Prazo de pagamento',
        'Preço',
        'Qualidade Produto',
        'Outros',
      ],
      [
        'Desistência da compra',
        'Endereço de entrega',
        'Preço errado',
        'Produto com defeito',
        'Produto errado',
        'Quantidade/Peso divergente',
        'Recebimento antecipado/atrasado',
        'Outros Troca/Devolução',
      ],
    ],
    justificativaOptions: [
      [prazoDePagamento, preco, precoErrado, outrosCondComercial],
      [
        prazoDePagamento,
        precoErrado,
        produtoComDefeito,
        produtoErrado,
        quantidadePesoDivergente,
        recebimentoAntecipadoAtrasado,
        outros,
        marketing,
      ],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
      ],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
      ],
      [comercial, logistica, qualidade],
      [outros],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
        entradaDePedido,
        definicaoDoProcessoComercial,
        concessaoComercial,
        improcedente,
        produtoTrocado,
        quantidadeIncorreta,
        identificacao,
        produtoDanificado,
        sinistro,
        causasOperacionais,
        composicaoQuimica,
        dimensoes,
        embalagem,
        ensaiosFisicos,
        forma,
        interno,
        peso,
        soldagem,
        superficiais,
      ],
      [
        atendimentoComercial,
        atrasoDeEntrega,
        portfolioProduto,
        prazoDeEntrega,
        prazoDePagamento,
        preco,
        qualidadeProduto,
        outros,
      ],
      [
        desistenciaDaCompra,
        enderecoDeEntrega,
        precoErrado,
        produtoComDefeito,
        produtoErrado,
        quantidadePesoDivergente,
        recebimentoAntecipadoAtrasado,
        outrosTrocaDevolucao,
      ],
    ],
    priority: ['Baixa', 'Média', 'Alta', 'Crítica'],
    modalDownload: {
      title: 'Informe o período',
      from: 'De: ',
      to: 'Até: ',
      btnSearch: 'Buscar',
      noData: 'Nenhum item encontrado',
      loading: 'Carregando...',
      errorData: 'Ocorreu um erro ao buscar a lista de clientes',
      errorDataCases: 'Ocorreu um erro ao buscar as manifestações. Tente novamente',
      errorDateField: 'Data inválida',
      cancel: 'Cancelar',
      filename: 'Manifestacoes',
      total: ' registros encontrados.',
    },
    destino: ['Reprocesso', 'Sucateamento', 'Avaliação EPP', 'Avaliação Garantia da Qualidade'],
  },
};
