import React from 'react';
import { useStore } from '@app/core/global-store.service';
import { UserStore, useUserStore } from '@app/providers';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { InpsModalStrings, Strings } from '../../nps.string';

const npsHeaderLanguagesImg = {
  pt: <Image.NpsPtHeader />,
  es: <Image.NpsEsHeader />,
  in: <Image.NpsInHeader />,
};

export const NpsModalHeader: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const strings: InpsModalStrings = Strings[user.language];

  return (
    <FlexColumn>
      <FlexRow hAlign='center'>
        <H2>{strings.title}</H2>
      </FlexRow>
      <FlexRow mt hAlign='center'>
        {npsHeaderLanguagesImg[user.language]}
      </FlexRow>
    </FlexColumn>
  );
};
