import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';

export const OrderInputUploadFileWrapper = styled(FlexRow)`
  width: 85%;
  justify-content: flex-start;
`;

export const SpreasheetFileWrapperStyled = styled.div`
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px 20px 10px;
  border: 1px solid ${Color.Gray};
  border-radius: 5px;
  margin: 0;
  & > div {
    justify-content: center;
    text-align: center;
  }
`;
