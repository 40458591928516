import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { FlexColumn } from '@atomic/obj.box';
import { Modal } from '@atomic/obj.modal';
import { setOpenConfigColumnSpreadsheet } from '../../store/order-input.store';
import { getSpreadsheetLayoutThunk } from '../../store/thunks/get-spreadsheet-layout.thunk';
import { ConfigColumnsModalBody } from './partials/config-columns-modal-body.componet';
import { ConfigColumnsModalFooter } from './partials/config-columns-modal-footer.component';
import { ConfigColumnsModalHeader } from './partials/config-columns-modal-header.component';

export const ConfigColumnsModal: React.FC = () => {
  const { openConfigColumnSpreadsheet } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    dispatch(getSpreadsheetLayoutThunk());
  }, []);

  return (
    <Modal
      testid='config-columns-modal'
      medium
      opened={openConfigColumnSpreadsheet}
      onClose={() => dispatch(setOpenConfigColumnSpreadsheet(false))}
    >
      <FlexColumn pl pr pb>
        <ConfigColumnsModalHeader />
        <ConfigColumnsModalBody />
        <ConfigColumnsModalFooter />
      </FlexColumn>
    </Modal>
  );
};
