import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2, Label } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { CrmStrings } from '../../crm.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

export const CrmManufacturingConsultation = props => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.MANUFACTURING_CONSULTATION} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>
      <CrmContentInfosWrapper gridRepeat={2}>
        {props?.data && (
          <>
            <Hbox>
              <Separator small />
              {props.data.infos.map(
                (item, index) =>
                  index < 10 && (
                    <Body key={index}>
                      <Label>{strings.fields[item.label]} </Label>
                      {item.value}
                    </Body>
                  ),
              )}
              <Separator small />
            </Hbox>
            <Hbox>
              <Separator small />
              {props.data.infos.map(
                (item, index) =>
                  index >= 10 && (
                    <Body key={index}>
                      <Label>{strings.fields[item.label]} </Label>
                      {item.value}
                    </Body>
                  ),
              )}
              <Separator small />
            </Hbox>
          </>
        )}
      </CrmContentInfosWrapper>
    </CrmContentWrapper>
  );
};
