import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, LabelList, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '@app/core/redux/store';
import { DashboardDataSource } from '@app/data/http/dashboard.datasource';
import { useGetDashboard } from '@app/domain/get-dashboard.use-case';
import { BilledOrderGraph, DashboardLegendData } from '@app/models/dashboard.model';
import { setReloadBilledDashboard } from '@app/providers/navigation/navigation.store';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Frame } from '@atomic/atm.frame';
import { Body, H2, H3 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { DashboardBlockHeader } from './dashboard-block-header.component';
import { DashboardBlockShimmer } from './dashboard-block.shimmer';
import { Strings, IDashBoardStrings } from './dashboard.string';
import { DashboardProps } from '.';

const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = '95%';

const BILLED_DASHBOARD_DATA = 'billed_dashboard_data';
const DO_REQUESTS_FOR_DASHBOARDS = 'do_requests_for_dashboards';

export const BilledDashboard: React.FC<DashboardProps> = props => {
  const strings: IDashBoardStrings = Strings.pt;
  const billedOrdersStrings = strings.graphs.billedOrders;

  const { reloadBilledDashboard } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();

  const [billedDashboard, setBilledDashboardData] = useLocalStorageState(BILLED_DASHBOARD_DATA, {});
  const doRequestForDashboards = window.localStorage.getItem(DO_REQUESTS_FOR_DASHBOARDS);

  const { data, loading, error, performRequest } = useGetDashboard<BilledOrderGraph>(
    DashboardDataSource.getBilledOrders,
    props.onSuccess,
  );

  const getMonthLegend = () => {
    const monthLegend = [];

    if (data) {
      data.monthly.forEach(item => {
        monthLegend.push({
          title: item.monthName,
          amount: item.billedQty,
          unit: 't',
        });
      });
    }

    return monthLegend;
  };

  const monthlyBilledQtyLegend: DashboardLegendData[] = [{ title: billedOrdersStrings.total }, ...getMonthLegend()];

  useEffect(() => {
    if (reloadBilledDashboard) {
      performRequest(props.clientNumber);
      dispatch(setReloadBilledDashboard(false));
    }
  }, [reloadBilledDashboard]);

  useEffect(() => {
    if (props.clientNumber && doRequestForDashboards === 'true') {
      performRequest(props.clientNumber);
      dispatch(setReloadBilledDashboard(false));
    }
  }, [props.clientNumber, doRequestForDashboards]);

  useEffect(() => {
    if (data) {
      setBilledDashboardData(data);
    }
  }, [data]);

  const renderFirstItem = item => {
    return (
      <FlexColumn vAlign='center' style={{ alignItems: 'center', position: 'absolute', left: '-15px' }}>
        <FlexColumn vAlign='center' style={{ alignItems: 'center' }}>
          <FlexRow style={{ width: '80px', justifyContent: 'center' }}>
            <Body>{item.title}</Body>
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    );
  };

  const renderSecondItem = item => {
    return (
      <FlexColumn vAlign='center' style={{ alignItems: 'center', left: '16px' }}>
        <FlexColumn vAlign='center' style={{ alignItems: 'center' }}>
          <FlexRow style={{ width: '80px', justifyContent: 'center' }}>
            <Body>{item.title}</Body>
          </FlexRow>
          <FlexRow>
            <H3>{item.amount + 't'}</H3>
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    );
  };

  const renderItens = (item, index) => {
    if (index === 0) {
      return renderFirstItem(item);
    } else if (index === 1) {
      return renderSecondItem(item);
    } else {
      return (
        <FlexColumn vAlign='center' style={{ width: '80px!', alignItems: 'center' }}>
          <FlexColumn vAlign='center' style={{ width: '80px!', alignItems: 'center' }}>
            <FlexRow style={{ width: '80px', justifyContent: 'center' }}>
              <Body>{item.title}</Body>
            </FlexRow>
            <FlexRow>
              <H3>{item.amount + 't'}</H3>
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      );
    }
  };

  return (
    <LoadingState loading={loading} data={!!billedDashboard} error={!!error}>
      <LoadingState.Shimmer>
        <DashboardBlockShimmer blocks={1} />
        <VSeparator />
        <DashboardBlockShimmer blocks={1} />
      </LoadingState.Shimmer>

      {!!billedDashboard && (
        <div key={props.screenWidth}>
          <H2 center>{strings.billed.title}</H2>
          <Frame height='415px'>
            <VSeparator />

            <DashboardBlockHeader title={strings.billed.monthly} description={strings.billed.monthlyDescription} />
            <VSeparator />

            <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
              <LineChart data={billedDashboard?.monthly}>
                <Line
                  type='linear'
                  dataKey='billedQty'
                  name={billedOrdersStrings.billedQty}
                  dot={{ strokeWidth: 15 }}
                />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip />
              </LineChart>
            </ResponsiveContainer>
            {data && data.monthly && data.monthly.length ? (
              <FlexRow style={{ position: 'absolute', width: '98%' }}>
                {monthlyBilledQtyLegend.map((item, index) => (
                  <React.Fragment key={index}>{renderItens(item, index)}</React.Fragment>
                ))}
              </FlexRow>
            ) : null}
            <VSeparator />
          </Frame>
          <VSeparator small />

          <Frame>
            <VSeparator />

            <DashboardBlockHeader title={strings.billed.daily} description={strings.billed.dailyDescription} />
            <VSeparator />

            <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
              <BarChart data={billedDashboard?.daily}>
                <XAxis dataKey='date' />
                <YAxis domain={[0, datamax => Math.floor(datamax * 1.3)]} />
                <Tooltip />
                <Bar
                  dataKey='billedQty'
                  isAnimationActive={false}
                  name={billedOrdersStrings.billedQty}
                  stackId='a'
                  fill={Color.Primary}
                  radius={4}
                >
                  <LabelList
                    dataKey='billedQty'
                    position='insideTop'
                    fill={Color.White}
                    formatter={value => value + 't'}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Frame>
        </div>
      )}
    </LoadingState>
  );
};
