export interface DocumentResponse {
  docNum?: string;
  xml?: string;
  id?: number;
  status?: string;
  base64: string;
}

export interface IDocumentCQParams {
  ov: string;
  itemOv: string;
  numDelivery: string;
  numDeliveryItem: string;
  customerIds: string;
}

export interface IDocumentMECQParams {
  deliveryDocument: string;
  deliveryDocumentItem: string;
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const documentResponseToBlob = (response: DocumentResponse): string => {
  if (!response.base64) {
    return null;
  }
  const blob = b64toBlob(response.base64, 'application/pdf');

  const fileURL = URL.createObjectURL(blob);
  return fileURL;
};

export const spreadsheetResponseToBlob = (response: DocumentResponse): string => {
  if (!response.base64) {
    return null;
  }

  const blob = b64toBlob(
    response.base64,
    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
  );

  const fileURL = URL.createObjectURL(blob);
  return fileURL;
};
