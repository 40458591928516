import { datadogRum } from '@datadog/browser-rum';
import * as QueryString from 'query-string';
import Container from 'typedi';
import { AppConfig } from '@app/configure-app';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { UserInfo } from '@app/models';
import { AppPath } from '@app/modules/app/route-constants';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { AppWindow } from '@app/utils/interfaces';
import { IAuthState } from '../../auth.interfaces';
import { handleLogUser, redirect } from '../../utils/utils';
import { hasGsbAccessSelector } from '../auth.selectores';
import { getProfile } from './user-profile.thunk';

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const initializeAndGetProfileThunk = (token: string) => async (dispatch, getState) => {
  const { ssoAccess }: IAuthState = getState().auth;

  await dispatch(getProfile({ token, ssoAccess }));

  const { userInfo }: IAuthState = getState().auth;
  const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;
  const queryString = QueryString.parse(location.search);
  const hasGsbAccess = hasGsbAccessSelector(getState());

  handleLogUser({ uid: userInfo.oktaUid, isEmployee: true }, userInfo.applications);

  if (config.qa || config.prod) {
    datadogRum.setUser({ id: userInfo.oktaUid });
  }

  const redirectInternalPath = queryString.page ? queryString.page : AppPath.CACHE_ROUTE;

  if (userInfo?.oktaUid && hasGsbAccess) {
    authStorageService.login(buildUserData(token, userInfo, ssoAccess));
    const path = redirectInternalPath.indexOf('/auth') > -1 ? AppPath.CACHE_ROUTE : redirectInternalPath;
    redirect(path);
  } else {
    dispatch(
      setToast({
        show: true,
        text: 'Você não tem perfil para acessar o sistema Gerdau Mais, entre em contato com seu administrador',
        variant: ToastVariant.DANGER,
      }),
    );
  }

  return;
};

export const buildUserData = (token: string, userInfo: UserInfo, ssoAccess: boolean) => {
  const userInfoData = {
    uid: userInfo.oktaUid,
    userName: userInfo.name,
    email: userInfo.email,
    accessToken: token,
    language: userInfo.language,
    ssoAccess: ssoAccess,
    role: userInfo.profile.profile,
  };

  return userInfoData;
};
