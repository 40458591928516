import { FormOptions2Dimensions, FormOptions1Dimensions } from '../../quote.string';

export const mapFormOptions = (keyValue: string, list: any[], language: string) => {
  switch (keyValue) {
    case 'RETANGULAR':
    case 'CHATO':
      return FormOptions2Dimensions[language];
    case 'REDONDO':
    case 'QUADRADO':
    case 'SEXTAVADO':
      return FormOptions1Dimensions[language];
    default:
      return list;
  }
};
