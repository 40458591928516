import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { AnnouncementModalDatasource } from '@app/data/http/announcement-modal-datasource';
import { IPutAnnouncementParams } from '@app/data/http/announcement.dto';
import { AnnouncementModalEvents } from '@app/modules/components/announcement-modal/announcement-modal.analytics';
import { SLICE_NAME } from '../lack-of-charge-modal.constants';

export const putLackOfChargeModal = createAsyncThunk(
  `${SLICE_NAME}/putLackOfChargeModal`,
  async (announcementParams: IPutAnnouncementParams, thunkAPI) => {
    return AnnouncementModalDatasource.putAnnouncement(announcementParams)
      .then(resp => resp)
      .catch(err => {
        LogAnalytics.error({
          tipo: AnnouncementModalEvents.PutLackOfChargeAnnouncementeError,
          message: err.response.status,
        });
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addPutLackOfChargeReducers = builder => {
  builder.addCase(putLackOfChargeModal.pending, state => {
    state.loading = true;
  });
  builder.addCase(putLackOfChargeModal.fulfilled, (state, _) => {
    state.announcements = [];
    state.opened = false;
    state.loading = false;
    state.hasLackOfChargeAnnouncement = false;
  });
  builder.addCase(putLackOfChargeModal.rejected, state => {
    state.loading = false;
  });
};
