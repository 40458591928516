import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { DropzoneStyled, DropzoneStyledLink } from '@atomic/atm.dropzone/dropzone.component.style';
import { DropzoneStrings } from '@atomic/atm.dropzone/dropzone.string';
import { Body } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';

interface ICustomDropZoneProps {
  showDropzoneMessage: boolean;
}

export const CustomDropZone: React.FC<ICustomDropZoneProps> = ({ showDropzoneMessage }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  return (
    <DropzoneStyled height='100px'>
      {showDropzoneMessage && (
        <FlexColumn vAlign='center' hAlign='center'>
          <Body>
            <DropzoneStyledLink>{DropzoneStrings[userInfo.language].inputOrderZoneClick}</DropzoneStyledLink>&nbsp;
            {`${DropzoneStrings[userInfo.language].inputOrderZoneDrop} ${
              DropzoneStrings[userInfo.language].inputOrderGptAcceptedTypes
            }`}
          </Body>
          <Body>{DropzoneStrings[userInfo.language].inputOrderGptLimitSize}</Body>
        </FlexColumn>
      )}
    </DropzoneStyled>
  );
};
