import * as React from 'react';
import { LogAnalytics } from '@app/core/analytics';
import { useStore } from '@app/core/global-store.service';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { NotificationDatasource } from '@app/data/http/notification.datasource';
import { NotificationConfigurationItem } from '@app/models/notification-center.model';
import { NotificationConfigurationKeys } from '@app/models/notification-configuration.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { NotificationStore, useNotificationStore } from '@app/providers/notification.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H1 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { ErrorPlaceholder } from '../error.placeholder';
import { NotificationConfigurationItemCell } from './notification-configuration-item.component-cell';
import { NotificationConfigurationShimmer } from './notification-configuration.component.shimmer';
import { NotificationEvents } from './notification.Analytics';
import { Strings, INotificationCenterStrings } from './notification.string';

export interface NotificationConfigurationProps {
  loading: boolean;
  error: boolean;
  onRefetch: () => void;
  onBackClick: () => void;
}

export const NotificationConfiguration: React.FC<NotificationConfigurationProps> = props => {
  const strings: INotificationCenterStrings = Strings.pt;
  const { configuration: configStrings } = strings;

  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const { configuration, setConfiguration } = useStore<NotificationStore>(useNotificationStore);
  const { performRequest: updateConfiguration, loading: loadingUpdate } = useLazyRequest(
    NotificationDatasource.configureNotificationSettings,
    () => {
      LogAnalytics.click({ tipo: NotificationEvents.NotificationConfigItemToogle });
      show(
        'success',
        'Você alterou suas preferências de notificações, todos os seus pedidos  vão respeitar esta nova configuração.',
        true,
        true,
      );
    },
    () => show('alert', 'Erro ao alterar preferências, tente novamente mais tarde', true, true),
  );

  const handleChange = (key: string, value: boolean) => {
    if (key === NotificationConfigurationKeys.inStock && !value) {
      setConfiguration({ ...configuration, [key]: false, [NotificationConfigurationKeys.sendShippingStock]: false });
    } else {
      setConfiguration({ ...configuration, [key]: value });
    }
  };

  const notificationConfigurationlist: NotificationConfigurationItem[] = [
    {
      key: NotificationConfigurationKeys.orderConfirmation,
      title: configStrings.orderConfirmation.title,
      active: configuration?.orderConfirmation,
      description: configStrings.orderConfirmation.description,
    },
    {
      key: NotificationConfigurationKeys.startProduction,
      title: configStrings.startProduction.title,
      active: configuration?.startProduction,
      description: configStrings.startProduction.description,
    },
    {
      key: NotificationConfigurationKeys.inStock,
      title: configStrings.inStock.title,
      active: configuration?.inStock,
      description: configStrings.inStock.description,
    },
    {
      key: NotificationConfigurationKeys.steps,
      title: configStrings.steps,
      subItems: [
        {
          key: NotificationConfigurationKeys.shipCreated,
          title: configStrings.shipCreated,
          active: configuration?.shipCreated,
        },
        {
          key: NotificationConfigurationKeys.shipmentStarted,
          title: configStrings.shipmentStarted,
          active: configuration?.shipmentStarted,
        },
        { key: NotificationConfigurationKeys.billed, title: configStrings.billed, active: configuration?.billed },
      ],
    },
  ];

  return (
    <>
      <FlexRow mt vAlign='center'>
        <FlexColumn noGrow vAlign='center'>
          <FaIcon.LeftArrow disabled='disabled' clickable='true' onClick={props.onBackClick} />
        </FlexColumn>
        <FlexRow.Separator />
        <FlexColumn>
          <H1 noMargin>{configStrings.title}</H1>
        </FlexColumn>
      </FlexRow>

      <VSeparator />

      <LoadingState loading={props.loading || loadingUpdate} data={!!configuration} error={props.error}>
        <LoadingState.Shimmer>
          <NotificationConfigurationShimmer />
        </LoadingState.Shimmer>

        <LoadingState.Error>
          <ErrorPlaceholder onRetryClick={props.onRefetch} />
        </LoadingState.Error>

        <FlexRow mt>
          <FlexColumn>
            {notificationConfigurationlist.map(item => (
              <React.Fragment key={item.key}>
                <NotificationConfigurationItemCell notification={item} onChange={handleChange} />
                <VSeparator />
              </React.Fragment>
            ))}
          </FlexColumn>
        </FlexRow>

        <FlexRow mt>
          <FlexColumn pr={Spacing.Medium} noGrow>
            <Button disabled={loadingUpdate} kind='primary' onClick={() => updateConfiguration(configuration)}>
              {configStrings.saveButton}
            </Button>
          </FlexColumn>
        </FlexRow>
      </LoadingState>
    </>
  );
};
