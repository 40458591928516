import * as React from 'react';
import { FontAwesomeIconStyled } from './fa-icon.component.style';

const FA = props => <FontAwesomeIconStyled clickable={props.clickable} fixedWidth size='lg' {...props} />;

export const FaIcon = {
  Attachment: props => <FA {...props} icon='paperclip' />,
  Ban: props => <FA {...props} icon='ban' />,
  Bell: props => <FA {...props} icon={['fa', 'bell']} />,
  BarCode: props => <FA {...props} icon={['fa', 'barcode']} />,
  BellSlash: props => <FA {...props} icon={['far', 'bell-slash']} />,
  Bus: props => <FA {...props} icon='bus' />,
  Calendar: props => <FA {...props} icon='calendar' />,
  Chat: props => <FA {...props} icon='comment-dots' />,
  Check: props => <FA {...props} icon='check' />,
  ChevronDown: props => <FA {...props} icon='chevron-down' />,
  ChevronLeft: props => <FA {...props} icon='chevron-left' />,
  ChevronRight: props => <FA {...props} icon='chevron-right' />,
  ChevronUp: props => <FA {...props} icon='chevron-up' />,
  Circle: props => <FA {...props} icon='circle' />,
  Clock: props => <FA {...props} icon={['far', 'clock']} />,
  Close: props => <FA {...props} icon='times' />,
  Cog: props => <FA {...props} icon='cog' />,
  Columns: props => <FA {...props} icon='columns' />,
  Comments: props => <FA {...props} icon='comments' />,
  Crown: props => <FA {...props} icon='crown' />,
  Custom: props => <FA {...props} />,
  Dashboard: props => <FA {...props} icon='chart-line' />,
  EditUser: props => <FA {...props} icon='edit' />,
  Eye: props => <FA {...props} icon='eye' />,
  EyeSlash: props => <FA {...props} icon='eye-slash' />,
  Exchange: props => <FA {...props} icon='exchange-alt' />,
  FileCSV: props => <FA {...props} icon='file-csv' />,
  FileXML: props => <FA {...props} icon='file-xml' />,
  FileImport: props => <FA {...props} icon='file-import' />,
  FileDownload: props => <FA {...props} icon='download' />,
  FileExcel: props => <FA {...props} icon='file-excel' />,
  FileUpload: props => <FA {...props} icon='upload' />,
  Filter: props => <FA {...props} icon='filter' />,
  FlashAlert: props => <FA {...props} icon='times-circle' />,
  FlashInfo: props => <FA {...props} icon='info-circle' />,
  FlashSuccess: props => <FA {...props} icon='check-circle' />,
  FlashWarning: props => <FA {...props} icon='exclamation-circle' />,
  Heart: props => <FA {...props} icon='heart' />,
  Help: props => <FA {...props} icon='question-circle' />,
  History: props => <FA {...props} icon='history' />,
  Image: props => <FA {...props} icon='image' />,
  Industry: props => <FA {...props} icon='industry' />,
  LeftArrow: props => <FA {...props} icon='arrow-left' />,
  Measure: props => <FA {...props} icon='ruler-combined' />,
  Menu: props => <FA {...props} icon='bars' />,
  Microphone: props => <FA {...props} icon='microphone' />,
  Minus: props => <FA {...props} icon='minus' />,
  Options: props => <FA {...props} icon='ellipsis-v' />,
  PDF: props => <FA {...props} icon='file-pdf' />,
  Plane: props => <FA {...props} icon={['far', 'paper-plane']} />,
  Pen: props => <FA {...props} icon='pen' />,
  Plus: props => <FA {...props} icon='plus' />,
  Power: props => <FA {...props} icon='power-off' />,
  Question: props => <FA {...props} icon='question-circle' />,
  RightArrow: props => <FA {...props} icon='arrow-right' />,
  Search: props => <FA {...props} icon='search' />,
  Settings: props => <FA {...props} icon='users-cog' />,
  Ship: props => <FA {...props} icon='ship' />,
  Star: props => <FA {...props} icon='star' />,
  StarHalf: props => <FA {...props} icon='star-half' />,
  SlidersH: props => <FA {...props} icon='sliders-h' />,
  Toolbox: props => <FA {...props} icon='toolbox' />,
  Trash: props => <FA {...props} icon='trash' />,
  Time: props => <FA {...props} icon='clock' />,
  Truck: props => <FA {...props} icon='truck' />,
  User: props => <FA {...props} icon='user' />,
  Warning: props => <FA {...props} icon='exclamation-triangle' />,
  Save: props => <FA {...props} icon='save' />,
  News: props => <FA {...props} icon='bullhorn' />,
  Kanban: props => <FA {...props} icon='columns' />,
  Envelope: props => <FA {...props} icon='envelope' />,
  Language: props => <FA {...props} icon='globe' />,
};
