import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@atomic/atm.button';
import { Body, HDisplay } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { BrandColor, FontWeight, Spacing } from '@atomic/obj.constants';
import { IOrderUpdateStrings, Strings } from '../../order-update.string';
import { disableOrderUpdateSelector } from '../../store/order-update.selectors';
import { setFinishModalOpened } from '../../store/order-update.store';
import { Hr, HrWrapper } from './order-update-actions.style';

const strings: IOrderUpdateStrings = Strings.pt;

export const OrderUpdateActions: React.FC = () => {
  const disableOrderUpdate = useSelector(disableOrderUpdateSelector);

  const dispatch = useDispatch();

  return (
    <FlexRow mt vAlign='staret'>
      <FlexColumn noGrow>
        <HDisplay>{strings.table.title.primary}</HDisplay>
        <FlexRow>
          <Body mt>{strings.page.instructions}</Body>
          <Body mt ml={Spacing.XSmall} bold={FontWeight.Bold} color={BrandColor.Blue}>
            {strings.page.instructionColor}
          </Body>
        </FlexRow>
      </FlexColumn>
      <FlexColumn hAlign='flex-end'>
        <Button
          type='button'
          onClick={() => dispatch(setFinishModalOpened(true))}
          kind='callToAction'
          disabled={disableOrderUpdate}
        >
          {strings.page.finish}
        </Button>
        <FlexRow fullWidth>
          <HrWrapper>
            <Hr />
            <Body bold={FontWeight.Bold} color={BrandColor.Blue} mt ml mr>
              {strings.page.posibleUpdates}
            </Body>
            <Hr />
          </HrWrapper>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  );
};
