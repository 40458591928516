import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AdminPermissionsCol, PermissionTitleColCell } from '../../admin-profile-view.styles';
import { compareTabOrder } from '../../utils/utils';

export const AdminProfileTabCol: React.FC = () => {
  const [tabArray, setTabArray] = useState([]);
  const { tabChips } = useSelector((state: RootState) => state.admin);

  useEffect(() => {
    if (tabChips?.length) {
      const tabs = tabChips.slice().sort(compareTabOrder);
      setTabArray(tabs);
    }
  }, []);

  return (
    <AdminPermissionsCol>
      {tabArray.map((tab, i) => {
        if (!tab.name) {
          return <PermissionTitleColCell key={`holder-${i}`}></PermissionTitleColCell>;
        }

        return (
          <PermissionTitleColCell key={`${tab}-${i}`}>
            <Tag variant='neutral'>{tab.name}</Tag>
          </PermissionTitleColCell>
        );
      })}
    </AdminPermissionsCol>
  );
};
