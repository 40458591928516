import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { format, parse } from 'date-fns';

const dateParser = (yyyymmdd: string) => {
  let date = '';

  try {
    yyyymmdd = yyyymmdd.split('-').join('');
    date = format(parse(yyyymmdd, 'yyyyMMdd', 0), 'dd/MM/yyyy');
  } catch {
    date = '';
  }
  return date;
};

const dotParser = (value: string) => {
  let currency = '';

  try {
    currency = value.replace('.', ',');
  } catch {
    currency = '';
  }
  return currency;
};

export const dateFormatter = ({ value }: ValueFormatterParams): string => {
  if (!value) {
    return '';
  }
  return value;
};

export const dotFormatter = ({ value }: ValueFormatterParams): string => {
  if (!value) {
    return '';
  }
  return dotParser(String(value));
};

export const visibilityDateFormatter = (params: ValueGetterParams): string => {
  if (!params.data) {
    return '';
  }

  return dateParser(params.data.visDeliveryMin) + ' - ' + dateParser(params.data.visDeliveryMax);
};
