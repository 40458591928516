import * as React from 'react';
import { Player, PlayerWrapperStyled } from './video.component.style';

export const Video = {
  HelpCenterVideo: props => (
    <PlayerWrapperStyled>
      <Player url={props.src} controls width='90%' height='90%'></Player>
    </PlayerWrapperStyled>
  ),
};
