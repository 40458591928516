import React from 'react';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { BadgesBpsSelected } from './partials/badges-bps-selected/badges-bps-selected';
import { ScheduleBpSelection } from './partials/bps-selection/schedule-bps-selection';
import { EmailForm } from './partials/email-form/email-form';
import { ScheduleDates } from './partials/schedule-dates/schedule-dates';
import { ScheduleName } from './partials/schedule-name/schedule-name.component';

export const ScheduleModalFormBody: React.FC = () => {
  return (
    <FlexColumn>
      <FlexRow mt mb>
        <ScheduleName />
      </FlexRow>
      <FlexRow mb>
        <FlexColumn>
          <FlexRow>
            <ScheduleBpSelection />
          </FlexRow>
          <BadgesBpsSelected />
        </FlexColumn>
      </FlexRow>
      <FlexRow mb>
        <ScheduleDates />
      </FlexRow>
      <FlexRow pb>
        <EmailForm />
      </FlexRow>
      <VSeparator lineVisible />
    </FlexColumn>
  );
};
