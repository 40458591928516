import { map, MapOpArray } from '@taqtile/ts-data-mapper';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpInterceptor, HttpRequest } from '.';

const MAP_OPERATIONS: MapOpArray<HttpRequest, AxiosRequestConfig> = [
  'data',
  'params',
  'method',
  'baseURL',
  'timeout',
  'headers',
  'onUploadProgress',
  { from: 'path', to: 'url' },
];

export class HttpClient {
  /**
   * Executes a request
   *
   * @param request request object
   */
  async execute(request: HttpRequest): Promise<any> {
    const interceptors = request.interceptors || [];
    const axiosReq = await this.before(request, interceptors);
    try {
      return this.onSuccess(await axios(axiosReq), interceptors);
    } catch (error) {
      throw await this.onError(error, interceptors);
    }
  }

  private async before(request: HttpRequest, interceptors: HttpInterceptor[]): Promise<AxiosRequestConfig> {
    const req = await this.intercept('before', request, interceptors);
    return map(req, MAP_OPERATIONS);
  }

  private async onSuccess(response: AxiosResponse, interceptors: HttpInterceptor[]): Promise<any> {
    return this.intercept('afterSuccess', response, interceptors);
  }

  private async onError(error: AxiosError, interceptors: HttpInterceptor[]): Promise<any> {
    return this.intercept('afterError', error, interceptors);
  }

  private async intercept(phase: keyof HttpInterceptor, data: any, interceptors: HttpInterceptor[]) {
    const filteredInterceptors = interceptors.filter(interceptor => interceptor[phase]);
    for (const interceptor of filteredInterceptors) {
      data = await interceptor[phase](data);
    }
    return data;
  }
}
