import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { useCQDocumentQuery } from '@app/domain/document.use-case';
import { downloadFile } from '@app/utils/download-file';
import { CellLink } from '@atomic/atm.cell-link/cell-link.component';
import { FaIcon } from '@atomic/atm.fa-icon';
import { handleItemOvToSalesDocumentItem, handleNumDeliveryItemToDeliveryDocumentItem } from '../utils/handler-utils';

interface CertificateCellComponentProps {
  data: { ov: string; itemOv: string; numDelivery: string; numDeliveryItem: string };
}

export const CertificateCellComponent: React.FC<CertificateCellComponentProps> = props => {
  const { data, loading, fetch } = useCQDocumentQuery();
  const [filename, setFilename] = React.useState('Certificate');
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);

  const customerIds = clientBpsSelected.map(client => client.customerId).join(',');

  React.useEffect(() => {
    if (data) {
      LogAnalytics.click({ tipo: 'DownloadCertificado' });
      downloadFile(data, filename);
    }
  }, [data]);

  const handleDocumentView = (ov: string, itemOv: string, numDelivery: string, numDeliveryItem: string) => () => {
    const formattedItemOV = handleItemOvToSalesDocumentItem(itemOv);
    const formattedNumDeliveryItem = handleNumDeliveryItemToDeliveryDocumentItem(numDeliveryItem);

    setFilename(`Certificate - ${numDelivery}_${formattedNumDeliveryItem}`);
    fetch({ ov, itemOv: formattedItemOV, numDelivery, numDeliveryItem: formattedNumDeliveryItem, customerIds });
  };

  if (!props.data) {
    return null;
  }

  return (
    <CellLink
      onClick={handleDocumentView(props.data.ov, props.data.itemOv, props.data.numDelivery, props.data.numDeliveryItem)}
      loading={loading}
    >
      <FaIcon.FileDownload />
    </CellLink>
  );
};
