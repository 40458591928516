import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteAttributes } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Col, Row } from '@atomic/obj.grid';
import { TechnicalAnalysisContext } from '../technical-analysis.context';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';
import { AttributeCriticalWrapper } from './attribute-critical.component.style';

export interface AttributeCriticalProps {
  attribute: QuoteAttributes;
  permission: boolean;
  onChangeCritical: (option: boolean) => void;
}

export const AttributeCritical: React.FC<AttributeCriticalProps> = props => {
  const { analysis } = React.useContext(TechnicalAnalysisContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell;

  const handleChangeCrtical = async (option: boolean) => {
    props.onChangeCritical(option);
  };
  const currentStatus = analysis?.internalStatus?.key;

  return (
    <Row>
      <Col xs={12}>
        {!quoteIsInternalUser || (currentStatus !== 'INTERNAL_QUERY' && !props.permission) ? (
          props.attribute.isCritical && (
            <AttributeCriticalWrapper>
              <FaIcon.Check size='1x' color={Color.Success} />
              <Hbox.Separator small /> <Body>{strings.criticalLabel}</Body>
            </AttributeCriticalWrapper>
          )
        ) : (
          <AttributeCriticalWrapper>
            <CheckboxField
              checked={props.attribute.isCritical}
              id='attrbute-critical'
              onValueChange={(_, checked) => handleChangeCrtical(checked)}
              disabled={currentStatus !== 'INTERNAL_QUERY' && !props.permission}
            >
              {strings.criticalLabel}
            </CheckboxField>
          </AttributeCriticalWrapper>
        )}
      </Col>
    </Row>
  );
};
