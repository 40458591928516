import * as React from 'react';
import { WorkflowDataProps } from '@app/models/crm.model';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Body, H2 } from '@atomic/atm.typography';
import { TD, TR, Table } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { CrmStrings } from '../../crmComplain.string';
import { CrmContentHeaderWrapper, CrmContentInfosWrapper, CrmContentWrapper } from './crmContent.style';

interface CrmWorkflowProps {
  data: WorkflowDataProps;
  loading: boolean;
}

export const CrmWorkflow = (props: CrmWorkflowProps) => {
  const strings = CrmStrings.pt;

  return (
    <CrmContentWrapper>
      <CrmContentHeaderWrapper>
        <H2>
          {strings.sessions.WROKFLOW} {props.loading && <ActivityIndicator type='spinner' />}
        </H2>
      </CrmContentHeaderWrapper>
      {props?.data?.infos?.length > 0 ? (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />

            <Table collapse>
              <TR bordered>
                <TD color={Color.Secondary} textAlign='left'>
                  <Body bold={600}>{strings.tables.cols.status}</Body>
                </TD>
                <TD color={Color.Secondary} textAlign='left'>
                  <Body bold={600}>{strings.tables.cols.responsibleCurrentPending}</Body>
                </TD>
                <TD color={Color.Secondary} textAlign='left'>
                  <Body bold={600}>{strings.tables.cols.responsibleLastApproval}</Body>
                </TD>
                <TD color={Color.Secondary} textAlign='left'>
                  <Body bold={600}>{strings.tables.cols.situation}</Body>
                </TD>
                <TD color={Color.Secondary} textAlign='left'>
                  <Body bold={600}>{strings.tables.cols.identifyTask}</Body>
                </TD>
                <TD color={Color.Secondary} textAlign='left'>
                  <Body bold={600}>{strings.tables.cols.createdAtWorkflow}</Body>
                </TD>
              </TR>
              {props.data.infos.map((item, index) => (
                <TR bordered key={`t-${index}`}>
                  <TD textAlign='left'>
                    <Body>{item.value.status}</Body>
                  </TD>
                  <TD textAlign='left'>
                    <Body>{item.value.responsibleCurrentPending}</Body>
                  </TD>
                  <TD textAlign='left'>
                    <Body>{item.value.responsibleLastApproval}</Body>
                  </TD>
                  <TD textAlign='left'>
                    <Body>{item.value.situation}</Body>
                  </TD>
                  <TD textAlign='left'>
                    <Body>{item.value.identifyTask}</Body>
                  </TD>
                  <TD textAlign='left'>
                    <Body>{item.value.createdAt}</Body>
                  </TD>
                </TR>
              ))}
            </Table>
          </Hbox>
        </CrmContentInfosWrapper>
      ) : (
        <CrmContentInfosWrapper>
          <Hbox>
            <Separator small />
            <Body centralized>{props.loading ? 'Carregando...' : 'Nenhum registro encontrado.'}</Body>
          </Hbox>
        </CrmContentInfosWrapper>
      )}
    </CrmContentWrapper>
  );
};
