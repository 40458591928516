import { TableColumnType } from '@app/models';
import { parseStringToDate } from '@app/utils/date-parser';
import { TIME_6_DAYS, orderUpdateTableRowData } from '../../order-update.constants';
import { IOrderUpdateTableData } from '../../order-update.interfaces';

export const isStaticColumn = (column: string): boolean => {
  return (
    orderUpdateTableRowData[column].type !== TableColumnType.StaticText &&
    orderUpdateTableRowData[column].type !== TableColumnType.StaticDate
  );
};

export const hasInPleasureStatus = (tableRows: IOrderUpdateTableData[], index: number) => {
  const row: IOrderUpdateTableData = tableRows[index];

  if (row) {
    if (row.removedAlertStatus) {
      return false;
    }

    return row.status === 'Em Aprazamento';
  }
  return false;
};

export const hasLessThan5DaysDiff = (tableRows: IOrderUpdateTableData[], index: number) => {
  const row: IOrderUpdateTableData = tableRows[index];

  if (row?.newDateSolClient) {
    if (row.removedAlertDate) {
      return false;
    }
    const dateSol: Date = parseStringToDate(row.dateSolClient);
    const dateMore6days = dateSol.getTime() + TIME_6_DAYS;
    const dateLess6days = dateSol.getTime() - TIME_6_DAYS;

    if (row.newDateSolClient > dateSol.getTime()) {
      return dateMore6days > row.newDateSolClient;
    }

    if (row.newDateSolClient < dateSol.getTime()) {
      return dateLess6days < row.newDateSolClient;
    }
  }
  return false;
};

export const hasCanceledItemWithQuantity = (tableRows: IOrderUpdateTableData[], index: number) => {
  const row: IOrderUpdateTableData = tableRows[index];

  if (row) {
    if (row.removedAlertCancel) {
      return false;
    }

    return !!row.qtyStock || !!row.qtyTotalProduction;
  }
  return false;
};
