import React from 'react';
import { Body } from '@atomic/atm.typography';
import { FlashMessage, FlashMessageType, FlashMessageWrapperStyled } from '@atomic/mol.flash-message';

interface FlashWrapperStaticProps {
  type: FlashMessageType;
  content: string;
  opened: boolean;
  setOpened?: (value: boolean) => void;
  alignRight?: boolean;
  small?: boolean;
}

export const FlashWrapperStatic: React.FC<FlashWrapperStaticProps> = ({
  type,
  content,
  opened,
  setOpened,
  alignRight,
  small,
}) => {
  return opened && content ? (
    <FlashMessageWrapperStyled type={type} alignRight={alignRight} small={small}>
      <FlashMessage type={type} onClose={() => setOpened && setOpened(false)} dismissible>
        <Body>{content}</Body>
      </FlashMessage>
    </FlashMessageWrapperStyled>
  ) : null;
};
