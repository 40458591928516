import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { chatFlow } from '@app/data/http/chat.dto';

interface ChatContextState {
  chatQuestions: any;
  chatResult: any;
  enableClientSearch: boolean;
  showList: boolean;
  fieldValue: string;
  timeQuote: number;
  setChatResult: (value: any) => void;
  handleValueClientChange: (value: string) => void;
  setEnableClientSearch: (value: boolean) => void;
  setShowList: (value: boolean) => void;
  setFieldValue: (value: string) => void;
}

export const ChatContext = React.createContext<ChatContextState>(null);

export const ChatProvider: React.FC = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [chatResult, setChatResult] = React.useState(null);
  const [enableClientSearch, setEnableClientSearch] = React.useState<boolean>(false);
  const [showList, setShowList] = React.useState(false);
  const [fieldValue, setFieldValue] = React.useState('');

  const [timeQuote, setTimeQuote] = React.useState(0);

  React.useEffect(() => {
    let interval;

    const addTime = () => {
      setTimeQuote(prevTempo => prevTempo + 1);
    };

    const startTime = () => {
      if (!interval) {
        interval = setInterval(addTime, 1000);
      }
    };

    const stopTime = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    window.addEventListener('focus', startTime);
    window.addEventListener('blur', stopTime);

    return () => {
      window.removeEventListener('focus', startTime);
      window.removeEventListener('blur', stopTime);
      clearInterval(interval);
    };
  }, []);

  const handleValueClientChange = (value: string) => {
    setFieldValue(value);

    if (value?.length === 3) {
      setEnableClientSearch(true);
    } else if (value?.length < 3) {
      setShowList(false);
      setEnableClientSearch(false);
    }
  };

  const chatQuestions = chatFlow[userInfo.language];

  const value = {
    chatQuestions,
    chatResult,
    setChatResult,
    handleValueClientChange,
    enableClientSearch,
    setEnableClientSearch,
    showList,
    setShowList,
    fieldValue,
    setFieldValue,
    timeQuote,
  };

  const memoizedValue = React.useMemo(() => value, [value]);

  return <ChatContext.Provider value={memoizedValue}>{props.children}</ChatContext.Provider>;
};
