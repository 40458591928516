import styled from 'styled-components';
import { Breakpoint, Color, HeaderHeight, Spacing } from '@atomic/obj.constants';
import { HeaderProps } from '../../../../app-header.interfaces';

export const TabsMenuAppHeaderWrapper = styled.div`
  width: 100%;
  order: 2;
  position: relative;
  display: 'flex';
  @media all and (max-width: ${Breakpoint.lg}em) {
    position: fixed;
    display: block;
    background-color: ${Color.GrayXLight};
    width: 256px;
    height: 100%;
    left: ${(props: HeaderProps) => (props.active ? 0 : '-256')}px;
    top: ${HeaderHeight.Mobile};
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overscroll-behavior: contain;
    transition: ${(props: HeaderProps) => (props.active ? 'left .3s' : 'left .3s, z-index .3s .3s')};
    overflow-y: scroll;
    z-index: ${(props: HeaderProps) => (props.active ? '2' : 'initial')};
  }
`;

export const MenuList = styled.div`
  width: 100%;
  display: flex;
  padding-top: ${({ shortHeader }) => (shortHeader ? Spacing.Medium : Spacing.XSmall)};
  @media all and (max-width: ${Breakpoint.lg}em) {
    flex-direction: column;
    padding-top: 0;
  }
`;
