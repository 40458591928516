import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { EventTypes } from '@app/models/analytics.model';
import { MarketType } from '@app/providers';
import {
  setReloadBilledDashboard,
  setReloadConfirmedDashboard,
  setReloadFinanceDashboard,
  setReloadOpenDashboard,
  setReloadStockDashboard,
} from '@app/providers/navigation/navigation.store';
import { arraysAreEquals } from '@app/utils/array-helper';
import { checkEqualDates } from '@app/utils/date-parser';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { useScreenSize } from '@app/utils/screen-resize.hook';
import { Frame } from '@atomic/atm.frame';
import { Image } from '@atomic/atm.image';
import { H1 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Feedback, FeedbackAnalytics, FeedbackType } from '@atomic/obj.feedback';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { hasFinanceTabAccessSelector, hasOrdersTabAccessSelector } from '../auth/store/auth.selectores';
import { GridButtonWithIcon } from '../components/grid-button-with-icon.component';
import { BilledDashboard } from './billed-dashboard';
import { ConfirmedDashboard } from './confirmed-dashboard.component';
import { loadDashboards } from './dashboard.store';
import { Strings, IDashBoardStrings } from './dashboard.string';
import { FinanceDashboard } from './finance-dashboard.component';
import { MockGraph } from './mock-graph.component';
import { OpenDashboard } from './open-dashboard.component';
import { DashboardHeader } from './partials/header/dashboard-header.component';
import { StockDashboard } from './stock-dashboard.component';

const strings: IDashBoardStrings = Strings.pt;

const soonToBeAddedBlocks = [];

const DO_REQUESTS_FOR_DASHBOARDS = 'do_requests_for_dashboards';
const LAST_SELECTED_BPS = 'last_selected_bps';
const DASHBOARD_FIRST_ACCESS = 'dashboard_first_access';
const DASHBOARD_FIRST_ACCESS_DATE = 'dashboard_first_access_date';
const MILESECONDS_OPEN_CSAT = 5000;

export const DashboardPage: React.FC = () => {
  const hasOrdersTabAccess = useSelector(hasOrdersTabAccessSelector);
  const hasFinanceTabAccess = useSelector(hasFinanceTabAccessSelector);
  const { currentMarket, clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const customerIds = clientBpsSelected.map(client => client.customerId).join(',');

  const [opened, setOpened] = React.useState(false);
  const [dashboardLoadedCount, setDashboardLoadedCount] = React.useState(0);
  const [dashboardFirstAccess, setDashboardFirstAccess] = useLocalStorageState(DASHBOARD_FIRST_ACCESS, true);
  const [dashboardFirstAccessDate, setDashboardFirstAccessDate] = useLocalStorageState(
    DASHBOARD_FIRST_ACCESS_DATE,
    null,
  );
  const lastSelectedBPslocalStorage = window.localStorage.getItem(LAST_SELECTED_BPS);
  const [lastSelectedBPs, setLastSelectedBPs] = useLocalStorageState(LAST_SELECTED_BPS, lastSelectedBPslocalStorage);
  const {
    reloadOpenDashboard,
    reloadConfirmedDashboard,
    reloadStockDashboard,
    reloadBilledDashboard,
    reloadFinanceDashboard,
  } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();

  const { width } = useScreenSize();

  useEffect(() => {
    LogAnalytics.pageView('Dashboard de indicadores');
  }, []);

  const onSuccess = () => setDashboardLoadedCount(value => value + 1);

  const handleLoad = () => {
    dispatch(setReloadOpenDashboard(true));
    dispatch(setReloadConfirmedDashboard(true));
    dispatch(setReloadStockDashboard(true));
    dispatch(setReloadBilledDashboard(true));
    dispatch(setReloadFinanceDashboard(true));
  };

  const getSelectedBPsIds = () => {
    const selectedBPsIds = [];

    if (clientBpsSelected && clientBpsSelected.length > 0) {
      clientBpsSelected.forEach(bp => {
        selectedBPsIds.push(bp.customerId);
      });
    }

    return selectedBPsIds;
  };

  useEffect(() => {
    let canLoad = false;

    if (
      !dashboardFirstAccessDate ||
      (dashboardFirstAccessDate && !checkEqualDates(dashboardFirstAccessDate, new Date()))
    ) {
      canLoad = true;
    }

    if (dashboardFirstAccess && canLoad) {
      setDashboardFirstAccess(true);
    }
  }, []);

  useEffect(() => {
    const doRequestForDashboards = window.localStorage.getItem(DO_REQUESTS_FOR_DASHBOARDS);

    if (dashboardLoadedCount >= 4) {
      setOpened(true);
    } else if (!doRequestForDashboards || doRequestForDashboards === 'false') {
      setTimeout(() => {
        setOpened(true);
      }, MILESECONDS_OPEN_CSAT);
    }
  }, [dashboardLoadedCount]);

  useEffect(() => {
    const selectedBPsIds = getSelectedBPsIds();

    if (
      (selectedBPsIds && selectedBPsIds.length > 0 && !arraysAreEquals(selectedBPsIds, lastSelectedBPs)) ||
      dashboardFirstAccess
    ) {
      dispatch(loadDashboards(true));
      setDashboardFirstAccess(false);
      setDashboardFirstAccessDate(new Date());
      setLastSelectedBPs(selectedBPsIds);
      window.localStorage.setItem(DO_REQUESTS_FOR_DASHBOARDS, 'true');
    } else {
      window.localStorage.setItem(DO_REQUESTS_FOR_DASHBOARDS, 'false');
      dispatch(loadDashboards(false));
    }
  }, [clientBpsSelected]);

  return (
    <>
      <DashboardHeader />
      <Frame>
        <FlexRow hAlign='flex-end'>
          <FlexColumn noGrow mt={Spacing.Large} mr={Spacing.XLarge}>
            <GridButtonWithIcon
              text={strings.refresh}
              icon={() => <Image.RefreshIcon />}
              kind='secondary'
              onClick={handleLoad}
            />
          </FlexColumn>
        </FlexRow>
        <VSeparator />
        <Grid fluid>
          {currentMarket === MarketType.Internal ? (
            <>
              {hasOrdersTabAccess && (
                <>
                  <OpenDashboard
                    clientNumber={customerIds}
                    screenWidth={width}
                    onSuccess={onSuccess}
                    reload={reloadOpenDashboard}
                  />
                  <ConfirmedDashboard
                    clientNumber={customerIds}
                    screenWidth={width}
                    onSuccess={onSuccess}
                    reload={reloadConfirmedDashboard}
                  />
                  <StockDashboard
                    clientNumber={customerIds}
                    screenWidth={width}
                    onSuccess={onSuccess}
                    reload={reloadStockDashboard}
                  />
                  <BilledDashboard
                    clientNumber={customerIds}
                    screenWidth={width}
                    onSuccess={onSuccess}
                    reload={reloadBilledDashboard}
                  />
                </>
              )}
              {hasFinanceTabAccess && (
                <FinanceDashboard
                  clientNumber={customerIds}
                  screenWidth={width}
                  onSuccess={onSuccess}
                  reload={reloadFinanceDashboard}
                />
              )}
              <FlexRow>
                {soonToBeAddedBlocks.map((item, index) => (
                  <React.Fragment key={item}>
                    <FlexColumn>
                      <MockGraph title={item} />
                    </FlexColumn>
                    {soonToBeAddedBlocks.length !== index + 1 && <FlexRow ml={Spacing.Medium} />}
                  </React.Fragment>
                ))}
              </FlexRow>
            </>
          ) : (
            <H1>{strings.noExternalBlocks}</H1>
          )}
        </Grid>
        <VSeparator />
        <Feedback
          opened={opened}
          setOpened={setOpened}
          context={FeedbackAnalytics.Dashboard}
          type={FeedbackType.Numeric}
          message={strings?.feedback}
          analyticsType={EventTypes.Feedback}
        />
      </Frame>
    </>
  );
};
