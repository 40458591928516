export interface IOrderTabOpenMaritimeStrings {
  title: string;
  filename: string;
  error: string;
  status: {
    notConfirmed: string;
    inAnalysis: string;
    confirmed: string;
  };
}

interface IOrderTabOpenMaritimeLanguages {
  pt: IOrderTabOpenMaritimeStrings;
  es: IOrderTabOpenMaritimeStrings;
  in: IOrderTabOpenMaritimeStrings;
}

const pt: IOrderTabOpenMaritimeStrings = {
  title: 'Em aberto',
  filename: 'Pedidos em aberto',
  error: 'Ocorreu um erro ao carregar os dados da aba Em aberto! Por favor, tente novamente.',
  status: {
    notConfirmed: 'Não confirmado',
    inAnalysis: 'Não confirmado - em análise',
    confirmed: 'Confirmado',
  },
};

const es: IOrderTabOpenMaritimeStrings = {
  title: 'En Abierto',
  filename: 'Ordenes abiertas',
  error: '¡Ocurrió un error al cargar datos desde la pestaña Abrir! Inténtalo de nuevo.',
  status: {
    notConfirmed: 'No confirmado',
    inAnalysis: 'No confirmado - en análisis',
    confirmed: 'Confirmado',
  },
};

const en: IOrderTabOpenMaritimeStrings = {
  title: 'Open purchase orders',
  filename: 'Purchase orders',
  error: 'An error occurred while loading data from the Open tab! Please try again.',
  status: {
    notConfirmed: 'Not confirmed',
    inAnalysis: 'Not confirmed - Under review',
    confirmed: 'Confirmed',
  },
};

export const Strings: IOrderTabOpenMaritimeLanguages = {
  pt,
  es,
  in: en,
};
