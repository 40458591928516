import { getDateStringFilename } from './date-utils';

export const downloadFile = (data: any, fileName: string) => {
  const a = document.createElement<'a'>('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.href = data;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(data);
};

export const downloadPDF = pdf => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = `Pedido_${getDateStringFilename()}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
