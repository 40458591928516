import React from 'react';
import { FlexColumn } from '@atomic/obj.box';
import { ScheduleModalFormBody } from './partials/schedule-modal-form-body/schedule-modal-form-body.component';
import { ScheduleModalFormFooter } from './partials/schedule-modal-form-footer/schedule-modal-form-footer.component';
import { ScheduleModalFormHeader } from './partials/schedule-modal-form-header/schedule-modal-form-header.component';

export const ScheduleModalForm: React.FC = () => {
  return (
    <FlexColumn>
      <ScheduleModalFormHeader />
      <ScheduleModalFormBody />
      <ScheduleModalFormFooter />
    </FlexColumn>
  );
};
