import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route } from '@app/core/route';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { AppPath } from '../../app/route-constants';
import { useQuoteGuard } from '../../quote/quote.guard';
import { FormPage } from './form.page';

export const FormRouter: React.FC = () => {
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const quoteAuthorized = useQuoteGuard();

  return (
    <Route
      exact
      path={AppPath.QUOTE.FORM.BASE}
      component={FormPage}
      authorized={quoteAuthorized && quoteIsInternalUser}
      redirectPath={AppPath.QUOTE.BASE}
    />
  );
};
