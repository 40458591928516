import { createSlice } from '@reduxjs/toolkit';

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    firstAccess: true,
    firstAccessDate: null,
    loadDashboards: true,
    openOrdersGraphData: null,
    qtdGraphData: null,
    statusGraphData: null,
    confirmedDashboardData: null,
    stockDashboardData: null,
    billedDashboardData: null,
    financeMMGraphData: null,
    financeOMGraphData: null,
  },
  reducers: {
    loadDashboards: (state, { payload }) => {
      return { ...state, firstAccess: payload, firstAccessDate: payload ? new Date() : null, loadDashboards: payload };
    },
  },
});

export const { loadDashboards } = dashboard.actions;

export const dashboardLoadDate = state => state.accessDate;

export const dashboardReducer = dashboard.reducer;
