export interface IOrderTabStockStrings {
  title: string;
  filename: string;
  status: {
    label: {
      expired: string;
      notExpired: string;
      cgData: string;
    };
  };
  actions: {
    shippingRequest: string;
    activateNotification: string;
    deactivateNotification: string;
  };
  error: string;
}

export interface OrderTabStockLanguageInterface {
  pt: IOrderTabStockStrings;
  es: IOrderTabStockStrings;
  in: IOrderTabStockStrings;
}

export const pt: IOrderTabStockStrings = {
  title: 'Em estoque',
  filename: 'Pedidos em estoque',
  status: {
    label: {
      expired: 'Vencido',
      notExpired: 'No prazo',
      cgData: '-',
    },
  },
  actions: {
    shippingRequest: 'Solicitação de remessa',
    activateNotification: 'Receber notificações',
    deactivateNotification: 'Remover notificações',
  },
  error: 'Ocorreu um erro ao carregar os dados da aba Em estoque! Por favor, tente novamente.',
};

export const Strings: OrderTabStockLanguageInterface = {
  pt,
  es: pt,
  in: pt,
};
