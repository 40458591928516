import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { HANDLE_APPLY_TEMPLATE_ACTION } from '@app/modules/order-input/store/order-input.reducers';
import { setMaterialTemplate, setUpdateMaterialModalOpened } from '@app/modules/order-input/store/order-input.store';
import { Button } from '@atomic/atm.button';
import { Divisor } from '@atomic/atm.divisor';
import { H2, Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { hasError } from '../../../../../order-input-validation';
import { MaterialCell } from '../../../../../order-input.constants';
import { CustomDateField } from '../custom-data-field/custom-date-field.component';
import {
  UpdateMaterialModalBodyWrapper,
  UpdateMaterialModalFooterWrapper,
  UpdateMaterialModalHeaderWrapper,
  UpdateMaterialModalInputStyled,
} from './update-material-modal.style';

export const UpdateMaterialModal: React.FC = () => {
  const [unity, setUnity] = useState<string>('');
  const { materialTemplate, updateMaterialModalOpened, selectedMaterials } = useSelector(
    (state: RootState) => state.orderInput,
  );
  const dispatch = useDispatch();
  const checkedMaterials = selectedMaterials.filter(item => !!item.selected);

  const onRadioSelectionChange = event => {
    setUnity(event.target.value);
    const updatedMaterial = { ...materialTemplate };
    updatedMaterial.unity = event.target.value.toString();
    dispatch(setMaterialTemplate({ ...updatedMaterial }));
  };

  const handleClose = () => {
    setUnity('');
    dispatch(setUpdateMaterialModalOpened(false));
  };

  const handleConfirm = () => {
    setUnity('');
    dispatch(HANDLE_APPLY_TEMPLATE_ACTION);
    dispatch(setUpdateMaterialModalOpened(false));
  };

  const materialTemplateDate = materialTemplate.date ? new Date(materialTemplate.date) : null;

  return (
    <Modal small overflow='visible' opened={updateMaterialModalOpened} onClose={handleClose}>
      <Grid fluid>
        <UpdateMaterialModalHeaderWrapper hAlign='center'>
          <H2>Alterar {checkedMaterials.length} item(s) selecionado(s)</H2>
        </UpdateMaterialModalHeaderWrapper>
        <Divisor />
        <UpdateMaterialModalBodyWrapper>
          <FlexColumn hAlign='center'>
            <Label>Nº do pedido</Label>
            <FlexColumn pt>
              <UpdateMaterialModalInputStyled>
                <input
                  style={{ borderColor: hasError(materialTemplate, MaterialCell.OrderNumber) && Color.MediumAlert }}
                  defaultValue={materialTemplate.orderNumber}
                  onBlur={e => {
                    const updatedMaterial = { ...materialTemplate };
                    updatedMaterial.orderNumber = e.target.value;
                    dispatch(setMaterialTemplate({ ...updatedMaterial }));
                  }}
                />
              </UpdateMaterialModalInputStyled>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn hAlign='center'>
            <Label>Quantidade</Label>
            <FlexColumn pt>
              <UpdateMaterialModalInputStyled>
                <input
                  style={{ borderColor: hasError(materialTemplate, MaterialCell.Amount) && Color.MediumAlert }}
                  defaultValue={materialTemplate.amount}
                  onBlur={e => {
                    const updatedMaterial = { ...materialTemplate };
                    updatedMaterial.amount = e.target.value;
                    dispatch(setMaterialTemplate({ ...updatedMaterial }));
                  }}
                  type='number'
                  step='0.01'
                />
              </UpdateMaterialModalInputStyled>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn hAlign='center'>
            <Label>Unidade</Label>
            <FlexRow pt>
              <FlexColumn>
                <FlexColumn>
                  <FlexRow>
                    <input
                      type='radio'
                      name='unity'
                      value='T'
                      checked={unity === 'T'}
                      onChange={e => onRadioSelectionChange(e)}
                    />
                    <label style={{ marginLeft: '5px' }}>T</label>
                  </FlexRow>
                  <FlexRow>
                    <input
                      type='radio'
                      name='unity'
                      value='KG'
                      checked={unity === 'KG'}
                      onChange={e => onRadioSelectionChange(e)}
                    />
                    <label style={{ marginLeft: '5px' }}>KG</label>
                  </FlexRow>
                </FlexColumn>
              </FlexColumn>
            </FlexRow>
          </FlexColumn>
          <FlexColumn hAlign='center'>
            <Label>Data desejada</Label>
            <FlexColumn pt>
              <FlexColumn>
                <CustomDateField
                  openBottom
                  selectedDate={materialTemplateDate}
                  onValueChange={(value: Date) => {
                    const updatedMaterial = { ...materialTemplate };
                    updatedMaterial.date = value.getTime();
                    dispatch(setMaterialTemplate({ ...updatedMaterial }));
                  }}
                />
              </FlexColumn>
            </FlexColumn>
          </FlexColumn>
        </UpdateMaterialModalBodyWrapper>
        <UpdateMaterialModalFooterWrapper>
          <Button onClick={() => handleClose()} kind='secondary'>
            Cancelar
          </Button>
          <Button onClick={() => handleConfirm()}>Aplicar em todos</Button>
        </UpdateMaterialModalFooterWrapper>
      </Grid>
    </Modal>
  );
};
