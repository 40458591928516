import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Container } from 'typedi';
import { IsaHttpRequestBuilder } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { handleUnauthorized } from '@app/utils/http-utils';

export async function createQuestions(payload): Promise<any> {
  const user = Container.get(AuthStorageService).getUser();
  // const defaultHeaders = {
  //   client_id: CXP_KEY_CLIENT_ID,
  //   client_secret: CXP_KEY_CLIENT_SECRET,
  //   Authorization: 'Bearer ' + user.accessToken,
  //   access_token: user?.accessToken,
  //   'Content-Type': 'application/json',
  //   Accept: '*/*',
  //   language: user.language,
  // };

  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

  const url = 'https://cxp-quote-api-cxp-dev.apps2.rosa-gerdau.bxnu.p1.openshiftapps.com/v3/api/forms/questions';

  try {
    const { data, status } = await axios.post(url, payload, { headers: defaultHeaders });
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    handleUnauthorized(error);
    return null;
  }
}

export function useCreateQuestions() {
  return useMutation(createQuestions, {
    retry: false,
  });
}
