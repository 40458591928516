import * as React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';

export const NotificationConfigurationShimmer: React.FC = () => {
  return (
    <>
      {Array(4)
        .fill('')
        .map((_, index) => (
          <React.Fragment key={index}>
            <Hbox key={index}>
              <Hbox.Item>
                <TextShimmerBoxStyled height='32px' width='100%' margin='0' />
                <VSeparator small />
                <TextShimmerBoxStyled height='24px' width='100%' margin='0' />
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item vAlign='center' noGrow>
                <TextShimmerBoxStyled height='20px' width='30px' margin='0' />
              </Hbox.Item>
            </Hbox>
            <VSeparator />
          </React.Fragment>
        ))}
      <TextShimmerBoxStyled height='32px' width='100%' margin='0' />
      <VSeparator />
      {Array(5)
        .fill('')
        .map((_, index) => (
          <React.Fragment key={index}>
            <Hbox key={index}>
              <Hbox.Item>
                <TextShimmerBoxStyled height='24px' width='100%' margin='0' />
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item noGrow vAlign='center'>
                <TextShimmerBoxStyled height='20px' width='30px' margin='0' />
              </Hbox.Item>
            </Hbox>
            <VSeparator small />
          </React.Fragment>
        ))}
    </>
  );
};
