import React, { useEffect } from 'react';
import Container from 'typedi';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { handleDatadogRUM } from '@app/utils/datadog-rum';

export interface DatadogContainerProps {
  children: JSX.Element;
  hasUserLoggedIn: boolean;
}
export const DatadogContainer: React.FC<DatadogContainerProps> = ({ children, hasUserLoggedIn }) => {
  const user = Container.get(AuthStorageService).getUser();

  useEffect(() => {
    if (hasUserLoggedIn && user) {
      handleDatadogRUM(false);
    }
  }, [hasUserLoggedIn]);

  return <>{children}</>;
};
