/* eslint-disable prettier/prettier */
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '@app/core/redux/store';
import { ConfigColumnsDatasource } from '@app/data/http';
import { ConfigColumnsModel } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID, SLICE_NAME } from '../../order-input.constants';
import type { IOrderInputState } from '../../order-input.interfaces';


export const getSpreadsheetLayoutThunk = createAsyncThunk(
  `${SLICE_NAME}/getSpreadsheetLayout`,
  async (_, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const preCustomerIds: string = customerIdsJoinedSelector(state);
    const customerIds = preCustomerIds.replace(MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID);

    return await ConfigColumnsDatasource.getConfigColumns(customerIds)
      .then(data => data).catch(err => {
        return thunkAPI.rejectWithValue({ status: err.response.status, message: err.response.message });
      });
  });

export const addGetSpreadsheetLayoutThunk = builder => {
  builder.addCase(getSpreadsheetLayoutThunk.pending, (state: IOrderInputState) => {
    state.loadingGetConfigColumnsSpreadsheet = true;
  });
  builder.addCase(getSpreadsheetLayoutThunk.fulfilled, (state: IOrderInputState, action: PayloadAction<ConfigColumnsModel>) => {
    const config: ConfigColumnsModel = action.payload;
    state.configColumnsSpreadsheet = config;
    state.loadingGetConfigColumnsSpreadsheet = false;

  });
  builder.addCase(getSpreadsheetLayoutThunk.rejected, (state: IOrderInputState) => {
    state.loadingGetConfigColumnsSpreadsheet = false;
  });
};
