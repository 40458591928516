import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { OrderInputEvents } from '@app/modules/order-input/order-input.analytics';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { setEditNewLine, setQuery } from '@app/modules/order-input/store/order-input.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';

const strings = OrderInputStrings.pt.dateAndAmount;

export const AddMoreButton: React.FC = () => {
  const { editedNewline } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch();

  return (
    <Button
      disabled={editedNewline}
      onClick={() => {
        LogAnalytics.click({ tipo: OrderInputEvents.OrderInputOptionAddMoreItemsClick });
        dispatch(setQuery(''));
        dispatch(setEditNewLine(true));
      }}
    >
      <FaIcon.Plus />
      &nbsp; {strings.addMoreItens}
    </Button>
  );
};
