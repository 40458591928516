import { Accordion } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AdminStrings } from '@app/modules/admin/admin.string';
import { setNPSUserActiveId } from '@app/modules/admin/store/admin.store';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { LoadingState } from '@atomic/obj.loading-state';
import { AdminNPSListContent } from './admin-nps-list-content.component';
import { AdminNPSListHeader } from './admin-nps-list-header.component';
import { AdminNPSListShimmer } from './admin-nps-list.shimmer';

export const AdminNPSList: React.FC = () => {
  const { npsUserSearch, loadingNPSUsers } = useSelector((state: RootState) => state.admin);
  const strings = AdminStrings.pt.common;
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleUserList = () => {
    const userList = [];

    if (npsUserSearch?.filteredUsers?.length) {
      npsUserSearch.filteredUsers.forEach((filteredUser, i) => {
        const headerData = <AdminNPSListHeader user={filteredUser} />;
        const contentData = <AdminNPSListContent user={filteredUser} />;

        userList.push({ id: i, title: headerData, content: contentData });
      });
    }

    return userList;
  };

  const handleExpandedAccordion = (id: number | string) => {
    if (npsUserSearch?.activeId !== id) {
      dispatch(setNPSUserActiveId(id));
    } else {
      dispatch(setNPSUserActiveId(''));
    }
  };

  return (
    <LoadingState loading={loadingNPSUsers} data={npsUserSearch.filteredUsers?.length > 0} error={!npsUserSearch}>
      <LoadingState.Shimmer>
        <AdminNPSListShimmer />
      </LoadingState.Shimmer>

      <LoadingState.NoData>
        <FlexRow hAlign='center' mt={Spacing.XLarge} mb={Spacing.XLarge}>
          <Body size='16px' bold={600}>
            {strings.noResultsFound}
          </Body>
        </FlexRow>
      </LoadingState.NoData>
      <FlexColumn vAlign='center' mt={Spacing.XLarge} mb={Spacing.Large} ml={Spacing.XLarge} mr={Spacing.XLarge}>
        <Accordion
          data={handleUserList()}
          activeId={npsUserSearch?.activeId}
          onExpandedAccordion={id => handleExpandedAccordion(id)}
        />
      </FlexColumn>
    </LoadingState>
  );
};
