import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { DrawerHeight, Spacing } from '@atomic/obj.constants';

export const FileUploaderWrapper = styled(FlexRow)`
  width: 85%;
`;

export const CustomDropzoneWrapperStyled = styled.div`
  width: 100%;
  height: ${DrawerHeight};
  display: flex;
  flex-direction: row;
  padding-top: ${Spacing.XLarge};
  margin: 0;
  padding-right: ${Spacing.Large};
`;
