import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { PlaceholderStyled, PlaceholderWrapperStyled } from './placeholder.style';

interface ErrorPlaceholderProps {
  onRetryClick?: () => void;
  customMessage?: string;
}

export const ErrorPlaceholder: React.FC<ErrorPlaceholderProps> = props => {
  return (
    <PlaceholderWrapperStyled>
      <PlaceholderStyled>
        <Hbox>
          <Hbox.Item hAlign='center'>
            <Image.ErrorPlaceholder />
          </Hbox.Item>
        </Hbox>
        <Hbox>
          <Hbox.Item hAlign='center'>
            <H2>{props.customMessage ? props.customMessage : 'Um erro inesperado aconteceu'}</H2>
          </Hbox.Item>
        </Hbox>
        <VSeparator small />

        <Hbox>
          {props.onRetryClick && (
            <Hbox.Item hAlign='center'>
              <Button kind='primary' onClick={props.onRetryClick}>
                Tentar novamente
              </Button>
            </Hbox.Item>
          )}
        </Hbox>
      </PlaceholderStyled>
    </PlaceholderWrapperStyled>
  );
};
