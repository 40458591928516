import React from 'react';
import { DatePicker } from '@atomic/atm.date-picker';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
import { DateSelectedWrapper } from './surrogate-date-picker.style';

interface DataFilterCalendarProps {
  onValueChange: (value) => void;
  selectedDate?: Date;
  minDate?: Date;
  isDisabled?: boolean;
}

export const SurrogateDatePickerCalendar: React.FC<DataFilterCalendarProps> = ({
  selectedDate,
  onValueChange,
  minDate,
  isDisabled,
}) => {
  return (
    <DateSelectedWrapper>
      <DatePicker
        minDate={minDate}
        popperPlacement='bottom'
        defineDate
        startDate={selectedDate}
        onValueChange={onValueChange}
        disabled={isDisabled}
      />
      <FaIcon.Calendar color={isDisabled ? Color.Gray : Color.Primary} />
    </DateSelectedWrapper>
  );
};
