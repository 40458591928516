import * as React from 'react';
import { Image } from '@atomic/atm.image';
import { FeedbackBoxStyled, FeedbackNumberStyled } from '../../feedback.component.style';

export const answers = {
  img: [
    isSelected => <Image.FeedbackHate selected={isSelected} />,
    isSelected => <Image.FeedbackUnsatisfied selected={isSelected} />,
    isSelected => <Image.FeedbackNeutral selected={isSelected} />,
    isSelected => <Image.FeedbackLike selected={isSelected} />,
    isSelected => <Image.FeedbackLove selected={isSelected} />,
  ],
  numeric: Array.from({ length: 5 }).map((_, index) => isSelected => (
    <FeedbackBoxStyled selected={isSelected}>
      <FeedbackNumberStyled>{index + 1}</FeedbackNumberStyled>
    </FeedbackBoxStyled>
  )),
};
