import styled from 'styled-components';
import { Color, Spacing, ZIndex } from '@atomic/obj.constants';

export const SpreadsheetInfoWrapperStyled = styled.div`
  max-height: 600px;
  z-index: ${ZIndex.DashboardHeaderWrapperStyled};
  position: absolute;
  background-color: ${Color.White};
  width: 400px;
  left: 50px;
  right: 0px;
  top: 0px;
  bottom: 50px;
  padding: ${Spacing.Medium};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  height: 150px;
`;
