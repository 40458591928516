import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { isEatonSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { ShippingRequestEvents } from '@app/modules/order/order.analytics';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { ALERT_EATON_QTY_MODAL_WIDTH, ALERT_QTY_MODAL_WIDTH } from '../../shipping-request.constants';
import { IShippingRequestStrings, Strings } from '../../shipping-request.string';
import { setOpenAlertQtyModal } from '../../store/shipping-request.store';
import { AlertButtonModalWrapper } from './alert-button-modal.style';

export const AlertButtonModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { openAlertQtyModal } = useSelector((state: RootState) => state.shippingRequest);
  const isEaton = useSelector(isEatonSelector);
  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setOpenAlertQtyModal(false));

    LogAnalytics.click({
      tipo: ShippingRequestEvents.AlertConfirmClick,
    });
  };

  return (
    <Modal opened={openAlertQtyModal} customWidth={isEaton ? ALERT_EATON_QTY_MODAL_WIDTH : ALERT_QTY_MODAL_WIDTH}>
      <AlertButtonModalWrapper>
        <FlexRow vAlign='center'>
          <FaIcon.Close color={Color.Alert} />
          <FlexRow ml={Spacing.Small} mr>
            {isEaton ? strings.page.modalCustomQtyAlert : strings.page.modalQtyAlert}
          </FlexRow>
          <Button kind='secondary' onClick={handleClick}>
            {strings.page.modalQtyButton}
          </Button>
        </FlexRow>
      </AlertButtonModalWrapper>
    </Modal>
  );
};
