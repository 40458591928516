import styled from 'styled-components';
import { Body } from '@atomic/atm.typography';
import { Border, Color, FieldHeight, Spacing } from '@atomic/obj.constants';

export const CustomerSelectTextStyled = styled(Body)`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Color.Accessory};
`;

export const CustomerSelectH1Styled = styled.div`
  width: 400px;
  cursor: pointer;
  display: flex;
  border-radius: ${Border.Radius};
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 ${Spacing.Small};
  height: ${FieldHeight};
  align-items: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
`;
