import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Body } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { TravelStatus } from '../../truck-tracking.interfaces';
import { Strings, ITruckTrackingStrings } from '../../truck-tracking.string';
import { extractDateFromLastUpdate, isExpiredTruckTrackingDate } from '../../truck-tracking.utils';
import { TruckTrackingExpiredMessage } from './truck-tracking-expired-message/truck-tracking-expired-message.component';
import { TruckTrackingFinishedPlaceholder } from './truck-tracking-finished-placeholder/truck-tracking-finished-placeholder.component';

export const TruckTrackingLocalization: React.FC = () => {
  const { generalInformations, trackingUpdateInformations, map } = useSelector(
    (state: RootState) => state.truckTracking,
  );

  const strings: ITruckTrackingStrings = Strings.pt;

  const travelStatus = generalInformations?.travelStatus;
  const isFinalizedTravel: boolean = travelStatus === TravelStatus.Done || travelStatus === TravelStatus.Delivering;

  if (isFinalizedTravel) {
    return <TruckTrackingFinishedPlaceholder />;
  } else {
    const lastUpdate = trackingUpdateInformations?.currentLocation?.lastUpdate;
    const vehicleLastUpdate = trackingUpdateInformations?.currentLocation.vehicleLastUpdate;
    const baseDate: Date = extractDateFromLastUpdate(vehicleLastUpdate);
    const today: Date = new Date();

    return (
      <FlexColumn>
        <FlexColumn>{map && <img src={map} width='100%' height='100%' />}</FlexColumn>
        <FlexColumn>{!!lastUpdate && <Body>{`${strings.updates.lastUpdate} ${lastUpdate}`}</Body>}</FlexColumn>
        <FlexColumn mt={Spacing.XSmall}>
          {isExpiredTruckTrackingDate({ baseDate, today }) && <TruckTrackingExpiredMessage />}
        </FlexColumn>
      </FlexColumn>
    );
  }
};
