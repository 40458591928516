export interface ILackOfChargeConfigModalStrings {
  title: string;
  message: string;
  confirmButton: string;
  cancelButton: string;
}

interface ILackOfChargeConfigModalLanguages {
  pt: ILackOfChargeConfigModalStrings;
  es: ILackOfChargeConfigModalStrings;
  in: ILackOfChargeConfigModalStrings;
}

const pt: ILackOfChargeConfigModalStrings = {
  title: 'Alerta de falta de carga',
  message: 'Tem certeza que deseja alertar os usuários que possuem acesso à aba Carteira sobre a falta de carga?',
  confirmButton: 'Sim, enviar alerta',
  cancelButton: 'Cancelar',
};

const es: ILackOfChargeConfigModalStrings = {
  title: 'Alerta de falta de carga',
  message: 'Tem certeza que deseja alertar os usuários que possuem acesso à aba Carteira sobre a falta de carga?',
  confirmButton: 'Si, enviar alerta',
  cancelButton: 'Cancelar',
};

const en: ILackOfChargeConfigModalStrings = {
  title: 'Alerta de falta de carga',
  message: 'Tem certeza que deseja alertar os usuários que possuem acesso à aba Carteira sobre a falta de carga?',
  confirmButton: 'Sim, enviar alerta',
  cancelButton: 'Cancel',
};

export const Strings: ILackOfChargeConfigModalLanguages = {
  pt,
  es: es,
  in: en,
};
