import { Material } from '@app/models';
import { getAmountError, removeMaterialError, validateAmount } from '@app/modules/order-input/order-input-validation';
import { MaterialCell } from '@app/modules/order-input/order-input.constants';
import { updateMaterial } from '@app/modules/order-input/store/order-input.store';

export const handleAmountAndUnityChange = (material: Material, salesOrg: string, dispatch: (action: any) => void) => {
  if (validateAmount(material, salesOrg)) {
    material.messages = removeMaterialError(material.messages, MaterialCell.Amount);
    material.messages = removeMaterialError(material.messages, MaterialCell.Unity);
  } else {
    const materialMessages = material.messages || [];
    const messages = [...materialMessages, getAmountError(material, salesOrg)];
    material.messages = messages;
  }

  dispatch(updateMaterial(material));
};
