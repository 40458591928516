import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Color, Spacing, ZIndex } from '@atomic/obj.constants';

export const ActivityIndicatorWrapper = styled(FlexRow)`
  width: 194px;
`;

interface ICatalogInfoWrapperStyledProps {
  show: boolean;
}
export const CatalogInfoWrapperStyled = styled.div`
  opacity: ${(props: ICatalogInfoWrapperStyledProps) => (props.show ? 1 : 0)};
  max-height: ${(props: ICatalogInfoWrapperStyledProps) => (props.show ? '600px' : 0)};
  z-index: ${ZIndex.DashboardHeaderWrapperStyled};
  position: absolute;
  background-color: ${Color.White};
  width: ${(props: ICatalogInfoWrapperStyledProps) => (props.show ? '400px' : 0)};
  left: 101%;
  right: 0px;
  top: 25px;
  bottom: 0px;
  padding: ${Spacing.Medium};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  height: 82px;
`;
