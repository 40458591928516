import * as React from 'react';
import { H2 } from '@atomic/atm.typography';
import { Col, Row } from '@atomic/obj.grid';
import { DashModalInfo, ModalDashboard } from './dashboard-modal';
import { Strings, IDashBoardStrings } from './dashboard.string';
import { OpenOrdersGraph } from './open-orders-graph/open-orders-graph';
import { QtdGraph } from './open-orders-graph/qtd-graph';
import { StatusGraph } from './open-orders-graph/status-graph';
import { DashboardProps } from '.';

export const OpenDashboard: React.FC<DashboardProps> = props => {
  const strings: IDashBoardStrings = Strings.pt;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState<DashModalInfo>({ title: '', name: '', category: '', quantity: '' });

  const handleClick = modalData => {
    setModalInfo({
      title: strings.orderOpen.title,
      name: modalData.tooltipPayload[0].dataKey,
      category: modalData.payload.name,
      quantity: modalData.payload[modalData.tooltipPayload[0].dataKey],
    });
    setModalOpen(true);
  };

  return (
    <>
      <div key={props.screenWidth}>
        <H2 center>{strings.orderOpen.title}</H2>
        <Row>
          <Col xs={12} md={4}>
            <OpenOrdersGraph
              handleClick={handleClick}
              clientNumber={props.clientNumber}
              screenWidth={props.screenWidth}
            />
          </Col>
          <Col xs={12} md={4}>
            <QtdGraph handleClick={handleClick} clientNumber={props.clientNumber} screenWidth={props.screenWidth} />
          </Col>
          <Col xs={12} md={4}>
            <StatusGraph handleClick={handleClick} clientNumber={props.clientNumber} screenWidth={props.screenWidth} />
          </Col>
        </Row>
      </div>
      <ModalDashboard opened={modalOpen} onModalClose={() => setModalOpen(false)} info={modalInfo} />
    </>
  );
};
