import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Caption } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { SchedulePhase } from '../../schedule.constants';
import { strings } from '../../schedule.strings';
import { WizardProgressWrapper } from './schedule-modal-wizard.style';

const Strings = strings.phases;

export const ScheduleWizard: React.FC = () => {
  const { schedulePhase } = useSelector((state: RootState) => state.schedule);

  const phasesOptions = {
    [SchedulePhase.Form]: { title: Strings.scheduleForm, percent: 50 },
    [SchedulePhase.SelectColumns]: { title: Strings.selectColumns, percent: 100 },
  };

  return (
    <FlexRow hAlign='flex-end'>
      <WizardProgressWrapper>
        <progress max='100' value={phasesOptions[schedulePhase].percent} />
        <FlexRow hAlign='space-around'>
          {Object.values(phasesOptions).map(phase => {
            const isActual: boolean = phasesOptions[schedulePhase].percent >= phase.percent;
            const isDone: boolean = phasesOptions[schedulePhase].percent > phase.percent;
            return (
              <FlexColumn key={phase.percent} noGrow>
                <Caption style={isActual ? { color: Color.Purple } : null}>
                  {isDone && <FaIcon.Check size='sm' />}
                  &nbsp;
                  {phase.title}
                </Caption>
              </FlexColumn>
            );
          })}
        </FlexRow>
      </WizardProgressWrapper>
    </FlexRow>
  );
};
