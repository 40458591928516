import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { Body, H2 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, FontWeight, Spacing } from '@atomic/obj.constants';

export const ShippingRequestReceiverModalHeader: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IShippingRequestStrings = Strings[userInfo.language];
  const { openReceiverModalIndex, tableRows, receiverOptions } = useSelector(
    (state: RootState) => state.shippingRequest,
  );
  const [selectedReceiver, setSelectedReceiver] = useState(null);

  useEffect(() => {
    const dataRow = tableRows[openReceiverModalIndex];

    if (dataRow.newReceiver) {
      setSelectedReceiver(receiverOptions.find(opt => opt.value === tableRows[openReceiverModalIndex].newReceiver));
    } else {
      setSelectedReceiver(receiverOptions.find(opt => opt.value === tableRows[openReceiverModalIndex].receiver));
    }
  }, [receiverOptions]);

  return (
    <FlexColumn pl vAlign='center' fullWidth>
      <H2>
        <FlexRow hAlign='center' fullWidth>
          {strings.receiverModal.title}
        </FlexRow>
      </H2>
      <FlexRow mt mb>
        <Body color={Color.Black} pr={Spacing.Small} bold={FontWeight.Bold}>
          {strings.receiverModal.currentReceiver}
        </Body>
        <Body color={Color.Black}>{selectedReceiver?.name}</Body>
      </FlexRow>
    </FlexColumn>
  );
};
