import styled from 'styled-components';
import { BrandColor, Color, FontFamily, FontSize, Shadow, Spacing } from '@atomic/obj.constants';

export const ComplainModalWrapperStyled = styled.div`
  & > form {
    & > label {
      color: ${Color.Black};
      display: block;
      font-size: ${FontSize.Small};
      font-weight: 600;
      font-family: ${FontFamily.Primary};
      margin-bottom: ${Spacing.XSmall};
    }
    & > input,
    select,
    textarea {
      border-radius: 4px;
      border: 1px solid;
      border-color: ${Color.GrayLight};
      color: ${Color.Black};
      font-size: ${FontSize.Small};
      font-family: ${FontFamily.Primary};
      width: 100%;
      height: 40px;
      padding: ${Spacing.Small};
      align-self: stretch;
      background-color: #eff2f3;
      transition: all 0.3s;
    }
    & > textarea {
      height: 130px;
    }
    & > button {
      position: relative;
      width: 100%;
      min-height: 40px;
      padding: 0 ${Spacing.Medium};
      font-family: ${FontFamily.Primary};
      font-weight: 600;
      font-size: ${FontSize.Small};
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      opacity: 1;
      pointer-events: auto;
      color: ${BrandColor.LightBlue};
      background-color: ${BrandColor.Blue};
      border-color: ${BrandColor.Blue};
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
    }
  }
`;

export const FileFieldWrapper = styled.div`
  & > div > div > label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
  }
  & > div > div > button {
    border-color: none;
    background-color: transparent;
  }
  & > label {
    & > svg {
      top: ${Spacing.Small};
      left: ${Spacing.Small};
    }
  }
`;

export const FileListWrapper = styled.div`
  border: 1px solid ${Color.GrayLight};
  padding: ${Spacing.Small};
  width: 100%;
`;

export const ModalNotasWrapper = styled.div`
  box-shadow: ${Shadow.Small};
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  margin-left: 65px;
  margin-top: -1px;
  & > button {
    padding: 0px 4px;
    min-height: 18px;
    border-radius: 50%;
    width: 18px;
    font-size: ${FontSize.XSmall};
  }
`;
