import { IOrderUpdateState } from '../order-update.interfaces';

export const initialState: IOrderUpdateState = {
  showOrderUpdate: false,
  openUpdateFeedback: false,
  isCommentModalOpen: false,
  finishModalOpened: false,
  loading: false,
  generalComment: '',
  tableRows: [],
};
