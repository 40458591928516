/* eslint-disable prettier/prettier */
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import type { RootState } from '@app/core/redux/store';
import { OrderInplantationParams, OrderInputDatasource, OrderInputResponse } from '@app/data/http';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import { getDateStringFilename, getNewDateISOString } from '@app/utils/date-utils';
import { OrderInputEvents } from '../../order-input.analytics';
import { MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID, MaterialType, OrderInputPhaseEnum, SLICE_NAME, SearchByMaterial } from '../../order-input.constants';
import type { IOrderInputState } from '../../order-input.interfaces';
import { mapMaterialToMaterialsParams } from '../../order-input.utils';
import { getReceiverCompanyNameSelector } from '../order-input.selectors';

export const submitOrder = createAsyncThunk(`${SLICE_NAME}/submitOrder`, async (_, thunkAPI) => {
  const state: RootState = thunkAPI.getState() as RootState;
  const orderInputState = state.orderInput;
  const { selectedIssuer, selectedReceiver, selectedMaterials, searchBy, implantationType } = orderInputState;
  const preCustomerIds: string = customerIdsJoinedSelector(state);
  const customerIds = preCustomerIds.replace(MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID);

  const payload: OrderInplantationParams = {
    emissionCode: selectedIssuer.value,
    emissionCompanyName: `${selectedIssuer.name} - ${selectedIssuer.city}`,
    clientRequestDate: getNewDateISOString(),
    receiverCode: selectedReceiver,
    receiverCompanyName: getReceiverCompanyNameSelector(state),
    clientImplantationQuantity: selectedMaterials.length,
    clientRequestNumber: getDateStringFilename(),
    issuer: selectedIssuer.name,
    receiver: selectedReceiver,
    materials: mapMaterialToMaterialsParams(selectedMaterials),
    customerIds: customerIds,
    typeMaterialName: searchBy === SearchByMaterial.CUSTOMER_MATERIAL_CODE ? MaterialType.CodProdClient : MaterialType.CodMatGerdau,
    typeImplantation: implantationType,
  };


  return await OrderInputDatasource.submitOrder(payload)
    .then((data: OrderInputResponse) => ({ data, customerIds }))
    .catch(error => {

      LogAnalytics.error({
        tipo: OrderInputEvents.OrderInputError,
        responseStatus: error.response.status,
        responseData: error.response.data?.message,
        customerIds,
      });

      thunkAPI.dispatch(setOpenErrorHandlerModal(true));
      return thunkAPI.rejectWithValue({});
    });
});

export const addSubmitOrderReducers = builder => {
  builder.addCase(submitOrder.pending, (state: IOrderInputState) => {
    state.loadingPost = true;
  });
  builder.addCase(submitOrder.fulfilled, (state: IOrderInputState, action: PayloadAction<{ data: OrderInputResponse; customerIds: string }>) => {
    const { data, customerIds } = action.payload;

    state.loadingPost = false;
    state.selectedMaterials = [];
    state.pdfBase64 = data.pdfBase64;
    state.phase = OrderInputPhaseEnum.Success;
    const materials = state.selectedMaterials.map(material => material.orderItem).join(',');

    LogAnalytics.success({
      tipo: OrderInputEvents.OrderInputSuccess,
      materials,
      customerIds,
    });
  });
  builder.addCase(submitOrder.rejected, (state: IOrderInputState) => {
    state.loadingPost = false;
  });
};
