import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import { AppPath } from '@app/modules/app/route-constants';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { hasSomeConfirmedFiltersSelector } from '../../store/sidebar-filters.selectors';
import { ActiveFilters } from './partials/active-filters/active-filters.component';
import { SidebarTitle } from './partials/sidebar-title/sidebar-title.component';
import { HeaderWrapper } from './sidebar-filters-header.style';

export const SidebarFiltersHeader: React.FC = () => {
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const history = useHistory();
  const tab = history.location.pathname === AppPath.FINANCE ? LayoutTabs.FinanceMI : MItabIndex[orderTabIndex];
  const hasSomeFilters = useSelector(state => hasSomeConfirmedFiltersSelector(state, tab));

  return (
    <HeaderWrapper>
      <SidebarTitle />
      {hasSomeFilters && <ActiveFilters />}
    </HeaderWrapper>
  );
};
