import * as React from 'react';
import { Button, ButtonProps } from '@atomic/atm.button';
import { Hbox } from '@atomic/obj.box';
import { NewsTag } from './news-tag/news-tag';

interface GridButtonProps extends ButtonProps {
  icon?: React.FC;
  text: string;
  newsTag?: boolean;
}

export const GridButtonWithIcon: React.FC<GridButtonProps> = props => {
  return (
    <Button
      size={props.size}
      kind={props.kind}
      to={!props.disabled && props.to}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Hbox vAlign='center'>
        {props.icon && (
          <>
            <Hbox.Item noGrow>
              <props.icon />
            </Hbox.Item>
            <Hbox.Separator small />
          </>
        )}
        <Hbox.Item>{props.text}</Hbox.Item>
        {props.newsTag && (
          <>
            <Hbox.Separator small />
            <Hbox.Item>
              <NewsTag />
            </Hbox.Item>
          </>
        )}
      </Hbox>
    </Button>
  );
};
