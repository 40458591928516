/* eslint-disable complexity */
/* eslint-disable no-console */
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { QuoteAttributeColumn, QuoteAttributeRow, QuoteAttributes } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { TechnicalAnalysisComments } from '@app/modules/quote/technical-analysis-copy/comment';
import { TechnicalAnalysisContext } from '@app/modules/quote/technical-analysis-copy/technical-analysis.context';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis-copy/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Cell } from '@atomic/atm.cell';
import { Frame } from '@atomic/atm.frame';
import { H3 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { TechnicalAnalysusAttributeEvents } from '../../quote.analytics';
import { TechnicalAnalysisGeneralInfo } from '../components/technical-analysis-general-info.component';
//import { useEditAttributes } from '../hooks/useEditAttributes';
import { useGetImageDerogateMutate } from '../hooks/useGetImagesDerogateMuteate';
import { useGetNewAttribute } from '../hooks/useGetNewAttributes';
import { TechnicalAnalysisDerogateAttributesList } from '../technical-analysis-attributes-derogate-list';
import { TechnicalAnalysisAttributesList } from '../technical-analysis-attributes-list.component';
import { checkPendingCharacteristcAnalysis } from '../utils/checkPendingCharacteristcAnalisys';
//import { AttributeCell } from './attribute-cell';
import { AttributeTestMethod } from './attribute-test-method';
//import { ChemicalComposition } from './chemicalComposition.component';
import { JominyAdvice } from './jominy-advice.component';
// import { Jominy } from './jominy.component';
// import { Macroataque } from './macroataque.component';
// import { Microinclusion } from './microinclusion.component';
import { TechnicalAnalysisAttributeContext } from './technical-analysis-attribute.context';
//import { TechnicalAnalysisTableAttribute } from './technical-analysis-table-attribute.component';

export interface TechnicalAnalysisAttributeProps {
  attribute: QuoteAttributes;
  readOnly?: boolean;
  recurrentProduct?: boolean;
  editedAttributes?: string[];
  images?: File[];
  list?: any;
  onDragEnd?: any;
  selectedAttribute?: QuoteAttributes;
  loading?: boolean;
  onHandleAttribute?: (value: QuoteAttributes) => void;
  disableButtons: boolean;
  resume?: boolean;
  onHandleEditAttribute?: (value: any) => void;
  setActionsLoading: (value: boolean) => void;
}

export interface AttributeSpecificProps {
  attribute: QuoteAttributes;
  language: string;
}

export interface AttributeChemicalCompositionProps {
  attribute: { columns: QuoteAttributeColumn[]; rows: QuoteAttributeRow[][] };
  language: string;
}

export const TechnicalAnalysisAttribute: React.FC<TechnicalAnalysisAttributeProps> = props => {
  const { setIsEditing, setComments, setSelectedAttribute, comments } = React.useContext(
    TechnicalAnalysisAttributeContext,
  );
  const { analysisId, tabIndex, newPlants } = React.useContext(TechnicalAnalysisContext);

  console.log('tabIndex', tabIndex);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const [hideComments, setHideComments] = React.useState(true);
  const [derogateImages, setDerogateImages] = React.useState([]);
  const [provImages, setProvImages] = React.useState([]);
  const [tempComment, setTempComment] = React.useState({ externalComments: [], internalComments: [] });
  const [tempCommentExternal, setTempCommentExternal] = React.useState([]);
  const [tempCommentInternal, setTempCommentInternal] = React.useState([]);
  //const [tooltipNewDerogateImages, setTooltipNewDerogateImages] = React.useState(false);

  const [loadingTestMethod, setLoadingTestMethod] = React.useState([]);
  const [loadingAttributes, setLoadingAttributes] = React.useState<boolean>(false);

  const strings = TechnicalAnalysisStrings[userInfo.language].attributes;
  const ATTRIBUTE_INDEX = newPlants[tabIndex].attributes.findIndex(
    attribute => attribute?.key === props.attribute?.key,
  );

  console.log(derogateImages);
  //const index = ATTRIBUTE_INDEX >= 0 ? ATTRIBUTE_INDEX : 0;
  //const analysisContext = newPlants[tabIndex].attributes[index];

  const { mutate: callGetAttribute } = useGetNewAttribute(handleSuccessCallBack, handleErrorCallback);
  function handleSuccessCallBack(data) {
    console.log('success attribute', data);
    setSelectedAttribute(data);
  }
  function handleErrorCallback() {
    console.log('error');
  }

  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const attributesList = newPlants[tabIndex].attributes;

  const handleSuccessImg = data => {
    setProvImages([]);
    setLoadingAttributes(false);
    setDerogateImages(data);
  };

  const handleErrorImgs = () => {
    LogAnalytics.error({ tipo: TechnicalAnalysusAttributeEvents.DeleteImagesError });
    show('alert', `${strings.errorMessage}`);
  };

  const { mutate: getImg, isLoading: loadingGetImages } = useGetImageDerogateMutate(handleSuccessImg, handleErrorImgs);

  //const [countSuccess, setCountSuccess] = React.useState(0);

  // const handleSuccessEditRequest = () => {
  //   setCountSuccess(countSuccess + 1);
  //   setIsEditing(false);
  //   show('success', strings.attributeCell.successMessage(props.attribute.label));
  //   //analysisContext.loading = loadingEditRequest;
  //   setBlockLoading(false);
  //   if (provImages.length > 0 || derogateImages.length > 0) {
  //     getImg({
  //       data: { analysisId, plantKey: newPlants[tabIndex].key, attributeKey: props.attribute.key, ATTRIBUTE_INDEX },
  //       language: userInfo.language,
  //     });
  //   }
  // };

  // const handleFailureEditRequest = () => {
  //   setIsEditing(false);
  //   setBlockLoading(false);
  //   LogAnalytics.error({ tipo: TechnicalAnalysusAttributeEvents.EditAttributeError });
  //   show('alert', `${strings.attributeCell.errorMessages.genericError}`);
  // };

  // const { mutate: editRequest, error: editError, isLoading: loadingEditRequest } = useEditAttributes(
  //   handleSuccessEditRequest,
  //   handleFailureEditRequest,
  // );

  // React.useEffect(() => {
  //   props.setActionsLoading(loadingEditRequest || loadingGetImages);
  // }, [loadingEditRequest, loadingGetImages]);

  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.cancelQueries(['getImageDerogateMutate']);
    setComments(props.attribute?.comments);
    setIsEditing(false);

    if (props.attribute && analysisId) {
      // setAttribute(analysisContext);
      // setLoadingAttributes(!analysisContext.loading);

      setDerogateImages([]);
    }
    if (provImages.length === 0 && props.attribute?.derogate?.images && props.attribute?.derogate?.images.length > 0) {
      setLoadingAttributes(true);
      getImg({
        data: { analysisId, plantKey: newPlants[tabIndex].key, attributeKey: props.attribute.key, ATTRIBUTE_INDEX },
      });
    }
  }, [props.attribute, analysisId]);

  const verifyProvImage = value => {
    if (provImages.length > 0) {
      //setTooltipNewDerogateImages(true);
    } else {
      props.onHandleAttribute(value);
    }
  };

  const derogateList = props.list?.filter(item => {
    return checkPendingCharacteristcAnalysis(
      item.status,
      item?.derogate?.permissions?.isEditable,
      item?.derogate?.suggestionStatus,
    );
  });

  function handleGetAttribute(attrKey: string) {
    console.log('aquii???', attrKey);
    callGetAttribute({
      analysisId: analysisId,
      plantKey: newPlants[tabIndex].key,
      attributeKey: attrKey,
      language: userInfo.language,
    });
  }

  return (
    <>
      {props.attribute?.type && (
        <Row mb>
          <Col xs={3}>
            {derogateList && derogateList.length > 0 ? (
              <TechnicalAnalysisDerogateAttributesList
                title={strings.derogateListTitle}
                list={derogateList}
                onHandleAttribute={value => verifyProvImage(value)}
                recurrentProduct={props.recurrentProduct}
                editedAttributes={props.editedAttributes}
                selectedAttribute={props.selectedAttribute}
                loading={props.loading || loadingGetImages}
              />
            ) : null}

            <TechnicalAnalysisAttributesList
              list={attributesList}
              onHandleAttribute={value => verifyProvImage(value)}
              recurrentProduct={props.recurrentProduct}
              editedAttributes={props.editedAttributes}
              onDragEnd={props.onDragEnd}
              selectedAttribute={props.selectedAttribute}
              loading={props.loading || loadingGetImages}
              plant={newPlants[tabIndex]}
              loadingAttributes={loadingAttributes}
              loadingTestMethod={loadingTestMethod}
              internalUser={quoteIsInternalUser}
              handleGetAttribute={handleGetAttribute}
              onHandleEditAttribute={props.onHandleEditAttribute}
            />
          </Col>
          <Col xs={props.readOnly ? 12 : 6}>
            {(userInfo.profile.profile === 'epp' || userInfo.profile.profile === 'rtc') &&
              props.attribute.key === 'HARDENABILITY' && (
                <>
                  <JominyAdvice />
                  <VSeparator />
                </>
              )}

            {props?.selectedAttribute?.testMethod && (
              <>
                <AttributeTestMethod
                  status={props.attribute.status}
                  derogate={props.attribute.derogate}
                  onGerdauCommentsClick={() => setHideComments(!hideComments)}
                  readOnly={props.readOnly || props.disableButtons}
                  recurrentProduct={props.recurrentProduct}
                  editedAttributes={props.editedAttributes}
                  setLoadingTestMethod={setLoadingTestMethod}
                  loadingTestMethod={loadingTestMethod}
                />
                {!props.resume ? <Separator /> : <Separator small />}
              </>
            )}

            {/* <AttributeCell
              analysis={props.analysis}
              title={props.attribute.label}
              status={props.attribute.status}
              onGerdauCommentsClick={() => setHideComments(!hideComments)}
              onEditClick={() => setIsEditing(true)}
              plantKey={newPlants[tabIndex]?.key}
              attribute={props.attribute}
              derogateImages={derogateImages}
              provImages={provImages}
              derogateImagesLoading={loadingGetImages}
              setProvImages={setProvImages}
              derogateOptionsList={props.attribute.allowed}
              readOnly={props.readOnly || props.disableButtons}
              recurrentProduct={props.recurrentProduct}
              editedAttributes={props.editedAttributes}
              setLoadingAttributes={setLoadingAttributes}
              tooltipNewDerogateImages={tooltipNewDerogateImages}
              setTooltipNewDerogateImages={setTooltipNewDerogateImages}
              editRequest={editRequest}
              editError={editError}
              loadingEditRequest={loadingEditRequest || props.loading}
              setActionsLoading={props.setActionsLoading}
              getImg={getImg}
            >
              {TechnicalAttributeMapping(selectedAttribute, userInfo.language)}
            </AttributeCell> */}
            {!props.resume ? <Separator /> : <Separator small />}

            {(!props.readOnly && !props.recurrentProduct) || props.resume ? (
              <Frame>
                <Cell>
                  <H3>{strings.attrComments}</H3>
                  <Separator small />
                  <TechnicalAnalysisComments
                    comments={comments}
                    setComments={setComments}
                    plantKey={newPlants[tabIndex]?.key}
                    attributeKey={props.attribute.key}
                    canAddComments={!!props.attribute.permissions.commentsAddible && !props.disableButtons}
                    canReplicateComments={!!props.attribute.permissions.commentsReplicable}
                    readOnly={props.readOnly}
                    recurrentProduct={props.recurrentProduct}
                    tempComment={tempComment}
                    setTempComment={setTempComment}
                    tempCommentExternal={tempCommentExternal}
                    tempCommentInternal={tempCommentInternal}
                    setTempCommentExternal={setTempCommentExternal}
                    setTempCommentInternal={setTempCommentInternal}
                    resume={props.resume}
                    attribute={props.attribute}
                  />
                </Cell>
              </Frame>
            ) : null}
          </Col>
          {props.resume && <Separator />}

          <Col xs={3}>
            {!props.readOnly && (
              <TechnicalAnalysisGeneralInfo analysisId={analysisId} quoteIsInternalUser={quoteIsInternalUser} />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

// const TechnicalAttributeMapping = (attribute: AttributesData, language: string) => {
//   const formatText = value => {
//     return attribute?.key === 'SECUNDARY_DIAMETER' ? value.toLowerCase() : value;
//   };

//   const strings = TechnicalAnalysisStrings[language].attributes;
//   switch (attribute?.type) {
//     case QuoteAttributeTypes.Table:
//       if (attribute?.key === 'MICRO_INCLUSION') {
//         //return <Microinclusion language={language} attribute={attribute} />;
//         return null; //prov
//       } else if (attribute?.key === 'CHEMICAL_COMPOSITION') {
//         return <ChemicalComposition language={language} attribute={attribute} />;
//       } else if (attribute?.key === 'HARDENABILITY') {
//         //return <Jominy language={language} attribute={attribute} />;
//         return null; //prov
//       } else if (attribute?.key === 'MACRO_ETCH') {
//         //return <Macroataque language={language} attribute={attribute} />;
//         return null; //prov
//       } else {
//         //return <TechnicalAnalysisTableAttribute language={language} attribute={attribute} />;
//         return null; //prov
//       }
//     case QuoteAttributeTypes.Range:
//       //return <TechnicalAnalysisRangeAttribute language={language} attribute={attribute} />;
//       return null; //prov
//     case QuoteAttributeTypes.Text:
//     case QuoteAttributeTypes.Options:
//       return <Body style={{ whiteSpace: 'pre-wrap' }}>{formatText(attribute?.label)}</Body>;
//     case QuoteAttributeTypes.Number:
//       return (
//         <Body>
//           {/* {`${
//             (attribute?.key === 'PARTIAL_DECARBURIZING' ||
//               attribute?.key === 'TOTAL_DECARBURIZING' ||
//               attribute?.key === 'GLOBAL_DECARBURIZING') &&
//             attribute.numberAttributeValue === 0
//               ? attribute.numberAttributeValue.toFixed(2)
//               : attribute.numberAttributeValue
//           } `} */}
//           {attribute?.unit ? attribute.unit : null}
//         </Body>
//       );
//     case QuoteAttributeTypes.TextArray:
//       return <Body>{attribute.value.map(item => item.label).join(',')}</Body>;
//     case QuoteAttributeTypes.Boolean:
//       return <Body>{attribute.value ? strings.yes : strings.no}</Body>;
//     case QuoteAttributeTypes.Date:
//       return <Body>{attribute.value}</Body>;
//     default:
//       return null;
//   }
// };

// const TechnicalAnalysisRangeAttribute: React.FC<AttributeSpecificProps> = props => {
//   const strings = TechnicalAnalysisStrings[props.language].attributes.rangeAttribute;
//   return (
//     <>
//       <Body>{strings.min(props.attribute.attributeValue.min, props.attribute.unit?.value?.min)}</Body>
//       <VSeparator small />
//       <Body>{strings.max(props.attribute.attributeValue.max, props.attribute.unit?.value?.max)}</Body>
//       <VSeparator />
//     </>
//   );
// };
