import { Service } from 'typedi';
import { LocalStorage } from '@app/core/storage';

const CONTEXT_KEY = 'conversation-context';

@Service()
export class ConversationContextStorageService {
  @LocalStorage(CONTEXT_KEY)
  private context: any;

  get = () => {
    return this.context;
  };

  set = (context: any) => {
    this.context = context;
  };
}
