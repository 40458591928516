import styled from 'styled-components';
import { FlexColumn } from '@atomic/obj.box';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export const SchedulingCompleteModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  background: ${Color.Primary};
  flex: 1;
  height: ${Spacing.XXLarge};
`;

export const SchedulingCompleteModalHeaderIconWrapper = styled.div`
  flex: 1;
  padding-left: ${Spacing.XLarge};
`;

export const SchedulingCompleteModalTitleWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-size: ${FontSize.XLarge};
  font-weight: 700;
  color: ${Color.White};
  padding-left: ${Spacing.Large};
`;

export const SchedulingCompleteModalCloseWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-right: ${Spacing.XLarge};
`;

export const CloseButtonWrapper = styled(FlexColumn)`
  cursor: pointer;
`;

export const SchedulingCompleteModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const SchedulingCompleteModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.Large};
  padding-bottom: ${Spacing.Large};
`;

interface BodyProps {
  noWrap?: boolean;
  color?: boolean;
  bold?: string;
  centralized?: boolean;
  align: string;
  size?: string;
  fullWidth?: boolean;
}

export const SchedulingComponentHtmlText = styled.div`
  color: ${(props: BodyProps) => (props.color ? props.color : Color.GrayDark)};
  margin: 0;
  font-size: ${(props: BodyProps) => (props?.size ? props?.size : FontSize.Small)};
  font-family: ${FontFamily.Primary};
  font-weight: ${(props: BodyProps) => (props.bold ? props.bold : FontWeight.Normal)};
  white-space: ${(props: BodyProps) => (props.noWrap ? 'nowrap' : 'normal')};
  justify-content: ${(props: BodyProps) => (props.centralized ? 'center' : 'initial')};
  text-align: ${(props: BodyProps) => props.align || 'left'};
  width: ${(props: BodyProps) => (props.fullWidth ? '100%' : 'auto')};
`;

export const SchedulingCompleteModalFooter = styled.div`
  width: 100%;
  background-color: ${Color.White};
  padding: ${Spacing.Medium};
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.12);
`;

export const SchedulingCompleteModalHeaderImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${Spacing.Medium};
  margin-bottom: ${Spacing.Medium};
`;

export const SchedulingCompleteModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;
  padding-left: 10%;
  padding-right: 10%;
`;

export const SchedulingCompleteModalTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
`;
