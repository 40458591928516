import styled from 'styled-components';
import { Shadow } from '@atomic/obj.constants';
import { FixedFooterProps } from './fixed-footer.component';

export const FixedFooterWrapperStyled = styled.div`
  padding-bottom: 100px;
`;

export const FixedFooterStyled = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  ${(props: FixedFooterProps) =>
    props.hasShadow &&
    `
    box-shadow: ${Shadow.Medium};
  `}
`;
