import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { CommentsFullData } from '@app/models/quote.model';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { Avatar } from '@atomic/atm.avatar';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { CommentCell } from './comment-cell';

export interface TempComment {
  client_visible: boolean;
  creation: {
    createBy: {
      email: string;
      firstName: string;
      lastName: string;
    };
    createdDate: Date;
    origin: string;
    status_stage: {
      key: string;
      label: string;
    };
  };
  key: string;
  permissions: {
    isDelible: boolean;
    isEditable: boolean;
  };
  value: string;
}

interface TechnicalAnalysisInternalCommentsProps {
  canAddComments: boolean;
  canReplicateComments: boolean;
  comments: CommentsFullData;
  loading: boolean;
  currentComment: string;
  initials: string;
  tempComment?: CommentsFullData;
  attributeKey?: string;
  resume?: boolean;
  blockCommentButtons?: boolean;
  blockButton: boolean;
  setCurrentComment: (value: string) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onReplicateClick: (value: boolean) => void;
  onDeleteComment: (key: string) => void;
  onEditComment: (key: string, value: string) => void;
  onClose: () => void;
}

export const TechnicalAnalysisInternalComments: React.FC<TechnicalAnalysisInternalCommentsProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = TechnicalAnalysisStrings[userInfo.language].comment;
  return (
    <>
      {props.comments?.internalComments.map(comment => (
        <CommentCell
          key={comment.key}
          showAvatar
          comment={comment}
          loading={comment.loading}
          onDeleteComment={() => props.onDeleteComment(comment.key)}
          onEditComment={value => props.onEditComment(comment.key, value)}
          canAddComments={props.canAddComments}
          blockButton={props.blockButton}
        />
      ))}

      {props.canAddComments && !props.resume ? (
        <Hbox>
          <Hbox.Item noGrow>
            <Avatar type='neutral' direction='none'>
              {props.initials.toUpperCase()}
            </Avatar>
          </Hbox.Item>
          <Hbox.Separator />

          <Hbox.Item>
            <TextField
              hasButton
              value={props.currentComment}
              onValueChange={props.setCurrentComment}
              placeholder={strings.placeholder}
              onKeyDown={props.onKeyDown}
            >
              <Button kind='link' onClick={props.onSubmit} loading={false}>
                <FaIcon.Plane size='1x' />
              </Button>
            </TextField>
            <VSeparator />

            <CheckboxField
              id='replicate'
              initialChecked={true}
              onValueChange={(_id, checked) => props.onReplicateClick(checked)}
            >
              {strings.replicateComment}
            </CheckboxField>
          </Hbox.Item>
        </Hbox>
      ) : (
        props.comments?.internalComments.length === 0 && <H3>{strings.noData}</H3>
      )}
    </>
  );
};
