import {
  DeliveryForecastItem,
  DeliveryStatus,
  InStockOrderItem,
  SalesOrderItem,
  SalesOrderSummaryItem,
  StockRealDateItem,
} from '@app/models/sales-order-item.model';
import { dateParser } from '@app/utils/date-parser';
import { parseOrderDate } from './order-date.mapper';

export interface OpenedSalesOrderSummaryDto {
  salesOrdersOnlineSumary: SalesOrderSummaryItemDto[];
  updateDate: string;
}

export interface BilledSalesOrderDto {
  onlineBillings: SalesOrderItemDto[];
}

export interface InStockOrderDto {
  stocks: InStockOrderItemDto[];
}
export interface StockRealDateDto {
  stocks: StockRealDateItemDto[];
}

export const DeliveryStatusMap: Record<string, DeliveryStatus> = {
  Atraso: DeliveryStatus.Delay,
  'Tendência de atraso': DeliveryStatus.DelayTrend,
  'Dentro do prazo': DeliveryStatus.OnTime,
  'Pedido ainda não analisado': DeliveryStatus.NotAnalized,
  'Pedido analisado e não confirmado': DeliveryStatus.NotConfirmed,
  'Sem previsão - Consultar Gerdau': DeliveryStatus.NoForecast,
  'Estoque aguardando faturamento': DeliveryStatus.StockWaitingBilling,
  'Falta de Carga': DeliveryStatus.LackOfCharge,
};

export interface SalesOrderItemDto {
  product: string;
  marketDest: string;
  width: number;
  alloyClient: string;
  netPrice: number;
  ocClient: string;
  dateMaxDeliveryClient: string;
  process: string;
  dateSolClient: string;
  lengthMin: number;
  docQtdOri: number;
  codGroupRentability: string;
  standardSteel: string;
  standardRef: string;
  dateDeliveryClient: string;
  invoiceType: string;
  docCategory: string;
  lengthMax: number;
  docQtdT: number;
  docDate: string;
  standardProdId: string;
  plant: string;
  plantName: string;
  descMaterial: string;
  clientNumber: string;
  taker: string;
  visDeliveryMax: string;
  lengthMult: number;
  shape: string;
  material: string;
  diameter: number;
  qtdConf: number;
  ocClientItem: string;
  invoiceDate: string;
  brand: string;
  ov: string;
  codMaterialClient: string;
  alloyGsb: string;
  dtStandardRef: string;
  visDeliveryMin: string;
  status: string;
  invoice: number;
  dateOfDeparture: string;
  qtdSolClient: number;
  thickness: number;
  numDocument: string;
  boarding: string;
  nameClient: string;
  numDeliveryItem: number;
  bookingId: number;
  bookingName: string;
  processId: number;
  ETD: string;
  ETA: string;
  ETC: string;
  freetimeDestination: number;
  shipName: string;
  docNum: string;
  numDelivery: string;
  itemOv: string;
  transportDocument: string;
  invoiceEmissionDate: string;
  CRT: string;
  CRTDate: string;
  takerId: string;
  concatenatedBillingDocument: string;
  activeSector: string;
  batch: string;
  truckPlate: string;
  heat: string;
  typeOfVehicle: string;
  carrierName: string;
  ovType: string;
  customerPurchaseOrderItemNumber?: string;
  standardGradeIssueDateStandardGrade: string;
}

export interface SalesOrderSummaryItemDto {
  product: string;
  marketDest: string;
  width: number;
  alloyClient: string;
  netPrice: number;
  ocClient: string;
  dateMaxDeliveryClient: string;
  process: string;
  dateSolClient: string;
  lengthMin: number;
  docQtdOri: number;
  codGroupRentability: string;
  standardSteel: string;
  standardRef: string;
  dateDeliveryClient: string;
  invoiceType: string;
  docCategory: string;
  invoiceItem: number;
  lengthMax: number;
  docDate: string;
  standardProdId: string;
  plant: string;
  plantName: string;
  descMaterial: string;
  clientNumber: string;
  taker: string;
  visDeliveryMax: string;
  lengthMult: number;
  shape: string;
  material: string;
  diameter: number;
  qtdConf: number;
  ocClientItem: string;
  invoiceDate: string;
  brand: string;
  ov: string;
  codMaterialClient: string;
  alloyGsb: string;
  dtStandardRef: string;
  visDeliveryMin: string;
  status: string;
  invoice: number;
  dateOfDeparture: string;
  qtdSolClient: number;
  thickness: number;
  numDocument: string;
  boarding: string;
  nameClient: string;
  numDeliveryItem: number;
  bookingId: number;
  bookingName: string;
  processId: number;
  ETD: string;
  ETA: string;
  ETC: string;
  freetimeDestination: number;
  shipName: string;
  docNum: string;
  numDelivery: string;
  itemOv: string;
  transportDocument: string;
  invoiceEmissionDate: string;
  CRT: string;
  CRTDate: string;
  qtyTotalBilled: number;
  qtyBillingOpen: number;
  qtyDelivery: number;
  qtyStock: number;
  qtyTotalProduction: number;
  statusFront: string;
  activateNotification: boolean;
  activateShipping: boolean;
  dateProcessJob?: string;
  dtConfirmedStock?: string;
  dtCustomerRequiredOriginal?: string;
  qtyNotAllocated?: number;
  qtyTotalStatus?: number;
  qtdeDatasCartCli: number;
  dateUpdate: string;
  activateCritical: boolean;
  customerPurchaseOrderItemNumber: string;
  standardGradeIssueDateStandardGrade: string;
  dtPurchase: string;
}

export interface InStockOrderItemDto {
  salesOrderStatus: string;
  shipToPartyName: string;
  materialName: string;
  customerRequiredDate: string;
  material: string;
  agreedQuantity: number;
  storageLocation: string;
  customerAgreedDate: string;
  language: string;
  soldToPartyName: string;
  shipToParty: string;
  customerPurchaseOrderItem: string;
  plant: string;
  plantName: string;
  customerMaterialCode: string;
  salesDocumentItem: string;
  soldToParty: string;
  salesDocument: string;
  customerPurchaseOrderNumber: number;
  confirmedShippingDate: string;
  purchaseDate: string;
  billingOpenQuantity: number;
  delivPendQtyGen: number;
  deliveryQuantity: number;
  totalBilledQuantity: number;
  pickingQuantity: number;
  status: string;
  stockQuantity: number;
  batch: string;
  qtyCustomerRequiredTons: number;
  totalStockQuantity: number;
  heat: string;
  typeStock: string;
  dateOfStockEntry: string;
  qtyDateOfStockEntry: number;
  soldToCGCityName: string;
  shipToCityName: string;
  customerPurchaseOrderItemNumber: string;
  breakHeat?: string;
  lastHeat?: string;
  finish?: string;
  hasQuantityLastHeat?: string;
  qtyStockLastHeat?: number;
}

export interface StockRealDateItemDto {
  totalStockQuantity: number;
  batch: string;
  dateOfStockEntry: string;
}
export interface DeliveryForecastDto {
  deliveryForecasts: DeliveryForecastItemDto[];
}
export interface DeliveryForecastItemDto {
  salesDocument: number;
  salesDocumentItem: string;
  soldToParty: string;
  material: number;
  plant: string;
  baseUnit: string;
  dtEstimateDeliveryMin: string;
  dtEstimateDeliveryMax: string;
  docQuantity: string;
}

export const mapOpenOrderSummary = (order: OpenedSalesOrderSummaryDto): SalesOrderSummaryItem[] => {
  return mapSalesOrderSummary(order.salesOrdersOnlineSumary);
};

export const mapBilledOrder = (order: BilledSalesOrderDto): SalesOrderItem[] => {
  return mapBilledSalesOrder(order.onlineBillings);
};

export const mapInStockOrder = (order: InStockOrderDto): InStockOrderItem[] => {
  return mapStockOrder(order.stocks);
};
export const mapStockRealDate = (data: StockRealDateDto): StockRealDateItem[] => {
  return mapStockRealDateItem(data.stocks);
};

const mapBilledSalesOrder = (salesOrder: SalesOrderItemDto[]): SalesOrderItem[] => {
  return salesOrder.map(item => {
    const deliveryStatusInfo = getDeliveryStatus(item.dateMaxDeliveryClient, item.visDeliveryMax);

    return {
      product: item.product,
      marketDest: item.marketDest,
      width: item.width,
      alloyClient: item.alloyClient,
      netPrice: item.netPrice,
      ocClient: item.ocClient,
      dateMaxDeliveryClient: item.dateMaxDeliveryClient ? parseOrderDate(item.dateMaxDeliveryClient) : '',
      process: item.process,
      dateSolClient: item.dateSolClient ? parseOrderDate(item.dateSolClient) : '',
      lengthMin: item.lengthMin,
      docQtdOri: item.docQtdOri,
      codGroupRentability: item.codGroupRentability,
      standardSteel: item.standardSteel,
      standardRef: item.standardRef,
      dateDeliveryClient: item.dateDeliveryClient ? parseOrderDate(item.dateDeliveryClient) : '',
      invoiceType: item.invoiceType,
      docCategory: item.docCategory,
      lengthMax: item.lengthMax,
      docQtdT: item.docQtdT,
      docDate: item.docDate ? parseOrderDate(item.docDate) : '',
      standardProdId: item.standardProdId,
      plant: item.plant,
      plantName: item.plantName,
      descMaterial: item.descMaterial,
      clientNumber: item.clientNumber,
      taker: item.taker,
      visDeliveryMax: item.visDeliveryMax ? parseOrderDate(item.visDeliveryMax) : '',
      lengthMult: item.lengthMult,
      shape: item.shape,
      material: item.material,
      diameter: item.diameter,
      qtdConf: item.qtdConf,
      ocClientItem: item.ocClientItem,
      invoiceDate: item.invoiceDate ? parseOrderDate(item.invoiceDate) : '',
      brand: item.brand,
      ov: item.ov,
      codMaterialClient: item.codMaterialClient,
      alloyGsb: item.alloyGsb,
      dtStandardRef: item.dtStandardRef ? parseOrderDate(item.dtStandardRef) : '',
      visDeliveryMin: item.visDeliveryMin ? parseOrderDate(item.visDeliveryMin) : '',
      status: item.status,
      invoice: item.invoice,
      dateOfDeparture: item.dateOfDeparture ? parseOrderDate(item.dateOfDeparture) : '',
      qtdSolClient: item.qtdSolClient,
      thickness: item.thickness,
      numDocument: item.numDocument,
      boarding: item.boarding,
      nameClient: item.nameClient,
      numDeliveryItem: item.numDeliveryItem,
      bookingId: item.bookingId,
      bookingName: item.bookingName,
      processId: item.processId,
      ETD: item.ETD ? parseOrderDate(item.ETD) : '',
      ETA: item.ETA ? parseOrderDate(item.ETA) : '',
      ETC: item.ETC ? parseOrderDate(item.ETC) : '',
      freetimeDestination: item.freetimeDestination,
      shipName: item.shipName,
      dateDeliveryForecast: visibilityDateFormatter(
        item.visDeliveryMin,
        item.visDeliveryMax,
        item.status,
        deliveryStatusInfo,
      ),
      deliveryStatus: deliveryStatusInfo,
      docNum: item.docNum,
      numDelivery: item.numDelivery,
      itemOv: item.itemOv,
      truck: item.ov,
      transportDocument: item.transportDocument,
      invoiceEmissionDate: item.invoiceEmissionDate,
      CRT: item.CRT,
      CRTDate: item.CRTDate ? parseOrderDate(item.CRTDate) : '',
      takerId: item.takerId,
      concatenatedBillingDocument: item.concatenatedBillingDocument,
      activeSector: item.activeSector,
      batch: item.batch,
      truckPlate: item.truckPlate,
      heat: item.heat,
      typeOfVehicle: item.typeOfVehicle,
      carrierName: item.carrierName,
      ovType: item.ovType,
      customerPurchaseOrderItemNumber: item.customerPurchaseOrderItemNumber,
      standardGradeIssueDateStandardGrade: item.standardGradeIssueDateStandardGrade,
    };
  });
};

const mapSalesOrderSummary = (salesOrder: SalesOrderSummaryItemDto[]): SalesOrderSummaryItem[] => {
  return salesOrder.map(item => {
    return {
      product: item.product,
      marketDest: item.marketDest,
      width: item.width,
      alloyClient: item.alloyClient,
      netPrice: item.netPrice,
      ocClient: item.ocClient,
      dateMaxDeliveryClient: parseOrderDate(item.dateMaxDeliveryClient),
      process: item.process,
      dateSolClient: parseOrderDate(item.dateSolClient),
      dateProcessJob: dateParser({ unformattedDate: item.dateProcessJob, hideHours: false, utc: false }),
      lengthMin: item.lengthMin,
      docQtdOri: item.docQtdOri,
      codGroupRentability: item.codGroupRentability,
      standardSteel: item.standardSteel,
      standardRef: item.standardRef,
      dateDeliveryClient: parseOrderDate(item.dateDeliveryClient),
      invoiceType: item.invoiceType,
      docCategory: item.docCategory,
      invoiceItem: item.invoiceItem,
      lengthMax: item.lengthMax,
      docDate: item.docDate ? parseOrderDate(item.docDate) : '',
      standardProdId: item.standardProdId,
      plant: item.plant,
      plantName: item.plantName,
      descMaterial: item.descMaterial,
      clientNumber: item.clientNumber,
      taker: item.taker,
      visDeliveryMax: item.visDeliveryMax ? parseOrderDate(item.visDeliveryMax) : '',
      lengthMult: item.lengthMult,
      shape: item.shape,
      material: item.material,
      diameter: item.diameter,
      qtdConf: item.qtdConf,
      ocClientItem: item.ocClientItem,
      invoiceDate: item.invoiceDate,
      brand: item.brand,
      ov: item.ov,
      codMaterialClient: item.codMaterialClient,
      alloyGsb: item.alloyGsb,
      dtStandardRef: item.dtStandardRef ? parseOrderDate(item.dtStandardRef) : '',
      visDeliveryMin: item.visDeliveryMin ? parseOrderDate(item.visDeliveryMin) : '',
      status: item.status,
      invoice: item.invoice,
      dateOfDeparture: item.dateOfDeparture,
      qtdSolClient: item.qtdSolClient,
      thickness: item.thickness,
      numDocument: item.numDocument,
      boarding: item.boarding,
      nameClient: item.nameClient,
      numDeliveryItem: item.numDeliveryItem,
      bookingId: item.bookingId,
      bookingName: item.bookingName,
      processId: item.processId,
      ETD: item.ETD ? parseOrderDate(item.ETD) : '',
      ETA: item.ETA ? parseOrderDate(item.ETA) : '',
      ETC: item.ETC ? parseOrderDate(item.ETC) : '',
      freetimeDestination: item.freetimeDestination,
      shipName: item.shipName,
      dateDeliveryForecast: visibilityDateFormatter(
        item.visDeliveryMin,
        item.visDeliveryMax,
        item.status,
        DeliveryStatusMap[item.statusFront],
      ),
      deliveryStatus: DeliveryStatusMap[item.statusFront],
      docNum: item.docNum,
      numDelivery: item.numDelivery,
      itemOv: item.itemOv,
      truck: item.ov,
      transportDocument: item.transportDocument,
      invoiceEmissionDate: item.invoiceEmissionDate,
      CRT: item.CRT,
      CRTDate: item.CRTDate,
      qtyTotalBilled: item.qtyTotalBilled,
      qtyBillingOpen: item.qtyBillingOpen,
      qtyDelivery: item.qtyDelivery,
      qtyStock: item.qtyStock,
      qtyTotalProduction: item.qtyTotalProduction,
      activateNotificationCol: item.activateNotification,
      activateShipping: item.activateShipping,
      dtConfirmedStock: parseOrderDate(item.dtConfirmedStock),
      dtCustomerRequiredOriginal: parseOrderDate(item.dtCustomerRequiredOriginal),
      qtyNotAllocated: item.qtyNotAllocated,
      qtyTotalStatus: item.qtyTotalStatus,
      qtdeDatasCartCli: item.qtdeDatasCartCli,
      dateUpdate: item.dateUpdate,
      activateCritical: item.activateCritical,
      customerPurchaseOrderItemNumber: item.customerPurchaseOrderItemNumber,
      standardGradeIssueDateStandardGrade: item.standardGradeIssueDateStandardGrade,
      dtPurchase: item.dtPurchase,
    };
  });
};

const mapStockOrder = (stockOrder: InStockOrderItemDto[]): InStockOrderItem[] => {
  return stockOrder.map(item => {
    return {
      salesOrderStatus: item.salesOrderStatus,
      shipToPartyName: item.shipToPartyName,
      materialName: item.materialName,
      customerRequiredDate: parseOrderDate(item.customerRequiredDate),
      material: item.material,
      agreedQuantity: item.agreedQuantity,
      storageLocation: item.storageLocation,
      customerAgreedDate: parseOrderDate(item.customerAgreedDate),
      language: item.language,
      soldToPartyName: item.soldToPartyName,
      shipToParty: item.shipToParty,
      customerPurchaseOrderItem: item.customerPurchaseOrderItem,
      customerMaterialCode: item.customerMaterialCode,
      salesDocumentItem: item.salesDocumentItem,
      soldToParty: item.soldToParty,
      salesDocument: item.salesDocument,
      plant: item.plant,
      plantName: item.plantName,
      customerPurchaseOrderNumber: item.customerPurchaseOrderNumber,
      confirmedShippingDate: parseOrderDate(item.confirmedShippingDate),
      purchaseDate: parseOrderDate(item.purchaseDate),
      billingOpenQuantity: item.billingOpenQuantity,
      delivPendQtyGen: item.delivPendQtyGen,
      deliveryQuantity: item.deliveryQuantity,
      totalBilledQuantity: item.totalBilledQuantity,
      pickingQuantity: item.pickingQuantity,
      status: item.status,
      stockQuantity: item.totalStockQuantity,
      qtyCustomerRequiredTons: item.qtyCustomerRequiredTons,
      batch: item.batch,
      heat: item.heat,
      typeStock: item.typeStock,
      dateOfStockEntry: parseOrderDate(item.dateOfStockEntry),
      qtyDateOfStockEntry: item.qtyDateOfStockEntry,
      soldToCGCityName: item.soldToCGCityName,
      shipToCityName: item.shipToCityName,
      customerPurchaseOrderItemNumber: item.customerPurchaseOrderItemNumber,
      breakHeat: item.breakHeat,
      lastHeat: item.lastHeat,
      finish: item.finish,
      hasQuantityLastHeat: item.hasQuantityLastHeat,
      qtyStockLastHeat: item.qtyStockLastHeat,
    };
  });
};

export const mapStockRealDateItem = (stocks: StockRealDateItemDto[]): StockRealDateItem[] => {
  return stocks.map(item => ({
    totalStockQuantity: item.totalStockQuantity,
    batch: item.batch,
    dateOfStockEntry: item.dateOfStockEntry,
  }));
};

export const mapDeliveryForecastItem = (res: DeliveryForecastDto): DeliveryForecastItem[] => {
  return res.deliveryForecasts.map(item => ({
    salesDocument: item.salesDocument,
    salesDocumentItem: item.salesDocumentItem,
    soldToParty: item.soldToParty,
    material: item.material,
    plant: item.plant,
    baseUnit: item.baseUnit,
    dtEstimateDeliveryMin: parseOrderDate(item.dtEstimateDeliveryMin),
    dtEstimateDeliveryMax: parseOrderDate(item.dtEstimateDeliveryMax),
    docQuantity: item.docQuantity,
  }));
};
const getDeliveryStatus = (maxDeliveryDate: string, maxVisibilityDate: string) => {
  if (!maxDeliveryDate || !maxVisibilityDate) {
    return null;
  }
  const dateMaxDelivery = new Date(maxDeliveryDate);
  const dateMaxVisibility = new Date(maxVisibilityDate);

  return dateMaxVisibility.getTime() > dateMaxDelivery.getTime() ? DeliveryStatus.Delay : DeliveryStatus.OnTime;
};

const visibilityDateFormatter = (
  minVisibilityDate: string,
  maxVisibilityDate: string,
  status,
  deliveryStatusInfo,
): string => {
  if (status !== 'Confirmado') {
    return 'Aguardando confirmação do pedido';
  }

  if (status === 'Confirmado' && deliveryStatusInfo === 'noForecast') {
    return 'Sem Previsão - Consultar Gerdau';
  }

  return parseOrderDate(minVisibilityDate) + ' - ' + parseOrderDate(maxVisibilityDate);
};
