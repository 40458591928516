import * as React from 'react';
import { LogAnalytics } from '@app/core/analytics';
import {
  CommentsFullData,
  InversedAttributeStatus,
  QuoteAttributeRow,
  QuoteAttributes,
  QuoteAttributeTypes,
} from '@app/models/quote.model';
import { TechnicalAnalysusAttributeEvents } from '@app/modules/quote/quote.analytics';

interface TechnicalAnalysisAttributeContextState {
  isEditing: boolean;
  editedStatus: string;
  rowsStatus: DerogateRowValues[];
  comments: CommentsFullData;
  setIsEditing: (value: boolean) => void;
  onAttributeStatusChange: (status: string) => void;
  setAttribute: (attribute: QuoteAttributes) => void;
  onRowStatusChanged: (status: string, index: number) => void;
  onRowReasonChanged: (reason: string, index: number) => void;
  onRowSuggestionChanged: (suggestion: string | number | string[], index: number, attribute?: string) => void;
  onRowMinChanged: (min: string, index: number, status?: string) => void;
  onRowMaxChanged: (max: string, index: number, status?: string) => void;
  setComments: (response: CommentsFullData) => void;
  setEditedStatus: (status: string) => void;
  checkStatusOption: (options: { value: string; name: string }[]) => void;
}

export interface DerogateRowValues {
  key: string;
  status: string;
  reason: string;
  suggestion?: string;
  minSuggestion?: string;
  maxSuggestion?: string;
  colSpan?: number;
  rowSpan?: number;
}

enum TableColumns {
  Status = 'status',
  Reason = 'reason',
  Suggestion = 'suggestion',
}

export const TechnicalAnalysisAttributeContext = React.createContext<TechnicalAnalysisAttributeContextState>(null);

export const TechnicalAnalysisAttributeProvider = props => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedStatus, setEditedStatus] = React.useState('');
  const [rowsStatus, setRowStatus] = React.useState<DerogateRowValues[]>([]);
  const [comments, setComments] = React.useState<CommentsFullData>();

  const getValue = (row: QuoteAttributeRow[], column: string) => {
    return row.find(item => item.columnRef === column)?.value;
  };

  const setAttribute = (attribute: QuoteAttributes) => {
    if (attribute.type === QuoteAttributeTypes.Table) {
      setRowStatus(
        attribute.attributeValue.rows.map(row => {
          const status = getValue(row, TableColumns.Status);
          return {
            key: row[0]?.key,
            status: status ? status : attribute.status,
            reason: getValue(row, TableColumns.Reason),
            suggestion: getValue(row, TableColumns.Suggestion),
            minSuggestion: getValue(row, TableColumns.Suggestion)?.min,
            maxSuggestion: getValue(row, TableColumns.Suggestion)?.max,
          };
        }),
      );
    } else {
      setRowStatus([]);
    }
  };

  const onAttributeStatusChange = (status: string) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.AttributeStatusChange });

    if (status !== InversedAttributeStatus.derogate) {
      const updatedRows = [...rowsStatus].map(row => {
        const updatedRow = row;
        updatedRow.status = status;
        updatedRow.reason = status === 'derogate' ? status : undefined;
        updatedRow.suggestion = undefined;
        return updatedRow;
      });

      setRowStatus(updatedRows);
    }
    setEditedStatus(status);
  };

  const onRowStatusChanged = (status: string, index: number) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowStatusChange });
    const rows = [...rowsStatus];
    const row = rowsStatus[index];
    row.status = status;
    row.maxSuggestion = null;
    row.minSuggestion = null;
    row.reason = status === 'derogate' ? status : undefined;
    row.suggestion = undefined;
    rows.splice(index, 1, row);
    setRowStatus(rows);
  };

  const onRowReasonChanged = (reason: string, index: number) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowReasonChange });
    const rows = [...rowsStatus];
    const row = rowsStatus[index];
    row.reason = reason;
    rows.splice(index, 1, row);
    setRowStatus(rows);
  };

  const convertToNumber = (value: number | string) => {
    if (value?.toString().includes(',')) {
      return value.toString().replace(',', '.');
    }
    return value.toString();
  };

  const onRowSuggestionChanged = (suggestion: string, index: number, status?: string) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowSuggestionChange });

    if (status) {
      onRowStatusChanged(status, index);
      onRowReasonChanged(status, index);
    }

    const rows = [...rowsStatus];
    const row = rowsStatus[index];
    row.suggestion = convertToNumber(suggestion);
    row.reason = status;
    rows.splice(index, 1, row);
    setRowStatus(rows);
  };

  const onRowMinChanged = (min: string, index: number, status?: string) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowMinChange });
    const rows = [...rowsStatus];
    const row = rowsStatus[index];
    row.minSuggestion = min;
    row.reason = status === 'derogate' ? status : null;
    rows.splice(index, 1, row);
    setRowStatus(rows);
  };

  const onRowMaxChanged = (max: string, index: number, status?: string) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.TableRowMaxChange });
    const rows = [...rowsStatus];
    const row = rowsStatus[index];
    row.maxSuggestion = max;
    row.reason = status === 'derogate' ? status : null;
    rows.splice(index, 1, row);
    setRowStatus(rows);
  };

  const checkStatusOption = options => {
    if (editedStatus === 'non-analysed' || editedStatus === 'accepted') {
      return options.filter(item => item.value !== 'derogate');
    } else {
      return options;
    }
  };

  const technicalAnalysisAttributeValue: TechnicalAnalysisAttributeContextState = {
    isEditing,
    editedStatus,
    setIsEditing,
    onAttributeStatusChange,
    rowsStatus,
    setAttribute,
    onRowStatusChanged,
    onRowReasonChanged,
    onRowSuggestionChanged,
    onRowMaxChanged,
    onRowMinChanged,
    comments,
    setComments,
    setEditedStatus,
    checkStatusOption,
  };

  return (
    <TechnicalAnalysisAttributeContext.Provider value={technicalAnalysisAttributeValue}>
      {props.children}
    </TechnicalAnalysisAttributeContext.Provider>
  );
};
