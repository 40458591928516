import React from 'react';
import { CheckboxField } from '@atomic/atm.checkbox';
import { TagRadioField } from '@atomic/atm.tag-radio';
import { TextField } from '@atomic/atm.text-field';
import { Separator } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';
import { FormResultItems } from '../result/form-result.component';
import { CheckBoxWrapper, CustomFieldWrapper } from '../result/form-result.component.style';

export const CheckBoxField = (props: FormFlowProps) => {
  const [otherValue, setOtherValue] = React.useState(false);
  //const [provValue, setProvValue] = React.useState([]);
  const { formResult, strings } = React.useContext(FormContext);
  const stringsNewForm = strings.newForm.messages;

  function checkOtherValue(value: string[]) {
    if (value) {
      if (value.includes('OTHER')) {
        setOtherValue(true);
      } else if (value.length === 0) {
        setOtherValue(false);
      } else {
        setOtherValue(false);
        props.handleValue(value, 'checkbox', props.field.key, props.stepKey);
      }
    } else {
      setOtherValue(false);
    }
  }

  const checkValidator = (value: FormResultItems[], mandatory: boolean) => {
    if (mandatory) {
      if (value) {
        return [];
      }
      return [Validators.Required(stringsNewForm.mandatoryAlert)];
    } else {
      return [];
    }
  };

  return (
    <>
      <Form.Field
        name={props.field.key}
        onValueChange={value => checkOtherValue(value)}
        validators={checkValidator(props.field?.value, props.field?.mandatory)}
        initialValue={
          formResult !== null && formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value
            ? formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value
            : []
        }
      >
        <CheckBoxWrapper>
          {props.field?.options.map((option, index) =>
            option.key !== 'OTHER' ? (
              <CheckboxField data-testid={option.key} key={`${option.key}-${index}`} id={option.label}>
                <span data-testid={`option${option.key}`}>{option.label}</span>
              </CheckboxField>
            ) : (
              <>
                <Separator />
                <TagRadioField
                  data-testid={option.key}
                  key={`${option.key}-${index}`}
                  id={option.key}
                  type='success'
                  disabled={false}
                >
                  <span data-testid={`option${option.key}`}>{option.label}</span>
                </TagRadioField>
              </>
            ),
          )}
        </CheckBoxWrapper>
      </Form.Field>

      {otherValue && (
        <Form.Field
          name={props.field.key}
          validators={props?.field?.mandatory ? [Validators.Required(stringsNewForm.mandatoryAlert)] : []}
          initialValue={
            formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value.length > 0
              ? formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value.join(', ')
              : null
          }
        >
          <Separator />
          <CustomFieldWrapper>
            <TextField
              id={props.field.key}
              placeholder={props?.field?.placeholder ? props.field.placeholder : stringsNewForm.otherFieldPlaceholder}
              onBlur={value => props.handleValue([value.target.value], 'checkbox', props.field.key, props.stepKey)}
            ></TextField>
          </CustomFieldWrapper>
        </Form.Field>
      )}
    </>
  );
};
