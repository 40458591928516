import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const ErrorHandlerModalHeader = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  background: ${Color.Primary};
  flex: 1;
  height: 72px;
`;

export const ErrorHandlerCloseButton = styled.div`
  position: absolute;
  top: 29px;
  right: 29px;
  cursor: pointer;
`;

export const LogosWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${Spacing.XLarge};
  padding-bottom: ${Spacing.XLarge};
`;

export const LogoDefaultWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 272px;
`;

export const LogoSeparator = styled.div`
  height: 50px;
  width: 1px;
  border-left: 1px solid ${Color.Primary};
  margin-left: 50px;
  margin-right: 40px;
`;

export const ErrorHandlerMessageWrapper = styled.div`
  max-width: 763px;
`;

export const ErrorHandlerModalFooter = styled.div`
  padding-top: ${Spacing.Medium};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid ${Color.GrayLight};
`;
