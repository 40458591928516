import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { Image } from '@atomic/atm.image';
import { H3 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { NotificationStepWrapperStyled } from './notification-center.style';

interface NotificationStepButtonProps {
  text: string;
  onClick: () => void;
}

export const NotificationStepButton: React.FC<NotificationStepButtonProps> = props => {
  return (
    <NotificationStepWrapperStyled onClick={props.onClick}>
      <Frame rounded padded>
        <FlexRow>
          <FlexColumn noGrow>
            <Image.NotificationIcon />
          </FlexColumn>
          <FlexRow.Separator />
          <FlexColumn>
            <FlexRow vAlign='center'>
              <H3>{props.text}</H3>
            </FlexRow>
          </FlexColumn>
          <FlexColumn vAlign='center' noGrow>
            <FaIcon.ChevronRight color={Color.Primary} />
          </FlexColumn>
        </FlexRow>
      </Frame>
    </NotificationStepWrapperStyled>
  );
};
