import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderPriorizationDataSource } from '@app/data/http/order-priorization.datasource';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { SLICE_NAME } from '../../order-priorization.constants';

export const getCriticalItemRules = createAsyncThunk(`${SLICE_NAME}/getCriticalItemRules`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const customerIds: string = customerIdsJoinedSelector(state);

  return OrderPriorizationDataSource.getCriticalItemRules({ customerIds })
    .then(resp => resp)
    .catch(err => err.message);
});

export const addGetCriticalItemRulesReducers = builder => {
  builder.addCase(getCriticalItemRules.pending, state => {
    state.criticalItemInfoLoading = true;
  });
  builder.addCase(getCriticalItemRules.fulfilled, (state, action) => {
    const { payload } = action;
    state.criticalItemInfo = payload;
    state.criticalItemInfoLoading = false;
  });
  builder.addCase(getCriticalItemRules.rejected, state => {
    state.criticalItemInfoLoading = false;
  });
};
