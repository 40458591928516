import styled from 'styled-components';
import { FlexColumn } from '@atomic/obj.box';
import { Color, FontSize } from '@atomic/obj.constants';

export const DateErrorWrapper = styled(FlexColumn)`
  font-size: ${FontSize.XSmall};
  color: ${Color.Alert};
  align-items: flex-start;
  padding-left: 3%;
  height: 18px;
  margin-top: 4px;
`;
