import React from 'react';
import { useDispatch } from 'react-redux';
import { IOrderUpdateTableData } from '@app/modules/order-update/order-update.interfaces';
import { deleteItem } from '@app/modules/order-update/store/order-update.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';

export interface IOrderUpdateDeleteButtonCellProps {
  item: IOrderUpdateTableData;
  disabled: boolean;
}
export const OrderUpdateDeleteButtonCell: React.FC<IOrderUpdateDeleteButtonCellProps> = ({ item, disabled }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    if (!disabled) {
      dispatch(deleteItem({ ov: item.ov, itemOv: item.itemOv }));
    }
  };

  return <FaIcon.Trash clickable={!disabled} color={disabled ? Color.Gray : Color.Alert} onClick={onClick} />;
};
