import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { RootState } from '@app/core/redux/store';
import { DashboardDataSource } from '@app/data/http/dashboard.datasource';
import { useGetDashboard } from '@app/domain/get-dashboard.use-case';
import { DashboardGraphName, DashboardLegendData, FinanceMatchedMatchesGraphs } from '@app/models/dashboard.model';
import { setReloadConfirmedDashboard } from '@app/providers/navigation/navigation.store';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Frame } from '@atomic/atm.frame';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { DashboardBlockHeader } from '../dashboard-block-header.component';
import { DashboardBlockShimmer } from '../dashboard-block.shimmer';
import { DashboardGroupLegend } from '../dashboard-group-legend';
import { Strings, IDashBoardStrings } from '../dashboard.string';
import { FinanceBarChartLegend } from './finance-bar-chart-legend-component';

const FRAME_HEIGHT = '480px';
const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = '95%';

const strings: IDashBoardStrings = Strings.pt;
const financeGraphStrings = strings.graphs.finance;

const FINANCE_MM_GRAPH_DATA = 'finance_mm_graph_data';
const DO_REQUESTS_FOR_DASHBOARDS = 'do_requests_for_dashboards';

export const FinanceMatchedMatchesGraph = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [financeMMGraph, setFinanceMMGraphData] = useLocalStorageState(FINANCE_MM_GRAPH_DATA, {});
  const doRequestForDashboards = window.localStorage.getItem(DO_REQUESTS_FOR_DASHBOARDS);

  const { reloadConfirmedDashboard } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();

  const { data, loading, error, performRequest } = useGetDashboard<FinanceMatchedMatchesGraphs>(
    DashboardDataSource.getFinanceMatchedMatches,
    props.onSuccess,
  );

  useEffect(() => {
    if (reloadConfirmedDashboard) {
      performRequest(props.clientNumber);
      dispatch(setReloadConfirmedDashboard(false));
    }
  }, [reloadConfirmedDashboard]);

  useEffect(() => {
    if (props.clientNumber && doRequestForDashboards === 'true') {
      performRequest(props.clientNumber);
      dispatch(setReloadConfirmedDashboard(false));
    }
  }, [props.clientNumber, doRequestForDashboards]);

  useEffect(() => {
    if (data && data.matchedMatches && data.matchedMatches.length > 0) {
      handleMatchedMatchesData();
    }
  }, [data?.matchedMatches]);

  const handleMatchedMatchesData = () => {
    let generalTotalCountAux = 0;
    let documentMonthNumberCountAux = 0;
    let maxGeneralTotalMonthAux = 0;

    if (data?.matchedMatches && data?.matchedMatches?.length) {
      const matchedMatchesAux = [];
      const crescentMatchedMatchesArray = data.matchedMatches.slice(0, 4).reverse();

      crescentMatchedMatchesArray.forEach(m => {
        generalTotalCountAux = generalTotalCountAux + m.generalTotalMonth;
        documentMonthNumberCountAux = documentMonthNumberCountAux + m.documentMonthNumber;

        if (m.generalTotalMonth > maxGeneralTotalMonthAux) {
          maxGeneralTotalMonthAux = m.generalTotalMonth;
        }

        matchedMatchesAux.push({
          compensationMonthDate: m.compensationMonthDate,
          documentMonthNumber: m.documentMonthNumber,
          generalTotalMonth: Math.floor(m.generalTotalMonth),
        });
      });

      const genetalTotalCount = `${Math.round(generalTotalCountAux).toLocaleString(userInfo?.language)}`;

      setFinanceMMGraphData({
        genetalTotalCount,
        documentMonthNumberCount: documentMonthNumberCountAux,
        matchedMatches: matchedMatchesAux,
      });
    }
  };

  const monthlyFinanceMatchedMatchesGraphLegend: DashboardLegendData[] = [
    { title: financeGraphStrings.generalTotal, amount: financeMMGraph?.generalTotalCount, currency: 'R$ ' },
    {
      title: financeGraphStrings.documentNumbers,
      amount: financeMMGraph?.documentMonthNumberCount,
    },
  ];

  const dataFormater = number => {
    return `R$  ${Math.floor(number).toLocaleString(userInfo?.language)}`;
  };

  return (
    <LoadingState loading={loading} data={!!financeMMGraph} error={!!error}>
      <LoadingState.Shimmer>
        <DashboardBlockShimmer blocks={1} />
      </LoadingState.Shimmer>
      <Frame height={FRAME_HEIGHT}>
        <VSeparator />

        <DashboardBlockHeader
          title={strings.finance.matchedMatches}
          description={financeGraphStrings.matchedMatches.description}
        />
        <VSeparator />

        <DashboardGroupLegend
          legend={monthlyFinanceMatchedMatchesGraphLegend}
          graph={DashboardGraphName.MonthlyFinanceMatchedMatchesGraph}
        />
        <VSeparator />

        <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
          <BarChart data={financeMMGraph?.matchedMatches} margin={{ top: 15, left: 60, bottom: 5 }}>
            <XAxis dataKey='compensationMonthDate' />
            <Tooltip formatter={dataFormater} />
            <Bar
              dataKey='generalTotalMonth'
              isAnimationActive={false}
              name={financeGraphStrings.matched}
              stackId='a'
              fill={Color.PinkDark}
              radius={[4, 4, 0, 0]}
            >
              <LabelList dataKey='generalTotalMonth' position='top' formatter={dataFormater} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <VSeparator />
        <FinanceBarChartLegend data={financeMMGraph?.matchedMatches} />
      </Frame>
    </LoadingState>
  );
};
