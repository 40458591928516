import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route } from '@app/core/route';
import { AppPath } from '../app/route-constants';
import { hasCrmTabAccessSelector } from '../auth/store/auth.selectores';
import { CrmPageDetails } from './crm/crm-detail/crm.page.details';
import { CrmPage } from './crm/crm.page';
import { useQuoteGuard } from './quote.guard';
import { QuotePage } from './quote.page';
import { TechnicalAnalysisPageNew } from './technical-analysis-copy/technical-analysis.page';
import { TechnicalAnalysisPage } from './technical-analysis/technical-analysis.page';

export const QuoteRouter: React.FC<{}> = () => {
  const quoteAuthorized = useQuoteGuard();
  const hasCrmTabAccess = useSelector(hasCrmTabAccessSelector);

  return (
    <>
      <Route
        exact
        path={'./'}
        component={QuotePage}
        authorized={quoteAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        exact
        path={AppPath.QUOTE.PENDINGS}
        component={QuotePage}
        authorized={quoteAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        exact
        path={AppPath.QUOTE.DRAFTS}
        component={QuotePage}
        authorized={quoteAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        exact
        path={AppPath.QUOTE.ANALYSIS_DETAIL}
        component={TechnicalAnalysisPage}
        authorized={quoteAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        exact
        path={AppPath.QUOTE.ANALYSIS_DETAIL_NEW}
        component={TechnicalAnalysisPageNew}
        authorized={quoteAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        exact
        path={AppPath.QUOTE.CRM}
        component={CrmPage}
        authorized={quoteAuthorized && hasCrmTabAccess}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        exact
        path={AppPath.QUOTE.CRM_DETAIL}
        component={CrmPageDetails}
        authorized={quoteAuthorized && hasCrmTabAccess}
        redirectPath={AppPath.AUTHENTICATION}
      />
    </>
  );
};
