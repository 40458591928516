import { useState, useEffect } from 'react';

export interface IWindowDimension {
  width: number;
  height: number;
}

export const useScreenSize = () => {
  const [windowSize, setWindowSize] = useState<IWindowDimension>({ width: 0, height: 0 });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
