import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const DocumentWrapper = styled.div`
  padding: ${Spacing.Medium};
  border: 1px solid;
  border-color: ${Color.GrayLight};
  background-color: ${Color.White};
  margin-bottom: ${Spacing.Medium};
  text-align: center;
  & > div > a {
    margin-top: ${Spacing.Small};
  }
  & > div > h3 {
    max-width: 70%;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  & > div > button {
    border: none;
    padding-right: 0;
    color: ${Color.Primary};
    min-height: 30px;
  }
`;

export const AddDocumentButtonWrapper = styled.div`
  padding: 0;
  border: 1px solid;
  border-color: ${Color.GrayLight};
  background-color: ${Color.White};
  margin-bottom: ${Spacing.Medium};
  text-align: center;
  justify-content: center;
  height: 130px;
  & > button {
    width: 100%;
    height: 100%;
  }
`;
