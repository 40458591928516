export const customerList = [
  'AFTERMARKET',
  'CAT',
  'GM',
  'JOHN DEERE',
  'MAN',
  'MBB',
  'RANDON',
  'SCANIA',
  'STELLANTIS',
  'TOYOTA',
  'VOLVO',
  'VW',
  'AGCO',
  'AGRALE',
  'CASE',
  'CNH',
  'DAF',
  'FACHINI',
  'FORD',
  'HONDA',
  'HYUNDAI',
  'IMPLEMENTO DE MÁQUINAS',
  'IVECO',
  'NEW HOLLAND',
  'PEUGEOUT',
  'RENAULT',
  'STARA',
];
