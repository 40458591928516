import * as React from 'react';
import { AttributesObjectItem, AttributesRangeUnit, AttributesRangeValue } from '@app/models';
import { QuoteAttributeTypes } from '@app/models/quote.model';
import { rangeLimits } from '@app/modules/chat/chat.contants';
import { localToInternationalDate } from '@app/utils/date-parser';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { MultipleSelectField } from '@atomic/atm.multiple-select';
import { SelectField } from '@atomic/atm.select';
import { Hbox } from '@atomic/obj.box';
import { Keys } from '@atomic/obj.constants';
import { InlineLinkStyled, InlineTextFieldStyle } from './inline-edit.component.style';

interface InlineEditProps {
  value?: boolean | string | AttributesObjectItem;
  rangedValue?: AttributesRangeValue;
  unit?: string;
  type?: string;
  options?: { key: string; label: string }[];
  editable: boolean;
  deletable: boolean;
  onCommitValue?: (value: string, unit: string) => void;
  onRemove: () => void;
  disableAction: boolean;
  attrName: string;
  strings?: any;
}

const Option = ({ value, name, selected }: { value: any; name: any; selected?: boolean }) => (
  <option value={value} selected={selected}>
    {name}
  </option>
);

export const InlineEdit: React.FC<InlineEditProps> = props => {
  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState(props.value);
  const [tempValue, setTempValue] = React.useState(props.value);
  const [tempRangedValue, setTempRangedValue] = React.useState(props.rangedValue);
  const [tempKey, setTempKey] = React.useState<string>();
  const [valueChanged, setValueChanged] = React.useState(false);

  const strings = props.strings;

  const formatStringValueRange = (val?: string | number) => {
    if (val) {
      const newMask = val.toString();
      if (val.toString() === rangeLimits.maxString) {
        return '-';
      }
      if (newMask.toString() === rangeLimits.minString) {
        return '-';
      }
      return newMask;
    } else if (val === null) {
      return '-';
    }
    return val;
  };

  const formatStringRange = (val: boolean | string | AttributesObjectItem, attr?: string) => {
    if (typeof val === 'boolean') {
      return val ? strings.basicOption.yes : strings.basicOption.no;
    }
    const newMask = val.toString().split(' a ');

    if (attr === 'AUSTENITE_GRAIN_SIZE' && (newMask[0] === '0' || newMask[1] === '0')) {
      //return format_AUSTENITE_GRAIN_SIZE(newMask);
      const newMin = newMask[0] ? formatStringValueRange(newMask[0]) : '';
      const newMax = newMask[1] ? ` ${strings.prep} ` + formatStringValueRange(newMask[1]) : '';
      const newString = newMin + newMax;
      return newString;
    } else if (attr === 'SECUNDARY_DIAMETER') {
      return newMask[0]?.toLowerCase();
    } else if (
      (attr === 'PARTIAL_DECARBURIZING' || attr === 'TOTAL_DECARBURIZING' || attr === 'GLOBAL_DECARBURIZING') &&
      val === '0'
    ) {
      const numberValue = +newMask;
      return numberValue.toFixed(2);
    } else {
      const newMin = newMask[0] ? formatStringValueRange(newMask[0]) : '';
      const newMax = newMask[1] ? ` ${strings.prep} ` + formatStringValueRange(newMask[1]) : '';
      const newString = newMin + newMax;
      return newString;
    }
  };

  React.useEffect(() => {
    setValue(props.value);

    setTempValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    let valResponse;

    if (props.options) {
      if (typeof value === 'string') {
        valResponse = value.split(', ');
      } else {
        valResponse = value;
      }
    }

    props.options &&
      setTempKey(
        value !== undefined &&
          valResponse
            .reduce((acc, cur) => {
              if (
                props.options.map(opt => opt.label).findIndex(item => item.toLowerCase() === cur.toLowerCase()) >= 0
              ) {
                acc.push(
                  props.options[
                    props.options.map(opt => opt.label).findIndex(item => item.toLowerCase() === cur.toLowerCase())
                  ].key,
                );
              }
              return acc;
            }, [])
            .toString(),
      );
  }, [value]);

  const handleValueChange = (newValue: string) => {
    setTempValue(newValue);
    setValueChanged(true);
  };

  const handleValueSelected = (newValue: string) => {
    setTempKey(newValue);
    setValueChanged(true);
  };

  const handleMinRangedValueSelected = (newValue: number) => {
    setTempRangedValue(prevValue => ({
      min: newValue ? newValue : null,
      max: prevValue?.max ? prevValue.max : null,
    }));
    setValueChanged(true);
  };

  const handleMaxRangedValueSelected = (newValue: number) => {
    setTempRangedValue(prevValue => ({
      min: prevValue?.min ? prevValue.min : null,
      max: newValue ? newValue : null,
    }));
    setValueChanged(true);
  };

  const handleSetEditing = (editMode: boolean) => () => {
    if (props.editable) {
      setEditing(editMode);
      setTempValue(props.value);
    }
  };

  const verifyValue = (val: boolean | string | AttributesObjectItem) => {
    if (!val || val === rangeLimits.minString || val === '-') {
      return rangeLimits.minString;
    } else {
      return `${val}`;
    }
  };

  const handleFinishEdit = () => {
    let newValue: string;

    if (props.type === QuoteAttributeTypes.Range) {
      const min = !tempRangedValue.min || tempRangedValue.min == null ? rangeLimits.min : tempRangedValue.min;
      const max = !tempRangedValue.max || tempRangedValue.max == null ? rangeLimits.max : tempRangedValue.max;
      newValue = `${min} - ${max}`;
    } else if (props.type === QuoteAttributeTypes.Date) {
      newValue = new Date(localToInternationalDate(tempValue.toString())).toISOString();
    } else {
      newValue = verifyValue(tempValue);
    }

    setValue(newValue);
    setEditing(false);
    setValueChanged(false);

    if (props.onCommitValue) {
      props.onCommitValue(tempKey?.toString() || newValue, convertUnit(props?.unit));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === Keys.Enter) {
      handleFinishEdit();
    } else if (event.keyCode === Keys.Esc) {
      setEditing(false);
    }
  };

  const handleRemove = () => {
    props.onRemove();
  };

  const convertUnit = (val: string | AttributesRangeUnit) => {
    if (val) {
      if (typeof val === 'string') {
        return val;
      } else {
        return val.min ? val.min : val.max;
      }
    }
    return '';
  };

  return (
    <>
      {editing ? (
        <Hbox>
          <Hbox.Item>
            {props.options ? (
              props.type === QuoteAttributeTypes.TextArray ? (
                <MultipleSelectField options={props.options} setSelected={handleValueSelected} selected={tempKey} />
              ) : (
                <SelectField value={tempKey} onValueChange={handleValueSelected}>
                  <Option value='' name='' />
                  {props.options?.map(option => (
                    <Option key={option.key} value={option.key} name={option.label} />
                  ))}
                </SelectField>
              )
            ) : props.type === QuoteAttributeTypes.Boolean ? (
              <SelectField value={tempKey} onValueChange={handleValueSelected}>
                <Option value={true} name={strings.basicOption.yes} selected={props.value === 'Sim'} />
                <Option value={false} name={strings.basicOption.no} selected={props.value === 'Não'} />
              </SelectField>
            ) : props.type === QuoteAttributeTypes.Range ? (
              <Hbox>
                <Hbox.Item>
                  <InlineTextFieldStyle
                    value={tempRangedValue?.min}
                    type='number'
                    onValueChange={handleMinRangedValueSelected}
                    onKeyDown={handleKeyDown}
                    autoFocus
                  />
                </Hbox.Item>
                <Hbox.Separator />
                <Hbox.Item>
                  <InlineTextFieldStyle
                    value={tempRangedValue?.max}
                    type='number'
                    onValueChange={handleMaxRangedValueSelected}
                    onKeyDown={handleKeyDown}
                    autoFocus
                  />
                </Hbox.Item>
              </Hbox>
            ) : props.type === QuoteAttributeTypes.Number ? (
              <InlineTextFieldStyle
                value={tempValue}
                type='number'
                onValueChange={handleValueChange}
                onKeyDown={handleKeyDown}
                autoFocus
              />
            ) : (
              <InlineTextFieldStyle
                value={tempValue}
                onValueChange={handleValueChange}
                onKeyDown={handleKeyDown}
                autoFocus
              />
            )}
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item noGrow>
            <Button kind='primary' onClick={handleFinishEdit} disabled={!valueChanged || tempKey?.length === 0}>
              <FaIcon.Check />
            </Button>
          </Hbox.Item>
          <Hbox.Item noGrow>
            <Button kind='secondary' onClick={handleSetEditing(false)}>
              <FaIcon.Close />
            </Button>
          </Hbox.Item>
        </Hbox>
      ) : (
        <Hbox>
          <Hbox.Item>
            <InlineLinkStyled onClick={handleSetEditing(true)}>
              {formatStringRange(tempValue, props.attrName)} {convertUnit(props.unit)}
            </InlineLinkStyled>
          </Hbox.Item>
          <Hbox.Separator />
          {props.editable && (
            <Hbox.Item noGrow>
              <Button kind='link' onClick={handleSetEditing(true)} disabled={props.disableAction}>
                <FaIcon.Pen />
              </Button>
            </Hbox.Item>
          )}
          {props.deletable && (
            <Hbox.Item noGrow>
              <Button kind='link' onClick={handleRemove} disabled={props.disableAction}>
                <FaIcon.Trash />
              </Button>
            </Hbox.Item>
          )}
        </Hbox>
      )}
    </>
  );
};
