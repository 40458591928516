import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { NotificationActivationItem } from '@app/data/http/notification.dto';
import { CriticalItemDto } from '@app/data/http/order-priorization.dto';
import { SalesOrderSummaryItem } from '@app/models';
import { OrderAction } from '@app/models/order.model';
import { IOrderTabOpenStrings, Strings } from '../order-tab-open.string';
import { isEditCriticalItem } from './handler-utils';
import {
  disableActivateNotificationButton,
  disableDeactivateNotificationButton,
  disableActivateShippingConfigButton,
  disableDeactivateShippingConfigButton,
} from './notification-utils';

export const mapSelectedRows = (selectedRows: SalesOrderSummaryItem[]): NotificationActivationItem[] => {
  return selectedRows.map(item => {
    return {
      salesDocument: item.ov,
      salesDocumentItem: item.itemOv,
      soldToPartyCG: item.clientNumber.toString(),
    };
  });
};

interface GetOpenMIOrderActionsParams {
  strings: IOrderTabOpenStrings;
  setShowOrderUpdate: (value: boolean) => void;
  selectedRows: SalesOrderSummaryItem[];
  activateNotifications: (activateNotificationsParams: { items: NotificationActivationItem[] }) => void;
  deactivateNotifications: (activateNotificationsParams: { items: NotificationActivationItem[] }) => void;
  activateShippingConfig: (activateNotificationsParams: { items: NotificationActivationItem[] }) => void;
  deactivateShippingConfig: (activateNotificationsParams: { items: NotificationActivationItem[] }) => void;
  setShowOrderPriorization: (value: boolean) => void;
  activedCriticalItem: CriticalItemDto[];
}

export const getOpenMIOrderActions = ({
  strings,
  setShowOrderUpdate,
  selectedRows,
  activateNotifications,
  deactivateNotifications,
  activateShippingConfig,
  deactivateShippingConfig,
  setShowOrderPriorization,
  activedCriticalItem,
}: GetOpenMIOrderActionsParams): OrderAction[] => {
  return [
    {
      title: strings.actions.orderUpdate,
      icon: 'pen',
      action: () => {
        setShowOrderUpdate(true);
      },
    },
    {
      title: strings.actions.activateNotification,
      icon: 'bell',
      action: () => activateNotifications({ items: mapSelectedRows(selectedRows) }),
      disabled: disableActivateNotificationButton(selectedRows),
      isNew: true,
    },
    {
      title: strings.actions.deactivateNotification,
      icon: 'bell-slash',
      action: () => deactivateNotifications({ items: mapSelectedRows(selectedRows) }),
      disabled: disableDeactivateNotificationButton(selectedRows),
    },
    {
      title: strings.actions.activateShippingConfig,
      icon: 'file-import',
      action: () => activateShippingConfig({ items: mapSelectedRows(selectedRows) }),
      disabled: disableActivateShippingConfigButton(selectedRows),
    },
    {
      title: strings.actions.deactivateShippingConfig,
      icon: 'file-import',
      action: () => deactivateShippingConfig({ items: mapSelectedRows(selectedRows) }),
      disabled: disableDeactivateShippingConfigButton(selectedRows),
    },
    {
      title: isEditCriticalItem(selectedRows, activedCriticalItem)
        ? strings.actions.editCriticalItems
        : strings.actions.flagCriticalItems,
      icon: 'exclamation-triangle',
      action: () => {
        setShowOrderPriorization(true);
      },
    },
  ];
};

export const configTanslateDeliveryStatusFilterOpened = (columns: ColDef[] = [], language: string) => {
  const strings: IOrderTabOpenStrings = Strings[language];
  if (columns) {
    columns.forEach(column => {
      if (column.field === 'deliveryStatus') {
        column.filterValueGetter = (params: ValueGetterParams) => {
          return strings.deliveryStatus[params.data.deliveryStatus];
        };
      }
    });
  }
};
