import { TableColumns, TableColumnType } from '@app/models/order-priorization.model';
import { IOrderPriorizationTableRowData } from './order-priorization.interfaces';

export const SLICE_NAME = 'order-priorization';

export const orderPriorizationTableRowData: IOrderPriorizationTableRowData = {
  [TableColumns.iconCell]: { type: TableColumnType.Icon },
  [TableColumns.ovItem]: { type: TableColumnType.StaticText },
  [TableColumns.itemOv]: { type: TableColumnType.StaticText },
  [TableColumns.ocClient]: { type: TableColumnType.StaticText },
  [TableColumns.codMaterialClient]: { type: TableColumnType.StaticText },
  [TableColumns.descMaterial]: { type: TableColumnType.StaticText },
  [TableColumns.dateMaxDeliveryClient]: { type: TableColumnType.StaticDate },
  [TableColumns.qtdConf]: { type: TableColumnType.StaticText },
  [TableColumns.qtdSolClient]: { type: TableColumnType.StaticText },
  [TableColumns.messageClient]: { type: TableColumnType.Text },
  [TableColumns.actionCell]: { type: TableColumnType.Action },
};
