import * as React from 'react';
import { useStore } from '@app/core/global-store.service';
import { NotificationStore, useNotificationStore } from '@app/providers/notification.store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InternalBadge } from '@atomic/atm.internal-badge/internal-badge.component';
import { BrandColor } from '@atomic/obj.constants';
import { NotificationPanel } from './notification-panel.component';

export const NotificationCenter: React.FC = () => {
  const { notificationLength, notificationPanelOpen, setNotificationPanelOpen, toggle } = useStore<NotificationStore>(
    useNotificationStore,
  );

  return (
    <>
      <InternalBadge notification={`${notificationLength}`}>
        <Button size='regularEqualSides' kind='link' onClick={toggle}>
          <FaIcon.Bell color={BrandColor.Blue} size='lg' />
        </Button>
      </InternalBadge>
      <NotificationPanel active={notificationPanelOpen} onCloseClick={() => setNotificationPanelOpen(false)} />
    </>
  );
};
