import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import { AppPath } from '@app/modules/app/route-constants';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { getBodyHeightDiscount } from '../../sidebar-filters.utils';
import { countFiltersSelector } from '../../store/sidebar-filters.selectors';
import { FinanceFilters } from './partials/finance-filters/finance-filters.component';
import { OrderFilters } from './partials/order-filters/order-filters.component';
import { SidebarFilterBodyWrapper } from './sidebar-filters-body.style';

export const SidebarFiltersBody: React.FC = () => {
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const history = useHistory();
  const isFinancePage = history.location.pathname === AppPath.FINANCE;
  const tab = isFinancePage ? LayoutTabs.FinanceMI : MItabIndex[orderTabIndex];
  const countFilters = useSelector(state => countFiltersSelector(state, tab));

  return (
    <SidebarFilterBodyWrapper bodyHeightDiscount={getBodyHeightDiscount(countFilters)}>
      {history.location.pathname === AppPath.FINANCE ? <FinanceFilters /> : <OrderFilters />}
    </SidebarFilterBodyWrapper>
  );
};
