import * as React from 'react';
import { Status } from '@app/core/route';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NotFoundProps {
  // implement props herer
}

export const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <Status code={404}>
      <h1>Sorry, can’t find that.</h1>
    </Status>
  );
};
