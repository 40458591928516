import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { AnnouncementModalDatasource } from '@app/data/http/announcement-modal-datasource';
import { IPatchAnnouncementParams } from '@app/data/http/announcement.dto';
import { AnnouncementModalEvents } from '@app/modules/components/announcement-modal/announcement-modal.analytics';
import { SCHEDULING_COMPLETE_KEY } from '@app/modules/components/announcement-modal/announcement-modal.constants';
import { SLICE_NAME } from '../scheduling-complete-modal.constants';
import { getSchedulingCompleteModal } from './get-scheduling-complete.thunk';

export const patchSchedulingCompleteModal = createAsyncThunk(
  `${SLICE_NAME}/patchSchedulingCompleteModal`,
  async (announcementParams: IPatchAnnouncementParams, thunkAPI) => {
    return AnnouncementModalDatasource.patchAnnouncement(announcementParams)
      .then(resp => {
        thunkAPI.dispatch(getSchedulingCompleteModal(SCHEDULING_COMPLETE_KEY));
        return resp;
      })
      .catch(err => {
        LogAnalytics.error({
          tipo: AnnouncementModalEvents.PatchSchedulingCompleteAnnouncementeError,
          message: err.response.status,
        });
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addPatchSchedulingCompleteReducers = builder => {
  builder.addCase(patchSchedulingCompleteModal.pending, state => {
    state.loading = true;
  });
  builder.addCase(patchSchedulingCompleteModal.fulfilled, (state, _) => {
    state.openedConfigModal = false;
    state.loading = false;
  });
  builder.addCase(patchSchedulingCompleteModal.rejected, state => {
    state.loading = false;
  });
};
