import { isEmailValid } from '@atomic/obj.form/regex-validator';
import { ISurrogateState } from '../../surrogate.interfaces';
import { setEmail, setSurrogate } from '../surrogate.store';
import { checkSurrogate } from './check-surrogate.thunk';

export const checkAndAddEmailThunk = (email: string) => async (dispatch, getState) => {
  await dispatch(checkSurrogate(email));

  const { error }: ISurrogateState = getState().surrogate;

  if (isEmailValid(email) && !error) {
    await dispatch(setSurrogate(email));
    await dispatch(setEmail(''));
  }
  return;
};
