import { Modal } from '@gerdau/hefesto';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Body } from '@atomic/atm.typography';
import { AdminStrings } from '../../admin.string';
import {
  clearUser,
  setBPFormGroupSelectedOptions,
  setBPFormSelectedOptions,
  setFormBpFilterOption,
  setFormBpTextQuery,
  setIsEditing,
  setShowConfirmModal,
  setShowUserForm,
} from '../../store/admin.store';
import { updateUser } from '../../store/thunk/user-update.thunk';
import { AdminConfirmModalWrapperStyled } from './admin-confirm-modal.styles';

export const AdminConfirmModal: React.FC = () => {
  const { loadingSubmit } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = AdminStrings.pt.modal;

  const handleConfirm = () => {
    dispatch(updateUser());
  };

  const handleExit = () => {
    dispatch(setShowConfirmModal(false));
    dispatch(setShowUserForm(false));
    handleClearForm();
  };

  const handleClearForm = () => {
    dispatch(clearUser(''));
    dispatch(setFormBpFilterOption([]));
    dispatch(setFormBpTextQuery(''));
    dispatch(setIsEditing(false));
    dispatch(setBPFormSelectedOptions([]));
    setBPFormGroupSelectedOptions([]);
  };

  return (
    <AdminConfirmModalWrapperStyled>
      <Modal
        title={strings.title}
        onClose={() => dispatch(setShowConfirmModal(false))}
        modalSize='sm'
        buttons={{
          left: {
            name: 'closeBtn',
            children: strings.cancelBtn,
            onClick: () => handleExit(),
          },
          right: {
            name: 'confirmBtn',
            children: strings.confirmBtn,
            loading: loadingSubmit,
            onClick: () => handleConfirm(),
          },
        }}
      >
        <Body>{strings.message}</Body>
      </Modal>
    </AdminConfirmModalWrapperStyled>
  );
};
