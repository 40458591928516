import styled from 'styled-components';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';

export const SearchMaterialFieldWrapper = styled(FlexColumn)`
  padding-right: ${Spacing.Medium};
  height: 40px;
  align-items: center;
`;

export const SearchMaterialFieldErrorWrapper = styled(FlexRow)`
  font-size: 12px;
  color: ${Color.Alert};
  height: 18px;
  margin-top: 4px;
`;
