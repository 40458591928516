import * as QueryString from 'query-string';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { LogAnalytics } from '@app/core/analytics';
import { HelpCenterContentModel, HelpCenterPageState } from '@app/models/help-center.model';
import { capitalizeFirstLetter } from '@app/utils/string-utils';
import { Frame } from '@atomic/atm.frame';
import { H1 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { quoteIsInternalUserSelector } from '../auth/store/auth.selectores';
import { HelpCenterContent } from './help-center-content.component';
import { HelpCenterString } from './help-center.string';
import { SectionCell } from './section-cell.component';
import { HelpCenterContentDefinition } from './utils/help-center-content.utils';

export const HelpCenterPage: React.FC<RouteComponentProps<{}, {}, HelpCenterPageState>> = props => {
  const strings = HelpCenterString;
  const content: HelpCenterContentModel = HelpCenterContentDefinition();
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const [showSubsections, setShowSubsections] = React.useState(false);
  const [currentSection, setCurrentSection] = React.useState('');
  const [currentSubsection, setCurrentSubsection] = React.useState('');
  const queryString = QueryString.parse(location.search);
  const [sectionQS] = React.useState(queryString.section);
  const [subSectionQS] = React.useState(queryString.subSection);

  React.useEffect(() => {
    if (props.location?.state?.section) {
      handleCellClick(props.location.state.section);
    }

    if (props.location?.state?.subsection) {
      handleSubsectionClick(props.location.state.subsection);
    }
  }, [props.location?.state?.section, props?.location?.state?.subsection]);

  React.useEffect(() => {
    if (sectionQS && subSectionQS) {
      handleCellClick(sectionQS);
      handleSubsectionClick(subSectionQS);
    }
  }, [sectionQS, subSectionQS]);

  const handleCellClick = (section: string) => {
    setCurrentSection(section);

    if (section === currentSection) {
      setShowSubsections(prev => !prev);
    } else {
      setShowSubsections(true);
    }
  };

  const handleSubsectionClick = (subsection: string) => {
    LogAnalytics.click({ tipo: `HelpCenter${capitalizeFirstLetter(subsection)}Click` });
    setCurrentSubsection(subsection);
  };

  const getContent = () => {
    const sectionsIndex = content.sections.map(section => section.key).indexOf(currentSection);
    const subsectionIndex = content.sections[sectionsIndex]?.subsections
      ?.map(subsection => subsection.key)
      .indexOf(currentSubsection);

    return currentSection && currentSubsection && content.sections[sectionsIndex]?.subsections[subsectionIndex];
  };

  return (
    <>
      <Frame>
        <VSeparator />
        <Grid fluid>
          <H1>{strings.title}</H1>
        </Grid>
        <VSeparator />
      </Frame>
      <Grid fluid>
        <Row mt pb>
          <Col xs={3}>
            <Frame>
              {content.sections.map((section, index) =>
                quoteIsInternalUser === true ? (
                  <SectionCell
                    key={`${section.key}-${index}`}
                    label={section.title}
                    id={section.key}
                    internalLink={section.internalLink}
                    subsections={section.subsections}
                    showSubsections={showSubsections}
                    onSectionClick={handleCellClick}
                    currentSection={currentSection}
                    currentSubsection={currentSubsection}
                    onSubsectionClick={handleSubsectionClick}
                    target={section.target}
                  />
                ) : (
                  section.key !== 'complain' && (
                    <SectionCell
                      key={`${section.key}-${index}`}
                      label={section.title}
                      id={section.key}
                      internalLink={section.internalLink}
                      subsections={section.subsections}
                      showSubsections={showSubsections}
                      onSectionClick={handleCellClick}
                      currentSection={currentSection}
                      currentSubsection={currentSubsection}
                      onSubsectionClick={handleSubsectionClick}
                      target={section.target}
                    />
                  )
                ),
              )}
            </Frame>
            <Separator />
          </Col>
          <Col xs={9}>
            <Frame>
              <HelpCenterContent subsectionData={getContent()} welcome={strings.welcome} />
            </Frame>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
