import * as React from 'react';
import { Tag } from '@atomic/atm.tag';
import { CrmFilterTagsWrapper } from './crmAdvancedFilter.style';

export interface CrmAdvancedFilterTagsProps {
  dataKey: string;
  data: any;
  mb?: number;
  handleEditFilters: (value: string, key: string, action: string) => void;
}

export const CrmAdvancedFilterTags = (props: CrmAdvancedFilterTagsProps) => {
  return props.data && props.data.length > 0 ? (
    <CrmFilterTagsWrapper mb={props?.mb}>
      {props.data.map((tag, index) => (
        <Tag
          key={`${props.dataKey}-${index}`}
          selected
          showCheck={false}
          onRemove={() => props.handleEditFilters(tag, props.dataKey, 'remove')}
        >
          {tag}
        </Tag>
      ))}
    </CrmFilterTagsWrapper>
  ) : null;
};
