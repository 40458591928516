import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { HelpCenterSubsectionKeys } from '@app/models/help-center.model';
import { AdvancedFiltersButton } from '@app/modules/components/advanced-filters-button/advanced-filters-button.component';
import {
  LACK_OF_CHARGE_MI_KEY,
  ONBOARDING_ORDER_MI_KEY,
  SCHEDULING_COMPLETE_KEY,
} from '@app/modules/components/announcement-modal/announcement-modal.constants';
import { FlashWrapperStatic } from '@app/modules/components/flash-wrapper-static/flash-wrapper-static.component';
import { OrderActions } from '@app/modules/components/order/order-actions.component';
import { setShowOrderPriorization } from '@app/modules/order-priorization/store/order-priorization.store';
import { getCriticalItemsAndRulesThunk } from '@app/modules/order-priorization/store/thunks/get-critical-items-and-rules.thunk';
import { setShowOrderUpdate } from '@app/modules/order-update/store/order-update.store';
import { GridOpenRowCriticalFilter } from '@app/modules/order/grid-filters/grid-open-row-critical-filter.component';
import { GridOpenRowLackOfChargeFilter } from '@app/modules/order/grid-filters/grid-open-row-lack-of-charge-filter.component';
import { OnboardTooltipCenterOrderTab } from '@app/modules/order/onboard-tooltips/onboard-tooltip-center-order-tab.component';
import { OrderTabGrid } from '@app/modules/order/order-tab-grid.component';
import { alredyOnboardingOpenOrderMiSelector } from '@app/providers/navigation/navigation.selectors';
import { putOnboarding } from '@app/providers/navigation/thunks/put-onboarding.thunk';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';
import { GridOpenRowFilter } from '../../../grid-filters/grid-open-row-filter.component';
import { OrderTabsActionsMI } from '../order-tabs-actions-MI.component';
import { DeliveryForecastCellComponent } from './cell-renderer/delivery-forecast/delivery-forecast-cell.component';
import { DeliveryStatusRenderer } from './cell-renderer/delivery-status-renderer.component';
import { NotificationRenderer } from './cell-renderer/notification-renderer.component';
import { OrderStatusRenderer } from './cell-renderer/order-status/order-status-renderer.component';
import { ShippingConfigRenderer } from './cell-renderer/shipping-config-renderer.component';
import { tabName } from './order-tab-open.constants';
import { IOrderTabOpenStrings, Strings } from './order-tab-open.string';
import { LackOfChargeModal } from './partials/lack-of-charge-modal/lack-of-charge-modal';
import { getLackOfChargeModal } from './partials/lack-of-charge-modal/store/get-lack-of-charge.thunk';
import { SchedulingCompleteModal } from './partials/scheduling-complete/scheduling-complete-modal';
import { getSchedulingCompleteModal } from './partials/scheduling-complete/store/get-scheduling-complete.thunk';
import { filteredDataSelector } from './store/order-tab-open.selectors';
import { setFilter, setFilteredRows, setSelectedRows, setShowActionBar } from './store/order-tab-open.store';
import { performRequest } from './store/order-tab-open.thunks';
import { getOpenMIOrderActions } from './utils/action-utils';
import { handleSelectionChange } from './utils/handler-utils';

export interface OrderTabOpenComponentProps {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  activateLoading: boolean;
  deactivateLoading: boolean;
  activateShippingConfigLoading: boolean;
  deactivateShippingConfigLoading: boolean;
  criticalItemInfoLoading: boolean;
  criticalItemsLoading: boolean;
  activateNotifications: (params?: any) => void;
  activateShippingConfig: (params?: any) => void;
  deactivateNotifications: (params?: any) => void;
  deactivateShippingConfig: (params?: any) => void;
}

export const OrderTabOpenComponent: React.FC<OrderTabOpenComponentProps> = ({
  gridEvent,
  activateLoading,
  deactivateLoading,
  activateShippingConfigLoading,
  deactivateShippingConfigLoading,
  criticalItemInfoLoading,
  criticalItemsLoading,
  activateNotifications,
  deactivateNotifications,
  activateShippingConfig,
  deactivateShippingConfig,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const strings: IOrderTabOpenStrings = Strings[userInfo?.language];
  const { data, error, loading, filter, selectedRows, showActionBar } = useSelector(
    (state: RootState) => state.orderTabOpen,
  );
  const { activedCriticalItem } = useSelector((state: RootState) => state.orderPriorization);
  const filteredData = useSelector((state: RootState) => filteredDataSelector(state, data));
  const showOnboardingFirstTime = useSelector(alredyOnboardingOpenOrderMiSelector);
  const { openBPsModal } = useSelector((state: RootState) => state.navigation);
  const { opened: openAnnouncementModal } = useSelector((state: RootState) => state.announcement);
  const { hasLackOfCharge, hasLackOfChargeAnnouncement } = useSelector((state: RootState) => state.lackOfCharge);
  const { hasSchedulingCompleteAnnouncement } = useSelector((state: RootState) => state.schedulingComplete);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (showOnboardingFirstTime) {
      setShowOnboarding(true);
    }
  }, [showOnboardingFirstTime]);

  useEffect(() => {
    dispatch(getLackOfChargeModal(LACK_OF_CHARGE_MI_KEY));
    dispatch(getSchedulingCompleteModal(SCHEDULING_COMPLETE_KEY));
  }, []);

  const loadingActions =
    activateLoading ||
    deactivateLoading ||
    activateShippingConfigLoading ||
    deactivateShippingConfigLoading ||
    criticalItemInfoLoading ||
    criticalItemsLoading;

  return (
    <>
      <FlashWrapperStatic opened={!!error} type='alert' content={strings.error} />
      <Grid fluid>
        <OrderTabsActionsMI
          tabName={tabName}
          refresh={() => {
            dispatch(performRequest());
            dispatch(getCriticalItemsAndRulesThunk());
          }}
          gridEvent={gridEvent}
          helpCenterSubsection={HelpCenterSubsectionKeys.OpenOrdersGlossary}
          filename={strings.filename}
          hasScheduleSend
        >
          <FlexRow fullWidth vAlign='baseline' mr={Spacing.Small}>
            <AdvancedFiltersButton />
            <GridOpenRowFilter
              value={filter}
              onTagChanged={value => {
                dispatch(setFilter(value));
              }}
            />
            <GridOpenRowCriticalFilter />
            <GridOpenRowLackOfChargeFilter />
          </FlexRow>
        </OrderTabsActionsMI>
        <OrderTabGrid
          handleSelectionChange={handleSelectionChange}
          gridEvent={gridEvent}
          performRequest={performRequest}
          setFilteredRows={setFilteredRows}
          setSelectedRows={setSelectedRows}
          strings={strings}
          data={filteredData}
          loading={loading}
          error={error}
          tabName={tabName}
          frameworkComponents={{
            deliveryStatusRenderer: DeliveryStatusRenderer,
            notificationRenderer: NotificationRenderer,
            shippingConfigRenderer: ShippingConfigRenderer,
            orderStatusRenderer: OrderStatusRenderer,
            dateDeliveryForecastRenderer: DeliveryForecastCellComponent,
          }}
          showCriticalFilter={true}
          showActionBar={showActionBar}
        />
      </Grid>
      {showActionBar && (
        <OrderActions
          loading={loadingActions}
          selectedNumber={selectedRows.length}
          actions={getOpenMIOrderActions({
            strings,
            selectedRows,
            setShowOrderUpdate: value => dispatch(setShowOrderUpdate(value)),
            activateNotifications,
            deactivateNotifications,
            activateShippingConfig,
            deactivateShippingConfig,
            setShowOrderPriorization: value => dispatch(setShowOrderPriorization(value)),
            activedCriticalItem: activedCriticalItem,
          })}
        />
      )}
      {!openAnnouncementModal && !openBPsModal && showOnboarding && (
        <OnboardTooltipCenterOrderTab
          onFinishOnboard={() => {
            setShowOnboarding(false);
            dispatch(setShowActionBar(false));
            dispatch(putOnboarding(ONBOARDING_ORDER_MI_KEY));
          }}
        />
      )}
      {!openAnnouncementModal && !openBPsModal && hasLackOfCharge && hasLackOfChargeAnnouncement ? (
        <LackOfChargeModal />
      ) : null}
      {!openAnnouncementModal && !openBPsModal && hasSchedulingCompleteAnnouncement ? (
        <SchedulingCompleteModal />
      ) : null}
    </>
  );
};
