import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { getReceiverCompanyNameSelector } from '@app/modules/order-input/store/order-input.selectors';
import { InputLabel, Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontSize, FontWeight } from '@atomic/obj.constants';
import { OrderInputStrings } from '../../../../../order-input.string';
import { BPNameTextField } from './receiver-form-style';

const Strings = OrderInputStrings.pt;

interface ReceiverFormDisabledProps {
  title: string;
}

export const ReceiverFormDisabled: React.FC<ReceiverFormDisabledProps> = ({ title }) => {
  const { selectedReceiver, selectedIssuer } = useSelector((state: RootState) => state.orderInput);
  const receiverName = useSelector(getReceiverCompanyNameSelector);

  return (
    <FlexColumn mb data-testid='order-input-receiver-form-disabled'>
      <Label size={FontSize.Medium}>{title}</Label>
      <FlexRow mb mt>
        <FlexColumn mr>
          <InputLabel weight={FontWeight.Normal}>{Strings.selectItens.stepOne.orderIssuer}</InputLabel>
          <BPNameTextField
            disabled
            value={`${selectedIssuer.value} - ${selectedIssuer.name} - ${selectedIssuer.city}`}
          />
        </FlexColumn>
        <FlexColumn mr>
          <InputLabel weight={FontWeight.Normal}>{Strings.selectItens.stepOne.orderReceiver}</InputLabel>
          <BPNameTextField disabled value={`${selectedReceiver} - ${receiverName}`} />
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  );
};
