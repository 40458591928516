import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Image } from '@atomic/atm.image';
import { Body, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { FeedbackStrings as strings } from '../../feedback.string';

export const FeedbackSuccess: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  return (
    <Hbox>
      <Hbox.Item hAlign='center'>
        <Separator />
        <Image.Check />
        <Separator />
        <H3>{strings[userInfo.language].successMessage}</H3>
        <Body>{strings[userInfo.language].successMessageCaption}</Body>
        <Separator />
      </Hbox.Item>
    </Hbox>
  );
};
