import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { IsaHttpRequestBuilder } from '@app/data/http';

interface GetAttributeParams {
  analysisId: number;
  plantKey: string;
  attributeKey: string;
  language?: string;
}

export const getAttribute = async (params: GetAttributeParams) => {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  // const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
  //   endpoint.ANALYSIS_STATUS
  // }`;

  const url = `https://cxp-quote-api-cxp-dev.apps2.rosa-gerdau.bxnu.p1.openshiftapps.com/v3/api/analysis/${params.analysisId}/plant/${params.plantKey}/attributes/${params.attributeKey}`;

  try {
    const { data, status } = await axios.get(url, { headers: defaultHeaders });

    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    //handleUnauthorized(error);
    return null;
  }
};

export function useGetNewAttribute(onSuccess?, onError?) {
  return useMutation(getAttribute, {
    onSuccess,
    onError,
    retry: false,
  });
}
