import { Material, MaterialErrorMessage } from '@app/models';
import {
  BRSD_SALES_ORG,
  BRSI_SALES_ORG,
  MaterialCell,
  MaterialCellError,
  MaterialUnity,
} from './order-input.constants';
import { OrderInputStrings } from './order-input.string';
import { getInvalidMinimunAmount } from './order-input.utils';

const string = OrderInputStrings.pt.dateAndAmount.validation;

export const getAmountError = (material: Material, salesOrg: string) => {
  let returnedValue: any = null;

  if (
    (material.unity === MaterialUnity.Kg && parseFloat(material.amount) < 1000) ||
    (material.unity === MaterialUnity.Kg && parseFloat(material.amount) > 999999)
  ) {
    returnedValue = {
      code: '4',
      message: string.KGinterval,
      details: string.KGdetails,
    };
  }

  if (
    (material.unity === MaterialUnity.Ton && parseFloat(material.amount) < 1) ||
    (material.unity === MaterialUnity.Ton && parseFloat(material.amount) > 999)
  ) {
    returnedValue = {
      code: '3',
      message: string.Tinterval,
      details: string.Tdetails,
    };
  }

  if (salesOrg === BRSD_SALES_ORG || salesOrg === BRSI_SALES_ORG) {
    if (material.unity === MaterialUnity.Kg && parseFloat(material.amount) < 10000) {
      returnedValue = {
        code: '11',
        message: string.minKG,
        details: string.minKGDetail,
      };
    }

    if (material.unity === MaterialUnity.Ton && parseFloat(material.amount) < 10) {
      returnedValue = {
        code: '12',
        message: string.minTon,
        details: string.minTonDetail,
      };
    }
  }

  return returnedValue;
};

export const validateAmount = (material: Material, salesOrg: string) => {
  let isValid = true;

  const invalidMinnimunAmount = getInvalidMinimunAmount(material, salesOrg);

  if (
    (material.unity === MaterialUnity.Kg && parseFloat(material.amount) < 1000) ||
    (material.unity === MaterialUnity.Kg && parseFloat(material.amount) > 999999) ||
    (material.unity === MaterialUnity.Ton && parseFloat(material.amount) < 1) ||
    (material.unity === MaterialUnity.Ton && parseFloat(material.amount) > 999) ||
    invalidMinnimunAmount
  ) {
    isValid = false;
  }

  return isValid;
};

export const getOrderNumberError = (material: Material) => {
  if (!material.orderNumber) {
    return {
      code: '7',
      message: string.orderNumberMessage,
      details: string.orderNumberDetails,
    };
  }

  return null;
};

export const hasError = (material: Material, materialCell: MaterialCell): boolean => {
  let error = false;

  if (!material) {
    return error;
  }

  if (material.messages) {
    // eslint-disable-next-line complexity
    material.messages.forEach(m => {
      switch (materialCell) {
        case MaterialCell.Description:
          if (
            m.code === MaterialCellError.MaterialNotFound ||
            (!material.materialDescription && m.code === MaterialCellError.RequiredField)
          ) {
            error = true;
          }
          break;
        case MaterialCell.Amount:
          if (
            m.code === MaterialCellError.TonQtyOutOfRange ||
            m.code === MaterialCellError.KgQtyOutOfRange ||
            m.code === MaterialCellError.MinKGAmount ||
            m.code === MaterialCellError.MinTonAmount ||
            (!material.amount && m.code === MaterialCellError.RequiredField)
          ) {
            error = true;
          }
          break;
        case MaterialCell.Unity:
          if (
            m.code === MaterialCellError.InvalidUnity ||
            (!material.unity && m.code === MaterialCellError.RequiredField)
          ) {
            error = true;
          }
          break;
        case MaterialCell.Date:
          if (
            m.code === MaterialCellError.PastDate ||
            m.code === MaterialCellError.BusinessDay ||
            m.code === MaterialCellError.InvalidDateFormat ||
            m.code === MaterialCellError.InvalidDate ||
            (!material.date && m.code === MaterialCellError.RequiredField)
          ) {
            error = true;
          }
          break;
        case MaterialCell.OrderNumber:
          if (!material.orderNumber && m.code === MaterialCellError.RequiredField) {
            error = true;
          }
          break;
        default:
          break;
      }
    });
  }

  return error;
};

export const hasInputError = (material: Material, materialCell: MaterialCell) => {
  let message = '';

  if (material.messages) {
    // eslint-disable-next-line complexity
    material.messages.forEach(m => {
      switch (materialCell) {
        case MaterialCell.Description:
          if (
            m.code === MaterialCellError.MaterialNotFound ||
            (!material.materialDescription && m.code === MaterialCellError.RequiredField)
          ) {
            message = m.message;
          }
          break;
        case MaterialCell.Amount:
          if (
            m.code === MaterialCellError.TonQtyOutOfRange ||
            m.code === MaterialCellError.KgQtyOutOfRange ||
            m.code === MaterialCellError.MinKGAmount ||
            m.code === MaterialCellError.MinTonAmount ||
            (!material.amount && m.code === MaterialCellError.RequiredField)
          ) {
            message = m.message;
          }
          break;
        case MaterialCell.Unity:
          if (
            m.code === MaterialCellError.InvalidUnity ||
            (!material.unity && m.code === MaterialCellError.RequiredField)
          ) {
            message = m.message;
          }
          break;
        case MaterialCell.Date:
          if (
            m.code === MaterialCellError.PastDate ||
            m.code === MaterialCellError.BusinessDay ||
            m.code === MaterialCellError.InvalidDateFormat ||
            m.code === MaterialCellError.InvalidDate ||
            (!material.date && m.code === MaterialCellError.RequiredField)
          ) {
            message = m.message;
          }
          break;
        case MaterialCell.OrderNumber:
          if (!material.orderNumber && m.code === MaterialCellError.RequiredField) {
            message = m.message;
          }
          break;
        default:
          break;
      }
    });
  }

  return message;
};

const hasRequiredError = (materialError: MaterialErrorMessage, fieldName: string) => {
  if (
    materialError.code !== MaterialCellError.RequiredField ||
    (materialError.code === MaterialCellError.RequiredField && materialError.details.indexOf(fieldName) < 0)
  ) {
    return true;
  } else {
    return false;
  }
};

export const removeMaterialError = (
  materialError: MaterialErrorMessage[],
  materialCell: MaterialCell,
): MaterialErrorMessage[] => {
  const result: MaterialErrorMessage[] = [];

  if (materialError && materialError.length) {
    // eslint-disable-next-line complexity
    materialError.forEach(m => {
      switch (materialCell) {
        case MaterialCell.Description:
          if (m.code !== MaterialCellError.MaterialNotFound && hasRequiredError(m, 'Sku')) {
            result.push(m);
          }
          break;
        case MaterialCell.Amount:
          if (
            m.code !== MaterialCellError.TonQtyOutOfRange &&
            m.code !== MaterialCellError.KgQtyOutOfRange &&
            m.code !== MaterialCellError.MinKGAmount &&
            m.code !== MaterialCellError.MinTonAmount &&
            hasRequiredError(m, 'Quantity')
          ) {
            result.push(m);
          }
          break;
        case MaterialCell.Unity:
          if (m.code !== MaterialCellError.InvalidUnity && hasRequiredError(m, 'UnitOfMeasure')) {
            result.push(m);
          }
          break;
        case MaterialCell.Date:
          if (
            m.code !== MaterialCellError.PastDate &&
            m.code !== MaterialCellError.BusinessDay &&
            m.code !== MaterialCellError.InvalidDateFormat &&
            m.code !== MaterialCellError.InvalidDate &&
            hasRequiredError(m, 'DatetCustomerRequired')
          ) {
            result.push(m);
          }
          break;
        case MaterialCell.OrderNumber:
          if (hasRequiredError(m, 'NumberOrder')) {
            result.push(m);
          }
          break;
        default:
          break;
      }
    });
  }

  return result;
};
