import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { IAdminUserList } from '@app/models/admin.model';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { AdminStrings } from '../../admin.string';

export const getAllUsers = createAsyncThunk(`${SLICE_NAME}/getAllUsers`, async (_, thunkAPI) => {
  const strings = AdminStrings.pt.userView;

  return await AdminDataSource.getUsers({
    skip: 0,
    limit: 9999,
    filterType: '',
    filterValue: '',
  })
    .then(data => data)
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: strings.expotExcelError, variant: ToastVariant.DANGER }));
    });
});

export const addGetAllUsersReducers = builder => {
  builder.addCase(getAllUsers.pending, state => {
    state.loaloadingAllUsersding = true;
  });
  builder.addCase(getAllUsers.fulfilled, (state, action) => {
    const payload: IAdminUserList = action.payload as IAdminUserList;
    state.loadingAllUsers = false;
    state.allUsers = payload.users;
  });
  builder.addCase(getAllUsers.rejected, state => {
    state.loadingAllUsers = false;
  });
};
