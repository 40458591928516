import styled, { css } from 'styled-components';
import { Border, Color, Spacing } from '@atomic/obj.constants';

export const POPOVER_WIDTH = 350;
export const POPOVER_HEIGHT = 100;
export const POPOVER_Z_INDEX = 99;
export const ARROW_DIMENSIONS = 10;

export const CustomizedPopoverWrapper = styled.div`
  position: absolute;
  z-index: ${POPOVER_Z_INDEX};
  width: ${POPOVER_WIDTH}px;
  height: ${POPOVER_HEIGHT}px;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
`;

export const CustomizedPopoverBoxStyled = styled.div`
  padding: ${Spacing.Medium};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : Color.White)};
  border-radius: ${Border.Radius};
  box-shadow: 0px 0px 40px rgba(114, 114, 114, 0.35);
`;
interface CustomizedPopoverArrowProps {
  xAxis: number;
  alignRight?: boolean;
  alignBottom?: boolean;
  arrowLeft?: number;
}

const arrowUpCss = css`
  border-bottom-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : Color.White)};
  border-width: 0 ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px;
  top: -9px;
`;

const arrowDownCss = css`
  border-top-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : Color.White)};
  border-width: ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px 0 ${ARROW_DIMENSIONS}px;
  top: ${POPOVER_HEIGHT - 30}px;
`;

export const CustomizedPopoverArrowStyled = styled.div`
  height: ${ARROW_DIMENSIONS}px;
  width: ${ARROW_DIMENSIONS}px;
  border-color: transparent;
  border-style: solid;
  position: absolute;
  transform-origin: center bottom;
  ${(props: CustomizedPopoverArrowProps) => (props.alignBottom ? arrowDownCss : arrowUpCss)};
  left: ${(props: CustomizedPopoverArrowProps) => (props.arrowLeft ? `${props.arrowLeft}px` : 'auto')};
  right: ${(props: CustomizedPopoverArrowProps) => (props.alignRight ? '20px' : 'auto')};
  box-shadow: 0px 0px 40px rgba(114, 114, 114, 0.35);
`;
