import React from 'react';
import { FlexColumn } from '@atomic/obj.box';
import { Grid } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { CustomerSearchModalBodyClient } from './customer-search-modal-body-client/customer-search-modal-body-client.component';
import { CustomerSearchModalFooterClient } from './customer-search-modal-footer-client/customer-search-modal-footer-client.component';
import { CustomerSearchModalHeaderClient } from './customer-search-modal-header-client/customer-search-modal-header-client.component';

interface CustomerSearchModalComponentProps {
  opened: boolean;
  onClose: () => void;
  textSearch: string;
  setTextSearch: (value: string) => void;
}

export const CustomerSearchModalClientComponent: React.FC<CustomerSearchModalComponentProps> = ({
  opened,
  onClose,
  textSearch,
  setTextSearch,
}) => {
  return (
    <Modal overflow='hidden' customWidth='50%' opened={opened} onClose={onClose}>
      <Grid fluid>
        <FlexColumn>
          <CustomerSearchModalHeaderClient textSearch={textSearch} setTextSearch={setTextSearch} />
          <CustomerSearchModalBodyClient textSearch={textSearch} />
          <CustomerSearchModalFooterClient onClose={onClose} />
        </FlexColumn>
      </Grid>
    </Modal>
  );
};
