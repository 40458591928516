import styled, { css } from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export type MarginPaddingType = boolean | Spacing;
export interface MarginPaddingProps {
  mb: MarginPaddingType;
  mt: MarginPaddingType;
  pb: MarginPaddingType;
  pt: MarginPaddingType;
  ml: MarginPaddingType;
  mr: MarginPaddingType;
  pl: MarginPaddingType;
  pr: MarginPaddingType;
}

const getSpacing = (prop: MarginPaddingType) => (prop ? (typeof prop === 'string' ? prop : Spacing.Large) : '0px');

export const MarginPaddingCSS = css`
  margin-bottom: ${(props: HboxProps) => getSpacing(props.mb)};
  margin-top: ${(props: HboxProps) => getSpacing(props.mt)};
  margin-left: ${(props: HboxProps) => getSpacing(props.ml)};
  margin-right: ${(props: HboxProps) => getSpacing(props.mr)};
  padding-top: ${(props: HboxProps) => getSpacing(props.pt)};
  padding-bottom: ${(props: HboxProps) => getSpacing(props.pb)};
  padding-left: ${(props: HboxProps) => getSpacing(props.pl)};
  padding-right: ${(props: HboxProps) => getSpacing(props.pr)};
`;

interface BoxProps {
  // src: https://www.w3schools.com/cssref/css3_pr_align-items.asp
  hAlign?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
  // src: https://www.w3schools.com/cssref/css3_pr_justify-content.asp
  vAlign?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit';
  fullWidth: boolean;
  fullHeight?: boolean;
}

export interface HboxProps extends BoxProps, MarginPaddingProps {
  noGrow?: boolean;
  vAlign?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit';
  hAlign?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
  small?: boolean;
  colWrap?: boolean;
  nowrap?: boolean;
}

const HboxStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${(props: HboxProps) => (props.vAlign ? props.vAlign : 'flex-start')};
  align-content: flex-start;
  justify-content: ${(props: HboxProps) => (props.hAlign ? props.hAlign : 'flex-start')};
  ${(props: HboxProps) => props.colWrap && 'flex-wrap:wrap'};
  width: ${(props: HboxProps) => (props.fullWidth ? '100%' : 'auto')};
  height: ${(props: HboxProps) => (props.fullHeight ? '100%' : 'auto')};
  ${MarginPaddingCSS}
`;

export const Hbox: any = HboxStyled;
Hbox.displayName = 'Hbox';

Hbox.Item = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  ${(props: HboxProps) => (!props.noGrow ? 'flex: 1' : null)};
  ${(props: HboxProps) => props.nowrap && 'white-space: nowrap'};
  width: ${(props: HboxProps) => (props.fullWidth ? '100%' : 'auto')};
  justify-content: ${(props: HboxProps) => (props.vAlign ? props.vAlign : 'flex-start')};
  align-items: ${(props: HboxProps) => (props.hAlign ? props.hAlign : 'stretch')};
  ${MarginPaddingCSS};
`;
Hbox.Item.displayName = 'Hbox.Item';

Hbox.Separator = styled.div`
  width: ${(props: HboxProps) => (props.small ? Spacing.Small : Spacing.Medium)};
`;
Hbox.Separator.displayName = 'Hbox.Separator';

export const FlexRow = Hbox;
export const FlexColumn = Hbox.Item;

export const Separator = styled.div`
  margin-bottom: ${props => (props.small ? Spacing.Small : Spacing.Large)};
`;

export const Hr = styled.hr`
  border: none;
  background-color: ${props => (props.color ? props.color : Color.GrayLight)};
  height: ${props => (props.height ? props.height : Spacing.XSmall)};
  margin-top: ${props => (props.small ? Spacing.Small : Spacing.Large)};
  margin-bottom: ${props => (props.small ? Spacing.Small : Spacing.Large)};
`;
