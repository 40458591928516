import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { BoxHeight, Spacing } from '@atomic/obj.constants';

export const DashboardHeaderWrapper = styled(FlexRow)`
  height: ${BoxHeight.Large};
  justify-content: flex-end;
  align-items: center;
  padding: 0px ${Spacing.XLarge};
`;
