import { CustomerSelection } from '@app/providers';

export enum COUNTRY_CODES {
  BRAZIL = 'BR',
  CHILE = 'CL',
  ARGENTINA = 'AR',
  URUGUAI = 'UY',
  PARAGUAI = 'PY',
  COLOMBIA = 'CO',
  ECUADOR = 'EC',
  PERU = 'PE',
  EUA = 'US',
  SPAIN = 'ES',
  PORTUGAL = 'PT',
  FRANCE = 'FR',
  GERMANY = 'DE',
  JAPAN = 'JP',
  CHINA = 'CN',
}

const roadDeliveryBps: string[] = [
  COUNTRY_CODES.ARGENTINA,
  COUNTRY_CODES.PARAGUAI,
  COUNTRY_CODES.URUGUAI,
  COUNTRY_CODES.BRAZIL,
  COUNTRY_CODES.CHILE,
];

export const hasMaritimeBps = bps => {
  let hasSomeMaritimeBps = false;

  if (bps?.length) {
    bps.forEach((bp: CustomerSelection) => {
      if (!roadDeliveryBps.includes(bp.country)) {
        hasSomeMaritimeBps = true;
      }
    });

    if (hasSomeMaritimeBps) {
      return true;
    }
  }

  return false;
};

export const hasRoadBps = bps => {
  let hasSomeRoadBps = false;

  if (bps?.length) {
    bps.forEach((bp: CustomerSelection) => {
      if (roadDeliveryBps.includes(bp.country) && bp.country !== COUNTRY_CODES.CHILE) {
        hasSomeRoadBps = true;
      }
    });

    if (hasSomeRoadBps) {
      return true;
    }
  }

  return false;
};

export const hasChileBps = bps => {
  let hasSomeChileBps = false;

  if (bps?.length) {
    bps.forEach((bp: CustomerSelection) => {
      if (bp.country === COUNTRY_CODES.CHILE) {
        hasSomeChileBps = true;
      }
    });

    if (hasSomeChileBps) {
      return true;
    }
  }

  return false;
};
