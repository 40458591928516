/* eslint-disable complexity */
import * as React from 'react';
import { Container } from 'typedi/Container';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { Frame } from '@atomic/atm.frame';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { CrmContentContainer } from './components/crmContentContainer';
import { CrmDetailsHeader } from './components/crmDetailsHeader';
import { useGetAllCachedData } from './hooks/useGetAllCachedData';
import { useGetAttachedFiles } from './hooks/useGetAttachedFiles';
import { useGetDates } from './hooks/useGetDates';
import { useGetExpedition } from './hooks/useGetExpedition';
import { useGetHistory } from './hooks/useGetHistory';
import { useGetItems } from './hooks/useGetItems';
import { useGetNotes } from './hooks/useGetNotes';
import { useGetOpportunityDetailsComplain } from './hooks/useGetOpportunityDetailsComplain';
import { useGetOrganizationalData } from './hooks/useGetOrganizationalData';
import { useGetPeopleInvolved } from './hooks/useGetPeopleInvolved';
import { useGetTotalPrices } from './hooks/useGetTotalPrices';
import { useGetWorkflow } from './hooks/useGetWorkflow';

export const CrmPageDetails = props => {
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);
  //const hasCache = window.localStorage.getItem('crmCache') === 'true' ? true : false;
  const hasCache = false;

  const crmId = props.match.params.cfId;

  const [enableRequest, setEnableRequest] = React.useState<boolean>(!hasCache);

  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const handleGetCachedSuccess = response => {
    setHasCachedData(response);
    window.localStorage.removeItem('crmCache');
  };

  const handleOpportunityDetailSuccess = () => {
    setEnableRequest(true);
  };

  const handleDataError = data => {
    if (data.message.includes('401')) {
      authStorageService.logout();
    } else {
      show('alert', data.message ? data.message : data.description);
    }
  };

  const { data: cachedData, isFetching: loadingCached } = useGetAllCachedData(
    { id: crmId, enable: hasCache },
    handleGetCachedSuccess,
    handleDataError,
  );

  const { data: opportunityDetailsData, isFetching: loadingOpportunityDetail } = useGetOpportunityDetailsComplain(
    { id: crmId, enable: enableRequest },
    handleOpportunityDetailSuccess,
    handleDataError,
  );

  const { data: itemsData, isFetching: loadingItems } = useGetItems(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: workflowData, isFetching: loadingWorkflow } = useGetWorkflow(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: attachedFilesData, isFetching: loadingAttachedFiles } = useGetAttachedFiles(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: notesData, isFetching: loadingNotes } = useGetNotes(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: historyData, isFetching: loadingHistory } = useGetHistory(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: totalPricesData, isFetching: loadingTotalPrices } = useGetTotalPrices(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: peopleInvolvedData, isFetching: loadingPeopleInvolved } = useGetPeopleInvolved(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: organizationalDataData, isFetching: loadingOrganizationalDataData } = useGetOrganizationalData(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: expeditionData, isFetching: loadingExpedition } = useGetExpedition(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const { data: datesData, isFetching: loadingDates } = useGetDates(
    { id: crmId, enable: enableRequest },
    handleDataError,
  );

  const [hasCachedData, setHasCachedData] = React.useState(cachedData);

  return (
    <>
      <Frame>
        <Grid fluid>
          <Row middle='xs'>
            <Col xs={12}>
              <CrmDetailsHeader
                loading={loadingOpportunityDetail || loadingCached}
                itemsData={
                  hasCachedData?.OPPORTUNITY_DETAILS
                    ? hasCachedData?.OPPORTUNITY_DETAILS
                    : opportunityDetailsData?.GENERAL_DATA?.infos?.[0]
                }
              />
            </Col>
          </Row>
        </Grid>
      </Frame>

      <CrmContentContainer
        opportunityDetailsData={
          hasCachedData?.OPPORTUNITY_DETAILS ? hasCachedData?.OPPORTUNITY_DETAILS : opportunityDetailsData
        }
        itemsData={itemsData}
        workflowData={hasCachedData?.ATTACHED_FILES ? hasCachedData.ATTACHED_FILES : workflowData}
        attachedFilesData={hasCachedData?.ATTACHED_FILES ? hasCachedData.ATTACHED_FILES : attachedFilesData}
        notesData={hasCachedData?.NOTES ? hasCachedData?.NOTES : notesData}
        historyData={hasCachedData?.PLANTS ? hasCachedData?.PLANTS : historyData}
        totalPricesData={hasCachedData?.PLANTS ? hasCachedData?.PLANTS : totalPricesData}
        peopleInvolvedData={hasCachedData?.PLANTS ? hasCachedData?.PLANTS : peopleInvolvedData}
        organizationalDataData={hasCachedData?.PLANTS ? hasCachedData.PLANTS : organizationalDataData}
        expeditionData={hasCachedData?.PLANTS ? hasCachedData.PLANTS : expeditionData}
        datesData={hasCachedData?.PLANTS ? hasCachedData.PLANTS : datesData}
        loadingOpportunityDetail={loadingOpportunityDetail || loadingCached}
        loadingItems={loadingItems || loadingCached}
        loadingAttachedFiles={loadingAttachedFiles || loadingCached}
        loadingNotes={loadingNotes || loadingCached}
        loadingHistory={loadingHistory || loadingCached}
        loadingTotalPrices={loadingTotalPrices || loadingCached}
        loadingPeopleInvolved={loadingPeopleInvolved || loadingCached}
        loadingOrganizationalDataData={loadingOrganizationalDataData || loadingCached}
        loadingExpedition={loadingExpedition || loadingCached}
        loadingDates={loadingDates || loadingCached}
        loadingWorkflow={loadingWorkflow || loadingCached}
      />
      <Grid fluid mb={30}>
        <VSeparator />
      </Grid>
    </>
  );
};
