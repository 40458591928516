import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Image } from '@atomic/atm.image';
import { FlexColumn } from '@atomic/obj.box';
import { ConfigColumnsModalStrings, Strings } from '../config-columns-modal.string';
import { FictionalExemple } from '../config-columns-modal.style';
import { ConfigColumns } from './config-columns.component';
import { ConfigRow } from './config-row.component';

export const ConfigColumnsModalBody: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const userLanguage = userInfo.language;
  const strings: ConfigColumnsModalStrings = Strings[userLanguage];

  return (
    <FlexColumn mt vAlign='center' hAlign='center'>
      <FictionalExemple>{strings.fictionalExample}</FictionalExemple>
      <Image.SpreadsheetColumns />
      <Image.SpreadsheetConfig />
      <ConfigColumns />
      <ConfigRow />
    </FlexColumn>
  );
};
