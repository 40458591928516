import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';
import { FilterTypeDropDownOptions } from './filter-type-drop-down-options/filter-type-drop-down-options.component';
import { FilterTypeDropDownWrapper, SelectedText } from './filter-type-drop-down.style';

export const FilterTypeDropDown: React.FC = () => {
  const [open, setOpen] = useState(false);

  const { filterOptionSearch } = useSelector((state: RootState) => state.customerSearch);

  let text = '';
  const market = filterOptionSearch?.market ? `- ${filterOptionSearch?.market}` : '';
  text = `${filterOptionSearch?.name} ${market}`;

  return (
    <FilterTypeDropDownWrapper open={open}>
      <FlexRow
        data-testid='customer-search-modal-filter-type'
        onClick={e => {
          setOpen(!open);
          e.stopPropagation();
        }}
      >
        <SelectedText>{text}</SelectedText>
        <Button kind='link'>{open ? <FaIcon.ChevronUp size='1x' /> : <FaIcon.ChevronDown size='1x' />}</Button>
      </FlexRow>
      {open && <FilterTypeDropDownOptions setOpen={setOpen} />}
    </FilterTypeDropDownWrapper>
  );
};
