import React from 'react';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { TooltipsWrapper } from '../../quote/components/quote.components.style';
import { OrdersStrings } from '../orders.string';

interface OnboardNotificationPanelTooltipProps {
  onClose: () => void;
  onClick: () => void;
  visible: boolean;
}

export const OnboardNotificationPanelTooltip: React.FC<OnboardNotificationPanelTooltipProps> = props => {
  return (
    <TooltipsWrapper>
      <CustomizedTooltip
        step={3}
        totalSteps={4}
        title={OrdersStrings.pt.tooltips.notificationPanel.title}
        description={OrdersStrings.pt.tooltips.notificationPanel.description}
        visible={props.visible}
        onClick={props.onClick}
        onClose={props.onClose}
        customHeight={-20}
        customRightAlign={15}
        positionXFromRight
        hasOffset
        alignRight
      />
    </TooltipsWrapper>
  );
};
