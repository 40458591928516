import { INavigationState } from './navigation.interface';

export const initialState: INavigationState = {
  reloadOpenDashboard: false,
  reloadConfirmedDashboard: false,
  reloadStockDashboard: false,
  reloadBilledDashboard: false,
  reloadFinanceDashboard: false,
  openNpsModal: false,
  openNewsModal: false,
  openNewsModalHistory: false,
  companyLogosTooltipVisible: true,
  openWarningModal: true,
  openBPsModal: false,
  openScheduleModal: false,
  openHamburguerMenu: false,
  openMaintenanceModal: false,
  openPreventiveStopModal: false,
  openSidebarFilters: false,
  openFinanceSidebarFilters: false,
  openErrorHandlerModal: false,
  onboarding: null,
  loading: false,
};
