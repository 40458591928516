export enum TruckTrackingStepStatus {
  Finished = 'finished',
  Current = 'current',
  Pending = 'pending',
}

export interface TrackingInformations {
  generalInformations: GeneralInformations;
  mapInformations: MapInformations;
  trackingUpdateInformations: UpdatedInformations;
}

export interface MapInformations {
  truck: Coordinates;
  origin: Coordinates;
  destination: Coordinates;
}

interface Coordinates {
  lat: string;
  long: string;
}

export interface GeneralInformations {
  transportType: string;
  licensePlate: string;
  truckLicensePlate: string;
  route: string;
  travelStatus: string;
}

export interface UpdatedInformations {
  scheduled: {
    shippingInformation: StatusInformation;
    deliveryInformation: StatusInformation;
  };
  real: {
    shippingInformation: StatusInformation;
    deliveryInformation: StatusInformation;
  };
  currentLocation: {
    city: string;
    state: string;
    remainingDistance: number;
    vehicleLastUpdate: string;
    lastUpdate: string;
  };
}

export interface StatusInformation {
  message: string;
  date: string;
  timeHasPassed: boolean;
}
