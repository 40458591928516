import React, { createContext, useState, RefObject } from 'react';
import { DropDownMenu } from './drop-down-menu.component';

export interface DropDownMenuContainerProps {
  children: JSX.Element;
}

export interface IMenuItem {
  icon: string;
  text: string;
  onclick: () => void;
}

export interface DropDownMenuContextState {
  openDropDownMenu: (ref: RefObject<HTMLElement>, menuItens: IMenuItem[], offSetLeft?: number) => void;
  closeDropDownMenu: () => void;
}

export const DropDownMenuContext = createContext(null);

const POSTION_ARROW_Y_FROM_TOP = 20;
const POSTION_ARROW_X_FROM_RIGHT = 160;

export const DropDownMenuContainer: React.FC<DropDownMenuContainerProps> = ({ children }) => {
  const [openDropDownMenuState, setOpenDropDownMenuState] = useState(false);
  const [offSetRightState, setOffSetRightState] = useState(0);
  const [elementRect, setElementRect] = useState<DOMRect>(null);
  const [menuItens, setMenuItens] = useState<IMenuItem[]>(null);

  const openDropDownMenu = (ref: RefObject<HTMLElement>, itens: IMenuItem[], offSetLeft = 0) => {
    setElementRect(ref?.current?.getBoundingClientRect());
    setOpenDropDownMenuState(true);
    setMenuItens(itens);
    setOffSetRightState(offSetLeft);
  };

  const closeDropDownMenu = () => setOpenDropDownMenuState(false);

  const dropdownmenuContextValue: DropDownMenuContextState = { closeDropDownMenu, openDropDownMenu };
  const yAxis = elementRect?.y + POSTION_ARROW_Y_FROM_TOP;
  const xAxis = elementRect?.x - POSTION_ARROW_X_FROM_RIGHT + offSetRightState;

  return (
    <DropDownMenuContext.Provider value={dropdownmenuContextValue}>
      {openDropDownMenuState && <DropDownMenu menuItens={menuItens} onClose={closeDropDownMenu} x={xAxis} y={yAxis} />}
      {children}
    </DropDownMenuContext.Provider>
  );
};
