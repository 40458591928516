import styled from 'styled-components';
import { Border, Breakpoint, Color } from '@atomic/obj.constants';

export const AuthBoxWrapperStyled = styled.div`
  background-image: url('assets/img/img_auth_background.jpg');
  background-size: cover;
  height: 100vh;
  width: 100vw;
`;

export const AuthBoxStyled = styled.div`
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${Color.White};
  border-radius: ${Border.Radius};
  max-width: 500px;

  @media all and (min-width: ${Breakpoint.lg}em) {
    width: 800px;
  }

  @media all and (min-width: ${Breakpoint.md}em) {
    width: 60%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const AuthFormStyled = styled.div`
  padding: 10px;

  @media all and (min-width: ${Breakpoint.md}em) {
    padding: 45px;
  }
`;

export const AuthLanguageStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  & > p {
    justify-content: flex-end;
  }
  & > p > img {
    max-width: 35px;
  }
`;
