import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { ConfigColumnsModel } from '@app/models';
import { SearchByMaterial } from '@app/modules/order-input/order-input.constants';
import {
  setAmountColumn,
  setDateWishedColumn,
  setItemIdentifierColumn,
  setMaterialCodeColumn,
  setOrderNumberColumn,
  setUnityAmountColumn,
} from '@app/modules/order-input/store/order-input.store';
import { Body, Label } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { ConfigColumnsModalStrings, Strings } from '../config-columns-modal.string';
import { ColumnsBorder, ColumnsWrapper } from '../config-columns-modal.style';
import { ColumnInput } from './column-input.component';

export const ConfigColumns: React.FC = () => {
  const { searchBy, loadingGetConfigColumnsSpreadsheet: loading, configColumnsSpreadsheet } = useSelector(
    (state: RootState) => state.orderInput,
  );
  const {
    materialCodeColumn,
    amountColumn,
    unityAmountColumn,
    dateWishedColumn,
    orderNumberColumn,
    itemIdentifierColumn,
  }: ConfigColumnsModel = configColumnsSpreadsheet;

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const userLanguage = userInfo.language;
  const strings: ConfigColumnsModalStrings = Strings[userLanguage];
  const dispatch = useDispatch();

  return (
    <FlexColumn mt fullWidth>
      <Body fullWidth>{strings.descriptionColumn}</Body>
      <FlexColumn hAlign='center'>
        <Label mt mb={Spacing.XSmall}>
          {strings.columnsTitle}
        </Label>
        <ColumnsBorder />
        <ColumnsWrapper>
          <ColumnInput
            disabled={loading}
            value={materialCodeColumn}
            labelText={
              searchBy === SearchByMaterial.SALES_DOCUMENT_ITEM_TEXT
                ? strings.columns.materialGerdau
                : strings.columns.materialClient
            }
            setValue={value => dispatch(setMaterialCodeColumn(value))}
            invalid={!materialCodeColumn}
          />
          <ColumnInput
            disabled={loading}
            value={amountColumn}
            labelText={strings.columns.amount}
            setValue={value => dispatch(setAmountColumn(value))}
            invalid={!amountColumn}
          />
          <ColumnInput
            disabled={loading}
            value={unityAmountColumn}
            labelText={strings.columns.unityAmount}
            setValue={value => dispatch(setUnityAmountColumn(value))}
            invalid={!unityAmountColumn}
          />
          <ColumnInput
            disabled={loading}
            value={dateWishedColumn}
            labelText={strings.columns.dateWished}
            setValue={value => dispatch(setDateWishedColumn(value))}
            invalid={!dateWishedColumn}
          />
          <ColumnInput
            disabled={loading}
            value={orderNumberColumn}
            labelText={strings.columns.orderNumber}
            setValue={value => dispatch(setOrderNumberColumn(value))}
            invalid={!orderNumberColumn}
          />
          <ColumnInput
            disabled={loading}
            value={itemIdentifierColumn}
            labelText={strings.columns.itemIndentifier}
            setValue={value => dispatch(setItemIdentifierColumn(value))}
          />
        </ColumnsWrapper>
      </FlexColumn>
    </FlexColumn>
  );
};
