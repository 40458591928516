import { ColDef } from 'ag-grid-community';
import { THYSSENKRUPP_BPS } from '@app/constants';
import { LayoutTabs } from '@app/models/layout.model';
import { GridColumnDefinition } from '@app/modules/components';
import { CustomerSelection } from '@app/providers';
import { addColumn, cloneReadOnlyTabs, removeColumn } from '../../grid-layout.utils';

export const manageFieldsTHYSSENKRUPP = (
  tabs: GridColumnDefinition[],
  clientBpsSelected: CustomerSelection[],
): GridColumnDefinition[] => {
  const configuredTabs = cloneReadOnlyTabs(tabs);

  if (tabs) {
    const openMITabIndex = tabs.findIndex((tab: GridColumnDefinition) => tab.name === LayoutTabs.OpenMI);
    const stockTabIndex = tabs.findIndex((tab: GridColumnDefinition) => tab.name === LayoutTabs.StockMI);
    const billedTabIndex = tabs.findIndex((tab: GridColumnDefinition) => tab.name === LayoutTabs.BilledMI);

    const applicableTabsIndex = [openMITabIndex, stockTabIndex, billedTabIndex];

    applicableTabsIndex.forEach(tabIndex => {
      const columnDef: ColDef[] = configuredTabs[tabIndex].cols;
      if (clientBpsSelected.some(bps => THYSSENKRUPP_BPS.includes(bps.customerId))) {
        addFieldsTHYSSENKRUPP(columnDef);
      } else {
        configuredTabs[tabIndex].cols = removeFieldsTHYSSENKRUPP(columnDef);
      }
    });
  }

  return configuredTabs;
};

export const addFieldsTHYSSENKRUPP = (columnDef: ColDef[]): void => {
  addColumn({
    columnDef,
    field: 'customerPurchaseOrderItemNumber',
    headerName: 'Sequencial',
    cellRenderer: null,
    width: 130,
    hide: false,
    filter: 'agTextColumnFilter',
  });
};

export const removeFieldsTHYSSENKRUPP = (columnDef: ColDef[]): ColDef[] => {
  return removeColumn(columnDef, 'customerPurchaseOrderItemNumber');
};
