import * as React from 'react';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { IconSize } from '@atomic/obj.constants';
import { CellLinkStyled, CellWrapperStyled } from './cell-link.component.style';

interface CellLinkProps {
  loading?: boolean;
  onClick?: () => void;
  to?: any;
}
export const CellLink: React.FC<CellLinkProps> = props => {
  const handleClick = e => {
    if (!props.to) {
      e.preventDefault();
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <CellWrapperStyled>
      {props.loading ? (
        <ActivityIndicator type='spinner' size={IconSize.Medium} />
      ) : (
        <CellLinkStyled onClick={handleClick} to={props.to}>
          {props.children}
        </CellLinkStyled>
      )}
    </CellWrapperStyled>
  );
};
