import React from 'react';
import { FlexColumn } from '@atomic/obj.box';
import { ScheduleModalListBody } from './partials/schedule-modal-list-body';
import { ScheduleModalListFooter } from './partials/schedule-modal-list-footer';
import { ScheduleModalListHeader } from './partials/schedule-modal-list-header';

export const ScheduleModalList: React.FC = () => {
  return (
    <FlexColumn>
      <ScheduleModalListHeader />
      <ScheduleModalListBody />
      <ScheduleModalListFooter />
    </FlexColumn>
  );
};
