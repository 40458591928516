import styled from 'styled-components';

export const NotificationStepWrapperStyled = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const NotificationListWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;
