import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { setOpenMaintenanceModal } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { FlexRow } from '@atomic/obj.box';
import { Modal } from '@atomic/obj.modal';
import { Strings, IAppStrings } from '../../app/app.string';
import {
  MaintenanceModalBody,
  MaintenanceModalCloseWrapper,
  MaintenanceModalFooter,
  MaintenanceModalHeader,
  MaintenanceModalImage,
  MaintenanceModalTitleWrapper,
  MaintenanceModalWrapper,
} from './maintenance-modal.style';

export const MaintenanceModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IAppStrings = Strings[userInfo?.language];
  const { openMaintenanceModal } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleClose = () => {
    dispatch(setOpenMaintenanceModal(false));
  };

  const handleImage = () => {
    let imageName = '';

    if (userInfo.language === 'in') {
      imageName = 'manutencao-simples-eng.jpg';
    } else if (userInfo.language === 'es') {
      imageName = 'manutencao-simples-spa.jpg';
    } else {
      imageName = 'manutencao-simples.jpg';
    }

    return (
      <MaintenanceModalImage>
        <img width='600px' src={`assets/img/maintenance-modal/${imageName}`} />
      </MaintenanceModalImage>
    );
  };

  const startDateBlockTime = new Date('2024-11-01T17:30:00').getTime();
  const endDateBlockTime = new Date('2024-11-01T18:30:00').getTime();
  const currentDate = Date.now();
  const isBlockTime = startDateBlockTime <= currentDate && endDateBlockTime >= currentDate;
  const isLoginPage = window.location.pathname.indexOf('/auth') === 0;

  useEffect(() => {
    if (isBlockTime && isLoginPage) {
      dispatch(setOpenMaintenanceModal(true));
    }
  }, []);

  return (
    <Modal small opened={openMaintenanceModal}>
      <MaintenanceModalHeader>
        <MaintenanceModalTitleWrapper>{strings?.maintenanceModal.title}</MaintenanceModalTitleWrapper>
        <MaintenanceModalCloseWrapper>
          {!isBlockTime && (
            <FlexRow onClick={() => dispatch(setOpenMaintenanceModal(false))} style={{ cursor: 'pointer' }}>
              <Image.NewsModalHeaderCloseButton />
            </FlexRow>
          )}
        </MaintenanceModalCloseWrapper>
      </MaintenanceModalHeader>

      <MaintenanceModalWrapper>
        <MaintenanceModalBody>
          <MaintenanceModalImage>{handleImage()}</MaintenanceModalImage>
          {!isBlockTime && (
            <MaintenanceModalFooter>
              <FlexRow hAlign='center'>
                <Button testid={`${TEST_ID_CLOSE_MODAL}-maintenance`} onClick={() => handleClose()}>
                  {strings?.newsModal.close}
                </Button>
              </FlexRow>
            </MaintenanceModalFooter>
          )}
        </MaintenanceModalBody>
      </MaintenanceModalWrapper>
    </Modal>
  );
};
