import { hasWindow } from '@app/core/browser/platform';
import { FakeStorage } from './fake.storage';
import { Storage } from './storage';

export enum StorageType {
  Local = 'localStorage',
  Session = 'sessionStorage',
}

export function getStorage(type: StorageType): Storage {
  const storage = getWindowStorage(type);
  return makeStorage(storage);
}

function getWindowStorage(type: StorageType) {
  if (!hasWindow() || !window[type]) {
    return new FakeStorage();
  } else {
    return window[type];
  }
}

function makeStorage(storage: any): Storage {
  return {
    get(key: string): any {
      try {
        return JSON.parse(storage.getItem(key));
      } catch (err) {
        return null;
      }
    },

    put(key: string, value: object) {
      const data = JSON.stringify(value);
      storage.setItem(key, data);
    },

    remove(key: string) {
      storage.removeItem(key);
    },
  };
}
