export const holidays = [
  '2023-01-01',
  '2023-02-21',
  '2023-04-07',
  '2023-04-21',
  '2023-05-01',
  '2023-06-08',
  '2023-08-13',
  '2023-09-07',
  '2023-10-12',
  '2023-11-02',
  '2023-11-15',
  '2023-12-25',
  '2024-01-01',
  '2024-02-13',
  '2024-03-29',
  '2024-04-21',
  '2024-05-01',
  '2024-05-30',
  '2024-08-11',
  '2024-09-07',
  '2024-10-12',
  '2024-11-02',
  '2024-11-15',
  '2024-12-25',
  '2025-01-01',
  '2025-03-04',
  '2025-04-18',
  '2025-04-21',
  '2025-05-01',
  '2025-06-19',
  '2025-08-10',
  '2025-09-07',
  '2025-10-12',
  '2025-11-02',
  '2025-11-15',
  '2025-12-25',
  '2026-01-01',
  '2026-02-17',
  '2026-04-03',
  '2026-04-21',
  '2026-05-01',
  '2026-06-04',
  '2026-08-09',
  '2026-09-07',
  '2026-10-12',
  '2026-11-02',
  '2026-11-15',
  '2026-12-25',
  '2027-01-01',
  '2027-02-09',
  '2027-03-26',
  '2027-04-21',
  '2027-05-01',
  '2027-05-27',
  '2027-08-08',
  '2027-09-07',
  '2027-10-12',
  '2027-11-02',
  '2027-11-15',
  '2027-12-25',
  '2028-01-01',
  '2028-02-29',
  '2028-04-14',
  '2028-04-21',
  '2028-05-01',
  '2028-06-15',
  '2028-09-07',
  '2028-10-12',
  '2028-11-02',
  '2028-11-15',
  '2028-12-25',
  '2029-01-01',
  '2029-02-13',
  '2029-03-30',
  '2029-04-21',
  '2029-05-01',
  '2029-05-31',
  '2029-09-07',
  '2029-10-12',
  '2029-11-02',
  '2029-11-15',
  '2029-12-25',
  '2030-01-01',
  '2030-03-05',
  '2030-04-19',
  '2030-04-21',
  '2030-05-01',
  '2030-06-20',
  '2030-09-07',
  '2030-10-12',
  '2030-11-02',
  '2030-11-15',
  '2030-12-25',
  '2031-01-01',
  '2031-02-25',
  '2031-04-11',
  '2031-04-21',
  '2031-05-01',
  '2031-06-12',
  '2031-09-07',
  '2031-10-12',
  '2031-11-02',
  '2031-11-15',
  '2031-12-25',
  '2032-01-01',
  '2032-02-10',
  '2032-03-26',
  '2032-04-21',
  '2032-05-01',
  '2032-05-27',
  '2032-09-07',
  '2032-10-12',
  '2032-11-02',
  '2032-11-15',
  '2032-12-25',
];
