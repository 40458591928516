import styled, { css } from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Border, Color, FontSize } from '@atomic/obj.constants';

const BorderMediumAlertCSS = css`
  border-color: ${Color.MediumAlert};
`;

interface AmountInputProps {
  hasError: boolean;
}

export const MultipleInput = styled.input`
  ${(props: AmountInputProps) => props.hasError && BorderMediumAlertCSS};
  width: 80px;
  height: 40px;
  background-color: ${Color.GrayXLight};
  border: 2px solid ${Color.GrayLight};
  border-radius: ${Border.Radius};
  text-align: center;
`;

export const AmountErrorWrapper = styled(FlexRow)`
  font-size: ${FontSize.XSmall};
  color: ${Color.Alert};
  height: 18px;
  justify-content: center;
  padding-left: 5%;
`;
