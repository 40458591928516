import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, Cell, LabelList, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '@app/core/redux/store';
import { DashboardDataSource } from '@app/data/http/dashboard.datasource';
import { useGetDashboard } from '@app/domain/get-dashboard.use-case';
import {
  ConfirmedDeliveryByStatus,
  ConfirmedOrderGraphs,
  DashboardGraphName,
  DashboardLegendData,
} from '@app/models/dashboard.model';
import { setReloadConfirmedDashboard } from '@app/providers/navigation/navigation.store';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Frame } from '@atomic/atm.frame';
import { H2 } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { DashboardBlockHeader } from './dashboard-block-header.component';
import { DashboardBlockShimmer } from './dashboard-block.shimmer';
import { DashboardGroupLegend } from './dashboard-group-legend';
import { Strings, IDashBoardStrings } from './dashboard.string';
import { COLORS, deliveryForecastStatus } from './random-data';
import { DashboardProps } from '.';

const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = '95%';

const strings: IDashBoardStrings = Strings.pt;
const { confirmedOrders: confirmedOrdersStrings } = strings.graphs;

const confirmedDeliveryByStatusInfo = (data: ConfirmedDeliveryByStatus) => {
  return [
    { name: confirmedOrdersStrings.confirmedDeliveryByStatus.delayed, value: data?.valueByStatus?.delayed },
    { name: confirmedOrdersStrings.confirmedDeliveryByStatus.onTime, value: data?.valueByStatus?.onTime },
    { name: confirmedOrdersStrings.confirmedDeliveryByStatus.noForecast, value: data?.valueByStatus?.noForecast },
    { name: confirmedOrdersStrings.confirmedDeliveryByStatus.delayTrend, value: data?.valueByStatus?.delayTrend },
  ];
};

const handleClick = props => {
  console.warn(props.tooltipPayload[0].name);
};

const CONFIRMED_DASHBOARD_DATA = 'confirmed_dashboard_data';
const DO_REQUESTS_FOR_DASHBOARDS = 'do_requests_for_dashboards';

export const ConfirmedDashboard: React.FC<DashboardProps> = props => {
  const { reloadConfirmedDashboard } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();

  const [confirmedDashboard, setConfirmedDashboardData] = useLocalStorageState(CONFIRMED_DASHBOARD_DATA, {});
  const doRequestForDashboards = window.localStorage.getItem(DO_REQUESTS_FOR_DASHBOARDS);

  const { data, loading, error, performRequest } = useGetDashboard<ConfirmedOrderGraphs>(
    DashboardDataSource.getConfirmedOrders,
    props.onSuccess,
  );

  useEffect(() => {
    if (reloadConfirmedDashboard) {
      performRequest(props.clientNumber);
      dispatch(setReloadConfirmedDashboard(false));
    }
  }, [reloadConfirmedDashboard]);

  useEffect(() => {
    if (props.clientNumber && doRequestForDashboards === 'true') {
      performRequest(props.clientNumber);
      dispatch(setReloadConfirmedDashboard(false));
    }
  }, [props.clientNumber, doRequestForDashboards]);

  useEffect(() => {
    if (data) {
      setConfirmedDashboardData(data);
    }
  }, [data]);

  const confirmedDeliveryGraphLegend: DashboardLegendData[] = [
    { title: confirmedOrdersStrings.total, unit: 't', amount: confirmedDashboard?.confirmedDeliveryByStatus?.total },
    {
      title: confirmedOrdersStrings.confirmedDeliveryByStatus.delayed,
      unit: 't',
      amount: confirmedDashboard?.confirmedDeliveryByStatus?.valueByStatus?.delayed,
      iconColor: Color.Accessory,
    },
    {
      title: confirmedOrdersStrings.confirmedDeliveryByStatus.onTime,
      unit: 't',
      amount: confirmedDashboard?.confirmedDeliveryByStatus?.valueByStatus?.onTime,
      iconColor: Color.Primary,
    },
    {
      title: confirmedOrdersStrings.confirmedDeliveryByStatus.noForecast,
      unit: 't',
      amount: confirmedDashboard?.confirmedDeliveryByStatus?.valueByStatus?.noForecast,
      iconColor: Color.PinkDark,
    },
    {
      title: confirmedOrdersStrings.confirmedDeliveryByStatus.delayTrend,
      unit: 't',
      amount: confirmedDashboard?.confirmedDeliveryByStatus?.valueByStatus?.delayTrend,
      iconColor: Color.Orange,
    },
  ];

  const monthlyDeliveryForecastGraphLegend: DashboardLegendData[] = [
    { title: confirmedOrdersStrings.total, amount: confirmedDashboard?.monthlyDeliveryForecast?.total, unit: 't' },
    {
      title: confirmedOrdersStrings.monthlyDeliveryForecast.billedQty,
      iconColor: Color.Accessory,
    },
    {
      title: confirmedOrdersStrings.monthlyDeliveryForecast.pendingQty,
      iconColor: Color.PinkDark,
    },
  ];

  return (
    <LoadingState loading={loading} data={!!confirmedDashboard} error={!!error}>
      <LoadingState.Shimmer>
        <DashboardBlockShimmer blocks={2} />
      </LoadingState.Shimmer>

      <div key={props.screenWidth}>
        <H2 center>{strings.confirmedOrder.title}</H2>

        <Row>
          <Col xs={12} md={6}>
            <Frame>
              <VSeparator />

              <DashboardBlockHeader
                title={strings.confirmedOrder.forecast}
                description={confirmedOrdersStrings.monthlyDeliveryForecast.description}
              />
              <VSeparator />

              <DashboardGroupLegend
                legend={monthlyDeliveryForecastGraphLegend}
                graph={DashboardGraphName.MonthlyDeliveryForecastGraph}
              />
              <VSeparator />

              <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
                <BarChart data={confirmedDashboard?.monthlyDeliveryForecast?.months}>
                  <XAxis dataKey='date' />
                  <YAxis domain={[0, datamax => Math.floor(datamax * 1.3)]} />
                  <Tooltip />
                  <Bar
                    dataKey='billed'
                    isAnimationActive={false}
                    name={confirmedOrdersStrings.monthlyDeliveryForecast.billed}
                    stackId='a'
                    fill={Color.Accessory}
                    radius={[0, 0, 4, 4]}
                    onClick={handleClick}
                  >
                    <LabelList
                      dataKey='billed'
                      position='insideTop'
                      fill={Color.White}
                      formatter={value => value + 't'}
                    />
                  </Bar>
                  <Bar
                    dataKey='pendingBilling'
                    isAnimationActive={false}
                    name={confirmedOrdersStrings.monthlyDeliveryForecast.pendingBilling}
                    stackId='a'
                    fill={Color.PinkDark}
                    radius={[4, 4, 0, 0]}
                    onClick={handleClick}
                  >
                    <LabelList dataKey='pendingBilling' position='top' formatter={value => value + 't'} />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Frame>
          </Col>
          <Col xs={12} md={6}>
            <Frame>
              <VSeparator />
              <DashboardBlockHeader
                title={strings.confirmedOrder.forecastStatus}
                description={confirmedOrdersStrings.confirmedDeliveryByStatus.description}
              />
              <VSeparator />

              <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
                <PieChart>
                  <Pie
                    data={confirmedDeliveryByStatusInfo(confirmedDashboard?.confirmedDeliveryByStatus)}
                    isAnimationActive={false}
                    dataKey='value'
                    cx='50%'
                    cy='50%'
                    innerRadius={80}
                    outerRadius={110}
                    label
                    legendType={'circle'}
                    onClick={handleClick}
                  >
                    {deliveryForecastStatus.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>

                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <DashboardGroupLegend
                legend={confirmedDeliveryGraphLegend}
                graph={DashboardGraphName.ConfirmedDeliveryGraph}
              />
              <VSeparator />
            </Frame>
          </Col>
        </Row>
      </div>
    </LoadingState>
  );
};
