import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { MarketType } from '@app/providers';
import { OrderTabsMEContent } from './ME/order-tabs-ME.component';
import { orderInternationalTabEvents } from './ME/order-tabs-ME.constants';
import { OrderTabsMIContent } from './MI/order-tabs-MI.component';
import { orderNationalTabEvents } from './MI/order-tabs-MI.constants';

const TabsEventsMarket = {
  [MarketType.Internal]: orderNationalTabEvents,
  [MarketType.External]: orderInternationalTabEvents,
};

export const OrderTabs: React.FC = () => {
  const { currentMarket } = useSelector((state: RootState) => state.customerPersist);
  const { orderTabIndex } = useSelector((state: RootState) => state.order);

  useEffect(() => {
    LogAnalytics.pageView(TabsEventsMarket[currentMarket][orderTabIndex]);
  }, []);

  return (
    <div data-testid='order-tabs'>
      {currentMarket === MarketType.Internal ? <OrderTabsMIContent /> : <OrderTabsMEContent />}
    </div>
  );
};
