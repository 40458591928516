import { LogAnalytics } from '@app/core/analytics';
import { UserAnalyticsType } from '@app/models/analytics.model';
import { UserData } from '@app/providers';
import { ANONYMOUS_ID } from './nps-modal.constants';

export const sendNpsAnalytcs = (payload: any, user: UserData) => {
  LogAnalytics.setUserId(ANONYMOUS_ID);
  LogAnalytics.setUserProperties({
    kind: UserAnalyticsType.Client,
    userId: ANONYMOUS_ID,
  });

  LogAnalytics.nps(payload);

  setTimeout(() => {
    LogAnalytics.setUserId(user.uid);
    LogAnalytics.setUserProperties({
      kind: user.isEmployee ? UserAnalyticsType.Employee : UserAnalyticsType.Client,
      userId: user.uid,
    });
  }, 0);
};
