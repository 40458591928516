import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '@app/core/redux/store';
import { DashboardDataSource } from '@app/data/http/dashboard.datasource';
import { useGetDashboard } from '@app/domain/get-dashboard.use-case';
import { DashboardGraphName, DashboardLegendData, FinanceOpenMatchesGraphs } from '@app/models/dashboard.model';
import { setReloadConfirmedDashboard } from '@app/providers/navigation/navigation.store';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Frame } from '@atomic/atm.frame';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { DashboardBlockHeader } from '../dashboard-block-header.component';
import { DashboardBlockShimmer } from '../dashboard-block.shimmer';
import { DashboardGroupLegend } from '../dashboard-group-legend';
import { Strings, IDashBoardStrings } from '../dashboard.string';

const FRAME_HEIGHT = '480px';
const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = '95%';

const FINANCE_OM_GRAPH_DATA = 'finance_om_graph_data';
const DO_REQUESTS_FOR_DASHBOARDS = 'do_requests_for_dashboards';

enum OpenMatchesStatus {
  Expired = 'Expirado',
  NotExpired = 'A vencer',
}

enum ExpireRanges {
  ExpiredGreaterThan30 = 'Vencido > 30',
  Expired11To30 = 'Vencido 11 - 30',
  Expired01To10 = 'Vencido 01 - 10',
  ToExpire0To10 = 'A vencer 00 - 10',
  ToExpire11To30 = 'A vencer 11 - 30',
  ToExpireGreaterThan30 = 'A vencer > 30',
}

export const FinanceOpenMatchesGraph = props => {
  const strings: IDashBoardStrings = Strings.pt;
  const { finance: financeGraphsStrings } = strings.graphs;

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { reloadConfirmedDashboard } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();

  const [financeOMGraph, setFinanceOMGraphData] = useLocalStorageState(FINANCE_OM_GRAPH_DATA, {});
  const doRequestForDashboards = window.localStorage.getItem(DO_REQUESTS_FOR_DASHBOARDS);

  const { data, loading, error, performRequest } = useGetDashboard<FinanceOpenMatchesGraphs>(
    DashboardDataSource.getFinanceOpenMatches,
    props.onSuccess,
  );

  const dataFormater = (number, islabel) => {
    if (islabel) {
      return `R$ ${Math.floor(number).toLocaleString(userInfo?.language)}`;
    } else {
      return `${Math.floor(number).toLocaleString(userInfo?.language)}`;
    }
  };

  const openMatchesGraphLegend: DashboardLegendData[] = [
    {
      title: financeGraphsStrings.generalTotal,
      amount: dataFormater(financeOMGraph?.expiredTotal, false),
      currency: 'R$ ',
    },
    {
      title: financeGraphsStrings.openMatch.expiredGeneralTotal,
      amount: dataFormater(financeOMGraph?.expiredGeneralTotal, false),
      iconColor: Color.Warning,
      currency: 'R$ ',
    },
    {
      title: financeGraphsStrings.openMatch.notExpiredGeneralTotal,
      amount: dataFormater(financeOMGraph?.notExpiredGeneralTotal, false),
      iconColor: Color.Primary,
      currency: 'R$ ',
    },
  ];

  const openMatchesDocumentGraphLegend: DashboardLegendData[] = [
    { title: financeGraphsStrings.openMatch.documentNumberQty, amount: financeOMGraph?.expiredDocumentNumbersTotal },
    {
      title: financeGraphsStrings.openMatch.expiredGeneralTotal,
      amount: dataFormater(financeOMGraph?.expiredDocumentNumbers, false),
    },
    {
      title: financeGraphsStrings.openMatch.notExpiredGeneralTotal,
      amount: dataFormater(financeOMGraph?.notExpiredDocumentNumbers, false),
    },
  ];

  useEffect(() => {
    if (reloadConfirmedDashboard) {
      performRequest(props.clientNumber);
      dispatch(setReloadConfirmedDashboard(false));
    }
  }, [reloadConfirmedDashboard]);

  useEffect(() => {
    if (props.clientNumber && doRequestForDashboards === 'true') {
      performRequest(props.clientNumber);
      dispatch(setReloadConfirmedDashboard(false));
    }
  }, [props.clientNumber, doRequestForDashboards]);

  useEffect(() => {
    if (data && data.openMatches && data.openMatches.length > 0) {
      handleDataOpenMatchStatus();
    }
  }, [data?.openMatches]);

  const handleRangeStatusDetail = openMatches => {
    let expiredGT30Total = 0;
    let expired11To30Total = 0;
    let expired01To10Total = 0;
    let notExpired0To10Total = 0;
    let notExpired11To30Total = 0;
    let notExpiredGT30Total = 0;

    openMatches.map(o => {
      switch (o.expiredStatus) {
        case ExpireRanges.ExpiredGreaterThan30:
          expiredGT30Total = expiredGT30Total + o.expiredGeneralTotal;
          break;
        case ExpireRanges.Expired11To30:
          expired11To30Total = expired11To30Total + o.expiredGeneralTotal;
          break;
        case ExpireRanges.Expired01To10:
          expired01To10Total = expired01To10Total + o.expiredGeneralTotal;
          break;
        default:
          break;
      }

      switch (o.notExpiredStatus.toLowerCase()) {
        case ExpireRanges.ToExpire0To10.toLowerCase():
          notExpired0To10Total = notExpired0To10Total + o.notExpiredGeneralTotal;
          break;
        case ExpireRanges.ToExpire11To30.toLowerCase():
          notExpired11To30Total = notExpired11To30Total + o.notExpiredGeneralTotal;
          break;
        case ExpireRanges.ToExpireGreaterThan30.toLowerCase():
          notExpiredGT30Total = notExpiredGT30Total + o.notExpiredGeneralTotal;
          break;
        default:
          break;
      }
    });

    return [
      { status: OpenMatchesStatus.Expired, range: ExpireRanges.ExpiredGreaterThan30, value: expiredGT30Total },
      { status: OpenMatchesStatus.Expired, range: ExpireRanges.Expired11To30, value: expired11To30Total },
      { status: OpenMatchesStatus.Expired, range: ExpireRanges.Expired01To10, value: expired01To10Total },
      { status: OpenMatchesStatus.NotExpired, range: ExpireRanges.ToExpire0To10, value: notExpired0To10Total },
      { status: OpenMatchesStatus.NotExpired, range: ExpireRanges.ToExpire11To30, value: notExpired11To30Total },
      { status: OpenMatchesStatus.NotExpired, range: ExpireRanges.ToExpireGreaterThan30, value: notExpiredGT30Total },
    ];
  };

  const handleDataOpenMatchStatus = () => {
    let expiredGeneralTotalAux = 0;
    let notExpiredGeneralTotalAux = 0;
    let expiredTotalAux = 0;
    let expiredDocumentNumbersAux = 0;
    let notExpiredDocumentNumbersAux = 0;
    let expiredDocumentNumbersTotalAux = 0;

    if (data?.openMatches && data?.openMatches?.length) {
      data?.openMatches.forEach(o => {
        expiredGeneralTotalAux = expiredGeneralTotalAux + o.expiredGeneralTotal;
        notExpiredGeneralTotalAux = notExpiredGeneralTotalAux + o.notExpiredGeneralTotal;
        expiredTotalAux = expiredTotalAux + o.expiredGeneralTotal + o.notExpiredGeneralTotal;
        expiredDocumentNumbersAux = expiredDocumentNumbersAux + o.expiredDocumentNumbers;
        notExpiredDocumentNumbersAux = notExpiredDocumentNumbersAux + o.notExpiredDocumentNumbers;
        expiredDocumentNumbersTotalAux =
          expiredDocumentNumbersTotalAux + o.expiredDocumentNumbers + o.notExpiredDocumentNumbers;
      });

      const openMatchStatusDataAux = [
        { name: financeGraphsStrings.openMatch.expiredGeneralTotal, value: Math.floor(expiredGeneralTotalAux) },
        {
          name: financeGraphsStrings.openMatch.notExpiredGeneralTotal,
          value: Math.floor(notExpiredGeneralTotalAux),
        },
      ];

      setFinanceOMGraphData({
        expiredGeneralTotal: expiredGeneralTotalAux,
        notExpiredGeneralTotal: notExpiredGeneralTotalAux,
        expiredTotal: expiredTotalAux,
        expiredDocumentNumbers: expiredDocumentNumbersAux,
        notExpiredDocumentNumbers: notExpiredDocumentNumbersAux,
        expiredDocumentNumbersTotal: expiredDocumentNumbersTotalAux,
        openMatchStatusData: openMatchStatusDataAux,
        expiredRangeStatusDetail: handleRangeStatusDetail(data?.openMatches),
      });
    }
  };

  const OpenMatchesLabel = event => {
    const { x, y, value, width } = event;
    const labelPadding = width ? width + x + 5 : x;

    return (
      <text x={labelPadding} y={y + 20} fontSize='16' textAnchor='start'>
        {dataFormater(value, true)}
      </text>
    );
  };

  return (
    <LoadingState loading={loading} data={!!financeOMGraph} error={!!error}>
      <LoadingState.Shimmer>
        <DashboardBlockShimmer blocks={1} />
      </LoadingState.Shimmer>

      <Frame height={FRAME_HEIGHT}>
        <VSeparator />
        <DashboardBlockHeader
          title={strings.finance.openMatches}
          description={financeGraphsStrings.openMatch.description}
        />
        <VSeparator />

        <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
          <BarChart
            data={financeOMGraph?.expiredRangeStatusDetail}
            layout='vertical'
            barCategoryGap={10}
            margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
          >
            <XAxis type='number' hide />
            <YAxis type='category' width={150} dataKey='range' orientation='left' tickMargin={15} tickLine={false} />
            <Tooltip formatter={dataFormater} />
            <Bar
              dataKey='value'
              radius={[4, 4, 4, 4]}
              name={strings.finance.expiredOpenMatches}
              label={<OpenMatchesLabel />}
            >
              {financeOMGraph?.expiredRangeStatusDetail?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.status === OpenMatchesStatus.Expired ? Color.Warning : Color.Primary}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <DashboardGroupLegend legend={openMatchesGraphLegend} graph={DashboardGraphName.OpenMatchesGraph} />
        <VSeparator />
        <DashboardGroupLegend
          legend={openMatchesDocumentGraphLegend}
          graph={DashboardGraphName.OpenMatchesDocumentGraph}
        />
        <VSeparator />
      </Frame>
    </LoadingState>
  );
};
