import React from 'react';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { strings } from '../../../schedule.strings';

export const ScheduleModalListEmpty: React.FC = () => {
  return (
    <>
      <FlexRow mt hAlign='center'>
        <Image.ScheduleModalCalendar />
      </FlexRow>
      <FlexRow mb hAlign='center'>
        <H2 style={{ color: Color.GrayDark }}>{strings.emptyMessage}</H2>
      </FlexRow>
      <VSeparator lineVisible />
    </>
  );
};
