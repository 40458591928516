import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Row } from 'react-styled-flexboxgrid';
import { RootState } from '@app/core/redux/store';
import { setShowM2CalendarModal } from '@app/modules/order-input/store/order-input.store';
import { Image } from '@atomic/atm.image';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';

export const M2CalendarModal: React.FC = () => {
  const { showM2CalendarModal } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setShowM2CalendarModal(false));
  };
  return (
    <Modal customWidth='1350px' customHeight='1000px' opened={showM2CalendarModal} onClose={onClose}>
      <Grid>
        <Row>
          <FlexRow fullWidth pt={Spacing.XXLarge} pb={Spacing.XXLarge} vAlign='center' hAlign='center'>
            <Image.OrderInputCalendar />
          </FlexRow>
        </Row>
      </Grid>
    </Modal>
  );
};
