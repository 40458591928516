import { Material } from '@app/models';

export interface MaterialDTO {
  materialCode: string;
  materialDescricao: string;
  materialName: string;
  minMaxLenght: string;
  multipleLength: string;
  standardGrade: string;
  plant: string;
}

export interface MaterialsDTO {
  implantationRequestMaterial: MaterialDTO[];
}

export const mapMaterials = (data: MaterialsDTO): Material[] => {
  return data.implantationRequestMaterial.map((materialDTO: MaterialDTO) => ({
    materialCode: materialDTO.materialCode,
    materialDescription: materialDTO.materialDescricao,
    materialName: materialDTO.materialName,
    minMaxLenght: materialDTO.minMaxLenght,
    multipleLength: materialDTO.multipleLength,
    standardGrade: materialDTO.standardGrade,
    plant: materialDTO.plant,
  }));
};
