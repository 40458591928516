import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { RouteComponentProps } from '@app/core/route';
import { validateTokens } from '@app/modules/components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { QuoteScreensAnalytics } from '@app/modules/quote/quote.analytics';
import { TechnicalAnalysis } from './technical-analysis.component';
import { TechnicalAnalysisProvider } from './technical-analysis.context';

const REFRESH_INTERVAL = 60000;

export const TechnicalAnalysisPage: React.FC<RouteComponentProps<{ id: string }, {}, {}>> = props => {
  LogAnalytics.setCurrentScreen(QuoteScreensAnalytics.TechnicalAnalysis + props.match.params.id);

  const { isFirstPageLoad } = useSelector((state: RootState) => state.notification);
  const dispatch = useDispatch<AppThunkDispatch>();
  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (isFirstPageLoad) {
        await dispatch(validateTokens(isFirstPageLoad));
      }
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [isFirstPageLoad]);

  return (
    <TechnicalAnalysisProvider>
      <TechnicalAnalysis id={+props.match.params.id} />
    </TechnicalAnalysisProvider>
  );
};
