import * as React from 'react';
import { ButtonText } from '@atomic/atm.typography';
import { TrailingAccordionCollapsibleStyled } from './trailing-accordion.component.style';

interface TrailingAccordionProps {
  openedTitle: string;
  closedTitle: string;
  expanded: boolean;
  onClick: () => void;
}

export const TrailingAccordion: React.FC<TrailingAccordionProps> = props => {
  const [currentHeight, setCurrentHeight] = React.useState(0);

  const measureElement = (element: HTMLDivElement) => {
    const values = element?.getBoundingClientRect();
    if (values?.height && values?.height !== currentHeight) {
      setCurrentHeight(values.height);
    }
  };

  return (
    <>
      <TrailingAccordionCollapsibleStyled expanded={props.expanded} contentHeight={currentHeight}>
        <div ref={measureElement}>{props.children}</div>
      </TrailingAccordionCollapsibleStyled>
      <ButtonText onClick={props.onClick}>{props.expanded ? props.openedTitle : props.closedTitle}</ButtonText>
    </>
  );
};
