import React from 'react';
import { useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { IAlertButtonTypesProps, IOrderUpdateTableData } from '@app/modules/order-update/order-update.interfaces';
import { Strings } from '@app/modules/order-update/order-update.string';
import {
  deleteItem,
  removeAlertCancel,
  removeAlertDate,
  removeAlertStatus,
  revisionItem,
} from '@app/modules/order-update/store/order-update.store';
import { OrderUpdateEvents } from '@app/modules/order/order.analytics';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { TD, TR } from '@atomic/mol.table';
import { FlexRow } from '@atomic/obj.box';
import { BrandColor, Color, Spacing } from '@atomic/obj.constants';

interface IOrderUpdateWarningTRProps {
  item: IOrderUpdateTableData;
  type: 'statusWarning' | 'dateWarning' | 'cancelWarning';
}
const strings = Strings.pt.table;

export const OrderUpdateWarningTR: React.FC<IOrderUpdateWarningTRProps> = ({ item, type }) => {
  const dispatch = useDispatch();

  const onClickIgnoreAlertStatus = () => {
    dispatch(removeAlertStatus({ ov: item.ov, itemOv: item.itemOv }));

    LogAnalytics.click({
      tipo: OrderUpdateEvents.IgnoreAlertStatusClick,
    });
  };
  const onClickIgnoreAlertDate = () => {
    dispatch(removeAlertDate({ ov: item.ov, itemOv: item.itemOv }));

    LogAnalytics.click({
      tipo: OrderUpdateEvents.IgnoreAlertDateClick,
    });
  };
  const onClickDeleteItem = () => {
    dispatch(deleteItem({ ov: item.ov, itemOv: item.itemOv }));

    LogAnalytics.click({
      tipo: OrderUpdateEvents.DeleteItemClick,
    });
  };

  const onClickRevisionItem = () => {
    dispatch(revisionItem({ ov: item.ov, itemOv: item.itemOv }));

    LogAnalytics.click({
      tipo: OrderUpdateEvents.DeleteItemClick,
    });
  };

  const onClickIgnoreAlertCancel = () => {
    dispatch(removeAlertCancel({ ov: item.ov, itemOv: item.itemOv }));

    LogAnalytics.click({
      tipo: OrderUpdateEvents.IgnoreAlertCancelClick,
    });
  };

  const mapFirstButton: IAlertButtonTypesProps = {
    statusWarning: { kind: 'secondary', onClick: onClickIgnoreAlertStatus, text: strings.trIgnoreButton },
    dateWarning: { kind: 'secondary', onClick: onClickIgnoreAlertDate, text: strings.trIgnoreButton },
    cancelWarning: { kind: 'secondary', onClick: onClickIgnoreAlertCancel, text: strings.trIgnoreButton },
  };

  const mapSecondButton: IAlertButtonTypesProps = {
    statusWarning: { kind: 'alertSecondary', onClick: onClickDeleteItem, text: strings.trDeleteItemButton },
    dateWarning: { kind: 'secondary', onClick: onClickRevisionItem, text: strings.trRevisionItemButton },
    cancelWarning: { kind: 'alertSecondary', onClick: onClickDeleteItem, text: strings.trDeleteItemButton },
  };

  const firstButton = mapFirstButton[type];
  const secondButton = mapSecondButton[type];

  return (
    <TR key={`alert-${item.ov}-${item.itemOv}`} color={Color.Yellow}>
      <TD colSpan={12}>
        <FlexRow fullWidth vAlign='center' hAlign='center'>
          <FaIcon.Warning size='sm' color={BrandColor.Yellow} />
          <Body ml={Spacing.Small} mr={Spacing.Small} dangerouslySetInnerHTML={{ __html: strings[type] }} />
          <FlexRow mr={Spacing.Small}>
            <Button size='small' kind={firstButton.kind} onClick={firstButton.onClick}>
              {firstButton.text}
            </Button>
          </FlexRow>
          <FlexRow>
            <Button size='small' kind={secondButton.kind} onClick={secondButton.onClick}>
              {secondButton.text}
            </Button>
          </FlexRow>
        </FlexRow>
      </TD>
    </TR>
  );
};
