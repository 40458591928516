import styled from 'styled-components';
import { Breakpoint, FontSize, Spacing } from '@atomic/obj.constants';

export const CrmItemWrapperStyled = styled.div`
  position: relative;
  & > div {
    margin-top: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${Spacing.Medium};
    grid-row-gap: ${Spacing.Medium};
    cursor: pointer;
    @media all and (max-width: ${Breakpoint.lg}em) {
      grid-template-columns: 100%;
    }
    & > div {
      display: flex;
      flex-direction: column;
      line-height: ${Spacing.Large};
      & > p {
        width: 100%;
        display: block;
        & > label {
          font-size: ${FontSize.Small};
        }
      }
    }
  }
`;

export const DateFilterWrapper = styled.div`
  position: relative;
`;
