import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../schedule.constants';
import { IScheduleState } from '../schedule.interfaces';
import { initialState } from './schedule.initial';
import { reducers } from './schedule.reducers';
import { addThunkReducers } from './thunks/schedule.thunks';

export const scheduleSlice = createSlice<IScheduleState, SliceCaseReducers<IScheduleState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const {
  setCurrentTab,
  setSchedulePhase,
  setScheduleName,
  setShowDaySelect,
  removeSelectedBpSchedule,
  setScheduleType,
  setTime,
  setSelectedDays,
  addSelectedDaysOfWeek,
  removeSelectedDaysOfWeek,
  removeSelectedEmail,
  checkColumnLayout,
  uncheckColumnLayout,
  setOrderColumnLayout,
  setEditSchedule,
  setEditMode,
  setSelectedColumns,
  setQuerySearch,
  toogleSelectClient,
  initClientBpsSearch,
  setSelectedEmails,
} = scheduleSlice.actions;

export const scheduleReducer = scheduleSlice.reducer;
