import styled from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export const FilterTypeDropDownWrapper = styled.div`
  border: 2px solid ${Color.Primary};
  border-radius: 5px;
  margin-right: ${Spacing.Small};
  width: 244px;
  button {
    padding: 0 ${Spacing.Small};
  }
`;

export const SelectedText = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-left: ${Spacing.Small};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Small};
`;
