import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { H2, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { Strings, IDashBoardStrings } from './dashboard.string';
interface ModalAddCommentProps {
  opened: boolean;
  onModalClose: () => void;
  info: DashModalInfo;
}

export interface DashModalInfo {
  title: string;
  name: string;
  category: string;
  quantity: string;
}

export const ModalDashboard: React.FC<ModalAddCommentProps> = props => {
  const strings: IDashBoardStrings = Strings.pt;

  return (
    <Modal small opened={props.opened} onClose={props.onModalClose}>
      <Grid fluid>
        <H2>{props.info.title}</H2>
        <VSeparator />
        <H3>{strings.modal.name(props.info.name, props.info.category)}</H3>
        <H3>{strings.modal.quantity(props.info.quantity)}</H3>
        <Hbox hAlign='flex-end'>
          <Hbox.Item noGrow>
            <Button kind='secondary' onClick={props.onModalClose}>
              {strings.modal.return}
            </Button>
          </Hbox.Item>
          <Hbox.Separator />
        </Hbox>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
