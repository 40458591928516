import styled, { css } from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Color, FontSize } from '@atomic/obj.constants';

const BorderMediumAlertCSS = css`
  border-color: ${Color.MediumAlert}!important;
`;

interface AmountInputProps {
  hasError: boolean;
}

export const AmountInput = styled.input`
  ${(props: AmountInputProps) => props.hasError && BorderMediumAlertCSS};
  width: 108px;
`;

export const AmountErrorWrapper = styled(FlexRow)`
  font-size: ${FontSize.XSmall};
  color: ${Color.Alert};
  height: 18px;
  justify-content: center;
`;
