import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TableRowData } from '@app/data/http/quote-params.dto';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { H1, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ChatStrings } from './chat.string';

export enum MacroataqueField {
  MACROATAQUE_S = 'MACROATAQUE_S',
  MACROATAQUE_R = 'MACROATAQUE_R',
  MACROATAQUE_C = 'MACROATAQUE_C',
}

export interface MacroataqueData {
  [MacroataqueField.MACROATAQUE_C]: number;
  [MacroataqueField.MACROATAQUE_R]: number;
  [MacroataqueField.MACROATAQUE_S]: number;
}

interface MacroataqueRowProps {
  label: string;
  value: number;
  name: string;
  strings: any;
  language: string;
}

const MacroataqueRow: React.FC<MacroataqueRowProps> = props => {
  return (
    <TR>
      <TD>
        <H3>{props.label}</H3>
      </TD>
      <TD>
        <Form.Field
          name={props.name}
          validators={[Validators.Required(props.strings.mandatory)]}
          initialValue={props.value ? props.value : ''}
          hideErrorCaption
        >
          <TextField />
        </Form.Field>
      </TD>
    </TR>
  );
};

interface ModalMacroataqueProps {
  openTableType: string;
  data: MacroataqueData;
  onSubmit: (data: TableRowData[], key: string) => void;
  onClose: () => void;
}

export const ModalMacroataque: React.FC<ModalMacroataqueProps> = props => {
  const [reset, setReset] = React.useState(0);
  const [opened, setOpened] = React.useState(false);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language].modalMacroataque;
  React.useEffect(() => {
    if (props.openTableType.toLowerCase() === 'macroataque') {
      setOpened(true);
    }
  }, [props.openTableType]);

  const handleSubmit = (data: FormData<MacroataqueData>) => {
    if (Object.keys(data.error).length > 0) {
      return;
    }
    props.onSubmit(setTableParams(data.data), 'MACRO_ETCH');
    setOpened(false);
  };

  const setTableParams = (data: MacroataqueData) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      acc.push([
        { columnRef: 'elements', value: key.split('_')[1] },
        { columnRef: 'value', value: value },
      ]);
      return acc;
    }, []);
  };

  const handleReset = () => {
    setReset(reset + 1);
  };

  const handleClose = () => {
    setOpened(false);
    props.onClose();
  };

  return (
    <Modal preventOverlayClick opened={opened} small onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row mb>
            <Col xs={12}>
              <H1>{strings.title}</H1>
            </Col>
          </Row>
          <Row mb key={reset}>
            <Col xs={12}>
              <Table>
                <MacroataqueRow
                  key='S'
                  strings={strings}
                  label='S'
                  name={MacroataqueField.MACROATAQUE_S}
                  value={props.data?.MACROATAQUE_S}
                  language={userInfo.language}
                />
                <MacroataqueRow
                  key='R'
                  strings={strings}
                  label='R'
                  name={MacroataqueField.MACROATAQUE_R}
                  value={props.data?.MACROATAQUE_R}
                  language={userInfo.language}
                />
                <MacroataqueRow
                  key='C'
                  strings={strings}
                  label='C'
                  name={MacroataqueField.MACROATAQUE_C}
                  value={props.data?.MACROATAQUE_C}
                  language={userInfo.language}
                />
              </Table>
            </Col>
          </Row>
          <Row mb>
            <Col xs={6}>
              <Button kind='secondary' expanded onClick={handleReset}>
                {strings.btnReset}
              </Button>
            </Col>
            <Col xs={6}>
              <Button kind='primary' expanded type='submit'>
                {strings.btnSave}
              </Button>
            </Col>
          </Row>
        </Grid>
      </Form>
    </Modal>
  );
};

ModalMacroataque.defaultProps = {
  openTableType: '',
};
