import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { BodySecondary, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { PopUp } from '@atomic/obj.popup';
import { FeedbackComponentProps, FeedbackPhase, FeedbackType } from '../feedback.interfaces';
import { FeedbackStrings as strings } from '../feedback.string';
import { FeedbackComment } from './partials/feedback-comment.component';
import { FeedbackSuccess } from './partials/feedback-success.component';

export const FeedbackModal: React.FC<FeedbackComponentProps> = ({
  onClose,
  feedbackPhase,
  message,
  comment,
  setComment,
  type,
  handleSubmit,
  answers,
  selected,
  setSelected,
}) => {
  const { loadingPost } = useSelector((state: any) => state.csat);
  const { userInfo } = useSelector((state: RootState) => state.auth);

  if (feedbackPhase === FeedbackPhase.Success) {
    return (
      <PopUp opened onClose={onClose} expanded={false}>
        <FeedbackSuccess />
      </PopUp>
    );
  }

  return (
    <PopUp opened onClose={onClose} expanded={feedbackPhase === FeedbackPhase.Comment}>
      <Hbox.Item mb>
        <Grid fluid>
          <Row>
            <Col xs={10}>
              <H2>{message ? message : strings[userInfo?.language]?.title}</H2>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row center='xs'>
              {answers[type].map((answer, index) => (
                <Col key={index} xs={2}>
                  <Button
                    kind='link'
                    onClick={() => {
                      setSelected(index + 1);
                    }}
                  >
                    {answer(!selected || selected === index + 1)}
                  </Button>
                </Col>
              ))}
            </Row>
            {type === FeedbackType.Numeric && (
              <Row center='xs'>
                <Col xs={8}>
                  <BodySecondary>{strings[userInfo?.language]?.least}</BodySecondary>
                </Col>
                <Col>
                  <BodySecondary>{strings[userInfo?.language]?.most}</BodySecondary>
                </Col>
              </Row>
            )}
            {selected <= 5 && (
              <FeedbackComment
                loading={loadingPost}
                selectedOption={selected}
                comment={comment}
                setComment={setComment}
              />
            )}
          </Form>
        </Grid>
      </Hbox.Item>
    </PopUp>
  );
};
