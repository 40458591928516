import { createAsyncThunk } from '@reduxjs/toolkit';
import { NPSDataSource } from '@app/data/http/nps.datasource';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../nps-modal.constants';

export const getNPS = createAsyncThunk(`${SLICE_NAME}/getNPS`, async (params: boolean, _) => {
  return await NPSDataSource.getNPS(params)
    .then(data => data)
    .catch(err => {
      handleUnauthorized(err);
    });
});

export const addGetNPSReducers = builder => {
  builder.addCase(getNPS.pending, state => {
    state.loadingNPS = true;
  });
  builder.addCase(getNPS.fulfilled, (state, action) => {
    state.hasNPS = action.payload?.data[0]?.isAble;
    state.loadingNPS = false;
  });
  builder.addCase(getNPS.rejected, state => {
    state.loadingNPS = false;
  });
};
