import { optionsMI } from '../customer-search.constants';
import { ICustomerPersistState } from '../customer-search.interfaces';

export const initialState: ICustomerPersistState = {
  filterOption: optionsMI[0],
  query: '',
  salesOrgs: [],
  corporateGroup: [],
  salesGroup: [],
  clientBps: [],
  clientBpsSelected: [],
  currentMarket: null,
  isMaritime: false,
};
