import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleDebounceInputUpdate } from '@app/modules/components/side-bar-filters/sidebar-filters.utils';
import { IOrderUpdateStrings, Strings } from '@app/modules/order-update/order-update.string';
import { setNewMessage } from '@app/modules/order-update/store/order-update.store';
import { ChangeOrderStyled } from '../order-update-table.style';

export interface IOrderUpdateMessageCellProps {
  index: number;
  disabled: boolean;
}

const strings: IOrderUpdateStrings = Strings.pt;

export const OrderUpdateMessageCell: React.FC<IOrderUpdateMessageCellProps> = ({ index, disabled }) => {
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    handleDebounceInputUpdate(message, () => dispatch(setNewMessage({ index, value: message })));
  }, [message]);

  return (
    <ChangeOrderStyled
      placeholder={strings.table.newMsg}
      disabled={disabled}
      selected={false}
      value={message}
      onValueChange={value => setMessage(value)}
    />
  );
};
