import React, { useRef } from 'react';
import { CellLink } from '@atomic/atm.cell-link/cell-link.component';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { IMenuItem } from './drop-down-menu-container';
import { CustomizedDropDownMenuBoxStyled, CustomizedDropDownMenuWrapper } from './drop-down-menu.style';

interface DropDownMenuProps {
  menuItens: IMenuItem[];
  x: number;
  y: number;
  onClose: () => void;
}

export const DropDownMenu: React.FC<DropDownMenuProps> = props => {
  const ref = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    const isOutsideClick = ref && !ref.current.contains(event.target);
    if (isOutsideClick) {
      props.onClose();
    }
  };

  useEventListener('click', handleClickOutside);

  return (
    <CustomizedDropDownMenuWrapper ref={ref} x={props.x} y={props.y}>
      <CustomizedDropDownMenuBoxStyled>
        <FlexColumn>
          {props.menuItens.map((menuItem: IMenuItem, index: number) => (
            <FlexRow mt={Spacing.Small} mb={Spacing.Small} key={index}>
              <CellLink onClick={() => menuItem.onclick()}>
                <FlexRow vAlign='baseline'>
                  <FaIcon.Custom icon={menuItem.icon} size='sm' />
                  <FlexRow ml={Spacing.XSmall}>
                    <Body>{menuItem.text}</Body>
                  </FlexRow>
                </FlexRow>
              </CellLink>
            </FlexRow>
          ))}
        </FlexColumn>
      </CustomizedDropDownMenuBoxStyled>
    </CustomizedDropDownMenuWrapper>
  );
};
