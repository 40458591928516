import ColorFunc from 'color';
import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Frame } from '@atomic/atm.frame';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';

export interface PreviewMessageProps {
  kind: 'success' | 'warning' | 'alert' | 'message';
  icon?: string;
  message: string;
}

const messageColors = (kind: string): { bg: string; icon: string; text: string } => {
  return {
    warning: {
      bg: ColorFunc(Color.Warning)
        .lighten(0.9)
        .blacken(0.1)
        .hsl()
        .string(),
      icon: Color.Warning,
      text: ColorFunc(Color.Warning)
        .darken(0.5)
        .hsl()
        .string(),
    },
    success: {
      bg: ColorFunc(Color.Success)
        .lighten(0.5)
        .blacken(0.1)
        .hsl()
        .string(),
      icon: Color.Success,
      text: ColorFunc(Color.Success)
        .darken(0.5)
        .hsl()
        .string(),
    },
    alert: {
      bg: ColorFunc(Color.Alert)
        .lighten(0.8)
        .blacken(0.1)
        .hsl()
        .string(),
      icon: Color.Alert,
      text: ColorFunc(Color.Alert)
        .darken(0.6)
        .hsl()
        .string(),
    },
    message: {
      bg: ColorFunc(Color.Purple)
        .lighten(0.9)
        .string(),
      icon: Color.Purple,
      text: ColorFunc(Color.Purple)
        .darken(0.5)
        .hsl()
        .string(),
    },
  }[kind];
};

export const PreviewMessage: React.FC<PreviewMessageProps> = props => {
  const type = messageColors(props.kind);
  return (
    <Frame color={type.bg} zIndex={-1}>
      <VSeparator />
      <Hbox>
        {props.icon && (
          <>
            <Hbox.Separator />
            <Hbox.Item noGrow>
              <FaIcon.Custom icon={props.icon} size='1x' color={type.icon} />
            </Hbox.Item>
          </>
        )}
        <Hbox.Separator />
        <Hbox.Item>
          <Body color={type.text}>{props.message}</Body>
        </Hbox.Item>
      </Hbox>
      <VSeparator />
    </Frame>
  );
};
