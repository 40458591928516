import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { SearchButton } from '@app/modules/components/search-button/search-button.component';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';

interface ISearchMaterialButtonProps {
  tempID: string;
  handleSearch: () => void;
}

export const SearchMaterialButton: React.FC<ISearchMaterialButtonProps> = ({ tempID, handleSearch }) => {
  const { selectedIssuer, materialFieldID, loadingMaterials } = useSelector((state: RootState) => state.orderInput);

  if (materialFieldID === tempID && loadingMaterials) {
    return (
      <Button loading={true} kind='link'>
        <FaIcon.Search size='1x' />
      </Button>
    );
  }
  return <SearchButton text='Buscar' disabled={!selectedIssuer?.value} handleSearch={handleSearch} />;
};
