import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TableRowData } from '@app/data/http/quote-params.dto';
import { AttributesTableItem } from '@app/models/chat.model';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { QuoteStrings } from '../quote/quote.string';
import { ChatResultAttributeWrapper, ChatResultTable } from './chat-result.component.style';
import { rangeLimits } from './chat.contants';
import { ChatStrings } from './chat.string';
import { ModalComposition, ElementSort } from './modal-composition.component';

interface CompositionTableProps {
  data: AttributesTableItem[];
  onData: (data: TableRowData[], key: string) => void;
  disableAction?: boolean;
  editable?: boolean;
  delible?: boolean;
  onDelete?: () => void;
  modified?: string;
  disableButton?: boolean;
}

export const CompositionTable: React.FC<CompositionTableProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [edit, setEdit] = React.useState(false);

  const strings = ChatStrings[userInfo.language];

  if (!props.data) {
    return null;
  }

  const handleModalData = (data: TableRowData[], key: string) => {
    props.onData(data, key);
    setEdit(false);
  };

  const formatStringMaxValue = (val: string) => {
    if (val) {
      const newMask = val.toString().replace(rangeLimits.maxString, '-');
      return newMask;
    }
    return val;
  };
  const formatStringMinValue = (val: any) => {
    if (val === 0) {
      const newMask = val.toString().replace(rangeLimits.minString, '-');
      return newMask;
    }
    return val;
  };

  return (
    <ChatResultAttributeWrapper modified={props.modified}>
      <ChatResultTable>
        <Hbox>
          <Hbox.Item vAlign='center'>
            <InputLabel>
              {strings.modalComposition.titleResume}
              {props.modified !== '' &&
                (props.modified === 'GERDAU' ? (
                  <span title={QuoteStrings[userInfo.language].defaultMessageGerdau.tootipChatResult}>
                    {QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultGerdauAdvice}
                  </span>
                ) : (
                  <span>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}</span>
                ))}
            </InputLabel>
          </Hbox.Item>
          {props.editable && (
            <Hbox.Item noGrow>
              <Button kind='link' onClick={() => setEdit(true)} disabled={props.disableAction || props.disableButton}>
                <FaIcon.Pen />
              </Button>
            </Hbox.Item>
          )}
          {props.delible && (
            <>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <Button kind='link' onClick={props.onDelete} disabled={props.disableAction || props.disableButton}>
                  <FaIcon.Trash />
                </Button>
              </Hbox.Item>
            </>
          )}
        </Hbox>
        <Table>
          <TR>
            <TD />
            <TD>
              <H4>Min</H4>
            </TD>
            <TD>
              <H4>Max</H4>
            </TD>
            <TD>
              <H4>Unit</H4>
            </TD>
          </TR>
          {props.data &&
            ElementSort(props.data, 'elements').map(element => (
              <TR key={element.elements}>
                <TD>
                  <H4>{element.elements}</H4>
                </TD>
                <TD>{formatStringMinValue(element.min)}</TD>
                <TD>{formatStringMaxValue(element.max)}</TD>
                <TD>{element.unit}</TD>
              </TR>
            ))}
        </Table>
        <VSeparator />
        <ModalComposition
          openTableType={edit ? 'elementos' : ''}
          onSubmit={handleModalData}
          data={{ ELEMENTOS: props.data }}
          onClose={() => setEdit(false)}
        />
      </ChatResultTable>
    </ChatResultAttributeWrapper>
  );
};
