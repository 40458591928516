import { format } from 'date-fns';
import React, { useState } from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';
import { CustomCalendar } from './custom-calendar/custom-calendar.component';
import { DateFieldWrapper, DateSelectedWrapper, DateSelected } from './custom-date-field.style';
interface CustomDateFieldProps {
  onValueChange: (value) => void;
  selectedDate?: Date;
  hasError?: boolean;
  openBottom?: boolean;
  hasWarning?: boolean;
}
export const CustomDateField: React.FC<CustomDateFieldProps> = ({
  selectedDate,
  onValueChange,
  hasError,
  hasWarning,
  openBottom,
}) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  return (
    <DateFieldWrapper>
      <DateSelectedWrapper hasError={hasError} hasWarning={hasWarning} onClick={() => setShowCalendar(!showCalendar)}>
        <DateSelected>{selectedDate ? format(selectedDate, 'dd/MM/yyyy') : ''}</DateSelected>
        <FaIcon.Calendar color={Color.Primary} />
      </DateSelectedWrapper>
      {showCalendar && (
        <CustomCalendar
          openBottom={openBottom}
          selectedDate={selectedDate}
          setSelectedDate={value => onValueChange(value)}
          setShowCalendar={setShowCalendar}
        />
      )}
    </DateFieldWrapper>
  );
};
