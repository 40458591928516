/* eslint-disable prettier/prettier */
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '@app/core/redux/store';
import { CustomerDataSource } from '@app/data/http';
import { Customer } from '@app/models';
import { CustomerSelection } from '@app/providers';
import { MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID, SLICE_NAME } from '../../order-input.constants';
import type { IOrderInputState } from '../../order-input.interfaces';
import { getBpsReplacingMusashi } from '../../order-input.utils';


interface IGetIssuersActionPayload {
  clientBpsSelected: CustomerSelection[];
  customers: Customer[];
}

export const getIssuersThunk = createAsyncThunk(
  `${SLICE_NAME}/getIssuers`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const clientBpsSelected = state.customerPersist.clientBpsSelected;
    let isMusashiCase = false;

    if (clientBpsSelected) {
      clientBpsSelected.forEach(bp => {
        if (bp.customerId === MUSASHI_CUSTOMER_ID) {
          isMusashiCase = true;
        }
      });
    }

    if (isMusashiCase) {
      return await CustomerDataSource.getCustomer({ customerIds: MUSASHI_REPLACE_CUSTOMER_ID })
        .then(data => {
          return ({ customers: data, clientBpsSelected });
        })
        .catch(err => {
          return thunkAPI.rejectWithValue({ status: err.response.status, message: err.response.message });
        });
    }
    else {
      return ({ clientBpsSelected });
    }
  },
);

export const addGetIssuersReducers = builder => {
  builder.addCase(getIssuersThunk.pending, (state: IOrderInputState) => {
    state.loadingIssuers = true;
  });
  builder.addCase(getIssuersThunk.fulfilled, (state: IOrderInputState, action: PayloadAction<IGetIssuersActionPayload>) => {
    const { customers, clientBpsSelected } = action.payload;

    if (customers?.length && clientBpsSelected?.length) {
      const bps: CustomerSelection[] = getBpsReplacingMusashi(customers, clientBpsSelected);
      state.issuerOptions = bps;
    } else {
      state.issuerOptions = clientBpsSelected;
    }

    state.loadingIssuers = false;
  });
  builder.addCase(getIssuersThunk.rejected, (state: IOrderInputState) => {
    state.loadingIssuers = false;
  });
};
