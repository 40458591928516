import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TIME_1_DAYS } from '@app/modules/order-update/order-update.constants';
import { IOrderUpdateStrings, Strings } from '@app/modules/order-update/order-update.string';
import { setNewDates } from '@app/modules/order-update/store/order-update.store';
import { getWeekendDays } from '@app/utils/date-parser';
import { brDateFormatToDate } from '@app/utils/date-utils';
import { DatePicker } from '@atomic/atm.date-picker';
import { Color } from '@atomic/obj.constants';

export interface IOrderUpdateDateCellProps {
  index: number;
  disabled: boolean;
}

const strings: IOrderUpdateStrings = Strings.pt;

export const OrderUpdateDateCell: React.FC<IOrderUpdateDateCellProps> = ({ index, disabled }) => {
  const { tableRows } = useSelector((state: RootState) => state.orderUpdate);
  const isSelected = tableRows[index].cancelOrder;
  const dispatch = useDispatch();
  const dateSol: Date = brDateFormatToDate(tableRows[index].dateSolClient);
  const dateSolMoreOneYear: Date = new Date(dateSol.getTime());

  dateSolMoreOneYear.setFullYear(dateSol.getFullYear() + 2);
  const weekendDays: Date[] = getWeekendDays(new Date(), dateSolMoreOneYear);
  const tomorrow = new Date(new Date().getTime() + TIME_1_DAYS);

  return (
    <DatePicker
      filled
      placeholder={strings.table.newDate}
      onValueChange={(date: Date) => dispatch(setNewDates({ index, date: date.getTime() }))}
      disabled={isSelected || disabled}
      color={isSelected ? Color.AlertLight : Color.White}
      startDate={tableRows[index].newDateSolClient ? new Date(tableRows[index].newDateSolClient) : null}
      minDate={tomorrow}
      maxDate={dateSolMoreOneYear}
      excludeDates={weekendDays}
      defineDate
      centralized={true}
    />
  );
};
