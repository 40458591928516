import * as React from 'react';
import { Route } from '@app/core/route';
import { AppPath } from '../app/route-constants';
import { useFinanceGuard } from './finance.guard';
import { FinancePage } from '.';

export const FinanceRouter: React.FC = () => {
  const financeAuthorized = useFinanceGuard();

  return (
    <Route exact path='./' component={FinancePage} authorized={financeAuthorized} redirectPath={AppPath.QUOTE.BASE} />
  );
};
