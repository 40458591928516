import { HelpCenterContentModel } from '@app/models/help-center.model';
import { HelpCenterAdminSection } from './help-center-admin-section';
import { HelpCenterBilledOrderSection } from './help-center-billed-order-section.utils';
import { HelpCenterCatalogsSection } from './help-center-catalogs-section.utils';
import { HelpCenterComplainSection } from './help-center-complain-section.utils';
import { HelpCenterDashboardSection } from './help-center-dashboard-section.utils';
import { HelpCenterDocumentsSection } from './help-center-documents-section.utils';
import { HelpCenterFinancesSection } from './help-center-finance-section.utils';
import { HelpCenterImplantationSection } from './help-center-implantation-section.utils';
import { HelpCenterOpenOrderSection } from './help-center-open-order-section.utils';
import { HelpCenterQuotationSection } from './help-center-quotation-section.utils';
import { HelpCenterStockOrderSection } from './help-center-stock-order-section.utils';

export const HelpCenterContentDefinition = (language = 'pt'): HelpCenterContentModel => {
  return {
    sections: [
      HelpCenterAdminSection(language),
      HelpCenterDashboardSection(language),
      HelpCenterQuotationSection(language),
      HelpCenterOpenOrderSection(language),
      HelpCenterStockOrderSection(language),
      HelpCenterBilledOrderSection(language),
      HelpCenterFinancesSection(language),
      HelpCenterImplantationSection(language),
      HelpCenterComplainSection(language),
      HelpCenterCatalogsSection(language),
      HelpCenterDocumentsSection(language),
    ],
  };
};
