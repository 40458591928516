import Container from 'typedi';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { BAGG_KEY_CLIENT_ID, BAGG_KEY_CLIENT_SECRET, CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';

const user = Container.get(AuthStorageService).getUser();

export const endpointCrm = {
  CRM: '/crm',
  CRM_SEARCH: '?search=',
  OPPORTUNITY_DETAILS: '/opportunityDetails',
  MANUFACTURING_CONSULTATION: '/manufacturingConsultation',
  ATTACHED_FILES: '/attachedFiles',
  PLANTS: '/plants',
  NOTES: '/notes',
  CACHE: '/cache',
  TOTAL_PRICES: '/totalPrices',
};

export const endpointRcs = {
  RCS: '/rcs',
  OPPORTUNITY_DETAILS: '/opportunityDetails',
  ITEMS: '/items',
  NOTES: '/notes',
  ATTACHED_FILES: '/attachedFiles',
  TOTAL_PRICES: '/totalPrices',
  PEOPLE_INVOLVED: '/peopleInvolved',
  ORGANIZATIONAL_DATA: '/organizationalData',
  EXPEDITION: '/expedition',
  DATES: '/dates',
  WORKFLOW: '/workflow',
  HISTORY: '/history',
  CACHE: '/cache',
};

export const defaultCrmHeaders = {
  client_id: CXP_KEY_CLIENT_ID,
  client_secret: CXP_KEY_CLIENT_SECRET,
  Authorization: 'Bearer ' + user?.accessToken,
  access_token: user?.accessToken,
  'Content-Type': 'application/json',
  Accept: '*/*',
  language: user?.language,
};

export const defaultCrmComplainHeaders = {
  client_id: BAGG_KEY_CLIENT_ID,
  client_secret: BAGG_KEY_CLIENT_SECRET,
  Authorization: 'Bearer ' + user?.accessToken,
  access_token: user?.accessToken,
  'Content-Type': 'application/json',
  Accept: '*/*',
  language: user?.language,
};
