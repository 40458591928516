export enum BrandColor {
  DarkBlueBG = '#244577',
  DarkBlue = '#003A76',
  Blue = '#0076C0',
  LightBlue = '#ECF2F6',
  Yellow = '#FFCE00',
  Green = '#00AF46',
  Gray = '#EFF2F3',
}

// We used class rather than enums since enums don't support computed values
export class Color {
  public static readonly Black = '#192D38';
  public static readonly White = 'white';
  public static readonly Transparent = 'transparent';

  public static readonly GrayXLight = '#FAFAFA';
  public static readonly GrayLight = '#EFF2F3';
  public static readonly MediumGray = '#e6e6e6';
  public static readonly MediumXGray = '#C4C4C4';
  public static readonly GrayLightSecondary = '#D2D3D4';
  public static readonly Gray = '#BFC9D0';
  public static readonly GrayDark = '#63727B';
  public static readonly GrayXDark = '#455961';
  public static readonly HefestoGray = '#414e55';
  public static readonly GrayDropZone = '#bdbdbd';

  public static readonly Primary = BrandColor.Blue;
  public static readonly Secondary = BrandColor.LightBlue;
  public static readonly Accessory = BrandColor.DarkBlue;
  public static readonly Neutral = BrandColor.Gray;
  public static readonly CallToAction = BrandColor.Yellow;

  public static readonly Alert = '#BC2B1F';
  public static readonly MediumAlert = '#C74A34';
  public static readonly AlertLight = '#FFECEC';
  public static readonly AlertDark = '#5F1311';
  public static readonly Warning = '#FBA800';
  public static readonly WarningDark = '#6C2C10';
  public static readonly Success = '#75C575';
  public static readonly SuccessMain = '#47A947';
  public static readonly SuccessDark = '#044934';
  public static readonly SuccessLight = '#deede3';
  public static readonly BlueLight = '#DEE8EF';
  public static readonly BlueLightSecondary = '#DCE9FF';

  public static readonly Yellow = '#FFF6B7';
  public static readonly LightYellow = '#FBF8DD';
  public static readonly Pink = '#FF00C7';
  public static readonly PinkDark = '#6D0E6A';
  public static readonly Purple = '#6418E0';
  public static readonly PurpleDark = '#874487';
  public static readonly Orange = '#FF7A00';
  public static readonly Brown = '#997D5C';
  public static readonly Delay = '#e84529a8';
  public static readonly DelayTrend = '#fdc3bf96';

  public static readonly DelayText = '#330202';
  public static readonly DelayTrendText = '#5e1710';
}

export enum DesignSystemColor {
  DarkBlue = '#244577',
  White = '#FFFFFF',
  Primary = '#0076BF',
  GreyText = '#495961',
}

export enum FontFamily {
  Primary = 'Open Sans, sans-serif;',
}

export enum FontWeight {
  Medium = 600,
  Bold = 'bold',
  Normal = 'normal',
  Lighter = 300,
}

export enum FontSize {
  XXSmall = '8px',
  XSmall = '12px',
  Small = '14px',
  Medium = '16px',
  Large = '18px',
  XLarge = '24px',
  XXLarge = '32px',
}

export enum Spacing {
  XXSmall = '2px',
  XSmall = '4px',
  Small = '8px',
  Medium = '16px',
  Large = '24px',
  XLarge = '32px',
  XXLarge = '64px',
  XXXLarge = '96px',
}

export const HeaderHeight = {
  Mobile: '60px',
  Desk: '110px',
  DeskShort: '60px',
  NewAdminDesk: '81px',
};

export enum IconSize {
  Small = '12px',
  Medium = '16px',
  Large = '20px',
}

export enum ShortHeaderLimit {
  pixel = 135,
  limitMobile = 1024,
}

export enum ZIndex {
  DashboardHeaderMessage = 1,
  FileFieldInputStyled = -1,
  InactiveDrawer = -1,
  CheckboxUncheckedStyled = 0,
  ProgressCellStyled = 0,
  CheckboxCheckedStyled,
  FileFieldIconStyled,
  FileFieldClearStyled,
  ProgressCellBadgeStyled,
  TextFieldButtonAreaStyled,
  TextFieldIconStyled,
  TooltipTextStyled,
  BoxStyled,
  CheckboxFieldTextStyled,
  DateFilterStyled,
  CustomerSearchStyled,
  GridColumnMenuStyled,
  AppConfigurationOptionsStyled,
  DashboardHeaderWrapperStyled,
  CustomerSelectMenuStyled,
  Fade,
  ModalCloseStyled,
  ChatCreateButtonWrapper,
  ChatSelectWrapper,
  DashboardWarning,
  HeaderDeskStyled,
  QuoteModalClientFilterStyled,
  AdminClientListStyled,
  TechnicalAnalysisUserListStyled,
  LoadingStyled,
  ActiveDrawer,
  HeaderWrapperStyled,
  ModalOpacityStyled,
  ModalBoxStyled,
  CustomizedTooltipWrapper,
  FlashMessageWrapperStyled,
}

export const DrawerWidth = '256px';
export const DrawerHeight = '256px';
export const DrawerLargeWidth = '512px';

export const DropzoneWidth = '100px';

export const Navbar = {
  a: '1',
};

export const FieldHeight = '40px';
export const FieldHeightSmall = '22px';
export const TransitionDuration = '.3s';

export const Border = {
  Color: Color.GrayLight,
  Width: '1px',
  Radius: '4px',
  RadiusLarge: '24px',
};

// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export enum PasswordLength {
  Max = 18,
  Min = 7,
}

export enum AspectRatio {
  Square = 1,
}

export const Breakpoint = {
  xs: 0, // em
  sm: 32, // em
  md: 48, // em
  lg: 64, // em
};

export const Shadow = {
  Small: '0px 0px 4px 0px rgba(0,0,0,0.2)',
  Medium: '0px 0px 8px 4px rgba(0,0,0,0.2)',
};

export enum Keys {
  Enter = 13,
  Esc = 27,
}

export const SHOW_FEEDBACK_DELAY = 30000;
export const LAST_UPDATE_REQUESTING = 'requesting';

export const BoxHeight = {
  Small: '28px',
  Medium: '48px',
  Large: '54px',
};

export const ScreenPanel = {
  Medium: '1228px',
};
