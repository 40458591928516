import React from 'react';
import { LogAnalytics } from '@app/core/analytics';
import { useMECQDocumentQuery } from '@app/domain/document.use-case';
import { downloadFile } from '@app/utils/download-file';
import { CellLink } from '@atomic/atm.cell-link/cell-link.component';
import { FaIcon } from '@atomic/atm.fa-icon';

interface CertificateMECellComponentProps {
  data: { deliveryDocument: string; deliveryDocumentItem: string };
}

export const CertificateMECellComponent: React.FC<CertificateMECellComponentProps> = props => {
  const { data, loading, fetch } = useMECQDocumentQuery();
  const [filename, setFilename] = React.useState('Certificate');

  React.useEffect(() => {
    if (data) {
      LogAnalytics.click({ tipo: 'DownloadCertificadoME' });
      downloadFile(data, filename);
    }
  }, [data]);

  const handleDocumentView = (deliveryDocument: string, deliveryDocumentItem: string) => () => {
    setFilename(`Certificate - ${deliveryDocument}_${deliveryDocumentItem}`);
    fetch({
      deliveryDocument,
      deliveryDocumentItem,
    });
  };

  if (!props.data) {
    return null;
  }

  return (
    <CellLink
      onClick={handleDocumentView(props.data.deliveryDocument, props.data.deliveryDocumentItem)}
      loading={loading}
    >
      <FaIcon.FileDownload />
    </CellLink>
  );
};
