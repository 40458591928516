import { PayloadAction } from '@reduxjs/toolkit';
import { IGridLayoutState, ISetColumnVisiblePayload, ITab } from './grid-layout.interfaces';

export const reducers = {
  setColumnVisible: (state: IGridLayoutState, { payload }: PayloadAction<ISetColumnVisiblePayload>) => {
    const tabIndex: number = state.tabs.findIndex(tab => tab.name === payload.tabName);
    const colIndex: number = state.tabs[tabIndex].cols.findIndex(col => col.field === payload.fieldName);

    state.tabs[tabIndex].cols[colIndex].hide = !payload.show;
    state.toogleVisible = !state.toogleVisible;
  },
  setTabColumns: (state: IGridLayoutState, { payload }: PayloadAction<ITab>) => {
    const tabIndex: number = state.tabs.findIndex(tab => tab.name === payload.name);
    state.tabs[tabIndex].cols = payload.cols;
    state.toogleVisible = !state.toogleVisible;
  },
};
