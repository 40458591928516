import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { SurrogateDataSource } from '@app/data/http/surrogate.datasource';
import { SurrogateEvents } from '../../surrogate.analytics';
import { INVALID_EMAIL, SLICE_NAME } from '../../surrogate.constants';
import { ISurrogateState } from '../../surrogate.interfaces';

export const checkSurrogate = createAsyncThunk(`${SLICE_NAME}/checkSurrogate`, async (email: string, thunkAPI) => {
  return await SurrogateDataSource.checkSurrogate(email)
    .then(resp => resp)
    .catch(err => {
      LogAnalytics.error({
        tipo: SurrogateEvents.CheckSurrogateError,
        errorMessage: err.response.message,
        errorDetails: JSON.stringify(err.response.data),
      });
      return thunkAPI.rejectWithValue({ status: err.response.status, message: INVALID_EMAIL });
    });
});

export const addCheckSurrogateReducer = builder => {
  builder.addCase(checkSurrogate.pending, state => {
    state.loading = true;
  });
  builder.addCase(checkSurrogate.fulfilled, (state: ISurrogateState, action) => {
    state.isValidSurrogate = action.payload;
    state.loading = false;
    state.error = false;
    state.errorMessage = '';
  });
  builder.addCase(checkSurrogate.rejected, (state, action) => {
    state.isValidSurrogate = false;
    state.loading = false;
    state.error = action.payload.status;
    state.errorMessage = action.payload.message;
  });
};
