import { Checkbox } from '@gerdau/hefesto';
import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { RoleDisplayName, RoleInternalName, TabInternalName } from '@app/models/admin.model';
import { setRtcRoleConfig } from '@app/modules/admin/store/admin.store';
import { updateRole } from '@app/modules/admin/store/thunk/profile-update.thunk';
import { addOrRemoveItem } from '@app/utils/array-helper';
import {
  AdminPermissionsRoleCol,
  PermissionRoleColCell,
  PermissionRoleTitleColCell,
} from '../../admin-profile-view.styles';
import { getTabIdList } from '../../utils/utils';

export const AdminProfileRtcRoleCol: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { tabChips, rtcRoleConfig } = useSelector((state: RootState) => state.admin);

  const handleChange = (tagName: string) => {
    const tab = { _id: tabChips.filter(t => t.tag === tagName)[0]._id, tag: tagName };
    const newTabArray = addOrRemoveItem(tagName, 'tag', rtcRoleConfig.tabs, tab);
    const updatedRtcRoleConfig = { _id: rtcRoleConfig._id, profile: rtcRoleConfig.profile, tabs: newTabArray };
    dispatch(setRtcRoleConfig(updatedRtcRoleConfig));
    dispatch(updateRole({ uid: rtcRoleConfig._id, profile: rtcRoleConfig.profile, tabs: getTabIdList(newTabArray) }));
  };

  return (
    <AdminPermissionsRoleCol>
      <PermissionRoleTitleColCell>
        <Tag variant='neutral'>{rtcRoleConfig?.profile === RoleInternalName.Rtc && RoleDisplayName.Rtc}</Tag>
      </PermissionRoleTitleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcDashboardCb'
          checked={
            rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.Dashboard)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Dashboard);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcQuotationtCb'
          checked={
            rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.Quotation)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Quotation);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcOrderInputCb'
          checked={
            rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.OrderInput)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.OrderInput);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcOrderCb'
          checked={rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.Order)[0]}
          onChange={() => {
            handleChange(TabInternalName.Order);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcFinanceCb'
          checked={
            rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.Finance)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Finance);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcManifestCb'
          checked={
            rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.Manifest)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Manifest);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcIsaManagerCb'
          checked={
            rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.IsaManager)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.IsaManager);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcCrmCb'
          checked={rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.Crm)[0]}
          onChange={() => {
            handleChange(TabInternalName.Crm);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcSurrogateCb'
          checked={
            rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.Surrogate)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Surrogate);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcLackOfLoadCb'
          checked={
            rtcRoleConfig?.tabs?.length && !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.LackOfLoad)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.LackOfLoad);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='rtcSchedulingCompletedCb'
          checked={
            rtcRoleConfig?.tabs?.length &&
            !!rtcRoleConfig.tabs.filter(t => t.tag === TabInternalName.SchedulingCompleted)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.SchedulingCompleted);
          }}
        />
      </PermissionRoleColCell>
    </AdminPermissionsRoleCol>
  );
};
