/* eslint-disable complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { FullAnalysisData } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { TechnicalAnalysisSKUModal } from '../status/technicalAnalysisSKUModal';
import { TechnicalAnalysisStrings } from '../technical-analysis.string';

interface SkuButtonProps {
  analysis: FullAnalysisData;
  analysisId: number;
}

export const SkuButton: React.FC<SkuButtonProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const strings = TechnicalAnalysisStrings[userInfo.language];
  const [skuModalOpened, setSkuModalOpened] = React.useState<boolean>(false);

  const handleSkuModalClose = () => {
    setSkuModalOpened(false);
  };

  return quoteIsInternalUser &&
    (props.analysis?.internalStatus.key === 'FINALIZED' ||
      props.analysis?.internalStatus.key === 'FINALIZED_WITH_DEROGATION') ? (
    <Hbox>
      <Button kind='secondary' title={strings.skuModalstatus.btnAdd} onClick={() => setSkuModalOpened(true)}>
        <Hbox hAlign='left' fullWidth>
          <Hbox.Item noGrow vAlign='center'>
            <FaIcon.BarCode />
          </Hbox.Item>
          <Hbox.Separator />
          <Body bold color={Color.GrayXDark} noWrap>
            {strings.skuModalstatus.btnAdd}
          </Body>
        </Hbox>
      </Button>
      <TechnicalAnalysisSKUModal
        analysisId={props.analysisId}
        language={userInfo.language}
        onModalClose={handleSkuModalClose}
        opened={skuModalOpened}
      />
    </Hbox>
  ) : null;
};
