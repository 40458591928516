import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';

export const AdminUserFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Large} ${Spacing.XLarge};
`;

export const AdminUserFormNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;

export const AdminUserFormEmailWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
`;

export const AdminUserFormEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
`;

export const AdminUserFormLanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.Large};
  padding-left: ${Spacing.XLarge};
`;

export const AdminUserFormSpan = styled.div`
  padding-left: ${Spacing.Medium};
  font-family: 'Work Sans', Arial, Helvetica, sans-serif;
  font-size: ${FontSize.Small};
  color: ${Color.HefestoGray};
`;

export const PermissionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${Spacing.Small};
`;

export const PermissionContainer = styled.div`
  margin-right: ${Spacing.Small};
`;

export const ControlLineWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${Spacing.Large};
`;

export const PaddedBtnWrapper = styled.div`
  padding-left: ${Spacing.Medium};
`;
