import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid } from 'react-styled-flexboxgrid';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { CSATUserModule } from '@app/data/http/admin-settings.datasource';
import { CSATModule } from '@app/modules/admin-settings/admin-settings.modules';
import { GridButtonWithIcon } from '@app/modules/components/grid-button-with-icon.component';
import { postAnswerCsatUser } from '@app/providers/csat/csat-post.thunk';
import { Button } from '@atomic/atm.button';
import { H2 } from '@atomic/atm.typography';
import { VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { IFinanceStrings, Strings } from '../finance.string';
import {
  FraudAlertModalBody,
  FraudAlertModalFooter,
  FraudAlertModalHeader,
  FraudAlertModalWrapper,
} from './fraud-alert-modal.style';

const HELP_CENTER_PATH = '/help-center';

export const FraudAlertModal: React.FC = () => {
  const [openFraudAlertModal, setOpenFraudAlertModal] = React.useState(false);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IFinanceStrings = Strings[userInfo.language];
  const history = useHistory();
  const { csatUser, loadingPost } = useSelector((state: RootState) => state.csat);
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleClose = redirect => {
    dispatch(postAnswerCsatUser({ functionality: CSATModule.antiFraude }));
    setOpenFraudAlertModal(false);

    if (redirect) {
      history.push(HELP_CENTER_PATH);
    }
  };

  useEffect(() => {
    const nps = csatUser.find((csat: CSATUserModule) => csat.functionality === CSATModule.antiFraude);
    if (nps && !nps.answer) {
      setOpenFraudAlertModal(true);
    }
  }, [csatUser]);

  return (
    <Modal small opened={openFraudAlertModal} onClose={() => handleClose(false)}>
      <Grid fluid>
        <FraudAlertModalWrapper>
          <FraudAlertModalHeader>
            <H2>{strings?.fraudControl.title}</H2>
          </FraudAlertModalHeader>
          <VSeparator lineVisible />
          <FraudAlertModalBody>
            <img width='574px' src='assets/img/finance/fraud-alert-1-5.png' />
          </FraudAlertModalBody>
          <FraudAlertModalFooter>
            <GridButtonWithIcon
              text={strings?.fraudControl.actionButton}
              kind='callToAction'
              onClick={() => handleClose(true)}
            />
            <Button kind='primary' loading={loadingPost} onClick={() => handleClose(false)}>
              {strings?.fraudControl.confirmButton}
            </Button>
          </FraudAlertModalFooter>
        </FraudAlertModalWrapper>
      </Grid>
    </Modal>
  );
};
