import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Divisor } from '@atomic/atm.divisor';
import { Frame } from '@atomic/atm.frame';
import { Image } from '@atomic/atm.image';
import { H3 } from '@atomic/atm.typography';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Strings, ITruckTrackingStrings } from '../../../truck-tracking.string';
import { TruckTrackingSubtitleItem } from './truck-tracking-subtitle-item.component';

export const TruckTrackingSubtitle: React.FC = () => {
  const { truckTrackingInfo } = useSelector((state: RootState) => state.truckTracking);
  const strings: ITruckTrackingStrings = Strings.pt;
  const { subtitleData: subtitleDataStrings } = strings;

  return (
    <Frame>
      <VSeparator />
      <Grid fluid>
        <H3>{subtitleDataStrings.title}</H3>
        <TruckTrackingSubtitleItem
          caption={subtitleDataStrings.caption.gerdau}
          description={subtitleDataStrings.description.gerdau}
        >
          <Image.GerdauMarker />
        </TruckTrackingSubtitleItem>
        <Divisor />
        <TruckTrackingSubtitleItem
          caption={subtitleDataStrings.caption.truck}
          description={subtitleDataStrings.description.truck}
        >
          <Image.TruckMarker />
        </TruckTrackingSubtitleItem>
        <Divisor />
        <TruckTrackingSubtitleItem
          caption={subtitleDataStrings.caption.destination}
          description={truckTrackingInfo?.nameClient}
        >
          <Image.DestinationMarker />
        </TruckTrackingSubtitleItem>
        <Divisor />
      </Grid>
    </Frame>
  );
};
