import styled from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Spacing, Color } from '@atomic/obj.constants';
import { fieldBorderCss, fieldCss } from '@atomic/obj.form/field.component.styled';

export const SelectWrapperStyled = styled.div`
  ${fieldBorderCss}
  position: relative;
`;

export const SelectStyled = styled.select`
  appearance: none;
  border: 0;
  min-width: 72px;
  outline: none;
  
  ${fieldCss}
  padding-right: ${Spacing.XLarge};
  &:hover {
    cursor: pointer;
  }
`;

export const SelectIconStyled = styled(FaIcon.ChevronDown).attrs({ size: '1x' })`
  position: absolute;
  pointer-events: none;
  top: ${Spacing.Medium};
  right: ${Spacing.Small};
  color: ${Color.GrayDark};
`;
