import React from 'react';
import { useSelector } from 'react-redux';
import { hasFinanceTabAccessSelector, hasOrdersTabAccessSelector } from '@app/modules/auth/store/auth.selectores';
import { CustomerSearchContainer } from '@app/modules/components/customer-search/customer-search.container';
import { FlexColumn } from '@atomic/obj.box';
import { ContainerMenu, HeaderFirstLineWrapper, HeaderSecondLineWrapper, SearchBarWrapper } from './menu-header.style';
import { LogosAppHeader } from './partials/logos-header/logos-header.component';
import { TabsMenuAppHeader } from './partials/tabs-menu-header/tabs-menu-header.component';

interface MenuAppHeaderProps {
  shortHeader: boolean;
  handleLogout: () => void;
}

export const MenuAppHeader: React.FC<MenuAppHeaderProps> = ({ shortHeader, handleLogout }) => {
  const hasOrdersTabAccess = useSelector(hasOrdersTabAccessSelector);
  const hasFinanceTabAccess = useSelector(hasFinanceTabAccessSelector);

  return (
    <ContainerMenu shortHeader={shortHeader}>
      <FlexColumn>
        <HeaderFirstLineWrapper shortHeader={shortHeader}>
          {(hasOrdersTabAccess || hasFinanceTabAccess) && (
            <SearchBarWrapper shortHeader={shortHeader}>
              <CustomerSearchContainer />
            </SearchBarWrapper>
          )}
          {shortHeader && <TabsMenuAppHeader shortHeader={shortHeader} handleLogout={handleLogout} />}
          <LogosAppHeader shortHeader={shortHeader} />
        </HeaderFirstLineWrapper>
        <HeaderSecondLineWrapper>
          {!shortHeader && <TabsMenuAppHeader shortHeader={shortHeader} handleLogout={handleLogout} />}
        </HeaderSecondLineWrapper>
      </FlexColumn>
    </ContainerMenu>
  );
};
