import ColorFunc from 'color';
import styled, { css } from 'styled-components';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Border, Color, IconSize, Spacing, TransitionDuration } from '@atomic/obj.constants';

export interface DefaultListCellStyledProps {
  bg?: boolean;
  hover?: boolean;
  selected?: boolean;
  hasClick?: boolean;
  list?: string;
}

export const DefaultListCellStyled = styled.div`
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  white-space: nowrap;
  text-decoration: none;
  padding: ${Spacing.Medium};
  border: 1px solid;
  border-color: ${Border.Color};
  ${(props: DefaultListCellStyledProps) => props.hasClick && 'cursor: pointer;'}

  ${(props: DefaultListCellStyledProps) =>
    props.hover &&
    `
    &:hover {
      background-color: ${
        props.list
          ? ColorFunc(Color.Warning)
              .lighten(0.7)
              .blacken(0.1)
              .hsl()
              .string()
          : Color.Secondary
      };
    }
  `}

  

  background-color: ${(props: DefaultListCellStyledProps) =>
    props.selected
      ? props.list
        ? ColorFunc(Color.Warning)
            .lighten(0.7)
            .blacken(0.1)
            .hsl()
            .string()
        : Color.Secondary
      : props.bg
      ? Color.GrayXLight
      : 'transparent'};

& + & {
  border-top: 0;
}
`;

export const DefaultListCellIconWrapperStyled = styled.div`
  width: ${Spacing.Large};
  height: ${Spacing.Large};
  padding-top: ${Spacing.XSmall};
  border-radius: ${Border.Radius};
  background-color: transparent;
  text-align: center;
  margin-right: ${Spacing.Small};
`;

export const DefaultListIconStyled = css`
  position: absolute;
  right: 0;
  transition: ${TransitionDuration};
  text-align: center;
  font-size: ${IconSize.Medium};
  vertical-align: middle;
  margin-right: ${Spacing.Medium};
`;

export const DefaultListCellAngleStyled = styled(FaIcon.ChevronRight)`
  ${DefaultListIconStyled};
  color: ${Color.Gray};
`;

export const DefaultListCellCloseStyled = styled(FaIcon.Close)`
  ${DefaultListIconStyled};
`;

export const CommentIconWrapperStyled = styled.div`
  right: 0;
  position: absolute;
  margin-right: 20px;
`;

export const EditIconWrapperStyled = styled.div`
  right: 0;
  position: absolute;
  margin-right: 50px;
`;
