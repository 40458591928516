import { HelpCenterSubsectionKeys, HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterDashboardSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Painel de gestão',
      key: HelpCenterSectionKeys.Dashboard,
      subsections: [
        {
          title: 'Pedidos em aberto',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.OpenOrdersDashboard,
          description:
            'Analise a situação da sua carteira compreendendo os gráficos apresentados em seu ”Painel de gestão”',
          informationBlocks: [
            {
              media: {
                path: 'assets/img/help-center/dashboard/order-open/order-open-01.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle: 'Status dos pedidos em aberto',
              paragraph:
                'Este gráfico mostra o cenário atual dos seus pedidos em aberto. A coluna "Qtd. Faturada" mostra quantas toneladas já foram faturadas apenas dos pedidos em aberto.',
            },
            {
              blockTitle: 'Qtd solicitada x qtd confirmada',
              paragraph:
                'Este gráfico mostra quantas toneladas foram solicitadas pelo cliente e quantas toneladas foram confirmadas pela Gerdau, mensalmente.',
            },
            {
              blockTitle: 'Status dos pedidos',
              paragraph: 'Este gráfico mostra quantas toneladas estão em cada estágio do pedido.',
              paragraphList: [
                'Implantado: CS já implantou no sistema.',
                'Em aprazamento: PCP está realizando a confirmação das datas para M+2',
                'Confirmado: PCP já confirmou as datas.',
              ],
            },
          ],
        },
        {
          title: 'Pedidos confirmados',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.ConfirmedOrderDashboard,
          description:
            'Analise a situação da sua carteira compreendendo os gráficos apresentados em seu ”Painel de gestão” ',
          informationBlocks: [
            {
              media: {
                path: 'assets/img/help-center/dashboard/order-confirmed/order-confirmed-01.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle: 'Previsão de entrega mensal',
              paragraph:
                'Este gráfico mostra a previsão de quantas toneladas o cliente vai receber mensalmente, sendo que no mês atual aparece a quantidade já faturada.',
            },
            {
              blockTitle: 'Status da previsão de entrega',
              paragraph:
                'Este gráfico mostra quantas toneladas estão previstas para ser entregues no prazo e quantas provavelmente irão atrasar. Quando não foi possível calcular a previsão, aparecerá "Consultar CS" para poder obter mais detalhes.',
            },
          ],
        },
        {
          title: 'Pedidos em estoque',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.StockOrderDashboard,
          description:
            'Analise a situação da sua carteira compreendendo os gráficos apresentados em seu ”Painel de gestão” ',
          informationBlocks: [
            {
              media: {
                path: 'assets/img/help-center/dashboard/order-stock/order-stock-01.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle: 'Estágio dos pedidos em estoque',
              paragraph: 'Este gráfico mostra quantas toneladas estão em cada estágio após entrar no estoque.',
              paragraphList: [
                'Em estoque: não tem remessa nem transporte contratado.',
                'Em remessa: tem remessa criada.',
                'Em carregamento: já possui remessa e transporte contratado.',
              ],
            },
            {
              blockTitle: 'Status dos pedidos em estoque',
              paragraph:
                'Este gráfico mostra o status dos pedidos em estoque, separando entre estoque "Vencido" (data confirmada no passado) e estoque "Não vencido" (data confirmada no futuro).',
            },
          ],
        },
        {
          title: 'Faturamento',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.InvoicingDashboard,
          description:
            'Analise a situação da sua carteira compreendendo os gráficos apresentados em seu ”Painel de gestão” ',
          informationBlocks: [
            {
              media: {
                path: 'assets/img/help-center/dashboard/invoicing/invoicing-01.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle: 'Volume faturado por mês',
              paragraph: 'Este gráfico mostra o volume faturado por mês, em toneladas, nos últimos 12 meses.',
            },
            {
              blockTitle: 'Volume faturado por dia',
              paragraph: 'Este gráfico mostra o volume faturado por dia, em toneladas, nos últimos 31 dias.',
            },
          ],
        },
        {
          title: 'Finanças',
          key: HelpCenterSubsectionKeys.Financas,
          hasComingSoonTag: true,
          informationBlocks: [
            {
              blockTitle: 'Em breve.',
            },
          ],
        },
      ],
    }
  );
};
