import { Button, Icon } from '@gerdau/hefesto';
import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { IAdminBP, IAdminBPUser, ProfileTypes } from '@app/models/admin.model';
import { AdminBPsLineSeparator, AdminDataSeparator, TagHeaderContainer } from '@app/modules/admin/admin.page.style';
import { AdminStrings } from '@app/modules/admin/admin.string';
import { getUser } from '@app/modules/admin/store/thunk/user-get.thunk';
import { capitalizeFirstLetter } from '@app/utils/string-utils';
import { Body } from '@atomic/atm.typography';
import {
  AdminBPInfoContainer,
  AdminBPsButtonContainer,
  AdminBPsContainer,
  AdminBPsUserContainer,
  AdminBPsUserData,
  AdminBPsUserDataRow,
  AdminBPsUserInfo,
  AdminBPsUserInfoContainer,
} from '../admin-bps-view.styles';

interface AdminBPListContentProps {
  bp: IAdminBP;
}

export const AdminBPListContent: React.FC<AdminBPListContentProps> = props => {
  const { loadingUser } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = AdminStrings.pt.common;

  const renderUserRow = (user: IAdminBPUser, index: number) => {
    return (
      <AdminBPsUserDataRow key={`${props.bp._id}-${index}`}>
        <AdminBPsUserInfo>
          <AdminBPsUserInfoContainer>
            <TagHeaderContainer>
              <Tag variant='neutral'>{ProfileTypes[capitalizeFirstLetter(user.profile.toLocaleLowerCase())]}</Tag>
            </TagHeaderContainer>
            <Body bold={700}>{user.name}</Body>
            <AdminDataSeparator />
            <Body>{user.email}</Body>
            <AdminDataSeparator />
            <Body>{`${strings.uid} ${user.oktaUid}`}</Body>
          </AdminBPsUserInfoContainer>
          <AdminBPsButtonContainer>
            <Button
              name='editUser'
              variant='secondary'
              icon={<Icon option='Edit' />}
              loading={loadingUser}
              onClick={() => dispatch(getUser(user.email))}
            >
              {strings.edit}
            </Button>
          </AdminBPsButtonContainer>
        </AdminBPsUserInfo>
        <AdminBPsUserData>
          <Body>{`${strings.createdBy} ${user.created.by.name}`}</Body>
          <AdminDataSeparator />
          <Body>{`${strings.created} ${user.created.at.dateFormat}`}</Body>
          <AdminDataSeparator />
          <Body>{`${strings.lastLogin} ${user.lastLogin.dateFormat}`}</Body>
        </AdminBPsUserData>
      </AdminBPsUserDataRow>
    );
  };

  return (
    <AdminBPsContainer>
      <AdminBPsLineSeparator />
      <AdminBPInfoContainer>
        <AdminBPsUserContainer>
          {props.bp.users.map((user, i) => {
            return renderUserRow(user, i);
          })}
        </AdminBPsUserContainer>
      </AdminBPInfoContainer>
    </AdminBPsContainer>
  );
};
