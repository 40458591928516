import * as React from 'react';
import { Cell } from '@atomic/atm.cell';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { VSeparator } from '@atomic/obj.grid';

export const CustomerSearchModalBodyClientShimmer: React.FC = () => {
  return (
    <>
      {Array(5)
        .fill('')
        .map((_item, index) => (
          <Cell key={index}>
            <TextShimmerBoxStyled height='20px' width='100%' margin='0' />
            <VSeparator small />

            <TextShimmerBoxStyled height='14px' width='40%' margin='0' />
            <VSeparator small />

            <TextShimmerBoxStyled height='14px' width='40%' margin='0' />
          </Cell>
        ))}
    </>
  );
};
