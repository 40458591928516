import React from 'react';
import { H2 } from '@atomic/atm.typography';
import { AdminStrings } from '../../../../admin.string';
import { AdminPermissionsWrapperStyled } from './admin-profile-view.styles';
import { AdminProfileList } from './list-component/admin-profile-list.component';

export const AdminProfileView: React.FC = () => {
  const strings = AdminStrings.pt;

  return (
    <AdminPermissionsWrapperStyled>
      <H2>{strings.profileView.title}</H2>
      <AdminProfileList />
    </AdminPermissionsWrapperStyled>
  );
};
