// eslint-disable-next-line prettier/prettier
import type { Identifier, XYCoord } from 'dnd-core';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { AppThunkDispatch } from '@app/core/redux/store';
import { DragItem } from '@app/modules/components/schedule-modal/schedule.interfaces';
import { uncheckColumnLayout } from '@app/modules/components/schedule-modal/store/schedule.store';
import { Image } from '@atomic/atm.image';
import { ColumnActionWrapper, ColumnBoxWrapper, ColumnIndexWrapper, ColumnItemWrapper, ColumnTitleWrapper } from './selected-column-item.style';

export interface OrderColumnItemProps {
  value: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

export const OrderColumnItem: React.FC<OrderColumnItemProps> = ({ value, text, index, moveCard }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { value, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const removeCard = () => {
    dispatch(uncheckColumnLayout(value));
  };

  return (
    <ColumnItemWrapper ref={ref} hasOpacity={opacity} data-handler-id={handlerId}>
      <ColumnIndexWrapper>{index + 1}</ColumnIndexWrapper>
      <ColumnBoxWrapper>
        <ColumnTitleWrapper>{text}</ColumnTitleWrapper>
        <ColumnActionWrapper onClick={() => removeCard()}><Image.CloseIcon /></ColumnActionWrapper>
      </ColumnBoxWrapper>
    </ColumnItemWrapper>
  );
};
