import { Grid } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';

export const OrderInputResumeWrapper = styled.div`
  overflow: auto;
`;

export const OrderInputResumeGridWrapper = styled(Grid)`
  margin-top: ${Spacing.XLarge};
  padding-bottom: 140px;
`;
