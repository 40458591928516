import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { ScheduleDataSource, UpdateScheduleParams } from '@app/data/http/schedule.datasource';
import { IScheduleClient, IScheduleLayout } from '@app/models/schedule.model';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import {
  SLICE_NAME,
  ScheduleEvents,
  SchedulePhase,
  UserScheduleType,
  mapScheduleTypeToName,
  scheduleTypeToOccurenceType,
} from '../../schedule.constants';
import { ICrudScheduleParams, IScheduleState } from '../../schedule.interfaces';
import { formatTime, getSelectedDays } from '../../utils';
import { bpsSelectedSelector } from '../schedule.selectores';

export const updateSchedule = createAsyncThunk(
  `${SLICE_NAME}/updateSchedule`,
  async ({ onSuccess }: ICrudScheduleParams, thunkAPI) => {
    const state: any = thunkAPI.getState();

    const {
      scheduleId,
      scheduleName,
      selectedEmails,
      scheduleType,
      time,
      layout,
      currentTab,
    }: IScheduleState = state.schedule;
    const clients: IScheduleClient[] = bpsSelectedSelector(state);
    const customerIds = clients.map(client => client.code).join();

    const payload: UpdateScheduleParams = {
      id: scheduleId,
      name: scheduleName,
      mail: selectedEmails.map(badgeEmail => badgeEmail.email).join(),
      user_type: currentTab,
      occurrence_type: scheduleTypeToOccurenceType[scheduleType],
      send_day: getSelectedDays(state.schedule),
      hour_to_send: parseInt(time.substring(0, 2)),
      active: true,
      clients: clients.map((client: IScheduleClient) => ({
        code: client.code,
        name: client.name,
        sche_id: client.sche_id,
      })),
      layout,
    };

    const tagPayload = {
      send_day: getSelectedDays(state.schedule),
      bps: clients.map(client => client.code).join(),
      user_type: mapScheduleTypeToName[UserScheduleType.ORDER_OPEN],
      frequency: scheduleType,
      column: layout
        .filter((column: IScheduleLayout) => !!column.checked)
        .map(col => col.value)
        .join(),
      mail: selectedEmails.map(badgeEmail => badgeEmail.email).join(),
      time: formatTime(time),
    };

    return await ScheduleDataSource.updateSchedule(payload, customerIds)
      .then(() => {
        onSuccess(tagPayload);
      })
      .catch(error => {
        LogAnalytics.error({
          tipo: ScheduleEvents.UpdateScheduleError,
          responseStatus: error.response.status,
          responseData: error.response?.data?.message,
          customerIds,
        });
        thunkAPI.dispatch(setOpenErrorHandlerModal(true));
        return thunkAPI.rejectWithValue({});
      });
  },
);

export const addUpdateScheduleReducers = builder => {
  builder.addCase(updateSchedule.pending, state => {
    state.loadingUpdate = true;
  });
  builder.addCase(updateSchedule.fulfilled, state => {
    state.schedulePhase = SchedulePhase.Success;
    state.loadingUpdate = false;
  });
  builder.addCase(updateSchedule.rejected, state => {
    state.loadingUpdate = false;
  });
};
