import React from 'react';
import { FlexColumn } from '@atomic/obj.box';
import { ScheduleModalColumnsBody } from './partials/schedule-modal-columns-body/schedule-modal-columns-body.component';
import { ScheduleModalColumnsFooter } from './partials/schedule-modal-columns-footer/schedule-modal-columns-footer.component';
import { ScheduleModalColumnsHeader } from './partials/schedule-modal-columns-header/schedule-modal-columns-header.component';

export const ScheduleModalColumns: React.FC = () => {
  return (
    <FlexColumn>
      <ScheduleModalColumnsHeader />
      <ScheduleModalColumnsBody />
      <ScheduleModalColumnsFooter />
    </FlexColumn>
  );
};
