import React from 'react';
import { DeliveryForecastItem } from '@app/models';
import { Table, TD, TH, THead, TR } from '@atomic/mol.table';

interface DeliveryForecastInfoParams {
  items: DeliveryForecastItem[];
}

export const DeliveryForecastInfo: React.FC<DeliveryForecastInfoParams> = ({ items }) => {
  return (
    <Table>
      <THead>
        <TH>Data de entrega - Quantidade</TH>
      </THead>
      {items.map((item, index) => {
        const formatedQuantity = item.docQuantity.toString().replace('.', ',');
        return (
          <TR key={index}>
            <TD>{`${item.dtEstimateDeliveryMin} - ${item.dtEstimateDeliveryMax} - ${formatedQuantity}t`}</TD>
          </TR>
        );
      })}
    </Table>
  );
};
