import * as React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';

export const TruckTrackingShimmer: React.FC = () => {
  return (
    <Grid fluid>
      <Row>
        <Col xs={5}>
          <TextShimmerBoxStyled height='200px' width='100%' margin='0' />
          <VSeparator small />

          <TextShimmerBoxStyled height='150px' width='100%' margin='0' />
        </Col>
        <Col xs={7}>
          <TextShimmerBoxStyled height='350px' width='100%' margin='0' />
        </Col>
      </Row>
    </Grid>
  );
};
