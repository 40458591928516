import styled from 'styled-components';
import { Breakpoint, Color, DrawerLargeWidth, DrawerWidth, HeaderHeight, ZIndex } from '@atomic/obj.constants';
import { DrawerProps } from './drawer.component';

export const DrawerStyled = styled.div`
  position: fixed;
  width: 100%;
  top: ${HeaderHeight.Mobile};
  left: 0;
  bottom: 0;
  ${(props: DrawerProps) =>
    !props.active &&
    `
    display: none;
  `};
  z-index: ${(props: DrawerProps) => (props.active ? ZIndex.ActiveDrawer : ZIndex.InactiveDrawer)};
  transition: ${(props: DrawerProps) => (props.active ? 'none' : 'z-index .3s .3s')};
`;

export const DrawerMenuStyled = styled.div`
  position: absolute;
  background-color: ${Color.GrayXLight};
  width: ${(props: DrawerProps) => (!props.active ? 0 : props.large ? '100%' : DrawerWidth)};
  height: 100%;
  ${(props: DrawerProps) => (props.right ? 'right: 0;' : 'left: 0;')}
  top: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overscroll-behavior: contain;
  transition: ${(props: DrawerProps) => (props.active ? 'width .3s' : 'width .3s, z-index .3s .3s')};
  overflow: scroll;
  z-index: ${(props: DrawerProps) => (props.active ? '2' : 'initial')};

  @media all and (min-width: ${Breakpoint.sm}em) {
    width: ${(props: DrawerProps) => (!props.active ? 0 : props.large ? DrawerLargeWidth : DrawerWidth)};
  }
`;

export const DrawerOverlayStyled = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-color: ${Color.Black};
  opacity: ${(props: DrawerProps) => (props.active ? '.3' : '0')};
  z-index: ${(props: DrawerProps) => (props.active ? '1' : '-1')};
  pointer-events: ${(props: DrawerProps) => (props.active ? 'initial' : 'none')};
  transition: ${(props: DrawerProps) =>
    props.active ? 'opacity .3s ease-out' : 'opacity .3s ease-out, z-index .3s .3s'};
`;
