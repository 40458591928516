import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontSize, Spacing } from '@atomic/obj.constants';
import { OrderInputStrings } from '../../../order-input.string';
import { CustomDropzoneWrapperStyled } from './order-input-upload-file.style';
import { LoadedFile } from './partials/loaded-file/loaded-file';
import { OrderInputUploadFileDropZone } from './partials/order-input-upload-file-drop-zone/order-input-upload-file-drop-zone';

const Strings = OrderInputStrings.pt.selectItens;

interface StepThreeHeaderInfoProps {
  description?: string;
}

export const OrderInputUploadFile: React.FC<StepThreeHeaderInfoProps> = () => {
  const { files } = useSelector((state: RootState) => state.orderInput);

  return (
    <FlexColumn>
      <FlexRow>
        <Label mr={Spacing.Small} size={FontSize.Medium}>
          {Strings.stepThree.titleIARead}
        </Label>
      </FlexRow>
      <CustomDropzoneWrapperStyled>
        <OrderInputUploadFileDropZone />
        {files && <LoadedFile />}
      </CustomDropzoneWrapperStyled>
    </FlexColumn>
  );
};
