import styled from 'styled-components';

export const PlaceholderWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const PlaceholderStyled = styled.div`
  display: block;
  align-items: 'center';
  justify-content: center;
`;

export const LoadingWrapperStyled = styled.div`
  height: 80vh;
  padding-top: 100px;
  align-items: center;
`;

export const TrackingPlaceholderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: 'center';
  justify-content: center;
  height: 80vh;
`;

export const UploadNormTitle = styled.h2`
  & > h2 {
    max-width: 450px;
    text-align: center;
  }
`;
