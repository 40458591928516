import { HelpCenterSubsectionKeys, HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterBilledOrderSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Carteira - Faturados',
      key: HelpCenterSectionKeys.BilledOrders,
      subsections: [
        {
          title: 'Dicionário',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.BilledOrdersGlossary,
          informationBlocks: [
            {
              blockTitle: 'Entenda melhor os termos usados nas tabelas do Gerdau Mais',
              table: {
                columns: ['Nome da coluna', 'Conceito'],
                rows: [
                  [
                    'Acompanhar entrega',
                    'Hiperlink para escolher qual nota fiscal será feita a consulta no truck tracking (rastreio do caminhão).',
                  ],
                  [
                    'Bitola (mm)',
                    'Valor numérico referente a bitola (seção transversal do material) para materiais redondos, quadrados e hexagonais.',
                  ],
                  ['Cert. qualidade', 'Hiperlink para realizar o download do Certificado de Qualidade.'],
                  ['Cliente', 'Nome do cliente emissor do pedido de compra.'],
                  ['Cod. cliente', 'Código interno Gerdau do emissor do pedido de compra.'],
                  ['Cod. prod. cliente', 'Também chamado de Part Number, é o código do material na visão do cliente.'],
                  ['Comp. mínimo', 'Comprimento mínimo possível do material.'],
                  ['Comp. máximo', 'Comprimento máximo possível do material.'],
                  ['Comp. múltiplo', 'Comprimento múltiplo do material.'],
                  ['Data confirmada entrega', 'Data confirmada pela Gerdau para entrega do pedido.'],
                  ['Data faturamento', 'Data da emissão da Nota Fiscal.'],
                  [
                    'Data saída produto',
                    'Data que o material deve ser faturado para ser possível entregar ao cliente na data confirmada.',
                  ],
                  ['Data solicitada cliente', 'Data requerida pelo cliente ao emitir o pedido de compra.'],
                  [
                    'Desc. material',
                    'Descrição do material, contemplando as principais características, como produto, forma, processo, acabamento, bitola, liga, etc.',
                  ],
                  ['Espessura', 'Aplicável às barras chatas, informa o valor numérico da espessura da barra.'],
                  ['Forma', 'Forma do material, se é redondo, quadrado, hexagonal, chato, etc.'],
                  ['Grupo cliente', 'Código que simboliza o agrupamento de clientes pertencentes ao mesmo grupo.'],
                  ['Item ordem de compra', 'Item do pedido de compra emitido pelo cliente.'],
                  ['Item ordem de venda', 'Item da ordem de venda implantada pela Gerdau.'],
                  ['Largura', 'Aplicável às barras chatas, informa o valor numérico da largura da barra.'],
                  [
                    'Marca',
                    'Referência relacionada a norma indicando se é um aço padrão (norma internacional), customizado ou aplicação especial.',
                  ],
                  ['Material', 'Código interno Gerdau que simboliza a descrição do material.'],
                  ['Nº nota fiscal', 'Número da Nota Fiscal.'],
                  ['Nº remessa', 'Número interno Gerdau da remessa para carregamento.'],
                  ['Norma aço | Revisão', 'A identificação da norma e da revisão que o material está sendo produzido.'],
                  ['Nota fiscal', 'Hiperlink para realizar o download da Nota Fiscal.'],
                  ['Ordem compra', 'Campo que simboliza o pedido de compra emitido pelo cliente.'],
                  ['Ordem venda', 'Campo que simboliza a ordem de venda implantada pela Gerdau.'],
                  ['Preço R$/t', 'Preço comercializado para o pedido, medido em Reais por toneladas.'],
                  ['Qtd. solicitada (t)', 'Quantidade requerida pelo cliente no pedido de compra.'],
                  ['Qualidade', 'Liga do aço referente ao material solicitado pelo cliente no pedido de compra.'],
                  ['Quantidade (t)', 'Quantidade da Nota Fiscal.'],
                  [
                    'Recebedor',
                    'Parceiro sinalizado pelo cliente para receber a mercadoria, podendo ser o próprio cliente.',
                  ],
                  ['Remessa item', 'Item da remessa de carregamento.'],
                  [
                    'Status OV',
                    'Status da ordem de venda, implantada, aguardando aprazamento pelo PCP ou confirmada para produção e atendimento.',
                  ],
                  [
                    'Tipo mercado',
                    'Material será utilizado para atendimento ao mercado nacional, mercado internacional ou mercado nacional e posterior internacional.',
                  ],
                  ['Tipo produto', 'Tipo de produto, se é barra, fio máquina, arame, etc.'],
                  ['Usina', 'Planta que produzirá o material para atendimento do pedido.'],
                ],
              },
            },
          ],
        },
        {
          title: 'Rastreamento de veículos',
          key: HelpCenterSubsectionKeys.TruckTracking,
          description: 'Como consultar a localização do veículo com a sua entrega',
          informationBlocks: [
            {
              blockTitle: '1. Vá até a aba da Carteira e em seguida clique em "Faturado"',
              media: {
                path: 'assets/img/help-center/order/truck-tracking/truck-tracking-01.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle: '2. Identifique a nota fiscal que deseja consultar e clique no ícone do caminhão',
              media: {
                path: 'assets/img/help-center/order/truck-tracking/truck-tracking-02.png',
                type: 'stretch-image',
              },
            },
            {
              blockTitle:
                '3. Caso o veículo com a sua entrega possua rastreio, será possível ver as informações de localização do mesmo como:',
              paragraphList: [
                'Cidade/estado atual;',
                'Quilometragem restante até o destino;',
                'Data e hora da última atualização do rastreio;',
                'Mapa da viagem;',
              ],
              media: {
                path: 'assets/img/help-center/order/truck-tracking/truck-tracking-03.png',
                type: 'stretch-image',
              },
            },
            {
              paragraph: 'Caso o veículo com a sua entrega já tenha sido concluída:',
              media: {
                path: 'assets/img/help-center/order/truck-tracking/truck-tracking-04.png',
                type: 'stretch-image',
              },
            },
            {
              paragraph: 'Caso o veículo não possua rastreio:',
              media: {
                path: 'assets/img/help-center/order/truck-tracking/truck-tracking-05.png',
                type: 'stretch-image',
              },
            },
            {
              paragraph:
                'Caso o tempo de carregamento da página expirar, a seguinte mensagem irá aparecer e você pode tentar novamente:',
              media: {
                path: 'assets/img/help-center/order/truck-tracking/truck-tracking-06.png',
                type: 'stretch-image',
              },
            },
          ],
        },
        {
          title: 'Download de tipos de NF',
          key: HelpCenterSubsectionKeys.OrderNotification,
          hasComingSoonTag: false,
          informationBlocks: [
            {
              paragraphHtml: 'Dentro da aba <b>Carteira - Faturados</b>, na coluna de <b>Nota Fiscal</b>.',
              media: {
                path: 'assets/img/help-center/order/document-download/document-download-01.png',
                type: 'stretch-image',
              },
            },
            {
              paragraphHtml:
                'Clique no ícone de download para abrir as opções e clique na opção de formato desejada.<br /><ul><li>PDF: Nota fiscal do produto em formato PDF</li><li>XML: Nota fiscal do produto em formato XML</li><li>NF de Remessa: Nota fiscal triangular em que o produto é entregue em outra localidade</li>',
              media: {
                path: 'assets/img/help-center/order/document-download/document-download-02.png',
                type: 'stretch-image',
              },
            },
          ],
        },
      ],
    }
  );
};
