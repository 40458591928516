import { subtotalFormatter } from '@app/utils/order-column-formatter';
import { OrdersStrings } from '../orders.string';
import {
  ICreateTotalDivParams,
  IGetColIndexParams,
  IGetSubtotalsParams,
  IRemoveTotalDivIfExistsParams,
} from './grid-subtotal.interfaces';

export const getSubtotals = ({ itens, fieldName }: IGetSubtotalsParams): number => {
  let total = 0;

  itens.forEach(item => {
    if (item[fieldName]) {
      total = total + parseFloat(item[fieldName]);
    }
  });

  return total;
};

export const getColIndex = ({ gridWrapperElement, fieldName }: IGetColIndexParams): number => {
  let index = null;

  if (gridWrapperElement) {
    const column = gridWrapperElement.querySelector(`[col-id='${fieldName}']`);
    if (column) {
      const ariaColIndexAttibute = column.attributes['aria-colindex'];
      if (ariaColIndexAttibute) {
        index = ariaColIndexAttibute.value;
      }
    }
  }
  return index;
};

export const removeFilterInputIfExists = (filterCell: Element): void => {
  const filterInput = filterCell.querySelector('input');
  if (filterInput) {
    filterInput.remove();
  }
};

export const removeTotalDivIfExists = ({ gridWrapperElement, className }: IRemoveTotalDivIfExistsParams): void => {
  const totalDivCreated = gridWrapperElement.getElementsByClassName(className);
  if (totalDivCreated[0]) {
    totalDivCreated[0].remove();
  }
};

export const factoryDivElement = (className: string): HTMLElement => {
  const totalElementDiv = document.createElement('div');
  totalElementDiv.className = className;
  totalElementDiv.style.width = '100%';
  totalElementDiv.style.display = 'flex';
  totalElementDiv.style.flexDirection = 'row';
  totalElementDiv.style.justifyContent = 'space-between';

  return totalElementDiv;
};

export const createTotalDiv = ({ language, subTotal, className }: ICreateTotalDivParams): HTMLElement => {
  const totalDiv = factoryDivElement(className);

  const labelText = document.createElement('span');
  labelText.innerHTML = OrdersStrings[language]?.common.agGrid.subtotal;

  const spanText = document.createElement('span');
  spanText.innerHTML = subTotal === 0 ? '0' : subtotalFormatter(subTotal, language);

  totalDiv.appendChild(labelText);
  totalDiv.appendChild(spanText);

  return totalDiv;
};

export const removeFilterInput = (tabName, fieldName) => {
  const gridWrapperElement = document.getElementById(`${tabName}-grid`);
  if (gridWrapperElement) {
    const colindex = getColIndex({ gridWrapperElement, fieldName });
    const elementsWithSameAriaColIndex = gridWrapperElement?.querySelectorAll(`[aria-colindex='${colindex}']`);
    if (elementsWithSameAriaColIndex) {
      const filterCell = elementsWithSameAriaColIndex[1];
      if (filterCell) {
        removeFilterInputIfExists(filterCell);
      }
    }
  }
};
