import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { OrderAction } from '@app/models/order.model';
import { Strings, IComponentsStrings } from '@app/modules/components/components.string';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H3 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { OrderActionsWrapperStyled } from './order-actions.component.style';

interface OrderActionsProps {
  selectedNumber: number;
  loading?: boolean;
  actions: OrderAction[];
}

export const OrderActions: React.FC<OrderActionsProps> = ({ selectedNumber, loading, actions }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IComponentsStrings = Strings[userInfo.language];

  const handleOrderActions = action => {
    if (!action.disabled) {
      return (
        <React.Fragment key={action.title}>
          <FlexColumn noGrow>
            <Button loading={loading} kind='secondary' onClick={action.action}>
              <FlexRow>
                <FlexColumn noGrow>
                  <FaIcon.Custom icon={action.icon} color={Color.Primary} />
                </FlexColumn>
                <Hbox.Separator small />
                <FlexColumn>{action.title}</FlexColumn>
              </FlexRow>
            </Button>
          </FlexColumn>
          <Hbox.Separator />
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <OrderActionsWrapperStyled>
      <VSeparator />
      <Grid fluid>
        <H3 color={Color.White}>{strings.actions(selectedNumber)}</H3>
        <VSeparator small />
        <FlexRow>{actions.map(action => handleOrderActions(action))}</FlexRow>
        <VSeparator small />
      </Grid>
      <VSeparator />
    </OrderActionsWrapperStyled>
  );
};
