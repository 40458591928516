import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { useRouter } from '@app/core/route';
import { IAppStrings, Strings } from '@app/modules/app/app.string';
import { AppPath } from '@app/modules/app/route-constants';
import {
  hasDashboardTabAccessSelector,
  hasFinanceTabAccessSelector,
  hasManifestTabAccessSelector,
  hasOrderInputTabAccessSelector,
  hasOrdersTabAccessSelector,
  hasQuoteTabAccessSelector,
} from '@app/modules/auth/store/auth.selectores';
import { OrderMenu } from '@app/modules/components/order/order-menu/order-menu.component';
import { MarketType } from '@app/providers';
import { Menu } from '@atomic/mol.menu';
import { ShortHeaderLimit } from '@atomic/obj.constants';
import { MenuList, TabsMenuAppHeaderWrapper } from './tabs-menu-header.style';

interface TabsMenuAppHeaderProps {
  shortHeader: boolean;
  handleLogout: () => void;
}

export const TabsMenuAppHeader: React.FC<TabsMenuAppHeaderProps> = ({ shortHeader, handleLogout }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const hasDashboardTabAccess = useSelector(hasDashboardTabAccessSelector);
  const hasQuoteTabAccess = useSelector(hasQuoteTabAccessSelector);
  const hasOrderInputTabAccess = useSelector(hasOrderInputTabAccessSelector);
  const hasOrdersTabAccess = useSelector(hasOrdersTabAccessSelector);
  const hasFinanceTabAccess = useSelector(hasFinanceTabAccessSelector);
  const hasManifestTabAccess = useSelector(hasManifestTabAccessSelector);
  const strings: IAppStrings = Strings[userInfo?.language];
  const { currentMarket, clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const { openHamburguerMenu } = useSelector((state: RootState) => state.navigation);
  const { location } = useRouter();
  const windowWitdh = document.body.clientWidth;

  const preventRerender = (e: React.MouseEvent<HTMLLinkElement>, to: string) => {
    if (location.pathname === to) {
      e.preventDefault();
    }
  };

  return (
    <TabsMenuAppHeaderWrapper shortHeader={shortHeader} active={openHamburguerMenu}>
      <MenuList shortHeader={shortHeader}>
        {hasDashboardTabAccess && currentMarket === MarketType.Internal && !!clientBpsSelected.length && (
          <Menu.NavLink
            active={location.pathname === AppPath.DASHBOARD}
            to={AppPath.DASHBOARD}
            onClick={e => {
              preventRerender(e, AppPath.DASHBOARD);
              LogAnalytics.click({ tipo: 'DashboardClick' });
            }}
          >
            {strings?.tabs.dashboard}
          </Menu.NavLink>
        )}
        {hasQuoteTabAccess && (
          <Menu.NavLink
            active={location.pathname === AppPath.QUOTE.BASE || location.pathname === AppPath.QUOTE.CRM}
            to={AppPath.QUOTE.BASE}
            onClick={e => {
              preventRerender(e, AppPath.QUOTE.BASE);
              LogAnalytics.click({ tipo: 'QuoteClick' });
            }}
          >
            {strings?.tabs.quote}
          </Menu.NavLink>
        )}
        {hasOrderInputTabAccess && !!clientBpsSelected.length && (
          <Menu.NavLink
            active={location.pathname === AppPath.ORDER_INPUT}
            to={AppPath.ORDER_INPUT}
            onClick={() => LogAnalytics.click({ tipo: 'OrderInputClick' })}
          >
            {strings?.tabs.ordersInput}
          </Menu.NavLink>
        )}
        {hasOrdersTabAccess && <OrderMenu preventRerender={preventRerender} />}
        {hasFinanceTabAccess && (
          <Menu.NavLink
            onClick={e => preventRerender(e, AppPath.FINANCE)}
            active={location.pathname === AppPath.FINANCE}
            to={AppPath.FINANCE}
          >
            {strings?.tabs.finance}
          </Menu.NavLink>
        )}
        {hasManifestTabAccess && (
          <Menu.NavLink
            active={
              location.pathname === AppPath.COMPLAIN.BASE ||
              location.pathname === AppPath.COMPLAIN.CRM ||
              location.pathname === AppPath.COMPLAIN.REPORT
            }
            to={AppPath.COMPLAIN.BASE}
            onClick={e => {
              preventRerender(e, AppPath.COMPLAIN.BASE);
              LogAnalytics.click({ tipo: 'ComplainClick' });
            }}
          >
            {strings?.tabs.complain}
          </Menu.NavLink>
        )}
        {windowWitdh <= ShortHeaderLimit.limitMobile && (
          <Menu.NavLink onClick={handleLogout}>{strings?.configuration.logout}</Menu.NavLink>
        )}
      </MenuList>
    </TabsMenuAppHeaderWrapper>
  );
};
