import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Container } from 'typedi';
import { IsaHttpRequestBuilder } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
//import { handleUnauthorized } from '@app/utils/http-utils';

export async function editQuestion(params: { payload: any; key: string; step: string }): Promise<any> {
  const user = Container.get(AuthStorageService).getUser();

  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

  const url = `https://cxp-quote-api-cxp-dev.apps2.rosa-gerdau.bxnu.p1.openshiftapps.com/v3/api/forms/questions/${params.step}/${params.key}`;

  try {
    const { data, status } = await axios.put(url, params.payload, { headers: defaultHeaders });
    if (status === 200) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    //handleUnauthorized(error);
    return null;
  }
}

export function useEditQuestion(onSuccess?, onError?) {
  return useMutation(editQuestion, {
    onSuccess,
    onError,
    retry: false,
  });
}
