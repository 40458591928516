import styled from 'styled-components';
import { Border, Color, Spacing, ZIndex } from '@atomic/obj.constants';

interface DashboardHeaderWrapperStyledProps {
  show: boolean;
}

export const DashboardHeaderWrapperStyled = styled.div`
  opacity: ${(props: DashboardHeaderWrapperStyledProps) => (props.show ? 1 : 0)};
  max-height: ${(props: DashboardHeaderWrapperStyledProps) => (props.show ? '600px' : 0)};
  z-index: ${ZIndex.DashboardHeaderWrapperStyled};
  position: absolute;
  background-color: ${Color.White};
  width: ${(props: DashboardHeaderWrapperStyledProps) => (props.show ? '400px' : 0)};
  right: 10px;
  top: 50px;
  padding: ${Spacing.Medium};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
`;

export const MockDashboardOpacityStyled = styled.div`
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${ZIndex.ModalOpacityStyled};
  background-color: ${Color.Gray};
  opacity: 0.3;
`;
export const MockDashboardWarningStyled = styled.div`
  position: relative;
  top: 250px;
  left: 46%;
  width: 120px;
  height: 40px;
  padding-top: 8px;
  padding-left: 23px;
  border: ${Border.Width} solid;
  background-color: ${Color.White};
  z-index: ${ZIndex.DashboardWarning};
`;
