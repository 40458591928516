import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { hasSomeColumnSelected } from '@app/modules/components/schedule-modal/store/schedule.selectores';
import { updateSchedule } from '@app/modules/components/schedule-modal/store/thunks/schedule-update.thunk';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';
import { ScheduleEvents, SchedulePhase } from '../../../../schedule.constants';
import { strings } from '../../../../schedule.strings';
import { setSchedulePhase } from '../../../../store/schedule.store';
import { createSchedule } from '../../../../store/thunks/schedule-create.thunk';

export const ScheduleModalColumnsFooter: React.FC = () => {
  const { loadingSubmit, loadingUpdate, editMode } = useSelector((state: RootState) => state.schedule);
  const someColumnSelected = useSelector(hasSomeColumnSelected);
  const loadingtButtons = loadingSubmit || loadingUpdate;
  const dispatch = useDispatch<AppThunkDispatch>();

  const onSuccessCreate = payload => {
    LogAnalytics.success({
      tipo: ScheduleEvents.CreateScheduleSuccess,
      ...payload,
    });
  };

  const onSuccessUpdate = payload => {
    LogAnalytics.success({
      tipo: ScheduleEvents.UpdateScheduleSuccess,
      ...payload,
    });
  };

  return (
    <FlexRow mb hAlign='flex-end'>
      <Button loading={loadingtButtons} kind='secondary' onClick={() => dispatch(setSchedulePhase(SchedulePhase.Form))}>
        {strings.cancelButtonSelectColumns}
      </Button>
      <Button
        disabled={!someColumnSelected}
        loading={loadingtButtons}
        onClick={() =>
          editMode
            ? dispatch(updateSchedule({ onSuccess: onSuccessUpdate }))
            : dispatch(createSchedule({ onSuccess: onSuccessCreate }))
        }
      >
        <FaIcon.Save />
        <FlexRow>{strings.saveButton}</FlexRow>
      </Button>
    </FlexRow>
  );
};
