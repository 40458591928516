import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import store from '@app/core/redux/store';
import { Credit } from '@app/models';
import { NonBindingCredit } from '@app/models/non-binding-credit.model';
import { CreditResponse, creditResponseToCreditList } from './credit.dto';

export interface GetCreditParams {
  customerIds: string;
}

const endpoint = {
  CREDITMI: 'mi/financials/credit',
  CREDITME: 'me/financials/credit',
  NON_BINDING_CREDIT: '/financial/credit/not-vinculation',
};

export const CreditDataSource = {
  getCreditMI: (params: GetCreditParams): Promise<Credit[]> => {
    const authStore = store.getState().auth;

    return Container.get(HttpRequestBuilder)
      .get(endpoint.CREDITMI)
      .headers({
        'Sso-Access': authStore.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: CreditResponse): Promise<Credit[]> => creditResponseToCreditList(res),
      })
      .execute();
  },

  getCreditME: (params: GetCreditParams): Promise<Credit[]> => {
    const authStore = store.getState().auth;

    return Container.get(HttpRequestBuilder)
      .get(endpoint.CREDITME)
      .headers({
        'Sso-Access': authStore.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: CreditResponse): Promise<Credit[]> => creditResponseToCreditList(res),
      })
      .execute();
  },

  getNonBindingCredit: (params: GetCreditParams): Promise<NonBindingCredit> => {
    const authStore = store.getState().auth;

    return Container.get(HttpRequestBuilder)
      .get(endpoint.NON_BINDING_CREDIT)
      .headers({
        'Sso-Access': authStore.ssoAccess,
      })
      .params(params)
      .execute();
  },
};
