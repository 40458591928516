import { IAnnouncementModalState } from '../announcement-modal.interfaces';
import { initialState } from './announcement-modal.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setAnnouncements: (state: IAnnouncementModalState, { payload }) => {
    state.announcements = payload;
  },
  setOpened: (state: IAnnouncementModalState, { payload }) => {
    state.opened = payload;
  },
};
