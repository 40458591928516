import * as React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { TechnicalAnalysisEvents } from '../../quote.analytics';
import { useEditSkus } from '../hooks/useEditSkus';
import { mapSKU } from './technical-analysis-status.mapper';

interface TechnicalAnalysisSKUsModalProps {
  analysisId: number;
  language: string;
  opened: boolean;
  onModalClose: () => void;
}

export interface TechnicalAnalysisSKUModalFormData {
  sku?: string;
}

export const TechnicalAnalysisSKUModal: React.FC<TechnicalAnalysisSKUsModalProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = TechnicalAnalysisStrings[props.language].skuModalstatus;
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const handleChangeStatusSuccess = () => {
    show('success', 'SKU enviado com sucesso.');
    props.onModalClose();
  };

  const handleChangeStatusError = () => {
    show('alert', 'Erro ao enviar SKU.');
    props.onModalClose();
  };

  const { mutate: patchSkus, isLoading: loading } = useEditSkus(handleChangeStatusSuccess, handleChangeStatusError);

  const handleSubmit = (form: FormData<TechnicalAnalysisSKUModalFormData>) => {
    if (Object.keys(form.error).length <= 0) {
      LogAnalytics.click({ tipo: TechnicalAnalysisEvents.StatusChange });

      patchSkus({
        analysisId: props.analysisId,
        skus: mapSKU(form.data?.sku),
        language: userInfo.language,
      });
    }
  };

  const handleClose = () => {
    props.onModalClose();
  };

  return (
    <Modal opened={props.opened} onClose={handleClose} small>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <VSeparator />

            <H3>{strings.title}</H3>
            <VSeparator />

            <Form onSubmit={handleSubmit}>
              <Form.Field
                name='sku'
                label={strings.skuField.label}
                validators={[
                  Validators.Required(strings.skuField.alertMandatory),
                  Validators.CheckSkusIsNumber(strings.skuField.alertSkuNumber),
                  Validators.CheckSkusFormat(strings.skuField.alertSkuFormat),
                ]}
              >
                <TextField placeholder={strings.skuField.placeholder} />
              </Form.Field>
              <VSeparator />

              <Hbox hAlign='flex-end'>
                <Hbox.Item noGrow>
                  <Button loading={loading} type='submit' kind='primary'>
                    {strings.btnSend}
                  </Button>
                </Hbox.Item>
              </Hbox>
            </Form>
            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
