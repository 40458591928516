import * as React from 'react';
import { HamburgerBoxStyled, HamburgerButtonStyled, HamburgerInnerStyled } from './hamburger-button.component.style';

export interface HamburgerButtonProps {
  active?: boolean;
  onClick?: () => void;
}

export class HamburgerButton extends React.Component<HamburgerButtonProps, undefined> {
  static defaultProps = {
    active: false,
  };

  render() {
    return (
      <HamburgerButtonStyled type='button' onClick={this.props.onClick}>
        <HamburgerBoxStyled>
          <HamburgerInnerStyled active={this.props.active} />
        </HamburgerBoxStyled>
      </HamburgerButtonStyled>
    );
  }
}
