import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';

const BADGE_SPACING = '-1px';
const BADGE_LARGE_SPACING = '-10px';
const BADGE_SIZE = '20px';
const BADGE_LARGE_WIDTH = '31px';
const BADGE_BORDER_RADIUS = '50%';
const BADGE_LARGE_RADIUS = '10px';

export const InternalBadgeWrapperStyled = styled.div`
  position: relative;
`;

export const InternalBadgeContentWrapperStyled = styled.div`
  position: absolute;
  right: ${BADGE_SPACING};
  top: ${BADGE_SPACING};
`;

export const InternalLargeBadgeContentWrapperStyled = styled.div`
  position: absolute;
  right: ${BADGE_LARGE_SPACING};
  top: ${BADGE_SPACING};
`;

export const InternalBadgeContentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${BADGE_SIZE};
  height: ${BADGE_SIZE};
  border-radius: ${BADGE_BORDER_RADIUS};
  background-color: ${Color.Alert};
`;

export const InternalLargeBadgeContentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${BADGE_LARGE_WIDTH};
  height: ${BADGE_SIZE};
  border-radius: ${BADGE_LARGE_RADIUS};
  background-color: ${Color.Alert};
`;
