import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'typedi';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Link } from '@app/core/route';
import { IAppStrings, Strings } from '@app/modules/app/app.string';
import { AppPath } from '@app/modules/app/route-constants';
import {
  hasAdminGsbSelector,
  hasGabAccessSelector,
  hasInternalAccessSelector,
  hasLackOfChargeAccessSelector,
  hasSchedulingCompletedAccessSelector,
  hasSurrogateAccessSelector,
  hasUserManagerAccessSelector,
} from '@app/modules/auth/store/auth.selectores';
import { setOpenLackOfChargeActionModal } from '@app/modules/order/order-tabs/MI/tab-open/partials/lack-of-charge-modal/store/lack-of-charge-modal.store';
import { setOpenSchedulingCompleteConfigModal } from '@app/modules/order/order-tabs/MI/tab-open/partials/scheduling-complete/store/scheduling-complete-modal.store';
import { Cell } from '@atomic/atm.cell';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { AppConfigurationOptionsStyled } from './app-configuration-options.component.style';
import { LanguageSelector } from './partials/language-selector/language-selector.component';

interface AppConfigurationOptionsProps {
  onLogout: () => void;
}

export const AppConfigurationOptions: React.FC<AppConfigurationOptionsProps> = props => {
  const gabUrl: string = Container.get('GabUrl');
  const menuRef = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const hasUserManagerAccess = useSelector(hasUserManagerAccessSelector);
  const hasAdminGsb = useSelector(hasAdminGsbSelector);
  const hasGabAccess = useSelector(hasGabAccessSelector);
  const hasInternalAccess = useSelector(hasInternalAccessSelector);
  const hasLackOfChargeAccess = useSelector(hasLackOfChargeAccessSelector);
  const hasSchedulingCompletedAccess = useSelector(hasSchedulingCompletedAccessSelector);
  const hasSurrogateAccess = useSelector(hasSurrogateAccessSelector);
  const dispatch = useDispatch<AppThunkDispatch>();

  const strings: IAppStrings = Strings[userInfo?.language];

  const handleMouseDown = (event: MouseEvent) => {
    if (show && menuRef && !menuRef.current.contains(event.target)) {
      // Detect click outside
      setShow(false);
    }
  };
  useEventListener('mousedown', handleMouseDown);

  const handleConfigurationOptionsClick = () => {
    LogAnalytics.click({ tipo: 'OptionsConfiguration' });
    setShow(show ? false : true);
  };

  const handleLackOfChargeAlertClick = () => {
    dispatch(setOpenLackOfChargeActionModal(true));
  };

  const handleSchedulingCompleteAlertClick = () => {
    dispatch(setOpenSchedulingCompleteConfigModal(true));
  };

  return (
    <>
      <FaIcon.Cog clickable='true' onClick={handleConfigurationOptionsClick} color={Color.Gray} />
      {show && (
        <AppConfigurationOptionsStyled show={show} ref={menuRef}>
          <VSeparator />

          <Link data-testid='change-password-menu' to={AppPath.CHANGE_PASSWORD}>
            <FlexRow hAlign='center' vAlign='center' ml={Spacing.Medium}>
              <FlexColumn noGrow vAlign='center'>
                <FaIcon.EditUser size='1x' color={Color.Gray} />
              </FlexColumn>
              <FlexColumn ml={Spacing.Medium}>
                <Body>{strings?.configuration.changePassword}</Body>
              </FlexColumn>
            </FlexRow>
          </Link>
          <VSeparator />

          {hasUserManagerAccess && (
            <>
              <Link data-testid='user-manager-menu' to={AppPath.ADMIN.BASE}>
                <FlexRow hAlign='center' vAlign='center' ml={Spacing.Medium}>
                  <FlexColumn noGrow vAlign='center'>
                    <FaIcon.Settings size='1x' color={Color.Gray} />
                  </FlexColumn>
                  <FlexColumn ml={Spacing.Medium}>
                    <Body>{strings?.configuration.manager}</Body>
                  </FlexColumn>
                  <Hbox.Separator />
                </FlexRow>
              </Link>
              <VSeparator />
            </>
          )}

          {hasAdminGsb && (
            <>
              <Link data-testid='admin-settings-menu' to={AppPath.ADMIN_SETTINGS}>
                <FlexRow hAlign='center' vAlign='center' ml={Spacing.Medium}>
                  <FlexColumn noGrow vAlign='center'>
                    <FaIcon.Toolbox size='1x' color={Color.Gray} />
                  </FlexColumn>
                  <FlexColumn ml={Spacing.Medium}>
                    <Body>{strings?.configuration.settings}</Body>
                  </FlexColumn>
                </FlexRow>
              </Link>
              <VSeparator />
            </>
          )}
          {hasGabAccess && (
            <>
              <Link data-testid='gab-link-menu' to={gabUrl}>
                <FlexRow hAlign='center' vAlign='center' ml={Spacing.Medium}>
                  <FlexColumn noGrow vAlign='center'>
                    <FaIcon.Exchange size='1x' color={Color.Gray} />
                  </FlexColumn>
                  <FlexColumn ml={Spacing.Medium}>
                    <Body>{strings?.configuration.navigateToGAB}</Body>
                  </FlexColumn>
                </FlexRow>
              </Link>
              <VSeparator />
            </>
          )}

          {hasInternalAccess && (
            <LanguageSelector data-testid='language-menu' title={strings?.configuration.language} />
          )}

          {hasSurrogateAccess && (
            <>
              <Link data-testid='surrogate-menu' to={AppPath.SURROGATE}>
                <FlexRow hAlign='center' vAlign='center' ml={Spacing.Medium}>
                  <FlexColumn noGrow vAlign='center'>
                    <Image.Mail />
                  </FlexColumn>
                  <FlexColumn ml={Spacing.Medium}>
                    <Body>{strings?.configuration.surrogate}</Body>
                  </FlexColumn>
                </FlexRow>
              </Link>
              <VSeparator />
            </>
          )}

          {hasLackOfChargeAccess && (
            <>
              <Cell data-testid='lack-of-charge-menu' hideBorder onClick={() => handleLackOfChargeAlertClick()}>
                <FlexRow hAlign='center' vAlign='center' ml={Spacing.Medium} mt={Spacing.Small}>
                  <FlexColumn noGrow vAlign='center'>
                    <FaIcon.Bell size='1x' color={Color.Gray} />
                  </FlexColumn>
                  <FlexColumn ml={Spacing.Medium}>
                    <Body>{strings?.configuration.lackOfCharge}</Body>
                  </FlexColumn>
                </FlexRow>
              </Cell>

              <VSeparator />
            </>
          )}

          {hasSchedulingCompletedAccess && (
            <>
              <Cell
                data-testid='scheduling-complete-menu'
                hideBorder
                onClick={() => handleSchedulingCompleteAlertClick()}
              >
                <FlexRow hAlign='center' vAlign='center' ml={Spacing.Medium} mt={Spacing.Small}>
                  <FlexColumn noGrow vAlign='center'>
                    <FaIcon.Bell size='1x' color={Color.Gray} />
                  </FlexColumn>
                  <FlexColumn ml={Spacing.Medium}>
                    <Body>{strings?.configuration.schedulingComplete}</Body>
                  </FlexColumn>
                </FlexRow>
              </Cell>

              <VSeparator />
            </>
          )}

          <Cell data-testid='logout-menu' hideBorder onClick={props.onLogout}>
            <FlexRow hAlign='center' vAlign='center' ml={Spacing.Medium} mt={Spacing.XSmall}>
              <FlexColumn noGrow vAlign='center'>
                <FaIcon.Power size='1x' color={Color.Gray} />
              </FlexColumn>
              <FlexColumn ml={Spacing.Medium}>
                <Body>{strings?.configuration.logout}</Body>
              </FlexColumn>
            </FlexRow>
          </Cell>
          <VSeparator />
        </AppConfigurationOptionsStyled>
      )}
    </>
  );
};
