import { Checkbox } from '@gerdau/hefesto';
import { Tag } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { RoleDisplayName, RoleInternalName, TabInternalName } from '@app/models/admin.model';
import { setAdminRoleConfig } from '@app/modules/admin/store/admin.store';
import { updateRole } from '@app/modules/admin/store/thunk/profile-update.thunk';
import { addOrRemoveItem } from '@app/utils/array-helper';
import {
  AdminPermissionsRoleCol,
  PermissionRoleColCell,
  PermissionRoleTitleColCell,
} from '../../admin-profile-view.styles';
import { getTabIdList } from '../../utils/utils';

export const AdminProfileAdminRoleCol: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { tabChips, adminRoleConfig } = useSelector((state: RootState) => state.admin);

  const handleChange = (tagName: string) => {
    const tab = { _id: tabChips.filter(t => t.tag === tagName)[0]._id, tag: tagName };
    const newTabArray = addOrRemoveItem(tagName, 'tag', adminRoleConfig.tabs, tab);
    const updatedAdminRoleConfig = { _id: adminRoleConfig._id, profile: adminRoleConfig.profile, tabs: newTabArray };
    dispatch(setAdminRoleConfig(updatedAdminRoleConfig));
    dispatch(
      updateRole({ uid: adminRoleConfig._id, profile: adminRoleConfig.profile, tabs: getTabIdList(newTabArray) }),
    );
  };

  return (
    <AdminPermissionsRoleCol>
      <PermissionRoleTitleColCell>
        <Tag variant='neutral'>{adminRoleConfig?.profile === RoleInternalName.Admin && RoleDisplayName.Admin}</Tag>
      </PermissionRoleTitleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminDashboardCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.Dashboard)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Dashboard);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminQuotationtCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.Quotation)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Quotation);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminOrderInputCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.OrderInput)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.OrderInput);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminOrderCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.Order)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Order);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminFinanceCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.Finance)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Finance);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminManifestCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.Manifest)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Manifest);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminIsaManagerCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.IsaManager)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.IsaManager);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminCrmCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.Crm)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Crm);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminSurrogateCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.Surrogate)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.Surrogate);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminLackOfLoadCb'
          checked={
            adminRoleConfig?.tabs?.length && !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.LackOfLoad)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.LackOfLoad);
          }}
        />
      </PermissionRoleColCell>
      <PermissionRoleColCell>
        <Checkbox
          name='adminSchedulingCompletedCb'
          checked={
            adminRoleConfig?.tabs?.length &&
            !!adminRoleConfig.tabs.filter(t => t.tag === TabInternalName.SchedulingCompleted)[0]
          }
          onChange={() => {
            handleChange(TabInternalName.SchedulingCompleted);
          }}
        />
      </PermissionRoleColCell>
    </AdminPermissionsRoleCol>
  );
};
