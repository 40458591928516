import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ITruckTrackingState } from '@app/modules/truck-tracking/truck-tracking.interfaces';
import { SLICE_NAME } from '../truck-tracking.constants';
import { addThunkReducers } from './thunk/truck-tracking.thunks';
import { initialState } from './truck-tracking.initial';
import { reducers } from './truck-tracking.reducers';

export const order = createSlice<ITruckTrackingState, SliceCaseReducers<ITruckTrackingState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

const RESET_TRUCK_TRACKING = `${SLICE_NAME}/reset`;

export const RESET_TRUCK_TRACKING_ACTION = { type: RESET_TRUCK_TRACKING };
export const { setShowTruckTracking, setTruckTrackingInfo, reset } = order.actions;

export const truckTrackingReducer = order.reducer;
