import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { ScheduleEvents, SchedulePhase } from '@app/modules/components/schedule-modal/schedule.constants';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { requiredFieldsSelector } from '@app/modules/components/schedule-modal/store/schedule.selectores';
import { setSchedulePhase } from '@app/modules/components/schedule-modal/store/schedule.store';
import { deleteSchedule } from '@app/modules/components/schedule-modal/store/thunks/schedule-delete.thunk';
import { updateSchedule } from '@app/modules/components/schedule-modal/store/thunks/schedule-update.thunk';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';

export const ScheduleModalFormFooter: React.FC = () => {
  const { editMode, loadingDelete, loadingUpdate } = useSelector((state: RootState) => state.schedule);
  const enableButton: boolean = useSelector(requiredFieldsSelector);
  const loadingEditButtons = loadingDelete || loadingUpdate;
  const dispatch = useDispatch<AppThunkDispatch>();

  const onSuccessUpdate = payload => {
    LogAnalytics.success({
      tipo: ScheduleEvents.UpdateScheduleSuccess,
      ...payload,
    });
  };

  const onSuccessDelete = payload => {
    LogAnalytics.success({
      tipo: ScheduleEvents.DeleteScheduleSuccess,
      ...payload,
    });
  };

  const onDeleteButtonClick = () => {
    dispatch(deleteSchedule({ onSuccess: onSuccessDelete }));
  };

  const onUpdateButtonClick = () => {
    dispatch(updateSchedule({ onSuccess: onSuccessUpdate }));
  };

  return (
    <FlexRow hAlign='space-between'>
      <FlexRow>
        {editMode && (
          <Button kind='alert' onClick={onDeleteButtonClick} loading={loadingEditButtons}>
            <FaIcon.Trash />
            {strings.deleteButton}
          </Button>
        )}
      </FlexRow>
      <FlexRow mb hAlign='flex-end'>
        <Button
          loading={loadingEditButtons}
          kind='secondary'
          onClick={() => dispatch(setSchedulePhase(SchedulePhase.List))}
        >
          {strings.cancelButtonCreate}
        </Button>
        <Button
          loading={loadingEditButtons}
          disabled={!enableButton}
          onClick={() => dispatch(setSchedulePhase(SchedulePhase.SelectColumns))}
        >
          <FaIcon.Columns />
          <FlexRow>{strings.columnSelectionButton}</FlexRow>
        </Button>
        {editMode && (
          <Button loading={loadingEditButtons} disabled={!enableButton} onClick={onUpdateButtonClick}>
            <FaIcon.Save />
            <FlexRow>{strings.saveButton}</FlexRow>
          </Button>
        )}
      </FlexRow>
    </FlexRow>
  );
};
