export const SLICE_NAME = 'announcement-modal';
export const NOTIFICATION_INSTABILITY_KEY = 'notifications_instability';
export const TEMPORARY_SHUTDOWN_PLANT_BR_KEY = 'temporary_shutdown_plant_br_key';
export const TEMPORARY_SHUTDOWN_PLANT_EN_KEY = 'temporary_shutdown_plant_en_key';
export const IMG_RELATIVE_PATH = 'assets/img/announcement-modal/';

export const ONBOARDING_SECTION = 'onboarding';
export const ONBOARDING_ORDER_MI_KEY = 'onboarding-order-mi';
export const ONBOARDING_BRANDS_KEY = 'onboarding-brands';
export const LACK_OF_CHARGE_MI_KEY = 'lack-of-charge-mi';
export const SCHEDULING_COMPLETE_KEY = 'scheduling-completed';
