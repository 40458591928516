export const parseOrderDate = (date?: string): string => {
  let formattedOrderDate = '';

  if (date) {
    const strippedDate = date.split('-').join('');
    formattedOrderDate = `${strippedDate.substring(6, 8)}/${strippedDate.substring(4, 6)}/${strippedDate.substring(
      0,
      4,
    )}`;
  }

  return formattedOrderDate;
};
