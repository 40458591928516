import styled, { css } from 'styled-components';
import { Label } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color, FontFamily, Spacing } from '@atomic/obj.constants';

export const OptionsWrapper = styled.div`
  position: absolute;
  z-index: 2;
  overflow-x: hidden;
  height: auto;
  left: 0;
  width: 244px;
  overflow-y: auto;
  background-color: ${Color.White};
  border: 2px solid ${Color.Primary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-family: ${FontFamily.Primary};
`;

const cssDisabledOption = css`
  & > label {
    color: ${Color.GrayLight};
  }
  &:hover {
    background-color: ${Color.Transparent};
  }
`;

export const Option = styled(FlexRow)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: ${Spacing.Small};
  padding-bottom: ${Spacing.Small};
  padding-left: ${Spacing.Small};
  &:hover {
    background-color: ${Color.Secondary};
  }
  ${({ disabled }) => (disabled ? cssDisabledOption : null)};
`;

export const MarketOrganization = styled(Label)`
  padding: ${Spacing.Small} 0 ${Spacing.Small} ${Spacing.Small};
  background-color: ${Color.GrayLight};
`;
