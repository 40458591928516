import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { AdminStrings } from '../../admin.string';

export const resetUserPassword = createAsyncThunk(`${SLICE_NAME}/resetPassword`, async (oktaUid: string, thunkAPI) => {
  const strings = AdminStrings.pt.sendResetPassword;
  const state: any = thunkAPI.getState();
  const { token } = state.auth;

  return await AdminDataSource.getResetPassword(oktaUid, token)
    .then(() => {
      thunkAPI.dispatch(setToast({ show: true, text: strings.success, variant: ToastVariant.SUCCESS }));
    })
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: err.response.data.message, variant: ToastVariant.DANGER }));
    });
});

export const addResetPasswordReducers = builder => {
  builder.addCase(resetUserPassword.pending, state => {
    state.loadingResetPassword = true;
  });
  builder.addCase(resetUserPassword.fulfilled, state => {
    state.loadingResetPassword = false;
  });
  builder.addCase(resetUserPassword.rejected, state => {
    state.loadingResetPassword = false;
  });
};
