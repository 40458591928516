import React, { useState } from 'react';
import { IWindowDimension, useScreenSize } from '@app/utils/screen-resize.hook';
import { CustomizedTooltipProps, IDimensionLayout } from './customized-tooltip.interfaces';
import { CustomizedTooltipArrowStyled, CustomizedTooltipWrapper } from './customized-tooltip.style';
import { CustomizedTooltipBody } from './partials/customized-tooltip-body.component';
import { CustomizedTooltipFooter } from './partials/customized-tooltip-footer.component';
import { CustomizedTooltipHeader } from './partials/customized-tooltip-header.component';
import { getArrowXPosition, getTooltipY, getXAxis, isSameLayout } from './utils';

export const CustomizedTooltip: React.FC<CustomizedTooltipProps> = ({
  children,
  title = '',
  description,
  step,
  totalSteps,
  hasOffset,
  customHeight,
  customLeftAlign,
  customRightAlign,
  alignRight,
  alignBottom,
  positionYFromBottom,
  positionXFromRight,
  visible,
  onClick,
  onClose,
  hasFooter = true,
  hasHeader = true,
  customArrowX,
  customWidth,
  fontSize,
}) => {
  const [childrenLayout, setChildrenLayout] = useState<IDimensionLayout>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const windowDimensions: IWindowDimension = useScreenSize();
  const measureElement = (element: HTMLDivElement) => {
    const values = element?.getBoundingClientRect();
    const layout = { x: values?.x, y: values?.y, width: values?.width, height: values?.height };

    if (!isSameLayout(childrenLayout, layout)) {
      setChildrenLayout(layout);
    }
  };

  const xAxis = getXAxis(positionXFromRight, customRightAlign, windowDimensions, childrenLayout, hasOffset);
  const yAxis = getTooltipY(childrenLayout, hasOffset, customHeight);
  const xAxisArrow = customArrowX ? customArrowX : getArrowXPosition(windowDimensions, childrenLayout, hasOffset);
  const hasH3Font = !!description;

  return (
    <>
      <div ref={measureElement}>{children}</div>
      {visible && (
        <CustomizedTooltipWrapper
          hasExternalOffset={hasOffset}
          xAxis={xAxis}
          yAxis={yAxis}
          alignRight={alignRight}
          alignLeft={customLeftAlign}
          positionYFromBottom={positionYFromBottom}
          positionXFromRight={positionXFromRight}
          customWidth={customWidth}
          data-testid='tooltip-alert'
        >
          {hasHeader && <CustomizedTooltipHeader title={title} onClose={onClose} hasH3Font={hasH3Font} />}
          <CustomizedTooltipBody fontSize={fontSize} description={description} />
          {hasFooter && <CustomizedTooltipFooter step={step} totalSteps={totalSteps} onClick={onClick} />}
          <CustomizedTooltipArrowStyled xAxis={xAxisArrow} alignBottom={alignBottom} alignRight={alignRight} />
        </CustomizedTooltipWrapper>
      )}
    </>
  );
};
