export interface DashboardLegendData {
  title: string;
  amount?: any;
  unit?: string;
  iconColor?: string;
  currency?: string;
}

export interface OpenOrderGraphs {
  openOrderStatusGraph: OpenOrderStatusGraph;
  requestedVsConfirmedOrderGraph: RequestedVsConfirmedOrderGraph[];
  orderStatusGraph: OrderStatusGraph;
}

export interface OpenOrderStatusGraph {
  confirmed: number;
  requested: number;
  billed: number;
  pendingBilling: number;
}

export interface RequestedVsConfirmedOrderGraph {
  date: string;
  confirmed: number;
  requested: number;
}
export interface RequestedOrderGraph {
  date: string;
  requested: number;
}

export interface ConfirmedOrderGraph {
  date: string;
  confirmed: number;
}

export interface OrderStatusGraph {
  deployed: number;
  onSchedule: number;
  confirmed: number;
  total: number;
}

export interface OrderStatusDeployedGraph {
  deployed: number;
}
export interface OrderStatusConfirmedGraph {
  confirmed: number;
}
export interface OrderStatusOnScheduleGraph {
  onSchedule: number;
}

export interface ConfirmedOrderGraphs {
  monthlyDeliveryForecast: MonthlyDeliveryForecast;
  confirmedDeliveryByStatus: ConfirmedDeliveryByStatus;
}

export interface MonthlyDeliveryForecast {
  total: number;
  months: MonthlyDeliveryForecastMonth[];
}

export interface MonthlyDeliveryForecastMonth {
  date: string;
  billed: number;
  pendingBilling: number;
}

export interface ConfirmedDeliveryByStatus {
  valueByStatus: {
    delayed: number;
    onTime: number;
    noForecast: number;
    delayTrend: number;
  };
  total: number;
}

export interface BilledOrderGraph {
  daily: BilledGraphData[];
  monthly: BilledGraphData[];
}

export interface BilledGraphData {
  date: string;
  billedQty: number;
  monthName?: string;
}

export interface StockOrderGraphs {
  stockStep: StockStepGraph;
  stockStatus: StockStatusGraph;
}

export interface StockStepGraph {
  inStock: number;
  loading: number;
  shipping: number;
  total: number;
}

export interface StockStatusGraph {
  expired: number;
  onTime: number;
  total: number;
}

export interface FinanceMatchedMatchesGraphs {
  matchedMatches: MatchedMatch[];
}

export interface MatchedMatch {
  compensationMonthDate: string;
  documentMonthNumber: number;
  generalTotalMonth: number;
}

export interface FinanceOpenMatchesGraphs {
  openMatches: OpenMatch[];
}

export interface OpenMatch {
  expiredGeneralTotal: number;
  notExpiredGeneralTotal: number;
  expiredDocumentNumbers: number;
  notExpiredDocumentNumbers: number;
  expiredStatus: string;
  notExpiredStatus: string;
}

export const CollapsedMonthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

export enum DashboardGraphName {
  MonthlyDeliveryForecastGraph = 'monthlyDeliveryForecastGraph',
  ConfirmedDeliveryGraph = 'confirmedDeliveryGraph',
  StockStepGraph = 'stockStepGraph',
  StockStatusGraph = 'stockStatusGraph',
  MonthlyFinanceMatchedMatchesGraph = 'monthlyFinanceMatchedMatchesGraph',
  OpenMatchesGraph = 'openMatchesGraph',
  OpenMatchesDocumentGraph = 'openMatchesDocumentGraph',
  requestedVsConfirmedOrderGraph = 'requestedVsConfirmedOrderGraph',
}
