import * as React from 'react';
import { parseOrderDate } from '@app/data/http/order-date.mapper';
import { Button } from '@atomic/atm.button';
import { Frame } from '@atomic/atm.frame';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontWeight, Spacing } from '@atomic/obj.constants';
import { CSATModule, CSATModuleType } from './admin-settings.modules';
import { Strings, IAdminSettingsStrings } from './admin-settings.string';

interface AdminSettingsCardsProps {
  csatModule: CSATModule;
  csatLastResetDate: string;
  type: CSATModuleType;
  onClick?: () => void;
}

export const AdminSettingsCards: React.FC<AdminSettingsCardsProps> = ({
  csatModule,
  csatLastResetDate,
  type,
  onClick,
}) => {
  const strings: IAdminSettingsStrings = Strings.pt;

  const modulesSession = Object.entries(strings[type]);
  const csatModuleFinded = modulesSession.find(module => module[0] === csatModule.toString());
  const text = csatModuleFinded?.length === 2 ? csatModuleFinded[1] : '';
  const date = parseOrderDate(csatLastResetDate);
  let lastUpdate = '';

  if (csatLastResetDate) {
    lastUpdate = `${strings.lastUpdate} ${date}`;
  }

  return (
    <Frame padded>
      <FlexRow>
        <FlexColumn vAlign='center'>
          <Body bold={FontWeight.Bold}>{text}</Body>
          <Body mt={Spacing.XSmall}>{lastUpdate}</Body>
        </FlexColumn>
        <FlexColumn noGrow>
          <Button onClick={onClick}>{strings.reset}</Button>
        </FlexColumn>
      </FlexRow>
    </Frame>
  );
};
