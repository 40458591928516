import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IShippingRequestRow } from '@app/modules/shipping-request/shipping-request.interfaces';
import { IShippingRequestStrings, Strings } from '@app/modules/shipping-request/shipping-request.string';
import { updateObservations } from '@app/modules/shipping-request/store/shipping-request.store';
import { ObsFieldStyled } from './shipping-request-obs-field.style';

interface StockTableObsFieldProps {
  item: IShippingRequestRow;
  hasSuggestion?: boolean;
}

export const ShippingRequestObsField: React.FC<StockTableObsFieldProps> = ({ item, hasSuggestion }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [text, setText] = useState('');
  const strings: IShippingRequestStrings = Strings[userInfo.language];

  const handleObsChanged = value => {
    if (value.length <= 140) {
      setText(value);
    }
  };

  const onBlur = () => {
    dispatch(updateObservations({ heat: item.heat, salesOrder: item.salesOrder, value: text }));
  };

  return (
    <ObsFieldStyled
      placeholder={strings.page.placeholderObs}
      onValueChange={value => handleObsChanged(value)}
      onBlur={onBlur}
      value={text}
      disabled={hasSuggestion}
    />
  );
};
