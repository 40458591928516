import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { LayoutModel, LayoutTabs } from '@app/models/layout.model';
import { dateComparator } from '@app/utils/date-utils';

export interface LayoutDto {
  tabs: TabLayoutDto[];
}

export interface TabLayoutDto {
  tab: string;
  columns: ColumnLayoutDto[];
}

export interface ColumnLayoutDto {
  field: string;
  headerName: string;
  headerTooltip: string;
  pinned?: boolean | 'left' | 'right';
  filter?: string | boolean;
  width?: number;
  cellRenderer?: string;
  hide?: boolean;
  checkboxSelection?: boolean;
  headerCheckboxSelection?: boolean;
  headerCheckboxSelectionFilteredOnly?: boolean;
  filterValueGetter?: ((params: ValueGetterParams) => any) | string;
}

export const dateFields = [
  'dateSolClient',
  'dateDeliveryClient',
  'dateMaxDeliveryClient',
  'customerRequiredDate',
  'confirmedShippingDate',
  'customerAgreedDate',
  'purchaseDate',
  'dateDeliveryClient',
  'invoiceDate',
  'dateOfDeparture',
  'dtCustomerConfirmed',
  'dtCustomerRequired',
  'dtEstimateBorder',
  'dtEstimateStock',
  'dtPurchase',
  'billingDocumentDate',
  'nfDate',
  'dtConfirmedStock',
  'dtCustomerRequiredOriginal',
  'dtEstimatedAtCustomer',
  'locationDestinationDate',
  'dateOfStockEntry',
  'shipmentDate',
  'etd',
  'eta',
  'etc',
  'dtPurchase',
];

export const mapLayoutResponseToLayoutData = (response: LayoutDto): LayoutModel => {
  return {
    tabs: response.tabs.map(tab => {
      tab.columns.forEach(column => configureColumn(column, tab.tab));

      return {
        name: tab.tab,
        cols: [...tab.columns],
      };
    }),
  };
};

export const configureColumn = (column: ColDef, tab: string) => {
  const linkFields = [
    'truck',
    'nf',
    'certificate',
    'waybill',
    'activateShipping',
    'activateNotificationCol',
    'orderStatus',
    'deliveryStatus',
    'salesOrderStatus',
  ];

  column.filter = 'agTextColumnFilter';

  if (linkFields.includes(column.field)) {
    column.filter = false;
  }

  if (
    (column.field === 'status' && tab !== LayoutTabs.BilledMI) ||
    (column.field === 'salesOrderStatus' && tab !== LayoutTabs.StockMI) ||
    (column.field === 'deliveryStatus' && tab === LayoutTabs.OpenMI)
  ) {
    column.filter = true;
  }

  if (column.field === 'checkboxSelection') {
    column.headerCheckboxSelection = true;
    column.headerCheckboxSelectionFilteredOnly = true;
    column.pinned = true;
    column.filter = false;
  }
};

export const mapColsToColsDto = (columns: ColDef[]): ColumnLayoutDto[] => {
  return columns.map(column => ({
    field: column.field,
    headerName: column.headerName,
    headerTooltip: column.headerTooltip,
    pinned: !!column.pinned as boolean,
    filter: !!column.filter,
    width: column.width,
    cellRenderer: column.cellRenderer as string,
    hide: column.hide,
    checkboxSelection: column.checkboxSelection as boolean,
    sort: column.sort,
    sortIndex: column.sortIndex,
  }));
};

export const dateFormatter = params => {
  const dateAsString = params.value;

  if (dateAsString && dateAsString.indexOf('/') > -1) {
    const dateParts = dateAsString.split('/');
    return params.value && `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`;
  } else if (dateAsString && dateAsString.indexOf('-') > -1) {
    const dateParts = dateAsString.split('-');
    return params.value && `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  } else {
    return dateAsString;
  }
};

export const dateFormatterUS = params => {
  let dateAsString = params.value;

  if (dateAsString && dateAsString.indexOf('/') > -1) {
    const dateParts = dateAsString.split('/');
    dateAsString = params.value && `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
  } else if (dateAsString && dateAsString.indexOf('-') > -1) {
    const dateParts = dateAsString.split('-');
    dateAsString = params.value && `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
  }

  return dateAsString;
};

export const mapDateColumns = (column: ColDef, language: string) => {
  column.comparator = (date1, date2) => dateComparator(date1, date2, language);
  column.valueFormatter = params => (language === 'in' ? dateFormatterUS(params) : dateFormatter(params));
};
