import * as React from 'react';
import { QuoteAttributes } from '@app/models/quote.model';
import { statusInfo } from '@app/modules/quote/quote.contants';
import { Frame } from '@atomic/atm.frame';
import { Body } from '@atomic/atm.typography';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { LoadingState } from '@atomic/obj.loading-state';
import { TechnicalAnalysisDerogateAttributeWrapperStyled, TitleDerogateListWrapper } from './technical-analysis.style';

interface TechnicalAnalysisTabProps {
  title: string;
  onHandleAttribute: any;
  recurrentProduct?: boolean;
  editedAttributes?: string[];
  selectedAttribute: QuoteAttributes;
  loading: boolean;
  list?: QuoteAttributes[];
}

export const TechnicalAnalysisDerogateAttributesList: React.FC<TechnicalAnalysisTabProps> = props => {
  return (
    <LoadingState loading={props.loading}>
      <TitleDerogateListWrapper>
        <Body>{props.title}</Body>
      </TitleDerogateListWrapper>
      <Frame>
        <TechnicalAnalysisDerogateAttributeWrapperStyled id='list-derogate'>
          {props.list.map(attribute => {
            return (
              <DefaultListCell
                list='derogate'
                key={`d-${attribute.key}`}
                hover
                selected={attribute.key === props.selectedAttribute?.key}
                arrow
                iconName={!props.recurrentProduct && statusInfo[attribute.status].icon}
                iconColor={statusInfo[attribute.status].color}
                onClick={() => props.onHandleAttribute(attribute)}
                bg={false}
              >
                <Body>
                  {`${attribute.label}${
                    attribute.creation === 'GERDAU' && !props.editedAttributes?.includes(attribute.key) ? ' *' : ''
                  }`}
                </Body>
              </DefaultListCell>
            );
          })}
        </TechnicalAnalysisDerogateAttributeWrapperStyled>
      </Frame>
    </LoadingState>
  );
};
