import React from 'react';
import { Image } from '@atomic/atm.image';
import { ShortHeaderLimit } from '@atomic/obj.constants';
import { LogoHeader } from '@atomic/org.header/header.component.style';

interface MainLogoHeaderProps {
  shortHeader: boolean;
}

export const MainLogoHeader: React.FC<MainLogoHeaderProps> = ({ shortHeader }) => {
  const windowWitdh = document.body.clientWidth;

  return (
    <LogoHeader shortHeader={shortHeader}>
      {shortHeader || windowWitdh <= ShortHeaderLimit.limitMobile ? <Image.LogoHorizontal /> : <Image.LogoVertical />}
    </LogoHeader>
  );
};
