import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { HelpCenterSubsectionKeys } from '@app/models/help-center.model';
import { AdvancedFiltersButton } from '@app/modules/components/advanced-filters-button/advanced-filters-button.component';
import { FlashWrapperStatic } from '@app/modules/components/flash-wrapper-static/flash-wrapper-static.component';
import { OrderTabGrid } from '@app/modules/order/order-tab-grid.component';
import { Button } from '@atomic/atm.button';
import { DatePicker } from '@atomic/atm.date-picker';
import { InputLabel } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Grid } from '@atomic/obj.grid';
import { OrderTabsActionsMI } from '../order-tabs-actions-MI.component';
import { CertificateCellComponent } from './cell-renderer/certificate-cell';
import { NFCellComponent } from './cell-renderer/nf-cell';
import { TruckTrackingCellComponent } from './cell-renderer/truck-tracking-cell';
import { WayBillCellComponent } from './cell-renderer/way-bill-cell';
import { tabName } from './order-tab-billed.constants';
import { IOrderTabBilledStrings, Strings } from './order-tab-billed.string';
import { DatePickerWrapper } from './order-tab-billed.style';
import { filteredDataSelector } from './store/order-tab-billed.selectors';
import { setEndDate, setStartDate, setFilteredRows } from './store/order-tab-billed.store';
import { performRequest } from './store/order-tab-billed.thunks';
import { handleEndDateChange, handleStartDateChange } from './utils/handler-utils';

interface OrderTabBilledComponentProps {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
}

export const OrderTabBilledComponent: React.FC<OrderTabBilledComponentProps> = ({ gridEvent }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabBilledStrings = Strings[userInfo.language];
  const { data, loading, error, startDate, endDate } = useSelector((state: RootState) => state.orderTabBilled);
  const filteredData = useSelector((state: RootState) => filteredDataSelector(state, data));
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleStartDateChangeConfigured = (date: Date): void =>
    handleStartDateChange({
      date,
      endDate: new Date(endDate),
      setStartDate: value => dispatch(setStartDate(value)),
      setEndDate: value => dispatch(setEndDate(value)),
    });
  const handleEndDateChangeConfigured = (date: Date): void =>
    handleEndDateChange({ date, setEndDate: value => dispatch(setEndDate(value)) });

  return (
    <>
      <FlashWrapperStatic opened={!!error} type='alert' content={strings.error} />
      <Grid fluid>
        <OrderTabsActionsMI
          tabName={tabName}
          refresh={() => dispatch(performRequest())}
          gridEvent={gridEvent}
          helpCenterSubsection={HelpCenterSubsectionKeys.BilledOrdersGlossary}
          filename={strings.filename}
        >
          <FlexRow vAlign='center'>
            <AdvancedFiltersButton />
            <FlexColumn vAlign='center' ml noGrow>
              <InputLabel>{strings.from}</InputLabel>
            </FlexColumn>
            <Hbox.Separator />
            <DatePickerWrapper noGrow>
              <DatePicker startDate={new Date(startDate)} onValueChange={handleStartDateChangeConfigured} />
            </DatePickerWrapper>
            <Hbox.Separator />
            <FlexColumn vAlign='center' noGrow>
              <InputLabel>{strings.to}</InputLabel>
            </FlexColumn>
            <Hbox.Separator />
            <DatePickerWrapper noGrow>
              <DatePicker
                minDate={new Date(startDate)}
                startDate={new Date(endDate)}
                onValueChange={handleEndDateChangeConfigured}
              />
            </DatePickerWrapper>
            <Hbox.Separator />
            <FlexColumn noGrow>
              <Button kind='primary' onClick={() => dispatch(performRequest())}>
                {strings.search}
              </Button>
            </FlexColumn>
          </FlexRow>
        </OrderTabsActionsMI>
        <OrderTabGrid
          gridEvent={gridEvent}
          performRequest={performRequest}
          setFilteredRows={setFilteredRows}
          strings={strings}
          data={filteredData}
          loading={loading}
          error={error}
          tabName={tabName}
          frameworkComponents={{
            nfCellRenderer: NFCellComponent,
            certificateCellRenderer: CertificateCellComponent,
            waybillCellRenderer: WayBillCellComponent,
            truckCellRenderer: TruckTrackingCellComponent,
          }}
        />
      </Grid>
    </>
  );
};
