import { IEmailSendingFailureModalState } from '../email-sending-failure-modal.interfaces';
import { initialState } from './email-sending-failure-modal.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setShowEmailSendingFailureModal: (state: IEmailSendingFailureModalState, { payload }) => {
    state.visited = payload.visited;
    state.date = payload.date;
  },
};
