import * as React from 'react';
import { ProgressBarWrapperStyled, ProgressBarWrapperStyledProps } from './progress-bar.component.style';

// tslint:disable-next-line:no-empty-interface
type ProgressBarProps = ProgressBarWrapperStyledProps;

export const ProgressBar: React.FC<ProgressBarProps> = props => {
  const [percentage, setPercentage] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => setPercentage(props.percentage), 0);
  }, [props.percentage]);

  return <ProgressBarWrapperStyled percentage={percentage} />;
};
