import styled from 'styled-components';
import { Breakpoint, Spacing } from '@atomic/obj.constants';
import { SearchBarProps } from '../../../../app-header.interfaces';

export const ContainerCompanyLogosWrapper = styled.div`
  display: flex;
  align-self: ${(props: SearchBarProps) => (props.shortHeader ? 'center' : 'flex-start')};
  height: ${(props: SearchBarProps) => (props.shortHeader ? '60px' : '61px')};
  width: auto;
  & > a {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-right: ${Spacing.Small};
    padding-left: ${Spacing.Medium};

    & > img {
      margin: 0 ${Spacing.Small};
      width: ${(props: SearchBarProps) => (props.shortHeader ? '40px' : '52px')};
    }
  }
  @media all and (max-width: ${Breakpoint.lg}em) {
    display: none;
  }
`;
