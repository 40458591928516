import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';

export const MenuQuoteWrapper = styled.div`
  ul > li {
    margin-left: 0px !important;
    & > a {
      text-align: center;
      padding: 22px 16px;
      & a {
        color: ${Color.GrayXDark};
      }
    }
  }
`;
