import { format } from 'date-fns';

export const isExpiredTruckTrackingDate = ({ baseDate, today }: { baseDate: Date; today: Date }): boolean => {
  const todayLess5Days = today;
  todayLess5Days.setDate(today.getDate() - 5);

  if (baseDate <= todayLess5Days) {
    return true;
  }
  return false;
};

export const extractDateFromLastUpdate = (lastUpdate: string): Date => {
  if (lastUpdate) {
    const year: string = lastUpdate.substring(0, 4);
    const month: string = lastUpdate.substring(5, 7);
    const day: string = lastUpdate.substring(8, 10);
    const hour: string = lastUpdate.substring(11, 13);
    const minutes: string = lastUpdate.substring(14, 16);
    const seconds: string = lastUpdate.substring(17, 19);

    const intYear: number = parseInt(year);
    const intMonth: number = parseInt(month) - 1;
    const intDay: number = parseInt(day);
    const intHour: number = parseInt(hour);
    const intMinutes: number = parseInt(minutes);
    const intSeconds: number = parseInt(seconds);

    const dateExtracketed: Date = new Date(intYear, intMonth, intDay, intHour, intMinutes, intSeconds);

    return dateExtracketed;
  }
  return null;
};

export const formatInvoiceDate = (invoiceDate: string) => {
  if (!invoiceDate) {
    return null;
  }

  const date = new Date(invoiceDate);

  return format(date, 'dd/MM/yyyy') + invoiceDate.substring(10);
};
