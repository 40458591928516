import { HelpCenterSubsectionKeys, HelpCenterSectionModel, HelpCenterSectionKeys } from '@app/models/help-center.model';

export const HelpCenterAdminSection = (language = 'pt'): HelpCenterSectionModel => {
  return (
    language === 'pt' && {
      title: 'Gerenciador de usuários',
      key: HelpCenterSectionKeys.Admin,
      subsections: [
        {
          title: 'Adicionar um usuário',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.AdminAddUser,
          informationBlocks: [
            {
              blockTitle: 'Passo a passo',
              paragraph:
                '1. Para acessar o Gerenciador de usuários clique no icone de engrenagem posicionado no canto superior direito do Gerdau Mais e clique em "Gerenciador de usuários".',
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/user-manager/add-user/add-user-01.png',
              },
            },
            {
              paragraph: '2. Para adicionar uma nova pessoa da organização, basta clicar no botão "Adicionar usuário"',
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/user-manager/add-user/add-user-02.png',
              },
            },
            {
              paragraph:
                'Ao clicar nessa opção, você irá adicionar um usuário da sua empresa, preencha as opções como:',
              paragraphList: [
                'Nome completo',
                'E-mail',
                'Idioma de preferência',
                'Telefone (opcional)',
                'Permissionamento',
              ],
            },
            {
              paragraph:
                'Na seção "Selecionar permissão" você irá selecionar a opção que mais se adequa ao usuário que você está a adicionar:',
              paragraphList: [
                'Gestor cliente: essa opção permite que o usuário tenha acesso a todo o Gerdau Mais visualizando todas as suas filiais. Além disso, o gestor cliente visualiza e concede o acesso ao Gerdau Mais para outras pessoas de todas as suas filiais.',
                'Gestor filial: essa opção permite que o usuário tenha acesso a todo o Gerdau Mais visualizando todas filiais as quais tem acesso. Além disso, o gestor filial visualiza e concede acesso ao Gerdau Mais a outras pessoas de suas filiais.',
                'Cliente comum: essa opção permite que o usuário tenha acesso ao Gerdau Mais de filiais as quais tem permissão. O cliente comum não consegue acessar o Gerenciador de usuários.',
              ],
              media: {
                type: 'centered-image',
                path: 'assets/img/help-center/user-manager/add-user/add-user-03.png',
              },
            },
            {
              paragraph:
                'Você como Gestor cliente ou Gestor filial pode configurar a visualização do Cliente comum nos seguintes aspectos:',
              paragraphList: ['Acesso a aba Carteira', 'Acesso a aba Finanças', 'Acesso a aba Cotação'],
            },
            {
              paragraph:
                'Na seção "Selecionar Empresa" você pode fazer uma busca por "CNPJ", "nome de empresa" ou "grupo rentabilidade", selecionar a empresa desejada e clicar em "Aplicar".',
              media: {
                type: 'centered-image',
                path: 'assets/img/help-center/user-manager/add-user/add-user-04.png',
              },
            },
            {
              paragraph: 'Caso queira apagar a sua seleção basta clicar no "X" ao lado da opção como mostra a imagem.',
              media: {
                type: 'centered-image',
                path: 'assets/img/help-center/user-manager/add-user/add-user-05.png',
              },
            },
            {
              paragraph:
                'Para finalizar a sua edição, basta clicar em "Salvar". Pronto agora o usuário vai receber um e-mail com o remetente no-reply@gerdau.com.br. com as instruções para criação da senha para o acesso.',
            },
          ],
        },
        {
          title: 'Editar um usuário',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.AdminEditUser,
          informationBlocks: [
            {
              blockTitle: 'Passo a passo',
              paragraph:
                'Se o usuário cadastrado no Gerenciador trocou de posição você, como Gestor cliente, pode editar o acesso dele.',
              paragraphList: [
                'Pesquisar por pessoa',
                'Clicar no Grupo rentabilidade desejado',
                'Localizar o nome da pessoa na lista',
                'Clicar no botão "Editar"',
              ],
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/user-manager/edit-user/edit-user-01.png',
              },
            },
            {
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/user-manager/edit-user/edit-user-02.png',
              },
            },
            {
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/user-manager/edit-user/edit-user-03.png',
              },
            },
          ],
        },
        {
          title: 'Apagar um usuário',
          hasComingSoonTag: false,
          key: HelpCenterSubsectionKeys.AdminDeleteUser,
          informationBlocks: [
            {
              blockTitle: 'Passo a passo',
              paragraph:
                'Se o usuário cadastrado no Gerenciador de usuários saiu da empresa ou trocou de posição você, como Gestor cliente, pode apagar o acesso dele.',
              paragraphList: [
                'Pesquisar por pessoa',
                'Clicar no Grupo rentabilidade desejado',
                'Clicar em "Ver perfil"',
                'Clicar no botão "Apagar usuário"',
              ],
              media: {
                type: 'stretch-image',
                path: 'assets/img/help-center/user-manager/delete-user/delete-user-01.jpeg',
              },
            },
          ],
        },
      ],
    }
  );
};
