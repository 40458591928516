import React, { useRef } from 'react';
import { FlexRow } from '@atomic/obj.box';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { CustomizedPopoverArrowStyled, CustomizedPopoverBoxStyled, CustomizedPopoverWrapper } from './popover.style';

interface PopoverProps {
  component: JSX.Element;
  x: number;
  y: number;
  onClose: () => void;
  backgroundColor: string;
  arrowLeft: number;
}

export const Popover: React.FC<PopoverProps> = ({ component, x, y, onClose, backgroundColor, arrowLeft }) => {
  const ref = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    const isOutsideClick = ref && !ref.current.contains(event.target);
    if (isOutsideClick) {
      onClose();
    }
  };

  useEventListener('click', handleClickOutside);

  return (
    <CustomizedPopoverWrapper ref={ref} x={x} y={y}>
      <CustomizedPopoverBoxStyled backgroundColor={backgroundColor}>
        <FlexRow>{component}</FlexRow>
        <CustomizedPopoverArrowStyled alignRight arrowLeft={arrowLeft} backgroundColor={backgroundColor} />
      </CustomizedPopoverBoxStyled>
    </CustomizedPopoverWrapper>
  );
};
