import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';

export const SidebarFilterBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${Spacing.Large};
  height: ${({ bodyHeightDiscount }) => `calc(100vh - ${bodyHeightDiscount}px)`};
  padding: ${Spacing.Medium} 0;
  overflow-y: auto;
  padding-bottom: ${Spacing.XXLarge};
`;
