import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { FlashWrapperStatic } from '@app/modules/components/flash-wrapper-static/flash-wrapper-static.component';
import { Grid } from '@atomic/obj.grid';
import { OrderTabGrid } from '../../../../order-tab-grid.component';
import { OrderTabsActionsME } from '../../order-tabs-actions-ME.component';
import { tabName } from './order-tab-frontier.constants';
import { performRequest, setFilteredRows } from './order-tab-frontier.store';
import { IOrderTabFrontierStrings, Strings } from './order-tab-frontier.string';

interface OrderTabFrontierComponentProps {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
}

export const OrderTabFrontierComponent: React.FC<OrderTabFrontierComponentProps> = ({ gridEvent }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabFrontierStrings = Strings[userInfo.language];

  const { data, loading, error } = useSelector((state: RootState) => state.orderTabFrontier);
  const dispatch = useDispatch<AppThunkDispatch>();

  return (
    <Grid fluid>
      <FlashWrapperStatic opened={!!error} type='alert' content={strings.error} />
      <OrderTabsActionsME
        tabName={tabName}
        refresh={() => dispatch(performRequest())}
        gridEvent={gridEvent}
        filename={strings.filename}
      />
      <OrderTabGrid
        gridEvent={gridEvent}
        performRequest={performRequest}
        setFilteredRows={setFilteredRows}
        strings={strings}
        data={data}
        loading={loading}
        error={error}
        tabName={tabName}
      />
    </Grid>
  );
};
