import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { FlexRow } from '@atomic/obj.box';
import { ICustomerSearchModalHeaderAdminStrings, Strings } from '../../../customer-search-modal-header-admin.string';
import { SearchFieldWrapper } from '../search-field.style';

export const SearchFieldInitial: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ICustomerSearchModalHeaderAdminStrings = Strings[userInfo.language];

  return (
    <SearchFieldWrapper>
      <FlexRow>
        <TextField readOnly placeholder={strings.defaultPlaceholder} hasButton>
          <Button kind='link'>
            <FaIcon.Search size='1x' />
          </Button>
        </TextField>
      </FlexRow>
    </SearchFieldWrapper>
  );
};
