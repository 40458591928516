import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputLabel } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { TagContentStyled, TagContentStyledProps, TagGroupStyled, TagWrapperStyled } from './tag.component.style';

interface TagProps extends TagContentStyledProps {
  selected?: boolean;
  disabled?: boolean;
  showCheck?: boolean;
  onRemove?: () => void;
  onClick?: () => void;
  label?: string;
  type?: 'success' | 'alert' | 'analysis' | 'accepted' | 'other';
  hide?: boolean;
}

export const Tag: React.FC<TagProps> = props => {
  return (
    <TagWrapperStyled onClick={!props.disabled ? props.onClick : null} ml={!!props.label} hide={props.hide}>
      <TagContentStyled type={props.type ? props.type : 'success'} selected={props.selected}>
        {props.selected && props.showCheck && <FaIcon.Check size='1x' />}
        <Hbox.Separator small />
        {props.children}
        <Hbox.Separator small />
        {!!props.onRemove && <FaIcon.Close onClick={props.onRemove} size='1x' />}
      </TagContentStyled>
      {props.label && <InputLabel>{props.label}</InputLabel>}
    </TagWrapperStyled>
  );
};

export const TagGroup = TagGroupStyled;
