import React from 'react';
import { formatTime } from '@app/modules/components/schedule-modal/utils';
import { TimeSelectOption, TimeSelectOptionsWrapper } from './time-select.style';

interface TimeSelectOptionsProps {
  onChange: (value: string) => void;
}

export const TimeSelectOptions: React.FC<TimeSelectOptionsProps> = ({ onChange }) => {
  const times: string[] = [];
  for (let index = 0; index < 24; index++) {
    const item = formatTime(index);
    times.push(item);
  }
  return (
    <TimeSelectOptionsWrapper>
      {times.map(time => {
        return (
          <TimeSelectOption onClick={() => onChange(time)} key={time}>
            {time}
          </TimeSelectOption>
        );
      })}
    </TimeSelectOptionsWrapper>
  );
};
