import { IShippingRequestRowParams } from '@app/data/http/shipping-request.dto';
import { InStockOrderItem } from '@app/models/sales-order-item.model';
import { formatQtyField } from '@app/utils/string-utils';
import {
  BP_SHIPPING_REQUEST_FIELD_STRING,
  BP_STOCK_FIELD_STRING,
  MATERIAL_FIELD_STRING,
  tableColumnsDefinitions,
  tableColumnsDefinitionsEaton,
  tableColumnsDefinitionsGKN,
  TableColumnsDefinitionsType,
} from './shipping-request.constants';
import {
  IBPGroup,
  IMaterialGroupInStockOrderItem,
  IMaterialGroupShippingRequestRow,
  IPlant,
  IShippingRequestColumns,
  IShippingRequestRow,
} from './shipping-request.interfaces';

export const filterSelectedRow = (selectedRow: InStockOrderItem[], plantName: string) => {
  return selectedRow.filter(row => row.plant === plantName);
};

export const getStockQuantityTotal = (rows: IShippingRequestRow[]): number => {
  return rows.reduce((sum, row) => sum + row.stockQuantity, 0);
};

export const getSummaryStockQuantityTotal = (rows: IShippingRequestRow[]): number => {
  return rows.reduce((sum, row) => sum + (row.stockQuantity - row.shipmentQuantity), 0);
};

export const mapShippingRequestTableRows = (
  selectedRows: InStockOrderItem[],
  isGKN: boolean,
  tableRows?: IShippingRequestRow[],
): IShippingRequestRow[] => {
  return selectedRows?.map((row: InStockOrderItem, index: number) => {
    const existentRow = tableRows.find(
      tableRow => tableRow.salesOrder === row.salesDocument && tableRow.material === row.material,
    );

    return {
      index,
      customerId: completeZeroReceiver(row.soldToParty),
      material: row.material,
      materialDescription: row.materialName,
      plantCode: row.plant,
      plantName: row.plantName,
      salesOrder: row.salesDocument,
      salesOrderItem: row.salesDocumentItem,
      purchaseOrder: row.customerPurchaseOrderNumber.toString(),
      customerProductCode: row.customerMaterialCode.toString(),
      stockQuantity: isGKN
        ? subtractShipmentQuantityAndPickingQuantity(row.stockQuantity, row.deliveryQuantity, row.pickingQuantity)
        : row.stockQuantity,
      shipmentQuantity: row.deliveryQuantity,
      batch: row.batch,
      receiver: completeZeroReceiver(row.soldToParty),
      newReceiver: null,
      observations: '',
      requestedShippingQuantity: existentRow ? existentRow.requestedShippingQuantity : 0,
      heat: row.heat,
      lastHeat: row.lastHeat,
      breakHeat: row.breakHeat,
      hasQuantityLastHeat: row.hasQuantityLastHeat,
      qtyStockLastHeat: row.qtyStockLastHeat,
      customerAgreedDate: row.customerAgreedDate,
    };
  });
};

export const subtractShipmentQuantityAndPickingQuantity = (
  stockQuantity: number,
  deliveryQuantity: number,
  pickingQuantity: number,
): number => {
  const total = stockQuantity - deliveryQuantity - pickingQuantity;
  return total;
};

export const completeZeroReceiver = (receiver: string) => {
  if (receiver.length >= 10) {
    return receiver;
  }

  return completeZeroReceiver('0' + receiver);
};

export const mapTableRowsToParams = (tableRows: IShippingRequestRow[]): IShippingRequestRowParams[] => {
  return tableRows.map((row: IShippingRequestRow) => ({
    customerId: row.customerId,
    receiver: row.newReceiver || row.receiver,
    material: row.material,
    materialDescription: row.materialDescription,
    plantCode: row.plantCode,
    plantName: row.plantName,
    salesOrder: row.salesOrder,
    salesOrderItem: row.salesOrderItem,
    purchaseOrder: row.purchaseOrder,
    customerProductCode: row.customerProductCode,
    stockQuantity: formatQtyField(row.stockQuantity),
    batch: row.batch,
    observations: row.observations,
    shipmentQuantity: formatQtyField(row.shipmentQuantity),
    heat: row.heat,
    lastHeat: row.lastHeat,
    breakHeat: row.breakHeat,
    hasQuantityLastHeat: row.hasQuantityLastHeat,
    confirmedMaterialDate: row.customerAgreedDate ? row.customerAgreedDate : '',
    lastHeatQuantity: row.qtyStockLastHeat ? row.qtyStockLastHeat.toString().replace('.', ',') : null,
    requestedShippingQuantity: !row.requestedShippingQuantity
      ? formatQtyField(row.stockQuantity)
      : formatQtyField(row.requestedShippingQuantity),
  }));
};

export const getTableColumnsDefinitions = (isGKN: boolean, isEaton: boolean): IShippingRequestColumns[] => {
  let definitionType = TableColumnsDefinitionsType.Default;

  if (isGKN) {
    definitionType = TableColumnsDefinitionsType.GKN;
  } else if (isEaton) {
    definitionType = TableColumnsDefinitionsType.Eaton;
  }

  switch (definitionType) {
    case TableColumnsDefinitionsType.GKN:
      return tableColumnsDefinitionsGKN;
    case TableColumnsDefinitionsType.Eaton:
      return tableColumnsDefinitionsEaton;
    default:
      return tableColumnsDefinitions;
  }
};

export const getOldestSuggestions = (selectedRows: InStockOrderItem[]) => {
  const mappedSuggestionOptions = mapShippingRequestTableRows(selectedRows, false, []);

  return mappedSuggestionOptions.sort(
    (a, b) =>
      new Date(handleDate(a.customerAgreedDate)).getTime() - new Date(handleDate(b.customerAgreedDate)).getTime(),
  );
};

const handleDate = (dateString: string): Date => {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}/${month}/${day}`);
};

const hasAlready = (filteredSelectedGroups: any[], material: string) => {
  let hasArrayAlready = false;

  if (filteredSelectedGroups.length > 0) {
    filteredSelectedGroups.forEach(materialItem => {
      if (materialItem.material === material) {
        hasArrayAlready = true;
      }
    });
  }

  return hasArrayAlready;
};

export const getBPGroupFromSelectedRows = (groupedSalesOrder: any, selectedRows: IShippingRequestRow[]): IBPGroup[] => {
  const bpGroups: IBPGroup[] = [];

  selectedRows.forEach(selectedItem => {
    if (groupedSalesOrder.hasOwnProperty(parseInt(selectedItem.customerId))) {
      for (const key of Object.entries(groupedSalesOrder)) {
        const bpKeyValue = groupedSalesOrder[key[0]];

        if (selectedItem.customerId.indexOf(key[0]) > -1) {
          if (bpKeyValue.hasOwnProperty(parseInt(selectedItem.material))) {
            for (const secondKey of Object.entries(bpKeyValue)) {
              const materialKey = secondKey[0];

              if (selectedItem.material.indexOf(materialKey) > -1) {
                const materialKeyValue = bpKeyValue[secondKey[0]];

                const materialGroupInStockOrderItem: IMaterialGroupInStockOrderItem = {
                  material: selectedItem.material,
                  ovs: materialKeyValue,
                };

                const createdBPGroup = bpGroups.find(group => group.bp === selectedItem.customerId);

                if (createdBPGroup) {
                  const createdStockMaterialGroup = createdBPGroup.orderedMaterialStockGroup.find(
                    stockGroup => stockGroup.material === materialKey,
                  );

                  if (!createdStockMaterialGroup) {
                    bpGroups
                      .find(group => group.bp === selectedItem.customerId)
                      .orderedMaterialStockGroup.push(materialGroupInStockOrderItem);
                  }
                } else {
                  const bpGroup: IBPGroup = {
                    bp: selectedItem.customerId,
                    orderedMaterialStockGroup: [],
                    orderedShippingMaterialUserGroup: [],
                  };
                  bpGroup.orderedMaterialStockGroup.push(materialGroupInStockOrderItem);
                  bpGroups.push(bpGroup);
                }
              }
            }
          }
        }
      }
    }
  });

  return bpGroups;
};

export const getFilteredSelectedGroups = (
  groupedSalesOrder: any[],
  selectedRows: IShippingRequestRow[],
): IMaterialGroupInStockOrderItem[] => {
  const filteredSelectedGroups: IMaterialGroupInStockOrderItem[] = [];

  selectedRows.forEach(item => {
    if (groupedSalesOrder.hasOwnProperty(item.material)) {
      const groupedSalesOrderMaterial: IMaterialGroupInStockOrderItem = {
        material: '',
        ovs: [],
      };

      const groupedSalesOrderByMaterial = groupedSalesOrder[item.material];

      if (
        groupedSalesOrderByMaterial &&
        groupedSalesOrderByMaterial.length > 1 &&
        !hasAlready(filteredSelectedGroups, groupedSalesOrderByMaterial[0].material)
      ) {
        groupedSalesOrderByMaterial.forEach(groupedMat => {
          groupedSalesOrderMaterial.ovs.push(groupedMat);
        });

        groupedSalesOrderMaterial.material = item.material;
        filteredSelectedGroups.push(groupedSalesOrderMaterial);
      }
    }
  });

  return filteredSelectedGroups;
};

export const groupByField = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const groupStockItensByBPAndMaterial = (data: InStockOrderItem[]) => {
  const fieldGroups = [BP_STOCK_FIELD_STRING, MATERIAL_FIELD_STRING];
  const groupedData = {};

  data.forEach(function(a) {
    fieldGroups
      .reduce(function(o, g, i) {
        o[a[g]] = o[a[g]] || (i + 1 === fieldGroups.length ? [] : {});
        return o[a[g]];
      }, groupedData)
      .push(a);
  });

  return groupedData;
};

export const groupShippingRequestItensByBPAndMaterial = (bpGroups: IBPGroup[], data: IShippingRequestRow[]) => {
  const fieldGroups = [BP_SHIPPING_REQUEST_FIELD_STRING, MATERIAL_FIELD_STRING];
  const groupedData = {};

  data.forEach(function(a) {
    fieldGroups
      .reduce(function(o, g, i) {
        o[a[g]] = o[a[g]] || (i + 1 === fieldGroups.length ? [] : {});
        return o[a[g]];
      }, groupedData)
      .push(a);
  });

  for (const key of Object.entries(groupedData)) {
    const bpGroupArray = groupedData[key[0]];

    for (const keyString of Object.entries(bpGroupArray)) {
      const materialGroupArray = bpGroupArray[keyString[0]];

      const newMaterialGroupShippingRequestRow: IMaterialGroupShippingRequestRow = {
        hasAlert: false,
        material: keyString[0],
        ovs: materialGroupArray,
      };

      bpGroups
        .find(group => group.bp === key[0])
        .orderedShippingMaterialUserGroup.push(newMaterialGroupShippingRequestRow);
    }
  }

  return bpGroups;
};

const orderMaterialStockGroupByOldest = (bpGroups: IBPGroup[]): IBPGroup[] => {
  const orderedBPGroups: IBPGroup[] = [];

  bpGroups.forEach(bpGroup => {
    bpGroup.orderedMaterialStockGroup.forEach(bpGroupItem => {
      const groupedMaterialGroupInStockOrderItems: IMaterialGroupInStockOrderItem[] = [];
      const materialGroupInStockOrderItem: IMaterialGroupInStockOrderItem = {
        material: bpGroupItem?.material,
        ovs: bpGroupItem?.ovs
          ?.sort(
            (a, b) =>
              new Date(handleDate(a.customerAgreedDate)).getTime() -
              new Date(handleDate(b.customerAgreedDate)).getTime(),
          )
          .sort(
            (a, b) =>
              getSuggestionValue(a.stockQuantity, a.deliveryQuantity) -
              getSuggestionValue(b.stockQuantity, b.deliveryQuantity),
          ),
      };

      groupedMaterialGroupInStockOrderItems.push(materialGroupInStockOrderItem);
    });

    orderedBPGroups.push(bpGroup);
  });

  return orderedBPGroups;
};

const orderShippingRequestGroupByOldest = (bpGroups: IBPGroup[]): IBPGroup[] => {
  const orderedBPGroups: IBPGroup[] = [];

  bpGroups.forEach(bpGroup => {
    bpGroup.orderedShippingMaterialUserGroup.forEach(bpGroupItem => {
      const groupedMaterialShippingRequestItems: IMaterialGroupShippingRequestRow[] = [];
      const materialGroupShippingRequestRow: IMaterialGroupShippingRequestRow = {
        material: bpGroupItem?.material,
        hasAlert: false,
        ovs: bpGroupItem?.ovs?.sort(
          (a, b) =>
            new Date(handleDate(a.customerAgreedDate)).getTime() - new Date(handleDate(b.customerAgreedDate)).getTime(),
        ),
      };

      groupedMaterialShippingRequestItems.push(materialGroupShippingRequestRow);
    });

    orderedBPGroups.push(bpGroup);
  });

  return orderedBPGroups;
};

const handleShippingRequestAlert = (plant: string, bpGroups: IBPGroup[]): IPlant => {
  bpGroups.forEach(bpGroup => {
    bpGroup.orderedShippingMaterialUserGroup?.forEach(shippingGroup => {
      const shippingMaterialSOs = shippingGroup.ovs;
      const relatedMaterialStockSOs = bpGroup.orderedMaterialStockGroup.find(
        stockGroup => stockGroup.material === shippingGroup.material,
      )?.ovs;

      if (shippingMaterialSOs && relatedMaterialStockSOs) {
        if (hasAnyOVWithSameOldestDateOV(shippingMaterialSOs, relatedMaterialStockSOs)) {
          // VERIFICAR SE ALGUMA DAS OVS SELECIONADAS É A MAIS ANTIGA OU ESTÁ NO MESMO AGRUPAMENTO POR DATA QUE A MAIS ANTIGA
          shippingGroup.hasAlert = false;
        } else {
          shippingGroup.hasAlert = true;
        }
      }
    });
  });

  return { plantCode: plant, bpGroup: bpGroups };
};

export const handleOldSalesOrderAlert = (
  plant: string,
  allData: InStockOrderItem[],
  selectedRows: IShippingRequestRow[],
): IPlant => {
  // OBTER TODAS AS OVS DA PLANTA SELECIONADA
  const plantGroupedSalesOrder: InStockOrderItem[] = allData.filter(
    ov => ov.plant === plant && ov.deliveryQuantity + ov.pickingQuantity < ov.stockQuantity,
  );

  // AGRUPAR TODAS OVS EM ESTOQUE POR BP E MATERIAL
  const bpMaterialGroupedSalesOrder = groupStockItensByBPAndMaterial(plantGroupedSalesOrder);

  // OBTER GRUPOS DE ITENS DO STOCK A PARTIR DOS ITENS SELECIONADOS
  const bpGroups = getBPGroupFromSelectedRows(bpMaterialGroupedSalesOrder, selectedRows);

  // ORDENAR CADA GRUPO PELA OV MAIS ANTIGA
  const orderedBPGroupByOldest: IBPGroup[] = orderMaterialStockGroupByOldest(bpGroups);

  // OBTER TODAS AS OVS SELECIONADAS PELO USUARIO DA PLANTA SELECIONADA [plantCode]
  const plantGroupedUserSalesOrder = selectedRows.filter(ov => ov.plantCode === plant);

  // PREENCHER orderedShippingMaterialUserGroup DOS RESPECTIVOS BPGROUPS
  const bpGroupedUpdatedShippingRequest = groupShippingRequestItensByBPAndMaterial(
    orderedBPGroupByOldest,
    plantGroupedUserSalesOrder,
  );

  // ORDENAR CADA GRUPO DAS OVS SELECIONADAS PELO USUÁRIO PELA OV MAIS ANTIGA #############
  const orderedBothBPGroupByOldest = orderShippingRequestGroupByOldest(bpGroupedUpdatedShippingRequest);

  const shippingRequestAlerts = handleShippingRequestAlert(plant, orderedBothBPGroupByOldest);

  return shippingRequestAlerts;
};

const hasAnyOVWithSameOldestDateOV = (selectedOVs: IShippingRequestRow[], suggestedOVs: InStockOrderItem[]) => {
  let hasSameOVLikeOldestDateOV = false;

  const orderedByOldestSuggestedOV = suggestedOVs.sort(
    (a, b) =>
      new Date(handleDate(a.customerAgreedDate)).getTime() - new Date(handleDate(b.customerAgreedDate)).getTime(),
  );

  const oldestSuggestedOVDate = orderedByOldestSuggestedOV[0].customerAgreedDate;

  selectedOVs.forEach(selectedOV => {
    if (selectedOV.customerAgreedDate === oldestSuggestedOVDate) {
      hasSameOVLikeOldestDateOV = true;
    }
  });

  return hasSameOVLikeOldestDateOV;
};

const getMaterialSequence = (oldTableRows: IShippingRequestRow[]) => {
  const sequence = [];

  oldTableRows.forEach(row => {
    const hasMaterialAlready = sequence.find(item => item === row.material);

    if (!hasMaterialAlready) {
      sequence.push(row.material);
    }
  });

  return sequence;
};

export const handleNewTableRows = (
  oldTableRows: IShippingRequestRow[],
  material: string,
  selectedSuggestions: IShippingRequestRow[],
) => {
  const materialSequence = getMaterialSequence(oldTableRows);
  const removedMaterialArray: IShippingRequestRow[] = [];
  const newTableRows: IShippingRequestRow[] = [];
  const newTableRowsAux: IShippingRequestRow[] = [];

  oldTableRows.forEach(row => {
    if (row.material !== material) {
      removedMaterialArray.push(row);
    }
  });

  removedMaterialArray.forEach(item => {
    newTableRows.push(item);
  });

  selectedSuggestions.forEach(suggestion => {
    newTableRows.push(suggestion);
  });

  let indexCount = 0;

  materialSequence.forEach(materialSeq => {
    const rowsByMaterial = newTableRows.filter(newRow => newRow.material === materialSeq);

    rowsByMaterial.forEach(row => {
      const rowAux = {
        index: indexCount,
        customerId: row.customerId,
        material: row.material,
        materialDescription: row.materialDescription,
        plantCode: row.plantCode,
        plantName: row.plantName,
        salesOrder: row.salesOrder,
        salesOrderItem: row.salesOrderItem,
        purchaseOrder: row.purchaseOrder,
        customerProductCode: row.customerProductCode,
        stockQuantity: row.stockQuantity,
        shipmentQuantity: row.shipmentQuantity,
        batch: row.batch,
        receiver: row.receiver,
        newReceiver: row.newReceiver,
        requestedShippingQuantity: row.requestedShippingQuantity,
        observations: row.observations,
        heat: row.heat,
        lastHeat: row.lastHeat,
        breakHeat: row.breakHeat,
        hasQuantityLastHeat: row.hasQuantityLastHeat,
        qtyStockLastHeat: row.qtyStockLastHeat,
        removedAlert: row.removedAlert,
        customerAgreedDate: row.customerAgreedDate,
      };

      newTableRowsAux.push(rowAux);
      indexCount += 1;
    });
  });

  return newTableRowsAux;
};

export const getUpdatedPlant = (
  plantData: IPlant,
  bp: string,
  material: string,
  updatedTableRow: IShippingRequestRow[],
): IPlant => {
  const updatedPlant: IPlant = { ...plantData };
  const updatedBPGroup: IBPGroup[] = [];
  const rowsToAdd = updatedTableRow.filter(
    row => row.plantCode === plantData.plantCode && row.customerId === bp && row.material === material,
  );

  plantData.bpGroup?.forEach(group => {
    if (group.bp === bp) {
      const bpGroupAux: IBPGroup = {
        bp: group.bp,
        orderedMaterialStockGroup: group.orderedMaterialStockGroup,
        orderedShippingMaterialUserGroup: [],
      };

      group.orderedShippingMaterialUserGroup?.forEach(shippingMaterialGroup => {
        if (shippingMaterialGroup.material === material) {
          bpGroupAux.orderedShippingMaterialUserGroup.push({
            material: material,
            hasAlert: false,
            ovs: rowsToAdd,
          });
        } else {
          bpGroupAux.orderedShippingMaterialUserGroup.push(shippingMaterialGroup);
        }
      });

      updatedBPGroup.push(bpGroupAux);
    } else {
      updatedBPGroup.push(group);
    }
  });

  updatedPlant.bpGroup = updatedBPGroup;

  return updatedPlant;
};

export const getValidSuggestions = (ovs: InStockOrderItem[]) => {
  const validSuggestions: InStockOrderItem[] = [];

  ovs.forEach(ov => {
    if (ov && ov.deliveryQuantity + ov.pickingQuantity < ov.stockQuantity) {
      validSuggestions.push(ov);
    }
  });

  return validSuggestions;
};

export const getSuggestionValue = (stockQty: number, shippingQty: number) => {
  const result = stockQty - shippingQty;

  return Math.abs(result);
};

export const getFilteredPlantGroup = (
  currentPlantGroup: IPlant,
  materialBP: string,
  material: string,
  heat: string,
): IPlant => {
  const newPlantGroup: IPlant = {
    plantCode: currentPlantGroup.plantCode,
    bpGroup: currentPlantGroup.bpGroup.map(group => {
      if (group.bp === materialBP) {
        for (const shippingGroup of group.orderedShippingMaterialUserGroup) {
          if (shippingGroup.material === material) {
            shippingGroup.ovs = shippingGroup.ovs.filter(ov => ov.heat !== heat);
            break;
          }
        }
      }

      return group;
    }),
  };

  return newPlantGroup;
};
