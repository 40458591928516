import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { IAdminTab } from '@app/models/admin.model';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';

export const getTabs = createAsyncThunk(`${SLICE_NAME}/getTabs`, async (_, thunkAPI) => {
  return await AdminDataSource.getTabs()
    .then(data => data)
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: err.response.data.message, variant: ToastVariant.DANGER }));
    });
});

export const addGetTabsReducers = builder => {
  builder.addCase(getTabs.pending, state => {
    state.loading = true;
  });
  builder.addCase(getTabs.fulfilled, (state, action) => {
    const payload: IAdminTab[] = action.payload as IAdminTab[];
    state.loading = false;
    state.tabChips = payload;
  });
  builder.addCase(getTabs.rejected, state => {
    state.loading = false;
  });
};
