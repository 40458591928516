export const valueQtyFormatter = (params, en) => {
  let value = params?.value?.toString();

  if (!en && params.value && params.value.toString().indexOf('.') > -1) {
    value = params.value.toString().replace('.', ',');
  }

  return value;
};

export const subtotalFormatter = (subtotal, language) => {
  return language === 'in'
    ? Math.round(subtotal).toLocaleString('en-US')
    : Math.round(subtotal).toLocaleString('pt-br');
};

export const sizeInFormatter = (params, language) => {
  if (params && params.value) {
    if (language !== 'in' && params.value.toString().indexOf('.') > -1) {
      return params.value.toFixed(4).replace('.', ',');
    } else {
      return params.value.toFixed(4).replace(',', '.');
    }
  }
};
