import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { AnnouncementModalDatasource } from '@app/data/http/announcement-modal-datasource';
import { IPutAnnouncementParams } from '@app/data/http/announcement.dto';
import { AnnouncementModalEvents } from '@app/modules/components/announcement-modal/announcement-modal.analytics';
import { SLICE_NAME } from '../../preventive-stop-modal.constants';

export const putPreventiveStopModal = createAsyncThunk(
  `${SLICE_NAME}/putPreventiveStopModal`,
  async (announcementParams: IPutAnnouncementParams, thunkAPI) => {
    return AnnouncementModalDatasource.putAnnouncement(announcementParams)
      .then(resp => resp)
      .catch(err => {
        LogAnalytics.error({
          tipo: AnnouncementModalEvents.PutPreventiveStopModalAnnouncementeError,
          message: err.response.status,
        });
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addPutPreventiveStopModalReducers = builder => {
  builder.addCase(putPreventiveStopModal.pending, state => {
    state.loading = true;
  });
  builder.addCase(putPreventiveStopModal.fulfilled, (state, _) => {
    state.loading = false;
    state.preventiveStopData = null;
    state.hasPreventiveStopAnnouncement = false;
  });
  builder.addCase(putPreventiveStopModal.rejected, state => {
    state.loading = false;
  });
};
