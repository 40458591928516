import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const SelectedBPsChipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectedBPsChipWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid ${Color.HefestoGray};
  width: 84.7em;
  height: 100px;
  max-height: 95px;
  overflow: auto;
`;

export const BPsFilterStyled = styled.div`
  width: 20%;
`;

export const BPsSearchStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  width: 65%;
  margin-left: ${Spacing.XLarge};
  margin-right: ${Spacing.XLarge};
`;

export const BPsTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
