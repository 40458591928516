export const SEARCH_DEBOUNCE_TIME = 1500;

export const LIMIT_ITEMS_PER_PAGE = 6;

export const userInitialState = {
  _id: '',
  oktaUid: '',
  name: '',
  email: '',
  language: '',
  applications: '',
  lastLogin: '',
  created: {
    at: '',
    by: {
      name: '',
      email: '',
    },
  },
  profile: {
    _id: '',
    profile: '',
    tabs: [],
  },
  bps: [],
  isAdmin: false,
};
