import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import { SLICE_NAME } from '../email-sending-failure-modal.constants';
import { IEmailSendingFailureModalState } from '../email-sending-failure-modal.interfaces';
import { initialState } from './email-sending-failure-modal.initial';
import { reducers } from './email-sending-failure-modal.reducers';

export const emailSendingFailureModalSlice = createSlice<
  IEmailSendingFailureModalState,
  SliceCaseReducers<IEmailSendingFailureModalState>
>({
  name: SLICE_NAME,
  initialState,
  reducers,
});

export const { reset, setShowEmailSendingFailureModal } = emailSendingFailureModalSlice.actions;

const persistConfig = {
  key: 'email-sending-failure-persist-reducer',
  storage,
};

export const emailSendingFailureReducer = persistReducer(persistConfig, emailSendingFailureModalSlice.reducer);
