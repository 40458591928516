import { createAsyncThunk } from '@reduxjs/toolkit';
import { LayoutDataSource } from '@app/data/http/layout.datasource';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { SLICE_NAME } from '../grid-layout.constants';
import { IPostGridLayoutParams } from '../grid-layout.interfaces';
import { mapSelectedTabColumns } from '../grid-layout.selectors';

export const postGridLayout = createAsyncThunk(
  `${SLICE_NAME}/post`,
  async ({ tab, language, handleSuccess, columns = [] }: IPostGridLayoutParams, thunkAPI) => {
    const customerIds = customerIdsJoinedSelector(thunkAPI.getState());
    const cols = mapSelectedTabColumns(columns, language);

    return await LayoutDataSource.postLayout({ tab, columns: cols, customerIds, language })
      .then(() => handleSuccess())
      .catch(err => err.message);
  },
);

export const addPostGridLayoutReducers = builder => {
  builder.addCase(postGridLayout.pending, state => {
    state.loadingPost = true;
  });
  builder.addCase(postGridLayout.fulfilled, state => {
    state.loadingPost = false;
  });
  builder.addCase(postGridLayout.rejected, state => {
    state.loadingPost = false;
  });
};
