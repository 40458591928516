import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { dateParser, dateParserPTBR } from '@app/utils/date-parser';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, H2, H3 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { IOrderUpdateStrings, Strings } from '../../order-update.string';
import { CancelOrderStyled, ReviewDetailsWrapperStyled } from '../../order-update.style';
import { setFinishModalOpened } from '../../store/order-update.store';
import { putNewWallet } from '../../store/order-update.thunk';
import { handleSuccessConfigured } from '../../utils/handler-utils';

export const ModalMessageClient: React.FC = () => {
  const strings: IOrderUpdateStrings = Strings.pt;
  const { finishModalOpened, generalComment, loading, tableRows } = useSelector(
    (state: RootState) => state.orderUpdate,
  );
  const customerIds = useSelector(customerIdsJoinedSelector);

  const dispatch = useDispatch<AppThunkDispatch>();
  const onCloseModal = () => dispatch(setFinishModalOpened(false));

  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const handleError = () => {
    dispatch(setFinishModalOpened(false));
  };

  const handleSuccess = handleSuccessConfigured({
    successMessage: strings.finish.success,
    show,
    tableRows,
    customerIds,
    dispatch,
  });

  const onClick = () => {
    dispatch(putNewWallet({ handleSuccess, handleError }));
  };

  return (
    <Modal small opened={finishModalOpened} preventOverlayClick={loading}>
      <Grid fluid>
        <H2>{strings.modal.title}</H2>
        <VSeparator />
        <ReviewDetailsWrapperStyled>
          {generalComment && (
            <>
              <H3>{strings.modal.comment}</H3>
              <Body>{generalComment}</Body>
              <VSeparator />
            </>
          )}
          {tableRows.map(
            (item, index) =>
              (item.cancelOrder || item.newDateSolClient || item.newQtdSolClient) && (
                <React.Fragment key={index}>
                  <H3>{strings.modal.item(item.itemOv, item.ocClient)}</H3>
                  {item.newQtdSolClient && (
                    <Body>
                      {strings.modal.changeQuantity(item.qtdSolClient)}
                      <FaIcon.RightArrow size='1x' />
                      {strings.modal.newQuantity(item.newQtdSolClient)}
                    </Body>
                  )}
                  {item.newDateSolClient && (
                    <Body>
                      {strings.modal.changeDate(
                        dateParserPTBR({ unformattedDate: item.dateSolClient.toString(), hideHours: true }),
                      )}
                      <FaIcon.RightArrow size='1x' />
                      {strings.modal.newDate(
                        dateParser({ unformattedDate: new Date(item.newDateSolClient).toString(), hideHours: true }),
                      )}
                    </Body>
                  )}
                  {item.cancelOrder && <CancelOrderStyled>{strings.table.requestCancel}</CancelOrderStyled>}
                  <VSeparator />
                </React.Fragment>
              ),
          )}
        </ReviewDetailsWrapperStyled>
        <FlexRow hAlign='flex-end'>
          <FlexColumn noGrow>
            <Button kind='secondary' onClick={onCloseModal} disabled={loading}>
              {strings.modal.goBack}
            </Button>
          </FlexColumn>
          <Hbox.Separator />
          <FlexColumn noGrow>
            <Button loading={loading} onClick={onClick}>
              {strings.modal.submit}
            </Button>
          </FlexColumn>
        </FlexRow>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
