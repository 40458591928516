/* eslint-disable import/order */
import { configure } from '@app/configure-app';
import { AppWindow } from '@app/utils/interfaces';

const config: any = {};
configure(((window as unknown) as AppWindow).__APP_CONFIG__ || config);

// !!! Warning !!! Hack explanation!
//
// Q: Why are we using require instead of import?
//    Why in the middle of the code?
// A: GraphQL decorator access an object that is only
//    available after `configure` is called. However
//    a decorator code is evaluated at reading time
//    while the configure is executed at runtime.

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { bootstrapClient } = require('./app/bootstrap');

bootstrapClient();
