import React, { useContext, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { IOrderInputStrings, OrderInputStrings } from '@app/modules/order-input/order-input.string';
import {
  setOpenConfigColumnSpreadsheet,
  setSpreadSheetFileName,
  setSpreadSheetFileRequestItems,
} from '@app/modules/order-input/store/order-input.store';
import { FlashMessageTargetName } from '@app/providers';
import { DropzoneStrings } from '@atomic/atm.dropzone/dropzone.string';
import {
  ConfigColumnsContext,
  ConfigColumnsContextState,
} from '../../../../../components/config-columns-modal/config-columns-modal.context';
import { FileUploaderWrapper } from '../../order-input-upload-file.style';
import { getFileContentArray } from '../../order-input-upload-utils';
import { CustomDropZone } from './custom-drop-zone/custom-drop-zone';

export const OrderInputUploadFileDropZone: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];

  const [showDropzoneMessage, setShowDropzoneMessage] = useState(true);
  const dispatch = useDispatch();
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const { setSpreadSheetFile } = useContext<ConfigColumnsContextState>(ConfigColumnsContext);
  const {
    configColumnsSpreadsheet: {
      materialCodeColumn,
      amountColumn,
      unityAmountColumn,
      dateWishedColumn,
      orderNumberColumn,
      itemIdentifierColumn,
      initRow,
    },
  } = useSelector((state: RootState) => state.orderInput);

  const validateFile = filesArray => {
    let isValid = true;

    if (filesArray.length > 1) {
      show('alert', strings?.uploadSpreadSheet?.multipleFilesAlert);
      isValid = false;
    } else if (
      filesArray[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      filesArray[0].type !== 'application/vnd.ms-excel' &&
      filesArray[0].type !== 'text/csv'
    ) {
      show('alert', strings?.uploadSpreadSheet?.fileFormatAlert);
      isValid = false;
    }

    return isValid;
  };

  const handleDragginStateChange = isDraggin => {
    if (isDraggin) {
      setShowDropzoneMessage(false);
    } else {
      setShowDropzoneMessage(true);
    }
  };

  const handleChange = async files => {
    const filesArray = Object.values(files);

    if (!validateFile(filesArray)) {
      return null;
    }

    const columns = [
      materialCodeColumn,
      amountColumn,
      unityAmountColumn,
      dateWishedColumn,
      orderNumberColumn,
      itemIdentifierColumn,
    ];
    const file: any = filesArray[0];
    setSpreadSheetFile(file);

    const fileContentArray = await getFileContentArray(file, columns, initRow);

    dispatch(setSpreadSheetFileName(file.name));
    dispatch(setOpenConfigColumnSpreadsheet(true));
    dispatch(setSpreadSheetFileRequestItems(fileContentArray));
  };

  return (
    <FileUploaderWrapper>
      <FileUploader
        classes='order-input-file-uploader'
        handleChange={handleChange}
        name='file'
        multiple={true}
        hoverTitle={DropzoneStrings[userInfo.language].uploadClickButton}
        onDraggingStateChange={e => handleDragginStateChange(e)}
      >
        <CustomDropZone showDropzoneMessage={showDropzoneMessage} />
      </FileUploader>
    </FileUploaderWrapper>
  );
};
