import * as XLSX from 'xlsx';
import { SpreasheetRow } from '../../../order-input.interfaces';

const alphabetPosition = (text: string) => {
  let result = '';

  for (let i = 0; i < text?.length; i++) {
    if (text) {
      const code = text.toUpperCase().charCodeAt(i);
      if (code > 64 && code < 91) {
        result += code - 64 + ' ';
      }
    }
  }

  return result.slice(0, result.length - 1);
};

const getValidDayOrMonthValue = (value: string) => {
  let validValue = value;

  if (value.length === 1) {
    validValue = `0${value}`;
  }

  return validValue;
};

const handleDate = (date: any, fileName: string) => {
  let validDate = '';

  if ((typeof date === 'string' && date.indexOf('/') > -1) || (date instanceof String && date.indexOf('/') > -1)) {
    const dateSplit = date.split('/');
    validDate = `${getValidDayOrMonthValue(dateSplit[0])}/${getValidDayOrMonthValue(dateSplit[1])}/${dateSplit[2]}`;
  } else if (
    (typeof date === 'string' && date.indexOf('.') > -1) ||
    (date instanceof String && date.indexOf('.') > -1)
  ) {
    const dateSplit = date.split('.');
    validDate = `${getValidDayOrMonthValue(dateSplit[0])}/${getValidDayOrMonthValue(dateSplit[1])}/${dateSplit[2]}`;
  } else {
    const dateAux = new Date(date);
    if (fileName.indexOf('.csv') > -1) {
      validDate = `${getValidDayOrMonthValue((dateAux.getMonth() + 1).toString())}/${getValidDayOrMonthValue(
        dateAux.getDate().toString(),
      )}/${dateAux.getFullYear()}`;
    } else {
      validDate = `${getValidDayOrMonthValue(dateAux.getDate().toString())}/${getValidDayOrMonthValue(
        (dateAux.getMonth() + 1).toString(),
      )}/${dateAux.getFullYear()}`;
    }
  }

  return validDate;
};

export const getFileContentArray = async (file, columns, initRow): Promise<SpreasheetRow[]> => {
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, { cellDates: true });
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  const rowsArray = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: true });
  const fileContentArray = [];

  if (rowsArray && rowsArray.length) {
    let skuValue = '';
    let quantityValue = '';
    let unitOfMeasureValue = '';
    let datetCustomerRequiredValue = '';
    let orderNumberValue = '';
    let orderItemValue = '';

    rowsArray.map((row: [], i) => {
      if (i >= initRow - 1 && row && row.length) {
        columns.forEach((col, j) => {
          if (j === 0) {
            skuValue = row[parseInt(alphabetPosition(col)) - 1] ? row[parseInt(alphabetPosition(col)) - 1] : '';
          }

          if (j === 1) {
            const amount = row[parseInt(alphabetPosition(col)) - 1] ? row[parseInt(alphabetPosition(col)) - 1] : '';
            quantityValue =
              amount.toString().indexOf('.') > -1 ? amount.toString().replace('.', ',') : amount.toString();
          }

          if (j === 2) {
            unitOfMeasureValue = row[parseInt(alphabetPosition(col)) - 1]
              ? row[parseInt(alphabetPosition(col)) - 1]
              : '';
          }

          if (j === 3) {
            datetCustomerRequiredValue = row[parseInt(alphabetPosition(col)) - 1]
              ? handleDate(row[parseInt(alphabetPosition(col)) - 1], file.name)
              : '';
          }

          if (j === 4) {
            orderNumberValue = row[parseInt(alphabetPosition(col)) - 1] ? row[parseInt(alphabetPosition(col)) - 1] : '';
          }

          if (j === 5) {
            orderItemValue = row[parseInt(alphabetPosition(col)) - 1] ? row[parseInt(alphabetPosition(col)) - 1] : '';
          }
        });

        const spreadhsheetRow: SpreasheetRow = {
          sku: skuValue.toString().trim(),
          quantity: quantityValue.trim(),
          unitOfMeasure: unitOfMeasureValue.toUpperCase().trim(),
          datetCustomerRequired: datetCustomerRequiredValue.trim(),
          numberOrder: orderNumberValue.toString().trim(),
          orderItem: orderItemValue.toString().trim(),
        };

        fileContentArray.push(spreadhsheetRow);
      }
    });
  }
  return fileContentArray;
};
