/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';

export const ComingSoonTagBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Color.GrayDark};
  color: ${Color.White};
  border-radius: 4px;
  width: auto;
  height: 20px;
  padding: 2px 8px 2px 8px;
  font-family: 'Open Sans';
  font-size: ${FontSize.XSmall};
  margin-left: ${Spacing.Large};
  min-width: 85px;
`;


