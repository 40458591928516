import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { IsaExternalRoles, Stages } from '@app/models/quote.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { useUsersByProfilesAllData } from '../hooks/useUsersByProfiles';
import { TechnicalAnalysisContext } from '../technical-analysis.context';
import { EmailFieldsComponent } from './technical-analysis-status-field.component';
import { mapSKU, mapTechnicalAnalysisEmails } from './technical-analysis-status.mapper';

interface TechnicalAnalysisStatusModalProps {
  loading: boolean;
  opened: boolean;
  data: Stages;
  onClose: () => void;
  onSubmit: (key: string, data: TechnicalAnalysisStatusData) => void;
  extraNotifications: ExtraNotifications;
  analysisType?: string;
}

export interface TechnicalAnalysisStatusData {
  emails: string[];
  observation?: string;
  observationClient?: string;
  APQP?: string;
  PE?: string | number;
  skus?: string[];
  partNumber?: string | number;
  FMEAS?: string;
  FMEAS_EDIT?: string;
  NEWPRODUCTEPP?: string;
  NEWPRODUCTEPPJUSTIFY?: string;
}

interface ExtraNotifications {
  APQP?: string;
  PE?: string | number;
  FMEAS?: string;
  FMEAS_EDIT?: string;
  NEWPRODUCTEPP?: string;
  NEWPRODUCTEPPJUSTIFY?: string;
}

export interface TechnicalAnalysisStatusModalFormData {
  observation?: string;
  observationClient?: string;
  email?: string;
  emailEpp?: string;
  emailPed?: string;
  emailRtc?: string;
  emailClient?: string;
  emailGq?: string;
  emailSeller?: string;
  APQP?: string;
  PE?: string | number;
  sku?: string;
  FMEAS?: string;
  FMEAS_EDIT?: string;
  NEWPRODUCTEPP?: string;
  NEWPRODUCTEPPJUSTIFY?: string;
}

export const TechnicalAnalysisStatusModal: React.FC<TechnicalAnalysisStatusModalProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { analysis } = React.useContext(TechnicalAnalysisContext);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const strings = TechnicalAnalysisStrings[userInfo.language].status.statusModal;

  const [usersList, setUsersList] = React.useState({
    epp: [],
    rtc: [],
    ped: [],
    client: [],
    gq: [],
    seller: [],
  });

  const mapsList = (userList, profile) => {
    return {
      [profile.toLowerCase()]: userList.filter(u => u.profile === IsaExternalRoles[profile]).map(u => u.email),
    };
  };

  const { mutate: getUsers, isLoading: loadingListUsers } = useUsersByProfilesAllData(
    handleGetUsersSuccess,
    handleGetUsersError,
  );

  function handleGetUsersError(data) {
    show('alert', data.description);
  }

  function handleGetUsersSuccess(data) {
    setUsersList(prevValue => ({ ...prevValue, ...mapsList(data.data, data.profile) }));
  }

  React.useEffect(() => {
    if (props?.data) {
      props?.data?.notification?.profiles.forEach(profile => {
        getUsers({ language: userInfo.language, profile, bp: analysis.bp });
      });
    }
  }, [props.opened]);

  const extraNotifications = props.extraNotifications;

  const handleSubmit = (form: FormData<TechnicalAnalysisStatusModalFormData>) => {
    if (!(Object.keys(form.error).length > 0)) {
      const emailCheck = email => {
        return email;
      };
      const emailList = [
        form.data.email,
        form.data.emailEpp,
        form.data.emailPed,
        form.data.emailRtc,
        form.data.emailClient,
        form.data.emailGq,
        form.data.emailSeller,
      ].filter(emailCheck);

      props.onSubmit(props.data.key, {
        emails: mapTechnicalAnalysisEmails(emailList),
        skus: mapSKU(form.data?.sku),
        observation: form.data.observation,
        observationClient: form.data.observationClient,
        APQP: extraNotifications.APQP,
        PE: extraNotifications.PE,
        FMEAS: extraNotifications.FMEAS,
        FMEAS_EDIT: extraNotifications.FMEAS_EDIT,
        NEWPRODUCTEPP: extraNotifications.NEWPRODUCTEPP,
        NEWPRODUCTEPPJUSTIFY: extraNotifications.NEWPRODUCTEPPJUSTIFY,
      });
    }
  };

  const handleClose = () => {
    if (!props.loading) {
      props.onClose();
    }
  };

  return (
    <Modal opened={props.opened} onClose={handleClose}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <VSeparator />
            <H3>{strings.changeStatus(props.data?.label)}</H3>
            <VSeparator />
            <Form onSubmit={handleSubmit}>
              {!!props.data && usersList && (
                <EmailFieldsComponent
                  loading={loadingListUsers}
                  users={usersList}
                  notification={props.data.notification}
                />
              )}
              <VSeparator small />
              {props.data?.key
                ? (props.data.key === 'FINALIZED' || props.data.key === 'FINALIZED_WITH_DEROGATION') && (
                    <>
                      <Form.Field
                        validators={[
                          Validators.CheckSkusIsNumber(strings.skuField.alertSkuNumber),
                          Validators.CheckSkusFormat(strings.skuField.alertSkuFormat),
                        ]}
                        name='sku'
                        label={`${strings.skuField.label} - ${strings.skuField.labelOptional}`}
                      >
                        <TextField data-testid='skuField' placeholder={strings.skuField.placeholder} />
                      </Form.Field>
                      <VSeparator />
                    </>
                  )
                : ''}

              <VSeparator />

              <Form.Field name='observation' label={strings.observationField.labelGerdau}>
                <TextAreaField
                  data-testid='observationField'
                  initialValue={analysis?.cancelRequestReason}
                  placeholder={strings.placeholder}
                  disableResize
                />
              </Form.Field>

              {props?.data?.notification?.profiles.includes('CLIENT') && (
                <>
                  <VSeparator small />
                  <Form.Field name='observationClient' label={strings.observationField.labelClient}>
                    <TextAreaField
                      data-testid='observationClientField'
                      initialValue={analysis?.cancelRequestReason}
                      placeholder={strings.placeholder}
                      disableResize
                    />
                  </Form.Field>
                </>
              )}
              <VSeparator small />
              <VSeparator />

              <Hbox hAlign='flex-end'>
                <Hbox.Item noGrow>
                  <Button loading={props.loading} type='submit' kind='primary'>
                    {strings.send}
                  </Button>
                </Hbox.Item>
              </Hbox>
            </Form>
            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
