import { addDays } from 'date-fns';

export const isD40Day = (currentDate: Date, today: Date) => {
  let result = false;
  const minDate = today.getTime();
  const maxDate = addDays(today, 40).getTime();
  const currentDateTime = currentDate.getTime();

  if (currentDateTime >= minDate && currentDateTime <= maxDate) {
    result = true;
  }

  return result;
};
