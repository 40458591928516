import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';

export const FileUploaderWrapper = styled(FlexRow)`
  width: 100%;
`;

export const CustomDropzoneWrapperStyled = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  padding-top: ${Spacing.XLarge};
  margin: 0;
  padding-right: ${Spacing.Large};
`;
