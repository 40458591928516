import * as React from 'react';
import { Avatar } from '@atomic/atm.avatar';
import { Image } from '@atomic/atm.image';
import { Hbox } from '@atomic/obj.box';
import { ChatMessageStyled } from './chat-message.component.style';

interface ChatMessageProps {
  type: 'isa' | 'user';
  initials?: string;
}

export const ChatMessage: React.FC<ChatMessageProps> = props => {
  return (
    <ChatMessageStyled>
      <Hbox>
        <Hbox.Item noGrow>
          {props.type === 'isa' && (
            <Avatar type='secondary' direction='left'>
              <div data-testid='chat-loading'>
                <Image.Isa />
              </div>
            </Avatar>
          )}
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item vAlign='flex-start' hAlign={props.type === 'isa' ? 'flex-start' : 'flex-end'}>
          {props.children}
        </Hbox.Item>
        <Hbox.Separator />
        <Hbox.Item noGrow>
          {props.type === 'user' && (
            <Avatar type='primary' direction='right'>
              {props.initials}
            </Avatar>
          )}
        </Hbox.Item>
      </Hbox>
    </ChatMessageStyled>
  );
};
