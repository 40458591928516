import {
  AvailableStatus,
  AvailableStatusNotification,
  CurrentStatus,
  IsaExternalRoles,
  StatusUserList,
  UserListAllData,
} from '@app/models/quote.model';
import { CurrentStatusResponse } from './quote.dto';

export interface GetUsersByProfileResponse {
  firstName: string;
  lastName: string;
  email: string;
  profile: string;
  type: string;
  isAdmin: boolean;
}

export interface AvailableStatusResponse {
  current: CurrentStatusResponse;
  history: any;
  stages: StagesResponse[];
}

export interface StagesResponse {
  key: string;
  label: string;
  disabled: boolean;
  notification: AvailableStatusNotification;
}

export const mapAvailableStatus = (availableStatus: AvailableStatusResponse): AvailableStatus => {
  return {
    stages: availableStatus.stages.filter(
      stage =>
        !stage.disabled &&
        stage.key !== availableStatus.current?.external?.key &&
        stage.key !== availableStatus.current?.internal?.key,
    ),
  };
};

export const mapCurrentStatus = (currentStatus: AvailableStatusResponse): CurrentStatus => {
  return {
    currentStatus: {
      internalStatus: currentStatus.current.internal,
      externalStatus: currentStatus.current.external,
    },
  };
};

export const mapUserList = (userList: GetUsersByProfileResponse[]): StatusUserList => {
  return {
    epp: userList.filter(user => user.profile === IsaExternalRoles.EPP).map(user => user.email),
    rtc: userList.filter(user => user.profile === IsaExternalRoles.RTC).map(user => user.email),
    ped: userList.filter(user => user.profile === IsaExternalRoles.PED).map(user => user.email),
    client: userList
      .filter(user => user.profile === IsaExternalRoles.CLIENT || user.profile === IsaExternalRoles.RTC)
      .map(user => user.email),
    gq: userList.filter(user => user.profile === IsaExternalRoles.GQ).map(user => user.email),
    seller: userList.filter(user => user.profile === IsaExternalRoles.SELLER).map(user => user.email),
  };
};

export const mapUserListGerdauAllData = (userList: GetUsersByProfileResponse[]): UserListAllData => {
  return {
    epp: userList.filter(user => user.profile === IsaExternalRoles.EPP).map(user => user),
    rtc: userList.filter(user => user.profile === IsaExternalRoles.RTC).map(user => user),
    ped: userList.filter(user => user.profile === IsaExternalRoles.PED).map(user => user),
    client: userList
      .filter(user => user.profile === IsaExternalRoles.CLIENT || user.profile === IsaExternalRoles.RTC)
      .map(user => user),
    gq: userList.filter(user => user.profile === IsaExternalRoles.GQ).map(user => user),
    seller: userList.filter(user => user.profile === IsaExternalRoles.SELLER).map(user => user),
  };
};
