import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '@app/core/redux/store';
import { OpenOrdersGraphDataSource } from '@app/data/http/dashboard/open-orders-graph/open-orders-graph.datasource';
import { useGetDashboard } from '@app/domain/get-dashboard.use-case';
import { OrderStatusGraph } from '@app/models/dashboard.model';
import { setReloadOpenDashboard } from '@app/providers/navigation/navigation.store';
import { useLocalStorageState } from '@app/utils/local-storage.hooks';
import { Frame } from '@atomic/atm.frame';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { DashboardBlockHeader } from '../dashboard-block-header.component';
import { DashboardBlockShimmer } from '../dashboard-block.shimmer';
import { Strings, IDashBoardStrings } from '../dashboard.string';

const strings: IDashBoardStrings = Strings.pt;
const { openOrders: openOrdersGraphStrings } = strings.graphs;

const orderStatusInfo = (data: OrderStatusGraph) => {
  return [
    { name: openOrdersGraphStrings.orderStatus.deployed, value: data.deployed },
    { name: openOrdersGraphStrings.orderStatus.onSchedule, value: data.onSchedule },
    { name: openOrdersGraphStrings.orderStatus.confirmed, value: data.confirmed },
    { name: openOrdersGraphStrings.orderStatus.total, value: data.total },
  ];
};

const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = '95%';

interface StatusGraphProps {
  clientNumber: string;
  screenWidth: number;
  onSuccess?: () => void;
  handleClick: (modalData) => void;
}

const STATUS_GRAPH_DATA = 'status_graph_data';
const DO_REQUESTS_FOR_DASHBOARDS = 'do_requests_for_dashboards';

export const StatusGraph: React.FC<StatusGraphProps> = props => {
  const { reloadOpenDashboard } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();
  const [statusGraphData, setStatusGraphData] = useLocalStorageState(STATUS_GRAPH_DATA, {});
  const doRequestForDashboards = window.localStorage.getItem(DO_REQUESTS_FOR_DASHBOARDS);

  const { data, loading, error, performRequest } = useGetDashboard<OrderStatusGraph>(
    OpenOrdersGraphDataSource.status,
    props.onSuccess,
  );

  useEffect(() => {
    if (reloadOpenDashboard) {
      performRequest(props.clientNumber);
      dispatch(setReloadOpenDashboard(false));
    }
  }, [reloadOpenDashboard]);

  useEffect(() => {
    if (props.clientNumber && doRequestForDashboards === 'true') {
      performRequest(props.clientNumber);
      dispatch(setReloadOpenDashboard(false));
    }
  }, [props.clientNumber, doRequestForDashboards]);

  useEffect(() => {
    if (data) {
      setStatusGraphData(data);
    }
  }, [data]);

  return (
    <LoadingState loading={loading} data={!!statusGraphData} error={!!error}>
      <LoadingState.Shimmer>
        <DashboardBlockShimmer blocks={1} />
      </LoadingState.Shimmer>

      {!!statusGraphData && (
        <Frame>
          <VSeparator />

          <DashboardBlockHeader
            title={strings.orderOpen.orderStatus}
            description={openOrdersGraphStrings.openOrderStatus.description}
          />
          <VSeparator />

          <ResponsiveContainer width={GRAPH_WIDTH} height={GRAPH_HEIGHT}>
            <BarChart data={orderStatusInfo(statusGraphData)}>
              <XAxis dataKey='name' />
              <YAxis domain={[0, datamax => Math.floor(datamax * 1.3)]} />
              <Tooltip />
              <Bar
                dataKey='value'
                isAnimationActive={false}
                name={openOrdersGraphStrings.value}
                fill={Color.Accessory}
                radius={4}
                unit='t'
                onClick={props.handleClick}
              >
                <LabelList dataKey='value' position='top' formatter={value => value + 't'} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Frame>
      )}
    </LoadingState>
  );
};
