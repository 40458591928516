import { Color } from '@atomic/obj.constants';

export const billedData = [
  { name: 'Jan/2021', vol: 75.6 },
  { name: 'Fev/2021', vol: 150.153 },
  { name: 'Mar/2021', vol: 199.578 },
  { name: 'Abr/2021', vol: 140.578 },
  { name: 'Mai/2021', vol: 250 },
  { name: 'Jun/2021', vol: 153 },
  { name: 'Jul/2021', vol: 98 },
  { name: 'Ago/2021', vol: 132 },
  { name: 'Set/2021', vol: 123 },
  { name: 'Out/2021', vol: 188 },
  { name: 'Nov/2021', vol: 25 },
  { name: 'Dez/2021', vol: 99.1 },
];

export const openOrderStatus = [
  { name: 'Qtd solicitada', a: 4500 },
  { name: 'Qtd confirmada', a: 3800 },
  { name: 'Qtd pend. faturamento', b: 50, c: 1360, d: 1355 },
  { name: 'Qtd faturada', a: 1048 },
];

export const solXCon = [
  { name: 'jul.', 'Qtd solicitada': 2500, 'Qtd confirmada': 2450 },
  { name: 'ago.', 'Qtd solicitada': 1300, 'Qtd confirmada': 1350 },
  { name: 'set.', 'Qtd solicitada': 300, 'Qtd confirmada': 0 },
  { name: 'out.', 'Qtd solicitada': 100, 'Qtd confirmada': 0 },
  { name: 'nov.', 'Qtd solicitada': 100, 'Qtd confirmada': 0 },
  { name: 'dez.', 'Qtd solicitada': 100, 'Qtd confirmada': 0 },
];

export const orderStatus = [
  { name: 'Implantado', Pedidos: 329 },
  { name: 'Em apraza...', Pedidos: 300 },
  { name: 'Confirmado', Pedidos: 3800 },
];

export const deliveryForecast = [
  { name: 'jul.', 'Qtd prevista de faturamento': 2000, 'Qtd faturada': 400 },
  { name: 'ago.', 'Qtd prevista de faturamento': 1050 },
  { name: 'set.', 'Qtd prevista de faturamento': 500 },
  { name: 'out.', 'Qtd prevista de faturamento': 250 },
  { name: 'nov.', 'Qtd prevista de faturamento': 0 },
  { name: 'dez.', 'Qtd prevista de faturamento': 0 },
];

export const deliveryForecastStatus = [
  { name: 'Atraso' },
  { name: 'Dentro do prazo' },
  { name: 'Sem previsão - consultar Gerdau' },
  { name: 'Tendência de atraso' },
];

export const orderStockStage = [
  { name: 'Em estoque', pv: 1200 },
  { name: 'Em remessa', pv: 120 },
  { name: 'Em carregamento', pv: 40 },
];

export const orderStockStatus = [
  { name: 'Vencido', pv: 156 },
  { name: 'Não vencido', pv: 1044 },
];

export const billedDay = [
  { name: '01/03', vol: 75.6 },
  { name: '02/03', vol: 150.153 },
  { name: '03/03', vol: 199.578 },
  { name: '04/03', vol: 140.578 },
  { name: '05/03', vol: 250 },
  { name: '06/03', vol: 153 },
  { name: '07/03', vol: 98 },
  { name: '08/03', vol: 132 },
  { name: '09/03', vol: 123 },
  { name: '10/03', vol: 188 },
  { name: '11/03', vol: 25 },
  { name: '12/03', vol: 99.1 },
  { name: '13/03', vol: 199.578 },
  { name: '14/03', vol: 140.578 },
  { name: '15/03', vol: 250 },
  { name: '16/03', vol: 153 },
  { name: '17/03', vol: 98 },
  { name: '18/03', vol: 132 },
  { name: '19/03', vol: 123 },
  { name: '20/03', vol: 123 },
  { name: '21/03', vol: 75.6 },
  { name: '22/03', vol: 150.153 },
  { name: '23/03', vol: 199.578 },
  { name: '24/03', vol: 140.578 },
  { name: '25/03', vol: 250 },
  { name: '26/03', vol: 153 },
];

export const COLORS = [Color.Accessory, Color.Primary, Color.PinkDark, Color.Warning];
