import { LogAnalytics } from '@app/core/analytics';
import { SalesOrderSummaryItem, UserInfo } from '@app/models';
import { FormData } from '@atomic/obj.form';
import { OrderPriorizationEvents } from '../../order/order.analytics';
import { ICriticalItem, IOrderPriorizationData } from '../order-priorization.interfaces';

interface IHandleSuccessConfiguredParams {
  userInfo: UserInfo;
  selectedRows: SalesOrderSummaryItem[];
  formData: FormData<IOrderPriorizationData>;
}

export const handleSuccessConfigured = ({ userInfo, selectedRows, formData }: IHandleSuccessConfiguredParams) => {
  selectedRows.forEach((row, i) => {
    LogAnalytics.success({
      tipo: OrderPriorizationEvents.OrderPriorizationSuccess,
      userName: userInfo?.name,
      email: userInfo?.email,
      bp: row.clientNumber,
      ov: row.ov,
      ovItem: row.itemOv,
      oc: row.ocClient,
      codProdClient: row.codMaterialClient,
      descMaterial: row.descMaterial,
      dateConf: row.dateMaxDeliveryClient,
      qtyConf: row.qtdConf,
      qtySolic: row.qtdSolClient,
      message: formData.data.order[i].messageClient,
    });
  });
};

interface IHandleErrorConfiguredParams {
  userInfo: UserInfo;
  formData: FormData<IOrderPriorizationData>;
  error: any;
  criticalItems: ICriticalItem[];
}

export const handleErrorConfigured = ({ userInfo, formData, criticalItems, error }: IHandleErrorConfiguredParams) => {
  criticalItems.forEach((criticalItem, index) => {
    LogAnalytics.error({
      tipo: OrderPriorizationEvents.OrderPriorizationError,
      userName: userInfo?.name,
      email: userInfo?.email,
      bp: criticalItem.customerId,
      ov: criticalItem.ov,
      ovItem: criticalItem.ovItem,
      oc: criticalItem.oc,
      codProdClient: criticalItem.codProdCliente,
      descMaterial: criticalItem.descMaterial,
      dateConf: criticalItem.dataConf,
      qtyConf: criticalItem.qtdConf,
      qtySolic: criticalItem.qtdSolic,
      message: formData.data.order[index].messageClient ? formData.data.order[index].messageClient : '',
      currentDate: new Date().toLocaleString('en-GB'),
      errorNumber: error.status,
      errorMessage: error.data.message,
    });
  });
};
