import { FeedbackAnalytics } from '@atomic/obj.feedback/feedback.analytics';

export enum CSATModule {
  technicalAnalysis = 'technicalAnalysis',
  shippingRequest = 'shippingRequest',
  orderUpdate = 'orderUpdate',
  truckTracking = 'truckTracking',
  notifications = 'notifications',
  nationalOrders = 'nationalOrders',
  internationalOrders = 'internationalOrders',
  nationalFinance = 'nationalFinance',
  internationalFinance = 'internationalFinance',
  dashboard = 'dashboard',
  orderInput = 'orderInput',
  nps = 'nps',
  antiFraude = 'antifraude',
}

export enum CSATModuleType {
  funcionalities = 'functionalities',
  information = 'information',
  nps = 'nps',
}

export const funcionalitiesData = [
  CSATModule.shippingRequest,
  CSATModule.orderUpdate,
  CSATModule.truckTracking,
  CSATModule.notifications,
  CSATModule.technicalAnalysis,
  CSATModule.orderInput,
];

export const informationData = [
  CSATModule.nationalOrders,
  CSATModule.internationalOrders,
  CSATModule.nationalFinance,
  CSATModule.internationalFinance,
  CSATModule.dashboard,
];

export const feedbackAnalyticsToCSATModule = {
  [FeedbackAnalytics.TechnicalAnalysisChangeStatus]: CSATModule.technicalAnalysis,
  [FeedbackAnalytics.WalletMI]: CSATModule.nationalOrders,
  [FeedbackAnalytics.WalletME]: CSATModule.internationalOrders,
  [FeedbackAnalytics.FinanceMI]: CSATModule.nationalFinance,
  [FeedbackAnalytics.FinanceME]: CSATModule.internationalFinance,
  [FeedbackAnalytics.TruckTracking]: CSATModule.truckTracking,
  [FeedbackAnalytics.OrderUpdate]: CSATModule.orderUpdate,
  [FeedbackAnalytics.ShippingRequest]: CSATModule.shippingRequest,
  [FeedbackAnalytics.NotificationPanel]: CSATModule.notifications,
  [FeedbackAnalytics.Dashboard]: CSATModule.dashboard,
  [FeedbackAnalytics.OrderInput]: CSATModule.orderInput,
};
