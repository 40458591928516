import {
  OrderStatusConfirmedGraph,
  OrderStatusDeployedGraph,
  OrderStatusGraph,
  OrderStatusOnScheduleGraph,
} from '@app/models/dashboard.model';

export interface StatusDeployedGraphsDTO {
  statusPedido: {
    implantado: {
      qtdSolicitada: number;
    };
  };
}

export interface StatusConfirmedGraphsDTO {
  statusPedido: {
    confirmado: {
      qtdConfirmada: number;
    };
  };
}

export interface StatusScheduledGraphsDTO {
  statusPedido: {
    emAprazamento: {
      qtdSolicitada: number;
    };
  };
}
export interface StatusGraphsDTO {
  statusPedido: {
    implantado: {
      qtdSolicitada: number;
    };
    confirmado: {
      qtdConfirmada: number;
    };
    emAprazamento: {
      qtdSolicitada: number;
    };
    total: {
      qtd: number;
    };
  };
}

export const mapOrderStatusDeployedGraph = (data: StatusDeployedGraphsDTO): OrderStatusDeployedGraph => {
  return {
    deployed: data.statusPedido.implantado?.qtdSolicitada
      ? +data.statusPedido.implantado?.qtdSolicitada?.toFixed(0)
      : 0,
  };
};

export const mapOrderStatusConfirmedGraph = (data: StatusConfirmedGraphsDTO): OrderStatusConfirmedGraph => {
  return {
    confirmed: data.statusPedido.confirmado?.qtdConfirmada
      ? +data.statusPedido.confirmado?.qtdConfirmada?.toFixed(0)
      : 0,
  };
};

export const mapOrderStatusOnScheduledGraph = (data: StatusScheduledGraphsDTO): OrderStatusOnScheduleGraph => {
  return {
    onSchedule: data.statusPedido.emAprazamento?.qtdSolicitada
      ? +data.statusPedido.emAprazamento?.qtdSolicitada?.toFixed(0)
      : 0,
  };
};

export const mapOrderStatusGraph = (data: StatusGraphsDTO): OrderStatusGraph => {
  return {
    deployed: data.statusPedido.implantado?.qtdSolicitada
      ? +data.statusPedido.implantado?.qtdSolicitada?.toFixed(0)
      : 0,
    onSchedule: data.statusPedido.emAprazamento?.qtdSolicitada
      ? +data.statusPedido.emAprazamento?.qtdSolicitada?.toFixed(0)
      : 0,
    confirmed: data.statusPedido.confirmado?.qtdConfirmada
      ? +data.statusPedido.confirmado?.qtdConfirmada?.toFixed(0)
      : 0,
    total: data.statusPedido.total?.qtd ? +data.statusPedido.total?.qtd?.toFixed(0) : 0,
  };
};
