import * as React from 'react';
import { H1 } from '@atomic/atm.typography';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';

interface TechnicalAnalysisViewModalProps {
  opened: boolean;
  onClose: () => void;
  title: string;
}

export const TechnicalAnalysisViewModal: React.FC<TechnicalAnalysisViewModalProps> = props => {
  const handleClose = () => {
    props.onClose?.();
  };

  return (
    <Modal opened={props.opened} onClose={handleClose}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <VSeparator />
            <H1>{props.title}</H1>
            <VSeparator />
            {props.children}
            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
