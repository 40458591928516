import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const ComplementariesWrapper = styled.details`
  color: ${Color.Primary};

  &[open] summary:after {
    content: '▾';
    margin-top: 2px;
  }
`;

export const ComplementariesHeaderWrapper = styled.summary`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 ${Spacing.Medium} 0;

  cursor: pointer;
  & > p {
    position: relative;
    margin-right: 5px;
  }
  &::after {
    content: '▸';
    margin-top: 2px;
  }
`;

export const ComplementariesTableStyled = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

export const GerdauDefaultWrapper = styled.div`
  font-style: italic;
  padding-top: 5px;
`;
