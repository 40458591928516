import styled from 'styled-components';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Grid } from '@atomic/obj.grid';

export const OrderInputUploadSpreadSheetWrapper = styled(Grid)`
  margin-top: ${Spacing.XLarge};
`;

export const MaterialFormWrapper = styled(FlexColumn)`
  width: 60%;
`;

export const MaterialFormOffSet = styled(FlexRow)`
  width: 40%;
`;
