import React from 'react';
import { Link } from '@app/core/route';
import { Image } from '@atomic/atm.image';
import { AppPath } from '../route-constants';
import { AdminHeaderLogoStyled, AdminHeaderWrapperStyled } from './admin-header.component.style';
import '@gerdau/hefesto-design-tokens/css/default/theme-1/light.css';
import '@gerdau/hefesto-design-tokens/css/globals.css';

export const AdminHeader: React.FunctionComponent = () => {
  return (
    <AdminHeaderWrapperStyled>
      <AdminHeaderLogoStyled>
        <Link to={AppPath.ADMIN.BASE}>
          <Image.LogoGerdauMais />
        </Link>
      </AdminHeaderLogoStyled>
    </AdminHeaderWrapperStyled>
  );
};
