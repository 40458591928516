import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { AppPath } from '@app/modules/app/route-constants';
import { SidebarFiltersBody } from './partials/sidebar-filters-body/sidebar-filters-body.component';
import { SidebarFiltersFooter } from './partials/sidebar-filters-footer/sidebar-filters-footer.component';
import { SidebarFiltersHeader } from './partials/sidebar-filters-header/sidebar-filters-header.component';
import { SidebarFilterReference, SidebarFilterWrapper } from './sidebar-filters.style';

export const SidebarFilters: React.FC = () => {
  const { openSidebarFilters, openFinanceSidebarFilters } = useSelector((state: RootState) => state.navigation);
  const history = useHistory();

  const handleActive = () => {
    if (history.location.pathname === AppPath.FINANCE) {
      return openFinanceSidebarFilters;
    } else {
      return openSidebarFilters;
    }
  };

  return (
    <SidebarFilterReference active={handleActive()}>
      <SidebarFilterWrapper active={handleActive()}>
        <SidebarFiltersHeader />
        <SidebarFiltersBody />
        <SidebarFiltersFooter />
      </SidebarFilterWrapper>
    </SidebarFilterReference>
  );
};
