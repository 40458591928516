import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TableRowData } from '@app/data/http/quote-params.dto';
import { Button } from '@atomic/atm.button';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { H1, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ChatStrings } from './chat.string';
import { ModalGenericValue } from './modal-generic-value.component';

export enum MicroinclusaoField {
  MICRO_A_FINO = 'MICRO_A_FINO',
  MICRO_A_GROSSO = 'MICRO_A_GROSSO',
  MICRO_B_FINO = 'MICRO_B_FINO',
  MICRO_B_GROSSO = 'MICRO_B_GROSSO',
  MICRO_C_FINO = 'MICRO_C_FINO',
  MICRO_C_GROSSO = 'MICRO_C_GROSSO',
  MICRO_D_FINO = 'MICRO_D_FINO',
  MICRO_D_GROSSO = 'MICRO_D_GROSSO',
}

export interface MicroinclusaoData {
  [MicroinclusaoField.MICRO_A_FINO]: string | number;
  [MicroinclusaoField.MICRO_A_GROSSO]: string | number;
  [MicroinclusaoField.MICRO_B_FINO]: string | number;
  [MicroinclusaoField.MICRO_B_GROSSO]: string | number;
  [MicroinclusaoField.MICRO_C_FINO]: string | number;
  [MicroinclusaoField.MICRO_C_GROSSO]: string | number;
  [MicroinclusaoField.MICRO_D_FINO]: string | number;
  [MicroinclusaoField.MICRO_D_GROSSO]: string | number;
}
export interface CustomData {
  label: string | number;
  values: string | number;
}
interface MicroinclusaoRowProps {
  label: string;
  data: MicroinclusaoData | CustomData;
}

const MicroinclusaoRow: React.FC<MicroinclusaoRowProps> = props => {
  const convertDataToNumber = (val: string) => {
    if (val) {
      return +val.toString().replace(',', '.');
    }
    return val;
  };

  return (
    <TR>
      <TD>
        <H3>{props.label}</H3>
      </TD>
      <TD>
        <Form.Field
          name={`MICRO_${props.label}_FINO`}
          initialValue={convertDataToNumber(props.data?.[`MICRO_${props.label}_FINO`])}
          validators={[Validators.Required('Obrigatório')]}
          hideErrorCaption
        >
          <TextField placeholder='Fina' type='number' />
        </Form.Field>
      </TD>
      <TD> - </TD>
      <TD>
        <Form.Field
          name={`MICRO_${props.label}_GROSSO`}
          initialValue={convertDataToNumber(props.data?.[`MICRO_${props.label}_GROSSO`])}
          validators={[Validators.Required('Obrigatório')]}
          hideErrorCaption
        >
          <TextField placeholder='Grossa' type='number' />
        </Form.Field>
      </TD>
    </TR>
  );
};

interface ModalMicroinclusaoProps {
  openTableType: string;
  data: MicroinclusaoData | CustomData;
  onSubmit?: (data: TableRowData[], key: string) => void;
  onClose: () => void;
}

export const ModalMicroinclusao: React.FC<ModalMicroinclusaoProps> = props => {
  const [reset, setReset] = React.useState(0);
  const [opened, setOpened] = React.useState(false);
  const [newAttributeForm, setNewAttributeForm] = React.useState(false);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language];

  React.useEffect(() => {
    if (props.openTableType.toLowerCase() === 'microinclusao') {
      setOpened(true);
    }
  }, [props.openTableType]);

  const handleSubmit = (data: FormData<MicroinclusaoData> | any) => {
    if (Object.keys(data.error).length > 0) {
      return;
    }
    props.onSubmit(data.data?.values ? data.data.values : setTableParams(data.data), 'MICRO_INCLUSION');
    setOpened(false);
    setNewAttributeForm(false);
  };

  const setTableParams = (data: MicroinclusaoData) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== 'ASTM') {
        acc.push([
          { columnRef: 'elements', value: key.split('_')[1] },
          { columnRef: 'series', value: key.split('_')[2] },
          { columnRef: 'value', value: value },
        ]);
      }

      return acc;
    }, []);
  };

  const microinclusaoLabels = ['A', 'B', 'C', 'D'];

  const handleReset = () => {
    setReset(reset + 1);
  };

  const handleClose = () => {
    setOpened(false);
    props.onClose();
  };

  const onSelectChange = value => {
    if (value === 'Outros') {
      setNewAttributeForm(true);
    } else {
      setNewAttributeForm(false);
    }
  };

  return (
    <Modal preventOverlayClick opened={opened} small onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row mb>
            <Col xs={12}>
              <H1>{!newAttributeForm ? strings.modalMicroinclusao.title : strings.modalGenericValues.title}</H1>
            </Col>
            <Col xs={4}>
              <Form.Field
                name={'filter'}
                initialValue={strings.modalMicroinclusao.selectOptions[0]}
                hideErrorCaption
                onValueChange={e => onSelectChange(e)}
              >
                <SelectField>
                  {strings.modalMicroinclusao.selectOptions.map(el => (
                    <option value={el} key={el}>
                      {el}
                    </option>
                  ))}
                </SelectField>
              </Form.Field>
            </Col>
          </Row>
          {!newAttributeForm ? (
            <Row mb key={reset}>
              <Col xs={12}>
                <Table>
                  {microinclusaoLabels.map((label: string) => (
                    <MicroinclusaoRow label={label} key={label + reset} data={props.data} />
                  ))}
                </Table>
              </Col>
            </Row>
          ) : (
            <ModalGenericValue type={strings.modalMicroinclusao.title} />
          )}
          <Hbox hAlign='flex-end'>
            <Hbox.Item noGrow>
              <Button kind='secondary' expanded onClick={handleReset}>
                {strings.modalMicroinclusao.btnReset}
              </Button>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item noGrow>
              <Button kind='primary' expanded type='submit'>
                {strings.modalMicroinclusao.btnSubmit}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};

ModalMicroinclusao.defaultProps = {
  openTableType: '',
};
