import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { marketMap } from '@app/modules/components/customer-search/customer-search.constants';
import { SearchButton } from '@app/modules/components/search-button/search-button.component';
import { TextField } from '@atomic/atm.text-field';
import { FlexRow } from '@atomic/obj.box';
import { ICustomerSearchModalHeaderAdminStrings, Strings } from '../../../customer-search-modal-header-admin.string';
import { SearchFieldWrapper } from '../search-field.style';
import { SearchFieldOptionsCorporateGroup } from './search-field-options-corporate-group.component';
import { getUnselectedOptions } from './utils';

export const SearchFieldCorporateGroup: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ICustomerSearchModalHeaderAdminStrings = Strings[userInfo.language];

  const [showOptions, setShowOptions] = useState(false);
  const [querySearch, setQuerySearch] = useState('');

  const { filterOptionSearch, corporateGroupSearch, clientBpsSearch } = useSelector(
    (state: RootState) => state.customerSearch,
  );

  const filteredCustomerSearch = clientBpsSearch.filter(
    bp => bp.corpGroup?.includes(querySearch) || bp.corpGroupName?.toUpperCase()?.includes(querySearch.toUpperCase()),
  );

  const getSelectedOptionsConfigured = useCallback(
    () =>
      getUnselectedOptions({
        data: filteredCustomerSearch,
        market: marketMap[filterOptionSearch?.market],
        corporateGroupSearch,
      }),
    [filteredCustomerSearch, filterOptionSearch, corporateGroupSearch],
  );

  const unselectedOptions = getSelectedOptionsConfigured();

  const handleValueChange = value => setQuerySearch(value);

  const handleSearch = () => setShowOptions(true);

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      handleSearch();
    }
  };

  const handleTyping = () => {
    if (querySearch.length > 2) {
      handleSearch();
    } else {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    handleTyping();
  }, [querySearch]);

  return (
    <SearchFieldWrapper>
      <FlexRow>
        <TextField
          value={querySearch}
          disabled={!filterOptionSearch}
          onFocus={handleTyping}
          onClick={e => e.stopPropagation()}
          onKeyPress={handleKeyPress}
          onValueChange={handleValueChange}
          placeholder={strings.corporateGroupPlaceholder}
          hasButton
        >
          <SearchButton handleSearch={handleSearch} />
        </TextField>
      </FlexRow>
      {!!filteredCustomerSearch.length && showOptions && (
        <SearchFieldOptionsCorporateGroup options={unselectedOptions} setShowOptions={setShowOptions} />
      )}
    </SearchFieldWrapper>
  );
};
