import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const ShippingRequestSuggestionModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  border-top: 2px solid ${Color.GrayLight};
  border-bottom: 2px solid ${Color.GrayLight};
  padding: ${Spacing.Medium};
  padding-bottom: ${Spacing.Medium};
  padding-left: ${Spacing.XLarge};
`;
