import { ColDef, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { MutableRefObject } from 'react';
import { LogAnalytics } from '@app/core/analytics';
import { InStockOrderItem } from '@app/models';
import { LayoutTabs } from '@app/models/layout.model';
import { StockStatusKey } from '@app/modules/order/grid-filters/grid-stock-row-filter.component';
import { ShippingRequestEvents } from '@app/modules/order/order.analytics';

interface HandleSelectionChangeParams {
  e: SelectionChangedEvent;
  setSelectedRows: (value: InStockOrderItem[]) => void;
}

export const handleStockSelectionChange = ({ e, setSelectedRows }: HandleSelectionChangeParams) => {
  const selectedRowsArray = e.api.getSelectedNodes();

  if (selectedRowsArray) {
    const selectedRowsAux = [];

    selectedRowsArray.forEach(r => {
      if (r.data.deliveryQuantity + r.data.pickingQuantity < r.data.stockQuantity) {
        selectedRowsAux.push(r.data);
      } else {
        r.setSelected(false, true, true);
      }
    });

    setSelectedRows(selectedRowsAux);
  } else {
    setSelectedRows([]);
  }
};

export const handleShippingRequestClick = () => {
  LogAnalytics.click({ tipo: ShippingRequestEvents.ShippingRequestClick });
};

export const handleStockTabColumn = (tabName: string, columns: ColDef[]) => {
  if (tabName === LayoutTabs.StockMI) {
    if (columns) {
      columns.forEach(column => {
        if (column.field === 'checkboxSelection') {
          column.checkboxSelection = params => {
            if (params.data.deliveryQuantity + params.data.pickingQuantity >= params.data.stockQuantity) {
              return false;
            } else {
              return true;
            }
          };
          column.showDisabledCheckboxes = true;
          column.tooltipComponent = 'customTooltip';
          column.tooltipField = 'deliveryQuantity';
        }

        if (column.field === 'status') {
          column.filter = 'agSetColumnFilter';
          column.floatingFilter = false;
        }
      });
    }
  }
};

interface HandleFilterParams {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  filter: string;
  handleFilterCheck: ({ checkedFilters, statusFilter }: { checkedFilters: string; statusFilter: string[] }) => any;
}

export const handleFilter = ({ gridEvent, filter, handleFilterCheck }: HandleFilterParams) => {
  const statusFilter: StockStatusKey[] = [StockStatusKey.Expired, StockStatusKey.NotExpired];

  if (!gridEvent || !gridEvent.current) {
    return;
  }

  const filterData = gridEvent.current.api.getFilterInstance('status');
  if (filterData) {
    const aux = handleFilterCheck({ checkedFilters: filter, statusFilter });

    const model = Object.keys(aux).reduce((acc, cur) => {
      if (aux[cur]) {
        acc.push(cur);
      }

      return acc;
    }, []);

    filterData.setModel({ values: model });
    gridEvent.current.api.onFilterChanged();
  }
};

interface HandleFilterCheckParams {
  checkedFilters: string;
  statusFilter: string[];
}

export const handleFilterCheck = ({ checkedFilters, statusFilter }: HandleFilterCheckParams) => {
  let expired = true;
  let notExpired = true;
  let cgDataCheck = true;
  let checkedFilterSplit = [];

  if (checkedFilters) {
    checkedFilterSplit = checkedFilters.split(',');

    statusFilter.forEach(c => {
      if (c === StockStatusKey.Expired) {
        const isCheckedImplanted = checkedFilterSplit.find(chk => chk === StockStatusKey.Expired);
        isCheckedImplanted ? (expired = true) : (expired = false);
      }

      if (c === StockStatusKey.NotExpired) {
        const isCheckedOnSchedule = checkedFilterSplit.find(chk => chk === StockStatusKey.NotExpired);
        isCheckedOnSchedule ? (notExpired = true) : (notExpired = false);
      }
    });
  } else {
    expired = false;
    notExpired = false;
    cgDataCheck = true;
  }

  return {
    [StockStatusKey.Expired]: expired,
    [StockStatusKey.NotExpired]: notExpired,
    [StockStatusKey.CGData]: cgDataCheck,
  };
};
