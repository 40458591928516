import styled from 'styled-components';
import { Border, Color, Spacing } from '@atomic/obj.constants';

interface AmountTableControlsStyledProps {
  show?: boolean;
}

export const AmountTableStyled = styled.div`
  z-index: 10;
  table {
    border-collapse: collapse;
    border: 2px solid ${Color.Secondary};
    th {
      background-color: ${Color.Secondary};
      padding: ${Spacing.Medium} 0px;
    }

    tr {
      border-bottom: 2px solid ${Color.Secondary};
      td {
        input {
          height: 40px;
          background-color: ${Color.GrayXLight};
          border: 2px solid ${Color.GrayLight};
          border-radius: ${Border.Radius};
          text-align: center;
        }
      }
    }
  }
`;

export const AmountTableControlsStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: ${Spacing.Small};
  padding-bottom: ${Spacing.Small};
  transition: all 0.5s ease-in-out;
  height: ${(props: AmountTableControlsStyledProps) => (props.show ? '48px' : '0px')};

  button {
    display: ${(props: AmountTableControlsStyledProps) => (props.show ? 'block' : 'none')};
  }
`;
