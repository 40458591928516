import styled from 'styled-components';

export const ShippingBoxPlaceholder = styled.div`
  height: 125px;
`;

export const ShippingRequestBodyWrapper = styled.div`
  overflow: auto;
  max-width: 100vw;
`;
