import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { H1 } from '@atomic/atm.typography';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Hbox, Separator } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';

interface CrmShimmerProps {
  tab?: string;
}

export const CrmShimmer = (props: CrmShimmerProps) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  return (
    <>
      <Hbox>
        <Hbox.Item>
          <H1>{props?.tab ? 'RC CRM' : TechnicalAnalysisStrings[userInfo.language].header.title}</H1>
          <Separator />
          <TextShimmerBoxStyled height='24px' width='50%' margin='0' />
        </Hbox.Item>
      </Hbox>
      <VSeparator />
    </>
  );
};
