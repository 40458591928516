import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { PlaceholderWrapperStyled, TrackingPlaceholderStyled } from '../../../components/placeholder.style';
import { RESET_TRUCK_TRACKING_ACTION, setShowTruckTracking } from '../../store/truck-tracking.store';
import { Strings, ITruckTrackingStrings } from '../../truck-tracking.string';

export const TruckTrackingNoData: React.FC = () => {
  const strings: ITruckTrackingStrings = Strings.pt;
  const dispatch = useDispatch();

  const handleClickBackButton = () => {
    dispatch(RESET_TRUCK_TRACKING_ACTION);
    dispatch(setShowTruckTracking(false));
  };

  return (
    <PlaceholderWrapperStyled>
      <TrackingPlaceholderStyled>
        <FlexRow>
          <FlexColumn hAlign='center'>
            <Image.NoTrackingPlaceholder />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn hAlign='center'>
            <H2>{strings.noTracking}</H2>
          </FlexColumn>
        </FlexRow>
        <FlexRow pt hAlign='center'>
          <Button onClick={handleClickBackButton}>{strings.header.back}</Button>
        </FlexRow>
        <VSeparator small />
      </TrackingPlaceholderStyled>
    </PlaceholderWrapperStyled>
  );
};
