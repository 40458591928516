import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { PopUpBoxStyled, PopUpCloseStyled, PopUpStyled } from './pop-up.component.style';

interface PopUpProps {
  opened: boolean;
  onClose: () => void;
  expanded: boolean;
}

export const PopUp: React.FC<PopUpProps> = props => {
  return (
    <PopUpStyled opened={props.opened}>
      {props.opened && (
        <PopUpBoxStyled expanded={props.expanded}>
          <PopUpCloseStyled>
            <FaIcon.Close onClick={props.onClose} />
          </PopUpCloseStyled>
          {props.children}
        </PopUpBoxStyled>
      )}
    </PopUpStyled>
  );
};
