import React from 'react';
import { useHistory } from 'react-router';
import { LogAnalytics } from '@app/core/analytics';
import { AppPath } from '@app/modules/app/route-constants';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ChatStrings } from '../chat.string';

interface ModalConfirmationProps {
  cfId: string;
  opened: boolean;
  setChatResult: (val: any) => void;
  language: string;
}

export const ModalConfirmationForm = (props: ModalConfirmationProps) => {
  const history = useHistory();
  const strings = ChatStrings[props.language].newForm.confirmationModal;

  const redirectSubmit = (action: string) => {
    props.setChatResult(null);
    if (action === 'addAttributes') {
      LogAnalytics.click({
        tipo: 'EditarNormaForm',
      });
      history.push(`${AppPath.CHAT.BASE}/edit/${props.cfId}`);
    } else {
      history.push(`${AppPath.QUOTE.ANALYSIS}/${props.cfId}`);
    }
  };

  return (
    <Modal preventOverlayClick opened={props.opened} small customHeight='100'>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <H2>{strings.title}</H2>

            <Body>{strings.intro}</Body>
            <VSeparator />
            <VSeparator />
            <Hbox>
              <Hbox.Item grow>
                <Button expanded kind='secondary' onClick={() => redirectSubmit('addAttributes')}>
                  {strings.editButton}
                </Button>
              </Hbox.Item>
              <Hbox.Separator small />

              <Hbox.Item grow>
                <Button expanded kind='callToAction' type='submit' onClick={() => redirectSubmit('submit')}>
                  {strings.submitButton}
                </Button>
              </Hbox.Item>
            </Hbox>
            <VSeparator />
            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
