import * as React from 'react';
import { FullAnalysisData } from '@app/models/quote.model';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Tab } from '@atomic/mol.tab';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
interface TechnicalAnalysisPlantsTabProps {
  analysis?: FullAnalysisData;
  tabIndex: number;
  handleTabChange: (index: number) => void;
}

export const TechnicalAnalysisPlantsTab = (props: TechnicalAnalysisPlantsTabProps) => {
  const [plants, setPlants] = React.useState(null);

  React.useEffect(() => {
    setPlants(props.analysis.plants);
  }, [plants]);

  return (
    <>
      {props.analysis && (
        <Tab initialIndex={props.tabIndex} onIndexChanged={props.handleTabChange}>
          {props.analysis.plants.map(plant => (
            <Tab.Item key={plant.label} id={plant.label}>
              <Hbox vAlign='center' fullHeight={true}>
                {plant.selection?.isSelected && plant.review?.isReviewed && (
                  <>
                    <Hbox.Item vAlign='center' noGrow>
                      <FaIcon.Check size='1x' color={Color.Success} />
                    </Hbox.Item>
                    <Hbox.Separator small />
                  </>
                )}
                <Hbox.Item>
                  {plant.label}
                  {plant.review?.isRequested && (!plant.selection?.isSelected || !plant.review?.isReviewed) && '*'}
                </Hbox.Item>
              </Hbox>
            </Tab.Item>
          ))}
        </Tab>
      )}
    </>
  );
};
