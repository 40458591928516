import styled from 'styled-components';
import { Color, ZIndex } from '@atomic/obj.constants';

interface AppConfigurationOptionsStyledProps {
  show?: boolean;
  isMaritime?: boolean;
}
export const AppConfigurationOptionsStyled = styled.div`
  opacity: ${(props: AppConfigurationOptionsStyledProps) => (props.show ? 1 : 0)};
  max-height: ${(props: AppConfigurationOptionsStyledProps) => (props.show ? '600px' : 0)};
  z-index: ${ZIndex.AppConfigurationOptionsStyled};
  position: absolute;
  background-color: ${Color.White};
  width: ${(props: AppConfigurationOptionsStyledProps) => (props.show ? '230px' : 0)};
  right: ${(props: AppConfigurationOptionsStyledProps) => (props.isMaritime ? '-14px' : '-10px')};
  top: 46px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
`;
