import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { hasError, hasInputError } from '@app/modules/order-input/order-input-validation';
import {
  AMOUNT_TABLE_AMOUNT_TOOLTIP_ARROW_LEFT_POSITION_REF,
  AMOUNT_TABLE_AMOUNT_TOOLTIP_LEFT_POSITION_REF,
  AMOUNT_TABLE_AMOUNT_TOOLTIP_TOP_POSITION_REF,
  AMOUNT_TABLE_AMOUNT_TOOLTIP_WIDTH,
  MaterialCell,
} from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { FlexColumn } from '@atomic/obj.box';
import { FontSize, Spacing } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { handleAmountAndUnityChange } from '../utils';
import { AmountErrorWrapper, AmountInput } from './amount-field.style';

interface IAmountFieldProps {
  material: Material;
  index: number;
}

const strings = OrderInputStrings.pt.dateAndAmount;

export const AmountField: React.FC<IAmountFieldProps> = ({ material, index }) => {
  const { issuerOptions, selectedIssuer } = useSelector((state: RootState) => state.orderInput);
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const ref = useRef(null);

  const handleOnInput = e => {
    const val = e.target.value.replace(/[^0-9.]/g, '').replace(/(..*)./g, '$1');
    return val;
  };

  let topTooltip = 0;
  let leftTooltip = 0;

  if (ref?.current) {
    const iconBoundings = ref?.current?.getBoundingClientRect();
    topTooltip = iconBoundings.top;
    leftTooltip = iconBoundings.left;
  }

  const customTop = topTooltip + AMOUNT_TABLE_AMOUNT_TOOLTIP_TOP_POSITION_REF;
  const customLeft = leftTooltip - AMOUNT_TABLE_AMOUNT_TOOLTIP_LEFT_POSITION_REF;
  const isFirstField = index === 0;

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current) {
      const isOutsideClick = ref && !ref.current.contains(event.target);

      if (isOutsideClick) {
        setShow(false);
      }
    }
  };

  useEventListener('click', handleClickOutside);

  return (
    <FlexColumn>
      <FlexColumn ref={ref} pt={Spacing.Medium}>
        <AmountInput
          className='numberInputBox'
          hasError={hasError(material, MaterialCell.Amount)}
          onInput={e => handleOnInput(e)}
          defaultValue={material.amount}
          onBlur={e => {
            const updatedMaterial = { ...material };
            updatedMaterial.amount = e.target.value;
            handleAmountAndUnityChange(
              updatedMaterial,
              issuerOptions.find(option => option.customerId === selectedIssuer.value)?.salesOrg,
              dispatch,
            );
          }}
        />
        <CustomizedTooltip
          description={strings.amountMessageTooltip}
          visible={show && isFirstField}
          onClick={() => setShow(false)}
          onClose={() => setShow(false)}
          customHeight={customTop}
          customLeftAlign={customLeft}
          customArrowX={AMOUNT_TABLE_AMOUNT_TOOLTIP_ARROW_LEFT_POSITION_REF}
          hasFooter={false}
          hasHeader={false}
          customWidth={AMOUNT_TABLE_AMOUNT_TOOLTIP_WIDTH}
          fontSize={FontSize.XSmall}
          hasOffset
        />
      </FlexColumn>
      <AmountErrorWrapper>{hasInputError(material, MaterialCell.Amount)}</AmountErrorWrapper>
    </FlexColumn>
  );
};
