import { format } from 'date-fns';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { Body, InputLabel } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { FormContext } from '../../form.context';
import { FormStrings } from '../../form.string';
import { Field } from '../form-flow-fields.container';
import { FormResultEditOptions } from './form-result-edit-options';
import { FormResultAttributeWrapper, FormResultOptionsWrapper } from './form-result.component.style';

interface AttributeProps {
  attribute?: Field;
  attrKey?: string;
  stepKey?: string;
}

export const Attribute: React.FC<AttributeProps> = props => {
  const { focusAttribute } = React.useContext(FormContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language];

  const convertValue = (value: any, type: string, key: string) => {
    const values = [];
    switch (type) {
      case 'date':
        return value && format(new Date(value), 'dd/MM/yyyy');
      case 'checkbox':
      case 'textArray':
        value !== undefined &&
          value.forEach((val: string) => {
            if (strings[key]) {
              return values.push(strings[key][val]);
            } else {
              return values.push(val);
            }
          });
        return values.join(', ');
      case 'range':
        if (strings.resumeAnalysis[value]) {
          return strings.resumeAnalysis[value];
        } else {
          const val = value.replaceAll('undefined', '-');
          return val.includes('- a -') ? null : value;
        }
      case 'number':
      case 'text':
      case 'select':
      case 'autocomplete':
      case 'radio':
        if (value?.label) {
          return value.label;
        } else if (strings.resumeAnalysis[value]) {
          return strings.resumeAnalysis[value];
        } else {
          return `${value}`;
        }
      case 'table':
        if (value?.label) {
          return value.label;
        } else if (strings.resumeAnalysis[value]) {
          return strings.resumeAnalysis[value];
        } else {
          return value;
        }
    }
  };

  return convertValue(props.attribute?.value, props.attribute?.type, props.attrKey) &&
    props.attribute?.permissions?.visible ? (
    <FormResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.readBy === 'nlu'}
      onClick={() => focusAttribute(props.attrKey)}
    >
      <Hbox.Item>
        <FormResultOptionsWrapper>
          <InputLabel>
            {props.attribute?.label}
            {props.attribute?.readBy === 'nlu' ? (
              <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
            ) : (
              props.attribute?.readBy === 'GERDAU' && ' *'
            )}
          </InputLabel>
          <FormResultEditOptions
            stepKey={props.stepKey}
            editable={props.attribute.permissions.editable}
            attrKey={!props.attrKey.includes('ADDITIONAL_') ? props.attrKey : 'ADDITIONAL_DIAMETER'}
            type={props.attribute.type}
            attribute={props.attribute}
          />
        </FormResultOptionsWrapper>

        <Body bold={600} color={Color.Accessory}>
          {props.attrKey === 'CLIENT_CUSTOM'
            ? `${props.attribute?.value.toUpperCase()}`
            : `${props.attrKey === 'LENGTH_MULTIPLES' ? 'Múltiplo: ' : ''} ${convertValue(
                props.attribute?.value,
                props.attribute?.type,
                props.attrKey,
              )}`}
          {props.attribute?.unit && ` ${props.attribute?.unit?.description}`}
          {props.attrKey === 'LENGTH_MULTIPLES' &&
            props?.attribute?.min &&
            ` - ${props?.attribute?.min} a ${props?.attribute?.max} ${props.attribute?.unit?.description}`}
        </Body>
      </Hbox.Item>
    </FormResultAttributeWrapper>
  ) : null;
};
