export interface ISchedulingCompletedConfigModalStrings {
  title: string;
  message: string;
  aditionalDays: string;
  confirmButton: string;
  cancelButton: string;
}

interface ISchedulingCompletedConfigModalLanguages {
  pt: ISchedulingCompletedConfigModalStrings;
  es: ISchedulingCompletedConfigModalStrings;
  in: ISchedulingCompletedConfigModalStrings;
}

const pt: ISchedulingCompletedConfigModalStrings = {
  title: 'Alerta de fechamento de aprazamento',
  message:
    'Tem certeza que deseja alertar os usuários que possuem acesso à aba Carteira sobre a conclusão do aprazamento atual?',
  aditionalDays: 'Dias adicionais:',
  confirmButton: 'Sim, enviar alerta',
  cancelButton: 'Cancelar',
};

const es: ISchedulingCompletedConfigModalStrings = {
  title: 'Alerta de fechamento de aprazamento',
  message:
    'Tem certeza que deseja alertar os usuários que possuem acesso à aba Carteira sobre a conclusão do aprazamento atual?',
  aditionalDays: 'Dias adicionais:',
  confirmButton: 'Si, enviar alerta',
  cancelButton: 'Cancelar',
};

const en: ISchedulingCompletedConfigModalStrings = {
  title: 'Alerta de fechamento de aprazamento',
  message:
    'Tem certeza que deseja alertar os usuários que possuem acesso à aba Carteira sobre a conclusão do aprazamento atual?',
  aditionalDays: 'Dias adicionais:',
  confirmButton: 'Sim, enviar alerta',
  cancelButton: 'Cancel',
};

export const Strings: ISchedulingCompletedConfigModalLanguages = {
  pt,
  es: es,
  in: en,
};
