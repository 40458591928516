import * as React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputLabel } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import {
  SwitchBackgroundStyled,
  SwitchHandleStyled,
  SwitchIconWrapperStyled,
  SwitchRootStyled,
  SwitchStyledProps,
  SwitchWrapperStyled,
} from './switch.component.style';

export interface SwitchProps extends SwitchStyledProps {
  onChange?: (checked: boolean, id: any, event: any) => void;
  id?: any;
  alert?: boolean;
  checkedIcon?: string;
  uncheckedIcon?: string;
}

interface SwitchState {
  checked: boolean;
  disabled: boolean;
}

export class Switch extends React.Component<SwitchProps, SwitchState> {
  constructor(props: SwitchProps) {
    super(props);

    this.state = {
      checked: !!props.checked,
      disabled: !!props.disabled,
    };
  }

  componentDidUpdate(prevProps: SwitchProps) {
    if (prevProps.checked !== this.props.checked && this.props.checked !== this.state.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  render() {
    const { checked, disabled } = this.state;
    return (
      <SwitchWrapperStyled>
        <SwitchRootStyled
          data-testid={`request-button-${this.state.checked}`}
          disabled={disabled}
          onClick={disabled ? null : this.handleClick}
        >
          <SwitchBackgroundStyled checked={checked} alert={this.props.alert}>
            &nbsp;
          </SwitchBackgroundStyled>
          <SwitchHandleStyled checked={checked} disabled={disabled} alert={this.props.alert}>
            {this.props.checkedIcon && this.props.uncheckedIcon && (
              <SwitchIconWrapperStyled>
                {checked ? (
                  <FaIcon.Custom icon={this.props.checkedIcon} color={Color.White} size='sm' />
                ) : (
                  <FaIcon.Custom icon={this.props.uncheckedIcon} color={Color.White} size='sm' />
                )}
              </SwitchIconWrapperStyled>
            )}
          </SwitchHandleStyled>
        </SwitchRootStyled>
        <InputLabel>{this.props.children}</InputLabel>
      </SwitchWrapperStyled>
    );
  }

  private handleClick = (event: any) => {
    const { onChange, id } = this.props;

    const { checked } = this.state;
    if (onChange) {
      onChange(!this.state.checked, id, event);
    }
    this.setState({ checked: !checked });
  };
}
