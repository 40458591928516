import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME } from './grid-layout.constants';
import { initialState } from './grid-layout.initial';
import { IGridLayoutState } from './grid-layout.interfaces';
import { reducers } from './grid-layout.reducers';
import { addGridLayoutThunks } from './thunks/grid-layout.thunks';

export const gridLayout = createSlice<IGridLayoutState, SliceCaseReducers<IGridLayoutState>>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addGridLayoutThunks,
});

export const { setColumnVisible, setTabColumns } = gridLayout.actions;

export const gridLayoutReducer = gridLayout.reducer;
