/* eslint-disable prettier/prettier */
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '@app/core/redux/store';
import { ConfigColumnsDatasource } from '@app/data/http';
import { ConfigColumnsModel } from '@app/models';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID, SLICE_NAME } from '../../order-input.constants';
import type { IOrderInputState } from '../../order-input.interfaces';


export const postSpreadsheetLayoutThunk = createAsyncThunk(
  `${SLICE_NAME}/postSpreadsheetLayout`,
  async (_, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const preCustomerIds: string = customerIdsJoinedSelector(state);
    const customerIds = preCustomerIds.replace(MUSASHI_CUSTOMER_ID, MUSASHI_REPLACE_CUSTOMER_ID);

    const params: ConfigColumnsModel = {
      ...state.orderInput.configColumnsSpreadsheet,
      customerIds,
    };

    return await ConfigColumnsDatasource.postConfigColumns(params)
      .then(data => data).catch(err => {
        return thunkAPI.rejectWithValue({ status: err.response.status, message: err.response.message });
      });
  });

export const addPostSpreadsheetLayoutThunk = builder => {
  builder.addCase(postSpreadsheetLayoutThunk.pending, (state: IOrderInputState) => {
    state.loadingPostConfigColumnsSpreadsheet = true;
  });
  builder.addCase(postSpreadsheetLayoutThunk.fulfilled, (state: IOrderInputState) => {
    state.loadingPostConfigColumnsSpreadsheet = false;
    state.openConfigColumnSpreadsheet = false;

  });
  builder.addCase(postSpreadsheetLayoutThunk.rejected, (state: IOrderInputState) => {
    state.loadingPostConfigColumnsSpreadsheet = false;
  });
};
