import React from 'react';
import { useDispatch } from 'react-redux';
import { SearchByMaterial } from '@app/modules/order-input/order-input.constants';
import { setSearchBy } from '@app/modules/order-input/store/order-input.store';
import { RadioField } from '@atomic/atm.radio';

interface ISearchByFieldOptionParams {
  value: SearchByMaterial;
  text: string;
}

export const SearchByFieldOption: React.FC<ISearchByFieldOptionParams> = ({ value, text }) => {
  const dispatch = useDispatch();

  return (
    <RadioField
      testid={`radio-field-${value}`}
      onClick={() => {
        dispatch(setSearchBy(value));
      }}
      id={value}
    >
      {text}
    </RadioField>
  );
};
