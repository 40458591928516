import ColorFunc from 'color';
import styled, { css } from 'styled-components';
import { Color, FontSize, FontWeight, Spacing, TransitionDuration, ZIndex } from '@atomic/obj.constants';

interface TechnicalAnalysisStatusBoxStyledProps {
  show?: boolean;
}

export const TOOLTIP_WIDTH = 500;

export const BoxStyled = css`
  opacity: ${(props: TechnicalAnalysisStatusBoxStyledProps) => (props.show ? 1 : 0)};
  max-height: ${(props: TechnicalAnalysisStatusBoxStyledProps) => (props.show ? '600px' : 0)};
  z-index: ${ZIndex.BoxStyled};
  position: absolute;
  background-color: ${Color.White};
  width: ${(props: TechnicalAnalysisStatusBoxStyledProps) => (props.show ? '260px' : 0)};
  transition: all ${TransitionDuration} ease-in-out;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  align-content: flex-start;
`;

export const TechnicalAnalysisAttributeWrapperStyled = styled.div`
  position: relative;
  background-color: ${Color.White};
  width: 100%;
  margin-bottom: 16px;
  height: 350px;
  overflow: scroll;
`;

export const TechnicalAnalysisDerogateAttributeWrapperStyled = styled.div`
  position: relative;
  background-color: ${ColorFunc(Color.Warning)
    .lighten(0.9)
    .blacken(0.1)
    .hsl()
    .string()};
  width: 100%;
  margin-bottom: ${Spacing.Large};
  & > div {
    border-color: ${ColorFunc(Color.Warning)
      .lighten(0.6)
      .blacken(0.1)
      .hsl()
      .string()};
    & > p {
      color: ${ColorFunc(Color.Warning)
        .darken(0.5)
        .hsl()
        .string()};
    }
    & > svg {
      color: ${ColorFunc(Color.Warning)
        .darken(0.2)
        .hsl()
        .string()};
    }
  }
`;

export const TitleDerogateListWrapper = styled.div`
  margin-bottom: ${Spacing.Small};
`;

interface TechnicalAnalysisTabWrapperStyledProps {
  hasPrice: boolean;
}

export const TechnicalAnalysisTabWrapperStyled = styled.div`
  ${(props: TechnicalAnalysisTabWrapperStyledProps) => props.hasPrice && 'height: auto;'}
`;

export const TechnicalAnalysisStatusBoxStyled = styled.div`
  ${BoxStyled};
  top: 50px;
  right: 5px;
`;

export const TechnicalAnalysisAboutBoxStyled = styled.div`
  ${BoxStyled};
  top: 50px;
  right: 5px;
`;

export const TechnicalAnalysisContentStyled = styled.div`
  cursor: pointer;
  padding-left: ${Spacing.Medium};
  padding-top: ${Spacing.Small};
  padding-bottom: ${Spacing.Small};
`;

export const UnfinishedAdviceWrapperStyled = styled.div`
  & > div > div {
    align-items: center;
    button {
      color: #000;
    }
  }
`;

export const TabIconDerogateWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;

export const LabelSimilarMaterialsModal = styled.p`
  display: flex;
  justify-content: flex-end;
  font-weight: ${FontWeight.Medium};
  font-size: ${FontSize.Small};
  color: ${Color.Alert};
  margin-bottom: 0;
`;

export const LoadingAttributeStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  z-index: 1;
  top: -50px;
  right: ${Spacing.Small};
  & span {
    margin-right: ${Spacing.Large};
    width: 20px;
    height: 20px;
  }
`;

export const CommentBadgeWrapper = styled.div`
  margin-left: ${Spacing.Large};
`;

export const TagStyled = styled.div`
  margin-right: ${Spacing.Medium};
  & > span {
    border-radius: ${Spacing.Medium};
    white-space: nowrap;
  }
`;

export const TechnicalAnalysisOptionsBoxStyled = styled.div`
  ${BoxStyled};
  background-color: ${Color.GrayLight};
  width: auto;
  top: 45px;
  right: 0;
  z-index: 99;
  & > div > button {
    width: 100%;
    text-align: left;
    white-space: nowrap;
  }
  & > div > a {
    width: 100%;
    text-align: left;
    white-space: nowrap;
  }
`;

export const NewProductEppWrapper = styled.div`
  margin: 0 0 ${Spacing.Medium} ${Spacing.XLarge};
  & > span {
    padding: ${Spacing.Small} ${Spacing.Medium} ${Spacing.Small} ${Spacing.Medium};
    & > span {
      font-size: ${FontSize.Medium};
    }
  }
`;
