export const ComingSoonTagStrings = {
  pt: {
    text: 'EM BREVE!',
  },
  es: {
    text: 'PRONTO!',
  },
  in: {
    text: 'COMING SOON!',
  },
} as const;
