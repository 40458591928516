import * as React from 'react';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';

export const TruckTrackingHeaderShimmer: React.FC = () => {
  return (
    <Grid fluid>
      <VSeparator />
      <VSeparator />
      <TextShimmerBoxStyled height='50px' width='30%' margin='0' />
      <VSeparator />
      <Row>
        <Col xs={6}>
          <TextShimmerBoxStyled height='30px' width='80%' margin='0' />
        </Col>
        <Col xs={6}>
          <TextShimmerBoxStyled height='30px' width='80%' margin='0' />
        </Col>
      </Row>
      <VSeparator />
      <VSeparator />
    </Grid>
  );
};
