import styled from 'styled-components';
import { InputLabel } from '@atomic/atm.typography';
import { BrandColor, Color, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';

export const DateSelectedWrapper = styled.div`
  height: 40px;
  width: 170px;
  border: 2px solid ${Color.GrayLight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  background-color: ${BrandColor.LightBlue};
  border-radius: ${Spacing.XSmall};

  input,
  input:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

export const DateSelected = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  border: 0;
  height: 25px;
  font-family: ${FontFamily.Primary};
  color: ${Color.GrayDark};
  font-size: ${FontSize.Small};
`;

export const DatePickerLabel = styled(InputLabel)`
  width: 50px;
`;
