import { EATON_BPS } from '@app/constants';
// eslint-disable-next-line prettier/prettier
import type { RootState } from '@app/core/redux/store';
import { InStockOrderItem } from '@app/models';
import { PlantNames } from '../shipping-request.constants';
import { IShippingRequestRow, ISuggestionOption } from '../shipping-request.interfaces';
import { getOldestSuggestions, getStockQuantityTotal, getSuggestionValue, getValidSuggestions } from '../shipping-request.utils';

export const selectedRowsByPlantSelector = (state, plantCode: string): IShippingRequestRow[] => {
  return state.shippingRequest?.tableRows?.filter(row => row.plantCode === plantCode);
};

export const totalStockQuantitySelector = (state, plantCode: string): number => {
  const filteredRows = state.shippingRequest?.tableRows?.filter(row => row.plantCode === plantCode);
  const total = getStockQuantityTotal(filteredRows);
  return total;
};

export const totalRequestedShippingQuantitySelector = (state: RootState, plantCode: string): number => {
  const filteredRows = state.shippingRequest?.tableRows?.filter(row => row.plantCode === plantCode);
  const total = filteredRows.reduce((sum, row) => {

    if (row.requestedShippingQuantity) {
      return sum + parseFloat(row.requestedShippingQuantity.toString());
    }
    return sum + parseFloat(row.stockQuantity.toString());
  }, 0);


  return total;
};

export const plantsDistinctSelector = (state): string[] => {
  const plantCodesSelected: string[] = state.shippingRequest?.tableRows?.map(row => row.plantCode);
  return [...new Set(plantCodesSelected)];
};

export const stockQunatityTotalRowsSelector = (state): number => {
  let totalResult = 0;
  const isEaton = state.customerPersist.clientBpsSelected.some(bp => EATON_BPS.includes(bp.customerId));

  const total = state.shippingRequest?.tableRows?.reduce(
    (accumulator: number, row: IShippingRequestRow) => accumulator + row.stockQuantity - row.shipmentQuantity,
    0,
  );

  const totalEaton = state.shippingRequest?.tableRows?.reduce(
    (accumulator: number, row: IShippingRequestRow) => accumulator + row.requestedShippingQuantity,
    0,
  );

  if (isEaton) {
    if (!isNaN(totalEaton as any) && !isNaN(parseFloat(totalEaton))) {
      totalResult = totalEaton;
    }
  } else {
    totalResult = total;
  }

  return totalResult;
};

export const selectStockQuantitySelector = (state, heat, salesOrder): number => {
  if (state.shippingRequest?.tableRows) {
    const index = state.shippingRequest?.tableRows?.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
    return state.shippingRequest?.tableRows[index]?.stockQuantity;
  }
  return null;
};

export const selectQtyWishedSelector = (state, heat, salesOrder): number => {
  if (state.shippingRequest?.tableRows) {
    const index = state.shippingRequest?.tableRows?.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
    return state.shippingRequest?.tableRows[index]?.requestedShippingQuantity;
  }
  return null;
};

export const selectEatonTableItemSelector = (state, salesOrder, material): IShippingRequestRow => {
  if (state.shippingRequest?.tableRows) {
    const index = state.shippingRequest?.tableRows?.findIndex(row => row.salesOrder === salesOrder && row.material === material);
    return state.shippingRequest?.tableRows[index];
  }
  return null;
};

export const selectEatonTableStockQuantitySelector = (state, salesOrder, material): number => {
  if (state.shippingRequest?.tableRows) {
    const index = state.shippingRequest?.tableRows?.findIndex(row => row.salesOrder === salesOrder && row.material === material);
    return state.shippingRequest?.tableRows[index]?.stockQuantity;
  }
  return null;
};

export const selectEatonTableQtyWishedSelector = (state, salesOrder, material): number => {
  if (state.shippingRequest?.tableRows) {
    const index = state.shippingRequest?.tableRows?.findIndex(row => row.item.salesOrder === salesOrder && row.material === material);
    return state.shippingRequest?.tableRows[index]?.requestedShippingQuantity;
  }
  return null;
};

export const selectEatonStockQuantitySelector = (state, salesOrder): number => {
  if (state.shippingRequest?.suggestionOptions) {
    const index = state.shippingRequest?.suggestionOptions?.findIndex(row => row.item.salesOrder === salesOrder);
    return state.shippingRequest?.suggestionOptions[index]?.item.stockQuantity;
  }
  return null;
};

export const selectEatonQtyWishedSelector = (state, salesOrder): number => {
  if (state.shippingRequest?.suggestionOptions) {
    const index = state.shippingRequest?.suggestionOptions?.findIndex(row => row.item.salesOrder === salesOrder);
    return state.shippingRequest?.suggestionOptions[index]?.item.requestedShippingQuantity;
  }
  return null;
};

export const selectEatonShipmentQtySelector = (state, salesOrder): number => {
  if (state.shippingRequest?.suggestionOptions) {
    const index = state.shippingRequest?.suggestionOptions?.findIndex(row => row.item.salesOrder === salesOrder);
    return state.shippingRequest?.suggestionOptions[index]?.item.shipmentQuantity;
  }
  return null;
};

export const selectEatonCheckedOrderSelector = (state, salesOrder): boolean => {
  if (state.shippingRequest?.suggestionOptions) {
    const index = state.shippingRequest?.suggestionOptions?.findIndex(row => row.item.salesOrder === salesOrder);
    return state.shippingRequest?.suggestionOptions[index]?.selected;
  }
  return false;
};

export const selectEatonSuggestionItemSelector = (state, salesOrder, material): ISuggestionOption => {
  if (state.shippingRequest?.suggestionOptions) {
    return state.shippingRequest?.suggestionOptions?.find(row => row.item.salesOrder === salesOrder && row.item.material === material);
  }

  return null;
};

// eslint-disable-next-line complexity
export const suggestionByPlantAndMaterialSelector = (state, plantCode: string, bp: string, material: string): IShippingRequestRow[] => {
  let suggestions = [];
  let ovs: InStockOrderItem[] = [];
  let ovsAux: InStockOrderItem[] = [];

  switch (plantCode) {
    case PlantNames.MOGI:
      if (state.shippingRequest?.mog && state.shippingRequest?.mog?.bpGroup && state.shippingRequest?.mog?.bpGroup.length > 0) {
        const stockBPGroup = state.shippingRequest?.mog?.bpGroup.find(group => group.bp === bp);

        if (stockBPGroup && stockBPGroup.orderedMaterialStockGroup && stockBPGroup.orderedMaterialStockGroup.length) {
          ovsAux = stockBPGroup.orderedMaterialStockGroup?.find(stockMaterialGroup => stockMaterialGroup?.material === material)?.ovs;
        }
      }
      break;
    case PlantNames.PINDA:
      if (state.shippingRequest?.pin && state.shippingRequest?.pin?.bpGroup && state.shippingRequest?.pin?.bpGroup.length > 0) {
        const stockBPGroup = state.shippingRequest?.pin?.bpGroup.find(group => group.bp === bp);

        if (stockBPGroup && stockBPGroup.orderedMaterialStockGroup && stockBPGroup.orderedMaterialStockGroup.length) {
          ovsAux = stockBPGroup.orderedMaterialStockGroup?.find(stockMaterialGroup => stockMaterialGroup?.material === material)?.ovs;
        }
      }
      break;
    case PlantNames.CHARQ:
      if (state.shippingRequest?.cha && state.shippingRequest?.cha?.bpGroup && state.shippingRequest?.cha?.bpGroup.length > 0) {
        const stockBPGroup = state.shippingRequest?.cha?.bpGroup.find(group => group.bp === bp);

        if (stockBPGroup && stockBPGroup.orderedMaterialStockGroup && stockBPGroup.orderedMaterialStockGroup.length) {
          ovsAux = stockBPGroup.orderedMaterialStockGroup?.find(stockMaterialGroup => stockMaterialGroup?.material === material)?.ovs;
        }
      }
      break;
    default:
      break;
  }

  ovs = getValidSuggestions(ovsAux);
  
  if (ovs) {
    suggestions = getOldestSuggestions(ovs);  
  }
  
  return suggestions;
};

// eslint-disable-next-line complexity
export const getRequestedTotalValue = (state, plant: string, bp: string, material: string): number => {
  let total = 0;
  let selectedRows: IShippingRequestRow[] = [];

  switch (plant) {
    case PlantNames.MOGI:
      if (state.shippingRequest?.mog && state.shippingRequest?.mog?.bpGroup && state.shippingRequest?.mog?.bpGroup.length > 0) {
        const shippingBPGroup = state.shippingRequest?.mog?.bpGroup.find(group => group.bp === bp);

        if (shippingBPGroup && shippingBPGroup.orderedShippingMaterialUserGroup && shippingBPGroup.orderedShippingMaterialUserGroup.length) {
          selectedRows = shippingBPGroup.orderedShippingMaterialUserGroup?.find(shippingMaterialGroup => shippingMaterialGroup?.material === material)?.ovs;
        }
      }
      break;
    case PlantNames.PINDA:
      if (state.shippingRequest?.pin && state.shippingRequest?.pin?.bpGroup && state.shippingRequest?.pin?.bpGroup.length > 0) {
        const shippingBPGroup = state.shippingRequest?.pin?.bpGroup.find(group => group.bp === bp);

        if (shippingBPGroup && shippingBPGroup.orderedShippingMaterialUserGroup && shippingBPGroup.orderedShippingMaterialUserGroup.length) {
          selectedRows = shippingBPGroup.orderedShippingMaterialUserGroup?.find(shippingMaterialGroup => shippingMaterialGroup?.material === material)?.ovs;
        }
      }
      break;
    case PlantNames.CHARQ:
      if (state.shippingRequest?.cha && state.shippingRequest?.cha?.bpGroup && state.shippingRequest?.cha?.bpGroup.length > 0) {
        const shippingBPGroup = state.shippingRequest?.cha?.bpGroup.find(group => group.bp === bp);

        if (shippingBPGroup && shippingBPGroup.orderedShippingMaterialUserGroup && shippingBPGroup.orderedShippingMaterialUserGroup.length) {
          selectedRows = shippingBPGroup.orderedShippingMaterialUserGroup?.find(shippingMaterialGroup => shippingMaterialGroup?.material === material)?.ovs;
        }
      }
      break;
    default:
      break;
  }

  selectedRows?.forEach(row => {
      total += parseFloat(row.stockQuantity.toString());
  });

  return total;
};

export const getSuggestionsTotalValue = (state, isEaton?: boolean): number => {
  let total = 0;
 
  state.shippingRequest?.suggestionOptions?.forEach(option => {
    if (option.selected) {
      if (isEaton) {
        total += option.item?.requestedShippingQuantity ? parseFloat(option.item?.requestedShippingQuantity?.toString()) : 0;
      } else {
        const suggestedValue = getSuggestionValue(option.item.stockQuantity, option.item.shipmentQuantity);
        total += parseFloat(suggestedValue.toString());
      }
    }
  });

  return total;
};

export const isDisabledByOldSalesOrder = (state, isGkn): boolean => {
  let isDisabled = false;
  let hasMogAlert = false;
  let hasPinAlert = false;
  let hasChaAlert = false;

  if (state.shippingRequest.mog) {
    state.shippingRequest.mog.bpGroup.forEach(group => {
      group.orderedShippingMaterialUserGroup.forEach(shippingMaterialGroup => {
        if (shippingMaterialGroup.hasAlert) {
          hasMogAlert = true;
        }
      });
    });
  }

  if (state.shippingRequest.pin) {
    state.shippingRequest.pin.bpGroup.forEach(group => {
      group.orderedShippingMaterialUserGroup.forEach(shippingMaterialGroup => {
        if (shippingMaterialGroup.hasAlert) {
          hasPinAlert = true;
        }
      });
    });
  }
  
  if (state.shippingRequest.cha) {
    state.shippingRequest.cha.bpGroup.forEach(group => {
      group.orderedShippingMaterialUserGroup.forEach(shippingMaterialGroup => {
        if (shippingMaterialGroup.hasAlert) {
          hasChaAlert = true;
        }
      });
    });
  }
  
  if (!isGkn && hasMogAlert || !isGkn && hasPinAlert || !isGkn && hasChaAlert) {
    isDisabled = true;
  }

  return isDisabled;
};
