import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { OrdersStrings } from '../orders.string';
import { AgGridRowCounterWrapper } from './grid-row-counter.component.style';

interface AgGridRowCounterProps {
  rows: number;
  criticalItemRows?: number;
  showCriticalFilter?: boolean;
}

export const AgGridRowCounter: React.FC<AgGridRowCounterProps> = ({ rows, criticalItemRows, showCriticalFilter }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { criticalItemInfo } = useSelector((state: RootState) => state.orderPriorization);

  return (
    <AgGridRowCounterWrapper>
      <FlexRow>
        <Body>{OrdersStrings[userInfo?.language]?.rowCounter}</Body>
      </FlexRow>
      <Body pl={Spacing.XSmall} pr={Spacing.XSmall} color={Color.GrayXDark} bold={700}>
        {` ${rows} `}
      </Body>
      <FlexRow>
        <Body>{OrdersStrings[userInfo?.language]?.rowCounterText}</Body>
      </FlexRow>
      {showCriticalFilter && (
        <>
          <FlexRow>
            <Body pl={Spacing.XSmall}>/</Body>
            <Body pl={Spacing.XSmall} color={Color.GrayXDark} bold={700}>{`${
              criticalItemInfo ? criticalItemRows : 0
            } `}</Body>
            <Body pl={Spacing.XSmall}>{OrdersStrings[userInfo?.language]?.criticalItensCounter}</Body>
          </FlexRow>
        </>
      )}
    </AgGridRowCounterWrapper>
  );
};
