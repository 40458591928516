import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { BodySecondary } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Strings, IOrderTabOpenStrings } from './MI/tab-open/order-tab-open.string';

interface OrderTabsLastUpdateProps {
  lastUpdate: string;
}

export const OrderTabsLastUpdate: React.FC<OrderTabsLastUpdateProps> = ({ lastUpdate }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderTabOpenStrings = Strings[userInfo.language];

  const handleLastUpdateMessage = () => {
    let message = '';

    if (lastUpdate === 'error') {
      return message;
    }
    if (lastUpdate) {
      if (lastUpdate !== 'requesting') {
        message = strings.lastUpdate(lastUpdate.replace('às', '-'));
      } else {
        message = strings.updating;
      }
    }

    return message;
  };

  return (
    <FlexRow fullHeight vAlign='center'>
      <FlexColumn hAlign='flex-end'>
        <BodySecondary>{handleLastUpdateMessage()}</BodySecondary>
      </FlexColumn>
    </FlexRow>
  );
};
