import React from 'react';
import { IOrderPriorizationTableData } from '@app/modules/order-priorization/order-priorization.interfaces';
import { Body } from '@atomic/atm.typography';

interface OrderPriorizationTableStaticTextProps {
  item: IOrderPriorizationTableData;
  column: string;
}

export const OrderPriorizationTableStaticText: React.FC<OrderPriorizationTableStaticTextProps> = ({ item, column }) => {
  let text = item[column];

  if (column === 'qtdConf' || column === 'qtdSolClient') {
    const textAux = item[column];
    if (textAux.toString().indexOf('.') > 0) {
      text = textAux.toString().replace('.', ',');
    }
  }

  return <Body centralized>{text}</Body>;
};
