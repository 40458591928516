import { IBrowserRecomendationString, Strings } from './browser-recomendation-modal.string';

export const isEdgeBrowser = (): boolean => {
  const isEdge = navigator.userAgent.indexOf('Edg') > -1;

  if (isEdge) {
    return true;
  }
  return false;
};

export const getStringsLanguage = (): IBrowserRecomendationString => {
  const browserLanguage = navigator.language;

  if (browserLanguage.includes('en')) {
    return Strings.in;
  } else if (browserLanguage.includes('es')) {
    return Strings.es;
  } else {
    return Strings.pt;
  }
};
