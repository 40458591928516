import React from 'react';
import { Body } from '@atomic/atm.typography';
import { IOrderUpdateTableData } from '../../../order-update.interfaces';

interface OrderUpdateTableStaticTextProps {
  item: IOrderUpdateTableData;
  column: string;
}

export const OrderUpdateTableStaticText: React.FC<OrderUpdateTableStaticTextProps> = ({ item, column }) => {
  let text = item[column];

  if (column === 'qtdConf' || column === 'qtdSolClient') {
    const textAux = item[column];
    if (textAux.toString().indexOf('.') > 0) {
      text = textAux.toString().replace('.', ',');
    }
  }

  return <Body centralized>{text}</Body>;
};
