import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { rangeLimits } from '../../form.contants';
import { FormContext } from '../../form.context';
import { FormResultEditOptions } from './form-result-edit-options';
import { FormResultAttributeWrapper, FormResultOptionsWrapper } from './form-result.component.style';

interface JominyItems {
  hardenabilityUnit: number;
  points: string;
  unit: string;
  value: {
    min: any;
    max: any;
    unit: string;
  };
}
interface JominyTableProps {
  attrKey: string;
  stepKey: string;
  attribute: {
    display: boolean;
    key: string;
    label?: string;
    mandatory?: boolean;
    permissions?: {
      visible: boolean;
      editable: boolean;
    };
    type: string;
    readBy: string;
    value: JominyItems[];
  };
}

export const JominyTable: React.FC<JominyTableProps> = props => {
  const { focusAttribute } = React.useContext(FormContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const formatStringValue = (val: string, range: string) => {
    if (typeof val !== 'string') {
      val = String(val);
    }
    if (val) {
      if (range === 'max') {
        const newMask = val === rangeLimits.maxString ? val.replace(rangeLimits.maxString, rangeLimits.dash) : val;
        return newMask;
      } else {
        if (val) {
          const newMask = val === rangeLimits.minString ? val.replace(rangeLimits.minString, rangeLimits.dash) : val;
          return newMask;
        }
        return val;
      }
    } else {
      return rangeLimits.dash;
    }
  };

  const orderData = (data: JominyItems[]) => {
    return data.sort((a: JominyItems, b: JominyItems) => (+a.points < +b.points ? -1 : +a.points > +b.points ? 1 : 0));
  };

  return props.attribute?.permissions.visible ? (
    <FormResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.readBy === 'nlu'}
      onClick={() => focusAttribute(props.attrKey)}
    >
      <Hbox.Item>
        <FormResultOptionsWrapper>
          <InputLabel>
            {props.attribute?.label}{' '}
            {props.attribute?.readBy === 'nlu' ? (
              <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
            ) : (
              props.attribute?.readBy === 'GERDAU' && ' *'
            )}
          </InputLabel>
          <FormResultEditOptions
            stepKey={props.stepKey}
            editable={props.attribute.permissions.editable}
            attrKey={props.attrKey}
            type={props.attribute.type}
            attribute={props.attribute}
          />
        </FormResultOptionsWrapper>
      </Hbox.Item>
      <Table>
        <TR>
          <TD>
            <H4>{props.attribute.value[0].unit}</H4>
          </TD>
          <TD>
            <H4>Min</H4>
          </TD>
          <TD>
            <H4>Max</H4>
          </TD>
          <TD></TD>
        </TR>
        {orderData(props.attribute.value).map(item => (
          <TR key={item.points}>
            <TD>
              <H4>{item.points}</H4>
            </TD>
            <TD>{formatStringValue(item.value.min, 'min').replace(',', '.')}</TD>
            <TD>{formatStringValue(item.value.max, 'max').replace(',', '.')}</TD>
            <TD>{item.value.unit}</TD>
          </TR>
        ))}
      </Table>
      <VSeparator />
    </FormResultAttributeWrapper>
  ) : (
    <></>
  );
};
