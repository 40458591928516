import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { columnsTabLayoutSelector } from '@app/core/redux/grid-layout/grid-layout.selectors';
import { RootState } from '@app/core/redux/store';
import {
  useNFDocumentQuery,
  useNFShippingDocumentRequest,
  useNFxmlDocumentRequest,
} from '@app/domain/document.use-case';
import { LayoutTabs } from '@app/models/layout.model';
import {
  DropDownMenuContext,
  DropDownMenuContextState,
  IMenuItem,
} from '@app/modules/components/drop-down-menu/drop-down-menu-container';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { downloadFile } from '@app/utils/download-file';
import { CellLink } from '@atomic/atm.cell-link/cell-link.component';
import { FaIcon } from '@atomic/atm.fa-icon';
import { FlexRow } from '@atomic/obj.box';

interface NFCellComponentProps {
  data: { docNum: string; numDocument: string; numDeliveryItem: string; ovType: string; invoice: string };
  customerIds: string;
}

const ovTypes = ['ZVOI', 'YVOI', 'ZVOD'];

export const NFCellComponent: React.FC<NFCellComponentProps> = props => {
  const { data, loading, fetch, error } = useNFDocumentQuery();
  const { loading: loadingXML, data: dataXMLfile, performRequest } = useNFxmlDocumentRequest();
  const { openDropDownMenu, closeDropDownMenu } = useContext<DropDownMenuContextState>(DropDownMenuContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const columnDef = useSelector(columnsTabLayoutSelector(LayoutTabs.BilledMI, userInfo?.language));
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const showedColumns = columnDef.filter(col => !col.hide);
  const pinnedShowedColumns = showedColumns.filter(col => col.pinned);
  const colIndex = showedColumns.findIndex(col => col.field === 'nf');
  const colIndexPinned = pinnedShowedColumns.findIndex(col => col.field === 'nf');

  const offMenuSetLeft = colIndex === 0 || colIndexPinned === 0 ? 160 : 0;

  const {
    loading: loadingShipping,
    data: nfShippingFile,
    performRequest: performRequestShipping,
  } = useNFShippingDocumentRequest();

  const [filename, setFilename] = React.useState('NF');
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const customerIds = clientBpsSelected.map(client => client.customerId).join(',');
  const ref = useRef(null);

  useEffect(() => {
    closeDropDownMenu();
  }, [ref]);

  useEffect(() => {
    if (data) {
      LogAnalytics.click({ tipo: 'DownloadNF' });
      downloadFile(data, filename);
    }
  }, [data]);

  useEffect(() => {
    if (dataXMLfile) {
      LogAnalytics.click({ tipo: 'DownloadNFxml' });
      downloadFile(dataXMLfile, filename);
    }
  }, [dataXMLfile]);

  useEffect(() => {
    if (nfShippingFile) {
      LogAnalytics.click({ tipo: 'DownloadNFShipping' });
      downloadFile(nfShippingFile, filename);
    }
  }, [nfShippingFile]);

  useEffect(() => {
    if (error) {
      show('warning', 'PDF não encontrado!');
    }
  }, [error]);

  const handleDocumentView = (docNum: string, invoice: string) => () => {
    setFilename('NF - ' + invoice);
    fetch(docNum, customerIds);
  };

  if (!props.data) {
    return null;
  }

  const handleDocumentViewXML = (numDocument: string, docItemNum: string) => () => {
    setFilename(`NF-XML-${numDocument}.xml`);
    performRequest({ document: numDocument, documentItem: docItemNum, customerIds: customerIds });
  };

  const handleDocumentViewShipping = (numDocument: string, docItemNum: string) => () => {
    setFilename(`NF-Remessa-${numDocument}.pdf`);
    performRequestShipping({ document: numDocument, documentItem: docItemNum, customerIds });
  };

  if (!props.data) {
    return null;
  }

  const items: IMenuItem[] = [
    {
      icon: 'download',
      text: 'PDF',
      onclick: handleDocumentView(props.data.docNum, props.data.invoice),
    },
    {
      icon: 'download',
      text: 'XML',
      onclick: handleDocumentViewXML(props.data.numDocument, props.data.numDeliveryItem),
    },
  ];

  const shippingNF: IMenuItem = {
    icon: 'download',
    text: 'NF da Remessa',
    onclick: handleDocumentViewShipping(props.data.numDocument, props.data.numDeliveryItem),
  };

  if (ovTypes.includes(props.data.ovType)) {
    items.push(shippingNF);
  }

  return (
    <FlexRow>
      <FlexRow ref={ref}>
        <CellLink
          onClick={() => openDropDownMenu(ref, items, offMenuSetLeft)}
          loading={loading || loadingXML || loadingShipping}
        >
          <FaIcon.FileDownload />
        </CellLink>
      </FlexRow>
    </FlexRow>
  );
};
