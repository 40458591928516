import { LoginProfileResponse } from '@app/data/http/auth.params.dto';
import { Tab } from './admin.model';
import { BPUser } from './bp.model';
import { ICustomerSearch } from './customer.model';

export interface AuthData {
  isa: AuthIsaGroupData;
  gsb: AuthGroups;
  name: string;
  token: string;
  uid: string;
  username: string;
  orderAccessList: string[];
  financeAccessList: string[];
  isaAccess: boolean;
  adminAccess: AdminAccess;
  isSeller: boolean;
  isTae: boolean;
  isScm: boolean;
  isEmployee: boolean;
  headerType: HeaderType;
  language: string;
  gabRedirect: boolean;
  isHybrid: boolean;
}

export interface LoginDataResponse {
  loginResponse: LoginProfileResponse;
  bps: any[];
}

export interface GetLoginResponse {
  token: string;
  tokenType: string;
  tokenExpiresIn: number;
  refreshToken: string;
  applications: string;
  ssoAccess: boolean;
}

export interface RefreshTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
}

export interface ErrorAuthData {
  err: string;
}

export enum Language {
  por = 'pt',
  spa = 'es',
  eng = 'in',
}

export enum HanaLanguage {
  pt = 'P',
  por = 'P',
  es = 'S',
  in = 'E',
}

export enum HeaderType {
  Select = 'select',
  Search = 'search',
}

export interface AuthGroups {
  name: string;
  group: AuthGroupData[];
}

interface AuthGroupData {
  name: string;
  groupId: string;
  description: string;
  adminGab: boolean;
  adminGsb: boolean;
  adminGerdau: boolean;
  gsb: boolean;
  gab: boolean;
  gsbProfile: string;
  gsbRoles?: AuthRoles[];
  gabProfile: string;
  gabRoles?: AuthRoles[];
  roles?: AuthRoles;
}

export interface AuthIsaGroupData {
  name: string;
  groupId: string;
  role: string;
}

export interface AdminAccess {
  adminGerdau: boolean;
  adminGab: boolean;
  adminGsb: boolean;
  adminCustomerGsb: boolean;
  adminCustomerGab: boolean;
  adminBpGsb: boolean;
  adminBpGab: boolean;
}

interface AuthRoles {
  EPP?: boolean;
  DEFAULT?: boolean;
  financialCustomers?: boolean;
  ordersViewCustomers?: boolean;
  ordersInputCustomers?: boolean;
  ordersViewPriceCustomers?: boolean;
  createGroups?: boolean;
  createRoles?: boolean;
  priceView?: boolean;
}

export interface LoginParameters {
  userName: string;
  password: string;
  applications: string;
}

export interface LoginParams {
  username: string;
  password: string;
  access_token?: string;
}

export interface UserInfo {
  _id?: string;
  oktaUid?: string;
  name?: string;
  email?: string;
  language: string;
  applications?: string;
  lastLogin?: string;
  created?: {
    by: {
      name: string;
      email: string;
    };
    at: string;
  };
  profile?: {
    _id: string;
    profile: string;
    tabs: Tab[];
  };
  bps?: BPUser[];
  bpList?: ICustomerSearch[];
  isAdmin?: boolean;
  type?: string;
}
