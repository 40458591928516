import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Credit } from '@app/models';
import { dateParser } from '@app/utils/date-parser';
import { ProgressBar } from '@atomic/atm.progress-bar';
import { DD, DL, DT } from '@atomic/atm.typography';
import { DDShimmer } from '@atomic/mol.shimmer/shimmer.component';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { toCurrency } from '@atomic/obj.utils';
import { Strings, IFinanceStrings } from './finance.string';

const FinanceCreditShimmer: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IFinanceStrings = Strings[userInfo?.language];

  return (
    <Row mb>
      <Col xs={3} md={2}>
        <DL hAlign='flex-start'>
          <DT>{strings.credit.limit}</DT>
          <DD>
            <DDShimmer />
          </DD>
        </DL>
      </Col>
      <Col xs={5} md={3}>
        <DL hAlign='flex-start'>
          <DT>{strings.credit.expirationDate}</DT>
          <DD>
            <DDShimmer />
          </DD>
        </DL>
      </Col>
      <Col xs={12} md={4}>
        <DL hAlign='flex-start'>
          <DT>{strings.credit.total}</DT>
          <DD>
            <DDShimmer />
          </DD>
        </DL>
        <VSeparator />
      </Col>
    </Row>
  );
};

interface FinanceCreditProps {
  data: Credit[];
  loading: boolean;
  error: boolean;
}

export const FinanceCredit: React.FC<FinanceCreditProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IFinanceStrings = Strings[userInfo.language];

  let mainCompany: Credit;

  if (props.data) {
    mainCompany = props.data.find((company: Credit) => company.branch === false);
  }

  return (
    <LoadingState loading={props.loading} error={props.error} data={!!props.data}>
      <LoadingState.Shimmer>
        <FinanceCreditShimmer />
      </LoadingState.Shimmer>
      <Row>
        <Col xs={3} md={2} style={{ borderRight: '1px solid #EFF2F3', marginBottom: '15px' }}>
          <DL hAlign='flex-start'>
            <DT>{strings.credit.limit}</DT>
            <DD>{mainCompany && toCurrency(mainCompany.limitCredit, userInfo.language === 'in' ? 'USD' : 'BRL')}</DD>
          </DL>
        </Col>
        <Col xs={5} md={3}>
          <DL hAlign='flex-start' style={{ paddingLeft: '15px' }}>
            <DT>{strings.credit.expirationDate}</DT>
            <DD>
              {mainCompany?.limitValidDate &&
                dateParser({
                  unformattedDate: mainCompany.limitValidDate,
                  hideHours: true,
                  utc: true,
                  language: userInfo.language,
                })}
            </DD>
          </DL>
        </Col>
        <Col xs={12} md={4}>
          <DL hAlign='flex-start'>
            <DT>{strings.credit.total}</DT>
            <ProgressBar percentage={mainCompany && mainCompany.percentUsedCredt} />
          </DL>
          <VSeparator />
        </Col>
      </Row>
    </LoadingState>
  );
};
