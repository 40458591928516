import { Select, SearchField, Button, Icon } from '@gerdau/hefesto';
import { Chips } from '@gerdau/hefesto-gerdau-mais-gsb-web';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { ExcelType } from '@app/models/admin.model';
import { AdminFilterStyled, AdminSearchStyled } from '@app/modules/admin/admin.page.style';
import { getNPSUserGroup } from '@app/modules/admin/store/thunk/nps-get-group.thunk';
import { getAllUsers } from '@app/modules/admin/store/thunk/users-get-all.thunk';
import { LIMIT_ITEMS_PER_PAGE, SEARCH_DEBOUNCE_TIME } from '@app/modules/admin/utils/utils';
import { AdminStrings } from '../../../../admin.string';
import {
  setNPSUserTextQuery,
  setNPSClearUserFilterQuery,
  clearAllUsers,
  setSelectedNPSGroup,
  setNPSUserFilterOption,
} from '../../../../store/admin.store';
import { AdminUserFormSpan } from '../../../admin-user-form/admin-user-form.styles';
import { NPSPageGroupOptions } from '../../../admin-user-form/utils/utils';
import { exportToCSV } from '../utils/export-excel';
import { AdminNPSList } from './admin-nps-list/admin-nps-list.component';
import { AdminNPSPagination } from './admin-nps-pagination/admin-nps-pagination.component';
import { AdminNPSStatistics } from './admin-nps-statistics/admin-nps-statistics.component';
import {
  AdminNPSViewContainer,
  AdminNPSViewWrapperStyled,
  AdminNPSActionButtonStyled,
  NPSGroupOptionsWrapperStyled,
} from './admin-nps.styles';
import { NPSUserFilterOptions } from './utils/utils';

export const AdminNPSComponent: React.FC = () => {
  const { npsUserSearch, loadingAllUsers, allUsers, selectedNPSGroup } = useSelector((state: RootState) => state.admin);
  const { ssoAccess } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = AdminStrings.pt;

  useEffect(() => {
    dispatch(setNPSClearUserFilterQuery(''));
  }, [npsUserSearch.filterOption]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const query = {
        group: selectedNPSGroup,
        ssoAccess: ssoAccess,
        skip: 0,
        limit: LIMIT_ITEMS_PER_PAGE,
        filterType: npsUserSearch.filterOption,
        filterValue: npsUserSearch.textQuery,
      };

      if (npsUserSearch.selectedOptions?.length) {
        query.filterValue = npsUserSearch.selectedOptions.join();
      } else if (npsUserSearch.textQuery?.length > 2) {
        query.filterValue = npsUserSearch.textQuery;
      } else {
        query.filterValue = '';
      }

      dispatch(getNPSUserGroup(query));
    }, SEARCH_DEBOUNCE_TIME);

    return () => clearTimeout(delayDebounceFn);
  }, [npsUserSearch.textQuery, npsUserSearch.selectedOptions]);

  useEffect(() => {
    if (allUsers.length) {
      exportToCSV(allUsers, strings.userView.actions.exportFileName, ExcelType.Users);
      dispatch(clearAllUsers([]));
    }
  }, [allUsers]);

  const handleSearchField = () => {
    return (
      <SearchField
        label={strings.common.search}
        name='userSearchField'
        inputValue={npsUserSearch.textQuery}
        disabled={!npsUserSearch.filterOption}
        onChange={e => dispatch(setNPSUserTextQuery(e.target.value))}
      />
    );
  };

  const downloadExcel = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(getAllUsers());
  };

  return (
    <AdminNPSViewContainer>
      <AdminNPSViewWrapperStyled>
        <AdminFilterStyled>
          <Select
            label={strings.common.filter}
            name='userNPSFilterSelect'
            value={npsUserSearch.filterOption}
            onSelect={e => dispatch(setNPSUserFilterOption(e))}
            options={NPSUserFilterOptions}
          />
        </AdminFilterStyled>
        <AdminSearchStyled>{handleSearchField()}</AdminSearchStyled>
        <AdminNPSActionButtonStyled margingLeft>
          <Button
            name='userExcellExportBtn'
            variant='secondary'
            loading={loadingAllUsers}
            icon={<Icon option='XLS' />}
            fullWidth={true}
            onClick={e => {
              downloadExcel(e);
            }}
          >
            {strings.userView.actions.exportButton}
          </Button>
        </AdminNPSActionButtonStyled>
      </AdminNPSViewWrapperStyled>
      <NPSGroupOptionsWrapperStyled>
        <AdminUserFormSpan>{strings.nps.groupLabel}</AdminUserFormSpan>
        <Chips
          chips={NPSPageGroupOptions}
          selectedChipIds={[selectedNPSGroup]}
          handleSelect={e => {
            dispatch(setSelectedNPSGroup(e[0]));
          }}
          type='choice'
        />
      </NPSGroupOptionsWrapperStyled>
      <AdminNPSStatistics />
      <AdminNPSList />
      <AdminNPSPagination />
    </AdminNPSViewContainer>
  );
};
