import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { Grid } from '@atomic/obj.grid';
import { OrderUpdateEvents } from '../order/order.analytics';
import { IOrderUpdateTableData } from './order-update.interfaces';
import { ModalAddComment } from './partials/modal-message-client/modal-add-comment';
import { ModalMessageClient } from './partials/modal-message-client/modal-message-client.component';
import { OrderUpdateActions } from './partials/order-update-actions/order-update-actions.component';
import { OrderUpdateHeader } from './partials/order-update-header/order-update-header.component';
import { OrderUpdateTable } from './partials/order-update-table/order-update-table.component';
import { setTableRows } from './store/order-update.store';
import { mapSelectedRows } from './utils';

export const OrderUpdatePage: React.FC = () => {
  const { selectedRows } = useSelector((state: RootState) => state.orderTabOpen);

  const dispatch = useDispatch();
  useEffect(() => {
    const tableRows: IOrderUpdateTableData[] = mapSelectedRows(selectedRows);
    dispatch(setTableRows(tableRows));
  }, []);

  useEffect(() => {
    LogAnalytics.pageView(OrderUpdateEvents.OrderUpdateView);
  }, []);

  return (
    <>
      <OrderUpdateHeader />
      <Grid fluid>
        <OrderUpdateActions />
        <OrderUpdateTable />
        <ModalMessageClient />
      </Grid>
      <ModalAddComment />
    </>
  );
};
