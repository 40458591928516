import styled from 'styled-components';
import { Color, TransitionDuration, ZIndex } from '@atomic/obj.constants';

export const GridColumnFilterStyled = styled.div`
  position: relative;
`;

interface AppConfigurationOptionsStyledProps {
  show?: boolean;
}
export const AppConfigurationOptionsStyled = styled.div`
  opacity: ${(props: AppConfigurationOptionsStyledProps) => (props.show ? 1 : 0)};
  max-height: ${(props: AppConfigurationOptionsStyledProps) => (props.show ? '600px' : 0)};
  z-index: ${ZIndex.AppConfigurationOptionsStyled};
  position: absolute;
  background-color: ${Color.White};
  width: ${(props: AppConfigurationOptionsStyledProps) => (props.show ? '230px' : 0)};
  right: 10px;
  top: 50px;
  transition: all ${TransitionDuration} ease-in-out;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
`;
