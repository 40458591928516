import { GridReadyEvent } from 'ag-grid-community';
import React, { MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { HelpCenterSectionKeys, HelpCenterSubsectionKeys } from '@app/models/help-center.model';
import { LayoutTabs } from '@app/models/layout.model';
import { GridColumnFilter, GridDownload } from '@app/modules/components';
import { GridButtonWithIcon } from '@app/modules/components/grid-button-with-icon.component';
import { Image } from '@atomic/atm.image';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Col, Row } from '@atomic/obj.grid';
import { IOrderStrings } from '../../order.strings.interface';
import { OrdersStrings } from '../../orders.string';

interface OrderHeaderActionsParams {
  refresh: () => void;
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  filename: string;
  helpCenterSubsection?: HelpCenterSubsectionKeys;
  children?: JSX.Element;
  tabName: LayoutTabs;
}

export const OrderTabsActionsME: React.FC<OrderHeaderActionsParams> = ({
  children,
  refresh,
  gridEvent,
  helpCenterSubsection,
  filename,
  tabName,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderStrings = OrdersStrings[userInfo?.language];
  let helpRedirect = null;

  if (helpCenterSubsection) {
    helpRedirect = { section: HelpCenterSectionKeys.Glossary, subsection: helpCenterSubsection };
  }

  return (
    <Row pt>
      <Col xs={12} sm={6}>
        {children}
      </Col>
      <Col xs={12} sm={6}>
        <FlexRow hAlign='flex-end'>
          <FlexColumn noGrow>
            <GridButtonWithIcon
              text={strings.common.refresh}
              icon={() => <Image.RefreshIcon />}
              kind='secondary'
              onClick={refresh}
            />
          </FlexColumn>
          <Hbox.Separator />
          <FlexColumn noGrow>
            <GridColumnFilter tabName={tabName} helpRedirect={helpRedirect} />
          </FlexColumn>
          <Hbox.Separator />
          <FlexColumn noGrow>
            <GridDownload gridEvent={gridEvent.current} filename={filename} tabName={tabName} />
          </FlexColumn>
        </FlexRow>
      </Col>
    </Row>
  );
};
