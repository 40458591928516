import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Material } from '@app/models';
import { SearchMaterialField } from '@app/modules/order-input/components/search-material-field/search-material-field';
import { OrderInputPhaseEnum } from '@app/modules/order-input/order-input.constants';
import { MaterialFormInfo } from '../material-form-info/material-form-info';

interface IMaterialFormSearchProps {
  onSelectItem: (materialOption: Material) => void;
}

export const MaterialFormSearch: React.FC<IMaterialFormSearchProps> = ({ onSelectItem }) => {
  const { phase } = useSelector((state: RootState) => state.orderInput);

  if (phase === OrderInputPhaseEnum.ItensSelection) {
    return (
      <>
        <SearchMaterialField onClick={onSelectItem} />
        <MaterialFormInfo />
      </>
    );
  }
  return null;
};
