import React from 'react';
import { useDispatch } from 'react-redux';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { setShowM2CalendarModal } from '@app/modules/order-input/store/order-input.store';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Label } from '@atomic/atm.typography';
import { Color, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { DateWarningWrapper } from './date-warning.style';

const strings = OrderInputStrings.pt.dateAndAmount;

export const DateWarning: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <DateWarningWrapper pt={Spacing.Small} pb={Spacing.Small} pl mb>
      <FaIcon.Custom icon='exclamation-triangle' size='sm' color={Color.Warning} />
      <Label size={FontSize.Small} weight={FontWeight.Normal} pl={Spacing.Small}>
        {strings.alertM2messagePart1}
        <a
          href='/'
          onClick={e => {
            dispatch(setShowM2CalendarModal(true));
            e.preventDefault();
          }}
        >
          {strings.alertM2messagePart2}
        </a>
      </Label>
    </DateWarningWrapper>
  );
};
