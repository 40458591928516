import { PayloadAction } from '@reduxjs/toolkit';
import { ISchedule } from '@app/models/schedule.model';
import { CustomerSelection } from '@app/providers';
import {
  SCHEDULE_TYPE,
  SchedulePhase,
  UserScheduleType,
  occurenceTypeToScheduleType,
  weekDays,
} from '../../schedule.constants';
import { IScheduleState } from '../../schedule.interfaces';

export const generalReducers = {
  setCurrentTab: (state: IScheduleState, { payload }) => {
    state.currentTab = payload;
  },
  setSchedulePhase: (state: IScheduleState, { payload }) => {
    state.schedulePhase = payload;
  },

  initClientBpsSearch: (state: IScheduleState, { payload }: PayloadAction<CustomerSelection[]>) => {
    state.clientBpsSchedule = payload.map((client, index) => {
      const key = `${client.customerId}_${index}`;
      return {
        key,
        name: client.name,
        code: client.customerId,
        city: client.city,
        selected: false,
      };
    });
  },

  setEditMode: (state: IScheduleState, { payload }) => {
    state.editMode = payload;
    if (!payload) {
      const currentLayout =
        state.currentTab === UserScheduleType.ORDER_OPEN ? state.layoutDefaultCart : state.layoutDefaultStock;

      state.scheduleId = null;
      state.scheduleName = '';
      state.querySearch = '';
      state.selectedEmails = [];
      state.scheduleType = SCHEDULE_TYPE.DAILY;
      state.time = '07:00';
      state.clientBpsSchedule = state.clientBpsSchedule.map(bp => ({ ...bp, selected: false }));
      state.layout = currentLayout;
      state.dragItems = [];
      state.showDaySelect = false;
      state.selectedDays = [];
      state.selectedDaysOfWeek = weekDays;
    }
  },

  setEditSchedule: (state: IScheduleState, { payload }: PayloadAction<ISchedule>) => {
    state.schedulePhase = SchedulePhase.Form;
    state.scheduleId = payload.id;
    state.scheduleName = payload.name;
    state.selectedEmails = payload.mail.split(',').map(email => ({ email, valid: true }));
    state.scheduleType = occurenceTypeToScheduleType[payload.occurrence_type];

    if (payload.send_day) {
      if (occurenceTypeToScheduleType[payload.occurrence_type] === SCHEDULE_TYPE.MONTHLY) {
        state.selectedDays = payload.send_day.split(',');
      } else {
        state.selectedDaysOfWeek = payload.send_day.split(',');
      }
    }
    state.time = payload.hour_to_send.toString();
    state.layout = payload.layout;
    state.dragItems = payload.layout.filter(column => column.checked).sort((a, b) => a.index - b.index);

    state.clientBpsSchedule = state.clientBpsSchedule.map(bp => {
      return {
        ...bp,
        sche_id: payload.id,
        selected: payload.clients.map(client => client.code).includes(bp.code),
      };
    });
    state.editMode = true;
  },

  setSelectedEmails: (state: IScheduleState, { payload }) => {
    state.selectedEmails = payload;
  },
};
