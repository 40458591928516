import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { TableRowData } from '@app/data/http/quote-params.dto';
import { Button } from '@atomic/atm.button';
import { Divisor } from '@atomic/atm.divisor';
import { FaIcon } from '@atomic/atm.fa-icon';
import { RadioField } from '@atomic/atm.radio';
import { TextField } from '@atomic/atm.text-field';
import { Body, ButtonText, H1, H3, H4 } from '@atomic/atm.typography';
import { Tab } from '@atomic/mol.tab/tab.component';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { jominyPoints, Measure, rangeLimits } from './chat.contants';
import { ChatStrings } from './chat.string';
import { formatDecimals } from './modal-composition.component';
import { JominyContentWrapperStyled, JominyErrorStyled } from './modal-jominy.component.style';

export interface JominyData {
  MEDIDA: string;
  PONTOS: {
    ponto: string;
    min: string;
    max: string;
    hardenabilityUnit: string;
  }[];
}

export interface JominyContext {
  JOMINY: JominyData;
}

interface JominyRowProps {
  currentPoints: string[];
  units: string;
  index: number;
  point: string;
  tabIndex: number;
  min?: string;
  max?: string;
  handleRemove?: (value: number) => void;
}

const convertData = (data: JominyContext) => {
  return data.JOMINY?.PONTOS?.reduce((acc, cur) => {
    acc[cur.ponto] = { min: cur.min, max: cur.max };
    return acc;
  }, {});
};

const formatStringValue = (val: any, range: string) => {
  if (val) {
    if (range === 'max') {
      const newMask = val.toString().replace(rangeLimits.maxString, '');
      return newMask;
    } else {
      if (val === rangeLimits.minString) {
        const newMask = val.toString().replace(rangeLimits.minString, '');
        return newMask;
      }
      return val;
    }
  }
  return '';
};

const JominyRow: React.FC<JominyRowProps> = props => {
  return (
    <>
      <TR>
        <TD>
          {props.point?.length ? (
            <>
              <Form.Field name={`JOMINY.PONTOS[${props.index}].ponto`} initialValue={props.point} />
              <H3>{props.point}</H3>
              <H4>{props.units}</H4>
            </>
          ) : (
            <Form.Field
              name={`JOMINY.PONTOS[${props.index}].ponto`}
              validators={[Validators.Required(), Validators.UniqueInArray(props.currentPoints)]}
              hideErrorCaption
            >
              <TextField placeholder='Pto.' type='number' />
            </Form.Field>
          )}
        </TD>
        <TD>
          <Form.Field
            name={`JOMINY.PONTOS[${props.index}].min`}
            hideErrorCaption
            key={props.min}
            initialValue={props.min}
          >
            <TextField placeholder='Min.' type='text' />
          </Form.Field>
        </TD>
        <TD> - </TD>
        <TD>
          <Form.Field name={`JOMINY.PONTOS[${props.index}].max`} hideErrorCaption initialValue={props.max}>
            <TextField placeholder='Máx.' type='text' />
          </Form.Field>
        </TD>

        <TD>
          <Button kind='link' onClick={() => props.handleRemove(props.index)}>
            <FaIcon.Trash />
          </Button>
        </TD>
      </TR>
      <VSeparator />
    </>
  );
};

interface ModalJominyProps {
  openTableType: string;
  data?: JominyContext;
  onSubmit?: (data: TableRowData[], key: string) => void;
  onClose: () => void;
}

export const ModalJominy: React.FC<ModalJominyProps> = props => {
  const [reset, setReset] = React.useState(0);
  const [opened, setOpened] = React.useState(false);
  const [measure, setMeasure] = React.useState(Measure.MM as string);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [currentPoints, setCurrentPoints] = React.useState(jominyPoints);
  const [jominyObj, setJominyObj] = React.useState<{ [key: string]: { min: string; max: string } }>({});
  const [errorData, setErrorData] = React.useState<boolean>(false);
  const contentRef = React.useRef(null);
  const units = [Measure.MM, Measure.POL];
  const hardenability_unit = ['HRC', 'HV'];
  const [hardenabilityUnit, setHardenabilityUnit] = React.useState(
    props.data?.JOMINY.PONTOS[0]?.hardenabilityUnit
      ? props.data?.JOMINY.PONTOS[0]?.hardenabilityUnit
      : hardenability_unit[0],
  );

  const verifyJominyPolUnit = value => {
    if (value % 1 === 0) {
      return `${value}/16`;
    } else {
      return value;
    }
  };

  const orderingPoints = (values: string[], unit: string) => {
    let newArray = [];
    if (unit === 'pol') {
      const teste = values.map(val => val.split('/').shift()).sort((a, b) => +a - +b);
      teste.map((item, index) => (newArray[index] = `${item}/16`));
    } else {
      newArray = values;
    }
    return newArray;
  };

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language].jominy;

  React.useEffect(() => {
    if (props.data?.JOMINY) {
      const incomingPoints = { ...jominyPoints };
      const unit = props.data?.JOMINY?.MEDIDA !== '' ? props.data.JOMINY.MEDIDA : 'mm';
      incomingPoints[unit] = [
        ...new Set([...incomingPoints[unit], ...props.data.JOMINY.PONTOS.map(item => item.ponto)]),
      ];
      orderingPoints(incomingPoints[unit], unit);
      setCurrentPoints(incomingPoints);
      setJominyObj(convertData(props.data));
      setMeasure(unit);
      if (props.data.JOMINY.MEDIDA === 'pol') {
        setTabIndex(1);
      } else {
        setTabIndex(0);
      }
    }
  }, [props.data]);

  React.useEffect(() => {
    if (props.openTableType.toLowerCase() === 'jominy') {
      setOpened(true);
    }
  }, [props.openTableType]);

  const handleSubmit = (data: FormData<JominyContext>) => {
    const haveData = data.data.JOMINY.PONTOS.filter(item => item.min || item.max).length;

    if (Object.keys(data.error).length > 0 || haveData === 0) {
      setErrorData(true);
      return;
    }
    props.onSubmit(setTableParams(data.data), 'HARDENABILITY');
    setOpened(false);
  };

  const setTableParams = (data: JominyContext) => {
    return data.JOMINY.PONTOS.filter(item => !!item.max || !!item.min).reduce((acc, item) => {
      acc.push([
        { columnRef: 'points', value: tabIndex === 1 ? verifyJominyPolUnit(item.ponto) : item.ponto },
        { columnRef: 'min', value: +(!item.min ? rangeLimits.min : formatDecimals(item.min)) },
        { columnRef: 'max', value: +(!item.max ? rangeLimits.max : formatDecimals(item.max)) },
        { columnRef: 'unit', value: measure },
        { columnRef: 'hardenability_unit', value: hardenabilityUnit },
      ]);
      return acc;
    }, []);
  };

  const handleReset = () => {
    setReset(reset + 1);
  };

  const handleClose = () => {
    setOpened(false);
    props.onClose();
  };

  const handleTabChange = (index: number) => {
    const newMeasure = index === 0 ? Measure.MM : Measure.POL;
    handleReset();
    setTabIndex(index);
    setMeasure(newMeasure);
  };

  const handleNewPointClick = () => {
    const updatedPoints = { ...currentPoints };
    updatedPoints[measure] = [...updatedPoints[measure], ''];
    setCurrentPoints(updatedPoints);
    contentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleRemovePoint = index => {
    const updatedPoints = { ...currentPoints };
    const pointsLeft = updatedPoints[measure].filter((_, indexItem) => index !== indexItem);
    setCurrentPoints({ ...currentPoints, [measure]: pointsLeft });
  };

  const handleChangeUnit = value => {
    setHardenabilityUnit(value);
  };

  return (
    <Modal preventOverlayClick opened={opened} small onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <H1>{strings.title}</H1>
              <VSeparator />
            </Col>
            <Col xs={12}>
              <Tab initialIndex={tabIndex} onIndexChanged={handleTabChange}>
                <Tab.Item>{strings.milimeters}</Tab.Item>
                <Tab.Item>{strings.inches}</Tab.Item>
              </Tab>
              <Divisor />
              {errorData && (
                <JominyErrorStyled>
                  <Body color={Color.Alert}>{strings.errorData}</Body>
                </JominyErrorStyled>
              )}
            </Col>
          </Row>
          <JominyContentWrapperStyled>
            <Row mb>
              <Col xs={12}>
                <VSeparator />
                <Form.Field
                  name='hardenability_unit'
                  initialValue={hardenabilityUnit}
                  onValueChange={e => handleChangeUnit(e)}
                >
                  <Hbox>
                    <Hbox.Item noGrow>
                      <RadioField id={hardenability_unit[0]}>{hardenability_unit[0]}</RadioField>
                    </Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Item noGrow>
                      <RadioField id={hardenability_unit[1]}>{hardenability_unit[1]}</RadioField>
                    </Hbox.Item>
                  </Hbox>
                </Form.Field>
              </Col>
            </Row>
            <Row mt mb key={reset}>
              <Col xs={12}>
                <Table>
                  <TR>
                    <TD></TD>
                    <TD></TD>
                    <TD>
                      <Body>{hardenabilityUnit}</Body>
                    </TD>
                    <TD></TD>
                  </TR>
                  {currentPoints[measure].map((point: string, index: number) => (
                    <JominyRow
                      tabIndex={tabIndex}
                      currentPoints={currentPoints[measure]}
                      units={units[tabIndex]}
                      point={point}
                      key={index + reset}
                      index={index}
                      min={formatStringValue(jominyObj[point]?.min, 'min')}
                      max={formatStringValue(jominyObj[point]?.max, 'max')}
                      handleRemove={handleRemovePoint}
                    />
                  ))}
                </Table>
              </Col>
            </Row>
            <div ref={contentRef} />
          </JominyContentWrapperStyled>
          <Divisor />
          <VSeparator />
          <Hbox vAlign='center'>
            <Hbox.Item>
              <ButtonText onClick={() => handleNewPointClick()}>
                <FaIcon.Plus size='1x' /> {strings.addMeasure}
              </ButtonText>
            </Hbox.Item>
            <Hbox.Item noGrow>
              <Button kind='secondary' expanded onClick={handleReset}>
                {strings.reset}
              </Button>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item noGrow>
              <Button kind='primary' expanded type='submit'>
                {strings.save}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};

ModalJominy.defaultProps = {
  openTableType: '',
};
