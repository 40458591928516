import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Cell } from '@atomic/atm.cell';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { QuoteStrings } from '../quote/quote.string';
import { OrderingFilterStyled } from './date-filter.style';

enum FilterOrdering {
  desc = 'desc',
  asc = 'asc',
  time = 'priorityDate',
}

export interface OrderingFilterProps {
  onSortChange: (option: string) => void;
  sortAnalysis: string;
}

export const OrderingFilter: React.FC<OrderingFilterProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const menuRef = React.useRef(null);
  const [show, setShow] = React.useState(false);

  const strings = QuoteStrings[userInfo.language].dateFilter;

  const filters = [
    { key: 'desc', value: strings.desc },
    { key: 'asc', value: strings.asc },
    { key: 'time', value: strings.time },
  ];

  const [selectedFilter, setSelectedFilter] = React.useState(filters[0]);

  const handleFilterSelect = (item: { key: string; value: string }) => {
    setSelectedFilter(item);
    setShow(!show);
    props.onSortChange(FilterOrdering[item.key]);
  };

  const handleMouseDown = (event: MouseEvent) => {
    if (show && menuRef && !menuRef.current.contains(event.target)) {
      setShow(!show);
    }
  };
  useEventListener('mousedown', handleMouseDown);

  return (
    <div ref={menuRef}>
      <Button kind='neutral' onClick={() => setShow(!show)}>
        <Hbox>
          <Hbox.Item>
            {strings.orderBy}
            {selectedFilter.value}
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item noGrow vAlign='bottom'>
            <FaIcon.ChevronDown size='1x' />
          </Hbox.Item>
        </Hbox>
      </Button>
      <OrderingFilterStyled show={show}>
        {filters.map(item => (
          <Cell key={item.key} hover onClick={() => handleFilterSelect(item)}>
            <Body>{item.value}</Body>
          </Cell>
        ))}
      </OrderingFilterStyled>
    </div>
  );
};
