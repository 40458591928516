import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { ICustomerSearchModalHeaderAdminStrings, Strings } from '../../../customer-search-modal-header-admin.string';
import { DropDownSelectedWrapper, DropDownWrapper, SelectedText } from '../search-field.style';
import { SearchFieldOptionsSalesOrgs } from './search-field-options-sales-orgs.component';

export const SearchFieldSalesOrgs: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ICustomerSearchModalHeaderAdminStrings = Strings[userInfo.language];
  const [showOptions, setShowOptions] = useState(false);
  const { filterOptionSearch } = useSelector((state: RootState) => state.customerSearch);

  return (
    <DropDownWrapper open={showOptions}>
      <DropDownSelectedWrapper
        onClick={e => {
          setShowOptions(!showOptions);
          e.stopPropagation();
        }}
      >
        <SelectedText>{`${strings.salesOrgsPlaceholder} (${filterOptionSearch?.market})`}</SelectedText>
        <Button kind='link'>{showOptions ? <FaIcon.ChevronUp size='1x' /> : <FaIcon.ChevronDown size='1x' />}</Button>
      </DropDownSelectedWrapper>
      {showOptions && <SearchFieldOptionsSalesOrgs setShowOptions={setShowOptions} />}
    </DropDownWrapper>
  );
};
