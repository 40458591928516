import { addGetLayoutReducers } from './layout-get.thunk';
import { addCreateScheduleReducers } from './schedule-create.thunk';
import { addDeleteScheduleReducers } from './schedule-delete.thunk';
import { addGetScheduleReducers } from './schedule-get.thunk';
import { addUpdateScheduleReducers } from './schedule-update.thunk';
import { addValidateEmailReducers } from './validate-email.thunk';

export * from './layout-get.thunk';
export * from './schedule-create.thunk';
export * from './schedule-delete.thunk';
export * from './schedule-get.thunk';
export * from './schedule-update.thunk';
export * from './validate-email.thunk';

export const addThunkReducers = builder => {
  addGetLayoutReducers(builder);
  addValidateEmailReducers(builder);
  addGetScheduleReducers(builder);
  addCreateScheduleReducers(builder);
  addUpdateScheduleReducers(builder);
  addDeleteScheduleReducers(builder);
};
