import React from 'react';
import { FlexColumn } from '@atomic/obj.box';
import { ScheduleModalSuccessBody } from './partials/schedule-modal-success-body';
import { ScheduleModalSuccessDeleteBody } from './partials/schedule-modal-success-delete-body';
import { ScheduleModalSuccessFooter } from './partials/schedule-modal-success-footer';

interface ScheduleModalSuccessProps {
  showDeleteMessage?: boolean;
}

export const ScheduleModalSuccess: React.FC<ScheduleModalSuccessProps> = ({ showDeleteMessage = false }) => {
  return (
    <FlexColumn>
      {showDeleteMessage ? <ScheduleModalSuccessDeleteBody /> : <ScheduleModalSuccessBody />}
      <ScheduleModalSuccessFooter />
    </FlexColumn>
  );
};
