import * as React from 'react';
import { Image } from '@atomic/atm.image';
import { H2, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Strings, IDashBoardStrings } from './dashboard.string';
import { MockDashboardOpacityStyled, MockDashboardWarningStyled } from './dashboard.style';

interface MockGraphProps {
  title: string;
}

export const MockGraph: React.FC<MockGraphProps> = props => {
  const strings: IDashBoardStrings = Strings.pt;

  return (
    <>
      <MockDashboardWarningStyled>
        <H3>{strings.soon}</H3>
      </MockDashboardWarningStyled>
      <H2 center>{props.title}</H2>
      <MockDashboardOpacityStyled>
        <Hbox>
          <Hbox.Item hAlign='center'>
            <Image.MockDashboardGraph />
          </Hbox.Item>
        </Hbox>
      </MockDashboardOpacityStyled>
    </>
  );
};
