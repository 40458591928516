import { DragItem } from 'ag-grid-community/dist/lib/dragAndDrop/dragAndDropService';
import { IScheduleLayout } from '@app/models/schedule.model';

export const filterSelectedColumns = (col: IScheduleLayout) =>
  col.value !== 'checkboxSelection' && col.value !== 'qtyNotAllocated' && col.value !== 'qtyTotalStatus';

export const nearestDivisibleByFive = number => {
  return Math.floor(number / 5) * 5;
};

export const getColumnsGroup = (columnIndex: number, totalLength: number) => {
  let length = 0;
  const nearestDivisible = nearestDivisibleByFive(totalLength);

  const division = nearestDivisible / 5;
  const restDivision = totalLength % 5;

  if (columnIndex === 0) {
    return length;
  }

  if (columnIndex <= restDivision) {
    length = division + 1;
  } else {
    length = division;
  }

  const next = getColumnsGroup(columnIndex - 1, totalLength);
  const result = next + length;

  return result;
};

export const divideColumns = <T>(columns: T[], field: string): T[][] => {
  if (columns) {
    const length = columns.length;
    const sortedColumns = [...columns].sort((a, b) => {
      return a[field].localeCompare(b[field]);
    });

    const start_first_interval = 0;
    const start_second_interval = getColumnsGroup(1, length);
    const start_third_interval = getColumnsGroup(2, length);
    const start_fourth_interval = getColumnsGroup(3, length);
    const start_fifth_interval = getColumnsGroup(4, length);

    const first = sortedColumns.slice(start_first_interval, start_second_interval);
    const second = sortedColumns.slice(start_second_interval, start_third_interval);
    const third = sortedColumns.slice(start_third_interval, start_fourth_interval);
    const fourth = sortedColumns.slice(start_fourth_interval, start_fifth_interval);
    const fifth = sortedColumns.slice(start_fifth_interval, length);

    const matrixColumns = [[...first], [...second], [...third], [...fourth], [...fifth]];

    const sortedMatrix = matrixColumns.sort((columnA, columnB) => columnB.length - columnA.length);

    return sortedMatrix;
  } else {
    return [[], [], [], [], []];
  }
};

export const reIndexDragItems = (dragItems: DragItem[]) => {
  const dragItensReindexed = [];

  dragItems.forEach((dragItem, index) => {
    dragItensReindexed.push({ ...dragItem, index });
  });

  return dragItensReindexed;
};
