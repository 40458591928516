import { AppPath } from '../app/route-constants';

interface IBreadCrumbs {
  label: string;
  path?: string;
}

export interface IOrderPriorizationStrings {
  page: {
    breadcrumbs: IBreadCrumbs[];
    title: string;
    help: string;
    goBack: string;
    confirm: string;
    currentAvailableItems: (corpGroup: string) => string;
    labelQtyItem: string;
    currentSelectedItems: string;
    currentActivatedItems: string;
    labelChangeQtyItem: (qtyItems: number) => string;
    headerControlInfo: string;
    removeItemsLabel: string;
  };
  table: {
    headerRow: {
      ovItem: string;
      itemOv: string;
      ocClient: string;
      codMaterialClient: string;
      descMaterial: string;
      dateMaxDeliveryClient: string;
      qtdConf: string;
      qtdSolClient: string;
      messageClient: string;
    };
    priorizationSlot: (qtyItems: number) => string;
  };
  modal: {
    title: string;
    comment: string;
    confirmMessage: (qtyItems: number) => string;
    warningMessage: string;
    item: (ov: string, oc: string) => string;
    alertMessage: string;
    itemLabel: string;
    deleteConfirmMessageStart: string;
    deleteConfirmMessageEnd: string;
    proceedBtn: string;
    removeBtn: string;
    cancelBtn: string;
  };
  successPage: {
    title: string;
    message: string;
    boldMessage: string;
    backBtn: string;
  };
}

export interface IOrderPriorizationLanguages {
  pt: IOrderPriorizationStrings;
  es: IOrderPriorizationStrings;
  in: IOrderPriorizationStrings;
}

export const pt: IOrderPriorizationStrings = {
  page: {
    breadcrumbs: [
      { label: 'Voltar para carteira de pedidos', path: AppPath.ORDERS },
      { label: 'Sinalizar itens críticos' },
    ],
    title: 'Sinalizar itens críticos',
    help: 'Como funciona?',
    goBack: 'Voltar',
    confirm: 'Confirmar',
    currentAvailableItems: (corpGroup: string) => `Nº de itens permitidos no momento (Grupo ${corpGroup}):`,
    labelQtyItem: 'item(s)',
    currentSelectedItems: 'Nº de itens selecionados para serem críticos:',
    currentActivatedItems: 'Nº de itens que já foram selecionados como críticos:',
    labelChangeQtyItem: (qtyItems: number) => `${qtyItems} item(s) (necessário remover alguns itens para substituição)`,
    headerControlInfo: 'Novos itens elencados para serem críticos:',
    removeItemsLabel: 'item(s) (necessário remover alguns itens)',
  },
  table: {
    headerRow: {
      ovItem: 'OV',
      itemOv: 'Item',
      ocClient: 'OC',
      codMaterialClient: 'Cod. prod. cliente',
      descMaterial: 'Desc. material',
      dateMaxDeliveryClient: 'Data conf.',
      qtdConf: 'Qtd conf (t)',
      qtdSolClient: 'Qtd solic (t)',
      messageClient: 'Mensagem (opcional)',
    },
    priorizationSlot: (qtyItems: number) => `Você ainda possui ${qtyItems} item(s) para adicionar como críticos`,
  },
  modal: {
    title: 'Revise a sua solicitação',
    comment:
      'Ao sinalizar um item como crítico, a equipe de atendimento estará ciente e realizará tratativas internas para que esses itens sejam priorizados na produção, mediante avaliação e disponibilidade.',
    confirmMessage: (qtyItems: number) =>
      `Tem certeza que deseja tornar os itens selecionados (${qtyItems}) como críticos?`,
    warningMessage:
      'Você ultrapassou o limite de itens críticos permitidos no momento, remova alguns itens até conseguir alcançar o limite permitido.',
    alertMessage: 'Número de itens permitidos no momento:',
    itemLabel: 'item(s)',
    deleteConfirmMessageStart: 'Tem certeza que deseja remover o item',
    deleteConfirmMessageEnd: 'como crítico?',
    item: (ov: string, oc: string) => `Ordem de venda ${ov} (Ordem de compra ${oc})`,
    proceedBtn: 'Prosseguir',
    removeBtn: 'Remover',
    cancelBtn: 'Cancelar',
  },
  successPage: {
    title: 'Sua sinalização de priorização de pedidos foi finalizada com sucesso.',
    message:
      'Nossa equipe já recebeu sua sinalização e vai providenciar os próximos passos para o atendimento desses pedidos. Em seguida um email será enviado com a confirmação da sua sinalização e você poderá consultar',
    boldMessage: 'seu pedido destacado na Carteira.',
    backBtn: 'Voltar para a carteira',
  },
};

export const Strings: IOrderPriorizationLanguages = {
  pt,
  es: pt,
  in: pt,
};
