import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { BodySecondary, H1 } from '@atomic/atm.typography';
import { Form, Validators } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';

interface ChangePasswordFormProps {
  onSubmit: (formData: ChangePasswordFormData) => void;
  loading: boolean;
}

export interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = props => {
  const handleSubmit = formData => {
    if (!(Object.keys(formData.error).length > 0)) {
      props.onSubmit(formData.data);
    }
  };

  return (
    <>
      <H1>Trocar senha</H1>
      <BodySecondary>Informe sua senha atual e a nova do sistema Gerdau Mais</BodySecondary>
      <VSeparator />

      <Form onSubmit={handleSubmit}>
        <Form.Field name={'oldPassword'} validators={[Validators.Required('Obrigatório')]}>
          <TextField type='password' placeholder='Senha atual' />
        </Form.Field>
        <VSeparator />

        <Form.Field
          name={'newPassword'}
          validators={[
            Validators.PasswordRegex(),
            Validators.MinLength(8, 'A senha deve conter pelo menos 8 caracteres'),
            Validators.Required('Obrigatório'),
          ]}
        >
          <TextField type='password' placeholder='Nova senha' />
        </Form.Field>
        <VSeparator />

        <Form.Field name={'newPasswordConfirmation'} validators={[Validators.Required('Obrigatório')]}>
          <TextField type='password' placeholder='Redigite a nova senha' />
        </Form.Field>
        <VSeparator />

        <Button type='submit' loading={props.loading}>
          Salvar alterações
        </Button>
      </Form>
      <VSeparator />
    </>
  );
};
