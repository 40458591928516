import * as React from 'react';
import { Link } from 'react-router-dom';
import { CrmData } from '@app/data/http/crm.dto';
import { AppPath } from '@app/modules/app/route-constants';
import { Badge } from '@atomic/atm.badge';
import { Cell } from '@atomic/atm.cell';
import { Body, Label } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { CrmStrings } from '../crm.string';
import { CrmItemWrapperStyled } from './crmListItem.style';

interface CrmProps {
  item: CrmData;
}

export const StatusBadge = (status: string) => {
  return <Badge color='warning'>{status}</Badge>;
};
// eslint-disable-next-line complexity
export const CrmListItem = (props: CrmProps) => {
  const strings = CrmStrings.pt.crmList;

  const formatLabels = Object.entries(props.item);

  const handleOpenCF = (cached: boolean) => {
    if (cached) {
      window.localStorage.setItem('crmCache', 'true');
    }
  };

  return (
    <Link to={`${AppPath.QUOTE.CRM}/${props.item.cfId}`} target='_blank'>
      <CrmItemWrapperStyled>
        <Cell hover onClick={() => handleOpenCF(props.item.hasCache)}>
          <Hbox>
            {formatLabels.map(
              (item, index) =>
                index <= 7 && (
                  <Body key={`a-${index}`}>
                    <Label>{strings[item[0]]}: </Label> {item[0] === 'cfStatus' ? StatusBadge(item[1]) : item[1]}
                  </Body>
                ),
            )}
          </Hbox>
          <Hbox>
            {formatLabels.map(
              (item, index2) =>
                index2 > 7 &&
                index2 < formatLabels.length &&
                item[0] !== 'hasCache' && (
                  <Body key={`b-${index2}`}>
                    <Label>{strings[item[0]]}: </Label> {item[0] === 'cfStatus' ? StatusBadge(item[1]) : item[1]}
                  </Body>
                ),
            )}
          </Hbox>
        </Cell>
      </CrmItemWrapperStyled>
    </Link>
  );
};
