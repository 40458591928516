import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppConfig } from '@app/configure-app';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { logout } from '@app/modules/auth/store/thunk/logout.thunk';
import { setOpenHambuguerMenu } from '@app/providers/navigation/navigation.store';
import { AppWindow } from '@app/utils/interfaces';
import { HamburgerButton } from '@atomic/atm.hamburger-button';
import { Header } from '@atomic/org.header';
import { ActionsAppHeader } from './partials/actions-header/actions-header.component';
import { MainLogoHeader } from './partials/main-logo-header/main-logo-header.component';
import { MenuAppHeader } from './partials/menu-header/menu-header.component';

interface AppHeaderProps {
  onNotificationClick?: () => void;

  onError?: () => void;

  shortHeader: boolean;

  isAdmin: boolean;
}

const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;

export const AppHeader: React.FunctionComponent<AppHeaderProps> = props => {
  const { openHamburguerMenu } = useSelector((state: RootState) => state.navigation);

  const dispatch = useDispatch<AppThunkDispatch>();

  const handleLogout = () => {
    const isLocalhost = window.location.origin.indexOf('localhost') > -1;

    dispatch(
      logout({
        ssoLogoutUrl: config.ssoLogoutUrl,
        ssoTenantId: config.ssoTenantId,
        ssoLogoutAuthPath: config.ssoLogoutAuthPath,
        gsbAuthUrl: isLocalhost ? `${window.location.origin}/auth` : config.gsbAuthUrl,
      }),
    );
  };

  const handleHamburguerClick = () => {
    dispatch(setOpenHambuguerMenu(!openHamburguerMenu));
  };

  return (
    <Header>
      <Header.Desk>
        <MainLogoHeader shortHeader={props.shortHeader} />

        <HamburgerButton active={openHamburguerMenu} onClick={handleHamburguerClick} />

        <MenuAppHeader shortHeader={props.shortHeader} handleLogout={handleLogout} />

        <ActionsAppHeader shortHeader={props.shortHeader} handleLogout={handleLogout} />
      </Header.Desk>
    </Header>
  );
};
