import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { dateFormatter } from '@app/data/http/layout.dto';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { setShowSurrogateInfoModal } from '../../store/surrogate.store';
import { ISurrogateStrings, Strings } from '../../surrogate.strings';
import {
  SurrogateInfoModalBody,
  SurrogateInfoModalCloseWrapper,
  SurrogateInfoModalFooter,
  SurrogateInfoModalHeader,
  SurrogateInfoModalTitleWrapper,
  SurrogateInfoModalWrapper,
} from './surrogate-info-modal.style';

export const SurrogateInfoModal: React.FC = () => {
  const [message, setMessage] = useState('');
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { isIndeterminateTime, surrogatesTo, expiresFrom, expiresTo, surrogateInfoModalOpened } = useSelector(
    (state: RootState) => state.surrogate,
  );

  const dispatch = useDispatch<AppThunkDispatch>();
  const strings: ISurrogateStrings = Strings[userInfo?.language];

  const handleClose = () => {
    dispatch(setShowSurrogateInfoModal(false));
  };

  useEffect(() => {
    let fullMessage = '';

    if (isIndeterminateTime) {
      fullMessage = `${strings?.infoModal.indeterminateTimeMessage} ${strings?.infoModal.message}`;
    } else {
      const startDate = dateFormatter(expiresFrom ? { value: new Date(expiresFrom).toISOString().split('T')[0] } : '');
      const endDate = dateFormatter(expiresTo ? { value: new Date(expiresTo).toISOString().split('T')[0] } : '');
      fullMessage = `${strings?.infoModal.expiresMessage(startDate, endDate)} ${strings?.infoModal.message}`;
    }

    setMessage(fullMessage);
  }, [isIndeterminateTime, expiresFrom, expiresTo]);

  return (
    <Modal small opened={surrogateInfoModalOpened}>
      <SurrogateInfoModalHeader>
        <SurrogateInfoModalTitleWrapper>{strings?.infoModal.title}</SurrogateInfoModalTitleWrapper>
        <SurrogateInfoModalCloseWrapper>
          <FlexColumn onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
            <Image.NewsModalHeaderCloseButton />
          </FlexColumn>
        </SurrogateInfoModalCloseWrapper>
      </SurrogateInfoModalHeader>
      <SurrogateInfoModalWrapper>
        <SurrogateInfoModalBody>
          <Body>{message}</Body>
          <VSeparator />
          {surrogatesTo?.map((surrogate, index) => {
            return <Body key={`surrogate-mail-${index}`}>{surrogate}</Body>;
          })}
          <SurrogateInfoModalFooter>
            <FlexRow hAlign='center'>
              <Button testid={`${TEST_ID_CLOSE_MODAL}-surrogate-info-modal`} onClick={() => handleClose()}>
                {strings?.infoModal.confirm}
              </Button>
            </FlexRow>
          </SurrogateInfoModalFooter>
        </SurrogateInfoModalBody>
      </SurrogateInfoModalWrapper>
    </Modal>
  );
};
