import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { strings } from '@app/modules/components/schedule-modal/schedule.strings';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { ScheduleWizard } from '../../../schedule-modal-wizard/schedule-modal-wizard.component';

export const ScheduleModalColumnsHeader: React.FC = () => {
  const { editMode } = useSelector((state: RootState) => state.schedule);

  return (
    <FlexRow hAlign='space-between' vAlign='center' mb>
      <Body bold='bold'>{strings.columnDescription}</Body>
      {!editMode && <ScheduleWizard />}
    </FlexRow>
  );
};
