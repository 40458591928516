import styled from 'styled-components';
import { TextField } from '@atomic/atm.text-field';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';

export const InlineLinkStyled = styled.span`
  display: inline-block;
  padding: ${Spacing.Small} 0;
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Medium};
  color: ${Color.Accessory};
  font-size: ${FontSize.Small};
  cursor: pointer;
`;

export const InlineTextFieldStyle = styled(TextField)`
  width: 100%;
`;
