import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowM2CalendarModal } from '@app/modules/order-input/store/order-input.store';
import { FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { CustomCalendarStrings, Strings } from '../custom-calendar.strings';
import { RulesMessage } from '../custom-calendar.style';

export const CustomCalendarRulesMessage = () => {
  const strings: CustomCalendarStrings = Strings.pt;
  const dispatch = useDispatch();

  return (
    <FlexRow pb={Spacing.Small} pl pr>
      <RulesMessage>
        {strings.rulesMessagePart1}
        <br /> {strings.rulesMessagePart2}
        <br /> {strings.rulesMessagePart3}
        <br /> {strings.rulesMessagePart4}
        <a
          href='/'
          onClick={e => {
            dispatch(setShowM2CalendarModal(true));
            e.preventDefault();
          }}
        >
          {strings.rulesMessagePart5}
        </a>
      </RulesMessage>
    </FlexRow>
  );
};
