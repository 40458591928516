/* eslint-disable complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Logs } from '@app/models/log.model';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body, DD, DL, H3, InputLabel } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { TechnicalAnalysisViewModal } from '../components/technical-analysis-view-modal.component';
import { useGetLogAnalysis } from '../hooks/useGetLogAnalysis';

interface LogButtonProps {
  analysisId: number;
}

export const LogButton: React.FC<LogButtonProps> = props => {
  const [modalOpenLog, setModalOpenLog] = React.useState(false);
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const { data: dataLogAnalysis, isFetching: getLogLoading, refetch: getLogAnalysis } = useGetLogAnalysis({
    analysisId: props.analysisId,
    language: userInfo.language,
  });

  const handleOpenLog = () => {
    getLogAnalysis();
    setModalOpenLog(true);
  };

  return userInfo?.profile?.profile === 'admin' ? (
    <Hbox>
      <Button kind='secondary' onClick={handleOpenLog}>
        <Hbox hAlign='left' fullWidth>
          <Hbox.Item noGrow vAlign='center'>
            <FaIcon.Search />
          </Hbox.Item>
          <Hbox.Separator />
          <Body bold color={Color.GrayXDark} noWrap>
            Log de atividade
          </Body>
        </Hbox>
      </Button>
      <TechnicalAnalysisViewModal title='Log de atividade' opened={modalOpenLog} onClose={() => setModalOpenLog(false)}>
        <LoadingState loading={getLogLoading} enableActivityIndicator={true}>
          {dataLogAnalysis &&
            dataLogAnalysis.map((log: Logs, index: number) => (
              <React.Fragment key={index + log.date}>
                <InputLabel small>{log.date}</InputLabel>
                <H3>{`${log.user.name} - ${log.user.email}`}</H3>

                <Hbox>
                  <Hbox.Item vAlign='center'>
                    <VSeparator small />
                    <DL horizontal>
                      <DD>{log.log}</DD>
                    </DL>
                  </Hbox.Item>
                </Hbox>

                <VSeparator />
                <VSeparator />
              </React.Fragment>
            ))}
        </LoadingState>
        <VSeparator />
        <VSeparator />
      </TechnicalAnalysisViewModal>
    </Hbox>
  ) : null;
};
