import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from '@app/core/redux/store';
import { LayoutTabs } from '@app/models/layout.model';
import { AppPath } from '@app/modules/app/route-constants';
import { ISidebarFiltersStrings, Strings } from '@app/modules/components/side-bar-filters/sidebar-filters.strings';
import {
  setEndDateFilter,
  setStartDateFilter,
} from '@app/modules/components/side-bar-filters/store/sidebar-filters.store';
import { MItabIndex } from '@app/modules/order/order-tabs/MI/order-tabs-MI.constants';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { DataFilterCalendar } from './data-filter-calendar.component';
import { DataLabel } from './date-filter-content.style';
import { handleEndDateChange, handleStartDateChange } from './utils';

interface IDateFilterContentProps {
  filterKey: string;
}

export const DateFilterContent: React.FC<IDateFilterContentProps> = ({ filterKey }) => {
  const { orderTabIndex } = useSelector((state: RootState) => state.order);
  const history = useHistory();
  const tab = history.location.pathname === AppPath.FINANCE ? LayoutTabs.FinanceMI : MItabIndex[orderTabIndex];
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: ISidebarFiltersStrings = Strings[userInfo.language];
  const { filters } = useSelector((state: RootState) => state.advancedFilters[tab]);
  const { startDate, endDate } = filters[filterKey];

  const dispatch = useDispatch();

  const handleStartDateChangeConfigured = (date: Date): void => {
    handleStartDateChange({
      date,
      endDate: new Date(endDate),
      setStartDate: value => dispatch(setStartDateFilter({ tab, filterKey, value })),
      setEndDate: value => dispatch(setEndDateFilter({ tab, filterKey, value })),
    });
  };

  const handleEndDateChangeConfigured = (date: Date): void => {
    handleEndDateChange({ date, setEndDate: value => dispatch(setEndDateFilter({ tab, filterKey, value })) });
  };

  return (
    <FlexColumn ml={Spacing.Medium} mt={Spacing.Medium}>
      <FlexRow mb vAlign='center'>
        <DataLabel>{strings.from}</DataLabel>
        <DataFilterCalendar selectedDate={startDate} onValueChange={handleStartDateChangeConfigured} />
      </FlexRow>
      <FlexRow mb={Spacing.Medium} vAlign='center'>
        <DataLabel>{strings.to}</DataLabel>
        <DataFilterCalendar
          minDate={new Date(startDate)}
          selectedDate={endDate}
          onValueChange={handleEndDateChangeConfigured}
        />
      </FlexRow>
    </FlexColumn>
  );
};
