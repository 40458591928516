import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationDatasource } from '@app/data/http/notification.datasource';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../notification.constants';

export const getNotification = createAsyncThunk(`${SLICE_NAME}/getNotification`, async (_, thunkAPI) => {
  return NotificationDatasource.getNotification()
    .then(resp => resp)
    .catch(err => {
      handleUnauthorized(err);
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const addGetNotificationReducers = builder => {
  builder.addCase(getNotification.pending, state => {
    state.loading = true;
  });
  builder.addCase(getNotification.fulfilled, (state, _) => {
    state.isFirstPageLoad = false;
    state.loading = false;
  });
  builder.addCase(getNotification.rejected, state => {
    state.loading = false;
  });
};
