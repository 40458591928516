import * as React from 'react';
import { Link } from '@app/core/route/link.component';
import { SubsectionData } from '@app/models/help-center.model';
import { H3 } from '@atomic/atm.typography';
import {
  SectionCellHideStyled,
  SectionCellShowStyled,
  SectionCellStyled,
  SectionCellStyledProps,
} from './section-cell.style';
import { SubsectionsList } from './subsections-list.component';

interface SectionCellProps extends SectionCellStyledProps {
  id: string;
  label: string;
  subsections: SubsectionData[];
  showSubsections?: boolean;
  currentSection: string;
  onSectionClick?: (section: string) => void;
  currentSubsection?: string;
  onSubsectionClick?: (subsection: string) => void;
  internalLink?: string;
  target?: string;
}

export const SectionCell: React.FC<SectionCellProps> = props => {
  const expanded = props.showSubsections && props.currentSection === props.id;

  return (
    <>
      <SectionCellStyled
        hover
        hasClick={!!props.onSectionClick}
        onClick={() => props.onSectionClick(props.id)}
        selected={expanded}
      >
        {!props.internalLink ? (
          <H3>
            {props.label}
            {expanded ? <SectionCellShowStyled /> : <SectionCellHideStyled />}
          </H3>
        ) : (
          <Link to={props.internalLink} target={props.target ? props.target : '_self'}>
            <H3>{props.label}</H3>
          </Link>
        )}
      </SectionCellStyled>
      <SubsectionsList
        expanded={expanded}
        subsections={props.subsections}
        currentSubsection={props.currentSubsection}
        onSubsectionClick={props.onSubsectionClick}
      />
    </>
  );
};
