import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Label } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { IOrderInputStrings, OrderInputStrings } from '../../order-input.string';
import { formatMaterialDescription } from '../../order-input.utils';
import { removeSelectedMaterialOption } from '../../store/order-input.store';
import {
  OptionItensDescription,
  OptionItensSelected,
  SelectedMaterialsWrapper,
} from './meterial-options-selected.style';

export const MaterialOptionsSelected: React.FC = () => {
  const { selectedMaterials, searchBy } = useSelector((state: RootState) => state.orderInput);
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IOrderInputStrings = OrderInputStrings[userInfo?.language];

  return (
    <FlexColumn ml={Spacing.Large}>
      <Label pb={Spacing.Small}>{strings.selectItens.stepTwo.selectedMaterialsTitle}</Label>
      <SelectedMaterialsWrapper>
        {selectedMaterials.map((material, index) => (
          <OptionItensSelected key={index} hAlign='space-between' pt={Spacing.Small} pb={Spacing.Small}>
            <OptionItensDescription>{formatMaterialDescription(material, searchBy)}</OptionItensDescription>
            <Button onClick={() => dispatch(removeSelectedMaterialOption(material.tempId))} kind='link'>
              <FaIcon.Close />
            </Button>
          </OptionItensSelected>
        ))}
      </SelectedMaterialsWrapper>
    </FlexColumn>
  );
};
