import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { useUserStore, UserStore } from '@app/providers';
import { Frame } from '@atomic/atm.frame';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { quoteIsInternalUserSelector } from '../auth/store/auth.selectores';
import { validateTokens } from '../components/notification-center/store/thunk/validate-and-get-notification.thunk';
import { MenuComplainPage } from './menu-complain-page.component';

const REFRESH_INTERVAL = 60000;
// eslint-disable-next-line complexity
export const ComplainPage: React.FC = () => {
  const { user } = useStore<UserStore>(useUserStore);
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);

  const { isFirstPageLoad } = useSelector((state: RootState) => state.notification);
  const dispatch = useDispatch<AppThunkDispatch>();
  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (isFirstPageLoad) {
        await dispatch(validateTokens(isFirstPageLoad));
      }
    }, REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [isFirstPageLoad]);

  return (
    <>
      <Frame>
        <Grid fluid>
          <Row>
            <Col md={5} xs={12}>
              {quoteIsInternalUser && user?.accessToken && <MenuComplainPage tabIndex={null} />}
            </Col>
            <Col md={7} xs={12}></Col>
          </Row>
        </Grid>
      </Frame>
      <Grid fluid>
        <Row mt>
          <Col xs={12}>
            <Row>
              <Col xs={12}></Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
