import { QuoteAttributes, QuoteAttributeTypes } from '@app/models/quote.model';
import { DerogateRowValues } from '@app/modules/quote/technical-analysis/attributes/technical-analysis-attribute.context';
import { isNullOrUndefined } from '@app/utils/validation';

export interface Range {
  min?: number;
  max?: number;
}

export const mapSuggestion = (attribute: QuoteAttributes, suggestion: string, min: number, max: number): any => {
  const x: Range = {};
  switch (attribute.type) {
    case QuoteAttributeTypes.Table:
    case QuoteAttributeTypes.Text:
    case QuoteAttributeTypes.Options:
    case QuoteAttributeTypes.TextArray:
    case QuoteAttributeTypes.Date:
      return suggestion;
    case QuoteAttributeTypes.Range:
      if (!isNullOrUndefined(min)) {
        x.min = +min;
      }
      if (!isNullOrUndefined(max)) {
        x.max = +max;
      }
      return x;
    case QuoteAttributeTypes.Number:
      return +suggestion;
    case QuoteAttributeTypes.Boolean:
      return !attribute.booleanAttributeValue;
    default:
      return null;
  }
};

export const mapRows = (rows: DerogateRowValues[]) => {
  return rows.map(row => {
    const result = [
      {
        key: row.key,
        columnRef: 'status',
        value: row.status,
      },
      row.reason
        ? {
            key: row.key,
            columnRef: 'reason',
            value: row.reason,
          }
        : null,
      row.suggestion || row.minSuggestion || row.maxSuggestion
        ? {
            key: row.key,
            columnRef: 'suggestion',
            value: mapRowSuggestion(row.suggestion, row.minSuggestion, row.maxSuggestion),
          }
        : null,
    ];
    return result.filter(value => !!value);
  });
};

export const mapRowsDerogateUpdate = (rows: DerogateRowValues[]) => {
  return rows.map(row => {
    const result = [
      {
        key: row.key,
        columnRef: 'status',
        value: row.status,
      },
      row.suggestion || row.minSuggestion || row.maxSuggestion
        ? {
            key: row.key,
            columnRef: 'suggestion',
            value: mapRowSuggestion(row.suggestion, row.minSuggestion, row.maxSuggestion),
          }
        : null,
    ];
    return result.filter(value => !!value);
  });
};

export const mapRowsDerogateUpdateMicroInclusion = (rows: DerogateRowValues[]) => {
  return rows.map(row => {
    const result = [
      {
        key: row.key,
        columnRef: 'status',
        value: row.status === 'non-analysed' ? 'accepted' : row.status,
      },
      row.reason
        ? {
            key: row.key,
            columnRef: 'reason',
            value: row.reason === 'non-analysed' ? 'accepted' : row.reason,
          }
        : null,
      row.suggestion
        ? {
            key: row.key,
            columnRef: 'suggestion',
            value: mapRowSuggestion(row.suggestion, row?.minSuggestion, row?.maxSuggestion),
          }
        : null,
    ];
    return result.filter(value => !!value);
  });
};

const mapRowSuggestion = (suggestion: string, min: string, max: string) => {
  if (suggestion && typeof suggestion !== 'object') {
    return +suggestion;
  } else {
    return {
      min: +min,
      max: +max,
    };
  }
};
