import styled from 'styled-components';
import { DrawerProps } from '@atomic/atm.drawer';
import {
  Breakpoint,
  Color,
  DrawerLargeWidth,
  DrawerWidth,
  HeaderHeight,
  Shadow,
  Spacing,
  ZIndex,
} from '@atomic/obj.constants';

export const HeaderWrapperStyled = styled.div`
  position: fixed;
  width: 100%;
  z-index: ${ZIndex.HeaderWrapperStyled};
`;

interface HeaderProps {
  adminHeader: boolean;
  active: boolean;
}

interface SearchBarProps {
  shortHeader: boolean;
}

export const HeaderDeskStyled = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props: HeaderProps) => (props.adminHeader ? Color.GrayXDark : Color.CallToAction)};
  box-shadow: ${Shadow.Small};
  position: sticky;
  z-index: ${ZIndex.HeaderDeskStyled};
  transition: height 0.3s;
  @media all and (max-width: ${Breakpoint.lg}em) {
    display: flex;
  }
`;

export const LogoHeader = styled.div`
  width: auto;
  padding: ${Spacing.Small} ${Spacing.XLarge} ${Spacing.Small} ${Spacing.Large};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props: SearchBarProps) => (props.shortHeader ? HeaderHeight.DeskShort : HeaderHeight.Desk)};
  transition: height 0.3s;
  @media all and (max-width: ${Breakpoint.lg}em) {
    height: ${HeaderHeight.DeskShort};
    order: 3;
  }
`;

export const HeaderMobileStyled = styled.div`
  display: ${(props: HeaderProps) => (props.adminHeader ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  height: ${HeaderHeight.Mobile};
  background-color: ${(props: HeaderProps) => (props.adminHeader ? Color.GrayXDark : Color.CallToAction)};
  box-shadow: ${Shadow.Small};
  @media all and (min-width: ${Breakpoint.lg}em) {
    display: none;
  }
`;

export const DrawerMenuStyled = styled.div`
  position: absolute;
  background-color: ${Color.GrayXLight};
  width: ${(props: DrawerProps) => (!props.active ? 0 : props.large ? '100%' : DrawerWidth)};
  height: 100%;
  ${(props: DrawerProps) => (props.right ? 'right: 0;' : 'left: 0;')}
  top: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overscroll-behavior: contain;
  transition: ${(props: DrawerProps) => (props.active ? 'width .3s' : 'width .3s, z-index .3s .3s')};
  overflow: scroll;
  z-index: ${(props: DrawerProps) => (props.active ? '2' : 'initial')};

  @media all and (min-width: ${Breakpoint.sm}em) {
    width: ${(props: DrawerProps) => (!props.active ? 0 : props.large ? DrawerLargeWidth : DrawerWidth)};
  }
`;

export const DrawerOverlayStyled = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background-color: ${Color.Black};
  opacity: ${(props: DrawerProps) => (props.active ? '.3' : '0')};
  z-index: ${(props: DrawerProps) => (props.active ? '1' : '-1')};
  pointer-events: ${(props: DrawerProps) => (props.active ? 'initial' : 'none')};
  transition: ${(props: DrawerProps) =>
    props.active ? 'opacity .3s ease-out' : 'opacity .3s ease-out, z-index .3s .3s'};
`;
