import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import {
  removeCorporateGroupSearch,
  removeSalesGroupSearch,
  removeSalesOrgsSearch,
  setQuerySearch,
} from '@app/modules/components/customer-search/store/customer-search.store';
import { Badge } from '../../../../../../badge/badge.component';
import { FilterBadgesWrapper } from './filter-badges.style';

export const FilterBadges: React.FC = () => {
  const { salesOrgsSearch, corporateGroupSearch, salesGroupSearch, querySearch } = useSelector(
    (state: RootState) => state.customerSearch,
  );
  const dispatch = useDispatch();

  return (
    <FilterBadgesWrapper>
      {corporateGroupSearch.map(corporateGroup => (
        <Badge
          key={corporateGroup.value}
          text={`${corporateGroup.value} - ${corporateGroup.name}`}
          onClick={() => dispatch(removeCorporateGroupSearch(corporateGroup.value))}
        />
      ))}
      {salesOrgsSearch.map(saleOrg => (
        <Badge key={saleOrg.value} text={saleOrg.name} onClick={() => dispatch(removeSalesOrgsSearch(saleOrg.value))} />
      ))}
      {salesGroupSearch.map(salesGroup => (
        <Badge
          key={salesGroup.value}
          text={salesGroup.name}
          onClick={() => dispatch(removeSalesGroupSearch(salesGroup.value))}
        />
      ))}
      {!!querySearch.length && <Badge text={querySearch} onClick={() => dispatch(setQuerySearch(''))} />}
    </FilterBadgesWrapper>
  );
};
