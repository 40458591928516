import { createAsyncThunk } from '@reduxjs/toolkit';
import Container from 'typedi';
import { LogAnalytics } from '@app/core/analytics';
import { OrderUpdateDataSource, OrderUpdateDataSourceParams } from '@app/data/http/order-update.datasource';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { customerIdsJoinedSelector } from '@app/modules/components/customer-search/store/customer-persist.store';
import { OrderUpdateEvents } from '@app/modules/order/order.analytics';
import { setOpenErrorHandlerModal } from '@app/providers/navigation/navigation.store';
import { dateParser } from '@app/utils/date-parser';
import { SLICE_NAME } from '../order-update.constants';
import { IOrderUpdateState, IOrderUpdateTableData } from '../order-update.interfaces';
import { getFormData } from '../utils';

interface IPutNewWalletParams {
  handleSuccess: () => void;
  handleError: () => void;
}

export const putNewWallet = createAsyncThunk(
  `${SLICE_NAME}/putNewWallet`,
  async ({ handleSuccess, handleError }: IPutNewWalletParams, thunkAPI) => {
    const user = Container.get(AuthStorageService).getUser();
    const state: any = thunkAPI.getState();
    const customerIds = customerIdsJoinedSelector(thunkAPI.getState());
    const { generalComment, tableRows }: IOrderUpdateState = state.orderUpdate;
    const saveData = getFormData({ tableRows, language: user?.language });
    const rowsGAT = [];

    tableRows.forEach((item: IOrderUpdateTableData) => {
      rowsGAT.push([
        item.ov,
        item.itemOv,
        item.newDateSolClient
          ? dateParser({ unformattedDate: new Date(item.newDateSolClient).toString(), hideHours: true })
          : '',
        item.newQtdSolClient ? item.newQtdSolClient : '',
        item.cancelOrder,
      ]);
    });

    const payload: OrderUpdateDataSourceParams = {
      data: saveData,
      row: rowsGAT,
      emissionCode: tableRows[0].clientNumber,
      companyName: tableRows[0].nameClient,
      comments: generalComment,
      customerIds,
      user,
    };

    return await OrderUpdateDataSource.putNewWallet(payload)
      .then(() => handleSuccess())
      .catch(error => {
        LogAnalytics.error({
          tipo: OrderUpdateEvents.OrderUpdateError,
          responseStatus: error.response.status,
          responseData: error.response.data?.message,
          customerIds,
        });
        thunkAPI.dispatch(setOpenErrorHandlerModal(true));
        handleError();
      });
  },
);

export const addUpdateOrderReducers = builder => {
  builder.addCase(putNewWallet.pending, state => {
    state.loading = true;
  });
  builder.addCase(putNewWallet.fulfilled, state => {
    state.loading = false;
  });
  builder.addCase(putNewWallet.rejected, state => {
    state.loading = false;
  });
};
