import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStore } from '@app/core/global-store.service';
import { NotificationPanelSteps } from '@app/models/notification-center.model';
import { NotificationStore, useNotificationStore } from '@app/providers/notification.store';
import { setShowActionBar } from '../order-tabs/MI/tab-open/store/order-tab-open.store';
import { OnboardActionTooltip } from './onboard-action-tooltip.component';
import { OnboardNotificationConfigTooltip } from './onboard-notification-config-tooltip.component';
import { OnboardNotificationPanelTooltip } from './onboard-notification-panel-tooltip.component';
import { OnboardSelectionTooltip } from './onboard-selection-tooltip.component';

interface OnboardTooltipCenterOrderTabProps {
  onFinishOnboard: () => void;
}

export const OnboardTooltipCenterOrderTab: React.FC<OnboardTooltipCenterOrderTabProps> = props => {
  const [tooltipSelectionVisible, setTooltipSelectionVisible] = useState<boolean>(true);
  const [tooltipActionVisible, setTooltipActionVisible] = useState<boolean>(false);
  const [tooltipNotificationPanelVisible, setTooltipNotificationPanelVisible] = useState<boolean>(false);
  const [tooltipNotificationConfigVisible, setTooltipNotificationConfigVisible] = useState<boolean>(false);

  const { setCurrentStep, setNotificationPanelOpen } = useStore<NotificationStore>(useNotificationStore);
  const dispatch = useDispatch();

  return (
    <>
      <OnboardSelectionTooltip
        visible={tooltipSelectionVisible}
        onClose={() => props.onFinishOnboard()}
        onClick={() => {
          setTooltipSelectionVisible(false);
          setTooltipActionVisible(true);
          dispatch(setShowActionBar(true));
        }}
      />
      <OnboardActionTooltip
        visible={tooltipActionVisible}
        onClose={() => props.onFinishOnboard()}
        onClick={() => {
          setTooltipActionVisible(false);
          dispatch(setShowActionBar(false));
          setTooltipNotificationPanelVisible(true);
        }}
      />
      <OnboardNotificationPanelTooltip
        visible={tooltipNotificationPanelVisible}
        onClose={() => props.onFinishOnboard()}
        onClick={() => {
          setTooltipNotificationPanelVisible(false);
          setCurrentStep(NotificationPanelSteps.Notification);
          setNotificationPanelOpen(true);
          setTooltipNotificationConfigVisible(true);
        }}
      />
      <OnboardNotificationConfigTooltip
        visible={tooltipNotificationConfigVisible}
        onClose={() => props.onFinishOnboard()}
        onClick={() => {
          setNotificationPanelOpen(false);
          setTooltipNotificationConfigVisible(false);
          props.onFinishOnboard();
        }}
      />
    </>
  );
};
