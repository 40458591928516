import * as React from 'react';
import { FormFieldContext, FormFieldContextState } from '@atomic/obj.form';
import {
  CheckboxCheckedStyledPrimary,
  CheckboxCheckedStyledSecondary,
  CheckboxFieldBulletStyled,
  CheckboxFieldStyled,
  CheckboxFieldTextStyled,
  CheckboxStyle,
  CheckboxUncheckedStyled,
} from './checkbox-field.component.style';

interface CheckboxFieldProps {
  id: any;
  initialChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onClick?: (id?: any) => void;
  onValueChange?: (id: any, checked: boolean) => void;
  paddingLeft?: string;
  style?: string;
  checkboxStyle?: CheckboxStyle;
}

interface CheckboxFieldState {
  checked: boolean;
}

const renderCheckboxCheckedStyled = (checkboxStyle, disabled, onClick) => {
  if (checkboxStyle === CheckboxStyle.Secondary) {
    return <CheckboxCheckedStyledSecondary onClick={onClick} disabled={disabled} checkboxstyle={checkboxStyle} />;
  } else {
    return <CheckboxCheckedStyledPrimary onClick={onClick} disabled={disabled} checkboxstyle={checkboxStyle} />;
  }
};

export class CheckboxField extends React.Component<CheckboxFieldProps, CheckboxFieldState> {
  private formFieldConsumer: FormFieldContextState;

  constructor(props: CheckboxFieldProps) {
    super(props);

    this.state = {
      checked: props.initialChecked || props.checked || false,
    };
  }

  componentDidMount() {
    if (this.isControlled() && this.props.initialChecked !== undefined) {
      throw new Error('Use either the initialChecked prop, or the checked prop, but not both');
    }

    if (this.formFieldConsumer) {
      if (this.isControlled() || this.props.initialChecked !== undefined) {
        throw new Error('Please, use value props in <Form.Field> instead of <CheckboxField> component.');
      }
    }
  }

  render() {
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer;
          const { name = '' } = this.formFieldConsumer || {};
          const checked = this.getCurrentValue();
          return (
            <CheckboxFieldStyled onClick={this.handlePress}>
              <CheckboxFieldBulletStyled
                name={name + '_' + this.props.id}
                type='checkbox'
                checked={checked}
                onChange={this.handlePress}
                value={this.props.id}
                checkboxstyle={this.props.checkboxStyle}
              />
              {renderCheckboxCheckedStyled(this.props.checkboxStyle, this.props.disabled, this.props.onClick)}
              <CheckboxUncheckedStyled disabled={this.props.disabled} />
              <CheckboxFieldTextStyled
                paddingLeft={this.props.paddingLeft}
                disabled={this.props.disabled}
                htmlFor={name + '_' + this.props.id}
              >
                {this.props.children}
              </CheckboxFieldTextStyled>
            </CheckboxFieldStyled>
          );
        }}
      </FormFieldContext.Consumer>
    );
  }

  private handlePress = () => {
    if (!this.props.disabled) {
      if (this.props.onClick) {
        this.props.onClick(this.props.id);
      }

      const checked = !this.getCurrentValue();

      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.id, checked);
      }

      if (this.isControlled()) {
        return;
      }

      if (this.formFieldConsumer) {
        this.formFieldConsumer.onValueChange([this.props.id], checked);
      }

      this.setState({ checked });
    }
  };

  private isControlled = () => this.props.checked !== undefined;

  private getCurrentValue = () => {
    if (this.formFieldConsumer && this.formFieldConsumer.value) {
      return Array.isArray(this.formFieldConsumer.value) && this.formFieldConsumer.value.indexOf(this.props.id) > -1;
    }

    return this.props.checked != null ? this.props.checked : this.state.checked;
  };
}
