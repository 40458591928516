import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Table } from '@atomic/mol.table';
import { VSeparator } from '@atomic/obj.grid';
import { PlantNames } from '../../shipping-request.constants';
import { IShippingRequestRow } from '../../shipping-request.interfaces';
import { ShippingRequestSelectedItemTableBody } from './partials/shipping-request-selected-item-table-body/shipping-request-selected-item-table-body.component';
import { ShippingRequestSelectedItemTableHeader } from './partials/shipping-request-selected-item-table-header/shipping-request-selected-item-table-header.component';
import {
  ShippingRequestSelectedItemTableOptionsScroll,
  ShippingRequestSelectedItemTableScroll,
  ShippingRequestSelectedItemTableWrapper,
} from './shipping-request-selected-item-table.style';

export const ShippingRequestSelectedItemTable: React.FC = () => {
  const { suggestionOptions, openShippingRequestSuggestionModalMaterial, mog, pin, cha } = useSelector(
    (state: RootState) => state.shippingRequest,
  );

  const getSelectedRows = (): IShippingRequestRow[] => {
    let selectedRows: IShippingRequestRow[] = [];

    switch (openShippingRequestSuggestionModalMaterial.plant) {
      case PlantNames.MOGI:
        selectedRows = mog.bpGroup
          .find(group => group.bp === openShippingRequestSuggestionModalMaterial.bp)
          ?.orderedShippingMaterialUserGroup.find(
            shippingMaterial => shippingMaterial.material === openShippingRequestSuggestionModalMaterial.material,
          )?.ovs;
        break;
      case PlantNames.PINDA:
        selectedRows = pin.bpGroup
          .find(group => group.bp === openShippingRequestSuggestionModalMaterial.bp)
          ?.orderedShippingMaterialUserGroup.find(
            shippingMaterial => shippingMaterial.material === openShippingRequestSuggestionModalMaterial.material,
          )?.ovs;
        break;
      case PlantNames.CHARQ:
        selectedRows = cha.bpGroup
          .find(group => group.bp === openShippingRequestSuggestionModalMaterial.bp)
          ?.orderedShippingMaterialUserGroup.find(
            shippingMaterial => shippingMaterial.material === openShippingRequestSuggestionModalMaterial.material,
          )?.ovs;
        break;
      default:
        break;
    }

    return selectedRows;
  };

  const handleTableWrapper = () => {
    const selectedRows = getSelectedRows();

    return (
      <ShippingRequestSelectedItemTableWrapper>
        <Table collapse>
          <ShippingRequestSelectedItemTableHeader />
          <ShippingRequestSelectedItemTableBody selectedRows={selectedRows} />
          <VSeparator />
        </Table>
      </ShippingRequestSelectedItemTableWrapper>
    );
  };

  if (suggestionOptions?.length > 1) {
    return (
      <ShippingRequestSelectedItemTableOptionsScroll>
        {handleTableWrapper()}
      </ShippingRequestSelectedItemTableOptionsScroll>
    );
  } else {
    return <ShippingRequestSelectedItemTableScroll>{handleTableWrapper()}</ShippingRequestSelectedItemTableScroll>;
  }
};
