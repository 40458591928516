import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

export const StockTabTooltipStyled = styled.div`
  max-height: '600px';
  background-color: ${Color.White};
  padding: ${Spacing.Medium};
  width: 290px;
  height: 70px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
`;
