import { css } from 'styled-components';
import { InputDisabledCss, InputValueCss } from '@atomic/atm.typography';
import { Border, BrandColor, Color, FieldHeight, FontFamily, FontSize, Spacing } from '@atomic/obj.constants';

export interface FieldProps {
  invalid?: boolean;
  disabled?: boolean;
  small?: boolean;
}

export const fieldBorderCss = css`
  border-radius: ${Border.Radius};
  overflow: hidden;
  border: ${Border.Width} solid;
  border-color: ${(props: FieldProps) => (props.invalid ? Color.Alert : Color.GrayLight)};

  @media all and (min-width: 48em) {
    :hover {
      box-shadow: inset 0 0 0 ${Border.Width} ${Color.Gray};
      border-color: ${Color.Gray};
    }
  }

  :focus {
    border-color: ${Color.Primary};
    /* Box-shadow instead of border-width, so the text won't dance */
    box-shadow: inset 0 0 0 ${Border.Width} ${Color.Primary};
  }
`;

export const fieldTypographyCss = css`
  ${(props: FieldProps) => (props.disabled ? InputDisabledCss : InputValueCss)}
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Small};
`;

export const fieldCss = css`
  ${fieldTypographyCss}
  width: ${(props: FieldProps) => (props.small ? '70px' : '100%')};
  height: ${FieldHeight};
  padding: ${Spacing.Small};
  align-self: stretch;
  background-color: ${BrandColor.LightBlue};

  :focus {
    outline: none;
  };

  /* https://stackoverflow.com/a/38573257/3670829 */
  -webkit-appearance: none;

  transition: all .3s;
`;
