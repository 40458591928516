import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { isGKNSelector } from '@app/modules/components/customer-search/store/customer-search.selectors';
import { nameBasedOnPlant } from '@app/modules/shipping-request/shipping-request.constants';
import { IShippingRequestRow } from '@app/modules/shipping-request/shipping-request.interfaces';
import { getSummaryStockQuantityTotal } from '@app/modules/shipping-request/shipping-request.utils';
import {
  selectedRowsByPlantSelector,
  totalRequestedShippingQuantitySelector,
} from '@app/modules/shipping-request/store/shipping-request.selectors';
import { formatQtyField } from '@app/utils/string-utils';
import { Body, DD } from '@atomic/atm.typography';
import { SummaryInfoWrapperStyled } from './summary-card-info.style';

interface SummaryCardInfoProps {
  plant: string;
}

export const SummaryCardInfo: React.FC<SummaryCardInfoProps> = ({ plant }) => {
  const tableRows: IShippingRequestRow[] = useSelector((state: RootState) => selectedRowsByPlantSelector(state, plant));
  const isGKN = useSelector(isGKNSelector);
  const qtyTotalWhished = useSelector((state: RootState) => totalRequestedShippingQuantitySelector(state, plant));
  const totalStockQuantity: number = getSummaryStockQuantityTotal(tableRows);
  const total = isGKN ? qtyTotalWhished : totalStockQuantity;

  return (
    <SummaryInfoWrapperStyled>
      <Body>{nameBasedOnPlant[plant]}</Body>
      <DD>{formatQtyField(total) + 't'}</DD>
    </SummaryInfoWrapperStyled>
  );
};
