import React from 'react';
import { useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch } from '@app/core/redux/store';
import { Divisor } from '@atomic/atm.divisor';
import { Frame } from '@atomic/atm.frame';
import { H3 } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { OrderInputBaseboard } from '../../components/order-input-baseboard/order-input-baseboard';
import { OrderInputEvents } from '../../order-input.analytics';
import { OrderInputPhaseEnum } from '../../order-input.constants';
import { OrderInputStrings } from '../../order-input.string';
import { setPhase } from '../../store/order-input.store';
import { ReceiverFormDisabled } from '../order-input-amount/partials/amount-table/receiver-form-disabled/receiver-form-disabled';
import { MultipleAlert } from './multiple-alert/multiple-alert';
import { MultipleTable } from './multiple-table/multiple-table';
import { MultipleAlertModal } from './multiple-table/partials/multiple-alert-modal/multiple-alert-modal';
import { OrderInputMultipleGridWrapper, OrderInputMultipleWrapper } from './order-input-multiple.style';

const Strings = OrderInputStrings.pt;

export const OrderInputMultiple: React.FC = () => {
  const dispatch = useDispatch<AppThunkDispatch>();

  return (
    <OrderInputMultipleWrapper data-testid='order-input-multiple-phase'>
      <OrderInputMultipleGridWrapper fluid>
        <Frame>
          <FlexColumn pt pl>
            <H3>{Strings.title}</H3>
            <FlexColumn fullWidth mt pb pr>
              <ReceiverFormDisabled title={OrderInputStrings.pt.phases.Revision} />
              <Divisor />
              <MultipleAlert />
              <MultipleTable />
            </FlexColumn>
          </FlexColumn>
        </Frame>
      </OrderInputMultipleGridWrapper>
      <OrderInputBaseboard
        onActionClick={() => {
          LogAnalytics.click({
            tipo: OrderInputEvents.OrderInputOptionClick,
            phase: OrderInputPhaseEnum.Resume,
          });
          dispatch(setPhase(OrderInputPhaseEnum.Resume));
        }}
        onReturnClick={() => dispatch(setPhase(OrderInputPhaseEnum.DataAndAmout))}
      />
      <MultipleAlertModal />
    </OrderInputMultipleWrapper>
  );
};
