import styled from 'styled-components';
import { BrandColor, Color, Spacing } from '@atomic/obj.constants';

interface SearchMaterialWrapperProps {
  hasError?: boolean;
}

export const SearchMaterialWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 400px;
  border: ${(props: SearchMaterialWrapperProps) => props.hasError && '2px solid'};
  border-color: ${(props: SearchMaterialWrapperProps) => props.hasError && Color.MediumAlert};
  border-radius: ${(props: SearchMaterialWrapperProps) => props.hasError && Spacing.XSmall};

  & > div > div {
    width: 100%;
  }

  input {
    text-align: left !important;
    height: 42px !important;
    width: 100% !important;
  }
`;

export const MaterialCatalogWrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${BrandColor.Blue};
  font-size: 15px;
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  cursor: pointer;
`;
