import { Select, SearchField, Button, Icon } from '@gerdau/hefesto';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AdminUserFilterOptionsId, ExcelType } from '@app/models/admin.model';
import { AdminFilterStyled, AdminSearchStyled } from '@app/modules/admin/admin.page.style';
import { getAllUsers } from '@app/modules/admin/store/thunk/users-get-all.thunk';
import { getUsers } from '@app/modules/admin/store/thunk/users-get.thunk';
import { LIMIT_ITEMS_PER_PAGE, SEARCH_DEBOUNCE_TIME } from '@app/modules/admin/utils/utils';
import { AdminStrings } from '../../../../admin.string';
import {
  setShowUserForm,
  setUserFilterOption,
  setUserTextQuery,
  setSelectedUserFilterOptions,
  setClearUserFilterQuery,
  clearAllUsers,
} from '../../../../store/admin.store';
import { PermissionsOptionsSelect, ProfileTypesOptionsSelect } from '../../../admin-user-form/utils/utils';
import { exportToCSV } from '../utils/export-excel';
import { AdminUserList } from './admin-user-list/admin-user-list.component';
import { AdminUserPagination } from './admin-user-pagination/admin-user-pagination.component';
import { AdminUserViewContainer, AdminUserViewWrapperStyled, AdminUserActionButtonStyled } from './admin-user.styles';
import { UserFilterOptions } from './utils/utils';

export const AdminUserComponent: React.FC = () => {
  const { userSearch, loadingAllUsers, allUsers } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = AdminStrings.pt;

  useEffect(() => {
    dispatch(setClearUserFilterQuery(''));
  }, [userSearch.filterOption]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const query = {
        skip: 0,
        limit: LIMIT_ITEMS_PER_PAGE,
        filterType: userSearch.filterOption,
        filterValue: userSearch.textQuery,
      };

      if (userSearch.selectedOptions?.length) {
        query.filterValue = userSearch.selectedOptions.join();
      } else if (userSearch.textQuery?.length > 2) {
        query.filterValue = userSearch.textQuery;
      } else {
        query.filterValue = '';
      }

      dispatch(getUsers(query));
    }, SEARCH_DEBOUNCE_TIME);

    return () => clearTimeout(delayDebounceFn);
  }, [userSearch.textQuery, userSearch.selectedOptions]);

  useEffect(() => {
    if (allUsers.length) {
      exportToCSV(allUsers, strings.userView.actions.exportFileName, ExcelType.Users);
      dispatch(clearAllUsers([]));
    }
  }, [allUsers]);

  const handleSearchField = () => {
    switch (userSearch.filterOption) {
      case AdminUserFilterOptionsId.Profile:
        return (
          <Select
            isMultipleSelect
            label={strings.common.profileSelect}
            name='userProfileSelect'
            value={userSearch.selectedOptions}
            disabled={!userSearch.filterOption || userSearch.filterOption !== AdminUserFilterOptionsId.Profile}
            onSelect={e => dispatch(setSelectedUserFilterOptions(e))}
            options={ProfileTypesOptionsSelect}
          />
        );
      case AdminUserFilterOptionsId.Tab:
        return (
          <Select
            isMultipleSelect
            label={strings.common.tabSelect}
            name='userTabSelect'
            value={userSearch.selectedOptions}
            disabled={!userSearch.filterOption || userSearch.filterOption !== AdminUserFilterOptionsId.Tab}
            onSelect={e => dispatch(setSelectedUserFilterOptions(e))}
            options={PermissionsOptionsSelect}
          />
        );
      default:
        return (
          <SearchField
            label={strings.common.search}
            name='userSearchField'
            inputValue={userSearch.textQuery}
            disabled={!userSearch.filterOption}
            onChange={e => dispatch(setUserTextQuery(e.target.value))}
          />
        );
    }
  };

  const downloadExcel = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(getAllUsers());
  };

  return (
    <AdminUserViewContainer>
      <AdminUserViewWrapperStyled>
        <AdminFilterStyled>
          <Select
            label={strings.common.filter}
            name='userFilterSelect'
            value={userSearch.filterOption}
            onSelect={e => dispatch(setUserFilterOption(e))}
            options={UserFilterOptions}
          />
        </AdminFilterStyled>
        <AdminSearchStyled>{handleSearchField()}</AdminSearchStyled>
        <AdminUserActionButtonStyled>
          <Button
            name='addUserBtn'
            icon={<Icon option='Plus' />}
            fullWidth={true}
            onClick={() => {
              dispatch(setShowUserForm(true));
            }}
          >
            {strings.userView.actions.addButton}
          </Button>
        </AdminUserActionButtonStyled>
        <AdminUserActionButtonStyled margingLeft>
          <Button
            name='userExcellExportBtn'
            variant='secondary'
            loading={loadingAllUsers}
            icon={<Icon option='XLS' />}
            fullWidth={true}
            onClick={e => {
              downloadExcel(e);
            }}
          >
            {strings.userView.actions.exportButton}
          </Button>
        </AdminUserActionButtonStyled>
      </AdminUserViewWrapperStyled>
      <AdminUserList />
      <AdminUserPagination />
    </AdminUserViewContainer>
  );
};
