import Container from 'typedi';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const handleUnauthorized = err => {
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);

  if (err.message.includes('401')) {
    authStorageService.logout();
  }
};
