import React from 'react';
import { Col, Row, VSeparator } from '@atomic/obj.grid';
import { TruckTrackingData } from './truck-tracking-data/truck-tracking-data.component';
import { TruckTrackingSubtitle } from './truck-tracking-subtitle/truck-tracking-subtitle.component';
import { TruckTrackingUpdate } from './truck-tracking-update/truck-tracking-update.component';

export const TruckTrackingDetails: React.FC = () => {
  return (
    <>
      <TruckTrackingUpdate />
      <VSeparator />
      <Row>
        <Col xs={6}>
          <TruckTrackingData />
        </Col>
        <Col xs={6}>
          <TruckTrackingSubtitle />
        </Col>
      </Row>
    </>
  );
};
