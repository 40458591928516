import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Color, FontSize } from '@atomic/obj.constants';

export const OrderNumberErrorWrapper = styled(FlexRow)`
  font-size: ${FontSize.XSmall};
  color: ${Color.Alert};
  height: 18px;
  justify-content: 'center';
`;

export const OrderItemOffSet = styled(FlexRow)`
  height: 18px;
`;
