/* eslint-disable prettier/prettier */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Badge } from '@atomic/atm.badge';
import { IOrderTabOpenInternationalStrings, Strings } from '../order-tab-open-international.string';

export interface DeliveryStatusInternationalRendererProps {
    data: {
        dateMaxDeliveryClient: string;
        visDeliveryMax: string;
    };
}

export const DeliveryStatusInternationalRenderer: React.FC<DeliveryStatusInternationalRendererProps> = props => {
    const { userInfo } = useSelector((state: RootState) => state.auth);

    const strings: IOrderTabOpenInternationalStrings = Strings[userInfo.language];

    const { data } = props;
    if (!data) {
        return null;
    }

    const dateMaxDelivery = parseInt(data.dateMaxDeliveryClient, 10);
    const dateMaxVisibility = parseInt(data.visDeliveryMax, 10);
    if (isNaN(dateMaxDelivery) || isNaN(dateMaxVisibility) || dateMaxVisibility === 0) {
        return <Badge color='neutral'>{strings.deliveryStatus.undefined}</Badge>;
    }

    return dateMaxVisibility > dateMaxDelivery ? (
        <Badge color='alert'>{strings.deliveryStatus.delay}</Badge>
    ) : (
        <Badge color='success'>{strings.deliveryStatus.onTime}</Badge>
    );
};
