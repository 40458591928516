import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { MutableRefObject } from 'react';
import { StatusByLanguage } from '@app/modules/order/grid-filters/grid-open-international-row-filter-options';
import { sizeInFormatter, valueQtyFormatter } from '@app/utils/order-column-formatter';

interface HandleFilterCheckParams {
  checkedFilters: string;
  statusFilter: string[];
  language: string;
}
interface HandleFilterParams {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  filter: string;
  language: string;
  handleFilterCheck: ({ checkedFilters, language }: HandleFilterCheckParams) => any;
}

export const handleFilter = ({ gridEvent, filter, language, handleFilterCheck }: HandleFilterParams) => {
  const orderTabOpenMaritimeStatusKey = StatusByLanguage[language];

  const statusFilter = [
    orderTabOpenMaritimeStatusKey.NotConfirmed,
    orderTabOpenMaritimeStatusKey.Confirmed,
    orderTabOpenMaritimeStatusKey.InAnalysis,
  ];

  if (!gridEvent || !gridEvent.current) {
    return;
  }

  const filterData = gridEvent.current.api.getFilterInstance('salesOrderStatus');

  if (filterData) {
    const aux = handleFilterCheck({ checkedFilters: filter, statusFilter, language });

    const model = Object.keys(aux).reduce((acc, cur) => {
      if (aux[cur]) {
        acc.push(cur);
      }

      return acc;
    }, []);

    filterData.setModel({ values: model });
    gridEvent.current.api.onFilterChanged();
  }
};

export const handleFilterCheck = ({ checkedFilters, statusFilter, language }: HandleFilterCheckParams) => {
  let notConfirmed = true;
  let inAnalysis = true;
  let confirmed = true;
  let checkedFilterSplit = [];

  const orderTabOpenMaritimeStatusKey = StatusByLanguage[language];

  if (checkedFilters) {
    checkedFilterSplit = checkedFilters.split(',');

    statusFilter.forEach(c => {
      if (c === orderTabOpenMaritimeStatusKey?.NotConfirmed) {
        const isCheckedNotConfirmed = checkedFilterSplit.find(
          chk => chk === orderTabOpenMaritimeStatusKey?.NotConfirmed,
        );
        isCheckedNotConfirmed ? (notConfirmed = true) : (notConfirmed = false);
      }

      if (c === orderTabOpenMaritimeStatusKey?.InAnalysis) {
        const isCheckedInAnalysis = checkedFilterSplit.find(chk => chk === orderTabOpenMaritimeStatusKey?.InAnalysis);
        isCheckedInAnalysis ? (inAnalysis = true) : (inAnalysis = false);
      }

      if (c === orderTabOpenMaritimeStatusKey?.Confirmed) {
        const isCheckedConfirmed = checkedFilterSplit.find(chk => chk === orderTabOpenMaritimeStatusKey?.Confirmed);
        isCheckedConfirmed ? (confirmed = true) : (confirmed = false);
      }
    });
  } else {
    notConfirmed = false;
    inAnalysis = false;
    confirmed = false;
  }

  return {
    [orderTabOpenMaritimeStatusKey.NotConfirmed]: notConfirmed,
    [orderTabOpenMaritimeStatusKey.InAnalysis]: inAnalysis,
    [orderTabOpenMaritimeStatusKey.Confirmed]: confirmed,
  };
};

export const handleMaritmeOpenTabColumn = (columns: ColDef[], language: string) => {
  const valueColumnsFields = [
    'qtyCustomerRequiredTons',
    'QtyAgreedTons',
    'qtyStockPendOvItem',
    'qtyTotalStockOvItem',
    'qtyShippedOvItem',
    'qtyPortoFronteiraOvItem',
    'size',
    'width',
    'thickness',
  ];

  if (columns) {
    columns.forEach(column => {
      if (column.field === 'salesOrderStatus') {
        column.filter = 'agSetColumnFilter';
        column.floatingFilter = false;
      }

      if (column.field === 'sizeIn') {
        column.valueFormatter = param => sizeInFormatter(param, language);
      }

      if (valueColumnsFields.includes(column.field)) {
        column.valueFormatter =
          language === 'in' ? param => valueQtyFormatter(param, true) : param => valueQtyFormatter(param, false);
      }
    });
  }
};
