import { ISurrogateState } from '../surrogate.interfaces';
import { initialState } from './surrogate.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setEmail: (state: ISurrogateState, { payload }) => {
    state.email = payload;
  },
  setSurrogate: (state: ISurrogateState, { payload }) => {
    state.surrogatesTo = [...state.surrogatesTo, payload];
  },
  setExpiresFrom: (state: ISurrogateState, { payload }) => {
    state.expiresFrom = payload;
  },
  setExpiresTo: (state: ISurrogateState, { payload }) => {
    state.expiresTo = payload;
  },
  setExpires: (state: ISurrogateState, { payload }) => {
    state.isIndeterminateTime = payload;
  },
  setClearError: (state: ISurrogateState, _) => {
    state.error = false;
    state.errorMessage = '';
  },
  setClearSurrogateInfo: (state: ISurrogateState) => {
    state._id = '';
    state.from = '';
    state.surrogatesTo = [];
    state.isIndeterminateTime = false;
    state.expiresFrom = null;
    state.expiresTo = null;
    state.error = false;
  },
  setSuccessMessage: (state: ISurrogateState, { payload }) => {
    state.successMessage = payload;
  },
  removeSurrogate: (state: ISurrogateState, { payload }) => {
    const newSelectedEmails: string[] = [...state.surrogatesTo];
    newSelectedEmails.forEach((email: string, index: number, array: string[]) => {
      if (email === payload) {
        array.splice(index, 1);
      }
    });
    state.surrogatesTo = newSelectedEmails;
  },
  setShowRemoveSurrogate: (state: ISurrogateState, { payload }) => {
    state.showRemoveSurrogate = payload;
  },
  setShowSurrogateInfoModal: (state: ISurrogateState, { payload }) => {
    state.surrogateInfoModalOpened = payload;
  },
};
