import React from 'react';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Label } from '@atomic/atm.typography';
import { Color, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { DateWarningWrapper } from '../../order-input-amount/partials/amount-table/date-warning/date-warning.style';

const strings = OrderInputStrings.pt.multiple;

export const MultipleAlert: React.FC = () => {
  return (
    <DateWarningWrapper pt={Spacing.Small} pb={Spacing.Small} pl mb>
      <FaIcon.Custom icon='exclamation-triangle' size='sm' color={Color.Warning} />
      <Label size={FontSize.Small} weight={FontWeight.Normal} pl={Spacing.Small}>
        {strings.alertMultiple}
      </Label>
    </DateWarningWrapper>
  );
};
