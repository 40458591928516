import React from 'react';
import { Frame } from '@atomic/atm.frame';
import { BodySecondary } from '@atomic/atm.typography';
import { ZIndex } from '@atomic/obj.constants';
import { IDashBoardStrings, Strings } from '../../dashboard.string';
import { DashboardHeaderWrapper } from './dashboard-header.style';

export const DashboardHeader: React.FC = () => {
  const strings: IDashBoardStrings = Strings.pt;

  return (
    <Frame zIndex={ZIndex.DashboardHeaderMessage}>
      <DashboardHeaderWrapper>
        <BodySecondary>{strings.headerMessage}</BodySecondary>
      </DashboardHeaderWrapper>
    </Frame>
  );
};
