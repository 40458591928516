/* eslint-disable complexity */
import * as qs from 'qs';
import { QuoteFilterData, QuoteFilterRange } from '@app/models/quote-filters.model';

export interface QuoteFilterDto {
  sort?: string;
  startDate?: string;
  endDate?: string;
  myStep?: boolean;
  myClients?: boolean;
  skip?: number;
  limit?: number;
  count?: boolean;
  order?: string;
}

interface Plant {
  key?: string;
  review?: {
    isReviewed?: boolean;
    isRequested?: boolean;
  };
  selection?: {
    isSelected?: boolean;
  };
}
export interface QuoteFilterParamsDto {
  clients?: {
    name?: string;
    code?: string;
    visible?: boolean;
  };
  id?: number;
  specifications?: string;
  status?: string;
  priority?: string;
  hasPrice?: boolean;
  clientVisible?: boolean;
  flow?: {
    type?: string;
  };
  userCreator?: {
    email?: string;
  };
  product?: {
    steelName?: string;
    shape?: string;
    diameter?: {
      min?: number;
      max?: number;
    };
    side?: {
      min?: number;
      max?: number;
    };
    width?: {
      min?: number;
      max?: number;
    };
    thickness?: {
      min?: number;
      max?: number;
    };
    cornerRadius?: {
      min?: number;
      max?: number;
    };
  };
  plants?: Plant[];
  provider?: string;
}

export interface QuoteFilterStatusDto {
  name: string;
}

export interface QuoteFilterClientDto {
  name: string[];
  code: string[];
}

export interface QuoteFilterUserDto {
  email?: string;
}

export interface QuoteFilterFlowDto {
  type?: string;
}

export interface QuoteFilterPlantDto {
  key: string;
  review?: {
    isReviewed?: boolean;
    isRequested?: boolean;
  };
  selection?: {
    isSelected?: boolean;
  };
}

export interface QuoteFilterQueryDto {
  STEEL_NAME?: string;
  GENERAL_SPECIFICATION?: string;
  SPECIFIC_SPECIFICATION?: string;
  FORMA?: string;
  RAIO_DE_CANTO?: string;
  ESPESSURA?: QuoteFilterRange;
  BITOLA?: QuoteFilterRange;
  LARGURA?: QuoteFilterRange;
  LADO?: QuoteFilterRange;
}

function convertNumber(value) {
  return value.replace(',', '.');
}

export const mapFilter = (data: QuoteFilterData) => {
  const filter: QuoteFilterParamsDto = {};

  if (data?.client && data?.client.length > 0) {
    const cli = [];
    data.client.forEach(item => {
      if (!item.includes('-')) {
        cli.push(item);
      }
    });
    if (cli.length > 0) {
      filter.clients = {
        name: cli.join('|'),
      };
    }
  }

  if (data?.codeClients && data?.codeClients.length > 0) {
    filter.clients = { ...filter.clients, code: data.codeClients.join('|') };
  }

  if (data?.clientVisible) {
    filter.clients = { ...filter.clients, visible: data?.clientVisible };
  }

  if (data?.id && data?.id !== null) {
    filter.id = data.id;
  }

  if (data?.specification && data?.specification !== null) {
    filter.specifications = data.specification;
  }

  if (data?.status && data?.status !== null && data?.status.length > 0) {
    filter.status = data.status.join('|');
  }

  if (data?.priority && data?.priority !== null && data?.priority.length > 0) {
    filter.priority = data.priority.join('|');
  }

  if (data?.hasPrice && data?.hasPrice !== null) {
    filter.hasPrice = data.hasPrice;
  }

  if (data?.flow && data?.flow !== null && data?.flow.length > 0) {
    filter.flow = {
      type: data.flow.join('|'),
    };
  }

  if (data?.email && data?.email !== null) {
    filter.userCreator = {
      email: data.email,
    };
  }

  if (data?.steel && data?.steel !== null) {
    filter.product = { ...filter.product, steelName: data.steel };
  }
  if (data?.form && data?.form !== null) {
    if (data.form === 'QUADRADO' || data.form === 'SEXTAVADO') {
      filter.product = { ...filter.product, shape: data.form };
      if (data?.sideMin && data?.sideMax) {
        filter.product = {
          ...filter.product,
          side: { min: +convertNumber(data.sideMin), max: +convertNumber(data.sideMax) },
        };
      }
    } else if (data.form === 'RETANGULAR' || data.form === 'CHATO') {
      filter.product = { ...filter.product, shape: data.form };
      if (data?.thicknessMin && data?.thicknessMax && data?.widthMin && data?.widthMax) {
        filter.product = {
          ...filter.product,
          thickness: { min: +convertNumber(data.thicknessMin), max: +convertNumber(data.thicknessMax) },
          width: { min: +convertNumber(data.widthMin), max: +convertNumber(data.widthMax) },
        };
      }
    } else if (data.form === 'REDONDO') {
      filter.product = { ...filter.product, shape: data.form };
      if (data?.gaugeMin && data?.gaugeMax) {
        filter.product = {
          ...filter.product,
          diameter: { min: +convertNumber(data.gaugeMin), max: +convertNumber(data.gaugeMax) },
        };
      }
    }
  }

  if (data?.charqueadas?.length > 1 || data?.pindamonhangaba?.length > 1 || data?.mogiDasCruzes?.length > 1) {
    filter.plants = [];
    if (data?.charqueadas.length > 1) {
      filter.plants.push({
        key: 'charqueadas',
        review: {
          isReviewed: data.charqueadas.includes('reviewed'),
          isRequested: data.charqueadas.includes('required'),
        },
        selection: {
          isSelected: data.charqueadas.includes('selected'),
        },
      });
    }
    if (data?.pindamonhangaba.length > 1) {
      filter.plants.push({
        key: 'pindamonhangaba',
        review: {
          isReviewed: data.pindamonhangaba.includes('reviewed'),
          isRequested: data.pindamonhangaba.includes('required'),
        },
        selection: {
          isSelected: data.pindamonhangaba.includes('selected'),
        },
      });
    }
    if (data?.mogiDasCruzes.length > 1) {
      filter.plants.push({
        key: 'mogiDasCruzes',
        review: {
          isReviewed: data.mogiDasCruzes.includes('reviewed'),
          isRequested: data.mogiDasCruzes.includes('required'),
        },
        selection: {
          isSelected: data.mogiDasCruzes.includes('selected'),
        },
      });
    }
  }

  if (data?.provider && data?.provider !== null) {
    filter.provider = data.provider;
  }

  return filter;
};

export const mapFilterParams = (filter: QuoteFilterData): string => {
  const data: QuoteFilterDto = {
    startDate: filter.startDate,
    endDate: filter.endDate,
    myStep: filter.myAnalysis,
    myClients: filter.myClients,
    skip: filter.skip,
    limit: filter.limit,
  };

  if (filter.sort === 'priorityDate') {
    data.order = filter.sort;
    data.sort = 'desc';
  } else {
    data.sort = filter.sort;
  }

  return qs.stringify(data, { encode: true });
};
