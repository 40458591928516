import React from 'react';
import { useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { Body, ButtonText, H3 } from '@atomic/atm.typography';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { ChatContext } from '../../chat.context';
import { ChatStrings } from '../../chat.string';
import { Attribute } from './chat-result-attribute.component';
import { CompositionTable } from './chat-result-composition.component';
import { FileNorm } from './chat-result-file.component';
import { JominyTable } from './chat-result-jominy.component';
import { MacroataqueTable } from './chat-result-macroataque.component';
import { MicroinclusaoTable } from './chat-result-microinclusao.component';
import { ChatDrawerStyled, ChatResultLabelStyled, ChatResultWrapperStyled } from './chat-result.component.style';

export interface ChatResultItems {
  key: string;
  label: string;
  value: any;
  creation?: { origin: boolean };
  visible?: boolean;
  type?: string;
  file?: File[];
}
interface ChatResultProps {
  initialized?: boolean;
  resetChat: () => void;
}

export const ChatResult: React.FC<ChatResultProps> = props => {
  const { chatResult } = React.useContext(ChatContext);

  const chat = chatResult && Object.entries(chatResult);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo.language].resumeAnalysis;

  const handleAnalysisReset = () => {
    props.resetChat();
  };

  return (
    <ChatResultWrapperStyled initialized={props.initialized}>
      <DefaultListCell bg>
        <Hbox>
          <Hbox.Item>
            <H3>{strings.resume}</H3>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item vAlign='center'>
            <ButtonText onClick={handleAnalysisReset}>{strings.reset}</ButtonText>
          </Hbox.Item>
        </Hbox>
      </DefaultListCell>
      <ChatResultLabelStyled>
        <DefaultListCell bg>
          <Hbox noGrow>
            <Hbox.Item>
              <Body>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResult}</Body>
            </Hbox.Item>
          </Hbox>
        </DefaultListCell>
      </ChatResultLabelStyled>
      <ChatDrawerStyled id='result-analysis'>
        <Scroll.Element>{chat ? chat.map(item => callAttributeComponents(item[0], item[1])) : null}</Scroll.Element>
        <VSeparator />
      </ChatDrawerStyled>
    </ChatResultWrapperStyled>
  );
};

function callAttributeComponents(key: string, attribute: any) {
  if (key === 'SPECIFIC_SPECIFICATION_NAME') {
    return (
      <Hbox.Item key={key}>
        <FileNorm attrKey={key} attribute={attribute} />
      </Hbox.Item>
    );
  } else if (key === 'CHEMICAL_COMPOSITION') {
    return (
      attribute.value.length > 0 && (
        <Hbox.Item key={key}>
          <CompositionTable attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  } else if (key === 'MICRO_INCLUSION') {
    return (
      attribute.value.length > 0 && (
        <Hbox.Item key={key}>
          <MicroinclusaoTable attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  } else if (key === 'HARDENABILITY') {
    return (
      attribute.value.length > 0 && (
        <Hbox.Item key={key}>
          <JominyTable attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  } else if (key === 'MACRO_ETCH') {
    return (
      attribute.value.length > 0 && (
        <Hbox.Item key={key}>
          <MacroataqueTable attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  } else {
    return (
      <Hbox.Item key={key}>
        <Attribute attrKey={key} attribute={attribute} />
      </Hbox.Item>
    );
  }
}
