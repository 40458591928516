import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { MutableRefObject } from 'react';
import { StatusByLanguage } from '@app/modules/order/grid-filters/grid-open-international-row-filter-options';
import { sizeInFormatter, valueQtyFormatter } from '@app/utils/order-column-formatter';

interface HandleFilterCheckParams {
  checkedFilters: string;
  statusFilter: string[];
  language: string;
}
interface HandleFilterParams {
  gridEvent: MutableRefObject<GridReadyEvent | null>;
  filter: string;
  language: string;
  handleFilterCheck: ({ checkedFilters, language }: HandleFilterCheckParams) => any;
}

export const handleFilter = ({ gridEvent, filter, language, handleFilterCheck }: HandleFilterParams) => {
  const orderTabOpenInternationalStatusKey = StatusByLanguage[language];
  const statusFilter = [
    orderTabOpenInternationalStatusKey.NotConfirmed,
    orderTabOpenInternationalStatusKey.Confirmed,
    orderTabOpenInternationalStatusKey.InAnalysis,
  ];

  if (!gridEvent || !gridEvent.current) {
    return;
  }

  const filterData = gridEvent.current.api.getFilterInstance('salesOrderStatus');

  if (filterData) {
    const aux = handleFilterCheck({ checkedFilters: filter, statusFilter, language });

    const model = Object.keys(aux).reduce((acc, cur) => {
      if (aux[cur]) {
        acc.push(cur);
      }

      return acc;
    }, []);

    filterData.setModel({ values: model });
    gridEvent.current.api.onFilterChanged();
  }
};

export const handleFilterCheck = ({ checkedFilters, statusFilter, language }: HandleFilterCheckParams) => {
  let notConfirmed = true;
  let inAnalysis = true;
  let confirmed = true;
  let checkedFilterSplit = [];

  const orderTabOpenInternationalStatusKey = StatusByLanguage[language];

  if (checkedFilters) {
    checkedFilterSplit = checkedFilters.split(',');
    statusFilter.forEach(c => {
      if (c === orderTabOpenInternationalStatusKey?.NotConfirmed) {
        const isCheckedNotConfirmed = checkedFilterSplit.find(
          chk => chk === orderTabOpenInternationalStatusKey?.NotConfirmed,
        );
        isCheckedNotConfirmed ? (notConfirmed = true) : (notConfirmed = false);
      }

      if (c === orderTabOpenInternationalStatusKey?.InAnalysis) {
        const isCheckedInAnalysis = checkedFilterSplit.find(
          chk => chk === orderTabOpenInternationalStatusKey?.InAnalysis,
        );
        isCheckedInAnalysis ? (inAnalysis = true) : (inAnalysis = false);
      }

      if (c === orderTabOpenInternationalStatusKey?.Confirmed) {
        const isCheckedConfirmed = checkedFilterSplit.find(
          chk => chk === orderTabOpenInternationalStatusKey?.Confirmed,
        );
        isCheckedConfirmed ? (confirmed = true) : (confirmed = false);
      }
    });
  } else {
    notConfirmed = false;
    inAnalysis = false;
    confirmed = false;
  }

  return {
    [orderTabOpenInternationalStatusKey.NotConfirmed]: notConfirmed,
    [orderTabOpenInternationalStatusKey.InAnalysis]: inAnalysis,
    [orderTabOpenInternationalStatusKey.Confirmed]: confirmed,
  };
};

export const handleOpenInternationalTabColumn = (columns: ColDef[], language: string) => {
  const valueColumnsFields = [
    'qtyCustomerRequiredTons',
    'qtyAgreedTons',
    'qtyInFrontier',
    'qtyShipped',
    'qtyStockPend',
    'qtyTotalStock',
    'qtyWithoutCRT',
    'qtyWithCRT',
    'size',
    'width',
    'thickness',
  ];

  if (columns) {
    columns.forEach(column => {
      if (column.field === 'salesOrderStatus') {
        column.filter = 'agSetColumnFilter';
        column.floatingFilter = false;
      }

      if (column.field === 'sizeIn') {
        column.valueFormatter = param => sizeInFormatter(param, language);
      }

      if (valueColumnsFields.includes(column.field)) {
        column.valueFormatter =
          language === 'in' ? param => valueQtyFormatter(param, true) : param => valueQtyFormatter(param, false);
      }
    });
  }
};
