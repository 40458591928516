import styled, { css } from 'styled-components';
import { Border, Color, Spacing } from '@atomic/obj.constants';

export const POPOVER_WIDTH = 240;
export const POPOVER_HEIGHT = 120;
export const POPOVER_Z_INDEX = 99;
export const ARROW_DIMENSIONS = 24;

export const OrderStatusPopoverWrapper = styled.div`
  position: absolute;
  z-index: ${POPOVER_Z_INDEX};
  width: ${POPOVER_WIDTH}px;
  height: ${POPOVER_HEIGHT}px;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
`;

export const OrderStatusPopoverBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Medium};
  background-color: ${Color.White};
  border-radius: ${Border.Radius};
  box-shadow: 0px 0px 40px rgba(114, 114, 114, 0.35);
  height: 178px;
`;

export const OrderStatusPopoverRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OrderStatusPopoverFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
`;

interface OrderStatusPopoverDotProps {
  color: string;
}

export const OrderStatusPopoverDotStyled = styled.div`
  background: ${(props: OrderStatusPopoverDotProps) => props.color};
  width: 8px;
  height: 8px;
  border-radius: 16px;
`;

interface OrderStatusPopoverArrowProps {
  xAxis: number;
  alignRight?: boolean;
  alignBottom?: boolean;
}

const arrowUpCss = css`
  border-bottom-color: ${Color.White};
  border-width: 0 ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px;
  top: -9px;
`;

const arrowDownCss = css`
  border-top-color: ${Color.White};
  border-width: ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px 0 ${ARROW_DIMENSIONS}px;
  top: 184px;
`;

export const OrderStatusPopoverArrowStyled = styled.div`
  height: ${ARROW_DIMENSIONS}px;
  width: ${ARROW_DIMENSIONS}px;
  border-color: transparent;
  border-style: solid;
  position: absolute;
  ${(props: OrderStatusPopoverArrowProps) => (props.alignBottom ? arrowDownCss : arrowUpCss)};
  top: 174px;
  left: ${(props: OrderStatusPopoverArrowProps) => (props.alignRight ? 'auto' : `${props.xAxis}px`)};
  right: ${(props: OrderStatusPopoverArrowProps) => (props.alignRight ? '20px' : 'auto')};
  box-shadow: 0px 40px 40px rgba(114, 114, 114, 0.35);
`;
