import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from 'typedi';
import { AppConfig } from '@app/configure-app';
import { AppThunkDispatch, persistor } from '@app/core/redux/store';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { AuthBox } from '@app/modules/components';
import { handleDatadogRUM } from '@app/utils/datadog-rum';
import { AppWindow } from '@app/utils/interfaces';
import { AppPath } from '../app/route-constants';
import { AuthForm } from './partials/form/auth-form.component';
import { setLoading, setLoadingProfile, setLoadingValidateToken } from './store/auth.store';

const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;
const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const AuthPage = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const envVersion = config.version;

  useEffect(() => {
    dispatch(setLoading(false));
    dispatch(setLoadingProfile(false));
    dispatch(setLoadingValidateToken(false));
    handleDatadogRUM(true);

    const logout = window.location.search.indexOf('logout') > -1;

    if (logout) {
      document.cookie = '@access_token=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Domain=.egerdau.com.br;';
      document.cookie = '@sso-access=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Domain=.egerdau.com.br;';
      authStorageService.logout();
      return;
    }
  }, []);

  useEffect(() => {
    const localStoreVersion = localStorage.getItem('version');

    if (envVersion !== localStoreVersion) {
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });

      setTimeout(() => {
        localStorage.setItem('version', envVersion);
        window.location.href = AppPath.AUTHENTICATION;
      }, 0);
    }
  }, []);

  return (
    <AuthBox>
      <AuthForm />
    </AuthBox>
  );
};
