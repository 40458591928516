import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminDataSource } from '@app/data/http/admin.datasource';
import { EditRoleParam } from '@app/data/http/admin.params.dto';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { handleUnauthorized } from '@app/utils/http-utils';
import { SLICE_NAME } from '../../admin.constants';
import { AdminStrings } from '../../admin.string';

export const updateRole = createAsyncThunk(`${SLICE_NAME}/updateRole`, async (role: EditRoleParam, thunkAPI) => {
  const strings = AdminStrings.pt.profileView;

  return await AdminDataSource.updateRole(role)
    .then(() => {
      thunkAPI.dispatch(setToast({ show: true, text: strings.success, variant: ToastVariant.SUCCESS }));
    })
    .catch(err => {
      handleUnauthorized(err);
      thunkAPI.dispatch(setToast({ show: true, text: strings.updateProfileError, variant: ToastVariant.DANGER }));
    });
});

export const addUpdateRoleReducers = builder => {
  builder.addCase(updateRole.pending, state => {
    state.loadingProfile = true;
  });
  builder.addCase(updateRole.fulfilled, state => {
    state.loadingProfile = false;
  });
  builder.addCase(updateRole.rejected, state => {
    state.loadingProfile = false;
  });
};
