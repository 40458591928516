import styled from 'styled-components';
import { Link } from '@app/core/route/link.component';
import { Color } from '@atomic/obj.constants';

export const CellWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 26px;
`;

export const CellLinkStyled = styled(Link)`
  color: ${Color.GrayXDark};
`;
