import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';

interface LanguageSelectorContainerProps {
  opened: boolean;
}

export const LanguageSelectorStyled = styled.div`
  min-height: 30px;
  height: ${(props: LanguageSelectorContainerProps) => (props.opened ? '175px' : '0px')};
  transition-property: height;
  transition-duration: 0.2s;
  padding-bottom: 37px;
`;

export const LanguageSelectorHeader = styled.div`
  min-height: 30px;
  cursor: pointer;
`;

export const LanguageSelectorBody = styled.div`
  background-color: ${Color.GrayLight};
  max-width: 230px;
  padding-top: ${(props: LanguageSelectorContainerProps) => (props.opened ? Spacing.Medium : '0px')};
  padding-right: ${Spacing.Medium};
  padding-bottom: ${Spacing.Medium};
  padding-left: ${Spacing.Large};
  display: ${(props: LanguageSelectorContainerProps) => (props.opened ? 'block' : 'none')};
  height: ${(props: LanguageSelectorContainerProps) => (props.opened ? '142px' : '0px')};
  transition-property: height;
  transition-duration: 0.5s;
`;
