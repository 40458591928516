import { Modal } from '@gerdau/hefesto';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Body } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { AdminStrings } from '../../admin.string';
import { setShowDeleteModal } from '../../store/admin.store';
import { deleteUser } from '../../store/thunk/user-delete.thunk';
import { AdminDeleteModalWrapperStyled, BoldNameWrapperStyled } from './admin-delete-modal.styles';

export const AdminDeleteModal: React.FC = () => {
  const { user, loadingSubmit } = useSelector((state: RootState) => state.admin);
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings = AdminStrings.pt.deleteModal;

  const handleConfirm = () => {
    dispatch(deleteUser(user.email));
  };

  return (
    <AdminDeleteModalWrapperStyled>
      <Modal
        title={strings.title}
        onClose={() => dispatch(setShowDeleteModal(false))}
        modalSize='sm'
        buttons={{
          left: {
            name: 'closeBtn',
            children: strings.cancelBtn,
            onClick: () => dispatch(setShowDeleteModal(false)),
          },
          right: {
            name: 'confirmBtn',
            variant: 'danger',
            loading: loadingSubmit,
            children: strings.deleteBtn,
            onClick: () => handleConfirm(),
          },
        }}
      >
        <FlexRow>
          <Body>{strings.message}</Body>
          <BoldNameWrapperStyled>
            <Body bold={700}>{user.name}?</Body>
          </BoldNameWrapperStyled>
        </FlexRow>
      </Modal>
    </AdminDeleteModalWrapperStyled>
  );
};
