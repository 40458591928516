export interface ICustomerSearchModalHeaderAdminStrings {
  defaultPlaceholder: string;
  bpPlaceholder: string;
  salesOrgsPlaceholder: string;
  corporateGroupPlaceholder: string;
  salesGroupPlaceholder: string;
  titleAdmin: string;
  addfFilterButton: string;
  badgeCorporateGroup: string;
  validationMessage: string;
  filterBy: string;
  searchBy: string;
}

interface ICustomerSearchModalHeaderAdminLanguages {
  pt: ICustomerSearchModalHeaderAdminStrings;
  es: ICustomerSearchModalHeaderAdminStrings;
  in: ICustomerSearchModalHeaderAdminStrings;
}

const pt: ICustomerSearchModalHeaderAdminStrings = {
  filterBy: 'Filtrar por',
  searchBy: 'Pesquisar por',
  defaultPlaceholder: 'Selecione um tipo de filtro',
  bpPlaceholder: 'Busque clientes pelo nome ou pelo seu número de BP',
  salesOrgsPlaceholder: 'Pesquisar por Organização de Vendas',
  salesGroupPlaceholder: 'Pesquisar por Cód. Vendedor(a)',
  corporateGroupPlaceholder: 'Pesquisar por Grupo cliente',
  titleAdmin: 'Busca de clientes',
  addfFilterButton: 'Adicionar',
  badgeCorporateGroup: 'Grupo Cliente',
  validationMessage: 'Selecione ao menos um cliente.',
};

const es: ICustomerSearchModalHeaderAdminStrings = {
  filterBy: 'Filtrar por',
  searchBy: 'Pesquisar por',
  defaultPlaceholder: 'Selecione um tipo de filtro',
  bpPlaceholder: 'Busque clientes pelo nome ou pelo seu número de BP',
  salesOrgsPlaceholder: 'Pesquisar por Organização de Vendas',
  salesGroupPlaceholder: 'Pesquisar por Cód. Vendedor(a)',
  corporateGroupPlaceholder: 'Pesquisar por Grupo cliente',
  titleAdmin: 'Selección de Filial',
  addfFilterButton: 'Adicionar',
  badgeCorporateGroup: 'Grupo Cliente',
  validationMessage: 'Seleccione al menos una filial.',
};

const en: ICustomerSearchModalHeaderAdminStrings = {
  filterBy: 'Filtrar por',
  searchBy: 'Pesquisar por',
  defaultPlaceholder: 'Selecione um tipo de filtro',
  bpPlaceholder: 'Busque clientes pelo nome ou pelo seu número de BP',
  salesOrgsPlaceholder: 'Pesquisar por Organização de Vendas',
  salesGroupPlaceholder: 'Pesquisar por Cód. Vendedor(a)',
  corporateGroupPlaceholder: 'Pesquisar por Grupo cliente',
  titleAdmin: 'Business Partner Selection',
  addfFilterButton: 'Adicionar',
  badgeCorporateGroup: 'Grupo Cliente',
  validationMessage: 'Select at least one business partner.',
};

export const Strings: ICustomerSearchModalHeaderAdminLanguages = {
  pt,
  es,
  in: en,
};
