import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { BodySecondary } from '@atomic/atm.typography';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Color, Spacing } from '@atomic/obj.constants';
import { CustomizedTooltipStrings } from '../customized-tooltip.string';

interface CustomizedTooltipFooterProps {
  step: number;
  totalSteps: number;
  onClick: () => void;
}

export const CustomizedTooltipFooter: React.FC<CustomizedTooltipFooterProps> = ({ step, totalSteps, onClick }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = CustomizedTooltipStrings[userInfo?.language];

  return (
    <FlexRow>
      {!!step && (
        <>
          <FlexColumn vAlign='center' hAlign='flex-start'>
            <BodySecondary color={Color.White}>{strings.step(step, totalSteps)}</BodySecondary>
          </FlexColumn>
          <Hbox.Separator />
        </>
      )}
      <FlexColumn pb={Spacing.Small} hAlign='flex-end'>
        <Button data-testid='next-close-tooltip' kind='secondary' onClick={onClick}>
          {step === totalSteps ? strings.close : strings.next}
        </Button>
      </FlexColumn>
    </FlexRow>
  );
};
