import { ColDef } from 'ag-grid-community';
import { LayoutTabs } from '@app/models/layout.model';
import { IRenderQtyFieldParams, IRenderSubtotalFieldParams } from './grid-subtotal.interfaces';
import { createTotalDiv, getColIndex, getSubtotals, removeFilterInputIfExists, removeTotalDivIfExists } from './utils';

export const renderQtyField = ({ subTotal, language, fieldName, tab }: IRenderQtyFieldParams): void => {
  const gridWrapperElement = document.getElementById(`${tab}-grid`);
  const colindex = getColIndex({ gridWrapperElement, fieldName });
  const elementsWithSameAriaColIndex = gridWrapperElement?.querySelectorAll(`[aria-colindex='${colindex}']`);

  if (elementsWithSameAriaColIndex?.length > 1) {
    const filterCell = elementsWithSameAriaColIndex[1];
    if (filterCell) {
      removeFilterInputIfExists(filterCell);
      const className = `total-column-${fieldName}`;
      removeTotalDivIfExists({ gridWrapperElement, className });
      const totalDiv = createTotalDiv({ language, subTotal, className });
      filterCell.appendChild(totalDiv);
    }
  }
};

export const renderSubtotalField = ({
  tab,
  itens,
  language,
  columnDef,
  isGKN = false,
}: IRenderSubtotalFieldParams): void => {
  if (columnDef) {
    columnDef.forEach((column: ColDef) => {
      const field = column.field.toLowerCase();
      if (field !== 'hasquantitylastheat' && !ignoreGKNFields(field, isGKN, tab)) {
        if (field.includes('qty') || field.includes('qtd') || field.includes('quantity')) {
          const fieldName = column.field;

          const subTotal = getSubtotals({ itens, fieldName });
          renderQtyField({
            subTotal: itens?.length ? subTotal : 0,
            language,
            fieldName,
            tab,
          });
        }
      }
    });
  }
};

export const ignoreGKNFields = (field: string, isGKN: boolean, tab: string): boolean => {
  let ignore = false;
  const gknIgnoredFields = ['agreedquantity', 'qtycustomerrequiredtons'];
  ignore = gknIgnoredFields.includes(field) && tab === LayoutTabs.StockMI && isGKN;
  return ignore;
};
