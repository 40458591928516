import * as React from 'react';
import { Container } from 'typedi/Container';
import { useStore } from '@app/core/global-store.service';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName, UserStore, useUserStore } from '@app/providers';
import { EnhancedError } from './request.model';

export interface LazyRequestHook<Params, Response> {
  loading: boolean;
  error: EnhancedError;
  data: Response;
  performRequest: (params?: Params) => void;
}

export function useLazyRequest<Params, Response>(
  request: (params?: Params) => Promise<Response>,
  onSuccess?: (res: Response) => void,
  onError?: (err: EnhancedError) => void,
): LazyRequestHook<Params, Response> {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<EnhancedError>(null);
  const [data, setData] = React.useState<Response>(null);
  const authStorageService: AuthStorageService = Container.get(AuthStorageService);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const { isLogged } = useStore<UserStore>(useUserStore);

  const memoizedRequest = React.useCallback(request, []);

  const performRequest = React.useCallback(
    async (params: Params): Promise<void> => {
      setLoading(true);
      setError(null);
      try {
        const res = await memoizedRequest(params);
        setData(res);
        setLoading(false);
        onSuccess?.(res);
      } catch (err) {
        const enhancedError = { ...err, message: err.message, code: err.message.replace(/\D/g, '') };
        setError(enhancedError);
        setLoading(false);
        if (err.message.includes('401') && isLogged()) {
          show('alert', 'Sua sessão expirou, por favor faça o login novamente');
          authStorageService.logout();
        } else {
          onError?.(enhancedError);
        }
      }
    },
    [memoizedRequest, onSuccess, onError],
  );

  return { loading, error, data, performRequest };
}
