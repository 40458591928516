import styled from 'styled-components';

export const CustomerSearchBody = styled.div`
  min-height: 50vh;
  height: auto;
`;

export const ListClientsWrapper = styled.div`
  max-height: 50vh;
  overflow: auto;
`;
